import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
} from '@chakra-ui/react';

import { Formik, Form } from 'formik';

import { createItem } from '../../services/items';
import { validateLongLat } from '../../services/portfolios';
import { isSubmitDisabled } from '../../services/utils';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import ErrorAccordion from '../utils/ErrorAccordion';

const AddHedgeItemModal = ({ updateItems, ...rest }) => {
    const bookId = useSelector((state) => state.connections.book);
    const books = useSelector((state) => state.item.dataForItemCreation.books);
    const itemTypes = useSelector((state) => state.item.itemTypes);

    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();
    const { url } = useRouteMatch();

    //Todo: Don't filter by description
    const financialAndPhysicalInstruments = itemTypes.filter(
        (itemType) => itemType.description === 'Financial Instrument' || itemType.description === 'Physical Instrument'
    );
    const defaultItemTypeId =
        financialAndPhysicalInstruments.length > 0 ? financialAndPhysicalInstruments[0].itemTypeId : null;

    const formLabels = {
        itemName: intl.formatMessage({ id: 'hedges_modal_item_name_label' }),
        book: intl.formatMessage({ id: 'hedge_book' }),
        itemType: intl.formatMessage({ id: 'hedges_modal_item_type_label' }),
        emptyPlaceholder: ' ',
        longitude: intl.formatMessage({ id: 'longitude' }),
        latitude: intl.formatMessage({ id: 'latitude' }),
    };

    const initialFormValues = {
        itemName: '',
        bookId,
        itemType: defaultItemTypeId,
        latitude: '',
        longitude: '',
    };

    const validateItemName = (value) => {
        const trimmed = value.trim();

        if (!trimmed) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Deal name' });
        }

        if (trimmed.length > 100) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Deal name', lengthRule: '100 characters or less' }
            );
        }
    };

    const onFormSubmit = async ({ itemName, bookId, itemType, longitude, latitude }) => {
        const successMessage = intl.formatMessage(
            { id: 'common_creation_success' },
            { item: intl.formatMessage({ id: 'hedge_item_text' }) }
        );
        const arcGISError = intl.formatMessage({ id: 'common_item_arcgis_lat_long_save_failed' });

        const payload = {
            description: itemName,
            bookId,
            itemTypeId: itemType,
            pricingPointId: null,
            longitude: longitude !== '' ? longitude : null,
            latitude: latitude !== '' ? latitude : null,
        };

        const { data, arcGisIssue } = await createItem(payload);

        updateItems(data);

        historyPush(`${url}/${data.id}`);

        rest.onClose();
        toast({
            message: successMessage,
            slot: arcGisIssue && <ErrorAccordion message={arcGISError} details={arcGisIssue} status={'warn'} />,
            duration: arcGisIssue && 20000,
        });
    };

    const validate = (values) => {
        let longitude = validateLongLat(values.longitude, 'long', values.latitude);
        let latitude = validateLongLat(values.latitude, 'lat', values.longitude);

        return {
            ...(longitude ? { longitude } : null),
            ...(latitude ? { latitude } : null),
        };
    };

    return (
        <Modal {...rest}>
            <ModalOverlay />

            <ModalContent pt={3} borderRadius="20">
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3">
                        <FormattedMessage id="hedges_add_new" />
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik
                    enableReinitialize
                    initialValues={initialFormValues}
                    onSubmit={onFormSubmit}
                    validate={validate}
                >
                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <ModalBody pb={10} borderBottom="1px" borderColor="border-secondary">
                                <InputFormikField
                                    name="itemName"
                                    type="text"
                                    label={formLabels.itemName}
                                    isRequired
                                    validate={validateItemName}
                                />

                                <SimpleGrid mt={4} columns={{ base: 1, md: 2 }} spacingX={6} spacingY={4}>
                                    <ComboboxFormikField
                                        id="bookId"
                                        name="bookId"
                                        label={formLabels.book}
                                        isRequired
                                        options={books}
                                        valueKey="id"
                                        labelKey="name"
                                    />

                                    <ComboboxFormikField
                                        name="itemType"
                                        label={formLabels.itemType}
                                        isRequired
                                        options={financialAndPhysicalInstruments}
                                        valueKey="itemTypeId"
                                        labelKey="description"
                                    />

                                    <InputFormikField
                                        id="latitude"
                                        name="latitude"
                                        label={formLabels.latitude}
                                        type="number"
                                        isFastField
                                    />

                                    <InputFormikField
                                        id="longitude"
                                        name="longitude"
                                        label={formLabels.longitude}
                                        type="number"
                                        isFastField
                                    />
                                </SimpleGrid>
                            </ModalBody>

                            <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                <Box ml="auto">
                                    <Button variant="secondary" onClick={rest.onClose}>
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id="common_cancel" />
                                        </Box>
                                    </Button>

                                    <Button
                                        type="submit"
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        ml={3}
                                    >
                                        <FormattedMessage id="hedges_modal_create_btn" />
                                    </Button>
                                </Box>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default AddHedgeItemModal;
