import axios from 'axios';

export const getComponentIdList = async (seriesType) => {
    const response = await axios.get(`/charts/lookup-list/${seriesType}`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getAllChartSpecs = async () => {
    const response = await axios.get(`/charts/allchart-specs`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getChartDataSeries = async (chartId, seriesId, filter) => {
    const response = await axios.get(`/charts/time-series/${chartId}/${seriesId}`, filter, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const removeChart = async (chartId) => {
    const response = await axios.delete(`/charts/remove/${chartId}`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getNewChart = async (title) => {
    const response = await axios.get(`/charts/new-chartspec`, {
        params: { title },
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateChart = async (chartSpec) => {
    const response = await axios.post(`/charts/update-chartspec`, chartSpec, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};
