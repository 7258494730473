import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { NavigationClient } from '@azure/msal-browser';

class CustomNavigationClient extends NavigationClient {
    constructor(history) {
        super();
        this.history = history;
    }
    async navigateInternal(url, options) {
        console.log('DEBUG START CustomNavigationClient not sure what it is used for ');
        console.log({ Origin: window.location.origin });
        const HistoryLocation = this.history?.location;
        console.log({ ...HistoryLocation });
        console.log({ url, options, History: this.history });

        const relativePath = url.replace(window.location.origin, '');
        console.log({ relativePath });
        if (options.noHistory) {
            console.log('DEBUG options.noHistory this.history.replace(relativePath); ');
            this.history.replace(relativePath);
        } else {
            console.log('DEBUG this.history.push(relativePath); ');
            this.history.push(relativePath);
        }

        const NewHistory = this.history;
        const NewHistoryLocation = NewHistory?.location;
        console.log({ ...NewHistory });
        console.log({ ...NewHistoryLocation });
        console.log('DEBUG FINISH CustomNavigationClient ');
        return false;
    }
}

const useCustomNavigationClient = () => {
    const { instance } = useMsal();
    const history = useHistory();

    useEffect(() => {
        const navigationClient = new CustomNavigationClient(history);

        instance.setNavigationClient(navigationClient);
    }, [history, instance]);
};

export default useCustomNavigationClient;
