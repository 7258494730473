import { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
} from '@chakra-ui/react';

import useCommonToast from '../../hooks/useCommonToast';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import { isSubmitDisabled } from '../../services/utils';

const AddSubItemModal = ({
    itemTypeId,
    itemType,
    definitions,
    formLabels,
    selectedLoad,
    onCreateSubItem,
    onAddSubItem,
    ...rest
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();

    const validateDescription = (value) => {
        const trimmed = value.trim();
        let errorMessage = '';
        const charsLimit = 50;

        if (trimmed.length === 0) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: `${itemType} name` }
            );
        } else if (trimmed.length > charsLimit) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: `${itemType} name`, lengthRule: `${charsLimit} characters or less` }
            );
        }

        return errorMessage;
    };

    const validateWeatherZone = (value) => {
        let errorMessage = '';

        if (value === '') {
            errorMessage = intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Weather zone' });
        }

        return errorMessage;
    };

    const initialFormValues = {
        description: '',
        modelWeightId: 43, // This is default id for Flat Weight
        moduleProcessId: 1, // This is default id for Default Item Sim
        retailPriceItemId: '',
        weatherZoneId: '',
        nativeLoad: -1, // This is default for false option Native Item
        plantTypeId: null,
    };

    const nativeOptions = useMemo(
        () => [
            { id: 0, description: formLabels.nativeOptionTrue },
            { id: -1, description: formLabels.nativeOptionFalse },
        ],
        [formLabels.nativeOptionTrue, formLabels.nativeOptionFalse]
    );

    const onFormSubmit = async ({ nativeLoad, inputTableMapId, retailPriceItemId, ...rest }) => {
        const payload = {
            itemId: selectedLoad.id,
            retailPriceItemId: retailPriceItemId === '' ? null : retailPriceItemId,
            nativeLoad: nativeLoad === 0,
            itemTypeId,
            ...rest,
        };

        const successMessage = intl.formatMessage({ id: 'common_sub_item_creation_success' }, { type: itemType });

        const tempSubItem = await onCreateSubItem(payload);

        onAddSubItem(tempSubItem);
        toast(successMessage);
    };

    return (
        <Modal {...rest}>
            <ModalOverlay />

            <ModalContent pt={3}>
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="common_item_add_subitem_modal_heading" values={{ item: itemType }} />
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik initialValues={initialFormValues} onSubmit={onFormSubmit}>
                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <ModalBody borderBottom="1px" borderColor="gray.200">
                                <SimpleGrid columns={{ xl: 3 }} spacingX={6} spacingY={4} mb={4}>
                                    <InputFormikField
                                        validate={(value) => validateDescription(value)}
                                        id="description"
                                        name="description"
                                        type="text"
                                        label={<FormattedMessage id="load_definitions_name" />}
                                        isRequired
                                    />

                                    <ComboboxFormikField
                                        id="moduleProcessId"
                                        name="moduleProcessId"
                                        label={formLabels.modelType}
                                        isRequired
                                        options={definitions.modelTypes}
                                        valueKey="id"
                                        labelKey="labelName"
                                        placeholderValue=""
                                        placeholderLabel={formLabels.selectPlaceholder}
                                    />

                                    <ComboboxFormikField
                                        id="weatherZoneId"
                                        name="weatherZoneId"
                                        label={formLabels.weatherZone}
                                        isRequired
                                        validate={(value) => validateWeatherZone(value)} // CHECK VALIDATION
                                        options={definitions.weatherZones}
                                        valueKey="id"
                                        labelKey="name"
                                        placeholderValue=""
                                        placeholderLabel={formLabels.selectPlaceholder}
                                    />

                                    <ComboboxFormikField
                                        id="modelWeightId"
                                        name="modelWeightId"
                                        label={formLabels.dataWeight}
                                        isRequired
                                        options={definitions.dataWeightings}
                                        valueKey="id"
                                        labelKey="description"
                                        placeholderValue=""
                                        placeholderLabel={formLabels.selectPlaceholder}
                                    />

                                    <ComboboxFormikField
                                        id="retailPriceItemId"
                                        name="retailPriceItemId"
                                        label={formLabels.retailPriceItem}
                                        options={definitions.retails}
                                        valueKey="id"
                                        labelKey="description"
                                        showPlaceholder={true}
                                        placeholderValue=""
                                    />

                                    <ComboboxFormikField
                                        id="nativeLoad"
                                        name="nativeLoad"
                                        label={formLabels.native}
                                        options={nativeOptions}
                                        valueKey="id"
                                        labelKey="description"
                                        placeholderValue=""
                                        placeholderLabel={formLabels.selectPlaceholder}
                                    />

                                    {Array.isArray(definitions?.plantTypes) && (
                                        <ComboboxFormikField
                                            id="plantTypeId"
                                            name="plantTypeId"
                                            label={formLabels.plantType}
                                            options={definitions?.plantTypes}
                                            valueKey="id"
                                            labelKey="description"
                                            placeholderLabel="-"
                                            placeholderValue={null}
                                            showPlaceholder={true}
                                        />
                                    )}
                                </SimpleGrid>
                            </ModalBody>

                            <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                <Box ml="auto">
                                    <Button variant="secondary" onClick={rest.onClose}>
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id="common_cancel" />
                                        </Box>
                                    </Button>

                                    <Button
                                        type="submit"
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        ml={3}
                                    >
                                        <FormattedMessage id="common_item_create_btn" />
                                    </Button>
                                </Box>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default AddSubItemModal;
