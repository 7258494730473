import { useState } from 'react';
import { Button, VStack, RadioGroup, Radio } from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';

import MainModal from '../modal/MainModal';

const JobsStatusModal = ({ statuses, onClose, onStatusChange }) => {
    const [selectedStatus, setSelectedStatus] = useState();

    //If userCanSelect field is defined => check value, else don't filter
    const selectableStatuses = statuses.filter((status) =>
        status.userCanSelect !== undefined ? status.userCanSelect : true
    );

    return (
        <MainModal
            size="2xl"
            scrollBehavior="inside"
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="jobs_tracker_change_status_header" />}
            content={
                <>
                    <RadioGroup value={selectedStatus} onChange={setSelectedStatus}>
                        <VStack align="start">
                            {selectableStatuses.map(({ id, description }) => (
                                <Radio key={id} value={id.toString()} mr={5}>
                                    {description}
                                </Radio>
                            ))}
                        </VStack>
                    </RadioGroup>
                </>
            }
            footerRightSlot={
                <Button onClick={() => onStatusChange(selectedStatus)} ml={3} isDisabled={selectedStatus === undefined}>
                    <FormattedMessage id="jobs_tracker_change_status" />
                </Button>
            }
        />
    );
};

export default JobsStatusModal;
