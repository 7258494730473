import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@chakra-ui/react';

import SecondaryButton from '../utils/SecondaryButton';

import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { scopes, msalInstance } from '../../services/auth';

const DownloadButtonCell = ({ node, jobId }) => {
    const { logName, createdDate } = node.data;
    const API_URL = process.env.REACT_APP_API;

    const { connectionId, environmentId, bookId } = axios.defaults.headers;

    const redirectToFileUrl = async () => {
        let url = new URL(`${API_URL}/job-log/${jobId}/download/`);

        const accounts = msalInstance.getAllAccounts();
        const { accessToken } = await msalInstance.acquireTokenSilent({ scopes, account: accounts[0] });
        url.searchParams.append('logName', logName);

        url.searchParams.append('createdDate', createdDate);
        url.searchParams.append('authorization', `Bearer ${accessToken}`);
        url.searchParams.append('environmentId', environmentId);
        url.searchParams.append('connectionId', connectionId);
        url.searchParams.append('bookId', bookId);

        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Flex justifyContent="center" alignItems="center" w="100%">
            <SecondaryButton
                leftIcon={<DownloadIcon />}
                w="auto !important"
                size="sm"
                type="button"
                variant="secondary"
                onClick={redirectToFileUrl}
            >
                <FormattedMessage id="common_download_btn_tooltip_and_label" />
            </SecondaryButton>
        </Flex>
    );
};

export default DownloadButtonCell;
