import { isValidElement } from 'react';
import styled from '@emotion/styled/macro';

const CustomItemIcon = ({ isMaster, icon, isStroke = false, smallIcon = false }) => {
    const LazyIcon = icon;

    return (
        <CustomIcon isMaster={isMaster} isStroke={isStroke} smallIcon={smallIcon}>
            {!!icon ? isValidElement(icon) ? icon : <LazyIcon /> : null}
        </CustomIcon>
    );
};

const CustomIcon = styled.div`
    svg {
        ${(props) =>
            props.smallIcon
                ? {
                      width: '24px',
                      height: '24px',
                  }
                : {
                      height: '32px',
                      width: '32px',
                  }}

        flex-shrink: 0;

        path {
            fill: ${(props) =>
                props.isMaster ? 'var(--chakra-colors-green-500)' : 'var(--chakra-colors-item-icon-fill)'};
            stroke: ${(props) =>
                props.isStroke
                    ? props.isMaster
                        ? 'var(--chakra-colors-green-500)'
                        : 'var(--chakra-colors-item-icon-fill)'
                    : null};
        }
    }
`;

export default CustomItemIcon;
