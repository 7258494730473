import { Flex, Text, useColorMode } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import CustomCheckbox from '../../forms/CustomCheckbox';

const NavigationDarkModeCheckbox = ({ config }) => {
    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <Wrapper onClick={toggleColorMode}>
            <Text position="absolute" left="0" right="0" zIndex="1">
                {config.label}
            </Text>

            <CustomCheckbox isChecked={colorMode === 'dark'} onChange={toggleColorMode} tabIndex={-1} />
        </Wrapper>
    );
};

const Wrapper = styled(Flex)`
    justify-content: flex-end;
    position: relative;
    border-radius: var(--chakra-radii-md);

    > p {
        color: var(--chakra-colors-menu-item-text) !important;
    }
`;

export default NavigationDarkModeCheckbox;
