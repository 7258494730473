import { RadioGroup } from '@chakra-ui/react';

import FormField from './FormField';

// If you need to extend RadioGroup functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for RadioGroup should be excluded from ...props and manually added
const RadioGroupField = ({
    id,
    name,
    value,
    onChange,
    onBlur,
    label,
    children,
    isInvalid,
    isWarning,
    error,
    status,
    ...props
}) => {
    return (
        <FormField id={id} name={name} label={label} isInvalid={isInvalid} error={error} status={status} {...props}>
            <RadioGroup
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                borderColor={isWarning ? 'orange.300' : undefined}
                _hover={isWarning ? { borderColor: 'orange.300' } : undefined}
            >
                {children}
            </RadioGroup>
        </FormField>
    );
};

export default RadioGroupField;
