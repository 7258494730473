import { Field, FastField } from 'formik';
import _ from 'lodash';

import FormField from './FormField';
import MultiSelect from './MultiSelect';

// If you need to extend Combobox functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for Combobox should be excluded from ...props and manually added
const MultiSelectFieldComponent = ({
    value,
    onChange,
    onBlur,
    options,
    meta,
    field,
    label,
    form,
    valueKey,
    labelKey,
    getCustomLabel,
    isDisabled,
    isOptionDisabled,
    placeholderLabel,
    multipleSelectedLabel,
    showSelectedCount,
    showSelectAll,
    menuProps,
    ...props
}) => {
    const status = _.get(form.status, field.name);
    const isInvalid = meta.touched && (meta.error || status?.errors?.length > 0);
    const isWarning = meta.touched && status?.warnings?.length > 0;

    return (
        <FormField
            name={field.name}
            isInvalid={isInvalid}
            isDisabled={isDisabled}
            error={meta.error}
            status={status}
            label={label}
            {...props}
        >
            <MultiSelect
                value={field.value}
                options={options}
                onChange={(val) => {
                    form.setFieldValue(field.name, val);
                    form.setStatus(_.omit(form.status, field.name));

                    //   Additional onChange logic could be hooked
                    if (onChange) {
                        onChange(val);
                    }
                }}
                onBlur={() => {
                    form.setFieldTouched(field.name, true, false);

                    //   Additional onBlur logic could be hooked
                    if (onBlur) {
                        onBlur();
                    }
                }}
                valueKey={valueKey}
                labelKey={labelKey}
                getCustomLabel={getCustomLabel}
                isInvalid={isInvalid}
                isWarning={isWarning}
                isDisabled={isDisabled}
                isOptionDisabled={isOptionDisabled}
                placeholderLabel={placeholderLabel}
                multipleSelectedLabel={multipleSelectedLabel}
                showSelectedCount={showSelectedCount}
                showSelectAll={showSelectAll}
                menuProps={menuProps}
            />
        </FormField>
    );
};

const MultiSelectFormikField = ({ name, validate, isFastField = false, ...props }) => (
    <>
        {isFastField ? (
            <FastField name={name} validate={validate}>
                {({ field, meta, form }) => (
                    <MultiSelectFieldComponent field={field} meta={meta} form={form} {...props} />
                )}
            </FastField>
        ) : (
            <Field name={name} validate={validate}>
                {({ field, meta, form }) => (
                    <MultiSelectFieldComponent field={field} meta={meta} form={form} {...props} />
                )}
            </Field>
        )}
    </>
);

export default MultiSelectFormikField;
