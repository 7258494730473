import {
    Heading,
    Flex,
    Box,
    SimpleGrid,
    Divider,
    AccordionItem,
    AccordionPanel,
    Text,
    useMediaQuery,
    MenuItem,
    Accordion,
    IconButton,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { checkItemInputEvent } from '../../services/items';
import Tooltip from '../utils/Tooltip';
import SubItemActionsMenu from '../../components/itemData/SubItemActionsMenu';
import AccordionButton from '../utils/AccordionButton';
import SortOrderProvider from '../grid/utils/SortOrderContext';
import InputFormikField from '../forms/InputFormikField';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete-circled.svg';
import { ReactComponent as AddItemIcon } from '../../icons/item-icons/add-item.svg';
import SubBatteryData from './SubBatteryData';
import AddSubItem from '../utils/AddSubItem';

import { helpAnchors } from 'constants/help';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';

import SubBatteryDefinitions from './SubBatteryDefinitions';

const SubBatteriesSection = ({
    itemTypeId,
    definitions,
    batteryDefinitions,
    editDescriptionsConfig,
    templatesConfig,
    formValues,
    validateDescription,
    helpPageUrlItemTypeId = null,
    onEditDescription,
    onDelete,
    onAdd,
    onTemplatesConfigChange,
    onResourceVariablesDataChange,
    onAutoformTableChange,
}) => {
    const intl = useIntl();
    const [isDesktop] = useMediaQuery('(min-width: 769px)');

    return (
        <Box>
            {definitions.length === 0 ? (
                <AddSubItem onOpen={onAdd} />
            ) : (
                <Accordion allowToggle defaultIndex={[0]}>
                    {definitions?.map((def, idx) => {
                        const foundEditDescriptionsConfig = editDescriptionsConfig[`${def.batteryId}`];
                        const isEditing = foundEditDescriptionsConfig ? foundEditDescriptionsConfig.isActive : false;

                        const templateConfig = templatesConfig[def.batteryId];
                        const templateId = templateConfig && templateConfig.templateId;

                        return (
                            <AccordionItem key={def.batteryId}>
                                {({ isExpanded }) => (
                                    <>
                                        <SimpleGrid
                                            mt="-1px"
                                            columns={2}
                                            bg="background-octenary"
                                            borderTop="1px solid"
                                            borderBottom="1px solid"
                                            borderColor="border-secondary"
                                        >
                                            <Flex align="center" ml={4} my={3}>
                                                <AccordionButton />

                                                <Box>
                                                    <Text size="xs" mb="2px">
                                                        <FormattedMessage id="batteries_sub_battery_heading" />
                                                    </Text>

                                                    {!isEditing ? (
                                                        <Heading as="h4" variant="h4">
                                                            {formValues.subBatteries[idx]?.description}
                                                        </Heading>
                                                    ) : (
                                                        <InputFormikField
                                                            id={'description' + idx}
                                                            name={`subBatteries[${idx}].description`}
                                                            type="text"
                                                            validate={(value) =>
                                                                validateDescription(value, 'sub-battery', def.batteryId)
                                                            }
                                                            onKeyDown={checkItemInputEvent}
                                                            isFastField
                                                        />
                                                    )}
                                                </Box>

                                                <Flex align="center" ml={3}>
                                                    <Tooltip
                                                        label={intl.formatMessage({
                                                            id: 'common_edit_btn_tooltip_and_label',
                                                        })}
                                                    >
                                                        <IconButton
                                                            minH="40px"
                                                            aria-label={intl.formatMessage({
                                                                id: 'common_edit_btn_tooltip_and_label',
                                                            })}
                                                            variant="circular-icon"
                                                            leftIcon={<EditIcon />}
                                                            onClick={() => onEditDescription(def.batteryId)}
                                                        />
                                                    </Tooltip>
                                                </Flex>
                                            </Flex>

                                            <Flex direction="row" alignItems="center" justify="flex-end" pr={6}>
                                                {!isDesktop ? (
                                                    <SubItemActionsMenu
                                                        menuListItems={
                                                            <>
                                                                <MenuItem
                                                                    key="delete-btn"
                                                                    textTransform="capitalize"
                                                                    onClick={() => {
                                                                        onDelete(def.batteryId, def.description);
                                                                    }}
                                                                >
                                                                    <FormattedMessage id="common_delete" />
                                                                </MenuItem>

                                                                <MenuItem
                                                                    key="create-btn"
                                                                    textTransform="capitalize"
                                                                    onClick={onAdd}
                                                                >
                                                                    <FormattedMessage id="common_add" />
                                                                </MenuItem>
                                                            </>
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        <Tooltip
                                                            label={intl.formatMessage({
                                                                id: 'common_delete_btn_tooltip_and_label',
                                                            })}
                                                        >
                                                            <IconButton
                                                                aria-label={intl.formatMessage({
                                                                    id: 'common_delete_btn_tooltip_and_label',
                                                                })}
                                                                variant="circular-icon"
                                                                icon={<DeleteIcon />}
                                                                onClick={() => {
                                                                    onDelete(def.batteryId, def.description);
                                                                }}
                                                            />
                                                        </Tooltip>

                                                        <Divider orientation="vertical" mx={6} />

                                                        <Tooltip
                                                            label={intl.formatMessage({
                                                                id: 'common_add_new',
                                                            })}
                                                        >
                                                            <IconButton
                                                                aria-label={intl.formatMessage({
                                                                    id: 'common_add_new',
                                                                })}
                                                                variant="circular-icon"
                                                                icon={<AddItemIcon />}
                                                                onClick={onAdd}
                                                            />
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </Flex>
                                        </SimpleGrid>

                                        {isExpanded && (
                                            <AccordionPanel px={6} pt={6} pb={4}>
                                                <Heading as="h4" variant="h4">
                                                    <FormattedMessage id="batteries_definitions_heading" />
                                                    <HelpButtonComponent
                                                        helpAnchor={helpAnchors.BATTERY_DEFINITIONS}
                                                        itemTypeId={helpPageUrlItemTypeId}
                                                    />
                                                </Heading>
                                                <SubBatteryDefinitions
                                                    batteryDefinitions={batteryDefinitions}
                                                    formValues={formValues}
                                                    idx={idx}
                                                />
                                                <SortOrderProvider>
                                                    <SubBatteryData
                                                        itemId={formValues.battery.itemId}
                                                        itemTypeId={itemTypeId}
                                                        batteryName={formValues.battery.description?.trim()}
                                                        subBatteryName={def?.description?.trim()}
                                                        subBatteryId={def.batteryId}
                                                        expanded={isExpanded}
                                                        ancillaries={batteryDefinitions.definitions.ancillaries.sort(
                                                            (a, b) => a.description.localeCompare(b.description)
                                                        )}
                                                        peakPeriods={batteryDefinitions.definitions.peakPeriods}
                                                        quantityTypes={batteryDefinitions.definitions.quantityTypes}
                                                        templates={batteryDefinitions.definitions.templates}
                                                        templateId={templateId}
                                                        templateConfig={
                                                            templateConfig || {
                                                                templateId: null,
                                                                hasUpload: false,
                                                            }
                                                        }
                                                        helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                                                        onTemplatesConfigChange={onTemplatesConfigChange}
                                                        onResourceVariablesDataChange={onResourceVariablesDataChange}
                                                        onAutoformTableChange={onAutoformTableChange}
                                                    />
                                                </SortOrderProvider>
                                            </AccordionPanel>
                                        )}
                                    </>
                                )}
                            </AccordionItem>
                        );
                    })}
                </Accordion>
            )}
        </Box>
    );
};

export default SubBatteriesSection;
