import React, { useMemo } from 'react';

import { getNodeIcon } from '../../services/items';
import { ReactComponent as ImagePlaceholder } from '../../icons/image-placeholder.svg';

/**
 * Appropriate icon for each autoform
 *
 * @param {number} itemTypeId - Item type id of the autoform. When not available,
 * a default icon is shown.
 */
const AutoformIconNode = ({ itemTypeId }) => {
    const Icon = useMemo(() => getNodeIcon(itemTypeId, ImagePlaceholder), [itemTypeId]);

    return <Icon />;
};

export default React.memo(AutoformIconNode);
