import React from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Flex,
    List,
    Text,
} from '@chakra-ui/react';
import useNavigation from '../../../hooks/useNavigation';
import styled from '@emotion/styled/macro';
import StyledStandaloneIconWrapper from '../StyledStandaloneIconWrapper';

const NavigationAccordion = ({ config, renderItem }) => {
    const { assetsMap } = useNavigation();

    if (config?.mobileConfig?.transform === 'Link' && config?.items[0].type === 'MenuItem') {
        return (
            <Flex flexDirection={'column'}>
                {config.icon}

                <StyledGroupNameText>{config.label}</StyledGroupNameText>

                {config.items.map((menuItem, menuItemIndex) => {
                    return (
                        <Box key={menuItemIndex} mt={'30px'}>
                            {renderItem({
                                ...menuItem,
                                type: menuItem.mobileConfig?.transform || config.mobileConfig.transform,
                            })}
                        </Box>
                    );
                })}
            </Flex>
        );
    } else {
        return (
            <Accordion variant="mobile-navigation" allowMultiple>
                <AccordionItem>
                    <StyledAccordionButton as={Button} variant="ghost">
                        {config.label ? (
                            <Flex alignItems={'center'} borderRadius={6}>
                                {config.icon}

                                <StyledText withGap={!config.icon}>{config.label}</StyledText>

                                {assetsMap?.dropdownArrow}
                            </Flex>
                        ) : (
                            <StyledStandaloneIconWrapper data-comp="standalone-icon-wrapper">
                                {config.icon}
                            </StyledStandaloneIconWrapper>
                        )}
                    </StyledAccordionButton>

                    <AccordionPanel>
                        <StyledList bg="background-senary">
                            {config.items.map((menuItem, menuItemIndex) => (
                                <React.Fragment key={menuItemIndex}>{renderItem(menuItem)}</React.Fragment>
                            ))}
                        </StyledList>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        );
    }
};

const StyledAccordionButton = styled(AccordionButton)`
    &[aria-expanded='true'] {
        [data-comp='dropdown-asset'] {
            transform: rotatex(180deg);
        }

        [data-comp='navigation-asset'] {
            color: var(--chakra-colors-blue-400);
        }

        [data-comp='standalone-icon-wrapper'] {
            background-color: var(--chakra-colors-blue-100);
        }
    }
`;

const StyledGroupNameText = styled(Text)`
    color: var(--chakra-colors-gray-600);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`;

const StyledText = styled(Text, { shouldForwardProp: (prop) => prop !== 'withGap' })`
    color: var(--chakra-colors-gray-800);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-left: ${(props) => (props.withGap ? 'var(--chakra-space-2)' : 0)};
`;

const StyledList = styled(List)`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    padding: var(--chakra-space-3) var(--chakra-space-6);
    margin-top: var(--chakra-space-3);
`;

export default NavigationAccordion;
