import styled from '@emotion/styled/macro';
import { Box, SimpleGrid, Tab, TabList, Tabs, TabPanel, TabPanels, Text } from '@chakra-ui/react';

import DynamicFormProperty from './DynamicFormProperty';
import TabListWrapper from './TabListWrapper';

const DynamicPropertyTabs = ({ groups, ...rest }) => {
    return (
        <Tabs {...rest}>
            <CustomTabList>
                <TabListWrapper>
                    {groups.map(({ groupId, properties, groupName }) => (
                        <Tab key={groupId}>
                            <TabTitle variant="medium" size="md">
                                {groupName}
                            </TabTitle>
                        </Tab>
                    ))}
                </TabListWrapper>
            </CustomTabList>

            <TabPanels>
                {groups.map(({ groupId, properties }) => (
                    <TabPanel key={groupId} mt={4}>
                        <SimpleGrid mb={4} columns={{ xl: 2 }} spacing={6}>
                            {properties.map((property) => (
                                <Box
                                    key={property.id}
                                    display="flex"
                                    alignItems="center"
                                    gridColumn={property.code === 'OTHERPARAMS' && '1 / -1'}
                                >
                                    <DynamicFormProperty groupId={groupId} property={property} />
                                </Box>
                            ))}
                        </SimpleGrid>
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    );
};

const CustomTabList = styled(TabList)`
    overflow-x: auto;
    padding: 5px;
    border-bottom: 1px solid var(--chakra-colors-gray-200);
`;

const TabTitle = styled(Text)`
    width: max-content;
`;

export default DynamicPropertyTabs;
