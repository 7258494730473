import { useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import useCommonToast from '../../hooks/useCommonToast';

import { addBookmark, deleteMapElement } from '../../services/maps';

import { Spacer, useDisclosure, Heading, Box, Button, Flex, Link, Text, IconButton } from '@chakra-ui/react';

import AddItemButton from '../utils/AddItemButton';
import Popover from '../utils/Popover';
import CustomIconButton from '../utils/CustomIconButton';

import LoadingModal from '../../components/modal/LoadingModal';

import InputField from '../forms/InputField';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';

const Bookmarks = ({ view, bookmarks, onBookmarkDeleteSuccess, onBookmarkCreateSuccess }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();

    const [newBookmarkLabel, setNewBookmarkLabel] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const bookmarkCreation = useDisclosure();

    const deleteBookmark = async (id) => {
        setIsLoading(true);
        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'bookmark' });
        try {
            await deleteMapElement(id);
            onBookmarkDeleteSuccess(id);
            toast(successMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const createBookmark = useCallback(
        async (extent) => {
            setIsLoading(true);
            const successMessage = intl.formatMessage({ id: 'maps_drawer_add_bookmark_success' });

            const payload = {
                description: newBookmarkLabel,
                data: JSON.stringify({
                    extent: extent,
                }),
            };

            try {
                const newExtent = await addBookmark(payload);
                onBookmarkCreateSuccess(newExtent);

                toast(successMessage);
            } finally {
                setIsLoading(false);
                bookmarkCreation.onClose();
                setNewBookmarkLabel('');
            }
        },
        [bookmarkCreation, newBookmarkLabel, onBookmarkCreateSuccess, toast, intl]
    );

    const updateExtent = (extent) => {
        view.extent = extent;
    };

    return (
        <Box mt={6}>
            <Flex align="center" justify="space-between">
                <Flex align="center">
                    <Heading as="h4" variant="h4" mr={2}>
                        <FormattedMessage id="maps_drawer_bookmarks" />
                    </Heading>
                    <Popover
                        placement="top"
                        trigger={<CustomIconButton mt="0px !important" variant="circular-icon" icon={<InfoIcon />} />}
                    >
                        <FormattedMessage id="maps_drawer_bookmarks_info" />
                    </Popover>
                </Flex>
                <Spacer />
                <Box>
                    <AddItemButton width="fit-content" leftIcon={<AddIcon />} onClick={bookmarkCreation.onToggle}>
                        <Text size="sm" color="gray.900" pl={1} pt={1}>
                            <FormattedMessage id="maps_drawer_add_bookmark" />
                        </Text>
                    </AddItemButton>
                </Box>
            </Flex>
            {bookmarkCreation.isOpen && (
                <Flex align="flex-end" gap={3}>
                    <InputField
                        id="bookmarkName"
                        name="bookmarkName"
                        label={intl.formatMessage({ id: 'maps_drawer_bookmark_label' })}
                        value={newBookmarkLabel}
                        type="text"
                        onChange={(e) => setNewBookmarkLabel(e.target.value)}
                        maxW="600px"
                    />
                    <Button
                        isLoading={isLoading}
                        size="sm"
                        variant="special-green"
                        textTransform="capitalize"
                        onClick={() => createBookmark(view.extent)}
                    >
                        <FormattedMessage id="maps_drawer_bookmarks_button" />
                    </Button>
                </Flex>
            )}
            {bookmarks.data.length === 0 ? (
                <FormattedMessage id="maps_drawer_no_bookmarks" />
            ) : (
                <>
                    {bookmarks.data.map((bookmark) => (
                        <Flex justify="space-between" align="center" key={bookmark.id}>
                            <BookmarkName>{bookmark.description}</BookmarkName>
                            <CustomLink color="teal.500" onClick={() => updateExtent(bookmark.data.extent)}>
                                <FormattedMessage id="maps_drawer_bookmarks_zoom" />
                            </CustomLink>
                            <IconButton
                                leftIcon={<DeleteIcon />}
                                onClick={() => deleteBookmark(bookmark.id)}
                                variant="circular-icon"
                            />
                        </Flex>
                    ))}
                </>
            )}
            {isLoading && <LoadingModal header={<FormattedMessage id="common_loading" />} isOpen />}
        </Box>
    );
};

const BookmarkName = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--chakra-colors-gray-900);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 100px;
`;

const CustomLink = styled(Link)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;
export default Bookmarks;
