import { useEffect, useState, createContext } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDisclosure } from '@chakra-ui/react';
import { Box, Button, Heading, Flex, IconButton } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as EyeIcon } from 'icons/eye.svg';
import { ReactComponent as EyeIconHidden } from 'icons/eye_hidden.svg';
import { ReactComponent as RefreshIcon } from 'icons/refresh.svg';
import MainWrapper from 'components/utils/MainWrapper';
import InputField from 'components/forms/InputField';

import { getAllChartSpecs, removeChart, getNewChart } from 'services/chartViewer';
import ChartItem from 'components/chartViewer/ChartItem';
import ChartList from 'components/chartViewer/ChartList';

export const ChartListContext = createContext({ allChartSpecs: [], handleChartRemove: () => {} });

const ChartArea = styled.div`
    height: 78vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
    justify-content: space-around;
    background-color: lightgray;
    border: 1px solid black;
    overflow: auto;
`;

const ChartViewerSpecs = ({ onRefresh, specs }) => {
    const { isOpen, onToggle } = useDisclosure();
    const [chartSpecs, setChartSpecs] = useState(specs);
    const [newChartTitle, setNewChartTitle] = useState();

    useEffect(() => {
        setChartSpecs(specs);
    }, [specs]);

    function handleRefresh() {
        setChartSpecs([]);
        onRefresh();
    }

    function handleChartAdd(chartName, e) {
        if (!chartName || !chartName.length > 3) return;
        if (!isOpen) onToggle();

        getNewChart(chartName)
            .then((newSpec) => setChartSpecs([...chartSpecs, newSpec]))
            .catch((err) => console.error(`Add new chart error: ${err}`));

        setNewChartTitle('');
    }

    function handleChartRemove(chartSpec) {
        if (window.confirm(`Are you sure you want to delete chart: ${chartSpec.title}`)) {
            removeChart(chartSpec.chartId)
                .then(() => {
                    const newSpecs = chartSpecs.filter((spec) => spec.chartId !== chartSpec.chartId);
                    setChartSpecs(newSpecs);
                })
                .catch((err) => console.error(`Chart delete error: ${err}`));
        }
    }

    return (
        <ChartListContext.Provider value={{ allChartSpecs: chartSpecs, handleChartRemove: handleChartRemove }}>
            <Flex m={4}>
                <Heading as="h2" variant="h2">
                    <FormattedMessage id="charts_title" />
                </Heading>

                <InputField
                    ml="2em"
                    w="30em"
                    placeholder={'Enter chart title'}
                    value={newChartTitle}
                    onChange={(e) => setNewChartTitle(e.target.value)}
                ></InputField>
                <Button ml="2em" size="sm" onClick={(e) => handleChartAdd(newChartTitle, e)}>
                    Add Chart
                </Button>
                <Box ml="auto">
                    <IconButton
                        aria-label={isOpen ? 'Hide chart list' : 'View chart list'}
                        variant="outline"
                        mr="1"
                        icon={isOpen ? <EyeIconHidden /> : <EyeIcon />}
                        onClick={onToggle}
                    />
                    <IconButton
                        aria-label="Refresh all charts"
                        variant="outline"
                        mr="1"
                        icon={<RefreshIcon />}
                        onClick={handleRefresh}
                    />
                </Box>
            </Flex>
            <ChartList isOpen={isOpen} />
        </ChartListContext.Provider>
    );
};

const GetCharts = ({ specs }) => {
    return Array.isArray(specs) ? (
        <>
            {specs
                .filter((spec) => spec.active)
                .map((spec) => (
                    <ChartItem key={spec.chartId} spec={spec}></ChartItem>
                ))}
        </>
    ) : null;
};

const ChartViewer = () => {
    const [chartSpecs, setChartSpecs] = useState([]);

    const fetchAllChartSpecs = async () => {
        return await getAllChartSpecs();
    };

    useEffect(() => {
        fetchAllChartSpecs()
            .then((specs) => setChartSpecs(specs))
            .catch((err) => console.error(`fetchAllChartSpecs error: ${err}`));
    }, []);

    function handleRefresh() {
        fetchAllChartSpecs()
            .then((specs) => {
                setChartSpecs(specs);
            })
            .catch((err) => console.error(`fetchAllChartSpecs refresh error: ${err}`));
    }

    return (
        <MainWrapper>
            <ChartViewerSpecs onRefresh={handleRefresh} specs={chartSpecs} />
            <ChartArea>
                <GetCharts specs={chartSpecs} />
            </ChartArea>
        </MainWrapper>
    );
};

export default ChartViewer;
