import React from 'react';
import { Flex } from '@chakra-ui/react';
import DesktopNavigationLink from '../desktopNavigation/NavigationLink';

const NavigationLink = ({ config, onClick }) => {
    return (
        <Flex>
            <DesktopNavigationLink config={config} onClick={onClick} />
        </Flex>
    );
};

export default NavigationLink;
