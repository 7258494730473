import { useMemo } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Autoform from './Autoform';
import TabularPage from '../components/tabs/TabularPage';

const ResourcePlanningPage = () => {
    const routes = useMemo(
        () => [
            {
                name: <FormattedMessage id="resource_planning_econ_assumption" />,
                relativePath: 'PSPLANMACROECON',
            },
            {
                name: <FormattedMessage id="resource_planning_objective_function" />,
                relativePath: 'PSPLANOBJFUNC',
            },
            {
                name: <FormattedMessage id="resource_planning_planning_constraints" />,
                relativePath: 'PSPLANCONSTSET',
            },
            {
                name: <FormattedMessage id="resource_planning_project_details" />,
                relativePath: 'PSPLANNEWPROJECT',
            },
        ],
        []
    );

    return (
        <TabularPage
            isAutoForm
            routes={routes}
            renderRoutes={({ path }) => (
                <Route path={`${path}/:autoformId`}>
                    <Autoform hasSecondaryMenu={true} />
                </Route>
            )}
        />
    );
};

export default ResourcePlanningPage;
