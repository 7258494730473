import axios from 'axios';
import { isNull } from 'lodash';
import { msalInstance } from '../auth';
import { getItem, setItem, removeItem } from '../storage';
import { subDays, format, parse, parseISO, isMatch } from 'date-fns';
import {
    convertRelativeToUTCDateMidnight,
    convertRelativeToUTCDateMidnightExtraDayMinusOneHour,
} from '../../services/utils';

import { ReactComponent as DocumentIcon } from '../../icons/item-icons/document.svg';
import mappedItemTypes from './mappedItemTypes';

export const getNodeIcon = (typeId, defaultIcon = null) => {
    for (const type in mappedItemTypes) {
        if (mappedItemTypes[type].id === typeId) {
            return mappedItemTypes[type].icon;
        }
    }

    return defaultIcon ? defaultIcon : DocumentIcon;
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

// function that prohibits the enter key down on item page inputs
export const checkItemInputEvent = (e) => {
    e.stopPropagation();

    if (e.type === 'keydown' && e.keyCode === 13) {
        e.preventDefault();
    }
};

// function that flats item name and given sub-items' descriptions into a single object to help control the edit view
export const flatItemDescriptions = (definitions, itemId) => {
    const descriptionsConfig = {};

    definitions.forEach((def) => {
        descriptionsConfig[`${def.id}`] = { isActive: false, penDisabled: false };
    });

    if (itemId) {
        descriptionsConfig[`${parseInt(itemId)}`] = {
            isActive: false,
            penDisabled: false,
        };
    }

    return descriptionsConfig;
};

export const getItemType = (itemTypes, description) => {
    const symbol = '_';
    const itemDescription = description.includes(symbol) ? description.replaceAll(symbol, ' ') : description;

    if (itemTypes.length > 0) {
        return itemTypes.find((item) => item.description.toLowerCase() === itemDescription.toLowerCase());
    }
};

export const getItems = async (itemTypeId) => {
    const response = await axios.get(`/items/${itemTypeId}/items`);
    return response.data;
};

export const getItemsByMultipleTypes = async (itemIds) => {
    const params = new URLSearchParams();

    itemIds.forEach((itemId) => {
        params.append('itemTypeId', itemId);
    });

    const response = await axios.get(`/items/by-types`, { params });
    return response.data;
};

export const getItemProperties = async (itemId) => {
    const response = await axios.get(`/items/${itemId}/properties`);
    return response.data;
};

export const createItem = async (payload) => {
    const response = await axios.post('/items/create', payload);

    if (response?.status === axios.HttpStatusCode.MultiStatus) {
        // Status 207 MultiStatus
        const multiStatus = response?.data?.multiStatus;
        if (Array.isArray(multiStatus)) {
            // Status 201 Created
            const data = multiStatus.find((s) => s.statusCode === axios.HttpStatusCode.Created)?.value;
            // Status 424 FailedDependency
            const arcGisIssue = multiStatus.find(
                (s) => s.statusCode === axios.HttpStatusCode.FailedDependency && s?.value?.isArcGisError
            )?.value?.error;
            return { data, arcGisIssue };
        }
    }

    return response;
};

export const sortItemsByDescription = (items) => {
    return items.slice().sort((a, b) => a.description.localeCompare(b.description));
};

export const findItem = (items, itemId) => {
    return items.find((item) => item.id === Number(itemId));
};

export const copyItem = async (itemId) => {
    const response = await axios.post(`/items/${itemId}/copy`);
    return response.data;
};

export const deleteItem = (itemId) => axios.delete(`/items/${itemId}`, { suppressErrorMessageHandler: true });

export const updateItem = (id, payload) => axios.put(`/items/${id}/properties`, payload);

// Used in Hedge and Main Market items
// Hedge uses Lat and Long, Main Market does not
// If update has multiStatus 207 lets check if we have ArcGIS error
export const editItem = async (id, payload) => {
    const response = await axios.put(`/items/${id}/modify`, payload);

    if (response?.status === axios.HttpStatusCode.MultiStatus) {
        // Status 207 MultiStatus
        const multiStatus = response?.data?.multiStatus;
        if (Array.isArray(multiStatus)) {
            // Status 200 OK
            const data = multiStatus.find((s) => s.statusCode === axios.HttpStatusCode.Ok)?.value;
            // Status 424 FailedDependency
            const arcGisIssue = multiStatus.find(
                (s) => s.statusCode === axios.HttpStatusCode.FailedDependency && s?.value?.isArcGisError
            )?.value?.error;
            return { data, arcGisIssue };
        }
    }

    return response;
};

export const addItemToPortfolios = (itemId, payload) =>
    axios.post(`/items/${itemId}/portfolios`, payload, { suppressErrorMessageHandler: true });

export const addItemNextToParent = (stack, parentId, temporaryNode) => {
    const newStack = [...stack];
    const parentNodeIndex = newStack.findIndex((node) => node.id === parentId);
    newStack.splice(parentNodeIndex + 1, 0, temporaryNode);

    return newStack;
};

export const createDateAsUTC = (date) => {
    return new Date(
        Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        )
    );
};

export const stripTimezoneIndicator = (date) => {
    const tempISOString = typeof date === 'string' ? date : date.toISOString();
    // "Z" in ISO format tells parseISO that we have UTC time, without Z it is treated as local time without conversion
    return tempISOString.slice(0, -1);
};

export const setFiltersConfig = (config, item) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length) {
        const itemFilters = `${item}Filters`;
        const filters = getItem(itemFilters) || {};

        setItem(itemFilters, {
            ...filters,
            [accounts[0].localAccountId]: config,
        });
    }
};

export const removeFilters = (item) => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length) {
        removeItem(`${item}Filters`);
    }
};

export const getFiltersConfig = (item) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts?.length) {
        const accountId = accounts[0].localAccountId;
        const filters = getItem(`${item}Filters`);
        if (filters && filters[accountId]) {
            let { startDateFrom, startDateTo, updateDateFrom, updateDateTo, ...rest } = filters[accountId];

            startDateFrom = startDateFrom && parseISO(startDateFrom);
            startDateTo = startDateTo && parseISO(startDateTo);
            updateDateFrom = updateDateFrom && parseISO(updateDateFrom);
            updateDateTo = updateDateTo && parseISO(updateDateTo);

            return {
                startDateFrom,
                startDateTo,
                updateDateFrom,
                updateDateTo,
                ...rest,
            };
        }
    }

    return null;
};

export const systemDefaultFilter = {
    startDateFrom: createDateAsUTC(new Date(2015, 0, 1)),
    startDateTo: createDateAsUTC(new Date(2050, 11, 31)),
    daysFrom: -21,
    daysTo: 0,
    toggleDaysFrom: false,
    toggleDaysTo: false,
    filterType: 1, // latest (1) | latest as of (2) | range (3)
    updateDateFrom: createDateAsUTC(subDays(new Date(), 20)),
    updateDateTo: createDateAsUTC(new Date()),
};

export const getDataFilters = (namespacePrefix) => {
    const userPreferences = getFiltersConfig(namespacePrefix);

    if (userPreferences) {
        const filters = getFiltersConfig(namespacePrefix);

        const parsedFilters = parseDataFilters(filters);
        return parsedFilters;
    } else {
        return {
            startDateFrom: systemDefaultFilter.startDateFrom,
            startDateTo: systemDefaultFilter.startDateTo,
            filterType: systemDefaultFilter.filterType,
            updateDateFrom: systemDefaultFilter.updateDateFrom,
            updateDateTo: systemDefaultFilter.updateDateTo,
        };
    }
};

export const parseDataFilters = (filters) => {
    const relativeStartDateFrom = convertRelativeToUTCDateMidnight(filters.daysFrom);
    // Per business request include extra day
    const relativeStartDateTo = convertRelativeToUTCDateMidnightExtraDayMinusOneHour(filters.daysTo);
    const filterType = parseInt(filters.filterType, 10);
    const setUpdateFrom = filterType === 2 || filterType === 3;
    const setUpdateTo = filterType === 3;

    return {
        // @todo add guards against partially set filters like this { toggleDaysFrom: true, daysFrom: undefined }
        startDateFrom: filters.toggleDaysFrom ? relativeStartDateFrom : filters.startDateFrom,
        startDateTo: filters.toggleDaysTo ? relativeStartDateTo : filters.startDateTo,
        filterType: filterType,
        updateDateFrom: setUpdateFrom ? filters.updateDateFrom || new Date() : null,
        updateDateTo: setUpdateTo ? filters.updateDateTo || new Date() : null,
    };
};

export const isFilterApplied = (namespacePrefix) => !isNull(getFiltersConfig(namespacePrefix));

export const hasAppliedCustomFilters = (filters) => {
    return (
        filters.startDateFrom.getTime() !== systemDefaultFilter.startDateFrom.getTime() ||
        filters.startDateTo.getTime() !== systemDefaultFilter.startDateTo.getTime() ||
        filters.filterType !== systemDefaultFilter.filterType ||
        filters.updateDateFrom.getTime() !== systemDefaultFilter.updateDateFrom.getTime() ||
        filters.updateDateTo.getTime() !== systemDefaultFilter.updateDateTo.getTime()
    );
};

export const formatChartData = (data) => {
    return data.map((el) => [new Date(el.dateTimeAxis).getTime(), el.avgLoad]);
};

export const uploadFile = async (itemType, itemId, file, cfg, sseClientId, treatWarningsAsErrors) => {
    const config = {
        ...cfg,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        suppressErrorMessageHandler: true,
    };
    const formData = new FormData();
    formData.append('file', file);

    if (sseClientId) {
        formData.append('clientId', sseClientId);
    }
    if (treatWarningsAsErrors) {
        formData.append('treatWarningsAsErrors', treatWarningsAsErrors);
    }

    let response;

    if (itemType === 'scaler') {
        response = await axios.post(`/basis/${itemId}/import-scaler`, formData, config);
    } else if (itemType === 'dart-spread') {
        response = await axios.post(`/basis/${itemId}/import-dart-spreads`, formData, config);
    } else if (itemType === 'price-formula') {
        response = await axios.post(`/price-formula/${itemId}/import`, formData, config);
    } else if (itemType === 'variables') {
        response = await axios.post(`/variables/import`, formData, config);
    } else if (itemType === 'thermal-matrix-periods') {
        response = await axios.post(`/thermal/${itemId}/matrix-periods-import`, formData, config);
    } else if (itemType === 'thermal-matrix-transitions') {
        response = await axios.post(`/thermal/${itemId}/matrix-transitions-import`, formData, config);
    } else if (itemType === 'thermals') {
        response = await axios.post(`/thermal/${itemId}/scheduled-and-historical-outputs-import`, formData, config);
    } else if (itemType === 'thermal-resource-variables-periods') {
        response = await axios.post(`/thermal/${itemId}/periods-import`, formData, config);
    } else if (itemType === 'thermal-resource-variables-fuels') {
        response = await axios.post(`/thermal/${itemId}/fuels-import`, formData, config);
    } else if (itemType === 'thermal-resource-variables-generation-capacity') {
        response = await axios.post(`/thermal/${itemId}/generation-capacity-import`, formData, config);
    } else if (itemType === 'thermal-resource-variables-forced-outages') {
        response = await axios.post(`/thermal/${itemId}/forced-outages-import`, formData, config);
    } else if (itemType === 'thermal-resource-variables-ancillary-contributions') {
        response = await axios.post(`/thermal/${itemId}/ancillary-contributions-import`, formData, config);
    } else if (itemType === 'thermal-resource-variables-link-resources') {
        response = await axios.post(`/thermal/${itemId}/link-resources-import`, formData, config);
    } else if (itemType === 'thermal-resource-variables-generation-derate') {
        response = await axios.post(`/thermal/${itemId}/generation-derate-import`, formData, config);
    } else if (itemType === 'thermal-resource-variables-forced-outage-temp') {
        response = await axios.post(`/thermal/${itemId}/forced-outage-temp-import`, formData, config);
    } else if (itemType === 'bidding-strategy') {
        response = await axios.post(`/bidding-strategies/${itemId}/import`, formData, config);
    } else {
        response = await axios.post(`/${itemType}/${itemId}/import`, formData, config);
    }

    return response.data;
};

export const buildAutoformUploadQueryParams = (itemId, subSchemaCode, parameters) => {
    const params = new URLSearchParams();
    if (itemId) {
        params.append('parentRecordId', itemId);
    }
    if (subSchemaCode) {
        params.append('schemaCode', subSchemaCode);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });

    return params;
};

export const fileUploadRequest = async (route, file, queryParams = {}, headers = {}, cfg = {}) => {
    headers['Content-Type'] = 'multipart/form-data';

    const config = {
        headers: headers,
        params: queryParams,
        suppressErrorMessageHandler: true,
        ...cfg,
    };

    const formData = new FormData();
    formData.append('file', file);

    const res = await axios.post(route, formData, config);

    return res.data;
};

export const uploadFileGeneric = async (
    file,
    subSchemaCode,
    itemId,
    parameters,
    isInsert,
    cfg,
    sseClientId,
    treatWarningsAsErrors
) => {
    const config = {
        ...cfg,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        suppressErrorMessageHandler: true,
    };
    const formData = new FormData();
    formData.append('file', file);

    const params = new URLSearchParams();
    if (itemId) {
        params.append('parentRecordId', itemId);
    }
    if (sseClientId) {
        params.append('sseClientId', sseClientId);
    }
    if (treatWarningsAsErrors) {
        params.append('treatWarningsAsErrors', treatWarningsAsErrors);
    }
    if (subSchemaCode) {
        params.append('schemaCode', subSchemaCode);
    }
    if (isInsert) {
        params.append('isInsert', isInsert);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });

    config.params = params;

    const response = await axios.post('/auto-forms/import-data', formData, config);

    return response?.data;
};

export const exportItemData = async (path, payload) => {
    const response = await axios.post(path, payload, { responseType: 'blob' });

    const filename = createExportFilename(response.headers);
    return { file: response.data, name: filename };
};

export const exportItemDataGeneric = async (schemaCode, itemId, daysBack = null, itemCompId = -1, level3Id = -1) => {
    const response = await axios.get(`/auto-forms/export/${schemaCode}/${itemId}/${itemCompId}/${level3Id}`, {
        params: {
            daysBack,
        },
        responseType: 'blob',
    });

    const filename = createExportFilename(response.headers);
    return { file: response.data, name: filename };
};

export const enUSFormat = 'MM/dd/yyyy hh:mm:ss a';
export const enUSFormatExcludingSeconds = 'MM/dd/yyyy h:mm aa';
export const enUSFormatExcludingTime = 'MM/dd/yyyy';

//Job log files have different content-disposition header pattern, hence different regex is needed
export const createExportFilename = (headers) => {
    let filename = 'export.xlsx';
    if (headers['content-disposition'] !== undefined) {
        filename = headers['content-disposition'].match('(?<=filename=)(.*?)(?=;)')[0];
        filename = filename.startsWith('"') && filename.endsWith('"') ? filename.slice(1, -1) : filename;
    }

    return filename;
};

export const lenientParse = (dateString, isUTC = true) => {
    if (!dateString || dateString === 'Invalid Date') {
        return new Date('Invalid Date');
    }

    const fromISOString = parseISO(isUTC ? stripTimezoneIndicator(dateString) : dateString);
    if (!Number.isNaN(fromISOString.getTime())) {
        return fromISOString;
    }

    // fallback to en-US format if parseISO fails
    const fromDateString = parse(isUTC ? stripTimezoneIndicator(dateString) : dateString, enUSFormat, new Date());
    if (!Number.isNaN(fromDateString.getTime())) {
        return fromDateString;
    }

    // fallback to new Date because parse fails at eg. (2015-01-13 0:00:00)
    return new Date(dateString);
};

export const formatDate = (date, dateFormat = enUSFormat) => {
    if (Number.isNaN(date.getTime())) {
        return 'Invalid date';
    }

    return format(date, dateFormat);
};

export const formatDateOnly = (date, dateFormat = enUSFormat, incomingDateFormat = 'yyyy-MM-dd') => {
    if (Number.isNaN(Date.parse(date))) {
        return '';
    }

    return format(parse(date, incomingDateFormat, new Date()), dateFormat);
};

export const dateValueFormatter = (params, format) => {
    const date = params.value ? lenientParse(params.value) : null;

    return date ? formatDate(date, format) : null;
};

export const checkValidityBeforeSave = (value) => {
    return (
        dateValueFormatter({
            value,
        }) !== 'Invalid date'
    );
};

export const removeTrailingNewLine = (data) => {
    return data.map((row) => {
        const pair = {};

        for (const key in row) {
            if (key === '__parsed_extra') continue;
            pair[key] = row[key].replace(/(?:\\[rn]|[\r\n]+)+/g, '');
        }

        return pair;
    });
};

export const isStringAValidDate = (value) => {
    // @todo do we even need this if here? the following 3 `isMatch` calls should suffice
    // to determine if the provided string value
    if (Number.isNaN(new Date(value).getTime())) {
        return false;
    }

    return (
        isMatch(value, enUSFormat) ||
        isMatch(value, enUSFormatExcludingSeconds) ||
        isMatch(value, enUSFormatExcludingTime)
    );
};

// @todo I think this function will incorrectly say that 0x8 is a valid number (it is actually, but not really...)
export const isStringAValidNumber = (value) => !isNaN(value);

export const isStringAValidBoolean = (value) => value === '1' || value === '0' || value === 'true' || value === 'false';

export const isStringAValidSelectOption = (value, options) => {
    const maybeId = Number(value);
    const maybeDescription = value;

    // try to find a matching select option that either matches the id property or the description
    const option = options.find(({ id, description }) => {
        return id === maybeId || description === maybeDescription;
    });

    return !!option;
};

export const isNumberCellEmpty = (value) => (value ? Number(value) : null);

export const downloadFile = (fileInfo) => {
    const url = window.URL.createObjectURL(new Blob([fileInfo.file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileInfo.name);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
};

export const validateItemOwnershipPercentage = (input) => {
    const isNumeric = (number) => {
        return !isNaN(parseFloat(number)) && isFinite(number);
    };

    return (
        input.includes('+') ||
        (input.includes('.') && input.split('.')[1] === '') ||
        !isNumeric(input) ||
        Number(input) < 0 ||
        Number(input) > 100
    );
};

export const formatMultisort = (sortedColumns, multisortPairs = {}, nullsLast = true) => {
    if (!sortedColumns.length) return [];
    return sortedColumns.map((c) => ({
        orderBy: multisortPairs[c.colId] ? multisortPairs[c.colId] : c.colId,
        orderByDescending: c.sort === 'desc',
        nullsLast,
    }));
};

export const getRouteTypeParamById = (id) => {
    for (const type in mappedItemTypes) {
        if (mappedItemTypes[type].id === id) {
            return mappedItemTypes[type].type;
        }
    }
};

export const formatDataGridDate = (params, dateFormat = enUSFormatExcludingSeconds) => {
    return params.value ? format(lenientParse(params.value, false), dateFormat) : '';
};

export const refreshMultisort = (keys) => keys.reduce((names, key) => ({ ...names, [key]: [] }), {});

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const getHumanFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
};

export const exportValidationResults = async (filename) => {
    const response = await axios.get(`/files/validation-result/${filename}`, { responseType: 'blob' });
    const formattedFilename = createExportFilename(response.headers);
    return { file: response.data, name: formattedFilename };
};

export const getItemTypeGenericAssetTemplates = (itemTypeId) => {
    const response = axios.get(`/generic-asset-library/itemTypes/${itemTypeId}/templates`);
    return response;
};

export const applyGenericAssetTemplateItemType = async (itemTypeId, parentRecordId, templateFileName) => {
    const response = await axios.post(`/generic-asset-library/itemTypes/${itemTypeId}`, {
        parentRecordId: parentRecordId,
        templateFileName: templateFileName,
    });
    return response;
};
