import React from 'react';
import CustomNavLink from '../../utils/CustomNavLink';
import styled from '@emotion/styled/macro';

const NavigationImageLink = ({ config }) => {
    return config.image ? (
        <StyledCustomNavLink exact={config.url === '/'} to={config.url}>
            {config.image}
        </StyledCustomNavLink>
    ) : null;
};

const StyledCustomNavLink = styled(CustomNavLink)`
    display: block;

    &:focus,
    &:focus-visible {
        box-shadow: var(--chakra-shadows-outline);
        border-radius: 4px;
        outline: 0;
    }
`;

export default NavigationImageLink;
