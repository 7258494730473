import { useState, useEffect, useCallback, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Spacer } from '@chakra-ui/react';
import Tree from '../react-tree/Tree';
import styled from '@emotion/styled/macro';

import {
    findNode,
    filterNodesByName,
    extractPortfoliosFromNodesExcludeSelected,
    getChildPortfolioNodeIds,
    removeNodeByParentId,
    replaceNodeProperties,
    movePortfolioToPortfolio,
    getNodeAncestors,
} from '../../services/portfolios';

import PortfolioTreeWrapper from './PortfolioTreeWrapper';
import PortfolioNodeIcon from './PortfolioNodeIcon';
import PortfolioArrowIcon from './PortfolioArrowIcon';
import MainModal from '../modal/MainModal';
import SearchBar from '../utils/SearchBar';

const MovePortfolioModal = ({ onClose, source, portfolios, onMoveSuccess, onMoveError }) => {
    const intl = useIntl();

    const [isLoading, setIsLoading] = useState(false);
    const [target, setTarget] = useState(null);
    const [nodes] = useState(() => {
        return extractPortfoliosFromNodesExcludeSelected(portfolios, source.id);
    });
    const [filteredNodes, setFilteredNodes] = useState(() => {
        return extractPortfoliosFromNodesExcludeSelected(portfolios, source.id);
    });
    const [expanded, setExpanded] = useState([]);
    const [search, setSearch] = useState('');

    const destinationContainsSourceName = useMemo(() => {
        if (target) {
            const targetNode = findNode(filteredNodes, target);
            if (Array.isArray(targetNode?.nodes)) {
                const targetNodes = targetNode.nodes.map((node) => node.properties.name);
                return targetNodes.includes(source.properties.name);
            }
        }
        return false;
    }, [filteredNodes, target, source.properties.name]);

    const destinationIsSourceParent = useMemo(() => {
        const ancestors = getNodeAncestors(portfolios, source);
        const isParentSelected = ancestors.length > 0 && ancestors[0] === target;

        return isParentSelected;
    }, [target, portfolios, source]);

    const destinationIsDescendant = useMemo(() => {
        if (target) {
            const targetNode = findNode(filteredNodes, target);
            if (targetNode && targetNode?.isFolder === true) {
                const ancestors = getNodeAncestors(portfolios, targetNode);
                const isParentSelected = ancestors?.length > 0 && ancestors?.find((x) => x === source.id);

                return isParentSelected;
            }
        }
        return false;
    }, [target, portfolios, source, filteredNodes]);

    useEffect(() => {
        const term = search.trimStart().toLowerCase();

        if (term && term.length >= 2) {
            const filteredNodes = filterNodesByName(portfolios, term);
            const portfolioOnlyNodes = extractPortfoliosFromNodesExcludeSelected(filteredNodes, -1);
            const ids = getChildPortfolioNodeIds(portfolioOnlyNodes);

            setFilteredNodes(portfolioOnlyNodes);
            setExpanded(ids);
        } else {
            setFilteredNodes(nodes);
            setExpanded(portfolios.map((node) => node.id));
        }
    }, [search]); // eslint-disable-line

    const movePortfolioToDestination = async () => {
        const targetNode = findNode(portfolios, target);
        let updatedNodes = [];

        // Remove transferred node from previous portfolio
        if (source.properties.parentId) {
            updatedNodes = removeNodeByParentId(portfolios, source);
        } else {
            updatedNodes = portfolios.filter((el) => el.id !== source.id);
        }

        // Update the id of the moved portfolio (check undoMovePortfolio if we start supporting move to root)
        const movedPortfolio = {
            ...source,
            properties: { ...source.properties, parentId: targetNode.properties.id },
        };

        // Add transferred node to selected portfolio
        const updatedNode = { ...targetNode, nodes: [...targetNode.nodes, movedPortfolio] };
        const replacedNodes = replaceNodeProperties(updatedNodes, targetNode.properties.id, updatedNode);

        try {
            setIsLoading(true);
            await movePortfolioToPortfolio(source.properties.id, targetNode.properties.id, {
                suppressErrorMessageHandler: true,
            });
            setIsLoading(false);
            // onMoveSuccess relies on movedPortfolio
            // which is the "selected" portfolio + the necessary updates like changed parentId
            onMoveSuccess(replacedNodes, movedPortfolio);
        } catch (error) {
            setIsLoading(false);
            onMoveError(error?.response?.data?.error);
        }
    };

    const onSelectChange = useCallback(({ id }) => {
        setTarget(id);
    }, []);

    const onExpandChange = useCallback(({ id }) => {
        setExpanded((prev) => (prev.includes(id) ? prev.filter((node) => node !== id) : prev.concat(id)));
    }, []);

    const renderLabel = useCallback(
        (node) => {
            return (
                <div className={`${node?.isExpandable ? '' : 'non-expandable '}portfolio-label-wrapper`}>
                    {node?.isExpandable && (
                        <span
                            onClick={() => {
                                setExpanded((prev) =>
                                    prev.includes(node.id) ? prev.filter((id) => id !== node.id) : prev.concat(node.id)
                                );
                            }}
                        >
                            <PortfolioArrowIcon isExpanded={node?.isExpanded} isMaster={node.properties.isMaster} />
                        </span>
                    )}

                    <div
                        onClick={(event) => {
                            if (event.shiftKey && node?.isExpandable) {
                                setExpanded((prev) =>
                                    prev.includes(node.id)
                                        ? prev.filter((nodeId) => nodeId !== node.id)
                                        : prev.concat(node.id)
                                );
                            } else {
                                onSelectChange(node);
                            }
                        }}
                        className="portfolio-label"
                    >
                        <PortfolioNodeIcon
                            isFolder={node.isFolder}
                            isExpanded={node?.isExpanded}
                            isMaster={node.properties.isMaster}
                            typeId={node.properties.typeId}
                        />

                        <span>{node.properties.name}</span>
                    </div>
                </div>
            );
        },
        [onSelectChange]
    );

    return (
        <MainModal
            scrollBehavior="inside"
            isOpen
            onClose={onClose}
            header={
                <FormattedMessage
                    id="portfolios_properties_move_modal_heading"
                    values={{ name: source.properties.name }}
                />
            }
            content={
                <>
                    <SearchBar
                        textHolder={intl.formatMessage({ id: 'common_search_portfolios' })}
                        onChange={(value) => setSearch(value)}
                    />

                    <Spacer mb={5} />

                    <PortfolioTreeWrapper isDisabled={false} isDisplayedInModal={true}>
                        <Tree
                            aria-labelledby="portfolios-title"
                            nodes={filteredNodes}
                            expanded={expanded}
                            onExpandChange={onExpandChange}
                            selected={target}
                            onSelectChange={onSelectChange}
                            renderLabel={renderLabel}
                            expandOnSelect={false}
                        />
                    </PortfolioTreeWrapper>
                </>
            }
            footerLeftSlot={
                <LeftFooterWrapper>
                    {destinationIsSourceParent ? (
                        <FormattedMessage id="portfolios_properties_move_modal_same_parent_warning" />
                    ) : destinationContainsSourceName ? (
                        <FormattedMessage id="portfolios_properties_move_modal_contains_name_warning" />
                    ) : (
                        destinationIsDescendant && (
                            <FormattedMessage id="portfolios_properties_move_modal_descendant_warning" />
                        )
                    )}
                </LeftFooterWrapper>
            }
            footerRightSlot={
                <Button
                    isLoading={isLoading}
                    isDisabled={
                        !target ||
                        target === source.id ||
                        destinationContainsSourceName ||
                        destinationIsSourceParent ||
                        destinationIsDescendant
                    }
                    onClick={movePortfolioToDestination}
                    ml={3}
                >
                    <FormattedMessage id="portfolios_properties_move_modal_btn" />
                </Button>
            }
        />
    );
};

const LeftFooterWrapper = styled.div`
    display: flex;
    color: var(--chakra-colors-red-500);
    max-width: 300px;
    font-size: 14px;

    @media screen and (max-width: 375px) {
        margin-bottom: var(--chakra-space-3);
    }
`;

export default MovePortfolioModal;
