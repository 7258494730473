import styled from '@emotion/styled/macro';

const FilteredWrapper = styled.div`
    display: flex;
    align-items: center;

    .path {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 10px;
        line-height: 1;
    }

    .path-wrapper {
        display: flex;
        flex-direction: column;
        min-width: 0;
    }
`;

export default FilteredWrapper;
