import { Progress } from '@chakra-ui/react';
import { Field } from 'formik';

import FormField from './FormField';

// If you need to extend Progress functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for Progress should be excluded from ...props and manually added
const ProgressBarFormikField = ({
    id,
    name,
    label,
    isIndeterminate = false,
    hasStripe = false,
    isAnimated = false,
    ...props
}) => {
    return (
        <Field name={name}>
            {({ field }) => (
                <FormField id={id} name={name} label={label} {...props}>
                    <Progress
                        {...field}
                        value={Number.isNaN(Number.parseFloat(field.value)) ? '' : field.value}
                        isIndeterminate={isIndeterminate}
                        hasStripe={hasStripe}
                        isAnimated={isAnimated}
                    />
                </FormField>
            )}
        </Field>
    );
};

export default ProgressBarFormikField;
