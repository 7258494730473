import {
    Heading,
    Flex,
    Box,
    SimpleGrid,
    AccordionItem,
    AccordionPanel,
    Text,
    Divider,
    useMediaQuery,
    MenuItem,
    IconButton,
} from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';

import { checkItemInputEvent } from '../../services/items';

import Tooltip from '../utils/Tooltip';
import AccordionButton from '../utils/AccordionButton';

import InputFormikField from '../forms/InputFormikField';

import RenewableDefinitionPanel from './RenewableDefinitionPanel';
import SubRenewableData from './SubRenewableData';

import SortOrderProvider from '../grid/utils/SortOrderContext';

import SubItemActionsMenu from '../itemData/SubItemActionsMenu';

import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete-circled.svg';
import { ReactComponent as AddItemIcon } from '../../icons/item-icons/add-item.svg';

const RenewableDefinition = ({
    itemId,
    itemTypeId,
    type,
    idx,
    definitions,
    formLabels,
    filters,
    editDescriptionConfig,
    setEditDescriptionConfig,
    onEditDescription,
    onAddSubRenewableModalOpen,
    onDeleteSubRenewableModalOpen,
    helpPageUrlItemTypeId = null,
}) => {
    const intl = useIntl();
    const [isDesktop] = useMediaQuery('(min-width: 769px)');

    const definition = definitions.renewableDefinitionResponses[idx];
    const foundEditDescriptionConfig = editDescriptionConfig[`${definition.id}`];
    const isEditing = foundEditDescriptionConfig ? foundEditDescriptionConfig.isActive : false;

    const { values } = useFormikContext();

    const validateName = (value, selectedRenewableId) => {
        const trimmed = value.trim();
        let errorMessage = '';

        if (trimmed.length === 0) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: 'Sub-installation name' }
            );
        } else if (trimmed.length > 50) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Sub-installation name', lengthRule: '50 characters or less' }
            );
        }

        if (errorMessage !== '') {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [selectedRenewableId]: { ...prevState[selectedRenewableId], penDisabled: true },
                };
            });
        } else {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [selectedRenewableId]: { ...prevState[selectedRenewableId], penDisabled: false },
                };
            });
        }

        return errorMessage;
    };

    return (
        <AccordionItem>
            {({ isExpanded }) => (
                <>
                    <SimpleGrid
                        columns={2}
                        mt="-1px"
                        bg="background-octenary"
                        borderTop="1px solid"
                        borderBottom="1px solid"
                        borderColor="border-secondary"
                    >
                        <Flex align="center" ml={4} my={3}>
                            <AccordionButton />

                            <Box flex={isEditing && 1}>
                                <Text size="xs" mb="2px">
                                    <FormattedMessage
                                        id="renewable_sub_renewable_heading"
                                        values={{ renewable: type }}
                                    />
                                </Text>

                                {!isEditing ? (
                                    <Heading as="h4" variant="h4">
                                        {values.definitions[idx]?.description}
                                    </Heading>
                                ) : (
                                    <InputFormikField
                                        validate={(value) => validateName(value, definition.id)}
                                        name={`definitions.${idx}.description`}
                                        type="text"
                                        onKeyDown={checkItemInputEvent}
                                        isFastField
                                    />
                                )}
                            </Box>

                            <Flex align="center" ml={3}>
                                <Tooltip label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}>
                                    <IconButton
                                        variant="circular-icon"
                                        minH="40px"
                                        aria-label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}
                                        leftIcon={<EditIcon />}
                                        onClick={() => onEditDescription(definition.id)}
                                    />
                                </Tooltip>
                            </Flex>
                        </Flex>

                        <Flex justify="flex-end" align="center" pr={6}>
                            {!isDesktop ? (
                                <SubItemActionsMenu
                                    menuListItems={
                                        <>
                                            <MenuItem
                                                key="delete-btn"
                                                textTransform="capitalize"
                                                onClick={() => {
                                                    onDeleteSubRenewableModalOpen(
                                                        definition.id,
                                                        definition.description
                                                    );
                                                }}
                                            >
                                                <FormattedMessage id="common_delete" />
                                            </MenuItem>

                                            <MenuItem
                                                key="create-btn"
                                                textTransform="capitalize"
                                                onClick={onAddSubRenewableModalOpen}
                                            >
                                                <FormattedMessage id="common_add" />
                                            </MenuItem>
                                        </>
                                    }
                                />
                            ) : (
                                <>
                                    <Box display="inline-block">
                                        <Tooltip
                                            label={intl.formatMessage({
                                                id: 'common_delete_btn_tooltip_and_label',
                                            })}
                                        >
                                            <IconButton
                                                aria-label={intl.formatMessage({
                                                    id: 'common_item_delete_subitem',
                                                })}
                                                variant="circular-icon"
                                                icon={<DeleteIcon />}
                                                onClick={() => {
                                                    onDeleteSubRenewableModalOpen(
                                                        definition.id,
                                                        definition.description
                                                    );
                                                }}
                                            />
                                        </Tooltip>
                                    </Box>

                                    <Divider orientation="vertical" mx={6} />

                                    <Box display="inline-block">
                                        <Tooltip label={intl.formatMessage({ id: 'common_add_new' })}>
                                            <IconButton
                                                aria-label={intl.formatMessage({ id: 'common_item_add_subitem' })}
                                                icon={<AddItemIcon />}
                                                variant="circular-icon"
                                                onClick={onAddSubRenewableModalOpen}
                                            />
                                        </Tooltip>
                                    </Box>
                                </>
                            )}
                        </Flex>
                    </SimpleGrid>

                    {isExpanded && (
                        <AccordionPanel px={6} pt={6} pb={4}>
                            <SortOrderProvider>
                                <RenewableDefinitionPanel
                                    type={type}
                                    idx={idx}
                                    definitions={definitions}
                                    formLabels={formLabels}
                                    helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                                />
                                <SubRenewableData
                                    subRenewableId={definition.id}
                                    itemLabel={type}
                                    itemId={itemId}
                                    itemTypeId={itemTypeId}
                                    itemType={type}
                                    filters={filters}
                                    helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                                />
                            </SortOrderProvider>
                        </AccordionPanel>
                    )}
                </>
            )}
        </AccordionItem>
    );
};

export default RenewableDefinition;
