import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSelector } from 'react-redux';

import { Box, Heading, HStack, Spacer, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useLocation } from 'react-router-dom';

import { getJobFilterOptions, getJobs, getJobFiltersConfig, setFiltersConfig, getStudies } from '../services/studies';

import LoadingModal from '../components/modal/LoadingModal';

import Error from '../components/utils/Error';
import Tooltip from '../components/utils/Tooltip';
import MainWrapper from '../components/utils/MainWrapper';

import JobsGrid from '../components/studies/JobsGrid';

const Jobs = () => {
    const autoUpdateJobs = useSelector((state) => state.jobs.autoUpdateJobs);

    const intl = useIntl();
    const { search } = useLocation();

    const [filterOptions, setFilterOptions] = useState([]);
    const [filtersLoading, setFiltersLoading] = useState(true);
    const [initialDataLoading, setInitialDataLoading] = useState(true);
    const [jobsDataLoading, setJobsDataLoading] = useState(true);
    const [studies, setStudies] = useState([]);
    const [filtersError, setFiltersError] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [isSingleStudyIdParam, setIsSingleStudyIdParam] = useState(false);

    const jobFiltersConfig = getJobFiltersConfig();
    const [filters, setFilters] = useState(jobFiltersConfig);
    const [jobIdInput, setJobIdInput] = useState(jobFiltersConfig ? jobFiltersConfig.jobIds.join(',') : '');

    const getData = useCallback(
        async ({ localFilters, localStudies }) => {
            // If no localFilters arg passed in then take filters from the component state
            const jobFilters = localFilters || filters;

            // If no localStudies arg passed in then take studies from the component state
            const studyIds = localStudies
                ? localStudies?.map((e) => parseInt(e.studyId))
                : studies?.map((e) => parseInt(e.studyId));

            const payload = {
                statusIds: jobFilters ? jobFilters.statusIds : [],
                moduleIds: jobFilters ? jobFilters.moduleIds : [],
                sensitivityIds: jobFilters ? jobFilters.sensitivityIds : [],
                jobIds: jobFilters ? jobFilters.jobIds : [],
                studyIds: studyIds ? studyIds : [],
            };

            try {
                setJobsDataLoading(true);
                const response = await getJobs(payload);
                setRowData(response.data);
            } catch {
                setRowData([]);
            }
            setJobsDataLoading(false);
        },
        [filters, studies]
    );

    const updateFilters = (value, type, refetch = true) => {
        if (filters) {
            const tempFilters = { ...filters, [type]: value };
            setFilters(tempFilters);
            setFiltersConfig(tempFilters, 'jobFilters');
            if (refetch) {
                getData({ localFilters: tempFilters });
            }
        } else {
            const initialFilters = {
                moduleIds: [],
                sensitivityIds: [],
                jobIds: [],
                statusIds: [],
            };

            setFilters({ ...initialFilters, [type]: value });
            setFiltersConfig({ ...initialFilters, [type]: value }, 'jobFilters');
            if (refetch) {
                getData({ localFilters: { ...initialFilters, [type]: value } });
            }
        }
    };

    const fetchInitialData = useCallback(async () => {
        const fetchFilterOptions = getJobFilterOptions();

        const query = new URLSearchParams(search);

        const studyIds = query.get('ids')
            ? query
                  .get('ids')
                  .split('-')
                  .map((e) => parseInt(e))
            : [];

        if (studyIds?.length === 0) {
            setInitialDataLoading(false);
            setFiltersLoading(false);
            return;
        }

        if (studyIds?.length === 1) {
            setIsSingleStudyIdParam(true);
        }

        const payload = {
            studyIds: studyIds,
        };

        const fetchStudies = getStudies(payload);

        return Promise.all([fetchFilterOptions, fetchStudies])
            .then((data) => {
                setFilterOptions(data[0]);
                setStudies(data[1].data);

                getData({ localStudies: data[1].data });
            })
            .catch(() => setFiltersError(true))
            .finally(() => {
                setFiltersLoading(false);
                setInitialDataLoading(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        fetchInitialData();
    }, [fetchInitialData]);

    useEffect(() => {
        let timer = setTimeout(function applyJobsAutoUpdate() {
            if (autoUpdateJobs) {
                // If we do not pass arguments then filters and studies component state will be used to retrieve data
                getData({});
                timer = setTimeout(applyJobsAutoUpdate, 30000);
            } else {
                clearTimeout(timer);
            }
        }, 30000);

        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoUpdateJobs, filters, studies]);

    return (
        <>
            <MainWrapper>
                <HStack alignItems="center" textTransform="capitalize" mt={3}>
                    <Heading flex="1 1 100%" as="h2" variant="h2">
                        {isSingleStudyIdParam ? (
                            <FormattedMessage
                                id="jobs_tracker_single_study_header"
                                values={{ study: studies[0]?.name }}
                            />
                        ) : (
                            <FormattedMessage id="jobs_tracker_header" />
                        )}
                    </Heading>
                    <Spacer />
                </HStack>
                <>
                    {filtersLoading || initialDataLoading ? (
                        <LoadingModal header={<FormattedMessage id="common_loading" />} isOpen />
                    ) : (
                        <>
                            {filtersError ? (
                                <Box m={6}>
                                    <Error
                                        primaryId="common_error"
                                        secondaryId="common_loading_error"
                                        additionalText={intl.formatMessage({
                                            id: 'study_tracker_study_filters',
                                        })}
                                    />
                                </Box>
                            ) : (
                                <CustomTabs>
                                    {!isSingleStudyIdParam && (
                                        <CustomTabList>
                                            <CustomTab textTransform="capitalize">
                                                <FormattedMessage id="jobs_tracker_all_selected" />
                                            </CustomTab>
                                            {studies?.map((elem) => (
                                                <TooltipWrapper key={elem.studyId}>
                                                    <Tooltip label={elem.name}>
                                                        <CustomTab>{elem.name}</CustomTab>
                                                    </Tooltip>
                                                </TooltipWrapper>
                                            ))}
                                        </CustomTabList>
                                    )}

                                    <CustomTabPanels bg="background-secondary">
                                        <CustomTabPanel>
                                            <JobsGrid
                                                filterOptions={filterOptions}
                                                rowData={rowData}
                                                filters={filters}
                                                updateFilters={updateFilters}
                                                jobIdInput={jobIdInput}
                                                setJobIdInput={setJobIdInput}
                                                getData={getData}
                                                studies={studies}
                                                jobsDataLoading={jobsDataLoading}
                                            />
                                        </CustomTabPanel>

                                        {studies?.map((study) => (
                                            <CustomTabPanel key={study.studyId}>
                                                <JobsGrid
                                                    filterOptions={filterOptions}
                                                    rowData={rowData.filter((job) => job.studyId === study.studyId)}
                                                    filters={filters}
                                                    updateFilters={updateFilters}
                                                    jobIdInput={jobIdInput}
                                                    setJobIdInput={setJobIdInput}
                                                    getData={getData}
                                                    studies={[study]}
                                                    jobsDataLoading={jobsDataLoading}
                                                />
                                            </CustomTabPanel>
                                        ))}
                                    </CustomTabPanels>
                                </CustomTabs>
                            )}
                        </>
                    )}
                </>
            </MainWrapper>
        </>
    );
};

const CustomTabList = styled(TabList)`
    padding: 10px 0 5px 0;
    margin: 0 25px 0 25px;
    border-bottom: 1px solid var(--chakra-colors-gray-200);
    overflow-y: auto;

    [aria-selected='true'] {
        max-width: fit-content;
    }
`;

const CustomTab = styled(Tab)`
    padding: 20px 0 2px 0;
    margin-right: 24px;
    margin-bottom: 0;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 200px;
    max-width: 300px;

    :focus {
        border-radius: var(--chakra-radii-base);
    }
`;

const CustomTabs = styled(Tabs)`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
`;
const CustomTabPanels = styled(TabPanels)`
    flex: 1;
    display: flex;
    flex-direction: column;
`;
const CustomTabPanel = styled(TabPanel)`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const TooltipWrapper = styled.div`
    .tooltip_wrapper {
        transform: translate(calc(-60%), -150%) !important;
    }

    .tooltip_children_wrapper {
        :hover + div {
            transform: translate(calc(-60%), -150%) !important;
        }
        :focus-within + div {
            visibility: hidden;
        }
    }
`;
export default Jobs;
