import React, { useMemo, useContext } from 'react';
import { useField, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ItemTopHeader from './ItemTopHeader';
import { useIntl } from 'react-intl';
import _ from 'lodash';

import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import useAutoformTableData from './hooks/useAutoformTableData';
import useAutoformMetadata from './hooks/useAutoformMetadata';
import useAutoformTableHierarchy from './hooks/useAutoformTableHierarchy';
import useAutoformParams from './hooks/useAutoformParams';
import { getInputFormName, getTableFormKey, getItemFormKey, parseColumnSettings } from './utils/autoformUtils';
import useCommonToast from '../../hooks/useCommonToast';
import { useDisclosure } from '@chakra-ui/react';
import DeleteRecordModal from '../utils/DeleteRecordModal';
import { deleteAutoformTableRow } from '../../store/autoform/autoformApi';
import AutoformUiContext from './utils/AutoformUiContext';
import ItemPropertiesModal from 'components/utils/ItemPropertiesModal';
import { onHasUnsavedChanges } from 'store/helpers/helpersSlice';
import { isSubmitDisabled } from 'services/utils';

/**
 * Container component for top header. Handles deletion logic for first
 * level tables (not displayed as grids). Handles Dynamic Properties
 * for Item autoforms.
 *
 * @param {string} tableId
 * @param {object} portfolioItem - Item in the portfolios screen.
 * @param {callback} onNodeItemDelete - Optional. Callback to delete the portfolio item in the portfolios screen.
 * @param {callback} onNodeOwnershipPercentageUpdate - Optional. Callback to change the percent ownership
 * of the item in the portfolio in the portfolios screen.
 * @param {object} config - containing config for this and nested components
 */
const ItemTopHeaderContainer = ({
    tableId,
    onNodeItemDelete,
    portfolioItem,
    onNodeOwnershipPercentageUpdate,
    config = {},
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const deleteItemModal = useDisclosure();
    const propertiesModal = useDisclosure();

    const dispatch = useDispatch();
    const { metadata: autoformMetadata, hierarchicalTables, itemTypeId } = useAutoformMetadata();
    const { tablesWithChildrenMap } = useAutoformTableHierarchy(hierarchicalTables.level2, hierarchicalTables.level3);
    const tablesState = useSelector((state) => state.autoform.tables);

    const { editingState, setEditingState } = useContext(AutoformUiContext);
    const parameters = useAutoformParams();

    const { selectedItem: item } = useAutoformTableData(tableId); // TODO Use single row of table data
    const {
        primaryKeyColumnName,
        descriptionColumnName,
        descriptionColumn,
        selectedItem,
        metadata: tableMetadata,
    } = useAutoformTableMetadata(tableId);
    const { errors, status, touched, isSubmitting, values } = useFormikContext();
    const inputName = getInputFormName(tableId, selectedItem, descriptionColumnName);
    const [, { error }] = useField(inputName);
    const fieldStatus = _.get(status, inputName);

    const fieldErrors = useMemo(() => {
        let result = [];
        if (error) {
            result.push(error);
        }
        if (fieldStatus?.errors) {
            result.push(...fieldStatus.errors);
        }
        return result;
    }, [fieldStatus?.errors, error]);

    const itemDescription = values?.[getTableFormKey(tableId)]?.[getItemFormKey(selectedItem)]?.[descriptionColumnName];

    const settings = useMemo(() => parseColumnSettings(descriptionColumn.settings), [descriptionColumn]);

    const canSave = useMemo(() => {
        let result = false;
        if (autoformMetadata.maxTableHierarchyLevel <= 2) {
            result = tableMetadata.canUpdate;
        } else {
            result =
                tableMetadata.canUpdate ||
                hierarchicalTables.level2
                    .filter((table) => {
                        const isNotLeafTable = tablesWithChildrenMap[table.id]?.length > 0;
                        return isNotLeafTable;
                    })
                    .find((table) => table.canUpdate);
        }

        if (!result) {
            for (const tableKey in tablesState) {
                if (Object.keys(tablesState[tableKey]?.bulkUpdateInitialValues ?? {}).length) {
                    result = true;
                    break;
                }
            }
        }

        return result;
    }, [autoformMetadata, tableMetadata, hierarchicalTables, tablesWithChildrenMap, tablesState]);

    if (!item) return null;

    const onDeleteModalOpen = () => {
        deleteItemModal.onOpen();
    };

    const onDeleteModalClose = () => {
        deleteItemModal.onClose();
    };

    const onPropertiesModalOpen = () => {
        propertiesModal.onOpen();
    };

    const onPropertiesModalClose = () => {
        propertiesModal.onClose();
    };

    const onRecordDelete = async () => {
        const recordId = item[primaryKeyColumnName];
        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'record' });
        await dispatch(
            deleteAutoformTableRow({
                tableId,
                recordId,
                primaryKeyColumnName,
                parameters,
            })
        ).unwrap();
        toast(config?.deleteSuccessMessage ?? successMessage);
        onDeleteModalClose();
        onNodeItemDelete && onNodeItemDelete();
        dispatch(onHasUnsavedChanges(false));
    };

    return (
        <>
            <ItemTopHeader
                inputName={inputName}
                onItemDelete={onDeleteModalOpen}
                isDeleteVisible={tableMetadata.canDelete}
                isSaveVisible={canSave}
                itemDescription={itemDescription}
                isSaveDisabled={isSubmitDisabled({ errors, touched })}
                isEditVisible={tableMetadata.canUpdate && !descriptionColumn?.isReadOnly}
                isPropertiesVisible={!config?.hidePropertiesButton && itemTypeId}
                onPropertiesClick={onPropertiesModalOpen}
                isSubmitting={isSubmitting}
                itemTypeId={+itemTypeId?.value}
                isMaster={item?.SECURITYID}
                isEditMode={editingState[inputName]}
                info={settings?.CELLTOOLTIP}
                onEditClick={(isEditing) =>
                    setEditingState((oldState) => ({
                        ...oldState,
                        [inputName]: isEditing,
                    }))
                }
                config={config}
                errors={fieldErrors}
                warnings={fieldStatus?.warnings}
            />
            {deleteItemModal.isOpen && (
                <DeleteRecordModal
                    onOpen={onDeleteModalOpen}
                    onClose={onDeleteModalClose}
                    headerItem={config?.deleteModalHeaderItem ?? 'Item'}
                    contentMessageItem={item[descriptionColumnName]}
                    onConfirm={onRecordDelete}
                    forAutoForm={true}
                />
            )}
            {propertiesModal.isOpen && (
                <ItemPropertiesModal
                    isOpen={onPropertiesModalOpen}
                    onClose={onPropertiesModalClose}
                    itemId={item[primaryKeyColumnName]}
                    portfolio={portfolioItem}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                    forAutoForm={true}
                />
            )}
        </>
    );
};

export default ItemTopHeaderContainer;
