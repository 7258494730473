import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled/macro';

const MainRouterOutlet = (props) => {
    const ref = useRef();
    const { pathname } = useLocation();

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }

        window.scrollTo({
            top: 0,
        });
    }, [pathname]);

    return <Main ref={ref} tabIndex={-1} {...props} />;
};

const Main = styled.div`
    // focus indicator is not necessary for the main content wrapper
    outline: 0;
`;

export default MainRouterOutlet;
