import { Box, Skeleton } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { SHAPE_TYPES } from 'constants/shapeSet';
import MonthDoWHourGrid from './MonthDoWHourGrid';
import DoWHourGrid from './DoWHourGrid';
import useAutoformMetadata from 'components/autoform/hooks/useAutoformMetadata';
import useAutoformTableMetadata from 'components/autoform/hooks/useAutoformTableMetadata';
import useAutoformLoadTableData from 'components/autoform/hooks/useAutoformLoadTableData';
import DayHourGrid from './DayHour';
import YearMonthDoWGrid from './YearMonthDoW';
import MonthHourGrid from './MonthHourGrid';

const ShapeSetGrid = ({ shapeType = SHAPE_TYPES.MonthDoWHour, isLoadingEntryTypeMap }) => {
    const { hierarchicalTables } = useAutoformMetadata();
    const level2Table = hierarchicalTables.level2[0];
    const level3Table = hierarchicalTables.level3[0];
    const { selectedItem } = useAutoformTableMetadata(level2Table?.id);
    const { isLoading, reloadData } = useAutoformLoadTableData(level3Table.id, selectedItem, { pageSize: 10000 });

    if (isLoading || isLoadingEntryTypeMap) {
        return (
            <Box px={6}>
                <Skeleton>
                    <Box h="34px">
                        <FormattedMessage id="common_loading" />
                    </Box>
                </Skeleton>
            </Box>
        );
    }
    switch (shapeType) {
        case SHAPE_TYPES.Month_DoW_Hour:
        case SHAPE_TYPES.Year_Month_DoW_Hour:
            return <MonthDoWHourGrid reloadData={reloadData} />;
        case SHAPE_TYPES.DoW_Hour:
        case SHAPE_TYPES.Year_DoW_Hour:
            return <DoWHourGrid reloadData={reloadData} />;
        case SHAPE_TYPES.Day_Hour:
            return <DayHourGrid reloadData={reloadData} />;
        case SHAPE_TYPES.Year_Month_DoW:
            return <YearMonthDoWGrid reloadData={reloadData} />;
        case SHAPE_TYPES.Month_Hour:
            return <MonthHourGrid reloadData={reloadData} />;
        default:
            return <MonthHourGrid reloadData={reloadData} />;
    }
};

export default ShapeSetGrid;
