import { useState, useEffect } from 'react';
import { getGroupPermissionDetails } from '../../services/userManagement';

const useGroupPermissionDetails = (permissionId) => {
    const [groupPermissionDetails, setGroupPermissionDetails] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (permissionId) {
            setIsLoading(true);
            setIsError(false);

            getGroupPermissionDetails(permissionId)
                .then((response) => {
                    setGroupPermissionDetails(response);
                })
                .catch(() => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [permissionId]);

    return { groupPermissionDetails, isLoading, isError };
};

export default useGroupPermissionDetails;
