import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Box,
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
} from '@chakra-ui/react';

import { Form, Formik } from 'formik';

import ComboboxField from '../forms/ComboboxField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import InputFormikField from '../forms/InputFormikField';

import useCommonToast from '../../hooks/useCommonToast';

import SimpleGridSkeleton from '../utils/SimpleGridSkeleton';
import Error from '../utils/Error';

import {
    createBasisBasicModel,
    editBasisBasicModel,
    getBasisBasicModelsDropdownOptions,
    getBasisBasicModelById,
} from '../../services/basis';
import { isSubmitDisabled } from '../../services/utils';

const BasicModelModal = ({ basisId, onFinish, basicModelId, ...props }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();

    const [copyFromSelected, setCopyFromSelected] = useState();

    const [basicModelsDropdownOptions, setBasicModelsDropdownOptions] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const defaultValues = {
        description: '',
        parameter1: '',
        parameter2: '',
        parameter3: '',
        parameter4: '',
        aR1PARM: '',
        aR2PARM: '',
        mA1PARM: '',
        mA2PARM: '',
        minval: '',
        maxval: '',
    };

    const [initialValues, setInitialValues] = useState({
        loading: false,
        error: false,
        data: defaultValues,
    });

    const fetchBasicModel = useCallback(
        (id) => {
            return getBasisBasicModelById(id)
                .then((data) => {
                    const formatData = { ...data };
                    Object.keys(data).forEach((key) => {
                        formatData[key] = data[key] || '';
                    });
                    if (!basicModelId) {
                        formatData.description = `Copy of ${formatData.description}`;
                    }
                    setInitialValues((prev) => ({ ...prev, error: false, data: formatData }));
                })
                .catch(() => setInitialValues((prev) => ({ ...prev, error: true })))
                .finally(() => setInitialValues((prev) => ({ ...prev, loading: false })));
        },
        [basicModelId]
    );

    const fetchBasicModelDropdownOptions = useCallback(() => {
        return getBasisBasicModelsDropdownOptions()
            .then((data) => setBasicModelsDropdownOptions((prev) => ({ ...prev, error: false, data })))
            .catch(() => setBasicModelsDropdownOptions((prev) => ({ ...prev, error: true })))
            .finally(() => setBasicModelsDropdownOptions((prev) => ({ ...prev, loading: false })));
    }, []);

    useEffect(() => {
        fetchBasicModelDropdownOptions();
        if (basicModelId) {
            setInitialValues((prev) => ({ ...prev, loading: true }));
            fetchBasicModel(basicModelId);
        }
    }, [fetchBasicModelDropdownOptions, basicModelId, fetchBasicModel]);

    const onCopyFrom = async (existingBasicModelId, setFieldTouched) => {
        if (existingBasicModelId) {
            setCopyFromSelected(existingBasicModelId);
            setInitialValues((prev) => ({ ...prev, loading: true }));
            await fetchBasicModel(existingBasicModelId);
            setFieldTouched('description', true, true);
        } else {
            setCopyFromSelected(null);
            setInitialValues((prev) => ({
                ...prev,
                data: defaultValues,
            }));
        }
    };

    const formLabels = {
        copyFromExisting: intl.formatMessage({ id: 'add_basic_model_modal_copy_from_existing' }),
        description: intl.formatMessage({ id: 'add_basic_model_modal_name' }),
        distributionId: intl.formatMessage({ id: 'add_basic_model_modal_distribution' }),
        dateIntervalId: intl.formatMessage({ id: 'add_basic_model_modal_date_interval' }),
        parameter1: intl.formatMessage({ id: 'add_basic_model_modal_parameter_1' }),
        parameter2: intl.formatMessage({ id: 'add_basic_model_modal_parameter_2' }),
        parameter3: intl.formatMessage({ id: 'add_basic_model_modal_parameter_3' }),
        parameter4: intl.formatMessage({ id: 'add_basic_model_modal_parameter_4' }),
        aR1PARM: intl.formatMessage({ id: 'add_basic_model_modal_ar_1_parameter' }),
        aR2PARM: intl.formatMessage({ id: 'add_basic_model_modal_ar_2_parameter' }),
        mA1PARM: intl.formatMessage({ id: 'add_basic_model_modal_ma_1_parameter' }),
        mA2PARM: intl.formatMessage({ id: 'add_basic_model_modal_ma_2_parameter' }),
        minval: intl.formatMessage({ id: 'add_basic_model_modal_min_value' }),
        maxval: intl.formatMessage({ id: 'add_basic_model_modal_max_value' }),
    };

    const onFormSubmit = async ({
        description,
        distributionId,
        dateIntervalId,
        parameter1,
        parameter2,
        parameter3,
        parameter4,
        aR1PARM,
        aR2PARM,
        mA1PARM,
        mA2PARM,
        minval,
        maxval,
    }) => {
        const successMessage = intl.formatMessage({
            id: basicModelId ? 'add_basic_model_modal_update_success' : 'add_basic_model_modal_create_success',
        });

        const payload = {
            parameter3: parameter3 || null,
            parameter4: parameter4 || null,
            aR1PARM: aR1PARM || null,
            aR2PARM: aR2PARM || null,
            mA1PARM: mA1PARM || null,
            mA2PARM: mA2PARM || null,
            description,
            minval,
            maxval,
            distributionId,
            dateIntervalId,
            parameter1,
            parameter2,
        };

        if (basicModelId) {
            await editBasisBasicModel(basicModelId, payload);
        } else {
            await createBasisBasicModel(payload);
        }

        toast(successMessage);
        props.onClose();
        onFinish();
    };

    const validateBasicModelName = (value) => {
        const trimmed = value.trim();

        if (!trimmed) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Basic Model name' });
        }

        if (trimmed.length > 50) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Basic Model name', lengthRule: '50 characters or less' }
            );
        }
    };

    const validateRequiredField = (value, label) => {
        let error;
        if (value === '' || value === undefined) {
            error = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }
        return error;
    };

    const getParameterName = useCallback(
        (distributionId, parameter, name) => {
            const distribution = basicModelsDropdownOptions.data?.distributions.filter((e) => e.id === distributionId);

            if (!distributionId) {
                return name;
            }

            if (parameter === 'parameter1') {
                return distribution && distribution[0].parameter1;
            } else if (parameter === 'parameter2') {
                return distribution && distribution[0].parameter2;
            } else if (parameter === 'parameter3') {
                return distribution && distribution[0].parameter3;
            } else if (parameter === 'parameter4') {
                return distribution && distribution[0].parameter4;
            }
        },
        [basicModelsDropdownOptions.data?.distributions]
    );

    return (
        <Modal {...props}>
            <ModalOverlay />

            <ModalContent pt={3}>
                <ModalHeader>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        {basicModelId ? (
                            <FormattedMessage
                                id="basis_configurator_edit_basic_model"
                                values={{ name: initialValues.data.description }}
                            />
                        ) : (
                            <FormattedMessage id="basis_configurator_add_new_basic_model" />
                        )}
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik enableReinitialize initialValues={initialValues.data} onSubmit={onFormSubmit}>
                    {({ isSubmitting, errors, touched, values, setFieldTouched }) => (
                        <Form>
                            <ModalBody>
                                {basicModelsDropdownOptions.loading || initialValues.loading ? (
                                    <SimpleGridSkeleton rows={9} cols={1} />
                                ) : basicModelsDropdownOptions.error || initialValues.error ? (
                                    <Box m={6}>
                                        <Error
                                            primaryId="common_error"
                                            secondaryId="common_loading_error"
                                            additionalText="commodity"
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        {!basicModelId && (
                                            <ComboboxField
                                                id="copyFromExisting"
                                                name="copyFromExisting"
                                                label={formLabels.copyFromExisting}
                                                options={basicModelsDropdownOptions.data.basicModelResponses}
                                                value={copyFromSelected}
                                                onChange={(value) => onCopyFrom(value, setFieldTouched)}
                                                labelKey="description"
                                                valueKey="basicModelId"
                                                mb={5}
                                                showPlaceholder={true}
                                                placeholderValue={null}
                                                placeholderLabel="-"
                                            />
                                        )}

                                        <InputFormikField
                                            name="description"
                                            type="text"
                                            label={formLabels.description}
                                            validate={validateBasicModelName}
                                            isRequired
                                            isFastField
                                        />

                                        <SimpleGrid columns={2} mt={5} spacingX={3} spacingY={5}>
                                            <ComboboxFormikField
                                                id="distributionId"
                                                name="distributionId"
                                                label={formLabels.distributionId}
                                                options={basicModelsDropdownOptions.data.distributions}
                                                validate={(value) =>
                                                    validateRequiredField(value, formLabels.distributionId)
                                                }
                                                labelKey="description"
                                                valueKey="id"
                                                isRequired
                                                isFastField
                                            />

                                            <ComboboxFormikField
                                                id="dateIntervalId"
                                                name="dateIntervalId"
                                                label={formLabels.dateIntervalId}
                                                options={basicModelsDropdownOptions.data.dateIntervals}
                                                validate={(value) =>
                                                    validateRequiredField(value, formLabels.dateIntervalId)
                                                }
                                                labelKey="description"
                                                valueKey="id"
                                                isRequired
                                            />

                                            <InputFormikField
                                                name="parameter1"
                                                type="number"
                                                label={getParameterName(
                                                    values.distributionId,
                                                    'parameter1',
                                                    formLabels.parameter1
                                                )}
                                                validate={(value) =>
                                                    validateRequiredField(value, formLabels.parameter1)
                                                }
                                                isRequired
                                            />

                                            <InputFormikField
                                                name="parameter2"
                                                type="number"
                                                label={getParameterName(
                                                    values.distributionId,
                                                    'parameter2',
                                                    formLabels.parameter2
                                                )}
                                                validate={(value) =>
                                                    validateRequiredField(value, formLabels.parameter2)
                                                }
                                                isRequired
                                            />

                                            <InputFormikField
                                                name="parameter3"
                                                type="number"
                                                label={getParameterName(
                                                    values.distributionId,
                                                    'parameter3',
                                                    formLabels.parameter3
                                                )}
                                                isDisabled={
                                                    getParameterName(
                                                        values.distributionId,
                                                        'parameter3',
                                                        formLabels.parameter3
                                                    ) === 'N/A'
                                                }
                                            />

                                            <InputFormikField
                                                name="parameter4"
                                                type="number"
                                                label={getParameterName(
                                                    values.distributionId,
                                                    'parameter4',
                                                    formLabels.parameter4
                                                )}
                                                isDisabled={
                                                    getParameterName(
                                                        values.distributionId,
                                                        'parameter4',
                                                        formLabels.parameter4
                                                    ) === 'N/A'
                                                }
                                            />

                                            <InputFormikField
                                                name="aR1PARM"
                                                type="number"
                                                label={formLabels.aR1PARM}
                                                isFastField
                                            />

                                            <InputFormikField
                                                name="aR2PARM"
                                                type="number"
                                                label={formLabels.aR2PARM}
                                                isFastField
                                            />

                                            <InputFormikField
                                                name="mA1PARM"
                                                type="number"
                                                label={formLabels.mA1PARM}
                                                isFastField
                                            />

                                            <InputFormikField
                                                name="mA2PARM"
                                                type="number"
                                                label={formLabels.mA2PARM}
                                                isFastField
                                            />

                                            <InputFormikField
                                                name="minval"
                                                type="number"
                                                label={formLabels.minval}
                                                validate={(value) => validateRequiredField(value, formLabels.minval)}
                                                isRequired
                                                isFastField
                                            />

                                            <InputFormikField
                                                name="maxval"
                                                type="number"
                                                label={formLabels.maxval}
                                                validate={(value) => validateRequiredField(value, formLabels.maxval)}
                                                isRequired
                                                isFastField
                                            />
                                        </SimpleGrid>
                                    </>
                                )}
                            </ModalBody>
                            <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                <Box ml="auto">
                                    <Button variant="secondary" onClick={props.onClose}>
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id={basicModelId ? 'common_back' : 'common_cancel'} />
                                        </Box>
                                    </Button>

                                    <Button
                                        type="submit"
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        ml={3}
                                        textTransform="capitalize"
                                    >
                                        <FormattedMessage id={basicModelId ? 'common_update' : 'common_continue'} />
                                    </Button>
                                </Box>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default BasicModelModal;
