import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

const StyledAddItemButton = styled(Button)`
    background: none;
    margin: 10px 0px 10px 0px;
    padding: 0px 15px 5px 12px;
    justify-content: start;
    width: 100%;

    &:hover {
        background: none;
    }

    span {
        width: 20px;
        height: 20px;
    }

    &[disabled] {
        opacity: 0.6;
        background: none;

        &:hover {
            background: none;
        }
    }
`;

const AddItemButton = (props) => <StyledAddItemButton variant="add-item-button" {...props} />;

export default AddItemButton;
