import { useState, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Spacer, Skeleton, Box, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import Tree from '../react-tree/Tree';

import {
    filterNodesByName,
    getChildPortfolioNodeIds,
    findNode,
    getPortfolios,
    getPortfolioItemIdsWithoutNested,
} from '../../services/portfolios';

import useCommonToast from '../../hooks/useCommonToast';

import PortfolioTreeWrapper from '../portfolios/PortfolioTreeWrapper';
import PortfolioNodeIcon from '../portfolios/PortfolioNodeIcon';
import PortfolioArrowIcon from '../portfolios/PortfolioArrowIcon';

import MainModal from '../modal/MainModal';

import SearchBar from '../utils/SearchBar';

import CustomCheckbox from '../forms/CustomCheckbox';

import { ReactComponent as RemoveIcon } from '../../icons/mdi-minus-circle.svg';

const AddItemPortfolios = ({ onClose, sourceId, onAddError, onAddSuccess, onSubmit, itemName }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();

    const [isLoadingError, setIsLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [nodes, setNodes] = useState([]);
    const [filteredNodes, setFilteredNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const [search, setSearch] = useState('');

    useEffect(() => {
        setIsLoading(true);

        getPortfolios()
            .then((data) => {
                setNodes(data.portfolios);
                setFilteredNodes(data.portfolios);
                setExpanded(data.portfolios.map((node) => node.id));
            })
            .catch(() => {
                setIsLoadingError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        const term = search.trimStart().toLowerCase();

        if (term && term.length >= 2) {
            const data = filterNodesByName(nodes, term);
            const ids = getChildPortfolioNodeIds(data);

            setFilteredNodes(data);
            setExpanded(ids);
        } else {
            setFilteredNodes(nodes);
            setExpanded(nodes.map((node) => node.id));
        }
    }, [search]); // eslint-disable-line

    const addItems = async () => {
        const portfolios = selectedItems.map((id) => findNode(nodes, id));
        const portfoliosPropertyIds = portfolios.map((p) => p.properties.id);

        try {
            setIsSaving(true);

            await onSubmit(sourceId, portfoliosPropertyIds);

            setIsSaving(false);
            onAddSuccess(portfolios);
        } catch (error) {
            setIsSaving(false); // using the finally block triggers memory leak warning

            if (error.response.status !== 401) {
                const message =
                    error.response.status === 403
                        ? error.response.data.error
                        : intl.formatMessage({ id: 'common_generic_saving_error' });

                toast({
                    status: 'error',
                    message,
                });
            }
        }
    };

    const checkSelectedPortfolio = (nodes, id) => {
        const itemsInSelectedPortfolio = getPortfolioItemIdsWithoutNested(nodes);

        return itemsInSelectedPortfolio.includes(parseInt(id, 10));
    };

    const onAddPortfolioSelectChange = useCallback(
        (node) => {
            if (!node.isFolder) {
                return;
            }

            const isInSelectedPortfolio = checkSelectedPortfolio(node.nodes, sourceId);

            if (isInSelectedPortfolio) {
                return;
            }

            setSelectedItems((prev) =>
                prev.includes(node.id) ? prev.filter((id) => id !== node.id) : prev.concat(node.id)
            );
        },
        [sourceId]
    );

    const onExpandChange = useCallback(({ id }) => {
        setExpanded((prev) => (prev.includes(id) ? prev.filter((node) => node !== id) : prev.concat(id)));
    }, []);

    const renderPortfolioLabel = useCallback(
        (node) => {
            const isInSelectedPortfolio = checkSelectedPortfolio(node.nodes, sourceId);
            const isSelected = selectedItems.includes(node.id);

            return (
                <div className={`${node?.isExpandable ? '' : 'non-expandable '}portfolio-label-wrapper`}>
                    {node?.isExpandable && (
                        <span
                            onClick={() => {
                                setExpanded((prev) =>
                                    prev.includes(node.id) ? prev.filter((id) => id !== node.id) : prev.concat(node.id)
                                );
                            }}
                        >
                            <PortfolioArrowIcon isExpanded={node?.isExpanded} isMaster={node.properties.isMaster} />
                        </span>
                    )}

                    <div
                        onClick={(event) => {
                            if (event.shiftKey && node?.isExpandable) {
                                setExpanded((prev) =>
                                    prev.includes(node.id)
                                        ? prev.filter((nodeId) => nodeId !== node.id)
                                        : prev.concat(node.id)
                                );
                            } else {
                                onAddPortfolioSelectChange(node);
                            }
                        }}
                        className="portfolio-label"
                    >
                        <PortfolioNodeIcon
                            isFolder={node.isFolder}
                            isExpanded={node?.isExpanded}
                            isMaster={node.properties.isMaster}
                            typeId={node.properties.typeId}
                        />

                        <span>{node.properties.name}</span>
                    </div>

                    {node.isFolder && (
                        <CustomCheckbox
                            tabIndex={-1}
                            onChange={() => onAddPortfolioSelectChange(node)}
                            isDisabled={isInSelectedPortfolio}
                            isChecked={isInSelectedPortfolio ? true : isSelected}
                        />
                    )}
                </div>
            );
        },
        [selectedItems, sourceId, onAddPortfolioSelectChange]
    );

    return (
        <MainModal
            scrollBehavior="inside"
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="load_add_load_to_portfolio" values={{ name: itemName }} />}
            content={
                <>
                    <SearchBar
                        textHolder={intl.formatMessage({ id: 'common_search_portfolios' })}
                        onChange={(value) => setSearch(value)}
                    />

                    <Spacer mb={5} />

                    <PortfolioTreeWrapper isDisplayedInModal isDisabled={isSaving}>
                        {isLoading ? (
                            <Skeleton>
                                <Box h="34px">
                                    <FormattedMessage id="common_loading" />
                                </Box>
                            </Skeleton>
                        ) : isLoadingError ? (
                            <Box mx={6}>
                                <Alert status="error">
                                    <AlertIcon />

                                    <Box>
                                        <AlertTitle textTransform="capitalize">
                                            <FormattedMessage id="common_error" />
                                        </AlertTitle>

                                        <AlertDescription>
                                            <FormattedMessage id="common_loading_error" values={{ items: 'items' }} />
                                        </AlertDescription>
                                    </Box>
                                </Alert>
                            </Box>
                        ) : (
                            <Tree
                                aria-label={intl.formatMessage({ id: 'common_items' })}
                                nodes={filteredNodes}
                                expanded={expanded}
                                onExpandChange={onExpandChange}
                                onSelectChange={onAddPortfolioSelectChange}
                                renderLabel={renderPortfolioLabel}
                                expandOnSelect={false}
                            />
                        )}
                    </PortfolioTreeWrapper>
                </>
            }
            footerLeftSlot={
                <div>
                    {selectedItems.length > 0 && (
                        <Button
                            isDisabled={isSaving}
                            onClick={() => setSelectedItems([])}
                            ml="-14px"
                            size="sm"
                            leftIcon={<RemoveIcon />}
                            variant="ghost"
                        >
                            <FormattedMessage id="common_items_selected" values={{ n: selectedItems.length }} />
                        </Button>
                    )}
                </div>
            }
            footerRightSlot={
                <Button isLoading={isSaving} isDisabled={selectedItems.length === 0} onClick={addItems} ml={3}>
                    <FormattedMessage id="common_add_to_portfolio_btn" />
                </Button>
            }
        />
    );
};

export default AddItemPortfolios;
