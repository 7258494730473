import { FormattedMessage } from 'react-intl';

export const STOPPED_JOB_STATUS_ID = -3;
export const SYSTEM_STUDY_ID = 1;

export const initialJobsVisibleColunms = [
    'status',
    'jobId',
    'moduleId',
    'studyId',
    'studyName',
    'bookName',
    'sensitivityId',
    'processedBy',
    'scheduledRunTime',
    'attempt',
    'startDate',
    'endDate',
    'runTimeHours',
    'jobType',
    'portfolio',
    'submittedBy',
    'runTimeMinutes',
    'debug',
];

// initialJobsVisibleColunms array and jobsColumnSelectionOptions array must have the same number and names/ids of columns
export const jobsColumnSelectionOptions = [
    {
        id: 'status',
        disabled: false,
        label: <FormattedMessage id="jobs_table_status" />,
    },
    {
        id: 'jobId',
        disabled: false,
        label: <FormattedMessage id="jobs_table_job_id" />,
    },
    {
        id: 'moduleId',
        disabled: false,
        label: <FormattedMessage id="jobs_table_module" />,
    },
    {
        id: 'studyId',
        disabled: false,
        label: <FormattedMessage id="jobs_table_study_id" />,
    },
    {
        id: 'studyName',
        disabled: false,
        label: <FormattedMessage id="jobs_table_study_name" />,
    },
    {
        id: 'bookName',
        disabled: false,
        label: <FormattedMessage id="jobs_table_book" />,
    },
    {
        id: 'sensitivityId',
        disabled: false,
        label: <FormattedMessage id="jobs_table_sensitivity" />,
    },
    {
        id: 'processedBy',
        disabled: false,
        label: <FormattedMessage id="jobs_table_processed_by" />,
    },
    {
        id: 'scheduledRunTime',
        disabled: false,
        label: <FormattedMessage id="jobs_table_scheduled_runtime" />,
    },
    {
        id: 'attempt',
        disabled: false,
        label: <FormattedMessage id="jobs_table_attempt" />,
    },
    {
        id: 'startDate',
        disabled: false,
        label: <FormattedMessage id="jobs_table_start_date" />,
    },
    {
        id: 'endDate',
        disabled: false,
        label: <FormattedMessage id="jobs_table_end_date" />,
    },
    {
        id: 'runTimeHours',
        disabled: false,
        label: <FormattedMessage id="jobs_table_run_time" />,
    },
    {
        id: 'jobType',
        disabled: false,
        label: <FormattedMessage id="jobs_table_job_type" />,
    },
    {
        id: 'portfolio',
        disabled: false,
        label: <FormattedMessage id="jobs_table_job_portfolio" />,
    },
    {
        id: 'submittedBy',
        disabled: false,
        label: <FormattedMessage id="jobs_table_job_submitted_by" />,
    },
    {
        id: 'runTimeMinutes',
        disabled: false,
        label: <FormattedMessage id="jobs_table_job_run_time_min" />,
    },
    {
        id: 'debug',
        disabled: false,
        label: <FormattedMessage id="jobs_table_debug" />,
    },
];
