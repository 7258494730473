import axios from 'axios';
import { loadModules } from 'esri-loader';
import { layersConfig } from '../../services/portfolios/layersConfig';
import {
    allPowerplants,
    allPlannedStorage,
    allISO,
    allIRA,
    allTransmissionLines,
    allPlannedRetirements,
    allISOSubregions,
    SUBSTATIONS_GROUP,
    IQ_GROUP,
    ELECTRIC_RETAIL_SERVICE_TERRITORIES_GROUP,
    RETIRED_GENERATORS_GROUP,
    EIA_PLANNED_GENERATORS_GROUP,
    ELECTRIC_LOAD_GROUP,
    USA_MEDIAN_HOME_VALUE_GROUP,
    POPULATION_DENSITY_GROUP,
} from '../../constants/portfolios';

export const downloadScreenshot = (uri, name) => {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const getArcGISOAuthTokenDetails = async () => {
    const response = await axios.get('/arcgis-oath-token-details');
    return response.data;
};

export const getPowersimmLayerItemsInfo = async () => {
    const response = await axios.get(`/arcgis-schema-feature-layer`, {
        suppressErrorMessageHandler: true,
    });
    return response?.data;
};

export const getPins = async () => {
    const response = await axios.get(`/map-pins`);
    return response.data;
};

export const addPin = async (payload) => {
    const response = await axios.post(`/map-pins`, payload);
    return response.data;
};

export const getBookmarks = async () => {
    const response = await axios.get(`/map-bookmarks`);
    return response.data;
};

export const addBookmark = async (payload) => {
    const response = await axios.post(`/map-bookmarks`, payload);
    return response.data;
};

export const generateContinuousColorRenderer = async (
    mapView,
    layer,
    fieldName,
    colorName,
    theme,
    isVislble = false,
    storageKey = null
) => {
    const [colorSchemes, colorRendererCreator] = await loadModules([
        'esri/smartMapping/symbology/color',
        'esri/smartMapping/renderers/color',
    ]);

    const colorParams = {
        layer: layer,
        valueExpression: `$feature.${fieldName}`,
        view: mapView,
        colorScheme: colorSchemes.flipColors(
            colorSchemes.getSchemeByName({ name: colorName, geometryType: 'point', theme: theme })
        ),
        outlineOptimizationEnabled: true,
        defaultSymbolEnabled: false,
    };

    await colorRendererCreator.createContinuousRenderer(colorParams).then(async (response) => {
        const rendererResult = response;
        layer.renderer = rendererResult.renderer;
        layer.visible = isVislble;

        if (window?.localStorage && storageKey != null) {
            localStorage.setItem(storageKey, JSON.stringify(rendererResult.renderer.clone()));
        }
    });
};

export const clearIsoLocalStorage = (isoName) => {
    if (window?.localStorage) {
        const searchString = `ISORENDERER_${isoName}`;

        const matchingKeys = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);

            if (key && key.includes(searchString)) {
                matchingKeys.push(key);
            }
        }

        matchingKeys?.forEach((key) => {
            localStorage.removeItem(key);
        });
    }
};

export const styleIsoLayerField = async (view, iso, isoLayer, isVislble, fieldName) => {
    const colorName = 'Blue and Red 9';
    const theme = 'above-and-below';
    const [ClassBreaksRenderer] = await loadModules(['esri/renderers/ClassBreaksRenderer']);

    if (window?.localStorage) {
        const storageKey = `ISORENDERER_${iso.name}_${fieldName}_${iso.version}`;
        const renderer = localStorage.getItem(storageKey);

        if (renderer) {
            isoLayer.renderer = ClassBreaksRenderer.fromJSON(JSON.parse(renderer));
            isoLayer.visible = isVislble;
        } else {
            // renderer not found - could be new version: remove all previous ISO renderers from storage
            clearIsoLocalStorage(iso.name);

            // generate and store ISO layer default field and version renderer
            await generateContinuousColorRenderer(view, isoLayer, fieldName, colorName, theme, isVislble, storageKey);
        }
    } else {
        await generateContinuousColorRenderer(view, isoLayer, fieldName, colorName, theme, isVislble);
    }
};

export const deleteMapElement = (id) => axios.delete(`/map-element/${id}`);

export const organizeMapLayers = (layers, view) => {
    let organizedLayers = [];
    let powerplants = [];
    let iso = [];
    let plannedStorage = [];
    let ira = [];
    let trlines = [];
    let retirements = [];
    let subregions = [];

    for (const layer of layers) {
        if (layer.title) {
            const layerInfo = layersConfig.find((elem) => elem.title === layer.title);
            layer.description = layerInfo ? layerInfo.description : '';
            layer.custom_title = layerInfo && layerInfo.custom_title ? layerInfo.custom_title : null;
        }

        layer.visibleAtScale = view.scale < layer.minScale || layer.minScale === 0;
        // Add in a different array all powerplants so we can later on add them to the fake node as children nodes.
        if (layer.title && allPowerplants.includes(layer.title)) {
            powerplants.push(layer);
            continue;
        }

        if (layer.title && allISO.includes(layer.title)) {
            const found = iso?.find((l) => l.title === layer.title);
            if (!found) iso.push(layer);
            continue;
        }

        if (layer.title && allPlannedStorage.includes(layer.title)) {
            plannedStorage.push(layer);
            continue;
        }

        if (layer.title && layer.title.includes(allIRA)) {
            ira.push(layer);
            continue;
        }

        if (layer.title && allTransmissionLines.includes(layer.title)) {
            trlines.push(layer);
            continue;
        }

        if (layer.title && layer.title.includes(allPlannedRetirements)) {
            retirements.push(layer);
            continue;
        }

        if (layer.title && allISOSubregions.includes(layer.title)) {
            subregions.push(layer);
            continue;
        }

        // Below groups contain only single layer
        if (layer?.title?.toUpperCase() === SUBSTATIONS_GROUP.toUpperCase()) {
            organizedLayers.push(layer);
            continue;
        }

        if (layer?.title?.toUpperCase() === IQ_GROUP.toUpperCase()) {
            organizedLayers.push(layer);
            continue;
        }

        if (layer?.title?.toUpperCase() === ELECTRIC_RETAIL_SERVICE_TERRITORIES_GROUP.toUpperCase()) {
            organizedLayers.push(layer);
            continue;
        }

        if (layer?.title?.toUpperCase() === RETIRED_GENERATORS_GROUP.toUpperCase()) {
            organizedLayers.push(layer);
            continue;
        }

        if (layer?.title?.toUpperCase() === EIA_PLANNED_GENERATORS_GROUP.toUpperCase()) {
            organizedLayers.push(layer);
            continue;
        }

        if (layer?.title?.toUpperCase() === ELECTRIC_LOAD_GROUP.toUpperCase()) {
            organizedLayers.push(layer);
            continue;
        }

        if (layer?.title?.toUpperCase() === USA_MEDIAN_HOME_VALUE_GROUP.toUpperCase()) {
            organizedLayers.push(layer);
            continue;
        }

        if (layer?.title?.toUpperCase() === POPULATION_DENSITY_GROUP.toUpperCase()) {
            organizedLayers.push(layer);
            continue;
        }

        //Custom PowerSimm Layer
        if (layer?.isPowerSimmLayer) {
            const found = organizedLayers?.find((l) => l.isPowerSimmLayer);
            if (!found) organizedLayers.push(layer);
            continue;
        }
    }

    // Sort groups
    powerplants = powerplants.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
    iso = iso.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
    plannedStorage = plannedStorage.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
    ira = ira.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
    // Do not sort Transmission lines
    //trlines = trlines.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
    retirements = retirements.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
    subregions = subregions.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));

    // Add fake nodes to the organizedLayers array
    const nodePowerplant = layers.find((elem) => elem.id === 'powerplant_node');
    nodePowerplant.childrenNodes = powerplants;
    organizedLayers.push(nodePowerplant);

    const isoLayer = layers.find((elem) => elem.id === 'iso_node');
    isoLayer.childrenNodes = iso;
    organizedLayers.unshift(isoLayer);

    const plannedStorageLayer = layers.find((elem) => elem.id === 'planned_storage_node');
    plannedStorageLayer.childrenNodes = plannedStorage;
    organizedLayers.unshift(plannedStorageLayer);

    const iraLayer = layers.find((elem) => elem.id === 'ira_node');
    iraLayer.childrenNodes = ira;
    organizedLayers.unshift(iraLayer);

    const transmissionLinesLayer = layers.find((elem) => elem.id === 'transmission_lines_node');
    transmissionLinesLayer.childrenNodes = trlines;
    organizedLayers.unshift(transmissionLinesLayer);

    // Planned Retirements
    const retirementsLayer = layers.find((elem) => elem.id === 'planned_retirements_node');
    retirementsLayer.childrenNodes = retirements;
    organizedLayers.unshift(retirementsLayer);

    // Ascend ISO Forecasting Subregions
    const subregionsLayer = layers.find((elem) => elem.id === 'forecasting_subregions_node');
    subregionsLayer.childrenNodes = subregions;
    organizedLayers.unshift(subregionsLayer);

    return organizedLayers.sort(function (a, b) {
        return a.layerOrder - b.layerOrder;
    });
};
