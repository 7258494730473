import { createSlice } from '@reduxjs/toolkit';
import {
    getAutoformMetadata,
    getAutoformLevelMeta,
    getAutoformLevelData,
    getAutoformLevelDropdowns,
    addAutoformTableRow,
    saveAutoformRecords,
    deleteAutoformTableRow,
    deleteAutoformTableRows,
    refetchSelectedItemBackendData,
    getBulkUpdateData,
} from './autoformApi';

const initialState = {
    metadata: null,
    customParameters: null,
    tables: {},
    reloadGridFlag: {},
};

const setBulkUpdateDataForTableHandler = (state, payload) => {
    state.tables[payload.tableId] = state.tables[payload.tableId] || {};
    state.tables[payload.tableId].bulkUpdateData = {
        ...(state.tables[payload.tableId].bulkUpdateData ?? {}),
        ...payload.bulkUpdateData,
    };
};

const autoformSlice = createSlice({
    name: 'autoform',
    initialState,
    reducers: {
        setSelectedItemForTable(state, action) {
            state.tables[action.payload.tableId] = state.tables[action.payload.tableId] || {};
            state.tables[action.payload.tableId].selectedItem = action.payload.itemId;
        },
        setBulkUpdateFieldsForTable(state, action) {
            state.tables[action.payload.tableId] = state.tables[action.payload.tableId] || {};
            state.tables[action.payload.tableId].bulkUpdateFields = action.payload.bulkUpdateFields;
        },
        setBulkUpdateDataForTable(state, action) {
            setBulkUpdateDataForTableHandler(state, action.payload);
        },
        setBulkUpdateInitialValues(state, action) {
            Object.keys(action.payload.values).forEach((tableId) => {
                state.tables[tableId] = state.tables[tableId] || {};
                state.tables[tableId].bulkUpdateInitialValues = {
                    ...(state.tables[tableId].bulkUpdateInitialValues ?? {}),
                    ...action.payload.values[tableId],
                };

                if (action.payload?.resetData) {
                    //previous server's data is no more relevant after saving changes without refetch
                    state.tables[tableId].bulkUpdateData = {};
                }
            });
        },
        setGeneralValidationMessages(state, action) {
            state.tables[action.payload.tableId] = state.tables[action.payload.tableId] || {};
            state.tables[action.payload.tableId].generalValidationMessages =
                state.tables[action.payload.tableId].generalValidationMessages || {};
            state.tables[action.payload.tableId].generalValidationMessages[action.payload.itemId] = {
                errors: action.payload.errors,
                warnings: action.payload.warnings,
            };
        },
        clearAutoformStore() {
            return initialState;
        },
        setFiltersForTable(state, action) {
            state.tables[action.payload.tableId] = {
                ...state.tables[action.payload.tableId],
                customFilters: action.payload.filters,
            };
        },
        setSortAndPaginationForTable(state, action) {
            state.tables[action.payload.tableId] = {
                ...state.tables[action.payload.tableId],
                sortModel: action.payload.sortModel,
                pageSize: action.payload.pageSize,
                page: action.payload.page,
            };
        },
        resetSortAndPagination(state) {
            Object.keys(state.tables).forEach((tableId) => {
                delete state.tables[tableId].sortModel;
                delete state.tables[tableId].pageSize;
                delete state.tables[tableId].page;
            });
        },
        setCustomParameters(state, action) {
            state.customParameters = action.payload;
        },

        toggleReloadGridFlag(state, action) {
            const tableIds = action.payload?.tableIds ?? state.metadata?.tables?.map((table) => table.id) ?? [];

            for (const tableId of tableIds) {
                state.reloadGridFlag[tableId] = state.reloadGridFlag[tableId] ?? 0;
                state.reloadGridFlag[tableId]++;
            }
        },

        updateBook(state, action) {
            state.tables[action.payload.tableId] = state.tables[action.payload.tableId] || {};
            state.tables[action.payload.tableId].data = state.tables[action.payload.tableId].data || [];
            state.tables[action.payload.tableId].data = state.tables[action.payload.tableId].data.map((item) => {
                let newItem;

                if (item[action.payload.primaryKeyColumnName] === action.payload.itemId) {
                    newItem = {
                        ...item,
                        ...action.payload.item,
                    };
                }
                return newItem ? newItem : item;
            });
        },

        clearDataForTable(state, action) {
            state.tables[action.payload.tableId] = state.tables[action.payload.tableId] || {};
            state.tables[action.payload.tableId].data = [];
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getAutoformMetadata.fulfilled, (state, action) => {
            state.metadata = action.payload;
        });
        builder.addCase(getAutoformLevelMeta.fulfilled, (state, action) => {
            state.tables[action.payload.tableId] = {
                ...(state.tables[action.payload.tableId] || {}),
                metadata: action.payload.meta,
            };
        });
        builder.addCase(getAutoformLevelData.fulfilled, (state, action) => {
            state.tables[action.payload.tableId] = {
                ...(state.tables[action.payload.tableId] || {}),
                data: action.payload.data,
            };
        });
        builder.addCase(getAutoformLevelDropdowns.fulfilled, (state, action) => {
            state.tables[action.payload.tableId] = {
                ...(state.tables[action.payload.tableId] || {}),
                dropdownOptions: action.payload.data,
            };
        });
        builder.addCase(addAutoformTableRow.fulfilled, (state, action) => {
            state.tables[action.payload.tableId] = state.tables[action.payload.tableId] || {};
            state.tables[action.payload.tableId].data = state.tables[action.payload.tableId].data || [];
            state.tables[action.payload.tableId].data.push(action.payload.data.createdRecord);
        });
        builder.addCase(saveAutoformRecords.fulfilled, (state, action) => {
            state.tables[action.payload.tableId] = state.tables[action.payload.tableId] || {};
            state.tables[action.payload.tableId].data = state.tables[action.payload.tableId].data || [];
            state.tables[action.payload.tableId].data = state.tables[action.payload.tableId].data.map((data) => {
                const toUpdate = action.payload.data.find(
                    (updatedItem) =>
                        updatedItem[action.payload.primaryKeyRecordName] === data[action.payload.primaryKeyRecordName]
                );
                if (toUpdate) {
                    return toUpdate;
                }
                return data;
            });
        });

        builder.addCase(deleteAutoformTableRow.fulfilled, (state, action) => {
            state.tables[action.payload.tableId] = state.tables[action.payload.tableId] || {};
            state.tables[action.payload.tableId].data = state.tables[action.payload.tableId].data || [];
            state.tables[action.payload.tableId].data = state.tables[action.payload.tableId].data.filter(
                (data) => data[action.payload.primaryKeyColumnName] !== action.payload.recordId
            );
        });
        builder.addCase(deleteAutoformTableRows.fulfilled, (state, action) => {
            state.tables[action.payload.tableId] = state.tables[action.payload.tableId] || {};
            state.tables[action.payload.tableId].data = state.tables[action.payload.tableId].data || [];
            state.tables[action.payload.tableId].data = state.tables[action.payload.tableId].data.filter(
                (data) => action.payload.recordsIds.indexOf(data[action.payload.primaryKeyColumnName]) === -1
            );
        });
        builder.addCase(refetchSelectedItemBackendData.fulfilled, (state, action) => {
            state.tables[action.payload.tableId] = state.tables[action.payload.tableId] || {};
            state.tables[action.payload.tableId].data = state.tables[action.payload.tableId].data || [];
            let itemAlreadyInData = false;

            state.tables[action.payload.tableId].data = state.tables[action.payload.tableId].data.map((data) => {
                if (data[action.payload.primaryKeyColumnName] !== action.payload.itemId) {
                    return data;
                } else {
                    itemAlreadyInData = true;
                    return action.payload.data;
                }
            });
            if (!itemAlreadyInData) {
                state.tables[action.payload.tableId].data.push(action.payload.data);
            }
        });
        builder.addCase(getBulkUpdateData.fulfilled, (state, action) => {
            const parentRecordId = action.meta.arg.parentRecordId;
            const tableId = action.meta.arg.tableId;

            setBulkUpdateDataForTableHandler(state, {
                tableId,
                bulkUpdateData: { [parentRecordId.toString()]: action.payload },
            });
        });
    },
});

export const {
    setSelectedItemForTable,
    setBulkUpdateFieldsForTable,
    setBulkUpdateDataForTable,
    setBulkUpdateInitialValues,
    updateBook,
    setGeneralValidationMessages,
    setFiltersForTable,
    clearAutoformStore,
    setSortAndPaginationForTable,
    resetSortAndPagination,
    setCustomParameters,
    toggleReloadGridFlag,
    clearDataForTable,
} = autoformSlice.actions;
export default autoformSlice.reducer;
