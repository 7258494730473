import { useField } from 'formik';
import { FormLabel, Flex, Box, Text } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from '@emotion/styled/macro';

import CheckboxField from '../forms/CheckboxField';
import Combobox from '../forms/Combobox';

import CustomIconButton from '../utils/CustomIconButton';
import Popover from '../utils/Popover';

import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import CustomRequiredIndicator from '../forms/CustomRequiredIndicator';

const HedgeCommonPropertySelect = ({
    name,
    label,
    isDisabled,
    options,
    placeholder,
    onMultipleToggleChange,
    onChange,
    validate,
    info,
    footerLabel,
    isRequired,
    ...rest
}) => {
    const intl = useIntl();
    const [{ value }, _, { setValue }] = useField({ name, validate }); // eslint-disable-line

    const isMultipleSupported = Array.isArray(value);
    const isMultipleSelected = isMultipleSupported && value.includes('multiple');
    let selected = '-';
    const MULTIPLE_PLACEHOLDER_VALUE = 'multiple';

    if (isMultipleSupported) {
        if (isMultipleSelected) {
            selected = MULTIPLE_PLACEHOLDER_VALUE;
        } else if (value.length === 1) {
            selected = value[0];
        } else {
            selected = -1;
        }
    } else {
        selected = value;
    }

    return (
        <Flex direction="column" {...rest}>
            <CustomFormControl>
                {info && (
                    <Popover
                        placement="top"
                        trigger={<CustomIconButton mt="0px !important" mr="5px !important" icon={<InfoIcon />} />}
                    >
                        {info}
                    </Popover>
                )}

                <FormLabel {...(isDisabled || isMultipleSelected ? { 'data-disabled': true } : {})}>
                    {label}
                    {isRequired && <CustomRequiredIndicator />}
                </FormLabel>

                <Combobox
                    id={name}
                    name={name}
                    info={info}
                    label={label}
                    isDisabled={isDisabled || isMultipleSelected}
                    value={selected}
                    options={options}
                    valueKey="id"
                    labelKey="description"
                    onChange={(v) => {
                        const newValue = isMultipleSupported ? [v] : v;
                        setValue(newValue);
                        onChange(v);
                    }}
                    showPlaceholder={!!placeholder || isMultipleSelected}
                    placeholderValue={isMultipleSelected ? MULTIPLE_PLACEHOLDER_VALUE : -1}
                    placeholderLabel={
                        isMultipleSelected ? intl.formatMessage({ id: 'hedge_select_multiple' }) : placeholder
                    }
                    isRequired={isRequired}
                />
            </CustomFormControl>

            {isMultipleSupported && (
                <CheckboxField
                    mt={2}
                    id={`multiple_select_${name}`}
                    name={`multiple_select_${name}`}
                    isDisabled={isDisabled}
                    isChecked={isMultipleSelected}
                    onChange={onMultipleToggleChange}
                >
                    <Text as="span" fontSize="12px" color="gray.600">
                        <FormattedMessage id="hedge_select_multiple" />
                    </Text>
                </CheckboxField>
            )}

            {footerLabel && (
                <Box fontSize={12} mt={2}>
                    {footerLabel}
                </Box>
            )}
        </Flex>
    );
};

// @workaround Chakra's FormControl has bug in which if the user has their focus on an input inside FormControl
// and if you make the `<FormControl isDisabled={true}>` then the document.activeElement will become the <body> element
// but the FormLabel will continue to show with the focus styles (i.e. the color is blue)
const CustomFormControl = styled.div`
    &:focus-within > label {
        color: var(--chakra-colors-blue-400);
    }
`;

export default HedgeCommonPropertySelect;
