import { Box, Heading, Flex } from '@chakra-ui/react';

import AutoformTableContainer from './AutoformTableContainer';
import MisconfigurationError from './MisconfigurationError';
import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import AutoformItemFields from './AutoformItemFields';
import ItemTopHeaderContainer from './ItemTopHeaderContainer';
import ItemFormContainer from './ItemFormContainer';
import GeneralValidationPanelContainer from './GeneralValidationPanelContainer';
import { DELETE_CONFIRMATION_TYPE } from '../../constants/autoforms';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';

import { getInputFormName } from './utils/autoformUtils';

import useAutoformMetadata from './hooks/useAutoformMetadata';
import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';

import SortOrderProvider from '../grid/utils/SortOrderContext';

/**
 * UI For right panel of level 1 autoforms.
 *
 * @param {object} portfolioItem - Item in the portfolios screen.
 * @param {callback} onNodeRename - Optional. Callback to rename the portfolio item in the portfolios screen.
 * @param {callback} onNodeItemDelete - Optional. Callback to delete the portfolio item in the portfolios screen.
 * @param {callback} onNodeOwnershipPercentageUpdate - Optional. Callback to change the percent ownership
 * of the item in the portfolio in the portfolios screen.
 * @param {object} config - containing config for this and nested components
 */
const Level1Autoform = ({
    config = {},
    onNodeRename,
    onNodeItemDelete,
    portfolioItem,
    onNodeOwnershipPercentageUpdate,
    helpPageUrlItemTypeId = null,
}) => {
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const showAsGridHint = hierarchicalTables.level1[0]?.showAsGridHint;
    const level1TableId = hierarchicalTables.level1[0]?.id;
    const { helpAnchor } = useAutoformTableMetadata(level1TableId);

    if (!metadata || !hierarchicalTables.level1.length === 0) {
        return <MisconfigurationError />;
    }

    // Search only searches on dataTypeDescription === 'Systesm.String' columns, if there are no string columns then hide the search bar
    const searchableColumnFound =
        Array.isArray(metadata?.tables) &&
        metadata.tables.some(
            (table) =>
                Array.isArray(table.columns) &&
                table.columns.some((column) => column.dataTypeDescription === 'System.String')
        );

    return (
        <SortOrderProvider>
            {showAsGridHint && !config?.hideLevel1AutoformHeader && (
                <Flex pt={9} pl={6} align="center">
                    <Heading as="h2" variant="h2">
                        {metadata.formLabel}
                    </Heading>
                    {helpAnchor && <HelpButtonComponent helpAnchor={helpAnchor} itemTypeId={helpPageUrlItemTypeId} />}
                </Flex>
            )}
            {showAsGridHint ? (
                <Box px={6} pt={6}>
                    <AutoformTableContainer
                        schemaCode={metadata.schemaCode}
                        tableId={level1TableId}
                        showSearchbar={(config?.showSearchbar ?? true) && searchableColumnFound}
                        showHeader={false}
                        showDownloadUploadButton={true}
                        config={config}
                        rowDeleteConfirmation={DELETE_CONFIRMATION_TYPE.Rows}
                        helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                    />
                </Box>
            ) : (
                <ExpandableItemWrapper>
                    <ItemFormContainer onNodeRename={onNodeRename} config={config}>
                        <ItemTopHeaderContainer
                            tableId={level1TableId}
                            onNodeItemDelete={onNodeItemDelete}
                            portfolioItem={portfolioItem}
                            onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                            config={config}
                        />

                        <Box px={6}>
                            <GeneralValidationPanelContainer tableId={level1TableId} />

                            {config?.level1InputFields ?? (
                                <AutoformItemFields tableId={level1TableId} getInputFormName={getInputFormName} />
                            )}
                        </Box>
                    </ItemFormContainer>
                </ExpandableItemWrapper>
            )}
        </SortOrderProvider>
    );
};

export default Level1Autoform;
