import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { MONTHS, DAYS_OF_WEEK } from 'constants/shapeSet';

const useShapeSetTranslations = () => {
    const intl = useIntl();
    const translations = useMemo(
        () => ({
            month: intl.formatMessage({ id: 'common_month' }),
            dow: intl.formatMessage({ id: 'common_dow' }),
            day: intl.formatMessage({ id: 'common_day' }),
            days: {
                [DAYS_OF_WEEK.ALL]: intl.formatMessage({ id: 'common_all' }),
                [DAYS_OF_WEEK.SUN]: intl.formatMessage({ id: 'common_sunday_short' }),
                [DAYS_OF_WEEK.MON]: intl.formatMessage({ id: 'common_monday_short' }),
                [DAYS_OF_WEEK.TUE]: intl.formatMessage({ id: 'common_tuesday_short' }),
                [DAYS_OF_WEEK.WED]: intl.formatMessage({ id: 'common_wednesday_short' }),
                [DAYS_OF_WEEK.THU]: intl.formatMessage({ id: 'common_thursday_short' }),
                [DAYS_OF_WEEK.FRI]: intl.formatMessage({ id: 'common_friday_short' }),
                [DAYS_OF_WEEK.SAT]: intl.formatMessage({ id: 'common_saturday_short' }),
            },
            months: {
                [MONTHS.ALL]: intl.formatMessage({ id: 'common_all' }),
                [MONTHS.JAN]: intl.formatMessage({ id: 'common_january_short' }),
                [MONTHS.FEB]: intl.formatMessage({ id: 'common_february_short' }),
                [MONTHS.MAR]: intl.formatMessage({ id: 'common_march_short' }),
                [MONTHS.APR]: intl.formatMessage({ id: 'common_april_short' }),
                [MONTHS.MAY]: intl.formatMessage({ id: 'common_may_short' }),
                [MONTHS.JUN]: intl.formatMessage({ id: 'common_june_short' }),
                [MONTHS.JUL]: intl.formatMessage({ id: 'common_july_short' }),
                [MONTHS.AUG]: intl.formatMessage({ id: 'common_august_short' }),
                [MONTHS.SEP]: intl.formatMessage({ id: 'common_september_short' }),
                [MONTHS.OCT]: intl.formatMessage({ id: 'common_october_short' }),
                [MONTHS.NOV]: intl.formatMessage({ id: 'common_november_short' }),
                [MONTHS.DEC]: intl.formatMessage({ id: 'common_december_short' }),
            },
        }),
        [intl]
    );

    return translations;
};

export default useShapeSetTranslations;
