import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Flex, IconButton, useDisclosure, Tooltip, useColorMode } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import ConfirmationModal from '../modal/ConfirmationModal';

import { ReactComponent as ImportIcon } from '../../icons/mdi-import.svg';
import { ReactComponent as InfoIcon } from '../../icons/mdi-information-variant-box-outline.svg';
import { ReactComponent as StudyInfoIcon } from '../../icons/mdi-information-variant-box-outline.svg';
import { ReactComponent as LogsIcon } from '../../icons/mdi-note-text-outline.svg';
import { ReactComponent as JobsIcon } from '../../icons/mdi-open-in-new.svg';
import { ReactComponent as PortfolioIcon } from '../../icons/mdi-folder-eye-outline.svg';
import { ReactComponent as ParametersIcon } from '../../icons/mdi-cog-outline.svg';
import { ReactComponent as EditIcon } from '../../icons/mdi-square-edit-outline.svg';
import { ReactComponent as FailedIcon } from '../../icons/mdi-timer-off-outline.svg';
import { ReactComponent as NotFailedIcon } from '../../icons/mdi-timer-outline.svg';

import { SYSTEM_STUDY_ID } from 'constants/studies';

/**
 * cellRenderer: 'MoreActionsCell' used in several Jobs Grids and in Dashboard Studies Grid
 */
const MoreActionsCell = ({
    node,
    onImportClick,
    onJobInfoClick,
    onViewJobLogsClicked,
    onStudyInfoClick,
    onViewStudyJobsClicked,
    onViewPortfolioClicked,
    onViewJobParametersClicked,
    onChangeStudyStatusClicked,
    onViewWhyJobNotRunningClicked,
}) => {
    const confirmationImportJobsModal = useDisclosure();
    const intl = useIntl();
    const { colorMode } = useColorMode();

    // "Why job is not running" action button is enabled if the job status is submitted ( statusId > 1)
    // It will be disabled for: "Archived", "Completed Successfully", "Failure", "Stopped", "Running" [-4, -1, -2, -3, 0]
    const enableWhyJobNotRunningButton = node?.data?.statusId > 1;

    return (
        <>
            <StyledFlex
                className={colorMode === 'dark' ? 'custom-dark-mode' : ''}
                justifyContent="center"
                alignItems="center"
                w="100%"
            >
                {onViewJobLogsClicked && (
                    <Tooltip
                        label={intl.formatMessage({
                            id: 'admin_express_jobs_logs',
                        })}
                    >
                        <IconButton
                            icon={<LogsIcon />}
                            variant="circular-icon"
                            textTransform="capitalize"
                            onClick={() => onViewJobLogsClicked(node)}
                            size="xs"
                        />
                    </Tooltip>
                )}
                {onViewStudyJobsClicked && (
                    <Tooltip
                        label={intl.formatMessage({
                            id: 'study_tracker_view_study_jobs_btn',
                        })}
                    >
                        <IconButton
                            icon={<JobsIcon />}
                            variant="circular-icon"
                            textTransform="capitalize"
                            onClick={() => onViewStudyJobsClicked(node)}
                            size="xs"
                        />
                    </Tooltip>
                )}
                {onJobInfoClick && (
                    <Tooltip
                        label={intl.formatMessage({
                            id: 'admin_express_jobs_info',
                        })}
                    >
                        <IconButton
                            icon={<InfoIcon />}
                            variant="circular-icon"
                            textTransform="capitalize"
                            onClick={() => onJobInfoClick(node)}
                            size="xs"
                        />
                    </Tooltip>
                )}
                {onStudyInfoClick && (
                    <Tooltip
                        label={intl.formatMessage({
                            id: 'study_tracker_info_column_btn',
                        })}
                    >
                        <IconButton
                            icon={<StudyInfoIcon />}
                            variant="circular-icon"
                            textTransform="capitalize"
                            onClick={() => onStudyInfoClick(node)}
                            size="xs"
                            isDisabled={!node?.data?.studyId}
                        />
                    </Tooltip>
                )}
                {onViewPortfolioClicked && (
                    <Tooltip
                        label={intl.formatMessage({
                            id: 'study_tracker_view_study_portfolio_btn',
                        })}
                    >
                        <IconButton
                            icon={<PortfolioIcon />}
                            variant="circular-icon"
                            textTransform="capitalize"
                            onClick={() => onViewPortfolioClicked(node)}
                            size="xs"
                            isDisabled={!node?.data?.portfolioId}
                        />
                    </Tooltip>
                )}
                {onViewJobParametersClicked && (
                    <Tooltip
                        label={intl.formatMessage({
                            id: 'job_parameters_btn',
                        })}
                    >
                        <IconButton
                            icon={<ParametersIcon />}
                            variant="circular-icon"
                            textTransform="capitalize"
                            onClick={() => onViewJobParametersClicked(node)}
                            size="xs"
                        />
                    </Tooltip>
                )}
                {onViewWhyJobNotRunningClicked && (
                    <Tooltip
                        label={intl.formatMessage({
                            id: 'job_not_running_btn',
                        })}
                    >
                        <IconButton
                            icon={enableWhyJobNotRunningButton ? <FailedIcon /> : <NotFailedIcon />}
                            variant="circular-icon"
                            textTransform="capitalize"
                            onClick={() => onViewWhyJobNotRunningClicked(node)}
                            size="xs"
                            isDisabled={!enableWhyJobNotRunningButton}
                        />
                    </Tooltip>
                )}
                {onImportClick && (
                    <Tooltip
                        label={intl.formatMessage({
                            id: 'admin_express_jobs_import',
                        })}
                    >
                        <IconButton
                            icon={<ImportIcon />}
                            variant="circular-icon"
                            textTransform="capitalize"
                            onClick={confirmationImportJobsModal.onOpen}
                            size="xs"
                        />
                    </Tooltip>
                )}
                {onChangeStudyStatusClicked && (
                    <Tooltip
                        label={intl.formatMessage({
                            id: 'study_change_status_button',
                        })}
                    >
                        <IconButton
                            icon={<EditIcon />}
                            variant="circular-icon"
                            textTransform="capitalize"
                            onClick={() => onChangeStudyStatusClicked(node)}
                            size="xs"
                            isDisabled={
                                !(
                                    node?.data?.studyId !== null &&
                                    node?.data?.studyId !== SYSTEM_STUDY_ID &&
                                    node?.data?.studyId > 0
                                )
                            }
                        />
                    </Tooltip>
                )}
            </StyledFlex>

            {confirmationImportJobsModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={confirmationImportJobsModal.onClose}
                    header={<FormattedMessage id="admin_express_jobs_import_title" values={{ item: 'Basis' }} />}
                    hasExtraStep
                    content={<FormattedMessage id="admin_express_jobs_import_text" />}
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_confirm" />
                        </Box>
                    }
                    onConfirm={() => {
                        confirmationImportJobsModal.onClose();
                        onImportClick(node);
                    }}
                    returnFocusOnClose={false}
                />
            )}
        </>
    );
};

const StyledFlex = styled(Flex)`
    &.custom-dark-mode {
        svg {
            path {
                fill: var(--chakra-colors-gray-400);
            }
        }
    }
`;

export default MoreActionsCell;
