import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
    Collapse,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import { Formik, Form } from 'formik';

import { getElectricNodeListData } from '../../services/traverseApi';

import {
    ISO_RTO_OPTIONS,
    DARTM_OPTIONS,
    DARTM_OPTIONS_CAISO,
    CAISO_OPTION,
    TRAVERSE_INPUT_TABLE_DESCRIPTION,
    POWERSIMM_INPUT_TABLE_DESCRIPTION,
    isSubmitDisabled,
} from '../../services/utils';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import { getSpotPriceDropdownOptions, createSpotPrice } from '../../services/spotPrice';

import ComboboxFormikField from '../forms/ComboboxFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';
import InputFormikField from '../forms/InputFormikField';

import LoadingModal from '../modal/LoadingModal';

import SimpleGridSkeleton from '../../components/utils/SimpleGridSkeleton';
import Error from '../../components/utils/Error';

import SpotPriceLookupValuesField from '../spotPrice/SpotPriceLookupValuesField';

const AddSpotPriceModal = ({ basisId, ...props }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();
    const formRef = useRef();

    const [spotPriceDropdownOption, setSpotPriceDropdownOptions] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [showTraverseOptions, setShowTraverseOptions] = useState(false);
    const [electricNodeList, setElectricNodeList] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const TRAVERSE_INPUT_TABLE_ID = useMemo(() => {
        return spotPriceDropdownOption.data?.inputTableMaps.find(
            (inputTable) => inputTable.description === TRAVERSE_INPUT_TABLE_DESCRIPTION
        )?.id;
    }, [spotPriceDropdownOption.data?.inputTableMaps]);

    const POWERSIMM_INPUT_TABLE_ID = useMemo(() => {
        return spotPriceDropdownOption.data?.inputTableMaps.find(
            (inputTable) => inputTable.description === POWERSIMM_INPUT_TABLE_DESCRIPTION
        )?.id;
    }, [spotPriceDropdownOption.data?.inputTableMaps]);

    const fetchSpotPriceDropdownOptions = useCallback(() => {
        return getSpotPriceDropdownOptions()
            .then((data) => setSpotPriceDropdownOptions((prev) => ({ ...prev, error: false, data })))
            .catch(() => setSpotPriceDropdownOptions((prev) => ({ ...prev, error: true })))
            .finally(() => setSpotPriceDropdownOptions((prev) => ({ ...prev, loading: false })));
    }, []);

    useEffect(() => {
        fetchSpotPriceDropdownOptions();
    }, [fetchSpotPriceDropdownOptions]);

    const formLabels = {
        name: intl.formatMessage({ id: 'spot_price_name' }),
        inputTable: intl.formatMessage({ id: 'spot_price_input_table' }),
        isoRto: intl.formatMessage({ id: 'spot_price_iso_rto' }),
        electricNode: intl.formatMessage({ id: 'spot_price_electric_node' }),
        dartm: intl.formatMessage({ id: 'spot_price_dartm' }),
        lookupValues: intl.formatMessage({ id: 'spot_price_lookup_values' }),
        commodity: intl.formatMessage({ id: 'spot_price_commodity' }),
        moduleProcess: intl.formatMessage({ id: 'spot_price_module_process' }),
        minSimPrice: intl.formatMessage({ id: 'spot_price_min_sim_price' }),
        maxSimPrice: intl.formatMessage({ id: 'spot_price_max_sim_price' }),
        weightSchema: intl.formatMessage({ id: 'spot_price_weight_schema' }),
        onPeakPeriod: intl.formatMessage({ id: 'spot_price_on_peak_period' }),
        offPeakPeriod: intl.formatMessage({ id: 'spot_price_off_peak_period' }),
        atcPeakPeriod: intl.formatMessage({ id: 'spot_price_atc_peak_period' }),
    };

    const initialFormValues = {
        name: '',
        inputTable: '',
        lookupValues: '',
        commodity: '',
        moduleProcess: '',
        minSimPrice: '',
        maxSimPrice: '',
        weightSchema: '',
        onPeakPeriod: '',
        offPeakPeriod: '',
        atcPeakPeriod: '',
    };

    const onFormSubmit = async ({
        name,
        commodity,
        moduleProcess,
        minSimPrice,
        maxSimPrice,
        weightSchema,
        inputTable,
        lookupValues,
        onPeakPeriod,
        offPeakPeriod,
        atcPeakPeriod,
    }) => {
        const successMessage = intl.formatMessage({ id: 'spot_price_successful_creation' });

        const { data } = await createSpotPrice({
            commodityId: commodity,
            description: name,
            moduleProcessId: moduleProcess,
            minPrice: Number(minSimPrice),
            maxPrice: Number(maxSimPrice),
            spotPriceWeightId: weightSchema,
            inputTableMapId: inputTable || null,
            lookupIdValues: lookupValues || null,
            onPeakPeakPeriodId: onPeakPeriod,
            offPeakPeakPeriodId: offPeakPeriod,
            atcPeakPeriodId: atcPeakPeriod,
        });

        const search = new URLSearchParams();
        search.append('basisId', basisId);
        search.append('mode', 'new');
        toast(successMessage);

        historyPush({
            pathname: `/spot-price/${data.spotPriceId}`,
            search,
        });
    };

    const validateItemName = (value) => {
        let error;
        const trimmed = value.trim();

        if (!trimmed) {
            error = intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Name' });
        }

        if (trimmed.length > 50) {
            error = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Name', lengthRule: '50 characters or less' }
            );
        }
        return error;
    };

    const validateRequiredField = (value, label) => {
        let error;
        if (value === '') {
            error = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }
        return error;
    };

    const validateSimPrice = (field, label, minSimPrice, maxSimPrice) => {
        let error;
        if ((field === 'minSimPrice' && minSimPrice === '') || (field === 'maxSimPrice' && maxSimPrice === '')) {
            error = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        } else if (field === 'minSimPrice' && maxSimPrice && Number(minSimPrice) >= Number(maxSimPrice)) {
            formRef.current.setFieldTouched('maxSimPrice', true, false);
            error = intl.formatMessage({ id: 'spot_price_min_sim_price_validation' });
        } else if (field === 'maxSimPrice' && minSimPrice && Number(maxSimPrice) <= Number(minSimPrice)) {
            formRef.current.setFieldTouched('minSimPrice', true, false);
            error = intl.formatMessage({ id: 'spot_price_max_sim_price_validation' });
        }
        return error;
    };

    const validateLookupValues = (inputTable, lookupValues) => {
        let error;

        if (inputTable && inputTable !== POWERSIMM_INPUT_TABLE_ID) {
            error = validateRequiredField(lookupValues, formLabels.lookupValues);
        }

        return error;
    };

    const validateForm = (values) => {
        const name = validateItemName(values.name);
        const isoRto = validateRequiredField(values.isoRto, formLabels.isoRto);
        const electricNode = validateRequiredField(values.electricNode, formLabels.electricNode);
        const dartm = validateRequiredField(values.dartm, formLabels.dartm);

        const minSimPrice = validateSimPrice(
            'minSimPrice',
            formLabels.minSimPrice,
            values.minSimPrice,
            values.maxSimPrice
        );

        const maxSimPrice = validateSimPrice(
            'maxSimPrice',
            formLabels.maxSimPrice,
            values.minSimPrice,
            values.maxSimPrice
        );

        const commodity = validateRequiredField(values.commodity, formLabels.commodity);
        const lookupValues = validateLookupValues(values.inputTable, values.lookupValues);
        const moduleProcess = validateRequiredField(values.moduleProcess, formLabels.moduleProcess);
        const weightSchema = validateRequiredField(values.weightSchema, formLabels.weightSchema);
        const onPeakPeriod = validateRequiredField(values.onPeakPeriod, formLabels.onPeakPeriod);
        const offPeakPeriod = validateRequiredField(values.offPeakPeriod, formLabels.offPeakPeriod);
        const atcPeakPeriod = validateRequiredField(values.atcPeakPeriod, formLabels.atcPeakPeriod);

        return {
            ...(name ? { name } : null),
            ...(isoRto ? { isoRto } : null),
            ...(electricNode ? { electricNode } : null),
            ...(dartm ? { dartm } : null),
            ...(minSimPrice ? { minSimPrice } : null),
            ...(maxSimPrice ? { maxSimPrice } : null),
            ...(commodity ? { commodity } : null),
            ...(lookupValues ? { lookupValues } : null),
            ...(moduleProcess ? { moduleProcess } : null),
            ...(weightSchema ? { weightSchema } : null),
            ...(onPeakPeriod ? { onPeakPeriod } : null),
            ...(offPeakPeriod ? { offPeakPeriod } : null),
            ...(atcPeakPeriod ? { atcPeakPeriod } : null),
        };
    };

    const onInputTableChange = (value) => {
        if (value === TRAVERSE_INPUT_TABLE_ID) {
            setShowTraverseOptions(true);
            formRef.current.setValues({
                ...formRef.current.values,
                inputTable: value,
                lookupValues: '',
                isoRto: '',
                electricNode: '',
                dartm: '',
            });
        } else {
            if (showTraverseOptions) {
                setShowTraverseOptions(false);
                const { isoRto, electricNode, dartm, ...rest } = formRef.current.values;
                formRef.current.setValues({ ...rest, inputTable: value, lookupValues: '' });
            }
        }
    };

    const onIsoRtoOptionChange = async (value) => {
        setIsLoadingData(true);
        const electricNodes = await getElectricNodeListData(value);
        setElectricNodeList(electricNodes);
        formRef.current.setValues({
            ...formRef.current.values,
            electricNode: '',
            lookupValues: '',
            dartm: '',
        });
        setIsLoadingData(false);
    };

    return (
        <>
            <Modal {...props}>
                <ModalOverlay />

                <ModalContent pt={3}>
                    <ModalHeader pr={12}>
                        <Heading as="h3" variant="h3" textTransform="capitalize">
                            <FormattedMessage id="basis_configurator_add_new_spot_price" />
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                    <Formik
                        innerRef={formRef}
                        enableReinitialize
                        initialValues={initialFormValues}
                        onSubmit={onFormSubmit}
                        validate={validateForm}
                    >
                        {({ isSubmitting, errors, touched, values }) => (
                            <Form>
                                <ModalBody borderBottom="1px" borderColor="border-secondary">
                                    {spotPriceDropdownOption.loading ? (
                                        <SimpleGridSkeleton rows={9} cols={1} />
                                    ) : spotPriceDropdownOption.error ? (
                                        <Box m={6}>
                                            <Error
                                                primaryId="common_error"
                                                secondaryId="common_loading_error"
                                                additionalText="spot price data"
                                            />
                                        </Box>
                                    ) : (
                                        <>
                                            <InputFormikField
                                                name="name"
                                                type="text"
                                                label={formLabels.name}
                                                isRequired
                                                info={<FormattedMessage id="spot_price_name_info" />}
                                            />

                                            <ComboboxFormikField
                                                id="inputTable"
                                                name="inputTable"
                                                label={formLabels.inputTable}
                                                options={spotPriceDropdownOption.data.inputTableMaps}
                                                onChange={(value) => onInputTableChange(value)}
                                                labelKey="description"
                                                valueKey="id"
                                                mt={3}
                                            />

                                            <CustomCollapse in={showTraverseOptions} animateOpacity>
                                                <ComboboxFormikField
                                                    id="isoRto"
                                                    name="isoRto"
                                                    label={formLabels.isoRto}
                                                    options={ISO_RTO_OPTIONS}
                                                    valueKey="id"
                                                    labelKey="description"
                                                    onChange={onIsoRtoOptionChange}
                                                    isRequired
                                                    mt={3}
                                                />

                                                <SimpleGrid columns={2} mt={5} spacingX={3}>
                                                    <ComboboxFormikField
                                                        id="electricNode"
                                                        name="electricNode"
                                                        label={formLabels.electricNode}
                                                        options={electricNodeList}
                                                        isRequired
                                                        isDisabled={!values.isoRto}
                                                    />

                                                    <ComboboxFormikField
                                                        id="dartm"
                                                        name="dartm"
                                                        label={formLabels.dartm}
                                                        options={
                                                            values.isoRto === CAISO_OPTION
                                                                ? DARTM_OPTIONS_CAISO
                                                                : DARTM_OPTIONS
                                                        }
                                                        labelKey="description"
                                                        valueKey="id"
                                                        isRequired
                                                        isDisabled={!values.isoRto}
                                                    />
                                                </SimpleGrid>
                                            </CustomCollapse>

                                            {showTraverseOptions ? (
                                                <SpotPriceLookupValuesField
                                                    name="lookupValues"
                                                    label={formLabels.lookupValues}
                                                    mt={3}
                                                    isDisabled
                                                />
                                            ) : (
                                                <InputFormikField
                                                    name="lookupValues"
                                                    type="text"
                                                    label={formLabels.lookupValues}
                                                    mt={3}
                                                    isRequired={
                                                        values.inputTable &&
                                                        values.inputTable !== POWERSIMM_INPUT_TABLE_ID
                                                    }
                                                />
                                            )}

                                            <SimpleGrid columns={2} mt={5} spacingX={3} spacingY={5}>
                                                <ComboboxFormikField
                                                    id="commodity"
                                                    name="commodity"
                                                    label={formLabels.commodity}
                                                    options={spotPriceDropdownOption.data.commodities}
                                                    labelKey="description"
                                                    valueKey="id"
                                                    isRequired
                                                />

                                                <ComboboxFormikField
                                                    id="moduleProcess"
                                                    name="moduleProcess"
                                                    label={formLabels.moduleProcess}
                                                    options={spotPriceDropdownOption.data.moduleProcesses}
                                                    labelKey="description"
                                                    valueKey="id"
                                                    isRequired
                                                />

                                                <NumberInputFormikField
                                                    id="minSimPrice"
                                                    name="minSimPrice"
                                                    label={formLabels.minSimPrice}
                                                    isRequired
                                                />

                                                <NumberInputFormikField
                                                    id="maxSimPrice"
                                                    name="maxSimPrice"
                                                    label={formLabels.maxSimPrice}
                                                    isRequired
                                                />

                                                <ComboboxFormikField
                                                    id="weightSchema"
                                                    name="weightSchema"
                                                    label={formLabels.weightSchema}
                                                    options={spotPriceDropdownOption.data.modelWeights}
                                                    labelKey="description"
                                                    valueKey="id"
                                                    isRequired
                                                />

                                                <ComboboxFormikField
                                                    id="onPeakPeriod"
                                                    name="onPeakPeriod"
                                                    label={formLabels.onPeakPeriod}
                                                    options={spotPriceDropdownOption.data.peakPeriods}
                                                    labelKey="description"
                                                    valueKey="id"
                                                    isRequired
                                                />

                                                <ComboboxFormikField
                                                    id="offPeakPeriod"
                                                    name="offPeakPeriod"
                                                    label={formLabels.offPeakPeriod}
                                                    options={spotPriceDropdownOption.data.peakPeriods}
                                                    labelKey="description"
                                                    valueKey="id"
                                                    isRequired
                                                />

                                                <ComboboxFormikField
                                                    id="atcPeakPeriod"
                                                    name="atcPeakPeriod"
                                                    label={formLabels.atcPeakPeriod}
                                                    options={spotPriceDropdownOption.data.peakPeriods}
                                                    labelKey="description"
                                                    valueKey="id"
                                                    isRequired
                                                />
                                            </SimpleGrid>
                                        </>
                                    )}
                                </ModalBody>

                                <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                    <Box ml="auto">
                                        <Button variant="secondary" onClick={props.onClose}>
                                            <Box as="span" textTransform="capitalize">
                                                <FormattedMessage id="common_cancel" />
                                            </Box>
                                        </Button>

                                        <Button
                                            type="submit"
                                            isDisabled={isSubmitDisabled({ errors, touched })}
                                            isLoading={isSubmitting}
                                            ml={3}
                                            textTransform="capitalize"
                                        >
                                            <FormattedMessage id="common_continue" />
                                        </Button>
                                    </Box>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>

            {isLoadingData && <LoadingModal header={intl.formatMessage({ id: 'common_loading' })} isOpen />}
        </>
    );
};

const CustomCollapse = styled(Collapse)`
    overflow: visible !important;
`;

export default AddSpotPriceModal;
