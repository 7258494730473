import { createSlice } from '@reduxjs/toolkit';
import { getNavigationConfig } from './navigationApi';

const initialState = {
    config: null,
    isLoading: false,
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: {
        [getNavigationConfig.fulfilled]: (state, action) => {
            state.config = action.payload;
        },
        [getNavigationConfig.rejected]: (state) => {
            state.config = null;
        },
    },
});

export const { setLoading } = navigationSlice.actions;
export default navigationSlice.reducer;
