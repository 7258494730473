import { SimpleGrid, Skeleton } from '@chakra-ui/react';

const RibbonFiltersSkeleton = () => {
    return (
        <SimpleGrid columns={{ xl: 5 }} spacingX={6} spacingY={4} w="100%" alignItems="end">
            <Skeleton height="40px" />
            <Skeleton height="40px" />
            <Skeleton height="40px" maxW={100} />
        </SimpleGrid>
    );
};

export default RibbonFiltersSkeleton;
