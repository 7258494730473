import React from 'react';
import CustomNavLink from '../../utils/CustomNavLink';
import { Button } from '@chakra-ui/react';

const NavigationButton = ({ config }) => {
    return (
        <Button as={CustomNavLink} exact={config.url === '/'} to={config.url}>
            {config.label}
        </Button>
    );
};

export default NavigationButton;
