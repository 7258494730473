import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BatteryIcon } from '../icons/item-icons/battery-secondary.svg';
import { ReactComponent as CalendarIcon } from '../icons/calendar.svg';
import Autoform from './Autoform';
import TabularPage from '../components/tabs/TabularPage';
import BiddingStrategies from './BiddingStrategies';
import { BiddingStrategiesParam } from './BiddingStrategies';

const tabsRoutes = [
    {
        // First tab is not an autoform
        // It is a custom component and might have selected item <Route path={`${path}/:strategyId`}>
        name: <FormattedMessage id="batteries_admin_bidding_strategies" />,
        relativePath: BiddingStrategiesParam,
        component: <BiddingStrategies />,
    },
    {
        name: <FormattedMessage id="batteries_admin_periods" />,
        relativePath: 'PSBATADMINPERIODS',
        itemIcon: CalendarIcon,
        autoformIcon: CalendarIcon,
    },
    {
        name: <FormattedMessage id="batteries_admin_products" />,
        relativePath: 'PSBATADMINPRODUCTS',
        itemIcon: BatteryIcon,
        autoformIcon: BatteryIcon,
    },
    {
        name: <FormattedMessage id="batteries_admin_day_types" />,
        relativePath: 'PSBATADMINDAYTYPES',
        itemIcon: BatteryIcon,
        autoformIcon: BatteryIcon,
    },
];

const BiddingStrategiesAdminPage = () => {
    return (
        <TabularPage
            routes={tabsRoutes}
            // custom render function to be used for tabs component display:
            renderRoutes={({ path, routes, selectedTab }) => {
                const routePath = `${path}/:autoformId`;
                const routeTab = routes[selectedTab];

                return (
                    <Route path={routePath}>
                        {routeTab?.component ? (
                            // If we have a custom component - then render it
                            routeTab.component
                        ) : (
                            // Otherwise render standard autoform with icons
                            <Autoform
                                hasSecondaryMenu={true}
                                config={{
                                    itemIcon: routeTab?.itemIcon,
                                    autoformIcon: routeTab?.autoformIcon,
                                }}
                            />
                        )}
                    </Route>
                );
            }}
        />
    );
};

export default BiddingStrategiesAdminPage;
