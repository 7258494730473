import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Heading, Box, Flex, Text } from '@chakra-ui/react';

import Popover from '../utils/Popover';
import CustomIconButton from '../utils/CustomIconButton';
import { getPowersimmLayerItemsInfo } from '../../services/maps';

import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as ErrorIcon } from '../../icons/warning.svg';

const ValidateCustomLayer = ({ map, layers }) => {
    const [powerSimmLayerData, setPowerSimmLayerData] = useState(null);
    const [powerSimmLayerDataError, setPowerSimmLayerDataError] = useState(null);

    let powerSimmLayer = null;
    const powerSimmLayers = layers?.filter((l) => l.isPowerSimmLayer);
    if (Array.isArray(powerSimmLayers) && powerSimmLayers.length > 0) {
        powerSimmLayer = powerSimmLayers[0];
    }

    const isoLayers = layers?.filter((l) => l.isIsoLayer);

    useEffect(() => {
        setPowerSimmLayerData(null);
        setPowerSimmLayerDataError(null);

        getPowersimmLayerItemsInfo()
            .then((result) => {
                if (Array.isArray(result)) {
                    setPowerSimmLayerData(`${result.length} Items have their LAT and LONG recorded`);
                } else {
                    setPowerSimmLayerData(result);
                }
            })
            .catch((exc) => {
                setPowerSimmLayerDataError(exc.response?.data?.error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layers]);

    return (
        <Box mt={6}>
            <Flex align="center" justify="space-between">
                <Flex align="center">
                    <Heading as="h4" variant="h4" mr={2}>
                        <FormattedMessage id="map_drawer_feature_layer" />
                    </Heading>
                    <Popover
                        placement="top"
                        trigger={<CustomIconButton mt="0px !important" variant="circular-icon" icon={<InfoIcon />} />}
                    >
                        <FormattedMessage id="map_drawer_feature_layer_info" />
                    </Popover>
                </Flex>
                {(powerSimmLayerDataError || !powerSimmLayer?.url) && <ErrorIcon />}
            </Flex>
            <Box>
                <Text size="md" color="gray.900" pl={1} pt={1}>
                    {powerSimmLayer?.title}
                </Text>
                {powerSimmLayer?.url ? (
                    <Text size="sm" color="gray.600" pl={1} pt={1}>
                        {`URL: ${powerSimmLayer.url}`}
                    </Text>
                ) : (
                    <Text size="sm" color="orange.600" pl={1} pt={1}>
                        {'Custom PowerSIMM map layer not found'}
                    </Text>
                )}
                {powerSimmLayer?.loadError && (
                    <Text size="sm" color="orange.600" pl={1} pt={1}>
                        {`Load error: ${powerSimmLayer?.loadError.message || powerSimmLayer?.loadError.name}`}
                    </Text>
                )}
                <Text size="sm" color="gray.600" pl={1} pt={1}>
                    {powerSimmLayerData}
                </Text>
                {powerSimmLayerDataError && (
                    <Text size="sm" color="orange.600" pl={1} pt={1}>
                        {powerSimmLayerDataError}
                    </Text>
                )}
                {Array.isArray(isoLayers) &&
                    isoLayers.map((l) => {
                        return (
                            l?.loadError && (
                                <Text size="sm" color="orange.600" pl={1} pt={1} key={l.id}>
                                    {`${l.title} ISO Layer load error: ${l?.loadError.message || l?.loadError.name}`}
                                </Text>
                            )
                        );
                    })}
                <Text size="sm" color="gray.600" pl={1} pt={1}>
                    {`Web Map Id: ${process.env.REACT_APP_ARCGIS_PORTAL_ID}`}
                </Text>
                {map?.loadError && (
                    <Text size="sm" color="orange.600" pl={1} pt={1} key={map.id}>
                        {`Map load error: ${map?.loadError.message || map?.loadError.name}`}
                    </Text>
                )}
            </Box>
        </Box>
    );
};

export default ValidateCustomLayer;
