import { Drawer, DrawerBody, DrawerHeader, DrawerContent, DrawerCloseButton, Heading } from '@chakra-ui/react';

const SideDrawer = ({ size = 'md', variant, header, content, onConfirm, ...rest }) => {
    return (
        <Drawer size={size} closeOnEsc={false} closeOnOverlayClick={false} variant={variant} {...rest}>
            <DrawerContent pt={8}>
                <DrawerCloseButton mt={8} mr={3} />

                <DrawerHeader px={7} mb={4} pr={12}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        {header}
                    </Heading>
                </DrawerHeader>

                <DrawerBody px={7}>{content}</DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default SideDrawer;
