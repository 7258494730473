import { useMemo } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Autoform from './Autoform';
import TabularPage from '../components/tabs/TabularPage';

const FuelsAndEmissionsPage = () => {
    const routes = useMemo(
        () => [
            {
                name: <FormattedMessage id="fuels_and_emissions_blend_definitions" />,
                relativePath: 'PSBLENDS',
            },
            {
                name: <FormattedMessage id="fuels_and_emissions_rates" />,
                relativePath: 'PSFUELEMISSIONRATES',
            },
        ],
        []
    );

    return (
        <TabularPage
            routes={routes}
            renderRoutes={({ path }) => (
                <Route path={`${path}/:autoformId`}>
                    <Autoform hasSecondaryMenu={true} />
                </Route>
            )}
        />
    );
};

export default FuelsAndEmissionsPage;
