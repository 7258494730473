import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import CustomNavLink from '../../utils/CustomNavLink';
import Logo from '../../utils/Logo';

import { ReactComponent as HamburgerNavIcon } from '../../../icons/hamburger-nav.svg';

const NavigationPanel = ({ onHamburgerClick, onLogoClick, isActive = false }) => (
    <Flex alignItems={'center'}>
        <Box as={HamburgerNavIcon} onClick={onHamburgerClick} color={isActive ? 'blue.500' : 'gray.400'} />

        <CenteredNavLink to="/" onClick={onLogoClick}>
            <Logo w="150px" h="44px" />
        </CenteredNavLink>
    </Flex>
);

const CenteredNavLink = styled(CustomNavLink)`
    margin-left: auto;
    margin-right: auto;
`;

export default NavigationPanel;
