import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import SecondaryButton from '../utils/SecondaryButton';
import { useDisclosure } from '@chakra-ui/react';

import { ReactComponent as UploadIcon } from '../../icons/upload.svg';

import UploadModal from '../utils/UploadModal';
/**
 *  Button to upload and import data for multiple tables.
 *
 * @param {object[]} tablesMetadata - Table metadata for the tables that will
 * be downloaded through this component.
 * @param {string} parentTableId - Id of the parent table for the tables
 * being downloaded.
 * @param {callback} onAfterUploadChange - Called on successful import.
 * @param {object} config - flexible config for this and nested components
 * @param rest
 */
const AutoformUploadButton = ({
    itemId,
    tablesMetadata,
    parentTableId,
    onAfterUploadChange,
    config,
    subschemaCode,
    parameters,
    ...rest
}) => {
    const uploadModal = useDisclosure();

    const [changesQueued, setChangesQueued] = useState(false);

    const closeModal = () => {
        if (changesQueued && onAfterUploadChange) {
            onAfterUploadChange();
        }
        uploadModal.onClose();
    };

    return (
        <>
            <SecondaryButton
                leftIcon={<UploadIcon />}
                size="sm"
                type="button"
                variant="secondary"
                onClick={uploadModal.onOpen}
                {...rest}
            >
                <FormattedMessage id="common_upload_btn_tooltip_and_label" />
            </SecondaryButton>
            {uploadModal.isOpen && (
                <UploadModal
                    onClose={closeModal}
                    tablesMetadata={tablesMetadata}
                    parentTableId={parentTableId}
                    onAfterUploadChange={() => setChangesQueued(true)}
                    itemId={itemId}
                    subschemaCode={subschemaCode}
                    parameters={parameters}
                    config={config}
                />
            )}
        </>
    );
};

export default AutoformUploadButton;
