import { useState, useEffect, useMemo, useCallback } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Skeleton, Text, Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import useCommonToast from '../hooks/useCommonToast';
import useHistoryPush from '../hooks/useHistoryPush';

import { getBasisList, copyBasis, getBasisDropdownOptions } from '../services/basis';
import { addItemNextToParent } from '../services/items';

import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as TrendIcon } from '../icons/trend.svg';

import basisPlaceholder from '../images/basis-unselected.png';

import LayoutSidebar from '../components/layout/LayoutSidebar';
import LayoutWrapper from '../components/layout/LayoutWrapper';
import WrapperEmptyState from '../components/layout/WrapperEmptyState';
import ItemListWrapper from '../components/layout/ItemListWrapper';
import OptimizedItemLinkWrapper from '../components/utils/OptimizedItemLinkWrapper';

import AddItemButton from '../components/utils/AddItemButton';
import VirtualisedItemList from '../components/utils/VirtualisedItemList';
import SortOrderProvider from '../components/grid/utils/SortOrderContext';

import Basis from '../components/basis/Basis';

const BasisList = () => {
    const intl = useIntl();
    const { path, url } = useRouteMatch();
    const historyPush = useHistoryPush();
    const { toast } = useCommonToast();

    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [basisList, setBasisList] = useState([]);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedBasis, setSelectedBasis] = useState(null);
    const { isSidebarVisible } = useSelector((state) => state.helpers);
    const [dropdownOptions, setDropdownOptions] = useState({});

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        const fetchBasisList = getBasisList();
        const fetchDropdownOptions = getBasisDropdownOptions();

        return Promise.all([fetchBasisList, fetchDropdownOptions])
            .then((data) => {
                setBasisList(data[0]);
                setDropdownOptions(data[1]);
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        const term = search.trimStart().toLowerCase();
        const filteredData = basisList.filter((o) => o.description?.toLowerCase().includes(term));
        setSearchResults(filteredData);
    }, [basisList, search]);

    const itemIndex = searchResults.findIndex((item) => item.id === selectedBasis?.id);

    const noSearchResults = useMemo(() => {
        return search.trim().length > 0 && searchResults.length === 0;
    }, [search, searchResults]);

    const copySingleBasis = async ({ id }) => {
        toast({ status: 'info', message: intl.formatMessage({ id: 'basis_copy_btn_progress_message' }) });

        const temporaryNode = await copyBasis(id);

        temporaryNode.id = temporaryNode.basisId;
        setBasisList((prev) => addItemNextToParent(prev, id, temporaryNode));

        historyPush(`${url}/${temporaryNode.basisId}`);
    };

    const onBasisEdit = (selectedBasis) => {
        const updatedItems = basisList.map((basis) => {
            if (basis.id === selectedBasis.id) {
                return { ...selectedBasis };
            }
            return basis;
        });
        setBasisList(updatedItems);
    };

    const onBasisDelete = (selectedBasis) => {
        const updatedBasisList = basisList.filter((basis) => basis.id !== selectedBasis.basisId);
        setBasisList(updatedBasisList);
    };

    const onItemClick = useCallback((item) => {
        if (item) {
            setSelectedBasis(item);
        }
    }, []);

    const onSearchChange = (value) => {
        setSearch(value);
    };

    return (
        <LayoutWrapper
            sidebar={
                isSidebarVisible && (
                    <LayoutSidebar
                        itemType={'basis'}
                        selected={selectedBasis}
                        titleId={'basis-title'}
                        titleMessage={intl.formatMessage({ id: 'basis_title' })}
                        accordionPlaceholderId={'basis_select_item'}
                        searchPlaceholderId={'basis_search'}
                        searchCallback={onSearchChange}
                    >
                        <ItemListWrapper>
                            <Box px={6} pb={2}>
                                <AddItemButton
                                    leftIcon={<AddIcon />}
                                    onClick={() => historyPush('/basis-configurator')}
                                >
                                    <Text size="sm" color="gray.900" pl={1} pt={1}>
                                        <FormattedMessage id="basis_add_new_item" />
                                    </Text>
                                </AddItemButton>

                                {isLoading ? (
                                    <Skeleton>
                                        <Box h="34px">
                                            <FormattedMessage id="common_loading" />
                                        </Box>
                                    </Skeleton>
                                ) : loadingError ? (
                                    <Alert status="error">
                                        <AlertIcon />

                                        <Box>
                                            <AlertTitle textTransform="capitalize">
                                                <FormattedMessage id="common_error" />
                                            </AlertTitle>

                                            <AlertDescription>
                                                <FormattedMessage
                                                    id="common_loading_error"
                                                    values={{ items: 'basis' }}
                                                />
                                            </AlertDescription>
                                        </Box>
                                    </Alert>
                                ) : noSearchResults ? (
                                    <Alert status="info">
                                        <AlertIcon />

                                        <FormattedMessage id="common_no_search_results" />
                                    </Alert>
                                ) : null}
                            </Box>
                            {!noSearchResults && !loadingError && !isLoading && (
                                <VirtualisedItemList
                                    items={searchResults}
                                    onCopy={copySingleBasis}
                                    itemIndex={itemIndex}
                                    renderItems={(basis) => (
                                        <OptimizedItemLinkWrapper
                                            item={basis}
                                            onClick={onItemClick}
                                            url={url}
                                            icon={TrendIcon}
                                        />
                                    )}
                                />
                            )}
                        </ItemListWrapper>
                    </LayoutSidebar>
                )
            }
            content={
                <Switch>
                    <Route exact path={path}>
                        <WrapperEmptyState imgSrc={basisPlaceholder} page="Basis" noun="properties" />
                    </Route>

                    <Route path={`${path}/:basisId`}>
                        {({ match }) => (
                            <SortOrderProvider>
                                <Basis
                                    key={match.params.basisId}
                                    onBasisEdit={onBasisEdit}
                                    onBasisDelete={onBasisDelete}
                                    options={dropdownOptions}
                                    basisList={basisList}
                                />
                            </SortOrderProvider>
                        )}
                    </Route>
                </Switch>
            }
        ></LayoutWrapper>
    );
};

export default BasisList;
