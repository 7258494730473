import { useState, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form, setNestedObjectValues } from 'formik';

import { validateNewUnit } from '../../services/thermals';
import { isSubmitDisabled } from '../../services/utils';

import { mapGeneralValidationMessages } from '../autoform/utils/autoformUtils';

import useCommonToast from '../../hooks/useCommonToast';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';
import GeneralFormValidationsAlert from '../forms/GeneralFormValidationsAlert';

import { FEATURE_FLAG_NAMES } from '../../constants/featureFlags';
import { SLICEOFDAY_PARTIAL_DELIVERABILITY } from 'constants/autoforms';
import { useIsFeatureActive } from '../../hooks/features-flags';
import { isNumberCellEmpty } from 'services/items';

const AddNewUnitModal = ({
    thermalItemId,
    unitTypes,
    dsmLoads,
    updateThermals,
    onSubmit,
    type,
    itemTypeId,
    deliverabilityStatusOptions,
    ...rest
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const errorsDisclosure = useDisclosure({ defaultIsOpen: true });
    const warningsDisclosure = useDisclosure({ defaultIsOpen: true });

    const [isValidating, setIsValidating] = useState(false);
    const [generalErrors, setGeneralErrors] = useState([]);
    const [generalWarnings, setGeneralWarnings] = useState([]);
    const [globalErrors, setGlobalErrors] = useState([]);
    const [globalWarnings, setGlobalWarnings] = useState([]);

    const allErrors = useMemo(() => [...globalErrors, ...generalErrors], [generalErrors, globalErrors]);
    const allWarnings = useMemo(() => [...globalWarnings, ...generalWarnings], [generalWarnings, globalWarnings]);

    const formLabels = {
        description: intl.formatMessage({ id: 'common_unit' }),
        unitType: intl.formatMessage({ id: 'unit_type' }),
        dsmLoad: intl.formatMessage({ id: 'common_dsm_load' }),
        selectPlaceholder: intl.formatMessage({ id: 'common_forms_select_option' }),
    };

    const initialFormValues = {
        itemId: parseInt(thermalItemId), // the parent thermal item id
        description: '',
        unitTypeId: null,
        ...(itemTypeId !== 2 && { dsmLoadId: null }), // dsmLoadId is excluded in Thermal(Generation asset)
    };

    const isCAMSliceOfDayVisible = useIsFeatureActive(FEATURE_FLAG_NAMES.CAM_SLICEOFDAY_FIELDS_VISIBLE);
    const [pcdsPercentDisabled, setPcdsPercentDisabled] = useState(true);

    const onDeliverabilityStatusChange = async (value) => {
        if (isCAMSliceOfDayVisible) {
            const selectedDeliverability = deliverabilityStatusOptions
                ?.find((x) => x.id === value)
                ?.description?.trim()
                .toUpperCase();
            if (SLICEOFDAY_PARTIAL_DELIVERABILITY === selectedDeliverability) {
                setPcdsPercentDisabled(false);
            } else {
                setPcdsPercentDisabled(true);
            }
        }
    };

    const validateDescription = (value) => {
        const trimmed = value.trim();

        if (!trimmed) {
            return intl.formatMessage({ id: 'unit_form_validation_required_name' });
        }

        if (trimmed.length > 50) {
            return intl.formatMessage({ id: `unit_form_validation_name_length` }, { length: 50 });
        }
    };

    const resetServerValidation = useCallback(() => {
        setGeneralErrors([]);
        setGeneralWarnings([]);
        setGlobalErrors([]);
        setGlobalWarnings([]);
    }, []);

    const onFormSubmit = async (values, { setErrors, setStatus }) => {
        try {
            if (isCAMSliceOfDayVisible) {
                //PCDSPercent (NumberInputFormikField) can return '' if it had a value and the value was deleted
                values = {
                    ...values,
                    pcdsPercent: isNumberCellEmpty(values.pcdsPercent),
                };
            }
            const { data } = await onSubmit(values);
            updateThermals(data.subThermal, thermalItemId);
            rest.onClose();

            toast(intl.formatMessage({ id: 'common_generic_item_change_success' }));
        } catch (err) {
            // reset the previous general errors and warnings
            resetServerValidation();

            // make sure to re-open the disclosures again,
            // because the user might have closed them and is submitting the form again
            errorsDisclosure.onOpen();
            warningsDisclosure.onOpen();

            if (err?.response?.status !== 500) {
                if (err?.response?.data?.validationResult?.issues) {
                    // when creating a single record we expect at most only 1 issue
                    const issue = err.response.data.validationResult.issues[0];

                    // handle general errors and warnings
                    const { warnings, errors } = mapGeneralValidationMessages(issue.generalValidationMessages);
                    setGeneralWarnings(warnings);
                    setGeneralErrors(errors);

                    // handle specific field errors
                    let fieldErrors = {};
                    let fieldStatus = {};

                    for (const field of issue.fields) {
                        const errors = field.validationMessages
                            .filter(({ severity }) => severity === 'Error')
                            .map(({ message }) => message);

                        const warnings = field.validationMessages
                            .filter(({ severity }) => severity === 'Warning')
                            .map(({ message }) => message);
                        fieldStatus[field.name] = { errors, warnings };
                    }

                    setErrors(fieldErrors);
                    setStatus(fieldStatus);
                }

                if (err?.response?.data?.validationResult?.globalIssues) {
                    // handle global errors and warnings
                    const { warnings, errors } = mapGeneralValidationMessages(
                        err.response.data.validationResult.globalIssues
                    );
                    setGlobalWarnings(warnings);
                    setGlobalErrors(errors);
                }
            }
        }
    };

    const onServerValidate = useCallback(
        async (values, setErrors, setStatus, setTouched, validateForm) => {
            if (isCAMSliceOfDayVisible) {
                //PCDSPercent (NumberInputFormikField) can return '' if it had a value and the value was deleted
                values = {
                    ...values,
                    pcdsPercent: isNumberCellEmpty(values.pcdsPercent),
                };
            }
            const errors = await validateForm(values);

            // reset the previous general errors and warnings
            resetServerValidation();

            if (Object.keys(errors).length > 0) {
                setTouched(setNestedObjectValues(errors, true));
            } else {
                try {
                    setIsValidating(true);

                    const data = await validateNewUnit(values);

                    // make sure to re-open the disclosure again
                    warningsDisclosure.onOpen();

                    if (data.issues) {
                        // reset the previous general errors and warnings
                        setGeneralWarnings([]);

                        // when creating a single record we expect at most only 1 issue
                        const issue = data.issues[0];

                        // handle general errors and warnings
                        const { warnings } = mapGeneralValidationMessages(issue.generalValidationMessages);
                        setGeneralWarnings(warnings);

                        // handle specific field errors
                        let fieldStatus = {};

                        for (const field of issue.fields) {
                            const warnings = field.validationMessages
                                .filter(({ severity }) => severity === 'Warning')
                                .map(({ message }) => message);

                            fieldStatus[field.name] = { warnings };
                        }

                        setStatus(fieldStatus);
                        setTouched(setNestedObjectValues(fieldStatus, true));
                    }

                    if (data.globalIssues) {
                        // handle global warnings
                        const { warnings } = mapGeneralValidationMessages(data.globalIssues);
                        setGlobalWarnings(warnings);
                    }

                    if (!data) {
                        toast(intl.formatMessage({ id: 'common_check_data_success' }));
                    }
                } catch (err) {
                    // make sure to re-open the disclosures again,
                    // because the user might have closed them and is submitting the form again
                    errorsDisclosure.onOpen();
                    warningsDisclosure.onOpen();

                    if (err.response?.status !== 500) {
                        if (err.response?.data?.issues) {
                            // when creating a single record we expect at most only 1 issue
                            const issue = err.response.data.issues[0];

                            // handle general errors and warnings
                            const { warnings, errors } = mapGeneralValidationMessages(issue.generalValidationMessages);
                            setGeneralWarnings(warnings);
                            setGeneralErrors(errors);

                            // handle specific field errors
                            let fieldErrors = {};
                            let fieldStatus = {};

                            for (const field of issue.fields) {
                                const errors = field.validationMessages
                                    .filter(({ severity }) => severity === 'Error')
                                    .map(({ message }) => message);

                                const warnings = field.validationMessages
                                    .filter(({ severity }) => severity === 'Warning')
                                    .map(({ message }) => message);

                                fieldStatus[field.name] = { errors, warnings };
                            }

                            setErrors(fieldErrors);
                            setStatus(fieldStatus);
                            setTouched(setNestedObjectValues(fieldStatus, true));
                        }

                        if (err.response?.data?.globalIssues) {
                            // handle global errors and warnings
                            const { warnings, errors } = mapGeneralValidationMessages(err.response.data.globalIssues);
                            setGlobalWarnings(warnings);
                            setGlobalErrors(errors);
                        }
                    }
                } finally {
                    setIsValidating(false);
                }
            }
        },
        [intl, toast, errorsDisclosure, warningsDisclosure, resetServerValidation, isCAMSliceOfDayVisible]
    );

    return (
        <Modal {...rest}>
            <ModalOverlay />

            <ModalContent pt={3}>
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="unit_add_heading" />
                    </Heading>
                </ModalHeader>

                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik enableReinitialize initialValues={initialFormValues} onSubmit={onFormSubmit}>
                    {({ values, errors, touched, isSubmitting, setErrors, setStatus, setTouched, validateForm }) => {
                        const isDisabled = isSubmitDisabled({ errors, touched });

                        return (
                            <Form>
                                {allErrors.length > 0 && (
                                    <GeneralFormValidationsAlert
                                        isOpen={errorsDisclosure.isOpen}
                                        onClose={errorsDisclosure.onClose}
                                        messages={allErrors}
                                    />
                                )}

                                {allWarnings.length > 0 && (
                                    <GeneralFormValidationsAlert
                                        status="warning"
                                        title={<FormattedMessage id="common_warning" />}
                                        isOpen={warningsDisclosure.isOpen}
                                        onClose={warningsDisclosure.onClose}
                                        messages={allWarnings}
                                    />
                                )}

                                <ModalBody borderBottom="1px" borderColor="border-secondary">
                                    <InputFormikField
                                        name="description"
                                        type="text"
                                        label={formLabels.description}
                                        isRequired
                                        validate={validateDescription}
                                    />

                                    <ComboboxFormikField
                                        id="unitTypeId"
                                        name="unitTypeId"
                                        label={formLabels.unitType}
                                        mt={3}
                                        options={unitTypes}
                                        valueKey="id"
                                        labelKey="description"
                                        placeholderLabel="-"
                                        placeholderValue={null}
                                        showPlaceholder={true}
                                    />

                                    {isCAMSliceOfDayVisible && (
                                        <>
                                        <ComboboxFormikField
                                            id={`deliverabilityStatusId`}
                                            name={`deliverabilityStatusId`}
                                            label={<FormattedMessage id="thermal_deliverability_status" />}
                                            options={deliverabilityStatusOptions}
                                            labelKey="description"
                                            valueKey="id"
                                            onChange={onDeliverabilityStatusChange}
                                            mt={3}
                                            placeholderValue={null}
                                            showPlaceholder={true}
                                        />
                                        <NumberInputFormikField
                                            id={`pcdsPercent`}
                                            name={`pcdsPercent`}
                                            keepWithinRange={true}
                                            min={0}
                                            max={100}
                                            step={1}
                                            label={<FormattedMessage id="thermal_pcds_percent" />}
                                            isDisabled={pcdsPercentDisabled}
                                            isRequired={!pcdsPercentDisabled}
                                            mt={3}
                                        />
                                        </>
                                    )}

                                    {itemTypeId !== 2 && ( // dsmLoadId is excluded in Thermal(Generation asset)
                                        <ComboboxFormikField
                                            id="dsmLoadId"
                                            name="dsmLoadId"
                                            label={formLabels.dsmLoad}
                                            mt={3}
                                            options={dsmLoads}
                                            valueKey="id"
                                            labelKey="description"
                                            placeholderLabel="-"
                                            placeholderValue={null}
                                            showPlaceholder={true}
                                        />
                                    )}
                                </ModalBody>

                                <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                    <Button
                                        variant="secondary"
                                        onClick={() =>
                                            onServerValidate(values, setErrors, setStatus, setTouched, validateForm)
                                        }
                                        isLoading={isValidating}
                                        isDisabled={isDisabled}
                                    >
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id="common_check_data" />
                                        </Box>
                                    </Button>

                                    <Box ml="auto">
                                        <Button variant="secondary" onClick={rest.onClose}>
                                            <Box as="span" textTransform="capitalize">
                                                <FormattedMessage id="common_cancel" />
                                            </Box>
                                        </Button>

                                        <Button type="submit" isDisabled={isDisabled} isLoading={isSubmitting} ml={3}>
                                            <FormattedMessage id="common_item_create_btn" />
                                        </Button>
                                    </Box>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default AddNewUnitModal;
