import { useRef, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled/macro';

import { Flex, IconButton } from '@chakra-ui/react';

import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down.svg';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

const TabListWrapper = ({
    step = 200,
    tabBackground = 'background-primary',
    tabCount,
    currentTab,
    children,
    ...rest
}) => {
    const intl = useIntl();

    const container = useRef(null);

    const [mounted, setMounted] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [scrollX, setScrollX] = useState(0);
    const { isSidebarVisible } = useSelector((state) => state.helpers);

    const resizeHandler = () => {
        if (container.current && container.current.scrollWidth > container.current.clientWidth) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    };

    useEffect(() => {
        resizeHandler();
    }, [isSidebarVisible]);

    useEffect(() => {
        window.onresize = resizeHandler;
    }, []);

    useEffect(() => {
        if (!mounted) {
            // only tabs with refresh button and a non-zero current tab/tab count should try restoring the scroll
            const shouldPerformInitialScroll = currentTab && tabCount;

            if (shouldPerformInitialScroll) {
                // here we calculate the maximum scroll
                const maxScroll = container.current.scrollWidth - container.current.clientWidth;

                // we get the width of the current tab
                const tabWidth = container.current.children[currentTab]?.getBoundingClientRect().width;

                // we calculate the tab target to be the middle of the element
                const tabTarget = tabWidth / 2;

                // finally we calculate the total scroll that we have to do
                const currentTabPosition = (maxScroll / tabCount) * currentTab + tabTarget;

                container.current?.scrollTo({
                    top: 0,
                    left: currentTabPosition,
                });

                setScrollX(currentTabPosition);
            }

            setMounted(true);
        }
    }, [mounted, currentTab, tabCount]);

    //Accordions and tabs might load the dom element dynamically, which can cause invalid dimensions
    //Resize observers handle this issue
    useEffect(() => {
        if (container.current) {
            const observer = new ResizeObserver(debounce(resizeHandler, 20));
            observer.observe(container.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [container]);

    const scrollRight = () => {
        const maxScroll = container.current.scrollWidth - container.current.clientWidth;
        const scrollChange = scrollX + step > maxScroll ? maxScroll : scrollX + step;

        container.current.scrollTo({
            top: 0,
            left: scrollChange,
            behavior: 'smooth',
        });
        setScrollX(scrollChange);
    };

    const scrollLeft = () => {
        const scrollChange = scrollX - step < 0 ? 0 : scrollX - step;

        container.current.scrollTo({
            top: 0,
            left: scrollChange,
            behavior: 'smooth',
        });
        setScrollX(scrollChange);
    };

    const callBackRef = (node) => {
        if (!node) return;

        container.current = node;
        if (node.scrollWidth > node.clientWidth) {
            setShowButtons(true);
        }
    };
    return (
        <TabContainer
            maxWidth="100%"
            scrolledRight={scrollX > 0}
            scrolledLeft={container.current && container.current.clientWidth + scrollX < container.current.scrollWidth}
            tabBackground={tabBackground}
            showButtons={showButtons}
            {...rest}
        >
            {showButtons && (
                <IconButtonLeft
                    aria-label={intl.formatMessage({ id: 'common_scroll_left' })}
                    icon={<ChevronDownIcon />}
                    onClick={scrollLeft}
                    variant="circular-icon"
                    disabled={scrollX === 0}
                />
            )}

            <Flex ref={callBackRef} overflow="hidden" pt="5px" pl="5px" pr={!mounted ? `${step}px` : '5px'}>
                {children}
            </Flex>

            {showButtons && (
                <IconButtonRight
                    aria-label={intl.formatMessage({ id: 'common_scroll_right' })}
                    icon={<ChevronDownIcon />}
                    onClick={scrollRight}
                    variant="circular-icon"
                    disabled={
                        container.current && container.current.scrollWidth - container.current.clientWidth === scrollX
                    }
                />
            )}
        </TabContainer>
    );
};

const TabContainer = styled(Flex, {
    shouldForwardProp: (prop) => !['scrolledLeft', 'scrolledRight', 'showButtons', 'tabBackground'].includes(prop),
})`
    position: relative;
    min-width: 0;

    > div {
        padding-bottom: 0;
    }

    &:after {
        display: ${(props) => (props.showButtons && props.scrolledLeft ? 'block' : 'none')};
        pointer-events: none;
        content: '';
        width: 50px;
        height: calc(100% - 1px);
        position: absolute;
        right: 50px;
        top: 0;
        background: linear-gradient(270deg, var(--chakra-colors-tab-list-gradient) 0%, rgba(250, 250, 250, 0) 100%);
    }

    &:before {
        display: ${(props) => (props.showButtons && props.scrolledRight ? 'block' : 'none')};
        content: '';
        pointer-events: none;
        width: 50px;
        height: calc(100% - 1px);
        position: absolute;
        left: 50px;
        top: 0;
        background: linear-gradient(270deg, rgba(250, 250, 250, 0) 0%, var(--chakra-colors-tab-list-gradient) 100%);
    }
`;

const IconButtonLeft = styled(IconButton)`
    transform: rotateZ(90deg);
    margin-right: 15px;
`;
const IconButtonRight = styled(IconButton)`
    transform: rotateZ(-90deg);
    margin-left: 15px;
`;
export default TabListWrapper;
