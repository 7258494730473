import { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Box,
    MenuItem,
    Skeleton,
    Accordion,
    AccordionItem,
    AccordionPanel,
    useDisclosure,
    IconButton,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useFormikContext } from 'formik';
import _ from 'lodash';

import { ReactComponent as DeleteIcon } from '../../icons/delete-circled.svg';

import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import useAutoformTableData from './hooks/useAutoformTableData';
import useAutoformLoadTableData from './hooks/useAutoformLoadTableData';
import useAutoformParams from './hooks/useAutoformParams';
import useCommonToast from '../../hooks/useCommonToast';

import AutoformAddRecordModal from './AutoformAddRecordModal';
import SubItemHeader from './SubItemHeader';

import { checkDataSingle } from '../../services/autoforms';
import { setSelectedItemForTable } from '../../store/autoform/autoformSlice';
import { addAutoformTableRow, deleteAutoformTableRow } from '../../store/autoform/autoformApi';

import {
    getInputFormName,
    getDtoFromDataValueObject,
    getTableFormKey,
    getItemFormKey,
    parseColumnSettings,
} from './utils/autoformUtils';
import AddSubItem from '../utils/AddSubItem';
import Tooltip from '../utils/Tooltip';
import DeleteRecordModal from '../utils/DeleteRecordModal';
import AutoformUiContext from './utils/AutoformUiContext';
import SubItemValidationStatusContainer from './SubItemValidationStatusContainer';

/**
 * Container component for the Accordion header. Renders an autoform table
 * as an accordion with corresponding CRUD logic.
 *
 * @param {string} schemaCode - schema code
 * @param {string} tableId - target table id
 * @param {callback} renderSubItem - Render prop to build accordion panel contents.
 * @param {boolean} isLazy - "lazy" flag
 * @param {object} config - containing config for this and nested components
 */
const SubItemListContainer = ({ schemaCode, tableId, renderSubItem, isLazy = true, config }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const addSubItemModal = useDisclosure();
    const deleteItemModal = useDisclosure();
    const dispatch = useDispatch();
    const { data: items } = useAutoformTableData(tableId);
    const { values, status } = useFormikContext();
    const { editingState, setEditingState } = useContext(AutoformUiContext);
    const parameters = useAutoformParams();
    const refetchDataCounter = useSelector(
        (state) => state.autoform?.reloadGridFlag && state.autoform?.reloadGridFlag[tableId]
    );

    const { metadata, primaryKeyColumnName, descriptionColumnName, descriptionColumn } =
        useAutoformTableMetadata(tableId);
    const { selectedItem: selectedParentItemId } = useAutoformTableMetadata(metadata && metadata.parentId);

    const [accordionIndex, setAccordionIndex] = useState(0);

    const { isLoading } = useAutoformLoadTableData(tableId, selectedParentItemId, { refetchDataCounter });

    const [subItemForDeletion, setSubItemForDeletion] = useState(null);

    const settings = useMemo(() => parseColumnSettings(descriptionColumn.settings), [descriptionColumn]);

    const onAccordionOpen = useCallback(
        (index) => {
            const item = items[index];

            if (item != null) {
                dispatch(setSelectedItemForTable({ itemId: item[primaryKeyColumnName], tableId }));
            }
            setAccordionIndex(index);
        },
        [dispatch, items, primaryKeyColumnName, tableId]
    );

    useEffect(() => {
        onAccordionOpen(accordionIndex);
    }, [onAccordionOpen, accordionIndex]);

    const itemDescription = (recordId) =>
        values[getTableFormKey(tableId)] &&
        values[getTableFormKey(tableId)][getItemFormKey(recordId)] &&
        values[getTableFormKey(tableId)][getItemFormKey(recordId)][descriptionColumnName];

    const onDeleteModalOpen = (item) => {
        setSubItemForDeletion(item);
        deleteItemModal.onOpen();
    };

    const onDeleteModalClose = () => {
        deleteItemModal.onClose();
        setSubItemForDeletion(null);
    };

    const onRecordDelete = async (recordId) => {
        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'record' });
        await dispatch(
            deleteAutoformTableRow({
                tableId,
                recordId,
                primaryKeyColumnName,
                parameters,
            })
        ).unwrap();
        toast(successMessage);
        onDeleteModalClose();
    };

    const onAddRecord = async (record) => {
        const response = await dispatch(
            addAutoformTableRow({
                schemaCode,
                tableId,
                record: getDtoFromDataValueObject(record, metadata),
                parentRecordId: selectedParentItemId,
                parameters,
            })
        ).unwrap();

        toast(intl.formatMessage({ id: 'common_record_creation_success' }));
        addSubItemModal.onClose();

        return response.data;
    };
    const onCheckData = async (record) => {
        const responseData = await checkDataSingle({
            tableId,
            record: getDtoFromDataValueObject(record, metadata),
            parentRecordId: selectedParentItemId,
            parameters,
        });
        return responseData;
    };

    return (
        <>
            {isLoading ? (
                <Box px={6}>
                    <Skeleton>
                        <Box h="34px">
                            <FormattedMessage id="common_loading" />
                        </Box>
                    </Skeleton>
                </Box>
            ) : items.length === 0 ? (
                <AddSubItem
                    onOpen={addSubItemModal.onOpen}
                    isDisabled={!metadata.canCreate}
                    messageId={config?.addSubItemMessageId}
                />
            ) : (
                <Accordion defaultIndex={[0]} allowToggle onChange={onAccordionOpen}>
                    {items.map((item) => (
                        <StyledAccordionItem key={item[primaryKeyColumnName]} border="none">
                            {({ isExpanded }) => {
                                const inputName = getInputFormName(
                                    tableId,
                                    item[primaryKeyColumnName],
                                    descriptionColumnName
                                );
                                return (
                                    <>
                                        <SubItemHeader
                                            inputName={inputName}
                                            subItemHeading={metadata.name}
                                            itemDescription={itemDescription(item[primaryKeyColumnName])}
                                            isExpanded={isExpanded}
                                            onAddSubItemClick={() => {
                                                addSubItemModal.onOpen();
                                            }}
                                            info={settings?.CELLTOOLTIP}
                                            isAddDisabled={!metadata.canCreate}
                                            isEditVisible={metadata.canUpdate && !descriptionColumn?.isReadOnly}
                                            isEditMode={editingState[inputName]}
                                            onEditClick={(isEditing) =>
                                                setEditingState((oldState) => ({
                                                    ...oldState,
                                                    [inputName]: isEditing,
                                                }))
                                            }
                                            status={_.get(status, inputName)}
                                            actionBtnMobile={
                                                <MenuItem
                                                    key="delete-btn"
                                                    textTransform="capitalize"
                                                    onClick={() => onDeleteModalOpen(item)}
                                                    isDisabled={!metadata.canDelete}
                                                >
                                                    <FormattedMessage id="common_delete" />
                                                </MenuItem>
                                            }
                                            actionBtn={
                                                <>
                                                    <SubItemValidationStatusContainer
                                                        tableId={tableId}
                                                        recordId={item[primaryKeyColumnName]}
                                                    />
                                                    <Box display="inline-block">
                                                        <Tooltip
                                                            label={intl.formatMessage({
                                                                id: 'common_delete_btn_tooltip_and_label',
                                                            })}
                                                        >
                                                            <StyledIconButton
                                                                aria-label={intl.formatMessage({
                                                                    id: 'common_delete_btn_tooltip_and_label',
                                                                })}
                                                                variant="circular-icon"
                                                                isDisabled={!metadata.canDelete}
                                                                icon={<DeleteIcon />}
                                                                onClick={() => onDeleteModalOpen(item)}
                                                            />
                                                        </Tooltip>
                                                    </Box>
                                                </>
                                            }
                                        />
                                        <AccordionPanel px={6} pt={0} pb={4}>
                                            {isExpanded && renderSubItem && renderSubItem(item)}
                                        </AccordionPanel>
                                    </>
                                );
                            }}
                        </StyledAccordionItem>
                    ))}
                </Accordion>
            )}
            {addSubItemModal.isOpen && (
                <AutoformAddRecordModal
                    tableId={tableId}
                    itemType="Sub-item"
                    isOpen={addSubItemModal.isOpen}
                    onClose={addSubItemModal.onClose}
                    onCreateRecord={onAddRecord}
                    onCheckData={onCheckData}
                />
            )}
            {deleteItemModal.isOpen && (
                <DeleteRecordModal
                    onOpen={onDeleteModalOpen}
                    onClose={onDeleteModalClose}
                    headerItem="Sub-item"
                    contentMessageItem={subItemForDeletion[descriptionColumnName]}
                    onConfirm={() => onRecordDelete(subItemForDeletion[primaryKeyColumnName])}
                    forAutoForm={true}
                />
            )}
        </>
    );
};

const StyledIconButton = styled(IconButton)`
    &:disabled {
        opacity: 0.6;
        &:hover svg path,
        svg path {
            fill: var(--chakra-colors-gray-600);
        }
    }
`;

const StyledAccordionItem = styled(AccordionItem)`
    .chakra-collapse {
        overflow: initial !important;
    }
`;

export default SubItemListContainer;
