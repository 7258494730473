import { useMemo, useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Box, Flex, Skeleton, SimpleGrid, Spacer } from '@chakra-ui/react';

import useCommonToast from '../../hooks/useCommonToast';
import { updateStrategyRows, filterStrategy, extractYearsFromBiddingStrategy } from '../../services/batteries';

import RibbonSkeleton from './ribbon/RibbonSkeleton';
import StrategyGrid from './table/StrategyGrid';

import MultiSelectField from '../forms/MultiSelectField';
import ComboboxField from '../forms/ComboboxField';

const biddingDataLoadingKey = 'bidding-data';
const biddingTemplateLoadingKey = 'bidding-template';

const BatteryBiddingStrategy = ({
    onLoadingStart,
    onLoadingEnd,
    ancillaries,
    templates = [],
    templateConfig,
    onTemplatesConfigChange,
    gridResource,
    actionBarSlot,
}) => {
    const { toast } = useCommonToast();
    const intl = useIntl();
    const [isInitialLoading, setIsInitialLoading] = useState(true);

    const containsTemplates = templates.length > 0;

    const [strategyFilters, setStrategyFilters] = useState({
        years: [],
        products: ancillaries.map((el) => el.id),
        templates,
        templateId: templateConfig.templateId,
    });

    const [strategyHandlers, setStrategyHandlers] = useState({
        data: [],
        filteredData: [],
        years: [],
        products: ancillaries,
        templateId: templateConfig.templateId,
        templates,
        disabled: false,
        shouldDisableButtons: templateConfig.templateId === null ? false : true,
        isLoading: false,
        error: null,
        isInitialRequestSent: false,
    });

    const getBiddingStrategyData = useCallback(
        async (localTemplateId) => {
            setStrategyHandlers((prev) => ({ ...prev, isLoading: true }));
            onLoadingStart && onLoadingStart(biddingDataLoadingKey);

            const payload = {
                templateId: localTemplateId,
            };

            try {
                const data = await gridResource.read(payload);

                // Extracts years from response in order to populate dropdown
                const years = extractYearsFromBiddingStrategy(data);

                setStrategyHandlers((prev) => ({
                    ...prev,
                    years,
                    data,
                    filteredData: data,
                    isInitialRequestSent: true,
                    isLoading: false,
                }));

                setStrategyFilters((prev) => ({
                    ...prev,
                    products: ancillaries.map((el) => el.id),
                    years: years.map((el) => el.id),
                }));
            } catch (error) {
                setStrategyHandlers((prev) => ({ ...prev, error: error.message }));
            } finally {
                setStrategyHandlers((prev) => ({
                    ...prev,
                    isLoading: false,
                    shouldDisableButtons: localTemplateId === null ? false : true,
                }));
                onLoadingEnd && onLoadingEnd(biddingDataLoadingKey);
            }
        },
        [gridResource, ancillaries, onLoadingStart, onLoadingEnd]
    );

    // when bidding strategy template is changed we save the new template and
    // then refetch the grid data to preserve data integrity because on the client side we do not know
    // how the genParticipationIds vary between different templates
    const onChangeStrategyTemplate = async (localTemplateId) => {
        setStrategyFilters((prev) => ({ ...prev, templateId: localTemplateId }));
        onLoadingStart && onLoadingStart(biddingTemplateLoadingKey);

        setStrategyHandlers((prev) => ({
            ...prev,
            disabled: true,
            templateId: localTemplateId,
        }));

        await gridResource.update({ templateId: localTemplateId });

        onTemplatesConfigChange({ templateId: localTemplateId, hasUpload: false });

        setStrategyHandlers((prev) => ({
            ...prev,
            disabled: false,
        }));

        onLoadingEnd && onLoadingEnd(biddingTemplateLoadingKey);

        // refetch the strategy data for the selected template id
        await getBiddingStrategyData(localTemplateId);
    };

    const onChangeStrategyFilters = (filters) => {
        const filteredData = filterStrategy(filters, strategyHandlers.data);

        setStrategyFilters(filters);
        setStrategyHandlers((prev) => ({ ...prev, filteredData }));
    };

    const onBidChange = useCallback(
        async (row, participationHour) => {
            setStrategyHandlers((prev) => ({
                ...prev,
                disabled: true,
            }));

            const { id, genParticipationIds, genParticipationPeriodId, rtoProductId, dayTypeId } = row;

            const successMessage = intl.formatMessage({ id: 'common_generic_item_change_success' });

            const payload = {
                genParticipationPeriodId,
                rtoProductId,
                dayTypeId,
                participationHour,
            };

            const participationId = genParticipationIds[participationHour];

            let cellId = null;
            // we have two endpoints for creating and deleting bid hence the conditional toggle
            if (participationId) {
                await gridResource.deleteBid(participationId);
            } else {
                cellId = await gridResource.createBid(payload);
            }

            genParticipationIds[participationHour] = cellId;

            setStrategyHandlers((prev) => ({
                ...prev,
                data: updateStrategyRows(strategyHandlers.data, id, genParticipationIds),
                filteredData: updateStrategyRows(strategyHandlers.filteredData, id, genParticipationIds),
                disabled: false,
            }));

            toast({
                id: 'single-toast',
                message: successMessage,
            });
        },
        // Chakra's toast identity changes too often
        [intl, strategyHandlers.data, strategyHandlers.filteredData, gridResource] // eslint-disable-line
    );

    useEffect(() => {
        setIsInitialLoading(true);

        getBiddingStrategyData(strategyFilters.templateId).finally(() => setIsInitialLoading(false));
    }, [getBiddingStrategyData, strategyFilters.templateId]);

    useEffect(() => {
        if (templateConfig.hasUpload) {
            onChangeStrategyTemplate(templateConfig.templateId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateConfig.hasUpload]);

    const columns = useMemo(() => {
        const hourEndingColumns = Array.from({ length: 24 }).map((_, i) => {
            return {
                field: String(i),
                type: 'button',
                headerName: intl.formatMessage(
                    { id: 'batteries_bidding_strategy_hour_ending' },
                    { n: String(i + 1).padStart(2, '0') }
                ),
                cellRendererParams: { onBidChange },
                sortable: false,
            };
        });

        return [
            {
                cellRenderer: 'agGroupCellRenderer',
                hide: true,
            },
            {
                field: 'periodName',
                rowGroup: true,
                hide: true,
            },
            {
                field: 'productDescription',
                headerName: intl.formatMessage({
                    id: 'batteries_bidding_strategy_product_name',
                }),
                pinned: 'left',
                maxWidth: 180,
            },
            {
                field: 'dayTypeDescription',
                headerName: '',
                pinned: 'left',
                maxWidth: 140,
            },
            {
                type: 'monthName',
                pinned: 'left',
                maxWidth: 46,
            },
        ].concat(hourEndingColumns);
    }, [intl, onBidChange]);

    if (isInitialLoading) {
        return (
            <>
                <SimpleGrid mt={4} columns={{ xl: containsTemplates ? 4 : 3 }} spacingX={6} spacingY={4} w="100%">
                    {containsTemplates && (
                        <>
                            <Skeleton height="40px" />
                            <Spacer />
                        </>
                    )}

                    <Skeleton height="40px" />
                    <Skeleton height="40px" />

                    {actionBarSlot && <Skeleton height="40px" />}
                </SimpleGrid>

                <Box mt={12}>
                    <RibbonSkeleton />
                </Box>
            </>
        );
    }

    return (
        <>
            <SimpleGrid mt={4} columns={{ xl: containsTemplates ? 4 : 3 }} spacingX={6} spacingY={4} w="100%">
                {containsTemplates && (
                    <>
                        <ComboboxField
                            textTransform="capitalize"
                            id="template"
                            name="template"
                            label={intl.formatMessage({ id: 'batteries_bidding_strategy_templates_select_title' })}
                            options={strategyHandlers.templates}
                            valueKey="id"
                            labelKey="description"
                            showPlaceholder={false}
                            value={strategyHandlers.templateId}
                            onChange={onChangeStrategyTemplate}
                        />

                        <Spacer />
                    </>
                )}

                <MultiSelectField
                    textTransform="capitalize"
                    id="years"
                    name="years"
                    label={intl.formatMessage({ id: 'batteries_bidding_strategy_years_multiselect_title' })}
                    options={strategyHandlers.years}
                    placeholderLabel={intl.formatMessage({
                        id: 'batteries_timeline_parameters_placeholder',
                    })}
                    multipleSelectedLabel={intl.formatMessage({
                        id: 'batteries_timeline_parameters_multiselect_placeholder',
                    })}
                    showSelectedCount={true}
                    labelKey="description"
                    valueKey="id"
                    value={strategyFilters.years}
                    onChange={(values) => onChangeStrategyFilters({ ...strategyFilters, years: values })}
                />

                <MultiSelectField
                    textTransform="capitalize"
                    id="products"
                    name="products"
                    label={intl.formatMessage({ id: 'batteries_bidding_strategy_products_multiselect_title' })}
                    options={strategyHandlers.products}
                    placeholderLabel={intl.formatMessage({
                        id: 'batteries_timeline_parameters_placeholder',
                    })}
                    multipleSelectedLabel={intl.formatMessage({
                        id: 'batteries_timeline_parameters_multiselect_placeholder',
                    })}
                    showSelectedCount={true}
                    labelKey="description"
                    valueKey="id"
                    value={strategyFilters.products}
                    onChange={(values) => onChangeStrategyFilters({ ...strategyFilters, products: values })}
                />

                {actionBarSlot && <Flex justify="flex-end">{actionBarSlot}</Flex>}
            </SimpleGrid>

            <Box mt={12}>
                <StrategyGrid
                    rowData={strategyHandlers.filteredData}
                    onBidChange={onBidChange}
                    disabled={strategyHandlers.disabled}
                    shouldDisableButtons={strategyHandlers.shouldDisableButtons}
                    columns={columns}
                />
            </Box>
        </>
    );
};

export default BatteryBiddingStrategy;
