import {
    Heading,
    Flex,
    Box,
    SimpleGrid,
    AccordionItem,
    AccordionPanel,
    Text,
    useMediaQuery,
    MenuItem,
    Divider,
    IconButton,
} from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';

import { checkItemInputEvent } from '../../services/items';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';

import SubItemActionsMenu from '../itemData/SubItemActionsMenu';

import Tooltip from '../utils/Tooltip';
import AccordionButton from '../utils/AccordionButton';

import SortOrderProvider from '../grid/utils/SortOrderContext';

import ForwardCurveData from './ForwardCurveData';

import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete-circled.svg';
import { ReactComponent as AddItemIcon } from '../../icons/item-icons/add-item.svg';

const ForwardCurveDefinition = ({
    forwardItemData,
    forwardItemDefinitionData,
    onAddSubItemModalOpen,
    editDescriptionConfig,
    onEditDescription,
    validateItemDescription,
    forwardItemId,
    onSubItemDeleteModalOpen,
}) => {
    const intl = useIntl();
    const [isDesktop] = useMediaQuery('(min-width: 769px)');

    const definitionId = forwardItemDefinitionData.id;
    const definitionDescription = forwardItemDefinitionData.description;

    const foundEditDescriptionConfig = editDescriptionConfig[`${definitionId}`];
    const isEditing = foundEditDescriptionConfig ? foundEditDescriptionConfig.isActive : false;

    const { values } = useFormikContext();

    const formLabels = {
        selectPlaceholder: intl.formatMessage({ id: 'common_forms_select_option' }),
        name: intl.formatMessage({ id: 'add_new_forward_item_name' }),
        commodity: intl.formatMessage({ id: 'add_new_forward_item_commodity' }),
        peakPeriod: intl.formatMessage({ id: 'add_new_forward_item_peak_period' }),
        marketScaler: intl.formatMessage({ id: 'add_new_forward_item_market_scaler' }),
        currency: intl.formatMessage({ id: 'add_new_forward_item_currency' }),
        market: intl.formatMessage({ id: 'add_new_forward_item_market_name' }),
        useCurveDeveloper: intl.formatMessage({ id: 'add_new_forward_item_use_curve_developer' }),
    };

    // combobox item with id: 0 is considered valid
    const validateRequiredComboBoxField = (value, label) => {
        let errorMessage = '';

        if (value === null || value === undefined) {
            errorMessage = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }

        return errorMessage;
    };

    return (
        <AccordionItem>
            {({ isExpanded }) => (
                <>
                    <SimpleGrid
                        columns={2}
                        mt="-1px"
                        bg="background-octenary"
                        borderTop="1px solid"
                        borderBottom="1px solid"
                        borderColor="border-secondary"
                    >
                        <Flex align="center" ml={4} my={3}>
                            <AccordionButton />

                            <Box flex={isEditing && 1}>
                                <Text size="xs" mb="2px">
                                    <FormattedMessage id="forward_curve_label" />
                                </Text>

                                {!isEditing ? (
                                    <Heading as="h4" variant="h4">
                                        {values.definitions[definitionId]?.description}
                                    </Heading>
                                ) : (
                                    <InputFormikField
                                        validate={(value) => validateItemDescription(value, definitionId, 50)}
                                        name={`definitions.${definitionId}.description`}
                                        type="text"
                                        onKeyDown={checkItemInputEvent}
                                        isFastField
                                    />
                                )}
                            </Box>

                            <Flex align="center" ml={3}>
                                <Tooltip label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}>
                                    <IconButton
                                        minH="40px"
                                        aria-label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}
                                        leftIcon={<EditIcon />}
                                        variant="circular-icon"
                                        onClick={() => onEditDescription(definitionId)}
                                    />
                                </Tooltip>
                            </Flex>
                        </Flex>

                        <Flex justify="flex-end" align="center" pr={6}>
                            {!isDesktop ? (
                                <SubItemActionsMenu
                                    menuListItems={
                                        <>
                                            <MenuItem
                                                key="create-btn"
                                                textTransform="capitalize"
                                                onClick={onAddSubItemModalOpen}
                                            >
                                                <FormattedMessage id="common_add" />
                                            </MenuItem>
                                        </>
                                    }
                                />
                            ) : (
                                <>
                                    <Box display="inline-block" mr={6}>
                                        <Tooltip
                                            label={intl.formatMessage({
                                                id: 'common_delete_btn_tooltip_and_label',
                                            })}
                                        >
                                            <IconButton
                                                aria-label={intl.formatMessage({
                                                    id: 'common_item_delete_subitem',
                                                })}
                                                variant="circular-icon"
                                                icon={<DeleteIcon />}
                                                onClick={() => {
                                                    onSubItemDeleteModalOpen(definitionId, definitionDescription);
                                                }}
                                            />
                                        </Tooltip>
                                    </Box>

                                    <Divider orientation="vertical" />

                                    <Box display="inline-block" mx={6}>
                                        <Tooltip label={intl.formatMessage({ id: 'common_add_new' })}>
                                            <IconButton
                                                variant="circular-icon"
                                                aria-label={intl.formatMessage({ id: 'common_item_add_subitem' })}
                                                icon={<AddItemIcon />}
                                                onClick={onAddSubItemModalOpen}
                                            />
                                        </Tooltip>
                                    </Box>
                                </>
                            )}
                        </Flex>
                    </SimpleGrid>

                    {isExpanded && (
                        <AccordionPanel px={6} pt={6} pb={4}>
                            <SimpleGrid columns={{ xl: 3 }} spacingX={6}>
                                <ComboboxFormikField
                                    id={`commodity-${definitionId}`}
                                    name={`definitions.${definitionId}.commodityId`}
                                    label={formLabels.commodity}
                                    validate={(value) => validateRequiredComboBoxField(value, 'Commodity')}
                                    options={forwardItemData.commodities}
                                    valueKey="id"
                                    labelKey="description"
                                    placeholderValue=""
                                    placeholderLabel={formLabels.selectPlaceholder}
                                    isRequired
                                    mt={3}
                                />

                                <ComboboxFormikField
                                    id={`peakPeriod-${definitionId}`}
                                    name={`definitions.${definitionId}.peakPeriodLabelId`}
                                    label={formLabels.peakPeriod}
                                    validate={(value) => validateRequiredComboBoxField(value, 'Peak Period')}
                                    options={forwardItemData.peakPeriods}
                                    valueKey="id"
                                    labelKey="description"
                                    placeholderValue=""
                                    placeholderLabel={formLabels.selectPlaceholder}
                                    isRequired
                                    mt={3}
                                />

                                <ComboboxFormikField
                                    id={`market-${definitionId}`}
                                    name={`definitions.${definitionId}.marketId`}
                                    label={formLabels.market}
                                    options={forwardItemData.marketNames}
                                    valueKey="id"
                                    labelKey="description"
                                    placeholderValue=""
                                    placeholderLabel={formLabels.selectPlaceholder}
                                    mt={3}
                                    isRequired={values.definitions[definitionId].useCurveDeveloper}
                                />

                                <NumberInputFormikField
                                    id={`marketScaler-${definitionId}`}
                                    name={`definitions.${definitionId}.marketScaler`}
                                    label={formLabels.marketScaler}
                                    step={1}
                                    mt={3}
                                />

                                <CheckboxFormikField
                                    id={`useCurveDeveloper-${definitionId}`}
                                    name={`definitions.${definitionId}.useCurveDeveloper`}
                                    mt={9}
                                >
                                    {formLabels.useCurveDeveloper}
                                </CheckboxFormikField>

                                <ComboboxFormikField
                                    id={`currency-${definitionId}`}
                                    name={`definitions.${definitionId}.currencyID`}
                                    label={formLabels.currency}
                                    options={forwardItemData.currencies}
                                    valueKey="id"
                                    labelKey="description"
                                    placeholderValue=""
                                    placeholderLabel={formLabels.selectPlaceholder}
                                    mt={3}
                                />
                            </SimpleGrid>

                            <SortOrderProvider>
                                <ForwardCurveData
                                    forwardCurveId={definitionId}
                                    options={forwardItemData}
                                    forwardItemId={forwardItemId}
                                />
                            </SortOrderProvider>
                        </AccordionPanel>
                    )}
                </>
            )}
        </AccordionItem>
    );
};

export default ForwardCurveDefinition;
