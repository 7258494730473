import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setupInterceptors, clearInterceptors } from '../../services/auth';

const AxiosInterceptors = () => {
    const history = useHistory();

    useEffect(() => {
        const { reqId, resId } = setupInterceptors(history);
        return () => {
            clearInterceptors(reqId, resId);
        };
    }, [history]);

    return null;
};

export default AxiosInterceptors;
