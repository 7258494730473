import { memo, useMemo } from 'react';
import styled from '@emotion/styled/macro';

import CustomItemIcon from '../utils/CustomItemIcon';
import CustomNavLink from '../utils/CustomNavLink';

const ItemLinkWrapper = ({ itemId, url, isMaster, icon, label, onClick }) => {
    const destination = useMemo(() => {
        let result = url;

        if ((typeof itemId !== 'string' || (itemId?.length && itemId[0] !== '?')) && url[url.length - 1] !== '/') {
            result += '/';
        }

        result += itemId;

        return result;
    }, [url, itemId]);

    return (
        <ItemNavLink to={destination} activeClassName="active-item" onClick={onClick}>
            <CustomItemIcon isMaster={isMaster} icon={icon} smallIcon={true} />

            <p>{label}</p>
        </ItemNavLink>
    );
};

const ItemNavLink = styled(CustomNavLink)`
    display: flex;
    align-items: center;
    padding: 6px 10px;
    flex-grow: 1;
    overflow: hidden;

    &:focus {
        outline: none;
    }

    > p {
        margin-left: var(--chakra-space-2);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--chakra-colors-text-secondary);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    &.active-item {
        > p {
            color: var(--chakra-colors-navigation-active-item-text);
        }
    }
`;

export default memo(ItemLinkWrapper);
