import { useRef } from 'react';
import {
    Box,
    Heading,
    Flex,
    Text,
    SimpleGrid,
    AccordionItem,
    AccordionPanel,
    Divider,
    useMediaQuery,
    MenuItem,
    useDisclosure,
    Button,
    IconButton,
} from '@chakra-ui/react';

import styled from '@emotion/styled/macro';

import { FormattedMessage, useIntl } from 'react-intl';

import useCommonToast from '../../hooks/useCommonToast';

import VariableStatisticsModal from './VariableStatisticsModal';

import SubItemActionsMenu from '../itemData/SubItemActionsMenu';

import Tooltip from '../utils/Tooltip';
import AccordionButton from '../utils/AccordionButton';

import { ReactComponent as VariableIcon } from '../../icons/variable-icon.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as CopyIcon } from '../../icons/copy-circled.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';

const VariableGroup = ({
    variableGroup,
    onVariableGroupEdit,
    onVariableGroupCopy,
    onVariableStatisticEdit,
    onVariableGroupDeselect,
}) => {
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const intl = useIntl();
    const { toast } = useCommonToast();

    const iterationsModal = useDisclosure();
    const selectedVariableId = useRef();

    const groupIncludesIterationVariable =
        variableGroup.variables.filter((variable) => variable.hasIterations).length > 0;

    const onVariableIterationModalOpen = (variableId) => {
        selectedVariableId.current = variableId;
        iterationsModal.onOpen();
    };

    const onVariableIterationModalClose = () => {
        iterationsModal.onClose();
    };

    const onVariableStatisticSuccess = (variableId, groupId, checkedCount, iterationChecked, meanChecked) => {
        onVariableStatisticEdit(variableId, groupId, checkedCount, iterationChecked, meanChecked);

        iterationsModal.onClose();

        const successMessage = intl.formatMessage({ id: 'run_a_study_iterations_save_success' });
        toast(successMessage);
    };
    const onVariableIterationError = (err) => {
        iterationsModal.onClose();
        toast({
            status: 'error',
            message: intl.formatMessage({ id: 'common_generic_saving_error' }),
        });
    };

    return (
        <AccordionItem>
            {({ isExpanded }) => (
                <>
                    <Flex
                        bg="background-tertiary"
                        border="1px solid"
                        borderColor="border-secondary"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Flex alignItems="center" ml={4}>
                            <AccordionButton />

                            <Heading as="h4" variant="h4">
                                {groupIncludesIterationVariable ? (
                                    <Text variant="highlighted">{variableGroup.description}</Text>
                                ) : (
                                    variableGroup.description
                                )}
                            </Heading>
                        </Flex>

                        <Flex alignItems="center">
                            {!isDesktop ? (
                                <SubItemActionsMenu
                                    menuListItems={
                                        <>
                                            <MenuItem
                                                key="edit-btn"
                                                textTransform="capitalize"
                                                onClick={() => onVariableGroupEdit(variableGroup)}
                                            >
                                                <FormattedMessage id="common_edit" />
                                            </MenuItem>

                                            <MenuItem
                                                key="copy-btn"
                                                textTransform="capitalize"
                                                onClick={() => onVariableGroupCopy(variableGroup.variableGroupId)}
                                            >
                                                <FormattedMessage id="common_copy" />
                                            </MenuItem>

                                            <MenuItem
                                                key="copy-btn"
                                                textTransform="capitalize"
                                                onClick={() => onVariableGroupDeselect(variableGroup.variableGroupId)}
                                            >
                                                <FormattedMessage id="variables_deselect_group" />
                                            </MenuItem>
                                        </>
                                    }
                                />
                            ) : (
                                <StyledWrapper>
                                    <TooltipWrapper>
                                        <Tooltip
                                            label={intl.formatMessage({
                                                id: 'variables_deselect_group',
                                            })}
                                        >
                                            <IconButton
                                                aria-label={intl.formatMessage({
                                                    id: 'variables_deselect_group',
                                                })}
                                                variant="circular-icon"
                                                icon={<StyledCloseIcon />}
                                                onClick={() => onVariableGroupDeselect(variableGroup.variableGroupId)}
                                            />
                                        </Tooltip>
                                    </TooltipWrapper>

                                    <TooltipWrapper>
                                        <Tooltip
                                            label={intl.formatMessage({
                                                id: 'common_copy_btn_tooltip_and_label',
                                            })}
                                        >
                                            <IconButton
                                                aria-label={intl.formatMessage({
                                                    id: 'common_copy_btn_tooltip_and_label',
                                                })}
                                                variant="circular-icon"
                                                icon={<CopyIcon />}
                                                onClick={() => onVariableGroupCopy(variableGroup.variableGroupId)}
                                            />
                                        </Tooltip>
                                    </TooltipWrapper>

                                    <Divider orientation="vertical" height="75px" />

                                    <TooltipWrapper>
                                        <Tooltip
                                            label={intl.formatMessage({
                                                id: 'common_edit_btn_tooltip_and_label',
                                            })}
                                        >
                                            <IconButton
                                                aria-label={intl.formatMessage({
                                                    id: 'common_edit_btn_tooltip_and_label',
                                                })}
                                                variant="circular-icon"
                                                icon={<EditIcon />}
                                                onClick={() => onVariableGroupEdit(variableGroup)}
                                            />
                                        </Tooltip>
                                    </TooltipWrapper>
                                </StyledWrapper>
                            )}
                        </Flex>
                    </Flex>

                    <AccordionPanel py={5} px={1}>
                        <SimpleGrid columns={4} spacing={5}>
                            {variableGroup.variables.map((variable) => {
                                if (variable.hasIterations) {
                                    return (
                                        <Box key={variable.variableId}>
                                            <Tooltip label={<FormattedMessage id="run_a_study_iterations_checked" />}>
                                                <Box display="flex">
                                                    <CustomVariableButton
                                                        leftIcon={<VariableIcon />}
                                                        type="button"
                                                        variant="secondary"
                                                        onClick={() =>
                                                            onVariableIterationModalOpen(variable.variableId)
                                                        }
                                                        size="sm"
                                                    >
                                                        <Text
                                                            variant="highlighted"
                                                            className={
                                                                variable.variableName.length >= 18
                                                                    ? variable.variableName.length >= 30
                                                                        ? 'xl_text'
                                                                        : 'l_text'
                                                                    : ''
                                                            }
                                                        >
                                                            {variable.variableName}
                                                        </Text>
                                                    </CustomVariableButton>
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    );
                                }

                                return (
                                    <Box key={variable.variableId} display="flex">
                                        <CustomVariableButton
                                            leftIcon={<VariableIcon />}
                                            type="button"
                                            variant="secondary"
                                            onClick={() => onVariableIterationModalOpen(variable.variableId)}
                                            size="sm"
                                        >
                                            <Text
                                                color="black"
                                                pl={2}
                                                className={
                                                    variable.variableName.length >= 18
                                                        ? variable.variableName.length >= 30
                                                            ? 'xl_text'
                                                            : 'l_text'
                                                        : ''
                                                }
                                            >
                                                {variable.variableName}
                                            </Text>
                                        </CustomVariableButton>
                                    </Box>
                                );
                            })}
                        </SimpleGrid>
                    </AccordionPanel>

                    {iterationsModal.isOpen && (
                        <VariableStatisticsModal
                            id={selectedVariableId.current}
                            group={variableGroup}
                            onClose={onVariableIterationModalClose}
                            onSuccess={onVariableStatisticSuccess}
                            onError={onVariableIterationError}
                        />
                    )}
                </>
            )}
        </AccordionItem>
    );
};

const CustomVariableButton = styled(Button)`
    background: none;
    &:hover {
        background: none;
    }
    padding: 0;

    .l_text {
        max-width: 200px;
        font-size: 10px;
    }
    .xl_text {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 10px;
    }
`;

const TooltipWrapper = styled.div`
    .tooltip_wrapper {
        transform: translate(-70%, 110%);
        &:focus {
            transform: translate(-70%, 110%);
        }
    }

    .tooltip_children_wrapper + div {
        transform: translate(-70%, 110%);
        &:focus {
            transform: translate(-70%, 110%);
        }
    }
`;

const StyledWrapper = styled(Flex)`
    align-items: center;

    > * {
        margin-right: 12px;
    }
`;

const StyledCloseIcon = styled(CloseIcon)`
    width: 32px;
    height: 32px;
    padding: 10px;
    background: var(--chakra-colors-gray-100);
    border-radius: 100%;
`;

export default VariableGroup;
