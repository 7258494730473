import { useIntl, FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';

import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
} from '@chakra-ui/react';

import useCommonToast from '../../hooks/useCommonToast';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import { isSubmitDisabled } from '../../services/utils';
import NumberInputFormikField from 'components/forms/NumberInputFormikField';
import { capitalizeFirstLetter } from '../../services/items';
import { FEATURE_FLAG_NAMES, useIsFeatureActive } from 'hooks/features-flags';
import { useState } from 'react';
import { SLICEOFDAY_PARTIAL_DELIVERABILITY } from 'constants/autoforms';

const RENEWABLES_ITEM_TYPE_DISPLAY_LABEL = 'renewable';

const AddSubRenewableModal = ({
    itemTypeId,
    itemType,
    definitions,
    formLabels,
    selectedRenewableId,
    onCreateSubItem,
    onAddSubItem,
    ...rest
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const typeLabel = RENEWABLES_ITEM_TYPE_DISPLAY_LABEL;
    const isCAMSliceOfDayVisible = useIsFeatureActive(FEATURE_FLAG_NAMES.CAM_SLICEOFDAY_FIELDS_VISIBLE);
    const [pcdsPercentDisabled, setPcdsPercent] = useState(true);

    const onDeliverabilityStatusChange = async (value) => {
        if (isCAMSliceOfDayVisible) {
            const selectedDeliverability = definitions?.deliverabilityStatusOptions
                ?.find((x) => x.id === value)
                ?.description?.trim()
                .toUpperCase();
            if (SLICEOFDAY_PARTIAL_DELIVERABILITY === selectedDeliverability) {
                setPcdsPercent(false);
            } else {
                setPcdsPercent(true);
            }
        }
    };

    const validateDescription = (value) => {
        const trimmed = value.trim();
        let errorMessage = '';

        if (trimmed.length === 0) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: `${capitalizeFirstLetter(typeLabel)} name` }
            );
        } else if (trimmed.length > 50) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: `${capitalizeFirstLetter(typeLabel)} name`, lengthRule: '50 characters or less' }
            );
        }

        return errorMessage;
    };

    const validateWeatherZone = (value) => {
        let errorMessage = '';

        if (value === '') {
            errorMessage = intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Weather zone' });
        }

        return errorMessage;
    };

    const validateDeliverability = (value) => {
        let errorMessage = '';

        if (!value) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: intl.formatMessage({ id: 'renewable_deliverability_status' }) }
            );
        }

        return errorMessage;
    };

    const initialFormValues = {
        description: '',
        weatherZoneId: '',
        moduleProcessId: 144, // This is default id for Default Wind Sim
        modelWeightId: 43, // This is default id for Flat Weight
        plantTypeId: null, // Not Required
        deliverabilityStatusId: null, // Not Required
        pcdsPercent: null, // Not Required, unless Partial Capacity is selected
    };

    const onFormSubmit = async ({
        description,
        weatherZoneId,
        moduleProcessId,
        modelWeightId,
        plantTypeId,
        deliverabilityStatusId,
        pcdsPercent,
    }) => {
        const payload = {
            description,
            weatherZoneId,
            moduleProcessId,
            modelWeightId,
            plantTypeId,
            itemId: selectedRenewableId,
            itemTypeId,
            deliverabilityStatusId,
            pcdsPercent,
        };

        const successMessage = intl.formatMessage({ id: 'common_sub_item_creation_success' }, { type: typeLabel });

        const tempSubItem = await onCreateSubItem(payload);

        onAddSubItem(tempSubItem);
        toast(successMessage);
    };

    return (
        <Modal {...rest}>
            <ModalOverlay />

            <ModalContent pt={3}>
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="common_item_add_subitem_modal_heading" values={{ item: typeLabel }} />
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik initialValues={initialFormValues} onSubmit={onFormSubmit}>
                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <ModalBody borderBottom="1px" borderColor="gray.200">
                                <SimpleGrid columns={{ xl: 3 }} spacingX={6} spacingY={4} mb={4}>
                                    <InputFormikField
                                        validate={(value) => validateDescription(value)}
                                        id="description"
                                        name="description"
                                        type="text"
                                        label={<FormattedMessage id="renewable_definitions_name" />}
                                        isRequired
                                    />

                                    <ComboboxFormikField
                                        id="moduleProcessId"
                                        name="moduleProcessId"
                                        label={formLabels.modelType}
                                        isRequired
                                        options={definitions.modelTypes}
                                        valueKey="id"
                                        labelKey="labelName"
                                        placeholderValue=""
                                        placeholderLabel={formLabels.selectPlaceholder}
                                    />

                                    <ComboboxFormikField
                                        id="weatherZoneId"
                                        name="weatherZoneId"
                                        label={formLabels.weatherZone}
                                        isRequired
                                        validate={(value) => validateWeatherZone(value)}
                                        options={definitions.weatherZones}
                                        labelKey="name"
                                        valueKey="id"
                                        placeholderValue=""
                                        placeholderLabel={formLabels.selectPlaceholder}
                                    />

                                    <ComboboxFormikField
                                        id="modelWeightId"
                                        name="modelWeightId"
                                        label={formLabels.dataWeight}
                                        isRequired
                                        options={definitions.dataWeightings}
                                        valueKey="id"
                                        labelKey="description"
                                        placeholderValue=""
                                        placeholderLabel={formLabels.selectPlaceholder}
                                    />

                                    {Array.isArray(definitions?.plantTypes) && (
                                        <ComboboxFormikField
                                            id="plantTypeId"
                                            name="plantTypeId"
                                            label={formLabels.plantType}
                                            options={definitions?.plantTypes}
                                            valueKey="id"
                                            labelKey="description"
                                            placeholderLabel="-"
                                            placeholderValue={null}
                                            showPlaceholder={true}
                                        />
                                    )}
                                    {isCAMSliceOfDayVisible && (
                                        <>
                                            <ComboboxFormikField
                                                id={'deliverabilityStatusId'}
                                                name={'deliverabilityStatusId'}
                                                label={<FormattedMessage id="renewable_deliverability_status" />}
                                                options={definitions.deliverabilityStatusOptions}
                                                validate={(value) => validateDeliverability(value)}
                                                labelKey="description"
                                                valueKey="id"
                                                onChange={onDeliverabilityStatusChange}
                                                placeholderValue={null}
                                                showPlaceholder={true}
                                            />
                                            <NumberInputFormikField
                                                id={'pcdsPercent'}
                                                name={'pcdsPercent'}
                                                keepWithinRange={true}
                                                min={0}
                                                max={100}
                                                step={1}
                                                label={<FormattedMessage id="renewable_pcds_percent" />}
                                                isDisabled={pcdsPercentDisabled}
                                                isRequired={!pcdsPercentDisabled}
                                            />
                                        </>
                                    )}
                                </SimpleGrid>
                            </ModalBody>

                            <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                <Box ml="auto">
                                    <Button variant="secondary" onClick={rest.onClose}>
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id="common_cancel" />
                                        </Box>
                                    </Button>

                                    <Button
                                        type="submit"
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        ml={3}
                                    >
                                        <FormattedMessage id="common_item_create_btn" />
                                    </Button>
                                </Box>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default AddSubRenewableModal;
