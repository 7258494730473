import { FormattedMessage } from 'react-intl';
import { Box } from '@chakra-ui/react';

import ConfirmationModal from '../../modal/ConfirmationModal';

const DeleteSubParameterModal = ({ headerMessageId, contentMessageItemId, ...rest }) => {
    return (
        <ConfirmationModal
            isOpen
            header={<FormattedMessage id={headerMessageId} />}
            hasExtraStep
            content={<FormattedMessage id={contentMessageItemId} />}
            confirmText={
                <Box as="span" textTransform="capitalize">
                    <FormattedMessage id="common_delete" />
                </Box>
            }
            {...rest}
        />
    );
};

export default DeleteSubParameterModal;
