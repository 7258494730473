import React, { useMemo, useState } from 'react';
import { CloseButton, Divider, Flex, Heading, SimpleGrid, Box, Text } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { FormattedMessage, useIntl } from 'react-intl';

import { FieldArray, useField, useFormikContext } from 'formik';

import ComboboxFormikField from '../forms/ComboboxFormikField';

import { INPUT_TYPES } from '../../constants/fields';
import { FILTER_DATA_TYPES } from '../../constants/autoforms';

import SecondaryButton from '../utils/SecondaryButton';
import AutoformInputFormikField from './AutoformInputFormikField';
import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import { parseColumnSettings, parseDateFormat, parseNumberFormat } from './utils/autoformUtils';

/**
 *
 * @param {number} index - form's index
 * @param {object[]} visibleCol - visible columns
 * @param {string} tableId - target table id
 * @param {function} onRemove - "remove" handler
 * @param {boolean} hideDivider - the divider's presence flag
 * @param {boolean} showRemoveButton - the remove button's presence flag
 * @returns {*}
 */
const ArrayForms = ({ index, visibleCol, tableId, onRemove, hideDivider, showRemoveButton }) => {
    const { dropdownOptions } = useAutoformTableMetadata(tableId);
    const intl = useIntl();
    const [{ value: colValue }] = useField(`customFilters.${index}.columnId`);
    const [, , { setValue: setOperation }] = useField(`customFilters.${index}.operation`);
    const [, , { setValue: setFilterValue }] = useField(`customFilters.${index}.filterValue`);
    const [options, setOptions] = useState('');

    const columnInputType = useMemo(() => visibleCol.find((col) => col.id === colValue), [colValue, visibleCol]);

    const columnOptions = useMemo(() => {
        let result = [];
        if (columnInputType) {
            result = FILTER_DATA_TYPES.filter((dataTypes) => {
                if (
                    columnInputType.inputTypeDescription === INPUT_TYPES.ComboBox ||
                    columnInputType.inputTypeDescription === INPUT_TYPES.CheckBox
                ) {
                    return dataTypes.validFor.includes(columnInputType.inputTypeDescription);
                }
                return dataTypes.validFor.includes(
                    columnInputType.dataTypeDescription || columnInputType.inputTypeDescription
                );
            }).map((options) => ({
                key: options.filterOperation,
                value: intl.formatMessage({ id: options.translationKey }),
            }));
        }
        return result;
    }, [columnInputType, intl]);

    const getInputType = useMemo(() => {
        if (!columnInputType) {
            return;
        }

        const column = Object.assign({}, columnInputType);

        if (column.inputTypeDescription === INPUT_TYPES.ComboBox) {
            setOptions(dropdownOptions[column.id]);
        }

        if (column.inputTypeDescription === INPUT_TYPES.CheckBox) {
            column.inputTypeDescription = INPUT_TYPES.ComboBox;
            setOptions([
                { value: '1', text: intl.formatMessage({ id: 'filter_option_true' }) },
                { value: '0', text: intl.formatMessage({ id: 'filter_option_false' }) },
            ]);
        }

        if (column.inputTypeDescription === INPUT_TYPES.ProgressBar) {
            column.inputTypeDescription = INPUT_TYPES.TextBox;
        }

        if (column.inputTypeDescription === INPUT_TYPES.MonthCalendar) {
            column['isInPortal'] = true;
        }

        return column;
    }, [columnInputType, dropdownOptions, intl]);

    const onColIdChange = (v) => {
        setFilterValue('');
        setOperation('');
    };

    const settings = parseColumnSettings(getInputType?.settings);
    const dateFormat = getInputType && parseDateFormat(getInputType);
    const numberFormat = getInputType && parseNumberFormat(getInputType);

    return (
        <>
            <SimpleGrid my={2} columns={{ xl: 1 }} spacingX={2} spacingY={4} w="100%">
                <Flex justifyContent="space-between">
                    <Heading as="h4" variant="h4">
                        <FormattedMessage id="common_item_custom_filter" />
                    </Heading>
                    {showRemoveButton && <CloseButton onClick={() => onRemove(index)} />}
                </Flex>

                <ComboboxFormikField
                    id="ids"
                    name={`customFilters.${index}.columnId`}
                    label={<FormattedMessage id="common_column_selection_filter" />}
                    options={visibleCol}
                    onChange={onColIdChange}
                    valueKey="id"
                    showPlaceholder={true}
                    placeholderValue=""
                    labelKey="displayName"
                />

                <ComboboxFormikField
                    id="keys"
                    name={`customFilters.${index}.operation`}
                    label={<FormattedMessage id="common_column_criteria_filter" />}
                    options={columnOptions}
                    valueKey="key"
                    labelKey="value"
                    placeholderValue=""
                    isRequired={!!colValue}
                />

                <AutoformInputFormikField
                    inputName={`customFilters.${index}.filterValue`}
                    inputType={getInputType?.inputTypeDescription}
                    inputLabel={<FormattedMessage id="common_column_value_filter" />}
                    placeholder={getInputType?.displayName}
                    dataType={getInputType?.dataTypeDescription}
                    settings={settings}
                    formats={{
                        dateFormat,
                        numberFormat,
                    }}
                    dropdownOptions={options}
                    isRequired={!!colValue}
                    isDisabled={!columnInputType}
                    isInPortal={getInputType?.isInPortal}
                />
            </SimpleGrid>

            {hideDivider ? null : (
                <Box position="relative">
                    <Divider my={4} />

                    <StyledDelimiterLabel size="sm" color="gray.600">
                        <FormattedMessage id="common_and" />
                    </StyledDelimiterLabel>
                </Box>
            )}
        </>
    );
};

/**
 * Custom filters for tables displayed as AG Grids.
 *
 * @param {object[]} columns - Column metadata for the table.
 * @param {string} tableId
 *
 */
const CustomFilter = ({ columns, tableId }) => {
    const { values } = useFormikContext();

    const visibleColumns = useMemo(() => columns.map((col) => col.isVisible && col), [columns]);

    return (
        <>
            <SimpleGrid mt={4} columns={{ xl: 1 }} spacingX={6} spacingY={4} w="100%">
                <FieldArray name="customFilters">
                    {({ insert, remove, push }) => (
                        <>
                            {values.customFilters.length > 0 &&
                                values.customFilters.map((_, index) => (
                                    <ArrayForms
                                        key={index}
                                        tableId={tableId}
                                        index={index}
                                        visibleCol={visibleColumns}
                                        onRemove={remove}
                                        showRemoveButton={values.customFilters.length > 1}
                                        hideDivider={index === values.customFilters.length - 1}
                                    />
                                ))}
                            <SecondaryButton
                                type="button"
                                variant="secondary"
                                size="md"
                                mb={{ base: 4, xl: 0 }}
                                onClick={() => push({ columnId: '', operation: '', filterValue: '' })}
                            >
                                <Box as="span" textTransform="capitalize">
                                    <FormattedMessage id="common_add_new" />
                                </Box>
                            </SecondaryButton>
                        </>
                    )}
                </FieldArray>
            </SimpleGrid>
        </>
    );
};

const StyledDelimiterLabel = styled(Text)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--chakra-colors-white);
    padding: 0 var(--chakra-space-4);
    text-transform: uppercase;
`;

export default CustomFilter;
