import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    authState: '',
    connection: null,
    connectionTitle: '',
    environment: null,
    environmentTitle: '',
    book: null,
    bookTitle: '',
    schemaGroupId: '',
    isDefaultConfig: false,
    versionName: '',
};

const connectionSlice = createSlice({
    name: 'connection',
    initialState,
    reducers: {
        setAccountConnection(state, action) {
            const {
                connection,
                connectionTitle,
                environment,
                environmentTitle,
                book,
                bookTitle,
                schemaGroupId,
                isDefaultConfig,
                versionName,
            } = action.payload;

            state.connection = connection;
            state.connectionTitle = connectionTitle;
            state.environment = environment;
            state.environmentTitle = environmentTitle;
            state.book = book;
            state.bookTitle = bookTitle;
            state.schemaGroupId = schemaGroupId;
            state.isDefaultConfig = isDefaultConfig;
            state.versionName = versionName;
        },
        setAuthState(state, action) {
            state.authState = action.payload;
        },
    },
});

export const { setAccountConnection, setAuthState } = connectionSlice.actions;
export default connectionSlice.reducer;
