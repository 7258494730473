import { useEffect, useState } from 'react';

const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            const item = window.localStorage.getItem(key);
            if (item !== null) {
                return JSON.parse(item);
            }
            localStorage.setItem(key, JSON.stringify(initialValue));
            window.dispatchEvent(new Event('storage'));
            return initialValue;
        } catch (error) {
            return initialValue;
        }
    });
    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            throw new Error('local storage error');
        }
    };

    useEffect(() => {
        localStorage.setItem(key, storedValue);
        window.dispatchEvent(new Event('storage'));
    }, [key, storedValue]);

    useEffect(() => {
        const listenStorageChange = () => {
            setStoredValue(() => {
                if (typeof window === 'undefined') {
                    return initialValue;
                }
                try {
                    const item = window.localStorage.getItem(key);
                    if (item !== null) {
                        return JSON.parse(item);
                    }
                    localStorage.setItem(key, JSON.stringify(initialValue));
                    window.dispatchEvent(new Event('storage'));
                    return initialValue;
                } catch (error) {
                    return initialValue;
                }
            });
        };
        window.addEventListener('storage', listenStorageChange);
        return () => window.removeEventListener('storage', listenStorageChange);
    }, [initialValue, key]);

    return [storedValue, setValue];
};

export default useLocalStorage;
