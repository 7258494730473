import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUserRestorationPermission = createAsyncThunk('getUserRestorationPermission', async () => {
    const response = await axios.get(`/restoration-jobs/can-use-restoration`);

    return response.data;
});

export const getUserInputDataExtractionPermission = createAsyncThunk(
    'getUserInputDataExtractionPermission',
    async () => {
        const response = await axios.get(`/restoration-jobs/can-use-input-data-extract`);

        return response.data;
    }
);
