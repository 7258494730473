import { useState, useEffect, useMemo } from 'react';
import { getUsersForRole } from '../../services/userManagement';

const useGroupUsers = (roleId) => {
    const [groupUsers, setGroupUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (roleId) {
            setIsLoading(true);
            setIsError(false);

            getUsersForRole(roleId)
                .then((response) => {
                    setGroupUsers(response);
                })
                .catch(() => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [roleId]);

    return useMemo(
        () => ({
            groupUsers,
            isLoading,
            isError,
            setGroupUsers,
        }),
        [groupUsers, isLoading, isError]
    );
};
export default useGroupUsers;
