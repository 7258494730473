import { Flex, Text, IconButton, Spacer } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as AddItemIcon } from '../../icons/item-icons/add-item.svg';

const AddSubItem = ({ onOpen, isDisabled = false, messageId }) => {
    const intl = useIntl();

    return (
        <Flex
            px={6}
            py={3}
            mt="-1px"
            bg="background-octenary"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="border-secondary"
            align="center"
        >
            <Text size="sm" variant="bold" color="black">
                {<FormattedMessage id={messageId ?? 'common_item_add_subitem'} />}
            </Text>

            <Spacer />

            <StyledIconButton
                aria-label={intl.formatMessage({ id: messageId ?? 'common_item_add_subitem' })}
                icon={<AddItemIcon />}
                isDisabled={isDisabled}
                onClick={onOpen}
                variant="circular-icon"
            />
        </Flex>
    );
};

const StyledIconButton = styled(IconButton)`
    background: none;
    border-radius: var(--chakra-radii-base);

    &:hover {
        background: none;

        svg path {
            fill: var(--chakra-colors-blue-400);
        }
    }

    svg path {
        fill: var(--chakra-colors-gray-600);
    }
`;

export default AddSubItem;
