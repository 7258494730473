import { FormattedMessage } from 'react-intl';
import { Box } from '@chakra-ui/react';

import ConfirmationModal from '../../modal/ConfirmationModal';

const PeriodOverlapModal = ({ modal, onConfirmCallback }) => {
    return (
        <>
            {modal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={modal.onClose}
                    header={<FormattedMessage id="batteries_timeline_overlap_modal_title" />}
                    hasExtraStep
                    content={<FormattedMessage id="batteries_timeline_overlap_modal_description" />}
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_continue" />
                        </Box>
                    }
                    onConfirm={onConfirmCallback}
                />
            )}
        </>
    );
};

export default PeriodOverlapModal;
