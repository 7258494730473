import PortfolioAutoformContainer from 'components/autoform/PortfolioAutoformContainer';
import ShapeSetSubItem from 'components/shapeSet/ShapeSetSubItem';

const ShapeSetPortfolioContainer = ({
    autoformId,
    itemId,
    onNodeRename,
    onNodeChangeIsMaster,
    onNodeItemDelete,
    portfolioItem,
    onNodeOwnershipPercentageUpdate,
}) => {
    return (
        <PortfolioAutoformContainer
            autoformId={autoformId}
            config={{
                renderSubItem: (item) => {
                    return <ShapeSetSubItem item={item} />;
                },
            }}
            portfolioItem={portfolioItem}
            itemId={itemId}
            onNodeRename={onNodeRename}
            onNodeChangeIsMaster={onNodeChangeIsMaster}
            onNodeItemDelete={onNodeItemDelete}
            onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
        />
    );
};

export default ShapeSetPortfolioContainer;
