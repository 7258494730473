import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

/**
 * DEPRECATED: use SecondaryButton instead
 * This changes the width based on media query and adds extra right margin
 *
 * @deprecated
 */
const SecondaryIconButton = styled(Button)`
    text-transform: capitalize;
    width: 100%;

    @media (min-width: 1280px) {
        width: auto;
    }

    span {
        margin-right: 4px;

        svg path {
            fill: var(--chakra-colors-gray-600);
        }
    }

    &:disabled {
        background: var(--chakra-colors-gray-100);
        color: var(--chakra-colors-gray-400);

        span svg path {
            fill: var(--chakra-colors-gray-400);
        }

        &:hover {
            background: var(--chakra-colors-gray-100);

            span svg path {
                fill: var(--chakra-colors-gray-400);
            }
        }
    }

    &:hover span svg path {
        fill: var(--chakra-colors-blue-400);
    }
`;

export default SecondaryIconButton;
