import { useState, useEffect, useCallback, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Spacer, Skeleton, Box, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import Tree from '../react-tree/Tree';
import styled from '@emotion/styled/macro';

import { getNodeIds, getAssignableItems, addItemsToPortfolio } from '../../services/portfolios';

import PortfolioTreeWrapper from './PortfolioTreeWrapper';
import PortfolioNodeIcon from './PortfolioNodeIcon';
import PortfolioArrowIcon from './PortfolioArrowIcon';
import MainModal from '../modal/MainModal';
import SearchBar from '../utils/SearchBar';
import Tooltip from '../utils/Tooltip';

import CustomCheckbox from '../forms/CustomCheckbox';

import { ReactComponent as RemoveIcon } from '../../icons/remove.svg';

const AddItemsPortfolioModal = ({ onClose, source, onAddSuccess, onAddError }) => {
    const intl = useIntl();

    const [isLoadingError, setIsLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [nodes, setNodes] = useState([]);
    const [filteredNodes, setFilteredNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [focused, setFocused] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);

    const [search, setSearch] = useState('');

    useEffect(() => {
        setIsLoading(true);

        getAssignableItems()
            .then((items) => {
                setFocused(items[0].id);
                setNodes(items);
                setFilteredNodes(items);
            })
            .catch(() => {
                setIsLoadingError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        const term = search.trimStart().toLowerCase();

        if (term && term.length >= 2) {
            const data = nodes.reduce((acc, group) => {
                const filtered = group.nodes.filter((node) => node.properties.name.toLowerCase().includes(term));

                if (filtered.length > 0) {
                    acc.push({
                        ...group,
                        nodes: filtered,
                    });
                }

                return acc;
            }, []);
            const ids = getNodeIds(data);

            setFilteredNodes(data);
            setExpanded(ids);
        } else {
            setFilteredNodes(nodes);
            setExpanded([]);
        }
    }, [search]); // eslint-disable-line

    const addItems = async () => {
        try {
            setIsSaving(true);
            const items = await addItemsToPortfolio(source.properties.id, selectedItems, {
                suppressErrorMessageHandler: true,
            });

            const updatedPortfolio = {
                ...source,
                nodes: items.concat(source.nodes.filter((node) => node.isFolder)),
            };

            setIsSaving(false);
            onAddSuccess(updatedPortfolio, selectedItems);
        } catch (error) {
            setIsSaving(false);
            onAddError(error);
        }
    };

    const itemsInSelectedPortfolio = useMemo(() => {
        return source.nodes
            .filter((node) => !node.isFolder && node.nodes.length > 0)
            .flatMap((group) => group.nodes.map((item) => item.properties.id));
    }, [source]);

    const onAddItemSelectChange = useCallback(
        (node) => {
            const id = node.id;

            const isItemGroup = nodes.some((group) => group.id === node.id);
            const isInSelectedPortfolio = itemsInSelectedPortfolio.includes(parseInt(node.id, 10));

            if (isItemGroup || isInSelectedPortfolio) {
                return;
            }

            setSelectedItems((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : prev.concat(id)));
        },
        [nodes, itemsInSelectedPortfolio]
    );

    const onExpandChange = useCallback(({ id }) => {
        setExpanded((prev) => (prev.includes(id) ? prev.filter((node) => node !== id) : prev.concat(id)));
    }, []);

    const onFocusChange = useCallback(({ id }) => {
        setFocused(id);
    }, []);

    const onClickToggleExpandCollapse = useCallback((node) => {
        // Expand or collapse a node on label click
        if (node?.isExpandable) {
            setExpanded((prev) =>
                prev.includes(node.id) ? prev.filter((id) => id !== node.id) : prev.concat(node.id)
            );
            setFocused(node.id);
        }
    }, []);

    const renderAddItemsLabel = useCallback(
        (node) => {
            const isInSelectedPortfolio = itemsInSelectedPortfolio.includes(parseInt(node.properties.id, 10));
            const isSelected = selectedItems.includes(node.id);

            return (
                <div
                    className={`${node?.isExpandable ? '' : 'non-expandable '}portfolio-label-wrapper`}
                    onClick={() => onClickToggleExpandCollapse(node)}
                >
                    {node?.isExpandable && (
                        <span>
                            <PortfolioArrowIcon isExpanded={node?.isExpanded} isMaster={node.properties.isMaster} />
                        </span>
                    )}

                    <div
                        className="portfolio-label"
                        onClick={(event) => {
                            if (event.shiftKey && node?.isExpandable) {
                                // parent div will take care of expand/collapse
                            } else {
                                onAddItemSelectChange(node);
                            }
                        }}
                    >
                        <PortfolioNodeIcon
                            isFolder={node.isFolder}
                            isExpanded={node?.isExpanded}
                            isMaster={node.properties.isMaster}
                            typeId={node.properties.typeId}
                        />

                        <span>{node.properties.name}</span>
                    </div>

                    {node.nodes.length === 0 && (
                        <CustomCheckbox
                            tabIndex={-1}
                            onChange={() => onAddItemSelectChange(node)}
                            isDisabled={isInSelectedPortfolio}
                            isChecked={isInSelectedPortfolio ? true : isSelected}
                        />
                    )}
                </div>
            );
        },
        [itemsInSelectedPortfolio, selectedItems, onAddItemSelectChange, onClickToggleExpandCollapse]
    );
    return (
        <MainModal
            scrollBehavior="inside"
            isOpen
            onClose={onClose}
            header={
                <FormattedMessage id="portfolios_add_items_to_portfolio" values={{ name: source.properties.name }} />
            }
            content={
                <>
                    <SearchBar
                        textHolder={intl.formatMessage({ id: 'portfolios_search_items' })}
                        onChange={(value) => setSearch(value)}
                    />

                    <Spacer mb={5} />

                    <PortfolioTreeWrapper isDisplayedInModal isDisabled={isSaving}>
                        {isLoading ? (
                            Array.from({ length: 15 }).map((_, index) => (
                                <Skeleton key={index}>
                                    <Box h="34px" mt={2}>
                                        <FormattedMessage id="common_loading" />
                                    </Box>
                                </Skeleton>
                            ))
                        ) : isLoadingError ? (
                            <Box mx={6}>
                                <Alert status="error">
                                    <AlertIcon />

                                    <Box>
                                        <AlertTitle textTransform="capitalize">
                                            <FormattedMessage id="common_error" />
                                        </AlertTitle>

                                        <AlertDescription>
                                            <FormattedMessage id="common_loading_error" values={{ items: 'items' }} />
                                        </AlertDescription>
                                    </Box>
                                </Alert>
                            </Box>
                        ) : (
                            <Tree
                                aria-label={intl.formatMessage({ id: 'common_items' })}
                                nodes={filteredNodes}
                                focused={focused}
                                onFocusChange={onFocusChange}
                                expanded={expanded}
                                onExpandChange={onExpandChange}
                                onSelectChange={onAddItemSelectChange}
                                renderLabel={renderAddItemsLabel}
                                expandOnSelect={false}
                            />
                        )}
                    </PortfolioTreeWrapper>
                </>
            }
            footerLeftSlot={
                <div>
                    {selectedItems.length > 0 && (
                        <Tooltip label={intl.formatMessage({ id: 'common_unselect_all' })}>
                            <StyledSelectedItemsButton
                                isDisabled={isSaving}
                                onClick={() => setSelectedItems([])}
                                ml="-14px"
                                size="sm"
                                leftIcon={<RemoveIcon />}
                                variant="ghost"
                            >
                                <FormattedMessage id="common_items_selected" values={{ n: selectedItems.length }} />
                            </StyledSelectedItemsButton>
                        </Tooltip>
                    )}
                </div>
            }
            footerRightSlot={
                <Button isLoading={isSaving} isDisabled={selectedItems.length === 0} onClick={addItems} ml={3}>
                    <FormattedMessage id="common_add_to_portfolio_btn" />
                </Button>
            }
        />
    );
};

const StyledSelectedItemsButton = styled(Button)`
    @media screen and (max-width: 375px) {
        margin-bottom: var(--chakra-space-3);
    }
`;

export default AddItemsPortfolioModal;
