import { useState, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import { Button, Spacer, Skeleton, Box, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import Tree from '../react-tree/Tree';
import styled from '@emotion/styled/macro';

import { getNodeIds, getPortfolioItemsForTreeView, removeMultipleItemsFromPortfolio } from '../../services/portfolios';

import PortfolioTreeWrapper from './PortfolioTreeWrapper';
import PortfolioNodeIcon from './PortfolioNodeIcon';
import PortfolioArrowIcon from './PortfolioArrowIcon';
import MainModal from '../modal/MainModal';
import SearchBar from '../utils/SearchBar';
import Tooltip from '../utils/Tooltip';

import CustomCheckbox from '../forms/CustomCheckbox';

import { ReactComponent as RemoveIcon } from '../../icons/remove.svg';

const RemoveItemsFromPortfolioModal = ({ onClose, source, onRemoveSuccess, onRemoveError }) => {
    const intl = useIntl();

    const [isLoadingError, setIsLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [nodes, setNodes] = useState([]);
    const [filteredNodes, setFilteredNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [focused, setFocused] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItemTypes, setSelectedItemTypes] = useState([]);

    // for item types that have some items selected but not all items to show a third check box state
    const [indeterminateItemTypes, setIndeterminateItemTypes] = useState([]);

    const portfolioId = source.properties.id;

    useEffect(() => {
        setIsLoading(true);

        getPortfolioItemsForTreeView(portfolioId)
            .then((items) => {
                if (!items || items.length === 0) {
                    // Portfolio has no items
                    return;
                }
                setFocused(items[0].id);
                setNodes(items);
                setFilteredNodes(items);
            })
            .catch(() => {
                setIsLoadingError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [portfolioId]);

    const removeItems = async () => {
        try {
            setIsSaving(true);
            const ids = selectedItems?.map((itemId) => parseInt(itemId, 10));

            await removeMultipleItemsFromPortfolio(portfolioId, ids);

            // source.nodes is an arry of item types that had assigned items
            let updatedPortfolioItemTypes = source.nodes.map((itemType) => {
                // filter out removed items
                const items = itemType.nodes?.filter((item) => !ids.includes(item.properties.id));
                const updatedItemType = { ...itemType, nodes: items };
                return updatedItemType;
            });

            // filter out item types that had all items removed
            updatedPortfolioItemTypes = updatedPortfolioItemTypes.filter((itemType) => itemType.nodes?.length > 0);

            const updatedPortfolio = {
                ...source,
                nodes: updatedPortfolioItemTypes,
            };

            setIsSaving(false);
            onRemoveSuccess(updatedPortfolio, ids);
        } catch (error) {
            setIsSaving(false);
            onRemoveError(error);
        }
    };

    const onRemoveItemSelectChange = debounce((node) => {
        const nodeId = node.id;
        const typeId = node.properties.typeId;
        const isItemTypeGroup = nodes.some((itemTypeGroup) => itemTypeGroup.id === nodeId);

        let modifiedSelectedItemsIds = [...selectedItems];
        let modifiedSelectedItemTypesIds = [...selectedItemTypes];
        let modifiedIndeterminateItemTypesIds = [...indeterminateItemTypes];

        const itemTypeNode = isItemTypeGroup
            ? nodes.find((itemTypeGroup) => itemTypeGroup.id === nodeId)
            : nodes.find((itemTypeGroup) => itemTypeGroup.properties.typeId === typeId);

        const itemTypeGroupItemsIds = itemTypeNode?.nodes?.map((i) => i.id);

        const isNodeCurrentlyChecked = isItemTypeGroup
            ? selectedItemTypes.includes(nodeId)
            : selectedItems.includes(nodeId);

        const itemTypeNodeId = itemTypeNode.id;

        // remove item type from selected list, we will add it later if needed
        modifiedSelectedItemTypesIds = modifiedSelectedItemTypesIds.filter(
            (selectedId) => selectedId !== itemTypeNodeId
        );
        // remove item type from indeterminate list, we will added it later if needed
        modifiedIndeterminateItemTypesIds = modifiedIndeterminateItemTypesIds.filter(
            (selectedId) => selectedId !== itemTypeNodeId
        );

        // CustomCheckbox isIndeterminate property will display white minus sign as a third state
        // indicating to the user that some (but not all) items under item type group were selected/checked
        // if all items are selected then type check box should be selected/checked
        // if all items are unselected then type check box should be unchecked

        if (isItemTypeGroup) {
            // item type group node check box was clicked
            // if check box was checked - need to select all filtered items under that item type
            // if check box was unchecked - need to unselect all items under that item type

            // remove all type's items, we will add them later if needed
            modifiedSelectedItemsIds = modifiedSelectedItemsIds.filter(
                (selectedId) => !itemTypeGroupItemsIds.includes(selectedId)
            );

            if (!isNodeCurrentlyChecked) {
                // add item type to selected
                modifiedSelectedItemTypesIds = modifiedSelectedItemTypesIds.concat([itemTypeNodeId]);
                // add all filtered type's items
                const itemTypeFilteredNode = filteredNodes.find((itemTypeGroup) => itemTypeGroup.id === nodeId);
                const itemTypeGroupFilteredItemsIds = itemTypeFilteredNode?.nodes?.map((i) => i.id);
                modifiedSelectedItemsIds = modifiedSelectedItemsIds.concat(itemTypeGroupFilteredItemsIds);
            }
        } else {
            // single item node check box was clicked, item was selected or unselected
            // we need it's item type group as well to set appropriate check box state

            // remove an item from selected list, we will add it later if needed
            modifiedSelectedItemsIds = modifiedSelectedItemsIds.filter((selectedId) => selectedId !== nodeId);

            if (!isNodeCurrentlyChecked) {
                // if item was not already in selected list then add item
                modifiedSelectedItemsIds = modifiedSelectedItemsIds.concat(nodeId);
            }
        }

        const allTypeItemsSelected = itemTypeGroupItemsIds.every((i) => modifiedSelectedItemsIds.includes(i));
        const allTypeItemsUnSelected = itemTypeGroupItemsIds.every((i) => !modifiedSelectedItemsIds.includes(i));

        if (allTypeItemsSelected) {
            // add item type to selected
            modifiedSelectedItemTypesIds = modifiedSelectedItemTypesIds.concat([itemTypeNodeId]);
        } else if (!allTypeItemsUnSelected) {
            // neither all checked nor all unchecked -> some but not all type's items are selected/checked
            // set item type group's check box state to Indeterminate
            modifiedIndeterminateItemTypesIds = modifiedIndeterminateItemTypesIds.concat(itemTypeNodeId);
        }

        setSelectedItems(modifiedSelectedItemsIds);
        setSelectedItemTypes(modifiedSelectedItemTypesIds);
        setIndeterminateItemTypes(modifiedIndeterminateItemTypesIds);
    }, 150);

    const onExpandChange = useCallback(({ id }) => {
        setExpanded((prev) => (prev.includes(id) ? prev.filter((node) => node !== id) : prev.concat(id)));
    }, []);

    const onFocusChange = useCallback(({ id }) => {
        setFocused(id);
    }, []);

    const onSearchChange = debounce((value) => {
        const term = value?.trimStart().toLowerCase();

        if (term && term.length >= 2) {
            const data = nodes.reduce((acc, group) => {
                const filtered = group.nodes.filter((node) => node.properties.name.toLowerCase().includes(term));

                if (filtered?.length > 0) {
                    acc.push({
                        ...group,
                        nodes: filtered,
                    });
                }

                return acc;
            }, []);
            const ids = getNodeIds(data);

            setFilteredNodes(data);
            setExpanded(ids);
        } else {
            setFilteredNodes(nodes);
        }
    }, 150);

    const onClickToggleExpandCollapse = useCallback((node) => {
        // Expand or collapse a node on label click
        if (node?.isExpandable) {
            setExpanded((prev) =>
                prev.includes(node.id) ? prev.filter((id) => id !== node.id) : prev.concat(node.id)
            );
            setFocused(node.id);
        }
    }, []);

    const renderRemoveItemsLabel = useCallback(
        (node) => {
            const nodeid = node.id;
            const isSelected = selectedItems.includes(nodeid) || selectedItemTypes.includes(nodeid);
            const isIndeterminate = indeterminateItemTypes.includes(nodeid);

            return (
                <div
                    className={`${node?.isExpandable ? '' : 'non-expandable '}portfolio-label-wrapper`}
                    onClick={() => onClickToggleExpandCollapse(node)}
                >
                    {node?.isExpandable && (
                        <span>
                            <PortfolioArrowIcon isExpanded={node?.isExpanded} isMaster={node.properties.isMaster} />
                        </span>
                    )}

                    <div
                        className="portfolio-label"
                        onClick={(event) => {
                            if (event.shiftKey && node?.isExpandable) {
                                // parent div will take care of expand/collapse
                            } else {
                                !node?.isExpandable && onRemoveItemSelectChange(node);
                            }
                        }}
                    >
                        <PortfolioNodeIcon
                            isFolder={node.isFolder}
                            isExpanded={node?.isExpanded}
                            isMaster={node.properties.isMaster}
                            typeId={node.properties.typeId}
                        />

                        <span>{node.properties.name}</span>
                    </div>

                    <CustomCheckbox
                        tabIndex={-1}
                        onChange={() => onRemoveItemSelectChange(node)}
                        isChecked={isSelected}
                        isIndeterminate={isIndeterminate}
                    />
                </div>
            );
        },
        [
            selectedItems,
            selectedItemTypes,
            indeterminateItemTypes,
            onClickToggleExpandCollapse,
            onRemoveItemSelectChange,
        ]
    );
    return (
        <MainModal
            scrollBehavior="inside"
            isOpen
            onClose={onClose}
            header={
                <FormattedMessage
                    id="portfolios_remove_items_from_portfolio"
                    values={{ name: source.properties.name }}
                />
            }
            content={
                <>
                    <SearchBar
                        textHolder={intl.formatMessage({ id: 'portfolios_search_items' })}
                        onChange={onSearchChange}
                    />

                    <Spacer mb={5} />

                    <PortfolioTreeWrapper isDisplayedInModal isDisabled={isSaving}>
                        {isLoading ? (
                            Array.from({ length: 15 }).map((_, index) => (
                                <Skeleton key={index}>
                                    <Box h="34px" mt={2}>
                                        <FormattedMessage id="common_loading" />
                                    </Box>
                                </Skeleton>
                            ))
                        ) : isLoadingError ? (
                            <Box mx={6}>
                                <Alert status="error">
                                    <AlertIcon />

                                    <Box>
                                        <AlertTitle textTransform="capitalize">
                                            <FormattedMessage id="common_error" />
                                        </AlertTitle>

                                        <AlertDescription>
                                            <FormattedMessage id="common_loading_error" values={{ items: 'items' }} />
                                        </AlertDescription>
                                    </Box>
                                </Alert>
                            </Box>
                        ) : (
                            <Tree
                                aria-label={intl.formatMessage({ id: 'common_items' })}
                                nodes={filteredNodes}
                                focused={focused}
                                onFocusChange={onFocusChange}
                                expanded={expanded}
                                onExpandChange={onExpandChange}
                                onSelectChange={onRemoveItemSelectChange}
                                renderLabel={renderRemoveItemsLabel}
                                expandOnSelect={false}
                            />
                        )}
                    </PortfolioTreeWrapper>
                </>
            }
            footerLeftSlot={
                <div>
                    {selectedItems?.length > 0 && (
                        <Tooltip label={intl.formatMessage({ id: 'common_unselect_all' })}>
                            <StyledSelectedItemsButton
                                isDisabled={isSaving}
                                onClick={() => {
                                    setSelectedItems([]);
                                    setSelectedItemTypes([]);
                                    setIndeterminateItemTypes([]);
                                }}
                                ml="-14px"
                                size="sm"
                                leftIcon={<RemoveIcon />}
                                variant="ghost"
                            >
                                <FormattedMessage
                                    id="common_items_selected"
                                    values={{
                                        n: selectedItems?.length,
                                    }}
                                />
                            </StyledSelectedItemsButton>
                        </Tooltip>
                    )}
                </div>
            }
            footerRightSlot={
                <Button isLoading={isSaving} isDisabled={selectedItems?.length === 0} onClick={removeItems} ml={3}>
                    <FormattedMessage id="common_remove_from_portfolio_btn" />
                </Button>
            }
        />
    );
};

const StyledSelectedItemsButton = styled(Button)`
    @media screen and (max-width: 375px) {
        margin-bottom: var(--chakra-space-3);
    }
`;

export default RemoveItemsFromPortfolioModal;
