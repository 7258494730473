import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';

import { Formik, Form } from 'formik';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import { createItem } from '../../services/items';

import ComboboxFormikField from '../forms/ComboboxFormikField';
import InputFormikField from '../forms/InputFormikField';
import { isSubmitDisabled } from '../../services/utils';

const AddMainMarketModal = ({ basisId, ...props }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();

    const books = useSelector((state) => state.item.dataForItemCreation.books);
    const book = useSelector((state) => state.connections.book);
    const locations = useSelector((state) => state.item.dataForItemCreation.locations);

    const { itemTypes } = useSelector((state) => state.item);
    const { itemTypeId } = itemTypes.length > 0 && itemTypes.find((item) => item.description === 'Market Price Model');

    const formLabels = {
        name: intl.formatMessage({ id: 'basis_configurator_name' }),
        book: intl.formatMessage({ id: 'common_book' }),
        location: intl.formatMessage({ id: 'common_location' }),
    };

    const initialFormValues = {
        name: '',
        bookId: book,
        locationId: '',
    };

    const validateItemName = (value) => {
        const trimmed = value.trim();

        if (!trimmed) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Main Model Name' });
        }

        if (trimmed.length > 50) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Main Model Name', lengthRule: '50 characters or less' }
            );
        }
    };

    const onFormSubmit = async ({ name, locationId, bookId }) => {
        const successMessage = intl.formatMessage({ id: 'main_market_model_successful_creation' });

        const { data } = await createItem({
            description: name,
            locationId: locationId || null,
            bookId,
            itemTypeId,
        });

        toast(successMessage);
        historyPush(`/main-market/${data.id}`);
    };

    return (
        <Modal {...props}>
            <ModalOverlay />

            <ModalContent pt={3}>
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="basis_configurator_add_new_main_market_item" />
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik enableReinitialize initialValues={initialFormValues} onSubmit={onFormSubmit}>
                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <ModalBody borderBottom="1px" borderColor="border-secondary">
                                <InputFormikField
                                    name="name"
                                    type="text"
                                    label={formLabels.name}
                                    validate={validateItemName}
                                    isRequired
                                    isFastField
                                    mb={5}
                                />

                                <ComboboxFormikField
                                    id="locationId"
                                    name="locationId"
                                    label={formLabels.location}
                                    options={locations}
                                    labelKey="description"
                                    valueKey="id"
                                    showPlaceholder={true}
                                    placeholderValue=""
                                    isFastField
                                    mb={5}
                                />

                                <ComboboxFormikField
                                    id="boodId"
                                    name="bookId"
                                    label={formLabels.book}
                                    options={books}
                                    labelKey="name"
                                    valueKey="id"
                                    isFastField
                                    mb={5}
                                />
                            </ModalBody>

                            <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                <Box ml="auto">
                                    <Button variant="secondary" onClick={props.onClose}>
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id="common_cancel" />
                                        </Box>
                                    </Button>

                                    <Button
                                        type="submit"
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        ml={3}
                                        textTransform="capitalize"
                                    >
                                        <FormattedMessage id="common_continue" />
                                    </Button>
                                </Box>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default AddMainMarketModal;
