import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs, TabPanels, TabPanel, TabList, Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import AutoformTableContainer from '../autoform/AutoformTableContainer';
import AutoformItemFields from '../autoform/AutoformItemFields';
import GeneralValidationPanelContainer from '../autoform/GeneralValidationPanelContainer';

import TabListWrapper from '../utils/TabListWrapper';

import { getInputFormName } from '../autoform/utils/autoformUtils';
import useAutoformMetadata from 'components/autoform/hooks/useAutoformMetadata';
import useAutoformTableMetadata from 'components/autoform/hooks/useAutoformTableMetadata';
import useAutoformTableData from 'components/autoform/hooks/useAutoformTableData';
import { CONSTRAINT_DETAILS_TABLE_ID, PORTFOLIO_CONSTRAINT_COLUMN_NAME } from '../../constants/globalConstraints';
import GlobalConstraintsComponentsTab from './GlobalConstraintsComponentsTab';

const GlobalConstraintsSubItem = ({
    item,
    editChildTableModal,
    setSelectedLevel3ItemDescription,
    setInitialValues,
}) => {
    const intl = useIntl();
    const { hierarchicalTables } = useAutoformMetadata();
    const level2Table = hierarchicalTables.level2[0];

    const { sortedColumns } = useAutoformTableMetadata(level2Table?.id);
    const { selectedItem } = useAutoformTableData(level2Table.id);
    const [portfolioConstraint, setPortfolioConstraint] = useState(false);

    const portfolioConstraintCol = useMemo(
        () => sortedColumns.find((col) => col.name === PORTFOLIO_CONSTRAINT_COLUMN_NAME),
        [sortedColumns]
    );

    useEffect(() => {
        if (portfolioConstraintCol && selectedItem) {
            const selectedPortfolioConstraint = selectedItem[portfolioConstraintCol.name];
            setPortfolioConstraint(!!selectedPortfolioConstraint);
        }
    }, [portfolioConstraintCol, sortedColumns, selectedItem]);

    const onChange = useCallback(
        (column) => (value) => {
            if (column.name === PORTFOLIO_CONSTRAINT_COLUMN_NAME) {
                setPortfolioConstraint(value);
            }
        },
        []
    );

    const itemFieldsConfig = useMemo(() => ({ onChange }), [onChange]);
    const autoformTableConfig = useMemo(
        () => ({
            childrenTablesColumnName: intl.formatMessage({ id: 'global_constraints_fuels_and_emission_types' }),
        }),
        [intl]
    );
    const onSelectItem = useCallback(
        (item) => {
            setSelectedLevel3ItemDescription(item.description);
            editChildTableModal.onOpen();
        },
        [setSelectedLevel3ItemDescription, editChildTableModal]
    );

    return (
        <>
            <GeneralValidationPanelContainer tableId={level2Table.id} />
            <AutoformItemFields
                tableId={level2Table.id}
                getInputFormName={getInputFormName}
                config={itemFieldsConfig}
            />

            <Tabs isLazy={false}>
                <TabList w="100%" flexWrap={{ base: 'wrap', xl: 'nowrap' }} alignItems="center">
                    <TabListWrapper>
                        <Tab>
                            <FormattedMessage id="global_constraints_item_constraints_tab" />
                        </Tab>
                        <Tab>
                            <FormattedMessage id="global_constraints_details_tab" />
                        </Tab>
                    </TabListWrapper>
                </TabList>

                <TabPanels mt={6}>
                    <TabPanel pt={portfolioConstraint ? 0 : 4}>
                        {portfolioConstraint && (
                            <Text color="gray.600" mb={8} fontSize="sm">
                                <FormattedMessage id="global_constraints_portfolio_disclaimer" />
                            </Text>
                        )}
                        <GlobalConstraintsComponentsTab
                            portfolioConstraint={portfolioConstraint}
                            parentRecordId={item.OPTCONSTRAINTID}
                            setInitialValues={setInitialValues}
                        />
                    </TabPanel>
                    <TabPanel pt={4}>
                        <AutoformTableContainer
                            tableId={CONSTRAINT_DETAILS_TABLE_ID}
                            childrenTables={hierarchicalTables.level4}
                            onSelectItem={onSelectItem}
                            showDownloadUploadButton={true}
                            config={autoformTableConfig}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default GlobalConstraintsSubItem;
