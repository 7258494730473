import React, { forwardRef } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { preserveConnectionSearchParams, removeConnectionSearchParams } from '../../services/connections';

const CustomNavLink = ({ isNavLink = true, to, children, ...props }, ref) => {
    const [pathname, destinationQueryParameters] = to.split('?');
    const persistentParameters = preserveConnectionSearchParams(window.location.search);
    const connectionSearchParams = new URLSearchParams(persistentParameters);

    // get the list of query params that are not part of the connection config
    const searchParams = new URLSearchParams(removeConnectionSearchParams(destinationQueryParameters));

    // combine them while making sure we don't have duplicate params
    connectionSearchParams.forEach((value, key) => {
        searchParams.set(key, value);
    });

    const searchParamsString = searchParams.toString();

    const Component = isNavLink ? NavLink : Link;

    return (
        <Component ref={ref} to={{ pathname, search: searchParamsString ? `?${searchParamsString}` : '' }} {...props}>
            {children}
        </Component>
    );
};

export default forwardRef(CustomNavLink);
