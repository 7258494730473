import { IconButton } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { forwardRef } from 'react';

const StyledCustomIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'float' })`
    float: ${(props) => (props.float ? props.float : 'left')};
    display: inline-block;
    background: none;
    margin-top: 3px;

    min-height: 18px;
    height: 18px;
    min-width: 18px;
    width: 18px;

    &:hover {
        background: none;
    }

    svg {
        circle,
        path {
            stroke: var(--chakra-colors-gray-500);
        }

        &[aria-expanded='true'] {
            circle,
            path {
                stroke: var(--chakra-colors-blue-400);
            }
        }
    }
`;

const CustomIconButton = forwardRef((props, ref) => (
    <StyledCustomIconButton ref={ref} variant="circular-icon" {...props} />
));

export default CustomIconButton;
