import axios from 'axios';
import { nanoid } from 'nanoid';

export const getAncillaries = async () => {
    const response = await axios.get('/bidding-strategies/products');
    return response.data;
};

export const getBiddingStrategies = async () => {
    const response = await axios.get('/bidding-strategies/strategies');
    return response.data;
};

export const createBiddingStrategy = async (payload) => {
    const response = await axios.post('/bidding-strategies/create', payload);
    return response.data;
};

export const updateBiddingStrategy = (biddingStrategyId, payload) =>
    axios.put(`/bidding-strategies/${biddingStrategyId}`, payload);

export const deleteBiddingStrategy = (biddingStrategyId) =>
    axios.delete(`/bidding-strategies/${biddingStrategyId}`, { suppressErrorMessageHandler: true });

export const getBiddingStrategyDefinitions = async (biddingStrategyId) => {
    const response = await axios.get(`/bidding-strategies/${biddingStrategyId}`);
    return response.data;
};

export const getBiddingStrategy = async (biddingStrategyId) => {
    const response = await axios.get(`/bidding-strategies/info/${biddingStrategyId}`);
    return response.data
        .map((yearPeriod) =>
            yearPeriod.biddingStrategyPeriods.map((period) => ({
                id: nanoid(12),
                periodName:
                    !period.startDate && !period.endDate
                        ? period.participationPeriodDescription
                        : String(yearPeriod.year),
                year: yearPeriod.year ? yearPeriod.year : 0, // 0 is for the Default year
                ...period,
            }))
        )
        .flat();
};

export const createBid = async (biddingStrategyId, payload) => {
    const response = await axios.post(`/bidding-strategies/${biddingStrategyId}/bid`, payload);
    return response.data;
};

export const deleteBid = async (biddingStrategyId, participationId) =>
    axios.delete(`/bidding-strategies/${biddingStrategyId}/bid/${participationId}`);
