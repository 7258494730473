import { useState, useMemo, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';

import {
    setupDefaultConnectionHeaders,
    setAccountConnectionConfig,
    getPrioritizedConnectionConfig,
    getConnectionInfo,
    getUIVersionByNameOrDefault,
    setRedirectUrl,
    getRedirectUrl,
    removeRedirectUrl,
} from '../services/connections';

import { setAccountConnection } from '../store/connection/connectionSlice';

const useConnectionConfigSetup = () => {
    const { instance } = useMsal();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.connections.authState);

    const [isValidatingConnection, setIsValidatingConnection] = useState(true);
    const [isConnectionValid, setIsConnectionValid] = useState(false);
    const [version, setVersion] = useState({ name: '', url: null, isDefault: false });

    useEffect(() => {
        if (authState === 'success') {
            //Get the redirect Url from local storage and redirect to this url if it is not null
            const redirectUrl = getRedirectUrl();
            if (redirectUrl) {
                if (redirectUrl.href !== window.location.href) {
                    let gotoUrl = redirectUrl.href;
                    //Get the current URL search paramters and construct the URL alongwith the redirect Url
                    if (redirectUrl.search == null) {
                        gotoUrl = redirectUrl.href + location.search;
                    }

                    console.log(
                        '*** DEBUG recent change for #40042 useConnectionConfigSetup Get the redirect Url from local storage and redirect to this url if it is not null... ',
                        gotoUrl
                    );
                    console.log({
                        gotoUrl,
                        redirectUrlHref: redirectUrl?.href,
                        windowlocationhref: window?.location?.href,
                    });
                    window.location.assign(gotoUrl);

                    removeRedirectUrl();
                }
            }
        }
    }, [authState, location.search]);

    const isDefaultConfig = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get('isDefaultConfig') === 'true';
    }, [location.search]);

    useEffect(() => {
        if (authState === 'success') {
            const connectionConfig = getPrioritizedConnectionConfig();

            if (connectionConfig) {
                // the provided connection config is valid
                const { connection, environment, book, versionName } = connectionConfig;

                // first retrieve version info
                getUIVersionByNameOrDefault(connection, environment, versionName)
                    .then((versionInfo) => {
                        // set version info if found
                        if (versionInfo?.url) {
                            setVersion(versionInfo);
                        }

                        // then retrieve connection info
                        return getConnectionInfo({
                            connectionId: connection,
                            environmentId: environment,
                            bookId: book,
                        });
                    })
                    .then((connectionInfo) => {
                        // set connection info

                        dispatch(
                            setAccountConnection({
                                ...connectionConfig,
                                connectionTitle: connectionInfo.data.connection.description,
                                environmentTitle: connectionInfo.data.environment.description,
                                bookTitle: connectionInfo.data.book.description,
                            })
                        );

                        setupDefaultConnectionHeaders();

                        setIsConnectionValid(true);
                        setIsValidatingConnection(false);

                        // update local storage if needed
                        if (isDefaultConfig) {
                            setAccountConnectionConfig(connectionConfig);

                            // @todo just remove connection,environment,etc... from search params
                            // and stay on the original route that the user wanted to visit
                            history.replace('/');
                        }
                    })
                    .catch((err) => {
                        const status = err?.response?.status;

                        if (status === 404 || status === 500) {
                            history.replace('/connection');
                        }

                        setIsConnectionValid(false);
                        setIsValidatingConnection(false);
                    });
            } else {
                setIsConnectionValid(false);
                setIsValidatingConnection(false);
            }
        } else if (authState === 'interaction-required' || authState === 'no-users') {
            console.log('automatic login was not successful...', authState);
            setIsConnectionValid(false);
            setIsValidatingConnection(false);

            const href = window?.location?.href;
            const search = window?.location?.search;
            const pathname = window?.location?.pathname;

            // we only care if we had path or search query string args provided
            if (href && !href.toLocaleLowerCase().includes('/login') && (pathname?.length > 1 || search)) {
                console.log(
                    'DEBUG *!*!* if NOT LOGIN Saving the desired window?.location with url in local storage for later use after loggin in, recent change for #40042 '
                );
                console.log({ ...window?.location });
                console.log({ LocationHref: href, search, pathname });
                //Saving the desired url in local storage for later use
                setRedirectUrl(window.location);
            }
            if (authState === 'interaction-required') {
                instance.loginRedirect();
            } else if (authState === 'no-users') {
                // make sure to redirect to /login even if we are on the same page
                history.replace({ pathname: '/login', search: window.location.search });
            }
        }
    }, [authState, instance, dispatch, history, isDefaultConfig]);

    return {
        isValidatingConnection,
        isConnectionValid,
        version,
    };
};

export default useConnectionConfigSetup;
