import { forwardRef, useCallback, useMemo, useState } from 'react';
import { InputGroup, Input, InputRightElement } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { useIntl } from 'react-intl';
import { format, getYear, startOfMonth, startOfToday, startOfDay, set } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';

import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';

import { enUSFormatExcludingSeconds, createDateAsUTC, stripTimezoneIndicator } from '../../services/items';

const CustomInput = forwardRef(({ disabled, isWarning, onClick, ...rest }, ref) => {
    return (
        <InputGroup>
            <Input
                ref={ref}
                {...rest}
                disabled={disabled}
                _hover={isWarning ? { borderColor: 'orange.300' } : undefined}
                borderColor={isWarning ? 'orange.300' : undefined}
                onClick={onClick}
            />

            <InputRightElement opacity={disabled && '0.5'} children={<CalendarIcon />} onClick={onClick} zIndex="1" />
        </InputGroup>
    );
});

const CustomHeader = ({
    changeYear,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    minDate,
    maxDate,
    selected,
}) => {
    const intl = useIntl();

    const onChange = useCallback(
        (date) => {
            if (date) {
                // only change the year if the user hasn't deleted the input text value
                const year = getYear(date);

                changeYear(year);
            }
        },
        [changeYear]
    );

    const popperModifiers = useMemo(
        () => [
            {
                name: 'offset',
                options: {
                    offset: [5, 10],
                },
            },
        ],
        []
    );

    return (
        <div className="react-year-picker-wrapper">
            <button
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--previous"
                aria-label={intl.formatMessage({ id: 'common_datepicker_previous_month' })}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
            >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                    &nbsp;
                </span>
            </button>

            <div className="react-datepicker__current-month">{format(selected, 'LLL')}</div>

            <DatePicker
                selected={selected}
                onChange={onChange}
                showYearPicker
                dateFormat="yyyy"
                minDate={minDate}
                maxDate={maxDate}
                popperPlacement="bottom"
                popperModifiers={popperModifiers}
            />

            <button
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--next"
                aria-label={intl.formatMessage({ id: 'common_datepicker_next_month' })}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
            >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                    &nbsp;
                </span>
            </button>
        </div>
    );
};

const getDatePickerValue = (value, utc, showMonthYearPicker) => {
    if (value) {
        const date = new Date(utc ? stripTimezoneIndicator(value) : value);

        // discard the time component from the date instance when it's a month-year picker
        return showMonthYearPicker ? startOfMonth(date) : date;
    }

    return null;
};

// @workaround The `onBlur` of the DatePicker works correctly after opening and closing at least once.
// `onCalendarClose` works for most cases when you want to apply form validation onBlur
// https://github.com/Hacker0x01/react-datepicker/issues/2028
const Datepicker = forwardRef(
    (
        {
            minDate,
            maxDate,
            value,
            onChange,
            autoComplete = 'off',
            placeholderText,
            dateFormat = enUSFormatExcludingSeconds,
            showTimeInput = true,
            utc = true,
            isClearable = false,
            isWarning,
            showMonthYearPicker = false,
            onCalendarOpen,
            ...rest
        },
        ref
    ) => {
        const intl = useIntl();
        const defaultPlaceholderText = intl.formatMessage({ id: 'common_select_date' });
        const timeInputLabel = intl.formatMessage({ id: 'common_datepicker_time_label' });

        const selected = getDatePickerValue(value, utc, showMonthYearPicker);

        // initial value of today isn't the most correct, but when the user opens the calendar
        // it will be changed to the more correct value
        const [initialValue, setInitialValue] = useState(new Date());

        const minDateValue = minDate ? new Date(minDate) : undefined;
        const maxDateValue = maxDate ? new Date(maxDate) : undefined;

        const onDatePickerOpen = useCallback(() => {
            // convert to utc if needed to prevent edge cases when startOfToday, which is in user's local time, might
            // end up in the previous month/year
            const fallback = utc ? createDateAsUTC(startOfToday()) : startOfToday();

            setInitialValue(selected || fallback);

            if (onCalendarOpen) {
                onCalendarOpen();
            }
        }, [utc, selected, onCalendarOpen]);

        const onDateChange = useCallback((v) => onChange(utc && v ? createDateAsUTC(v) : v), [utc, onChange]);

        const onHeaderDateChange = useCallback(
            (newValue) => {
                let date;

                if (selected) {
                    // we have selected - create a new date from newValue and set the time of selected
                    date = set(newValue, {
                        date: selected.getDate(),
                        hours: selected.getHours(),
                        minutes: selected.getMinutes(),
                        seconds: 0,
                        milliseconds: 0,
                    });
                } else {
                    // selected is null - create a new date from newValue depending on the view and set the time to midnight
                    date = showMonthYearPicker ? startOfMonth(newValue) : startOfDay(newValue);
                }

                onChange(utc ? createDateAsUTC(date) : date);
            },
            [selected, onChange, utc, showMonthYearPicker]
        );

        return (
            <DatePicker
                renderCustomHeader={(props) => (
                    <CustomHeader
                        {...props}
                        minDate={minDateValue}
                        maxDate={maxDateValue}
                        // either use the currently non-null date from `selected`
                        // or fallback to the initial value that the picker had before the user manually deleted all the input text value
                        selected={selected || initialValue}
                    />
                )}
                useWeekdaysShort={true}
                placeholderText={placeholderText ? placeholderText : defaultPlaceholderText}
                timeInputLabel={timeInputLabel}
                autoComplete={autoComplete}
                dateFormat={dateFormat}
                showTimeInput={showTimeInput}
                isClearable={isClearable}
                showMonthYearPicker={showMonthYearPicker}
                {...rest}
                onCalendarOpen={onDatePickerOpen}
                onChange={onDateChange}
                onYearChange={onHeaderDateChange}
                onMonthChange={onHeaderDateChange}
                ref={ref}
                selected={selected}
                customInput={<CustomInput disabled={rest.disabled} value={selected} isWarning={isWarning} />}
                minDate={minDateValue}
                maxDate={maxDateValue}
            />
        );
    }
);

export default Datepicker;
