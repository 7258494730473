import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    autoUpdateJobs: false,
};

const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        onJobsAutoUpdate(state, action) {
            state.autoUpdateJobs = action.payload;
        },
    },
});

export const { onJobsAutoUpdate } = jobsSlice.actions;
export default jobsSlice.reducer;
