const Alert = {
    variants: {
        solid: ({ status }) => {
            let baseStyles = {
                container: {
                    border: '1px',
                    borderRadius: 'lg',
                    color: 'gray.800',
                },
                title: {
                    color: 'black',
                    _dark: {
                        fontWeight: 'semibold',
                    },
                },
            };

            if (status === 'error') {
                baseStyles = {
                    ...baseStyles,
                    container: {
                        ...baseStyles.container,
                        ...{
                            borderColor: 'red.900',
                            bg: 'red.200',
                        },
                    },
                    icon: {
                        color: 'red.500',
                    },
                };
            }

            if (status === 'warning') {
                baseStyles = {
                    ...baseStyles,
                    container: {
                        ...baseStyles.container,
                        ...{
                            borderColor: 'orange.300',
                            bg: 'orange.10',
                        },
                    },
                    icon: {
                        color: 'orange.500',
                    },
                };
            }

            if (status === 'info') {
                baseStyles = {
                    ...baseStyles,
                    container: {
                        ...baseStyles.container,
                        ...{
                            bg: 'blue.50',
                            borderColor: 'blue.200',
                        },
                    },
                    icon: {
                        color: 'blue.500',
                    },
                };
            }

            if (status === 'success') {
                baseStyles = {
                    ...baseStyles,
                    container: {
                        ...baseStyles.container,
                        ...{
                            bg: 'green.100',
                            borderColor: 'green.200',
                        },
                    },
                    icon: {
                        color: 'green.500',
                    },
                };
            }

            return baseStyles;
        },
    },
    defaultProps: {
        variant: 'solid',
        status: 'info',
    },
};

export default Alert;
