import { useState } from 'react';
import AutoformUiContext from './utils/AutoformUiContext';

/**
 * Provider for custom UI state in Autoforms, such as editing state for some inputs..
 *
 */
const AutoformUiProvider = ({ children }) => {
    const [editingState, setEditingState] = useState({});

    return (
        <AutoformUiContext.Provider value={{ editingState, setEditingState }}>{children}</AutoformUiContext.Provider>
    );
};

export default AutoformUiProvider;
