import styled from '@emotion/styled/macro';
import sidebarScrollbarCss from '../utils/sidebarScrollbar';

const ItemListWrapper = styled.div`
    ${sidebarScrollbarCss}

    > div {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    ul li:last-child:not(:first-of-type) .tooltip_wrapper {
        transform: translate(-50%, -145%);
    }

    ul li:last-child:not(:first-of-type) .tooltip_children_wrapper:hover + div {
        transform: translate(-50%, -135%);
    }
`;

export default ItemListWrapper;
