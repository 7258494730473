import { memo } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useIntl } from 'react-intl';

import { noop } from '../../services/utils/index';

import TooltipIconList from '../utils/TooltipIconList';
import ContextMenu from '../utils/ContextMenu';

import { ReactComponent as Copy } from '../../icons/copy.svg';
import { ReactComponent as AddPortfolio } from '../../icons/add-portfolio.svg';

const ItemHoverLabelIcons = ({ selected, onCopy = noop, onAdd = noop }) => {
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const intl = useIntl();

    const items = [
        {
            id: 1,
            label: intl.formatMessage({ id: 'common_add_to_portfolio_btn_tooltip_and_label' }),
            action: () => onAdd(selected),
            Icon: AddPortfolio,
            disabled: onAdd === noop,
        },
        {
            id: 2,
            label: intl.formatMessage({ id: 'common_copy_btn_tooltip_and_label' }),
            action: () => onCopy(selected),
            Icon: Copy,
            disabled: onCopy === noop,
        },
    ];

    return isDesktop ? (
        <TooltipIconList items={items} />
    ) : (
        <ContextMenuWrapper className="ctx-menu-wrapper">
            <ContextMenu aria-label={intl.formatMessage({ id: 'common_actions' })} items={items} />
        </ContextMenuWrapper>
    );
};

const ContextMenuWrapper = styled.div`
    padding: 6px;
`;

export default memo(ItemHoverLabelIcons);
