import { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Skeleton, Text, Box, useDisclosure } from '@chakra-ui/react';

import useCommonToast from '../hooks/useCommonToast';
import useHistoryPush from '../hooks/useHistoryPush';

import {
    getItems,
    getItemType,
    getNodeIcon,
    addItemNextToParent,
    findItem,
    addItemToPortfolios,
    sortItemsByDescription,
} from '../services/items';
import mappedItemTypes from '../services/items/mappedItemTypes';
import { placeholderImages, copyThermal } from '../services/thermals';

import LayoutSidebar from '../components/layout/LayoutSidebar';
import LayoutWrapper from '../components/layout/LayoutWrapper';
import WrapperEmptyState from '../components/layout/WrapperEmptyState';
import ItemListWrapper from '../components/layout/ItemListWrapper';
import ItemLinkWrapper from '../components/utils/OptimizedItemLinkWrapper';
import AddItemButton from '../components/utils/AddItemButton';
import Thermal from '../components/thermals/Thermal';
import AddItemPortfolios from '../components/utils/AddItemPortfolios';
import AddItemModal from '../components/utils/AddItemModal';
import VirtualisedItemList from '../components/utils/VirtualisedItemList';
import ComboboxFormikField from '../components/forms/ComboboxFormikField';
import { SUBSCHEMACODE_THERMAL } from 'constants/uploadDownload';
import { ReactComponent as AddIcon } from '../icons/add.svg';

const Thermals = ({ type }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const { path, url } = useRouteMatch();
    const historyPush = useHistoryPush();

    const addItemModal = useDisclosure();
    const addToPortfolioModal = useDisclosure();

    const itemTypes = useSelector((state) => state.item.itemTypes);
    const isSidebarVisible = useSelector((state) => state.helpers.isSidebarVisible);

    const dataForItemCreation = useSelector((state) => state.item.dataForItemCreation);
    const gasBasis = dataForItemCreation?.gasBasis;
    const cO2Basis = dataForItemCreation?.cO2Basis;

    const [thermals, setThermals] = useState([]);
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [temporaryNode, setTemporaryNode] = useState(null);

    const { itemTypeId, labelName } = getItemType(itemTypes, type) || {};
    const Icon = getNodeIcon(itemTypeId);

    const label = type.toLowerCase();

    // @todo don't recalculate the itemIndex on each rerender - useMemo
    const itemIndex = searchResults.findIndex((item) => item.id === selected?.id);

    const noSearchResults = useMemo(() => {
        return search.trim().length > 0 && searchResults.length === 0;
    }, [search, searchResults]);

    useEffect(() => {
        if (itemTypes.length > 0) {
            getItems(itemTypeId)
                .then((data) => {
                    setThermals(data);
                })
                .catch(() => setLoadingError(true))
                .finally(() => setIsLoading(false));
        }
    }, [itemTypeId, itemTypes.length]);

    useEffect(() => {
        const term = search.trimStart().toLowerCase();
        const filteredData = thermals.filter((o) => o.description.toLowerCase().includes(term));

        setSearchResults(filteredData);
    }, [thermals, search]);

    const onItemEdit = (selectedItem) => {
        const updatedItems = thermals.map((thermal) => {
            if (thermal.id === selectedItem.id) {
                return { ...selectedItem };
            }
            return thermal;
        });
        setThermals(updatedItems);
    };

    const onThermalClick = useCallback((thermal) => {
        if (thermal) {
            setSelected(thermal);
        }
    }, []);

    const onCopy = async ({ id }) => {
        toast({ status: 'info', message: intl.formatMessage({ id: 'common_copy_btn_progress_message' }) });

        const temporaryNode = await copyThermal(id);
        setThermals((prev) => addItemNextToParent(prev, id, temporaryNode));

        historyPush(`${url}/${temporaryNode.id}`);
    };

    const onAddToPortfolioModalOpen = ({ id }) => {
        setTemporaryNode(findItem(thermals, id));
        addToPortfolioModal.onOpen();
    };

    const onCloseAddToPortfolioModal = () => {
        addToPortfolioModal.onClose();
        setTemporaryNode(null);
    };

    const getAddItemSuccessMessage = (item, portfolios) => {
        if (portfolios.length === 1) {
            return intl.formatMessage(
                { id: 'common_add_item_success_message' },
                { item: item.description, destination: portfolios[0].properties.name }
            );
        }

        return intl.formatMessage(
            { id: 'common_add_item_to_multiple_portfolios_success_message' },
            { item: item.description, n: portfolios.length }
        );
    };

    const onAddItemToPortfolioSuccess = (selectedPortfolios) => {
        toast(getAddItemSuccessMessage(temporaryNode, selectedPortfolios));
        onCloseAddToPortfolioModal();
    };

    const onAddItem = (newItem) => {
        setThermals(sortItemsByDescription([...thermals, newItem]));
        setSelected(newItem);
    };

    const onItemDelete = (selectedThermal) => {
        const updatedThermals = thermals.filter((thermal) => thermal.id !== selectedThermal.id);
        setThermals(updatedThermals);
    };

    return (
        <LayoutWrapper
            sidebar={
                isSidebarVisible && (
                    <LayoutSidebar
                        itemType={label}
                        selected={selected}
                        titleId={`${label}-title`}
                        titleMessage={intl.formatMessage({ id: `${label}_title` })}
                        accordionPlaceholderId={`${label}_select_item`}
                        searchPlaceholderId={`${label}_search`}
                        searchCallback={setSearch}
                        searchTerm={search}
                    >
                        <ItemListWrapper>
                            <Box px={6} pb={2}>
                                <AddItemButton leftIcon={<AddIcon />} onClick={addItemModal.onOpen}>
                                    <Text size="sm" color="gray.900" pl={1} pt={1}>
                                        <FormattedMessage id={`${label}_add_new_item`} />
                                    </Text>
                                </AddItemButton>

                                {isLoading ? (
                                    <Skeleton>
                                        <Box h="34px">
                                            <FormattedMessage id="common_loading" />
                                        </Box>
                                    </Skeleton>
                                ) : loadingError ? (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <Box>
                                            <AlertTitle textTransform="capitalize">
                                                <FormattedMessage id="common_error" />
                                            </AlertTitle>

                                            <AlertDescription>
                                                <FormattedMessage id={`${label}_loading_error`} />
                                            </AlertDescription>
                                        </Box>
                                    </Alert>
                                ) : noSearchResults ? (
                                    <Alert status="info">
                                        <AlertIcon />
                                        <FormattedMessage id="common_no_search_results" />
                                    </Alert>
                                ) : null}
                            </Box>
                            {!noSearchResults && !loadingError && !isLoading && (
                                <VirtualisedItemList
                                    items={searchResults}
                                    onCopy={onCopy}
                                    itemIndex={itemIndex}
                                    itemType={!!itemTypeId}
                                    onAddToPortfolioModalOpen={onAddToPortfolioModalOpen}
                                    renderItems={(thermal) => (
                                        <ItemLinkWrapper
                                            item={thermal}
                                            onClick={onThermalClick}
                                            url={url}
                                            icon={Icon}
                                        />
                                    )}
                                />
                            )}
                        </ItemListWrapper>
                    </LayoutSidebar>
                )
            }
            content={
                <Switch>
                    <Route exact path={path}>
                        <WrapperEmptyState
                            imgSrc={placeholderImages[type]}
                            page={type === 'generation_asset' ? 'Thermal' : labelName}
                            noun="configuration"
                        />
                    </Route>

                    {itemTypeId != null && (
                        <Route path={`${path}/:thermalId`}>
                            <Thermal
                                type={type}
                                typeId={itemTypeId}
                                onItemEdit={onItemEdit}
                                onItemDelete={onItemDelete}
                            />
                        </Route>
                    )}
                </Switch>
            }
        >
            {addItemModal.isOpen && (
                <AddItemModal
                    isOpen
                    updateItems={onAddItem}
                    onClose={addItemModal.onClose}
                    itemTypeId={itemTypeId}
                    typeLabel={labelName}
                    formLabelsPhrasing={{ pricingPoint: intl.formatMessage({ id: 'common_electric_pricing_point' }) }}
                    starterDataAutoFormSchemaCode={SUBSCHEMACODE_THERMAL}
                    extraFields={
                        <>
                            {itemTypeId !== mappedItemTypes.DSM.id && (
                                <>
                                    <ComboboxFormikField
                                        id="gasBasisId"
                                        name="gasBasisId"
                                        label={intl.formatMessage({ id: 'common_gas_pricing_point' })}
                                        options={gasBasis}
                                        labelKey="description"
                                        valueKey="id"
                                        showPlaceholder={true}
                                        placeholderValue={null}
                                        isFastField
                                    />

                                    <ComboboxFormikField
                                        id="cO2BasisId"
                                        name="cO2BasisId"
                                        label={intl.formatMessage({ id: 'common_CO2_pricing_point' })}
                                        options={cO2Basis}
                                        labelKey="description"
                                        valueKey="id"
                                        showPlaceholder={true}
                                        placeholderValue={null}
                                        isFastField
                                    />
                                </>
                            )}
                        </>
                    }
                    extraFieldsInitialValues={
                        itemTypeId !== mappedItemTypes.DSM.id && { gasBasisId: '', cO2BasisId: '' }
                    }
                />
            )}

            {addToPortfolioModal.isOpen && (
                <AddItemPortfolios
                    onClose={onCloseAddToPortfolioModal}
                    sourceId={temporaryNode.id}
                    itemName={temporaryNode.description}
                    onAddSuccess={onAddItemToPortfolioSuccess}
                    onSubmit={addItemToPortfolios}
                />
            )}
        </LayoutWrapper>
    );
};

export default Thermals;
