import { useMemo } from 'react';

import { getTablesWithChildrenMap } from '../utils/autoformUtils';

/**
 * Builds a map with parent table ids as keys and an array of
 * child table metadata as the values. Tables with parentId
 * matching a parent tables id are considered children.
 *
 * @param {object[]} parentTableList - List of tables metadata that represents
 * parent tables in the relationship.
 * @param {object[]} childTableList - List of tables metadata that represents
 * child tables in the relationship.
 * @returns {object} tablesWithChildrenMap - A map of tables that has children, along with their
 * children.
 * @returns {object[]} tablesWithoutChildrenList - A list of tables that do not have children.
 */
const useAutoformTableHierarchy = (parentTableList, childTableList) => {
    const tablesWithChildrenMap = useMemo(
        () => getTablesWithChildrenMap(parentTableList, childTableList),
        [parentTableList, childTableList]
    );
    const tablesWithoutChildrenList = useMemo(
        () => parentTableList.filter((level3Table) => !tablesWithChildrenMap[level3Table.id]?.length),
        [parentTableList, tablesWithChildrenMap]
    );

    return { tablesWithChildrenMap, tablesWithoutChildrenList };
};

export default useAutoformTableHierarchy;
