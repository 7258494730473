import React, { useCallback, useMemo } from 'react';
import { Flex, Link, ListItem } from '@chakra-ui/react';
import CustomNavLink from '../../utils/CustomNavLink';
import styled from '@emotion/styled/macro';
import { useLocation } from 'react-router-dom';
import useUserPermissions from '../../../hooks/useUserPermissions';
import { ReactComponent as PremiumIcon } from 'icons/premium.svg';

const NavigationListItem = ({ config, onClick }) => {
    const { isAdmin } = useUserPermissions();
    const { pathname } = useLocation();

    const isDisabled = useMemo(() => config?.adminOnly && !isAdmin, [config, isAdmin]);
    const isPremium = useMemo(() => config?.isEnabled === false, [config]);

    const handler = useCallback(
        (event) => {
            if (isDisabled) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                onClick && onClick();
                config?.clickHandler && config.clickHandler(event);
            }
        },
        [config, onClick, isDisabled]
    );

    const isRouteMatched = useMemo(() => {
        const configPathname = config.url.split('?')[0];

        return (configPathname === '/' && configPathname === pathname) || pathname.startsWith(configPathname);
    }, [config.url, pathname]);

    return (
        <StyledFlex isRouteMatched={isRouteMatched}>
            {isDisabled ? (
                <StyledListItem
                    color="menu-item-text"
                    variant="secondary"
                    as={Link}
                    onClick={handler}
                    isDisabled={isDisabled}
                >
                    {config.label}
                </StyledListItem>
            ) : config.url.startsWith('http') ? (
                <StyledListItem
                    color="menu-item-text"
                    variant="secondary"
                    as={Link}
                    href={config.url}
                    target="_blank"
                    onClick={handler}
                >
                    {config.label}
                </StyledListItem>
            ) : (
                <StyledListItem
                    variant="secondary"
                    color="menu-item-text"
                    as={CustomNavLink}
                    exact={config.url === '/'}
                    to={config.url}
                    onClick={handler}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {config.label}
                    {isPremium && <PremiumIcon />}
                </StyledListItem>
            )}
        </StyledFlex>
    );
};

const StyledFlex = styled(Flex, { shouldForwardProp: (prop) => prop !== 'isRouteMatched' })`
    ${(props) =>
        props.isRouteMatched
            ? `
            background-color: var(--chakra-colors-blue-100);
            border-radius: 6px;
        `
            : ''}
`;

const StyledListItem = styled(ListItem, { shouldForwardProp: (prop) => prop !== 'isDisabled' })`
    padding: 10px var(--chakra-space-10) 10px var(--chakra-space-4);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 100%;

    &.active {
        color: var(--chakra-colors-black);
    }

    &:hover {
        text-decoration: none;
    }

    ${(props) =>
        props.isDisabled
            ? `
        color: var(--chakra-colors-gray-400);
    `
            : ''}
`;

export default NavigationListItem;
