import FormField from './FormField';
import Combobox from './Combobox';

// If you need to extend Combobox functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for Combobox should be excluded from ...props and manually added
const ComboboxField = ({
    value,
    onChange,
    onBlur,
    options,
    valueKey,
    labelKey,
    showPlaceholder,
    placeholderLabel,
    placeholderValue,
    isControlled,
    isInvalid,
    isWarning,
    isDisabled,
    hasDivider,
    placement,
    strategy,
    modifiers,
    isRequired,
    ...props
}) => {
    return (
        <FormField isInvalid={isInvalid} isDisabled={isDisabled} {...props}>
            <Combobox
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                options={options}
                valueKey={valueKey}
                labelKey={labelKey}
                isInvalid={isInvalid}
                isWarning={isWarning}
                isDisabled={isDisabled}
                showPlaceholder={showPlaceholder}
                placeholderLabel={placeholderLabel}
                placeholderValue={placeholderValue}
                isControlled={isControlled}
                hasDivider={hasDivider}
                placement={placement}
                strategy={strategy}
                modifiers={modifiers}
                isRequired={isRequired}
            />
        </FormField>
    );
};

export default ComboboxField;
