import { useState } from 'react';
import { SimpleGrid, Divider, Text, Flex, Button } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';

import MultiSelectField from '../forms/MultiSelectField';
import DateField from '../forms/DateField';

import { removeFilters } from '../../services/items';

const StudyFilters = ({ filterOptions, filters, onApplyFilters, onResetFilters }) => {
    const intl = useIntl();
    const item = intl.formatMessage({ id: 'common_study' });

    const [selectedBooks, setSelectedBooks] = useState(filters && filters.bookIds ? filters.bookIds : []);
    const [selectedSubmittedBy, setSelectedSubmittedBy] = useState(
        filters && filters.submittedByNames ? filters.submittedByNames : []
    );
    const [selectedJobTypes, setSelectedJobTypes] = useState(filters && filters.jobTypeIds ? filters.jobTypeIds : []);
    const [selectedStates, setSelectedStates] = useState(filters && filters.statuses ? filters.statuses : []);
    const [runDateFrom, setRunDateFrom] = useState(
        filters && filters.scheduledRunDateTimeFrom ? filters.scheduledRunDateTimeFrom : null
    );
    const [runDateTo, setRunDateTo] = useState(
        filters && filters.scheduledRunDateTimeTo ? filters.scheduledRunDateTimeTo : null
    );

    const saveFilters = () => {
        const payload = {
            scheduledRunDateTimeFrom: runDateFrom,
            scheduledRunDateTimeTo: runDateTo,
            bookIds: selectedBooks,
            statuses: selectedStates,
            jobTypeIds: selectedJobTypes,
            submittedByNames: selectedSubmittedBy,
        };
        onApplyFilters(payload);
    };

    const resetToDefaultFilter = () => {
        removeFilters(item);
        const payload = {
            scheduledRunDateTimeFrom: null,
            scheduledRunDateTimeTo: null,
            bookIds: [],
            statuses: [],
            jobTypeIds: [],
            submittedByNames: [],
        };
        onResetFilters(payload);
    };

    return (
        <>
            <MultiSelectField
                id="books"
                name="books"
                label={<FormattedMessage id="study_tracker_drawer_field_books" />}
                placeholderLabel={<FormattedMessage id="run_a_study_select_placeholder" />}
                multipleSelectedLabel={<FormattedMessage id="run_a_study_multiple_selected_placeholder" />}
                allSelectedLabel={intl.formatMessage({
                    id: 'common_all_selected_label',
                })}
                options={filterOptions.books}
                valueKey="id"
                labelKey="name"
                onChange={setSelectedBooks}
                value={selectedBooks}
                mb={5}
            />

            <MultiSelectField
                id="status"
                name="status"
                label={<FormattedMessage id="study_tracker_drawer_field_status" />}
                placeholderLabel={<FormattedMessage id="run_a_study_select_placeholder" />}
                multipleSelectedLabel={<FormattedMessage id="run_a_study_multiple_selected_placeholder" />}
                allSelectedLabel={intl.formatMessage({
                    id: 'common_all_selected_label',
                })}
                options={filterOptions.statuses}
                value={selectedStates}
                onChange={setSelectedStates}
                mb={5}
            />

            <MultiSelectField
                id="jobTypes"
                name="jobTypes"
                label={<FormattedMessage id="study_tracker_drawer_field_job_types" />}
                placeholderLabel={<FormattedMessage id="run_a_study_select_placeholder" />}
                multipleSelectedLabel={<FormattedMessage id="run_a_study_multiple_selected_placeholder" />}
                allSelectedLabel={intl.formatMessage({
                    id: 'common_all_selected_label',
                })}
                options={filterOptions.jobTypes}
                value={selectedJobTypes}
                valueKey="id"
                labelKey="description"
                onChange={setSelectedJobTypes}
                mb={5}
            />

            <MultiSelectField
                id="submittedBy"
                name="submittedBy"
                label={<FormattedMessage id="study_tracker_drawer_field_submitted_by" />}
                placeholderLabel={<FormattedMessage id="run_a_study_select_placeholder" />}
                multipleSelectedLabel={<FormattedMessage id="run_a_study_multiple_selected_placeholder" />}
                allSelectedLabel={intl.formatMessage({
                    id: 'common_all_selected_label',
                })}
                options={filterOptions.submittedByNames}
                value={selectedSubmittedBy}
                valueKey="id"
                labelKey="description"
                onChange={setSelectedSubmittedBy}
                mb={5}
            />

            <Text fontSize={16} _dark={{ color: 'white' }} fontWeight="500" mt={6} mb={4} textTransform="capitalize">
                <FormattedMessage id="study_tracker_drawer_field_run_date" />
            </Text>

            <SimpleGrid columns={{ xl: 2 }} spacingX={4} spacingY={4} mb={10}>
                <DateField
                    id="startDateFrom"
                    name="startDateFrom"
                    label={<FormattedMessage id="common_from" />}
                    value={runDateFrom}
                    onChange={setRunDateFrom}
                    showTimeInput
                    utc={false}
                />

                <DateField
                    id="startDateTo"
                    name="startDateTo"
                    label={<FormattedMessage id="common_to" />}
                    value={runDateTo}
                    onChange={setRunDateTo}
                    showTimeInput
                    utc={false}
                />
            </SimpleGrid>

            <Divider mt={6} mb={8} />

            <Flex justify="space-between">
                <Button onClick={resetToDefaultFilter} variant="secondary">
                    <FormattedMessage id="common_reset_to_default_btn" />
                </Button>

                <Flex direction="column" align="flex-end">
                    <Button onClick={saveFilters}>
                        <FormattedMessage id="common_apply_filter_btn" />
                    </Button>

                    <Text size="xs" variant="regular" maxW={52} mt={3} textAlign="end">
                        <FormattedMessage id="common_apply_filter_message" values={{ item }} />
                    </Text>
                </Flex>
            </Flex>
        </>
    );
};

export default StudyFilters;
