import { createAsyncThunk } from '@reduxjs/toolkit';
import * as powerBiService from '../../services/powerBi';

export const getPowerBiEmbedToken = createAsyncThunk('getPowerBiEmbedToken', powerBiService.getPowerBiEmbedToken);

export const getGroups = createAsyncThunk('getGroups', powerBiService.getGroups);

export const getReports = createAsyncThunk('getReports', powerBiService.getReports);

export const getDataset = createAsyncThunk('getDataset', powerBiService.getDataset);
