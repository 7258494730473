import { useMemo, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import { set, isBefore, isAfter } from 'date-fns';
import styled from '@emotion/styled/macro';
import { Heading, SimpleGrid, Divider, Text, Flex, Button, Spacer, Radio, HStack } from '@chakra-ui/react';

import {
    getFiltersConfig,
    setFiltersConfig,
    parseDataFilters,
    systemDefaultFilter,
    removeFilters,
} from '../../services/items';

import DateFormikField from '../forms/DateFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';
import RadioGroupFormikField from '../forms/RadioGroupFormikField';
import { convertRelativeToUTCDateMidnight, convertRelativeToUTCDateMidnightExtraDayMinusOneHour } from 'services/utils';

const ItemFilters = (props) => {
    const {
        onApplyFilters,
        item,
        tableName,
        children,
        customFilters,
        validateCustomFilters,
        hasEndDateFilter = true,
        hasStartDateFilter = true,
        hasUpdateOrCreateDateFilter = true,
        itemStartFilterHeading,
        filterHint,
        secondaryFilterHeading,
    } = props;

    const intl = useIntl();
    const hasDateFilters = hasStartDateFilter || hasEndDateFilter;

    const initialValues = useMemo(() => {
        const prefs = getFiltersConfig(item) || { ...systemDefaultFilter };

        // chakra's form fields are all using string values so we might as well stringify them here to stay consistent
        return {
            ...prefs,
            customFilters,
            daysFrom: String(prefs.daysFrom),
            daysTo: String(prefs.daysTo),
            filterType: String(prefs.filterType),
        };
    }, [customFilters, item]);

    const formLabels = {
        from: intl.formatMessage({ id: 'common_from' }),
        to: intl.formatMessage({ id: 'common_to' }),
        latest: intl.formatMessage({ id: 'common_filter_radio_btn_latest' }),
        latestAsOf: intl.formatMessage({ id: 'common_filter_radio_btn_latest_as_of' }),
        range: intl.formatMessage({ id: 'common_filter_radio_btn_range' }),
        relative_from: intl.formatMessage({ id: 'common_relative_from' }),
        relative_to: intl.formatMessage({ id: 'common_relative_to' }),
    };

    const validateSiblingDate = useCallback(
        ({ value, siblingValue, type }) => {
            // when you delete the contents of the datepicker/numberinput the value is null or empty string
            const isInvalidValue = value === null || value === '';
            const isInvalidSiblingValue = siblingValue === null || siblingValue === '';

            if (isInvalidValue || (isInvalidValue && isInvalidSiblingValue)) {
                // show an error if the value is invalid or both the value and the sibling value are invalid
                return intl.formatMessage({ id: 'common_forms_invalid_value' });
            }

            if (isInvalidSiblingValue) {
                // if the sibling value is invalid don't try validating this value
                return null;
            }

            const dateA = set(value, { seconds: 0, milliseconds: 0 });
            const dateB = set(siblingValue, { seconds: 0, milliseconds: 0 });

            if (type === 'from' && isAfter(dateA, dateB)) {
                return intl.formatMessage({ id: 'filter_sibling_date_smaller' });
            } else if (type === 'to' && isBefore(dateA, dateB)) {
                return intl.formatMessage({ id: 'filter_sibling_date_bigger' });
            }

            return null;
        },
        [intl]
    );

    const validate = useCallback(
        (values) => {
            const realtiveDaysFrom = parseInt(values.daysFrom, 10);
            const relativeDaysTo = parseInt(values.daysTo, 10);

            const relativeStartDateFrom = convertRelativeToUTCDateMidnight(realtiveDaysFrom);
            // Per business request include extra day
            const relativeStartDateTo = convertRelativeToUTCDateMidnightExtraDayMinusOneHour(relativeDaysTo);

            const startDateFrom = !values.toggleDaysFrom
                ? validateSiblingDate({
                      value: values.startDateFrom,
                      siblingValue: values.toggleDaysTo ? relativeStartDateTo : values.startDateTo,
                      type: 'from',
                  })
                : null;

            const daysFrom = values.toggleDaysFrom
                ? validateSiblingDate({
                      value: values.daysFrom ? relativeStartDateFrom : realtiveDaysFrom,
                      siblingValue: values.toggleDaysTo ? relativeStartDateTo : values.startDateTo,
                      type: 'from',
                  })
                : null;

            const startDateTo = !values.toggleDaysTo
                ? validateSiblingDate({
                      value: values.startDateTo,
                      siblingValue: values.toggleDaysFrom ? relativeStartDateFrom : values.startDateFrom,
                      type: 'to',
                  })
                : null;

            const daysTo = values.toggleDaysTo
                ? validateSiblingDate({
                      value: values.daysTo ? relativeStartDateTo : relativeDaysTo,
                      siblingValue: values.toggleDaysFrom ? relativeStartDateFrom : values.startDateFrom,
                      type: 'to',
                  })
                : null;

            const updateDateFrom =
                values.filterType === '3'
                    ? validateSiblingDate({
                          value: values.updateDateFrom,
                          siblingValue: values.updateDateTo,
                          type: 'from',
                      })
                    : values.filterType === '2' && !values.updateDateFrom
                    ? intl.formatMessage({ id: 'common_forms_invalid_value' })
                    : null;

            const updateDateTo =
                values.filterType === '3'
                    ? validateSiblingDate({
                          value: values.updateDateTo,
                          siblingValue: values.updateDateFrom,
                          type: 'to',
                      })
                    : null;

            const customFilterValidations = validateCustomFilters && validateCustomFilters(values);

            return {
                ...(startDateFrom ? { startDateFrom } : null),
                ...(daysFrom ? { daysFrom } : null),
                ...(startDateTo ? { startDateTo } : null),
                ...(daysTo ? { daysTo } : null),
                ...(updateDateFrom ? { updateDateFrom } : null),
                ...(updateDateTo ? { updateDateTo } : null),
                ...(customFilterValidations ? { ...customFilterValidations } : null),
            };
        },
        [validateSiblingDate, validateCustomFilters, intl]
    );

    const onKeyDown = useCallback((event) => {
        if (event.key === 'Enter') event.preventDefault();
    }, []);

    const onSubmit = useCallback(
        (values) => {
            const { customFilters, ...rest } = values;

            const realtiveDaysFrom = parseInt(values.daysFrom, 10);
            const relativeDaysTo = parseInt(values.daysTo, 10);

            const relativeStartDateFrom = convertRelativeToUTCDateMidnight(realtiveDaysFrom);
            // Per business request include extra day
            const relativeStartDateTo = convertRelativeToUTCDateMidnightExtraDayMinusOneHour(relativeDaysTo);

            const newFilters = {
                ...rest,
                daysFrom: realtiveDaysFrom,
                daysTo: relativeDaysTo,
                filterType: parseInt(values.filterType, 10),
            };

            if (values.toggleDaysFrom) {
                newFilters.startDateFrom = relativeStartDateFrom;
            }

            if (values.toggleDaysTo) {
                newFilters.startDateTo = relativeStartDateTo;
            }

            (hasDateFilters || hasUpdateOrCreateDateFilter) && setFiltersConfig(newFilters, item);

            let filters = parseDataFilters(newFilters);
            if (customFilters) {
                filters = {
                    ...(hasDateFilters || hasUpdateOrCreateDateFilter ? newFilters : null),
                    customFilters,
                };
            }

            onApplyFilters(filters);
        },
        [item, onApplyFilters, hasDateFilters, hasUpdateOrCreateDateFilter]
    );

    const resetToDefaultFilter = useCallback(() => {
        removeFilters(item);
        onApplyFilters(systemDefaultFilter);
    }, [onApplyFilters, item]);

    const secondaryFilterLabel = secondaryFilterHeading || <FormattedMessage id={'common_item_update_filter'} />;

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {({ values, isValid, dirty }) => (
                <Form onKeyDown={onKeyDown}>
                    {(hasStartDateFilter || hasEndDateFilter) && (
                        <>
                            <Heading as="h4" variant="h4" mb={5}>
                                {itemStartFilterHeading}
                            </Heading>

                            <SimpleGrid columns={{ xl: 2 }} spacingX={4} spacingY={3} mb={4}>
                                <DateFormikField
                                    id="startDateFrom"
                                    name="startDateFrom"
                                    label={formLabels.from}
                                    isDisabled={values.toggleDaysFrom}
                                />

                                <NumberInputFormikField
                                    mt={{ base: '0px', xl: '18px' }}
                                    id="daysFrom"
                                    name="daysFrom"
                                    isDisabled={!values.toggleDaysFrom}
                                    precision={0}
                                    step={1}
                                    max={100000}
                                    min={-100000}
                                />

                                <Spacer display={{ base: 'none', xl: 'block' }} />

                                <CheckboxFormikField id="toggleDaysFrom" name="toggleDaysFrom">
                                    {formLabels.relative_from}
                                </CheckboxFormikField>
                            </SimpleGrid>

                            <SimpleGrid columns={{ xl: 2 }} spacingX={4} spacingY={3}>
                                <DateFormikField
                                    id="startDateTo"
                                    name="startDateTo"
                                    label={formLabels.to}
                                    isDisabled={values.toggleDaysTo}
                                />

                                <NumberInputFormikField
                                    mt={{ base: '0px', xl: '18px' }}
                                    id="daysTo"
                                    name="daysTo"
                                    isDisabled={!values.toggleDaysTo}
                                    precision={0}
                                    step={1}
                                    max={100000}
                                    min={-100000}
                                />

                                <Spacer display={{ base: 'none', xl: 'block' }} />

                                <CheckboxFormikField id="toggleDaysTo" name="toggleDaysTo">
                                    {formLabels.relative_to}
                                </CheckboxFormikField>
                            </SimpleGrid>
                        </>
                    )}

                    {(hasStartDateFilter || hasEndDateFilter) && <Divider my={6} />}

                    {hasUpdateOrCreateDateFilter && (
                        <>
                            <Heading as="h4" variant="h4">
                                {secondaryFilterLabel}
                            </Heading>

                            <RadioGroupFormikField my={5} id="latest" name="filterType">
                                <HStack spacing={8}>
                                    <StyledRadio value="1">{formLabels.latest}</StyledRadio>
                                    <StyledRadio value="2">{formLabels.latestAsOf}</StyledRadio>
                                    <StyledRadio value="3">{formLabels.range}</StyledRadio>
                                </HStack>
                            </RadioGroupFormikField>

                            <SimpleGrid columns={{ xl: 2 }} spacingX={4} spacingY={4}>
                                <DateFormikField
                                    id="updateDateFrom"
                                    name="updateDateFrom"
                                    label={formLabels.from}
                                    isDisabled={values.filterType === '1'}
                                />

                                <DateFormikField
                                    id="updateDateTo"
                                    name="updateDateTo"
                                    label={formLabels.to}
                                    isDisabled={values.filterType !== '3'}
                                />
                            </SimpleGrid>
                        </>
                    )}

                    {children}
                    <Divider mt={6} mb={8} />

                    <Flex>
                        <Button onClick={resetToDefaultFilter} variant="secondary">
                            <FormattedMessage id="common_reset_to_default_btn" />
                        </Button>

                        <Spacer />

                        <Flex flexDirection={'column'} alignItems={'flex-end'}>
                            <Button type="submit" disabled={!isValid || (!hasDateFilters && !dirty)}>
                                <FormattedMessage id="common_apply_filter_btn" />
                            </Button>

                            <Text size="xs" variant="regular" maxW={52} marginTop={3} textAlign="end">
                                {filterHint ?? (
                                    <FormattedMessage
                                        id="common_apply_filter_message"
                                        values={{ item: tableName ?? item }}
                                    />
                                )}
                            </Text>
                        </Flex>
                    </Flex>
                    <Spacer my={6} />
                </Form>
            )}
        </Formik>
    );
};

const StyledRadio = styled(Radio)`
    margin-right: 2px;
    border-color: var(--chakra-colors-green-500);

    &:hover {
        border-color: var(--chakra-colors-blue-400);
    }
`;

export default ItemFilters;
