import { isAfter, isBefore, isDate, isValid, parseISO } from 'date-fns';
import { keyBy, mergeWith, values } from 'lodash';

import { checkValidityBeforeSave, lenientParse, createDateAsUTC, dateValueFormatter } from '../items';

export const getValidRowsWithDates = (rows, dateFields) => {
    let valid = [];

    for (const row of rows) {
        const dateValuesToValidate = dateFields.map((field) => row[field]);

        if (dateValuesToValidate.every((date) => checkValidityBeforeSave(date))) {
            // when all off the provided date fields pass the checks
            // we parse the dates and add the row data to the list of valid rows

            const dates = dateFields.reduce((acc, field) => {
                acc[field] = createDateAsUTC(lenientParse(row[field]));

                return acc;
            }, {});

            valid.push({
                ...row,
                ...dates,
            });
        }
    }

    return valid;
};

export const cellValidationThreshold = 10000;

export const filterIssuesColumn = (rows) => {
    return rows.map((row) => {
        if ('issues' in row) {
            delete row['issues'];
        }

        return row;
    });
};

export const addComboboxOption = (options, id, description) => {
    return [{ id, description }, ...options];
};

export const updateIssues = (currentData, newData) => {
    const merged = mergeWith(keyBy(currentData, 'id'), keyBy(newData, 'id'), (currentData, newData) => newData);
    const updatedValues = values(merged);

    return updatedValues;
};

export const getIssuesMap = (issues) => {
    return (
        issues.reduce((obj, issue) => {
            const id = issue.id ?? issue.index;
            const fieldErrors = issue.fields ?? [];
            const generalValidationMessages = issue.generalValidationMessages ?? [];

            obj[id] = {
                errors: [],
                errorFields: [],
                generalErrors: [],
                warnings: [],
                warningFields: [],
                generalWarnings: [],
            };

            fieldErrors.forEach((field) => {
                field.validationMessages.forEach((validation) => {
                    if (validation.severity === 'Warning') {
                        obj[id].warnings.push(validation.message);
                        obj[id].warningFields.push(field.name);
                    } else if (validation.severity === 'Error') {
                        obj[id].errors.push(validation.message);
                        obj[id].errorFields.push(field.name);
                    }
                });
            });

            generalValidationMessages.forEach((message) => {
                if (message.severity === 'Warning') {
                    obj[id].generalWarnings.push(message.message);
                } else if (message.severity === 'Error') {
                    obj[id].generalErrors.push(message.message);
                }
            });

            return obj;
        }, {}) || {}
    );
};

export const columnTypes = {
    date: {
        valueFormatter(params) {
            if (params.value == null || params.value === '') {
                return '';
            }

            const def = params.column.getColDef();
            const dateFormat = def.cellEditorParams.dateFormat;

            return dateValueFormatter(params, dateFormat);
        },
        cellEditor: 'DataGridCalendar',
        minWidth: 258,
        valueSetter(params) {
            const def = params.column.getColDef();
            const value = params.newValue;

            // commit value as null if empty or invalid
            if (value == null || value === '' || value === 'Invalid Date') {
                if (def.cellEditorParams?.nullable) {
                    params.data[def.field] = null;

                    return true;
                }

                return false;
            }

            // commit value if it's instance of Date or a valid string like date except Numbers
            if (isDate(value) || (isValid(new Date(value)) && Number.isNaN(Number(value)))) {
                const newDate = createDateAsUTC(lenientParse(value)).toISOString();
                if (
                    def.cellEditorParams?.minDate &&
                    isBefore(parseISO(newDate), new Date(def.cellEditorParams?.minDate))
                ) {
                    return false;
                } else if (
                    def.cellEditorParams?.maxDate &&
                    isAfter(parseISO(newDate), new Date(def.cellEditorParams?.maxDate))
                ) {
                    return false;
                } else {
                    params.data[def.field] = newDate;
                    return true;
                }
            }

            return false;
        },
        cellEditorPopup: true,
        cellEditorParams: {
            dateFormat: undefined,
            showTimeInput: true,
        },
    },
    number: {
        valueFormatter(params) {
            if (params.value == null || params.value === '') {
                return '';
            }

            const def = params.column.getColDef();

            if (def.cellEditorParams.format === 'percent') {
                return `${params.value}%`;
            }

            if (def.cellEditorParams.format === 'currency') {
                return `$${params.value}`;
            }

            return params.value;
        },
        valueSetter(params) {
            const def = params.column.getColDef();
            const value = parseFloat(params.newValue);

            if (Number.isNaN(value)) {
                if (def.cellEditorParams.required) {
                    return false;
                } else {
                    params.data[def.field] = null;
                }
            } else {
                if (def.cellEditorParams.min !== undefined && def.cellEditorParams.min > value) {
                    params.data[def.field] = def.cellEditorParams.min;
                } else if (def.cellEditorParams.max !== undefined && def.cellEditorParams.max < value) {
                    params.data[def.field] = def.cellEditorParams.max;
                } else {
                    params.data[def.field] = value;
                }
            }

            return true;
        },
        cellEditorParams: {
            required: false,
        },
    },
    checkbox: {
        cellRenderer: 'DataGridCheckbox',
        cellEditor: 'DataGridCheckboxEditor',
        valueSetter(params) {
            const def = params.column.getColDef();
            const allowedValues = ['1', '0', 1, 0, 'true', 'false', true, false];
            const value = params.newValue;

            if (allowedValues.includes(value)) {
                if (value === '1' || value === 1 || value === 'true' || value === true) {
                    params.data[def.field] = true;
                }

                if (value === '0' || value === 0 || value === 'false' || value === false) {
                    params.data[def.field] = false;
                }

                return true;
            }

            return false;
        },
    },
    select: {
        cellEditor: 'DataGridCombobox',
        valueFormatter(params) {
            if (params.value == null || params.value === '') {
                return '';
            }

            const def = params.column.getColDef();
            const option = def.cellEditorParams.options.find(({ id }) => id === params.data[def.field]);

            return option?.description ? option.description : '';
        },
        valueSetter(params) {
            const def = params.column.getColDef();

            if (params.newValue === '' || params.newValue === undefined) {
                if (!def.cellEditorParams.required) {
                    // set the new dropdown value to the empty <option> if it's a non-required dropdown
                    params.data[def.field] = null;
                }

                // commit the new value only if it's a non-required dropdown
                return !def.cellEditorParams.required;
            }

            const maybeId = params.newValue;
            const maybeIdNumber = Number(params.newValue);
            const maybeDescription = params.newValue;

            // try to find a matching select option that either matches the id property or the description
            const option = def.cellEditorParams.options.find(({ id, description }) => {
                return id === maybeId || id === maybeIdNumber || description === maybeDescription;
            });

            if (option) {
                params.data[def.field] = option.id;
            }

            return !!option;
        },
        cellEditorPopup: true,
        cellEditorParams: {
            options: [],
            required: true,
        },
    },
    button: {
        editable: false,
        cellRenderer: 'DataGridButton',
    },
    progressBar: {
        cellRenderer: 'DataGridProgressBar',
    },
    percentage: {
        valueFormatter(params) {
            const value = params.value;

            if (value == null) {
                return ``;
            }

            return `${value}%`;
        },
        valueGetter(params) {
            const def = params.column.getColDef();
            const value = Number.parseFloat(params.data[def.field]);
            const multiplier = def.cellEditorParams.multiplier || 100;

            if (Number.isNaN(value)) {
                return (params.data[def.field] = def.cellEditorParams.required ? 0 : null);
            }

            return params.data[def.field] * multiplier;
        },
        valueSetter(params) {
            const def = params.column.getColDef();
            const multiplier = def.cellEditorParams.multiplier || 100;
            const newValue = Number.parseFloat(params.newValue);

            if (Number.isNaN(newValue)) {
                if (def.cellEditorParams.required) {
                    return false;
                } else {
                    params.data[def.field] = null;
                }
            } else {
                if (
                    (def.cellEditorParams.min === undefined || def.cellEditorParams.min * multiplier <= newValue) &&
                    (def.cellEditorParams.max === undefined || def.cellEditorParams.max * multiplier >= newValue)
                ) {
                    params.data[def.field] = newValue / multiplier;
                }
            }

            return true;
        },
    },
    issues: {
        cellRenderer: 'DataGridIssuesDialog',
    },
    internal_issues: {
        cellRenderer: 'DataGridInternalIssuesDialog',
    },
    text: {
        valueSetter(params) {
            const def = params.column.getColDef();

            if (params.newValue === '' && def.cellEditorParams.required) {
                return false;
            }

            params.data[def.field] = params.newValue;

            return true;
        },
    },
    string: {},
};

export const isColEditable = (params, colDef) => {
    let editable = colDef?.editable ?? true;
    if (typeof colDef?.editable === 'function') {
        editable = colDef?.editable(params);
    }
    return editable;
};
