import React, { useState, useEffect } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled/macro';
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    useMediaQuery,
    Heading,
} from '@chakra-ui/react';

import DownArrow from '../../icons/arrow_drop_down.svg';
import SearchBar from 'components/utils/SearchBar';

const LayoutSidebar = ({
    children,
    itemType,
    selected,
    titleId,
    titleMessage,
    accordionPlaceholderId,
    searchPlaceholderId,
    searchCallback,
    searchTerm,
}) => {
    const intl = useIntl();
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const [index, setIndex] = useState(isDesktop ? [0] : []);

    useEffect(() => {
        if (isDesktop) {
            setIndex([0]);
        }
    }, [isDesktop]);

    // build accordion label used for smaller resolutions based on the incoming item type
    const buildLabel = (selected) => {
        let label = intl.formatMessage({ id: accordionPlaceholderId });

        switch (itemType) {
            case 'portfolio':
                label = selected.properties.name;
                break;

            case 'load':
            case 'battery':
            case 'wind':
                label = selected.description;
                break;
            case 'autoform':
                label = typeof selected === 'string' ? selected : label;
                break;
            default:
                break;
        }

        return label;
    };

    return (
        <Sidebar>
            <Box pt={8} px={6}>
                <Heading id={titleId} as="h2" variant="h2" mb={2}>
                    {titleMessage}
                </Heading>
            </Box>

            {
                // `reduceMotion` is used to remove the unwanted .chakra-collapse div
                // that has an endingHeight: 'auto' which is not configurable in Accordion
            }
            <Accordion reduceMotion={true} variant="sidebar" allowToggle index={index} onChange={setIndex}>
                <AccordionItem>
                    {({ isExpanded }) => (
                        <>
                            <AccordionButton>
                                {selected ? buildLabel(selected) : <FormattedMessage id={accordionPlaceholderId} />}
                                <Icon src={DownArrow} alt="" />
                            </AccordionButton>

                            <AccordionPanel>
                                <Box className="sidebar-search">
                                    <SearchBar
                                        textHolder={intl.formatMessage({ id: searchPlaceholderId })}
                                        onChange={searchCallback}
                                        searchTerm={searchTerm}
                                    />
                                </Box>
                                {children}
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
            </Accordion>
        </Sidebar>
    );
};

const Sidebar = styled.div`
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--chakra-colors-border-secondary);
    background: var(--chakra-colors-background-primary);

    // remove x-padding from the root tree items
    [role='tree'] > [role='treeitem'] {
        padding: 0;
    }

    @media (max-width: 768px) {
        .sidebar-search {
            margin-bottom: 8px;

            input {
                background: var(--chakra-colors-white);
            }
        }
    }

    @media (min-width: 769px) {
        position: relative;

        .sidebar-search {
            margin-bottom: 0;
            padding: 0 24px 12px;
            border-bottom: 1px solid var(--chakra-colors-border-secondary);
        }
    }
`;

const Icon = styled.img`
    margin-left: auto;
    transition: transform 0.2s;

    [aria-expanded='true'] & {
        transform: rotate(-180deg);
    }
`;

export default LayoutSidebar;
