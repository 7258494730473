import CustomIconButton from '../utils/CustomIconButton';
import Popover from '../utils/Popover';

import { ReactComponent as InfoIcon } from '../../icons/info.svg';

const InfoPopover = ({ info, placement = 'top' }) => (
    <Popover
        placement={placement}
        trigger={
            <CustomIconButton variant="circular-icon" mt="0px !important" mr="5px !important" icon={<InfoIcon />} />
        }
    >
        {info}
    </Popover>
);

export default InfoPopover;
