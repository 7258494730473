export const findNestedRoutes = (source, predicate) =>
    source.reduce((previousValue, currentValue) => {
        if (currentValue?.url && predicate(currentValue)) {
            previousValue.push(currentValue.url.split('?')[0]);
        } else if (currentValue?.items?.length) {
            previousValue.push(...findNestedRoutes(currentValue.items, predicate));
        }

        return previousValue;
    }, []);

export const findNestedHelpPageUrls = (source, predicate) =>
    source.reduce((previousValue, currentValue) => {
        if (currentValue?.helpPageUrl && predicate(currentValue)) {
            previousValue.push(currentValue.helpPageUrl);
        } else if (currentValue?.items?.length) {
            previousValue.push(...findNestedHelpPageUrls(currentValue.items, predicate));
        }

        return previousValue;
    }, []);

export const isRouteIncludedInList = (url, routes) => {
    let result = false;
    const pathname = url.split('?')[0];

    for (let i = 0; i < routes.length; i++) {
        if (routes[i] !== '/' && pathname.startsWith(routes[i])) {
            result = true;
            break;
        }
    }

    return result;
};
