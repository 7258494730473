import React from 'react';
import { MenuGroup } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

const NavigationMenuGroup = ({ config, renderItem }) => {
    return (
        <StyledMenuGroup title={config.label} bg="background-quinary" color="menu-group-text">
            {config.items.map((menuItem, menuItemIndex) => (
                <React.Fragment key={menuItemIndex}>{renderItem(menuItem)}</React.Fragment>
            ))}
        </StyledMenuGroup>
    );
};

const StyledMenuGroup = styled(MenuGroup)`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    border-radius: 6px;
    padding: var(--chakra-space-1) var(--chakra-space-3);
    margin: 0 0 6px;
    width: 100%;
`;

export default NavigationMenuGroup;
