import React, { useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';

import {
    useDisclosure,
    Heading,
    Box,
    Flex,
    IconButton,
    Text,
    Spacer,
    Table,
    TableContainer,
    Th,
    Td,
    Tr,
    Thead,
    Tbody,
} from '@chakra-ui/react';

import { addPin, deleteMapElement } from '../../services/maps';
import useCommonToast from '../../hooks/useCommonToast';

import LoadingModal from '../../components/modal/LoadingModal';

import Popover from '../utils/Popover';
import CustomIconButton from '../utils/CustomIconButton';

import InputField from '../forms/InputField';

import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as MagnifyIcon } from '../../icons/magnify.svg';
import { ReactComponent as EyeIcon } from '../../icons/eye.svg';
import { ReactComponent as EyeIconHidden } from '../../icons/eye_hidden.svg';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';

import AddItemButton from '../utils/AddItemButton';

import { ReactComponent as PinGreen } from '../../icons/pin_green.svg';
import { ReactComponent as PinOrange } from '../../icons/pin_orange.svg';
import { ReactComponent as PinBlue } from '../../icons/pin_blue.svg';

const Pins = ({ view, pins, pinsLayer, onPinCreateSuccess, onPinDeleteSuccess }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();

    const [newPinLabel, setNewPinLabel] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [pinSelected, setPinSelected] = useState('green');
    const [hiddenPins, setHiddenPins] = useState([]);

    const pinCreation = useDisclosure();

    const deletePin = async (id) => {
        setIsLoading(true);
        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'pin' });
        try {
            await deleteMapElement(id);
            onPinDeleteSuccess(id);
            toast(successMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const createPin = useCallback(
        async (event) => {
            setIsLoading(true);
            const successMessage = intl.formatMessage({ id: 'maps_drawer_add_pin_success' });

            const payload = {
                description: newPinLabel,
                data: JSON.stringify({
                    point: event.mapPoint,
                    pinType: pinSelected,
                }),
            };

            try {
                const newPin = await addPin(payload);
                onPinCreateSuccess(newPin);

                toast(successMessage);
            } finally {
                setIsLoading(false);
                pinCreation.onClose();
                setNewPinLabel('');
            }
        },
        [pinCreation, newPinLabel, pinSelected, onPinCreateSuccess, toast, intl]
    );

    const goToPin = (id) => {
        const graphic = pinsLayer.graphics.find((i) => i.attributes.id === id);
        if (graphic) {
            view.goTo(graphic.geometry);
        }
    };

    const goToAllPins = (id) => {
        view.goTo(pinsLayer.graphics);
    };

    const togglePinVisibility = (id) => {
        const graphic = pinsLayer.graphics.find((i) => i.attributes.id === id);
        if (graphic) {
            graphic.visible = !graphic.visible;

            let label = pinsLayer.graphics.find((l) => l.attributes.id === id + '_label');
            if (label) {
                label.visible = !label.visible;
            }

            if (hiddenPins.includes(graphic.attributes.id)) {
                setHiddenPins([...hiddenPins].filter((e) => e !== graphic.attributes.id));
            } else {
                setHiddenPins([...hiddenPins, graphic.attributes.id]);
            }
        }
    };

    const toggleAllPinsVisibility = () => {
        if (hiddenPins.length === pins.data.length) {
            //Show all
            pinsLayer.graphics.forEach((g) => (g.visible = true));
            setHiddenPins([]);
        } else {
            //Hide all
            const allGraphicIds = pinsLayer.graphics.map((g) => {
                g.visible = false;
                return g.attributes?.labelGraphic ? null : g.attributes?.id;
            });
            setHiddenPins(allGraphicIds.filter((e) => e));
        }
    };

    const handleMapClick = useCallback(
        (event) => {
            if (pinCreation.isOpen && !isLoading) {
                createPin(event);
            }
            event.stopPropagation();
        },
        [createPin, pinCreation, isLoading]
    );

    let clickEvt = view.on('click', (e) => {
        handleMapClick(e);
        clickEvt.remove();
    });

    return (
        <Box mt={6}>
            <Flex align="center" justify="space-between">
                <Flex align="center">
                    <Heading as="h4" variant="h4" mr={2}>
                        <FormattedMessage id="maps_drawer_pins" />
                    </Heading>
                    <Popover
                        placement="top"
                        trigger={<CustomIconButton variant="circular-icon" mt="0px !important" icon={<InfoIcon />} />}
                    >
                        <FormattedMessage id="maps_drawer_pins_info" />
                    </Popover>
                </Flex>
                <Spacer />
                <Box>
                    <AddItemButton width="fit-content" leftIcon={<AddIcon />} onClick={pinCreation.onToggle}>
                        <Text size="sm" color="gray.900" pl={1} pt={1}>
                            <FormattedMessage id="maps_drawer_add_pin" />
                        </Text>
                    </AddItemButton>
                </Box>
            </Flex>

            {pinCreation.isOpen && (
                <Flex direction="column" gap={3}>
                    <Text fontSize="12px" fontWeight="500" color="black">
                        <FormattedMessage id="maps_drawer_pins_click_to_add" />
                    </Text>
                    <Text fontSize="12px" fontWeight="500" color="gray.600">
                        <FormattedMessage id="maps_drawer_pins_style" />
                    </Text>
                    <Flex>
                        <StyledPinIcon
                            icon={<PinGreen />}
                            variant="ghost"
                            isSelected={pinSelected === 'green'}
                            textTransform="capitalize"
                            onClick={() => setPinSelected('green')}
                        />
                        <StyledPinIcon
                            icon={<PinBlue />}
                            variant="ghost"
                            isSelected={pinSelected === 'blue'}
                            textTransform="capitalize"
                            onClick={() => setPinSelected('blue')}
                        />
                        <StyledPinIcon
                            icon={<PinOrange />}
                            variant="ghost"
                            isSelected={pinSelected === 'orange'}
                            textTransform="capitalize"
                            onClick={() => setPinSelected('orange')}
                        />
                    </Flex>
                    <InputField
                        id="bookmarkName"
                        name="bookmarkName"
                        label={intl.formatMessage({ id: 'maps_drawer_pin_label' })}
                        value={newPinLabel}
                        type="text"
                        onChange={(e) => setNewPinLabel(e.target.value)}
                        maxW="600px"
                    />
                </Flex>
            )}

            {pins.data.length === 0 ? (
                <FormattedMessage id="maps_drawer_no_pins" />
            ) : (
                <TableContainer>
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr height={50}>
                                <StyledTh />
                                <StyledTh />
                                <StyledTh>
                                    {pins.data.length > 1 && (
                                        <>
                                            <IconButton
                                                leftIcon={
                                                    hiddenPins.length === pins.data.length ? (
                                                        <EyeIconHidden />
                                                    ) : (
                                                        <EyeIcon />
                                                    )
                                                }
                                                onClick={toggleAllPinsVisibility}
                                                variant="circular-icon"
                                            />
                                            <IconButton
                                                variant="circular-icon"
                                                leftIcon={<MagnifyIcon />}
                                                onClick={goToAllPins}
                                            />
                                        </>
                                    )}
                                </StyledTh>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {pins.data.map((pin) => (
                                <Tr height={50} key={pin.id}>
                                    <StyledTd>
                                        {pin.data.pinType === 'green' && <PinGreen className="small-pin" />}
                                        {pin.data.pinType === 'blue' && <PinBlue className="small-pin" />}
                                        {pin.data.pinType === 'orange' && <PinOrange className="small-pin" />}
                                    </StyledTd>
                                    <StyledTd width="100%">
                                        <PinName>{pin.description}</PinName>
                                    </StyledTd>
                                    <StyledTd>
                                        <IconButton
                                            leftIcon={hiddenPins.includes(pin.id) ? <EyeIconHidden /> : <EyeIcon />}
                                            onClick={() => togglePinVisibility(pin.id)}
                                            variant="circular-icon"
                                        />
                                        <IconButton
                                            leftIcon={<MagnifyIcon />}
                                            onClick={() => goToPin(pin.id)}
                                            variant="circular-icon"
                                        />
                                        <IconButton
                                            leftIcon={<DeleteIcon />}
                                            onClick={() => deletePin(pin.id)}
                                            variant="circular-icon"
                                        />
                                    </StyledTd>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            )}
            {isLoading && <LoadingModal header={<FormattedMessage id="common_loading" />} isOpen />}
        </Box>
    );
};

const StyledTd = styled(Td)`
    padding: 0px 5px !important;

    .small-pin {
        width: 20px;
        height: 20px;
        margin: auto;
    }
`;
const StyledTh = styled(Th)`
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    padding: 0px 5px !important;
`;

const PinName = styled(Text)`
    margin-left: var(--chakra-space-2);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--chakra-colors-gray-900);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;
const StyledPinIcon = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'isSelected' })`
    background: ${(props) => props.isSelected && 'var(--chakra-colors-blue-100);'};
    box-shadow: ${(props) => props.isSelected && 'var(--chakra-shadows-outline);'};
`;

export default Pins;
