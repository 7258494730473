import { useState, useEffect, useMemo, useCallback } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Skeleton, Text, Box, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import useCommonToast from '../hooks/useCommonToast';
import useHistoryPush from '../hooks/useHistoryPush';

import {
    getItems,
    getItemType,
    addItemNextToParent,
    sortItemsByDescription,
    copyItem,
    findItem,
    addItemToPortfolios,
} from '../services/items';

import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as PowerCableIcon } from '../icons/item-icons/load.svg';

import loadPlaceholder from '../images/load-unselected.png';

import LayoutSidebar from '../components/layout/LayoutSidebar';
import LayoutWrapper from '../components/layout/LayoutWrapper';
import WrapperEmptyState from '../components/layout/WrapperEmptyState';
import ItemListWrapper from '../components/layout/ItemListWrapper';
import OptimizedItemLinkWrapper from '../components/utils/OptimizedItemLinkWrapper';
import VirtualisedItemList from '../components/utils/VirtualisedItemList';
import AddItemPortfolios from '../components/utils/AddItemPortfolios';
import AddItemModal from '../components/utils/AddItemModal';
import AddItemButton from '../components/utils/AddItemButton';

import Load from '../components/load/Load';

const Loads = () => {
    const { itemTypes } = useSelector((state) => state.item);
    const { itemTypeId } = getItemType(itemTypes, 'Load') || {};

    const intl = useIntl();
    const { path, url } = useRouteMatch();
    const historyPush = useHistoryPush();
    const { toast } = useCommonToast();

    const addModal = useDisclosure();
    const addLoadItemModal = useDisclosure();

    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loads, setLoads] = useState([]);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedLoad, setSelectedLoad] = useState(null);
    const [temporaryNode, setTemporaryNode] = useState(null);
    const { isSidebarVisible } = useSelector((state) => state.helpers);

    useEffect(() => {
        if (itemTypes.length > 0) {
            getItems(itemTypeId)
                .then((data) => setLoads(data))
                .catch(() => setLoadingError(true))
                .finally(() => setIsLoading(false));
        }
    }, [itemTypeId, itemTypes]);

    useEffect(() => {
        const term = search.trimStart().toLowerCase();
        const filteredData = loads.filter((o) => o.description.toLowerCase().includes(term));
        setSearchResults(filteredData);
    }, [loads, search]);

    const ItemType = itemTypeId ? true : false;

    // @todo don't recalculate the itemIndex on each rerender - useMemo
    const itemIndex = searchResults.findIndex((item) => item.id === selectedLoad?.id);

    const noSearchResults = useMemo(() => {
        return search.trim().length > 0 && searchResults.length === 0;
    }, [search, searchResults]);

    const copySingleLoad = async ({ id }) => {
        toast({ status: 'info', message: intl.formatMessage({ id: 'common_copy_btn_progress_message' }) });

        const temporaryNode = await copyItem(id);
        setLoads((prev) => addItemNextToParent(prev, id, temporaryNode));

        historyPush(`${url}/${temporaryNode.id}`);
    };

    const onAddModalOpen = ({ id }) => {
        setTemporaryNode(findItem(loads, id));

        addModal.onOpen();
    };

    const closeAddModal = () => {
        addModal.onClose();
        setTemporaryNode(null);
    };

    const getAddItemSuccessMessage = (item, portfolios) => {
        if (portfolios.length === 1) {
            return intl.formatMessage(
                { id: 'common_add_item_success_message' },
                { item: item.description, destination: portfolios[0].properties.name }
            );
        }

        return intl.formatMessage(
            { id: 'common_add_item_to_multiple_portfolios_success_message' },
            { item: item.description, n: portfolios.length }
        );
    };

    const onAddSuccess = (selectedPortfolios) => {
        toast(getAddItemSuccessMessage(temporaryNode, selectedPortfolios));

        closeAddModal();
    };

    const onLoadEdit = (selectedLoad) => {
        const updatedLoads = loads.map((load) => {
            if (load.id === selectedLoad.id) {
                return { ...selectedLoad };
            }
            return load;
        });
        setLoads(updatedLoads);
    };

    const onLoadDelete = (selectedLoad) => {
        const updatedLoads = loads.filter((load) => load.id !== selectedLoad.id);
        setLoads(updatedLoads);
    };

    const onLoadClick = useCallback((load) => {
        if (load) {
            setSelectedLoad(load);
        }
    }, []);

    const onSearchChange = (value) => {
        setSearch(value);
    };

    const updateLoads = (newLoadItem) => {
        setLoads(sortItemsByDescription([...loads, newLoadItem]));
        setSelectedLoad(newLoadItem);
    };

    return (
        <LayoutWrapper
            sidebar={
                isSidebarVisible && (
                    <LayoutSidebar
                        itemType={'load'}
                        selected={selectedLoad}
                        titleId={'load-title'}
                        titleMessage={intl.formatMessage({ id: 'load_title' })}
                        accordionPlaceholderId={'load_select_item'}
                        searchPlaceholderId={'common_search_loads'}
                        searchCallback={onSearchChange}
                        searchTerm={search}
                    >
                        <ItemListWrapper>
                            <Box px={6} pb={2}>
                                <AddItemButton leftIcon={<AddIcon />} onClick={addLoadItemModal.onOpen}>
                                    <Text size="sm" color="gray.900" pl={1} pt={1}>
                                        <FormattedMessage id="load_add_new_item" />
                                    </Text>
                                </AddItemButton>
                                {isLoading ? (
                                    <Skeleton>
                                        <Box h="34px">
                                            <FormattedMessage id="common_loading" />
                                        </Box>
                                    </Skeleton>
                                ) : loadingError ? (
                                    <Alert status="error">
                                        <AlertIcon />

                                        <Box>
                                            <AlertTitle textTransform="capitalize">
                                                <FormattedMessage id="common_error" />
                                            </AlertTitle>

                                            <AlertDescription>
                                                <FormattedMessage
                                                    id="common_loading_error"
                                                    values={{ items: 'loads' }}
                                                />
                                            </AlertDescription>
                                        </Box>
                                    </Alert>
                                ) : noSearchResults ? (
                                    <Alert status="info">
                                        <AlertIcon />

                                        <FormattedMessage id="common_no_search_results" />
                                    </Alert>
                                ) : null}
                            </Box>
                            {!noSearchResults && !loadingError && !isLoading && (
                                <VirtualisedItemList
                                    items={searchResults}
                                    onCopy={copySingleLoad}
                                    itemIndex={itemIndex}
                                    itemType={ItemType}
                                    onAddToPortfolioModalOpen={onAddModalOpen}
                                    renderItems={(load) => (
                                        <OptimizedItemLinkWrapper
                                            url={url}
                                            icon={PowerCableIcon}
                                            onClick={onLoadClick}
                                            item={load}
                                        />
                                    )}
                                />
                            )}
                        </ItemListWrapper>
                    </LayoutSidebar>
                )
            }
            content={
                <Switch>
                    <Route exact path={path}>
                        <WrapperEmptyState imgSrc={loadPlaceholder} page="Load" noun="properties" />
                    </Route>

                    {itemTypeId != null && (
                        <Route path={`${path}/:loadId`}>
                            <Load onLoadEdit={onLoadEdit} onLoadDelete={onLoadDelete} typeId={itemTypeId} />
                        </Route>
                    )}
                </Switch>
            }
        >
            {addModal.isOpen && (
                <AddItemPortfolios
                    onClose={closeAddModal}
                    sourceId={temporaryNode.id}
                    itemName={temporaryNode.description}
                    onAddSuccess={onAddSuccess}
                    onSubmit={addItemToPortfolios}
                />
            )}

            {addLoadItemModal.isOpen && (
                <AddItemModal
                    isOpen
                    updateItems={updateLoads}
                    onClose={addLoadItemModal.onClose}
                    itemTypeId={itemTypeId}
                    typeLabel="load"
                    hideStarterDataOption={true}
                />
            )}
        </LayoutWrapper>
    );
};

export default Loads;
