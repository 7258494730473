import { useMemo } from 'react';
import { Heading } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    getScheduledOutages,
    updateScheduledOutages,
    createScheduledOutages,
    deleteScheduledOutages,
    deleteAllScheduledOutages,
    validateScheduledOutages,
    getScheduledOutagesDataSourceView,
} from '../../services/thermals';
import { getValidRowsWithDates } from '../../services/grid';

import { additionalGridKeys } from '../../constants/thermal';

import SubItemData from '../itemData/SubItemData';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';

import { helpAnchors } from 'constants/help';

const ScheduledOutages = ({ subThermalId, onLoadingStart, onLoadingEnd, type, helpPageUrlItemTypeId = null }) => {
    const intl = useIntl();

    const gridResource = useMemo(() => {
        return {
            create: (rows) => createScheduledOutages(subThermalId, rows),
            read: (pagination) => getScheduledOutages(subThermalId, { ...pagination }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate']);
                return updateScheduledOutages(subThermalId, validRows);
            },
            delete: (rows) =>
                deleteScheduledOutages(
                    subThermalId,
                    rows.map((row) => row.generationOutageId)
                ),
            deleteAll: () => deleteAllScheduledOutages(subThermalId),
            validate: (rows) => validateScheduledOutages(subThermalId, rows),
            getDataSourceView: (pagination) => getScheduledOutagesDataSourceView(subThermalId, { ...pagination }),
        };
    }, [subThermalId]);

    const columns = useMemo(() => {
        return [
            {
                type: 'date',
                field: 'startDate',
                headerName: intl.formatMessage({ id: 'thermal_scheduled_outages_start_date' }),
                sort: 'asc',
                cellEditorParams: { required: true },
            },
            {
                type: 'date',
                field: 'endDate',
                headerName: intl.formatMessage({ id: 'thermal_scheduled_outages_end_date' }),
                cellEditorParams: { required: true },
            },

            {
                type: 'number',
                field: 'percentOutage',
                headerName: intl.formatMessage({ id: 'thermal_scheduled_outages_percent_outage' }),
                cellEditorParams: { required: true },
            },
        ];
    }, [intl]);

    return (
        <>
            <Heading as="h3" variant="h3" textTransform="capitalize" mt={2}>
                <FormattedMessage id="thermal_scheduled_outages_heading" />
                <HelpButtonComponent
                    helpAnchor={
                        type === 'generation asset'
                            ? helpAnchors.THERMAL_SCHEDULED_OUTAGES
                            : helpAnchors.DSM_SCHEDULED_OUTAGES
                    }
                    itemTypeId={helpPageUrlItemTypeId}
                />
            </Heading>

            <SubItemData
                tab="11"
                name={additionalGridKeys.SCHEDULED_OUTAGES}
                onLoadingStart={onLoadingStart}
                onLoadingEnd={onLoadingEnd}
                gridResource={gridResource}
                columns={columns}
                addDataColumns={columns}
                primaryKeyName="generationOutageId"
            />
        </>
    );
};

export default ScheduledOutages;
