import { createSlice } from '@reduxjs/toolkit';
import { getForecastTypes } from './forecastApi';

const initialState = {
    types: [],
};

const forecastSlice = createSlice({
    name: 'forecast',
    initialState,
    reducers: {},
    extraReducers: {
        [getForecastTypes.fulfilled]: (state, action) => {
            state.types = action.payload;
        },
    },
});

export default forecastSlice.reducer;
