import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getItemTypes, getPricingPoints } from '../store/item/itemApi';
import { getForecastTypes } from '../store/forecast/forecastApi';
import { getDateIntervals, getLinkedObjects } from '../store/adjustment/adjustmentApi';
import { getUserRestorationPermission, getUserInputDataExtractionPermission } from '../store/helpers/helpersApi';
import { getFeatureFlags } from 'store/featureFlags/featureFlagsApi';

const useFetchCommonMetaData = (isAppReady) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAppReady) {
            dispatch(getItemTypes());
            dispatch(getPricingPoints());
            dispatch(getForecastTypes());
            dispatch(getDateIntervals());
            dispatch(getLinkedObjects());
            dispatch(getUserRestorationPermission());
            dispatch(getUserInputDataExtractionPermission());
            dispatch(getFeatureFlags());
        }
    }, [dispatch, isAppReady]);
};

export default useFetchCommonMetaData;
