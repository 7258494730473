import { useState, useEffect } from 'react';

import { getUserBooks } from '../../services/userManagement';

const useUserBooks = (userId) => {
    const [userBooks, setUserBooks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        if (userId) {
            setIsLoading(true);
            setIsError(false);

            getUserBooks(userId)
                .then((response) => {
                    setUserBooks(response);
                })
                .catch(() => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [userId]);

    return { userBooks, isLoading, isError };
};
export default useUserBooks;
