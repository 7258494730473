import { useState, useEffect } from 'react';

import { getUserRoles } from '../../services/userManagement';

const useUserRoles = (username) => {
    const [userRoles, setUserRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        if (username) {
            setIsLoading(true);
            setIsError(false);

            getUserRoles(username)
                .then((response) => {
                    setUserRoles(response);
                })
                .catch(() => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [username]);

    return { userRoles, isLoading, isError };
};
export default useUserRoles;
