import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Flex,
    Heading,
    Icon,
    Text,
    AlertIcon,
    AlertDescription,
    UnorderedList,
    ListItem,
    Alert,
    AlertTitle,
    CloseButton,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import useHistoryPush from '../hooks/useHistoryPush';

import { setLoading, setActiveReport } from '../store/powerBi/powerBiSlice';
import { getReports, getGroups, getPowerBiEmbedToken, getDataset } from '../store/powerBi/powerBiApi';

import Combobox from '../components/forms/Combobox';
import LoadingModal from '../components/modal/LoadingModal';
import Tooltip from '../components/utils/Tooltip';

import styled from '@emotion/styled/macro';
import { uniq } from 'lodash';

import { ReactComponent as InfoHintIcon } from '../icons/info-hint.svg';
import useWindowDimensions from '../hooks/useWindowDimensions';

const reservedHeight = 260;
//709 - full HD on windows with default settings
const minAvailableHeight = 500;

const PowerBiReports = () => {
    const { accounts } = useMsal();
    const intl = useIntl();
    const historyPush = useHistoryPush();
    const { workspaceId } = useParams();
    const dispatch = useDispatch();
    const [reportRatio, setReportRatio] = useState(0);
    const [errors, setErrors] = useState([]);

    const { isLoading, groups, reports, activeReport, activeDataset, embedToken } = useSelector(
        (state) => state.powerBi
    );
    const { height } = useWindowDimensions();

    const userEmail = useMemo(() => (accounts.length > 0 ? accounts[0].username : ''), [accounts]);
    const availableReportHeight = useMemo(() => {
        const element = document.querySelector('.report-style-class');
        let scrollSize = element ? element.offsetHeight - element.clientHeight : 0;
        scrollSize += scrollSize ? 1 : 0;

        return height - reservedHeight - scrollSize;
    }, [height]);

    useEffect(() => {
        if (workspaceId) {
            dispatch(setLoading(true));

            dispatch(getReports({ groupId: workspaceId })).then((result) => {
                if (result?.error) {
                    dispatch(setLoading(false));
                    setErrors((prevState) => [...prevState, intl.formatMessage({ id: 'reports_not_received' })]);
                }
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceId]);

    useEffect(() => {
        if (groups === undefined) {
            dispatch(setLoading(true));

            dispatch(getGroups()).then((result) => {
                if (result?.error) {
                    dispatch(setLoading(false));
                    setErrors((prevState) => [...prevState, intl.formatMessage({ id: 'workspaces_not_received' })]);
                }
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups]);

    useEffect(() => {
        if (Array.isArray(groups) && Array.isArray(reports) && reportRatio) {
            dispatch(setLoading(false));
        }
    }, [groups, reports, dispatch, reportRatio]);

    useEffect(() => {
        if (activeReport) {
            dispatch(setLoading(true));

            dispatch(getDataset({ groupId: workspaceId, datasetId: activeReport.datasetId })).then((result) => {
                dispatch(setLoading(false));
                if (result?.error) {
                    setErrors((prevState) => [...prevState, intl.formatMessage({ id: 'dataset_not_received' })]);
                }
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeReport]);

    useEffect(() => {
        if (activeDataset) {
            dispatch(setLoading(true));
            dispatch(
                getPowerBiEmbedToken({
                    reportId: activeReport?.id,
                    dataset: activeDataset,
                    identity: userEmail,
                })
            ).then((result) => {
                dispatch(setLoading(false));
                if (result?.error) {
                    setErrors((prevState) => [...prevState, intl.formatMessage({ id: 'embed_token_not_received' })]);
                }
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDataset]);

    const handleErrorCloseButtonClick = useCallback(() => {
        setErrors([]);
    }, []);

    const handleWorkspaceChange = useCallback(
        (groupId) => {
            setReportRatio(0);
            setErrors([]);
            historyPush(`/power_bi_reports/${groupId}`);
        },
        [historyPush]
    );

    const handleReportChange = useCallback(
        (selectedReport) => {
            setReportRatio(0);
            setErrors([]);
            dispatch(setActiveReport(selectedReport));
        },
        [dispatch]
    );

    const handleEmbedErrorEvent = useCallback(
        (event) => {
            if (event.detail?.message === 'LoadReportFailed') {
                setErrors((prevState) =>
                    uniq([...prevState, intl.formatMessage({ id: 'target_report_not_received' })])
                );
            }
        },
        [intl]
    );

    return (
        <AuthenticatedTemplate>
            {isLoading ? <LoadingModal header={intl.formatMessage({ id: 'common_loading' })} isOpen /> : null}

            <Flex flexDirection="column" pt="74px" pb={10}>
                {errors?.length ? (
                    <Alert status="error">
                        <AlertIcon />

                        <Box>
                            <AlertTitle textTransform="capitalize">
                                <FormattedMessage id="common_error" />
                            </AlertTitle>

                            <AlertDescription>
                                <UnorderedList>
                                    {errors.map((error, index) => (
                                        <ListItem key={index}>{error}</ListItem>
                                    ))}
                                </UnorderedList>
                            </AlertDescription>
                        </Box>

                        <CloseButton onClick={handleErrorCloseButtonClick} position="absolute" right={2} top={2} />
                    </Alert>
                ) : null}

                <StyledFlexStatusBar>
                    <Flex alignItems={'center'} flexShrink={0} mr={4}>
                        <Heading as="h2" variant="h2" color="black" mr={2}>
                            <FormattedMessage id="power_bi_reports" />
                        </Heading>

                        <StyledTooltip label={intl.formatMessage({ id: 'power_bi_reports_hint' })}>
                            <Icon as={InfoHintIcon} data-comp="tooltip-icon" color={'gray.400'} />
                        </StyledTooltip>
                    </Flex>

                    <Flex alignItems={'flex-end'} justifyContent={'flex-end'} flexGrow={1} flexShrink={1}>
                        <Box maxWidth="240px" flexGrow={1} mr={4}>
                            <Text as="span" fontSize="12px" color="gray.600">
                                <FormattedMessage id="workspace" />
                            </Text>

                            <Combobox
                                options={groups}
                                valueKey="id"
                                labelKey="name"
                                onChange={handleWorkspaceChange}
                                isDisabled={!Array.isArray(groups) || !groups?.length}
                                value={workspaceId}
                            />
                        </Box>

                        <Box maxWidth="548px" flexGrow={1} mr={4}>
                            <Text as="span" fontSize="12px" color="gray.600">
                                <FormattedMessage id="report" />
                            </Text>

                            <Combobox
                                options={reports}
                                labelKey="name"
                                onChange={handleReportChange}
                                isDisabled={!Array.isArray(reports) || !reports?.length}
                                value={activeReport}
                            />
                        </Box>
                    </Flex>
                </StyledFlexStatusBar>

                <StyledBoxEmbedWrapper reportRatio={reportRatio} availableHeight={availableReportHeight}>
                    <PowerBIEmbed
                        embedConfig={{
                            type: 'report',
                            embedUrl: activeReport?.embedUrl,
                            accessToken: embedToken,
                            tokenType: models.TokenType.Embed,
                            settings: {
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: true,
                                    },
                                },
                                background: models.BackgroundType.Transparent,
                            },
                        }}
                        eventHandlers={new Map([['error', handleEmbedErrorEvent]])}
                        cssClassName={'report-style-class'}
                        getEmbeddedComponent={(embeddedReport) => {
                            const initialWidth = embeddedReport.iframe.clientWidth;
                            const initialHeight = embeddedReport.iframe.clientHeight;

                            setReportRatio(initialWidth / initialHeight);
                        }}
                    />
                </StyledBoxEmbedWrapper>
            </Flex>
        </AuthenticatedTemplate>
    );
};

const StyledTooltip = styled(Tooltip)`
    &:hover {
        .tooltip_wrapper {
            transform: translateX(-50%) translateY(100%) translateY(8px) !important;
            max-width: 174px;
            text-transform: none;
        }

        [data-comp='tooltip-icon'] {
            color: var(--chakra-colors-blue-400);
        }
    }
`;

const StyledFlexStatusBar = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    padding: 26px var(--chakra-space-6) 22px var(--chakra-space-5);
    margin-bottom: var(--chakra-space-8);
    border-top: 1px solid var(--chakra-colors-border-secondary);
    border-bottom: 1px solid var(--chakra-colors-border-secondary);
    flex-wrap: wrap;
`;

const StyledBoxEmbedWrapper = styled(Box, {
    shouldForwardProp: (prop) => !['availableHeight', 'availableWidth', 'reportRatio'].includes(prop),
})`
    .report-style-class {
        width: 100%;
        overflow: auto;

        iframe {
            background: var(--chakra-colors-white);
            visibility: ${(props) => (props.reportRatio ? 'visible' : 'hidden')};
            height: ${(props) =>
                props.reportRatio
                    ? props.availableHeight < minAvailableHeight
                        ? `calc(100vw / ${props.reportRatio}) !important`
                        : `${props.availableHeight}px !important`
                    : 'auto !important'};
            width: ${(props) => (props.reportRatio ? `100% !important` : 'auto !important')};
            margin: 0 auto;
        }
    }
`;

export default PowerBiReports;
