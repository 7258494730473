import { Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { downloadFile, exportValidationResults } from 'services/items';

export const DownloadErrorReportButton = ({ fileName, ...rest }) => {
    const [downloadLoading, setDownloadLoading] = useState(false);

    const downloadErrorReport = async () => {
        setDownloadLoading(true);
        try {
            const fileInfo = await exportValidationResults(fileName);
            downloadFile(fileInfo);
        } finally {
            setDownloadLoading(false);
        }
    };

    return (
        <Button
            type="submit"
            isLoading={downloadLoading}
            textTransform="capitalize"
            variant="secondary"
            onClick={downloadErrorReport}
            {...rest}
        >
            <FormattedMessage id="upload_modal_download_error_report" />
        </Button>
    );
};
