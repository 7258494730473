export const GLOBAL_CONSTRAINTS_SCHEMA_CODE = 'PSGLOBCONST';

export const PORTFOLIO_CONSTRAINT_COLUMN_NAME = 'PORTFOLIOCONSTRAINT';
export const TARGET_COMPONENT_COLUMN_NAME = 'TARGETCOMPONENTID';

export const CONSTRAINT_COMPONENTS_TABLE_ID = 57300;
export const CONSTRAINT_COMPONENTS_PRIMARY_KEY = 'OPTCONSTRAINTCOMPID';

export const CONSTRAINT_DETAILS_TABLE_ID = 57267;

export const CONSTRAINT_TABLE_ID = 57260;
