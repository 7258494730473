import { useCallback, useState, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, Flex, Box, useDisclosure, Radio, Stack } from '@chakra-ui/react';

import { GRID_KEYS, deleteAllScalarData, getBasisDataFilters } from '../../services/basis';
import { parseDataFilters, exportItemData } from '../../services/items';

import SecondaryButton from '../utils/SecondaryButton';
import Popover from '../utils/Popover';
import DownloadModal from '../utils/DownloadModal';
import CustomIconButton from '../utils/CustomIconButton';

import ConfirmationModal from '../modal/ConfirmationModal';
import SectionHint from './SectionHint';
import UploadButton from '../itemData/UploadButton';
import RadioGroupField from '../forms/RadioGroupField';
import ScalarTab from '../basis/ScalarTab';

import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

const ScalerSection = ({ options, basis, item }) => {
    const discardScalerModal = useDisclosure();
    const downloadModal = useDisclosure();

    const [key, setKey] = useState(0);
    const [filters, setFilters] = useState(() => getBasisDataFilters(item));
    const [scalerOption, setScalerOption] = useState('manual');

    const refreshTabs = useCallback(() => setKey((prev) => prev + 1), []);

    const switchToScalerHistorical = useCallback(async () => {
        await deleteAllScalarData(basis.basisId);

        setScalerOption('historical');
        discardScalerModal.onClose();
    }, [basis.basisId, discardScalerModal]);

    const onRadioChange = (val) => {
        if (val === 'historical') {
            discardScalerModal.onOpen();
            return;
        }

        setScalerOption(val);
    };

    const onDownloadData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const { startDateFrom, startDateTo } = parseDataFilters(filters);

            const payload = {
                startDateFrom,
                startDateTo,
                sorting: sortOrder[GRID_KEYS.basisConfiguratorScaler],
                exportTemplate,
            };

            return exportItemData(`/basis/${basis.basisId}/export-scalers`, payload);
        },
        [basis.basisId, filters]
    );

    return (
        <Fragment key={key}>
            <SectionHint hintHeader="hint_heading_scaler" hintText="hint_text_scaler" />

            <RadioGroupField name="scalerOption" value={scalerOption} onChange={onRadioChange} mb={2}>
                <Stack>
                    <Flex>
                        <Radio value="mi" mr={2} isDisabled>
                            <FormattedMessage id="basis_configurator_scaler_section_mi" />
                        </Radio>

                        <Popover placement="top" trigger={<CustomIconButton icon={<InfoIcon />} />}>
                            <FormattedMessage id="basis_configurator_scaler_section_mi_popover" />
                        </Popover>
                    </Flex>

                    <Radio value="historical">
                        <FormattedMessage id="basis_configurator_scaler_section_historical" />
                    </Radio>

                    <Radio value="manual">
                        <FormattedMessage id="basis_configurator_scaler_section_manual" />
                    </Radio>
                </Stack>
            </RadioGroupField>

            {scalerOption === 'manual' && (
                <>
                    <ScalarTab
                        actionBarSlot={
                            <>
                                <SecondaryButton
                                    leftIcon={<DownloadIcon />}
                                    w="auto !important"
                                    mr={2}
                                    mb={{ base: 4, xl: 0 }}
                                    size="sm"
                                    type="button"
                                    variant="secondary"
                                    onClick={downloadModal.onOpen}
                                >
                                    <FormattedMessage id="common_download_btn_tooltip_and_label" />
                                </SecondaryButton>

                                <UploadButton
                                    type="scaler"
                                    itemId={basis.basisId}
                                    hasLabel={true}
                                    mr={4}
                                    mb={{ base: 4, xl: 0 }}
                                    w="auto !important"
                                    onAfterUploadChange={refreshTabs}
                                />
                            </>
                        }
                        name={GRID_KEYS.basisConfiguratorScaler}
                        options={options}
                        basis={basis}
                        filters={filters}
                        onFiltersChange={setFilters}
                        item={item}
                    />
                </>
            )}

            {discardScalerModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={discardScalerModal.onClose}
                    header={<FormattedMessage id="basis_configurator_scaler_section_discard_heading" />}
                    hasExtraStep
                    content={
                        <Box>
                            <Text>
                                <FormattedMessage id="basis_configurator_scaler_section_discard_body" />
                            </Text>
                        </Box>
                    }
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_confirm" />
                        </Box>
                    }
                    onConfirm={switchToScalerHistorical}
                />
            )}

            {downloadModal.isOpen && (
                <DownloadModal
                    isOpen
                    onClose={downloadModal.onClose}
                    onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                    onDownloadTemplate={() => onDownloadData(true)}
                    bodyMessage={<FormattedMessage id="scaler_download_modal_explanation" />}
                />
            )}
        </Fragment>
    );
};

export default ScalerSection;
