import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { onIsSidebarVisible } from '../../store/helpers/helpersSlice';

import { ReactComponent as CollapseFullscreenIcon } from '../../icons/collapse-fullscreen.svg';
import { ReactComponent as ExpandFullscreenIcon } from '../../icons/expand-fullscreen.svg';

import SecondaryIconButton from '../utils/SecondaryIconButton';

const ShowHideSidebarButton = ({ includeMessage = true }) => {
    const { isSidebarVisible } = useSelector((state) => state.helpers);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(onIsSidebarVisible(true));
        };
    }, [dispatch]);

    const changeExpanded = () => {
        dispatch(onIsSidebarVisible(!isSidebarVisible));
    };

    return (
        <SecondaryIconButton
            leftIcon={!isSidebarVisible ? <CollapseFullscreenIcon /> : <ExpandFullscreenIcon />}
            size="sm"
            type="button"
            variant="secondary"
            onClick={changeExpanded}
        >
            {includeMessage && (
                <FormattedMessage id={!isSidebarVisible ? 'common_collapse_btn' : 'common_expand_btn'} />
            )}
        </SecondaryIconButton>
    );
};

export default ShowHideSidebarButton;
