import axios from 'axios';
import { isDate } from 'date-fns';

import { checkValidityBeforeSave, lenientParse, createDateAsUTC } from '../../services/items';

export const GRID_KEY = 'hedgesContracts';

export const multisortPairs = {
    peakPeriodId: 'peakPeriod',
};

export const getHedge = async (hedgeId) => {
    const response = await axios.get(`/hedges/${hedgeId}`);
    return response.data;
};

export const validateHedge = async (payload) => {
    const response = await axios.post('/hedges/validate', payload);
    return response.data;
};

export const getHedgeFilters = async (hedgeId, itemTypeId) => {
    const response = await axios.get(`/hedges/${hedgeId}/filters/${itemTypeId}`);
    return response.data;
};

export const getHedgeInstruments = async (hedgeId, payload) => {
    const data = typeof payload === 'number' ? { instrumentTypeId: payload } : payload;

    const response = await axios.post(`/hedges/${hedgeId}/instruments`, data);

    return response.data;
};

export const getHedgeInstrumentsDataSourceView = async (hedgeId, payload) => {
    const data = typeof payload === 'number' ? { instrumentTypeId: payload } : payload;

    const response = await axios.post(`/hedges/${hedgeId}/instruments/datasourceview`, data);

    return response.data;
};

export const resetPlantTypes = async (hedgeId, instrumentTypeIds) => {
    const payload = {
        instrumentTypeIds,
    };
    const response = await axios.put(`/hedges/${hedgeId}/reset-plant-types`, payload);
    return response.data;
};

export const getResetPlantTypesCount = async (hedgeId, instrumentTypeIds) => {
    const payload = {
        instrumentTypeIds,
    };
    const response = await axios.post(`/hedges/${hedgeId}/reset-plant-types-count`, payload);
    return response.data;
};

export const modifyHedgeProperty = async (hedgeId, config) => {
    const propertyNamesMap = {
        primaryCommodities: 'PrimaryCommodity',
        counterparties: 'CounterParty',
        secondaryCommodities: 'SecondaryCommodity',
        quantityTypes: 'QuantityType',
        masterAgreements: 'MasterAgreement',
        plantTypes: 'PlantType',
        dates: 'Date',
    };

    const propertyValue =
        config.propertyValue === -1 || config.propertyValue === null
            ? null
            : isDate(config.propertyValue)
            ? config.propertyValue
            : config.propertyValue.toString();

    const payload = {
        instrumentTypeId: config.instrumentType,
        propertyName: propertyNamesMap[config.propertyName],
        propertyValue,
        itemTypeId: config?.itemTypeId,
    };

    const response = await axios.put(`/hedges/${hedgeId}/modify-property`, payload);

    return response.data;
};

export const createInstruments = async (payload) => {
    const response = await axios.post('/hedges/create-instruments', payload);
    return response.data;
};

export const getAdjustmentsForInstrument = async (instrumentId, payload) => {
    const response = await axios.post(`hedges/${instrumentId}/adjustments`, payload);
    return {
        totalCount: response.data.totalCount,
        data: response.data.items,
    };
};

export const getValidRows = (rows, itemTypeId) => {
    return rows
        .map((row) => {
            const { startDate, endDate, expiryDate, transactionDate, ...rest } = row.data;

            const isValid =
                startDate &&
                checkValidityBeforeSave(startDate) &&
                endDate &&
                checkValidityBeforeSave(endDate) &&
                // expiryDate & transactionDate are not required and can be empty, but should be a valid date otherwise
                checkValidityBeforeSave(expiryDate) &&
                checkValidityBeforeSave(transactionDate);

            if (isValid) {
                return {
                    ...rest,
                    startDate: createDateAsUTC(lenientParse(startDate)),
                    endDate: createDateAsUTC(lenientParse(endDate)),
                    expiryDate: expiryDate ? createDateAsUTC(lenientParse(expiryDate)) : null,
                    transactionDate: transactionDate ? createDateAsUTC(lenientParse(transactionDate)) : null,
                    itemTypeId,
                };
            }

            return null;
        })
        .filter((row) => row !== null);
};

export const getValidAdjustmentRows = (rows) => {
    return rows.map((row) => {
        const { startDate, endDate, ...rest } = row.data;

        let dates = {};
        if (!startDate && !endDate) {
            dates = { startDate, endDate };
        } else if (startDate && !endDate) {
            const isValid = checkValidityBeforeSave(startDate);

            if (isValid) {
                dates = {
                    startDate: createDateAsUTC(lenientParse(startDate)),
                    endDate: null,
                };
            }
        } else if (!startDate && endDate) {
            const isValid = checkValidityBeforeSave(endDate);

            if (isValid) {
                dates = {
                    startDate: null,
                    endDate: createDateAsUTC(lenientParse(endDate)),
                };
            }
        } else if (startDate && endDate) {
            const isValidStartDate = checkValidityBeforeSave(startDate);
            const isValidEndDate = checkValidityBeforeSave(endDate);

            if (isValidStartDate && isValidEndDate) {
                dates = {
                    startDate: createDateAsUTC(lenientParse(startDate)),
                    endDate: createDateAsUTC(lenientParse(endDate)),
                };
            }
        }

        return {
            ...dates,
            ...rest,
        };
    });
};

export const createAdjustments = (instrumentId, payload) =>
    axios.post(`/hedges/${instrumentId}/create-adjustments`, payload, { suppressErrorMessageHandler: true });

export const updateAdjustmentCells = (instrumentId, payload) =>
    axios.put(`/hedges/${instrumentId}/modify-adjustments`, payload);

export const deleteAdjustments = (instrumentId, payload) =>
    axios.delete(`/hedges/${instrumentId}/adjustments`, { data: payload });

export const deleteAllAdjustments = (instrumentId) => axios.delete(`/hedges/${instrumentId}/delete-all-adjustments`);

export const deleteInstruments = (hedgeId, instrumentIds) =>
    axios.delete(`/hedges/${hedgeId}/instruments`, { data: instrumentIds });

export const updateInstruments = (payload) => axios.put(`/hedges/modify-instruments`, payload);

export const gridColumns = [
    { field: 'useFlag', hide: false },
    { field: 'instrumentTypeId', hide: false },
    { field: 'primaryCommodityId', hide: false },
    { field: 'startDate', hide: false },
    { field: 'endDate', hide: false },
    { field: 'peakPeriod', hide: false },
    { field: 'price1', hide: false },
    { field: 'price2', hide: false },
    { field: 'price3', hide: false },
    { field: 'price4', hide: false },
    { field: 'price5', hide: false },
    { field: 'price6', hide: false },
    { field: 'quantity', hide: false },
    { field: 'quantityTypeId', hide: false },
    { field: 'counterPartyId', hide: false },
    { field: 'transactionDate', hide: false },
    { field: 'expiryDate', hide: false },
    { field: 'masterAgreementId', hide: false },
    { field: 'plantTypeId', hide: false },
    { field: 'secondaryCommodityId', hide: false },

    // expanded
    { field: 'sourceLocation', hide: false },
    { field: 'systemIdentifier', hide: false },
    { field: 'expansion1', hide: false },
    { field: 'expansion2', hide: false },
    { field: 'expansion3', hide: false },
    { field: 'expansion4', hide: false },
    { field: 'expansion5', hide: false },
    { field: 'expansion6', hide: false },
    { field: 'expansion7', hide: false },
    { field: 'expansion8', hide: false },
    { field: 'expansion9', hide: false },
    { field: 'expansion10', hide: false },
    { field: 'expansion11', hide: false },
    { field: 'expansion12', hide: false },
    { field: 'expansion13', hide: false },
    { field: 'expansion14', hide: false },
    { field: 'expansion15', hide: false },
    { field: 'expansion16', hide: false },
    { field: 'expansion17', hide: false },
    { field: 'expansion18', hide: false },
    { field: 'expansion19', hide: false },
    { field: 'expansion20', hide: false },

    // these are not shown to the user
    { field: 'instrumentId', hide: true },
    { field: 'itemId', hide: true },
    { field: 'peakPeriodId', hide: true },
];

export const deleteAllInstruments = (hedgeId) => axios.delete(`/hedges/${hedgeId}/delete-all-instruments`);
