import { useState, useEffect, useMemo, useCallback } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Skeleton, Text, Box, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import useCommonToast from '../hooks/useCommonToast';
import useHistoryPush from '../hooks/useHistoryPush';

import {
    getItems,
    getItemType,
    addItemNextToParent,
    sortItemsByDescription,
    copyItem,
    findItem,
    addItemToPortfolios,
    capitalizeFirstLetter,
} from '../services/items';

import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as WindIcon } from '../icons/turbine.svg';
import { ReactComponent as SolarIcon } from '../icons/item-icons/solar.svg';
import { ReactComponent as RenewablesIcon } from '../icons/turbine.svg';

import renewablesPlaceholder from '../images/wind-unselected.png';

import LayoutSidebar from '../components/layout/LayoutSidebar';
import LayoutWrapper from '../components/layout/LayoutWrapper';
import WrapperEmptyState from '../components/layout/WrapperEmptyState';
import ItemListWrapper from '../components/layout/ItemListWrapper';
import OptimizedItemLinkWrapper from '../components/utils/OptimizedItemLinkWrapper';

import AddItemModal from '../components/utils/AddItemModal';
import AddItemPortfolios from '../components/utils/AddItemPortfolios';
import AddItemButton from '../components/utils/AddItemButton';

import Renewable from '../components/renewables/Renewable';
import VirtualisedItemList from 'components/utils/VirtualisedItemList';

const RENEWABLES_ITEM_TYPE_DESCR = 'Wind Asset';
const WIND_ITEM_TYPE_DESCR = 'Wind Asset';
const WIND_ITEM_TYPE = 'wind';
const SOLAR_ITEM_TYPE_DESCR = 'Solar Asset';
const SOLAR_ITEM_TYPE = 'solar';

const RENEWABLES_ITEM_TYPE_DISPLAY_LABEL = 'renewable';

const Renewables = ({ type }) => {
    const { itemTypes } = useSelector((state) => state.item);

    const itemType =
        type === WIND_ITEM_TYPE
            ? WIND_ITEM_TYPE_DESCR
            : type === SOLAR_ITEM_TYPE
              ? SOLAR_ITEM_TYPE_DESCR
              : RENEWABLES_ITEM_TYPE_DESCR;

    const { itemTypeId } = getItemType(itemTypes, itemType) || {};

    const typeLabel = RENEWABLES_ITEM_TYPE_DISPLAY_LABEL;

    const { isSidebarVisible } = useSelector((state) => state.helpers);

    const { path, url } = useRouteMatch();
    const historyPush = useHistoryPush();
    const { toast } = useCommonToast();
    const intl = useIntl();

    // this icon is used in the items left side bar display
    const RenewableIcon = type === WIND_ITEM_TYPE ? WindIcon : type === SOLAR_ITEM_TYPE ? SolarIcon : RenewablesIcon;

    const emptyPlaceholderImage = renewablesPlaceholder;

    const addRenewableItemModal = useDisclosure();
    const addToPortfolioModal = useDisclosure();

    const [renewables, setRenewables] = useState([]);
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [temporaryNode, setTemporaryNode] = useState(null);

    useEffect(() => {
        if (itemTypes.length > 0) {
            getItems(itemTypeId)
                .then((data) => setRenewables(data))
                .catch(() => setLoadingError(true))
                .finally(() => setIsLoading(false));
        }
    }, [itemTypeId, itemTypes]);

    useEffect(() => {
        const term = search.trimStart().toLowerCase();
        const filteredData = renewables.filter((o) => o.description.toLowerCase().includes(term));
        setSearchResults(filteredData);
    }, [renewables, search]);

    const ItemType = itemTypeId ? true : false;

    // @todo don't recalculate the itemIndex on each rerender - useMemo
    const itemIndex = searchResults.findIndex((item) => item.id === selected?.id);

    const noSearchResults = useMemo(() => {
        return search.trim().length > 0 && searchResults.length === 0;
    }, [search, searchResults]);

    const onRenewableClick = useCallback((renewable) => {
        if (renewable) {
            setSelected(renewable);
        }
    }, []);

    const onSearchChange = (value) => {
        setSearch(value);
    };

    const onRenewableEdit = (selectedRenewable) => {
        const updatedRenewables = renewables.map((renewable) => {
            if (renewable.id === selectedRenewable.id) {
                return { ...selectedRenewable };
            }
            return renewable;
        });
        setRenewables(updatedRenewables);
    };

    const updateRenewables = (newRenewableItem) => {
        setRenewables(sortItemsByDescription([...renewables, newRenewableItem]));
        setSelected(newRenewableItem);
    };

    const onRenewableCopy = async ({ id }) => {
        toast({ status: 'info', message: intl.formatMessage({ id: 'common_copy_btn_progress_message' }) });

        const temporaryRenewable = await copyItem(id);
        setRenewables((prev) => addItemNextToParent(prev, id, temporaryRenewable));

        historyPush(`${url}/${temporaryRenewable.id}`);
    };

    const onRenewableDelete = (selectedRenewable) => {
        const updatedRenewables = renewables.filter((renewable) => renewable.id !== selectedRenewable.id);
        setRenewables(updatedRenewables);
    };

    const onAddToPortfolioModalOpen = ({ id }) => {
        setTemporaryNode(findItem(renewables, id));
        addToPortfolioModal.onOpen();
    };

    const closeAddToPortfolioModal = () => {
        addToPortfolioModal.onClose();
        setTemporaryNode(null);
    };

    const getAddItemSuccessMessage = (item, portfolios) => {
        if (portfolios.length === 1) {
            return intl.formatMessage(
                { id: 'common_add_item_success_message' },
                { item: item.description, destination: portfolios[0].properties.name }
            );
        }

        return intl.formatMessage(
            { id: 'common_add_item_to_multiple_portfolios_success_message' },
            { item: item.description, n: portfolios.length }
        );
    };

    const onAddSuccess = (selectedPortfolios) => {
        toast(getAddItemSuccessMessage(temporaryNode, selectedPortfolios));
        closeAddToPortfolioModal();
    };

    return (
        <LayoutWrapper
            sidebar={
                isSidebarVisible && (
                    <LayoutSidebar
                        itemType={itemType}
                        selected={selected}
                        titleId={`renewables-title`}
                        titleMessage={intl.formatMessage({ id: `renewables_title` })}
                        accordionPlaceholderId={`renewables_select_item`}
                        searchPlaceholderId={`common_search_renewables`}
                        searchCallback={onSearchChange}
                        searchTerm={search}
                    >
                        <ItemListWrapper>
                            <Box px={6} pb={2}>
                                <AddItemButton leftIcon={<AddIcon />} onClick={addRenewableItemModal.onOpen}>
                                    <Text size="sm" color="gray.900" textTransform="capitalize" pl={1} pt={1}>
                                        <FormattedMessage id="renewables_add_new_item" />
                                    </Text>
                                </AddItemButton>

                                {isLoading ? (
                                    <Skeleton>
                                        <Box h="34px">
                                            <FormattedMessage id="common_loading" />
                                        </Box>
                                    </Skeleton>
                                ) : loadingError ? (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <Box>
                                            <AlertTitle textTransform="capitalize">
                                                <FormattedMessage id="common_error" />
                                            </AlertTitle>

                                            <AlertDescription>
                                                <FormattedMessage
                                                    id="common_loading_error"
                                                    values={{ items: `${itemType} items` }}
                                                />
                                            </AlertDescription>
                                        </Box>
                                    </Alert>
                                ) : noSearchResults ? (
                                    <Alert status="info">
                                        <AlertIcon />
                                        <FormattedMessage id="common_no_search_results" />
                                    </Alert>
                                ) : null}
                            </Box>

                            {!noSearchResults && !loadingError && !isLoading && (
                                <VirtualisedItemList
                                    items={searchResults}
                                    onCopy={onRenewableCopy}
                                    itemIndex={itemIndex}
                                    itemType={ItemType}
                                    onAddToPortfolioModalOpen={onAddToPortfolioModalOpen}
                                    renderItems={(renewable) => (
                                        <OptimizedItemLinkWrapper
                                            url={url}
                                            icon={RenewableIcon}
                                            onClick={onRenewableClick}
                                            item={renewable}
                                        />
                                    )}
                                />
                            )}
                        </ItemListWrapper>
                    </LayoutSidebar>
                )
            }
            content={
                <Switch>
                    <Route exact path={path}>
                        <WrapperEmptyState
                            imgSrc={emptyPlaceholderImage}
                            page={capitalizeFirstLetter(itemType)}
                            noun="configuration"
                        />
                    </Route>

                    {itemTypeId != null && (
                        <Route path={`${path}/:renewableId`}>
                            <Renewable
                                typeId={itemTypeId}
                                onRenewableEdit={onRenewableEdit}
                                onRenewableDelete={onRenewableDelete}
                            />
                        </Route>
                    )}
                </Switch>
            }
        >
            {addRenewableItemModal.isOpen && (
                <AddItemModal
                    isOpen
                    updateItems={updateRenewables}
                    onClose={addRenewableItemModal.onClose}
                    itemTypeId={itemTypeId}
                    typeLabel={typeLabel}
                />
            )}

            {addToPortfolioModal.isOpen && (
                <AddItemPortfolios
                    onClose={closeAddToPortfolioModal}
                    sourceId={temporaryNode.id}
                    itemName={temporaryNode.description}
                    onAddSuccess={onAddSuccess}
                    onSubmit={addItemToPortfolios}
                />
            )}
        </LayoutWrapper>
    );
};

export default Renewables;
