import axios from 'axios';

export const createMainMarketModel = (payload) => axios.post(`/main-market-model/create`, payload);

export const getMainMarket = async (mainMarketId, payload) => {
    const response = await axios.post(`/main-market-model/${mainMarketId}`, payload);
    return response.data;
};
export const getDropdownOptions = async () => {
    const response = await axios.get(`/main-market-model/dropdown-options`);
    return response.data;
};

export const modifyModelDefinitions = (itemId, payload) =>
    axios.put(`/main-market-model/${itemId}/update-models`, payload);

export const addModelDefinitionRows = async (itemId, payload) => {
    const response = await axios.post(`/main-market-model/${itemId}/create-models`, payload);
    return response.data;
};

export const validateModelDefinitionRows = async (itemId, payload) => {
    const response = await axios.post(`/main-market-model/${itemId}/validate`, payload);
    return response.data;
};

export const deleteModelDefinitions = (itemId, rowIds) =>
    axios.delete(`/main-market-model/${itemId}/models`, { data: rowIds });

export const getMainMarketModels = async () => {
    const response = await axios.get('/main-market-model/main-market-items');
    return response.data;
};

export const validateMainMarketModel = async (mainMarketModelId) => {
    const response = await axios.get(`/main-market-model/${mainMarketModelId}/validate-models`);
    return response.data;
};

// #region SystemComponent child table CRUD

export const getItemComponentLookup = async () => {
    const response = await axios.get(`/main-market-model/item-components`);
    return response.data;
};

export const getSystemComponentsForModel = async (modelId, payload) => {
    const response = await axios.get(`/main-market-model/${modelId}/system-components`, payload);
    return {
        totalCount: Array.isArray(response.data) ? response.data.length : 0,
        data: response.data,
    };
};

export const createSystemComponents = (modelId, payload) =>
    axios.post(`/main-market-model/${modelId}/create-system-component`, payload, { suppressErrorMessageHandler: true });

export const updateSystemComponentCells = (modelId, payload) =>
    axios.put(`/main-market-model/${modelId}/system-components`, payload);

export const deleteSystemComponents = (modelId, payload) =>
    axios.delete(`/main-market-model/${modelId}/system-component`, { data: payload });

// #endregion
