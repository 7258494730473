import axios from 'axios';

export const getItems = async () => {
    const response = await axios.get('/globalconstraints/items');

    return response.data;
};

export const getSubItems = async (itemIds) => {
    const response = await axios.post('/globalconstraints/subitems', itemIds);
    return response.data;
};

export const checkIfSubItemHasGenerationAsset = async (constraintId) => {
    const response = await axios.get(`/globalconstraints/checkifhasgenerationitemtypes/${constraintId}`);
    return response.data;
};
