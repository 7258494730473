import { useState, useCallback, useMemo } from 'react';
import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
    useDisclosure,
} from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Formik, Form, setNestedObjectValues } from 'formik';

import useCommonToast from '../../hooks/useCommonToast';

import { createForwardCurveDefinition, validateForwardCurveDefinition } from '../../services/forwardCurve';
import { isSubmitDisabled } from '../../services/utils';
import { isNumberCellEmpty } from '../../services/items';

import { mapGeneralValidationMessages } from '../autoform/utils/autoformUtils';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';
import GeneralFormValidationsAlert from '../forms/GeneralFormValidationsAlert';

const AddForwardCurveModal = ({ forwardItem, onAddSubItem, ...rest }) => {
    const { toast } = useCommonToast();
    const intl = useIntl();

    const errorsDisclosure = useDisclosure({ defaultIsOpen: true });
    const warningsDisclosure = useDisclosure({ defaultIsOpen: true });

    const [isValidating, setIsValidating] = useState(false);
    const [generalErrors, setGeneralErrors] = useState([]);
    const [generalWarnings, setGeneralWarnings] = useState([]);
    const [globalErrors, setGlobalErrors] = useState([]);
    const [globalWarnings, setGlobalWarnings] = useState([]);

    const allErrors = useMemo(() => [...globalErrors, ...generalErrors], [generalErrors, globalErrors]);
    const allWarnings = useMemo(() => [...globalWarnings, ...generalWarnings], [generalWarnings, globalWarnings]);

    const formLabels = {
        selectPlaceholder: intl.formatMessage({ id: 'common_forms_select_option' }),
        description: intl.formatMessage({ id: 'add_new_forward_item_name' }),
        commodity: intl.formatMessage({ id: 'add_new_forward_item_commodity' }),
        peakPeriod: intl.formatMessage({ id: 'add_new_forward_item_peak_period' }),
        marketScaler: intl.formatMessage({ id: 'add_new_forward_item_market_scaler' }),
        currency: intl.formatMessage({ id: 'add_new_forward_item_currency' }),
        market: intl.formatMessage({ id: 'add_new_forward_item_market_name' }),
        useCurveDeveloper: intl.formatMessage({ id: 'add_new_forward_item_use_curve_developer' }),
    };

    const initialFormValues = {
        description: '',
        commodityId: '',
        peakPeriodLabelId: '',
        currencyId: '',
        marketId: '',
        marketScaler: '',
        useCurveDeveloper: false,
    };

    const formatFieldValues = useCallback(
        (values) => {
            return {
                ...values,
                itemId: forwardItem.forwardPriceItem.id,
                marketScaler: isNumberCellEmpty(values.marketScaler),
                currencyId: values.currencyId || null,
                marketId: values.marketId || null,
            };
        },
        [forwardItem.forwardPriceItem.id]
    );

    const resetServerValidation = useCallback(() => {
        setGeneralErrors([]);
        setGeneralWarnings([]);
        setGlobalErrors([]);
        setGlobalWarnings([]);
    }, []);

    const onFormSubmit = async (values, { setErrors, setStatus }) => {
        try {
            const successMessage = intl.formatMessage(
                { id: 'common_sub_item_creation_success' },
                { type: 'forward curve' }
            );

            const payload = formatFieldValues(values);
            const newForwardItem = await createForwardCurveDefinition(payload);

            onAddSubItem(newForwardItem.forwardCurveResponse);
            toast(successMessage);
        } catch (err) {
            // reset the previous general errors and warnings
            resetServerValidation();

            // make sure to re-open the disclosures again,
            // because the user might have closed them and is submitting the form again
            errorsDisclosure.onOpen();
            warningsDisclosure.onOpen();

            if (err.response.status !== 500) {
                if (err.response.data.validationResult.issues) {
                    // when creating a single record we expect at most only 1 issue
                    const issue = err.response.data.validationResult.issues[0];

                    // handle general errors and warnings
                    const { warnings, errors } = mapGeneralValidationMessages(issue.generalValidationMessages);
                    setGeneralWarnings(warnings);
                    setGeneralErrors(errors);

                    // handle specific field errors
                    let fieldErrors = {};
                    let fieldStatus = {};

                    for (const field of issue.fields) {
                        const errors = field.validationMessages
                            .filter(({ severity }) => severity === 'Error')
                            .map(({ message }) => message);

                        const warnings = field.validationMessages
                            .filter(({ severity }) => severity === 'Warning')
                            .map(({ message }) => message);
                        fieldStatus[field.name] = { errors, warnings };
                    }

                    setErrors(fieldErrors);
                    setStatus(fieldStatus);
                }

                if (err.response.data.validationResult.globalIssues) {
                    // handle global errors and warnings
                    const { warnings, errors } = mapGeneralValidationMessages(
                        err.response.data.validationResult.globalIssues
                    );
                    setGlobalWarnings(warnings);
                    setGlobalErrors(errors);
                }
            }
        }
    };

    const validateForwardItemName = (value) => {
        const trimmed = value.trim();
        let errorMessage = '';

        if (trimmed.length === 0) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: 'Forward Curve name' }
            );
        } else if (trimmed.length > 50) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Forward Curve name', lengthRule: '50 characters or less' }
            );
        }

        return errorMessage;
    };

    const validateRequiredField = (value, label) => {
        let errorMessage = '';

        if (value === '') {
            errorMessage = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }

        return errorMessage;
    };

    const validate = (values) => {
        const errors = {};

        if (values.useCurveDeveloper && values.marketId == null) {
            errors.marketId = intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: formLabels.market }
            );
        }
        return errors;
    };

    const onServerValidate = useCallback(
        async (values, setErrors, setStatus, setTouched, validateForm) => {
            const errors = await validateForm(values);

            // reset the previous general errors and warnings
            resetServerValidation();

            if (Object.keys(errors).length > 0) {
                setTouched(setNestedObjectValues(errors, true));
            } else {
                try {
                    setIsValidating(true);

                    const payload = formatFieldValues(values);

                    const data = await validateForwardCurveDefinition(payload);

                    // make sure to re-open the disclosures again
                    warningsDisclosure.onOpen();

                    if (data.issues) {
                        // reset the previous general warnings
                        setGeneralWarnings([]);

                        // when creating a single record we expect at most only 1 issue
                        const issue = data.issues[0];

                        // handle general warnings
                        const { warnings } = mapGeneralValidationMessages(issue.generalValidationMessages);
                        setGeneralWarnings(warnings);

                        // handle specific field warnings
                        let fieldStatus = {};

                        for (const field of issue.fields) {
                            const warnings = field.validationMessages
                                .filter(({ severity }) => severity === 'Warning')
                                .map(({ message }) => message);
                            fieldStatus[field.name] = { warnings };
                        }

                        setStatus(fieldStatus);
                        setTouched(setNestedObjectValues(fieldStatus, true));
                    }

                    if (data.globalIssues) {
                        // handle global warnings
                        const { warnings } = mapGeneralValidationMessages(data.globalIssues);
                        setGlobalWarnings(warnings);
                    }

                    if (!data) {
                        toast(intl.formatMessage({ id: 'common_check_data_success' }));
                    }
                } catch (err) {
                    // make sure to re-open the disclosures again,
                    // because the user might have closed them and is submitting the form again
                    errorsDisclosure.onOpen();
                    warningsDisclosure.onOpen();

                    if (err.response?.status !== 500) {
                        if (err.response?.data?.issues) {
                            // when creating a single record we expect at most only 1 issue
                            const issue = err.response.data.issues[0];

                            // handle general errors and warnings
                            const { warnings, errors } = mapGeneralValidationMessages(issue.generalValidationMessages);
                            setGeneralWarnings(warnings);
                            setGeneralErrors(errors);

                            // handle specific field errors
                            let fieldErrors = {};
                            let fieldStatus = {};

                            for (const field of issue.fields) {
                                const errors = field.validationMessages
                                    .filter(({ severity }) => severity === 'Error')
                                    .map(({ message }) => message);

                                const warnings = field.validationMessages
                                    .filter(({ severity }) => severity === 'Warning')
                                    .map(({ message }) => message);
                                fieldStatus[field.name] = { errors, warnings };
                            }

                            setErrors(fieldErrors);
                            setStatus(fieldStatus);
                            setTouched(setNestedObjectValues(fieldStatus, true));
                        }

                        if (err.response?.data?.globalIssues) {
                            // handle global errors and warnings
                            const { warnings, errors } = mapGeneralValidationMessages(err.response.data.globalIssues);
                            setGlobalWarnings(warnings);
                            setGlobalErrors(errors);
                        }
                    }
                } finally {
                    setIsValidating(false);
                }
            }
        },
        [intl, toast, errorsDisclosure, warningsDisclosure, formatFieldValues, resetServerValidation]
    );

    return (
        <Modal {...rest}>
            <ModalOverlay />

            <ModalContent pt={3}>
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="add_new_forward_item_heading" />
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik validate={validate} initialValues={initialFormValues} onSubmit={onFormSubmit}>
                    {({ isSubmitting, errors, touched, values, setErrors, setStatus, setTouched, validateForm }) => {
                        const isDisabled = isSubmitDisabled({ errors, touched });

                        return (
                            <Form noValidate>
                                {allErrors.length > 0 && (
                                    <GeneralFormValidationsAlert
                                        isOpen={errorsDisclosure.isOpen}
                                        onClose={errorsDisclosure.onClose}
                                        messages={allErrors}
                                    />
                                )}

                                {allWarnings.length > 0 && (
                                    <GeneralFormValidationsAlert
                                        status="warning"
                                        title={<FormattedMessage id="common_warning" />}
                                        isOpen={warningsDisclosure.isOpen}
                                        onClose={warningsDisclosure.onClose}
                                        messages={allWarnings}
                                    />
                                )}

                                <ModalBody borderBottom="1px" borderColor="gray.200">
                                    <SimpleGrid columns={{ xl: 3 }} spacingX={6} spacingY={4} mb={4}>
                                        <InputFormikField
                                            id="description"
                                            name="description"
                                            type="text"
                                            label={formLabels.description}
                                            validate={(value) => validateForwardItemName(value)}
                                            isRequired
                                        />

                                        <ComboboxFormikField
                                            id="commodityId"
                                            name="commodityId"
                                            label={formLabels.commodity}
                                            validate={(value) => validateRequiredField(value, formLabels.commodity)}
                                            options={forwardItem.commodities}
                                            valueKey="id"
                                            labelKey="description"
                                            placeholderValue=""
                                            placeholderLabel={formLabels.selectPlaceholder}
                                            isRequired
                                        />

                                        <ComboboxFormikField
                                            id="peakPeriodLabelId"
                                            name="peakPeriodLabelId"
                                            label={formLabels.peakPeriod}
                                            validate={(value) => validateRequiredField(value, formLabels.peakPeriod)}
                                            options={forwardItem.peakPeriods}
                                            valueKey="id"
                                            labelKey="description"
                                            placeholderValue=""
                                            placeholderLabel={formLabels.selectPlaceholder}
                                            isRequired
                                        />

                                        <ComboboxFormikField
                                            id="marketId"
                                            name="marketId"
                                            label={formLabels.market}
                                            options={forwardItem.marketNames}
                                            valueKey="id"
                                            labelKey="description"
                                            placeholderValue=""
                                            placeholderLabel={formLabels.selectPlaceholder}
                                            isRequired={values.useCurveDeveloper}
                                        />

                                        <NumberInputFormikField
                                            id="marketScaler"
                                            name="marketScaler"
                                            type="text"
                                            label={formLabels.marketScaler}
                                        />

                                        <CheckboxFormikField id="useCurveDeveloper" name="useCurveDeveloper" mt={6}>
                                            {formLabels.useCurveDeveloper}
                                        </CheckboxFormikField>

                                        <ComboboxFormikField
                                            id="currencyId"
                                            name="currencyId"
                                            label={formLabels.currency}
                                            options={forwardItem.currencies}
                                            valueKey="id"
                                            labelKey="description"
                                            placeholderValue=""
                                            placeholderLabel={formLabels.selectPlaceholder}
                                        />
                                    </SimpleGrid>
                                </ModalBody>

                                <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                    <Button
                                        variant="secondary"
                                        onClick={() =>
                                            onServerValidate(values, setErrors, setStatus, setTouched, validateForm)
                                        }
                                        isDisabled={isDisabled}
                                        isLoading={isValidating}
                                    >
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id="common_check_data" />
                                        </Box>
                                    </Button>

                                    <Box ml="auto">
                                        <Button variant="secondary" onClick={rest.onClose}>
                                            <Box as="span" textTransform="capitalize">
                                                <FormattedMessage id="common_cancel" />
                                            </Box>
                                        </Button>

                                        <Button type="submit" isDisabled={isDisabled} isLoading={isSubmitting} ml={3}>
                                            <FormattedMessage id="common_item_create_btn" />
                                        </Button>
                                    </Box>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default AddForwardCurveModal;
