import { useMemo, useCallback } from 'react';
import { Box, Text, Button, SimpleGrid } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import Combobox from '../../forms/Combobox';
import MultiSelectField from '../../forms/MultiSelectField';

const RibbonFiltersBar = ({ isLoading, view, onViewChange, filters, onFiltersChange, onApply }) => {
    const intl = useIntl();
    const getMultiSelectLabel = useCallback((option) => `${option.label} ${option.hasData ? '*' : ''}`, []);

    const selected = filters.filter((p) => p.isSelected);

    const isOptionDisabled = useCallback((option) => option.disabled, []);
    const timelineViewOptions = useMemo(
        () => [
            {
                key: 'monthly',
                label: intl.formatMessage({ id: 'batteries_timeline_parameters_select_monthly' }),
                value: 1,
            },
            {
                key: 'yearly',
                label: intl.formatMessage({ id: 'batteries_timeline_parameters_select_yearly' }),
                value: 2,
            },
        ],
        [intl]
    );

    return (
        <SimpleGrid columns={{ xl: 5 }} spacingX={6} spacingY={4} w="100%" alignItems="end">
            <MultiSelectField
                textTransform="capitalize"
                id="parameters"
                name="parameters"
                info={<FormattedMessage id="batteries_timeline_parameters_explanation" />}
                label={intl.formatMessage({ id: 'batteries_timeline_parameters_multiselect_title' })}
                options={filters}
                placeholderLabel={intl.formatMessage({
                    id: 'batteries_timeline_parameters_multiselect_placeholder',
                })}
                multipleSelectedLabel={intl.formatMessage({
                    id: 'batteries_timeline_parameters_multiselect_placeholder',
                })}
                showSelectedCount={true}
                labelKey="label"
                value={selected}
                isOptionDisabled={isOptionDisabled}
                getCustomLabel={getMultiSelectLabel}
                onChange={onFiltersChange}
            />

            <Box>
                <Text size="xs" letterSpacing="0.5px" mb={1}>
                    <FormattedMessage id="batteries_timeline_parameters_select_title" />
                </Text>

                <Combobox
                    options={timelineViewOptions}
                    valueKey="value"
                    labelKey="label"
                    value={view}
                    onChange={onViewChange}
                />
            </Box>

            <Button textTransform="capitalize" minH={4} maxW={100} onClick={onApply} disabled={isLoading}>
                <FormattedMessage id="batteries_timeline_filters_btn" />
            </Button>
        </SimpleGrid>
    );
};

export default RibbonFiltersBar;
