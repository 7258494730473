import { FormattedMessage, useIntl } from 'react-intl';
import { Heading, Flex, Box, Button, Spacer, Divider, IconButton } from '@chakra-ui/react';

import InputFormikField from '../forms/InputFormikField';
import ShowHideSidebarButton from '../layout/ShowHideSidebarButton';

import Tooltip from '../utils/Tooltip';
import ActionBar from '../utils/ActionBar';
import CustomItemIcon from '../utils/CustomItemIcon';
import FormServerErrorMessage from 'components/forms/FormServerErrorMessage';
import FormWarningMessage from 'components/forms/FormWarningMessage';

import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as DeleteLoadIcon } from '../../icons/delete.svg';
import { ReactComponent as FileIcon } from '../../icons/file.svg';

import SecondaryIconButton from '../utils/SecondaryIconButton';
import AutoformIconNode from './AutoformIconNode';
import InfoPopover from 'components/forms/InfoPopover';

/**
 * Presentational component for top header.
 *
 */
const ItemTopHeader = ({
    inputName,
    onItemSave,
    onItemDelete,
    isSubmitting,
    isEditVisible,
    isSaveVisible,
    isSaveDisabled,
    isDeleteVisible,
    itemDescription,
    itemTypeId,
    isMaster,
    info,
    isEditMode,
    onEditClick,
    onPropertiesClick,
    isPropertiesVisible,
    errors,
    warnings,
    config = {},
}) => {
    const intl = useIntl();

    return (
        <>
            <Box pt={3} pl={6}>
                <Flex mt={!isEditVisible && 3} align="center">
                    <CustomItemIcon
                        isMaster={isMaster}
                        icon={config.autoformIcon ?? <AutoformIconNode itemTypeId={itemTypeId} />}
                    />
                    {isEditMode ? (
                        <InputFormikField px={3} flex="1" maxW="500px" name={inputName} type="text" />
                    ) : (
                        <Heading px={2} as="h2" variant="h2">
                            {itemDescription}
                        </Heading>
                    )}

                    {isEditVisible && (
                        <Box display="inline-block" mr={4}>
                            <Tooltip
                                label={intl.formatMessage({
                                    id: 'common_edit_btn_tooltip_and_label',
                                })}
                            >
                                <IconButton
                                    aria-label={intl.formatMessage({
                                        id: 'common_edit_btn_tooltip_and_label',
                                    })}
                                    leftIcon={<EditIcon />}
                                    variant="circular-icon"
                                    onClick={() => onEditClick(!isEditMode)}
                                />
                            </Tooltip>
                        </Box>
                    )}

                    {info && <InfoPopover info={info} placement="bottom" />}
                </Flex>
                {!isEditMode && (
                    <Box>
                        {errors?.map((err) => (
                            <FormServerErrorMessage key={err}>{err}</FormServerErrorMessage>
                        ))}
                        {warnings?.map((warning) => (
                            <FormWarningMessage key={warning}>{warning}</FormWarningMessage>
                        ))}
                    </Box>
                )}
            </Box>

            <ActionBar>
                <ShowHideSidebarButton />
                {isPropertiesVisible && (
                    <SecondaryIconButton
                        leftIcon={<FileIcon />}
                        size="sm"
                        variant="secondary"
                        onClick={onPropertiesClick}
                        flexShrink={0}
                    >
                        <FormattedMessage id="portfolio_item_properties" />
                    </SecondaryIconButton>
                )}

                <Spacer display={{ base: 'none', xl: 'block' }} />

                {isDeleteVisible && (
                    <Button
                        leftIcon={<DeleteLoadIcon />}
                        type="button"
                        variant="special"
                        onClick={onItemDelete}
                        size="sm"
                    >
                        <FormattedMessage
                            id={config?.itemDeleteButtonMessageId ?? 'common_delete_btn_tooltip_and_label'}
                        />
                    </Button>
                )}

                {isSaveVisible && (
                    <Button
                        leftIcon={<SaveIcon />}
                        isLoading={isSubmitting}
                        onClick={onItemSave}
                        disabled={isSaveDisabled}
                        type="submit"
                        size="sm"
                        variant="primary-success"
                    >
                        <FormattedMessage id="common_save_changes_button" />
                    </Button>
                )}
            </ActionBar>

            <Divider />
        </>
    );
};

export default ItemTopHeader;
