import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box } from '@chakra-ui/react';

import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import Error from '../utils/Error';
import LayoutWrapper from '../layout/LayoutWrapper';
import SidebarItemListContainer from '../autoform/SidebarItemListContainer';
import WrapperEmptyState from '../layout/WrapperEmptyState';

import useAutoformLoadMetadata from '../autoform/hooks/useAutoformLoadMetadata';
import useAutoformMetadata from '../autoform/hooks/useAutoformMetadata';

import Groups from './Groups';
import groupsPlaceholder from '../../images/group-unselected.png';
import { ReactComponent as GroupUsersIcon } from '../../icons/group-users.svg';
import AutoformUiProvider from '../autoform/AutoformUiProvider';
import { setSearchTerm } from 'store/helpers/helpersSlice';

const GroupsTab = () => {
    const AUTOFORM_CODE = 'PSGROUPMNG';

    const intl = useIntl();
    const { isLoading, loadingError } = useAutoformLoadMetadata(AUTOFORM_CODE);
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const level1TableId = hierarchicalTables.level1[0]?.id;

    let { path } = useRouteMatch();

    const dispatch = useDispatch();

    useEffect(
        () => () => {
            dispatch(setSearchTerm(''));
        },
        [dispatch]
    );

    if (loadingError)
        return (
            <Box m={6}>
                <Error primaryId="common_error" secondaryId="common_loading_error" />
            </Box>
        );

    return metadata && !isLoading ? (
        <LayoutWrapper
            hasSecondaryMenu={true}
            sidebar={
                <SidebarItemListContainer
                    schemaCode={metadata.schemaCode}
                    tableId={level1TableId}
                    config={{
                        addNewItemMessageId: 'user_management_add_new_group',
                        accordionPlaceholderId: 'user_management_select_group',
                        searchPlaceholderId: 'user_management_search_groups',
                        createSuccessMessage: intl.formatMessage({ id: 'user_management_group_create_success' }),
                        addRecordModalItemType: intl.formatMessage({ id: 'user_management_group' }),
                        itemIcon: <GroupUsersIcon />,
                    }}
                />
            }
            content={
                <Switch>
                    <Route exact path={path}>
                        <WrapperEmptyState imgSrc={groupsPlaceholder} page="groups" noun="properties" item="group" />
                    </Route>

                    <Route path={`${path}/:itemId`}>
                        <AutoformUiProvider>
                            <Groups />
                        </AutoformUiProvider>
                    </Route>
                </Switch>
            }
        />
    ) : (
        <DefinitionsSkeleton />
    );
};

export default GroupsTab;
