import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

const SecondaryButton = styled(Button)`
    .chakra-button__icon {
        color: var(--chakra-colors-gray-600);
    }

    &:hover:not(:disabled) {
        .chakra-button__icon {
            color: var(--chakra-colors-blue-400);
        }
    }

    &:disabled {
        .chakra-button__icon {
            color: inherit;
        }
    }
`;

export default SecondaryButton;
