import { useRef, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';

import DataGridWrapper from '../grid/DataGridWrapper';
import DataGrid from '../grid/DataGrid';

const ModelDefinitionsTab = ({ options, basis, updateBasis, issues }) => {
    const gridApi = useRef(null);
    const intl = useIntl();

    const [columns, setColumns] = useState([
        {
            field: 'basisModelTypeId',
            type: 'select',
            cellEditorParams: { options: options.basisModelTypes, required: false },
            headerName: intl.formatMessage({ id: 'basis_tabs_basis_model_type' }),
            minWidth: 180,
            sortable: false,
        },
        {
            field: 'basicModelId',
            type: 'select',
            editable: basis.basisModelTypeId === 41,
            cellEditorParams: { options: options.basicModels, required: false },
            headerName: intl.formatMessage({ id: 'basis_tabs_basic_model' }),
            minWidth: 180,
            sortable: false,
        },
        {
            field: 'moduleProcessId',
            type: 'select',
            cellEditorParams: { options: options.moduleProcesses, required: false },
            headerName: intl.formatMessage({ id: 'basis_tabs_module_process' }),
            minWidth: 180,
            sortable: false,
        },
        {
            field: 'modelWeightId',
            type: 'select',
            cellEditorParams: { options: options.modelWeights, required: false },
            headerName: intl.formatMessage({ id: 'basis_tabs_model_weight' }),
            minWidth: 180,
            sortable: false,
        },
        {
            field: 'transformMethodId',
            type: 'select',
            cellEditorParams: { options: options.transformMethods, required: false },
            headerName: intl.formatMessage({ id: 'basis_tabs_transform_method' }),
            minWidth: 180,
            sortable: false,
        },
        {
            type: 'checkbox',
            field: 'lmpFlag',
            headerName: intl.formatMessage({ id: 'basis_tabs_full_destination' }),
            sortable: false,
        },
        {
            type: 'checkbox',
            field: 'hourlySim',
            headerName: intl.formatMessage({ id: 'basis_tabs_hourly_sim' }),
            sortable: false,
        },
        {
            type: 'checkbox',
            field: 'forwardSim',
            headerName: intl.formatMessage({ id: 'basis_tabs_forward_sim' }),
            sortable: false,
        },
        {
            type: 'checkbox',
            field: 'dailySim',
            headerName: intl.formatMessage({ id: 'basis_tabs_daily_sim' }),
            sortable: false,
        },
    ]);

    const onGridReady = useCallback(
        (params) => {
            const {
                basisModelTypeId,
                basicModelId,
                moduleProcessId,
                modelWeightId,
                transformMethodId,
                hourlySim,
                dailySim,
                forwardSim,
                lmpFlag,
            } = basis;
            gridApi.current = params.api;

            gridApi.current.setRowData([
                {
                    basisModelTypeId,
                    basicModelId,
                    moduleProcessId,
                    modelWeightId,
                    transformMethodId,
                    hourlySim,
                    dailySim,
                    forwardSim,
                    lmpFlag,
                },
            ]);
        },
        [basis]
    );

    const updateBasisData = useCallback(
        async (rowIds) => {
            const rows = rowIds.map((id) => gridApi.current.getRowNode(id));

            const {
                basisModelTypeId,
                basicModelId,
                moduleProcessId,
                modelWeightId,
                transformMethodId,
                hourlySim,
                dailySim,
                forwardSim,
                lmpFlag,
            } = rows[0].data;

            const updatedColumns = columns.map((col) =>
                col.field === 'basicModelId' ? { ...col, editable: basisModelTypeId === 41 } : col
            );

            setColumns(updatedColumns);

            updateBasis({
                ...basis,
                basisModelTypeId,
                basicModelId,
                moduleProcessId,
                modelWeightId,
                transformMethodId,
                hourlySim,
                dailySim,
                forwardSim,
                lmpFlag,
            });
        },
        [basis, columns, updateBasis]
    );

    return (
        <DataGridWrapper>
            <DataGrid
                onGridReady={onGridReady}
                onDataChange={updateBasisData}
                suppressPaginationPanel
                columns={columns}
                issues={issues}
            />
        </DataGridWrapper>
    );
};

export default ModelDefinitionsTab;
