import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';
import MainModal from '../modal/MainModal';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from '../../services/utils';
import { getTablespaceUsage } from '../../services/studies';

const TablespaceDetailModal = ({ schemaGroupId, onClose }) => {
    const [tablespaceDetails, setTablespaceDetails] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getTablespaceUsage(schemaGroupId)
            .then((data) => {
                setTablespaceDetails(data);
                setIsLoading(false);
            })
            .catch((ex) => {
                setIsError(true);
                setIsLoading(false);
            });
    }, [schemaGroupId, setIsLoading]);

    function getDetails() {
        if (!isLoading && (isError || isEmpty(tablespaceDetails)))
            return (
                <code>
                    <FormattedMessage id="common_tablespace_modal_msg_notfound" />
                </code>
            );

        let detail = getTable();

        return detail;
    }

    function getTable() {
        return (
            <>
                {isLoading ? (
                    <FormattedMessage id="common_loading" />
                ) : (
                    <TableContainer>
                        <Table variant="striped" colorScheme="gray">
                            <Thead textAlign={['center']}>
                                <Tr>
                                    <Th>
                                        <FormattedMessage id="common_tablespace_modal_th_tblspacename" />
                                    </Th>
                                    <Th>
                                        <FormattedMessage id="common_tablespace_modal_th_consumed" />
                                    </Th>
                                    <Th>
                                        <FormattedMessage id="common_tablespace_modal_th_allotted" />
                                    </Th>
                                    <Th>
                                        <FormattedMessage id="common_tablespace_modal_th_pctused" />
                                    </Th>
                                    <Th>
                                        <FormattedMessage id="common_tablespace_modal_th_pctusedtotal" />
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablespaceDetails.map((detail, idx) => (
                                    <Tr key={idx}>
                                        <Td>{detail.tableSpaceName}</Td>
                                        <Td>{Number.parseFloat(detail.consumption).toFixed(2)}</Td>
                                        <Td>{detail.allotted}</Td>
                                        <Td>{detail.pctUsedQuota}</Td>
                                        <Td>{detail.pctUsedTotal}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                )}
            </>
        );
    }

    return (
        <MainModal
            size="4xl"
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="common_tablespace_modal_title" />}
            content={<Box>{getDetails()}</Box>}
        />
    );
};

export default TablespaceDetailModal;
