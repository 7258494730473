import React, { useCallback } from 'react';
import { Button, Divider, Flex, Spacer, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import SideDrawer from '../../utils/SideDrawer';
import DateFormikField from '../../forms/DateFormikField';
import { Form, Formik } from 'formik';

const ResourceVariablesFilters = ({ tableName, onApplyFilters, filters, onClose, isOpen }) => {
    const onKeyDown = useCallback((event) => {
        if (event.key === 'Enter') event.preventDefault();
    }, []);

    const onSubmit = useCallback(
        (data) => {
            onClose && onClose();

            onApplyFilters && onApplyFilters(data);
        },
        [onClose, onApplyFilters]
    );

    return isOpen ? (
        <SideDrawer
            isOpen
            header={<FormattedMessage id="autoform_filter_drawer_heading" values={{ tableName }} />}
            content={
                <Formik initialValues={filters} onSubmit={onSubmit}>
                    {({ dirty }) => (
                        <Form onKeyDown={onKeyDown}>
                            <DateFormikField
                                id="minEndDate"
                                name="minEndDate"
                                label={<FormattedMessage id="show_end_dates_later_than" />}
                                isClearable={true}
                            />

                            <Divider mt={6} mb={8} />

                            <Flex flexDirection={'column'} alignItems={'flex-end'}>
                                <Button type="submit" disabled={!dirty}>
                                    <FormattedMessage id="common_apply_filter_btn" />
                                </Button>

                                <Text size="xs" variant="regular" maxW={52} marginTop={3} textAlign="end">
                                    <FormattedMessage id="common_apply_filter_message" values={{ item: tableName }} />
                                </Text>
                            </Flex>

                            <Spacer my={6} />
                        </Form>
                    )}
                </Formik>
            }
            onClose={onClose}
            placement="right"
        />
    ) : null;
};

export default ResourceVariablesFilters;
