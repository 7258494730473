import Input from './Input';

const Textarea = {
    baseStyle: {
        maxHeight: '150px',
    },

    variants: {
        filled: Input.variants.filled.field,
    },

    defaultProps: {
        variant: 'filled',
    },
};

export default Textarea;
