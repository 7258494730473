import { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import useAutoformMetadata from './useAutoformMetadata';
import useCommonToast from '../../../hooks/useCommonToast';

import { saveAutoformRecords } from '../../../store/autoform/autoformApi';
import {
    setBulkUpdateInitialValues,
    setGeneralValidationMessages,
    toggleReloadGridFlag,
} from '../../../store/autoform/autoformSlice';

import {
    getDtoFromDataValueObject,
    getPrimaryKeyColName,
    getTableIdFromInputName,
    formikStatus,
    mapGeneralValidationMessages,
    findKeysWithDifferences,
} from '../utils/autoformUtils';
import AutoformUiContext from '../utils/AutoformUiContext';
import { noop } from 'services/utils';
import useAutoformParams from './useAutoformParams';
import { executeBulkUpdate, getBulkUpdatedTableIds, groupBulkUpdateFieldsByTable } from '../../../services/autoforms';

/**
 * Handles form submission when editing level 1 and 2 tables.
 *
 */
const useAutoformSubmit = ({ setFreezeInitialValues = noop, setInitialFormikStatus = noop }) => {
    const dispatch = useDispatch();
    const { toast } = useCommonToast();
    const intl = useIntl();

    const { tableMap, metadata } = useAutoformMetadata();
    const autoformTablesState = useSelector((state) => state.autoform.tables);

    const { setEditingState } = useContext(AutoformUiContext);
    const parameters = useAutoformParams();

    const updateInitialValues = useCallback(
        (formValues) => {
            dispatch(
                setBulkUpdateInitialValues({
                    values: groupBulkUpdateFieldsByTable(formValues),
                    resetData: true,
                })
            );
        },
        [dispatch]
    );

    const submitBulkUpdate = useCallback(
        async (formValues, formBag) => {
            const {
                isRequestSent,
                error: bulkUpdateError,
                formikStatus,
            } = await executeBulkUpdate({
                formValues,
                status: formBag.status,
                parameters,
                metadata,
                autoformTablesState,
            });

            if (isRequestSent && !bulkUpdateError) {
                updateInitialValues(formValues);

                dispatch(
                    toggleReloadGridFlag({
                        tableIds: getBulkUpdatedTableIds(formValues, autoformTablesState),
                    })
                );
            }

            if (isRequestSent) {
                formBag.setStatus(formikStatus);
            }

            return !bulkUpdateError;
        },
        [dispatch, parameters, metadata, updateInitialValues, autoformTablesState]
    );

    const handleSubmit = useCallback(
        async ({ values, formBag, initialFormValues, onError, onBeforeSuccess, successMessageId }) => {
            let newFormikStatus = {};
            const { setSubmitting, setStatus } = formBag;
            const formValues = Object.entries(values).reduce((acc, [key, value]) => {
                if (key.includes('table')) {
                    acc[key] = value;
                }

                return acc;
            }, {});
            const changedTableIds = findKeysWithDifferences(formValues, initialFormValues);
            setSubmitting(true);
            setFreezeInitialValues(true);

            const recordsByTable = Object.keys(formValues)
                .filter((data) => changedTableIds.includes(data))
                .map((data) => {
                    const tableId = getTableIdFromInputName(data);

                    const primaryKeyRecordName = getPrimaryKeyColName(tableMap[tableId].columns);
                    const tableMetadata = metadata.tables.find((t) => t.id === tableId);

                    return {
                        records: Object.keys(formValues[data]).map((key) =>
                            getDtoFromDataValueObject(formValues[data][key], tableMetadata)
                        ),
                        tableId,
                        primaryKeyRecordName,
                    };
                });

            const results = await Promise.allSettled(
                recordsByTable
                    .filter(({ tableId, records }) => tableMap[tableId]?.canUpdate && records.length)
                    .map(({ tableId, records, primaryKeyRecordName }) => {
                        return dispatch(
                            saveAutoformRecords({
                                tableId,
                                records,
                                primaryKeyRecordName,
                                parameters,
                            })
                        ).unwrap();
                    })
            );

            const errors = results.filter((r) => r.status === 'rejected').map((r) => r.reason);
            const errorsWithIssues = errors.filter((r) => r.error?.issues?.length > 0);
            const successWithIssues = results
                .filter((r) => r.status !== 'rejected')
                .map((r) => r.value)
                .filter((r) => r.error?.issues?.length > 0);
            const resultsWithIssues = [...errorsWithIssues, ...successWithIssues];

            if (resultsWithIssues.length > 0) {
                for (let result of resultsWithIssues) {
                    let issues = result.error.issues;

                    for (let issue of issues) {
                        const { errors: generalErrors, warnings: generalWarnings } = mapGeneralValidationMessages(
                            issue.generalValidationMessages
                        );
                        if (generalErrors.length > 0 || generalWarnings.length > 0) {
                            // TODO: Refactor into one dispatch
                            dispatch(
                                setGeneralValidationMessages({
                                    errors: generalErrors,
                                    warnings: generalWarnings,
                                    tableId: result.tableId,
                                    itemId: issue.id,
                                })
                            );
                        }
                    }
                }
                newFormikStatus = { ...newFormikStatus, ...formikStatus(resultsWithIssues) };
            }

            setStatus(newFormikStatus);
            setInitialFormikStatus(newFormikStatus);

            if (errors.length > 0) {
                onError && (await onError(errors));
            } else {
                try {
                    onBeforeSuccess &&
                        (await onBeforeSuccess({
                            values,
                            formBag,
                            initialFormValues,
                            metadata,
                            parameters,
                        }));

                    const isBulkUpdateSuccess = await submitBulkUpdate(values, formBag);

                    if (isBulkUpdateSuccess) {
                        toast(intl.formatMessage({ id: successMessageId ?? 'autoform_save_changes_success' }));
                    }
                } catch (beforeSuccessError) {
                    //do nothing
                }

                setEditingState({});
                setSubmitting(false);
                setFreezeInitialValues(false);
            }
        },
        [
            dispatch,
            intl,
            metadata,
            setEditingState,
            tableMap,
            parameters,
            toast,
            setFreezeInitialValues,
            submitBulkUpdate,
            setInitialFormikStatus,
        ]
    );

    return useMemo(
        () => ({
            handleSubmit,
            submitBulkUpdate,
        }),
        [handleSubmit, submitBulkUpdate]
    );
};

export default useAutoformSubmit;
