import { Box } from '@chakra-ui/react';

import DynamicPropertiesSkeleton from '../utils/DynamicPropertiesSkeleton';
import DynamicPropertyTabs from '../utils/DynamicPropertyTabs';
import Error from '../utils/Error';

function PortfolioTabs({ isLoading, loadingError, properties }) {
    if (isLoading) {
        return <DynamicPropertiesSkeleton />;
    }

    if (loadingError) {
        return (
            <Box mt={6}>
                <Error
                    primaryId="common_error"
                    secondaryId="common_loading_error"
                    additionalText="portfolio properties"
                />
            </Box>
        );
    }

    return <DynamicPropertyTabs mt={6} groups={properties} />;
}

export default PortfolioTabs;
