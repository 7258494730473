import { useRef, useState } from 'react';
import { Flex, Button } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as UploadIcon } from '../../icons/upload.svg';
import { FormattedMessage } from 'react-intl';

const DragAndDropFile = ({ handleFileChange }) => {
    const [dragActive, setDragActive] = useState(false);

    const inputRef = useRef(null);

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (Array.isArray(e?.dataTransfer?.files) && e.dataTransfer.files?.[0]) {
            handleFileChange(e.dataTransfer.files[0]);
        }
    };

    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFileChange(e.target.files[0]);
        }
    };

    const onButtonClick = () => {
        inputRef.current.click();
    };

    return (
        <DropContainer onDragOver={handleDrag} onDragLeave={handleDrag} onDragEnter={handleDrag} onDrop={handleDrop}>
            <UploadIconCircledWrapper>
                <StyledUploadIcon className={dragActive && 'activeDrag'} />
            </UploadIconCircledWrapper>
            {dragActive ? (
                <FormattedMessage id="upload_modal_header" />
            ) : (
                <Flex gap={2}>
                    <FormattedMessage id="upload_modal_upload_drag_and_drop" />

                    <Button variant="link" p={0} h={6} onClick={onButtonClick}>
                        <FormattedMessage id="upload_modal_upload_browse" />
                    </Button>

                    <FormattedMessage id="upload_modal_upload_your_files" />
                </Flex>
            )}
            <input
                style={{ display: 'none' }}
                ref={inputRef}
                type="file"
                id="input-file-upload"
                multiple={true}
                onChange={handleChange}
            />
        </DropContainer>
    );
};

const StyledUploadIcon = styled(UploadIcon)`
    fill: #677380;
`;
const UploadIconCircledWrapper = styled(Flex)`
    background: var(--chakra-colors-gray-100);
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;

    .activeDrag path {
        fill: var(--chakra-colors-blue-400) !important;
    }
`;

const DropContainer = styled(Flex)`
    min-height: 200px;
    border: 1px dashed var(--chakra-colors-border-secondary) !important;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--chakra-colors-background-octenary);
    margin-bottom: 24px;
`;

export default DragAndDropFile;
