import { useEffect } from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';

const SpotPriceLookupValuesField = ({ name, label, ...rest }) => {
    const {
        values: { isoRto, electricNode, dartm },
        setFieldValue,
    } = useFormikContext();

    const [field] = useField({ name });

    useEffect(() => {
        if (isoRto && electricNode && dartm) {
            setFieldValue(name, `iso=${isoRto},node=${electricNode},product=${dartm}`, true);
        }
    }, [isoRto, electricNode, dartm, setFieldValue, name]);

    return (
        <FormControl {...rest}>
            <FormLabel>{label}</FormLabel>
            <Input name={name} {...field} />
        </FormControl>
    );
};

export default SpotPriceLookupValuesField;
