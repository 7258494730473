import { createSlice } from '@reduxjs/toolkit';
import { getDateIntervals, getLinkedObjects } from './adjustmentApi';

const initialState = {
    dateIntervals: [],
    linkedObjects: [],
};

const adjustmentSlice = createSlice({
    name: 'adjustment',
    initialState,
    reducers: {},
    extraReducers: {
        [getDateIntervals.fulfilled]: (state, action) => {
            state.dateIntervals = action.payload;
        },
        [getLinkedObjects.fulfilled]: (state, action) => {
            state.linkedObjects = action.payload;
        },
    },
});

export default adjustmentSlice.reducer;
