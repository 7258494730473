import { Field } from 'formik';
import _ from 'lodash';

import FormField from './FormField';
import Datepicker from './Datepicker';
import { useCallback } from 'react';

// If you need to extend Datepicker functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for Datepicker should be excluded from ...props and manually added
const DateFormikField = ({
    id,
    name,
    label,
    isDisabled,
    value,
    onChange,
    onCalendarClose,
    maxDate,
    minDate,
    utc,
    dateFormat,
    showTimeInput,
    placeholderText,
    isClearable,
    showMonthYearPicker,
    autoComplete,
    isInPortal,
    showPlaceholderIfDisabled,
    ...props
}) => {
    const getFieldValue = useCallback(
        (field) => {
            let value = '';

            if (!showPlaceholderIfDisabled || !isDisabled || !placeholderText) {
                value = field.value;
            }

            return value;
        },
        [showPlaceholderIfDisabled, isDisabled, placeholderText]
    );

    return (
        <Field name={name}>
            {({ field, meta, form }) => {
                const status = _.get(form.status, field.name);
                const isInvalid = meta.touched && (meta.error || status?.errors?.length > 0);
                const isWarning = meta.touched && status?.warnings?.length > 0;

                return (
                    <FormField
                        id={id}
                        name={name}
                        label={label}
                        isDisabled={isDisabled}
                        isInvalid={isInvalid}
                        error={meta.error}
                        status={status}
                        {...props}
                    >
                        <Datepicker
                            disabled={isDisabled}
                            showTimeInput={showTimeInput}
                            {...field} // {...field} also contains onChange, onCalendarClose, which we overwrite
                            onChange={(date) => {
                                const value = date || '';

                                form.setFieldValue(name, value);
                                form.setStatus(_.omit(form.status, field.name));

                                // additional custom action could be hooked up with onChange callback
                                if (typeof onChange === 'function') {
                                    onChange(value);
                                }
                            }}
                            onCalendarClose={() => {
                                form.setFieldTouched(name, true, false);

                                // additional custom action could be hooked up with onCalendarClose callback
                                if (typeof onCalendarClose === 'function') {
                                    onCalendarClose();
                                }
                            }}
                            value={getFieldValue(field)}
                            dateFormat={dateFormat}
                            placeholderText={placeholderText}
                            isClearable={isClearable}
                            maxDate={maxDate}
                            minDate={minDate}
                            isWarning={isWarning}
                            utc={utc}
                            showMonthYearPicker={showMonthYearPicker}
                            autoComplete={autoComplete}
                            portalId={isInPortal && 'datepicker-portal'}
                        />
                    </FormField>
                );
            }}
        </Field>
    );
};

export default DateFormikField;
