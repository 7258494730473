import axios from 'axios';

export const GRID_KEY = 'variables';

export const addVariableRows = async (payload) => {
    const response = await axios.post(`/variables/create`, payload, { suppressErrorMessageHandler: true });
    return response.data;
};

export const getVariables = async (payload) => {
    const response = await axios.post(`/variables/filtered`, payload);
    return response.data;
};

export const modifyVariables = (payload) =>
    axios.put(`/variables/update`, payload, { suppressErrorMessageHandler: true });

export const deleteVariableRows = (rowIds) => axios.delete(`/variables/delete`, { data: rowIds });

export const getValidVariableRows = (rows) => {
    return rows.map((row) => ({ ...row.data })).filter((row) => row !== null);
};

export const multisortPairs = {
    bookId: 'bookDescription',
    statUnitId: 'statUnitDescription',
};

export const getVariableSources = async () => {
    const response = await axios.get(`/variables/sources`);
    return response.data;
};

export const getDropdownOptions = async () => {
    const response = await axios.get(`/variables/dropdown-options`);
    return response.data;
};
