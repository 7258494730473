import { useState, useEffect, useCallback } from 'react';
import { Button, Tabs, TabList, TabPanels, Tab, TabPanel, Box, Flex } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import useCommonToast from '../../hooks/useCommonToast';

import { getStudyValidationProgress, getStudyData } from '../../services/studies';
import { restoreStudy, downloadStudyFlatFiles } from '../../services/restorationInputExtract';

import MainModal from '../modal/MainModal';

import SimpleGridSkeleton from '../../components/utils/SimpleGridSkeleton';
import Error from '../../components/utils/Error';

import StudyValidationsTable from './StudyValidationsTable';
import StudyInfoGrid from './StudyInfoGrid';

const StudyInfoModal = ({ studyId, studyName, onClose }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const canUseRestoration = useSelector((state) => state.helpers.canUseRestoration);
    const canUseInputDataExtract = useSelector((state) => state.helpers.canUseInputDataExtract);

    const [studyData, setStudyData] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [validationProgress, setValidationProgress] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const fetchStudyData = useCallback(() => {
        return getStudyData(studyId)
            .then((data) => setStudyData((prev) => ({ ...prev, data })))
            .catch(() => setStudyData((prev) => ({ ...prev, error: true })))
            .finally(() => setStudyData((prev) => ({ ...prev, loading: false })));
    }, [studyId]);

    const fetchStudyValidationProgress = useCallback(() => {
        return getStudyValidationProgress(studyId)
            .then((data) => setValidationProgress((prev) => ({ ...prev, data })))
            .catch(() => setValidationProgress((prev) => ({ ...prev, error: true })))
            .finally(() => setValidationProgress((prev) => ({ ...prev, loading: false })));
    }, [studyId]);

    useEffect(() => {
        fetchStudyValidationProgress();
        fetchStudyData();
    }, [fetchStudyValidationProgress, fetchStudyData]);

    const onRestoreStudy = useCallback(async () => {
        try {
            restoreStudy(studyId);

            toast(intl.formatMessage({ id: 'study_info_restore_study_success_message' }));
            onClose();
        } catch (error) {
            if (error.response.status !== 401) {
                toast({
                    status: 'error',
                    message: error.response.data.error,
                });
            }
        }
    }, [intl, toast, onClose, studyId]);

    const onExtractStudy = useCallback(async () => {
        try {
            downloadStudyFlatFiles(studyId);

            toast(intl.formatMessage({ id: 'study_info_extract_study_success_message' }));
            onClose();
        } catch (error) {
            if (error.response.status !== 401) {
                toast({
                    status: 'error',
                    message: error.response.data.error,
                });
            }
        }
    }, [intl, toast, onClose, studyId]);

    return (
        <MainModal
            isOpen
            onClose={onClose}
            size="6xl"
            header={
                <>
                    <FormattedMessage id="study_info_info_for" /> {studyName}
                </>
            }
            content={
                <Tabs>
                    <TabList>
                        <Tab>
                            <FormattedMessage id="study_info_summary" />
                        </Tab>
                        <Tab>
                            <FormattedMessage id="study_info_validation_output" />
                        </Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            {studyData.loading ? (
                                <SimpleGridSkeleton rows={9} cols={1} />
                            ) : studyData.error ? (
                                <Box m={6}>
                                    <Error
                                        primaryId="common_error"
                                        secondaryId="common_loading_error"
                                        additionalText="summary data"
                                    />
                                </Box>
                            ) : (
                                <StudyInfoGrid studyData={studyData.data} />
                            )}
                        </TabPanel>
                        <TabPanel>
                            {validationProgress.loading || studyData.loading ? (
                                <SimpleGridSkeleton rows={9} cols={3} />
                            ) : validationProgress.error || studyData.error ? (
                                <Box m={6}>
                                    <Error
                                        primaryId="common_error"
                                        secondaryId="common_loading_error"
                                        additionalText="validation progress"
                                    />
                                </Box>
                            ) : (
                                <StudyValidationsTable
                                    skipValidation={studyData.data.study.skipValidation}
                                    validationProgress={validationProgress.data}
                                />
                            )}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            }
            secondaryButton={
                <Flex gap="10px">
                    {canUseRestoration && (
                        <>
                            <Button variant="secondary" onClick={onRestoreStudy}>
                                <Box as="span">
                                    <FormattedMessage id="study_info_restore_study_button" />
                                </Box>
                            </Button>
                        </>
                    )}

                    {canUseInputDataExtract && (
                        <>
                            <Button variant="secondary" onClick={onExtractStudy}>
                                <Box as="span" textTransform="capitalize">
                                    <FormattedMessage id="study_info_extract_study_button" />
                                </Box>
                            </Button>
                        </>
                    )}

                    <Button variant="secondary" onClick={onClose}>
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_close" />
                        </Box>
                    </Button>
                </Flex>
            }
        />
    );
};

export default StudyInfoModal;
