import {
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

const SimpleModal = ({
    title,
    headerContent,
    withHeaderDivider = false,
    withFooterDivider = false,
    children,
    footerContent,
    ...props
}) => {
    return (
        <Modal {...props}>
            <ModalOverlay />

            <ModalContent borderRadius="2xl">
                <StyledModalHeader withDivider={withHeaderDivider}>
                    {headerContent ?? (
                        <Heading as="h3" variant="h3" mr={12}>
                            {title}
                        </Heading>
                    )}

                    <StyledModalCloseButton />
                </StyledModalHeader>

                <ModalBody px={6} pb={6} pt={0}>
                    {children}
                </ModalBody>

                {footerContent ? (
                    <StyledModalFooter withDivider={withFooterDivider}>{footerContent}</StyledModalFooter>
                ) : null}
            </ModalContent>
        </Modal>
    );
};

const StyledModalHeader = styled(ModalHeader, { shouldForwardProp: (prop) => prop !== 'withDivider' })`
    position: relative;
    padding: var(--chakra-space-6) var(--chakra-space-6) var(--chakra-space-4);

    ${(props) =>
        props.withDivider
            ? `
            margin-bottom: var(--chakra-space-6);
            border-bottom: 1px solid var(--chakra-colors-border-secondary);
        `
            : ''};
`;

const StyledModalFooter = styled(ModalFooter, { shouldForwardProp: (prop) => prop !== 'withDivider' })`
    ${(props) =>
        props.withDivider
            ? `
                border-top: 1px solid var(--chakra-colors-border-secondary);
            `
            : ''};
`;

const StyledModalCloseButton = styled(ModalCloseButton)`
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
`;

export default SimpleModal;
