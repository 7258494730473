import { useState, useMemo, useRef, useCallback } from 'react';
import { Flex, TabList, Tab, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';

import { getStatements, getJobLogs } from '../../services/studies';
import { formatDataGridDate } from '../../services/items';

import DataGridWrapper from '../grid/DataGridWrapper';
import DataGrid from '../grid/DataGrid';
import MainModal from '../modal/MainModal';
import SortOrderProvider from '../grid/utils/SortOrderContext';

import { ReactComponent as ErrorIcon } from '../../icons/warning.svg';

const JobLogsModal = ({ jobId, onClose }) => {
    const intl = useIntl();

    const filesGridApi = useRef();
    const statementGridApi = useRef();

    const [errorsFound, setErrorsFound] = useState(false);

    const onFilesGridReady = useCallback(
        async (params) => {
            filesGridApi.current = params.api;
            try {
                const response = await getJobLogs(jobId);
                filesGridApi.current.setRowData(response);
            } catch {
                filesGridApi.current.showNoRowsOverlay();
            }
        },
        [jobId]
    );

    const onStatementsGridReady = useCallback(
        async (params) => {
            statementGridApi.current = params.api;
            try {
                const response = await getStatements(jobId);

                const errorsFound = response.findIndex(
                    (e) =>
                        e.messageType.toLowerCase().includes('error') || e.messageType.toLowerCase().includes('warning')
                );

                setErrorsFound(errorsFound !== -1);

                statementGridApi.current.setRowData(response);
            } catch {
                statementGridApi.current.showNoRowsOverlay();
            }
        },
        [jobId]
    );

    const filesColumns = useMemo(
        () => [
            {
                field: 'logName',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_logs_report_name' }),
                minWidth: 330,
            },
            {
                field: 'createdDate',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_logs_created_on' }),
                minWidth: 180,
                valueFormatter: formatDataGridDate,
            },
            {
                field: '',
                headerName: intl.formatMessage({ id: 'jobs_table_logs' }),
                sortable: false,
                cellRenderer: 'DownloadButtonCell',
                editable: false,
                cellRendererParams: { jobId },
            },
        ],
        [intl, jobId]
    );

    const statementColumns = useMemo(
        () => [
            {
                field: 'processByUser',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_logs_processed_by' }),
            },
            {
                field: 'logDate',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_logs_log_date' }),
                valueFormatter: formatDataGridDate,
            },
            {
                field: 'messageDate',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_logs_message_date' }),
                valueFormatter: formatDataGridDate,
            },
            {
                field: 'messageType',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_logs_message_type' }),
                cellRenderer: 'StatementMessageTypeCell',
                minWidth: 180,
            },
            {
                field: 'message',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_logs_message' }),
            },
        ],
        [intl]
    );

    return (
        <MainModal
            isOpen
            onClose={onClose}
            size="6xl"
            header={<FormattedMessage id="job_logs_header" values={{ id: jobId }} />}
            content={
                <Tabs>
                    <TabList>
                        <Tab>
                            <Flex gap={2} align="center">
                                <FormattedMessage id="job_logs_statements" />
                                {errorsFound && <ErrorIcon />}
                            </Flex>
                        </Tab>
                        <Tab>
                            <FormattedMessage id="job_logs_files" />
                        </Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <SortOrderProvider>
                                <JobsDataGridWrapper>
                                    <DataGrid
                                        onGridReady={onStatementsGridReady}
                                        disableReadOnlyStyles={true}
                                        columns={statementColumns}
                                        pagination={false}
                                    />
                                </JobsDataGridWrapper>
                            </SortOrderProvider>
                        </TabPanel>

                        <TabPanel>
                            <SortOrderProvider>
                                <JobsDataGridWrapper>
                                    <DataGrid
                                        onGridReady={onFilesGridReady}
                                        disableReadOnlyStyles={true}
                                        columns={filesColumns}
                                        pagination={false}
                                    />
                                </JobsDataGridWrapper>
                            </SortOrderProvider>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            }
        />
    );
};

const JobsDataGridWrapper = styled(DataGridWrapper)`
    flex: 1 1 300px;
    margin-top: 24px;
`;

export default JobLogsModal;
