import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, TabPanels, TabPanel, Tabs, TabList, Tab, useDisclosure } from '@chakra-ui/react';
import { Formik, Form } from 'formik';

import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import Error from '../utils/Error';

import { setSelectedItemForTable } from '../../store/autoform/autoformSlice';

import useAutoformMetadata from '../autoform/hooks/useAutoformMetadata';
import useAutoformTableMetadata from '../autoform/hooks/useAutoformTableMetadata';

import ItemTopHeader from '../autoform/ItemTopHeader';
import AutoformItemFields from '../autoform/AutoformItemFields';
import useAutoformTableData from '../autoform/hooks/useAutoformTableData';
import { getInputFormName } from '../autoform/utils/autoformUtils';

import TabListWrapper from '../utils/TabListWrapper';
import { updateUserBooks, updateUserRoles } from '../../services/userManagement';
import useUserBooks from '../../hooks/userManagement/useUserBooks';
import useUserRoles from 'hooks/userManagement/useUserRoles';
import useUsersInitialValues from '../../hooks/userManagement/useUsersInitialValues';
import useCommonToast from '../../hooks/useCommonToast';
import CheckboxesGrid from './CheckboxesGrid';
import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import { isEqual } from 'lodash';
import DetailsButton from './DetailsButton';
import UserGroupDetailsModal from './UserGroupDetailsModal';
import { ReactComponent as UserIcon } from '../../icons/user.svg';
import { onHasUnsavedChanges } from 'store/helpers/helpersSlice';

import PromptWrapper from 'components/utils/PromptWrapper';
import AutomaticFormikDirtyCheck from 'components/forms/AutomaticFormikDirtyCheck';

const Users = () => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const userRolesDetailsModal = useDisclosure();
    const [currentModalItemId, setCurrentModalItemId] = useState(null);
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const level1TableId = hierarchicalTables.level1[0]?.id;
    const [isFormDirty, setIsFormDirty] = useState(false);

    const dispatch = useDispatch();
    const { search } = useLocation();

    const selectedItemId = useMemo(() => {
        const query = new URLSearchParams(search);

        return query.get('user');
    }, [search]);

    const { descriptionColumnName, selectedItem } = useAutoformTableMetadata(level1TableId);
    const { selectedItem: selectedItemData } = useAutoformTableData(level1TableId);

    const { userBooks, isLoading: isUserBooksLoading, isError: isUserBooksError } = useUserBooks(selectedItem);
    const { userRoles, isLoading: isUserRolesLoading, isError: isUserRolesError } = useUserRoles(selectedItem);

    useEffect(() => {
        if (selectedItemId && level1TableId) {
            dispatch(setSelectedItemForTable({ itemId: selectedItemId, tableId: level1TableId }));
        }
    }, [dispatch, metadata, selectedItemId, level1TableId]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const { initialValues, setInitialValues } = useUsersInitialValues(
        level1TableId,
        selectedItem,
        selectedItemData,
        descriptionColumnName,
        userBooks,
        userRoles
    );

    if (isUserBooksError || isUserRolesError)
        return (
            <Box m={6}>
                <Error primaryId="common_error" secondaryId="common_loading_error" />
            </Box>
        );

    const onDetailsModalOpen = (itemId) => {
        setCurrentModalItemId(itemId);
        userRolesDetailsModal.onOpen();
    };

    const onDetailsModalClose = () => {
        userRolesDetailsModal.onClose();
        setCurrentModalItemId(null);
    };

    if (isUserBooksLoading || isUserRolesLoading) return <DefinitionsSkeleton />;
    if (!metadata || selectedItem !== selectedItemId) return <DefinitionsSkeleton />;

    const onFormSubmit = async (values) => {
        const successMessage = intl.formatMessage({ id: 'user_management_user_saved' });

        const selectedUserBooks = Object.entries(values.userBooks)
            .filter(([key, value]) => !!value)
            .map(([key, value]) => Number(key));
        const selectedUserRoles = Object.entries(values.userRoles)
            .filter(([key, value]) => !!value)
            .map(([key, value]) => Number(key));

        const areBooksEqual = isEqual(values.userBooks, initialValues.userBooks);
        const areRolesEqual = isEqual(values.userRoles, initialValues.userRoles);

        const updatedValues = [];

        if (!areBooksEqual) {
            updatedValues.push(updateUserBooks(selectedItem, selectedUserBooks));
        }

        if (!areRolesEqual) {
            updatedValues.push(updateUserRoles(selectedItem, selectedUserRoles));
        }

        await Promise.all(updatedValues);
        setInitialValues(values);

        toast(successMessage);
    };

    return (
        <>
            <ExpandableItemWrapper>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={onFormSubmit}>
                    {({ isSubmitting, isValid, dirty }) => (
                        <Form>
                            <PromptWrapper />
                            <AutomaticFormikDirtyCheck isFormDirty={isFormDirty} onFormDirtyChange={setIsFormDirty} />
                            <ItemTopHeader
                                inputName={`${getInputFormName(level1TableId, selectedItem, descriptionColumnName)}`}
                                isDeleteVisible={false}
                                isSaveVisible={true}
                                itemDescription={selectedItemData && selectedItemData[descriptionColumnName]}
                                isSaveDisabled={!dirty}
                                isEditVisible={false}
                                isPropertiesVisible={false}
                                isSubmitting={isSubmitting}
                                isEditMode={false}
                                config={{ autoformIcon: <UserIcon /> }}
                            />
                            <AutoformItemFields tableId={level1TableId} getInputFormName={getInputFormName} />

                            <Tabs px={0} isLazy isManual lazyBehavior="keepMounted">
                                <TabList px={6} w="100%" flexWrap={{ base: 'wrap', xl: 'nowrap' }} alignItems="center">
                                    <TabListWrapper showButtons={true}>
                                        <Tab>
                                            <FormattedMessage id="user_management_books_tab" />
                                        </Tab>
                                        <Tab>
                                            <FormattedMessage id="user_management_user_groups_tab" />
                                        </Tab>
                                    </TabListWrapper>
                                </TabList>
                                <TabPanels>
                                    <TabPanel pt={4}>
                                        <CheckboxesGrid
                                            data={userBooks}
                                            fieldNamePrefix="userBooks"
                                            header={<FormattedMessage id="user_management_books" />}
                                        />
                                    </TabPanel>
                                    <TabPanel pt={4}>
                                        <CheckboxesGrid
                                            data={userRoles}
                                            fieldNamePrefix="userRoles"
                                            header={<FormattedMessage id="user_management_groups_title" />}
                                            renderDetailsButton={(currentItemId) => (
                                                <DetailsButton
                                                    onDetailsButtonClick={() => onDetailsModalOpen(currentItemId)}
                                                />
                                            )}
                                        />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Form>
                    )}
                </Formik>
            </ExpandableItemWrapper>

            {userRolesDetailsModal.isOpen && (
                <UserGroupDetailsModal
                    currentItemId={currentModalItemId}
                    onOpen={onDetailsModalOpen}
                    onClose={onDetailsModalClose}
                />
            )}
        </>
    );
};

export default Users;
