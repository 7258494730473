import axios from 'axios';

export const getActions = async (admin = false) => {
    let response;
    if (admin) {
        response = await axios.get('/admin-express-jobs/actions');
    } else {
        response = await axios.get('/express-jobs/actions');
    }
    return response.data;
};

export const getProperties = async (moduleId, jobId = null, admin = false) => {
    let response;
    if (admin) {
        response = await axios.get(
            `/admin-express-jobs/${moduleId}/dynamic-properties${jobId ? '?jobId=' + jobId : ''}`
        );
    } else {
        response = await axios.get(`/express-jobs/${moduleId}/dynamic-properties${jobId ? '?jobId=' + jobId : ''}`);
    }
    return response.data;
};

export const createExpressJob = (payload, admin = false) => {
    if (admin) {
        return axios.post('/admin-express-jobs/create', payload);
    }
    return axios.post('/express-jobs/create', payload);
};

export const getJobs = async (payload, admin = false) => {
    let response;
    if (admin) {
        response = await axios.post('/admin-express-jobs/jobs', payload);
    } else {
        response = await axios.post('/express-jobs/jobs', payload);
    }
    return response.data;
};

export const getDropdownOptions = async (admin = false) => {
    let response;
    if (admin) {
        response = await axios.get('/admin-express-jobs/dropdown-options');
    } else {
        response = await axios.get('/express-jobs/dropdown-options');
    }
    return response.data;
};

export const getStatuses = async (admin = false) => {
    let response;
    if (admin) {
        response = await axios.get('/admin-express-jobs/statuses');
    } else {
        response = await axios.get('/express-jobs/statuses');
    }
    return response.data;
};

export const editJobStatuses = (payload, admin = false) => {
    if (admin) {
        return axios.put(`/admin-express-jobs/status`, payload);
    }
    return axios.put(`/express-jobs/status`, payload);
};

export const multisortPairs = {
    runTimeString: 'runTimeMinsSort',
};
