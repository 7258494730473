import { Heading, Text, Flex } from '@chakra-ui/react';
import AddDataButton from 'components/utils/AddDataButton';
import { FormattedMessage } from 'react-intl';

const DataGridNoRowsOverlay = ({ onAdd, canCreate }) => {
    return canCreate ? (
        <Flex direction="column" pointerEvents="auto">
            <Heading variant="h3" color="gray.600" textTransform="capitalize">
                <FormattedMessage id="common_no_results" />
            </Heading>
            <AddDataButton messageId="common_add_rows_heading" onAdd={onAdd} />
        </Flex>
    ) : (
        <Text size="sm" variant="regular">
            <FormattedMessage id="common_no_data_found" />
        </Text>
    );
};

export default DataGridNoRowsOverlay;
