import axios from 'axios';
import groupBy from 'lodash/groupBy';
import { compareAsc, compareDesc, differenceInCalendarMonths, isAfter, parseISO } from 'date-fns';
import { nanoid } from 'nanoid';

import { createExportFilename } from '../items';
import { msalInstance } from '../auth';
import { getItem } from '../storage';

// common batteries
export const GRID_KEYS = {
    SCHEDULED_OUTAGES: 'batteryScheduledOutages',
    HISTORICAL_OUTPUT: 'batteryHistoricalOutput',
};

export const getBatteryDefinitions = async (batteryId) => {
    const response = await axios.get(`/batteries/${batteryId}/sub-batteries`);
    return response.data;
};

export const editBatteryAndSubbatteries = async (payload) => {
    const response = await axios.put(`/batteries/modify-battery-sub-batteries`, payload);

    //Note: update battery end point returns 204 no content or 200 Ok validation issues if there were any, however we do not use data in FE...
    if (response?.status === axios.HttpStatusCode.MultiStatus) {
        // Status 207 MultiStatus
        const multiStatus = response?.data?.multiStatus;
        if (Array.isArray(multiStatus)) {
            // No need to check for axios.HttpStatusCode.NoContent
            // Status 200 OK
            const data = multiStatus.find((s) => s.statusCode === axios.HttpStatusCode.Ok)?.value;
            // Status 424 FailedDependency
            const arcGisIssue = multiStatus.find(
                (s) => s.statusCode === axios.HttpStatusCode.FailedDependency && s?.value?.isArcGisError
            )?.value?.error;
            return { data, arcGisIssue };
        }
    }

    return response;
};

export const deleteSubBattery = (subBatteryId) => axios.delete(`/batteries/${subBatteryId}`);

export const createSubBattery = (payload) => axios.post(`/batteries/sub-battery`, payload);

export const validateSubBattery = async (payload) => {
    const response = await axios.post(`/batteries/validate/sub-battery`, payload);
    return response.data;
};

export const createSubBatteryFromWizard = (payload) => axios.post(`/batteries/create-setup`, payload);

export const validateSubBatteryFromWizard = async (payload) => {
    const response = await axios.post(`/batteries/validate/create-setup`, payload);
    return response.data;
};

export const getFiltersConfig = (item) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length) {
        const accountId = accounts[0].localAccountId;
        const filters = getItem(`${item}Filters`);

        if (filters && filters[accountId]) {
            return filters[accountId];
        }
    }

    return null;
};

export const getBatteryFilters = (namespacePrefix) => {
    const userPreferences = getFiltersConfig(namespacePrefix);

    if (userPreferences) {
        return getFiltersConfig(namespacePrefix);
    }

    return [];
};

// timeline
export const getTimelineRibbonData = (subBatteryId, selectedParameters) => {
    //currently defaulting to 80yrs as we do not have date pickers in the UI but BE expects values to limit the subset
    const payload = {
        startDateFrom: '2010-01-01T00:00:00.000Z',
        startDateTo: '2090-12-12T00:00:00.000Z',
        parameters: selectedParameters,
    };

    return axios.post(`batteries/${subBatteryId}/time-ribbon`, payload);
};

export const getBatteryRibbonParameters = async (subBatteryId) => {
    const response = await axios.get(`/batteries/${subBatteryId}/time-ribbon-filter`);

    return {
        parameters: mapBatteryFilterParameters(response.data.batteryFilterParameters),
    };
};

const mapBatteryFilterParameters = (parameters) =>
    parameters.map((parameter) => {
        return {
            value: parameter,
            label: parameter.name,
            disabled: parameter.isRequired,
            hasData: parameter.hasData,
            dbFieldName: parameter.dbFieldName,
        };
    });

// function used to create both main and sub parameter periods;
// endpoint is identical but the payload is loaded with different properties
export const createParameterPeriod = (subBatteryId, payload) =>
    axios.post(`/batteries/${subBatteryId}/create-sub-battery-period`, payload);

export const deleteSubParameter = (subBatteryId, payload) =>
    axios.delete(`/batteries/${subBatteryId}/delete-sub-battery-parameter`, { data: payload });

// function used to update period values used for both main/sub parameters
// endpoint is identical but the payload is loaded with periodIds when updating main parameters / subPeriodIds when updating the sub parameters
export const updateParameterPeriodValue = (subBatteryId, payload) =>
    axios.put(`/batteries/${subBatteryId}/update-sub-battery-parameter`, payload);

export const checkParameterPeriodOverlap = async (subBatteryId, payload) => {
    const result = await axios.post(`/batteries/${subBatteryId}/create-sub-battery-period-validation`, payload);
    return result.data;
};

export const formatTimelineData = (filterParameters, data) => {
    const result = data.map((item) => {
        const { name, periods, subParameters } = item;

        const filterParameter = filterParameters.find((p) => p.dbFieldName === name);

        const flattedPeriods = periods && periods.length > 0 ? flatPeriodData(periods) : [];
        const formattedSubParameters =
            subParameters && subParameters.length > 0
                ? subParameters.map((item) => {
                      const flattedSubPeriods = flatPeriodData(item.periods);
                      return { ...item, periods: flattedSubPeriods };
                  })
                : [];

        return {
            parameterLabel: filterParameter.label,
            parameterDbField: filterParameter.dbFieldName,
            isParameterRequired: filterParameter.value.isRequired,
            periods: flattedPeriods,
            subParameters: formattedSubParameters,
        };
    });

    return result;
};

// function to validate and conjoin the incoming period data
const flatPeriodData = (periods) => {
    // 0) filter out the invalid periods
    const validatedParameterPeriods = periods
        .map((item) => {
            const valid = validatePeriod(item);
            return { ...item, isValidPeriod: valid };
        })
        .filter((item) => item.isValidPeriod === true);

    // 1) group periods data by their value
    // key = identival value, value = array of the matching periods
    const groupedValues = groupBy(validatedParameterPeriods, 'value');

    // 2) get which periods by value are consecutive, then for each consecutive group concat the value into a single object
    const combinedItems = [];
    Object.entries(groupedValues).forEach(([key, value]) => {
        if (value.length > 1) {
            const sequences = getDateSequences(value);
            const keyVal = key === 'null' ? 'null' : parseFloat(key);
            const filtered = sequences
                .filter((item) => item.length > 1)
                .map((sequence) => concatPeriodValues(sequence, keyVal));
            combinedItems.push(filtered);
        }
    });

    const filteredCombinedItems = combinedItems.filter((item) => item.length > 0);

    // 3) replace the combined items with their corresponding items in the original periods array
    // (for each item we check if period ids exist, remove the original item for that period id and then replace it with the item)
    filteredCombinedItems.forEach((item) => {
        item.forEach((el) => {
            if (el.periodIds && el.periodIds.length > 0) {
                el.periodIds.forEach((id) => {
                    let elementToRemove = validatedParameterPeriods.find((p) => p.periodId === id);
                    let indexToSplice = validatedParameterPeriods.indexOf(elementToRemove);

                    validatedParameterPeriods.splice(indexToSplice, 1);
                });

                validatedParameterPeriods.push(el);
            }
        });
    });

    // 4) replace null values for non-consecutive items
    const result = validatedParameterPeriods.map((item) => (item.value === null ? { ...item, value: 'null' } : item));

    return result;
};

// function to get periods with identical values as time sequences
const getDateSequences = (data) => {
    let lastChecked = null;
    let currIndex = 0;
    let results = [[]];

    for (let index = 0; index < data.length; index++) {
        const element = data[index];

        const monthDiff = differenceInCalendarMonths(parseISO(lastChecked?.startDate), parseISO(element.startDate));

        if (monthDiff === -1) {
            results[currIndex].push(element);
        } else {
            if (results[currIndex] && results[currIndex].length === 1) {
                results[currIndex][0] = element;
            } else {
                currIndex++;
                if (!results[currIndex]) results.push([]);
                results[currIndex].push(element);
            }
        }

        lastChecked = element;
    }

    return results;
};

// function to concat identical values from different but sequential periods
const concatPeriodValues = (values, key) => {
    // 1) add the period id to the periodIds object
    const periodIds = values.map((val) => val.periodId);
    const subPeriodIds = values.map((val) => val.subPeriodId);

    // 2) get the earliest and latest date
    const startDates = values.map((item) => parseISO(item.startDate));
    const endDates = values.map((item) => parseISO(item.endDate));

    const [earliest] = startDates.sort(compareAsc);
    const [latest] = endDates.sort(compareDesc);

    return {
        periodIds,
        subPeriodIds,
        startDate: earliest.toISOString(),
        endDate: latest.toISOString(),
        value: key,
        isValidPeriod: true,
        relatedPeriods: values,
    };
};

// function to validate period by its' start and end date
const validatePeriod = (item) => {
    const startDate = parseISO(item.startDate);
    const endDate = parseISO(item.endDate);

    if (isAfter(startDate, endDate)) {
        return false;
    }

    const monthDiff = differenceInCalendarMonths(startDate, endDate);
    if (monthDiff !== -1) {
        return false;
    }

    const isValidStartDate = checkIfDateMatchesFormat(item.startDate);
    const isValidEndDate = checkIfDateMatchesFormat(item.endDate);

    return isValidStartDate && isValidEndDate;
};

// function to check if given date value matches the validity criteria
const checkIfDateMatchesFormat = (date) => {
    const value = new Date(date);
    const day = value.getUTCDate();
    const hours = value.getUTCHours();
    const minutes = value.getUTCMinutes();
    const seconds = value.getUTCSeconds();

    return day === 1 && hours === 0 && minutes === 0 && seconds === 0;
};

// function to get the battery label switch setting
export const getBatteryLabelSwitch = (value) => (value === '0-100%' ? '%' : 'MWh');

// function to map parameters data for ribbon
export const mapPeriodsData = (data) => {
    return data.map((item, i) => {
        const transformedSubParameters = item.subParameters
            ? item.subParameters.map((sp, i) => {
                  return {
                      ...sp,
                      id: `sub-track-${i}`,
                      periods: sp.periods.map((p) => {
                          return {
                              ...p,
                              peakPeriodId: sp.peakPeriodId,
                              batteryLevel: sp.batteryLevel,
                              productTypeId: sp.productTypeId,
                          };
                      }),
                  };
              })
            : item.subParameters;

        return {
            title: item.parameterLabel,
            parameterDbField: item.parameterDbField,
            isRequired: item.isParameterRequired,
            id: `track-${i}`,
            periods: item.periods,
            subParameters: transformedSubParameters,
        };
    });
};

// function to create initial form fields for formik based on incoming subparameter
export const mapSubParameterFormValues = (selectedParameter, defaultPeakPeriodId = null) => {
    let result = {};

    switch (selectedParameter.dbField) {
        // '{Peak period} at Battery %/MWh {level}'
        case 'MaxChargePowerSOC':
        case 'MaxDischargePowerSOC':
        case 'OperationCost':
        case 'CostlessAdder':
            result = {
                startDate: '',
                endDate: '',
                value: '',
                peakPeriodId: defaultPeakPeriodId || selectedParameter.parameterValues.peakPeriodId,
                batteryLevel: selectedParameter.parameterValues.batteryLevel,
            };
            break;

        // '{Peak period}'
        case 'ChargingCosts':
        case 'DischargingCosts':
        case 'ChargingAdder':
        case 'DischargingAdder':
        case 'RTChargingAdder':
        case 'RTDischargingAdder':
        case 'MaxEnergyStorage':
        case 'MinEnergyStorage':
            result = {
                startDate: '',
                endDate: '',
                value: '',
                peakPeriodId: defaultPeakPeriodId || selectedParameter.parameterValues.peakPeriodId,
            };
            break;

        // 'Percent outage at {percentOutage} default to 100% for Forced Outages'
        case 'EFOR':
        case 'OutageMean':
        case 'OutageStandard':
            result = {
                startDate: '',
                endDate: '',
                value: '',
                percentOutage: selectedParameter.parameterValues.percentOutage
                    ? selectedParameter.parameterValues.percentOutage
                    : '100',
            };
            break;
        // '{peakPeriod} at {ancillaryProductType}'
        case 'AllowedContribution':
            result = {
                startDate: '',
                endDate: '',
                value: '',
                peakPeriodId: defaultPeakPeriodId || selectedParameter.parameterValues.peakPeriodId,
                productTypeId: selectedParameter.parameterValues.productTypeId,
            };
            break;

        default:
            return result;
    }

    return result;
};

// function to get additional required subparameter fields
export const getRequiredSubParameterFields = (selectedParameter) => {
    let result = null;

    switch (selectedParameter.dbField) {
        case 'MaxChargePowerSOC':
        case 'MaxDischargePowerSOC':
        case 'OperationCost':
        case 'CostlessAdder':
            result = ['value', 'peakPeriodId', 'batteryLevel'];
            break;

        case 'ChargingCosts':
        case 'DischargingCosts':
        case 'ChargingAdder':
        case 'DischargingAdder':
        case 'RTChargingAdder':
        case 'RTDischargingAdder':
        case 'MaxEnergyStorage':
        case 'MinEnergyStorage':
            result = ['value', 'peakPeriodId'];
            break;

        case 'EFOR':
        case 'OutageMean':
        case 'OutageStandard':
            result = ['value', 'percentOutage'];
            break;

        case 'AllowedContribution':
            result = ['value', 'peakPeriodId', 'productTypeId'];
            break;

        default:
            return result;
    }

    return result;
};

// function to create initial formik field state for period modals based on incoming parameter
export const mapPeriodFormValues = (period, dbParam) => {
    switch (dbParam) {
        // Main parameters
        case 'MinChargePower':
        case 'MaxChargePower':
        case 'MinDischargePower':
        case 'MaxDischargePower':
        case 'ChargeEfficiency':
        case 'DischargeEfficiency':
        case 'LeekageRate':
        case 'LeakageRate':
        case 'FixedCost':
        case 'MinAverageSOC':
        case 'MaxAverageSOC':
        case 'MaxGeneration':
            return {
                startDate: period.startDate,
                endDate: period.endDate,
                value: period.value === 'null' ? undefined : period.value,
            };

        case 'MaxChargePowerSOC':
        case 'MaxDischargePowerSOC':
        case 'OperationCost':
        case 'CostlessAdder':
            return {
                startDate: period.startDate,
                endDate: period.endDate,
                value: period.value === 'null' ? undefined : period.value,
                peakPeriodId: period.peakPeriodId,
                batteryLevel: period.batteryLevel,
            };

        case 'ChargingCosts':
        case 'DischargingCosts':
        case 'ChargingAdder':
        case 'DischargingAdder':
        case 'RTChargingAdder':
        case 'RTDischargingAdder':
        case 'MaxEnergyStorage':
        case 'MinEnergyStorage':
            return {
                startDate: period.startDate,
                endDate: period.endDate,
                value: period.value === 'null' ? undefined : period.value,
                peakPeriodId: period.peakPeriodId,
            };

        case 'EFOR':
        case 'OutageMean':
        case 'OutageStandard':
            return {
                startDate: period.startDate,
                endDate: period.endDate,
                value: period.value === 'null' ? undefined : period.value,
                percentOutage: period.percentOutage,
            };

        case 'AllowedContribution':
            return {
                startDate: period.startDate,
                endDate: period.endDate,
                value: period.value === 'null' ? undefined : period.value,
                peakPeriodId: period.peakPeriodId,
                productTypeId: period.productTypeId,
            };

        default:
            return {};
    }
};

// function to get title for sub parameter add modal button based on incoming parameter
export const getSubParameterButtonTitle = (parameter) => {
    switch (parameter) {
        case 'MaxChargePowerSOC':
        case 'MaxDischargePowerSOC':
        case 'OperationCost':
        case 'CostlessAdder':
            return 'batteries_timeline_sidebar_add_peak_and_level';

        case 'ChargingCosts':
        case 'DischargingCosts':
        case 'ChargingAdder':
        case 'DischargingAdder':
        case 'RTChargingAdder':
        case 'RTDischargingAdder':
        case 'MaxEnergyStorage':
        case 'MinEnergyStorage':
            return 'batteries_timeline_sidebar_add_peak';

        case 'EFOR':
        case 'OutageMean':
        case 'OutageStandard':
            return 'batteries_timeline_sidebar_add_percent_outage';

        case 'AllowedContribution':
            return 'batteries_timeline_sidebar_add_peak_and_product';

        default:
            return null;
    }
};

// function to get all subPeriodIds from periods that is used for deleting sub parameter values
export const getPeriodIdsFromSubParameter = (data) => {
    const periodsToReplace = [];

    // go through each item that has more than one subPeriodId and push a new object for each sub period id
    const ungroupedPeriods = data.reduce((arr, item) => {
        if (item.subPeriodIds) {
            item.subPeriodIds.forEach((id) => {
                arr.push({
                    subPeriodId: id,
                });
            });

            periodsToReplace.push(item);
        }

        return arr;
    }, []);

    if (periodsToReplace.length > 0) {
        const filtered = data.filter((item) => !periodsToReplace.includes(item));
        return filtered.concat(ungroupedPeriods).map((period) => period.subPeriodId);
    }

    return data.map((period) => period.subPeriodId);
};
export const isMainParameter = (parameterDbField) => {
    const mainParameterDbFields = [
        'MinChargePower',
        'MaxChargePower',
        'MinDischargePower',
        'MaxDischargePower',
        'ChargeEfficiency',
        'DischargeEfficiency',
        'LeekageRate',
        'LeakageRate',
        'FixedCost',
        'MinAverageSOC',
        'MaxAverageSOC',
        'MaxGeneration',
    ];

    return mainParameterDbFields.some((p) => p === parameterDbField);
};

// function to get the dbFieldValue for given parameter when manually editing
export const getManualEditInputDbFieldName = (parameter) => {
    switch (parameter) {
        case 'MaxChargePowerSOC':
        case 'MaxDischargePowerSOC':
        case 'OperationCost':
        case 'CostlessAdder':
            return 'BatteryLevel';

        case 'EFOR':
        case 'OutageMean':
        case 'OutageStandard':
            return 'PercentOutage';

        default:
            return null;
    }
};

// bidding strategy
export const getBiddingStrategy = async (subBatteryId, payload) => {
    const response = await axios.post(`/batteries/${subBatteryId}/bidding-strategy`, payload);

    return response.data
        .map((yearPeriod) =>
            yearPeriod.biddingStrategyPeriods.map((period) => ({
                id: nanoid(12),
                periodName:
                    !period.startDate && !period.endDate
                        ? period.participationPeriodDescription
                        : String(yearPeriod.year),
                year: yearPeriod.year ? yearPeriod.year : 0, // 0 is for the Default year
                ...period,
            }))
        )
        .flat();
};

export const createBid = async (subBatteryId, payload) => {
    const response = await axios.post(`/batteries/${subBatteryId}/create-bid`, payload);

    return response.data;
};

export const deleteBid = async (subBatteryId, participationId) =>
    axios.delete(`/batteries/${subBatteryId}/delete-bid/${participationId}`);

export const extractYearsFromBiddingStrategy = (data) => {
    const years = [...new Set(data.map((el) => el.year))];
    const defaultYearName = data.find((el) => el.year === 0).participationPeriodDescription;

    return years.map((year) => ({
        id: year,
        description: year === 0 ? defaultYearName : String(year),
    }));
};

export const updateStrategyRows = (data, rowId, updatedGenParticipationIds) => {
    return data.map((row) => {
        if (row.id === rowId) {
            return { ...row, genParticipationIds: updatedGenParticipationIds };
        }

        return row;
    });
};

export const filterStrategy = (filters, data) => {
    return data
        .map((el) => {
            if (filters.years.includes(el.year) && filters.products.includes(el.rtoProductId)) {
                return el;
            } else {
                return null;
            }
        })
        .filter((el) => el !== null);
};

export const getBiddingStrategyTemplates = async () => {
    const response = await axios.get(`/batteries/bidding-strategy-templates`);
    return response;
};

export const setBiddingStrategyTemplate = async (subBatteryId, payload) =>
    axios.put(`/batteries/${subBatteryId}/set-bidding-strategy-template`, payload);

// Batteries scheduled outages
export const getScheduledOutages = async (subBatteryId, payload) => {
    const response = await axios.post(`batteries/${subBatteryId}/scheduled-outages`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getScheduledOutagesDataSourceView = async (subBatteryId, payload) => {
    const response = await axios.post(`batteries/${subBatteryId}/scheduled-outages/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const createOutages = (subBatteryId, payload) =>
    axios.post(`/batteries/${subBatteryId}/create-scheduled-outages`, payload);

export const updateOutages = (subBatteryId, payload) =>
    axios.put(`/batteries/${subBatteryId}/scheduled-outages`, payload);

export const deleteOutages = (subBatteryId, payload) =>
    axios.delete(`/batteries/${subBatteryId}/scheduled-outages`, { data: payload, suppressErrorMessageHandler: true });

export const deleteAllOutages = (subBatteryId) => axios.delete(`/batteries/${subBatteryId}/all-scheduled-outages`);

export const validateOutages = async (subBatteryId, payload) => {
    const response = await axios.post(`/batteries/${subBatteryId}/validate/scheduled-outages`, payload);
    return response.data;
};

// historical output
export const getHistoricalOutput = async (subBatteryId, payload) => {
    const response = await axios.post(`batteries/${subBatteryId}/historical-output`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getHistoricalOutputDataSourceView = async (subBatteryId, payload) => {
    const response = await axios.post(`batteries/${subBatteryId}/historical-output/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const createHistoricalOutput = (subBatteryId, payload) =>
    axios.post(`/batteries/${subBatteryId}/create-historical-output`, payload);

export const updateHistoricalOutput = (subBatteryId, payload) =>
    axios.put(`/batteries/${subBatteryId}/historical-output`, payload);

export const deleteHistoricalOutput = (subBatteryId, payload) =>
    axios.delete(`/batteries/${subBatteryId}/historical-output`, { data: payload, suppressErrorMessageHandler: true });

export const deleteAllHistoricalOutput = (subBatteryId) =>
    axios.delete(`/batteries/${subBatteryId}/all-historical-output`, { suppressErrorMessageHandler: true });

export const validateHistoricalOutput = async (subBatteryId, payload) => {
    const response = await axios.post(`/batteries/${subBatteryId}/validate/historical-output`, payload);
    return response.data;
};

export const getResourceVariablesTableData = async ({
    tableId,
    parameters,
    selectedParentRecordId,
    pageSize,
    page,
    cancelToken,
    sortData,
    minEndDate,
}) => {
    const params = new URLSearchParams();
    if (selectedParentRecordId) {
        params.append('parentRecordId', selectedParentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.post(
        `/batteries/resourcevariables/tables/${tableId}/records`,
        {
            page,
            pageSize,
            sortData,
            minEndDate,
        },
        {
            params,
            cancelToken,
        }
    );

    return response.data;
};

export const getResourceVariablesDataSourceView = async ({
    tableId,
    parameters,
    selectedParentRecordId,
    pageSize,
    page,
    cancelToken,
    sortData,
    minEndDate,
}) => {
    const params = new URLSearchParams();
    if (selectedParentRecordId) {
        params.append('parentRecordId', selectedParentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.post(
        `/batteries/resourcevariables/tables/${tableId}/records/datasourceview`,
        {
            page,
            pageSize,
            sortData,
            minEndDate,
        },
        {
            params,
            cancelToken,
        }
    );

    return response.data;
};

export const saveResourceVariablesTableData = async ({ tableId, records, parameters }) => {
    const params = new URLSearchParams();
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });

    const response = await axios.put(`/batteries/resourcevariables/tables/${tableId}/records`, records, { params });

    return response.data;
};

export const addGenerationPeriodsTableRow = async ({ records, parentRecordId, parameters }) => {
    const params = new URLSearchParams();
    if (parentRecordId) {
        params.append('parentRecordId', parentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.post(
        `/batteries/resourcevariables/generationperiods/records/create/multiple`,
        records,
        { params }
    );

    return response.data;
};

export const exportAutoformTables = async ({ payload, parameters, fileName, isTemplateOnly }) => {
    const params = new URLSearchParams();
    params.append('fileName', fileName);
    params.append('downloadTemplate', isTemplateOnly);

    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });

    const response = await axios.post(`/batteries/resourcevariables/export`, payload, {
        params,
        responseType: 'blob',
    });

    const filename = createExportFilename(response.headers);
    return { file: response.data, name: filename };
};

export const importAutoformTables = async ({
    tableId,
    parentRecordId,
    parameters,
    additionalConfig,
    sseClientId,
    treatWarningsAsErrors,
    file,
}) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        suppressErrorMessageHandler: true,
        ...additionalConfig,
    };
    const formData = new FormData();
    formData.append('file', file);

    const params = new URLSearchParams();
    if (parentRecordId) {
        params.append('parentRecordId', parentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    if (sseClientId) {
        params.append('clientId', sseClientId);
    }
    if (treatWarningsAsErrors) {
        params.append('treatWarningsAsErrors', treatWarningsAsErrors);
    }
    params.append('tableId', tableId);
    config.params = params;

    let response = await axios.post(`/batteries/resourcevariables/import`, formData, config);

    return response.data;
};

export const checkDataMultiple = async ({ tableId, records, parentRecordId, parameters }) => {
    const params = new URLSearchParams();
    if (parentRecordId) {
        params.append('parentRecordId', parentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.post(`/batteries/resourcevariables/check-data/${tableId}/multiple`, records, {
        params,
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const deleteAutoformGridTable = async ({ tableId, itemId, parentRecordId, parameters }) => {
    const params = new URLSearchParams();

    if (parentRecordId) {
        params.append('parentRecordId', parentRecordId);
    }

    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });

    const response = await axios.delete(
        `/batteries/resourcevariables/${itemId}/tables/${tableId}/records/multiple/byparentid`,
        { params }
    );

    return response.data;
};

export const getBulkUpdateData = async ({ tableId, parentRecordId, parameters, allVariedByRecord = false }) => {
    const params = new URLSearchParams();

    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    params.append('allVariedByRecord', allVariedByRecord);

    const response = await axios.get(
        `/batteries/resourcevariables/tables/bulkupdate/state/${tableId}/${parentRecordId}`,
        { params }
    );

    return response.data;
};

export const bulkUpdateAutoformRecords = async (payload, parameters) => {
    const params = new URLSearchParams();

    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });

    const response = await axios.put('/batteries/resourcevariables/tables/bulkupdate', payload, { params });

    return response.data;
};
