import { Text, Button, SkeletonText } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@chakra-ui/react';
import Error from 'components/utils/Error';
import useUserRoleDetails from 'hooks/userManagement/useUserRoleDetails';

import SimpleModal from '../modal/SimpleModal';

const UserGroupDetailsModal = ({ onClose, currentItemId }) => {
    const { userRoleDetails, isLoading, isError } = useUserRoleDetails(currentItemId);

    if (isError)
        return (
            <Box m={6}>
                <Error primaryId="common_error" secondaryId="common_loading_error" />
            </Box>
        );

    return (
        <SimpleModal
            title={
                isLoading ? (
                    <FormattedMessage id="common_loading" />
                ) : (
                    <FormattedMessage id="user_management_details_for" values={{ name: userRoleDetails.name }} />
                )
            }
            size={'2xl'}
            isOpen
            onClose={onClose}
            withFooterDivider={true}
            footerContent={
                <Button mt={2} mb={2} type="button" align="end" onClick={onClose} textTransform="capitalize">
                    <FormattedMessage id="common_continue" />
                </Button>
            }
        >
            {isLoading ? (
                <Box>
                    <SkeletonText noOfLines={3} spacing="3" padding={3} />
                </Box>
            ) : (
                <>
                    <Text size="md" color="gray.600">
                        <FormattedMessage id="user_management_about_title" />
                    </Text>

                    <Text size="md" variant="regular" color="gray.900" mt={1}>
                        {userRoleDetails.description !== null ? (
                            userRoleDetails.description
                        ) : (
                            <FormattedMessage id="user_management_missing_role_details" />
                        )}
                    </Text>

                    <Text size="md" color="gray.600" textTransform="capitalize" mt={3}>
                        <FormattedMessage id="user_management_permissions_title" />:
                    </Text>

                    <Text size="md" variant="regular" color="gray.900" mt={1}>
                        {userRoleDetails.permissions.length > 0 ? (
                            userRoleDetails.permissions.join(', ')
                        ) : (
                            <FormattedMessage id="user_management_role_without_permissions" />
                        )}
                    </Text>
                </>
            )}
        </SimpleModal>
    );
};

export default UserGroupDetailsModal;
