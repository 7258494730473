import axios from 'axios';
import _ from 'lodash';

import { lenientParse, checkValidityBeforeSave, createDateAsUTC, createExportFilename } from '../../services/items';

import {
    getDtoFromDataValueObject,
    mapValidationMessagesToFormikStatus,
} from '../../components/autoform/utils/autoformUtils';
import { TAB_GROUP_SETTING_KEY } from 'constants/autoforms';

export const getAutoformTableData = async ({
    tableId,
    parameters,
    selectedParentRecordId,
    pageSize,
    page,
    cancelToken,
    sortData,
    filters,
    dateTimeFilters,
}) => {
    const params = new URLSearchParams();
    if (selectedParentRecordId) {
        params.append('parentRecordId', selectedParentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.post(
        `/auto-forms/tables/${tableId}/records`,
        {
            page,
            pageSize,
            sortData,
            filters,
            dateTimeFilters,
        },
        {
            params,
            cancelToken,
        }
    );

    return response.data;
};

export const getAutoformTableDataSourceView = async ({
    tableId,
    parameters,
    selectedParentRecordId,
    pageSize,
    page,
    cancelToken,
    sortData,
    filters,
    dateTimeFilters,
}) => {
    const params = new URLSearchParams();
    if (selectedParentRecordId) {
        params.append('parentRecordId', selectedParentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.post(
        `/auto-forms/tables/${tableId}/records/datasourceview`,
        {
            page,
            pageSize,
            sortData,
            filters,
            dateTimeFilters,
        },
        {
            params,
            cancelToken,
        }
    );

    return response.data;
};

export const saveAutoformRecords = async ({ tableId, records, parameters }) => {
    const params = new URLSearchParams();
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.put(`/auto-forms/tables/${tableId}/records`, records, {
        params,
    });

    return response.data;
};

export const generateBulkUpdateFieldName = (subItemId, tableId, columnId) => `${subItemId}__${tableId}__${columnId}`;
export const generateBulkUpdateSwitcherName = (...props) => `${generateBulkUpdateFieldName(...props)}--switcher`;
export const findBulkUpdateSwitcherFieldNames = (fieldValues, parentRecordId, tableId) =>
    Object.keys(fieldValues).filter(
        (key) =>
            key.includes('--switcher') &&
            (!(parentRecordId && tableId) || key.includes(`${parentRecordId}__${tableId}`))
    );
export const findBulkUpdateDataFieldsBySwitchers = (fieldsPool, switcherFieldNames) => {
    let result = {};

    for (const switcherFieldName of switcherFieldNames) {
        const dataFieldName = switcherFieldName.replace('--switcher', '');

        if (fieldsPool.hasOwnProperty(dataFieldName)) {
            result[dataFieldName] = fieldsPool[dataFieldName];
        }
    }

    return result;
};

export const getBulkUpdateData = async ({ tableId, parentRecordId, parameters }) => {
    const params = new URLSearchParams();

    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });

    const response = await axios.get(`/auto-forms/tables/bulkupdate/state/${tableId}/${parentRecordId}`, { params });

    return response.data;
};

export const bulkUpdateAutoformRecords = async (payload, parameters) => {
    const params = new URLSearchParams();

    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });

    const response = await axios.put('/auto-forms/tables/bulkupdate', payload, { params });

    return response.data;
};

export const mapBulkUpdateIssuesToFormikFields = (bulkUpdateErrorData, currentStatus) => {
    const newFormikStatus = { ...currentStatus, bulkUpdate: {} };

    for (const errorItem of bulkUpdateErrorData) {
        const { parentRecordId, tableId, columnsWithIssues } = errorItem;

        for (const columnWithIssues of columnsWithIssues) {
            const formikFieldIssuesData = mapValidationMessagesToFormikStatus(
                [columnWithIssues.columnValidationResult],
                () => generateBulkUpdateFieldName(parentRecordId, tableId, columnWithIssues.columnId)
            );

            newFormikStatus.bulkUpdate = {
                ...newFormikStatus.bulkUpdate,
                ...formikFieldIssuesData,
            };
        }
    }

    return newFormikStatus;
};

export const executeBulkUpdate = async ({
    formValues,
    status,
    parameters,
    metadata,
    dependentColumns,
    customRequestHandler,
    autoformTablesState,
}) => {
    let result = { isRequestSent: false };
    let entries = {};
    const payload = [];

    //--switcher: true means column/field values are varied by date; --switcher: false - do bulk update to the same value
    const variedByDateKeyPostfix = '--switcher';
    const bulkUpdateInfo = formValues?.bulkUpdate ?? {};

    const dataFields = Object.keys(bulkUpdateInfo)
        .filter((key) => !key.includes(variedByDateKeyPostfix))
        .reduce((acc, currentKey) => {
            acc[currentKey] = bulkUpdateInfo[currentKey];
            return acc;
        }, {});

    for (const [key, value] of Object.entries(dataFields)) {
        // Example: 555634760__25585__25772: "444"
        const [subItemIdentifier, tableIdentifier, columnIdentifier] = key.split('__');
        const tableId = parseInt(tableIdentifier);
        const columnId = parseInt(columnIdentifier);

        const tableMetadata = metadata?.tables.find((table) => table.id === tableId);
        const column = tableMetadata?.columns?.find((column) => column.id === columnId);
        const columnName = column?.name;
        const preparedValue = getDtoFromDataValueObject({ [columnName]: value }, tableMetadata)[columnName];

        const bulkUpdateColumnInitialValue = autoformTablesState[tableId]?.bulkUpdateInitialValues?.[key];
        const variedByDateKey = key + variedByDateKeyPostfix;
        const doBulkUpdate = !bulkUpdateInfo[variedByDateKey];

        if (dependentColumns && Object.values(dependentColumns)?.includes(columnName) && doBulkUpdate) {
            // Ex: dependentColumns: {'BATMAXAVGSOC': 'BATMINAVGSOC', 'BATMINAVGSOC': 'BATMAXAVGSOC'},
            // If we do bulk update on one of the dependent columns need to do validations on unchanged column
        } else if (bulkUpdateColumnInitialValue === value || !doBulkUpdate) {
            // Value was not changed, do not need to update this column or
            //Skip bulk update for a column if the column is varied by date
            continue;
        }

        entries = _.merge({}, entries, {
            [subItemIdentifier]: {
                [tableIdentifier]: {
                    [columnIdentifier]: preparedValue,
                },
            },
        });
    }

    for (const [subItemIdentifier, subItemData] of Object.entries(entries)) {
        for (const [tableIdentifier, tableData] of Object.entries(subItemData)) {
            const payloadItem = {
                parentRecordId: parseInt(subItemIdentifier),
                tableId: parseInt(tableIdentifier),
                columnData: [],
            };
            const tableId = parseInt(tableIdentifier);

            const tableMetadata = metadata?.tables.find((table) => table.id === tableId);
            const bulkUpdateInfo = Object.entries(tableData);
            const dependentColumnNames = Object.keys(dependentColumns ?? {});

            for (const [columnIdentifier, value] of bulkUpdateInfo) {
                const columnId = parseInt(columnIdentifier);
                const column = tableMetadata?.columns?.find((column) => column.id === columnId);
                const columnName = column?.name;

                let dependentColumnsInfo = [];

                if (Array.isArray(dependentColumnNames) && dependentColumnNames.includes(columnName)) {
                    // Pass dependent column info
                    const dependentColumnName = dependentColumns[columnName];
                    const dependentColumn = tableMetadata?.columns?.find(
                        (column) => column.name === dependentColumnName
                    );
                    const dependentColumnId = dependentColumn?.id;
                    const dependentColumnValue = tableData[dependentColumnId];

                    const dependentColumnInfo = {
                        columnName: dependentColumnName,
                        columnId: dependentColumnId,
                        value: dependentColumnValue,
                    };
                    dependentColumnsInfo = [dependentColumnInfo];
                }

                const columnData = {
                    columnName,
                    columnId: parseInt(columnIdentifier),
                    value,
                    dependentColumns: dependentColumnsInfo,
                };

                payloadItem.columnData.push(columnData);
            }

            payload.push(payloadItem);
        }
    }

    if (payload?.length) {
        try {
            result.isRequestSent = true;
            let response;

            if (typeof customRequestHandler === 'function') {
                // If we passed in Custom Handler, for ex. from Batteries then use that
                response = await customRequestHandler(payload, parameters);
            } else {
                response = await bulkUpdateAutoformRecords(payload, parameters);
            }

            result.formikStatus = mapBulkUpdateIssuesToFormikFields(response ?? [], status);
        } catch (error) {
            if (error.status !== 401) {
                result.error = error;
                result.formikStatus = mapBulkUpdateIssuesToFormikFields(error?.response?.data ?? [], status);
            }
        }
    }

    return result;
};

export const getBulkUpdatedTableIds = (formValues, autoformTablesState) => {
    const tablesWithState = Object.keys(autoformTablesState);
    const tablesToRefresh = Object.keys(formValues?.bulkUpdate ?? {}).reduce((acc, fieldName) => {
        const [, tableId] = fieldName.split('__');

        if (tablesWithState.includes(tableId)) {
            acc[tableId] = true;
        }

        return acc;
    }, {});

    return Object.keys(tablesToRefresh);
};

export const groupBulkUpdateFieldsByTable = (formValues) => {
    const groupedFields = {};

    Object.keys(formValues.bulkUpdate).forEach((key) => {
        const [, tableId] = key.split('__');

        if (!groupedFields.hasOwnProperty(tableId)) {
            groupedFields[tableId] = {};
        }

        groupedFields[tableId][key] = formValues.bulkUpdate[key];
    });

    return groupedFields;
};

export const addItemToPortfolios = (itemId, payload) => axios.post(`/items/${itemId}/portfolios`, payload);

export const deleteAutoformTableRow = async ({ tableId, recordId, parameters }) => {
    const params = new URLSearchParams();
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.delete(`/auto-forms/tables/${tableId}/records/single/${recordId}`, { params });

    return response.data;
};

export const deleteAutoformTableRows = async ({ tableId, recordsIds, parameters }) => {
    const params = new URLSearchParams();
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.delete(`/auto-forms/tables/${tableId}/records/multiple`, {
        data: { recordsIds },
        params,
    });
    return response.data;
};

export const deleteAutoformGridTable = async ({
    tableId,
    parentRecordId,
    parameters,
    ignoreRequiredParentValidation = false,
}) => {
    const params = new URLSearchParams();

    if (parentRecordId) {
        params.append('parentRecordId', parentRecordId);
    }

    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });

    if (ignoreRequiredParentValidation) {
        params.append('ignoreRequiredParentRecordId', true);
    }

    const response = await axios.delete(`/auto-forms/tables/${tableId}/records/multiple/byparentid`, { params });

    return response.data;
};

export const addSingleAutoformTableRow = async ({ tableId, record, parentRecordId, parameters }) => {
    const params = new URLSearchParams();
    if (parentRecordId) {
        params.append('parentRecordId', parentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });

    const response = await axios.post(`/auto-forms/tables/${tableId}/records/create/single`, record, { params });
    return response.data;
};
export const addMultipleAutoformTableRow = async ({ tableId, records, parentRecordId, parameters }) => {
    const params = new URLSearchParams();
    if (parentRecordId) {
        params.append('parentRecordId', parentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.post(`/auto-forms/tables/${tableId}/records/create/multiple`, records, {
        params,
    });

    return response.data;
};

export const checkDataSingle = async ({ tableId, record, parentRecordId, parameters }) => {
    const params = new URLSearchParams();
    if (parentRecordId) {
        params.append('parentRecordId', parentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });

    const response = await axios.post(`/auto-forms/check-data/${tableId}/single`, record, {
        params,
        suppressErrorMessageHandler: true,
    });
    return response.data;
};
export const checkDataMultiple = async ({ tableId, records, parentRecordId, parameters }) => {
    const params = new URLSearchParams();
    if (parentRecordId) {
        params.append('parentRecordId', parentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.post(`/auto-forms/check-data/${tableId}/multiple`, records, {
        params,
        suppressErrorMessageHandler: true,
    });
    return response.data;
};
// TODO: Is this the final table validation or do we need more?
export const getValidRows = (rows, columns) => {
    return rows.map((row) => formatRow(row.data, columns)).filter((row) => row !== null);
};

// Check if needed when working on validations
export const formatRow = (row, columns) => {
    const output = {};
    for (let col in columns) {
        const colData = row[col.name];
        if (col.inputTypeDescription === 'MonthCalendar') {
            if (!checkValidityBeforeSave(colData)) {
                return null;
            } else {
                output[col.name] = createDateAsUTC(lenientParse(colData));
            }
        } else {
            output[col.name] = colData;
        }
    }
    return row;
};

export const refetchSelectedItemBackendData = async ({ tableId, itemId }) => {
    const response = await axios.get(`/auto-forms/tables/${tableId}/records/${itemId}`);

    return response.data;
};

export const exportAutoformTables = async ({
    tablesData,
    parentRecordId,
    parameters,
    fileName,
    formLabel,
    isTemplateOnly,
}) => {
    const params = new URLSearchParams();
    params.append('fileName', fileName);
    params.append('downloadTemplate', isTemplateOnly);
    if (formLabel) {
        params.append('formLabel', formLabel);
    }
    if (parentRecordId) {
        params.append('parentRecordId', parentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.post(`/auto-forms/export`, tablesData, { params, responseType: 'blob' });

    const filename = createExportFilename(response.headers);
    return { file: response.data, name: filename };
};

export const importAutoformTables = async ({
    tableIds,
    parentRecordId,
    parameters,
    file,
    additionalConfig,
    sseClientId,
    treatWarningsAsErrors,
}) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        suppressErrorMessageHandler: true,
        ...additionalConfig,
    };
    const formData = new FormData();
    formData.append('file', file);

    const params = new URLSearchParams();
    if (parentRecordId) {
        params.append('parentRecordId', parentRecordId);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    tableIds.forEach((tableId) => {
        params.append('tableIds', tableId);
    });
    if (sseClientId) {
        params.append('sseClientId', sseClientId);
    }
    if (treatWarningsAsErrors) {
        params.append('treatWarningsAsErrors', treatWarningsAsErrors);
    }

    config.params = params;

    let response = await axios.post(`/auto-forms/import`, formData, config);

    return response.data;
};

export const sanitizeDates = (obj) => {
    return Object.entries(obj).reduce((acc, [key, val]) => {
        if (val instanceof Date) {
            acc[key] = val.toISOString();
        } else {
            acc[key] = val;
        }
        return acc;
    }, {});
};

export const getDescriptionColumn = (columns) => {
    const friendlyNameColumns = columns.filter((column) => column.isFriendlyName);
    if (friendlyNameColumns.length !== 0) {
        return friendlyNameColumns[0];
    }
    const descriptionColumn = columns.find((column) => column.name === 'DESCRIPTION');
    if (descriptionColumn) {
        return descriptionColumn;
    } else {
        return columns[0];
    }
};

export const getAutoformChartData = async ({
    tableId,
    selectedParentRecordId,
    parameters,
    filters,
    dateTimeFilters,
    pointCount,
}) => {
    const params = new URLSearchParams();
    if (selectedParentRecordId) {
        params.append('parentRecordId', selectedParentRecordId);
    }
    if (pointCount) {
        params.append('pointCount', pointCount);
    }
    parameters.forEach((parameterValue) => {
        params.append('parameters', parameterValue);
    });
    const response = await axios.post(
        `/auto-forms/tables/${tableId}/records/chartdata`,
        {
            filters,
            dateTimeFilters,
        },
        {
            params,
        }
    );

    return response.data;
};

export const getAutoFormGenericAssetTemplates = (schemaCode) => {
    const response = axios.get(`/generic-asset-library/auto-forms/${schemaCode}/templates`);
    return response;
};

export const applyGenericAssetTemplate = async (schemaCode, parentRecordId, templateFileName) => {
    const response = await axios.post(`/generic-asset-library/auto-forms/${schemaCode}`, {
        parentRecordId: parentRecordId,
        templateFileName: templateFileName,
    });
    return response;
};

// helper function to determine if the next item in a list of autoform tables has the same GROUP_KEY value in it's setting
export const isNextTableInGroup = (tables, currentIdx) => {
    if (currentIdx >= tables?.length) {
        return false;
    }

    const currentSetting = tables[currentIdx]?.settings?.find((s) => s.name === TAB_GROUP_SETTING_KEY)?.value;
    const nextSetting = tables[currentIdx + 1]?.settings?.find((s) => s.name === TAB_GROUP_SETTING_KEY)?.value;

    if (!currentSetting || !nextSetting) return false;

    return currentSetting === nextSetting;
};

// helper function to determine if the previous item in a list of autoform tables has the same GROUP_KEY value in it's setting
export const isPreviousTableInGroup = (tables, currentIdx) => {
    if (currentIdx === 0) {
        return false;
    }

    const currentSetting = tables[currentIdx]?.settings?.find((s) => s.name === TAB_GROUP_SETTING_KEY)?.value;
    const prevSetting = tables[currentIdx - 1]?.settings?.find((s) => s.name === TAB_GROUP_SETTING_KEY)?.value;

    if (!currentSetting || !prevSetting) return false;

    return currentSetting === prevSetting;
};
