import { useState, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Flex, Container } from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setAccountConnection } from '../store/connection/connectionSlice';
import { getUserConnections, setAccountConnectionConfig, removeConnectionSearchParams } from '../services/connections';
import useConnectionSearch from '../hooks/useConnectionSearch';
import useHistoryPush from '../hooks/useHistoryPush';

import ConnectionStep from '../components/connection/ConnectionStep';
import ConnectionOption from '../components/connection/ConnectionOption';

const Connection = () => {
    const intl = useIntl();
    const historyPush = useHistoryPush();
    const dispatch = useDispatch();
    const connectionConfig = useSelector((state) => state.connections);
    const history = useHistory();

    const [connection, setConnection] = useState({
        isLoading: false,
        options: [],
        error: '',
        value: connectionConfig?.connection,
        title: connectionConfig?.connectionTitle,
    });

    const { search, setSearch, setSearchCollection, noSearchResultsFound, searchResultsFound } = useConnectionSearch();
    console.log('DEBUG Connection Page');
    const handleSearch = (value) => {
        setSearch((prev) => ({ ...prev, term: value }));
        setSearchCollection(connection.options);
    };

    const getConnections = useCallback(async () => {
        setConnection((prev) => ({ ...prev, isLoading: true }));

        try {
            const data = await getUserConnections();
            console.log('DEBUG getUserConnections data');
            console.log({ data });
            setConnection((prev) => ({
                ...prev,
                isLoading: false,
                options: data.map((item) => ({
                    title: item.name,
                    subtitle: '',
                    value: item.id,
                    icon: null,
                })),
            }));
        } catch (err) {
            if (err.response?.status === 500) {
                setConnection((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: intl.formatMessage({ id: 'connection_error_fetch_connections' }),
                }));
            }
        }
    }, [intl]);

    const clearDefaultsConfig = useCallback(async () => {
        console.log('DEBUG clearDefaultsConfig( Clear all connection local storage defaults');
        // Clear all connection local storage defaults
        setAccountConnectionConfig(null);

        const connectionConfig = {
            connection: connection?.value,
            connectionTitle: connection?.title,
            environment: null,
            environmentTitle: '',
            book: null,
            bookTitle: '',
            schemaGroupId: null,
            isDefaultConfig: false,
            versionName: '',
        };

        // Clear store, but need to preserve original connection if any
        dispatch(setAccountConnection(connectionConfig));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    useEffect(() => {
        getConnections();
        clearDefaultsConfig();
    }, [getConnections, clearDefaultsConfig]);

    const onConnectionSelect = async (selected, selectedTitle) => {
        console.log('DEBUG onConnectionSelect()');
        setConnection((prev) => ({ ...prev, value: selected, title: selectedTitle }));

        //Added to avoid the blank page that appears after encountering an incompatible version
        const clearCurrent = removeConnectionSearchParams(window.location.search);
        const searchParams = new URLSearchParams(clearCurrent);
        console.log('DEBUG history.replace(window.location.pathname, searchParams.toString());');
        history.replace(window.location.pathname, searchParams.toString());

        const search = new URLSearchParams();
        search.append('_connectionId', selected);
        search.append('_connectionTitle', selectedTitle);

        console.log('DEBUG historyPush');
        console.log({ searchParams, pathname: `/environment`, search });

        historyPush({
            pathname: `/environment`,
            search,
        });
    };

    return (
        <Flex minHeight="100vh" bg="background-secondary" pt={40} pb={20} flexDirection="column">
            <Container maxW={1056}>
                <ConnectionStep
                    onSearch={handleSearch}
                    current={1}
                    step={1}
                    total={3}
                    title={<FormattedMessage id="connection_select_connection" />}
                    subtitle={<FormattedMessage id="connection_connection" />}
                    description={<FormattedMessage id="connection_please_select_the_connection" />}
                    isLoading={connection.isLoading}
                    error={connection.error}
                    noResults={noSearchResultsFound}
                    onErrorDismiss={() => {
                        setConnection((prev) => ({ ...prev, error: '' }));
                        getConnections();
                    }}
                >
                    {(searchResultsFound ? search.results : connection.options).map((o) => (
                        <ConnectionOption
                            {...o}
                            key={o.value}
                            isSelected={Number(connection.value) === o.value}
                            onClick={() => onConnectionSelect(o.value, o.title)}
                        />
                    ))}
                </ConnectionStep>
            </Container>
        </Flex>
    );
};

export default Connection;
