import FormField from './FormField';
import MultiSelect from './MultiSelect';

// If you need to extend MultiSelect functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for MultiSelect should be excluded from ...props and manually added
const MultiSelectField = ({
    value,
    onChange,
    onBlur,
    options,
    valueKey,
    labelKey,
    getCustomLabel,
    isInvalid,
    isWarning,
    isDisabled,
    isControlled,
    isOptionDisabled,
    placeholderLabel,
    multipleSelectedLabel,
    allSelectedLabel, // custom label, not part of MultiSelect component
    showSelectedCount,
    showSelectAll,
    menuProps,
    ...props
}) => {
    const allOptionsAreSelected = options?.length === value?.length;
    const manySelectedLabel = allSelectedLabel && allOptionsAreSelected ? allSelectedLabel : multipleSelectedLabel;

    return (
        <FormField isInvalid={isInvalid} isDisabled={isDisabled} {...props}>
            <MultiSelect
                value={value}
                options={options}
                onChange={onChange}
                onBlur={onBlur}
                valueKey={valueKey}
                labelKey={labelKey}
                getCustomLabel={getCustomLabel}
                isInvalid={isInvalid}
                isWarning={isWarning}
                isDisabled={isDisabled}
                isControlled={isControlled}
                isOptionDisabled={isOptionDisabled}
                placeholderLabel={placeholderLabel}
                multipleSelectedLabel={manySelectedLabel}
                showSelectedCount={showSelectedCount}
                showSelectAll={showSelectAll}
                menuProps={menuProps}
            />
        </FormField>
    );
};

export default MultiSelectField;
