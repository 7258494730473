import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

const NavigationListGroup = ({ config, renderItem }) => {
    return (
        <StyledBox>
            <StyledText bg="background-quinary" color="menu-group-text">
                {config.label}
            </StyledText>

            <Flex flexDirection="column">
                {config.items.map((menuItem, menuItemIndex) => (
                    <React.Fragment key={menuItemIndex}>{renderItem(menuItem)}</React.Fragment>
                ))}
            </Flex>
        </StyledBox>
    );
};

const StyledBox = styled(Box)`
    & + * {
        margin-top: 16px;
    }
`;

const StyledText = styled(Text)`
    text-transform: uppercase;
    padding: var(--chakra-space-1) var(--chakra-space-4);
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 6px;
`;

export default NavigationListGroup;
