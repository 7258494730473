import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Heading, Flex, Box, SimpleGrid, Button, Divider, Spacer, useDisclosure, IconButton } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
    bulkUpdateAutoformRecords,
    deleteSubBattery,
    editBatteryAndSubbatteries,
    getBatteryDefinitions,
    getBiddingStrategyTemplates,
} from '../../services/batteries';

import { getBulkUpdateData } from '../../store/batteries/batteriesApi';
import { checkItemInputEvent, deleteItem, flatItemDescriptions } from '../../services/items';
import { validateLongLat } from '../../services/portfolios';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import ShowHideSidebarButton from '../layout/ShowHideSidebarButton';

import ConfirmationModal from '../modal/ConfirmationModal';

import Tooltip from '../utils/Tooltip';
import Error from '../utils/Error';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import ItemPropertiesModal from '../utils/ItemPropertiesModal';
import DeleteRecordModal from '../utils/DeleteRecordModal';
import SecondaryIconButton from '../utils/SecondaryIconButton';
import ActionBar from '../utils/ActionBar';
import CustomItemIcon from '../utils/CustomItemIcon';
import PromptWrapper from '../utils/PromptWrapper';
import ErrorAccordion from '../utils/ErrorAccordion';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import CheckboxFormikField from './../forms/CheckboxFormikField';

import { onHasUnsavedChanges } from '../../store/helpers/helpersSlice';

import { ReactComponent as BatteryIcon } from '../../icons/item-icons/battery.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as FileIcon } from '../../icons/file.svg';
import { ReactComponent as DeleteBatteryIcon } from '../../icons/delete.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';

import AddSubBatteryModal from './AddSubBatteryModal';
import SubBatteriesSection from './SubBatteriesSection';
import { executeBulkUpdate, getBulkUpdatedTableIds, groupBulkUpdateFieldsByTable } from '../../services/autoforms';
import { setBulkUpdateInitialValues, toggleReloadGridFlag } from '../../store/autoform/autoformSlice';
import useAutoformBulkUpdate from '../autoform/hooks/useAutoformBulkUpdate';
import useAutoformFormValidation from '../autoform/hooks/useAutoformFormValidation';
import useAutoformParams from '../autoform/hooks/useAutoformParams';
import useAutoformMetadata from '../autoform/hooks/useAutoformMetadata';
import AutoformFormikDirtyCheck from '../autoform/AutoformFormikDirtyCheck';
import { isSubmitDisabled } from '../../services/utils';
import useAutoformLoadMetadata from '../autoform/hooks/useAutoformLoadMetadata';
import { FEATURE_FLAG_NAMES } from '../../constants/featureFlags';
import { useIsFeatureActive } from '../../hooks/features-flags';

const resourceVariablesAutoformId = 'PSBATSTO';

const Battery = ({
    batteryItem,
    typeId,
    onBatteryEdit,
    onBatteryDelete,
    onNodeRename,
    onNodeChangeIsMaster,
    onNodeOwnershipPercentageUpdate,
    onNodeItemDelete,
    refreshMapLayer,
    helpPageUrlItemTypeId = null,
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const { batteryId } = useParams();
    const historyPush = useHistoryPush();
    const dispatch = useDispatch();

    const deleteModal = useDisclosure();
    const propertiesModal = useDisclosure();
    const addSubBatteryModal = useDisclosure();
    const deleteBatteryModal = useDisclosure();

    const autoformTablesState = useSelector((state) => state.autoform.tables);
    const dataForItemCreation = useSelector((state) => state.item.dataForItemCreation);
    const pricingPoints = dataForItemCreation?.pricingPoints;
    const rtelecBasis = dataForItemCreation?.rtelecBasis;

    const [selectedBattery, setSelectedBattery] = useState(null);
    const [batteryDefinitions, setBatteryDefinitions] = useState(null);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [editDescriptionsConfig, setEditDescriptionsConfig] = useState(null);
    const [templatesConfig, setTemplatesConfig] = useState(null);
    const [subBatteryForDeletion, setSubBatteryForDeletion] = useState(null);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [autoformSelectedEntities, setAutoformSelectedEntities] = useState({
        subItemId: null,
        tableId: null,
    });
    const { handleDependentDataChange, validationSettings, dirtyCheck } = useAutoformBulkUpdate({
        tableId: autoformSelectedEntities?.tableId,
        parentRecordId: autoformSelectedEntities?.subItemId,
        customLoadDataHandler: getBulkUpdateData,
    });
    const { onValidate } = useAutoformFormValidation({
        validationSettings,
    });
    const { isLoading: isMetadataLoading } = useAutoformLoadMetadata(resourceVariablesAutoformId);
    const isCAMSliceOfDayVisible = useIsFeatureActive(FEATURE_FLAG_NAMES.CAM_SLICEOFDAY_FIELDS_VISIBLE);

    const parameters = useAutoformParams();
    const { metadata } = useAutoformMetadata();

    const selectedBatteryRef = useRef(null);

    const mapBatteryDefinitionsConfig = useCallback(
        (definitions, deliverabilityStatusId) => {
            return definitions.map((def) => {
                const config = {};

                config['batteryId'] = def.id; //BE expects sub battery id as 'batteryId'
                config['itemId'] = batteryId ? parseInt(batteryId) : parseInt(batteryItem.properties.id);
                config['description'] = def.description;
                config['batteryTypeId'] = def.batteryTypeId || null;
                if (isCAMSliceOfDayVisible) {
                    config['deliverabilityStatusId'] = def.deliverabilityStatusId || null;
                    config['numRACycles'] = def.numRACycles || 1;
                    config['pcdsPercent'] = def.pcdsPercent || null;
                }

                return config;
            });
        },
        [batteryId, batteryItem?.properties.id, isCAMSliceOfDayVisible]
    );

    const mapBiddingStrategyTemplates = (subBatteries) => {
        const config = {};

        subBatteries.forEach((def) => {
            config[`${def.id}`] = { templateId: def.templateId, hasUpload: false };
        });

        return config;
    };

    const formLabels = {
        book: intl.formatMessage({ id: 'batteries_book' }),
        pricingPoint: intl.formatMessage({ id: 'common_electric_pricing_point' }),
        rtelecBasis: intl.formatMessage({ id: 'common_rtelec_pricing_point' }),
        latitude: intl.formatMessage({ id: 'latitude' }),
        longitude: intl.formatMessage({ id: 'longitude' }),
        master: intl.formatMessage({ id: 'common_master' }),
    };

    const initialFormValues = useMemo(
        () => ({
            battery: {
                itemId: selectedBattery?.id,
                description: selectedBattery?.description,
                bookId: selectedBattery?.bookId,
                pricingPointId: selectedBattery?.pricingPointId,
                itemTypeId: typeId || batteryItem.properties.typeId,
                latitude: selectedBattery?.latitude != null ? selectedBattery?.latitude : '',
                longitude: selectedBattery?.longitude != null ? selectedBattery?.longitude : '',
                securityId: selectedBattery?.securityId,
                rtelecBasisPriceId: selectedBattery?.rtelecBasisPriceId,
            },
            subBatteries: batteryDefinitions?.initialFormValues,
        }),
        [selectedBattery, batteryDefinitions?.initialFormValues, batteryItem?.properties?.typeId, typeId]
    );

    const onAutoformTableChange = useCallback((selectedTable, subItemId) => {
        setAutoformSelectedEntities({
            subItemId,
            tableId: selectedTable?.id,
        });
    }, []);

    const fetchSubBatteriesData = useCallback(() => {
        setLoadingError(false);

        Promise.all([getBatteryDefinitions(batteryId || batteryItem.properties.id), getBiddingStrategyTemplates()])
            .then(([batteryItemData, batteryBiddingStrategyTemplates]) => {
                setSelectedBattery(batteryItemData.batteryItem);
                selectedBatteryRef.current = batteryItemData.batteryItem;

                const defaultTemplate = {
                    id: null,
                    description: intl.formatMessage({ id: 'common_custom' }),
                };

                setBatteryDefinitions({
                    definitions: {
                        ...batteryItemData,
                        templates: [...batteryBiddingStrategyTemplates.data, defaultTemplate],
                    },
                    initialFormValues: mapBatteryDefinitionsConfig(
                        batteryItemData.subBatteries,
                        null
                    ),
                });

                setEditDescriptionsConfig(
                    flatItemDescriptions(batteryItemData.subBatteries, batteryId || batteryItem.properties.id)
                );

                setTemplatesConfig(mapBiddingStrategyTemplates(batteryItemData.subBatteries));
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [batteryId, batteryItem?.properties.id, intl, mapBatteryDefinitionsConfig]);

    useEffect(() => {
        setIsLoading(true);
        fetchSubBatteriesData();
        // fetchSubBatteriesData function reference will change for each new batterId triggering reload of data
    }, [fetchSubBatteriesData]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const updateInitialValues = useCallback(
        (formValues) => {
            dispatch(
                setBulkUpdateInitialValues({
                    values: groupBulkUpdateFieldsByTable(formValues),
                    resetData: true,
                })
            );
        },
        [dispatch]
    );

    const submitBulkUpdate = useCallback(
        async (formValues, formBag) => {
            const {
                isRequestSent,
                error: bulkUpdateError,
                formikStatus,
            } = await executeBulkUpdate({
                autoformTablesState,
                formValues,
                status: formBag.status,
                parameters,
                metadata,
                dependentColumns: {
                    BATMAXAVGSOC: 'BATMINAVGSOC',
                    BATMINAVGSOC: 'BATMAXAVGSOC',
                    MAXENERGYSTORAGE: 'MINENERGYSTORAGE',
                    MINENERGYSTORAGE: 'MAXENERGYSTORAGE',
                },
                customRequestHandler: (...args) =>
                    bulkUpdateAutoformRecords(...args, autoformSelectedEntities?.subItemId),
            });

            if (isRequestSent && !bulkUpdateError) {
                updateInitialValues(formValues);
                dispatch(
                    toggleReloadGridFlag({
                        tableIds: getBulkUpdatedTableIds(formValues, autoformTablesState),
                    })
                );
            }

            if (isRequestSent) {
                formBag.setStatus(formikStatus);
            }

            return !bulkUpdateError;
        },
        [dispatch, parameters, autoformSelectedEntities?.subItemId, metadata, updateInitialValues, autoformTablesState]
    );

    const onBatteryFormSubmit = async (values, formBag) => {
        const successMessage = intl.formatMessage({ id: 'batteries_save_changes_success' });
        const arcGISError = intl.formatMessage({ id: 'common_item_arcgis_lat_long_save_failed' });

        try {
            if (isCAMSliceOfDayVisible) {
                //PCDSPercent (NumberInputFormikField) can return '' if it had a value and the value was deleted
                values?.subBatteries
                    ?.filter((sub) => Number.isNaN(Number.parseFloat(sub?.pcdsPercent)))
                    ?.forEach((sub) => {
                        sub.pcdsPercent = null;
                    });
            }

            const {
                battery: {
                    itemId,
                    description,
                    bookId,
                    pricingPointId,
                    itemTypeId,
                    latitude,
                    longitude,
                    securityId,
                    rtelecBasisPriceId,
                },
                subBatteries,
            } = values;
            const ppId = pricingPointId || null;

            const payload = {
                battery: {
                    description,
                    bookId,
                    itemTypeId,
                    pricingPointId: ppId,
                    itemId,
                    longitude: longitude !== '' ? longitude : null,
                    latitude: latitude !== '' ? latitude : null,
                    securityId,
                    rtelecBasisPriceId,
                },
                subBatteries,
            };

            const { arcGisIssue } = await editBatteryAndSubbatteries(payload);

            fetchSubBatteriesData();

            if (!batteryItem) {
                onBatteryEdit({
                    id: itemId,
                    description,
                    bookId,
                    pricingPointId,
                    securityId,
                    rtelecBasisPriceId,
                });
            } else {
                onNodeRename(description);
                onNodeChangeIsMaster(securityId);
            }

            const isBulkUpdateSuccess = await submitBulkUpdate(values, formBag);

            if (isBulkUpdateSuccess) {
                toast({
                    message: successMessage,
                    slot: arcGisIssue && <ErrorAccordion message={arcGISError} details={arcGisIssue} status={'warn'} />,
                    duration: arcGisIssue && 20000,
                });
            }
        } finally {
            if (typeof refreshMapLayer === 'function') {
                refreshMapLayer({
                    long: values.battery.longitude,
                    lat: values.battery.latitude,
                    objectRefId: selectedBatteryRef.current.objectRefId,
                });
            }
        }
    };

    const onEditDescription = (batteryId) => {
        if (!editDescriptionsConfig[batteryId].penDisabled) {
            setEditDescriptionsConfig((prevState) => {
                return {
                    ...prevState,
                    [batteryId]: { isActive: !prevState[batteryId].isActive, penDisabled: false },
                };
            });
        }
    };

    const validateDescription = (value, which, batteryId) => {
        const trimmed = value.trim();
        const maxLength = which === 'battery' ? 100 : 50;
        const label = which.charAt(0).toUpperCase() + which.slice(1);
        let errorMessage = '';

        if (trimmed.length === 0) {
            errorMessage = intl.formatMessage({ id: 'common_forms_validation_required' }, { label: `${label} name` });
        } else if (trimmed.length > maxLength) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: `${label} name`, lengthRule: `${maxLength} characters or less` }
            );
        }

        if (errorMessage !== '') {
            setEditDescriptionsConfig((prevState) => {
                return { ...prevState, [batteryId]: { ...prevState[batteryId], penDisabled: true } };
            });
        } else {
            setEditDescriptionsConfig((prevState) => {
                return { ...prevState, [batteryId]: { ...prevState[batteryId], penDisabled: false } };
            });
        }

        return errorMessage;
    };

    const validate = (values) => {
        let longitude = validateLongLat(values.battery.longitude, 'long', values.battery.latitude);
        let latitude = validateLongLat(values.battery.latitude, 'lat', values.battery.longitude);

        return {
            ...(longitude || latitude
                ? {
                      battery: {
                          longitude: longitude ? longitude : null,
                          latitude: latitude ? latitude : null,
                      },
                  }
                : {}),
            ...onValidate(values),
        };
    };

    const onDeleteModalOpen = (subBatteryId, subBatteryDesc) => {
        setSubBatteryForDeletion({
            id: subBatteryId,
            description: subBatteryDesc,
        });
        deleteModal.onOpen();
    };

    const onDeleteModalClose = () => {
        deleteModal.onClose();
        setSubBatteryForDeletion(null);
    };

    const onSubBatteryDelete = async (subBatteryId) => {
        onDeleteModalClose();

        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'installation' });

        try {
            setIsLoading(true);

            await deleteSubBattery(subBatteryId);
            fetchSubBatteriesData();

            toast(successMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const updateBatteries = ({ id, batteryTypeId, description, templateId }, itemId) => {
        const typeId = batteryTypeId || undefined;

        initialFormValues.subBatteries.push({
            batteryId: id,
            batteryTypeId: typeId,
            description,
            itemId,
            templateId,
            numRACycles: 1,
            deliverabilityStatusId: null,
        });

        setTemplatesConfig((prev) => {
            return {
                ...prev,
                [id]: { templateId: templateId, hasUpload: false },
            };
        });

        setEditDescriptionsConfig((prev) => {
            return {
                ...prev,
                [id]: { isActive: false, penDisabled: false },
            };
        });
    };

    const onItemDelete = async (item) => {
        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'battery item' });

        await deleteItem(item.id);

        dispatch(onHasUnsavedChanges(false));

        if (batteryItem) {
            onNodeItemDelete();
        } else {
            onBatteryDelete(item);
            historyPush('/batteries');
        }

        toast(successMessage);
    };

    const onTemplatesConfigChange = (subBatteryId, config) => {
        setTemplatesConfig((prevState) => {
            return {
                ...prevState,
                [subBatteryId]: config,
            };
        });
    };

    return (
        <>
            <PromptWrapper />

            <ExpandableItemWrapper>
                {isLoading || isMetadataLoading ? (
                    <DefinitionsSkeleton />
                ) : loadingError ? (
                    <Box m={6}>
                        <Error
                            primaryId="common_error"
                            secondaryId="common_loading_error"
                            additionalText="definitions"
                        />
                    </Box>
                ) : (
                    <>
                        <Formik
                            enableReinitialize
                            initialValues={initialFormValues}
                            onSubmit={onBatteryFormSubmit}
                            validate={validate}
                        >
                            {({ isSubmitting, errors, touched, values, setValues, setTouched, status, setStatus }) => (
                                <Form>
                                    <AutoformFormikDirtyCheck
                                        initialValues={initialFormValues}
                                        isFormDirty={isFormDirty}
                                        onFormDirtyChange={setIsFormDirty}
                                        config={{
                                            dirtyCheck,
                                        }}
                                    />

                                    <Flex pt={3} pl={6} align="center">
                                        <CustomItemIcon isMaster={selectedBattery.securityId} icon={<BatteryIcon />} />

                                        {!editDescriptionsConfig[`${selectedBattery.id}`]?.isActive ? (
                                            <Heading px={2} as="h2" variant="h2">
                                                {values.battery.description}
                                            </Heading>
                                        ) : (
                                            <InputFormikField
                                                px={3}
                                                name="battery.description"
                                                type="text"
                                                flex="1"
                                                maxW="500px"
                                                validate={(value) =>
                                                    validateDescription(value, 'battery', selectedBattery.id)
                                                }
                                                onKeyDown={checkItemInputEvent}
                                                isFastField
                                            />
                                        )}

                                        <Box display="inline-block" mr={4}>
                                            <Tooltip
                                                label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}
                                            >
                                                <IconButton
                                                    aria-label={intl.formatMessage({
                                                        id: 'common_edit_btn_tooltip_and_label',
                                                    })}
                                                    variant="circular-icon"
                                                    leftIcon={<EditIcon />}
                                                    onClick={() => onEditDescription(selectedBattery.id)}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Flex>

                                    <ActionBar>
                                        <ShowHideSidebarButton />

                                        <SecondaryIconButton
                                            leftIcon={<FileIcon />}
                                            size="sm"
                                            variant="secondary"
                                            flexShrink={0}
                                            onClick={propertiesModal.onOpen}
                                        >
                                            <FormattedMessage id="portfolio_item_properties" />
                                        </SecondaryIconButton>

                                        <Spacer display={{ base: 'none', xl: 'block' }} />

                                        <Button
                                            leftIcon={<DeleteBatteryIcon />}
                                            type="button"
                                            variant="special"
                                            size="sm"
                                            onClick={deleteBatteryModal.onOpen}
                                        >
                                            <FormattedMessage id="common_delete_item_btn" />
                                        </Button>

                                        <Button
                                            leftIcon={<SaveIcon />}
                                            isDisabled={isSubmitDisabled({ errors, touched })}
                                            isLoading={isSubmitting}
                                            type="submit"
                                            size="sm"
                                            variant="primary-success"
                                        >
                                            <FormattedMessage id="common_save_changes_button" />
                                        </Button>
                                    </ActionBar>

                                    <Divider />
                                    <SimpleGrid pt={7} pb={0} px={6} columns={{ xl: 4 }} spacingX={6} spacingY={4}>
                                        <ComboboxFormikField
                                            id="battery.bookId"
                                            name="battery.bookId"
                                            label={formLabels.book}
                                            options={batteryDefinitions.definitions.books}
                                            valueKey="id"
                                            labelKey="name"
                                            isFastField
                                            isRequired
                                        />
                                        <InputFormikField
                                            id="battery.latitude"
                                            name="battery.latitude"
                                            label={formLabels.latitude}
                                            type="number"
                                            isFastField
                                        />
                                        <InputFormikField
                                            id="battery.longitude"
                                            name="battery.longitude"
                                            label={formLabels.longitude}
                                            type="number"
                                            isFastField
                                        />

                                        <CheckboxFormikField id="battery.securityId" name="battery.securityId" mt={5}>
                                            {formLabels.master}
                                        </CheckboxFormikField>
                                    </SimpleGrid>

                                    <SimpleGrid pt={7} pb={5} px={6} columns={{ xl: 2 }} spacingX={6} spacingY={4}>
                                        <ComboboxFormikField
                                            id="battery.pricingPointId"
                                            name="battery.pricingPointId"
                                            label={formLabels.pricingPoint}
                                            info={<FormattedMessage id="common_pricing_point_empty_tooltip" />}
                                            options={pricingPoints}
                                            labelKey="description"
                                            valueKey="id"
                                            showPlaceholder={true}
                                            placeholderValue={null}
                                            isFastField
                                        />
                                        <ComboboxFormikField
                                            id="battery.rtelecBasisPriceId"
                                            name="battery.rtelecBasisPriceId"
                                            label={formLabels.rtelecBasis}
                                            options={rtelecBasis}
                                            labelKey="description"
                                            valueKey="id"
                                            showPlaceholder={true}
                                            placeholderValue={null}
                                            isFastField
                                        />
                                    </SimpleGrid>

                                    <SubBatteriesSection
                                        itemTypeId={typeId || batteryItem.properties.typeId}
                                        definitions={initialFormValues.subBatteries}
                                        batteryDefinitions={batteryDefinitions}
                                        editDescriptionsConfig={editDescriptionsConfig}
                                        templatesConfig={templatesConfig}
                                        formValues={values}
                                        helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                                        validateDescription={validateDescription}
                                        onEditDescription={onEditDescription}
                                        onDelete={onDeleteModalOpen}
                                        onAdd={addSubBatteryModal.onOpen}
                                        onTemplatesConfigChange={onTemplatesConfigChange}
                                        onResourceVariablesDataChange={() =>
                                            handleDependentDataChange({
                                                setValues,
                                                setTouched,
                                                status,
                                                setStatus,
                                            })
                                        }
                                        onAutoformTableChange={onAutoformTableChange}
                                    />
                                </Form>
                            )}
                        </Formik>

                        {deleteModal.isOpen && (
                            <DeleteRecordModal
                                onClose={onDeleteModalClose}
                                headerItem="Installation"
                                contentMessageItem={subBatteryForDeletion.description}
                                onConfirm={() => {
                                    onSubBatteryDelete(subBatteryForDeletion.id);
                                }}
                            />
                        )}

                        {propertiesModal.isOpen && (
                            <ItemPropertiesModal
                                isOpen
                                onClose={propertiesModal.onClose}
                                itemId={selectedBattery.id}
                                portfolio={batteryItem}
                                onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                            />
                        )}

                        {addSubBatteryModal.isOpen && (
                            <AddSubBatteryModal
                                isOpen
                                headerItem="Installation"
                                batteryTypes={batteryDefinitions.definitions.batteryTypes}
                                batteryItemId={batteryId || batteryItem.properties.id}
                                updateBatteries={updateBatteries}
                                onClose={addSubBatteryModal.onClose}
                                scrollBehavior="inside"
                                size="5xl"
                            />
                        )}

                        {deleteBatteryModal.isOpen && (
                            <ConfirmationModal
                                isOpen
                                onClose={deleteBatteryModal.onClose}
                                header={<FormattedMessage id="common_delete_modal_heading" values={{ item: 'Item' }} />}
                                hasExtraStep
                                content={
                                    <FormattedMessage
                                        id="common_delete_modal_msg"
                                        values={{ item: selectedBattery.description }}
                                    />
                                }
                                confirmText={
                                    <Box as="span" textTransform="capitalize">
                                        <FormattedMessage id="common_delete" />
                                    </Box>
                                }
                                onConfirm={() => {
                                    deleteBatteryModal.onClose();
                                    onItemDelete(selectedBattery);
                                }}
                            />
                        )}
                    </>
                )}
            </ExpandableItemWrapper>
        </>
    );
};

export default Battery;
