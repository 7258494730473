import { FormattedMessage } from 'react-intl';
import { DELETE_CONFIRMATION_TYPE } from '../../constants/autoforms';
import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';

const AutoformDeleteConfirmationModal = ({ isOpen, onClose, rowDeleteConfirmation, onConfirm, isLoading, ...rest }) => {
    let modalHeader = null;
    let modalContent = null;

    if (rowDeleteConfirmation === DELETE_CONFIRMATION_TYPE.SingleGrid) {
        modalHeader = <FormattedMessage id={'common_delete_all_modal_heading'} />;
        modalContent = <FormattedMessage id={'common_delete_all_modal_description'} />;
    }

    if (rowDeleteConfirmation === DELETE_CONFIRMATION_TYPE.MultiGrid) {
        modalHeader = <FormattedMessage id={'common_delete_multi_grid'} />;
        modalContent = <FormattedMessage id={'delete_multi_grid_modal_description'} />;
    }

    if (rowDeleteConfirmation === DELETE_CONFIRMATION_TYPE.Rows) {
        modalHeader = <FormattedMessage id={'common_delete_rows'} />;
        modalContent = <FormattedMessage id={'common_delete_rows_modal_description'} />;
    }

    return (
        <DeleteConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onConfirm}
            isLoading={isLoading}
            header={modalHeader}
            content={modalContent}
        />
    );
};

export default AutoformDeleteConfirmationModal;
