import { FormattedMessage } from 'react-intl';
import { Heading, SimpleGrid } from '@chakra-ui/react';

import ComboboxFormikField from '../forms/ComboboxFormikField';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';
import { helpAnchors } from 'constants/help';

const LoadDefinitionPanel = ({ idx, definitions, formLabels, helpPageUrlItemTypeId = null }) => {
    // Formik considers numbers in fields as an array of objects
    const fieldNamePrefix = `definitions.${idx}`;

    return (
        <>
            <Heading as="h4" variant="h4">
                <FormattedMessage id="load_definitions_heading" />
                <HelpButtonComponent helpAnchor={helpAnchors.LOAD_DEFINITIONS} itemTypeId={helpPageUrlItemTypeId} />
            </Heading>

            <SimpleGrid columns={{ xl: 3 }} spacingX={6}>
                <ComboboxFormikField
                    mt={3}
                    id={`${fieldNamePrefix}.moduleProcessId`}
                    name={`${fieldNamePrefix}.moduleProcessId`}
                    label={formLabels.modelType}
                    options={definitions.modelTypes}
                    labelKey="labelName"
                    valueKey="id"
                    placeholderValue=""
                    placeholderLabel={formLabels.selectPlaceholder}
                    isFastField
                    validateOnBlur={false}
                    isRequired
                />

                <ComboboxFormikField
                    mt={3}
                    id={`${fieldNamePrefix}.weatherZoneId`}
                    name={`${fieldNamePrefix}.weatherZoneId`}
                    label={formLabels.weatherZone}
                    options={definitions.weatherZones}
                    valueKey="id"
                    labelKey="name"
                    placeholderValue=""
                    placeholderLabel={formLabels.selectPlaceholder}
                    isFastField
                    validateOnBlur={false}
                    isRequired
                />

                <ComboboxFormikField
                    mt={3}
                    id={`${fieldNamePrefix}.modelWeightId`}
                    name={`${fieldNamePrefix}.modelWeightId`}
                    label={formLabels.dataWeight}
                    options={definitions.dataWeightings}
                    valueKey="id"
                    labelKey="description"
                    placeholderValue=""
                    placeholderLabel={formLabels.selectPlaceholder}
                    isFastField
                    validateOnBlur={false}
                    isRequired
                />

                <ComboboxFormikField
                    mt={3}
                    id={`${fieldNamePrefix}.retailPriceItemId`}
                    name={`${fieldNamePrefix}.retailPriceItemId`}
                    label={formLabels.retailPriceItem}
                    options={definitions.retails}
                    valueKey="id"
                    labelKey="description"
                    placeholderValue=""
                    placeholderLabel={formLabels.selectPlaceholder}
                    isFastField
                    validateOnBlur={false}
                />

                <ComboboxFormikField
                    mt={3}
                    id={`${fieldNamePrefix}.nativeLoad`}
                    name={`${fieldNamePrefix}.nativeLoad`}
                    label={formLabels.native}
                    options={definitions.nativeOptions}
                    valueKey="id"
                    labelKey="description"
                    isFastField
                    validateOnBlur={false}
                />

                {Array.isArray(definitions?.plantTypes) && (
                    <ComboboxFormikField
                        mt={3}
                        id={`${fieldNamePrefix}.plantTypeId`}
                        name={`${fieldNamePrefix}.plantTypeId`}
                        label={formLabels.plantType}
                        options={definitions?.plantTypes}
                        valueKey="id"
                        labelKey="description"
                        placeholderLabel="-"
                        placeholderValue={null}
                        showPlaceholder={true}
                        isFastField
                        validateOnBlur={false}
                    />
                )}
            </SimpleGrid>
        </>
    );
};

export default LoadDefinitionPanel;
