import Input from './Input';

const Select = {
    variants: Input.variants,

    defaultProps: {
        variant: 'filled',
    },
};

export default Select;
