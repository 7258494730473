import { useMemo, useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';
import {
    useBoolean,
    Popover as ChakraPopover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
} from '@chakra-ui/react';

import CustomIconButton from '../utils/CustomIconButton';
import DataGridIssueMessage from './DataGridIssueMessage';

import { getIssuesMap } from '../../services/grid';

import { ReactComponent as WarningIcon } from '../../icons/warning.svg';

const DataGridInternalIssuesDialog = ({ rowIndex, context, columnApi, data, ...props }) => {
    const ref = useRef();
    const [isOpen, setIsOpen] = useBoolean();

    const getColumnName = useCallback(
        (field) => {
            const col = columnApi.getColumn(field);

            return columnApi.getDisplayNameForColumn(col);
        },
        [columnApi]
    );

    useEffect(() => {
        ref.current = props.eGridCell.closest('.ag-center-cols-viewport');
    }, [props.eGridCell]);

    const key = context.primaryKeyName ? data[context.primaryKeyName] : rowIndex;
    const rowIssues = context.issues[key];

    const isWarningIcon = useMemo(() => {
        // shows a red icon when there is any error in the field or general validations and a yellow icon if there are no errors
        return (
            !!rowIssues &&
            (rowIssues.generalValidationMessages.some((validation) => validation.severity === 'Error') ||
                rowIssues.fields.some((field) =>
                    field.validationMessages.some((validation) => validation.severity === 'Error')
                ))
        );
    }, [rowIssues]);

    const issuesMap = useMemo(() => {
        if (rowIssues) {
            const { warnings, errors, errorFields, warningFields, generalErrors, generalWarnings } = getIssuesMap([
                rowIssues,
            ])[key];

            const fieldErrors = errorFields.map((fieldName, index) => `${getColumnName(fieldName)} - ${errors[index]}`);

            const fieldWarnings = warningFields.map(
                (fieldName, index) => `${getColumnName(fieldName)} - ${warnings[index]}`
            );

            return {
                combinedErrors: generalErrors.concat(fieldErrors),
                combinedWarnings: generalWarnings.concat(fieldWarnings),
            };
        }
    }, [rowIssues, key, getColumnName]);

    if (!rowIssues) {
        return null;
    }

    return (
        <ChakraPopover
            strategy="fixed"
            placement="bottom-end"
            isOpen={isOpen}
            onOpen={setIsOpen.on}
            onClose={setIsOpen.off}
        >
            <PopoverTrigger>
                <StyledCustomIconButton icon={<WarningIcon />} isWarning={!isWarningIcon} />
            </PopoverTrigger>

            <Portal containerRef={ref}>
                <PopoverContent>
                    <PopoverBody bg="gray.100" p={3} textAlign="left" shadow="xs" maxH="170px" overflowY="auto">
                        {issuesMap.combinedErrors.map((message, index) => {
                            return <DataGridIssueMessage key={index} severity="Error" message={message} />;
                        })}

                        {issuesMap.combinedWarnings.map((message, index) => {
                            return <DataGridIssueMessage key={index} severity="Warning" message={message} />;
                        })}
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </ChakraPopover>
    );
};

const StyledCustomIconButton = styled(CustomIconButton, { shouldForwardProp: (prop) => prop !== 'isWarning' })`
    margin-top: 0px;
    margin-left: 4px;
    height: 20px;
    width: 20px;

    svg {
        padding: 0.5px;
    }

    svg path {
        stroke: var(--chakra-colors-transparent);
        fill: ${(props) => (props.isWarning ? 'var(--chakra-colors-yellow-500)' : 'var(--chakra-colors-red-1000)')};
    }
`;

export default DataGridInternalIssuesDialog;
