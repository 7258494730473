import Level4Autoform from './Level4Autoform';

/**
 * DEPRECATED: Level4Autoform components implements logic for levels 3 and 4
 * Code is commented out in case it's needed in the future.
 */
const Level3Autoform = ({
    config = {},
    onNodeRename,
    onNodeItemDelete,
    portfolioItem,
    onNodeOwnershipPercentageUpdate,
    helpPageUrlItemTypeId = null,
}) => {
    return (
        <Level4Autoform
            config={config}
            portfolioItem={portfolioItem}
            onNodeRename={onNodeRename}
            onNodeItemDelete={onNodeItemDelete}
            onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
            helpPageUrlItemTypeId={helpPageUrlItemTypeId}
        />
    );
    /* const { metadata, hierarchicalTables } = useAutoformMetadata();

    const { tablesWithChildrenMap, tablesWithoutChildrenList } = useAutoformTableHierarchy(
        hierarchicalTables.level2,
        hierarchicalTables.level3
    );

    if (
        !metadata ||
        hierarchicalTables.level1.length === 0 ||
        hierarchicalTables.level2.length === 0 ||
        hierarchicalTables.level3.length === 0
    ) {
        return <MisconfigurationError />;
    }
    const level1TableId = hierarchicalTables.level1[0].id;

    const renderAutoformTable = (table, parentTable) => (
        <AutoformTableContainer
            schemaCode={metadata.schemaCode}
            tableId={table.id}
            showDownloadUploadButton={tablesWithChildrenMap[parentTable.id]?.length === 1}
        />
    );

    const renderSubItemList = (level2Table) => (
        <SubItemListContainer
            tableId={level2Table.id}
            schemaCode={metadata.schemaCode}
            renderSubItem={(item) => {
                return (
                    <>
                        <GeneralValidationPanelContainer tableId={level2Table.id} />
                        <AutoformItemFields tableId={level2Table.id} getInputFormName={getInputFormName} />

                        {!!tablesWithChildrenMap[level2Table.id]?.length && (
                            <AutoformTabs
                                renderTabPanel={(table) => renderAutoformTable(table, level2Table)}
                                mt={6}
                                tables={tablesWithChildrenMap[level2Table.id]}
                                tabListButtons={
                                    <Flex ml="auto" order={{ base: -1, xl: 0 }} w={{ base: '100%', xl: 'auto' }}>
                                        <AutoformDownloadButton
                                            tablesMetadata={tablesWithChildrenMap[level2Table.id]}
                                            parentTableId={level2Table.id}
                                            mr={2}
                                        />
                                        <AutoformUploadButton
                                            tablesMetadata={tablesWithChildrenMap[level2Table.id]}
                                            parentTableId={level2Table.id}
                                        />
                                    </Flex>
                                }
                            />
                        )}
                    </>
                );
            }}
        />
    );

    return (
        <ExpandableItemWrapper>
            <ItemFormContainer includeLevel2Values={true} onNodeRename={onNodeRename}>
                <ItemTopHeaderContainer
                    tableId={level1TableId}
                    onNodeItemDelete={onNodeItemDelete}
                    portfolioItem={portfolioItem}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                />

                <Box px={6}>
                    <GeneralValidationPanelContainer tableId={level1TableId} />
                    <AutoformItemFields tableId={level1TableId} getInputFormName={getInputFormName} />
                </Box>

                <AutoformTabs
                    renderTabPanel={(level2Table) =>
                        tablesWithChildrenMap[level2Table.id]?.length > 0 ? (
                            renderSubItemList(level2Table)
                        ) : (
                            <Box px={6}>{renderAutoformTable(level2Table)}</Box>
                        )
                    }
                    tabListPx={6}
                    tables={hierarchicalTables.level2}
                    tabListButtons={
                        tablesWithoutChildrenList?.length > 0 ? (
                            <Flex ml="auto" order={{ base: -1, xl: 0 }} w={{ base: '100%', xl: 'auto' }}>
                                <AutoformDownloadButton
                                    tablesMetadata={tablesWithoutChildrenList}
                                    parentTableId={level1TableId}
                                    mr={2}
                                    showFullTableList={true}
                                />
                                <AutoformUploadButton
                                    tablesMetadata={tablesWithoutChildrenList}
                                    parentTableId={level1TableId}
                                />
                            </Flex>
                        ) : null
                    }
                />
            </ItemFormContainer>
        </ExpandableItemWrapper>
    ); */
};

export default Level3Autoform;
