const Drawer = {
    parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],

    baseStyle: {
        dialog: {
            background: 'white',

            _dark: {
                background: 'black',
            },
        },

        closeButton: {
            color: 'black',

            _dark: {
                color: 'gray.600',
            },
        },
    },

    variants: {
        mapDrawer: {
            dialog: {
                pointerEvents: 'auto',
            },

            dialogContainer: {
                pointerEvents: 'none',
            },
        },
        help: {
            dialog: {
                maxW: '1600px',
            },
        },
    },

    sizes: {
        map_md: {
            dialog: {
                maxW: '550px',
            },
        },
    },
};

export default Drawer;
