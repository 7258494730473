import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useAutoformMetadata from './useAutoformMetadata';
import useAutoformTableHierarchy from './useAutoformTableHierarchy';
import {
    getPrimaryKeyColName,
    getDataValueObjectFromDto,
    getFormTableIds,
    getInputFormName,
    getValidationConfigForField,
} from '../utils/autoformUtils';

/**
 * Generate validation config that will be used for client-side validations. This config
 * returns a friendly object containing all validations set in the metadata.
 *
 * Prerequisites: Autoform Metadata should be loaded into the redux store (useLoadAutoformMetadata).
 * @param {number} selectedLevel1Item - The selected item from the level 1 autoform table for which
 * we want to send values to the server.
 * @param {boolean} includeLevel2Values - Should initial values for level 2 tables be included. In cases
 * where the autoform max table hierarchy level is > 2, we need this to be true.
 * @returns {object} The initial values to be used by Formik directly. Has format:
 * {
 *    table123: {
 *       item123: {
 *          [COLUMN_NAME]: {
 *              isRequired: boolean
 *              min: number
 *              max: number
 *          }
 *       }
 *    }
 * }
 */
const useAutoformFormValidationConfig = ({ includeLevel2Values, selectedLevel1Item, extraValidationConfig = [] }) => {
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const { tablesWithChildrenMap } = useAutoformTableHierarchy(hierarchicalTables.level2, hierarchicalTables.level3);
    const tableData = useSelector((state) => state.autoform.tables);

    const validationSettings = useMemo(() => {
        if (metadata) {
            const tableIds = getFormTableIds(hierarchicalTables, includeLevel2Values, tablesWithChildrenMap);

            const settings = tableIds.reduce((acc, tableId) => {
                const tableMetadata = metadata.tables.find((t) => t.id === tableId);
                const data = tableData[tableId];
                if (!tableMetadata || !data?.data || !Array.isArray(data.data)) {
                    return acc;
                }

                const primaryColName = getPrimaryKeyColName(tableMetadata.columns);

                data.data?.forEach((dataRow) => {
                    const parsedRow = getDataValueObjectFromDto(dataRow, tableMetadata);
                    const itemId = dataRow[primaryColName];

                    if (hierarchicalTables?.level1[0]?.id === tableId && itemId !== selectedLevel1Item) {
                        return;
                    }
                    Object.keys(parsedRow).forEach((columnName) => {
                        acc.push(
                            getValidationConfigForField(
                                columnName,
                                tableMetadata,
                                getInputFormName(tableId, itemId, columnName)
                            )
                        );
                    }, {});
                });

                return acc;
            }, []);

            return [...settings, ...extraValidationConfig];
        }
        return [];
    }, [
        metadata,
        selectedLevel1Item,
        includeLevel2Values,
        hierarchicalTables,
        tableData,
        tablesWithChildrenMap,
        extraValidationConfig,
    ]);

    return validationSettings;
};

export default useAutoformFormValidationConfig;
