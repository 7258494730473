import axios from 'axios';
import { isNull } from 'lodash';
import { msalInstance } from '../auth';
import { getItem, setItem } from '../storage';
import { parseISO } from 'date-fns';
import {
    convertRelativeToUTCDateMidnight,
    convertRelativeToUTCDateMidnightExtraDayMinusOneHour,
} from '../../services/utils';

export const POWER_COMMODITY_DESCRIPTION = 'POWER';

export const GRID_KEYS = {
    basisScalar: 'basisScalar',
    basisDartSpread: 'basisDartSpread',
    basisConfiguratorDartSpread: 'basisConfiguratorDartSpread',
    basisConfiguratorScaler: 'basisConfiguratorScaler',
};

export const getBasisList = async () => {
    const response = await axios.get(`/basis`);
    return response.data;
};

export const createBasis = async (payload) => {
    const response = await axios.post(`/basis/create`, payload);
    return response.data;
};

export const copyBasis = async (itemId) => {
    const response = await axios.post(`/basis/${itemId}/copy`);
    return response.data;
};

export const getBasisById = async (itemId) => {
    const response = await axios.get(`/basis/${itemId}`);
    return response.data;
};

export const editBasis = (itemId, payload) => axios.put(`/basis/${itemId}/update`, payload);

export const deleteBasis = (itemId) => axios.delete(`/basis/${itemId}`);

export const getBasisDropdownOptions = async () => {
    const response = await axios.get(`/basis/dropdown-options`);
    return response.data;
};

export const getBasisScalar = async (itemId, payload) => {
    const response = await axios.post(`/basis/${itemId}/scalar`, payload);
    return response.data;
};

export const modifyScalar = (payload) => axios.put(`/basis/modify-scalar`, payload);

export const deleteAllScalarData = (itemId) => axios.delete(`/basis/${itemId}/scalar/delete-all`);

export const deleteScalerRows = (itemId, rowIds) => axios.delete(`/basis/${itemId}/scalar/delete`, { data: rowIds });

export const multisortPairs = {
    peakPeriodId: 'peakPeriodDescription',
    peakPeriodLabelId: 'peakPeriodDescription',
    statUnitId: 'statUnitDescription',
};

export const addScalarRows = async (itemId, payload) => {
    const response = await axios.post(`/basis/${itemId}/scalar/add`, payload);
    return response.data;
};

export const getBasisDartSpread = async (itemId, payload) => {
    const response = await axios.post(`/basis/${itemId}/dart-spreads`, payload);
    return response.data;
};

export const modifyDartSpread = (payload) => axios.put(`/basis/modify-dart-spread`, payload);

export const deleteAllDartSpreadData = (itemId) => axios.delete(`/basis/${itemId}/dart-spread/delete-all`);

export const deleteDartSpreadRows = (itemId, rowIds) =>
    axios.delete(`/basis/${itemId}/dart-spread/delete`, { data: rowIds });

export const addDartSpreadRows = async (itemId, payload) => {
    const response = await axios.post(`/basis/${itemId}/create-dart-spreads`, payload);
    return response.data;
};

export const setBasisFiltersConfig = (config, item) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length) {
        const itemFilters = `${item}Filters`;
        const filters = getItem(itemFilters) || {};

        setItem(itemFilters, {
            ...filters,
            [accounts[0].localAccountId]: config,
        });
    }
};

export const getBasisFiltersConfig = (item) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length) {
        const accountId = accounts[0].localAccountId;
        const filters = getItem(`${item}Filters`);

        if (filters && filters[accountId]) {
            let { startDateFrom, startDateTo, ...rest } = filters[accountId];

            startDateFrom = startDateFrom && parseISO(startDateFrom);
            startDateTo = startDateTo && parseISO(startDateTo);

            return {
                startDateFrom,
                startDateTo,
                ...rest,
            };
        }
    }

    return null;
};

export const systemDefaultBasisFilter = {
    startDateFrom: new Date(2015, 0, 1),
    startDateTo: new Date(2050, 11, 31),
    daysFrom: -21,
    daysTo: 0,
    toggleDaysFrom: false,
    toggleDaysTo: false,
};

export const getBasisDataFilters = (namespacePrefix) => {
    const userPreferences = getBasisFiltersConfig(namespacePrefix);

    if (userPreferences) {
        const filters = getBasisFiltersConfig(namespacePrefix);

        return parseBasisDataFilters(filters);
    } else {
        return {
            startDateFrom: systemDefaultBasisFilter.startDateFrom,
            startDateTo: systemDefaultBasisFilter.startDateTo,
        };
    }
};

export const parseBasisDataFilters = (filters) => {
    const relativeStartDateFrom = convertRelativeToUTCDateMidnight(filters.daysFrom);
    // Per business request include extra day
    const relativeStartDateTo = convertRelativeToUTCDateMidnightExtraDayMinusOneHour(filters.daysTo);

    return {
        // @todo add guards against partially set filters like this { toggleDaysFrom: true, daysFrom: undefined }
        startDateFrom: filters.toggleDaysFrom ? relativeStartDateFrom : filters.startDateFrom,
        startDateTo: filters.toggleDaysTo ? relativeStartDateTo : filters.startDateTo,
    };
};

export const isFilterApplied = (namespacePrefix) => !isNull(getBasisFiltersConfig(namespacePrefix));

export const hasAppliedBasisFilters = (filters) => {
    return (
        filters.startDateFrom.getTime() !== systemDefaultBasisFilter.startDateFrom.getTime() ||
        filters.startDateTo.getTime() !== systemDefaultBasisFilter.startDateTo.getTime()
    );
};

export const getBasisBasicModelsDropdownOptions = async () => {
    const response = await axios.get('/basis/basic-models');
    return response.data;
};

export const createBasisBasicModel = (payload) => axios.post('/basis/basic-models', payload);

export const editBasisBasicModel = (id, payload) => axios.put(`/basis/${id}/basic-models`, payload);

export const getBasisBasicModelById = async (basicModelId) => {
    const response = await axios.get(`/basis/${basicModelId}/basic-models`);
    return response.data;
};

export const mapCommoditiesByTypeAndDescription = (commodities) => {
    return commodities.flatMap((commodityType) =>
        commodityType.commodities.map((commodity) => ({
            ...commodity,
            description: `${commodityType.description}:${commodity.description}`,
        }))
    );
};

export const validateBasisCreationData = (basisData, autoCreate, isValidMainMarketModel, daOptionError) => {
    const {
        selectedCommodityType,
        basisName,
        selectedMainMarketModel,
        selectedIsoRto,
        selectedElectricNode,
        selectedDartMarkets,
    } = basisData;

    if (
        selectedCommodityType &&
        (selectedCommodityType.toUpperCase() !== POWER_COMMODITY_DESCRIPTION ||
            (selectedCommodityType.toUpperCase() === POWER_COMMODITY_DESCRIPTION && !autoCreate)) &&
        basisName?.length > 0
    ) {
        return true;
    }

    if (
        selectedCommodityType?.toUpperCase() === POWER_COMMODITY_DESCRIPTION &&
        autoCreate &&
        selectedMainMarketModel &&
        isValidMainMarketModel &&
        selectedIsoRto &&
        selectedElectricNode &&
        (selectedDartMarkets.da || selectedDartMarkets.rt5mm || selectedDartMarkets.rt15mm) &&
        !daOptionError
    ) {
        return true;
    }

    return false;
};

export const autoCreateBasis = async (payload) => axios.post('/basis/autocreate', payload);

export const autoCreateExistingBases = async (payload) => axios.post('/basis/autocreate/existingbases', payload);

export const validateBasisDaOption = (payload) => axios.post('/basis/validate-da-basis-exists', payload);

export const validateScalar = async (payload) => {
    const response = await axios.post('basis/validate/scaler', payload);
    return response.data;
};
