import { useState, useEffect, useMemo, useCallback } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Skeleton, Text, Box, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { sortItemsByDescription } from '../services/items';
import { getBiddingStrategies } from '../services/biddingStrategies';

import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as BatteryIcon } from '../icons/item-icons/battery-secondary.svg';

import biddingStrategyPlaceholder from '../images/bidding-strategy-unselected.png';

import LayoutSidebar from '../components/layout/LayoutSidebar';
import LayoutWrapper from '../components/layout/LayoutWrapper';
import WrapperEmptyState from '../components/layout/WrapperEmptyState';
import ItemListWrapper from '../components/layout/ItemListWrapper';

import OptimizedItemLinkWrapper from '../components/utils/OptimizedItemLinkWrapper';
import VirtualisedItemList from '../components/utils/VirtualisedItemList';
import AddItemButton from '../components/utils/AddItemButton';

import AddStrategyModal from '../components/biddingStrategy/AddStrategyModal';
import BiddingStrategy from '../components/biddingStrategy/BiddingStrategy';

export const BiddingStrategiesParam = 'STRATEGIES';
const BiddingAutoformParamPrefix = 'PSBATADMIN';

const BiddingStrategies = () => {
    const intl = useIntl();
    const {
        path,
        url,
        params: { autoformId },
    } = useRouteMatch();

    const addStrategyModal = useDisclosure();

    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [strategies, setStrategies] = useState([]);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState(null);

    const { isSidebarVisible } = useSelector((state) => state.helpers);

    useEffect(() => {
        if (autoformId?.toUpperCase().startsWith(BiddingAutoformParamPrefix)) return;

        getBiddingStrategies()
            .then((data) => setStrategies(data.biddingStrategies))
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [autoformId]);

    useEffect(() => {
        const term = search.trimStart().toLowerCase();
        const filteredData = strategies.filter((o) => o.description.toLowerCase().includes(term));

        setSearchResults(filteredData);
    }, [strategies, search]);

    const itemIndex = useMemo(
        () => searchResults.findIndex((item) => item.id === selected?.id),
        [searchResults, selected?.id]
    );

    const noSearchResults = useMemo(() => {
        return search.trim().length > 0 && searchResults.length === 0;
    }, [search, searchResults]);

    const onStrategyEdit = (selectedStrategy) => {
        const updatedStrategies = strategies.map((strategy) => {
            if (strategy.id === selectedStrategy.id) {
                return { ...selectedStrategy };
            }
            return strategy;
        });

        setStrategies(updatedStrategies);
    };

    const onStrategyDelete = (selectedStrategy) => {
        const updatedStrategies = strategies.filter((strategy) => strategy.id !== selectedStrategy.id);
        setStrategies(updatedStrategies);
    };

    const onStrategySelect = useCallback((strategy) => {
        if (strategy) {
            setSelected(strategy);
        }
    }, []);

    const onSearchChange = (value) => {
        setSearch(value);
    };

    const updateStrategies = (newStrategy) => {
        setStrategies(sortItemsByDescription([...strategies, newStrategy]));
        setSelected(newStrategy);
    };

    return (
        <LayoutWrapper
            hasSecondaryMenu={true}
            sidebar={
                isSidebarVisible && (
                    <LayoutSidebar
                        selected={selected}
                        titleMessage={intl.formatMessage({ id: 'bidding_strategies_title' })}
                        accordionPlaceholderId="common_select" // not used
                        searchPlaceholderId="bidding_strategies_search"
                        searchCallback={onSearchChange}
                        searchTerm={search}
                    >
                        <ItemListWrapper>
                            <Box px={6} pb={2}>
                                <AddItemButton leftIcon={<AddIcon />} onClick={addStrategyModal.onOpen}>
                                    <Text size="sm" color="gray.900" pl={1} pt={1}>
                                        <FormattedMessage id="bidding_strategies_add_new" />
                                    </Text>
                                </AddItemButton>

                                {isLoading ? (
                                    <Skeleton>
                                        <Box h="34px">
                                            <FormattedMessage id="common_loading" />
                                        </Box>
                                    </Skeleton>
                                ) : loadingError ? (
                                    <Alert status="error">
                                        <AlertIcon />

                                        <Box>
                                            <AlertTitle textTransform="capitalize">
                                                <FormattedMessage id="common_error" />
                                            </AlertTitle>

                                            <AlertDescription>
                                                <FormattedMessage
                                                    id="common_loading_error"
                                                    values={{ items: 'bidding strategies' }}
                                                />
                                            </AlertDescription>
                                        </Box>
                                    </Alert>
                                ) : noSearchResults ? (
                                    <Alert status="info">
                                        <AlertIcon />

                                        <FormattedMessage id="common_no_search_results" />
                                    </Alert>
                                ) : null}
                            </Box>

                            {!noSearchResults && !loadingError && !isLoading && (
                                <VirtualisedItemList
                                    items={searchResults}
                                    itemIndex={itemIndex}
                                    shouldShowIcons={false}
                                    renderItems={(strategy) => (
                                        <OptimizedItemLinkWrapper
                                            url={url}
                                            icon={BatteryIcon}
                                            onClick={onStrategySelect}
                                            item={strategy}
                                        />
                                    )}
                                />
                            )}
                        </ItemListWrapper>
                    </LayoutSidebar>
                )
            }
            content={
                <Switch>
                    <Route exact path={path}>
                        <WrapperEmptyState
                            imgSrc={biddingStrategyPlaceholder}
                            page="Bidding Strategy"
                            noun="properties"
                        />
                    </Route>

                    <Route path={`${path}/:strategyId`}>
                        {autoformId?.toUpperCase().startsWith(BiddingAutoformParamPrefix) ? (
                            <WrapperEmptyState
                                imgSrc={biddingStrategyPlaceholder}
                                page="Bidding Strategy"
                                noun="properties"
                            />
                        ) : (
                            <BiddingStrategy onEdit={onStrategyEdit} onDelete={onStrategyDelete} />
                        )}
                    </Route>
                </Switch>
            }
        >
            {addStrategyModal.isOpen && (
                <AddStrategyModal isOpen updateList={updateStrategies} onClose={addStrategyModal.onClose} />
            )}
        </LayoutWrapper>
    );
};

export default BiddingStrategies;
