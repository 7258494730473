import {
    Box,
    Flex,
    Text,
    Divider,
    Heading,
    MenuItem,
    SimpleGrid,
    useMediaQuery,
    FormErrorMessage,
    FormControl,
    IconButton,
} from '@chakra-ui/react';
import { useField } from 'formik';

import styled from '@emotion/styled/macro';

import { FormattedMessage, useIntl } from 'react-intl';

import Tooltip from '../utils/Tooltip';
import AccordionButton from '../utils/AccordionButton';

import InputFormikField from '../forms/InputFormikField';
import FormServerErrorMessage from '../forms/FormServerErrorMessage';
import FormWarningMessage from '../forms/FormWarningMessage';

import SubItemActionsMenu from '../itemData/SubItemActionsMenu';

import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as AddItemIcon } from '../../icons/item-icons/add-item.svg';
import InfoPopover from 'components/forms/InfoPopover';

/**
 * Presentational component for the Accordion header.
 */
const SubItemHeader = ({
    actionBtn,
    actionBtnMobile,
    isValidated,
    isEditVisible,
    inputName,
    info,
    subItemHeading,
    itemDescription,
    onAddSubItemClick,
    isAddDisabled,
    isEditMode,
    status,
    onEditClick,
}) => {
    const intl = useIntl();
    const [isDesktop] = useMediaQuery('(min-width: 992px)');
    const [, { error }] = useField(inputName);

    return (
        <>
            <SimpleGrid
                columns={2}
                mt="-1px"
                bg="background-octenary"
                borderTop="1px solid"
                borderBottom="1px solid"
                borderColor="border-secondary"
            >
                <Flex align="center" ml={4} my={3}>
                    <AccordionButton />

                    <Box>
                        <Text size="xs" mb="2px">
                            {subItemHeading}
                        </Text>

                        {!isEditMode ? (
                            <>
                                <Heading as="h4" variant="h4">
                                    {itemDescription}
                                </Heading>
                                <FormControl isInvalid={!!error}>
                                    <FormErrorMessage>{error}</FormErrorMessage>
                                    {status?.errors?.map((err) => (
                                        <FormServerErrorMessage key={err}>{err}</FormServerErrorMessage>
                                    ))}
                                    {status?.warnings?.map((warning) => (
                                        <FormWarningMessage key={warning}>{warning}</FormWarningMessage>
                                    ))}
                                </FormControl>
                            </>
                        ) : (
                            <InputFormikField name={inputName} validate={isValidated} type="text" isFastField />
                        )}
                    </Box>

                    {isEditVisible && (
                        <Flex align="center" mx={3}>
                            <Tooltip label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}>
                                <IconButton
                                    minH="40px"
                                    variant="circular-icon"
                                    aria-label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}
                                    leftIcon={<EditIcon />}
                                    onClick={() => onEditClick(!isEditMode)}
                                />
                            </Tooltip>
                        </Flex>
                    )}

                    {info && <InfoPopover info={info} />}
                </Flex>

                <Flex justify="flex-end" align="center" pr={6}>
                    {!isDesktop ? (
                        <SubItemActionsMenu
                            menuListItems={
                                <>
                                    {actionBtnMobile}

                                    <MenuItem
                                        key="create-btn"
                                        textTransform="capitalize"
                                        onClick={onAddSubItemClick}
                                        isDisabled={isAddDisabled}
                                    >
                                        <FormattedMessage id="common_add" />
                                    </MenuItem>
                                </>
                            }
                        />
                    ) : (
                        <>
                            {actionBtn}

                            <Divider orientation="vertical" mx={6} />

                            <Box display="inline-block">
                                <Tooltip label={intl.formatMessage({ id: 'common_add_new' })}>
                                    <StyledIconButton
                                        isDisabled={isAddDisabled}
                                        aria-label={intl.formatMessage({ id: 'common_add_new' })}
                                        icon={<AddItemIcon />}
                                        variant="circular-icon"
                                        onClick={onAddSubItemClick}
                                    />
                                </Tooltip>
                            </Box>
                        </>
                    )}
                </Flex>
            </SimpleGrid>
        </>
    );
};

const StyledIconButton = styled(IconButton)`
    &:disabled {
        opacity: 0.6;

        &:hover svg path,
        svg path {
            fill: var(--chakra-colors-gray-600);
        }
    }
`;

export default SubItemHeader;
