import { AlertIcon, Box, AlertDescription, UnorderedList, ListItem, Alert } from '@chakra-ui/react';

/**
 * Panel to display general validation messages.
 *
 * @param {string[]} errors - List of errors.
 * @param {string[]} warnings - List of warnings.
 *
 */
const GeneralValidationPanel = ({ errors, warnings }) => (
    <>
        {!!errors.length && (
            <Alert status="error" alignItems="flex-start" py={5} mb={warnings.length ? 6 : 0}>
                <AlertIcon />
                <Box flex="1">
                    <AlertDescription display="block">
                        <UnorderedList>
                            {errors.map((error) => (
                                <ListItem>{error}</ListItem>
                            ))}
                        </UnorderedList>
                    </AlertDescription>
                </Box>
            </Alert>
        )}
        {!!warnings.length && (
            <Alert status="warning" alignItems="flex-start" py={5}>
                <AlertIcon />
                <Box flex="1">
                    <AlertDescription display="block">
                        <UnorderedList>
                            {warnings.map((error) => (
                                <ListItem>{error}</ListItem>
                            ))}
                        </UnorderedList>
                    </AlertDescription>
                </Box>
            </Alert>
        )}
    </>
);

export default GeneralValidationPanel;
