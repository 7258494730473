import { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertIcon, Box } from '@chakra-ui/react';

import {
    formatTimelineData,
    getTimelineRibbonData,
    getBatteryRibbonParameters,
    getBatteryFilters,
} from '../../services/batteries';
import { setFiltersConfig } from '../../services/items';

import RibbonFiltersBar from './ribbon/RibbonFiltersBar';
import RibbonFiltersSkeleton from './ribbon/RibbonFiltersSkeleton';
import RibbonSkeleton from './ribbon/RibbonSkeleton';
import TimeRibbon from './ribbon/TimeRibbon';
import { FEATURE_FLAG_NAMES } from '../../constants/featureFlags';
import { useIsFeatureActive } from '../../hooks/features-flags';

const timelineParamsLoadingKey = 'timeline-parameters';
const timelineDataLoadingKey = 'timeline-data';

const BatteryTimeline = ({
    loadingQueue,
    onLoadingStart,
    onLoadingEnd,
    subBatteryId,
    ancillaries,
    peakPeriods,
    onDataChange,
}) => {
    // 1. Battery Forced Outages is an autoform. If PSBATSTO autoform Forced Outages table PERCENTOUTAGE column isReadonly property checked
    // then this field will be disabled and defaulted to 100 in Tabular view Forced Outages tab autoform
    // 2. Battery Scheduled Outages tab and parameters percent outage field editable property governed by feature flag
    const isPercentOutageReadOnly = !useIsFeatureActive(FEATURE_FLAG_NAMES.BATTERY_PERCENTOUTAGE_FIELD_EDITABLE);

    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [info, setInfo] = useState(false);

    // RibbonFiltersBar state
    const [parameters, setParameters] = useState([]);
    const [selectedView, setSelectedView] = useState(2);
    const [readonlyFields, setReadonlyFields] = useState([]);

    // TimeRibbon state
    const [focusCellId, setFocusCellId] = useState(null);
    const [timelineData, setTimelineData] = useState([]);
    const [timelineMetadata, setTimelineMetadata] = useState(null);

    const fetchRibbonParameters = useCallback(async () => {
        onLoadingStart(timelineParamsLoadingKey);

        try {
            const response = await getBatteryRibbonParameters(subBatteryId);

            const batteryParameters = response?.parameters;
            const batteryReadonlyFields = isPercentOutageReadOnly ? ['PERCENTOUTAGE'] : [];
            let params = [];
            const config = getBatteryFilters('timeline');

            // merge response with the local storage filters if they already exist to get the accurate data
            if (config && config.length > 0) {
                params = batteryParameters?.map((item) => {
                    const filterItem = config.find((filter) => filter.dbFieldName === item.dbFieldName);
                    return {
                        ...item,
                        isSelected: filterItem ? filterItem.isSelected : false,
                    };
                });
            } else {
                params = batteryParameters?.map((p) => ({
                    ...p,
                    isSelected: p.disabled,
                }));
            }

            setParameters(params);
            setFiltersConfig(params, 'timeline');
            setReadonlyFields(batteryReadonlyFields);

            return params;
        } catch (err) {
            setHasError(true);
        } finally {
            onLoadingEnd(timelineParamsLoadingKey);
        }
    }, [subBatteryId, onLoadingStart, onLoadingEnd, isPercentOutageReadOnly]);

    // we're using the function params in place of local state to avoid dealing with the async nature of React's setState
    // other functions that would like to re-fetch the ribbon data should pass `parameters` and `ribbonParameters` from useState
    const fetchRibbonData = useCallback(
        async (params) => {
            onLoadingStart(timelineDataLoadingKey);

            try {
                const requestFilters = params;
                const selectedParameters = requestFilters.filter((p) => p.isSelected).map((p) => p.value);

                const { data } = await getTimelineRibbonData(subBatteryId, selectedParameters);

                // isDataValid is a flag returned from BE to indicate if periods data is valid
                // when true, we'll go through mapping and check each period object
                if (data.parameters.length > 0 && data.metadata.isDataValid) {
                    const parametersData = formatTimelineData(params, data.parameters);

                    setTimelineData(parametersData);
                    setTimelineMetadata(data.metadata);
                } else if (data.parameters.length === 0 && !data.metadata.isDataValid) {
                    setInfo(true);
                }
            } catch {
                setHasError(true);
            } finally {
                onLoadingEnd(timelineDataLoadingKey);
            }
        },
        [onLoadingStart, subBatteryId, onLoadingEnd]
    );

    const fetchData = useCallback(() => {
        setIsInitialLoading(true);
        setInfo(false);

        fetchRibbonParameters()
            .then((params) => {
                fetchRibbonData(params);
            })
            .finally(() => setIsInitialLoading(false));
    }, [fetchRibbonData, fetchRibbonParameters]);

    useEffect(() => {
        fetchData();
    }, [subBatteryId, fetchData]);

    const onFiltersChange = (selected) => {
        setParameters((prev) => {
            return prev.map((p) => ({ ...p, isSelected: selected.some((s) => s.dbFieldName === p.dbFieldName) }));
        });
    };

    const onApply = async () => {
        setFiltersConfig(parameters, 'timeline');

        if (focusCellId) {
            setFocusCellId(null); // reset the focused cell
        }

        await fetchRibbonData(parameters);
    };

    const updateRibbonData = useCallback(() => {
        fetchData();
        onDataChange && onDataChange();
    }, [fetchData, onDataChange]);

    if (isInitialLoading) {
        return (
            <>
                <Box mt={4}>
                    <RibbonFiltersSkeleton />
                </Box>

                <Box mt={12}>
                    <RibbonSkeleton />
                </Box>
            </>
        );
    }

    return hasError ? (
        <Alert status="error" mt={4}>
            <AlertIcon />
            <FormattedMessage id="batteries_timeline_editor_error" />
        </Alert>
    ) : info ? (
        <Alert status="info" mt={4}>
            <AlertIcon />
            <FormattedMessage id="batteries_timeline_overlap_error" />
        </Alert>
    ) : (
        <>
            <Box mt={4}>
                <RibbonFiltersBar
                    isLoading={loadingQueue.includes(timelineDataLoadingKey)}
                    view={selectedView}
                    onViewChange={setSelectedView}
                    filters={parameters}
                    onFiltersChange={onFiltersChange}
                    onApply={onApply}
                />
            </Box>

            <Box mt={12}>
                {loadingQueue.includes(timelineDataLoadingKey) ? (
                    <RibbonSkeleton />
                ) : (
                    <TimeRibbon
                        data={timelineData}
                        metadata={timelineMetadata}
                        selectedView={selectedView}
                        subBatteryId={subBatteryId}
                        updateRibbonData={updateRibbonData}
                        focusCellId={focusCellId}
                        onFocusCellIdChange={setFocusCellId}
                        ribbonStaticData={{
                            parameters,
                            ancillaries,
                            peakPeriods,
                            readonlyFields,
                        }}
                    />
                )}
            </Box>
        </>
    );
};

export default BatteryTimeline;
