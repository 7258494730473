import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled/macro';
import { useColorMode } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import * as Highcharts from 'highcharts/highstock';

import { colors } from '../../theme/colors';

const DataChart = ({ isLoading, tooltipName, yAxisLabel, data, getData }) => {
    const intl = useIntl();
    const isSidebarVisible = useSelector((state) => state.helpers.isSidebarVisible);

    const chartInstance = useRef();
    const el = useRef();
    const { colorMode } = useColorMode();

    const isDarkMode = colorMode === 'dark';

    useEffect(() => {
        const options = {
            colors: [colors.green['500']],
            chart: {
                zoomType: 'xy',
                backgroundColor: isDarkMode ? colors.black : colors.white,
                panning: {
                    enabled: true,
                    type: 'y',
                },

                panKey: 'alt',
                resetZoomButton: {
                    theme: {
                        fill: colors.gray['100'],
                        stroke: 'transparent',
                        r: '4px',
                        style: {
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            fontWeight: '500',
                        },
                        states: {
                            hover: {
                                fill: colors.gray['200'],
                                style: {
                                    color: colors.gray['900'],
                                },
                            },
                        },
                    },
                },
            },
            scrollbar: {
                liveRedraw: false, // This prevents a loop bug when using navigator which results in multiple requests
            },
            lang: {
                noData: intl.formatMessage({ id: 'common_no_data_found' }),
            },
            navigator: {
                adaptToUpdatedData: false,
            },
            series: [
                {
                    name: tooltipName,
                    data,
                    tooltip: {
                        valueDecimals: 2,
                    },
                },
            ],
            tooltip: {
                formatter: function (tooltip) {
                    let result = tooltip.defaultFormatter.call(this, tooltip);

                    if (!tooltipName) {
                        result = result.map((value) => value.replace(/span> Series \d: <b>/, 'span><b>'));
                    }

                    return result;
                },
            },
            rangeSelector: {
                enabled: data.length > 0,
                inputEnabled: false,
                labelStyle: {
                    color: isDarkMode ? colors.gray[500] : colors.black,
                },
            },
            xAxis: {
                minRange: 3600000, // 1 hour zoom precision (user can't zoom in more than 1 hour ticks)
                lineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
                gridLineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
                minorGridLineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
                minorTickColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
                tickColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
                events: {
                    setExtremes(event) {
                        if (event.min !== undefined && event.max !== undefined) {
                            // min/max values are floating point numbers
                            const min = Math.round(event.min);
                            const max = Math.round(event.max);

                            // highcharts has a bug and doesn't report correct min/max values
                            // they can be off by 10-12 hours and even stretch before/after the initial first/last value
                            // so we take the min or if it's lower than dataMin the dataMin value and the same for max
                            // this bug affects also userMin/userMax and it's the same behavior in afterSetExtremes event
                            const start = new Date(Math.max(min, data[0][0]));
                            const end = new Date(Math.min(max, data[data.length - 1][0]));

                            getData({ start, end }, data);
                        } else {
                            // reset button was pressed
                            getData();
                        }
                    },
                },
                labels: {
                    style: {
                        fontSize: '14px',
                        color: isDarkMode ? `${colors.gray[500]}` : `${colors.black}`,
                    },
                },
            },
            yAxis: {
                opposite: false,
                gridLineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
                minorGridLineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
                minorTickColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
                lineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
                tickColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
                title: {
                    text: yAxisLabel,
                    style: {
                        color: isDarkMode ? colors.gray[500] : colors.black,
                        fontWeight: '500',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                    },
                },
                labels: {
                    y: 5,
                    style: {
                        fontSize: '14px',
                        color: isDarkMode ? colors.gray[500] : colors.black,
                    },
                },
            },
        };

        if (chartInstance.current) {
            // @todo when a HMR happens the Chart doesn't re-init correctly
            chartInstance.current.series[0].setData([...data]);
            chartInstance.current.update(options);
            chartInstance.current.reflow();
        } else {
            chartInstance.current = Highcharts.stockChart(el.current, options);
        }
    }, [getData, data, isSidebarVisible, intl, tooltipName, yAxisLabel, isDarkMode]);

    useEffect(() => {
        if (isLoading) {
            chartInstance.current.showLoading();
        } else {
            chartInstance.current.hideLoading();
        }
    }, [isLoading]);

    return <StyledChart ref={el} />;
};

const StyledChart = styled.div`
    height: auto;

    @media (min-width: 1280px) {
        height: 600px;
    }
`;

export default DataChart;
