import styled from '@emotion/styled/macro';

const MainWrapper = styled.div`
    padding-bottom: 40px;
    position: fixed;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0px 24px;
`;

export default MainWrapper;
