import { useMemo } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Autoform from './Autoform';
import TabularPage from '../components/tabs/TabularPage';
import HydroTreeTab from '../components/hydro-project/HydroTreeTab';
import { PLANT_CONFIGURATION_CODE } from 'constants/hydroProject';
import { APP_NAME } from 'constants/autoforms';

const unitRatingsAndDataSchemaId = 'PSHYDPROURD';

const HydroProject = () => {
    const intl = useIntl();

    const routes = useMemo(
        () => [
            {
                name: <FormattedMessage id="plant_configuration" />,
                relativePath: PLANT_CONFIGURATION_CODE,
            },
            {
                name: <FormattedMessage id="plant_data" />,
                relativePath: 'PSHYDPROPLADAT',
            },
            {
                name: <FormattedMessage id="project_constraints" />,
                relativePath: 'PSHYDPROCON',
            },
            {
                name: <FormattedMessage id="elevation_capacity" />,
                relativePath: 'PSHYDPROELECAP',
            },
            {
                name: <FormattedMessage id="unit_ratings_and_data" />,
                relativePath: unitRatingsAndDataSchemaId,
            },
            {
                name: <FormattedMessage id="unit_outages" />,
                relativePath: 'PSHYDPROUNIOUT',
            },
        ],
        []
    );
    const autoformConfig = useMemo(
        () => ({
            pageTitle: `${intl.formatMessage({ id: 'hydro_project' })} - ${APP_NAME}`,
        }),
        [intl]
    );

    return (
        <TabularPage
            routes={routes}
            renderRoutes={({ path, selectedTab, setPersistedRoute }) => (
                <Route path={`${path}/:autoformId`}>
                    {routes[selectedTab].relativePath !== unitRatingsAndDataSchemaId ? (
                        <Autoform hasSecondaryMenu={true} config={autoformConfig} />
                    ) : (
                        <HydroTreeTab
                            setPersistedRoute={setPersistedRoute}
                            autoformId={unitRatingsAndDataSchemaId}
                            pageTitle={autoformConfig.pageTitle}
                        />
                    )}
                </Route>
            )}
        />
    );
};

export default HydroProject;
