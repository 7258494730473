import { useIntl } from 'react-intl';

import { enUSFormatExcludingTime } from '../../../services/items';

import DateFormikField from '../../forms/DateFormikField';
import NumberInputFormikField from '../../forms/NumberInputFormikField';
import ComboboxFormikField from '../../forms/ComboboxFormikField';

const ParameterFormField = ({ fieldName, formHelpers, ribbonStaticData }) => {
    const intl = useIntl();

    const label = formHelpers[fieldName].label;
    const isDisabled = formHelpers[fieldName].isDisabled;
    const minDate = formHelpers[fieldName].min;
    const maxDate = formHelpers[fieldName].max;
    const min = formHelpers[fieldName].allowNegativeValues ? undefined : 0;

    switch (fieldName) {
        case 'value':
            return (
                <NumberInputFormikField
                    id={fieldName}
                    name={fieldName}
                    label={label}
                    isDisabled={isDisabled}
                    step={1}
                    min={min}
                />
            );

        case 'startDate':
        case 'endDate':
            return (
                <DateFormikField
                    id={fieldName}
                    name={fieldName}
                    label={label}
                    isDisabled={isDisabled}
                    dateFormat={enUSFormatExcludingTime}
                    showMonthYearPicker={true}
                    showTimeInput={false}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            );

        case 'peakPeriodId':
            return (
                <ComboboxFormikField
                    id={fieldName}
                    name={fieldName}
                    label={label}
                    isDisabled={isDisabled}
                    options={ribbonStaticData.peakPeriods}
                    valueKey="id"
                    labelKey="description"
                    placeholderLabel={intl.formatMessage({
                        id: 'batteries_timeline_modals_peak_period_select_placeholder',
                    })}
                />
            );

        case 'productTypeId':
            return (
                <ComboboxFormikField
                    id={fieldName}
                    name={fieldName}
                    label={label}
                    isDisabled={isDisabled}
                    options={ribbonStaticData.ancillaries}
                    valueKey="id"
                    labelKey="description"
                    placeholderLabel={intl.formatMessage({
                        id: 'batteries_timeline_modals_product_type_select_placeholder',
                    })}
                    placeholderValue=""
                />
            );

        case 'batteryLevel':
        case 'percentOutage':
            return (
                <NumberInputFormikField
                    id={fieldName}
                    name={fieldName}
                    label={label}
                    isDisabled={isDisabled}
                    step={1}
                    min={0}
                />
            );

        default:
            return null;
    }
};

export default ParameterFormField;
