import { useState, useCallback, useEffect, useRef } from 'react';
import { Flex, Box, Button, TabList, Tab, Tabs, TabPanels, TabPanel, SimpleGrid } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';

import { getProperties } from '../../services/expressJobs';

import MainModal from '../modal/MainModal';

import SimpleGridSkeleton from '../utils/SimpleGridSkeleton';
import Error from '../utils/Error';

import ComboboxField from '../forms/ComboboxField';
import DateField from '../forms/DateField';

import DataGridWrapper from '../grid/DataGridWrapper';
import DataGrid from '../grid/DataGrid';
import SortOrderProvider from '../grid/utils/SortOrderContext';

import ExpressJobsProperties from './ExpressJobsProperties';

const SUBMIT_PRIORITY_DEFAULT_OPTION_ID = 6;

const InfoBoxModal = ({ selectedJobToImport: selectedJob, onClose, dropdownOptions, actions, admin = false }) => {
    const [inboundProperties, setInboundProperties] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [outboundProperties, setOutboundProperties] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [allTables, setTables] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [selectedAction, setSelectedAction] = useState();

    const [selectedSettings, setSelectedSettings] = useState({
        submitPriority: SUBMIT_PRIORITY_DEFAULT_OPTION_ID,
        scheduledRunDate: new Date(),
        jobType: null,
        associatedStudy: null,
    });

    const intl = useIntl();
    const formPropertiesRef = useRef();
    const gridApi = useRef([]);

    const onGridReady = useCallback(
        (params, index) => {
            gridApi.current[index] = params.api;

            gridApi.current[index].setRowData(allTables.data.tables[index]);
            if (allTables.data.tables[index].length === 0) {
                gridApi.current[index].showNoRowsOverlay();
            }
        },
        [allTables]
    );

    const fetchProperties = useCallback(
        async (moduleId) => {
            try {
                const data = await getProperties(moduleId, selectedJob.jobId, admin);

                setInboundProperties((prev) => ({
                    ...prev,
                    data: data.inboundProperties.map((item) => {
                        return {
                            ...item,
                            properties: item.properties.map((property) => ({ ...property, readOnly: true })),
                        };
                    }),
                    loading: false,
                }));

                setOutboundProperties((prev) => ({
                    ...prev,
                    data: data.outboundProperties.map((item) => {
                        return {
                            ...item,
                            properties: item.properties.map((property) => ({ ...property, readOnly: true })),
                        };
                    }),
                    loading: false,
                }));

                setTables((prev) => ({
                    ...prev,
                    data: {
                        tables: data.tables,
                        names: data.tableNames,
                    },
                    loading: false,
                }));
            } catch {
                setInboundProperties((prev) => ({ ...prev, error: true, loading: false }));
                setOutboundProperties((prev) => ({ ...prev, error: true, loading: false }));
                setTables((prev) => ({ ...prev, error: true, loading: false }));
            }
        },
        [selectedJob, admin]
    );

    const updateAllTables = async () => {
        setTables((prev) => ({ ...prev, loading: true }));
        const action = actions.data.find((elem) => elem.description === selectedJob.action);

        try {
            const allProperties = await getProperties(action.id, selectedJob.jobId, admin);
            setTables((prev) => ({
                ...prev,
                data: {
                    tables: allProperties.tables,
                    names: allProperties.tableNames,
                },
            }));

            for (let index in gridApi.current) {
                gridApi.current[index].setRowData(allProperties.tables[index]);
                if (allProperties.tables[index].length === 0) {
                    gridApi.current[index].showNoRowsOverlay();
                }
            }
        } catch {
            setTables((prev) => ({ ...prev, error: true }));
        } finally {
            setTables((prev) => ({ ...prev, loading: false }));
        }
    };

    useEffect(() => {
        if (selectedJob) {
            const action = actions.data.find((elem) => elem.description === selectedJob.action);

            setSelectedAction(action ? action.id : null);
            setInboundProperties((prev) => ({ ...prev, loading: true }));
            setOutboundProperties((prev) => ({ ...prev, loading: true }));
            setTables((prev) => ({ ...prev, loading: true }));
            if (action) {
                fetchProperties(action.id);
            }

            setSelectedSettings({
                submitPriority: selectedJob.statusId,
                scheduledRunDate: new Date(selectedJob.scheduledRunTime),
                jobType: selectedJob.jobTypeId,
                associatedStudy: selectedJob.portfolioJobManagementId,
            });
        }
    }, [selectedJob, actions, fetchProperties, dropdownOptions]);

    useEffect(() => {
        if (!selectedJob) {
            setSelectedSettings((prev) => ({
                ...prev,
                associatedStudy: dropdownOptions.data.studies[dropdownOptions.data.studies.length - 1].id,
            }));
        }
    }, [dropdownOptions, selectedJob]);

    const onActionSelect = (moduleId) => {
        setSelectedAction(moduleId);
        setInboundProperties((prev) => ({ ...prev, loading: true }));
        fetchProperties(moduleId);
    };

    return (
        <MainModal
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="express_job_info_modal_header" values={{ name: selectedJob.jobId }} />}
            content={
                <>
                    {actions.loading || dropdownOptions.loading ? (
                        <SimpleGridSkeleton rows={3} cols={1} />
                    ) : actions.error || dropdownOptions.error ? (
                        <Error primaryId="common_error" secondaryId="common_loading_error" additionalText="data" />
                    ) : (
                        <Tabs>
                            <TabList>
                                <Tab>
                                    <FormattedMessage id="admin_express_jobs_info_modal_tab_input" />
                                </Tab>
                                <Tab isDisabled={!selectedAction}>
                                    <FormattedMessage id="admin_express_jobs_actions_modal_tab_advanced_settings" />
                                </Tab>
                                <Tab>
                                    <FormattedMessage id="admin_express_jobs_info_modal_tab_output" />
                                </Tab>
                                {allTables.data?.tables?.length > 0 && (
                                    <Tab>
                                        <FormattedMessage id="admin_express_jobs_info_modal_tab_tables" />
                                    </Tab>
                                )}
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <ComboboxField
                                        id="actions"
                                        name="actions"
                                        label={<FormattedMessage id="admin_express_jobs_actions_modal_tab_actions" />}
                                        options={actions.data}
                                        value={selectedAction}
                                        onChange={onActionSelect}
                                        valueKey="id"
                                        labelKey="description"
                                        isDisabled
                                        placeholderValue=""
                                        placeholderLabel={intl.formatMessage({ id: 'common_forms_select_option' })}
                                    />

                                    {selectedAction && (
                                        <ExpressJobsProperties
                                            properties={inboundProperties}
                                            formPropertiesRef={formPropertiesRef}
                                        />
                                    )}
                                </TabPanel>

                                <TabPanel>
                                    <SimpleGrid columns={2} spacingX={3} spacingY={3}>
                                        <ComboboxField
                                            id="submitPriority"
                                            name="submitPriority"
                                            label={<FormattedMessage id="admin_express_jobs_submit_priority" />}
                                            options={dropdownOptions.data.priorities}
                                            value={selectedSettings.submitPriority}
                                            onChange={(value) =>
                                                setSelectedSettings((prev) => ({ ...prev, submitPriority: value }))
                                            }
                                            valueKey="id"
                                            labelKey="description"
                                            isDisabled
                                            placeholderValue=""
                                            placeholderLabel={intl.formatMessage({ id: 'common_forms_select_option' })}
                                        />

                                        <DateField
                                            id="scheduledRunDate"
                                            name="scheduledRunDate"
                                            label={<FormattedMessage id="admin_express_jobs_scheduledRunDate" />}
                                            value={selectedSettings.scheduledRunDate}
                                            onChange={(value) =>
                                                setSelectedSettings((prev) => ({
                                                    ...prev,
                                                    scheduledRunDate: value,
                                                }))
                                            }
                                            showTimeInput
                                            utc={false}
                                            isDisabled
                                        />

                                        <ComboboxField
                                            id="jobType"
                                            name="jobType"
                                            label={<FormattedMessage id="admin_express_jobs_job_type" />}
                                            options={dropdownOptions.data.jobTypes}
                                            value={selectedSettings.jobType}
                                            onChange={(value) =>
                                                setSelectedSettings((prev) => ({ ...prev, jobType: value }))
                                            }
                                            valueKey="id"
                                            labelKey="description"
                                            showPlaceholder={true}
                                            placeholderLabel={intl.formatMessage({
                                                id: 'admin_express_jobs_job_type_placeholder',
                                            })}
                                            placeholderValue={null}
                                            isDisabled
                                        />

                                        <ComboboxField
                                            id="associatedStudyPortfolioSensitivity"
                                            name="associatedStudyPortfolioSensitivity"
                                            label={
                                                <FormattedMessage id="admin_express_jobs_associated_study_portfolio_sensitivity" />
                                            }
                                            options={dropdownOptions.data.studies}
                                            value={selectedSettings.associatedStudy}
                                            onChange={(value) =>
                                                setSelectedSettings((prev) => ({ ...prev, associatedStudy: value }))
                                            }
                                            valueKey="id"
                                            labelKey="description"
                                            isDisabled
                                            placeholderValue=""
                                            placeholderLabel={intl.formatMessage({ id: 'common_forms_select_option' })}
                                        />
                                    </SimpleGrid>
                                </TabPanel>
                                <TabPanel>
                                    {selectedAction && (
                                        <ExpressJobsProperties
                                            properties={outboundProperties}
                                            formPropertiesRef={formPropertiesRef}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel>
                                    {allTables.loading ? (
                                        <SimpleGridSkeleton rows={3} cols={1} />
                                    ) : allTables.error ? (
                                        <Error
                                            primaryId="common_error"
                                            secondaryId="common_loading_error"
                                            additionalText="data"
                                        />
                                    ) : (
                                        <Box>
                                            {allTables.data.tables.length > 0 && (
                                                <Tabs>
                                                    <TabList>
                                                        {allTables.data.tables.map((table, index) => (
                                                            <Tab key={index}>
                                                                {allTables.data.names[index] || (
                                                                    <FormattedMessage
                                                                        id="express_job_default_table_header"
                                                                        values={{ index: index + 1 }}
                                                                    />
                                                                )}
                                                            </Tab>
                                                        ))}
                                                        <Flex ml="auto" w="auto">
                                                            <Button variant="secondary">
                                                                <Box
                                                                    as="span"
                                                                    textTransform="capitalize"
                                                                    onClick={updateAllTables}
                                                                >
                                                                    <FormattedMessage id="common_refresh" />
                                                                </Box>
                                                            </Button>
                                                        </Flex>
                                                    </TabList>
                                                    <TabPanels>
                                                        {allTables.data.tables.map((table, index) => (
                                                            <TabPanel key={index}>
                                                                <SortOrderProvider>
                                                                    <DataGridWrapper>
                                                                        <DataGrid
                                                                            onGridReady={(api) =>
                                                                                onGridReady(api, index)
                                                                            }
                                                                            pageSize={100}
                                                                            rowSelection="multiple"
                                                                            disableReadOnlyStyles={true}
                                                                            columns={Object.keys(table[0]).map(
                                                                                (key) => {
                                                                                    return {
                                                                                        field: key,
                                                                                        headerName: key,
                                                                                        editable: false,
                                                                                    };
                                                                                }
                                                                            )}
                                                                        />
                                                                    </DataGridWrapper>
                                                                </SortOrderProvider>
                                                            </TabPanel>
                                                        ))}
                                                    </TabPanels>
                                                </Tabs>
                                            )}
                                        </Box>
                                    )}
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    )}
                </>
            }
        />
    );
};

export default InfoBoxModal;
