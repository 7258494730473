import { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Box,
    Radio,
    RadioGroup,
    HStack,
    Modal,
    ModalBody,
    ModalHeader,
    ModalContent,
    ModalCloseButton,
    ModalOverlay,
    useMediaQuery,
} from '@chakra-ui/react';

import AddSingleRowForm from './AddSingleRowForm';
import AddMultiRowsForm from './AddMultiRowsForm';

const AddDataModal = ({
    columns,
    gridResource,
    onAddRowsSuccess,
    onClose,
    heading,
    instructions,
    initialMode = 'basic',
}) => {
    const [addAction, setAddAction] = useState(initialMode);
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const [isAddingData, setIsAddingData] = useState(false);
    const [isValidatingData, setIsValidatingData] = useState(false);

    const onCreateData = useCallback(
        async (rows) => {
            setIsAddingData(true);

            try {
                // note: always pass a `create` axios call with `suppressErrorMessageHandler: true`, because the forms will handle the errors
                await gridResource.create(rows);

                setIsAddingData(false);
                onAddRowsSuccess();
                onClose();
            } catch (err) {
                setIsAddingData(false);

                // we need to stop the loading spinner and that's why we catch the error
                // re-throwing it to allow AddSingleRowForm/AddMultiRowsForm to catch the error too
                // `finally` could've been used, but React 17.x complains about setting state in an un-mounted component
                throw err;
            }
        },
        [gridResource, onClose, onAddRowsSuccess]
    );

    const onValidateData = useCallback(
        async (rows) => {
            setIsValidatingData(true);

            try {
                // note: always pass a `validate` axios call with `suppressErrorMessageHandler: true`, because the forms will handle the errors
                const response = await gridResource.validate(rows);

                setIsValidatingData(false);

                // we have a scenario where we could check data and receive only warnings with status code 200
                // we return to allow AddSingleRowForm/AddMultiRowsForm to set the warnings
                return response;
            } catch (err) {
                setIsValidatingData(false);

                // we need to stop the loading spinner and that's why we catch the error
                // re-throwing it to allow AddSingleRowForm/AddMultiRowsForm to catch the error too
                // `finally` could've been used, but React 17.x complains about setting state in an un-mounted component
                throw err;
            }
        },
        [gridResource]
    );

    return (
        <Modal isOpen onClose={onClose} size={isDesktop ? (columns.length > 12 ? '6xl' : '3xl') : 'full'}>
            <ModalOverlay />

            <ModalContent pt={3} borderRadius={isDesktop ? '2xl' : 0}>
                <ModalHeader>{heading || <FormattedMessage id="common_add_rows_heading" />}</ModalHeader>

                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <ModalBody>
                    <RadioGroup onChange={setAddAction} value={addAction} mb={6}>
                        <HStack spacing={5}>
                            <Radio value="basic">
                                <FormattedMessage id="grid_single_row" />
                            </Radio>

                            <Radio value="advanced">
                                <FormattedMessage id="grid_multiple_rows" />
                            </Radio>
                        </HStack>
                    </RadioGroup>

                    {/* don't unmount these components on action change, because we'll lose the entered data */}
                    <Box style={{ display: addAction === 'basic' ? 'block' : 'none' }}>
                        <AddSingleRowForm
                            columns={columns}
                            onAdd={onCreateData}
                            onValidate={typeof gridResource.validate === 'function' ? onValidateData : null}
                            onClose={onClose}
                            isLoading={isAddingData}
                            isValidating={isValidatingData}
                        />
                    </Box>

                    <Box style={{ display: addAction === 'advanced' ? 'block' : 'none' }}>
                        <AddMultiRowsForm
                            columns={columns}
                            onAdd={onCreateData}
                            onValidate={typeof gridResource.validate === 'function' ? onValidateData : null}
                            onClose={onClose}
                            isLoading={isAddingData}
                            isValidating={isValidatingData}
                            instructions={instructions}
                        />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AddDataModal;
