import { useCallback, useMemo } from 'react';
import CustomNavLink from '../../utils/CustomNavLink';
import { Link } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react/macro';

import useUserPermissions from '../../../hooks/useUserPermissions';
import StyledStandaloneIconWrapper from '../StyledStandaloneIconWrapper';

const NavigationLink = ({ config, onClick }) => {
    const { isAdmin } = useUserPermissions();
    const isDisabled = useMemo(() => config?.adminOnly && !isAdmin, [config, isAdmin]);

    const handler = useCallback(
        (event) => {
            if (isDisabled) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                onClick && onClick();
                config?.clickHandler && config.clickHandler(event);
            }
        },
        [config, onClick, isDisabled]
    );

    const content = useMemo(
        () => (
            <>
                {config.label ? (
                    config.icon
                ) : (
                    <StyledStandaloneIconWrapper data-comp="standalone-icon-wrapper">
                        {config.icon}
                    </StyledStandaloneIconWrapper>
                )}
                {config.label}
            </>
        ),
        [config]
    );

    return isDisabled ? (
        <StyledLink variant="secondary" onClick={handler} isDisabled={isDisabled}>
            {content}
        </StyledLink>
    ) : config.url.startsWith('http') ? (
        <StyledLink variant="secondary" href={config.url} target="_blank" onClick={handler}>
            {content}
        </StyledLink>
    ) : (
        <StyledCustomNavLink
            exact={config.url === '/'}
            to={config.url}
            onClick={handler}
            style={{ color: 'var(--chakra-colors-menu-item-text)' }}
        >
            {content}
        </StyledCustomNavLink>
    );
};

const CommonLinkStyle = css`
    display: flex;
    align-items: center;
    font-weight: 500;

    &.active [data-comp='navigation-asset'],
    &:hover [data-comp='navigation-asset'] {
        color: var(--chakra-colors-blue-400);
    }

    &:hover {
        text-decoration: none;
    }

    &:focus,
    &:focus-visible {
        box-shadow: var(--chakra-shadows-outline);
        border-radius: 4px;
        outline: 0;
    }
`;

const disabledStyles = `
    color: var(--chakra-colors-gray-400);
    box-shadow: var(--chakra-shadows-none) !important;

    &.active [data-comp='navigation-asset'],
    &:hover [data-comp='navigation-asset'] {
        color: inherit;
    }
`;

const StyledLink = styled(Link, { shouldForwardProp: (prop) => prop !== 'isDisabled' })`
    ${CommonLinkStyle}
    ${(props) => (props.isDisabled ? disabledStyles : '')}
`;

const StyledCustomNavLink = styled(CustomNavLink, {
    shouldForwardProp: (prop) => prop !== 'isDisabled',
})`
    ${CommonLinkStyle}
    ${(props) => (props.isDisabled ? disabledStyles : '')}
`;

export default NavigationLink;
