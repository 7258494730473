import { useRouteMatch } from 'react-router-dom';

import Autoform from './Autoform';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import useAutoformMetadata from './hooks/useAutoformMetadata';
import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import useAutoformSelectItem from './hooks/useAutoformSelectItem';

/**
 * Autoform wrapper with some initialization and checks
 *
 * @param {object} config - containing config for this and nested components
 * @param {boolean} isLoading - loading flag
 * @param {function} onNodeRename - node rename handler
 * @param {function} onNodeChangeIsMaster - isMaster change handler
 * @param {callback} onNodeItemDelete - Optional. Callback to delete the portfolio item in the portfolios screen.
 * @param {object} portfolioItem - Item in the portfolios screen.
 * @param {callback} onNodeOwnershipPercentageUpdate - Optional. Callback to change the percent ownership
 * @returns {*}
 * @constructor
 */
const AutoformContainer = ({
    config = {},
    isLoading = false,
    onNodeRename,
    onNodeChangeIsMaster,
    onNodeItemDelete,
    portfolioItem,
    onNodeOwnershipPercentageUpdate,
}) => {
    const { hierarchicalTables } = useAutoformMetadata();
    const level1TableId = hierarchicalTables?.level1[0]?.id;
    const { selectedItem } = useAutoformTableMetadata(level1TableId);

    let {
        params: { itemId: routeId },
    } = useRouteMatch();
    const selectedItemId = parseInt(routeId);

    useAutoformSelectItem(level1TableId, selectedItemId);

    if (isLoading || selectedItem !== selectedItemId) return <DefinitionsSkeleton />;

    return (
        <Autoform
            config={config}
            onNodeRename={onNodeRename}
            onNodeChangeIsMaster={onNodeChangeIsMaster}
            onNodeItemDelete={onNodeItemDelete}
            portfolioItem={portfolioItem}
            onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
        />
    );
};

export default AutoformContainer;
