import axios from 'axios';

export const GRID_KEYS = {
    spotPriceData: 'spotPriceData',
    spotPriceVolatilityForecast: 'spotPriceVolatilityForecast',
    spotPriceOptions: 'spotPriceOptions',
};

export const getSpotPriceDropdownOptions = async () => {
    const response = await axios.get('/spot-price/dropdown-options');
    return response.data;
};

export const createSpotPrice = (payload) => axios.post('/spot-price/create', payload);

export const getSpotPriceDefinition = async (spotPriceId) => {
    const response = await axios.get(`/spot-price/${spotPriceId}`);
    return response.data;
};

export const getSpotPriceData = async (spotPriceId, payload) => {
    const response = await axios.post(`/spot-price/${spotPriceId}/data`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const createSpotPriceData = (spotPriceId, payload) =>
    axios.post(`/spot-price/${spotPriceId}/create-data`, payload);

export const updateSpotPriceData = (spotPriceId, payload) => axios.put(`/spot-price/${spotPriceId}/data`, payload);

export const deleteSpotPriceData = (payload) =>
    axios.delete('/spot-price/delete-data', { data: payload, suppressErrorMessageHandler: true });

export const deleteAllSpotPriceData = (spotPriceId) =>
    axios.delete(`/spot-price/${spotPriceId}/delete-all-data`, { suppressErrorMessageHandler: true });

export const getSpotPriceOptionData = async (spotPriceId, payload) => {
    const response = await axios.post(`/spot-price/${spotPriceId}/options`, payload, {
        suppressErrorMessageHandler: true,
    });

    return {
        totalCount: response.data.spotPriceOptions.totalCount,
        items: response.data.spotPriceOptions.items,
    };
};

export const createSpotPriceOptionData = (spotPriceId, payload) =>
    axios.post(`/spot-price/${spotPriceId}/create-options`, payload, { suppressErrorMessageHandler: true });

export const updateSpotPriceOptionData = (spotPriceId, payload) =>
    axios.put(`/spot-price/${spotPriceId}/options`, payload, { suppressErrorMessageHandler: true });

export const deleteSpotPriceOptionData = (payload) =>
    axios.delete('/spot-price/delete-options', { data: payload, suppressErrorMessageHandler: true });

export const deleteAllSpotPriceOptionData = (spotPriceId) =>
    axios.delete(`/spot-price/${spotPriceId}/delete-all-options`, { suppressErrorMessageHandler: true });
export const getSpotPriceVolatilityForecast = async (spotPriceId, payload) => {
    const response = await axios.post(`/spot-price/${spotPriceId}/volatility-forecasts`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const createSpotPriceVolatilityForecast = (spotPriceId, payload) =>
    axios.post(`/spot-price/${spotPriceId}/create-volatility-forecasts`, payload, {
        suppressErrorMessageHandler: true,
    });

export const updateSpotPriceVolatilityForecast = (spotPriceId, payload) =>
    axios.put(`/spot-price/${spotPriceId}/volatility-forecasts`, payload, { suppressErrorMessageHandler: true });

export const deleteSpotPriceVolatilityForecast = (spotPriceId, payload) =>
    axios.delete(`/spot-price/${spotPriceId}/volatility-forecasts`, {
        data: payload,
        suppressErrorMessageHandler: true,
    });

export const deleteAllSpotPriceVolatilityForecast = (spotPriceId) =>
    axios.delete(`/spot-price/${spotPriceId}/delete-all-volatility-forecasts`, { suppressErrorMessageHandler: true });

export const deleteSpotPrice = (spotPriceId) => axios.delete(`/spot-price/${spotPriceId}/cancel`);

export const editSpotPrice = (spotPriceId, payload) => axios.put(`/spot-price/${spotPriceId}`, payload);

export const multisortPairs = {
    peakPeriodLabelId: 'peakPeriodDescription',
};

export const formatLookupValues = (lookupIdValues) => {
    try {
        const lookupValues = lookupIdValues.split(',');
        const isoRto = lookupValues[0].split('=')[1];
        const electricNode = lookupValues[1].split('=')[1];
        const dartm = lookupValues[2].split('=')[1];

        return { isoRto, electricNode, dartm };
    } catch {
        return { isoRto: '', electricNode: '', dartm: '' };
    }
};

export const validateSpotPriceData = async (spotPriceId, payload) => {
    const response = await axios.post(`/spot-price/${spotPriceId}/validate/data`, payload);
    return response.data;
};
