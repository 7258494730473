import { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled/macro';

import {
    Heading,
    Flex,
    Box,
    Divider,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Spacer,
    Button,
    IconButton,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';

import { checkItemInputEvent } from '../services/items';
import { preserveConnectionSearchParams } from '../services/connections';
import { getPriceFormula, getPriceFormulaTypes, deletePriceFormula, editPriceFormula } from '../services/priceFormula';
import { getBasisDropdownOptions } from '../services/basis';

import { onHasUnsavedChanges } from '../store/helpers/helpersSlice';

import useCommonToast from '../hooks/useCommonToast';
import useHistoryPush from '../hooks/useHistoryPush';

import AutomaticFormikDirtyCheck from '../components/forms/AutomaticFormikDirtyCheck';
import Error from '../components/utils/Error';
import DefinitionsSkeleton from '../components/utils/DefinitionsSkeleton';
import ActionBar from '../components/utils/ActionBar';
import Tooltip from '../components/utils/Tooltip';

import PromptWrapper from '../components/utils/PromptWrapper';

import PriceEquations from '../components/priceFormula/PriceEquations';

import ComboboxFormikField from '../components/forms/ComboboxFormikField';
import InputFormikField from '../components/forms/InputFormikField';

import { ReactComponent as EditIcon } from '../icons/edit-circled.svg';
import { ReactComponent as PriceFormulaIcon } from '../icons/item-icons/functional-forms-1.svg';
import { ReactComponent as SaveIcon } from '../icons/save.svg';
import { ReactComponent as BreadcrumbSeparatorIcon } from '../icons/breadcrumb-separator.svg';
import { isSubmitDisabled } from '../services/utils';

const PriceFormula = () => {
    const { priceFormulaId } = useParams();
    const { search } = useLocation();

    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();
    const formRef = useRef();
    const dispatch = useDispatch();

    const searchParams = new URLSearchParams(search);
    const basisId = searchParams.get('basisId');
    const mode = searchParams.get('mode');
    const isNewItem = mode !== null && mode === 'new';

    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);

    const [priceFormula, setPriceFormula] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [formulaTypes, setFormulaTypes] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [dropdownOptions, setDropdownOptions] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [editDescriptionConfig, setEditDescriptionConfig] = useState(null);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [confirmedDiscardChanges, setConfirmedDiscardChanges] = useState(false);

    const fetchPriceFormulaData = useCallback(() => {
        return getPriceFormula(priceFormulaId)
            .then((data) => {
                setPriceFormula((prev) => ({
                    ...prev,
                    data,
                }));
                setEditDescriptionConfig({
                    [data.priceFormulaId]: { isActive: false, penDisabled: false },
                });
            })
            .catch(() => setPriceFormula((prev) => ({ ...prev, error: true })))
            .finally(() => setPriceFormula((prev) => ({ ...prev, loading: false })));
    }, [priceFormulaId]);

    const fetchFormulaTypes = useCallback(() => {
        return getPriceFormulaTypes()
            .then((data) => setFormulaTypes((prev) => ({ ...prev, data })))
            .catch(() => setFormulaTypes((prev) => ({ ...prev, error: true })))
            .finally(() => setFormulaTypes((prev) => ({ ...prev, loading: false })));
    }, []);

    const fetchDropdownOptions = useCallback(() => {
        return getBasisDropdownOptions()
            .then((data) => setDropdownOptions((prev) => ({ ...prev, error: false, data })))
            .catch(() => setDropdownOptions((prev) => ({ ...prev, error: true })))
            .finally(() => setDropdownOptions((prev) => ({ ...prev, loading: false })));
    }, []);

    useEffect(() => {
        fetchPriceFormulaData();
        fetchFormulaTypes();
        fetchDropdownOptions();
    }, [fetchPriceFormulaData, fetchFormulaTypes, fetchDropdownOptions]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const navigateToBasisConfigurator = (forceRedirect = false) => {
        historyPush({
            pathname: `/basis-configurator/${basisId}`,
            hash: '#price-formula',
            search: preserveConnectionSearchParams(window.location.search),
            forceRedirect,
        });
    };

    const formLabels = {
        description: intl.formatMessage({ id: 'basis_configurator_price_formula_name' }),
        priceFormulaType: intl.formatMessage({ id: 'basis_configurator_price_formula_type' }),
    };

    const initialFormValues = {
        description: priceFormula.data?.description,
        priceFormulaTypeId: priceFormula.data?.priceFormulaTypeId,
    };

    const onFormSubmit = async ({ description, priceFormulaTypeId }) => {
        try {
            await editPriceFormula([
                {
                    description,
                    priceFormulaTypeId,
                    priceFormulaId: Number(priceFormulaId),
                },
            ]);

            setEditDescriptionConfig({
                [priceFormulaId]: { isActive: false, penDisabled: false },
            });

            toast(intl.formatMessage({ id: 'price_formula_save_changes_success' }));

            navigateToBasisConfigurator(true);
        } catch (error) {
            if (error.response.status !== 401 && error.response.status !== 500) {
                const serverError = error.response.data.error || error.response.data.title;

                toast({
                    status: 'error',
                    message: serverError || intl.formatMessage({ id: 'common_generic_saving_error' }),
                });
            }
        }
    };

    const onCancelPriceFormulaCreationConfirm = async () => {
        const successMessage = intl.formatMessage({ id: 'price_formula_delete_success' });

        await deletePriceFormula(priceFormulaId);

        toast(successMessage);
    };

    const onBasisSetupBreadcrumbClicked = () => {
        navigateToBasisConfigurator();
    };

    const onEditDescription = () => {
        if (!editDescriptionConfig[priceFormulaId].penDisabled) {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [priceFormulaId]: { isActive: !prevState[priceFormulaId].isActive, penDisabled: false },
                };
            });
        }
    };

    // Move validateDescription to services
    const validateDescription = (value) => {
        const trimmed = value.trim();
        let errorMessage = '';

        if (trimmed.length === 0) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: 'Price formula name' }
            );
        } else if (trimmed.length > 100) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Price formula name', lengthRule: '100 characters or less' }
            );
        }

        setEditDescriptionConfig((prevState) => {
            return {
                ...prevState,
                [priceFormulaId]: { ...prevState[priceFormulaId], penDisabled: errorMessage !== '' },
            };
        });

        return errorMessage;
    };

    return (
        <Box pt={'80px'} pb={'50px'}>
            {priceFormula.loading || formulaTypes.loading || dropdownOptions.loading ? (
                <DefinitionsSkeleton />
            ) : priceFormula.error || formulaTypes.error || dropdownOptions.error ? (
                <Box m={6}>
                    <Error primaryId="common_error" secondaryId="common_loading_error" additionalText="price formula" />
                </Box>
            ) : (
                <>
                    <Breadcrumb pt="12px" px="24px" separator={<BreadcrumbSeparatorIcon />}>
                        <BreadcrumbItem onClick={onBasisSetupBreadcrumbClicked}>
                            <BreadcrumbLink>
                                <FormattedMessage id="basis_setup" />
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>
                                <FormattedMessage id="price_formula_page_title" />
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>

                    <Formik
                        validateOnChange={false}
                        enableReinitialize
                        initialValues={initialFormValues}
                        onSubmit={onFormSubmit}
                        innerRef={formRef}
                    >
                        {({ isSubmitting, errors, values, touched }) => (
                            <Form>
                                <AutomaticFormikDirtyCheck
                                    isFormDirty={isFormDirty}
                                    onFormDirtyChange={setIsFormDirty}
                                />
                                <ActionBar>
                                    <Flex align="center">
                                        <CustomPriceFormulaIcon />
                                        {!editDescriptionConfig[priceFormulaId]?.isActive ? (
                                            <Heading px={2} as="h2" variant="h2">
                                                {values.description}
                                            </Heading>
                                        ) : (
                                            <InputFormikField
                                                px={3}
                                                flex="1"
                                                maxW="500px"
                                                validate={validateDescription}
                                                name="description"
                                                type="text"
                                                onKeyDown={checkItemInputEvent}
                                                isFastField
                                            />
                                        )}

                                        <Box display="inline-block" mr={4}>
                                            <Tooltip
                                                label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}
                                            >
                                                <IconButton
                                                    aria-label={intl.formatMessage({
                                                        id: 'common_edit_btn_tooltip_and_label',
                                                    })}
                                                    variant="circular-icon"
                                                    leftIcon={<EditIcon />}
                                                    onClick={onEditDescription}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Flex>

                                    <Spacer display={{ base: 'none', xl: 'block' }} />

                                    <Button variant="secondary" onClick={() => navigateToBasisConfigurator()}>
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id={isNewItem ? 'common_cancel' : 'common_back'} />
                                        </Box>
                                    </Button>

                                    <Button
                                        leftIcon={<SaveIcon />}
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        type="submit"
                                        size="sm"
                                        variant="primary-success"
                                    >
                                        <FormattedMessage id="common_save_and_return" />
                                    </Button>
                                </ActionBar>

                                <Divider />

                                <ComboboxFormikField
                                    id="priceFormulaTypeId"
                                    name="priceFormulaTypeId"
                                    label={formLabels.priceFormulaType}
                                    options={formulaTypes.data}
                                    labelKey="description"
                                    valueKey="id"
                                    isFastField
                                    isRequired
                                    my={5}
                                    ml={6}
                                    maxW={{ base: '100%', lg: '30%' }}
                                />
                            </Form>
                        )}
                    </Formik>

                    <PriceEquations priceFormulaId={priceFormulaId} peakPeriods={dropdownOptions.data.peakPeriods} />

                    <PromptWrapper
                        when={hasUnsavedChanges || (isNewItem && !confirmedDiscardChanges)}
                        onPromptConfirm={() => {
                            if (isNewItem) {
                                setConfirmedDiscardChanges(true);
                                onCancelPriceFormulaCreationConfirm();
                            }
                        }}
                        header={<FormattedMessage id="common_discard_changes" />}
                        content={
                            <FormattedMessage
                                id={isNewItem ? 'price_formula_cancel_confirmation' : 'common_confirmation_explanation'}
                            />
                        }
                    />
                </>
            )}
        </Box>
    );
};

const CustomPriceFormulaIcon = styled(PriceFormulaIcon)`
    width: 32px;
    height: 32px;
`;

export default PriceFormula;
