import { Box } from '@chakra-ui/react';

const BoxTextDisplay = ({ label, value }) => {
    return (
        <Box mr="4">
            {label}: {value}
        </Box>
    );
};

export default BoxTextDisplay;
