import { useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import useHistoryPush from '../../hooks/useHistoryPush';
import { onHasUnsavedChanges } from '../../store/helpers/helpersSlice';
import ConfirmationModal from '../modal/ConfirmationModal';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const defaultProps = {
    header: <FormattedMessage id="common_discard_changes" />,
    content: <FormattedMessage id="common_confirmation_explanation" />,
};

/**
 * Component that wraps unsaved changes prompt before navigating away from a page
 *
 * @param when - flag indicating when to activate the prompt on navigation change
 * @param header - confirmation modal header
 * @param content - confirmation modal content
 * @param onPromptConfirm - callback function that executes after confirming of the discard changes ( Used in all basis items )
 */
const PromptWrapper = ({ when, header = defaultProps.header, content = defaultProps.content, onPromptConfirm }) => {
    const modal = useDisclosure();
    const historyPush = useHistoryPush();
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const { pathname: currentPathname } = useLocation();

    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);

    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const condition = useMemo(() => (when === undefined ? hasUnsavedChanges : when), [when, hasUnsavedChanges]);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            if (lastLocation.pathname === '/_signOut') {
                instance.logoutRedirect();
            } else {
                historyPush({
                    ...lastLocation,
                    search: new URLSearchParams(lastLocation.search),
                });
            }
        }
    }, [confirmedNavigation, historyPush, instance, lastLocation]);

    useEffect(() => {
        const onBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = ''; // Chrome requires `event.returnValue` to be set
        };

        if (condition) {
            window.addEventListener('beforeunload', onBeforeUnload);
        }

        return () => {
            if (condition) {
                window.removeEventListener('beforeunload', onBeforeUnload);
            }
        };
    }, [condition]);

    // message func will be called with the next location & action the user is attempting to navigate to
    // 'action' could be used for more complex scenarios in the future
    const onNavigationChange = (location) => {
        setLastLocation(location);
        setConfirmedNavigation(false);

        if (location.pathname !== currentPathname && condition && !location.forceRedirect) {
            modal.onOpen();
            return false;
        }

        return true;
    };

    const confirmNavigation = () => {
        if (onPromptConfirm) {
            onPromptConfirm();
        }
        setConfirmedNavigation(true);
        modal.onClose();
        dispatch(onHasUnsavedChanges(false));
    };

    return (
        <>
            <Prompt when={condition} message={onNavigationChange} />

            {modal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={modal.onClose}
                    onConfirm={confirmNavigation}
                    hasExtraStep
                    header={header}
                    content={content}
                />
            )}
        </>
    );
};

export default PromptWrapper;
