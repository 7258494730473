import { FormattedMessage } from 'react-intl';
import {
    Heading,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';

const ScreenshotModal = ({ imageUrl, ...rest }) => {
    return (
        <Modal size="md" {...rest}>
            <ModalOverlay />

            <ModalContent pt={3} borderRadius="2xl">
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3">
                        <FormattedMessage id="map_drawer_export_map_preview" />
                    </Heading>
                </ModalHeader>

                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <ModalBody pb={5} borderBottom="1px" borderColor="gray.200">
                    <Image src={imageUrl} />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ScreenshotModal;
