import { FormattedMessage } from 'react-intl';
import UsersTab from '../components/userManagement/UsersTab';
import BooksTab from '../components/userManagement/BooksTab';
import GroupsTab from '../components/userManagement/GroupsTab';
import { useMemo } from 'react';
import TabularPage from '../components/tabs/TabularPage';

const UserManagementPage = () => {
    const routes = useMemo(
        () => [
            {
                name: <FormattedMessage id="user_management_users" />,
                relativePath: 'users',
                component: <UsersTab />,
            },
            {
                name: <FormattedMessage id="user_management_groups" />,
                relativePath: 'groups',
                component: <GroupsTab />,
            },
            {
                name: <FormattedMessage id="user_management_books" />,
                relativePath: 'books',
                component: <BooksTab />,
            },
        ],
        []
    );

    return <TabularPage routes={routes} />;
};

export default UserManagementPage;
