import { useMemo, useEffect, useRef, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Text,
    HStack,
    Spacer,
    Button,
    useDisclosure,
    InputLeftElement,
    Flex,
    InputGroup,
    InputRightElement,
    IconButton,
    Spinner,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import SecondaryButton from '../utils/SecondaryButton';
import { onStudiesAutoUpdate } from '../../store/studies/studiesSlice';

import {
    formatStudyHours,
    getStudies,
    updateStudy,
    getFiltersConfig,
    setFiltersConfig,
    getStudyFilterOptions,
    exportStudies,
    formatFilterIds,
    sortStudyStatus,
    checkForChildJobsFromStudies,
    getStudyChangeStatuses,
    submitStudyStatusChange,
    getJobs,
} from '../../services/studies';
import { downloadFile, formatDataGridDate } from '../../services/items';

import { formatDuration } from '../../services/utils';

import useHistoryPush from '../../hooks/useHistoryPush';
import useUserPermissions from '../../hooks/useUserPermissions';
import useCommonToast from '../../hooks/useCommonToast';

import Tooltip from '../utils/Tooltip';
import Error from '../utils/Error';
import SimpleGridSkeleton from '../utils/SimpleGridSkeleton';
import FiltersButton from '../utils/FiltersButton';
import CustomIconButton from '../utils/CustomIconButton';
import Popover from '../utils/Popover';
import SideDrawer from '../utils/SideDrawer';

import CheckboxField from '../forms/CheckboxField';
import Combobox from '../forms/Combobox';
import InputField from '../forms/InputField';

import DataGrid from '../grid/DataGrid';
import DataGridWrapper from '../grid/DataGridWrapper';
import SortOrderProvider from '../grid/utils/SortOrderContext';

import StudyFilters from './StudyFilters';
import DeleteStudiesModal from './DeleteStudiesModal';
import StudyInfoModal from './StudyInfoModal';
import StudyStatusModal from './StudyStatusModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { STOPPED_JOB_STATUS_ID } from 'constants/studies';

import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as StudyIcon } from '../../icons/study.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

const filterViewTypes = {
    studyName: 1,
    studyId: 2,
    jobId: 3,
};

const StudyGrid = () => {
    const autoUpdateStudies = useSelector((state) => state.studies.autoUpdateStudies);
    const dispatch = useDispatch();
    const intl = useIntl();
    const { toast } = useCommonToast();
    const { isAdmin } = useUserPermissions();

    const gridApi = useRef(null);

    const searchStudyNameField = useRef(null);
    const searchStudyIdsField = useRef(null);
    const searchJobIdsField = useRef(null);

    const historyPush = useHistoryPush();

    const [displayDoNotDeleteError, setDisplayDoNotDeleteError] = useState(false);
    const [selectedStudies, setSelectedStudies] = useState([]);
    const [studyInfo, setStudyInfo] = useState({});

    const [filters, setFilters] = useState(getFiltersConfig());
    const [filterOptions, setFilterOptions] = useState([]);
    const [filtersLoading, setFiltersLoading] = useState(true);
    const [filtersError, setFiltersError] = useState(false);
    const [isLoadingStudies, setIsLoadingStudies] = useState(false);
    const [filterView, setFilterView] = useState(filterViewTypes.studyName);
    const [jobIdFilter, setJobIdFilter] = useState('');
    const [studyIdFilter, setStudyIdFilter] = useState('');
    const [studyNameFilter, setStudyNameFilter] = useState('');
    const [validationErrorMessage, setValidationErrorMessage] = useState(null);
    const [jobWithPendingChildJobs, setJobWithPendingChildJobs] = useState('');
    const [selectedStudyStatus, setSelectedStudyStatus] = useState();
    const [selectableStatuses, setSelectableStatuses] = useState();

    const filterDrawer = useDisclosure();
    const deleteModal = useDisclosure();
    const studyInfoModal = useDisclosure();
    const studyStatusModal = useDisclosure();
    const stopJobsWarningModal = useDisclosure();

    useEffect(() => {
        // set focus to the search field when we change search by type
        if (searchStudyNameField.current) {
            searchStudyNameField.current.focus();
        } else if (searchStudyIdsField.current) {
            searchStudyIdsField.current.focus();
        } else if (searchJobIdsField.current) {
            searchJobIdsField.current.focus();
        }
    }, [filterView]);

    const getData = useCallback(
        async ({ localFilters, localJobIds, localBooks, localStudyName, localStudyIds, resetInputsOnAutoUpdate }) => {
            //Get localFilters if defined before state filters
            const studyFilters = localFilters || filters;

            //Get localBooks if defined before filterOptions.books
            const books = localBooks || filterOptions.books;

            const payload = {
                scheduledRunDateTimeFrom: studyFilters ? studyFilters.scheduledRunDateTimeFrom : null,
                scheduledRunDateTimeTo: studyFilters ? studyFilters.scheduledRunDateTimeTo : null,
                bookIds: studyFilters ? studyFilters.bookIds : books?.map((book) => book.id),
                statuses: studyFilters ? studyFilters.statuses : [],
                jobTypeIds: studyFilters ? studyFilters.jobTypeIds : [],
                submittedByNames: studyFilters ? studyFilters.submittedByNames : [],
            };

            if (localJobIds) {
                payload.jobIds = localJobIds;
            }

            if (localStudyName) {
                payload.searchNameQuery = localStudyName.trim();
            }

            if (localStudyIds) {
                payload.studyIds = localStudyIds;
            }

            if (gridApi.current) {
                try {
                    setIsLoadingStudies(true);

                    const response = await getStudies(payload);

                    setIsLoadingStudies(false);
                    gridApi.current.setRowData(response.data);

                    if (resetInputsOnAutoUpdate) {
                        setStudyIdFilter('');
                        setStudyNameFilter('');
                        setJobIdFilter('');
                    }
                } catch {
                    // in case of server error show the no rows overlay and hide the search form spinner
                    setIsLoadingStudies(false);
                    gridApi.current.showNoRowsOverlay();
                }
            }
        },
        [filters, filterOptions]
    );

    const fetchFilterOptions = useCallback(async () => {
        return getStudyFilterOptions()
            .then((data) => {
                setFilterOptions({
                    ...data,
                    submittedByNames: data.submittedByNames.map((elem) => ({
                        id: elem,
                        description: elem === null ? '-' : elem,
                    })),
                });
                getData({ localBooks: data.books });
            })
            .catch(() => setFiltersError(true))
            .finally(() => setFiltersLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchValidStudyChangeStatuses = useCallback(async () => {
        return getStudyChangeStatuses().then((data) => {
            setSelectableStatuses(data);
        });
    }, []);

    useEffect(() => {
        fetchFilterOptions();
        fetchValidStudyChangeStatuses();
    }, [fetchFilterOptions, fetchValidStudyChangeStatuses]);

    useEffect(() => {
        let timer = setTimeout(function applyStudiesAutoUpdate() {
            if (autoUpdateStudies) {
                getData({ resetInputsOnAutoUpdate: true });
                timer = setTimeout(applyStudiesAutoUpdate, 30000);
            } else {
                clearTimeout(timer);
            }
        }, 30000);

        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoUpdateStudies, filters, filterOptions]);

    const onSelectionChanged = () => {
        const nodes = gridApi.current.getSelectedNodes();
        const studies = nodes.map((node) => node.data);

        setSelectedStudies(studies);
        setDisplayDoNotDeleteError(!isAdmin && studies.findIndex((x) => x.doNotDelete) > -1);
    };

    const onStudyInfoModalOpen = useCallback(
        (studyData) => {
            setStudyInfo(studyData);
            studyInfoModal.onOpen();
        },
        [studyInfoModal]
    );

    const onStudyInfoClick = useCallback(
        ({ data: node }) => {
            setStudyInfo(node);
            studyInfoModal.onOpen();
        },
        [studyInfoModal]
    );

    const onChangeStudyStatusClicked = useCallback(
        ({ data: node }) => {
            setStudyInfo(node);
            studyStatusModal.onOpen();
        },
        [studyStatusModal]
    );

    const onViewStudyJobsClicked = useCallback(
        ({ data: node }) => {
            if (node?.studyId) {
                const search = new URLSearchParams();
                search.append('ids', node?.studyId);

                historyPush({
                    pathname: '/jobs',
                    search,
                });
            }
        },
        [historyPush]
    );

    const onViewPortfolioClicked = useCallback(
        ({ data: node }) => {
            if (node?.portfolioId) {
                const search = new URLSearchParams();
                search.append('portfolioId', node?.portfolioId);

                historyPush({
                    pathname: '/portfolios',
                    search,
                });
            }
        },
        [historyPush]
    );

    const onViewMultipleStudiesJobsClicked = () => {
        const search = new URLSearchParams();
        search.append('ids', selectedStudies.map((s) => s.studyId).join('-'));

        historyPush({
            pathname: '/jobs',
            search,
        });
    };

    const onResetFilters = (filters) => {
        // remove text from search input
        setStudyIdFilter('');
        setStudyNameFilter('');
        setJobIdFilter('');

        setValidationErrorMessage(null);
        setFilters(null);

        getData({ localFilters: filters });
        filterDrawer.onClose();
    };

    const onApplyFilters = (filters) => {
        filterDrawer.onClose();
        setFiltersConfig(filters, 'studyFilters');
        setFilters(filters);
        getData({ localFilters: filters });
    };

    const onFilterInputGroupSearch = useCallback(() => {
        // https://stackoverflow.com/questions/56753932/regex-for-numbers-commas-and-whitespaces
        const numbersAndCommasRegex = '^\\s*\\d+(?:\\s*,\\s*\\d+)*\\s*$';
        let getDataParameters = {};
        setValidationErrorMessage(null);

        if (filterView === filterViewTypes.studyName) {
            getDataParameters = { localStudyName: studyNameFilter?.trim() };
        } else if (filterView === filterViewTypes.studyId) {
            // Empty string or comma separated numbers are allowed for Ids search
            const isValidIdsInput = !studyIdFilter?.trim() || !!studyIdFilter.match(numbersAndCommasRegex);

            if (!isValidIdsInput) {
                const errorMessage = intl.formatMessage({ id: 'study_tracker_filter_invalid_study_ids' });
                setValidationErrorMessage(errorMessage);
                if (searchStudyIdsField.current) {
                    searchStudyIdsField.current.focus();
                }
                toast({
                    status: 'error',
                    message: errorMessage,
                });
                return;
            }

            const studyIds = formatFilterIds(studyIdFilter?.trim());
            getDataParameters = { localStudyIds: studyIds };
        } else if (filterView === filterViewTypes.jobId) {
            // Empty string or comma separated numbers are allowed for Ids search
            const isValidIdsInput = !jobIdFilter?.trim() || !!jobIdFilter.match(numbersAndCommasRegex);

            if (!isValidIdsInput) {
                const errorMessage = intl.formatMessage({ id: 'study_tracker_filter_invalid_job_ids' });
                setValidationErrorMessage(errorMessage);
                if (searchJobIdsField.current) {
                    searchJobIdsField.current.focus();
                }
                toast({
                    status: 'error',
                    message: errorMessage,
                });
                return;
            }

            const jobIds = formatFilterIds(jobIdFilter?.trim());
            getDataParameters = { localJobIds: jobIds };
        }

        getData(getDataParameters);
    }, [filterView, getData, studyNameFilter, studyIdFilter, jobIdFilter, intl, toast]);

    // on filter view change we should reset filters/data accordingly
    // filterType studyName: 1,
    // filterType studyId: 2,
    // filterType jobId: 3,
    const onFilterViewChange = (filterTypeVal) => {
        // update the combobox value
        setFilterView(filterTypeVal);

        // remove text from search input
        setStudyIdFilter('');
        setStudyNameFilter('');
        setJobIdFilter('');

        setValidationErrorMessage(null);
    };

    const onStudyNameChange = (e) => {
        const val = e.target.value;
        setStudyNameFilter(val);
    };

    const onStudiesAutoUpdateChange = (event) => {
        dispatch(onStudiesAutoUpdate(event.target.checked));
    };

    const exportData = async () => {
        const payload = {
            scheduledRunDateTimeFrom: filters ? filters.scheduledRunDateTimeFrom : null,
            scheduledRunDateTimeTo: filters ? filters.scheduledRunDateTimeTo : null,
            bookIds: filters ? filters.bookIds : filterOptions.books.map((book) => book.id),
            statuses: filters ? filters.statuses : [],
            jobTypeIds: filters ? filters.jobTypeIds : [],
            submittedByNames: filters ? filters.submittedByNames : [],
            clientOffset: new Date().getTimezoneOffset(),
        };

        const fileInfo = await exportStudies(payload);

        downloadFile(fileInfo);
    };

    const onCellKeyPress = useCallback(
        (params) => {
            if (params.event.key === 'Enter' && params.column.colId === 'name') {
                onStudyInfoModalOpen(params.data);
            }
        },
        [onStudyInfoModalOpen]
    );

    const onSearchKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter' && !isLoadingStudies) {
                onFilterInputGroupSearch();
            }
        },
        [onFilterInputGroupSearch, isLoadingStudies]
    );

    const searchByOptions = useMemo(
        () => [
            {
                label: intl.formatMessage({ id: 'study_tracker_study_name' }),
                value: filterViewTypes.studyName,
            },
            {
                label: intl.formatMessage({ id: 'study_tracker_study_ids' }),
                value: filterViewTypes.studyId,
            },
            {
                label: intl.formatMessage({ id: 'study_tracker_job_ids' }),
                value: filterViewTypes.jobId,
            },
        ],
        [intl]
    );

    const columns = useMemo(() => {
        return [
            {
                field: 'status',
                editable: false,
                headerName: intl.formatMessage({ id: 'study_tracker_status' }),
                minWidth: 300,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                cellRenderer: 'StudyTrackerStatusBadge',
                cellRendererParams: {
                    isInJobsTable: false,
                },
                comparator: sortStudyStatus,
            },
            {
                field: 'moreActions',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'jobs_table_moreActions' }),
                minWidth: 125,
                editable: false,
                sortable: false,
                cellRenderer: 'MoreActionsCell',
                cellRendererParams: {
                    onStudyInfoClick,
                    onViewStudyJobsClicked,
                    onViewPortfolioClicked,
                    onChangeStudyStatusClicked,
                },
            },
            {
                type: 'number',
                field: 'studyId',
                editable: false,
                headerName: intl.formatMessage({ id: 'study_tracker_study_id' }),
            },
            {
                field: 'name',
                editable: false,
                headerName: intl.formatMessage({ id: 'study_tracker_name' }),
                minWidth: 370,
                cellRenderer: 'StudyTrackerName',
            },
            {
                type: 'number',
                field: 'bookName',
                editable: false,
                headerName: intl.formatMessage({ id: 'study_tracker_book_id' }),
                minWidth: 80,
            },
            {
                field: 'scheduledRunDateTime',
                editable: false,
                headerName: intl.formatMessage({ id: 'study_tracker_run_schedule' }),
                minWidth: 180,
                valueFormatter: formatDataGridDate,
            },
            {
                field: 'startTime',
                editable: false,
                headerName: intl.formatMessage({ id: 'study_tracker_start_time' }),
                minWidth: 180,
                valueFormatter: formatDataGridDate,
            },
            {
                field: 'updateTime',
                editable: false,
                headerName: intl.formatMessage({ id: 'study_tracker_update_time' }),
                minWidth: 180,
                valueFormatter: formatDataGridDate,
                sort: 'desc',
            },
            {
                type: 'number',
                field: 'runTimeSeconds',
                editable: false,
                headerName: intl.formatMessage({ id: 'study_tracker_run_time_hours' }),
                minWidth: 120,
                valueFormatter: formatDuration,
            },
            {
                type: 'number',
                field: 'spaceUsed',
                editable: false,
                headerName: intl.formatMessage({ id: 'study_tracker_space_used' }),
                minWidth: 80,
                valueFormatter: formatStudyHours,
            },
            {
                field: 'submittedBy',
                editable: false,
                headerName: intl.formatMessage({ id: 'study_tracker_submitted_by' }),
                minWidth: 220,
            },
            {
                field: 'doNotDelete',
                editable: isAdmin,
                sortable: false,
                headerName: intl.formatMessage({ id: 'study_tracker_do_not_delete' }),
                minWidth: 80,
                headerCheckboxSelection: false,
                cellRenderer: 'DataGridCheckbox',
                cellRendererParams: { isDisabled: !isAdmin },
            },
        ];
    }, [intl, isAdmin, onStudyInfoClick, onViewStudyJobsClicked, onViewPortfolioClicked, onChangeStudyStatusClicked]);

    const updateStudyInfo = useCallback(
        async (rowIds) => {
            const rows = rowIds.map((id) => gridApi.current.getRowNode(id));

            const { doNotDelete, studyId } = rows[0].data;
            const payload = {
                doNotDelete: doNotDelete,
            };

            updateStudy(studyId, payload).then(() => {
                const successMessage = intl.formatMessage({ id: 'common_generic_item_change_success' });
                toast(successMessage);
            });
        },
        [intl, toast]
    );

    const startStudyChangeStatus = async (statusId) => {
        const newStatusId = parseInt(statusId);
        setSelectedStudyStatus(newStatusId);

        //check if job has pending child jobs in other studies. This is checked only if the new job status is '-3'(stopped).
        if (newStatusId === STOPPED_JOB_STATUS_ID) {
            //get the jobs for the selected study
            const getJobsPayload = {
                studyIds: [studyInfo.studyId],
            };
            const getJobsResponse = await getJobs(getJobsPayload);
            const selectedJobs = getJobsResponse.data;

            //check the study's jobs for related child jobs from other studies
            const checkJobsPayload = {
                jobIds: selectedJobs.map((elem) => elem.jobId),
                statusId: newStatusId,
            };
            const checkJobsResponse = await checkForChildJobsFromStudies(checkJobsPayload);
            const childJobs = checkJobsResponse?.data;

            //if child jobs in other studies are found, then display a warning message
            if (Array.isArray(childJobs) && childJobs.length > 0) {
                const childJobList = childJobs.join(',');
                setJobWithPendingChildJobs(childJobList);
                stopJobsWarningModal.onOpen();
            } else {
                await updateStudyJobStatuses(studyInfo.studyId, newStatusId);
            }
        } else {
            await updateStudyJobStatuses(studyInfo.studyId, newStatusId);
        }
    };

    const updateStudyJobStatuses = async (studyId, statusId) => {
        const successMessage = intl.formatMessage({ id: 'study_change_status_message' });
        await submitStudyStatusChange(studyId, statusId);
        toast(successMessage);
        studyStatusModal.onClose();
    };

    const editStudyStatusAfterConfirmation = async () => {
        stopJobsWarningModal.onClose();
        await updateStudyJobStatuses(studyInfo.studyId, selectedStudyStatus);
    };

    return (
        <>
            <Flex direction="column" m={4} flexGrow="1">
                <HStack
                    gridGap="10px"
                    flexDirection={{ base: 'column', xl: 'row' }}
                    alignItems={{ base: 'flex-start', xl: 'center' }}
                >
                    <Flex flexDirection={{ base: 'column', xl: 'row' }} gridGap="10px" w="100%" flex="1">
                        <Flex gridGap="10px" alignItems="center">
                            <StyledStudyIcon />
                            <Text textTransform="capitalize" color="gray.800" fontWeight="600">
                                <FormattedMessage id="study_tracker_title" />
                            </Text>
                        </Flex>

                        <Spacer />

                        <Flex
                            alignItems={{ base: 'flex-start', xl: 'center' }}
                            flexDirection={{ base: 'column', xl: 'row' }}
                            w={{ base: '100%', md: 'auto' }}
                        >
                            <Box pr={3} borderRight="1px solid" borderColor={{ base: 'transparent', xl: 'gray.300' }}>
                                <CheckboxField
                                    id="chbAutoUpdate"
                                    name="chbAutoUpdate"
                                    w="max-content"
                                    isChecked={autoUpdateStudies}
                                    onChange={onStudiesAutoUpdateChange}
                                >
                                    <Text size="sm">
                                        <FormattedMessage id="common_grid_auto_update_text" />
                                    </Text>
                                </CheckboxField>
                            </Box>
                        </Flex>

                        <Flex
                            flexDirection={{ base: 'column', md: 'row' }}
                            alignItems={{ base: 'flex-start', md: 'center' }}
                        >
                            <Flex pr={4}>
                                <Popover
                                    placement="top"
                                    trigger={
                                        <CustomIconButton
                                            mt="0px !important"
                                            mr="5px !important"
                                            background="none !important"
                                            icon={<InfoIcon />}
                                        />
                                    }
                                >
                                    <FormattedMessage id="study_tracker_filter_info" />
                                </Popover>
                                <Text minWidth="fit-content" fontSize={14} textAlign="center" mr={2}>
                                    <FormattedMessage id="study_tracker_filter_title" />
                                </Text>
                            </Flex>

                            <InputGroup>
                                <StyledInputLeftElement
                                    children={
                                        <Combobox
                                            options={searchByOptions}
                                            valueKey="value"
                                            labelKey="label"
                                            value={filterView}
                                            onChange={onFilterViewChange}
                                        />
                                    }
                                />

                                {filterView === filterViewTypes.studyName && (
                                    <StyledInput
                                        id="filterViewStudyName"
                                        name="filterViewStudyName"
                                        type="text"
                                        value={studyNameFilter}
                                        onChange={onStudyNameChange}
                                        onKeyDown={onSearchKeyDown}
                                        maxWidth={{ '2xl': '460px' }}
                                        placeholder={intl.formatMessage({
                                            id: 'study_tracker_study_name_search_placeholder',
                                        })}
                                        ref={searchStudyNameField}
                                    />
                                )}

                                {filterView === filterViewTypes.studyId && (
                                    <StyledInput
                                        id="filterViewStudyId"
                                        name="filterViewStudyId"
                                        type="text"
                                        value={studyIdFilter}
                                        onChange={(e) => {
                                            setValidationErrorMessage(null);
                                            setStudyIdFilter(e.target.value);
                                        }}
                                        onKeyDown={onSearchKeyDown}
                                        maxWidth={{ '2xl': '460px' }}
                                        placeholder={intl.formatMessage({
                                            id: 'study_tracker_study_ids_search_placeholder',
                                        })}
                                        ref={searchStudyIdsField}
                                        isInvalid={validationErrorMessage}
                                    />
                                )}

                                {filterView === filterViewTypes.jobId && (
                                    <StyledInput
                                        id="filterViewJobId"
                                        name="filterViewJobId"
                                        type="text"
                                        value={jobIdFilter}
                                        onChange={(e) => {
                                            setValidationErrorMessage(null);
                                            setJobIdFilter(e.target.value);
                                        }}
                                        onKeyDown={onSearchKeyDown}
                                        maxWidth={{ '2xl': '460px' }}
                                        placeholder={intl.formatMessage({
                                            id: 'study_tracker_job_ids_search_placeholder',
                                        })}
                                        ref={searchJobIdsField}
                                        isInvalid={validationErrorMessage}
                                    />
                                )}

                                <InputRightElement
                                    children={
                                        <StyledIconButton
                                            onClick={onFilterInputGroupSearch}
                                            variant="ghost"
                                            aria-label={intl.formatMessage({
                                                id: 'study_tracker_search_btn_aria_label',
                                            })}
                                            icon={isLoadingStudies ? <Spinner size="sm" /> : <SearchIcon />}
                                            isDisabled={isLoadingStudies || validationErrorMessage}
                                        />
                                    }
                                />
                            </InputGroup>
                        </Flex>
                    </Flex>

                    <Flex
                        ml={'0rem !important'}
                        alignItems="end"
                        w={{ base: '100%', md: 'auto' }}
                        gridGap="10px"
                        flexDirection={{ base: 'column', md: 'row' }}
                    >
                        <SecondaryButton
                            leftIcon={<RefreshIcon />}
                            size="sm"
                            type="button"
                            variant="secondary"
                            textTransform="capitalize"
                            onClick={onFilterInputGroupSearch}
                            isLoading={isLoadingStudies}
                            loadingText={intl.formatMessage({ id: 'common_loading' })}
                            w={{ base: '100%', md: 'auto' }}
                        >
                            <FormattedMessage id="common_refresh" />
                        </SecondaryButton>
                        <FiltersButton
                            hasIndicator={filters}
                            onClick={filterDrawer.onOpen}
                            isDisabled={isLoadingStudies}
                            w={{ base: '100%', md: 'auto' }}
                        />
                        <SecondaryButton
                            leftIcon={<DownloadIcon />}
                            size="sm"
                            type="button"
                            variant="secondary"
                            textTransform="capitalize"
                            onClick={exportData}
                            w={{ base: '100%', md: 'auto' }}
                        >
                            <FormattedMessage id="common_export_all" />
                        </SecondaryButton>

                        {selectedStudies.length > 0 && (
                            <>
                                <Tooltip
                                    isDisabled={!displayDoNotDeleteError}
                                    label={intl.formatMessage({
                                        id: 'study_grid_donotdelete_error',
                                    })}
                                >
                                    <Button
                                        leftIcon={<DeleteIcon />}
                                        type="button"
                                        variant="special"
                                        textTransform="capitalize"
                                        onClick={deleteModal.onOpen}
                                        size="sm"
                                        w={{ base: '100%', md: 'auto' }}
                                        isDisabled={isLoadingStudies || displayDoNotDeleteError}
                                    >
                                        <FormattedMessage id="common_delete" />
                                    </Button>
                                </Tooltip>
                                <Button
                                    size="sm"
                                    onClick={onViewMultipleStudiesJobsClicked}
                                    textTransform="capitalize"
                                    w={{ base: '100%', md: 'auto' }}
                                    isDisabled={isLoadingStudies}
                                >
                                    <FormattedMessage id="study_tracker_view_jobs_btn" />
                                </Button>
                            </>
                        )}
                    </Flex>
                </HStack>

                <SortOrderProvider>
                    <StyledDataGridWrapper>
                        <DataGrid
                            onGridReady={(params) => (gridApi.current = params.api)}
                            paginationPageSize={100}
                            rowHeight={34}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            enableRangeSelection={false}
                            onSelectionChanged={onSelectionChanged}
                            disableReadOnlyStyles={true}
                            onCellKeyPress={onCellKeyPress}
                            columns={columns}
                            onDataChange={updateStudyInfo}
                        />
                    </StyledDataGridWrapper>
                </SortOrderProvider>

                {filterDrawer.isOpen && (
                    <SideDrawer
                        isOpen
                        onClose={filterDrawer.onClose}
                        header={<FormattedMessage id="study_tracker_drawer_heading" />}
                        content={
                            <>
                                {filtersLoading || filtersError ? (
                                    <Box>
                                        {filtersLoading && <SimpleGridSkeleton rows={6} cols={1} />}
                                        {filtersError && (
                                            <Box m={6}>
                                                <Error
                                                    primaryId="common_error"
                                                    secondaryId="common_loading_error"
                                                    additionalText={intl.formatMessage({
                                                        id: 'study_tracker_study_filters',
                                                    })}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    <StudyFilters
                                        filterOptions={filterOptions}
                                        filters={filters}
                                        onApplyFilters={onApplyFilters}
                                        onResetFilters={onResetFilters}
                                    />
                                )}
                            </>
                        }
                        placement="right"
                    />
                )}
            </Flex>

            {deleteModal.isOpen && (
                <DeleteStudiesModal
                    studies={selectedStudies}
                    onClose={deleteModal.onClose}
                    fetchStudies={getData}
                    defaultBooks={filterOptions.books}
                    onStudiesChange={setSelectedStudies}
                />
            )}

            {studyInfoModal.isOpen && (
                <StudyInfoModal
                    studyId={studyInfo.studyId}
                    studyName={studyInfo.name}
                    onClose={studyInfoModal.onClose}
                />
            )}

            {studyStatusModal.isOpen && (
                <StudyStatusModal
                    statuses={selectableStatuses}
                    studyId={studyInfo?.studyId}
                    onClose={studyStatusModal.onClose}
                    onStatusChange={startStudyChangeStatus}
                />
            )}
            {stopJobsWarningModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={stopJobsWarningModal.onClose}
                    header={<FormattedMessage id="warning_stop_job_with_child_jobs_header" />}
                    content={
                        <FormattedMessage
                            id="warning_stop_job_with_child_jobs"
                            values={{ jobs: jobWithPendingChildJobs }}
                        />
                    }
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_continue" />
                        </Box>
                    }
                    onConfirm={editStudyStatusAfterConfirmation}
                />
            )}
        </>
    );
};

const StyledDataGridWrapper = styled(DataGridWrapper)`
    flex: 1 1 300px;
    margin-top: 24px;

    .ag-cell {
        line-height: 34px;
    }

    .ag-selection-checkbox {
        margin-top: 7px;
    }
`;

const StyledStudyIcon = styled(StudyIcon)`
    height: 24px;
    width: 24px;
    color: var(--chakra-colors-blue-400);
`;

const StyledInputLeftElement = styled(InputLeftElement)`
    left: 1px;
    width: 160px;
    input {
        height: var(--chakra-sizes-9);
    }
`;

const StyledInput = styled(InputField)`
    padding-left: 165px;
    padding-right: 40px;
    min-width: 300px;
`;

const StyledIconButton = styled(IconButton)`
    &:disabled {
        background: transparent;
        color: var(--chakra-colors-gray-600);
    }

    &:hover {
        background: none;
        svg path {
            fill: var(--chakra-colors-blue-400);
        }
    }
`;

export default StudyGrid;
