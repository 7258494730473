import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import {
    Heading,
    Flex,
    Box,
    useDisclosure,
    Button,
    Divider,
    Spacer,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    SimpleGrid,
    IconButton,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled/macro';

import {
    GRID_KEYS,
    getBasisById,
    editBasis,
    deleteBasis,
    getBasisDataFilters,
    mapCommoditiesByTypeAndDescription,
} from '../../services/basis';
import { checkItemInputEvent, parseDataFilters, refreshMultisort, exportItemData } from '../../services/items';
import { getIssuesMap } from '../../services/grid';

import { onHasUnsavedChanges } from '../../store/helpers/helpersSlice';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import { SortOrderContext } from '../grid/utils/SortOrderContext';

import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import ShowHideSidebarButton from '../layout/ShowHideSidebarButton';
import AutomaticFormikDirtyCheck from '../forms/AutomaticFormikDirtyCheck';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';

import PeakPeriodTab from './PeakPeriodTab';
import SpotPriceTab from './SpotPriceTab';
import ForwardPriceTab from './ForwardPriceTab';
import ModelDefinitionsTab from './ModelDefinitionsTab';
import ScalarTab from './ScalarTab';
import DartSpreadTab from './DartSpreadTab';

import ConfirmationModal from '../modal/ConfirmationModal';
import MainModal from '../modal/MainModal';

import Error from '../utils/Error';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import Tooltip from '../utils/Tooltip';
import TabListWrapper from '../utils/TabListWrapper';
import ActionBar from '../utils/ActionBar';
import SecondaryButton from '../utils/SecondaryButton';
import CustomItemIcon from '../utils/CustomItemIcon';
import PromptWrapper from '../utils/PromptWrapper';

import UploadButton from '../itemData/UploadButton';
import DownloadModal from '../utils/DownloadModal';

import { ReactComponent as TrendIcon } from '../../icons/trend.svg';
import { ReactComponent as DeleteLoadIcon } from '../../icons/delete.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

import GeneralFormValidationsAlert from '../forms/GeneralFormValidationsAlert';

import { mapGeneralValidationMessages } from '../autoform/utils/autoformUtils';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';
import { helpAnchors } from 'constants/help';

const Basis = ({ onBasisEdit, onBasisDelete, options, basisList }) => {
    const { basisId } = useParams();
    const historyPush = useHistoryPush();
    const { toast } = useCommonToast();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { setSortOrder } = useContext(SortOrderContext);

    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);

    const deleteItemModal = useDisclosure();
    const downloadModal = useDisclosure();
    const deleteBasisWithLinkedObjectsModal = useDisclosure();
    const errorsDisclosure = useDisclosure({ defaultIsOpen: true });
    const warningsDisclosure = useDisclosure({ defaultIsOpen: true });

    const itemId = Number(basisId);
    const item = 'basis';

    const [selectedBasis, setSelectedBasis] = useState(null);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [inEditDescription, setInEditDescription] = useState(false);
    const [key, setKey] = useState(0);
    const [loadingQueue, setLoadingQueue] = useState([]); // unique keys represeting which resource is in loading state
    const [selectedTab, setSelectedTab] = useState(0);
    const [filters, setFilters] = useState(() => getBasisDataFilters(item));
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [issues, setIssues] = useState([]);
    const [fieldErrors, setFieldErrors] = useState([]);
    const [fieldWarnings, setFieldWarnings] = useState([]);
    const [generalErrors, setGeneralErrors] = useState([]);
    const [generalWarnings, setGeneralWarnings] = useState([]);
    const [globalErrors, setGlobalErrors] = useState([]);
    const [globalWarnings, setGlobalWarnings] = useState([]);

    const showAdditionalGridButtons = ![0, 1, 2, 3].includes(selectedTab);

    const allErrors = useMemo(
        () => [...globalErrors, ...generalErrors, ...fieldErrors],
        [fieldErrors, generalErrors, globalErrors]
    );
    const allWarnings = useMemo(
        () => [...globalWarnings, ...generalWarnings, ...fieldWarnings],
        [fieldWarnings, generalWarnings, globalWarnings]
    );

    const refreshTabs = useCallback(() => {
        setKey((prev) => prev + 1);
        setSortOrder((prev) => ({ ...prev, ...refreshMultisort([GRID_KEYS.basisScalar, GRID_KEYS.basisDartSpread]) }));
    }, [setSortOrder]);

    const onLoadingStart = useCallback((key) => {
        setLoadingQueue((prev) => prev.concat(key));
    }, []);

    const onLoadingEnd = useCallback((key) => {
        setLoadingQueue((prev) => prev.filter((p) => p !== key));
    }, []);

    const formLabels = {
        commodity: intl.formatMessage({ id: 'basis_commodity' }),
        priceFormula: intl.formatMessage({ id: 'basis_price_formula' }),
    };

    const initialFormValues = {
        description: selectedBasis?.description,
        commodityId: selectedBasis?.commodityId,
        priceFormulaId: selectedBasis?.priceFormulaId,
    };

    const fetchBasisProperties = useCallback(() => {
        return getBasisById(itemId)
            .then((data) => {
                setSelectedBasis(data);

                // This is needed to show results after previous error
                setLoadingError(false);
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [itemId]);

    useEffect(() => {
        fetchBasisProperties();
    }, [fetchBasisProperties]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const validateDescription = (value) => {
        const trimmed = value.trim();
        const label = intl.formatMessage({ id: 'basis_name' });

        if (trimmed.length === 0) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }

        if (trimmed.length > 50) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label, lengthRule: '50 characters or less' }
            );
        }
    };

    const normalizeIssues = (data, { setStatus, setTouched }) => {
        // in basis we always receive all issues for all supported fields and grid rows
        // smart mutation of issues is not necessary here

        // make sure to re-open the disclosures again,
        // because the user might have closed them and is submitting the form again
        errorsDisclosure.onOpen();
        warningsDisclosure.onOpen();

        if (data.issues) {
            // when creating a single record we expect at most only 1 issue
            const issue = data.issues[0];

            // handle specific field errors
            let fieldStatus = {};

            for (const field of issue.fields) {
                const errors = field.validationMessages
                    .filter(({ severity }) => severity === 'Error')
                    .map(({ message }) => message);

                const warnings = field.validationMessages
                    .filter(({ severity }) => severity === 'Warning')
                    .map(({ message }) => message);

                fieldStatus[field.name] = { errors, warnings };
            }

            const {
                errors: fieldErrorsMessages,
                warnings: fieldWarningsMessages,
                errorFields,
                warningFields,
                generalErrors: generalErrorMessages,
                generalWarnings: generalWarningMessages,
            } = getIssuesMap(data.issues)[0];

            setFieldErrors(fieldErrorsMessages);
            setFieldWarnings(fieldWarningsMessages);
            setGeneralErrors(generalErrorMessages);
            setGeneralWarnings(generalWarningMessages);
            setTouched([...errorFields, ...warningFields].reduce((obj, key) => ({ ...obj, [key]: true })));
            setStatus(fieldStatus);

            // we swap messages in the issues dialog for the row
            setIssues(data.issues);
        }

        if (data.globalIssues) {
            // handle global errors and warnings
            const { warnings, errors } = mapGeneralValidationMessages(data.globalIssues);
            setGlobalWarnings(warnings);
            setGlobalErrors(errors);
        }

        if (!data) {
            errorsDisclosure.onClose();
            warningsDisclosure.onClose();

            // reset formik fields status
            setStatus({});

            // reset all server errors and warnings
            setIssues([]);
            setFieldErrors([]);
            setFieldWarnings([]);
            setGeneralErrors([]);
            setGeneralWarnings([]);
            setGlobalWarnings([]);
            setGlobalErrors([]);
        }
    };

    const onFormSubmit = async ({ description, commodityId, priceFormulaId }, formikBag) => {
        await updateBasis({ ...selectedBasis, description, commodityId, priceFormulaId }, formikBag);

        onBasisEdit({
            id: selectedBasis.basisId,
            description,
        });
    };

    const updateBasis = async (basis, formikBag) => {
        try {
            const successMessage = intl.formatMessage({ id: 'basis_save_changes_success' });

            const { data = {} } = await editBasis(selectedBasis.basisId, { ...basis, useWarnings: true });

            normalizeIssues(data, formikBag);

            setSelectedBasis(basis);
            setInEditDescription(false);

            toast(successMessage);
        } catch (err) {
            if (err.response?.status !== 500) {
                if (err.response?.data?.issues) {
                    normalizeIssues(err.response.data, formikBag);
                }
            }
        }
    };

    const onItemDelete = async (targetBasis) => {
        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'basis' });
        dispatch(onHasUnsavedChanges(false));

        await deleteBasis(targetBasis.basisId);

        onBasisDelete(targetBasis);
        historyPush('/basis');

        toast(successMessage);
    };

    const onEditDescription = (isValid) => {
        if (!isValid) {
            return;
        }
        setInEditDescription((prev) => !prev);
    };

    const onDownloadData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const { startDateFrom, startDateTo } = parseDataFilters(filters);

            const payload = {
                scalarFilter: {
                    startDateFrom,
                    startDateTo,
                    sorting: sortOrder[GRID_KEYS.basisScalar],
                },
                dartSpreadSorting: sortOrder[GRID_KEYS.basisDartSpread],
                exportTemplate,
            };

            return exportItemData(`basis/${itemId}/export`, payload);
        },
        [itemId, filters]
    );

    return (
        <ExpandableItemWrapper>
            {isLoading || Object.keys(options).length === 0 ? (
                <DefinitionsSkeleton />
            ) : loadingError ? (
                <Box m={6}>
                    <Error primaryId="common_error" secondaryId="common_loading_error" additionalText="basis" />
                </Box>
            ) : (
                <>
                    <Formik
                        validateOnChange={false}
                        enableReinitialize
                        initialValues={initialFormValues}
                        onSubmit={onFormSubmit}
                    >
                        {({ isSubmitting, status = {}, isValid, values, setErrors, setStatus, setTouched }) => (
                            <Form>
                                <AutomaticFormikDirtyCheck
                                    isFormDirty={isFormDirty}
                                    onFormDirtyChange={setIsFormDirty}
                                />
                                <Flex pt={3} pl={6} align="center">
                                    <CustomItemIcon isMaster={selectedBasis.securityId} icon={<TrendIcon />} />
                                    {!inEditDescription ? (
                                        <Heading px={2} as="h2" variant="h2">
                                            {values.description}
                                        </Heading>
                                    ) : (
                                        <InputFormikField
                                            px={3}
                                            flex="1"
                                            maxW="500px"
                                            validate={(value) => validateDescription(value)}
                                            name="description"
                                            type="text"
                                            onKeyDown={checkItemInputEvent}
                                            isFastField
                                        />
                                    )}

                                    <Box display="inline-block" mr={4}>
                                        <Tooltip
                                            label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}
                                        >
                                            <IconButton
                                                aria-label={intl.formatMessage({
                                                    id: 'common_edit_btn_tooltip_and_label',
                                                })}
                                                variant="circular-icon"
                                                leftIcon={<EditIcon />}
                                                onClick={() => onEditDescription(isValid)}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Flex>

                                <ActionBar>
                                    <ShowHideSidebarButton />

                                    <Spacer display={{ base: 'none', xl: 'block' }} />

                                    <Button
                                        leftIcon={<DeleteLoadIcon />}
                                        type="button"
                                        variant="special"
                                        onClick={
                                            selectedBasis.linkedObjectsCount
                                                ? deleteBasisWithLinkedObjectsModal.onOpen
                                                : deleteItemModal.onOpen
                                        }
                                        size="sm"
                                    >
                                        <FormattedMessage id="basis_delete_btn" />
                                    </Button>

                                    <Button
                                        leftIcon={<SaveIcon />}
                                        isDisabled={
                                            !isValid ||
                                            status.commodityId?.errors.length > 0 ||
                                            status.priceFormulaId?.errors.length > 0
                                        }
                                        isLoading={isSubmitting}
                                        type="submit"
                                        size="sm"
                                        variant="primary-success"
                                    >
                                        <FormattedMessage id="common_save_changes_button" />
                                    </Button>
                                </ActionBar>

                                <Divider />

                                <SimpleGrid py={5} px={6} columns={{ xl: 3 }} spacingX={6}>
                                    <ComboboxFormikField
                                        id="commodityId"
                                        name="commodityId"
                                        options={mapCommoditiesByTypeAndDescription(options.commodityTypes)}
                                        valueKey="id"
                                        labelKey="description"
                                        label={formLabels.commodity}
                                        placeholderValue=""
                                        placeholderLabel={intl.formatMessage({ id: 'common_forms_select_option' })}
                                        isRequired
                                    />

                                    <ComboboxFormikField
                                        id="priceFormulaId"
                                        name="priceFormulaId"
                                        options={options.priceFormula}
                                        valueKey="id"
                                        labelKey="description"
                                        label={formLabels.priceFormula}
                                        showPlaceholder={true}
                                        placeholderValue={null}
                                    />
                                </SimpleGrid>

                                {allErrors.length > 0 && (
                                    <GeneralFormValidationsAlert
                                        mx={6}
                                        w="unset"
                                        isOpen={errorsDisclosure.isOpen}
                                        onClose={errorsDisclosure.onClose}
                                        messages={allErrors}
                                    />
                                )}

                                {allWarnings.length > 0 && (
                                    <GeneralFormValidationsAlert
                                        mx={6}
                                        w="unset"
                                        status="warning"
                                        title={<FormattedMessage id="common_warnings" />}
                                        isOpen={warningsDisclosure.isOpen}
                                        onClose={warningsDisclosure.onClose}
                                        messages={allWarnings}
                                    />
                                )}

                                <Divider />

                                <Tabs
                                    px={6}
                                    isLazy
                                    isManual
                                    lazyBehavior="keepMounted"
                                    key={key}
                                    index={selectedTab}
                                    onChange={setSelectedTab}
                                >
                                    <CustomTabList
                                        w="100%"
                                        flexWrap={{ base: 'wrap', xl: 'nowrap' }}
                                        alignItems="center"
                                    >
                                        <TabListWrapper currentTab={selectedTab} tabCount={7} minW={0}>
                                            <CustomTab>
                                                <FormattedMessage id="basis_tab_peak_periods" />
                                                <HelpButtonComponent helpAnchor={helpAnchors.BASIS_PEAK_PERIOD} />
                                            </CustomTab>

                                            <CustomTab>
                                                <FormattedMessage id="basis_tab_model_definition" />
                                                <HelpButtonComponent helpAnchor={helpAnchors.BASIS_MODEL_DEFINITION} />
                                            </CustomTab>

                                            <CustomTab>
                                                <FormattedMessage id="basis_tab_forward_price" />
                                                <HelpButtonComponent helpAnchor={helpAnchors.BASIS_FORWARD_PRICE} />
                                            </CustomTab>

                                            <CustomTab>
                                                <FormattedMessage id="basis_tab_spot_price" />
                                                <HelpButtonComponent helpAnchor={helpAnchors.BASIS_SPOT_PRICE} />
                                            </CustomTab>

                                            <CustomTab>
                                                <FormattedMessage id="basis_tab_scaler" />
                                                <HelpButtonComponent helpAnchor={helpAnchors.BASIS_SCALER} />
                                            </CustomTab>

                                            <CustomTab isDisabled={true}>
                                                <Tooltip
                                                    label={intl.formatMessage({
                                                        id: 'common_feature_in_development',
                                                    })}
                                                >
                                                    <FormattedMessage id="basis_tab_sub-hourly_details" />
                                                </Tooltip>
                                            </CustomTab>

                                            <CustomTab>
                                                <FormattedMessage id="basis_tab_dart_spread" />
                                            </CustomTab>
                                        </TabListWrapper>

                                        <Flex ml="auto" order={{ base: -1, xl: 0 }} w={{ base: '100%', xl: 'auto' }}>
                                            {showAdditionalGridButtons && (
                                                <>
                                                    <SecondaryButton
                                                        leftIcon={<DownloadIcon />}
                                                        w="auto !important"
                                                        mx={2}
                                                        size="sm"
                                                        type="button"
                                                        variant="secondary"
                                                        onClick={downloadModal.onOpen}
                                                    >
                                                        <FormattedMessage id="common_download_btn_tooltip_and_label" />
                                                    </SecondaryButton>

                                                    <UploadButton
                                                        type="basis"
                                                        itemId={itemId}
                                                        hasLabel={true}
                                                        mr={4}
                                                        w="auto !important"
                                                        onAfterUploadChange={refreshTabs}
                                                    />
                                                </>
                                            )}

                                            <SecondaryButton
                                                onClick={refreshTabs}
                                                size="sm"
                                                variant="secondary"
                                                leftIcon={<RefreshIcon />}
                                                isLoading={loadingQueue.length > 0}
                                                loadingText={intl.formatMessage({ id: 'common_loading' })}
                                                textTransform="capitalize"
                                            >
                                                <FormattedMessage id="common_refresh" />
                                            </SecondaryButton>
                                        </Flex>
                                    </CustomTabList>

                                    <TabPanels mt={2}>
                                        <TabPanel>
                                            <PeakPeriodTab
                                                options={options}
                                                basis={selectedBasis}
                                                updateBasis={(basis) =>
                                                    updateBasis(basis, { setStatus, setTouched, setErrors })
                                                }
                                                issues={issues}
                                            />
                                        </TabPanel>

                                        <TabPanel>
                                            <ModelDefinitionsTab
                                                options={options}
                                                basis={selectedBasis}
                                                updateBasis={(basis) =>
                                                    updateBasis(basis, { setStatus, setTouched, setErrors })
                                                }
                                                issues={issues}
                                            />
                                        </TabPanel>

                                        <TabPanel>
                                            <ForwardPriceTab
                                                options={options}
                                                basis={selectedBasis}
                                                updateBasis={(basis) =>
                                                    updateBasis(basis, { setStatus, setTouched, setErrors })
                                                }
                                                issues={issues}
                                            />
                                        </TabPanel>

                                        <TabPanel>
                                            <SpotPriceTab
                                                options={options}
                                                basis={selectedBasis}
                                                updateBasis={(basis) =>
                                                    updateBasis(basis, { setStatus, setTouched, setErrors })
                                                }
                                                issues={issues}
                                            />
                                        </TabPanel>

                                        <TabPanel>
                                            <ScalarTab
                                                tab="1"
                                                name={GRID_KEYS.basisScalar}
                                                options={options}
                                                basis={selectedBasis}
                                                filters={filters}
                                                onFiltersChange={setFilters}
                                                item={item}
                                                onLoadingStart={onLoadingStart}
                                                onLoadingEnd={onLoadingEnd}
                                            />
                                        </TabPanel>

                                        <TabPanel />

                                        <TabPanel>
                                            <DartSpreadTab
                                                tab="2"
                                                name={GRID_KEYS.basisDartSpread}
                                                options={options}
                                                basis={selectedBasis}
                                                basisList={basisList}
                                                updateBasis={(basis) =>
                                                    updateBasis(basis, { setStatus, setTouched, setErrors })
                                                }
                                                onLoadingStart={onLoadingStart}
                                                onLoadingEnd={onLoadingEnd}
                                            />
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Form>
                        )}
                    </Formik>

                    {deleteItemModal.isOpen && (
                        <ConfirmationModal
                            isOpen
                            onClose={deleteItemModal.onClose}
                            header={<FormattedMessage id="common_delete_modal_heading" values={{ item: 'Basis' }} />}
                            hasExtraStep
                            content={
                                <FormattedMessage
                                    id="common_delete_modal_msg"
                                    values={{
                                        item: selectedBasis.description,
                                    }}
                                />
                            }
                            confirmText={
                                <Box as="span" textTransform="capitalize">
                                    <FormattedMessage id="common_delete" />
                                </Box>
                            }
                            onConfirm={() => {
                                deleteItemModal.onClose();
                                onItemDelete(selectedBasis);
                            }}
                        />
                    )}

                    {deleteBasisWithLinkedObjectsModal.isOpen && (
                        <MainModal
                            isOpen
                            size="md"
                            onClose={deleteBasisWithLinkedObjectsModal.onClose}
                            header={<FormattedMessage id="common_delete_modal_heading" values={{ item: 'Basis' }} />}
                            content={
                                <Box mb={5}>
                                    {selectedBasis.linkedArchivedObjects ? (
                                        <FormattedMessage
                                            id="basis_delete_modal_linked_archived_objects_msg"
                                            values={{
                                                count: selectedBasis.linkedObjectsCount,
                                                archived: selectedBasis.linkedArchivedObjects,
                                            }}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="basis_delete_modal_linked_objects_msg"
                                            values={{
                                                count: selectedBasis.linkedObjectsCount,
                                            }}
                                        />
                                    )}
                                </Box>
                            }
                            footerRightSlot={
                                <Button
                                    ml={3}
                                    type="button"
                                    variant="special"
                                    onClick={() => {
                                        deleteItemModal.onClose();
                                        onItemDelete(selectedBasis);
                                    }}
                                >
                                    <Box as="span" textTransform="capitalize">
                                        <FormattedMessage id="basis_unlink_and_delete" />
                                    </Box>
                                </Button>
                            }
                        />
                    )}
                </>
            )}

            {downloadModal.isOpen && (
                <DownloadModal
                    isOpen
                    bodyMessage={<FormattedMessage id="basis_download_modal_explanation" />}
                    onClose={downloadModal.onClose}
                    onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                    onDownloadTemplate={() => onDownloadData(true)}
                />
            )}
            <PromptWrapper
                when={hasUnsavedChanges}
                header={<FormattedMessage id="common_discard_changes" />}
                content={<FormattedMessage id="common_confirmation_explanation" />}
            />
        </ExpandableItemWrapper>
    );
};

const CustomTabList = styled(TabList)`
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 5px;
    border-bottom: 1px solid var(--chakra-colors-gray-200);
`;

const CustomTab = styled(Tab)`
    margin-right: 24px;
    margin-bottom: 0;
    white-space: nowrap;

    .tooltip_wrapper {
        transform: translate(-50%, -110%) !important;
    }
`;

export default Basis;
