import styled from '@emotion/styled/macro';

const FormWarningMessage = styled.div`
    display: flex;
    align-items: center;
    color: var(--chakra-colors-orange-300);
    border-color: var(--chakra-colors-orange-300);
    margin-top: var(--chakra-space-2);
    font-size: var(--chakra-fontSizes-sm);
`;

export default FormWarningMessage;
