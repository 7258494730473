import { Skeleton, Stack, HStack, Box } from '@chakra-ui/react';

const DynamicPropertiesSkeleton = () => {
    return (
        <Box mt={3}>
            <HStack>
                <Box w="120px">
                    <Skeleton height="40px" />
                </Box>
                <Box w="120px">
                    <Skeleton height="40px" />
                </Box>
                <Box w="120px">
                    <Skeleton height="40px" />
                </Box>
                <Box w="120px">
                    <Skeleton height="40px" />
                </Box>
            </HStack>
            <Stack mt={3}>
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
            </Stack>
        </Box>
    );
};

export default DynamicPropertiesSkeleton;
