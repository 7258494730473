import React, { useMemo } from 'react';
import { Box, Button, Flex, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import useNavigation from '../../../hooks/useNavigation';
import Masonry from 'react-masonry-component';
import styled from '@emotion/styled/macro';
import StyledStandaloneIconWrapper from '../StyledStandaloneIconWrapper';

const menuVisualSettings = Object.freeze({
    itemHorizontalSpacing: 12,
    itemVerticalSpacing: 16,
    itemWidth: 261,
    wrapperSpacing: 10,
    getWrapperWidth: function (itemsCount) {
        let actualColumns = 1;

        if (itemsCount > 9 && itemsCount <= 18) {
            actualColumns = 2;
        } else if (itemsCount > 18 && itemsCount <= 27) {
            actualColumns = 3;
        } else if (itemsCount > 27) {
            actualColumns = 4;
        }

        return (
            this.itemWidth * actualColumns +
            this.itemHorizontalSpacing * (actualColumns > 0 ? actualColumns - 1 : 0) +
            this.wrapperSpacing * 2
        );
    },
});

const NavigationMenu = ({ config, renderItem }) => {
    const { assetsMap } = useNavigation();

    const menuType = useMemo(
        () => (config?.items?.length && config.items[0].type === 'MenuItem' ? 'MenuOfLinks' : 'MenuOfGroups'),
        [config?.items]
    );

    const wrapperWidth = useMemo(() => {
        let columnsCount = 0;

        if (menuType === 'MenuOfGroups') {
            columnsCount = config?.items?.reduce((previousValue, currentValue) => {
                return previousValue + 1 + (currentValue?.items?.length ?? 0);
            }, 0);
        }

        return menuVisualSettings.getWrapperWidth(columnsCount);
    }, [config?.items, menuType]);

    return (
        <Menu placement={config?.desktopConfig?.menuPlacement ?? 'bottom'}>
            <StyledMenuButton as={Button} variant="ghost">
                {config.label ? (
                    <Flex alignItems={'center'} borderRadius={6}>
                        {config.icon}

                        <StyledText
                            withGap={!config.icon}
                            labelMaxWidth={config?.desktopConfig?.labelMaxWidth}
                            isTruncated
                        >
                            {config.label}
                        </StyledText>

                        {assetsMap?.dropdownArrow}
                    </Flex>
                ) : (
                    <StyledStandaloneIconWrapper data-comp="standalone-icon-wrapper">
                        {config.icon}
                    </StyledStandaloneIconWrapper>
                )}
            </StyledMenuButton>

            <StyledMenuList wrapperWidth={wrapperWidth} bg="background-tertiary">
                <Masonry
                    options={{
                        transitionDuration: 0,
                        columnWidth: menuVisualSettings.itemWidth,
                        itemSelector: '.masonry-item',
                        horizontalOrder: true,
                        containerStyle: {
                            maxWidth: wrapperWidth,
                            position: 'relative',
                            padding: `${menuVisualSettings.wrapperSpacing}px`,
                            marginBottom: `-${menuVisualSettings.itemVerticalSpacing}px`,
                        },
                        gutter: menuVisualSettings.itemHorizontalSpacing,
                    }}
                >
                    {menuType === 'MenuOfLinks' ? (
                        <StyledMasonryItem className="masonry-item">
                            {config.items.map((menuItem, menuItemIndex) => (
                                <React.Fragment key={menuItemIndex}>{renderItem(menuItem)}</React.Fragment>
                            ))}
                        </StyledMasonryItem>
                    ) : (
                        config.items.map((menuItem, menuItemIndex) => (
                            <StyledMasonryItem key={menuItemIndex} className="masonry-item">
                                {renderItem(menuItem)}
                            </StyledMasonryItem>
                        ))
                    )}
                </Masonry>
            </StyledMenuList>
        </Menu>
    );
};

const StyledMenuButton = styled(MenuButton)`
    padding: 0;
    min-width: 0;
    min-height: 0;

    &[data-active],
    &:hover {
        [data-comp='navigation-asset'] {
            color: var(--chakra-colors-blue-400);
        }
    }

    &[data-active] {
        [data-comp='standalone-icon-wrapper'] {
            background-color: var(--chakra-colors-blue-100);
        }
    }
`;

const StyledText = styled(Text, { shouldForwardProp: (prop) => !['withGap', 'labelMaxWidth'].includes(prop) })`
    color: var(--chakra-colors-gray-800);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-left: ${(props) => (props.withGap ? 'var(--chakra-space-2)' : 0)};
    max-width: ${(props) => (props.labelMaxWidth ? `${props.labelMaxWidth}px` : 'auto')};
`;

const StyledMenuList = styled(MenuList, { shouldForwardProp: (prop) => prop !== 'wrapperWidth' })`
    box-sizing: content-box;
    padding: 0;
    position: relative;
    width: ${(props) => props.wrapperWidth}px;
`;

const StyledMasonryItem = styled(Box)`
    width: ${menuVisualSettings.itemWidth}px;
    margin-bottom: ${menuVisualSettings.itemVerticalSpacing}px;
`;

export default NavigationMenu;
