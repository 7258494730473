import { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';
import SecondaryButton from '../utils/SecondaryButton';
import useCommonToast from '../../hooks/useCommonToast';

import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';

const DeleteAllButton = ({
    selectedView,
    isInTabularTab = false,
    subItemId,
    refetchData,
    onDelete,
    onSuccess,
    disabled = false,
    modalHeader,
    modalContent,
    ...rest
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const deleteAllModal = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);

    const deleteAllOnConfirm = async () => {
        setIsLoading(true);
        const successMessage = intl.formatMessage({ id: 'common_delete_all_modal_on_success' });

        try {
            await onDelete(subItemId);
            toast(successMessage);
            refetchData();
            if (onSuccess) {
                onSuccess();
            }
            setIsLoading(false);
            deleteAllModal.onClose();
        } catch (e) {
            setIsLoading(false);
            throw e;
        }
    };

    return (
        <>
            <SecondaryButton
                onClick={deleteAllModal.onOpen}
                leftIcon={<DeleteIcon />}
                size="sm"
                type="button"
                variant="special"
                textTransform="capitalize"
                disabled={disabled}
                {...rest}
            >
                <FormattedMessage id="common_delete_all_btn" />
            </SecondaryButton>

            <DeleteConfirmationModal
                isOpen={deleteAllModal.isOpen}
                onClose={deleteAllModal.onClose}
                onConfirm={deleteAllOnConfirm}
                isLoading={isLoading}
                header={modalHeader}
                content={modalContent}
            />
        </>
    );
};

export default DeleteAllButton;
