import { IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as ContextMenuIcon } from '../../icons/kebab-menu.svg';

const SubItemActionsMenu = ({ menuListItems }) => {
    return (
        <MenuWrapper className="subitem-context-menu" tabIndex={-1}>
            <Menu>
                <MenuButton as={IconButton} icon={<ContextMenuIcon />} variant="ghost" />
                <MenuList>{menuListItems}</MenuList>
            </Menu>
        </MenuWrapper>
    );
};

const MenuWrapper = styled.div`
    margin-right: 10px;

    > button {
        width: 24px;
        min-width: 24px;
        height: 24px;
        min-height: 24px;

        &,
        &:active,
        &[data-active] {
            background: none;
        }
    }
`;

export default SubItemActionsMenu;
