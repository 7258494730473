import { useState, useImperativeHandle, useEffect, useRef, forwardRef } from 'react';

const DataGridCheckboxEditor = forwardRef((props, ref) => {
    const inputRef = useRef();
    const [value, setValue] = useState(props.value ? '1' : '0');

    useEffect(() => {
        setTimeout(() => {
            inputRef.current.focus();
            inputRef.current.select();
        });
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
        };
    });

    return (
        <input
            ref={inputRef}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={props.column.getColDef().headerName}
        />
    );
});

export default DataGridCheckboxEditor;
