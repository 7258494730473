const Tooltip = {
    baseStyle: {
        borderRadius: 'md',
        bg: 'gray.500',
        color: 'white',
        fontSize: '10px',
    },
};

export default Tooltip;
