import { ReactComponent as RightArrow } from '../../icons/right-arrow.svg';
import { ReactComponent as DownArrow } from '../../icons/arrow_drop_down.svg';

const HydroArrowIcon = ({ isExpanded }) => {
    const Icon = isExpanded ? DownArrow : RightArrow;

    return <Icon role="presentation" className="hydro-label-icon arrow-icon" />;
};

export default HydroArrowIcon;
