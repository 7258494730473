import { useState } from 'react';
import { Button, Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import TextareaField from '../forms/TextareaField';

import MainModal from '../modal/MainModal';

const EquationsModal = ({ node, ...rest }) => {
    const [error, setError] = useState({ disabled: false, message: '' });
    const [input, setInput] = useState(node.data.varEquation);

    const intl = useIntl();

    const onFormSubmit = () => {
        node.setDataValue('varEquation', input);
        rest.onClose();
    };

    const onEditData = (value) => {
        setInput(value);
        if (value.length > 1000) {
            setError((error) => ({
                ...error,
                message: intl.formatMessage(
                    { id: 'common_forms_validation_length' },
                    { label: 'Equation', lengthRule: '1000 characters or less' }
                ),
            }));
        } else {
            setError((error) => ({ ...error, message: '' }));
        }
    };
    return (
        <MainModal
            {...rest}
            isOpen
            onClose={rest.onClose}
            header={`${node.data.source}: ${node.data.description}`}
            content={
                <>
                    <Text variant="regular" mb={2}>
                        <FormattedMessage id="variables_grid_equation" />
                    </Text>
                    <TextareaField
                        isInvalid={error.message}
                        onChange={(evt) => onEditData(evt.target.value)}
                        placeholder={intl.formatMessage({ id: 'common_add_rows_input_placeholder' })}
                        error={error.message}
                        value={input}
                    />
                </>
            }
            secondaryButton={
                <Button onClick={rest.onClose} variant="secondary" mr={3} textTransform="capitalize">
                    <FormattedMessage id="common_cancel" />
                </Button>
            }
            footerRightSlot={
                <Button onClick={onFormSubmit} textTransform="capitalize">
                    <FormattedMessage id="common_continue" />
                </Button>
            }
        />
    );
};

export default EquationsModal;
