import { useState, useEffect } from 'react';
import { getSubItems } from 'services/globalConstraints';

// TODO: Abstract this common loading hook logic into a hook
const useGlobalConstraintsSubItems = (itemIds) => {
    const [subItems, setSubItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (itemIds.length) {
            setIsLoading(true);
            setIsError(false);

            getSubItems(itemIds)
                .then((response) => {
                    setSubItems(response);
                })
                .catch(() => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setSubItems([]);
        }
    }, [itemIds]);

    return { subItems, isLoading, isError };
};

export default useGlobalConstraintsSubItems;
