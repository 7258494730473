import { useState, useEffect, useMemo } from 'react';
import { getPermissionsForRole } from '../../services/userManagement';

const useGroupPermissions = (roleId) => {
    const [groupPermissions, setGroupPermissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (roleId) {
            setIsLoading(true);
            setIsError(false);

            getPermissionsForRole(roleId)
                .then((response) => {
                    setGroupPermissions(response);
                })
                .catch(() => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [roleId]);

    return useMemo(
        () => ({
            groupPermissions,
            isLoading,
            isError,
            setGroupPermissions,
        }),
        [groupPermissions, isLoading, isError]
    );
};
export default useGroupPermissions;
