export const UPLOAD_TABS_ALL = 'all';
export const UPLOAD_TABS_CURRENT = 'current';
export const UPDATE_TYPE_UPDATE = 'update';
export const UPDATE_TYPE_REPLACE = 'replace';

export const SUBSCHEMACODE_BATTERY = 'PSBATSTO';
export const SUBSCHEMACODE_THERMAL = 'PSGENASSED';
export const SUBSCHEMACODE_RENEWABLES = 'PSWIND';

export const FINISHED_STATUSES = [4, 5, 6];
export const INITIAL_STATUS = -1;
export const SUCCESS_STATUS = 4;
export const SUCCESS_WARNING_STATUS = 5;
export const FAIL_STATUS = 6;

export const DEFAULT_HUB_CONNECTION_URL = `${process.env.REACT_APP_API}/hubs/uploadprogress`;
