import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Box, Text, Divider } from '@chakra-ui/react';

import { FormattedMessage, useIntl } from 'react-intl';

import { getVariables, getVariablesSources, createVariableGroup, editVariableGroup } from '../../services/studies';

import useCommonToast from '../../hooks/useCommonToast';

import MainModal from '../modal/MainModal';

import Error from '../utils/Error';
import SimpleGridSkeleton from '../utils/SimpleGridSkeleton';
import SearchBar from '../utils/SearchBar';

import InputField from '../forms/InputField';
import ComboboxField from '../forms/ComboboxField';

import VariablesList from './VariablesList';

import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';

const VariableGroupModal = ({
    onCreateVariableGroupSuccess,
    onEditVariableGroupSuccess,
    onClose,
    variableGroup,
    actionType,
    onVariableGroupDelete,
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const bookId = useSelector((state) => state.connections.book);

    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);

    const [allVariables, setAllVariables] = useState([]);
    const [allSources, setAllSources] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [sourceFilter, setSourceFilter] = useState('');

    const [groupName, setGroupName] = useState(variableGroup ? variableGroup.description : '');
    const [variables, setVariables] = useState(
        variableGroup
            ? variableGroup.variables.map(({ variableName, variableId, source }) => ({
                  variableName,
                  variableId,
                  source,
              }))
            : []
    );

    const [isVariablesListTouched, setIsVariablesListTouched] = useState(false);
    const [groupNameError, setGroupNameError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateGroupName = useCallback(
        (val) => {
            if (val.trim().length) {
                setGroupNameError('');
            } else {
                setGroupNameError(intl.formatMessage({ id: 'run_a_study_variable_group_name_required' }));
            }
        },
        [intl]
    );

    const onGroupNameChange = useCallback(
        (event) => {
            const name = event.target.value;

            validateGroupName(name);
            setGroupName(name);
        },
        [validateGroupName]
    );

    const onGroupNameBlur = useCallback(
        (event) => {
            validateGroupName(event.target.value);
        },
        [validateGroupName]
    );

    const isVariableChecked = useCallback(
        (variable) => {
            return variables.some(({ variableId }) => variable.variableId === variableId);
        },
        [variables]
    );

    const onVariableCheckedChange = useCallback((variable, isChecked) => {
        setIsVariablesListTouched(true);

        setVariables((prev) =>
            isChecked ? prev.concat({ ...variable }) : prev.filter((v) => v.variableId !== variable.variableId)
        );
    }, []);

    const onSubmit = useCallback(async () => {
        setIsVariablesListTouched(true);
        validateGroupName(groupName);

        if (groupName.length > 0 && variables.length > 0) {
            setIsSubmitting(true);

            try {
                const selectedVariableIds = variables.map(({ variableId }) => variableId);

                if (actionType === 'edit') {
                    const updatedVariableGroup = await editVariableGroup(variableGroup.variableGroupId, {
                        name: groupName,
                        selectedVariableIds,
                    });

                    setIsSubmitting(false);
                    toast(intl.formatMessage({ id: 'run_a_study_edit_variable_group_success' }));

                    onEditVariableGroupSuccess(updatedVariableGroup);
                } else {
                    const newVariableGroup = await createVariableGroup({
                        description: groupName,
                        bookId,
                        variableIds: selectedVariableIds,
                    });

                    setIsSubmitting(false);
                    toast(intl.formatMessage({ id: 'run_a_study_create_variable_group_success' }));

                    onCreateVariableGroupSuccess(newVariableGroup);
                }
            } catch (err) {
                toast({
                    status: 'error',
                    message:
                        err.response?.data.code === 400
                            ? err.response.data.error
                            : intl.formatMessage({ id: 'common_generic_saving_error' }),
                });

                setIsSubmitting(false);
            }
        }
    }, [
        validateGroupName,
        groupName,
        variables,
        actionType,
        bookId,
        intl,
        onCreateVariableGroupSuccess,
        onEditVariableGroupSuccess,
        toast,
        variableGroup?.variableGroupId,
    ]);

    const filteredVariables = useMemo(() => {
        const term = searchTerm.trimStart().toUpperCase();

        if (term.length < 2 && !sourceFilter) {
            return allVariables;
        }

        return allVariables.filter(({ variableName, source }) => {
            const isNameMatch = variableName.toUpperCase().includes(term);
            const isSourceMatch = sourceFilter ? source === sourceFilter : true;

            return isNameMatch && isSourceMatch;
        });
    }, [allVariables, searchTerm, sourceFilter]);

    useEffect(() => {
        Promise.all([getVariables(), getVariablesSources()])
            .then(([variables, sources]) => {
                setAllVariables(
                    variables.map(({ varName, variableId, source }) => ({ variableName: varName, variableId, source }))
                );

                // old code has guards against empty sources (which are strings)
                // so we can't be sure if the old system allows empty sources or not and we just remove them
                setAllSources(sources.filter(Boolean));
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <MainModal
            size="4xl"
            scrollBehavior="inside"
            isOpen
            onClose={onClose}
            header={
                actionType === 'edit' ? (
                    <FormattedMessage id="run_a_study_edit_variable_group" />
                ) : (
                    <FormattedMessage id="run_a_study_create_a_new_variables_group" />
                )
            }
            content={
                <>
                    <Box>
                        <InputField
                            id="groupName"
                            name="groupName"
                            type="text"
                            label={<FormattedMessage id="run_a_study_variable_group_name" />}
                            isRequired
                            isInvalid={groupNameError}
                            error={groupNameError}
                            value={groupName}
                            onChange={onGroupNameChange}
                            onBlur={onGroupNameBlur}
                        />
                    </Box>

                    <Divider my={4} />

                    <ComboboxField
                        id="variableSource"
                        name="variableSource"
                        label={intl.formatMessage({ id: 'run_a_study_variable_source' })}
                        options={allSources}
                        showPlaceholder={true}
                        placeholderLabel={intl.formatMessage({ id: 'common_all' })}
                        placeholderValue=""
                        value={sourceFilter}
                        onChange={setSourceFilter}
                    />

                    <Box my={4}>
                        <SearchBar
                            textHolder={intl.formatMessage({ id: 'run_a_study_search_variables' })}
                            searchTerm={searchTerm}
                            onChange={setSearchTerm}
                        />
                    </Box>

                    {isLoading ? (
                        <SimpleGridSkeleton rows={12} cols={3} />
                    ) : loadingError ? (
                        <Box mb={4}>
                            <Error
                                primaryId="common_error"
                                secondaryId="common_loading_error"
                                additionalText="variables data"
                            />
                        </Box>
                    ) : (
                        <Box mb={4}>
                            <Text size="sm">
                                <FormattedMessage id="run_a_study_select_variables" />

                                <Box as="span" role="presentation" aria-hidden="true" ml={1} color="red.500">
                                    *
                                </Box>
                            </Text>

                            <VariablesList
                                variables={filteredVariables}
                                isVariableChecked={isVariableChecked}
                                onVariableCheckedChange={onVariableCheckedChange}
                                isListInvalid={isVariablesListTouched && variables.length === 0}
                            />

                            {isVariablesListTouched && variables.length === 0 && (
                                <Text size="sm" color="text-error" variant="regular" mt={2}>
                                    <FormattedMessage id="run_a_study_variable_group_selection_required" />
                                </Text>
                            )}
                        </Box>
                    )}
                </>
            }
            footerRightSlot={
                <Button
                    isDisabled={groupNameError || (isVariablesListTouched && variables.length === 0)}
                    isLoading={isSubmitting}
                    onClick={onSubmit}
                    ml={3}
                >
                    {actionType === 'edit' ? (
                        <FormattedMessage id="common_save_changes_button" />
                    ) : (
                        <FormattedMessage id="run_a_study_create_variable_group" />
                    )}
                </Button>
            }
            footerLeftSlot={
                <Button
                    leftIcon={<DeleteIcon />}
                    type="button"
                    variant="special"
                    onClick={() => onVariableGroupDelete(variableGroup)}
                    size="sm"
                >
                    <FormattedMessage id="variables_delete_group" />
                </Button>
            }
        />
    );
};

export default VariableGroupModal;
