import { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { loadModules } from 'esri-loader';
import styled from '@emotion/styled/macro';
import {
    useDisclosure,
    Heading,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Box,
    Button,
    Flex,
    Link,
    Spinner,
    Text,
} from '@chakra-ui/react';

import Combobox from '../forms/Combobox';
import ScreenshotModal from '../utils/ScreenshotModal';
import Popover from '../utils/Popover';
import CustomIconButton from '../utils/CustomIconButton';
import Bookmarks from '../maps/Bookmarks';
import Pins from '../maps/Pins';
import ValidateCustomLayer from './ValidateCustomLayer';

import MapTreeNode from './MapTreeNode';
import MapFilter from './MapFilter';
import { isoConfig } from '../../services/portfolios/isoConfig';
import { downloadScreenshot, generateContinuousColorRenderer, organizeMapLayers } from '../../services/maps';
import { allISO } from '../../constants/portfolios';

import { ReactComponent as InfoIcon } from '../../icons/info.svg';

const MapDrawer = ({
    layers,
    map,
    view,
    pins,
    pinsLayer,
    bookmarks,
    onPinCreateSuccess,
    onPinDeleteSuccess,
    onBookmarkCreateSuccess,
    onBookmarkDeleteSuccess,
}) => {
    const [allLayers, setAllLayers] = useState(organizeMapLayers(layers, view));
    const [selected, setSelected] = useState();
    const [filters, setFilters] = useState([]);
    const [filterResult, setFilterResult] = useState();
    const [currentIsoConfig, setCurrentIsoConfig] = useState();
    const [study, setStudy] = useState();
    const [year, setYear] = useState();
    const [currentTab, setTab] = useState(0);
    const [histogramLoading, setHistogramLoading] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);

    const filtersInfo = useRef([]);
    const selectedScreenshot = useRef(null);

    const screenshotModal = useDisclosure();

    useEffect(() => {
        const eventHandler = view.watch('scale', () => {
            let newLayers = organizeMapLayers(layers, view);
            setAllLayers([...newLayers]);
        });

        return function cleanup() {
            eventHandler.remove();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAllLayers(organizeMapLayers(layers, view));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layers]);

    const changeExtent = async (layer) => {
        const extent = await layer.queryExtent();
        view.goTo(extent);
    };

    const onStudyChange = (studyName) => {
        const study = currentIsoConfig.studies.find((elem) => elem.name === studyName);
        setStudy(study);

        const defaultRendererField = currentIsoConfig.defaultRendererField;
        const firstYear = study?.years[0];
        // Check if study has ISO's default field
        const defaultYear = study?.years.find(
            (year) => year.fieldName?.toUpperCase() === defaultRendererField?.toUpperCase()
        );
        const selectYear = defaultYear ? defaultYear : firstYear;
        setYear(selectYear);
        histogramColorRenderer(selectYear?.fieldName);
    };

    const onYearChange = (fieldName) => {
        const newYear = study.years.find((elem) => elem.fieldName === fieldName);
        setYear(newYear);
        histogramColorRenderer(fieldName);
    };

    const takeScreenshot = async (action) => {
        const screenshot = await view.takeScreenshot();
        const date = new Date();

        if (action === 'preview') previewScreenshot(screenshot.dataUrl);
        if (action === 'download') downloadScreenshot(screenshot.dataUrl, date.toLocaleString());
    };

    const previewScreenshot = async (imageUrl) => {
        selectedScreenshot.current = imageUrl;
        screenshotModal.onOpen();
    };

    const histogramColorRenderer = async (fieldName, layer) => {
        setHistogramLoading(true);
        const colorName = 'Blue and Red 9';
        const theme = 'above-and-below';
        const isVislble = true;

        await generateContinuousColorRenderer(view, layer ? layer : selected, fieldName, colorName, theme, isVislble);
        setHistogramLoading(false);
    };

    const hideOtherLayers = (layerTitle) => {
        // Sets all layers to opacity 0%, except the selected the layer
        for (let layer of allLayers) {
            layer.effect = '';
            if (layer.title !== layerTitle) {
                layer.effect = 'opacity(0%)';

                if (layer.childrenNodes && layer.childrenNodes.length > 0) {
                    for (let sublayer of layer.childrenNodes) {
                        sublayer.effect = '';
                        if (sublayer.title !== layerTitle) {
                            sublayer.effect = 'opacity(0%)';
                        }
                    }
                }
            }
        }
    };

    const changeIsoHandler = (isoLayerName) => {
        if (isoLayerName) {
            //Clear filters, results, and highlights
            clearFilters();

            //Find the selected iso layer
            const iso_layer = allLayers.find((elem) => elem.id === 'iso_node');
            const selectedLayer = iso_layer.childrenNodes.find(
                (elem) => elem.title?.toUpperCase() === isoLayerName?.toUpperCase()
            );

            setSelected(selectedLayer);

            //Get the new ISO layer config values
            let layer_config = isoConfig.find((elem) => elem.name?.toUpperCase() === isoLayerName?.toUpperCase());
            if (!layer_config) return;

            // Title could be different from year number, if it exists then use title property for display in drop down
            let studies = layer_config.studies?.map((study) => {
                const yearsWithTitle = study.years?.map((year) => {
                    const isForecasted = year.isForecasted;
                    let yearTitle = year.title ? year.title : year.year.toString();
                    yearTitle = isForecasted ? yearTitle + ' Forecast' : yearTitle;
                    return { ...year, title: yearTitle };
                });
                const changeStudy = study;
                changeStudy.years = yearsWithTitle;
                return changeStudy;
            });

            layer_config.studies = studies;
            setCurrentIsoConfig(layer_config);

            const defaultRendererField = layer_config.defaultRendererField;
            const firstStudy = layer_config.studies[0];
            setStudy(firstStudy);

            const firstYear = firstStudy.years[0];
            // Check if study has ISO's default field
            const defaultYear = firstStudy.years.find(
                (year) => year.fieldName.toUpperCase() === defaultRendererField?.toUpperCase()
            );
            const selectYear = defaultYear ? defaultYear : firstYear;
            setYear(selectYear);

            // Create color renderer and assign to current layer
            if (currentTab === 0) {
                histogramColorRenderer(selectYear?.fieldName, selectedLayer);
            }

            // Move map to layer extent
            changeExtent(selectedLayer);

            // Show only selected layer
            hideOtherLayers(isoLayerName);
        }
    };

    const addNewFilter = () => {
        let newFilter = {
            field: '',
            operator: '',
            input: '',
        };
        setFilters([...filters, newFilter]);
        filtersInfo.current = [...filtersInfo.current, newFilter];
    };

    const onFilterChange = (value, index) => {
        let tempArr = [...filtersInfo.current];
        tempArr[index] = value;
        filtersInfo.current = tempArr;
    };

    const onTabChange = (index) => {
        setTab(index);
        clearFilters();

        // on Layers tab select, show all layers once again by removing their opacity
        if (index === 1) {
            for (let layer of allLayers) {
                layer.effect = '';

                if (!layer.childrenNodes) continue;
                for (let sublayer of layer.childrenNodes) {
                    sublayer.effect = '';
                }
            }
        }

        // on Filters tab select, show only the selected layer if defined
        if (index === 2) {
            if (selected) {
                changeExtent(selected);
                hideOtherLayers(selected.title);
            }
        }
    };

    const runFilters = async () => {
        if (!selected) {
            return;
        }

        setFilterLoading(true);
        let whereQuery = '';

        // Generate Query
        for (let [index, filter] of filtersInfo.current.entries()) {
            filter.input = filter.input.replace(',', '.');

            if (filter.input === '') continue;

            whereQuery += filter.field + ' ' + filter.operator + ' ' + filter.input;

            if (filtersInfo.current.length !== index + 1) {
                whereQuery += ' AND ';
            }
        }

        let query = selected.createQuery();
        query.where = whereQuery;
        query.returnGeometry = true;

        let features = await selected.queryFeatures(query);
        setFilterResult(features);
        setFilterLoading(false);
    };

    const highlightAndZoom = async () => {
        const [Graphic] = await loadModules(['esri/Graphic']);
        let graphics = [];
        filterResult.features.forEach((filterFeature) => {
            let graphic = new Graphic({
                geometry: filterFeature.geometry,
                symbol: {
                    type: 'simple-marker',
                    style: 'circle',
                    size: 8,
                    color: [0, 0, 0, 0],
                    outline: {
                        color: [0, 255, 255, 0.5],
                        width: 2,
                    },
                },
                attributes: filterFeature.attributes,
            });
            graphics.push(graphic);
        });
        view.graphics.removeAll();
        view.graphics.addMany(graphics);

        view.goTo(graphics);
    };

    const clearFilters = () => {
        setFilters([]);
        filtersInfo.current = [];
        view.graphics.removeAll();
        setFilterResult();
    };

    return (
        <>
            <Tabs onChange={onTabChange}>
                <CustomTabList>
                    <CustomTab>
                        <FormattedMessage id="map_drawer_tab_histogram" />
                    </CustomTab>
                    <CustomTab>
                        <FormattedMessage id="map_drawer_tab_layers" />
                    </CustomTab>
                    <CustomTab>
                        <FormattedMessage id="map_drawer_tab_filters" />
                    </CustomTab>
                    <CustomTab>
                        <FormattedMessage id="map_drawer_tab_tools" />
                    </CustomTab>
                </CustomTabList>

                <TabPanels>
                    <TabPanel>
                        <Combobox
                            options={allISO}
                            placeholderLabel="Select ISO layer"
                            onChange={changeIsoHandler}
                            value={selected?.title}
                        />

                        {selected && (
                            <>
                                <Combobox
                                    my={3}
                                    options={currentIsoConfig.studies}
                                    valueKey="name"
                                    labelKey="name"
                                    value={study.name}
                                    onChange={onStudyChange}
                                />

                                <Combobox
                                    my={3}
                                    options={study.years}
                                    valueKey="fieldName"
                                    labelKey="title"
                                    value={year.fieldName}
                                    onChange={onYearChange}
                                />

                                {study && (
                                    <>
                                        <Text>{'ISO: ' + selected?.title}</Text>
                                        <Text>{'Metric: ' + study?.name}</Text>
                                        <Text>{'Unit of Measurement: ' + study?.unitOfMeasurement}</Text>
                                        <Text>{'Year: ' + year?.title + ' [' + year?.fieldName + '] '}</Text>
                                        <Text>{study.description}</Text>
                                    </>
                                )}
                            </>
                        )}

                        {histogramLoading && (
                            <Flex my={5} direction="column" align="center">
                                <Spinner my={3} />
                                <Text>
                                    <FormattedMessage id="portfolio_map_loading" />
                                </Text>
                            </Flex>
                        )}
                    </TabPanel>

                    <TabPanel>
                        {allLayers.map((elem) => (
                            <MapTreeNode key={elem.id} layer={elem} map={map} />
                        ))}
                    </TabPanel>

                    <TabPanel>
                        <Combobox
                            options={allISO}
                            placeholderLabel="Select ISO layer"
                            onChange={changeIsoHandler}
                            value={selected?.title}
                        />

                        {selected && (
                            <Flex my={3} justify="flex-end">
                                <Link onClick={addNewFilter}>
                                    <FormattedMessage id="map_drawer_new_filter" />
                                </Link>
                            </Flex>
                        )}

                        {filters.map((elem, index) => {
                            return (
                                <MapFilter
                                    key={index}
                                    filter={elem}
                                    onFilterChange={(val) => onFilterChange(val, index)}
                                    layerSelected={selected.title}
                                    isoConfig={currentIsoConfig}
                                />
                            );
                        })}

                        <Flex mt={3} justify="center" align="center">
                            <Button disabled={filterLoading} mx={3} variant="primary" onClick={runFilters}>
                                <Box as="span" textTransform="capitalize">
                                    <FormattedMessage id="map_drawer_run_filter" />
                                </Box>
                            </Button>
                            <Button mx={3} variant="secondary" onClick={clearFilters}>
                                <Box as="span" textTransform="capitalize">
                                    <FormattedMessage id="map_drawer_clear_filter" />
                                </Box>
                            </Button>
                        </Flex>

                        {filterResult && (
                            <Flex mt={3} align="center" direction="column">
                                <p style={{ width: 'fit-content' }}>{filterResult.features.length} matching nodes</p>

                                <Button my={3} variant="special-green" onClick={highlightAndZoom}>
                                    <Box as="span" textTransform="capitalize">
                                        <FormattedMessage id="map_drawer_highlight_and_zoom" />
                                    </Box>
                                </Button>
                            </Flex>
                        )}
                    </TabPanel>
                    <TabPanel>
                        <Box>
                            <Flex align="center" justify="space-between">
                                <Flex align="center">
                                    <Heading as="h4" variant="h4" mr={2}>
                                        <FormattedMessage id="map_drawer_export_map" />
                                    </Heading>
                                    <Popover
                                        placement="top"
                                        trigger={
                                            <CustomIconButton
                                                variant="circular-icon"
                                                mt="0px !important"
                                                icon={<InfoIcon />}
                                            />
                                        }
                                    >
                                        <FormattedMessage id="map_drawer_export_info" />
                                    </Popover>
                                </Flex>
                                <Flex gap={3}>
                                    <CustomLink color="teal.500" onClick={() => takeScreenshot('preview')}>
                                        <FormattedMessage id="map_drawer_view" />
                                    </CustomLink>
                                    <CustomLink color="teal.500" onClick={() => takeScreenshot('download')}>
                                        <FormattedMessage id="map_drawer_download" />
                                    </CustomLink>
                                </Flex>
                            </Flex>
                            <Bookmarks
                                bookmarks={bookmarks}
                                view={view}
                                onBookmarkCreateSuccess={onBookmarkCreateSuccess}
                                onBookmarkDeleteSuccess={onBookmarkDeleteSuccess}
                            />
                            <Pins
                                map={map}
                                pins={pins}
                                view={view}
                                pinsLayer={pinsLayer}
                                onPinCreateSuccess={onPinCreateSuccess}
                                onPinDeleteSuccess={onPinDeleteSuccess}
                            />
                            <ValidateCustomLayer map={map} layers={layers} />
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {screenshotModal.isOpen && (
                <ScreenshotModal isOpen onClose={screenshotModal.onClose} imageUrl={selectedScreenshot.current} />
            )}
        </>
    );
};

const CustomLink = styled(Link)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;
const CustomTabList = styled(TabList)`
    overflow-x: auto;
    padding: 5px;
    border-bottom: 1px solid var(--chakra-colors-gray-200);
`;

const CustomTab = styled(Tab)`
    padding: 16px 0;
    margin-right: 24px;
    margin-bottom: 0;

    :focus {
        border-radius: var(--chakra-radii-base);
    }
`;

export default MapDrawer;
