import { useMemo, useState, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Spacer, Skeleton, Box, Alert, AlertIcon, AlertTitle, AlertDescription, Text } from '@chakra-ui/react';
import Tree from '../react-tree/Tree';

import {
    getChildPortfolioIds,
    findPortfolioByPropertiesId,
    filterNodesByName,
    getPortfolios,
    getChildPortfolioNodeIds,
} from '../../services/portfolios';

import PortfolioTreeWrapper from '../portfolios/PortfolioTreeWrapper';
import PortfolioNodeIcon from '../portfolios/PortfolioNodeIcon';
import PortfolioArrowIcon from '../portfolios/PortfolioArrowIcon';
import MainModal from '../modal/MainModal';
import SearchBar from '../utils/SearchBar';
import Tooltip from '../utils/Tooltip';

import CustomCheckbox from '../forms/CustomCheckbox';

import { ReactComponent as RemoveIcon } from '../../icons/remove.svg';

const SelectPortfolioModal = ({ selectedPortfolios, onSelectPortfolioModalContinue, onClose }) => {
    const intl = useIntl();

    const [isLoadingError, setIsLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [nodes, setNodes] = useState([]);
    const [filteredNodes, setFilteredNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [focused, setFocused] = useState(null);
    const [selectedItems, setSelectedItems] = useState(selectedPortfolios.map((p) => p.properties.id));

    const disabledPortfolios = useMemo(() => {
        if (nodes.length === 0) {
            // no need to map the selectedItems if we haven't received the nodes from the /portfolios request
            return [];
        }

        return selectedItems.flatMap((id) => {
            const node = findPortfolioByPropertiesId(nodes, id);

            if (!node) {
                // if we didn't find the node we may have received a portfolioId that wasn't returned by the /portfolios request
                return [];
            }

            return getChildPortfolioIds(node);
        });
    }, [nodes, selectedItems]);

    const [search, setSearch] = useState('');

    useEffect(() => {
        setIsLoading(true);

        getPortfolios()
            .then((data) => {
                setNodes(data.portfolios);
                setFilteredNodes(data.portfolios);
                setExpanded(data.portfolios.map((node) => node.id));
                setFocused(data.portfolios[0].id);
            })
            .catch(() => {
                setIsLoadingError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        const term = search.trimStart().toLowerCase();

        if (term && term.length >= 2) {
            const data = filterNodesByName(nodes, term);
            const ids = getChildPortfolioNodeIds(data);

            setFilteredNodes(data);
            setExpanded(ids);
        } else {
            setFilteredNodes(nodes);
            setExpanded(nodes.map((node) => node.id));
        }
    }, [search]); // eslint-disable-line

    const onPortfolioSelectChange = useCallback(
        (node) => {
            const isSelectable = node.isFolder && !disabledPortfolios.includes(node.properties.id);

            if (isSelectable) {
                const portfolioId = node.properties.id;

                setSelectedItems((prev) => {
                    const isAlreadySelected = prev.includes(portfolioId);

                    if (isAlreadySelected) {
                        return prev.filter((id) => id !== portfolioId);
                    } else {
                        const children = getChildPortfolioIds(node);

                        return prev.filter((id) => !children.includes(id)).concat(portfolioId);
                    }
                });
            }
        },
        [disabledPortfolios]
    );

    const onFocusChange = useCallback(({ id }) => {
        setFocused(id);
    }, []);

    const onExpandChange = useCallback(({ id }) => {
        setExpanded((prev) => (prev.includes(id) ? prev.filter((node) => node !== id) : prev.concat(id)));
    }, []);

    const renderPortfolioLabel = useCallback(
        (node) => {
            const isSelected = selectedItems.includes(node.properties.id);
            const isSelectable = node.isFolder && !disabledPortfolios.includes(node.properties.id);

            return (
                <div className={`${node?.isExpandable ? '' : 'non-expandable '}portfolio-label-wrapper`}>
                    {node?.isExpandable && (
                        <span
                            onClick={() => {
                                setExpanded((prev) =>
                                    prev.includes(node.id) ? prev.filter((id) => id !== node.id) : prev.concat(node.id)
                                );
                                setFocused(node.id);
                            }}
                        >
                            <PortfolioArrowIcon isExpanded={node?.isExpanded} isMaster={node.properties.isMaster} />
                        </span>
                    )}

                    <div
                        onClick={(event) => {
                            if (event.shiftKey && node?.isExpandable) {
                                setExpanded((prev) =>
                                    prev.includes(node.id)
                                        ? prev.filter((nodeId) => nodeId !== node.id)
                                        : prev.concat(node.id)
                                );
                            } else {
                                onPortfolioSelectChange(node);
                            }
                        }}
                        className="portfolio-label"
                    >
                        <PortfolioNodeIcon
                            isFolder={node.isFolder}
                            isExpanded={node?.isExpanded}
                            isMaster={node.properties.isMaster}
                            typeId={node.properties.typeId}
                        />

                        <span>{node.properties.name}</span>
                    </div>

                    {isSelectable && (
                        <CustomCheckbox
                            tabIndex={-1}
                            isChecked={isSelected}
                            onChange={() => onPortfolioSelectChange(node)}
                        />
                    )}
                </div>
            );
        },
        [disabledPortfolios, selectedItems, onPortfolioSelectChange]
    );

    return (
        <MainModal
            scrollBehavior="inside"
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="run_a_study_choose_portfolio" />}
            content={
                <>
                    <SearchBar
                        textHolder={intl.formatMessage({ id: 'common_search_portfolios' })}
                        onChange={(value) => setSearch(value)}
                    />

                    <Spacer mb={5} />

                    <PortfolioTreeWrapper isDisplayedInModal>
                        {isLoading ? (
                            <Skeleton>
                                <Box h="34px">
                                    <FormattedMessage id="common_loading" />
                                </Box>
                            </Skeleton>
                        ) : isLoadingError ? (
                            <Box mx={6}>
                                <Alert status="error">
                                    <AlertIcon />

                                    <Box>
                                        <AlertTitle textTransform="capitalize">
                                            <FormattedMessage id="common_error" />
                                        </AlertTitle>

                                        <AlertDescription>
                                            <FormattedMessage id="common_loading_error" values={{ items: 'items' }} />
                                        </AlertDescription>
                                    </Box>
                                </Alert>
                            </Box>
                        ) : (
                            <Tree
                                aria-label={intl.formatMessage({ id: 'common_items' })}
                                nodes={filteredNodes}
                                expanded={expanded}
                                onExpandChange={onExpandChange}
                                focused={focused}
                                onFocusChange={onFocusChange}
                                onSelectChange={onPortfolioSelectChange}
                                renderLabel={renderPortfolioLabel}
                                expandOnSelect={false}
                            />
                        )}
                    </PortfolioTreeWrapper>
                </>
            }
            footerLeftSlot={
                <div>
                    {selectedItems.length > 0 ? (
                        <Tooltip label={intl.formatMessage({ id: 'common_unselect_all' })}>
                            <Button
                                onClick={() => setSelectedItems([])}
                                ml="-14px"
                                size="sm"
                                leftIcon={<RemoveIcon />}
                                variant="ghost"
                            >
                                <FormattedMessage
                                    id="run_a_study_portfolios_selected"
                                    values={{ n: selectedItems.length }}
                                />
                            </Button>
                        </Tooltip>
                    ) : (
                        <Text size="sm">
                            <FormattedMessage id="run_a_study_no_portfolio_selected" />
                        </Text>
                    )}
                </div>
            }
            footerRightSlot={
                <Button
                    isDisabled={selectedItems.length === 0}
                    onClick={() => {
                        const portfolios = selectedItems
                            .map((id) => findPortfolioByPropertiesId(nodes, id))
                            .filter((node) => !!node); // remove the null values if couldn't find the portfolio in nodes

                        onSelectPortfolioModalContinue(portfolios);
                    }}
                    ml={3}
                    textTransform="capitalize"
                >
                    <FormattedMessage id="common_continue" />
                </Button>
            }
        />
    );
};

export default SelectPortfolioModal;
