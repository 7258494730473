import React, { useCallback } from 'react';
import { Button, Divider, Flex, Spacer, Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';

import { setFiltersConfig, removeFilters } from '../../services/items';
import { generationAssetDefaultFilter } from '../../services/thermals';

import SideDrawer from '../utils/SideDrawer';
import DateFormikField from '../forms/DateFormikField';

const FILTERS_STORAGE_NAMESPACE = 'thermalDsm';

const ThermalFilters = ({ header, onApplyFilters, filters, onClose }) => {
    const intl = useIntl();

    const onKeyDown = useCallback((event) => {
        if (event.key === 'Enter') event.preventDefault();
    }, []);

    const onSubmit = useCallback(
        (data) => {
            setFiltersConfig(data, FILTERS_STORAGE_NAMESPACE);
            onClose && onClose();
            onApplyFilters && onApplyFilters(data);
        },
        [onClose, onApplyFilters]
    );

    const onResetFilters = useCallback(() => {
        removeFilters(FILTERS_STORAGE_NAMESPACE);
        onApplyFilters(generationAssetDefaultFilter);
    }, [onApplyFilters]);

    return (
        <SideDrawer
            isOpen
            header={header}
            content={
                <Formik initialValues={filters} onSubmit={onSubmit}>
                    {({ dirty, values }) => (
                        <Form onKeyDown={onKeyDown}>
                            <DateFormikField
                                id="minEndDate"
                                name="minEndDate"
                                label={<FormattedMessage id="show_end_dates_later_than" />}
                                isClearable={true}
                            />

                            <Divider mt={6} mb={8} />

                            <Flex>
                                <Button
                                    onClick={onResetFilters}
                                    isDisabled={!filters.minEndDate}
                                    variant="secondary"
                                    textTransform="capitalize"
                                >
                                    <FormattedMessage id="common_reset" />
                                </Button>

                                <Spacer />

                                <Flex flexDirection="column" alignItems="flex-end">
                                    <Button type="submit" disabled={!dirty || !values.minEndDate}>
                                        <FormattedMessage id="common_apply_filter_btn" />
                                    </Button>
                                    <Text size="xs" variant="regular" maxW={52} marginTop={3} textAlign="end">
                                        <FormattedMessage
                                            id="common_apply_filter_message"
                                            values={{ item: intl.formatMessage({ id: 'generation_asset_title' }) }}
                                        />
                                    </Text>
                                </Flex>
                            </Flex>

                            <Spacer my={6} />
                        </Form>
                    )}
                </Formik>
            }
            onClose={onClose}
            placement="right"
        />
    );
};
export default ThermalFilters;
