import { isNumber, isEqual, reduce, isNull } from 'lodash';
import {
    convertRelativeToUTCDateMidnight,
    convertRelativeToUTCDateMidnightExtraDayMinusOneHour,
} from '../../../services/utils';
import { getFiltersConfig, systemDefaultFilter } from '../../../services/items';

import { INPUT_TYPES, DATE_FORMATS, INPUT_FORMATS, NUMBER_FORMATS, DATA_TYPES } from '../../../constants/fields';
import { DATE_TIME_FILTER_TYPES } from '../../../constants/autoforms';

export const getPrimaryKeyColName = (columns) => {
    const primaryKeyCol = columns.find((col) => {
        return col.isPrimaryKey;
    });
    return primaryKeyCol ? primaryKeyCol.name : '';
};

export const getDataValueObjectFromDto = (data, tableMetadata) => {
    const dataTypes = getDataTypes(tableMetadata.columns);
    const outputData = Object.assign({}, data);
    for (const column in data) {
        const value = data[column];
        if (dataTypes[column]) {
            const { formatCode } = dataTypes[column];

            if (formatCode === INPUT_FORMATS.Percent && value !== null && value !== undefined) {
                outputData[column] = stripFloatingPointErrors(value * 100);
            }
        }
    }

    return outputData;
};

export const getDtoFromDataValueObject = (data, tableMetadata) => {
    const dataTypes = getDataTypes(tableMetadata.columns);
    const outputData = {};

    for (const column in data) {
        let value = data[column];

        if (dataTypes[column]) {
            const { dataTypeDescription, inputTypeDescription, formatCode } = dataTypes[column];
            const valueNotNull = value !== null && value !== undefined && value !== '';

            if (valueNotNull) {
                if (inputTypeDescription === INPUT_TYPES.CheckBox) {
                    const validTruthyValues = [1, true, 'true', '1'];
                    value = validTruthyValues.includes(value) ? 1 : 0; // BE requires 1 or 0 for checkboxes
                }

                if (formatCode === INPUT_FORMATS.Percent) {
                    value = stripFloatingPointErrors(value / 100);
                }

                if (
                    inputTypeDescription !== INPUT_TYPES.ComboBox &&
                    (dataTypeDescription === DATA_TYPES.Int || dataTypeDescription === DATA_TYPES.Double)
                ) {
                    value = Number(value);
                }
            } else {
                value = null;
            }
            outputData[column] = value;
        }
    }

    return outputData;
};

export const getTableFormKey = (tableId) => `table${tableId}`;
export const getItemFormKey = (itemId) => `item${itemId}`;
export const getInputFormName = (tableId, itemId, columnName) =>
    `${getTableFormKey(tableId)}.${getItemFormKey(itemId)}.${columnName}`;

export const getTableIdFromInputName = (data) => Number(data.slice(5));
export const getItemIdFromInputName = (keys) => Number(keys.slice(4));

export const getInitialValuesForDataRow = (row, tableMetadata) => {
    return Object.keys(row).reduce((acc, column) => {
        acc[column] = getInitialValueForColumnType(row, column, tableMetadata);
        return acc;
    }, {});
};
export const getInitialValueForColumnType = (row, column, tableMetadata, ignoreCheckboxes = false) => {
    const dataTypes = getDataTypes(tableMetadata.columns);
    const { inputTypeDescription, dataTypeDescription } = dataTypes[column];
    const isCheckbox = inputTypeDescription === INPUT_TYPES.CheckBox;
    let result = row[column];

    if (isCheckbox && ignoreCheckboxes) {
        return result;
    }

    const isCheckboxWithValue = row[column] != null && isCheckbox;

    // Maps the field value to the correct value type that Formik expects as initial
    if (isCheckboxWithValue) {
        result = row[column] === 1; // 1 is true, 0 is false
    }
    // For text and numeric fields, we want number values to be converted to string
    if (
        (inputTypeDescription === INPUT_TYPES.NumericUpDown || inputTypeDescription === INPUT_TYPES.TextBox) &&
        (dataTypeDescription === DATA_TYPES.Double || dataTypeDescription === DATA_TYPES.Int) &&
        row[column] != null
    ) {
        result = String(row[column]);
    }

    return result !== null && result !== undefined ? result : getDefaultValueForColumnType(inputTypeDescription);
};

export const getDefaultValueForColumnType = (columnType) => {
    // Chakra does .toString() for input values hence the empty strings
    switch (columnType) {
        case INPUT_TYPES.DropDownForm:
        case INPUT_TYPES.TextBox:
            return '';
        case INPUT_TYPES.ComboBox:
            return '';
        case INPUT_TYPES.MonthCalendar:
            return null;
        case INPUT_TYPES.CheckBox:
            return false;
        case INPUT_TYPES.NumericUpDown:
            return '';
        case INPUT_TYPES.ProgressBar:
            return '';
        default:
            return '';
    }
};

export const isFilterApplied = (namespacePrefix, customFilters) => {
    let hasNonEmptyCustomFilter = false;

    for (let filter of customFilters ?? []) {
        if (filter?.columnId) {
            hasNonEmptyCustomFilter = true;
            break;
        }
    }

    return !isNull(getFiltersConfig(namespacePrefix)) || hasNonEmptyCustomFilter;
};

export const hasAppliedAutoformFilters = (filterValues) => {
    const dateFilter = filterValues.dateTimeFilters.filter((values) => {
        if (values.type === DATE_TIME_FILTER_TYPES.Overlap) {
            return (
                values.toDate?.getTime() !== systemDefaultFilter.startDateTo.getTime() ||
                values.fromDate?.getTime() !== systemDefaultFilter.startDateFrom.getTime()
            );
        } else if (values.type === DATE_TIME_FILTER_TYPES.StartDateRange) {
            return values.fromDate?.getTime() !== systemDefaultFilter.startDateFrom.getTime();
        } else if (values.type === DATE_TIME_FILTER_TYPES.EndDateRange) {
            return values.toDate?.getTime() !== systemDefaultFilter.startDateTo.getTime();
        } else {
            return (
                values.toDate?.getTime() !== systemDefaultFilter.updateDateTo.getTime() ||
                values.fromDate?.getTime() !== systemDefaultFilter.updateDateFrom.getTime()
            );
        }
    });

    return filterValues.filters.length >= 1 || dateFilter.length >= 1;
};

export const parseAutoformDataFilters = (
    filterValues,
    { hasEndDateFilter, hasStartDateFilter, hasUpdateOrCreateDateFilter, columns }
) => {
    if (!filterValues) return { filters: [], dateTimeFilters: [] };
    let { customFilters } = filterValues;
    const dateTimeFilters = getDateTimeFilters({
        startDateFrom: filterValues.toggleDaysFrom
            ? convertRelativeToUTCDateMidnight(filterValues.daysFrom)
            : filterValues.startDateFrom,
        startDateTo: filterValues.toggleDaysTo // Per business request include extra day
            ? convertRelativeToUTCDateMidnightExtraDayMinusOneHour(filterValues.daysTo)
            : filterValues.startDateTo,
        filterType: parseInt(filterValues.filterType, 10),
        updateDateFrom: filterValues.updateDateFrom,
        updateDateTo: filterValues.updateDateTo,
        hasEndDateFilter,
        hasStartDateFilter,
        hasUpdateOrCreateDateFilter,
    });
    const filters =
        customFilters
            ?.filter((value) => value.columnId && value.operation)
            .map((values) => {
                const column = columns.find((col) => col.id === values.columnId);
                const mappedValues = {
                    columnId: parseInt(values.columnId, 10),
                    operation: parseInt(values.operation, 10),
                    filterValue: values.filterValue,
                };
                if (column.formatCode === INPUT_FORMATS.Percent) {
                    mappedValues.filterValue = `${stripFloatingPointErrors(values.filterValue / 100)}`;
                }
                return mappedValues;
            }) ?? [];
    return {
        filters,
        dateTimeFilters,
    };
};

export const getDateTimeFilters = (filters) => {
    let { startDateFrom, startDateTo, updateDateFrom, updateDateTo, filterType } = filters;
    let dateTime = [];
    if (filters.hasEndDateFilter && !filters.hasStartDateFilter) {
        dateTime.push({
            fromDate: startDateFrom,
            toDate: startDateTo,
            type: DATE_TIME_FILTER_TYPES.EndDateRange,
        });
    }
    if (!filters.hasEndDateFilter && filters.hasStartDateFilter) {
        dateTime.push({
            fromDate: startDateFrom,
            toDate: startDateTo,
            type: DATE_TIME_FILTER_TYPES.StartDateRange,
        });
    }
    if (filters.hasEndDateFilter && filters.hasStartDateFilter) {
        dateTime.push({
            fromDate: startDateFrom,
            toDate: startDateTo,
            type: DATE_TIME_FILTER_TYPES.Overlap,
        });
    }
    if (filters.hasUpdateOrCreateDateFilter) {
        const updateFilterMap = {
            1: DATE_TIME_FILTER_TYPES.UpdateDateLatest,
            2: DATE_TIME_FILTER_TYPES.UpdateDateLatestAsOf,
            3: DATE_TIME_FILTER_TYPES.UpdateDateRange,
        };
        dateTime.push({
            fromDate: updateDateFrom,
            toDate: updateDateTo,
            type: updateFilterMap[filterType],
        });
    }

    return dateTime;
};

export const getDataTypes = (columns) =>
    columns.reduce((acc, col) => {
        acc[col.name] = {
            dataTypeDescription: col.dataTypeDescription,
            inputTypeDescription: col.inputTypeDescription,
            formatCode: col.formatCode,
        };
        return acc;
    }, {});

export const parseColumnSettings = (settings) =>
    settings?.reduce((acc, setting) => {
        acc[setting.name] = setting.value;
        if (isNumber(setting.value)) {
            acc[setting.name] = parseFloat(setting.value);
        }
        return acc;
    }, {}) ?? {};

export const parseDateFormat = (column) => {
    return DATE_FORMATS[column.formatCode];
};

export const parseNumberFormat = (column) => {
    return NUMBER_FORMATS[column.formatCode];
};

export const mapValidationMessagesToFormikStatus = (fields, getFieldName) =>
    fields.reduce((acc, field) => {
        const errors = field.validationMessages
            ?.filter((message) => message.severity === 'Error')
            .map((message) => message.message);
        const warnings = field.validationMessages
            ?.filter((message) => message.severity === 'Warning')
            .map((message) => {
                return message.message;
            });
        acc[getFieldName(field.name)] = { errors, warnings };
        return acc;
    }, {});

export const formikStatus = (results) => {
    const response = results.reduce((acc, result) => {
        acc[getTableFormKey(result.tableId)] = result.error.issues.reduce((obj, issue) => {
            obj[getItemFormKey(issue.id)] = mapValidationMessagesToFormikStatus(
                issue.fields,
                (columnName) => columnName
            );
            return obj;
        }, {});

        return acc;
    }, {});
    return response;
};

export const mapValidationMessagesToAddTableRowsErrors = (issues) =>
    issues.reduce((issuesAcc, issue) => {
        issuesAcc[issue.index] = (issue.fields || []).reduce((acc, field) => {
            acc[field.name] = {
                severity: field.validationMessages.find((f) => f.severity === 'Error') ? 'Error' : 'Warning',
                ...field,
            };
            return acc;
        }, {});
        return issuesAcc;
    }, []);

export const mapGeneralValidationMessages = (generalValidationMessages) => {
    let result = (generalValidationMessages ?? []).reduce(
        (acc, message) => {
            if (message.severity === 'Warning') {
                acc.warnings.push(message.message);
            } else {
                acc.errors.push(message.message);
            }
            return acc;
        },
        { warnings: [], errors: [] }
    );
    return result;
};

export const getAddRecordModalInitialFormValues = (sortedColumns) =>
    sortedColumns?.reduce((acc, col) => {
        acc[col.name] = getDefaultValueForColumnType(col.inputTypeDescription);

        // Check if we have metadata column defaultValue for DropDownForm, TextBox or ComboBox
        // columns displayed in Add New Autoform Record Modal
        // At this point we do not use metadata defaults for MonthCalendar, CheckBox, NumericUpDown or ProgressBar
        if (col.defaultValue != null) {
            if (
                col.inputTypeDescription === INPUT_TYPES.ComboBox &&
                (col.dataTypeDescription === DATA_TYPES.Int || col.dataTypeDescription === DATA_TYPES.Double)
            ) {
                // Typically Key/ID of drop down options, stored in metadata as strings,
                // need to convert default value to Number in order to see correct selected option in drop down by default
                acc[col.name] = Number(col.defaultValue);
            } else if (
                col.inputTypeDescription === INPUT_TYPES.DropDownForm ||
                col.inputTypeDescription === INPUT_TYPES.TextBox ||
                col.inputTypeDescription === INPUT_TYPES.ComboBox
            ) {
                acc[col.name] = col.defaultValue;
            }
        }

        return acc;
    }, {}) ?? {};

export const getAddRecordModalValidationSettings = (tableMetadata, sortedColumns) =>
    sortedColumns?.map((col) => getValidationConfigForField(col.name, tableMetadata, col.name)) ?? [];

export const getTablesWithChildrenMap = (tables, childTables) =>
    tables.reduce((acc, table) => {
        acc[table.id] = childTables.filter((t) => t.parentId === table.id);
        return acc;
    }, {});

export const parseSortModel = (sortModel, sortedColumns) =>
    sortModel
        .map((sortModel) => ({
            sortColumnId: sortedColumns.find((def) => def.name === sortModel.colId)?.id,
            sortByDescending: sortModel.sort !== 'asc',
        }))
        .filter((sort) => !!sort.sortColumnId);

export const findKeysWithDifferences = (obj1, obj2) =>
    reduce(obj1, (result, value, key) => (isEqual(value, obj2[key]) ? result : result.concat(key)), []);

export const getColumnDisplayNameMap = (columns) =>
    columns.reduce((acc, col) => {
        acc[col.name] = col.displayName;
        return acc;
    }, {});

export const getFormTableIds = (hierarchicalTables, includeLevel2Values, tablesWithChildrenMap) => {
    const level1TableIds = hierarchicalTables.level1.length > 0 ? [hierarchicalTables.level1[0]?.id] : [];
    const level2TableIds = includeLevel2Values
        ? hierarchicalTables.level2
              .filter((table) => tablesWithChildrenMap[table.id]?.length > 0)
              .map((table) => table.id)
        : [];
    const tableIds = [...level1TableIds, ...level2TableIds];
    return tableIds;
};

export const getValidationConfigForField = (columnName, tableMetadata, inputName) => {
    const column = tableMetadata.columns.find((col) => col.name === columnName);
    const settings = parseColumnSettings(column.settings);
    return {
        ...settings,
        isRequired: column.isRequired,
        inputName,
        dataType: column.dataTypeDescription,
        inputType: column.inputTypeDescription,
        info: settings?.CELLTOOLTIP,
        label: column.displayName,
    };
};

export const cleanEmptyObjects = (obj) => {
    let result = {};
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            const cleanChildObject = cleanEmptyObjects(obj[key]);
            if (Object.keys(cleanChildObject).length > 0) {
                result[key] = cleanChildObject;
            }
        } else {
            result[key] = obj[key];
        }
    });
    return result;
};

export const differenceBetweenObjects = (obj1, obj2) => {
    let result = {};
    if (!isEqual(obj1, obj2)) {
        Object.keys(obj1).forEach((key) => {
            if (key in obj2 && typeof obj1[key] === 'object') {
                let diffedChildObject = differenceBetweenObjects(obj1[key], obj2[key]);
                if (Object.keys(diffedChildObject).length > 0) {
                    result[key] = diffedChildObject;
                }
            } else if (!(key in obj2) || obj1[key] !== obj2[key]) {
                result[key] = obj1[key];
            }
        });
    }
    return result;
};

function stripFloatingPointErrors(number) {
    return parseFloat(parseFloat(number).toPrecision(16));
}
