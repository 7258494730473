import Autoform from './Autoform';

import useGlobalConstraintConfig from 'hooks/globalConstraints/useGlobalConstraintConfig';

const GlobalConstraints = () => {
    const config = useGlobalConstraintConfig();

    return <Autoform config={config} />;
};

export default GlobalConstraints;
