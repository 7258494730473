import { useRef, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Flex, Spacer, useDisclosure } from '@chakra-ui/react';

import DeleteAllButton from '../itemData/DeleteAllButton';

import {
    getAdjustmentsForInstrument,
    getValidAdjustmentRows,
    updateAdjustmentCells,
    deleteAdjustments,
    deleteAllAdjustments,
    createAdjustments,
} from '../../services/hedges';
import { formatMultisort } from '../../services/items';

import useCommonToast from '../../hooks/useCommonToast';

import MainModal from '../modal/MainModal';

import DataGrid from '../grid/DataGrid';
import DataGridWrapper from '../grid/DataGridWrapper';
import AddDataModal from '../grid/AddDataModal';

import SecondaryButton from '../utils/SecondaryButton';

import { ReactComponent as AddIcon } from '../../icons/add-item.svg';

const EditAdjustmentsModal = ({ instrumentId, onClose }) => {
    const gridApi = useRef(null);
    const intl = useIntl();
    const { toast } = useCommonToast();
    const addDataModal = useDisclosure();

    const { dateIntervals, linkedObjects } = useSelector((state) => state.adjustment);

    const serversideDataSource = useMemo(() => {
        return {
            getRows: async (params) => {
                const pagination = {
                    paging: {
                        pageSize: 500,
                        page: params.request.startRow / 500 + 1,
                    },
                    sorting: formatMultisort(params.request.sortModel),
                };

                const response = await getAdjustmentsForInstrument(instrumentId, pagination);

                if (response.totalCount === 0) {
                    params.api.showNoRowsOverlay();
                } else {
                    params.api.hideOverlay();
                }

                params.success({ rowData: response.data, rowCount: response.totalCount });
            },
        };
    }, [instrumentId]);

    const refreshServerSideData = useCallback(() => {
        gridApi.current.api.refreshServerSideStore({
            purge: true,
        });
    }, []);

    const updateServerSideData = useCallback(
        async (rowIds) => {
            const rows = rowIds.map((id) => gridApi.current.api.getRowNode(id));
            const validRows = getValidAdjustmentRows(rows);

            if (validRows.length === 0) return;

            await updateAdjustmentCells(instrumentId, validRows);

            toast(intl.formatMessage({ id: 'hedge_adjustments_on_success' }));
        },
        [instrumentId, intl, toast]
    );

    const onDeleteRow = useCallback(
        async (rowIds) => {
            const adjustmentIds = rowIds.map((id) => gridApi.current.api.getRowNode(id).data.instrumentAdjustmentId);

            if (adjustmentIds.length === 0) return;

            await deleteAdjustments(instrumentId, adjustmentIds);
            toast(intl.formatMessage({ id: 'common_delete_grid_msg' }, { n: adjustmentIds.length }));

            refreshServerSideData();
        },
        [intl, toast, instrumentId, refreshServerSideData]
    );

    const columns = useMemo(() => {
        return [
            {
                type: 'date',
                field: 'startDate',
                headerName: intl.formatMessage({ id: 'common_grid_start_date' }),
                sort: 'asc',
                minWidth: 250,
                cellEditorParams: { nullable: true },
            },
            {
                type: 'date',
                field: 'endDate',
                headerName: intl.formatMessage({ id: 'common_grid_end_date' }),
                minWidth: 250,
                cellEditorParams: { nullable: true },
            },
            {
                type: 'number',
                field: 'scaler',
                headerName: intl.formatMessage({ id: 'hedge_adjustments_grid_scaler' }),
            },
            {
                type: 'number',
                field: 'adder',
                headerName: intl.formatMessage({ id: 'hedge_adjustments_grid_adder' }),
            },
            {
                type: 'number',
                field: 'min',
                headerName: intl.formatMessage({ id: 'hedge_adjustments_grid_min' }),
            },
            {
                type: 'number',
                field: 'max',
                headerName: intl.formatMessage({ id: 'hedge_adjustments_grid_max' }),
            },
            {
                type: 'select',
                field: 'dateIntervalId',
                headerName: intl.formatMessage({ id: 'hedge_adjustments_grid_date_interval' }),
                minWidth: 150,
                cellEditorParams: { options: dateIntervals, required: false },
            },
            {
                type: 'select',
                field: 'linkedObjectId',
                headerName: intl.formatMessage({ id: 'hedge_adjustments_grid_linked_object' }),
                minWidth: 400,
                cellEditorParams: { options: linkedObjects, required: false },
            },
        ];
    }, [dateIntervals, linkedObjects, intl]);

    const gridResource = useMemo(() => {
        return {
            create: (rows) => {
                return createAdjustments(instrumentId, rows);
            },
        };
    }, [instrumentId]);

    const onAddRowsSuccess = useCallback(() => {
        toast(intl.formatMessage({ id: 'common_generic_item_change_success' }));
        refreshServerSideData();
    }, [toast, intl, refreshServerSideData]);

    return (
        <MainModal
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="hedge_adjustments_modal_title" />}
            content={
                <Box my={4}>
                    <Flex mt={4} mb={12} wrap="wrap">
                        <Spacer display={{ base: 'none', sm: 'block' }} />

                        <DeleteAllButton
                            subItemId={instrumentId}
                            onDelete={deleteAllAdjustments}
                            refetchData={refreshServerSideData}
                            mb={{ base: 4, xl: 0 }}
                        />

                        <SecondaryButton
                            ml={4}
                            variant="secondary"
                            size="sm"
                            leftIcon={<AddIcon />}
                            onClick={addDataModal.onOpen}
                            textTransform="capitalize"
                        >
                            <FormattedMessage id="common_add_rows_btn" />
                        </SecondaryButton>
                    </Flex>

                    <DataGridWrapper>
                        <DataGrid
                            ref={gridApi}
                            serverSideDatasource={serversideDataSource}
                            onDataChange={updateServerSideData}
                            onDelete={onDeleteRow}
                            rowModelType="serverSide"
                            serverSideStoreType="partial"
                            paginationPageSize={500}
                            cacheBlockSize={500}
                            columns={columns}
                            addDataColumns={columns}
                            gridResource={gridResource}
                            onAddRowsSuccess={onAddRowsSuccess}
                        />
                    </DataGridWrapper>

                    {addDataModal.isOpen && (
                        <AddDataModal
                            isOpen
                            onClose={addDataModal.onClose}
                            columns={columns}
                            gridResource={gridResource}
                            onAddRowsSuccess={onAddRowsSuccess}
                        />
                    )}
                </Box>
            }
            secondaryButton={<></>}
        />
    );
};

export default EditAdjustmentsModal;
