const FormLabel = {
    baseStyle: {
        fontSize: 12,
        letterSpacing: 0.5,
        lineHeight: 1.5,
        fontWeight: 500,
        color: 'gray.600',
        mb: 1,

        _focus: {
            color: 'blue.400',
        },

        _disabled: {
            opacity: 1,
            color: 'gray.400',

            _dark: {
                color: 'gray.600',
            },
        },

        _readOnly: {
            opacity: 1,
            color: 'gray.400',
        },

        _dark: {
            color: 'gray.300',
        },
    },
};

export default FormLabel;
