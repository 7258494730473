import { useMemo } from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';

import { ReactComponent as ErrorIcon } from '../../icons/warning.svg';
import { ReactComponent as WarningIcon } from '../../icons/soft-warning.svg';

const StatementMessageTypeCell = ({ value }) => {
    const icon = useMemo(() => {
        if (!value || typeof value !== 'string') return null;

        if (value.toLowerCase().includes('error')) return <ErrorIcon />;
        if (value.toLowerCase().includes('warning')) return <WarningIcon />;
    }, [value]);

    return (
        <Flex w="100%" align="center" justify="space-between">
            <Text>{value}</Text>
            <Box ml={2}>{icon}</Box>
        </Flex>
    );
};

export default StatementMessageTypeCell;
