import { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
    Heading,
    Flex,
    Box,
    SimpleGrid,
    Divider,
    useDisclosure,
    Spacer,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    IconButton,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';

import { onHasUnsavedChanges } from '../store/helpers/helpersSlice';

import { checkItemInputEvent, editItem, deleteItem } from '../services/items';
import { preserveConnectionSearchParams } from '../services/connections';
import {
    getMainMarket,
    getDropdownOptions,
    modifyModelDefinitions,
    addModelDefinitionRows,
    deleteModelDefinitions,
    validateModelDefinitionRows,
} from '../services/mainMarket';

import useCommonToast from '../hooks/useCommonToast';
import useHistoryPush from '../hooks/useHistoryPush';

import SubItemData from '../components/itemData/SubItemData';
import AutomaticFormikDirtyCheck from '../components/forms/AutomaticFormikDirtyCheck';
import Popover from '../components/utils/Popover';
import Error from '../components/utils/Error';
import CustomIconButton from '../components/utils/CustomIconButton';
import DefinitionsSkeleton from '../components/utils/DefinitionsSkeleton';
import ActionBar from '../components/utils/ActionBar';
import Tooltip from '../components/utils/Tooltip';
import SecondaryButton from '../components/utils/SecondaryButton';
import ItemPropertiesModal from '../components/utils/ItemPropertiesModal';
import CustomItemIcon from '../components/utils/CustomItemIcon';

import SortOrderProvider from '../components/grid/utils/SortOrderContext';
import PromptWrapper from '../components/utils/PromptWrapper';

import ComboboxFormikField from '../components/forms/ComboboxFormikField';
import CheckboxFormikField from '../components/forms/CheckboxFormikField';
import InputFormikField from '../components/forms/InputFormikField';

import { FEATURE_FLAG_NAMES, useIsFeatureActive } from '../hooks/features-flags';

import EditSystemComponentModal from 'components/basisConfigurator/EditSystemComponentModal';

import { ReactComponent as EditIcon } from '../icons/edit-circled.svg';
import { ReactComponent as MarketPriceModelIcon } from '../icons/item-icons/market-price-model.svg';
import { ReactComponent as SaveIcon } from '../icons/save.svg';
import { ReactComponent as InfoIcon } from '../icons/info.svg';
import { ReactComponent as FileIcon } from '../icons/file.svg';
import { ReactComponent as BreadcrumbSeparatorIcon } from '../icons/breadcrumb-separator.svg';
import { isSubmitDisabled } from '../services/utils';

const MainMarket = () => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();
    const dispatch = useDispatch();

    const { mainMarketId } = useParams();
    const itemId = Number(mainMarketId);

    const [confirmedDiscardChanges, setConfirmedDiscardChanges] = useState(false);

    const { itemTypes } = useSelector((state) => state.item);
    const { itemTypeId } = itemTypes.length > 0 && itemTypes.find((item) => item.description === 'Market Price Model');

    const itemPropertiesModal = useDisclosure();
    const editSystemComponentModal = useDisclosure();

    const books = useSelector((state) => state.item.dataForItemCreation.books);
    const locations = useSelector((state) => state.item.dataForItemCreation.locations);

    const isSystemComponentActive = useIsFeatureActive(FEATURE_FLAG_NAMES.SYSTEMCOMPONENT_GRID_VISIBLE);

    const [mainMarket, setMainMarket] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [dropdownOptions, setDropdownOptions] = useState({
        data: { loads: [], spotPrices: [], forwardPrices: [] },
        loading: true,
        error: false,
    });

    const [selectedModelId, setSelectedModelId] = useState(null);

    const [inEditDescription, setInEditDescription] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);

    const fetchMainMarketList = useCallback(() => {
        return getMainMarket(mainMarketId, {})
            .then((data) => setMainMarket((prev) => ({ ...prev, data: data.item })))
            .catch((err) => setMainMarket((prev) => ({ ...prev, error: true })))
            .finally(() => setMainMarket((prev) => ({ ...prev, loading: false })));
    }, [mainMarketId]);

    const fetchDropdownOptions = useCallback(() => {
        return getDropdownOptions()
            .then((data) => setDropdownOptions((prev) => ({ ...prev, data })))
            .catch((err) => setDropdownOptions((prev) => ({ ...prev, error: true })))
            .finally(() => setDropdownOptions((prev) => ({ ...prev, loading: false })));
    }, []);

    useEffect(() => {
        fetchMainMarketList();
        fetchDropdownOptions();
    }, [fetchMainMarketList, fetchDropdownOptions]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const formLabels = {
        description: intl.formatMessage({ id: 'main_market_model_name' }),
        book: intl.formatMessage({ id: 'common_book' }),
        location: intl.formatMessage({ id: 'common_location' }),
        master: intl.formatMessage({ id: 'common_master' }),
    };

    const initialFormValues = {
        bookId: mainMarket.data?.bookId || '',
        description: mainMarket.data?.description || '',
        locationId: mainMarket.data?.locationId || '',
        securityId: mainMarket.data?.securityId,
    };

    const closeEditSystemComponentModal = () => {
        setSelectedModelId(null);
        editSystemComponentModal.onClose();
    };

    const openEditSystemComponentModal = useCallback(
        (modelId) => {
            setSelectedModelId(modelId);
            editSystemComponentModal.onOpen();
        },
        [editSystemComponentModal]
    );

    const onEditSystemComponentCellClick = useCallback(
        (params) => {
            openEditSystemComponentModal(params.value);
        },
        [openEditSystemComponentModal]
    );

    const onCellKeyPress = useCallback(
        (params) => {
            if (params.event.key === 'Enter' && params.column.colId === 'systemComponentEdit') {
                openEditSystemComponentModal(params.value);
            }
        },
        [openEditSystemComponentModal]
    );

    const onFormSubmit = async (params) => {
        const successMessage = intl.formatMessage({ id: 'main_market_model_save_changes_success' });
        const { description, bookId, locationId, securityId } = params;

        const payload = {
            locationId: locationId || null,
            description,
            bookId,
            itemTypeId,
            securityId,
        };

        await editItem(mainMarketId, payload);
        toast(successMessage);
        navigateToBasisConfigurator(true);
    };

    const navigateToBasisConfigurator = (forceRedirect = false) => {
        historyPush({
            pathname: `/basis-configurator`,
            search: preserveConnectionSearchParams(window.location.search),
            forceRedirect,
        });
    };

    const onCancelMainMarketModelCreationConfirm = async () => {
        const successMessage = intl.formatMessage({ id: 'main_market_model_delete_success' });

        await deleteItem(mainMarketId);
        toast(successMessage);
    };

    const columns = useMemo(
        () => [
            {
                field: 'description',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'main_market_model_table_name' }),
            },
            {
                field: 'loadId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.loads, required: true },
                headerName: intl.formatMessage({ id: 'main_market_model_table_elec_load' }),
                minWidth: 180,
            },
            {
                field: 'elecSpotPriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.spotPrices, required: true },
                headerName: intl.formatMessage({ id: 'main_market_model_table_elec_spot_price' }),
                minWidth: 180,
            },
            {
                field: 'gasSpotPriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.spotPrices, required: true },
                headerName: intl.formatMessage({ id: 'main_market_model_table_gas_spot_price' }),
                minWidth: 180,
            },
            {
                field: 'elecForwardPriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: true },
                headerName: intl.formatMessage({ id: 'main_market_model_table_elec_forward_price' }),
                minWidth: 180,
            },
            {
                field: 'elecOffForwardPriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: true },
                headerName: intl.formatMessage({ id: 'main_market_model_table_elect_off_forward_price' }),
                minWidth: 180,
            },
            {
                field: 'gasForwardPriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: true },
                headerName: intl.formatMessage({ id: 'main_market_model_table_gas_forward_price' }),
                minWidth: 180,
            },
            {
                field: 'oilPriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'main_market_model_table_oil_price' }),
                minWidth: 180,
            },
            {
                field: 'oil6PriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'main_market_model_table_oil6_price' }),
                minWidth: 180,
            },
            {
                field: 'coalPriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'main_market_model_table_coal_price' }),
                minWidth: 180,
            },
            {
                field: 'dieselPriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'main_market_model_table_diesel_price' }),
                minWidth: 180,
            },
            {
                field: 'noxPriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'main_market_model_table_nox_price' }),
                minWidth: 180,
            },
            {
                field: 'exchangePriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'main_market_model_table_exchange_price' }),
                minWidth: 180,
            },
            {
                field: 'interestPriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'main_market_model_table_interest_price' }),
                minWidth: 180,
            },
            {
                field: 'cO2PriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'main_market_model_table_co2_price' }),
                minWidth: 180,
            },
            {
                field: 'sO2PriceId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'main_market_model_table_so2_price' }),
                minWidth: 180,
            },
            {
                field: 'priorToDeliveryPayoff',
                type: 'number',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'main_market_model_table_prior_to_delivery' }),
            },
            {
                field: 'modelId',
                type: 'button',
                headerName: intl.formatMessage({ id: 'main_market_system_component_modal_title' }),
                colId: 'systemComponetEdit',
                onCellClicked: onEditSystemComponentCellClick,
                sortable: false,
                hide: !isSystemComponentActive,
            },
        ],
        [
            dropdownOptions.data.forwardPrices,
            dropdownOptions.data.loads,
            dropdownOptions.data.spotPrices,
            intl,
            isSystemComponentActive,
            onEditSystemComponentCellClick,
        ]
    );

    const validateItemName = (value) => {
        const trimmed = value.trim();

        if (!trimmed) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Main Model Name' });
        }

        if (trimmed.length > 50) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Main Model Name', lengthRule: '50 characters or less' }
            );
        }
    };

    const phrasing = useMemo(
        () => ({
            create: {
                content: <FormattedMessage id="common_add_rows_sub_heading_without_date" />,
            },
        }),
        []
    );

    const gridResource = useMemo(() => {
        return {
            create: (rows) => addModelDefinitionRows(mainMarketId, rows),
            read: async (pagination) => {
                const data = await getMainMarket(mainMarketId, pagination);
                return { items: data.marketModels, totalCount: data.marketModelsTotalCount };
            },

            update: (rows) => modifyModelDefinitions(mainMarketId, rows[0]),
            delete: (rows) => {
                const data = rows.map((elem) => elem.modelId);
                return deleteModelDefinitions(mainMarketId, data);
            },
            validate: (rows) => validateModelDefinitionRows(mainMarketId, rows),
        };
    }, [mainMarketId]);

    return (
        <Box pt="80px" pb="50px">
            {mainMarket.loading || dropdownOptions.loading ? (
                <DefinitionsSkeleton />
            ) : mainMarket.error || dropdownOptions.error ? (
                <Box m={6}>
                    <Error
                        primaryId="common_error"
                        secondaryId="common_loading_error"
                        additionalText="main market model"
                    />
                </Box>
            ) : (
                <>
                    <Breadcrumb pt="12px" px="24px" separator={<BreadcrumbSeparatorIcon />}>
                        <BreadcrumbItem onClick={() => navigateToBasisConfigurator()}>
                            <BreadcrumbLink>
                                <FormattedMessage id="basis_setup" />
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>
                                <FormattedMessage id="main_market_model_title" />
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>

                    <Formik
                        validateOnChange={false}
                        enableReinitialize
                        initialValues={initialFormValues}
                        onSubmit={onFormSubmit}
                    >
                        {({ isSubmitting, errors, touched, values }) => (
                            <Form>
                                <AutomaticFormikDirtyCheck
                                    isFormDirty={isFormDirty}
                                    onFormDirtyChange={setIsFormDirty}
                                />

                                <ActionBar>
                                    <Flex align="center">
                                        <CustomItemIcon
                                            isMaster={mainMarket.data.securityId}
                                            icon={<MarketPriceModelIcon />}
                                            isStroke={true}
                                        />
                                        {!inEditDescription ? (
                                            <Heading maxW="500px" px={2} as="h2" variant="h2">
                                                {values.description}
                                            </Heading>
                                        ) : (
                                            <InputFormikField
                                                px={3}
                                                flex="1"
                                                maxW="500px"
                                                validate={validateItemName}
                                                name="description"
                                                type="text"
                                                onKeyDown={checkItemInputEvent}
                                                isFastField
                                            />
                                        )}

                                        <Flex align="center">
                                            <Box display="inline-block" mr={1}>
                                                <Tooltip
                                                    label={intl.formatMessage({
                                                        id: 'common_edit_btn_tooltip_and_label',
                                                    })}
                                                >
                                                    <IconButton
                                                        aria-label={intl.formatMessage({
                                                            id: 'common_edit_btn_tooltip_and_label',
                                                        })}
                                                        variant="circular-icon"
                                                        leftIcon={<EditIcon />}
                                                        onClick={() => setInEditDescription((prev) => !prev)}
                                                    />
                                                </Tooltip>
                                            </Box>
                                            <Popover placement="top" trigger={<CustomIconButton icon={<InfoIcon />} />}>
                                                <FormattedMessage id="main_market_model_info_box" />
                                            </Popover>
                                        </Flex>
                                    </Flex>

                                    <Spacer display={{ base: 'none', xl: 'block' }} />

                                    <Button variant="secondary" onClick={() => navigateToBasisConfigurator()}>
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id="common_cancel" />
                                        </Box>
                                    </Button>

                                    <Button
                                        leftIcon={<SaveIcon />}
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        type="submit"
                                        size="sm"
                                        variant="primary-success"
                                    >
                                        <FormattedMessage id="common_save_and_return" />
                                    </Button>
                                </ActionBar>

                                <ActionBar>
                                    <SecondaryButton
                                        leftIcon={<FileIcon />}
                                        size="sm"
                                        variant="secondary"
                                        onClick={itemPropertiesModal.onOpen}
                                        flexShrink={0}
                                    >
                                        <FormattedMessage id="common_properties" />
                                    </SecondaryButton>
                                </ActionBar>

                                <Divider />

                                <SimpleGrid
                                    alignItems="center"
                                    mt={5}
                                    pb={5}
                                    px={6}
                                    columns={{ xl: 3 }}
                                    spacingX={6}
                                    spacingY={4}
                                >
                                    <ComboboxFormikField
                                        id="boodId"
                                        name="bookId"
                                        label={formLabels.book}
                                        options={books}
                                        labelKey="name"
                                        valueKey="id"
                                        isFastField
                                    />

                                    <ComboboxFormikField
                                        id="locationId"
                                        name="locationId"
                                        label={formLabels.location}
                                        options={locations}
                                        labelKey="description"
                                        valueKey="id"
                                        showPlaceholder={true}
                                        placeholderValue=""
                                        isFastField
                                    />
                                    <CheckboxFormikField id="securityId" name="securityId" mt={5}>
                                        {formLabels.master}
                                    </CheckboxFormikField>
                                </SimpleGrid>
                            </Form>
                        )}
                    </Formik>

                    <SortOrderProvider>
                        <Box p={6}>
                            <SubItemData
                                tab="0"
                                gridResource={gridResource}
                                columns={columns}
                                addDataColumns={columns.slice(0, -1)}
                                phrasing={phrasing}
                                primaryKeyName="modelId"
                                onCellKeyPress={onCellKeyPress}
                            />
                        </Box>

                        {editSystemComponentModal.isOpen && (
                            <EditSystemComponentModal
                                modelId={selectedModelId}
                                onClose={closeEditSystemComponentModal}
                            />
                        )}
                    </SortOrderProvider>

                    {itemPropertiesModal.isOpen && (
                        <ItemPropertiesModal
                            isOpen
                            onClose={itemPropertiesModal.onClose}
                            itemId={itemId}
                            successMessage={intl.formatMessage({ id: 'main_market_model_save_changes_success' })}
                        />
                    )}

                    <PromptWrapper
                        when={!confirmedDiscardChanges}
                        onPromptConfirm={() => {
                            setConfirmedDiscardChanges(true);
                            onCancelMainMarketModelCreationConfirm();
                        }}
                        header={<FormattedMessage id="common_discard_changes" />}
                        content={<FormattedMessage id="main_market_model_cancel_confirmation" />}
                    />
                </>
            )}
        </Box>
    );
};

export default MainMarket;
