import React, { useMemo } from 'react';
import { ReactComponent as HydroItemIcon } from '../../icons/item-icons/hydro-item.svg';
import { ReactComponent as HydroPlantIcon } from '../../icons/item-icons/hydro-plant.svg';
import { ReactComponent as HydroPlantUnitIcon } from '../../icons/item-icons/hydro-plant-unit.svg';

const HydroNodeIcon = ({ node }) => {
    return useMemo(() => {
        let Icon;

        if (node.properties.isItem) {
            Icon = HydroItemIcon;
        } else if (node.properties.isPlant) {
            Icon = HydroPlantIcon;
        } else if (node.properties.isPlantUnit) {
            Icon = HydroPlantUnitIcon;
        }

        return Icon ? (
            <Icon role="presentation" className={`hydro-label-icon${node.properties.isMaster ? ' master-node' : ''}`} />
        ) : null;
    }, [node]);
};

export default HydroNodeIcon;
