import React, { useEffect, useRef, memo } from 'react';

import TreeItem from './TreeItem.jsx';
import useInternalState from '../../hooks/useInternalState.js';

import { findNode, noop, isUndefined } from './utils';

const Tree = ({
    nodes,

    defaultFocused,
    focused: focusedProp,
    onFocusChange = noop,

    defaultExpanded = [],
    expanded: expandedProp,
    onExpandChange = noop,

    defaultSelected,
    selected: selectedProp,
    onSelectChange = noop,

    renderLabel,
    expandOnSelect = true,
    ...rest
}) => {
    const rootEl = useRef(null);
    const initialFocus = isUndefined(focusedProp)
        ? isUndefined(defaultFocused) && nodes.length > 0
            ? nodes[0].id
            : defaultFocused
        : undefined;

    const [focused, setFocused] = useInternalState(focusedProp, initialFocus);
    const [expanded, setExpanded] = useInternalState(expandedProp, defaultExpanded);
    const [selected, setSelected] = useInternalState(selectedProp, defaultSelected);

    useEffect(() => {
        // remove tabIndex from previous and add it to current
        const node = rootEl.current.querySelector(`[data-id="treeitem-${focused}"]`);
        const oldNode = rootEl.current.querySelector(`[tabindex="0"]`);

        if (oldNode) {
            oldNode.setAttribute('tabindex', '-1');
        }

        /* istanbul ignore next when nodes is empty it's okay not to have a focused element */
        if (node) {
            node.setAttribute('tabindex', '0');
        }
    }, [focused]);

    useEffect(() => {
        // remove aria-selected from previous and add it to current
        const node = rootEl.current.querySelector(`[data-id="treeitem-${selected}"]`);
        const oldNode = rootEl.current.querySelector(`[aria-selected="true"]`);

        if (oldNode) {
            oldNode.removeAttribute('aria-selected');
        }

        // selected node is optional so we don't show a warning if no node is found
        if (node) {
            node.setAttribute('aria-selected', 'true');
        }
    }, [selected]);

    // This function is passed as a prop to <TreeItem />, but it has custom `memo(arePropsEqual)`
    // which ignores object identity changes. If the `memo()` changes make sure to wrap this with `useCallback()`
    const onItemSelect = (id, isExpandable) => {
        const node = findNode(nodes, id);

        if (expandOnSelect && isExpandable) {
            setExpanded((prev) => {
                return prev.includes(id) ? prev.filter((node) => node !== id) : prev.concat(id);
            });
            onExpandChange(node);
        }

        setFocused(id);
        onFocusChange(node);

        setSelected(id);
        onSelectChange(node);
    };

    const moveToTreeItem = (isPrev) => {
        const items = rootEl.current.querySelectorAll('[role="treeitem"]');

        let nextNode;
        for (let i = 0; i < items.length; i++) {
            const element = items[i];

            if (element.tabIndex === 0) {
                nextNode = isPrev ? items[i - 1] : items[i + 1];
                break;
            }
        }

        if (nextNode) {
            const id = nextNode.dataset.id.replace('treeitem-', '');
            const node = findNode(nodes, id);

            setFocused(id);
            onFocusChange(node);

            nextNode.focus();
            nextNode.firstElementChild.scrollIntoView({ block: 'center' });
        }
    };

    const onKeyDown = (e) => {
        /* istanbul ignore next we test this, but the code coverage tool is still unconvinced */
        if (!nodes.length || e.altKey || e.ctrlKey || e.metaKey) {
            return;
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            // move to previous node that is visible on the screen
            moveToTreeItem(true);
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();

            // move to next node that is visible on the screen
            moveToTreeItem(false);
        } else if (e.key === 'ArrowLeft') {
            e.preventDefault();

            const { treeItem, isExpandable, isExpanded } = getTreeItem(focused);

            if (isExpandable && isExpanded) {
                // close node
                const node = findNode(nodes, focused);

                setExpanded((prev) => prev.filter((node) => node !== focused));
                onExpandChange(node);
            } else {
                // move focus to parent node
                focusItem(treeItem.closest('[role="treeitem"]:not([tabindex="0"])'));
            }
        } else if (e.key === 'ArrowRight') {
            e.preventDefault();

            const { treeItem, isExpandable, isExpanded } = getTreeItem(focused);

            if (isExpandable) {
                if (isExpanded) {
                    // move focus to next child node
                    focusItem(treeItem.querySelector('[role="treeitem"]'));
                } else {
                    // open node
                    const node = findNode(nodes, focused);

                    setExpanded((prev) => prev.concat(focused));
                    onExpandChange(node);
                }
            }
        } else if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();

            const isExpandable = rootEl.current
                .querySelector(`[data-id="treeitem-${focused}"]`)
                .hasAttribute('aria-expanded');

            onItemSelect(focused, isExpandable);
        }
    };

    const getTreeItem = (id) => {
        const treeItem = rootEl.current.querySelector(`[data-id="treeitem-${id}"]`);

        return {
            treeItem,
            isExpandable: treeItem.hasAttribute('aria-expanded'),
            isExpanded: treeItem.getAttribute('aria-expanded') === 'true',
        };
    };

    const focusItem = (item) => {
        if (item) {
            item.focus();
            item.firstElementChild.scrollIntoView({ block: 'center' });

            const id = item.dataset.id.replace('treeitem-', '');
            const node = findNode(nodes, id);

            setFocused(id);
            onFocusChange(node);
        }
    };

    return (
        <ul role="tree" onKeyDown={onKeyDown} {...rest} ref={rootEl}>
            {nodes.map((node) => (
                <TreeItem
                    {...node}
                    expanded={expanded}
                    onItemSelect={onItemSelect}
                    renderLabel={renderLabel}
                    key={node.id}
                />
            ))}
        </ul>
    );
};

export default memo(Tree);
