import { useMemo, useState, useCallback, useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Flex, Heading, Tabs, Tab, TabPanels, TabPanel, TabList, useDisclosure } from '@chakra-ui/react';

import {
    GRID_KEYS,
    createOutages,
    getScheduledOutages,
    updateOutages,
    deleteOutages,
    deleteAllOutages,
    getScheduledOutagesDataSourceView,
    getHistoricalOutput,
    createHistoricalOutput,
    updateHistoricalOutput,
    deleteAllHistoricalOutput,
    deleteHistoricalOutput,
    getHistoricalOutputDataSourceView,
    validateOutages,
    validateHistoricalOutput,
    deleteBid,
    createBid,
    getBiddingStrategy,
    setBiddingStrategyTemplate,
    getBatteryFilters,
} from '../../services/batteries';
import { getValidRowsWithDates } from '../../services/grid';
import { refreshMultisort, exportItemData, exportItemDataGeneric } from '../../services/items';

import { SortOrderContext } from '../grid/utils/SortOrderContext';

import BatteryTimeline from './BatteryTimeline';
import BatteryBiddingStrategy from './BatteryBiddingStrategy';

import SecondaryButton from '../../components/utils/SecondaryButton';
import DownloadModal from '../utils/DownloadModal';

import SubItemData from '../itemData/SubItemData';
import UploadButton from '../itemData/UploadButton';
import ResourceVariablesTabs, { filtersStorageNamespace } from './resourceVariables/ResourceVariablesTabs';
import { FEATURE_FLAG_NAMES } from '../../constants/featureFlags';
import { useIsFeatureActive } from '../../hooks/features-flags';

import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { helpAnchors } from 'constants/help';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';
import AutoformDownloadButton from 'components/autoform/AutoformDownloadButton';
import AutoformUploadButton from 'components/autoform/AutoformUploadButton';
import { useSelector } from 'react-redux';
import { SUBSCHEMACODE_BATTERY } from 'constants/uploadDownload';
import { differenceInDays } from 'date-fns';

const SubBatteryData = ({
    itemId,
    itemTypeId,
    batteryName,
    subBatteryName,
    templateId,
    subBatteryId,
    ancillaries,
    peakPeriods,
    quantityTypes,
    templates,
    templateConfig,
    helpPageUrlItemTypeId = null,
    onTemplatesConfigChange,
    onResourceVariablesDataChange,
    onAutoformTableChange,
}) => {
    const intl = useIntl();
    const downloadModal = useDisclosure();
    const { setSortOrder } = useContext(SortOrderContext);

    const bookId = useSelector((state) => state.connections.book);
    const uploadParameters = [`${bookId}`, `${itemTypeId}`, `${subBatteryId}`];

    const [innerSelectedTab, setInnerSelectedTab] = useState(0);
    const [outerSelectedTab, setOuterSelectedTab] = useState(0);
    const [tabsKey, setTabsKey] = useState(0);
    const [resourceVariablesKey, setResourceVariablesKey] = useState(0);
    const [timelineKey, setTimelineKey] = useState(0);
    const [loadingQueue, setLoadingQueue] = useState([]); // unique keys represeting which resource is in loading state
    const [filters, setFilters] = useState(
        (() => {
            let result = getBatteryFilters(filtersStorageNamespace);

            if (Array.isArray(result)) {
                result = {
                    minEndDate: '',
                };
            }

            return result;
        })()
    );
    const resourceVariablesTabsRef = useRef();

    // 1. Battery Forced Outages is an autoform. If PSBATSTO autoform Forced Outages table PERCENTOUTAGE column isReadonly property checked
    // then this field will be disabled and defaulted to 100 in Tabular view Forced Outages tab autoform
    // 2. Battery Scheduled Outages tab and parameters percent outage field editable property governed by feature flag
    const isPercentOutageReadOnly = !useIsFeatureActive(FEATURE_FLAG_NAMES.BATTERY_PERCENTOUTAGE_FIELD_EDITABLE);

    const showAdditionalGridButtons = ![0].includes(outerSelectedTab);

    const refreshTabs = useCallback(() => {
        setTabsKey((prev) => prev + 1);
        setSortOrder((prev) => ({ ...prev, ...refreshMultisort(Object.values(GRID_KEYS)) }));
    }, [setSortOrder]);

    const onLoadingStart = useCallback((key) => {
        setLoadingQueue((prev) => prev.concat(key));
    }, []);

    const onLoadingEnd = useCallback((key) => {
        setLoadingQueue((prev) => prev.filter((p) => p !== key));
    }, []);

    const scheduledOutagesGridResource = useMemo(() => {
        return {
            create: (rows) => createOutages(subBatteryId, rows),
            read: (pagination) => getScheduledOutages(subBatteryId, pagination),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate']);

                return updateOutages(subBatteryId, validRows);
            },
            delete: (rows) =>
                deleteOutages(
                    subBatteryId,
                    rows.map((row) => row.generationOutageId)
                ),
            deleteAll: () => deleteAllOutages(subBatteryId),
            validate: (rows) => validateOutages(subBatteryId, rows),
            getDataSourceView: (pagination) => getScheduledOutagesDataSourceView(subBatteryId, pagination),
        };
    }, [subBatteryId]);

    const historicalOutputGridResource = useMemo(() => {
        return {
            create: (rows) => createHistoricalOutput(subBatteryId, rows),
            read: (pagination) => getHistoricalOutput(subBatteryId, pagination),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['eventDate']);

                return updateHistoricalOutput(subBatteryId, validRows);
            },
            delete: (rows) =>
                deleteHistoricalOutput(
                    subBatteryId,
                    rows.map((row) => row.id)
                ),
            deleteAll: () => deleteAllHistoricalOutput(subBatteryId),
            validate: (rows) => validateHistoricalOutput(subBatteryId, rows),
            getDataSourceView: (pagination) => getHistoricalOutputDataSourceView(subBatteryId, pagination),
        };
    }, [subBatteryId]);

    const onDownloadData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const payload = {
                biddingFilter: {
                    templateId,
                },
                scheduledOutageSorting: sortOrder[GRID_KEYS.SCHEDULED_OUTAGES],
                historicalOutputSorting: sortOrder[GRID_KEYS.HISTORICAL_OUTPUT],
                exportTemplate,
            };

            return exportItemData(`batteries/${subBatteryId}/export`, payload);
        },
        [subBatteryId, templateId]
    );

    const onDownloadGeneric = useCallback(() => {
        const itemCompId = subBatteryId;
        const filterDate = filters?.minEndDate ? new Date(filters?.minEndDate) : undefined;
        const daysBack = filterDate ? Math.abs(differenceInDays(filterDate, new Date())) : undefined;
        return exportItemDataGeneric(SUBSCHEMACODE_BATTERY, itemId, daysBack, itemCompId);
    }, [itemId, subBatteryId, filters?.minEndDate]);

    // 'Percent outage at {percentOutage} default to 100% for Scheduled Outages and check readonly'
    // Battery Scheduled Outages Percent outage - percentOutage field is required,
    // defaulted to 100% and could be readOnly based on feature flag
    const scheduledOutagesColumns = useMemo(
        () => [
            {
                type: 'date',
                field: 'startDate',
                headerName: intl.formatMessage({ id: 'common_grid_start_date' }),
                cellEditorParams: { nullable: true },
            },
            {
                type: 'date',
                field: 'endDate',
                headerName: intl.formatMessage({ id: 'common_grid_end_date' }),
                cellEditorParams: { nullable: true },
            },
            {
                type: 'number',
                field: 'percentOutage',
                headerName: intl.formatMessage({
                    id: 'batteries_scheduled_outages_percent_outage',
                }),
                editable: !isPercentOutageReadOnly,
                cellEditorParams: {
                    defaultValue: '100',
                    // Backend validation requires this value in Battery Scheduled Outages Create and Update Rules
                    required: true,
                },
            },
        ],
        [intl, isPercentOutageReadOnly]
    );

    const historicalOutputColumns = useMemo(
        () => [
            {
                type: 'date',
                field: 'eventDate',
                headerName: intl.formatMessage({ id: 'batteries_historical_output_event_date' }),
                cellEditorParams: { nullable: true },
            },
            {
                type: 'number',
                field: 'value',
                headerName: intl.formatMessage({
                    id: 'batteries_historical_output_value',
                }),
            },
            {
                field: 'quantityTypeId',
                type: 'select',
                cellEditorParams: { options: quantityTypes, required: true },
                headerName: intl.formatMessage({ id: 'batteries_historical_output_quantity' }),
            },
        ],
        [intl, quantityTypes]
    );

    const handleResourceVariablesDataChange = useCallback(() => {
        //refresh the Timeline
        setTimelineKey((prev) => prev + 1);
        onResourceVariablesDataChange && onResourceVariablesDataChange();
    }, [onResourceVariablesDataChange]);

    const handleResourceVariablesUploadChange = useCallback(() => {
        //refresh the Timeline
        setTimelineKey((prev) => prev + 1);
        //refresh the selected Resources Variable tab
        setTabsKey((prev) => prev + 1);
        onResourceVariablesDataChange && onResourceVariablesDataChange();
    }, [onResourceVariablesDataChange]);

    const handleAutoformTableChange = useCallback(
        (table) => onAutoformTableChange(table, subBatteryId),
        [onAutoformTableChange, subBatteryId]
    );

    const biddingStrategyGridResource = useMemo(() => {
        return {
            read: (payload) => getBiddingStrategy(subBatteryId, payload),
            update: (payload) => setBiddingStrategyTemplate(subBatteryId, payload),
            createBid: (payload) => createBid(subBatteryId, payload),
            deleteBid: (participationId) => deleteBid(subBatteryId, participationId),
        };
    }, [subBatteryId]);

    return (
        <Tabs
            px={0}
            mt={6}
            isLazy
            isManual
            lazyBehavior="keepMounted"
            key={tabsKey}
            index={outerSelectedTab}
            onChange={setOuterSelectedTab}
        >
            <TabList w="100%" flexWrap={{ base: 'wrap' }} alignItems="center">
                <Tab>
                    <FormattedMessage id="common_historical_data_timeline_editor" />
                </Tab>

                <Tab>
                    <FormattedMessage id="batteries_tabular_view" />
                </Tab>

                <Tab>
                    <FormattedMessage id="common_historical_data_bidding_strategy" />
                </Tab>

                <Tab>
                    <FormattedMessage id="batteries_scheduled_outages_heading" />
                </Tab>

                <Tab>
                    <FormattedMessage id="batteries_historical_output_heading" />
                </Tab>

                <Flex
                    ml={{ base: 'auto' }}
                    mb={{ base: 1 }}
                    order={{ base: -1, xl: 0 }}
                    w={{ base: '100%', xl: 'auto' }}
                    flexWrap={{ base: 'wrap' }}
                >
                    {showAdditionalGridButtons && (
                        <>
                            {outerSelectedTab === 1 ? (
                                resourceVariablesTabsRef?.current && (
                                    <>
                                        <AutoformDownloadButton
                                            tablesMetadata={resourceVariablesTabsRef?.current?.autoformTables}
                                            parentTableId={resourceVariablesTabsRef?.current?.parentTableId}
                                            ml={{ base: 0 }}
                                            mr={{ base: 2 }}
                                            mb={{ base: 1 }}
                                            mt={{ base: 1 }}
                                            config={resourceVariablesTabsRef?.current?.downloadButtonConfig}
                                            onDownloadGeneric={onDownloadGeneric}
                                            filterDate={filters?.minEndDate}
                                            w="auto !important"
                                        />
                                        <AutoformUploadButton
                                            tablesMetadata={resourceVariablesTabsRef?.current?.autoformTables}
                                            parentTableId={resourceVariablesTabsRef?.current?.parentTableId}
                                            onAfterUploadChange={handleResourceVariablesUploadChange}
                                            mr={{ base: 2 }}
                                            mb={{ base: 1 }}
                                            mt={{ base: 1 }}
                                            itemId={subBatteryId}
                                            subschemaCode={SUBSCHEMACODE_BATTERY}
                                            parameters={uploadParameters}
                                            config={resourceVariablesTabsRef?.current?.uploadButtonConfig}
                                            w="auto !important"
                                        />
                                    </>
                                )
                            ) : (
                                <>
                                    <SecondaryButton
                                        leftIcon={<DownloadIcon />}
                                        w="auto !important"
                                        mr={{ base: 2 }}
                                        mt={{ base: 1 }}
                                        size="sm"
                                        type="button"
                                        variant="secondary"
                                        onClick={downloadModal.onOpen}
                                    >
                                        <FormattedMessage id="common_download_btn_tooltip_and_label" />
                                    </SecondaryButton>
                                    <UploadButton
                                        type="batteries"
                                        typeFriendlyName="Bidding Strategy, Scheduled Outages, and Historical Output Tabs"
                                        subschemaCode={SUBSCHEMACODE_BATTERY}
                                        parameters={uploadParameters}
                                        itemId={subBatteryId}
                                        onTemplatesConfigChange={onTemplatesConfigChange}
                                        onAfterUploadChange={refreshTabs}
                                        hasLabel={true}
                                        mr={{ base: 2 }}
                                        mt={{ base: 1 }}
                                        w="auto !important"
                                    />
                                </>
                            )}
                        </>
                    )}

                    <SecondaryButton
                        onClick={refreshTabs}
                        size="sm"
                        variant="secondary"
                        leftIcon={<RefreshIcon />}
                        isLoading={loadingQueue.length > 0}
                        loadingText={intl.formatMessage({ id: 'common_loading' })}
                        textTransform="capitalize"
                        mr={{ base: 2 }}
                        mb={{ base: 1 }}
                        mt={{ base: 1 }}
                    >
                        <FormattedMessage id="common_refresh" />
                    </SecondaryButton>
                </Flex>
            </TabList>

            <TabPanels mt={4}>
                <TabPanel key={`timelineKey_${timelineKey}`}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="batteries_timeline_editor_heading" />
                        <HelpButtonComponent
                            helpAnchor={helpAnchors.BATTERY_DEFINITIONS}
                            itemTypeId={helpPageUrlItemTypeId}
                        />
                    </Heading>

                    <BatteryTimeline
                        loadingQueue={loadingQueue}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        subBatteryId={subBatteryId}
                        ancillaries={ancillaries}
                        peakPeriods={peakPeriods}
                        onDataChange={() => setResourceVariablesKey((prev) => prev + 1)}
                    />
                </TabPanel>

                <TabPanel p={0} key={`resourceVariablesKey_${resourceVariablesKey}`}>
                    <ResourceVariablesTabs
                        ref={resourceVariablesTabsRef}
                        itemId={itemId}
                        innerSelectedTab={innerSelectedTab}
                        onInnerTabChange={setInnerSelectedTab} // Inner tab's state is lifted here in order to not reset tabs on refresh
                        batteryName={batteryName}
                        subBatteryName={subBatteryName}
                        subBatteryId={subBatteryId}
                        onDataChange={handleResourceVariablesDataChange}
                        onAutoformTableChange={handleAutoformTableChange}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        filters={filters}
                        setFilters={setFilters}
                    />
                </TabPanel>

                <TabPanel>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="batteries_bidding_strategy_heading" />
                        <HelpButtonComponent
                            helpAnchor={helpAnchors.BATTERY_BIDDING_STRATEGY}
                            itemTypeId={helpPageUrlItemTypeId}
                        />
                    </Heading>

                    <BatteryBiddingStrategy
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={biddingStrategyGridResource}
                        ancillaries={ancillaries}
                        templates={templates}
                        templateConfig={templateConfig}
                        onTemplatesConfigChange={(payload) => onTemplatesConfigChange(subBatteryId, payload)}
                    />
                </TabPanel>

                <TabPanel>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="batteries_scheduled_outages_heading" />
                        <HelpButtonComponent
                            helpAnchor={helpAnchors.BATTERY_SCHEDULED_OUTAGES}
                            itemTypeId={helpPageUrlItemTypeId}
                        />
                    </Heading>

                    <SubItemData
                        tab="2"
                        name={GRID_KEYS.SCHEDULED_OUTAGES}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={scheduledOutagesGridResource}
                        columns={scheduledOutagesColumns}
                        addDataColumns={scheduledOutagesColumns}
                        primaryKeyName="generationOutageId"
                    />
                </TabPanel>

                <TabPanel>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="batteries_historical_output_heading" />
                        <HelpButtonComponent
                            helpAnchor={helpAnchors.BATTERY_HISTORICAL_OUTPUT}
                            itemTypeId={helpPageUrlItemTypeId}
                        />
                    </Heading>

                    <SubItemData
                        tab="3"
                        name={GRID_KEYS.HISTORICAL_OUTPUT}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={historicalOutputGridResource}
                        columns={historicalOutputColumns}
                        addDataColumns={historicalOutputColumns}
                        primaryKeyName="id"
                    />
                </TabPanel>
            </TabPanels>

            {downloadModal.isOpen && (
                <DownloadModal
                    isOpen
                    onClose={downloadModal.onClose}
                    onDownloadGeneric={onDownloadGeneric}
                    onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                    onDownloadTemplate={() => onDownloadData(true)}
                    bodyMessage={<FormattedMessage id="download_batteries_modal_explanation" />}
                />
            )}
        </Tabs>
    );
};

export default SubBatteryData;
