import { useMemo, useState, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tabs, TabPanels, TabPanel, TabList, Tab, Heading, useDisclosure } from '@chakra-ui/react';

import { transitionMatrixGridKeys } from '../../constants/thermal';

import {
    getTransitionMatrixPeriods,
    updateTransitionMatrixPeriods,
    createTransitionMatrixPeriods,
    deleteTransitionMatrixPeriods,
    deleteAllTransitionMatrixPeriods,
    getTransitionMatrixTransitions,
    updateTransitionMatrixTransitions,
    createTransitionMatrixTransitions,
    deleteTransitionMatrixTransitions,
    deleteAllTransitionMatrixTransitions,
    getAllMatrixPeriods,
} from '../../services/thermals';
import { getValidRowsWithDates, addComboboxOption } from '../../services/grid';
import { exportItemData } from '../../services/items';

import SortOrderProvider from '../grid/utils/SortOrderContext';

import MainModal from '../modal/MainModal';

import TabListWrapper from '../utils/TabListWrapper';
import DownloadModal from '../utils/DownloadModal';
import SecondaryButton from '../utils/SecondaryButton';
import SubItemData from '../itemData/SubItemData';
import UploadButton from '../itemData/UploadButton';

import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

const TransitionMatrixModal = ({ selectedThermal, metadata, subThermals, onClose }) => {
    const intl = useIntl();
    const downloadModal = useDisclosure();

    const [key, setKey] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const [datePeriods, setDatePeriods] = useState([]);

    const refreshTabs = useCallback(() => setKey((prev) => prev + 1), []);

    const subThermalsOptions = useMemo(
        () => addComboboxOption(subThermals, -1, intl.formatMessage({ id: 'common_off' })),
        [intl, subThermals]
    );

    const getAllPeriods = useCallback(() => {
        getAllMatrixPeriods(selectedThermal.id).then((data) => {
            setDatePeriods(data);
        });
    }, [selectedThermal.id]);

    const onTabChange = useCallback(
        (index) => {
            setSelectedTab(index);
            getAllPeriods();
        },
        [getAllPeriods]
    );

    useEffect(() => {
        getAllPeriods();
    }, [getAllPeriods]);

    const transitionMatrixPeriodsGridResource = useMemo(() => {
        return {
            create: (rows) => createTransitionMatrixPeriods(selectedThermal.id, rows),
            read: (pagination) => getTransitionMatrixPeriods(selectedThermal.id, { ...pagination }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate']);
                return updateTransitionMatrixPeriods(selectedThermal.id, validRows);
            },
            delete: (rows) =>
                deleteTransitionMatrixPeriods(
                    selectedThermal.id,
                    rows.map((row) => row.transitionPeriodId)
                ),
            deleteAll: () => deleteAllTransitionMatrixPeriods(selectedThermal.id),
        };
    }, [selectedThermal.id]);

    const transitionMatrixTransitionsGridResource = useMemo(() => {
        return {
            create: (rows) => createTransitionMatrixTransitions(selectedThermal.id, rows),
            read: (pagination) => getTransitionMatrixTransitions(selectedThermal.id, { ...pagination }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate']);
                return updateTransitionMatrixTransitions(selectedThermal.id, validRows);
            },
            delete: (rows) =>
                deleteTransitionMatrixTransitions(
                    selectedThermal.id,
                    rows.map((row) => row.transitionId)
                ),
            deleteAll: () => deleteAllTransitionMatrixTransitions(selectedThermal.id),
        };
    }, [selectedThermal.id]);

    const transitionMatrixPeriodsColumns = useMemo(() => {
        return [
            {
                field: 'description',
                headerName: intl.formatMessage({ id: 'common_date_period' }),
                cellEditorParams: { required: true },
            },
            {
                type: 'date',
                field: 'startDate',
                headerName: intl.formatMessage({ id: 'common_grid_start_date' }),
                sort: 'asc',
                minWidth: 250,
                cellEditorParams: { required: true },
            },
            {
                type: 'date',
                field: 'endDate',
                headerName: intl.formatMessage({ id: 'common_grid_end_date' }),
                minWidth: 250,
                cellEditorParams: { required: true },
            },
        ];
    }, [intl]);

    const transitionMatrixTransitionsColumns = useMemo(() => {
        return [
            {
                field: 'transitionPeriodId',
                type: 'select',
                cellEditorParams: { options: datePeriods, required: true },
                headerName: intl.formatMessage({ id: 'common_transition_periods' }),
                sort: 'asc',
                minWidth: 180,
            },
            {
                field: 'fromItemCompId',
                type: 'select',
                cellEditorParams: { options: subThermalsOptions, required: false },
                headerName: intl.formatMessage({ id: 'matrix_from_unit' }),
                minWidth: 180,
            },
            {
                field: 'toItemCompId',
                type: 'select',
                cellEditorParams: { options: subThermalsOptions, required: false },
                headerName: intl.formatMessage({ id: 'matrix_to_unit' }),
                minWidth: 180,
            },
            {
                field: 'parameterId',
                type: 'select',
                cellEditorParams: {
                    options: metadata.dropdowns.transitionMatrixParameters,
                    required: false,
                },
                headerName: intl.formatMessage({ id: 'matrix_parameter' }),
                minWidth: 180,
            },
            {
                field: 'parameterValue',
                type: 'number',
                headerName: intl.formatMessage({ id: 'thermal_common_value' }),
            },
        ];
    }, [datePeriods, intl, metadata.dropdowns.transitionMatrixParameters, subThermalsOptions]);

    const transitionMatrixPeriodsPhrasing = useMemo(() => {
        return {
            delete: {
                header: <FormattedMessage id="common_delete_row" />,
                content: <FormattedMessage id="thermal_transition_matrix_multi_grid_delete_description" />,
            },
            deleteAll: {
                header: <FormattedMessage id="common_delete_all_modal_heading" />,
                content: <FormattedMessage id="thermal_transition_matrix_multi_grid_delete_all_description" />,
            },
        };
    }, []);

    const onDownloadMatrixPeriodsData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const payload = { sorting: sortOrder[transitionMatrixGridKeys.PERIODS], exportTemplate };

            return exportItemData(`/thermal/${selectedThermal.id}/matrix-periods-export`, payload);
        },
        [selectedThermal.id]
    );

    const onDownloadMatrixTransitionsData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const payload = { sorting: sortOrder[transitionMatrixGridKeys.TRANSITIONS], exportTemplate };

            return exportItemData(`/thermal/${selectedThermal.id}/matrix-transitions-export`, payload);
        },
        [selectedThermal.id]
    );

    return (
        <MainModal
            isOpen
            onClose={onClose}
            header={
                <>
                    {selectedThermal.description} - <FormattedMessage id="common_transition_matrix" />
                </>
            }
            content={
                <SortOrderProvider>
                    <Tabs isLazy isManual key={key} index={selectedTab} onChange={onTabChange}>
                        <TabList>
                            <TabListWrapper>
                                <Tab>
                                    <FormattedMessage id="common_date_periods" />
                                </Tab>

                                <Tab>
                                    <FormattedMessage id="common_transitions" />
                                </Tab>
                            </TabListWrapper>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <Heading as="h3" variant="h3" textTransform="capitalize">
                                    <FormattedMessage id="common_date_periods" />
                                </Heading>

                                <SubItemData
                                    tab="1"
                                    name={transitionMatrixGridKeys.PERIODS}
                                    heading={<FormattedMessage id="common_date_periods" />}
                                    gridResource={transitionMatrixPeriodsGridResource}
                                    actionBarSlot={() => (
                                        <>
                                            <SecondaryButton
                                                leftIcon={<DownloadIcon />}
                                                w="auto !important"
                                                mr={2}
                                                size="sm"
                                                type="button"
                                                variant="secondary"
                                                onClick={downloadModal.onOpen}
                                            >
                                                <FormattedMessage id="common_download_btn_tooltip_and_label" />
                                            </SecondaryButton>

                                            <UploadButton
                                                type="thermal-matrix-periods"
                                                itemId={selectedThermal.id}
                                                hasLabel={true}
                                                onAfterUploadChange={refreshTabs}
                                                mr={4}
                                                w="auto !important"
                                                hideTreatWarningsAsErrors
                                            />

                                            {downloadModal.isOpen && (
                                                <DownloadModal
                                                    isOpen
                                                    onClose={downloadModal.onClose}
                                                    onDownloadData={(sortOrder) =>
                                                        onDownloadMatrixPeriodsData(false, sortOrder)
                                                    }
                                                    onDownloadTemplate={() => onDownloadMatrixPeriodsData(true)}
                                                />
                                            )}
                                        </>
                                    )}
                                    columns={transitionMatrixPeriodsColumns}
                                    addDataColumns={transitionMatrixPeriodsColumns}
                                    phrasing={transitionMatrixPeriodsPhrasing}
                                />
                            </TabPanel>

                            <TabPanel>
                                <Heading as="h3" variant="h3" textTransform="capitalize">
                                    <FormattedMessage id="common_transitions" />
                                </Heading>

                                <SubItemData
                                    tab="2"
                                    name={transitionMatrixGridKeys.TRANSITIONS}
                                    heading={<FormattedMessage id="common_transitions" />}
                                    gridResource={transitionMatrixTransitionsGridResource}
                                    actionBarSlot={() => (
                                        <>
                                            <SecondaryButton
                                                leftIcon={<DownloadIcon />}
                                                w="auto !important"
                                                mr={2}
                                                size="sm"
                                                type="button"
                                                variant="secondary"
                                                onClick={downloadModal.onOpen}
                                            >
                                                <FormattedMessage id="common_download_btn_tooltip_and_label" />
                                            </SecondaryButton>

                                            <UploadButton
                                                type="thermal-matrix-transitions"
                                                itemId={selectedThermal.id}
                                                hasLabel={true}
                                                onAfterUploadChange={refreshTabs}
                                                mr={4}
                                                w="auto !important"
                                                hideTreatWarningsAsErrors
                                            />

                                            {downloadModal.isOpen && (
                                                <DownloadModal
                                                    isOpen
                                                    onClose={downloadModal.onClose}
                                                    onDownloadData={(sortOrder) =>
                                                        onDownloadMatrixTransitionsData(false, sortOrder)
                                                    }
                                                    onDownloadTemplate={() => onDownloadMatrixTransitionsData(true)}
                                                />
                                            )}
                                        </>
                                    )}
                                    columns={transitionMatrixTransitionsColumns}
                                    addDataColumns={transitionMatrixTransitionsColumns}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </SortOrderProvider>
            }
            secondaryButton={<></>}
        />
    );
};

export default TransitionMatrixModal;
