import { createSlice } from '@reduxjs/toolkit';
import { getItemTypes, getPricingPoints } from './itemApi';

const initialState = {
    itemTypes: [],
    dataForItemCreation: [],
};

const itemSlice = createSlice({
    name: 'items',
    initialState,
    reducers: {},
    extraReducers: {
        [getItemTypes.fulfilled]: (state, action) => {
            state.itemTypes = action.payload;
        },
        [getPricingPoints.fulfilled]: (state, action) => {
            state.dataForItemCreation = action.payload;
        },
    },
});

export default itemSlice.reducer;
