import { useRef, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import DataGridWrapper from '../grid/DataGridWrapper';
import DataGrid from '../grid/DataGrid';

const ForwardPriceTab = ({ options, basis, updateBasis, issues }) => {
    const gridApi = useRef(null);
    const intl = useIntl();

    const columns = useMemo(
        () => [
            {
                field: 'refForwardOnPeakId',
                type: 'select',
                cellEditorParams: { options: options.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_ref_forward_on_peak' }),
                minWidth: 180,
                sortable: false,
            },
            {
                field: 'refForwardOffPeakId',
                type: 'select',
                cellEditorParams: { options: options.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_ref_forward_off_peak' }),
                minWidth: 180,
                sortable: false,
            },
            {
                field: 'refForwardATCId',
                type: 'select',
                cellEditorParams: { options: options.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_ref_forward_atc' }),
                minWidth: 180,
                sortable: false,
            },
            {
                field: 'delForwardOnPeakId',
                type: 'select',
                cellEditorParams: { options: options.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_del_forward_on_peak' }),
                minWidth: 180,
                sortable: false,
            },
            {
                field: 'delForwardOffPeakId',
                type: 'select',
                cellEditorParams: { options: options.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_del_forward_off_peak' }),
                minWidth: 180,
                sortable: false,
            },
            {
                field: 'delForwardATCId',
                type: 'select',
                cellEditorParams: { options: options.forwardPrices, required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_del_forward_atc' }),
                minWidth: 180,
                sortable: false,
            },
        ],
        [intl, options.forwardPrices]
    );

    const onGridReady = useCallback(
        (params) => {
            const {
                refForwardOnPeakId,
                refForwardOffPeakId,
                refForwardATCId,
                delForwardOnPeakId,
                delForwardOffPeakId,
                delForwardATCId,
            } = basis;
            gridApi.current = params.api;

            gridApi.current.setRowData([
                {
                    refForwardOnPeakId,
                    refForwardOffPeakId,
                    refForwardATCId,
                    delForwardOnPeakId,
                    delForwardOffPeakId,
                    delForwardATCId,
                },
            ]);
        },
        [basis]
    );

    const updateBasisData = useCallback(
        async (rowIds) => {
            const rows = rowIds.map((id) => gridApi.current.getRowNode(id));

            const {
                refForwardOnPeakId,
                refForwardOffPeakId,
                refForwardATCId,
                delForwardOnPeakId,
                delForwardOffPeakId,
                delForwardATCId,
            } = rows[0].data;

            updateBasis({
                ...basis,
                refForwardOnPeakId,
                refForwardOffPeakId,
                refForwardATCId,
                delForwardOnPeakId,
                delForwardOffPeakId,
                delForwardATCId,
            });
        },
        [basis, updateBasis]
    );

    return (
        <DataGridWrapper>
            <DataGrid
                onGridReady={onGridReady}
                onDataChange={updateBasisData}
                suppressPaginationPanel
                columns={columns}
                issues={issues}
            />
        </DataGridWrapper>
    );
};

export default ForwardPriceTab;
