import { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Flex,
    SimpleGrid,
    Heading,
    Box,
    useBoolean,
    IconButton,
    useDisclosure,
    Spacer,
    Button,
    Divider,
} from '@chakra-ui/react';

import {
    GRID_KEY,
    multisortPairs,
    createInstruments,
    deleteAllInstruments,
    updateInstruments,
    deleteInstruments,
    getHedgeInstrumentsDataSourceView,
    gridColumns,
    getHedge,
    getHedgeFilters,
    getHedgeInstruments,
    modifyHedgeProperty,
    getValidRows,
    validateHedge,
    resetPlantTypes,
} from '../../services/hedges';
import { editItem, deleteItem, checkItemInputEvent, formatMultisort, exportItemData } from '../../services/items';
import { validateLongLat } from '../../services/portfolios';
import { updateIssues } from '../../services/grid';
import { isSubmitDisabled } from '../../services/utils';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import { onHasUnsavedChanges } from '../../store/helpers/helpersSlice';

import HedgeCommonPropertySelect from './HedgeCommonPropertySelect';
import HedgeCommonPropertyDate from './HedgeCommonPropertyDate';
import EditAdjustmentsModal from './EditAdjustmentsModal';

import ItemPropertiesModal from '../utils/ItemPropertiesModal';
import CustomItemIcon from '../utils/CustomItemIcon';

import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import ShowHideSidebarButton from '../layout/ShowHideSidebarButton';

import DeleteAllButton from '../itemData/DeleteAllButton';
import UploadButton from '../itemData/UploadButton';

import ConfirmationModal from '../modal/ConfirmationModal';

import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import Error from '../utils/Error';
import Tooltip from '../utils/Tooltip';
import PromptWrapper from '../utils/PromptWrapper';
import ActionBar from '../utils/ActionBar';
import SecondaryIconButton from '../utils/SecondaryIconButton';
import SecondaryButton from '../utils/SecondaryButton';
import DownloadModal from '../utils/DownloadModal';

import { mapGeneralValidationMessages } from '../autoform/utils/autoformUtils';

import DataGrid from '../grid/DataGrid';
import DataGridWrapper from '../grid/DataGridWrapper';
import SortOrderProvider from '../grid/utils/SortOrderContext';
import AddDataModal from '../grid/AddDataModal';

import InputField from '../forms/InputField';
import CustomCheckbox from '../forms/CustomCheckbox';
import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';
import AutomaticFormikDirtyCheck from '../forms/AutomaticFormikDirtyCheck';
import GeneralFormValidationsAlert from '../forms/GeneralFormValidationsAlert';
import HelpButtonComponent from '../utils/HelpButtonComponent';
import ErrorAccordion from '../utils/ErrorAccordion';

import { ReactComponent as HedgeIcon } from '../../icons/item-icons/hedges-and-contracts.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as FileIcon } from '../../icons/file.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { ReactComponent as AddIcon } from '../../icons/add-item.svg';
import { helpAnchors } from 'constants/help';
import { TechTypeCount } from './TechTypeCount';

const none = '-';
const PRIMARY_KEY_NAME = 'instrumentId';
const MULTIPLE_PLACEHOLDER_VALUE = 'multiple';

const Hedge = ({
    hedgeItem,
    onHedgeDelete,
    onHedgeEdit,
    onNodeRename,
    onNodeChangeIsMaster,
    onNodeItemDelete,
    onNodeOwnershipPercentageUpdate,
    refreshMapLayer,
    helpPageUrlItemTypeId = null,
}) => {
    const intl = useIntl();
    const params = useParams();
    const { toast } = useCommonToast();
    const dispatch = useDispatch();
    const historyPush = useHistoryPush();

    const itemPropertiesModal = useDisclosure();
    const deleteHedgeItemModal = useDisclosure();
    const editAdjustmentsModal = useDisclosure();
    const downloadModal = useDisclosure();
    const addDataModal = useDisclosure();
    const globalErrorsDisclosure = useDisclosure({ defaultIsOpen: true });
    const globalWarningsDisclosure = useDisclosure({ defaultIsOpen: true });

    const books = useSelector((state) => state.item.dataForItemCreation.books);
    const itemTypes = useSelector((state) => state.item.itemTypes);
    const financialAndPhysicalInstruments = itemTypes.filter(
        (itemType) => itemType.description === 'Financial Instrument' || itemType.description === 'Physical Instrument'
    );
    const hedgeId = Number(params.hedgeId || hedgeItem.properties.id);

    const [selectedItemTypeId, setSelectedItemTypeId] = useState();

    const [selectedInstrumentId, setSelectedInstrumentId] = useState(null);
    const [inEditDescription, setInEditDescription] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSubForm, setIsLoadingSubForm] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [hedge, setHedge] = useState({
        description: '',
        bookId: null,
        pricingPoint: '',
        itemTypeId: null,
    });
    const [initialMainFormValues, setInitialMainFormValues] = useState({
        description: hedge.description,
        bookId: hedge.bookId,
        itemTypeId: hedge.itemTypeId,
        latitude: hedge?.latitude !== null ? hedge?.latitude : '',
        longitude: hedge?.longitude !== null ? hedge?.longitude : '',
        securityId: hedge.securityId,
    });
    const [afterUpload, setAfterUpload] = useState(null);
    const [isFormDirty, setIsFormDirty] = useState(false);

    const [primaryCommoditiesOptions, setPrimaryCommoditiesOptions] = useState([]);
    const [instrumentTypeOptions, setInstrumentTypeOptions] = useState([]);
    const [counterpartiesOptions, setCounterpartiesOptions] = useState([]);
    const [secondaryCommoditiesOptions, setSecondaryCommoditiesOptions] = useState([]);
    const [quantityTypesOptions, setQuantityTypesOptions] = useState([]);
    const [masterAgreementsOptions, setMasterAgreementsOptions] = useState([]);
    const [peakPeriodsOptions, setPeakPeriodsOptions] = useState([]);
    const [plantTypeOptions, setPlantTypeOptions] = useState([]);
    const [dataSourcePagination, setDataSourcePagination] = useState();

    const [initialTransactionFormValues, setInitialTransactionFormValues] = useState({
        primaryCommodities: [],
        instrumentType: none,
        counterparties: [],
        secondaryCommodities: [],
        quantityTypes: [],
        masterAgreements: [],
        plantTypes: [],
        dates: [],
    });

    const initialTransactionFormTouched = {
        primaryCommodities: true,
        instrumentType: true,
        counterparties: true,
        secondaryCommodities: true,
        quantityTypes: true,
        masterAgreements: true,
        plantTypes: true,
        dates: true,
    };

    const mainFormLabels = {
        book: intl.formatMessage({ id: 'hedges_book' }),
        financialPhysical: intl.formatMessage({ id: 'hedges_modal_item_type_label' }),
        powerSimmID: intl.formatMessage({ id: 'hedge_powersimm_id' }),
        latitude: intl.formatMessage({ id: 'latitude' }),
        longitude: intl.formatMessage({ id: 'longitude' }),
        master: intl.formatMessage({ id: 'common_master' }),
    };

    const gridApi = useRef(null);
    const [isExpanded, setIsExpanded] = useBoolean();
    const [columns, setColumns] = useState([]);
    const [issues, setIssues] = useState([]);
    const [globalErrors, setGlobalErrors] = useState([]);
    const [globalWarnings, setGlobalWarnings] = useState([]);

    const globalIssues = useMemo(
        () => ({ errors: globalErrors, warnings: globalWarnings }),
        [globalErrors, globalWarnings]
    );

    const fetchInstrumentsByType = useCallback(
        async (instrumentTypeId) => {
            const data = await getHedgeInstruments(hedgeId, instrumentTypeId);

            const maybeAddMultiple = (list) => (list.length > 1 ? list.concat(MULTIPLE_PLACEHOLDER_VALUE) : list);

            setInitialTransactionFormValues((prev) => ({
                ...prev,
                primaryCommodities: maybeAddMultiple(data.primaryCommodityIds),
                counterparties: maybeAddMultiple(data.counterPartyIds),
                secondaryCommodities: maybeAddMultiple(data.secondaryCommodityIds),
                quantityTypes: maybeAddMultiple(data.quantityTypeIds),
                masterAgreements: maybeAddMultiple(data.masterAgreementIds),
                plantTypes: maybeAddMultiple(data.plantTypeIds),
                dates: maybeAddMultiple(data.dates),
            }));

            setColumns(data.columns);

            return data;
        },
        [hedgeId]
    );

    // Will change afterUpload value which will trigger below useEffect initialSetup()
    const reloadHedgeItem = useCallback(() => setAfterUpload((prev) => !prev), []);

    useEffect(() => {
        const initialSetup = async () => {
            setIsLoading(true);
            setIsLoadingSubForm(true);
            setLoadingError(false);

            try {
                const hedgeProperties = await getHedge(hedgeId);
                setHedge(hedgeProperties);

                setInitialMainFormValues({
                    description: hedgeProperties.description,
                    bookId: hedgeProperties.bookId,
                    itemTypeId: hedgeProperties.itemTypeId,
                    latitude: hedgeProperties?.latitude !== null ? hedgeProperties?.latitude : '',
                    longitude: hedgeProperties?.longitude !== null ? hedgeProperties?.longitude : '',
                    securityId: hedgeProperties.securityId,
                });

                setSelectedItemTypeId(hedgeProperties.itemTypeId);

                const commonProperties = await getHedgeFilters(hedgeId, hedgeProperties.itemTypeId);

                setPrimaryCommoditiesOptions(commonProperties.primaryCommodities);
                setCounterpartiesOptions(commonProperties.counterparties);
                setSecondaryCommoditiesOptions(commonProperties.secondaryCommodities);
                setQuantityTypesOptions(commonProperties.quantityTypes);
                setMasterAgreementsOptions(commonProperties.masterAgreements);
                setPeakPeriodsOptions(commonProperties.peakPeriods);
                setPlantTypeOptions(commonProperties.plantTypes);

                const defaultInstrumentType = commonProperties.instumentTypes.find((elem) => elem.instrumentsCount > 0);

                setInstrumentTypeOptions(
                    commonProperties.instumentTypes.map((elem) => {
                        let suffix = '';
                        if (elem.instrumentsCount > 0) {
                            suffix = intl.formatMessage({ id: 'hedge_instrument_type_has_data' });
                        }

                        return {
                            ...elem,
                            description: elem.description + suffix,
                            code: elem.description,
                        };
                    })
                );

                setInitialTransactionFormValues((prev) => ({
                    ...prev,
                    instrumentType: defaultInstrumentType
                        ? defaultInstrumentType.id
                        : commonProperties.defaultInstrumentTypeId,
                }));

                fetchInstrumentsByType(
                    defaultInstrumentType ? defaultInstrumentType.id : commonProperties.defaultInstrumentTypeId
                );
            } catch (error) {
                setLoadingError(true);
            }
            setIsLoading(false);
            setIsLoadingSubForm(false);
        };

        initialSetup();
    }, [hedgeId, fetchInstrumentsByType, intl, afterUpload]);

    const resetServerValidation = useCallback((resetIssues = true) => {
        resetIssues && setIssues([]);
        setGlobalErrors([]);
        setGlobalWarnings([]);
    }, []);

    const refreshGridData = useCallback(() => {
        resetServerValidation();

        gridApi.current.api.refreshServerSideStore({
            purge: true,
        });
    }, [resetServerValidation]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    // top section
    const onHedgeFormSubmit = async ({ bookId, description, itemTypeId, latitude, longitude, securityId }) => {
        const successMessage = intl.formatMessage({ id: 'hedges_save_changes_success' });
        const arcGISError = intl.formatMessage({ id: 'common_item_arcgis_lat_long_save_failed' });

        try {
            const payload = {
                bookId,
                description,
                itemTypeId,
                pricingPointId: null,
                longitude: longitude !== '' ? longitude : null,
                latitude: latitude !== '' ? latitude : null,
                securityId,
            };
            const { arcGisIssue } = await editItem(hedge.id, payload);

            setInitialMainFormValues({
                description,
                bookId,
                itemTypeId,
                longitude,
                latitude,
                securityId,
            });

            if (!hedgeItem) {
                onHedgeEdit({ id: hedge.id, description, bookId, securityId });
            } else {
                onNodeRename(description);
                onNodeChangeIsMaster(securityId.securityId);
            }

            setInEditDescription(false);

            toast({
                message: successMessage,
                slot: arcGisIssue && <ErrorAccordion message={arcGISError} details={arcGisIssue} status={'warn'} />,
                duration: arcGisIssue && 20000,
            });
        } finally {
            if (typeof refreshMapLayer === 'function') {
                refreshMapLayer({ long: longitude, lat: latitude, objectRefId: hedge.objectRefId });
            }
        }
    };

    const onEditDescription = (isValid) => {
        if (!isValid) {
            return;
        }
        setInEditDescription((prev) => !prev);
    };

    const validateDescription = (value) => {
        const trimmed = value.trim();
        const label = intl.formatMessage({ id: 'hedge_label_name' });

        if (trimmed.length === 0) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }

        if (trimmed.length > 100) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label, lengthRule: '100 characters or less' }
            );
        }
    };

    const validateMainForm = (values) => {
        let longitude = validateLongLat(values.longitude, 'long', values.latitude);
        let latitude = validateLongLat(values.latitude, 'lat', values.longitude);

        return {
            ...(longitude ? { longitude } : null),
            ...(latitude ? { latitude } : null),
        };
    };

    const onItemDelete = async ({ id }) => {
        await deleteItem(id);

        dispatch(onHasUnsavedChanges(false));

        if (!hedgeItem) {
            onHedgeDelete(id);
            historyPush('/hedges-contracts');
        } else {
            onNodeItemDelete();
        }

        toast(intl.formatMessage({ id: 'common_delete_success' }, { item: 'hedge item' }));
    };

    // transaction specifics
    const toggleMultiple = (key) => {
        setInitialTransactionFormValues((prev) => {
            const oldValue = prev[key];

            return {
                ...prev,
                [key]: oldValue.includes(MULTIPLE_PLACEHOLDER_VALUE)
                    ? oldValue.filter((c) => c !== MULTIPLE_PLACEHOLDER_VALUE)
                    : oldValue.concat(MULTIPLE_PLACEHOLDER_VALUE),
            };
        });
    };

    const validateField = (value) => {
        return !value.includes(MULTIPLE_PLACEHOLDER_VALUE) && (value.length === 0 || value.includes(-1));
    };

    const validateTransactionForm = (values) => {
        let errors = {};

        if (validateField(values.primaryCommodities)) {
            errors.primaryCommodities = intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: 'Primary Commodity' }
            );
        }

        if (validateField(values.quantityTypes)) {
            errors.quantityTypes = intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: 'Quantity type' }
            );
        }

        return errors;
    };

    const openEditAdjustmentModal = useCallback(
        (instrumentId) => {
            setSelectedInstrumentId(instrumentId);
            editAdjustmentsModal.onOpen();
        },
        [editAdjustmentsModal]
    );

    const closeEditAdjustmentModal = () => {
        setSelectedInstrumentId(null);
        editAdjustmentsModal.onClose();
    };

    const serverSideDatasource = useMemo(() => {
        return {
            // @todo this is called 3 times on Instrument Type change
            async getRows(params) {
                const pagination = {
                    paging: {
                        pageSize: 500,
                        page: params.request.startRow / 500 + 1,
                    },
                    sorting: formatMultisort(params.request.sortModel, multisortPairs),
                };

                const payload = {
                    ...pagination,
                    instrumentTypeId: initialTransactionFormValues.instrumentType,
                };
                setDataSourcePagination(pagination);

                const data = await getHedgeInstruments(hedgeId, payload);

                if (data.instruments.totalCount === 0) {
                    params.api.showNoRowsOverlay();
                }

                params.success({
                    rowData: data.instruments.items,
                    rowCount: data.instruments.totalCount,
                });
            },
        };
    }, [initialTransactionFormValues.instrumentType, hedgeId]);

    const serverSideDataSourceView = useMemo(() => {
        return {
            async getRows() {
                const payload = {
                    ...dataSourcePagination,
                    instrumentTypeId: initialTransactionFormValues.instrumentType,
                };

                const data = await getHedgeInstrumentsDataSourceView(hedgeId, payload);
                return data;
            },
        };
    }, [initialTransactionFormValues.instrumentType, hedgeId, dataSourcePagination]);

    const updateServerSideData = useCallback(
        async (rowIds) => {
            const rows = rowIds.map((id) => gridApi.current.api.getRowNode(id));
            const validRows = getValidRows(rows, selectedItemTypeId);

            if (validRows.length === 0) return;

            // reset the previous errors and warnings
            resetServerValidation(false);

            try {
                // @todo the `peakPeriod` isn't correct - it's the old value, because we're only working with `peakPeriodId` (the dropdown)
                // the BE shouldn't be even sending it, but at least it doesn't read it when sending the /modify-instruments endpoint
                const { data = {} } = await updateInstruments(validRows);

                let updatedIssues = [];

                if (data.issues) {
                    // if we had previous errors, we make a change and now we only have warnings
                    // we swap messages in the issues dialog for the row
                    updatedIssues = updateIssues(issues, data.issues);
                } else {
                    // if we had previous errors/warnings, we made a change and we don't have anything wrong
                    // we delete all messages in the issues dialog for the row
                    const ids = rowIds.map((rowId) => gridApi.current.api.getRowNode(rowId).data[PRIMARY_KEY_NAME]); // instrumentTypeId
                    updatedIssues = issues.filter((issueObj) => !ids.includes(issueObj.id));
                }

                if (data.globalIssues) {
                    // make sure to re-open the disclosures again
                    globalWarningsDisclosure.onOpen();

                    // handle global warnings
                    const { warnings } = mapGeneralValidationMessages(data.globalIssues);
                    setGlobalWarnings(warnings);
                }

                setIssues(updatedIssues);

                await fetchInstrumentsByType(initialTransactionFormValues.instrumentType);

                toast(intl.formatMessage({ id: 'hedge_save_changes_success' }));
            } catch (error) {
                if (error.response?.data.issues) {
                    setIssues((prev) => [...prev, ...error.response.data.issues]);
                }

                if (error.response?.data.globalIssues) {
                    // make sure to re-open the disclosures again,
                    // because the user might have closed them and is submitting the form again
                    globalErrorsDisclosure.onOpen();
                    globalWarningsDisclosure.onOpen();

                    // handle global errors and warnings
                    const { warnings, errors } = mapGeneralValidationMessages(error.response.data.globalIssues);
                    setGlobalWarnings(warnings);
                    setGlobalErrors(errors);
                }
            }
        },
        [
            fetchInstrumentsByType,
            resetServerValidation,
            intl,
            toast,
            initialTransactionFormValues.instrumentType,
            issues,
            globalWarningsDisclosure,
            globalErrorsDisclosure,
            selectedItemTypeId,
        ]
    );

    // Update instrument array with the new instrument count and update suffix
    const updateInstrumentTypeOptions = useCallback(
        (rows, type) => {
            const suffix = intl.formatMessage({ id: 'hedge_instrument_type_has_data' });

            const instrumentOccurrences = rows
                .map((elem) => elem.instrumentTypeId)
                .reduce(function (prev, curr) {
                    if (type === 'add') {
                        if (prev[curr]) {
                            prev[curr] += 1;
                        } else {
                            prev[curr] = 1;
                        }
                    } else {
                        if (prev[curr]) {
                            prev[curr] -= 1;
                        } else {
                            prev[curr] = -1;
                        }
                    }
                    return prev;
                }, {});

            setInstrumentTypeOptions(([...prev]) =>
                prev.map((elem) => {
                    if (instrumentOccurrences[elem.id]) {
                        return {
                            ...elem,
                            instrumentsCount: elem.instrumentsCount + instrumentOccurrences[elem.id],
                            description:
                                elem.instrumentsCount + instrumentOccurrences[elem.id] > 0
                                    ? elem.code + suffix
                                    : elem.code,
                        };
                    }
                    return elem;
                })
            );
        },
        [intl]
    );

    const onDeleteRow = useCallback(
        async (rowIds) => {
            const nodes = rowIds.map((id) => gridApi.current.api.getRowNode(id).data);
            const instrumentIds = nodes.map((elem) => elem.instrumentId);

            await deleteInstruments(hedgeId, instrumentIds);
            updateInstrumentTypeOptions(nodes, 'delete');

            toast(intl.formatMessage({ id: 'common_delete_grid_msg' }, { n: instrumentIds.length }));

            refreshGridData();
        },
        [hedgeId, intl, toast, updateInstrumentTypeOptions, refreshGridData]
    );

    const onDeleteAllSuccess = () => {
        setInstrumentTypeOptions(([...prev]) =>
            prev.map((elem) => {
                return {
                    ...elem,
                    instrumentsCount: 0,
                    description: elem.code,
                };
            })
        );
    };

    const onCellKeyPress = useCallback(
        (params) => {
            if (params.event.key === 'Enter' && params.column.colId === 'adjustmentEdit') {
                openEditAdjustmentModal(params.value);
            }
        },
        [openEditAdjustmentModal]
    );

    const onEditAdjustmentsCellClick = useCallback(
        (params) => {
            openEditAdjustmentModal(params.value);
        },
        [openEditAdjustmentModal]
    );

    const onAddRowsSuccess = useCallback(async () => {
        toast(intl.formatMessage({ id: 'common_generic_item_change_success' }));

        await fetchInstrumentsByType(initialTransactionFormValues.instrumentType);
        refreshGridData();
    }, [intl, toast, refreshGridData, fetchInstrumentsByType, initialTransactionFormValues.instrumentType]);

    const mainGridResource = useMemo(() => {
        return {
            // the create, validate requests don't follow the RESTful principles and includes the Resource ID in each row
            create: async (rows) => {
                const payload = rows.map((row) => ({ ...row, itemId: hedgeId, itemTypeId: selectedItemTypeId }));
                const data = await createInstruments(payload);

                updateInstrumentTypeOptions(rows, 'add');

                return data;
            },
            validate: async (rows) => {
                const payload = rows.map((row) => ({ ...row, itemId: hedgeId, itemTypeId: selectedItemTypeId }));
                return await validateHedge(payload);
            },
            getDataSourceView: serverSideDataSourceView.getRows,
        };
    }, [hedgeId, updateInstrumentTypeOptions, selectedItemTypeId, serverSideDataSourceView.getRows]);

    const getColumnDisplayName = (field, columns) => {
        const fieldName = field.toLowerCase();
        const col = columns.find(({ name }) => name.toLowerCase() === fieldName);

        return col ? col.displayName : field;
    };

    const visibleColumns = gridColumns
        .filter(({ field }) => {
            const fieldName = field.toLowerCase();

            return columns.some(({ name }) => name.toLowerCase() === fieldName);
        })
        .map((column) => column.field);

    const getUserGeneratedColumns = useCallback(
        (type, n, isExpandable, extraColumnConfig) => {
            return Array.from({ length: n }).map((_, i) => {
                const field = type + (i + 1);

                const headerName = getColumnDisplayName(field, columns);
                const hide = !headerName || (isExpandable ? !isExpanded : false) || !visibleColumns.includes(field);

                return {
                    field,
                    headerName,
                    hide,
                    minWidth: 150,
                    ...extraColumnConfig,
                };
            });
        },
        [columns, isExpanded, visibleColumns]
    );

    const updateHedgeProperty = useCallback(
        async (propertyName, propertyValue) => {
            setIsDisabled(true);

            try {
                if (propertyName === 'instrumentType') {
                    setInitialTransactionFormValues((prev) => ({
                        ...prev,
                        instrumentType: propertyValue,
                    }));

                    await fetchInstrumentsByType(propertyValue);
                } else {
                    setInitialTransactionFormValues((prev) => ({
                        ...prev,
                        [propertyName]: [propertyValue],
                    }));

                    const isRequired = propertyName === 'primaryCommodities' || propertyName === 'quantityTypes';

                    if ((isRequired && propertyValue !== -1) || !isRequired) {
                        await modifyHedgeProperty(hedgeId, {
                            instrumentType: initialTransactionFormValues.instrumentType,
                            propertyName,
                            propertyValue,
                            itemTypeId: selectedItemTypeId,
                        });

                        refreshGridData();

                        toast(intl.formatMessage({ id: 'hedge_save_changes_success' }));
                    }
                }
            } finally {
                setIsDisabled(false);
            }
        },
        [
            hedgeId,
            intl,
            initialTransactionFormValues,
            selectedItemTypeId,
            fetchInstrumentsByType,
            refreshGridData,
            toast,
        ]
    );

    const mainGridColumns = useMemo(() => {
        return [
            {
                type: 'checkbox',
                field: 'useFlag',
                headerName: intl.formatMessage({ id: 'hedge_grid_use_flag' }),
                hide: !visibleColumns.includes('useFlag'),
            },
            {
                field: 'primaryCommodityId',
                type: 'select',
                cellEditorParams: { options: primaryCommoditiesOptions },
                headerName: intl.formatMessage({ id: 'hedge_primary_commodity' }),
                hide:
                    !initialTransactionFormValues.primaryCommodities.includes(MULTIPLE_PLACEHOLDER_VALUE) ||
                    !visibleColumns.includes('primaryCommodityId'),
                minWidth: 180,
            },
            {
                field: 'startDate',
                type: 'date',
                headerName: intl.formatMessage({ id: 'common_grid_start_date' }),
                hide: !visibleColumns.includes('startDate'),
            },
            {
                field: 'endDate',
                type: 'date',
                headerName: intl.formatMessage({ id: 'common_grid_end_date' }),
                hide: !visibleColumns.includes('endDate'),
            },
            {
                field: 'peakPeriodId',
                type: 'select',
                cellEditorParams: { options: peakPeriodsOptions },
                headerName: intl.formatMessage({ id: 'hedge_grid_peak_period' }),
                hide: !visibleColumns.includes('peakPeriodId'),
                minWidth: 180,
            },
            ...getUserGeneratedColumns('price', 6, false, { type: 'number' }),
            {
                field: 'quantity',
                type: 'number',
                headerName: intl.formatMessage({ id: 'hedge_grid_quantity' }),
                hide: !visibleColumns.includes('quantity'),
            },
            {
                field: 'quantityTypeId',
                type: 'select',
                cellEditorParams: { options: quantityTypesOptions },
                headerName: intl.formatMessage({ id: 'hedge_quantity_type' }),
                hide:
                    !initialTransactionFormValues.quantityTypes.includes(MULTIPLE_PLACEHOLDER_VALUE) ||
                    !visibleColumns.includes('quantityTypeId'),
                minWidth: 180,
            },
            {
                field: 'counterPartyId',
                type: 'select',
                cellEditorParams: { options: counterpartiesOptions, required: false },
                headerName: intl.formatMessage({ id: 'hedge_counterparty' }),
                hide:
                    !initialTransactionFormValues.counterparties.includes(MULTIPLE_PLACEHOLDER_VALUE) ||
                    !visibleColumns.includes('counterPartyId'),
                minWidth: 180,
            },
            {
                field: 'transactionDate',
                type: 'date',
                cellEditorParams: { nullable: true },
                headerName: intl.formatMessage({ id: 'hedge_transaction_date' }),
                hide:
                    !initialTransactionFormValues.dates.includes(MULTIPLE_PLACEHOLDER_VALUE) ||
                    !visibleColumns.includes('transactionDate'),
            },
            {
                field: 'expiryDate',
                type: 'date',
                cellEditorParams: { nullable: true },
                headerName: intl.formatMessage({ id: 'hedge_grid_expiry_date' }),
                hide: !visibleColumns.includes('expiryDate'),
            },
            {
                field: 'masterAgreementId',
                type: 'select',
                cellEditorParams: { options: masterAgreementsOptions, required: false },
                headerName: intl.formatMessage({ id: 'hedge_master_agreement' }),
                hide:
                    !initialTransactionFormValues.masterAgreements.includes(MULTIPLE_PLACEHOLDER_VALUE) ||
                    !visibleColumns.includes('masterAgreementId'),
                minWidth: 180,
            },
            {
                field: 'plantTypeId',
                type: 'select',
                cellEditorParams: { options: plantTypeOptions, required: false },
                headerName: intl.formatMessage({ id: 'hedge_plant_type' }),
                hide:
                    !initialTransactionFormValues.plantTypes.includes(MULTIPLE_PLACEHOLDER_VALUE) ||
                    !visibleColumns.includes('plantTypeId'),
                minWidth: 180,
            },
            {
                field: 'secondaryCommodityId',
                type: 'select',
                cellEditorParams: { options: secondaryCommoditiesOptions, required: false },
                headerName: intl.formatMessage({ id: 'hedge_secondary_commodity' }),
                hide:
                    !initialTransactionFormValues.secondaryCommodities.includes(MULTIPLE_PLACEHOLDER_VALUE) ||
                    !visibleColumns.includes('secondaryCommodityId'),
                minWidth: 180,
            },
            {
                field: 'sourceLocation',
                headerName: intl.formatMessage({ id: 'hedge_grid_source_location' }),
                hide: !visibleColumns.includes('sourceLocation'),
                sortable: false,
            },
            {
                field: 'systemIdentifier',
                headerName: intl.formatMessage({ id: 'hedge_grid_system_identifier' }),
                hide: !visibleColumns.includes('systemIdentifier'),
                sortable: false,
            },
            {
                field: 'instrumentId',
                type: 'button',
                headerName: intl.formatMessage({ id: 'hedge_adjustments_modal_title' }),
                colId: 'adjustmentEdit',
                onCellClicked: onEditAdjustmentsCellClick,
                sortable: false,
            },
        ];
    }, [
        counterpartiesOptions,
        getUserGeneratedColumns,
        initialTransactionFormValues.counterparties,
        initialTransactionFormValues.dates,
        initialTransactionFormValues.masterAgreements,
        initialTransactionFormValues.primaryCommodities,
        initialTransactionFormValues.quantityTypes,
        initialTransactionFormValues.secondaryCommodities,
        initialTransactionFormValues.plantTypes,
        intl,
        masterAgreementsOptions,
        plantTypeOptions,
        onEditAdjustmentsCellClick,
        peakPeriodsOptions,
        primaryCommoditiesOptions,
        quantityTypesOptions,
        secondaryCommoditiesOptions,
        visibleColumns,
    ]);

    let addCols = mainGridColumns
        .filter((c) => c.field !== 'instrumentId')
        .map((c) => {
            const re = c.field.match(/^(?<type>price|expansion)(?<index>\d+)$/);
            const id = re ? (re.groups.type === 'price' ? 'hedge_grid_price_n' : 'hedge_grid_expansion_n') : null;

            return {
                type: c.type,
                field: c.field,
                headerName: re ? intl.formatMessage({ id }, { n: re.groups.index }) : c.headerName,
                cellEditorParams: {
                    ...c.cellEditorParams,
                    ...([
                        'instrumentTypeId',
                        'primaryCommodityId',
                        'startDate',
                        'endDate',
                        'peakPeriodId',
                        'quantityTypeId',
                    ].includes(c.field)
                        ? { required: true }
                        : null),
                },
            };
        });

    addCols.splice(1, 0, {
        field: 'instrumentTypeId',
        type: 'select',
        headerName: intl.formatMessage({ id: 'hedge_instrument_type' }),
        cellEditorParams: {
            options: instrumentTypeOptions.map((option) => ({ id: option.id, description: option.code })),
            required: true,
            defaultValue: initialTransactionFormValues.instrumentType,
        },
    });

    const onDownloadData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const payload = {
                sorting: sortOrder[GRID_KEY],
                instrumentTypeId: initialTransactionFormValues.instrumentType,
                exportTemplate,
            };

            return exportItemData(`hedges/${hedgeId}/export`, payload);
        },
        [hedgeId, initialTransactionFormValues.instrumentType]
    );

    const instrumentTypeIds = useMemo(() => instrumentTypeOptions.map((inst) => inst.id), [instrumentTypeOptions]);

    const onItemTypeIdChange = useCallback(
        async (value) => {
            setIsDisabled(true);
            setSelectedItemTypeId(value);
            try {
                const commonProperties = await getHedgeFilters(hedgeId, value);
                setPlantTypeOptions(commonProperties?.plantTypes);
                const defaultInstrumentType = commonProperties.instumentTypes.find((elem) => elem.instrumentsCount > 0);
                await fetchInstrumentsByType(
                    defaultInstrumentType ? defaultInstrumentType.id : commonProperties.defaultInstrumentTypeId
                );
                await resetPlantTypes(hedgeId, instrumentTypeIds);

                refreshGridData();
            } catch (error) {
                setLoadingError(true);
            }
            setIsDisabled(false);
        },
        [hedgeId, fetchInstrumentsByType, refreshGridData, instrumentTypeIds]
    );

    return (
        <>
            <PromptWrapper />

            <ExpandableItemWrapper>
                {isLoading ? (
                    <DefinitionsSkeleton />
                ) : loadingError ? (
                    <Box m={6}>
                        <Error primaryId="common_error" secondaryId="common_loading_error" additionalText="hedge" />
                    </Box>
                ) : (
                    <SortOrderProvider>
                        {/* main form  */}
                        <Formik
                            enableReinitialize
                            initialValues={initialMainFormValues}
                            onSubmit={onHedgeFormSubmit}
                            validate={validateMainForm}
                        >
                            {({ isSubmitting, isValid, errors, touched, values }) => (
                                <Form>
                                    <AutomaticFormikDirtyCheck
                                        isFormDirty={isFormDirty}
                                        onFormDirtyChange={setIsFormDirty}
                                    />

                                    <Flex pt={3} pl={6} align="center">
                                        <CustomItemIcon
                                            isMaster={initialMainFormValues.securityId}
                                            icon={<HedgeIcon />}
                                        />

                                        {!inEditDescription ? (
                                            <Heading px={2} as="h2" variant="h2">
                                                {values.description}
                                            </Heading>
                                        ) : (
                                            <InputFormikField
                                                name="description"
                                                px={3}
                                                type="text"
                                                flex="1"
                                                maxW="500px"
                                                validate={validateDescription}
                                                onKeyDown={checkItemInputEvent}
                                                isFastField
                                            />
                                        )}

                                        <Box display="inline-block" mr={4}>
                                            <Tooltip
                                                label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}
                                            >
                                                <IconButton
                                                    aria-label={intl.formatMessage({
                                                        id: 'common_edit_btn_tooltip_and_label',
                                                    })}
                                                    variant="circular-icon"
                                                    leftIcon={<EditIcon />}
                                                    onClick={() => onEditDescription(isValid)}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Flex>

                                    <ActionBar>
                                        <ShowHideSidebarButton />

                                        <SecondaryIconButton
                                            leftIcon={<FileIcon />}
                                            size="sm"
                                            variant="secondary"
                                            flexShrink={0}
                                            onClick={itemPropertiesModal.onOpen}
                                        >
                                            <FormattedMessage id="portfolio_item_properties" />
                                        </SecondaryIconButton>

                                        <Spacer display={{ base: 'none', xl: 'block' }} />

                                        <Button
                                            onClick={deleteHedgeItemModal.onOpen}
                                            leftIcon={<DeleteIcon />}
                                            type="button"
                                            variant="special"
                                            size="sm"
                                        >
                                            <FormattedMessage id="common_delete_item_btn" />
                                        </Button>

                                        <Button
                                            leftIcon={<SaveIcon />}
                                            isDisabled={isSubmitDisabled({ errors, touched })}
                                            isLoading={isSubmitting}
                                            type="submit"
                                            size="sm"
                                            variant="primary-success"
                                        >
                                            <FormattedMessage id="common_save_changes_button" />
                                        </Button>
                                    </ActionBar>

                                    <Divider />

                                    <SimpleGrid pt={7} pb={5} px={6} columns={{ xl: 3 }} spacingX={6} spacingY={4}>
                                        <ComboboxFormikField
                                            id="bookId"
                                            name="bookId"
                                            label={mainFormLabels.book}
                                            options={books}
                                            valueKey="id"
                                            labelKey="name"
                                            isRequired
                                        />

                                        <ComboboxFormikField
                                            id="itemTypeId"
                                            name="itemTypeId"
                                            label={mainFormLabels.financialPhysical}
                                            options={financialAndPhysicalInstruments}
                                            onChange={onItemTypeIdChange}
                                            valueKey="itemTypeId"
                                            labelKey="description"
                                            isRequired
                                            requireConfirmation
                                            confirmationConfig={{
                                                hasExtraStep: true,
                                                header: <FormattedMessage id="hedge_modify_item_type" />,
                                                content: <FormattedMessage id="hedge_modify_item_type_content" />,
                                                additionalContent: (
                                                    <TechTypeCount
                                                        hedgeId={hedgeId}
                                                        instrumentTypeIds={instrumentTypeIds}
                                                    />
                                                ),
                                                confirmText: (
                                                    <Box as="span" textTransform="capitalize">
                                                        <FormattedMessage id="common_continue" />
                                                    </Box>
                                                ),
                                            }}
                                        />

                                        <InputField
                                            id="powerSimmID"
                                            name="powerSimmID"
                                            label={mainFormLabels.powerSimmID}
                                            value={hedge.id}
                                            isDisabled
                                        />

                                        <InputFormikField
                                            id="latitude"
                                            name="latitude"
                                            label={mainFormLabels.latitude}
                                            type="number"
                                            isFastField
                                        />

                                        <InputFormikField
                                            id="longitude"
                                            name="longitude"
                                            label={mainFormLabels.longitude}
                                            type="number"
                                            isFastField
                                        />

                                        <CheckboxFormikField id="securityId" name="securityId" mt={5}>
                                            {mainFormLabels.master}
                                        </CheckboxFormikField>
                                    </SimpleGrid>

                                    <Divider />
                                </Form>
                            )}
                        </Formik>

                        {/* transaction specifics form */}
                        <Heading m={6} as="h2" variant="h2">
                            <FormattedMessage id="hedge_transaction_specifics" />
                            <HelpButtonComponent
                                helpAnchor={helpAnchors.HEDGES_TRANSACTION_SPECIFICS}
                                itemTypeId={helpPageUrlItemTypeId}
                            />
                        </Heading>
                        {isLoadingSubForm ? (
                            <DefinitionsSkeleton />
                        ) : (
                            <Formik
                                enableReinitialize
                                initialTouched={initialTransactionFormTouched}
                                initialValues={initialTransactionFormValues}
                                validate={validateTransactionForm}
                            >
                                <Form>
                                    <Flex
                                        m={6}
                                        justify="space-between"
                                        flexWrap="wrap"
                                        direction={{ base: 'column', xl: 'row' }}
                                        align={{ base: 'unset', xl: 'center' }}
                                    >
                                        <Flex direction={{ base: 'column', xl: 'row' }}>
                                            <HedgeCommonPropertySelect
                                                name="instrumentType"
                                                label={<FormattedMessage id="hedge_instrument_type_filter" />}
                                                isDisabled={isDisabled}
                                                options={instrumentTypeOptions}
                                                info={<FormattedMessage id="hedge_instrument_type_popover" />}
                                                footerLabel={
                                                    <FormattedMessage
                                                        id="hedge_instrument_select_footer"
                                                        values={{
                                                            haveData: instrumentTypeOptions.filter(
                                                                (elem) => elem.instrumentsCount > 0
                                                            ).length,
                                                            all: instrumentTypeOptions.length,
                                                        }}
                                                    />
                                                }
                                                onChange={(value) => updateHedgeProperty('instrumentType', value)}
                                                minWidth="160px"
                                                isRequired
                                            />
                                        </Flex>
                                        <Flex direction={{ base: 'column', xl: 'row' }}>
                                            <CustomCheckbox
                                                minWidth="fit-content"
                                                isChecked={isExpanded}
                                                onChange={setIsExpanded.toggle}
                                                mr={5}
                                            >
                                                <FormattedMessage id="hedge_expand_details" />
                                            </CustomCheckbox>

                                            <SecondaryButton
                                                leftIcon={<DownloadIcon />}
                                                w="auto !important"
                                                m={{ base: '0 0 16px 0', xl: '0 16px 0 0' }}
                                                size="sm"
                                                type="button"
                                                variant="secondary"
                                                onClick={downloadModal.onOpen}
                                            >
                                                <FormattedMessage id="common_download_btn_tooltip_and_label" />
                                            </SecondaryButton>

                                            <UploadButton
                                                type="hedges"
                                                itemId={hedgeId}
                                                queryParams={{ itemTypeId: selectedItemTypeId }}
                                                hasLabel={true}
                                                m={{ base: '16px 0', xl: '0 16px 0 0' }}
                                                onAfterUploadChange={reloadHedgeItem}
                                            />

                                            <DeleteAllButton
                                                subItemId={hedgeId}
                                                onDelete={deleteAllInstruments}
                                                onSuccess={onDeleteAllSuccess}
                                                refetchData={reloadHedgeItem}
                                                mb={{ base: 4, xl: 0 }}
                                            />

                                            <SecondaryButton
                                                ml={{ base: 0, xl: 4 }}
                                                variant="secondary"
                                                size="sm"
                                                leftIcon={<AddIcon />}
                                                onClick={addDataModal.onOpen}
                                                textTransform="capitalize"
                                            >
                                                <FormattedMessage id="common_add_rows_btn" />
                                            </SecondaryButton>
                                        </Flex>
                                    </Flex>

                                    <SimpleGrid m={6} columns={{ xl: 3 }} spacingX={6} spacingY={5}>
                                        <HedgeCommonPropertySelect
                                            name="primaryCommodities"
                                            label={<FormattedMessage id="hedge_primary_commodity" />}
                                            isDisabled={isDisabled}
                                            options={primaryCommoditiesOptions}
                                            onMultipleToggleChange={() => toggleMultiple('primaryCommodities')}
                                            onChange={(value) => updateHedgeProperty('primaryCommodities', value)}
                                        />

                                        <HedgeCommonPropertySelect
                                            name="counterparties"
                                            label={<FormattedMessage id="hedge_counterparty" />}
                                            isDisabled={isDisabled}
                                            options={counterpartiesOptions}
                                            onMultipleToggleChange={() => toggleMultiple('counterparties')}
                                            onChange={(value) => updateHedgeProperty('counterparties', value)}
                                            placeholder="-"
                                        />

                                        <HedgeCommonPropertySelect
                                            name="secondaryCommodities"
                                            label={<FormattedMessage id="hedge_secondary_commodity" />}
                                            isDisabled={isDisabled}
                                            options={secondaryCommoditiesOptions}
                                            onMultipleToggleChange={() => toggleMultiple('secondaryCommodities')}
                                            onChange={(value) => updateHedgeProperty('secondaryCommodities', value)}
                                            placeholder="-"
                                        />

                                        <HedgeCommonPropertySelect
                                            name="quantityTypes"
                                            label={<FormattedMessage id="hedge_quantity_type" />}
                                            isDisabled={isDisabled}
                                            options={quantityTypesOptions}
                                            onMultipleToggleChange={() => toggleMultiple('quantityTypes')}
                                            onChange={(value) => updateHedgeProperty('quantityTypes', value)}
                                        />

                                        <HedgeCommonPropertySelect
                                            name="masterAgreements"
                                            label={<FormattedMessage id="hedge_master_agreement" />}
                                            isDisabled={isDisabled}
                                            options={masterAgreementsOptions}
                                            onMultipleToggleChange={() => toggleMultiple('masterAgreements')}
                                            onChange={(value) => updateHedgeProperty('masterAgreements', value)}
                                            placeholder="-"
                                        />

                                        <HedgeCommonPropertySelect
                                            name="plantTypes"
                                            label={<FormattedMessage id="hedge_plant_type" />}
                                            isDisabled={isDisabled}
                                            options={plantTypeOptions}
                                            onMultipleToggleChange={() => toggleMultiple('plantTypes')}
                                            onChange={(value) => updateHedgeProperty('plantTypes', value)}
                                            placeholder="-"
                                        />

                                        <HedgeCommonPropertyDate
                                            name="dates"
                                            label={<FormattedMessage id="hedge_transaction_date" />}
                                            isDisabled={isDisabled}
                                            onMultipleToggleChange={() => toggleMultiple('dates')}
                                            onChange={(value) => updateHedgeProperty('dates', value)}
                                        />
                                    </SimpleGrid>
                                </Form>
                            </Formik>
                        )}

                        <Box m={6}>
                            {globalErrors.length > 0 && (
                                <GeneralFormValidationsAlert
                                    isOpen={globalErrorsDisclosure.isOpen}
                                    onClose={globalErrorsDisclosure.onClose}
                                    messages={globalErrors}
                                />
                            )}

                            {globalWarnings.length > 0 && (
                                <GeneralFormValidationsAlert
                                    status="warning"
                                    title={<FormattedMessage id="common_warning" />}
                                    isOpen={globalWarningsDisclosure.isOpen}
                                    onClose={globalWarningsDisclosure.onClose}
                                    messages={globalWarnings}
                                />
                            )}
                        </Box>

                        {!isLoadingSubForm && (
                            <Box mx={6} my={9}>
                                <DataGridWrapper>
                                    <DataGrid
                                        name={GRID_KEY}
                                        ref={gridApi}
                                        serverSideDatasource={serverSideDatasource}
                                        serverSideDataSourceView={serverSideDataSourceView.getRows}
                                        onDataChange={updateServerSideData}
                                        onDelete={onDeleteRow}
                                        rowModelType="serverSide"
                                        serverSideStoreType="partial"
                                        paginationPageSize={500}
                                        cacheBlockSize={500}
                                        onCellKeyPress={onCellKeyPress}
                                        columns={mainGridColumns}
                                        multisortPairs={multisortPairs}
                                        gridResource={mainGridResource}
                                        onAddRowsSuccess={onAddRowsSuccess}
                                        addDataColumns={addCols}
                                        issues={issues}
                                        globalIssues={globalIssues}
                                        primaryKeyName={PRIMARY_KEY_NAME}
                                    />
                                </DataGridWrapper>
                            </Box>
                        )}

                        {itemPropertiesModal.isOpen && (
                            <ItemPropertiesModal
                                isOpen
                                onClose={itemPropertiesModal.onClose}
                                itemId={hedge.id}
                                portfolio={hedgeItem}
                                onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                            />
                        )}

                        {deleteHedgeItemModal.isOpen && (
                            <ConfirmationModal
                                isOpen
                                onClose={deleteHedgeItemModal.onClose}
                                header={<FormattedMessage id="common_delete_modal_heading" values={{ item: 'Item' }} />}
                                hasExtraStep
                                content={
                                    <FormattedMessage
                                        id="common_delete_modal_msg"
                                        values={{ item: hedge.description }}
                                    />
                                }
                                confirmText={
                                    <Box as="span" textTransform="capitalize">
                                        <FormattedMessage id="common_delete" />
                                    </Box>
                                }
                                onConfirm={() => {
                                    onItemDelete(hedge);
                                    deleteHedgeItemModal.onClose();
                                }}
                            />
                        )}

                        {editAdjustmentsModal.isOpen && (
                            <EditAdjustmentsModal
                                instrumentId={selectedInstrumentId}
                                onClose={closeEditAdjustmentModal}
                            />
                        )}

                        {downloadModal.isOpen && (
                            <DownloadModal
                                isOpen
                                onClose={downloadModal.onClose}
                                onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                                onDownloadTemplate={() => onDownloadData(true)}
                            />
                        )}

                        {addDataModal.isOpen && (
                            <AddDataModal
                                isOpen
                                onClose={addDataModal.onClose}
                                columns={addCols}
                                gridResource={mainGridResource}
                                onAddRowsSuccess={onAddRowsSuccess}
                            />
                        )}
                    </SortOrderProvider>
                )}
            </ExpandableItemWrapper>
        </>
    );
};

export default Hedge;
