import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Divider, TabPanels, TabPanel, Tabs, TabList, Tab, useDisclosure } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import useHistoryPush from '../../hooks/useHistoryPush';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import Error from '../utils/Error';
import { setSelectedItemForTable } from '../../store/autoform/autoformSlice';
import useAutoformMetadata from '../autoform/hooks/useAutoformMetadata';
import AutoformItemFields from '../autoform/AutoformItemFields';
import { getInputFormName } from '../autoform/utils/autoformUtils';
import TabListWrapper from '../utils/TabListWrapper';
import { updateGroupPermissions, updateGroupUsers } from '../../services/userManagement';
import useCommonToast from '../../hooks/useCommonToast';
import useGroupUsers from '../../hooks/userManagement/useGroupUsers';
import ItemTopHeaderContainer from '../autoform/ItemTopHeaderContainer';
import GeneralValidationPanelContainer from '../autoform/GeneralValidationPanelContainer';
import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import useAutoformSubmit from '../autoform/hooks/useAutoformSubmit';
import useAutoformFormInitialValues from '../autoform/hooks/useAutoformFormInitialValues';
import { ReactComponent as GroupUsersIcon } from '../../icons/group-users.svg';
import { isEqual } from 'lodash';
import CheckboxesGrid from './CheckboxesGrid';
import AutoformUiContext from '../autoform/utils/AutoformUiContext';
import useAutoformReloadItemData from '../autoform/hooks/useAutoformReloadItemData';
import useGroupPermissions from '../../hooks/userManagement/useGroupPermissions';
import DetailsButton from './DetailsButton';
import GroupPermissionDetailsModal from './GroupPermissionDetailsModal';
import useGroupsInitialValues from '../../hooks/userManagement/useGroupsInitialValues';
import { onHasUnsavedChanges } from 'store/helpers/helpersSlice';

import PromptWrapper from 'components/utils/PromptWrapper';
import AutomaticFormikDirtyCheck from 'components/forms/AutomaticFormikDirtyCheck';

const Groups = () => {
    const dispatch = useDispatch();
    const groupPermissionsDetailsModal = useDisclosure();
    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const [freezeInitialValues, setFreezeInitialValues] = useState(false);
    const [currentModalPermissionId, setCurrentModalPermissionId] = useState(null);
    const [isFormDirty, setIsFormDirty] = useState(false);

    let {
        params: { itemId: userGroupIdParam },
    } = useRouteMatch();
    const level1TableId = useMemo(() => hierarchicalTables.level1[0]?.id, [hierarchicalTables]);
    const selectedItemId = useMemo(() => parseInt(userGroupIdParam), [userGroupIdParam]);
    const { isLoading: isReloadItemLoading, reloadItemLoadingError } = useAutoformReloadItemData(
        level1TableId,
        selectedItemId
    );
    const { groupUsers, isLoading: isGroupUsersLoading, isError: isGroupUsersError } = useGroupUsers(selectedItemId);

    const {
        groupPermissions,
        isLoading: isGroupPermissionsLoading,
        isError: isGroupPermissionsError,
    } = useGroupPermissions(selectedItemId);

    const { handleSubmit } = useAutoformSubmit(setFreezeInitialValues);
    const autoformInitialValues = useAutoformFormInitialValues({
        includeLevel2Values: false,
        selectedLevel1Item: selectedItemId,
        freezeInitialValues,
    });
    const { initialValues, setInitialValues } = useGroupsInitialValues({
        autoformInitialValues,
        groupUsers,
        groupPermissions,
    });
    const { setEditingState } = useContext(AutoformUiContext);

    useEffect(() => {
        if (selectedItemId && level1TableId) {
            dispatch(setSelectedItemForTable({ itemId: selectedItemId, tableId: level1TableId }));
            setEditingState({});
        }
    }, [dispatch, metadata, selectedItemId, level1TableId, setEditingState]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const onNodeItemDelete = useCallback(() => {
        dispatch(setSelectedItemForTable({ itemId: null, tableId: level1TableId }));
        dispatch(onHasUnsavedChanges(false));

        historyPush({
            pathname: '/user-management/groups',
            search: new URLSearchParams(window.location.search),
        });
    }, [historyPush, dispatch, level1TableId]);

    const onFormSubmit = useCallback(
        async (values, formBag) => {
            const { groupUsers: formGroupUsers, groupPermissions: formGroupPermissions, ...autoformValues } = values;

            return handleSubmit({
                values: autoformValues,
                formBag,
                initialFormValues: initialValues,
                successMessageId: 'user_management_group_saved',
                onBeforeSuccess: async () => {
                    const errorMessage = intl.formatMessage({ id: 'common_generic_saving_error' });
                    const isGroupUsersChanged = !isEqual(formGroupUsers, initialValues.groupUsers);
                    const isGroupPermissionsChanged = !isEqual(formGroupPermissions, initialValues.groupPermissions);
                    const promises = [];

                    try {
                        if (isGroupUsersChanged) {
                            const selectedGroupUsers = Object.entries(formGroupUsers)
                                .filter(([key, value]) => !!value)
                                .map(([key]) => key);
                            promises.push(updateGroupUsers(selectedItemId, selectedGroupUsers));
                        }

                        if (isGroupPermissionsChanged) {
                            const selectedGroupPermissions = Object.entries(formGroupPermissions)
                                .filter(([key, value]) => !!value)
                                .map(([key]) => Number(key));
                            promises.push(updateGroupPermissions(selectedItemId, selectedGroupPermissions));
                        }

                        promises.length && (await Promise.all(promises));

                        setInitialValues(values);
                    } catch (error) {
                        if (error.response?.status !== 401) {
                            toast({
                                status: 'error',
                                message: errorMessage,
                            });
                        }

                        return Promise.reject(error);
                    }
                },
            });
        },
        [initialValues, intl, handleSubmit, selectedItemId, toast, setInitialValues]
    );

    const onDetailsModalOpen = useCallback(
        (permissionId) => {
            setCurrentModalPermissionId(permissionId);
            groupPermissionsDetailsModal.onOpen();
        },
        [groupPermissionsDetailsModal]
    );

    const onDetailsModalClose = useCallback(() => {
        groupPermissionsDetailsModal.onClose();
        setCurrentModalPermissionId(null);
    }, [groupPermissionsDetailsModal]);

    return isGroupUsersError || isGroupPermissionsError || reloadItemLoadingError ? (
        <Box m={6}>
            <Error primaryId="common_error" secondaryId="common_loading_error" />
        </Box>
    ) : isGroupUsersLoading || isGroupPermissionsLoading || !metadata || isReloadItemLoading ? (
        <DefinitionsSkeleton />
    ) : (
        <>
            <ExpandableItemWrapper>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={onFormSubmit}>
                    <Form>
                        <PromptWrapper />
                        <AutomaticFormikDirtyCheck isFormDirty={isFormDirty} onFormDirtyChange={setIsFormDirty} />
                        <ItemTopHeaderContainer
                            tableId={level1TableId}
                            onNodeItemDelete={onNodeItemDelete}
                            config={{
                                deleteModalHeaderItem: intl.formatMessage({ id: 'user_management_group' }),
                                deleteSuccessMessage: intl.formatMessage({
                                    id: 'user_management_group_delete_success',
                                }),
                                itemDeleteButtonMessageId: 'user_management_delete_group',
                                autoformIcon: <GroupUsersIcon />,
                            }}
                        />

                        <Box px={6}>
                            <GeneralValidationPanelContainer tableId={level1TableId} />
                            <AutoformItemFields tableId={level1TableId} getInputFormName={getInputFormName} />
                        </Box>

                        <Divider />

                        <Tabs px={0} isLazy isManual lazyBehavior="keepMounted">
                            <TabList px={6} w="100%" flexWrap={{ base: 'wrap', xl: 'nowrap' }} alignItems="center">
                                <TabListWrapper showButtons={true}>
                                    <Tab>
                                        <FormattedMessage id="user_management_manage_users" />
                                    </Tab>
                                    <Tab>
                                        <FormattedMessage id="user_management_manage_permissions" />
                                    </Tab>
                                </TabListWrapper>
                            </TabList>
                            <TabPanels>
                                <TabPanel pt={4}>
                                    <CheckboxesGrid
                                        data={groupUsers}
                                        fieldNamePrefix="groupUsers"
                                        breakWord={true}
                                        header={<FormattedMessage id="user_management_manage_users_title" />}
                                    />
                                </TabPanel>
                                <TabPanel pt={4}>
                                    <CheckboxesGrid
                                        data={groupPermissions}
                                        fieldNamePrefix="groupPermissions"
                                        header={<FormattedMessage id="user_management_manage_permissions_title" />}
                                        breakWord={true}
                                        renderDetailsButton={(permissionId) => (
                                            <DetailsButton
                                                onDetailsButtonClick={() => onDetailsModalOpen(permissionId)}
                                            />
                                        )}
                                    />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Form>
                </Formik>
            </ExpandableItemWrapper>

            {groupPermissionsDetailsModal.isOpen && (
                <GroupPermissionDetailsModal
                    permissionId={currentModalPermissionId}
                    onOpen={onDetailsModalOpen}
                    onClose={onDetailsModalClose}
                />
            )}
        </>
    );
};

export default Groups;
