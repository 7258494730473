import styled from '@emotion/styled/macro';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from 'constants/local-storage';
import { PlotBandControls } from './PlotBandControls';
import { useIntl } from 'react-intl';
import { Flex, Skeleton, Spacer, VStack, useColorMode, useMediaQuery } from '@chakra-ui/react';
import { buildChartOptions, chartHasSeriesData } from 'services/charts';
import EmptyGraph from 'components/itemData/EmptyGraph';

export const Highchart = memo(function Highchart({ fetchData, initialData, onAdd, canCreate }) {
    const intl = useIntl();
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const { colorMode } = useColorMode();
    const chartInstance = useRef(null);
    const [hasSeriesData, setHasSeriesData] = useState(false);
    const [chartOptions, setChartOptions] = useState({});
    const [plotBands, setPlotBands] = useState([]);
    const [config, setConfig] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [sideBarWidth] = useLocalStorage(LOCAL_STORAGE_KEYS.SIDEBAR_WIDTH);

    const refetchOptions = useCallback(
        async (range) => {
            chartInstance?.current?.chart?.showLoading();
            const data = await fetchData(range);
            setChartOptions(data?.options);
            setPlotBands(data?.options?.xAxis?.plotBands || []);
            chartInstance?.current?.chart?.hideLoading();
        },
        [fetchData, chartInstance]
    );

    useEffect(() => {
        // watch for sidebar chages and reflow chart
        if (chartInstance?.current) {
            chartInstance.current?.chart?.reflow();
        }
    }, [chartInstance, sideBarWidth]);

    useEffect(() => {
        const setupChart = async () => {
            try {
                setIsLoading(true);
                const data = initialData ? initialData : await fetchData();
                const opts = buildChartOptions(data?.options, data?.config, refetchOptions, intl, colorMode);
                setChartOptions(opts);
                setPlotBands(data?.options?.xAxis?.plotBands || []);
                setConfig(data?.config);
                setHasSeriesData(chartHasSeriesData(data?.options));
            } catch (error) {
                setHasSeriesData(false);
            } finally {
                setIsLoading(false);
            }
        };

        setupChart();
    }, [chartInstance, initialData, fetchData, colorMode, intl, refetchOptions]);

    if (isLoading) {
        return (
            <VStack spacing={4} align="stretch">
                <Skeleton h="56px" />
            </VStack>
        );
    }

    if (!hasSeriesData) {
        return <EmptyGraph messageId="common_no_data_found" onAdd={onAdd} canCreate={canCreate} />;
    }

    return (
        <>
            <Flex>
                {isDesktop && <Spacer />}
                {config?.showPlotBandControls && (
                    <PlotBandControls plotBands={plotBands} setChartOptions={setChartOptions} />
                )}
            </Flex>
            <StyledChart>
                <HighchartsReact
                    ref={chartInstance}
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={chartOptions}
                    containerProps={{ style: { height: '100%' } }}
                />
            </StyledChart>
        </>
    );
});

const StyledChart = styled.div`
    height: auto;
    padding: 10px;

    @media (min-width: 1280px) {
        height: 600px;
    }
`;
