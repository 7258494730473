import {
    Accordion,
    AccordionItem,
    AccordionPanel,
    Box,
    Divider,
    Flex,
    Heading,
    MenuItem,
    SimpleGrid,
    Text,
    useMediaQuery,
    IconButton,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { checkItemInputEvent } from '../../services/items';
import Tooltip from '../utils/Tooltip';
import AddSubItem from '../utils/AddSubItem';
import AccordionButton from '../utils/AccordionButton';
import InputFormikField from '../forms/InputFormikField';
import SubItemActionsMenu from '../itemData/SubItemActionsMenu';
import { ReactComponent as DeleteCircledIcon } from '../../icons/delete-circled.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as AddItemIcon } from '../../icons/item-icons/add-item.svg';
import { ReactComponent as CopyCircledIcon } from '../../icons/copy-circled.svg';

const SubThermalSection = ({
    definitions,
    onAdd,
    accordionIndex,
    setAccordionIndex,
    editDescriptionsConfig,
    formValues,
    validateDescription,
    onEditDescription,
    onCopyUnit,
    onDelete,
    renderPanelContent,
    helpPageUrlItemTypeId = null,
}) => {
    const intl = useIntl();
    const [isDesktop] = useMediaQuery('(min-width: 769px)');

    return (
        <Box>
            {definitions.length === 0 ? (
                <AddSubItem onOpen={onAdd} />
            ) : (
                <Accordion allowToggle defaultIndex={[0]} index={accordionIndex} onChange={setAccordionIndex}>
                    {definitions.map((def, idx) => {
                        const foundEditDescriptionsConfig = editDescriptionsConfig[`${def.subThermalId}`];
                        const isEditing = foundEditDescriptionsConfig ? foundEditDescriptionsConfig.isActive : false;

                        return (
                            <AccordionItem key={def.subThermalId}>
                                {({ isExpanded }) => (
                                    <>
                                        <SimpleGrid
                                            columns={2}
                                            mt="-1px"
                                            bg="background-octenary"
                                            borderTop="1px solid"
                                            borderBottom="1px solid"
                                            borderColor="border-secondary"
                                        >
                                            <Flex align="center" ml={4} my={3}>
                                                <AccordionButton />

                                                <Box>
                                                    <Text size="xs" mb="2px" textTransform="capitalize">
                                                        <FormattedMessage id="common_unit" />
                                                    </Text>

                                                    {!isEditing ? (
                                                        <Heading as="h4" variant="h4">
                                                            {formValues.subThermals[idx]?.description}
                                                        </Heading>
                                                    ) : (
                                                        <InputFormikField
                                                            id={'description' + idx}
                                                            name={`subThermals[${idx}].description`}
                                                            type="text"
                                                            validate={(value) =>
                                                                validateDescription(value, def.subThermalId, true)
                                                            }
                                                            onKeyDown={checkItemInputEvent}
                                                            isFastField
                                                        />
                                                    )}
                                                </Box>

                                                <Flex align="center" ml={3}>
                                                    <Tooltip
                                                        label={intl.formatMessage({
                                                            id: 'common_edit_btn_tooltip_and_label',
                                                        })}
                                                    >
                                                        <IconButton
                                                            minH="40px"
                                                            aria-label={intl.formatMessage({
                                                                id: 'common_edit_btn_tooltip_and_label',
                                                            })}
                                                            variant="circular-icon"
                                                            leftIcon={<EditIcon />}
                                                            onClick={() => onEditDescription(def.subThermalId)}
                                                        />
                                                    </Tooltip>
                                                </Flex>
                                            </Flex>

                                            <Flex direction="row" alignItems="center" justify="flex-end" pr={6}>
                                                {!isDesktop ? (
                                                    <SubItemActionsMenu
                                                        menuListItems={
                                                            <>
                                                                <MenuItem
                                                                    key="copy-btn"
                                                                    textTransform="capitalize"
                                                                    onClick={() => onCopyUnit(def.subThermalId)}
                                                                >
                                                                    <FormattedMessage id="common_copy_btn_tooltip_and_label" />
                                                                </MenuItem>

                                                                <MenuItem
                                                                    key="delete-btn"
                                                                    textTransform="capitalize"
                                                                    onClick={() =>
                                                                        onDelete(def.subThermalId, def.description)
                                                                    }
                                                                >
                                                                    <FormattedMessage id="common_delete" />
                                                                </MenuItem>

                                                                <MenuItem
                                                                    key="create-btn"
                                                                    textTransform="capitalize"
                                                                    onClick={onAdd}
                                                                >
                                                                    <FormattedMessage id="common_add" />
                                                                </MenuItem>
                                                            </>
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        <Box mr={3}>
                                                            <Tooltip
                                                                label={intl.formatMessage({
                                                                    id: 'common_copy_btn_tooltip_and_label',
                                                                })}
                                                            >
                                                                <IconButton
                                                                    aria-label={intl.formatMessage({
                                                                        id: 'common_copy_btn_tooltip_and_label',
                                                                    })}
                                                                    variant="circular-icon"
                                                                    icon={<CopyCircledIcon />}
                                                                    onClick={() => onCopyUnit(def.subThermalId)}
                                                                />
                                                            </Tooltip>
                                                        </Box>

                                                        <Tooltip
                                                            label={intl.formatMessage({
                                                                id: 'common_delete_btn_tooltip_and_label',
                                                            })}
                                                        >
                                                            <IconButton
                                                                aria-label={intl.formatMessage({
                                                                    id: 'common_delete_btn_tooltip_and_label',
                                                                })}
                                                                variant="circular-icon"
                                                                icon={<DeleteCircledIcon />}
                                                                onClick={() =>
                                                                    onDelete(def.subThermalId, def.description)
                                                                }
                                                            />
                                                        </Tooltip>

                                                        <Divider orientation="vertical" mx={6} />

                                                        <Tooltip
                                                            label={intl.formatMessage({
                                                                id: 'common_add_new',
                                                            })}
                                                        >
                                                            <IconButton
                                                                aria-label={intl.formatMessage({
                                                                    id: 'common_add_new',
                                                                })}
                                                                variant="circular-icon"
                                                                icon={<AddItemIcon />}
                                                                onClick={onAdd}
                                                            />
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </Flex>
                                        </SimpleGrid>

                                        <AccordionPanel px={6} pt={6} pb={4}>
                                            {renderPanelContent({
                                                idx,
                                                subThermalId: def.subThermalId,
                                                isExpanded,
                                                helpPageUrlItemTypeId,
                                            })}
                                        </AccordionPanel>
                                    </>
                                )}
                            </AccordionItem>
                        );
                    })}
                </Accordion>
            )}
        </Box>
    );
};

export default SubThermalSection;
