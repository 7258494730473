const Badge = {
    baseStyle: {
        fontWeight: '600',
        borderRadius: '6px',
        fontSize: '12px',
        lineHeight: '20px',
    },

    sizes: {
        sm: {
            fontSize: '12px',
            p: '3px 10px',
        },
    },
    variants: {
        solid: {},
        subtle: {},
        outline: {},
        blue: {
            bg: 'blue.100',
            color: 'blue.500',
            border: '1px',
            borderColor: 'blue.200',
        },
        gray: {
            bg: 'gray.200',
            color: 'gray.900',
            border: '1px',
            borderColor: 'gray.500',
        },
        red: {
            bg: 'red.200',
            color: 'red.900',
            border: '1px',
            borderColor: 'red.500',
        },
        green: {
            bg: 'green.200',
            color: 'green.1000',
            border: '1px',
            borderColor: 'green.500',
        },
    },

    defaultProps: {
        colorScheme: 'gray',
        size: 'sm',
    },
};

export default Badge;
