import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { refetchSelectedItemBackendData } from '../../../store/autoform/autoformApi';
import useAutoformTableMetadata from './useAutoformTableMetadata';

/**
 * Reload the data in the store for a specific record.
 *
 * @param {string} tableId - Id of the table to which the record belongs.
 * @param {string} itemId - Id of the record to be reloadded.
 * @returns {boolean} isLoading - Is the data still being loaded.
 * @returns {boolean} loadingError - Is there an error loading the data.
 * @returns {callback} reLoadOnSelect - Reload the data manually.
 */
const useAutoformReloadItemData = (tableId, itemId) => {
    const { primaryKeyColumnName } = useAutoformTableMetadata(tableId);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const dispatch = useDispatch();

    const reLoadOnSelect = useCallback(() => {
        return dispatch(
            refetchSelectedItemBackendData({
                tableId,
                itemId,
                primaryKeyColumnName,
            })
        );
    }, [dispatch, primaryKeyColumnName, tableId, itemId]);

    useEffect(() => {
        if (tableId && itemId) {
            setIsLoading(true);
            setLoadingError(false);
            const metadataPromise = reLoadOnSelect();
            metadataPromise
                .unwrap()
                .then((action) => {
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);

                    if (err?.name !== 'AbortError') {
                        setLoadingError(true);
                    }
                });
            return () => {
                metadataPromise?.abort();
            };
        }
    }, [reLoadOnSelect, tableId, itemId]);

    return { isLoading, loadingError, reLoadOnSelect };
};

export default useAutoformReloadItemData;
