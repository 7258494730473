const version = 'v5';
const ns = `powersimm-${version}`;

const getStore = () => {
    const store = localStorage.getItem(ns);

    return store ? JSON.parse(store) : {};
};

export const getItem = (key) => {
    const store = getStore();

    return key in store ? store[key] : null;
};

export const setItem = (key, value) => {
    const store = getStore();

    store[key] = value;
    console.log(' DEBUG CREATE store[key] = value; localStorage.setItem');
    console.log({ ns, key, value, STORE: JSON.stringify(store) });
    localStorage.setItem(ns, JSON.stringify(store));
};

export const removeItem = (key) => {
    const store = getStore();

    delete store[key];
    console.log(' DEBUG DELETE store[key]; localStorage.setItem');
    console.log({ ns, key, STORE: JSON.stringify(store) });
    localStorage.setItem(ns, JSON.stringify(store));
};

export const resetStorage = () => localStorage.setItem(ns, JSON.stringify({}));

// On module init; make sure that the store always exists in localStorage
if (!localStorage.getItem(ns)) {
    resetStorage();
}
