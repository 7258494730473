import { forwardRef, useRef, useMemo, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import { useDisclosure } from '@chakra-ui/react';
import { keyBy } from 'lodash';

import { colors } from '../../theme/colors';

import { dateValueFormatter, formatMultisort } from '../../services/items';
import { columnTypes, getIssuesMap, isColEditable } from '../../services/grid';

import SortAscIcon from '../../icons/sort-asc.svg';
import SortDescIcon from '../../icons/sort-desc.svg';
import SortNoneIcon from '../../icons/sort-none.svg';
import FirstPageIcon from '../../icons/first-page.svg';
import LeftArrowIcon from '../../icons/prev-page.svg';
import LastPageIcon from '../../icons/last-page.svg';
import RightArrowIcon from '../../icons/next-page.svg';
import GridMenuIcon from '../../icons/grid-menu.svg';

import DataGridButton from '../hedges/DataGridButton';
import StudyTrackerStatusBadge from '../studies/StudyTrackerStatusBadge';
import StudyTrackerName from '../studies/StudyTrackerName';
import CellIconButton from '../utils/CellIconButton';
import MoreActionsCell from '../utils/MoreActionsCell';
import ViewJobLogsCell from './ViewJobLogsCell';
import StatementMessageTypeCell from './StatementMessageTypeCell';
import DownloadButtonCell from './DownloadButtonCell';
import CheckboxColumnHeader from '../utils/CheckboxColumnHeader';

import { SortOrderContext } from './utils/SortOrderContext';

import AddDataModal from './AddDataModal';
import DataSourceViewModal from './DataSourceViewModal';
import DataGridCalendar from './DataGridCalendar';
import DataGridCombobox from './DataGridCombobox';
import DataGridCheckbox from './DataGridCheckbox';
import DataGridCheckboxEditor from './DataGridCheckboxEditor';
import DataGridProgressBar from './DataGridProgressBar';
import DataGridNoRowsOverlay from './DataGridNoRowsOverlay';
import DataGridIssuesDialog from './DataGridIssuesDialog';
import DataGridInternalIssuesDialog from './DataGridInternalIssuesDialog';
import DataGridTooltip from './DataGridTooltip';

const DataGrid = forwardRef(
    (
        {
            name,
            columns,
            className,
            isInitialRequestSent,
            disableReadOnlyStyles = false,
            onDelete,
            onDataChange,
            rowHeight = 44,
            pagination = true,
            phrasing = {},
            gridResource,
            onAddRowsSuccess,
            addDataColumns = [],
            onGridReady,
            multisortPairs = {},
            issues,
            globalIssues,
            primaryKeyName,
            serverSideDataSourceView,
            ...rest
        },
        ref
    ) => {
        const intl = useIntl();
        const { setSortOrder } = useContext(SortOrderContext);

        // useState is not suitable as we need something that is updated synchronous
        const isPasting = useRef(false);
        const rowsChanged = useRef([]);

        const gridApi = useRef();

        const addDataModal = useDisclosure();
        const dataSourceViewModal = useDisclosure();

        const canCreate = typeof gridResource?.create === 'function';
        const canDisplayDataSourceView = gridResource?.getDataSourceView !== undefined;

        const onDeleteData = (params) => {
            if (params.event.key === 'Delete' && !params.editing && typeof onDelete !== 'undefined') {
                // suppressMultiRangeSelection is used so there is only one range
                const [range] = params.api.getCellRanges();
                const columnLength = params.columnApi.getAllDisplayedColumns().length;

                if (range.columns.length === columnLength) {
                    const start = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                    const end = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

                    const ids = [];
                    for (let i = start; i <= end; i++) {
                        const node = params.api.getDisplayedRowAtIndex(i);

                        if (node) {
                            ids.push(node.id);
                        }
                    }

                    if (ids.length > 0) {
                        onDelete(ids);
                    }
                }

                return true;
            }

            return false;
        };

        const onCopyFromGrid = (params) => {
            const def = params.column.getColDef();

            if (def.type === 'date') {
                return dateValueFormatter({ value: params.value });
            }

            if (def.type === 'checkbox') {
                return params.value ? '1' : '0';
            }

            if (def.type === 'select') {
                if (params.value == null || params.value === '') {
                    return '';
                }

                const option = def.cellEditorParams.options.find(({ id }) => id === params.value);

                return option ? option.description : '';
            }

            if (def.type === 'issues') {
                return '';
            }

            return params.value;
        };

        const onPasteStart = (event) => {
            if (rest.onPasteStart) {
                rest.onPasteStart(event);
            }

            isPasting.current = true;
        };

        const onCellValueChanged = (event) => {
            if (rest.onCellValueChanged) {
                rest.onCellValueChanged(event);
            }

            if (!event.node) return;

            if (!rowsChanged.current.includes(event.node.id)) {
                rowsChanged.current.push(event.node.id);
            }

            if (!isPasting.current) {
                if (event.oldValue === event.newValue) return;
                propagateChanges();
            }
        };

        const onPasteEnd = (event) => {
            if (rest.onPasteEnd) {
                rest.onPasteEnd(event);
            }

            propagateChanges();

            isPasting.current = false;
        };

        const propagateChanges = () => {
            if (rowsChanged.current.length === 0) return;

            onDataChange(rowsChanged.current);
            rowsChanged.current = [];
        };

        const issuesMap = useMemo(() => {
            const data = getIssuesMap(issues ?? []);
            const hasErrors =
                Object.values(data).some((props) => props.errors.length > 0 || props.generalErrors.length > 0) ||
                globalIssues?.errors.length > 0;

            const hasWarnings =
                Object.values(data).some((props) => props.warnings.length > 0 || props.generalWarnings.length > 0) ||
                globalIssues?.warnings.length > 0;

            return { data, hasErrors, hasWarnings };
        }, [issues, globalIssues]);

        const serverSideCellColorValidation = useCallback(
            (params) => {
                let result;
                const key = primaryKeyName ? params.data[primaryKeyName] : params.rowIndex;

                if (issuesMap.data.hasOwnProperty(key)) {
                    if (issuesMap.data[key].errorFields.includes(params.colDef.field)) {
                        result = { borderColor: colors.red['900'] };
                    } else if (issuesMap.data[key].warningFields.includes(params.colDef.field)) {
                        result = { borderColor: colors.orange['400'] };
                    } else {
                        result = { borderColor: null };
                    }
                } else {
                    result = { borderColor: null };
                }

                return result;
            },
            [primaryKeyName, issuesMap.data]
        );

        const getReadonlyClassNames = useCallback(
            (params) => {
                let editable = isColEditable(params, params.colDef);

                return !disableReadOnlyStyles && !editable;
            },
            [disableReadOnlyStyles]
        );

        const noRowsOverlayComponentFramework = useMemo(() => {
            return DataGridNoRowsOverlay;
        }, []);

        const noRowsOverlayComponentParams = useMemo(() => {
            return {
                onAdd: addDataModal.onOpen,
                canCreate,
            };
        }, [addDataModal, canCreate]);

        //https://ag-grid.com/javascript-data-grid/context-menu/
        const getContextMenuItems = useCallback(() => {
            let items = [];
            if (canCreate) {
                items.push({
                    name: intl.formatMessage({ id: 'common_add_data' }),
                    action: addDataModal.onOpen,
                });
                items.push('separator');
            }
            items.push('resetColumns');

            if (canDisplayDataSourceView) {
                items.push('separator');
                items.push({
                    name: 'DataSource View',
                    action: dataSourceViewModal.onOpen,
                });
            }

            return items;
        }, [canCreate, canDisplayDataSourceView, addDataModal, dataSourceViewModal, intl]);

        //https://ag-grid.com/javascript-data-grid/column-menu/
        const getMainMenuItems = useCallback(() => {
            let items = [];
            if (canCreate) {
                items.push({
                    name: intl.formatMessage({ id: 'common_add_data' }),
                    action: addDataModal.onOpen,
                });
            }
            items.push('autoSizeThis', 'resetColumns');
            return items;
        }, [intl, addDataModal, canCreate]);

        const onSortChanged = useCallback(
            (params) => {
                const columnState = params.columnApi.getColumnState();

                const sortOrder = formatMultisort(
                    columnState.filter((col) => col.sort !== null),
                    multisortPairs
                );

                setSortOrder((prev) => ({ ...prev, [name]: sortOrder }));
            },
            [setSortOrder, name, multisortPairs]
        );

        const setGridRef = (node) => {
            gridApi.current = node?.api;
            if (typeof onGridReady === 'function') {
                onGridReady(node);
            }
        };

        const context = useMemo(() => {
            return {
                // use the provided row id when available to support issues column for grid updates
                // but fallback to row index when adding rows to grid
                issues: keyBy(issues, (value) => value.id ?? value.index),
                primaryKeyName,
            };
        }, [issues, primaryKeyName]);

        const columnsWithIssues = useMemo(() => {
            const columnsWithTooltip = columns?.map((column) => {
                const tooltip = column.headerTooltip
                    ? `${column.headerName?.toUpperCase()}: ${column.headerTooltip}`
                    : column.headerName?.toUpperCase();
                return { ...column, headerTooltip: tooltip };
            });

            return columnsWithTooltip.concat([
                {
                    headerName: intl.formatMessage({ id: 'common_grid_issues' }),
                    headerTooltip: intl.formatMessage({ id: 'common_grid_issues' }),
                    type: 'internal_issues',
                    editable: false,
                    sortable: false,
                    hide: !issues?.length,
                    maxWidth: 100,
                    // @workaround ag grid doesn't refresh cell content when `context` prop changes
                    // `equals: () => false` to the issues column makes sure it's rerendered
                    equals() {
                        return false;
                    },
                },
            ]);
        }, [intl, columns, issues]);

        const getRowId = useMemo(() => {
            return primaryKeyName ? (params) => params.data[primaryKeyName] : undefined;
        }, [primaryKeyName]);

        const classNames = useMemo(() => {
            const issuesClassName = issuesMap.hasErrors
                ? 'ag-error-issues'
                : issuesMap.hasWarnings
                ? 'ag-warning-issues'
                : '';

            if (className) {
                return [issuesClassName, className].join(' ').trim();
            }

            return issuesClassName;
        }, [className, issuesMap.hasErrors, issuesMap.hasWarnings]);

        const processDataFromClipboard = useCallback((params) => {
            // the data will be a 2D array.
            // to prevent the extra null row we are looking for the case when the last element has a single empty string: ['']
            const data = params?.data;
            if (data?.length > 0 && data[data.length - 1]?.length === 1) {
                if (data[data.length - 1][0] === '') {
                    data?.pop();
                }
            }
            return data;
        }, []);

        return (
            <>
                <AgGridReact
                    className={classNames}
                    ref={ref}
                    context={context}
                    localeText={{
                        loadingOoo: intl.formatMessage({ id: 'common_loading' }),
                        noRowsToShow: intl.formatMessage({ id: 'common_no_data_found' }),
                    }}
                    suppressColumnVirtualisation={true} // col virtualization messes up the cell borders
                    defaultColDef={{
                        editable: true,
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        lockPosition: true,
                        menuTabs: ['generalMenuTab'],
                        unSortIcon: true,
                        resizable: true,
                        tooltipComponent: DataGridTooltip,
                        suppressKeyboardEvent: rest.rowModelType === 'serverSide' && onDeleteData,
                        cellStyle: rest.rowModelType === 'serverSide' ? serverSideCellColorValidation : undefined,
                        // cellClass does not update, hence using cellClasRules as a replacemant
                        cellClassRules: {
                            readonly: getReadonlyClassNames,
                        },
                        // the default text editor of ag grid changes the newValue to undefined
                        // when a user enters and exits edit mode without changing anything to an empty cell
                        valueParser: (params) => {
                            return params.newValue === undefined && params.oldValue === null ? null : params.newValue;
                        },
                    }}
                    suppressMenuHide={true}
                    getMainMenuItems={getMainMenuItems}
                    getContextMenuItems={getContextMenuItems}
                    processCellForClipboard={onCopyFromGrid}
                    columnTypes={columnTypes}
                    pagination={pagination}
                    enableRangeSelection={true}
                    frameworkComponents={{
                        DataGridCalendar,
                        DataGridCheckbox,
                        DataGridCheckboxEditor,
                        DataGridProgressBar,
                        DataGridCombobox,
                        DataGridButton,
                        StudyTrackerStatusBadge,
                        StudyTrackerName,
                        DataGridNoRowsOverlay,
                        DataGridIssuesDialog,
                        DataGridInternalIssuesDialog,
                        CellIconButton,
                        MoreActionsCell,
                        ViewJobLogsCell,
                        StatementMessageTypeCell,
                        DownloadButtonCell,
                        CheckboxColumnHeader,
                    }}
                    icons={{
                        sortAscending: `<img src="${SortAscIcon}" />`,
                        sortDescending: `<img src="${SortDescIcon}" />`,
                        sortUnSort: `<img src="${SortNoneIcon}" />`,
                        first: `<img src="${FirstPageIcon}" />`,
                        previous: `<img src="${LeftArrowIcon}" />`,
                        next: `<img src="${RightArrowIcon}" />`,
                        last: `<img src="${LastPageIcon}" />`,
                        menu: `<img src="${GridMenuIcon}" />`,
                    }}
                    rowHeight={rowHeight}
                    columnDefs={columnsWithIssues}
                    rowData={rest.rowData}
                    onPasteStart={onPasteStart}
                    onCellValueChanged={onCellValueChanged}
                    onPasteEnd={onPasteEnd}
                    onSortChanged={onSortChanged}
                    noRowsOverlayComponentFramework={noRowsOverlayComponentFramework}
                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    tooltipShowDelay={0}
                    onGridReady={setGridRef}
                    getRowId={getRowId}
                    enableCellChangeFlash={true}
                    stopEditingWhenCellsLoseFocus={true}
                    suppressScrollWhenPopupsAreOpen={true}
                    processDataFromClipboard={processDataFromClipboard}
                    {...rest}
                />

                {canCreate && addDataModal.isOpen && (
                    <AddDataModal
                        isOpen
                        onClose={addDataModal.onClose}
                        columns={addDataColumns}
                        gridApi={gridApi.current}
                        gridResource={gridResource}
                        onAddRowsSuccess={onAddRowsSuccess}
                        heading={phrasing.header}
                        instructions={phrasing.content}
                    />
                )}

                {canDisplayDataSourceView && dataSourceViewModal.isOpen && (
                    <DataSourceViewModal
                        isOpen
                        onClose={dataSourceViewModal.onClose}
                        fetchDataFunction={serverSideDataSourceView}
                        gridApi={gridApi.current}
                        gridResource={gridResource}
                    />
                )}
            </>
        );
    }
);

export default DataGrid;
