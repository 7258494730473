import { useEffect, useRef, useState, useCallback, useMemo, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { HttpStatusCode } from 'axios';

import {
    Box,
    Spacer,
    InputGroup,
    InputRightElement,
    IconButton,
    useDisclosure,
    Button,
    Text,
    Tooltip,
    Flex,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import SecondaryButton from '../utils/SecondaryButton';
import {
    formatModule,
    formatSensitivity,
    editJobStatuses,
    exportStudiesJobs,
    formatFilterIds,
    updateJob,
    checkForChildJobsFromStudies,
    getParentJobs,
} from '../../services/studies';
import { downloadFile, formatDataGridDate } from '../../services/items';

import { formatDuration } from '../../services/utils';

import { onJobsAutoUpdate } from '../../store/jobs/jobsSlice';

import useCommonToast from '../../hooks/useCommonToast';

import DataGridWrapper from '../grid/DataGridWrapper';
import DataGrid from '../grid/DataGrid';
import SortOrderProvider from '../grid/utils/SortOrderContext';

import StudyInfoModal from './StudyInfoModal';
import JobsStatusModal from './JobsStatusModal';
import JobLogsModal from './JobLogsModal';
import JobParametersModal from './JobParametersModal';
import WhyJobNotRunningModal from './WhyJobNotRunningModal';

import InputField from '../forms/InputField';
import CheckboxField from '../forms/CheckboxField';
import MultiSelectField from '../forms/MultiSelectField';

import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { STOPPED_JOB_STATUS_ID, initialJobsVisibleColunms, jobsColumnSelectionOptions } from 'constants/studies';

const JobsGrid = ({
    rowData,
    filterOptions,
    filters,
    updateFilters,
    jobIdInput,
    setJobIdInput,
    getData,
    studies,
    jobsDataLoading,
}) => {
    const autoUpdateJobs = useSelector((state) => state.jobs.autoUpdateJobs);
    const dispatch = useDispatch();

    const intl = useIntl();
    const { search } = useLocation();
    const { toast } = useCommonToast();

    const gridApi = useRef(null);
    const mappedModules = useRef(null);

    const studyInfoModal = useDisclosure();
    const jobLogsModal = useDisclosure();
    const jobParametersModal = useDisclosure();
    const statusModal = useDisclosure();
    const stopJobsWarningModal = useDisclosure();
    const whyJobNotRunningModal = useDisclosure();

    const [studyInfo, setStudyInfo] = useState({});
    const [selectedJobs, setSelectedJobs] = useState([]);
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [selectedJobStatus, setSelectedJobStatus] = useState(null);
    const [jobWithPendingChildJobs, setjobWithPendingChildJobs] = useState('');
    const [parentJobs, setParentJobs] = useState([]);
    const [selectedJobNode, setSelectedJobNode] = useState(null);
    const [selectedJobsDataLoading, setSelectedJobsDataLoading] = useState(false);

    const [visibleColumns, setVisibleColumns] = useState(initialJobsVisibleColunms);

    const changeJobsStatus = async (statusId) => {
        statusModal.onClose();
        setSelectedJobStatus(statusId);

        const payload = {
            jobIds: selectedJobs.map((elem) => elem.jobId),
            statusId: parseInt(statusId),
        };

        //check if job has pending child jobs in other studies. This is checked only if the new job status is '-3'(stopped).
        if (parseInt(statusId) === STOPPED_JOB_STATUS_ID) {
            const response = await checkForChildJobsFromStudies(payload);
            const childJobs = response?.data;

            if (Array.isArray(childJobs) && childJobs.length > 0) {
                const childJobList = childJobs.join(',');
                setjobWithPendingChildJobs(childJobList);
                stopJobsWarningModal.onOpen();
            } else {
                await updateJobStatuses(statusId);
            }
        } else {
            await updateJobStatuses(statusId);
        }
    };

    const editJobStatusesAfterConfirmation = async () => {
        stopJobsWarningModal.onClose();
        await updateJobStatuses(selectedJobStatus);
    };

    const updateJobStatuses = async (statusId) => {
        const successMessage = intl.formatMessage({ id: 'jobs_tracker_change_status_message' });

        const payload = {
            jobIds: selectedJobs.map((elem) => elem.jobId),
            statusId: parseInt(statusId),
        };

        await editJobStatuses(payload);
        toast(successMessage);
        setSelectedJobs([]);
        getData({});
    };

    const refreshData = async () => {
        setSelectedJobs([]);
        setParentJobs([]);
        await getData({});
    };

    const removeParentJobsFromGrid = () => {
        if (gridApi.current && parentJobs?.length > 0) {
            gridApi.current.applyTransaction({
                remove: parentJobs,
            });
        }
        setParentJobs([]);
    };

    const getSelectedJobsParentJobs = async () => {
        try {
            setSelectedJobsDataLoading(true);

            const payload = {
                jobIds: selectedJobs.map((elem) => elem.jobId),
            };
            const result = await getParentJobs(payload);
            removeParentJobsFromGrid();

            if (Array.isArray(result)) {
                if (result.length > 0) {
                    const successMessage = intl.formatMessage(
                        { id: 'jobs_tracker_parent_jobs_found_success' },
                        { count: result.length }
                    );
                    //Add isParentJob field with value = true for UI checkbox display
                    const parents = result.map((a) => {
                        return { ...a, isParentJob: true };
                    });

                    if (gridApi.current && parents) {
                        gridApi.current.applyTransaction({
                            add: parents,
                        });
                    }

                    setParentJobs(parents);
                    toast(successMessage);
                } else {
                    const noParentsFoundMessage = intl.formatMessage({
                        id: 'jobs_tracker_parent_jobs_not_found_info',
                    });
                    toast({ status: 'info', message: noParentsFoundMessage });
                }
            }
        } catch (error) {
            if (error?.response?.status && error.response.status !== HttpStatusCode.Unauthorized) {
                // Not Status 401 Unauthorized
                let serverError;

                if (error.response.status !== HttpStatusCode.InternalServerError) {
                    serverError = error.response.data.error || error.response.data.title;
                }

                toast({
                    status: 'error',
                    message: serverError || intl.formatMessage({ id: 'common_generic_loading_error' }),
                });
            }
        } finally {
            setSelectedJobsDataLoading(false);
        }
    };

    const addJobIdFilter = (value) => {
        setJobIdInput(value);

        const jobIds = formatFilterIds(value);

        updateFilters(jobIds, 'jobIds', false);
    };

    const onJobIdFilterEnter = () => {
        const jobIds = formatFilterIds(jobIdInput);

        getData({ localJobIds: jobIds });
    };

    const updateTableData = useCallback(() => {
        setSelectedJobs([]);
        setParentJobs([]);
        if (gridApi.current) {
            gridApi.current.setRowData(rowData);
            if (rowData.length === 0) {
                gridApi.current.showNoRowsOverlay();
            }
        }
    }, [rowData]);

    useEffect(() => {
        // Wait until we finished getting data from parent Jobs component
        if (!jobsDataLoading) {
            updateTableData();
        }
    }, [jobsDataLoading, rowData, updateTableData]);

    useEffect(() => {
        if (filterOptions) {
            mappedModules.current = new Map(filterOptions.modules?.map((e) => [e.id, e.description]));
        }
    }, [filterOptions]);

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const onColumnChange = (ids) => {
        const selectedColumns = jobsColumnSelectionOptions.map((elem) => elem.id).filter((id) => ids.includes(id));

        setVisibleColumns(selectedColumns);
    };

    // TODO: Add functionality when jobs selected
    const onSelectionChanged = () => {
        const nodes = gridApi.current.getSelectedNodes();
        const jobs = nodes.map((node) => node.data);
        setSelectedJobs(jobs);
    };

    const onDataChange = async (rowNodeIds) => {
        const rowNode = rowNodeIds?.length ? gridApi?.current?.getRowNode?.(rowNodeIds[0]) : undefined;
        const payload = rowNode?.data;

        if (!payload) return;

        try {
            await updateJob(payload);
            toast(intl.formatMessage({ id: 'jobs_update_successful' }));
        } catch (error) {
            toast({
                status: 'error',
                message: intl.formatMessage({ id: 'common_generic_processing_error' }),
            });
            getData({});
        }
    };

    const exportData = async () => {
        const query = new URLSearchParams(search);
        const studyIds = query.get('ids')
            ? query
                  .get('ids')
                  .split('-')
                  .map((e) => parseInt(e))
            : [];

        const payload = {
            jobIds: filters ? filters.jobIds : [],
            moduleIds: filters ? filters.moduleIds : [],
            statusIds: filters ? filters.statusIds : [],
            sensitivityIds: filters ? filters.sensitivityIds : [],
            studyIds: studies.length > 0 ? studies.map((study) => study.studyId) : studyIds,
            clientOffset: new Date().getTimezoneOffset(),
        };

        const fileInfo = await exportStudiesJobs(payload);

        downloadFile(fileInfo);
    };

    const sortStudyName = (valA, valB, nodeA, nodeB) => {
        return nodeA.data.studyName > nodeB.data.studyName ? 1 : -1;
    };

    const sortModules = (valA, valB) => {
        if (!mappedModules.current) return -1;

        return mappedModules.current.get(valA) > mappedModules.current.get(valB) ? 1 : -1;
    };

    const onStudyInfoClick = useCallback(
        ({ data: node }) => {
            setSelectedJobId(node.jobId);
            setStudyInfo(node);
            studyInfoModal.onOpen();
        },
        [studyInfoModal]
    );

    const onViewJobLogsClicked = useCallback(
        ({ data: node }) => {
            setSelectedJobId(node.jobId);
            jobLogsModal.onOpen();
        },
        [jobLogsModal]
    );

    const onViewJobParametersClicked = useCallback(
        ({ data: node }) => {
            setSelectedJobId(node.jobId);
            jobParametersModal.onOpen();
        },
        [jobParametersModal]
    );

    const onViewWhyJobNotRunningClicked = useCallback(
        ({ data: node }) => {
            setSelectedJobId(node.jobId);
            setSelectedJobNode(node);
            whyJobNotRunningModal.onOpen();
        },
        [whyJobNotRunningModal]
    );

    const onJobsAutoUpdateChange = (event) => {
        const checkedValue = event.target.checked;
        dispatch(onJobsAutoUpdate(checkedValue));
    };

    //#region Grid Columns Definitions
    const gridColumns = useMemo(() => {
        return [
            {
                field: 'status',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_status' }),
                minWidth: 320,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                cellRenderer: 'StudyTrackerStatusBadge',
                cellRendererParams: {
                    isInJobsTable: true,
                    pb: 0,
                    pt: '2px',
                },
                hide: !visibleColumns.includes('status'),
            },
            {
                field: 'moreActions',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'jobs_table_moreActions' }),
                minWidth: 125,
                editable: false,
                sortable: false,
                cellRenderer: 'MoreActionsCell',
                cellRendererParams: {
                    onStudyInfoClick,
                    onViewJobLogsClicked,
                    onViewJobParametersClicked,
                    onViewWhyJobNotRunningClicked,
                },
            },
            {
                type: 'number',
                field: 'jobId',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_job_id' }),
                hide: !visibleColumns.includes('jobId'),
                sort: 'desc',
            },
            {
                field: 'isParentJob',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_job_is_parent_job' }),
                minWidth: 110,
                cellRenderer: 'DataGridCheckbox',
                cellRendererParams: { isDisabled: true },
                hide: parentJobs?.length > 0 ? false : true,
            },
            {
                field: 'moduleId',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_module' }),
                valueFormatter: (params) => formatModule(params, filterOptions.modules),
                hide: !visibleColumns.includes('moduleId'),
                comparator: sortModules,
            },
            {
                type: 'number',
                field: 'studyId',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_study_id' }),
                hide: !visibleColumns.includes('studyId'),
                sort: 'desc',
            },
            {
                field: 'studyName',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_study_name' }),
                minWidth: 250,
                cellRenderer: 'StudyTrackerName',
                hide: !visibleColumns.includes('studyName'),
                comparator: sortStudyName,
            },
            {
                type: 'number',
                field: 'bookName',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_book' }),
                filter: true,
                hide: !visibleColumns.includes('bookName'),
            },
            {
                field: 'sensitivityId',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_sensitivity' }),
                hide: !visibleColumns.includes('sensitivityId'),
                valueFormatter: (params) => formatSensitivity(params, filterOptions.sensitivities),
                sort: 'asc',
            },
            {
                field: 'processedBy',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_processed_by' }),
                hide: !visibleColumns.includes('processedBy'),
            },
            {
                field: 'scheduledRunTime',
                editable: false,
                valueFormatter: (params) => formatDataGridDate(params),
                headerName: intl.formatMessage({ id: 'jobs_table_scheduled_runtime' }),
                minWidth: 120,
                hide: !visibleColumns.includes('scheduledRunTime'),
                sort: 'desc',
            },
            {
                field: 'attemptNumber',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_attempt' }),
                hide: !visibleColumns.includes('attempt'),
            },
            {
                field: 'startDate',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_start_date' }),
                minWidth: 200,
                valueFormatter: (params) => formatDataGridDate(params),
                hide: !visibleColumns.includes('startDate'),
            },
            {
                field: 'endDate',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_end_date' }),
                minWidth: 200,
                valueFormatter: (params) => formatDataGridDate(params),
                hide: !visibleColumns.includes('endDate'),
            },
            {
                type: 'number',
                field: 'runTime',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_run_time' }),
                minWidth: 120,
                valueFormatter: formatDuration,
                hide: !visibleColumns.includes('runTimeHours'),
            },
            {
                field: 'jobType',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_job_type' }),
                hide: !visibleColumns.includes('jobType'),
            },
            {
                field: 'portfolioName',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_job_portfolio' }),
                hide: !visibleColumns.includes('portfolio'),
            },
            {
                field: 'submittedBy',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_job_submitted_by' }),
                minWidth: 250,
                hide: !visibleColumns.includes('submittedBy'),
            },
            {
                type: 'number',
                field: 'runTimeMinutes',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_job_run_time_min' }),
                minWidth: 120,
                valueFormatter: (params) => Math.floor(params.value),
                hide: !visibleColumns.includes('runTimeMinutes'),
            },
            {
                field: 'debug',
                editable: true,
                headerName: intl.formatMessage({ id: 'jobs_table_debug' }),
                minWidth: 100,
                cellRenderer: 'DataGridCheckbox',
                hide: !visibleColumns.includes('debug'),
            },
        ];
    }, [
        filterOptions.modules,
        filterOptions.sensitivities,
        intl,
        onStudyInfoClick,
        onViewJobLogsClicked,
        onViewJobParametersClicked,
        onViewWhyJobNotRunningClicked,
        parentJobs?.length,
        visibleColumns,
    ]);
    //#endregion

    return (
        <>
            <FiltersWrapper>
                {selectedJobs?.length > 0 && (
                    <Button
                        size="sm"
                        textTransform="capitalize"
                        onClick={statusModal.onOpen}
                        isDisabled={jobsDataLoading}
                    >
                        <FormattedMessage id="jobs_tracker_change_status" />
                    </Button>
                )}

                {selectedJobs?.length > 0 && (
                    <SecondaryButton
                        size="sm"
                        textTransform="capitalize"
                        onClick={getSelectedJobsParentJobs}
                        isDisabled={jobsDataLoading || selectedJobsDataLoading}
                    >
                        <FormattedMessage id="jobs_tracker_parent_jobs_show" />
                    </SecondaryButton>
                )}

                {parentJobs?.length > 0 && (
                    <SecondaryButton
                        size="sm"
                        textTransform="capitalize"
                        onClick={removeParentJobsFromGrid}
                        isDisabled={jobsDataLoading || selectedJobsDataLoading}
                    >
                        <FormattedMessage id="jobs_tracker_parent_jobs_hide" />
                    </SecondaryButton>
                )}

                <MultiSelectField
                    id="column"
                    name="column"
                    label={intl.formatMessage({ id: 'jobs_column_select' })}
                    placeholderLabel={intl.formatMessage({
                        id: 'run_a_study_select_placeholder',
                    })}
                    multipleSelectedLabel={intl.formatMessage({
                        id: 'run_a_study_multiple_selected_placeholder',
                    })}
                    allSelectedLabel={intl.formatMessage({
                        id: 'common_all_selected_label',
                    })}
                    options={jobsColumnSelectionOptions}
                    valueKey="id"
                    labelKey="label"
                    onChange={onColumnChange}
                    value={visibleColumns}
                />

                <MultiSelectField
                    id="modules"
                    name="modules"
                    label={intl.formatMessage({ id: 'jobs_column_select_modules' })}
                    placeholderLabel={intl.formatMessage({
                        id: 'run_a_study_select_placeholder',
                    })}
                    multipleSelectedLabel={intl.formatMessage({
                        id: 'run_a_study_multiple_selected_placeholder',
                    })}
                    allSelectedLabel={intl.formatMessage({
                        id: 'common_all_selected_label',
                    })}
                    options={filterOptions.modules}
                    valueKey="id"
                    labelKey="description"
                    onChange={(val) => updateFilters(val, 'moduleIds')}
                    value={filters ? filters.moduleIds : []}
                    menuProps={{ width: 'xs' }}
                />

                <MultiSelectField
                    id="jobStatus"
                    name="jobStatus"
                    label={intl.formatMessage({ id: 'jobs_column_select_job_status' })}
                    placeholderLabel={intl.formatMessage({
                        id: 'run_a_study_select_placeholder',
                    })}
                    multipleSelectedLabel={intl.formatMessage({
                        id: 'run_a_study_multiple_selected_placeholder',
                    })}
                    allSelectedLabel={intl.formatMessage({
                        id: 'common_all_selected_label',
                    })}
                    options={filterOptions.statuses}
                    valueKey="id"
                    labelKey="description"
                    onChange={(val) => updateFilters(val, 'statusIds')}
                    value={filters ? filters.statusIds : []}
                />

                <MultiSelectField
                    id="sensitivities"
                    name="sensitivities"
                    label={intl.formatMessage({ id: 'jobs_column_select_sensitivities' })}
                    placeholderLabel={intl.formatMessage({
                        id: 'run_a_study_select_placeholder',
                    })}
                    multipleSelectedLabel={intl.formatMessage({
                        id: 'run_a_study_multiple_selected_placeholder',
                    })}
                    allSelectedLabel={intl.formatMessage({
                        id: 'common_all_selected_label',
                    })}
                    options={filterOptions.sensitivities}
                    valueKey="id"
                    labelKey="description"
                    onChange={(val) => updateFilters(val, 'sensitivityIds')}
                    value={filters ? filters.sensitivityIds : []}
                />

                <InputGroup>
                    <StyledInputField
                        id="search"
                        name="search"
                        info={<FormattedMessage id="jobs_tracker_job_id_info" />}
                        label={intl.formatMessage({ id: 'jobs_column_select_job_ids' })}
                        value={jobIdInput}
                        type="text"
                        onChange={(e) => addJobIdFilter(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onJobIdFilterEnter();
                            }
                        }}
                        placeholder={intl.formatMessage({ id: 'common_search' })}
                    />

                    <InputRightElement mt="22px">
                        <StyledIconButton
                            onClick={getData}
                            variant="ghost"
                            aria-label={intl.formatMessage({ id: 'common_search' })}
                            icon={<SearchIcon />}
                            isDisabled={jobsDataLoading}
                        />
                    </InputRightElement>
                </InputGroup>

                <Spacer display={{ base: 'none', xl: 'block' }} />

                <Flex
                    alignItems={{ base: 'flex-start', md: 'center' }}
                    flexDirection={{ base: 'column', md: 'row' }}
                    w={{ base: '100%', md: 'auto' }}
                    pt={2}
                    mr={3}
                >
                    <Box pr={3} borderRight="1px solid" borderColor={{ base: 'transparent', md: 'border-secondary' }}>
                        <CheckboxField w="max-content" isChecked={autoUpdateJobs} onChange={onJobsAutoUpdateChange}>
                            <Text size="sm">
                                <FormattedMessage id="common_grid_auto_update_text" />
                            </Text>
                        </CheckboxField>
                    </Box>
                </Flex>

                <Tooltip
                    label={intl.formatMessage({
                        id: 'jobs_tracker_refresh_filtered_tooltip',
                    })}
                    textTransform="capitalize"
                >
                    <SecondaryButton
                        leftIcon={<RefreshIcon />}
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={refreshData}
                        isLoading={jobsDataLoading}
                        loadingText={intl.formatMessage({ id: 'common_loading' })}
                    >
                        <FormattedMessage id="jobs_tracker_refresh_filtered" />
                    </SecondaryButton>
                </Tooltip>
                <Tooltip
                    label={intl.formatMessage({
                        id: 'jobs_tracker_download_filtered_tooltip',
                    })}
                    textTransform="capitalize"
                >
                    <SecondaryButton
                        leftIcon={<DownloadIcon />}
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={exportData}
                        isDisabled={jobsDataLoading}
                    >
                        <FormattedMessage id="jobs_tracker_download_filtered" />
                    </SecondaryButton>
                </Tooltip>
            </FiltersWrapper>

            <SortOrderProvider>
                <JobsDataGridWrapper>
                    <DataGrid
                        onDataChange={onDataChange}
                        onGridReady={onGridReady}
                        pageSize={100}
                        rowHeight={34}
                        rowSelection="multiple"
                        suppressRowClickSelection={true}
                        enableRangeSelection={false}
                        onSelectionChanged={onSelectionChanged}
                        disableReadOnlyStyles={true}
                        columns={gridColumns}
                        isLoading={jobsDataLoading}
                    />
                </JobsDataGridWrapper>
            </SortOrderProvider>

            {studyInfoModal.isOpen && (
                <StudyInfoModal
                    studyId={studyInfo.studyId}
                    studyName={studyInfo.studyName}
                    onClose={studyInfoModal.onClose}
                />
            )}

            {statusModal.isOpen && (
                <JobsStatusModal
                    onClose={statusModal.onClose}
                    statuses={filterOptions.statuses}
                    onStatusChange={changeJobsStatus}
                />
            )}

            {jobLogsModal.isOpen && selectedJobId && (
                <JobLogsModal jobId={selectedJobId} onClose={jobLogsModal.onClose} />
            )}
            {jobParametersModal.isOpen && selectedJobId && (
                <JobParametersModal jobId={selectedJobId} onClose={jobParametersModal.onClose} />
            )}
            {whyJobNotRunningModal.isOpen && selectedJobId && (
                <WhyJobNotRunningModal
                    jobId={selectedJobId}
                    jobInfo={selectedJobNode}
                    onClose={whyJobNotRunningModal.onClose}
                />
            )}
            {stopJobsWarningModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={stopJobsWarningModal.onClose}
                    header={<FormattedMessage id="warning_stop_job_with_child_jobs_header" />}
                    content={
                        <FormattedMessage
                            id="warning_stop_job_with_child_jobs"
                            values={{ jobs: jobWithPendingChildJobs }}
                        />
                    }
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_continue" />
                        </Box>
                    }
                    onConfirm={editJobStatusesAfterConfirmation}
                />
            )}
        </>
    );
};

function propsAreEqual(prevProps, nextProps) {
    const isrowDataEqual = JSON.stringify(prevProps.rowData) === JSON.stringify(nextProps.rowData);
    const isfilterOptionsEqual = prevProps.filterOptions === nextProps.filterOptions;
    const isjobIdInputEqual = prevProps.jobIdInput === nextProps.jobIdInput;
    const isstudiesEqual = JSON.stringify(prevProps.studies) === JSON.stringify(nextProps.studies);
    const isfilterEqual = prevProps.filters === nextProps.filters;
    const isjobsDataLoadingEqual = prevProps.jobsDataLoading === nextProps.jobsDataLoading;

    const allAreEqual =
        isrowDataEqual &&
        isfilterOptionsEqual &&
        isjobIdInputEqual &&
        isstudiesEqual &&
        isfilterEqual &&
        isjobsDataLoadingEqual;

    return allAreEqual;
}

const FiltersWrapper = styled.div`
    display: flex;
    margin-top: 5px;
    flex-wrap: wrap;

    > * {
        width: 100%;
        margin-bottom: 5px;
    }

    > div:last-of-type {
        margin-top: 8px;
    }

    @media (min-width: 768px) {
        > * {
            margin-right: 10px;
            width: auto;
        }

        > button {
            margin-top: 20px;
        }

        > div {
            width: 200px;
        }
    }
`;

const JobsDataGridWrapper = styled(DataGridWrapper)`
    flex: 1 1 300px;
    margin-top: 10px;

    .ag-cell {
        line-height: 34px;
    }

    .ag-selection-checkbox {
        margin-top: 7px;
    }
`;

const StyledInputField = styled(InputField)`
    input {
        padding-right: 40px;
    }
`;

const StyledIconButton = styled(IconButton)`
    &:hover {
        background: none;
        svg path {
            fill: var(--chakra-colors-blue-400);
        }
    }
`;

export default memo(JobsGrid, propsAreEqual);
