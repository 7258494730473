import caiso_eimJSON from './isoConfig/CAISO_EIM.json';
import caisoJSON from './isoConfig/CAISO.json';
import ercotJSON from './isoConfig/ERCOT.json';
import isoneJSON from './isoConfig/ISONE.json';
import misoJSON from './isoConfig/MISO.json';
import nyisoJSON from './isoConfig/NYISO.json';
import pjmJSON from './isoConfig/PJM.json';
import sppJSON from './isoConfig/SPP.json';

export const isoConfig = [caisoJSON, caiso_eimJSON, ercotJSON, isoneJSON, misoJSON, nyisoJSON, pjmJSON, sppJSON];
