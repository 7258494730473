import { Field } from 'formik';
import _ from 'lodash';

import RadioGroupField from './RadioGroupField';

// If you need to extend RadioGroup functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for RadioGroup should be excluded from ...props and manually added
const RadioGroupFormikField = ({ id, name, label, children, ...props }) => {
    return (
        <Field name={name}>
            {({ field, meta, form }) => {
                const status = _.get(form.status, field.name);
                const isInvalid = meta.touched && (meta.error || status?.errors?.length > 0);
                const isWarning = meta.touched && status?.warnings?.length > 0;

                return (
                    <RadioGroupField
                        id={id}
                        name={field.name}
                        label={label}
                        value={field.value}
                        onChange={(val) => {
                            form.setStatus(_.omit(form.status, field.name));
                            form.setFieldValue(name, val);
                        }}
                        onBlur={(val) => form.setFieldTouched(val, true, false)}
                        isInvalid={isInvalid}
                        isWarning={isWarning}
                        error={meta.error}
                        status={status}
                        {...props}
                    >
                        {children}
                    </RadioGroupField>
                );
            }}
        </Field>
    );
};

export default RadioGroupFormikField;
