import { Skeleton, Stack, Box } from '@chakra-ui/react';

const DefinitionsSkeleton = () => {
    return (
        <Box mt={4} px={6}>
            <Stack>
                <Skeleton height="60px" />
                <Skeleton height="60px" />
                <Skeleton height="60px" />
            </Stack>
        </Box>
    );
};

export default DefinitionsSkeleton;
