import {
    Accordion,
    AccordionItem,
    AccordionPanel,
    Flex,
    Heading,
    SimpleGrid,
    Skeleton,
    Box,
    Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import BulkUpdateFields from './BulkUpdateFields';
import CheckboxField from '../forms/CheckboxField';
import AccordionButton from '../utils/AccordionButton';

// Thermal Resource Variables Bulk Update (Varied By Date)
const BulkUpdate = ({
    isLoading,
    fields,
    allVariedByDate,
    onFieldChange,
    onToggleVariedByDate,
    onToggleAllVariedByDate,
}) => (
    <Accordion allowToggle reduceMotion my={6} defaultIndex={[0]}>
        <AccordionItem>
            {({ isExpanded }) => (
                <>
                    <Flex
                        px={4}
                        py={1}
                        alignItems="center"
                        bg="background-octenary"
                        border="1px solid"
                        borderColor="border-secondary"
                    >
                        <AccordionButton />

                        <Heading as="h4" variant="h4" fontSize="14px" lineHeight="20px">
                            <FormattedMessage id="common_bulk_update_constant_values_heading" />
                        </Heading>
                    </Flex>

                    <AccordionPanel px={0} py={6}>
                        <SimpleGrid spacingX={6} spacingY={5} columns={3}>
                            {isLoading ? (
                                <>
                                    {Array.from(Array(fields.length).keys()).map((num) => (
                                        <Box key={num}>
                                            <Skeleton height="98px" />
                                        </Box>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <CheckboxField
                                        my={2}
                                        gridColumn="span 3"
                                        id="all_varied_by_date"
                                        name="all_varied_by_date"
                                        isChecked={allVariedByDate === 'checked'}
                                        isIndeterminate={allVariedByDate === 'indeterminate'}
                                        onChange={onToggleAllVariedByDate}
                                    >
                                        <Text as="span" fontSize="12px" color="gray.600">
                                            <FormattedMessage id="common_bulk_update_all_varied_by_date" />
                                        </Text>
                                    </CheckboxField>

                                    {fields.map((property) => (
                                        <Flex direction="column" key={property.id}>
                                            <BulkUpdateFields
                                                property={property}
                                                onChange={onFieldChange}
                                                onToggle={onToggleVariedByDate}
                                            />
                                        </Flex>
                                    ))}
                                </>
                            )}
                        </SimpleGrid>
                    </AccordionPanel>
                </>
            )}
        </AccordionItem>
    </Accordion>
);

export default BulkUpdate;
