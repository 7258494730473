// Feature Flags are coming from feature-flags API endpoint
// Example to use in the component:
// const featureFlags = useSelector((state) => state.featureFlags.features);
// const isSystemComponentActive = useIsFeatureActive(FEATURE_FLAG_NAMES.SYSTEMCOMPONENT_GRID_VISIBLE);
export const FEATURE_FLAG_NAMES = {
    BATTERY_PERCENTOUTAGE_FIELD_EDITABLE: 'BATTERY_PERCENTOUTAGE_FIELD_EDITABLE',
    SYSTEMCOMPONENT_GRID_VISIBLE: 'SYSTEMCOMPONENT_GRID_VISIBLE',
    GENERATION_DERATE_VISIBLE: 'GENERATION_DERATE_VISIBLE',
    THERMAL_FORCED_OUTAGE_TEMP_VISIBLE: 'THERMAL_FORCED_OUTAGE_TEMP_VISIBLE',
    STUDY_SETTINGS_FO_SIMS_COUNT_VISIBLE: 'STUDY_SETTINGS_FO_SIMS_COUNT_VISIBLE',
    CAM_SLICEOFDAY_FIELDS_VISIBLE: 'CAM_SLICEOFDAY_FIELDS_VISIBLE',
    RENEWABLE_MODEL_TAB_VISIBLE: 'RENEWABLE_MODEL_TAB_VISIBLE',
    LOAD_MODEL_TAB_VISIBLE: 'LOAD_MODEL_TAB_VISIBLE',
};
