import styled from '@emotion/styled/macro';
import { Button } from '@chakra-ui/react';
import letter from '../../../icons/letter-p.svg';
import { useCallback, useEffect, useRef, useState } from 'react';

const TableButton = ({ colDef, value, data, api, rowIndex }) => {
    const ref = useRef(null);
    const colId = Number(colDef.field); // don't use column.colId as it shows the ones in the current viewport
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
        setIsLoading(true);
        await colDef.cellRendererParams.onBidChange(data, colId);
        setIsLoading(false);
    };

    const focusButton = useCallback(() => {
        const focusedCell = api?.getFocusedCell();
        const field = focusedCell?.column?.colDef?.field;
        const rowIdx = focusedCell?.rowIndex;

        // check if this cell is the one focued, if so focus the button inside
        if (field === colDef.field && rowIdx === rowIndex) {
            ref?.current?.focus();
        }
    }, [rowIndex, colDef.field, api]);

    useEffect(() => {
        api?.addEventListener('cellFocused', focusButton);
        return () => {
            api?.removeEventListener('cellFocused', focusButton);
        };
    }, [api, focusButton]);

    return (
        <StyledButton
            ref={ref}
            type="button"
            isChecked={!!value}
            variant="batteries-bidding"
            _dark={{ _disabled: { border: '1px solid', borderColor: 'gray.600' } }}
            onClick={handleClick}
            isLoading={isLoading}
            // if strategy has template other than the 'custom' applied, all values have to be disabled & checked values - grayed out
            disabled={colDef.cellRendererParams.disabled}
        />
    );
};

const StyledButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'isChecked' })`
    background: ${(props) =>
        props.isChecked ? `var(--chakra-colors-green-300) url(${letter})` : 'var(--chakra-colors-border-tertiary)'};

    &:disabled {
        background: ${(props) =>
            props.isChecked
                ? `var(--chakra-colors-gray-400) url(${letter})`
                : 'var(--chakra-colors-batteries-button-unchecked-disabled)'};

        &:hover {
            background: ${(props) =>
                props.isChecked
                    ? `var(--chakra-colors-gray-400) url(${letter})`
                    : 'var(--chakra-colors-batteries-button-unchecked-disabled)'};
        }
    }
`;

export default TableButton;
