import styled from '@emotion/styled/macro';

import Tooltip from './Tooltip';

const TooltipIconList = ({ items }) => {
    return (
        <Wrapper className="icons-wrapper">
            {items
                .filter((item) => !item.disabled)
                .map(({ Icon, id, label, action }) => (
                    <Tooltip key={id} label={label}>
                        <button aria-label={label} onClick={action}>
                            <Icon aria-hidden="true" focusable="false" />
                        </button>
                    </Tooltip>
                ))}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    // this will be toggled to 'inline-grid' when item receives focus/hover,
    // targeted by the .icons-wrapper className by the user of the component
    display: none;

    grid-auto-flow: column;
    grid-column-gap: 8px;
    margin-left: auto;
    padding-right: 8px;

    button {
        display: inline-flex;
        appearance: none;
        align-items: center;
        justify-content: center;
        user-select: none;
        white-space: nowrap;
        vertical-align: middle;
        outline: 2px solid transparent;
        outline-offset: 2px;
        border-radius: 4px;
        font-weight: 500;
        font-size: var(--chakra-fontSizes-md);
        padding: 0;
        color: var(--chakra-colors-gray-600);

        line-height: 24px;
        height: 24px;
        width: 24px;
        background: none;
        min-height: 0;
        min-width: 0;

        &:focus,
        &:hover {
            color: var(--chakra-colors-blue-400);
        }
    }
`;

export default TooltipIconList;
