import { useCallback } from 'react';
import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import CheckboxFormikField from '../forms/CheckboxFormikField';

const CheckboxesGrid = ({
    data,
    fieldNamePrefix,
    header,
    valueKey = 'value',
    labelKey = 'text',
    renderDetailsButton,
    breakWord,
}) => {
    const getFieldName = useCallback(
        (dataItem) => {
            let result = '';

            if (!dataItem || !dataItem.hasOwnProperty(valueKey)) {
                return result;
            }

            if (typeof dataItem[valueKey] === 'string') {
                result = `${fieldNamePrefix}.['${dataItem[valueKey].replaceAll('\\', '\\\\').replaceAll("'", "\\'")}']`;
            } else {
                result = `${fieldNamePrefix}.${dataItem[valueKey]}`;
            }

            return result;
        },
        [fieldNamePrefix, valueKey]
    );

    return (
        <Box pl={6}>
            <Heading as="h4" variant="h4" color="grey" textTransform="capitalize" pt={2} pb={5}>
                {header}
            </Heading>

            <SimpleGrid columns={{ md: 3 }} spacingX={4} spacingY={4}>
                {data.map((dataItem) => (
                    <CheckboxFormikField key={dataItem[valueKey]} name={getFieldName(dataItem)} breakWord={breakWord}>
                        {dataItem[labelKey]}
                        {renderDetailsButton && renderDetailsButton(dataItem[valueKey])}
                    </CheckboxFormikField>
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default CheckboxesGrid;
