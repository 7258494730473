import { VisuallyHiddenInput, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

const CustomCheckbox = ({
    tabIndex,
    isChecked,
    isDisabled,
    isInvalid,
    isWarning,
    isIndeterminate,
    children,
    name,
    onChange,
    onBlur,
    breakWord,
    ...props
}) => {
    const checkedState = isIndeterminate ? true : isChecked;

    const { id } = { ...props };
    const inputIdForLabel = id ?? name;

    return (
        // Chakra wrapper is needed so we can use theme props
        <Flex {...props}>
            <Label data-disabled={isDisabled ? '' : null} breakWord={breakWord}>
                <VisuallyHiddenInput
                    type="checkbox"
                    id={inputIdForLabel}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={checkedState}
                    disabled={isDisabled}
                    tabIndex={tabIndex}
                />

                <Icon
                    aria-hidden="true"
                    data-disabled={isDisabled ? '' : null}
                    data-checked={checkedState ? '' : null}
                    data-invalid={isInvalid ? '' : null}
                    data-warning={isWarning ? '' : null}
                >
                    {isIndeterminate ? (
                        <svg viewBox="0 0 24 24">
                            <line x1="21" x2="3" y1="12" y2="12" strokeWidth="4" />
                        </svg>
                    ) : (
                        <svg viewBox="0 -0.75 12 12">
                            <polyline points="1.5 6 4.5 9 10.5 1" />
                        </svg>
                    )}
                </Icon>

                {children && <span>{children}</span>}
            </Label>
        </Flex>
    );
};

const Label = styled.label`
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border-radius: var(--chakra-radii-base);

    &[data-disabled] {
        cursor: not-allowed;
        user-select: none;
    }

    span:nth-of-type(2) {
        margin-left: var(--chakra-sizes-3);
        line-height: 1.5;
        font-size: 16px;
        ${(props) => props.breakWord && `word-break: break-word;`}
    }

    &:focus-within input:not([tabindex='-1']) + span,
    &:active input:not([tabindex='-1']) + span:not([data-disabled]) {
        box-shadow: var(--chakra-shadows-outline);
    }

    &:focus-within input:not([tabindex='-1']) + span:not([data-checked]) {
        border-color: var(--chakra-colors-blue-400);
    }
`;

const Icon = styled.span`
    flex-shrink: 0;
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: var(--chakra-sizes-4);
    height: var(--chakra-sizes-4);
    vertical-align: top;
    border: 2px solid var(--chakra-colors-gray-400);
    border-radius: var(--chakra-radii-sm);
    color: var(--chakra-colors-white);
    user-select: none;

    svg {
        fill: none;
        stroke-width: 2;
        stroke: currentColor;
        stroke-dasharray: 16;
        stroke-dashoffset: 16;
    }

    &[data-checked] {
        transition: background 0.2s ease-out;
        background: var(--chakra-colors-green-500);
        border-color: var(--chakra-colors-green-500);
        color: var(--chakra-colors-white);

        svg {
            transition: stroke-dashoffset 0.2s ease-out;
            stroke-dashoffset: 0;
        }
    }

    &[data-disabled] {
        border-color: var(--chakra-colors-gray-200);
        background: var(--chakra-colors-gray-200);
        color: var(--chakra-colors-gray-500);

        + span {
            color: var(--chakra-colors-gray-500);
        }
    }

    &[data-invalid] {
        border-color: var(--chakra-colors-text-error);
        background: var(--chakra-colors-white);
    }

    &[data-invalid]&[data-checked] {
        border-color: var(--chakra-colors-red-500);
        background: var(--chakra-colors-red-500);
    }

    &[data-warning] {
        border-color: var(--chakra-colors-orange-300);
        background: var(--chakra-colors-red-200);
    }

    &[data-warning]&[data-checked] {
        border-color: var(--chakra-colors-orange-300);
        background: var(--chakra-colors-orange-300);
    }
`;

export default CustomCheckbox;
