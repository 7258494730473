import PortfolioAutoformContainer from 'components/autoform/PortfolioAutoformContainer';
import useGlobalConstraintConfig from 'hooks/globalConstraints/useGlobalConstraintConfig';

const GlobalConstraintsPortfolioContainer = ({
    autoformId,
    itemId,
    onNodeRename,
    onNodeChangeIsMaster,
    onNodeItemDelete,
    portfolioItem,
    onNodeOwnershipPercentageUpdate,
}) => {
    const config = useGlobalConstraintConfig();

    return (
        <PortfolioAutoformContainer
            autoformId={autoformId}
            config={config}
            portfolioItem={portfolioItem}
            itemId={itemId}
            onNodeRename={onNodeRename}
            onNodeChangeIsMaster={onNodeChangeIsMaster}
            onNodeItemDelete={onNodeItemDelete}
            onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
        />
    );
};

export default GlobalConstraintsPortfolioContainer;
