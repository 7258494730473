import { useRef, useCallback, useState, useEffect, useMemo } from 'react';

import { Box, Heading, Flex, Button, useDisclosure } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';

import { formatDataGridDate } from '../services/items';
import {
    getDropdownOptions,
    getJobs,
    getActions,
    getStatuses,
    editJobStatuses,
    multisortPairs,
} from '../services/expressJobs';

import ExpressJobsActionsModal from '../components/expressJobs/ExpressJobsActionsModal';
import InfoBoxModal from '../components/expressJobs/InfoBoxModal';
import LoadingModal from '../components/modal/LoadingModal';
import ExpressAndRestorationGrid from '../components/utils/ExpressAndRestorationGrid';
import Error from '../components/utils/Error';
import MainWrapper from '../components/utils/MainWrapper';
import JobLogsModal from '../components/studies/JobLogsModal';

const AdminExpressJobs = () => {
    const intl = useIntl();
    const gridApi = useRef(null);
    const actionsModal = useDisclosure();
    const infoModal = useDisclosure();

    const selectedJobForModal = useRef(null);
    const jobLogsModal = useDisclosure();

    const [dropdownOptions, setDropdownOptions] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [statuses, setStatuses] = useState({
        data: [],
        loading: true,
        error: false,
    });

    const [actions, setActions] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const onGridReady = useCallback((params) => {
        gridApi.current = params.api;
    }, []);

    const fetchDropdownOptions = useCallback(() => {
        return getDropdownOptions(true)
            .then((data) => setDropdownOptions((prev) => ({ ...prev, error: false, data })))
            .catch(() => setDropdownOptions((prev) => ({ ...prev, error: true })))
            .finally(() => setDropdownOptions((prev) => ({ ...prev, loading: false })));
    }, []);

    const fetchActions = useCallback(() => {
        return getActions(true)
            .then((data) => setActions((prev) => ({ ...prev, error: false, data })))
            .catch(() => setActions((prev) => ({ ...prev, error: true })))
            .finally(() => setActions((prev) => ({ ...prev, loading: false })));
    }, []);

    const fetchStatuses = useCallback(() => {
        return getStatuses(true)
            .then((data) => setStatuses((prev) => ({ ...prev, error: false, data })))
            .catch(() => setStatuses((prev) => ({ ...prev, error: true })))
            .finally(() => setStatuses((prev) => ({ ...prev, loading: false })));
    }, []);

    useEffect(() => {
        fetchDropdownOptions();
        fetchActions();
        fetchStatuses();
    }, [fetchDropdownOptions, fetchActions, fetchStatuses]);

    const refreshGridData = () => {
        gridApi.current.refreshServerSideStore({
            purge: true,
        });
    };

    const onImportClick = useCallback(
        ({ data: node }) => {
            selectedJobForModal.current = node;
            actionsModal.onOpen();
            gridApi.current.stopEditing();
        },
        [actionsModal]
    );

    const onJobInfoClick = useCallback(
        ({ data: node }) => {
            selectedJobForModal.current = node;
            infoModal.onOpen();
            gridApi.current.stopEditing();
        },
        [infoModal]
    );

    const onViewJobLogsClicked = useCallback(
        ({ data: node }) => {
            selectedJobForModal.current = node;
            jobLogsModal.onOpen();
            gridApi.current.stopEditing();
        },
        [jobLogsModal]
    );

    const onActionsClose = () => {
        actionsModal.onClose();
        selectedJobForModal.current = null;
    };

    const gridResource = useMemo(() => {
        return {
            read: (payload) => getJobs(payload, true),
            update: (payload) => editJobStatuses(payload, true),
        };
    }, []);

    const gridColumns = useMemo(() => {
        return [
            {
                field: 'jobStatus',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_status' }),
                minWidth: 300,
                checkboxSelection: true,
                cellRenderer: 'StudyTrackerStatusBadge',
                headerComponent: 'CheckboxColumnHeader',
                cellRendererParams: {
                    serversideCheckbox: true,
                    isInJobsTable: true,
                },
            },
            {
                field: 'moreActions',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'admin_express_jobs_table_moreActions' }),
                minWidth: 100,
                editable: false,
                sortable: false,
                cellRenderer: 'MoreActionsCell',
                cellRendererParams: { onImportClick, onJobInfoClick, onViewJobLogsClicked },
            },
            {
                type: 'number',
                field: 'jobId',
                editable: false,
                headerName: intl.formatMessage({ id: 'admin_express_jobs_table_id' }),
            },
            {
                field: 'action',
                editable: false,
                headerName: intl.formatMessage({ id: 'admin_express_jobs_table_action' }),
            },
            {
                type: 'number',
                field: 'attemptNumber',
                editable: false,
                headerName: intl.formatMessage({ id: 'admin_express_jobs_table_attempt' }),
            },
            {
                field: 'submittedBy',
                editable: false,
                headerName: intl.formatMessage({ id: 'admin_express_jobs_table_submittedBy' }),
            },
            {
                field: 'processedBy',
                editable: false,
                headerName: intl.formatMessage({ id: 'admin_express_jobs_table_processedBy' }),
            },
            {
                field: 'scheduledRunTime',
                editable: false,
                valueFormatter: (params) => formatDataGridDate(params),
                headerName: intl.formatMessage({ id: 'admin_express_jobs_table_schedRunTime' }),
                minWidth: 120,
                initialSort: 'asc',
                initialSortIndex: 1,
            },
            {
                field: 'startDate',
                editable: false,
                headerName: intl.formatMessage({ id: 'admin_express_jobs_table_startDate' }),
                minWidth: 200,
                valueFormatter: (params) => formatDataGridDate(params),
            },
            {
                field: 'endDate',
                editable: false,
                headerName: intl.formatMessage({ id: 'admin_express_jobs_table_endDate' }),
                minWidth: 200,
                valueFormatter: (params) => formatDataGridDate(params),
            },
            {
                field: 'runTimeString',
                editable: false,
                headerName: intl.formatMessage({ id: 'admin_express_jobs_table_runType' }),
                minWidth: 120,
            },
            {
                field: 'jobType',
                editable: false,
                headerName: intl.formatMessage({ id: 'admin_express_jobs_table_jobType' }),
            },
        ];
    }, [intl, onImportClick, onJobInfoClick, onViewJobLogsClicked]);

    return (
        <MainWrapper>
            <Flex justifyContent="space-between" mt={3}>
                <Heading as="h2" variant="h2">
                    <FormattedMessage id="admin_express_jobs_title" />
                </Heading>

                <Button variant="secondary" onClick={actionsModal.onOpen}>
                    <FormattedMessage id="admin_express_jobs_actions" />
                </Button>
            </Flex>

            {dropdownOptions.loading ? (
                <LoadingModal header={<FormattedMessage id="common_loading" />} isOpen />
            ) : (
                <>
                    {dropdownOptions.error ? (
                        <Box m={6}>
                            <Error
                                primaryId="common_error"
                                secondaryId="common_loading_error"
                                additionalText={intl.formatMessage({
                                    id: 'admin_express_jobs_dropdown_options',
                                })}
                            />
                        </Box>
                    ) : (
                        <ExpressAndRestorationGrid
                            statuses={statuses.data}
                            gridApi={gridApi}
                            onGridReady={onGridReady}
                            gridResource={gridResource}
                            multisortPairs={multisortPairs}
                            columns={gridColumns}
                        />
                    )}
                </>
            )}

            {actionsModal.isOpen && (
                <ExpressJobsActionsModal
                    onClose={onActionsClose}
                    selectedJobToImport={selectedJobForModal.current}
                    actions={actions}
                    dropdownOptions={dropdownOptions}
                    admin={true}
                    onCreateJob={refreshGridData}
                />
            )}

            {infoModal.isOpen && (
                <InfoBoxModal
                    onClose={infoModal.onClose}
                    selectedJobToImport={selectedJobForModal.current}
                    actions={actions}
                    dropdownOptions={dropdownOptions}
                    admin={true}
                />
            )}

            {jobLogsModal.isOpen && (
                <JobLogsModal jobId={selectedJobForModal.current?.jobId} onClose={jobLogsModal.onClose} />
            )}
        </MainWrapper>
    );
};

export default AdminExpressJobs;
