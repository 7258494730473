import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadModules } from 'esri-loader';
import { setArcGISOAuthToken } from '../store/helpers/helpersSlice';
import { getArcGISOAuthTokenDetails } from '../services/maps';

// Custom hook to retrieve and register ArcGIS OAuth 2.0 token to view web map and feature layers.
// If the token was alredy aquired and set in store then hook will just return it as is
const useAcquireArcGISToken = () => {
    const [isAcquiring, setIsAcquiring] = useState(false);
    const [isError, setIsError] = useState(false);

    const authState = useSelector((state) => state.connections.authState);
    const arcGISOAuthToken = useSelector((state) => state.helpers.arcGISOAuthToken);
    const dispatch = useDispatch();

    useEffect(() => {
        if (authState === 'success' && !arcGISOAuthToken) {
            setIsAcquiring(true);
            setIsError(false);

            getArcGISOAuthTokenDetails()
                .then((oAuthTokenDetails) => {
                    loadModules(['esri/identity/OAuthInfo', 'esri/identity/IdentityManager']).then(
                        async ([OAuthInfo, esriId]) => {
                            try {
                                const appId = oAuthTokenDetails.oAuthClientId;
                                const oAuthInfo = new OAuthInfo({
                                    appId,
                                });
                                esriId.registerOAuthInfos([oAuthInfo]);

                                const server = oAuthTokenDetails.server;
                                const token = oAuthTokenDetails.oAuthToken;
                                const registerInfo = {
                                    server,
                                    token,
                                };

                                esriId.registerToken(registerInfo);

                                dispatch(setArcGISOAuthToken(token));
                            } catch (err) {
                                setIsError(true);
                                console.error(err);
                            } finally {
                                setIsAcquiring(false);
                            }
                        }
                    );
                })
                .catch((err2) => {
                    setIsError(true);
                    console.error(err2);
                    setIsAcquiring(false);
                });
        }
    }, [arcGISOAuthToken, authState, dispatch]);

    return { arcGISOAuthToken, isAcquiring, isError };
};

export default useAcquireArcGISToken;
