import { FormattedMessage } from 'react-intl';
import SimpleModal from '../modal/SimpleModal';
import React from 'react';
import Tile from '../utils/Tile';

import { ReactComponent as FileIcon } from '../../icons/file.svg';
import { ReactComponent as ArrowForwardIcon } from '../../icons/arrow-forward.svg';

const PowerBiModal = ({ groups, onGroupClick, ...props }) => {
    return (
        <SimpleModal title={<FormattedMessage id="select_power_bi_workspace" />} size={'xl'} {...props}>
            {groups?.map((group, index) => (
                <Tile
                    key={index}
                    leftIcon={FileIcon}
                    label={group.name}
                    rightIcon={ArrowForwardIcon}
                    onClick={() => onGroupClick(group)}
                />
            ))}
        </SimpleModal>
    );
};

export default PowerBiModal;
