import { AccordionButton as ChakraAccordionButton } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down.svg';

const AccordionButton = ({ children, ...props }) => {
    return (
        <ChakraAccordionButton {...props}>
            {typeof children === 'function' ? children(<AccordionIcon />) : <AccordionIcon />}
        </ChakraAccordionButton>
    );
};

const AccordionIcon = styled(ChevronDownIcon)`
    transition: transform 0.2s;

    [aria-expanded='true'] & {
        transform: rotate(-180deg);
    }
`;

export default AccordionButton;
