import { useDisclosure } from '@chakra-ui/react';
import AddDataModal from 'components/grid/AddDataModal';
import { FormattedMessage } from 'react-intl';

import SecondaryButton from '../utils/SecondaryButton';

import { ReactComponent as AddIcon } from '../../icons/add-item.svg';

const defaultConfig = {};

/**
 * Button to open modal to add new grid rows to an autoform AG Grid table.
 * @param {object[]} columnDefinitions - Column definitions compatible with AG Grid. Defaults
 * are provided by the hook useAutoformColumnDefinitions
 * @param {string} gridResource - Holds create and validate methods used to create and validate
 * table rows.
 * @param {callback} onAddRowsSuccess - Called on successful data creation.
 * @param {object} config - flexible config for this and nested components
 */
const AddGridRowsContainer = ({ gridResource, columnDefinitions, onAddRowsSuccess, config = defaultConfig }) => {
    const addRowsModal = useDisclosure();

    return (
        <>
            <SecondaryButton
                variant="secondary"
                size="sm"
                leftIcon={<AddIcon />}
                onClick={addRowsModal.onOpen}
                textTransform="capitalize"
                ml={{ base: 0, xl: 4 }}
            >
                <FormattedMessage id="common_add_rows_btn" />
            </SecondaryButton>
            {addRowsModal.isOpen && (
                <AddDataModal
                    isOpen
                    onClose={addRowsModal.onClose}
                    columns={columnDefinitions}
                    gridResource={gridResource}
                    onAddRowsSuccess={onAddRowsSuccess}
                    instructions={config?.addDataModalInstructions}
                />
            )}
        </>
    );
};

export default AddGridRowsContainer;
