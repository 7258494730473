import { createSlice } from '@reduxjs/toolkit';
import { getPowerBiEmbedToken, getGroups, getReports, getDataset } from './powerBiApi';
import { subMinutes } from 'date-fns';

const initialState = {
    embedToken: null,
    embedTokenExpirationDate: null,
    groups: undefined,
    reports: [],
    activeReport: null,
    activeDataset: null,
    isLoading: false,
};

const powerBiSlice = createSlice({
    name: 'powerBi',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setActiveReport: (state, action) => {
            state.activeReport = action.payload;
            state.activeDataset = null;
            state.embedToken = null;
            state.embedTokenExpirationDate = null;
        },
        reset: (state, action) => {
            state.embedToken = initialState.embedToken;
            state.embedTokenExpirationDate = initialState.embedTokenExpirationDate;
            state.reports = initialState.reports;
            state.activeReport = initialState.activeReport;
            state.activeDataset = initialState.activeDataset;
            state.isLoading = initialState.isLoading;

            if (action.payload?.isHardReset) {
                state.groups = initialState.groups;
            }
        },
    },
    extraReducers: {
        [getPowerBiEmbedToken.fulfilled]: (state, action) => {
            state.embedToken = action.payload.token;
            //to have some time buffer for long operations
            state.embedTokenExpirationDate = subMinutes(new Date(action.payload.expiration), 5).toISOString();
        },
        [getGroups.fulfilled]: (state, action) => {
            state.groups = action.payload;
        },
        [getDataset.fulfilled]: (state, action) => {
            state.activeDataset = action.payload;
        },
        [getReports.fulfilled]: (state, action) => {
            state.reports = Array.isArray(action?.payload)
                ? action.payload.sort((a, b) => a.name.localeCompare(b.name))
                : action.payload;
            state.activeReport = Array.isArray(action?.payload) && action?.payload?.length ? action?.payload[0] : null;
            state.activeDataset = null;
            state.embedToken = null;
            state.embedTokenExpirationDate = null;
        },
    },
});

export const { setLoading, setActiveReport, reset } = powerBiSlice.actions;
export default powerBiSlice.reducer;
