import { useEffect, useState } from 'react';

import { Flex, Text, Box, Button, SimpleGrid, Stack, Checkbox, IconButton } from '@chakra-ui/react';

import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import SimpleModal from 'components/modal/SimpleModal';
import ComboboxField from 'components/forms/ComboboxField';
import InputField from 'components/forms/InputField';

import { getComponentIdList, updateChart } from 'services/chartViewer';

//TODO: this lookup list will eventually be pulled from API
const seriesTypeList = [
    { id: 'SpotPriceHistory', description: 'Spot Price History' },
    // { id: 'LoadHistory', description: 'Load History' },
];

const seriesTypeDescription = (bizName) => {
    const description = seriesTypeList?.find((serType) => serType.id === bizName).description;

    if (description) {
        return description;
    } else return 'Unknown series type'; // should never happen
};

const SpecialSeriesSpec = ({ serSpec }) => {
    const [selectedComponentId, setSelectedComponentId] = useState('');

    //TODO: this lookup list will eventually be pulled from API
    const componentIdList = [
        { id: '1', description: 'Special Comp 1' },
        { id: '2', description: 'Special 2' },
    ];

    return (
        <SimpleGrid key={serSpec.seriesId} templateColumns="1fr 1fr 2fr .5fr" gap={3}>
            <Text>{serSpec.bizName}</Text>
            <ComboboxField
                id="componentId"
                name="componentId"
                options={componentIdList}
                valueKey="id"
                labelKey="description"
                value={selectedComponentId}
                onChange={(value) => setSelectedComponentId(value)}
            />
            <InputField>Legend name</InputField>
            <IconButton aria-label="Delete series" variant="outline" mr="2" icon={<DeleteIcon />} />
        </SimpleGrid>
    );
};

const SeriesSpec = ({ idx, serSpec, handleRemoveSeries }) => {
    const [spec, setSpec] = useState(serSpec);
    const [componentIdList, setComponentIdList] = useState([]);

    useEffect(() => {
        const fetchComponentIdList = async () => {
            return await getComponentIdList(spec.bizName);
        };

        fetchComponentIdList()
            .then((response) => setComponentIdList(response))
            .catch((err) => console.error(`fetchComponetIdList error: ${err}`));
    }, [spec.bizName]);

    function handleSpecChange(fieldName, value) {
        setSpec({ ...spec, [fieldName]: value });

        // the next line makes a change to the outer chartSpec object
        // that is used during the chartSpec save event
        serSpec[fieldName] = value;
    }

    return (
        <SimpleGrid templateColumns="1fr 2fr 1fr .5fr" gap={3}>
            <Text>{seriesTypeDescription(spec.bizName)}</Text>
            <ComboboxField
                id="componentId"
                name="componentId"
                options={componentIdList}
                valueKey="id"
                labelKey="description"
                value={spec.component}
                onChange={(value) => handleSpecChange('component', value)}
            />
            <InputField
                placeholder="legend label"
                value={spec.name}
                onChange={(e) => handleSpecChange('name', e.target.value)}
            />

            <IconButton
                aria-label="Delete series"
                variant="outline"
                mr="2"
                icon={<DeleteIcon />}
                onClick={(e) => handleRemoveSeries(idx, e)}
            />
        </SimpleGrid>
    );
};

const SeriesInfo = ({ chartSpec, handleRemoveSeries }) => {
    return chartSpec.seriesInfo.map((ser, index) => {
        switch (ser.bizName) {
            case 'special':
                return <SpecialSeriesSpec key={ser.seriesId} serSpec={ser} />;
            default:
                return <SeriesSpec key={index} idx={index} serSpec={ser} handleRemoveSeries={handleRemoveSeries} />;
        }
    });
};

function ChartSpecForm({ chartSpec }) {
    const [selectedSeriesType, setSelectedSeriesType] = useState(seriesTypeList[0].id);
    const [seriesCount, setSeriesCount] = useState(chartSpec?.seriesInfo?.length);
    const [spec, setSpec] = useState(chartSpec);

    function handleAddSeries(seriesTypeId) {
        let cnt = seriesCount ? seriesCount + 1 : 1;
        chartSpec.seriesInfo.push({ name: `Series ${cnt}`, bizName: seriesTypeId });
        setSeriesCount(cnt);
    }

    function handleRemoveSeries(idx) {
        let cnt = seriesCount - 1;
        chartSpec.seriesInfo.splice(idx, 1);
        setSeriesCount(cnt);
    }

    function handleSpecChange(fieldName, event) {
        let type = event.target.type;
        let value = '';

        switch (type) {
            case 'checkbox':
                value = event.target.checked;
                break;
            default:
                value = event.target.value;
        }

        setSpec({ ...spec, [fieldName]: value });
    }

    function handleSaveChart() {
        updateChart(spec)
            .then(() => alert(`Chart: ${spec.title} was saved.`))
            .catch((err) => console.error(`ChartSpec update error: ${err}`));
    }

    return (
        <Box w="100%">
            <SimpleGrid gridTemplateColumns={'1fr 3fr'} gap={4}>
                <Stack spacing={4} borderRight="1px solid #000" paddingRight={2}>
                    <InputField
                        label="Chart Title"
                        value={spec.title}
                        onChange={(e) => handleSpecChange('title', e)}
                        isRequired
                    />
                    <InputField
                        label="Relative Start Month"
                        value={spec.relStartMonth}
                        onChange={(e) => handleSpecChange('relStartMonth', e)}
                        isRequired
                        placeholder="relative to today"
                    />
                    <InputField
                        label="Relative End Month"
                        value={spec.relEndMonth}
                        onChange={(e) => handleSpecChange('relEndMonth', e)}
                        isRequired
                        placeholder="relative to today"
                    />
                    <Checkbox
                        colorScheme="green"
                        size="lg"
                        isChecked={spec.active}
                        onChange={(e) => handleSpecChange('active', e)}
                    >
                        Show chart
                    </Checkbox>
                </Stack>
                <Stack spacing={3}>
                    <SeriesInfo chartSpec={spec} handleRemoveSeries={handleRemoveSeries} />
                </Stack>
            </SimpleGrid>
            <Flex borderTop="1px" borderColor="gray.700" justifyContent="space-between" mt="3" alignItems="flex-end">
                <Flex w="fit-content" alignItems="flex-end" mt="2">
                    <ComboboxField
                        id="seriesType"
                        name="seriesType"
                        label={'Series Type'}
                        options={seriesTypeList}
                        valueKey="id"
                        labelKey="description"
                        value={selectedSeriesType}
                        onChange={(value) => setSelectedSeriesType(value)}
                    />
                    <Button ml="5" size="sm" onClick={(e) => handleAddSeries(selectedSeriesType, e)}>
                        Add Series
                    </Button>
                </Flex>
                <Button size="sm" onClick={handleSaveChart}>
                    Save
                </Button>
            </Flex>
        </Box>
    );
}

const ChartSpecModal = ({ chartSpec, isOpen, onClose }) => {
    return (
        <>
            <SimpleModal size={'6xl'} isOpen={isOpen} onClose={onClose} title={`Chart Spec - ${chartSpec.title}`}>
                <ChartSpecForm chartSpec={chartSpec} />
            </SimpleModal>
        </>
    );
};

export default ChartSpecModal;
