import { FormattedMessage } from 'react-intl';
import { Heading, Text, Flex, Box } from '@chakra-ui/react';

const WrapperEmptyState = ({ imgSrc, page, noun, item, messages = {} }) => {
    return (
        <Flex justify="center">
            <Box maxW="408px" mx="auto" my={8} px={4} textAlign="center">
                <img width="373px" height="373px" alt="" src={imgSrc} />

                <Heading size="md" mb={2} mt={12}>
                    {messages.header ?? (
                        <FormattedMessage id="common_no_items_selected_1" values={{ item: item ? item : 'items' }} />
                    )}
                </Heading>

                <Text fontSize="sm">
                    {messages.body ??
                        (item ? (
                            <FormattedMessage
                                id="common_no_items_selected_3"
                                values={{ item: item, page: page, noun: noun }}
                            />
                        ) : (
                            <FormattedMessage id="common_no_items_selected_2" values={{ page: page, noun: noun }} />
                        ))}
                </Text>
            </Box>
        </Flex>
    );
};

export default WrapperEmptyState;
