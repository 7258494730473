import { useState } from 'react';
import { Button, VStack, RadioGroup, Radio } from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';

import MainModal from '../modal/MainModal';

const StudyStatusModal = ({ statuses, studyId, onClose, onStatusChange }) => {
    const [selectedStatus, setSelectedStatus] = useState();

    const selectableStatuses = statuses?.filter((status) =>
        status?.userCanSelect !== null ? status?.userCanSelect : true
    );

    return (
        <MainModal
            size="2xl"
            scrollBehavior="inside"
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="study_change_status_header" values={{ studyId: studyId }} />}
            content={
                <>
                    <RadioGroup value={selectedStatus} onChange={setSelectedStatus}>
                        <VStack align="start">
                            {Array.isArray(selectableStatuses) &&
                                selectableStatuses.map(({ id, description }) => (
                                    <Radio key={id} value={id?.toString()} mr={5}>
                                        {description}
                                    </Radio>
                                ))}
                        </VStack>
                    </RadioGroup>
                </>
            }
            footerRightSlot={
                <Button onClick={() => onStatusChange(selectedStatus)} ml={3} isDisabled={selectedStatus === null}>
                    <FormattedMessage id="study_change_status_button" />
                </Button>
            }
        />
    );
};

export default StudyStatusModal;
