import React from 'react';
import { Center } from '@chakra-ui/react';

import { appVersion } from '../../services/utils';

const Footer = (props) => {
    return (
        <Center as="footer" pt={8} pb={4} bg="gray.50" fontSize={12} _dark={{ color: 'gray.300' }} {...props}>
            {appVersion}
        </Center>
    );
};

export default Footer;
