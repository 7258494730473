import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import Error from '../utils/Error';
import Users from './Users';
import LayoutWrapper from '../layout/LayoutWrapper';
import SidebarItemListContainer from '../autoform/SidebarItemListContainer';
import WrapperEmptyState from '../layout/WrapperEmptyState';

import useAutoformLoadMetadata from '../autoform/hooks/useAutoformLoadMetadata';
import useAutoformMetadata from '../autoform/hooks/useAutoformMetadata';

import usersPlaceholder from '../../images/users-unselected.png';
import { ReactComponent as UserIcon } from '../../icons/user.svg';
import ItemLinkWrapper from '../layout/ItemLinkWrapper';
import React, { useCallback, useEffect } from 'react';
import useHistoryPush from '../../hooks/useHistoryPush';
import { setSearchTerm } from 'store/helpers/helpersSlice';

const UsersTab = () => {
    const intl = useIntl();
    const AUTOFORM_CODE = 'PSUSERMNG';
    const { isLoading, loadingError } = useAutoformLoadMetadata(AUTOFORM_CODE);
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const level1TableId = hierarchicalTables.level1[0]?.id;

    let { path } = useRouteMatch();
    const historyPush = useHistoryPush();
    const dispatch = useDispatch();

    const getUserNameHash = useCallback((userName) => {
        let hash = '';

        for (let i = 0; i < userName?.length; i++) {
            hash += userName.charCodeAt(i).toString(36);
        }

        return hash;
    }, []);

    const renderVirtualizedItem = useCallback(
        ({ itemId, ...wrapperProps }) => {
            //on this stage it is possible to have '?'
            const [userName, searchPart] = itemId?.split('?');

            if (!userName) {
                return null;
            }

            const searchParams = new URLSearchParams(searchPart);
            searchParams.set('user', userName);

            return (
                <ItemLinkWrapper {...wrapperProps} itemId={`${getUserNameHash(userName)}?${searchParams.toString()}`} />
            );
        },
        [getUserNameHash]
    );

    //This handler not suppose to be used but in case of any CR it will work properly
    const selectItem = useCallback(
        ({ url, itemId: userName }) => {
            const searchParams = new URLSearchParams(window.location.search);

            searchParams.set('user', userName);

            historyPush({
                pathname: `${url}/${getUserNameHash(userName)}`,
                search: searchParams,
            });
        },
        [getUserNameHash, historyPush]
    );

    useEffect(
        () => () => {
            dispatch(setSearchTerm(''));
        },
        [dispatch]
    );

    if (loadingError)
        return (
            <Box m={6}>
                <Error primaryId="common_error" secondaryId="common_loading_error" />
            </Box>
        );

    if (isLoading || !metadata) return <DefinitionsSkeleton />;

    return (
        <LayoutWrapper
            hasSecondaryMenu={true}
            sidebar={
                <SidebarItemListContainer
                    schemaCode={metadata.schemaCode}
                    tableId={level1TableId}
                    config={{
                        addNewItemMessageId: 'user_management_add_new_user',
                        accordionPlaceholderId: 'user_management_select_user',
                        searchPlaceholderId: 'user_management_search_user',
                        addRecordModalItemType: intl.formatMessage({ id: 'user_management_user' }),
                        itemIcon: <UserIcon />,
                        renderVirtualizedItem,
                        selectItem,
                    }}
                />
            }
            content={
                <Switch>
                    <Route exact path={path}>
                        <WrapperEmptyState imgSrc={usersPlaceholder} page="users" item="user" noun="properties" />
                    </Route>

                    <Route path={`${path}/:hash`}>
                        <Users />
                    </Route>
                </Switch>
            }
        />
    );
};

export default UsersTab;
