import styled from '@emotion/styled/macro';
import {
    Accordion,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    Flex,
    Heading,
    SimpleGrid,
    Skeleton,
    Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down.svg';

import AutoformBulkUpdateField from './AutoformBulkUpdateField';
import CheckboxField from '../forms/CheckboxField';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    findBulkUpdateSwitcherFieldNames,
    generateBulkUpdateFieldName,
    generateBulkUpdateSwitcherName,
} from '../../services/autoforms';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialValueForColumnType, parseColumnSettings } from './utils/autoformUtils';
import { COLUMN_TYPES } from '../../constants/fields';
import { setBulkUpdateFieldsForTable, setBulkUpdateInitialValues } from '../../store/autoform/autoformSlice';

const skeletonsNumber = 3;
const defaultConfig = {};

/**
 * Renders Bulk Update section
 *
 * @param {string} tableId - bulk update target table id
 * @param {number} parentRecordId - parent record id
 * @param {object[]} columnDefinitions - target grid's column definitions
 * @param {object[]} columns - metadata's columns
 * @param {object} config - containing config for this and nested components
 * @returns {*}
 * @constructor
 */
const AutoformBulkUpdate = ({ tableId, parentRecordId, columnDefinitions, columns, config = defaultConfig }) => {
    const dispatch = useDispatch();
    const { values: formValues, setValues } = useFormikContext();

    const bulkUpdateData = useSelector((state) => state.autoform.tables?.[tableId]?.bulkUpdateData);
    const metadata = useSelector((state) => state.autoform.metadata);

    const [isLoading, setIsLoading] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [isAllVariedByRecord, setIsAllVariedByRecord] = useState(false);
    const [isSomeVariedByRecord, setIsSomeVariedByRecord] = useState(false);

    const lastBulkUpdateFormData = useRef();

    useEffect(() => {
        const targetBulkUpdateData = bulkUpdateData?.[parentRecordId?.toString()];

        setIsLoading(targetBulkUpdateData === null);
        setIsHidden(targetBulkUpdateData?.length === 0);

        if (!targetBulkUpdateData?.length) {
            return;
        }

        const bulkUpdateFormData = {};
        const tableMetadata = metadata?.tables.find((table) => table.id === tableId);

        for (const bulkUpdateDataItem of targetBulkUpdateData) {
            const fieldName = generateBulkUpdateFieldName(parentRecordId, tableId, bulkUpdateDataItem.columnId);
            const switcherName = generateBulkUpdateSwitcherName(parentRecordId, tableId, bulkUpdateDataItem.columnId);
            const column = tableMetadata.columns.find((column) => column.id === bulkUpdateDataItem.columnId);

            // The column might be not visible and not present in table metadata columns
            if (column) {
                bulkUpdateFormData[fieldName] = getInitialValueForColumnType(
                    { [column.name]: bulkUpdateDataItem.columnValue },
                    column.name,
                    tableMetadata,
                    true
                );
            }

            bulkUpdateFormData[switcherName] = bulkUpdateDataItem.variedByRecord;
        }

        dispatch(
            setBulkUpdateInitialValues({
                values: {
                    [tableId]: bulkUpdateFormData,
                },
            })
        );

        setValues((values) => ({
            ...values,
            bulkUpdate: {
                ...bulkUpdateFormData,
                ...values.bulkUpdate,
            },
        }));
    }, [setValues, tableId, parentRecordId, bulkUpdateData, metadata, dispatch]);

    useEffect(() => {
        if (lastBulkUpdateFormData.current && !formValues?.bulkUpdate) {
            setValues((values) => ({
                ...values,
                bulkUpdate: lastBulkUpdateFormData.current,
            }));
            lastBulkUpdateFormData.current = formValues?.bulkUpdate;
            return;
        }

        const fieldValues = formValues?.bulkUpdate;
        lastBulkUpdateFormData.current = fieldValues;
        const switcherNames = findBulkUpdateSwitcherFieldNames(fieldValues ?? {}, parentRecordId, tableId);

        if (switcherNames) {
            let allResult = true;
            let someResult = false;

            for (const fieldName of switcherNames) {
                allResult = allResult && fieldValues[fieldName];
                someResult = someResult || fieldValues[fieldName];
            }

            if (allResult) {
                someResult = false;
            }

            setIsAllVariedByRecord(allResult);
            setIsSomeVariedByRecord(someResult);
        }
    }, [formValues?.bulkUpdate, parentRecordId, tableId, setValues]);

    const subItemIdentifier = useMemo(() => (parentRecordId ? parentRecordId.toString() : ''), [parentRecordId]);
    const tableIdentifier = useMemo(() => (tableId ? tableId.toString() : ''), [tableId]);

    const columnDefinitionsAdjusted = useMemo(() => {
        const result = [];

        for (const definition of columnDefinitions) {
            if (definition.editable === false) {
                continue;
            }

            const targetColumn = columns.find((column) => column.name === definition?.field);

            if (targetColumn) {
                const settings = parseColumnSettings(targetColumn.settings);

                if (settings?.ALLOWBULKUPDATE === '1') {
                    result.push({
                        ...definition,
                        id: targetColumn.id,
                        columnMetadata: targetColumn,
                    });
                }
            }
        }

        return result;
    }, [columnDefinitions, columns]);

    const bulkUpdateFieldDefinitions = useMemo(
        () =>
            columnDefinitionsAdjusted.map((col) => {
                const inputType = Object.keys(COLUMN_TYPES).find((key) => COLUMN_TYPES[key] === col.type);
                const name = generateBulkUpdateFieldName(subItemIdentifier, tableIdentifier, col.id);

                return {
                    id: col.id,
                    name,
                    label: col.headerName,
                    inputType,
                    gridColumnType: col.type,
                    gridCellEditorParams: col.cellEditorParams,
                    columnMetadata: col.columnMetadata,
                };
            }),
        [subItemIdentifier, columnDefinitionsAdjusted, tableIdentifier]
    );

    useEffect(() => {
        if (tableId) {
            dispatch(setBulkUpdateFieldsForTable({ bulkUpdateFields: bulkUpdateFieldDefinitions, tableId }));
        }
    }, [dispatch, bulkUpdateFieldDefinitions, tableId]);

    const onToggleAllVariedByRecord = useCallback(() => {
        setValues((values) => {
            const bulkUpdate = { ...(values?.bulkUpdate ?? {}) };

            findBulkUpdateSwitcherFieldNames(bulkUpdate ?? {}, parentRecordId, tableId).forEach((key) => {
                bulkUpdate[key] = !isAllVariedByRecord;
            });

            return {
                ...values,
                bulkUpdate,
            };
        });
    }, [parentRecordId, tableId, isAllVariedByRecord, setValues]);

    return bulkUpdateFieldDefinitions?.length && !isHidden ? (
        <Accordion allowToggle reduceMotion my={6} defaultIndex={[0]}>
            <AccordionItem>
                {({ isExpanded }) => (
                    <>
                        <Flex
                            px={4}
                            py={1}
                            alignItems="center"
                            mt="-1px"
                            bg="background-octenary"
                            border="1px solid"
                            borderColor="border-secondary"
                        >
                            <AccordionButton>
                                {isExpanded ? <StyledChevronUpIcon /> : <StyledChevronDownIcon />}
                            </AccordionButton>

                            <Heading as="h4" variant="h4" fontSize="14px" lineHeight="20px">
                                <FormattedMessage
                                    id={
                                        config?.bulkUpdateConstantValuesLabelId ??
                                        'autoform_bulk_update_constant_values'
                                    }
                                />
                            </Heading>
                        </Flex>

                        <AccordionPanel px={0} py={6}>
                            <SimpleGrid spacingX={6} spacingY={5} columns={3}>
                                {isLoading ? (
                                    <>
                                        {[...Array(skeletonsNumber).keys()].map((index) => (
                                            <Skeleton height="56px" key={index} />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <CheckboxField
                                            my={2}
                                            gridColumn="span 3"
                                            id="all_varied_by_record"
                                            name="all_varied_by_record"
                                            isChecked={isAllVariedByRecord}
                                            isIndeterminate={isSomeVariedByRecord}
                                            onChange={onToggleAllVariedByRecord}
                                        >
                                            <Text as="span" fontSize="12px" color="gray.600">
                                                <FormattedMessage
                                                    id={
                                                        config?.bulkUpdateVariedAllLabelId ??
                                                        'autoform_bulk_update_varied_all'
                                                    }
                                                />
                                            </Text>
                                        </CheckboxField>

                                        {bulkUpdateFieldDefinitions.map((definition) => (
                                            <Flex direction="column" key={definition.id}>
                                                <AutoformBulkUpdateField {...definition} config={config} />
                                            </Flex>
                                        ))}
                                    </>
                                )}
                            </SimpleGrid>
                        </AccordionPanel>
                    </>
                )}
            </AccordionItem>
        </Accordion>
    ) : null;
};

const StyledChevronDownIcon = styled(ChevronDownIcon)`
    circle {
        fill: var(--chakra-colors-gray-100);
    }

    path {
        fill: var(--chakra-colors-gray-600);
    }

    &:hover {
        cursor: pointer;
        path {
            fill: var(--chakra-colors-blue-400);
        }
    }
`;

const StyledChevronUpIcon = styled(StyledChevronDownIcon)`
    transform: rotate(180deg);
`;

export default AutoformBulkUpdate;
