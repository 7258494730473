import { colors } from '../colors';

const Popover = {
    parts: ['body', 'arrow'],
    baseStyle: {
        body: {
            background: 'gray.200',
            textAlign: 'center',

            _dark: {
                color: 'gray.300',
                background: 'gray.800',
            },
        },
        arrow: {
            background: `${colors.gray['200']} !important`,

            _dark: {
                background: `${colors.gray['800']} !important`,
            },
        },
    },
};

export default Popover;
