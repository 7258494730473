import { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { Box, Input } from '@chakra-ui/react';

import Combobox from '../forms/Combobox';

const operators = [
    {
        name: 'is',
        value: 'is',
        operator: '=',
    },
    {
        name: 'is greater than',
        value: 'is_greater_than',
        operator: '>',
    },
    {
        name: 'is less than',
        value: 'is_less_than',
        operator: '<',
    },
    {
        name: 'is not',
        value: 'is_not',
        operator: '<>',
    },
    {
        name: 'is at least',
        value: 'is_atleast',
        operator: '>=',
    },
    {
        name: 'is at most',
        value: 'is_at_most',
        operator: '<=',
    },
];

const MapFilter = ({ filter, onFilterChange, isoConfig }) => {
    const firstStudy = isoConfig.studies[0];
    const [study, setStudy] = useState(firstStudy);
    const firstYear = firstStudy.years[0];
    const defaultRendererField = isoConfig.defaultRendererField;
    // Check if study has ISO's default field
    const defaultYear = firstStudy.years.find(
        (year) => year.fieldName.toUpperCase() === defaultRendererField?.toUpperCase()
    );
    const selectYear = defaultYear ? defaultYear : firstYear;

    const [year, setYear] = useState(selectYear);
    const [operator, setOperator] = useState(operators[0]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        filter.field = selectYear.fieldName;
        filter.operator = operator.operator;
        onFilterChange(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onStudyChange = (studyName) => {
        const study = isoConfig.studies.find((elem) => elem.name === studyName);
        setStudy(study);

        const firstYear = study?.years[0];
        const defaultRendererField = isoConfig.defaultRendererField;
        // Check if study has ISO's default field
        const defaultYear = study?.years.find(
            (year) => year.fieldName.toUpperCase() === defaultRendererField?.toUpperCase()
        );
        const selectYear = defaultYear ? defaultYear : firstYear;
        setYear(selectYear);

        filter.field = selectYear.fieldName;
        onFilterChange(filter);
    };

    const onYearChange = (fieldName) => {
        const newYear = study.years.find((elem) => elem.fieldName === fieldName);
        setYear(newYear);
        filter.field = newYear.fieldName;
        onFilterChange(filter);
    };

    const onOperatorChange = (value) => {
        const newOperator = operators.find((elem) => elem.value === value);
        setOperator(newOperator);
        filter.operator = newOperator.operator;
        onFilterChange(filter);
    };

    const onInputChange = (value) => {
        setInputValue(value);
        filter.input = value;
        onFilterChange(filter);
    };

    return (
        <FilterContainer>
            <Combobox
                my={3}
                options={isoConfig.studies}
                valueKey="name"
                labelKey="name"
                onChange={onStudyChange}
                value={study.name}
            />
            <Combobox
                my={3}
                options={study.years}
                valueKey="fieldName"
                labelKey="title"
                value={year.fieldName}
                onChange={onYearChange}
            />
            <Combobox
                my={3}
                options={operators}
                valueKey="value"
                labelKey="name"
                value={operator.value}
                onChange={onOperatorChange}
            />
            <Input
                type="number"
                value={inputValue}
                placeholder="Value"
                onChange={(val) => onInputChange(val.target.value)}
            />
        </FilterContainer>
    );
};
const FilterContainer = styled(Box)`
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 10px 20px;
    margin: 20px 0px;
`;

export default MapFilter;
