import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import Battery from '../../components/batteries/Battery';
import { getRouteTypeParamById } from 'services/items';
import mappedItemTypes from 'services/items/mappedItemTypes';
import Load from '../load/Load';
import Renewable from '../renewables/Renewable';
import Hedge from '../hedges/Hedge';
import Thermal from '../thermals/Thermal';
import ShapeSetPortfolioContainer from '../shapeSet/ShapeSetPortfolioContainer';
import GlobalConstraintsPortfolioContainer from '../globalConstraints/GlobalConstraintsPortfolioContainer';
import PortfolioAutoformContainer from '../autoform/PortfolioAutoformContainer';
import HydroProjectPortfolio from '../../components/hydro-project/HydroProjectPortfolio';

const PortfolioItem = ({
    item,
    itemTypes,
    onNodeRename,
    onNodeChangeIsMaster,
    onNodeOwnershipPercentageUpdate,
    onNodeItemDelete,
    refreshMapLayer,
}) => {
    const isAutoform = useMemo(() => {
        return !!itemTypes.find((itemType) => itemType.itemTypeId === item.properties.typeId)?.subSchemaId;
    }, [itemTypes, item]);

    switch (item.properties.typeId) {
        case mappedItemTypes.LOAD.id:
            return (
                <Load
                    loadItem={item}
                    onNodeRename={onNodeRename}
                    onNodeChangeIsMaster={onNodeChangeIsMaster}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                    onNodeItemDelete={onNodeItemDelete}
                    refreshMapLayer={refreshMapLayer}
                    helpPageUrlItemTypeId={item.properties.typeId}
                />
            );

        case mappedItemTypes.BATTERY_STORAGE.id:
            return (
                <Battery
                    batteryItem={item}
                    onNodeRename={onNodeRename}
                    onNodeChangeIsMaster={onNodeChangeIsMaster}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                    onNodeItemDelete={onNodeItemDelete}
                    refreshMapLayer={refreshMapLayer}
                    helpPageUrlItemTypeId={item.properties.typeId}
                />
            );

        case mappedItemTypes.SOLAR_ASSET.id:
        case mappedItemTypes.WIND_ASSET.id:
            return (
                <Renewable
                    renewableItem={item}
                    onNodeRename={onNodeRename}
                    onNodeChangeIsMaster={onNodeChangeIsMaster}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                    onNodeItemDelete={onNodeItemDelete}
                    refreshMapLayer={refreshMapLayer}
                    helpPageUrlItemTypeId={item.properties.typeId}
                />
            );

        case mappedItemTypes.FINANCIAL_INSTRUMENT.id:
        case mappedItemTypes.PHYSICAL_INSTRUMENT.id:
            return (
                <Hedge
                    hedgeItem={item}
                    onNodeRename={onNodeRename}
                    onNodeChangeIsMaster={onNodeChangeIsMaster}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                    onNodeItemDelete={onNodeItemDelete}
                    refreshMapLayer={refreshMapLayer}
                    helpPageUrlItemTypeId={item.properties.typeId}
                />
            );

        case mappedItemTypes.GENERATION_ASSET.id:
        case mappedItemTypes.DSM.id:
            return (
                <Thermal
                    type={getRouteTypeParamById(item.properties.typeId)}
                    thermalItem={item}
                    onNodeRename={onNodeRename}
                    onNodeChangeIsMaster={onNodeChangeIsMaster}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                    onNodeItemDelete={onNodeItemDelete}
                    refreshMapLayer={refreshMapLayer}
                    helpPageUrlItemTypeId={item.properties.typeId}
                />
            );

        case mappedItemTypes.SHAPE_SETS.id:
            return (
                <ShapeSetPortfolioContainer
                    portfolioItem={item}
                    itemId={item.properties.id}
                    autoformId={item.properties.typeId}
                    onNodeRename={onNodeRename}
                    onNodeChangeIsMaster={onNodeChangeIsMaster}
                    onNodeItemDelete={onNodeItemDelete}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                    helpPageUrlItemTypeId={item.properties.typeId}
                />
            );

        case mappedItemTypes.GLOBAL_CONSTRAINTS.id:
            return (
                <GlobalConstraintsPortfolioContainer
                    portfolioItem={item}
                    itemId={item.properties.id}
                    autoformId={item.properties.typeId}
                    onNodeRename={onNodeRename}
                    onNodeChangeIsMaster={onNodeChangeIsMaster}
                    onNodeItemDelete={onNodeItemDelete}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                />
            );
        case mappedItemTypes.HYDRO_PROJECT.id:
            return <HydroProjectPortfolio portfolioItem={item} />;
        default:
            if (isAutoform) {
                return (
                    <PortfolioAutoformContainer
                        portfolioItem={item}
                        autoformId={item.properties.typeId}
                        itemId={item.properties.id}
                        onNodeRename={onNodeRename}
                        onNodeChangeIsMaster={onNodeChangeIsMaster}
                        onNodeItemDelete={onNodeItemDelete}
                        onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                    />
                );
            } else {
                return (
                    <Box padding={6}>
                        <Alert status="warning">
                            <AlertIcon />
                            <FormattedMessage
                                id="portfolios_item_info_not_found_warning"
                                values={{ itemType: item?.properties?.itemType, name: item?.properties?.name }}
                            />
                        </Alert>
                    </Box>
                );
            }
    }
};

export default PortfolioItem;
