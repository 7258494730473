import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import { getTableFormKey, getItemFormKey } from './utils/autoformUtils';

import { ReactComponent as ErrorIcon } from '../../icons/warning.svg';
import { ReactComponent as WarningIcon } from '../../icons/soft-warning.svg';

/**
 * Component to render errors and warnings outside of formik field validation.
 * Used where description columns are shown as text.
 *
 * @param {string} tableId
 * @param {callback} renderSubItem - Render prop to build accordion panel contents.
 */
const SubItemValidationStatusContainer = ({ tableId, recordId }) => {
    const { errors, status, touched } = useFormikContext();
    const errorsForSubItem =
        errors && errors[getTableFormKey(tableId)] && errors[getTableFormKey(tableId)][getItemFormKey(recordId)];
    const touchedForSubItem = useMemo(
        () =>
            !!Object.entries(
                (touched &&
                    touched[getTableFormKey(tableId)] &&
                    touched[getTableFormKey(tableId)][getItemFormKey(recordId)]) ??
                    {}
            ).find(([, value]) => value === true),
        [tableId, recordId, touched]
    );
    const { errors: serverErrorsForSubItem, warnings: warningsForSubItem } = useMemo(() => {
        if (status && status[getTableFormKey(tableId)] && status[getTableFormKey(tableId)][getItemFormKey(recordId)]) {
            return Object.values(status[getTableFormKey(tableId)][getItemFormKey(recordId)]).reduce(
                (acc, itemErrorsAndWarnings) => {
                    if (itemErrorsAndWarnings.errors?.length) {
                        acc.errors.push(...itemErrorsAndWarnings.errors);
                    }
                    if (itemErrorsAndWarnings.warnings?.length) {
                        acc.warnings.push(...itemErrorsAndWarnings.warnings);
                    }
                    return acc;
                },
                { errors: [], warnings: [] }
            );
        } else {
            return { errors: [], warnings: [] };
        }
    }, [recordId, status, tableId]);

    const validationMessages = useSelector(
        (state) =>
            state.autoform.tables[tableId] &&
            state.autoform.tables[tableId].generalValidationMessages &&
            state.autoform.tables[tableId].generalValidationMessages[recordId]
    );

    const hasErrors = useMemo(() => {
        if (!touchedForSubItem) {
            return false;
        } else {
            return (
                Object.values(errorsForSubItem ?? {}).filter((errorText) => !!errorText).length > 0 ||
                serverErrorsForSubItem?.length > 0 ||
                validationMessages?.errors?.length > 0
            );
        }
    }, [errorsForSubItem, serverErrorsForSubItem, validationMessages, touchedForSubItem]);
    const hasWarnings = useMemo(
        () =>
            Object.values(warningsForSubItem ?? {}).filter((warningText) => !!warningText).length > 0 ||
            warningsForSubItem?.length > 0 ||
            validationMessages?.warnings?.length > 0,
        [warningsForSubItem, validationMessages?.warnings]
    );

    return hasErrors ? (
        <Box display="inline-flex" alignItems={'center'} mr={6}>
            <Text color="text-error" textTransform={'capitalize'} mr={2}>
                <FormattedMessage id="common_error" />
            </Text>
            <ErrorIcon />
        </Box>
    ) : hasWarnings ? (
        <Box display="inline-flex" mr={6} alignItems={'center'}>
            <Text color="yellow.500" textTransform={'capitalize'} mr={2}>
                <FormattedMessage id="common_warning" />
            </Text>
            <WarningIcon />
        </Box>
    ) : null;
};

export default SubItemValidationStatusContainer;
