import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAutoformLevelDropdowns } from '../../../store/autoform/autoformApi';
import useAutoformParams from './useAutoformParams';

/**
 * Loads the options for each dropdown for a single autoform table. These options
 * are shown in comboboxes either in AG Grid or in Formik forms.
 *
 * @param {string} tableId - Id of the table to dropdown options data for.
 * @param {number} parentRecordId - parent record's id
 * @returns {boolean} isLoading - Is the dropdown data still being loaded.
 */
const useAutoformLoadTableDropdowns = (tableId, parentRecordId = undefined) => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const parameters = useAutoformParams();

    useEffect(() => {
        let dropdownsPromise;
        if (tableId) {
            dropdownsPromise = dispatch(getAutoformLevelDropdowns({ tableId, parameters, parentRecordId }));
            dropdownsPromise.then((action) => {
                if (!action.meta.aborted) {
                    setIsLoading(false);
                }
            });
        }

        return () => {
            dropdownsPromise?.abort();
        };
    }, [dispatch, parameters, tableId, parentRecordId]);

    return { isLoading };
};

export default useAutoformLoadTableDropdowns;
