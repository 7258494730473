import { useMemo } from 'react';
import AutoformInputFormikField from '../autoform/AutoformInputFormikField';
import { parseColumnSettings, parseDateFormat, parseNumberFormat } from '../autoform/utils/autoformUtils';
import { DATE_FORMATS_WITHOUT_TIME } from 'constants/fields';
import useAutoformMetadata from 'components/autoform/hooks/useAutoformMetadata';
import useAutoformTableMetadata from 'components/autoform/hooks/useAutoformTableMetadata';

const SpotPriceInputField = ({ columnName, mode, onChange, isDisabled = false, getInputFormName }) => {
    const { hierarchicalTables } = useAutoformMetadata();

    const tableId = hierarchicalTables.level1[0]?.id;
    const { metadata: tableMetadata, selectedItem, dropdownOptions, sortedColumns } = useAutoformTableMetadata(tableId);

    const column = useMemo(() => sortedColumns.find((col) => col.name === columnName), [columnName, sortedColumns]);

    return useMemo(() => {
        const settings = parseColumnSettings(column.settings);
        const dateFormat = parseDateFormat(column);
        const numberFormat = parseNumberFormat(column);

        return (
            <AutoformInputFormikField
                inputName={getInputFormName(tableId, selectedItem, column.name)}
                key={column.name}
                inputType={column.inputTypeDescription}
                inputLabel={column.displayName}
                placeholder={column.displayName}
                dataType={column.dataTypeDescription}
                dropdownOptions={dropdownOptions[column.id]}
                settings={settings}
                formats={{
                    dateFormat,
                    numberFormat,
                }}
                showTimeInput={!DATE_FORMATS_WITHOUT_TIME.includes(column.formatCode)}
                isDisabled={isDisabled || column.isReadOnly || (!tableMetadata.canUpdate && mode === 'edit')}
                isRequired={column.isRequired}
                onChange={onChange}
            />
        );
    }, [selectedItem, tableId, column, mode, onChange, tableMetadata, dropdownOptions, isDisabled, getInputFormName]);
};

export default SpotPriceInputField;
