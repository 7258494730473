import styled from '@emotion/styled/macro';
import { Flex, IconButton, Text } from '@chakra-ui/react';

const CellIconButton = ({ value, valueFormatted, node, onClick, icon }) => {
    return (
        <Flex justifyContent="space-between" alignItems="center" w="100%">
            <TextWrapper>
                <Text color="inherit" _dark={{ color: 'inherit' }} fontWeight="normal">
                    {valueFormatted || value}
                </Text>
            </TextWrapper>

            <IconButton icon={icon} variant="ghost" textTransform="capitalize" onClick={() => onClick(node)} />
        </Flex>
    );
};

const TextWrapper = styled.div`
    width: 180px;
    line-height: 20px;
    padding-top: 2px;
    p {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export default CellIconButton;
