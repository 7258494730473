import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPrimaryKeyColName } from '../utils/autoformUtils';
import { getDescriptionColumn } from '../../../services/autoforms';
import { HELP_SETTINGS_ANCHOR_KEY } from 'constants/help';

/**
 * Selects table metadata from the store and does useful transformations.
 *
 * @param {string} tableId - Id of the table.
 * @returns {object} metadata - The raw metadata of the table.
 * @returns {string} primaryKeyColumnName - The key for the primary column. Records
 * are returned as objects in the database, with column names as the keys of the object.
 * To identify a record uniquely, we need to access the primary  column via primaryKeyColumnName.
 * @returns {object[]} sortedColumns - Column metadata for each column in the table, sorted by order.
 * @returns {string} selectedItem - The id (primary key column value) for the selected record in the table.
 * @returns {{[columnId]: object[]}} dropdownOptions - Map of column ids to arrays of the dropdown
 * options associated with that column id. Used with comboboxes.
 * @returns {object} descriptionColumn - Column metadata for the special column used for titles
 * and headings in the UI.
 * @returns {object} descriptionColumnName - The name of the special column used for titles and headings
 * in the UI.
 * * @returns {object[]} settings - Key value pair of all settings assosiated with the table.
 * * @returns {object} helpAnchor - The value associated with the ANCHOR key in the settings array, undefined if not found.
 */
const useAutoformTableMetadata = (tableId) => {
    const metadata = useSelector(
        (state) => state.autoform.metadata && state.autoform.metadata.tables.find((table) => table.id === tableId)
    );
    const selectedItem = useSelector(
        (state) => state.autoform.tables[tableId] && state.autoform.tables[tableId].selectedItem
    );
    const dropdownOptionsRaw = useSelector(
        (state) => state.autoform.tables[tableId] && state.autoform.tables[tableId].dropdownOptions
    );

    const primaryKeyColumnName = useMemo(() => metadata && getPrimaryKeyColName(metadata.columns), [metadata]);

    const sortedColumns = useMemo(() => {
        if (metadata) {
            const visibleColumns = metadata?.columns?.filter((column) => column.isVisible);
            return visibleColumns?.sort((prev, next) => (prev.order > next.order ? 1 : -1));
        } else {
            return [];
        }
    }, [metadata]);

    const dropdownOptions = useMemo(
        () =>
            (dropdownOptionsRaw || []).reduce((acc, item) => {
                acc[item.columnId] = item.lookupData;
                return acc;
            }, {}),
        [dropdownOptionsRaw]
    );

    const descriptionColumn = useMemo(() => getDescriptionColumn(sortedColumns), [sortedColumns]);

    const descriptionColumnName = descriptionColumn?.name || '';

    const settings = useMemo(() => {
        if (metadata) {
            return metadata?.settings || [];
        } else {
            return [];
        }
    }, [metadata]);

    const helpAnchor = useMemo(() => {
        const helpAnchorObj = Array.isArray(settings) ? settings.find((a) => a.name === HELP_SETTINGS_ANCHOR_KEY) : {};
        const helpAnchor = helpAnchorObj?.value;
        return helpAnchor;
    }, [settings]);

    return {
        metadata,
        primaryKeyColumnName,
        sortedColumns,
        selectedItem,
        dropdownOptions,
        descriptionColumn,
        descriptionColumnName,
        settings,
        helpAnchor,
    };
};

export default useAutoformTableMetadata;
