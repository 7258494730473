import { useMemo, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import { set, isBefore, isAfter } from 'date-fns';
import { Heading, SimpleGrid, Divider, Text, Flex, Button, Spacer } from '@chakra-ui/react';

import {
    convertRelativeToUTCDateMidnight,
    convertRelativeToUTCDateMidnightExtraDayMinusOneHour,
} from '../../services/utils';
import { removeFilters } from '../../services/items';

import {
    getBasisFiltersConfig,
    setBasisFiltersConfig,
    parseBasisDataFilters,
    systemDefaultBasisFilter,
} from '../../services/basis';

import DateFormikField from '../forms/DateFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';

const BasisFilters = ({ onApplyFilters, item }) => {
    const intl = useIntl();

    const initialValues = useMemo(() => {
        const prefs = getBasisFiltersConfig(item) || { ...systemDefaultBasisFilter };

        // chakra's form fields are all using string values so we might as well stringify them here to stay consistent
        return {
            ...prefs,
            daysFrom: String(prefs.daysFrom),
            daysTo: String(prefs.daysTo),
        };
    }, [item]);

    const formLabels = {
        from: intl.formatMessage({ id: 'common_from' }),
        to: intl.formatMessage({ id: 'common_to' }),
        relative_from: intl.formatMessage({ id: 'common_relative_from' }),
        relative_to: intl.formatMessage({ id: 'common_relative_to' }),
    };

    const validateSiblingDate = useCallback(
        ({ value, siblingValue, type }) => {
            // when you delete the contents of the datepicker/numberinput the value is null or empty string
            const isInvalidValue = value === null || value === '';
            const isInvalidSiblingValue = siblingValue === null || siblingValue === '';

            if (isInvalidValue || (isInvalidValue && isInvalidSiblingValue)) {
                // show an error if the value is invalid or both the value and the sibling value are invalid
                return intl.formatMessage({ id: 'common_forms_invalid_value' });
            }

            if (isInvalidSiblingValue) {
                // if the sibling value is invalid don't try validating this value
                return null;
            }

            const dateA = set(value, { seconds: 0, milliseconds: 0 });
            const dateB = set(siblingValue, { seconds: 0, milliseconds: 0 });

            if (type === 'from' && isAfter(dateA, dateB)) {
                return intl.formatMessage({ id: 'filter_sibling_date_smaller' });
            } else if (type === 'to' && isBefore(dateA, dateB)) {
                return intl.formatMessage({ id: 'filter_sibling_date_bigger' });
            }

            return null;
        },
        [intl]
    );

    const validate = useCallback(
        (values) => {
            const realtiveDaysFrom = parseInt(values.daysFrom, 10);
            const relativeDaysTo = parseInt(values.daysTo, 10);

            const relativeStartDateFrom = convertRelativeToUTCDateMidnight(realtiveDaysFrom);
            // Per business request include extra day
            const relativeStartDateTo = convertRelativeToUTCDateMidnightExtraDayMinusOneHour(relativeDaysTo);

            const startDateFrom = !values.toggleDaysFrom
                ? validateSiblingDate({
                      value: values.startDateFrom,
                      siblingValue: values.toggleDaysTo ? relativeStartDateTo : values.startDateTo,
                      type: 'from',
                  })
                : null;

            const daysFrom = values.toggleDaysFrom
                ? validateSiblingDate({
                      value: values.daysFrom ? relativeStartDateFrom : realtiveDaysFrom,
                      siblingValue: values.toggleDaysTo ? relativeStartDateTo : values.startDateTo,
                      type: 'from',
                  })
                : null;

            const startDateTo = !values.toggleDaysTo
                ? validateSiblingDate({
                      value: values.startDateTo,
                      siblingValue: values.toggleDaysFrom ? relativeStartDateFrom : values.startDateFrom,
                      type: 'to',
                  })
                : null;

            const daysTo = values.toggleDaysTo
                ? validateSiblingDate({
                      value: values.daysTo ? relativeStartDateTo : relativeDaysTo,
                      siblingValue: values.toggleDaysFrom ? relativeStartDateFrom : values.startDateFrom,
                      type: 'to',
                  })
                : null;

            return {
                ...(startDateFrom ? { startDateFrom } : null),
                ...(daysFrom ? { daysFrom } : null),
                ...(startDateTo ? { startDateTo } : null),
                ...(daysTo ? { daysTo } : null),
            };
        },
        [validateSiblingDate]
    );

    const onKeyDown = useCallback((event) => {
        if (event.key === 'Enter') event.preventDefault();
    }, []);

    const onSubmit = useCallback(
        (values) => {
            const realtiveDaysFrom = parseInt(values.daysFrom, 10);
            const relativeDaysTo = parseInt(values.daysTo, 10);

            const relativeStartDateFrom = convertRelativeToUTCDateMidnight(realtiveDaysFrom);
            // Per business request include extra day
            const relativeStartDateTo = convertRelativeToUTCDateMidnightExtraDayMinusOneHour(relativeDaysTo);

            const newFilters = {
                ...values,
                daysFrom: realtiveDaysFrom,
                daysTo: relativeDaysTo,
            };

            if (values.toggleDaysFrom) {
                newFilters.startDateFrom = relativeStartDateFrom;
            }

            if (values.toggleDaysTo) {
                newFilters.startDateTo = relativeStartDateTo;
            }

            setBasisFiltersConfig(newFilters, item);
            onApplyFilters(parseBasisDataFilters(newFilters));
        },
        [onApplyFilters, item]
    );

    const resetToDefaultFilter = useCallback(() => {
        removeFilters(item);
        onApplyFilters(systemDefaultBasisFilter);
    }, [onApplyFilters, item]);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {({ values, isValid }) => (
                <Form onKeyDown={onKeyDown}>
                    <Heading as="h4" variant="h4" mb={5}>
                        <FormattedMessage id="common_item_start_filter" />
                    </Heading>

                    <SimpleGrid columns={{ xl: 2 }} spacingX={4} spacingY={3} mb={4}>
                        <DateFormikField
                            id="startDateFrom"
                            name="startDateFrom"
                            label={formLabels.from}
                            isDisabled={values.toggleDaysFrom}
                        />

                        <NumberInputFormikField
                            mt={{ base: '0px', xl: '18px' }}
                            id="daysFrom"
                            name="daysFrom"
                            isDisabled={!values.toggleDaysFrom}
                            precision={0}
                            step={1}
                            max={100000}
                            min={-100000}
                        />

                        <Spacer display={{ base: 'none', xl: 'block' }} />

                        <CheckboxFormikField id="toggleDaysFrom" name="toggleDaysFrom">
                            {formLabels.relative_from}
                        </CheckboxFormikField>
                    </SimpleGrid>

                    <SimpleGrid columns={{ xl: 2 }} spacingX={4} spacingY={3}>
                        <DateFormikField
                            id="startDateTo"
                            name="startDateTo"
                            label={formLabels.to}
                            isDisabled={values.toggleDaysTo}
                        />

                        <NumberInputFormikField
                            mt={{ base: '0px', xl: '18px' }}
                            id="daysTo"
                            name="daysTo"
                            isDisabled={!values.toggleDaysTo}
                            precision={0}
                            step={1}
                            max={100000}
                            min={-100000}
                        />

                        <Spacer display={{ base: 'none', xl: 'block' }} />

                        <CheckboxFormikField id="toggleDaysTo" name="toggleDaysTo">
                            {formLabels.relative_to}
                        </CheckboxFormikField>
                    </SimpleGrid>

                    <Divider mt={60} mb={8} />

                    <Flex>
                        <Button onClick={resetToDefaultFilter} variant="secondary">
                            <FormattedMessage id="common_reset_to_default_btn" />
                        </Button>

                        <Spacer />

                        <Flex flexDirection={'column'} alignItems={'flex-end'}>
                            <Button type="submit" disabled={!isValid}>
                                <FormattedMessage id="common_apply_filter_btn" />
                            </Button>

                            <Text size="xs" variant="regular" maxW={52} marginTop={3} textAlign="end">
                                <FormattedMessage id="basis_apply_filter_message" />
                            </Text>
                        </Flex>
                    </Flex>
                </Form>
            )}
        </Formik>
    );
};

export default BasisFilters;
