import {
    Heading,
    Flex,
    Text,
    Box,
    AccordionItem,
    AccordionPanel,
    SimpleGrid,
    Divider,
    useMediaQuery,
    MenuItem,
    IconButton,
} from '@chakra-ui/react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { checkItemInputEvent } from '../../services/items';

import Tooltip from '../utils/Tooltip';
import AccordionButton from '../utils/AccordionButton';

import InputFormikField from '../forms/InputFormikField';

import SubItemActionsMenu from '../itemData/SubItemActionsMenu';

import SortOrderProvider from '../grid/utils/SortOrderContext';

import LoadDefinitionPanel from './LoadDefinitionPanel';
import SubLoadData from './SubLoadData';

import { ReactComponent as AddItemIcon } from '../../icons/item-icons/add-item.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete-circled.svg';

const LoadDefinition = ({
    idx,
    itemId,
    itemLabel,
    definitions,
    formLabels,
    filters,
    onDeleteModalOpen,
    editDescriptionConfig,
    setEditDescriptionConfig,
    onEditDescription,
    onAddSubItemModalOpen,
    helpPageUrlItemTypeId = null,
}) => {
    const intl = useIntl();
    const [isDesktop] = useMediaQuery('(min-width: 769px)');

    const definition = definitions.loadDefinitionResponses[idx];
    const foundEditDescriptionConfig = editDescriptionConfig[`${definition.id}`];
    const isEditing = foundEditDescriptionConfig ? foundEditDescriptionConfig.isActive : false;

    const { values } = useFormikContext();

    const errorNameMissing = intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Sub-load name' });
    const errorNameAboveMax = intl.formatMessage(
        { id: 'common_forms_validation_length' },
        { label: 'Sub-load name', lengthRule: '50 characters or less' }
    );

    const validateName = (value, selectedLoadId) => {
        const trimmed = value.trim();
        let errorMessage = '';

        if (trimmed.length === 0) {
            errorMessage = errorNameMissing;
        } else if (trimmed.length > 50) {
            errorMessage = errorNameAboveMax;
        }

        if (errorMessage !== '') {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [selectedLoadId]: { ...prevState[selectedLoadId], penDisabled: true },
                };
            });
        } else {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [selectedLoadId]: { ...prevState[selectedLoadId], penDisabled: false },
                };
            });
        }

        return errorMessage;
    };

    return (
        <AccordionItem>
            {({ isExpanded }) => (
                <>
                    <SimpleGrid
                        columns={2}
                        mt="-1px"
                        bg="background-octenary"
                        borderTop="1px solid"
                        borderBottom="1px solid"
                        borderColor="border-secondary"
                    >
                        <Flex align="center" ml={4} my={3}>
                            <AccordionButton />

                            <Box flex={isEditing && 1}>
                                <Text size="xs" mb="2px">
                                    <FormattedMessage id="load_sub_load_heading" />
                                </Text>

                                {!isEditing ? (
                                    <Heading as="h4" variant="h4">
                                        {values.definitions[idx]?.description}
                                    </Heading>
                                ) : (
                                    <InputFormikField
                                        validate={(value) => validateName(value, definition.id)}
                                        name={`definitions.${idx}.description`}
                                        type="text"
                                        onKeyDown={checkItemInputEvent}
                                        isFastField
                                    />
                                )}
                            </Box>

                            <Flex align="center" ml={3}>
                                <Tooltip label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}>
                                    <IconButton
                                        minH="40px"
                                        variant="circular-icon"
                                        aria-label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}
                                        leftIcon={<EditIcon />}
                                        onClick={() => onEditDescription(definition.id)}
                                    />
                                </Tooltip>
                            </Flex>
                        </Flex>

                        <Flex justify="flex-end" align="center" pr={6}>
                            {!isDesktop ? (
                                <SubItemActionsMenu
                                    menuListItems={
                                        <>
                                            <MenuItem
                                                key="delete-btn"
                                                textTransform="capitalize"
                                                onClick={() => {
                                                    onDeleteModalOpen(definition.id, definition.description);
                                                }}
                                            >
                                                <FormattedMessage id="common_delete" />
                                            </MenuItem>

                                            <MenuItem
                                                key="create-btn"
                                                textTransform="capitalize"
                                                onClick={onAddSubItemModalOpen}
                                            >
                                                <FormattedMessage id="common_add" />
                                            </MenuItem>
                                        </>
                                    }
                                />
                            ) : (
                                <>
                                    <Box display="inline-block">
                                        <Tooltip
                                            label={intl.formatMessage({
                                                id: 'common_delete_btn_tooltip_and_label',
                                            })}
                                        >
                                            <IconButton
                                                aria-label={intl.formatMessage({
                                                    id: 'common_delete_btn_tooltip_and_label',
                                                })}
                                                variant="circular-icon"
                                                icon={<DeleteIcon />}
                                                onClick={() => {
                                                    onDeleteModalOpen(definition.id, definition.description);
                                                }}
                                            />
                                        </Tooltip>
                                    </Box>

                                    <Divider orientation="vertical" mx={6} />

                                    <Box display="inline-block">
                                        <Tooltip label={intl.formatMessage({ id: 'common_add_new' })}>
                                            <IconButton
                                                variant="circular-icon"
                                                aria-label={intl.formatMessage({ id: 'common_add_new' })}
                                                icon={<AddItemIcon />}
                                                onClick={onAddSubItemModalOpen}
                                            />
                                        </Tooltip>
                                    </Box>
                                </>
                            )}
                        </Flex>
                    </SimpleGrid>

                    {isExpanded && (
                        <AccordionPanel px={6} pt={6} pb={4}>
                            <LoadDefinitionPanel
                                idx={idx}
                                definitions={definitions}
                                formLabels={formLabels}
                                helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                            />

                            <SortOrderProvider>
                                <SubLoadData
                                    itemLabel={itemLabel}
                                    subLoadId={definition.id}
                                    itemId={itemId}
                                    filters={filters}
                                    helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                                />
                            </SortOrderProvider>
                        </AccordionPanel>
                    )}
                </>
            )}
        </AccordionItem>
    );
};

export default LoadDefinition;
