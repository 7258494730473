import { theme } from '@chakra-ui/react';

export const semanticTokens = {
    colors: {
        // Background styles
        'background-primary': {
            default: 'white',
            _dark: 'black',
        },

        'background-secondary': {
            default: 'gray.50',
            _dark: 'black',
        },

        'background-tertiary': {
            default: 'white',
            _dark: 'gray.800',
        },

        'background-quaternary': {
            default: 'gray.100',
            _dark: 'gray.800',
        },

        'background-quinary': {
            default: 'gray.100',
            _dark: 'gray.600',
        },

        'background-senary': {
            default: 'gray.100',
            _dark: 'black',
        },

        'background-septenary': {
            default: 'gray.100',
            _dark: '#2F2F39',
        },

        'background-octenary': {
            default: 'gray.50',
            _dark: 'gray.800',
        },

        // Border styles
        'border-primary': {
            default: 'gray.100',
            _dark: 'gray.800',
        },

        'border-secondary': {
            default: 'gray.200',
            _dark: 'gray.600',
        },

        'border-tertiary': {
            default: 'gray.100',
            _dark: 'gray.600',
        },

        'text-primary': {
            default: 'black',
            _dark: 'gray.200',
        },

        'text-secondary': {
            default: 'gray.900',
            _dark: 'gray.200',
        },

        'text-tertiary': {
            default: 'gray.600',
            _dark: 'gray.200',
        },

        'text-quaternary': {
            default: 'black',
            _dark: 'gray.300',
        },

        'text-quinary': {
            default: 'gray.800',
            _dark: 'white',
        },

        'text-senary': {
            default: 'gray.600',
            _dark: 'white',
        },

        'text-septenery': {
            default: 'gray.900',
            _dark: 'white',
        },

        'text-error': {
            default: 'red.900',
            _dark: 'red.800',
        },

        'navigation-active-item-text': {
            default: 'gray.900',
        },

        'item-icon-fill': {
            default: 'gray.600',
            _dark: 'gray.400',
        },

        // AG Grid styles
        'ag-body-viewport': {
            default: 'gray.200',
            _dark: 'gray.600',
        },

        'ag-header-cell-text': {
            default: 'gray.600',
            _dark: 'gray.300',
        },

        'ag-cell-color': {
            default: 'gray.800',
            _dark: 'gray.100',
        },

        'ag-cell-color-readonly': {
            default: 'gray.800',
            _dark: 'gray.100',
        },

        'ag-cell-border': {
            default: 'gray.200',
            _dark: 'gray.600',
        },

        'ag-row-even': {
            default: 'gray.100',
            _dark: 'rgba(61, 61, 78, 0.6)', // gray.300 with 0.6 alpha
        },

        'ag-row-even-job-logs': {
            default: 'white',
            _dark: 'rgba(61, 61, 78, 0.6)', // gray.300 with 0.6 alpha
        },

        'ag-row-hover': {
            default: 'var(--ag-row-hover-color, rgba(33, 150, 243, 0.1))',
            _dark: 'rgba(0, 77, 140, 0.6)',
        },

        'ag-paging-panel': {
            default: 'gray.800',
            _dark: 'gray.300',
        },

        'ag-paging-button': {
            default: 'transparent',
            _dark: 'gray.600',
        },

        'ag-selection-checkbox': {
            default: 'white',
            _dark: 'transparent',
        },

        'ag-selection-border': {
            default: 'gray.300',
            _dark: 'gray.400',
        },

        'ag-readonly-cell-background': {
            default: 'gray.200',
            _dark: 'gray.600',
        },

        'ag-readonly-cell-border': {
            default: 'gray.400',
            _dark: 'gray.800',
        },

        'ag-menu': {
            default: 'white',
            _dark: 'gray.800',
        },

        'ag-menu-text': {
            default: 'black',
            _dark: 'gray.100',
        },

        'ag-row-group-batteries': {
            default: 'black',
            _dark: 'black',
        },

        'batteries-button-unchecked-disabled': {
            default: 'gray.200',
            _dark: 'gray.800',
        },

        // Navigation styles
        'menu-item-hover': {
            default: 'gray.100',
            _dark: 'gray.600',
        },

        'menu-item-text': {
            default: 'gray.800',
            _dark: 'gray.100',
        },

        'menu-group-text': {
            default: 'gray.500',
            _dark: 'gray.300',
        },

        // UI components styles
        'study-summary-grid': {
            default: 'gray.600',
            _dark: 'gray.100',
        },

        'tab-list-text': {
            default: 'black',
            _dark: 'gray.300',
        },

        'tab-list-text-selected': {
            default: 'blue.600',
            _dark: 'white',
        },

        // Input fields styles
        'input-text': {
            default: 'black',
            _dark: 'white',
        },

        'input-border': {
            default: 'gray.100',
            _dark: 'gray.600',
        },

        'input-disabled-border': {
            default: 'gray.200',
            _dark: 'gray.600',
        },

        'input-arrow': {
            default: 'gray.600',
            _dark: 'gray.500',
        },

        'item-text': {
            default: 'gray.800',
            _dark: 'gray.300',
        },

        'item-selected': {
            default: 'gray.100',
            _dark: 'black',
        },

        'item-hover': {
            default: 'blue.50',
            _dark: 'gray.600',
        },

        'breadcrumb-active': {
            default: 'blue.500',
            _dark: 'white',
        },

        'basis-configurator-icon': {
            default: 'blue.500',
            _dark: 'blue.400',
        },

        // Datepicker styles
        'datepicker-border': {
            default: 'unset',
            _dark: 'gray.600',
        },

        'datepicker-header-border': {
            default: '#a4a4a4',
            _dark: 'gray.600',
        },

        'datepicker-input-border': {
            default: 'transparent',
            _dark: 'gray.300',
        },

        'datepicker-text': {
            default: 'black',
            _dark: 'gray.300',
        },

        'datepicker-text-disabled': {
            default: 'gray.300',
            _dark: 'gray.600',
        },

        'datepicker-month-selected': {
            default: 'gray.800',
            _dark: 'white',
        },

        'datepicker-yearpicker-border': {
            default: 'gray.100',
            _dark: 'gray.600',
        },

        'tab-list-gradient': {
            default: 'gray.50',
            _dark: 'black',
        },

        'background-track-element': {
            default: 'blue.100',
            _dark: 'blue.200',
        },

        // Toast colors
        'success-toast-text-color': {
            default: 'gray.800',
            _dark: 'gray.200',
        },
        'success-toast-background': {
            default: 'green.100',
            _dark: 'green.1000',
        },
        'success-toast-border-color': {
            default: 'green.500',
            _dark: 'green.500',
        },

        'error-toast-text-color': {
            default: 'gray.800',
            _dark: 'gray.200',
        },
        'error-toast-background': {
            default: 'red.200',
            _dark: 'red.700',
        },
        'error-toast-border-color': {
            default: 'red.900',
            _dark: 'red.900',
        },

        'info-toast-text-color': {
            default: 'gray.800',
            _dark: 'gray.200',
        },
        'info-toast-background': {
            default: 'blue.100',
            _dark: 'blue.600',
        },
        'info-toast-border-color': {
            default: 'blue.500',
            _dark: 'blue.500',
        },

        // Toast Error Accordion colors
        'error-error-accordion-message-text-color': {
            default: 'red.900',
            _dark: 'red.100',
        },
        'error-error-accordion-border-color': {
            default: 'gray.200',
            _dark: 'gray.200',
        },

        'warn-error-accordion-message-text-color': {
            default: 'orange.700',
            _dark: 'orange.500',
        },
        'warn-error-accordion-border-color': {
            default: 'orange.700',
            _dark: 'orange.700',
        },

        'info-error-accordion-message-text-color': {
            default: 'gray.800',
            _dark: 'gray.200',
        },
        'info-error-accordion-border-color': {
            default: 'gray.200',
            _dark: 'gray.200',
        },
    },
};

export const colors = {
    black: '#1A1A1A',
    white: '#FFFFFF',
    blue: {
        ...theme.colors.blue,
        50: '#E5F0FA',
        100: '#CCE2F6',
        200: '#99C5EC',
        400: '#006DD0',
        500: '#004D8C',
        900: '#003967',
    },
    gray: {
        ...theme.colors.gray,
        50: '#FAFAFA',
        100: '#EDF0F5',
        200: '#DEE4ED',
        300: '#C8C8CC',
        400: '#B4B9BF',
        500: '#91919A',
        600: '#646471',
        800: '#3D3D4E',
        900: '#333333',
    },
    green: {
        ...theme.colors.green,
        100: '#F3F9EC',
        200: '#E8F4D9',
        300: '#CDE5C3',
        500: '#8DC63F',
        900: '#5AB026',
        1000: '#2C6A04',
    },
    red: {
        ...theme.colors.red,
        200: '#FEF0EE',
        800: '#DB3E47',
        900: '#A71F27',
        1000: '#C43E40',
    },
    orange: {
        ...theme.colors.orange,
        10: '#FCF5EB',
        200: '#FFE1CC',
        900: '#FF6700',
        1000: '#D68916',
    },
    purple: {
        ...theme.colors.purple,
        200: '#E2D3EA',
        900: '#6F2396',
    },
    yellow: {
        ...theme.colors.yellow,
        500: '#D68916',
    },
};
