import { useRouteMatch } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import { createBiddingStrategy } from '../../services/biddingStrategies';

import InputFormikField from '../forms/InputFormikField';
import { isSubmitDisabled } from '../../services/utils';

const AddStrategyModal = ({ updateList, ...rest }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();
    const { url } = useRouteMatch();

    const formLabels = {
        description: intl.formatMessage({ id: 'bidding_strategy_name' }),
    };

    const initialFormValues = {
        description: '',
    };

    const validateName = (value) => {
        const trimmed = value.trim();

        if (!trimmed) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Bidding strategy name' });
        }

        if (trimmed.length > 100) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Bidding strategy name', lengthRule: '100 characters or less' }
            );
        }
    };

    const onFormSubmit = async ({ description }) => {
        const successMessage = intl.formatMessage({ id: 'bidding_strategies_add_new_success' });

        const data = await createBiddingStrategy({ description });

        updateList(data);

        historyPush(`${url}/${data.id}`);
        rest.onClose();
        toast(successMessage);
    };

    return (
        <Modal {...rest}>
            <ModalOverlay />

            <ModalContent pt={3}>
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="bidding_strategies_add_new" />
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik initialValues={initialFormValues} onSubmit={onFormSubmit}>
                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <ModalBody pb={10} borderBottom="1px" borderColor="border-secondary">
                                <InputFormikField
                                    name="description"
                                    type="text"
                                    label={formLabels.description}
                                    validate={validateName}
                                    isRequired
                                    isFastField
                                />
                            </ModalBody>

                            <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                <Box ml="auto">
                                    <Button variant="secondary" onClick={rest.onClose}>
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id="common_cancel" />
                                        </Box>
                                    </Button>

                                    <Button
                                        type="submit"
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        ml={3}
                                    >
                                        <FormattedMessage id="common_item_create_btn" />
                                    </Button>
                                </Box>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default AddStrategyModal;
