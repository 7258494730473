import React from 'react';
import { Box, Button, Divider } from '@chakra-ui/react';

import CustomNavLink from '../../utils/CustomNavLink';

const NavigationButton = ({ config, onClick }) => {
    return (
        <Box>
            <Button as={CustomNavLink} width="100%" exact={config.url === '/'} to={config.url} onClick={onClick}>
                {config.label}
            </Button>

            <Divider orientation="horizontal" mt="35.5px" mb="5.5px" borderColor="gray.200" />
        </Box>
    );
};

export default NavigationButton;
