const styles = {
    global: {
        body: {
            color: 'var(--chakra-colors-text-primary)',
            bg: 'var(--chakra-colors-background-primary)',
        },
        'b, strong': {
            fontWeight: '600',
        },
    },
};

export default styles;
