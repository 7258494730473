const Checkbox = {
    parts: ['container', 'control', 'label', 'icon'],

    baseStyle: {
        label: {
            _disabled: {
                opacity: 1,
                color: 'gray.500',
            },
        },
        control: {
            borderColor: 'gray.400',

            _focus: {
                borderColor: 'blue.400',
            },

            _checked: {
                bg: 'green.500',
                borderColor: 'green.500',

                _hover: {
                    bg: 'green.500',
                    color: 'white',
                    borderColor: 'green.500',
                },

                _focus: {
                    borderColor: 'green.500',
                },
            },
        },
    },
};

export default Checkbox;
