import { useCallback, useState } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import useHistoryPush from '../../hooks/useHistoryPush';

import ComboboxField from '../forms/ComboboxField';
import SecondaryButton from '../utils/SecondaryButton';

import SectionHint from './SectionHint';
import BasisConfigDropdownButtonGroup from './BasisConfigDropdownButtonGroup';

const ForwardPriceSection = ({ basis, options, updateBasis, status }) => {
    const historyPush = useHistoryPush();
    const intl = useIntl();

    const [isLoading, setIsLoading] = useState({
        refForwardOnPeakId: false,
        delForwardOnPeakId: false,
        refForwardOffPeakId: false,
        delForwardOffPeakId: false,
        refForwardATCId: false,
        delForwardATCId: false,
    });

    const getOptionState = useCallback(
        (forwardPriceId) => {
            if (forwardPriceId === null || forwardPriceId === undefined) {
                return true;
            }

            const forwardItemId = options.forwardPrices.find(
                (forwardPrice) => forwardPrice.id === forwardPriceId
            ).itemId;

            if (forwardItemId) {
                return false;
            }

            return true;
        },
        [options.forwardPrices]
    );

    const navigateToForwardItem = useCallback(
        (forwardPriceId) => {
            const search = new URLSearchParams();
            search.append('basisId', basis.basisId);

            const forwardItemId = options.forwardPrices.find(
                (forwardPrice) => forwardPrice.id === forwardPriceId
            ).itemId;

            historyPush({
                pathname: `/forward-item/${forwardItemId}`,
                search,
            });
        },
        [basis.basisId, historyPush, options.forwardPrices]
    );

    const onForwardPriceOptionChange = useCallback(
        async (field, value) => {
            setIsLoading((prev) => ({ ...prev, [field]: true }));

            await updateBasis({ ...basis, [field]: value });

            setIsLoading((prev) => ({ ...prev, [field]: false }));
        },
        [basis, updateBasis]
    );

    return (
        <>
            <SectionHint hintHeader="hint_heading_forward_price" hintText="hint_text_forward_price" />

            <SimpleGrid pb={5} px={{ base: 5, sm: 0 }} columns={{ md: 2 }} spacingX={6} spacingY={6}>
                <BasisConfigDropdownButtonGroup>
                    <ComboboxField
                        id="refForwardOnPeakId"
                        name="refForwardOnPeakId"
                        options={options.forwardPrices}
                        valueKey="id"
                        labelKey="description"
                        value={basis.refForwardOnPeakId}
                        onChange={(value) => onForwardPriceOptionChange('refForwardOnPeakId', value)}
                        label={intl.formatMessage({
                            id: 'basis_configurator_reference_forward_on_peak',
                        })}
                        showPlaceholder={true}
                        isInvalid={status.refForwardOnPeakId?.errors.length}
                        isWarning={status.refForwardOnPeakId?.warnings.length}
                        status={status.refForwardOnPeakId}
                    />

                    <SecondaryButton
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={() => navigateToForwardItem(basis.refForwardOnPeakId)}
                        w={{ sm: 'auto' }}
                        isDisabled={isLoading.refForwardOnPeakId || getOptionState(basis.refForwardOnPeakId)}
                    >
                        <FormattedMessage id="basis_configurator_view_edit" />
                    </SecondaryButton>
                </BasisConfigDropdownButtonGroup>

                <BasisConfigDropdownButtonGroup>
                    <ComboboxField
                        id="delForwardOnPeakId"
                        name="delForwardOnPeakId"
                        options={options.forwardPrices}
                        valueKey="id"
                        labelKey="description"
                        value={basis.delForwardOnPeakId}
                        onChange={(value) => onForwardPriceOptionChange('delForwardOnPeakId', value)}
                        label={intl.formatMessage({
                            id: 'basis_configurator_delivery_forward_on_peak',
                        })}
                        showPlaceholder={true}
                        isInvalid={status.delForwardOnPeakId?.errors.length}
                        isWarning={status.delForwardOnPeakId?.warnings.length}
                        status={status.delForwardOnPeakId}
                    />

                    <SecondaryButton
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={() => navigateToForwardItem(basis.delForwardOnPeakId)}
                        w={{ sm: 'auto' }}
                        isDisabled={isLoading.delForwardOnPeakId || getOptionState(basis.delForwardOnPeakId)}
                    >
                        <FormattedMessage id="basis_configurator_view_edit" />
                    </SecondaryButton>
                </BasisConfigDropdownButtonGroup>

                <BasisConfigDropdownButtonGroup>
                    <ComboboxField
                        id="refForwardOffPeakId"
                        name="refForwardOffPeakId"
                        options={options.forwardPrices}
                        valueKey="id"
                        labelKey="description"
                        value={basis.refForwardOffPeakId}
                        onChange={(value) => onForwardPriceOptionChange('refForwardOffPeakId', value)}
                        label={intl.formatMessage({
                            id: 'basis_configurator_reference_forward_off_peak',
                        })}
                        showPlaceholder={true}
                        isInvalid={status.refForwardOffPeakId?.errors.length}
                        isWarning={status.refForwardOffPeakId?.warnings.length}
                        status={status.refForwardOffPeakId}
                    />

                    <SecondaryButton
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={() => navigateToForwardItem(basis.refForwardOffPeakId)}
                        w={{ sm: 'auto' }}
                        isDisabled={isLoading.refForwardOffPeakId || getOptionState(basis.refForwardOffPeakId)}
                    >
                        <FormattedMessage id="basis_configurator_view_edit" />
                    </SecondaryButton>
                </BasisConfigDropdownButtonGroup>

                <BasisConfigDropdownButtonGroup>
                    <ComboboxField
                        id="delForwardOffPeakId"
                        name="delForwardOffPeakId"
                        options={options.forwardPrices}
                        valueKey="id"
                        labelKey="description"
                        value={basis.delForwardOffPeakId}
                        onChange={(value) => onForwardPriceOptionChange('delForwardOffPeakId', value)}
                        label={intl.formatMessage({
                            id: 'basis_configurator_delivery_forward_off_peak',
                        })}
                        showPlaceholder={true}
                        isInvalid={status.delForwardOffPeakId?.errors.length}
                        isWarning={status.delForwardOffPeakId?.warnings.length}
                        status={status.delForwardOffPeakId}
                    />

                    <SecondaryButton
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={() => navigateToForwardItem(basis.delForwardOffPeakId)}
                        w={{ sm: 'auto' }}
                        isDisabled={isLoading.delForwardOffPeakId || getOptionState(basis.delForwardOffPeakId)}
                    >
                        <FormattedMessage id="basis_configurator_view_edit" />
                    </SecondaryButton>
                </BasisConfigDropdownButtonGroup>

                <BasisConfigDropdownButtonGroup>
                    <ComboboxField
                        id="refForwardATCId"
                        name="refForwardATCId"
                        options={options.forwardPrices}
                        valueKey="id"
                        labelKey="description"
                        value={basis.refForwardATCId}
                        onChange={(value) => onForwardPriceOptionChange('refForwardATCId', value)}
                        label={intl.formatMessage({
                            id: 'basis_configurator_reference_forward_atc',
                        })}
                        showPlaceholder={true}
                        isInvalid={status.refForwardATCId?.errors.length}
                        isWarning={status.refForwardATCId?.warnings.length}
                        status={status.refForwardATCId}
                    />

                    <SecondaryButton
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={() => navigateToForwardItem(basis.refForwardATCId)}
                        w={{ sm: 'auto' }}
                        isDisabled={isLoading.refForwardATCId || getOptionState(basis.refForwardATCId)}
                    >
                        <FormattedMessage id="basis_configurator_view_edit" />
                    </SecondaryButton>
                </BasisConfigDropdownButtonGroup>

                <BasisConfigDropdownButtonGroup>
                    <ComboboxField
                        id="delForwardATCId"
                        name="delForwardATCId"
                        options={options.forwardPrices}
                        valueKey="id"
                        labelKey="description"
                        value={basis.delForwardATCId}
                        onChange={(value) => onForwardPriceOptionChange('delForwardATCId', value)}
                        label={intl.formatMessage({
                            id: 'basis_configurator_delivery_forward_atc',
                        })}
                        showPlaceholder={true}
                        isInvalid={status.delForwardATCId?.errors.length}
                        isWarning={status.delForwardATCId?.warnings.length}
                        status={status.delForwardATCId}
                    />

                    <SecondaryButton
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={() => navigateToForwardItem(basis.delForwardATCId)}
                        w={{ sm: 'auto' }}
                        isDisabled={isLoading.delForwardATCId || getOptionState(basis.delForwardATCId)}
                    >
                        <FormattedMessage id="basis_configurator_view_edit" />
                    </SecondaryButton>
                </BasisConfigDropdownButtonGroup>
            </SimpleGrid>
        </>
    );
};

export default ForwardPriceSection;
