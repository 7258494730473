import styled from '@emotion/styled/macro';

const NavigationItem = styled.li`
    display: flex;
    list-style: none;
    margin-bottom: 4px;
    border-radius: var(--chakra-radii-md);

    .active-item {
        background-color: var(--chakra-colors-blue-50);
        border-radius: var(--chakra-radii-md);
        color: var(--chakra-colors-navigation-active-item-text);

        + .ctx-menu-wrapper {
            border-top-right-radius: var(--chakra-radii-md);
            border-bottom-right-radius: var(--chakra-radii-md);
            background-color: var(--chakra-colors-blue-50);
        }
    }

    &:focus,
    &:hover,
    &:focus-within {
        background-color: var(--chakra-colors-blue-50);
        overflow: visible;
        box-shadow: var(--chakra-shadows-outline);

        .icons-wrapper {
            display: inline-grid;
        }
    }

    &:hover p {
        color: var(--chakra-colors-blue-400);
    }
`;

export default NavigationItem;
