import FormField from './FormField';

import NumberInput from './NumberInput';

// If you need to extend NumberInput functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for NumberInput should be excluded from ...props and manually added
const NumberInputField = ({
    placeholder,
    precision,
    step,
    min,
    max,
    value,
    isInvalid,
    isWarning,
    onChange,
    hasStepperButtons,
    rightElement,
    leftElement,
    isRequired,
    ...props
}) => {
    const regex = new RegExp('^((-)?(.)?)$');
    const regexForBlur = new RegExp('^([-.])+$');

    const onNumberInputBlur = (event) => {
        const currentVal = event.target.value;
        //Prevent any combination of '-' and '.' as value in the control when user clicks away from the control
        if (regexForBlur.test(currentVal)) {
            event.preventDefault(); //prevents the usual behaviour of 'OnBlur' event
            onChange(''); //Clears the value if the value is just '-' or '.' when user moves away from the control
        }
    };

    return (
        <FormField isInvalid={isInvalid} isRequired={isRequired} {...props}>
            <NumberInput
                // zDefaultValue could be returned and Chakra uses toString(value)
                value={Number.isNaN(Number.parseFloat(value)) && !regex.test(value) ? '' : value} //regex to allow for values starting with '-' , '.' and '-.'
                onChange={onChange}
                onBlur={onNumberInputBlur}
                placeholder={placeholder}
                precision={precision}
                step={step}
                max={max}
                min={min}
                isWarning={isWarning}
                hasStepperButtons={hasStepperButtons}
                rightElement={rightElement}
                leftElement={leftElement}
                isRequired={isRequired}
            />
        </FormField>
    );
};

export default NumberInputField;
