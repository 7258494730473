import { Heading, Flex, Icon, Box } from '@chakra-ui/react';
import React from 'react';
import styled from '@emotion/styled/macro';

const Tile = ({ label, onClick, leftIcon, rightIcon, labelHelper, tileSpacing = 4 }) => {
    return (
        <StyledFlex onClick={onClick} tileSpacing={tileSpacing} data-comp="tile">
            {leftIcon ? <StyledLeftIcon as={leftIcon} /> : null}

            <Box flexGrow={1}>
                <Heading as="h4" variant="h4" color="black">
                    {label}
                </Heading>
                <div>{labelHelper}</div>
            </Box>

            {rightIcon ? <StyledRightIcon as={rightIcon} /> : null}
        </StyledFlex>
    );
};

const StyledFlex = styled(Flex, { shouldForwardProp: (prop) => prop !== 'tileSpacing' })`
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--chakra-shadows-xs);
    border: 1px solid var(--chakra-colors-border-secondary);
    border-radius: 6px;
    padding: 18px 24px;

    &:hover {
        box-shadow: var(--chakra-shadows-md);
        cursor: pointer;
    }

    & ~ [data-comp='tile'] {
        margin-top: ${(props) =>
            typeof props.tileSpacing === 'string' ? props.tileSpacing : `var(--chakra-space-${props.tileSpacing})`};
    }
`;

const StyledLeftIcon = styled(Icon)`
    background: none;
    height: 44px;
    width: 44px;
    margin-right: var(--chakra-space-4);

    path {
        fill: var(--chakra-colors-blue-200);
    }
`;

const StyledRightIcon = styled(Icon)`
    color: var(--chakra-colors-gray-600);
    width: 40px;
    height: 40px;
    padding: var(--chakra-space-2);
    border-radius: 50%;
    background-color: var(--chakra-colors-gray-100);
    margin-left: var(--chakra-space-4);
`;

export default Tile;
