import React from 'react';

import { Box, useMediaQuery } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import Footer from './Footer';
import { useRef, useState, useEffect } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from 'constants/local-storage';

const LayoutWrapper = ({
    sidebar,
    content,
    backgroundColor,
    hasFooter = false,
    hasSecondaryMenu = false,
    minSidebarWidth = 300,
    children,
}) => {
    const wrapperRef = useRef();
    const sidebarRef = useRef();
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const [sidebarWidth, setSidebarWidth] = useLocalStorage(LOCAL_STORAGE_KEYS.SIDEBAR_WIDTH, minSidebarWidth);
    const [dragging, setDragging] = useState(false);

    const onMove = (clientX) => {
        if (dragging && sidebarRef?.current) {
            if (clientX < minSidebarWidth) {
                setSidebarWidth(minSidebarWidth);
                return;
            }

            if (wrapperRef?.current) {
                const width = wrapperRef.current?.clientWidth;
                if (clientX > width - minSidebarWidth) {
                    setSidebarWidth(width - minSidebarWidth);
                    return;
                }
                setSidebarWidth(clientX);
            }
        }
    };

    const onMouseMove = (e) => {
        e?.preventDefault();
        onMove(e?.clientX);
    };

    const onMouseUp = () => {
        setDragging(false);
    };

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    });

    useEffect(() => {
        if (sidebarRef?.current && isDesktop) {
            if (!sidebarWidth) {
                setSidebarWidth(sidebarRef.current?.clientWidth);
                return;
            }

            if (sidebarWidth < minSidebarWidth) {
                setSidebarWidth(minSidebarWidth);
                return;
            }

            sidebarRef.current.style.width = `${sidebarWidth}px`;
        }
    }, [sidebarRef, sidebarWidth, setSidebarWidth, minSidebarWidth, isDesktop]);

    return (
        <Wrapper ref={wrapperRef} hasSecondaryMenu={hasSecondaryMenu} backgroundColor={backgroundColor}>
            {sidebar && (
                <Box ref={sidebarRef} position="relative" w={{ base: 'full', md: '480px', lg: '550px', xl: '700px' }}>
                    {sidebar}
                    {isDesktop && (
                        <Box
                            position="absolute"
                            right={0}
                            top={0}
                            bottom={0}
                            pl={1}
                            h="full"
                            cursor="ew-resize"
                            onMouseDown={() => setDragging(true)}
                            onMouseUp={onMouseUp}
                        >
                            <Box w="2px" bg="slate.500" h="full" />
                        </Box>
                    )}
                </Box>
            )}

            <MainContent>
                {content}

                {hasFooter && (
                    <Box mt="auto" mb="0">
                        <Footer />
                    </Box>
                )}
            </MainContent>

            {children}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: ${({ hasSecondaryMenu }) => (hasSecondaryMenu ? '139px' : '75px')};
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    background: ${(props) =>
        props.backgroundColor ? `${props.backgroundColor}` : 'var(--chakra-colors-background-primary)'};

    @media (min-width: 769px) {
        overflow-y: initial;
        flex-direction: row;
    }
`;

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (min-width: 769px) {
        overflow-y: auto;
    }
`;

export default LayoutWrapper;
