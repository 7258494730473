import styled from '@emotion/styled/macro';

const ActionBar = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding: 12px 24px;
    gap: 16px;

    > button {
        text-transform: capitalize;
        width: 100%;
    }

    button:first-of-type {
        margin-top: 4px;
    }

    @media (min-width: 769px) {
        button:first-of-type {
            margin-top: 0px;
        }
    }

    @media (min-width: 1280px) {
        flex-direction: row;
        button {
            width: auto;
        }
    }
`;

export default ActionBar;
