import { useEffect, useState } from 'react';
import Papa from 'papaparse';

import { removeTrailingNewLine } from '../services/items';

const useTabDelimitedParser = ({ value, columns, onStart, onComplete }) => {
    const [parsed, setParsed] = useState([]);

    useEffect(() => {
        if (value.trim().length) {
            onStart();

            const header = columns
                .map((col) => col.field)
                .join('\t')
                .concat('\n');

            Papa.parse(header + value, {
                header: true,
                skipEmptyLines: 'greedy', // Greedy remove empty lines
                worker: true,
                delimiter: '\t',

                complete({ data }) {
                    // Papa parse leaves \r on random at the end of some fields e.g.(...T14:48:00.000Z\r)
                    const parsed = removeTrailingNewLine(data);

                    onComplete(parsed);
                    setParsed(parsed);
                },
            });
        } else {
            onComplete([]);
            setParsed([]);
        }
    }, [columns, value, onStart, onComplete]);

    return parsed;
};

export default useTabDelimitedParser;
