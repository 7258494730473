import { FormattedMessage } from 'react-intl';
import { Flex, Button } from '@chakra-ui/react';

// cellRenderer: 'ViewJobLogsCell' used in JobsGrid
const ViewJobLogsCell = ({ node, onClick }) => {
    const { jobId } = node.data;
    return (
        <Flex justifyContent="center" alignItems="center" w="100%">
            <Button variant="secondary-special" textTransform="capitalize" onClick={() => onClick(jobId)}>
                <FormattedMessage id="common_view" />
            </Button>
        </Flex>
    );
};

export default ViewJobLogsCell;
