import _ from 'lodash';
import { colors } from 'theme/colors';

const getDefaultOptions = (intl) => {
    return {
        colors: [colors.green['500']],
        chart: {
            zoomType: 'xy',
            panning: {
                enabled: true,
                type: 'y',
            },
            panKey: 'alt',
            resetZoomButton: {
                theme: {
                    fill: colors.gray['100'],
                    stroke: 'transparent',
                    r: '4px',
                    style: {
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: '500',
                    },
                    states: {
                        hover: {
                            fill: colors.gray['200'],
                            style: {
                                color: colors.gray['900'],
                            },
                        },
                    },
                },
            },
        },
        scrollbar: {
            liveRedraw: false,
        },
        lang: {
            noData: intl.formatMessage({ id: 'common_no_data_found' }),
        },
        navigator: {
            adaptToUpdatedData: false,
        },
        tooltip: {
            formatter: function (tooltip) {
                let result = tooltip.defaultFormatter.call(this, tooltip);
                return result;
            },
        },
        rangeSelector: {
            enabled: true,
            inputEnabled: false,
        },
        xAxis: {
            minRange: 3600000, // 1 hour zoom precision (user can't zoom in more than 1 hour ticks)
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        yAxis: {
            opposite: false,
            title: {
                style: {
                    fontWeight: '500',
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                },
            },
            labels: {
                y: 5,
                style: {
                    fontSize: '14px',
                },
            },
        },
    };
};

export const getThemedOptions = (colorMode) => {
    const isDarkMode = colorMode === 'dark';
    return {
        chart: {
            backgroundColor: isDarkMode ? colors.black : colors.white,
        },
        rangeSelector: {
            labelStyle: {
                color: isDarkMode ? colors.gray[500] : colors.black,
            },
        },
        xAxis: {
            lineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
            gridLineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
            minorGridLineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
            minorTickColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
            tickColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
            labels: {
                style: {
                    color: isDarkMode ? `${colors.gray[500]}` : `${colors.black}`,
                },
            },
        },
        yAxis: {
            gridLineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
            minorGridLineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
            minorTickColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
            lineColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
            tickColor: isDarkMode ? colors.gray[300] : '#e6e6e6',
            title: {
                style: {
                    color: isDarkMode ? colors.gray[500] : colors.black,
                },
            },
            labels: {
                style: {
                    color: isDarkMode ? colors.gray[500] : colors.black,
                },
            },
        },
    };
};

export const buildChartOptions = (options, config, refetchData, intl, colorMode) => {
    let opts = JSON.parse(JSON.stringify(options));

    if (config?.extendDefaultOptions) {
        const defaultOptions = getDefaultOptions(intl);
        const themedOptions = getThemedOptions(colorMode);
        opts = _.merge(defaultOptions, themedOptions, opts);
    }

    if (typeof refetchData === 'function') {
        const events = {
            xAxis: {
                events: {
                    setExtremes(event) {
                        if (event.min !== undefined && event.max !== undefined) {
                            const start = new Date(Math.round(event.min));
                            const end = new Date(Math.round(event.max));
                            refetchData({ start, end });
                        } else {
                            refetchData();
                        }
                    },
                },
            },
        };
        return _.merge(opts, events);
    }

    return opts;
};

export const chartHasSeriesData = (options) => {
    return options?.series?.[0]?.data?.length > 0;
};
