import { useState, useEffect } from 'react';

export const useFirstVisibleElement = (observedElementsProvider, visibleElementCallback) => {
    const [firstVisibleElement, setFirstVisibleElement] = useState(null);

    useEffect(() => {
        visibleElementCallback(firstVisibleElement);
    }, [visibleElementCallback, firstVisibleElement]);

    useEffect(() => {
        const observedElements = observedElementsProvider();
        const visibilityByElement = new Map();

        function manageVisibility(entries) {
            for (const entry of entries) {
                visibilityByElement.set(entry.target, entry.isIntersecting);
            }
        }

        function manageFirstVisibleElement() {
            const visibleElements = Array.from(visibilityByElement.entries())
                .filter(([, value]) => value)
                .map(([key]) => key);

            setFirstVisibleElement(visibleElements[0] ?? null);
        }

        const observer = new IntersectionObserver(
            (entries) => {
                manageVisibility(entries);
                manageFirstVisibleElement();
            },
            {
                threshold: [0.0, 1.0],
            }
        );

        observedElements.forEach((element) => {
            visibilityByElement.set(element, false);
            observer.observe(element);
        });

        return () => observer.disconnect();
    }, [observedElementsProvider, visibleElementCallback]);
};

export default useFirstVisibleElement;
