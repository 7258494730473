import { useState, useEffect } from 'react';

const useConnectionSearch = () => {
    const [searchCollection, setSearchCollection] = useState([]); // Remove collection if connection state is moved to Redux

    const [search, setSearch] = useState({
        term: '',
        results: [],
    });

    useEffect(() => {
        if (search.term.length === 0) {
            setSearch((prev) => ({ ...prev, results: [] }));
            return;
        }

        const term = search.term.trimStart().toLowerCase();
        const filtered = searchCollection.filter((o) => o.title.toLowerCase().includes(term));

        if (search.term.length > 0) {
            setSearch((prev) => ({ ...prev, results: filtered }));
        }
    }, [searchCollection, search.term]);

    // Utilities
    const clearSearch = () => setSearch({ term: '', results: [] });
    const noSearchResultsFound = search.results.length === 0 && search.term.length > 0;
    const searchResultsFound = search.results.length > 0;

    return {
        search,
        setSearch,
        setSearchCollection,
        clearSearch,
        noSearchResultsFound,
        searchResultsFound,
    };
};

export default useConnectionSearch;
