import { useSelector } from 'react-redux';

export { FEATURE_FLAG_NAMES } from '../constants/featureFlags';

export const useIsFeatureActive = (name) => {
    const featureFlags = useSelector((state) => state.featureFlags.features);

    const curFeature = featureFlags?.find((feature) => feature.featureCode === name);

    if (curFeature) return curFeature.isActive;

    return false;
};
