import { useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import useAutoformMetadata from './hooks/useAutoformMetadata';

import AutoformTabs from './AutoformTabs';
import AutoformTableContainer from './AutoformTableContainer';
import AutoformItemFields from './AutoformItemFields';
import ItemTopHeaderContainer from './ItemTopHeaderContainer';
import ItemFormContainer from './ItemFormContainer';
import MisconfigurationError from './MisconfigurationError';
import GeneralValidationPanelContainer from './GeneralValidationPanelContainer';
import AutoformDownloadButton from './AutoformDownloadButton';
import AutoformUploadButton from './AutoformUploadButton';

import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import { getInputFormName } from './utils/autoformUtils';

import SortOrderProvider from '../grid/utils/SortOrderContext';
import { useMemo, useState } from 'react';
import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import useAutoformBulkUpdate from './hooks/useAutoformBulkUpdate';

/**
 * UI For right panel of level 2 autoforms.
 *
 * @param {object} portfolioItem - Item in the portfolios screen.
 * @param {callback} onNodeRename - Optional. Callback to rename the portfolio item in the portfolios screen.
 * @param {callback} onNodeItemDelete - Optional. Callback to delete the portfolio item in the portfolios screen.
 * @param {callback} onNodeOwnershipPercentageUpdate - Optional. Callback to change the percent ownership
 * of the item in the portfolio in the portfolios screen.
 * @param {object} config - containing config for this and nested components
 */
const Level2Autoform = ({
    onNodeRename,
    onNodeItemDelete,
    portfolioItem,
    onNodeOwnershipPercentageUpdate,
    config = {},
    helpPageUrlItemTypeId = null,
}) => {
    const { metadata, hierarchicalTables } = useAutoformMetadata();

    const level1TableId = useMemo(() => hierarchicalTables.level1[0]?.id, [hierarchicalTables.level1]);
    const { selectedItem: parentRecordId } = useAutoformTableMetadata(level1TableId);

    const [selectedTable, setSelectedTable] = useState(null);

    const { itemFormContainerConfig, handleDependentDataChange } = useAutoformBulkUpdate({
        tableId: selectedTable?.id ?? hierarchicalTables.level2[0]?.id,
        parentRecordId,
        autoformConfig: config,
    });

    const onTabOpen = useCallback(
        (index) => setSelectedTable(hierarchicalTables.level2[index]),
        [hierarchicalTables.level2]
    );

    if (!metadata || hierarchicalTables.level1.length === 0 || hierarchicalTables.level2.length === 0) {
        return <MisconfigurationError />;
    }

    return (
        <SortOrderProvider>
            <ExpandableItemWrapper>
                <ItemFormContainer onNodeRename={onNodeRename} config={itemFormContainerConfig}>
                    {({ setValues, setTouched, status, setStatus }) => (
                        <>
                            <ItemTopHeaderContainer
                                tableId={level1TableId}
                                onNodeItemDelete={onNodeItemDelete}
                                portfolioItem={portfolioItem}
                                onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                                config={config}
                            />

                            <Box px={6}>
                                <GeneralValidationPanelContainer tableId={level1TableId} />
                                {config?.level1InputFields ?? (
                                    <AutoformItemFields tableId={level1TableId} getInputFormName={getInputFormName} />
                                )}

                                <AutoformTabs
                                    onTabOpen={onTabOpen}
                                    renderTabPanel={(table) => (
                                        <AutoformTableContainer
                                            schemaCode={metadata.schemaCode}
                                            tableId={table.id}
                                            showDownloadUploadButton={hierarchicalTables.level2.length === 1}
                                            onActionInitiated={() =>
                                                handleDependentDataChange({ setValues, setTouched, status, setStatus })
                                            }
                                            config={config}
                                            helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                                        />
                                    )}
                                    tabListButtons={
                                        <Flex ml="auto" order={{ base: -1, xl: 0 }} w={{ base: '100%', xl: 'auto' }}>
                                            <AutoformDownloadButton
                                                mr={2}
                                                tablesMetadata={hierarchicalTables.level2}
                                                parentTableId={level1TableId}
                                            />
                                            {!!hierarchicalTables.level2.find((table) => table.canCreate) && (
                                                <AutoformUploadButton
                                                    tablesMetadata={hierarchicalTables.level2}
                                                    parentTableId={level1TableId}
                                                />
                                            )}
                                        </Flex>
                                    }
                                    tables={hierarchicalTables.level2}
                                />
                            </Box>
                        </>
                    )}
                </ItemFormContainer>
            </ExpandableItemWrapper>
        </SortOrderProvider>
    );
};

export default Level2Autoform;
