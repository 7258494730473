import axios from 'axios';

export const getHydroTree = async () => {
    const response = await axios.get('/hydro/getall');

    return mapHydroTree(response.data);
};

const getIdPropName = (data) => {
    for (const keyName of ['itemId', 'unitId', 'HYDROUNITRESOURCEID']) {
        if (data.hasOwnProperty(keyName)) {
            return keyName;
        }
    }
};

export const getItemId = (hydroTreeData, plantUnitId) => {
    for (let i = 0; i < hydroTreeData?.length; i++) {
        const item = hydroTreeData[i];
        for (let j = 0; j < item?.nodes?.length; j++) {
            const unit = item.nodes[j];
            for (let k = 0; k < unit?.nodes?.length; k++) {
                const id = unit.nodes[k]?.properties?.id;
                if (id === plantUnitId) {
                    return item?.properties?.id;
                }
            }
        }
    }
};

export const mapHydroTree = (data, name) => {
    return data.map((item) => {
        const description = item.description ?? item.DESCRIPTION;
        const path = name ? name + ' > ' + description : description;

        const nodes = item.units
            ? mapHydroTree(item.units, path)
            : item.resources
            ? mapHydroTree(item.resources, path)
            : [];

        const idKey = getIdPropName(item);

        return {
            id: `${idKey}_${item[idKey]}`,
            properties: {
                id: item[idKey],
                isItem: item.hasOwnProperty('itemId'),
                isPlant: item.hasOwnProperty('unitId'),
                isPlantUnit: item.hasOwnProperty('HYDROUNITRESOURCEID'),
                name: description,
                isMaster: item.securityId,
            },
            path: name ?? '',
            nodes,
        };
    });
};

export const filterNodesByName = (nodes, term) => {
    const stack = [...nodes];
    const matches = [];

    while (stack.length > 0) {
        const node = stack.pop();

        if (`${node.properties.name}`.toLowerCase().includes(term)) {
            matches.push(node);
        } else {
            stack.push(...node.nodes);
        }
    }

    return matches;
};

export const removeTreeNode = (nodes, nodeId) => {
    return nodes
        .map(({ nodes: children, ...props }) => ({
            ...props,
            nodes: removeTreeNode(children, nodeId),
        }))
        .filter((item) => item.properties.id !== nodeId);
};
