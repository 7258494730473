import { useState, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Box,
    Flex,
    Divider,
    Heading,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Radio,
    Text,
    SimpleGrid,
    useMediaQuery,
    useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form, setNestedObjectValues } from 'formik';
import { set, isBefore, isAfter, isEqual } from 'date-fns';

import {
    createSubBattery,
    createSubBatteryFromWizard,
    validateSubBattery,
    validateSubBatteryFromWizard,
} from '../../services/batteries';
import { isSubmitDisabled } from '../../services/utils';
import { isNumberCellEmpty } from '../../services/items';
import { mapGeneralValidationMessages } from '../autoform/utils/autoformUtils';

import useCommonToast from '../../hooks/useCommonToast';

import GeneralFormValidationsAlert from '../forms/GeneralFormValidationsAlert';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import DateFormikField from '../forms/DateFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';
import RadioGroupFormikField from '../forms/RadioGroupFormikField';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';
import { helpAnchors } from 'constants/help';

import styled from '@emotion/styled/macro';

const AddSubBatteryModal = ({ headerItem, batteryTypes, batteryItemId, updateBatteries, ...rest }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const errorsDisclosure = useDisclosure({ defaultIsOpen: true });
    const warningsDisclosure = useDisclosure({ defaultIsOpen: true });

    const [wizardMode, setWizardMode] = useState(true); // 'wizard' holds the complete setup, 'basic' holds two only fields
    const [isValidating, setIsValidating] = useState(false);
    const [generalErrors, setGeneralErrors] = useState([]);
    const [generalWarnings, setGeneralWarnings] = useState([]);
    const [globalErrors, setGlobalErrors] = useState([]);
    const [globalWarnings, setGlobalWarnings] = useState([]);

    const allErrors = useMemo(() => [...globalErrors, ...generalErrors], [generalErrors, globalErrors]);
    const allWarnings = useMemo(() => [...globalWarnings, ...generalWarnings], [generalWarnings, globalWarnings]);

    const formLabels = {
        description: intl.formatMessage({ id: 'batteries_sub_battery_name_label' }),
        batteryType: intl.formatMessage({ id: 'batteries_battery_type' }),
        startDate: intl.formatMessage({ id: 'common_grid_start_date' }),
        endDate: intl.formatMessage({ id: 'common_grid_end_date' }),
        mwPower: intl.formatMessage({ id: 'batteries_battery_wizard_mw_power' }),
        hrDuration: intl.formatMessage({ id: 'batteries_battery_wizard_hr_duration' }),
        chargeEfficiency: intl.formatMessage({ id: 'batteries_battery_wizard_charge_efficiency' }),
        dischargeEfficiency: intl.formatMessage({ id: 'batteries_battery_wizard_discharge_efficiency' }),
        vom: intl.formatMessage({ id: 'batteries_battery_wizard_vom_discharging' }),
        fixedCost: intl.formatMessage({ id: 'batteries_battery_wizard_fixed_costs' }),
        annualCycleLimit: intl.formatMessage({ id: 'batteries_battery_wizard_annual_cycle_limit' }),
        costlessAdder: intl.formatMessage({ id: 'batteries_battery_wizard_costless_adder' }),
        minAverageSOC: intl.formatMessage({ id: 'batteries_battery_wizard_min_average_soc' }),
        maxAverageSOC: intl.formatMessage({ id: 'batteries_battery_wizard_max_average_soc' }),
        emptyPlaceholder: '',
    };

    const initialFormValues = {
        description: '',
        batteryTypeId: '',
        itemId: parseInt(batteryItemId), // the parent battery item id
        startDate: '',
        endDate: '',
        mwPower: '',
        hrDuration: '',
        chargeEfficiency: '',
        dischargeEfficiency: '',
        vom: '',
        fixedCost: '',
        annualCycleLimit: '',
        costlessAdder: '',
        minAverageSOC: 0,
        maxAverageSOC: 100,
        singlePeriodMode: '0',
    };

    const validateDescription = (value) => {
        const trimmed = value.trim();

        if (!trimmed) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label: formLabels.description });
        }

        if (trimmed.length > 50) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: formLabels.description, lengthRule: '50 characters or less' }
            );
        }
    };

    const validateRequiredField = (value, label) => {
        if (value === '') {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }
    };

    const validateSiblingDate = ({ value, siblingValue, type }) => {
        // when you delete the contents of the datepicker the value is null or empty string
        const isInvalidValue = value === null || value === '';
        const isInvalidSiblingValue = siblingValue === null || siblingValue === '';

        if (isInvalidValue || (isInvalidValue && isInvalidSiblingValue)) {
            // show an error if the value is invalid or both the value and the sibling value are invalid
            return intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: type === 'start' ? formLabels.startDate : formLabels.endDate }
            );
        }

        // skip validation on invalid sibling
        if (isInvalidSiblingValue) {
            return null;
        }

        const dateA = set(value, { seconds: 0, milliseconds: 0 });
        const dateB = set(siblingValue, { seconds: 0, milliseconds: 0 });

        if (type === 'start' && (isAfter(dateA, dateB) || isEqual(dateA, dateB))) {
            return intl.formatMessage({ id: 'batteries_common_modals_date_smaller' });
        } else if (type === 'end' && (isBefore(dateA, dateB) || isEqual(dateA, dateB))) {
            return intl.formatMessage({ id: 'batteries_common_modals_date_bigger' });
        }

        return null;
    };

    const validateForm = (values) => {
        const description = validateDescription(values.description);

        if (!wizardMode) {
            return {
                ...(description ? { description } : null),
            };
        }

        const startDate = validateSiblingDate({
            value: values.startDate,
            siblingValue: values.endDate,
            type: 'start',
        });

        const endDate = validateSiblingDate({
            value: values.endDate,
            siblingValue: values.startDate,
            type: 'end',
        });

        const mwPower = validateRequiredField(values.mwPower, formLabels.mwPower);
        const hrDuration = validateRequiredField(values.hrDuration, formLabels.hrDuration);
        const chargeEfficiency = validateRequiredField(values.chargeEfficiency, formLabels.chargeEfficiency);
        const dischargeEfficiency = validateRequiredField(values.dischargeEfficiency, formLabels.dischargeEfficiency);

        return {
            ...(description ? { description } : null),
            ...(startDate ? { startDate } : null),
            ...(endDate ? { endDate } : null),
            ...(mwPower ? { mwPower } : null),
            ...(hrDuration ? { hrDuration } : null),
            ...(chargeEfficiency ? { chargeEfficiency } : null),
            ...(dischargeEfficiency ? { dischargeEfficiency } : null),
        };
    };

    const formatFieldValues = useCallback((values) => {
        return {
            itemId: values.itemId,
            description: values.description,
            startDate: values.startDate || null,
            endDate: values.endDate || null,
            mwPower: isNumberCellEmpty(values.mwPower),
            hrDuration: isNumberCellEmpty(values.hrDuration),
            chargeEfficiency: isNumberCellEmpty(values.chargeEfficiency),
            dischargeEfficiency: isNumberCellEmpty(values.dischargeEfficiency),
            vom: isNumberCellEmpty(values.vom),
            fixedCost: isNumberCellEmpty(values.fixedCost),
            annualCycleLimit: isNumberCellEmpty(values.annualCycleLimit),
            costlessAdder: isNumberCellEmpty(values.costlessAdder),
            minAverageSOC: isNumberCellEmpty(values.minAverageSOC),
            maxAverageSOC: isNumberCellEmpty(values.maxAverageSOC),
            singlePeriodMode: values.singlePeriodMode,
        };
    }, []);

    const resetServerValidation = useCallback(() => {
        setGeneralErrors([]);
        setGeneralWarnings([]);
        setGlobalErrors([]);
        setGlobalWarnings([]);
    }, []);

    const onFormSubmit = async (values, { setErrors, setStatus }) => {
        try {
            const successMessage = intl.formatMessage({ id: 'common_item_creation_success' }, { type: headerItem });

            const payload = formatFieldValues(values);

            const { data } = wizardMode
                ? await createSubBatteryFromWizard(payload)
                : await createSubBattery({
                      description: values.description,
                      batteryTypeId: values.batteryTypeId || null,
                      itemId: values.itemId,
                  });

            updateBatteries(data.subBatteryResponse, payload.itemId);
            rest.onClose();
            toast(successMessage);
        } catch (err) {
            // reset the previous general errors and warnings
            resetServerValidation();

            // make sure to re-open the disclosures again,
            // because the user might have closed them and is submitting the form again
            errorsDisclosure.onOpen();
            warningsDisclosure.onOpen();

            if (err.response.status !== 500) {
                if (err.response.data.validationResult.issues) {
                    // when creating a single record we expect at most only 1 issue
                    const issue = err.response.data.validationResult.issues[0];

                    // handle general errors and warnings
                    const { warnings, errors } = mapGeneralValidationMessages(issue.generalValidationMessages);
                    setGeneralWarnings(warnings);
                    setGeneralErrors(errors);

                    // handle specific field errors
                    let fieldErrors = {};
                    let fieldStatus = {};

                    for (const field of issue.fields) {
                        const errors = field.validationMessages
                            .filter(({ severity }) => severity === 'Error')
                            .map(({ message }) => message);

                        const warnings = field.validationMessages
                            .filter(({ severity }) => severity === 'Warning')
                            .map(({ message }) => message);
                        fieldStatus[field.name] = { errors, warnings };
                    }

                    setErrors(fieldErrors);
                    setStatus(fieldStatus);
                }

                if (err.response.data.validationResult.globalIssues) {
                    // handle global errors and warnings
                    const { warnings, errors } = mapGeneralValidationMessages(
                        err.response.data.validationResult.globalIssues
                    );
                    setGlobalWarnings(warnings);
                    setGlobalErrors(errors);
                }
            }
        }
    };

    const onServerValidate = useCallback(
        async (values, setErrors, setStatus, setTouched, validateForm) => {
            const errors = await validateForm(values);

            // reset the previous general errors and warnings
            resetServerValidation();

            if (Object.keys(errors).length > 0) {
                setTouched(setNestedObjectValues(errors, true));
            } else {
                try {
                    setIsValidating(true);

                    const payload = formatFieldValues(values);

                    const data = wizardMode ? await validateSubBatteryFromWizard(payload) : validateSubBattery(payload);

                    // make sure to re-open the disclosure again
                    warningsDisclosure.onOpen();

                    if (data.issues) {
                        // reset the previous general warnings
                        setGeneralWarnings([]);

                        // when creating a single record we expect at most only 1 issue
                        const issue = data.issues[0];

                        // handle general warnings
                        const { warnings } = mapGeneralValidationMessages(issue.generalValidationMessages);
                        setGeneralWarnings(warnings);

                        // handle specific field warnings
                        let fieldStatus = {};

                        for (const field of issue.fields) {
                            const warnings = field.validationMessages
                                .filter(({ severity }) => severity === 'Warning')
                                .map(({ message }) => message);

                            fieldStatus[field.name] = { warnings };
                        }

                        setStatus(fieldStatus);
                        setTouched(setNestedObjectValues(fieldStatus, true));
                    }

                    if (data.globalIssues) {
                        // handle global warnings
                        const { warnings } = mapGeneralValidationMessages(data.globalIssues);
                        setGlobalWarnings(warnings);
                    }

                    if (!data) {
                        toast(intl.formatMessage({ id: 'common_check_data_success' }));
                    }
                } catch (err) {
                    // make sure to re-open the disclosures again,
                    // because the user might have closed them and is submitting the form again
                    errorsDisclosure.onOpen();
                    warningsDisclosure.onOpen();

                    if (err.response?.status !== 500) {
                        if (err.response?.data?.issues) {
                            // when creating a single record we expect at most only 1 issue
                            const issue = err.response.data.issues[0];

                            // handle general errors and warnings
                            const { warnings, errors } = mapGeneralValidationMessages(issue.generalValidationMessages);
                            setGeneralWarnings(warnings);
                            setGeneralErrors(errors);

                            // handle specific field errors
                            let fieldErrors = {};
                            let fieldStatus = {};

                            for (const field of issue.fields) {
                                const errors = field.validationMessages
                                    .filter(({ severity }) => severity === 'Error')
                                    .map(({ message }) => message);

                                const warnings = field.validationMessages
                                    .filter(({ severity }) => severity === 'Warning')
                                    .map(({ message }) => message);
                                fieldStatus[field.name] = { errors, warnings };
                            }

                            setErrors(fieldErrors);
                            setStatus(fieldStatus);
                            setTouched(setNestedObjectValues(fieldStatus, true));
                        }

                        if (err.response?.data?.globalIssues) {
                            // handle global errors and warnings
                            const { warnings, errors } = mapGeneralValidationMessages(err.response.data.globalIssues);
                            setGlobalWarnings(warnings);
                            setGlobalErrors(errors);
                        }
                    }
                } finally {
                    setIsValidating(false);
                }
            }
        },
        [intl, toast, errorsDisclosure, warningsDisclosure, wizardMode, formatFieldValues, resetServerValidation]
    );

    return (
        <Modal {...rest}>
            <ModalOverlay />

            <ModalContent pt={3} borderRadius={isDesktop ? '2xl' : 0}>
                <ModalHeader>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        {wizardMode ? (
                            <FormattedMessage id="batteries_battery_wizard_title" />
                        ) : (
                            <FormattedMessage
                                id="common_add_subitem_modal_heading"
                                values={{ item: formLabels.description }}
                            />
                        )}
                        <HelpButtonComponent helpAnchor={helpAnchors.BATTERY_NEW_SETUP} />
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <ModalBody borderTop="1px" borderColor="gray.200">
                    {wizardMode && (
                        <Box pt={3} pb={6}>
                            <FormattedMessage id="batteries_battery_wizard_explanation" />
                        </Box>
                    )}

                    <Formik
                        enableReinitialize
                        initialValues={initialFormValues}
                        onSubmit={onFormSubmit}
                        validate={validateForm}
                    >
                        {({ values, isSubmitting, errors, touched, setErrors, setStatus, setTouched }) => {
                            const isDisabled = isSubmitDisabled({ errors, touched });

                            return (
                                <Form noValidate>
                                    {allErrors.length > 0 && (
                                        <GeneralFormValidationsAlert
                                            isOpen={errorsDisclosure.isOpen}
                                            onClose={errorsDisclosure.onClose}
                                            messages={allErrors}
                                        />
                                    )}

                                    {allWarnings.length > 0 && (
                                        <GeneralFormValidationsAlert
                                            status="warning"
                                            title={<FormattedMessage id="common_warning" />}
                                            isOpen={warningsDisclosure.isOpen}
                                            onClose={warningsDisclosure.onClose}
                                            messages={allWarnings}
                                        />
                                    )}

                                    <SimpleGrid columns={1} spacingY={3} pt={!wizardMode && 3} pb={3}>
                                        <InputFormikField
                                            name="description"
                                            type="text"
                                            label={formLabels.description}
                                            isRequired
                                        />

                                        {!wizardMode && (
                                            <ComboboxFormikField
                                                id="batteryTypeId"
                                                name="batteryTypeId"
                                                label={formLabels.batteryType}
                                                options={batteryTypes}
                                                valueKey="id"
                                                labelKey="description"
                                                showPlaceholder={true}
                                            />
                                        )}
                                    </SimpleGrid>

                                    {wizardMode && (
                                        <>
                                            <Flex>
                                                <FormattedMessage id="batteries_battery_wizard_date_mode" />
                                                <Text ml={4} color="grey">
                                                    <FormattedMessage id="batteries_battery_wizard_date_mode_note" />
                                                </Text>
                                            </Flex>

                                            <RadioGroupFormikField mx={0} mb={3} id="dateMode" name="singlePeriodMode">
                                                <HStack spacing={8}>
                                                    <StyledRadio value={'0'}>{'Monthly'}</StyledRadio>
                                                    <StyledRadio value={'1'}>{'Single date period'}</StyledRadio>
                                                </HStack>
                                            </RadioGroupFormikField>

                                            <SimpleGrid columns={2} gap={6} py={3}>
                                                <DateFormikField
                                                    id="startDate"
                                                    name="startDate"
                                                    label={formLabels.startDate}
                                                    isRequired
                                                    showMonthYearPicker
                                                    showTimeInput={false}
                                                    info={
                                                        <FormattedMessage id="batteries_battery_wizard_start_date_info" />
                                                    }
                                                />

                                                <DateFormikField
                                                    id="endDate"
                                                    name="endDate"
                                                    label={formLabels.endDate}
                                                    isRequired
                                                    showMonthYearPicker
                                                    showTimeInput={false}
                                                    info={
                                                        <FormattedMessage id="batteries_battery_wizard_end_date_info" />
                                                    }
                                                />

                                                <NumberInputFormikField
                                                    id="mwPower"
                                                    name="mwPower"
                                                    label={formLabels.mwPower}
                                                    isRequired
                                                />

                                                <NumberInputFormikField
                                                    id="hrDuration"
                                                    name="hrDuration"
                                                    label={formLabels.hrDuration}
                                                    isRequired
                                                />

                                                <NumberInputFormikField
                                                    id="chargeEfficiency"
                                                    name="chargeEfficiency"
                                                    label={formLabels.chargeEfficiency}
                                                    isRequired
                                                    clampValueOnBlur={true}
                                                    keepWithinRange={true}
                                                    min={0}
                                                    max={100}
                                                />

                                                <NumberInputFormikField
                                                    id="dischargeEfficiency"
                                                    name="dischargeEfficiency"
                                                    label={formLabels.dischargeEfficiency}
                                                    isRequired
                                                    clampValueOnBlur={true}
                                                    keepWithinRange={true}
                                                    min={0}
                                                    max={100}
                                                />
                                            </SimpleGrid>

                                            <Divider my={6} />

                                            <Heading as="h4" variant="h4" textTransform="capitalize">
                                                <FormattedMessage
                                                    id="batteries_battery_wizard_optional_title"
                                                    textTransform="capitalize"
                                                />
                                            </Heading>
                                            <Box pb={3}>
                                                <FormattedMessage
                                                    id="batteries_battery_wizard_optional_explanation"
                                                    textTransform="capitalize"
                                                />
                                            </Box>

                                            <SimpleGrid columns={2} gap={6} py={3}>
                                                <NumberInputFormikField
                                                    id="vom"
                                                    name="vom"
                                                    label={formLabels.vom}
                                                    isRequired={false}
                                                />

                                                <NumberInputFormikField
                                                    id="fixedCost"
                                                    name="fixedCost"
                                                    label={formLabels.fixedCost}
                                                    isRequired={false}
                                                />

                                                <NumberInputFormikField
                                                    id="annualCycleLimit"
                                                    name="annualCycleLimit"
                                                    label={formLabels.annualCycleLimit}
                                                    isRequired={false}
                                                />

                                                <NumberInputFormikField
                                                    id="costlessAdder"
                                                    name="costlessAdder"
                                                    label={formLabels.costlessAdder}
                                                    isRequired={false}
                                                />

                                                <NumberInputFormikField
                                                    id="minAverageSOC"
                                                    name="minAverageSOC"
                                                    label={formLabels.minAverageSOC}
                                                    isRequired={false}
                                                    clampValueOnBlur={true}
                                                    keepWithinRange={true}
                                                    min={0}
                                                    max={100}
                                                />

                                                <NumberInputFormikField
                                                    id="maxAverageSOC"
                                                    name="maxAverageSOC"
                                                    label={formLabels.maxAverageSOC}
                                                    isRequired={false}
                                                    clampValueOnBlur={true}
                                                    keepWithinRange={true}
                                                    min={0}
                                                    max={100}
                                                />
                                            </SimpleGrid>
                                        </>
                                    )}
                                    <ModalFooter
                                        justifyContent="stretch"
                                        flexWrap="wrap"
                                        border="none"
                                        paddingInline={0}
                                    >
                                        <Button
                                            variant="secondary"
                                            onClick={() =>
                                                onServerValidate(values, setErrors, setStatus, setTouched, validateForm)
                                            }
                                            isDisabled={isDisabled}
                                            isLoading={isValidating}
                                        >
                                            <Box as="span" textTransform="capitalize">
                                                <FormattedMessage id="common_check_data" />
                                            </Box>
                                        </Button>

                                        <Box ml="auto">
                                            {wizardMode ? (
                                                <Button
                                                    variant="secondary"
                                                    onClick={() => {
                                                        setWizardMode(false);
                                                    }}
                                                    isDisabled={isSubmitting}
                                                >
                                                    <Box as="span" textTransform="capitalize">
                                                        <FormattedMessage id="common_skip" />
                                                    </Box>
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" onClick={rest.onClose}>
                                                    <Box as="span" textTransform="capitalize">
                                                        <FormattedMessage id="common_cancel" />
                                                    </Box>
                                                </Button>
                                            )}

                                            <Button
                                                type="submit"
                                                isDisabled={isDisabled}
                                                isLoading={isSubmitting}
                                                ml={3}
                                            >
                                                <FormattedMessage id="common_item_create_btn" />
                                            </Button>
                                        </Box>
                                    </ModalFooter>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const StyledRadio = styled(Radio)`
    margin-right: 2px;
    border-color: var(--chakra-colors-green-500);

    &:hover {
        border-color: var(--chakra-colors-blue-400);
    }
`;

export default AddSubBatteryModal;
