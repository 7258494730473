import { FormattedMessage } from 'react-intl';
import { Heading, Flex, Text } from '@chakra-ui/react';
import AddDataButton from 'components/utils/AddDataButton';

const EmptyGraph = ({ messageId, onAdd, canCreate }) => {
    return canCreate ? (
        <Flex h="300px" justify="center" align="center">
            <Flex direction="column" pointerEvents="auto">
                <Heading variant="h3" color="gray.600" textTransform="capitalize" align="center">
                    <FormattedMessage id="common_no_results" />
                </Heading>

                <AddDataButton messageId="common_add_rows_heading" onAdd={onAdd} />
            </Flex>
        </Flex>
    ) : (
        <Flex h="300px" justify="center" align="top">
            <Text size="sm" variant="regular">
                <FormattedMessage id={messageId} />
            </Text>
        </Flex>
    );
};

export default EmptyGraph;
