import axios from 'axios';

export const GRID_KEYS = {
    forwardCurveData: 'forwardCurveData',
    forwardCurveOptions: 'forwardCurveOptions',
    forwardCurveModelDefinitions: 'forwardCurveModelDefinitions',
};

export const multisortPairs = {
    peakPeriodId: 'peakPeriodDescription',
    peakPeriodLabelId: 'peakPeriodDescription',
    curveTypeId: 'curveTypeDescription',
    moduleProcessId: 'moduleProcessDescription',
    curveSourceId: 'curveSourceDescription',
    weightSchemaId: 'weightSchemaDescription',
};

export const getForwardCurveDefinitions = async (itemId) => {
    const response = await axios.get(`/forward-curve/${itemId}/sub-forward-curves`);
    return response.data;
};

export const createForwardCurveData = (forwardCurveId, payload) =>
    axios.post(`/forward-curve/${forwardCurveId}/create-forward-curve-data`, payload, {
        suppressErrorMessageHandler: true,
    });

export const getForwardCurveData = async (forwardCurveId, payload) => {
    const formattedPayload = {
        ...payload,
        forwardCurveFilter: {
            ...payload.forwardCurveFilter,
            transactionDateFrom: payload.forwardCurveFilter.startDateFrom,
            transactionDateTo: payload.forwardCurveFilter.startDateTo,
        },
    };

    const response = await axios.post(`/forward-curve/${forwardCurveId}/forward-curve-data`, formattedPayload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getForwardPriceModelDefinitions = async (itemId, payload) => {
    const response = await axios.post(`/forward-curve/${itemId}/model-definitions`, payload);
    return response.data;
};

export const updateModelDefinitionsData = (itemId, payload) =>
    axios.put(`/forward-curve/${itemId}/model-definitions`, payload);

export const deleteForwardCurveData = (forwardCurveId, payload) =>
    axios.delete(`/forward-curve/${forwardCurveId}/delete-data`, { data: payload, suppressErrorMessageHandler: true });

export const updateForwardCurveData = (forwardCurveId, payload) =>
    axios.put(`/forward-curve/${forwardCurveId}/update-forward-curve-data`, payload, {
        suppressErrorMessageHandler: true,
    });

export const deleteAllForwardCurveData = (forwardCurveId) =>
    axios.delete(`/forward-curve/${forwardCurveId}/delete-all-forward-curve-data`, {
        suppressErrorMessageHandler: true,
    });

export const validateForwardCurveData = async (forwardCurveId, payload) => {
    const response = await axios.post(`/forward-curve/${forwardCurveId}/validate/forward-curve-data`, payload);
    return response.data;
};

export const createForwardCurveDefinition = async (payload) => {
    const response = await axios.post('/forward-curve/create', payload);
    return response.data;
};

export const validateForwardCurveDefinition = async (payload) => {
    const response = await axios.post('/forward-curve/validate/forward-curve', payload);
    return response.data;
};

export const createForwardCurveOptions = (forwardCurveId, payload) =>
    axios.post(`/forward-curve/${forwardCurveId}/create-options`, payload, { suppressErrorMessageHandler: true });

export const getForwardCurveOptions = async (forwardCurveId, payload) => {
    const formattedPayload = {
        ...payload,
        forwardCurveFilter: {
            ...payload.forwardCurveFilter,
            transactionDateFrom: payload.forwardCurveFilter.startDateFrom,
            transactionDateTo: payload.forwardCurveFilter.startDateTo,
        },
    };

    const response = await axios.post(`/forward-curve/${forwardCurveId}/forward-curve-options`, formattedPayload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const deleteForwardCurveOptions = (forwardCurveId, payload) =>
    axios.delete(`/forward-curve/${forwardCurveId}/delete-option`, {
        data: payload,
        suppressErrorMessageHandler: true,
    });

export const updateForwardCurveOptions = (forwardCurveId, payload) =>
    axios.put(`/forward-curve/${forwardCurveId}/update-options`, payload, { suppressErrorMessageHandler: true });

export const deleteAllForwardCurveOptions = (forwardCurveId) =>
    axios.delete(`/forward-curve/${forwardCurveId}/delete-all-options`, { suppressErrorMessageHandler: true });

export const validateForwardCurveOptions = async (forwardCurveId, payload) => {
    const response = await axios.post(`/forward-curve/${forwardCurveId}/validate/forward-curve-options`, payload);
    return response.data;
};

export const editForwardCurve = (payload) =>
    axios.put('/forward-curve/modify-forward-curves', payload, { suppressErrorMessageHandler: true });

export const deleteForwardItem = (itemId) => axios.delete(`/items/${itemId}`);

export const deleteForwardCurve = (forwardCurveId) => axios.delete(`/forward-curve/${forwardCurveId}`);

export const formatCurveSource = (curveSourceId, curveSources) => {
    const selectedCurveSource = curveSources?.find((curveSource) => curveSource.id === curveSourceId);
    return selectedCurveSource ? selectedCurveSource.description : '';
};

export const getForwardItemBooks = async (forwardCurveId) => {
    const response = await axios.get(`/forward-curve/${forwardCurveId}/book-options`);
    return response.data;
};
