const Link = {
    baseStyle: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
    },

    sizes: {
        sm: {
            fontSize: '12px',
            lineHeight: '18px',
        },
    },

    variants: {
        primary: {
            color: 'blue.400',
        },

        secondary: {
            color: 'gray.800',

            _dark: {
                color: 'gray.100',
            },
        },

        tertiary: {
            color: 'blue.500',

            _dark: {
                color: 'blue.400',
            },
        },
    },

    defaultProps: {
        variant: 'primary',
    },
};

export default Link;
