const primaryButton = {
    bg: 'blue.500',
    color: 'white',
    _hover: {
        bg: 'green.500',
        _disabled: {
            bg: 'gray.100',
        },
    },
    _disabled: {
        bg: 'gray.100',
        opacity: 1,
        color: 'gray.400',
    },
    _dark: {
        bg: 'blue.400',
        _disabled: {
            bg: 'gray.800',
            color: 'gray.600',
            _hover: {
                bg: 'gray.800',
                color: 'gray.600',
            },
        },
        _hover: {
            bg: 'blue.500',
        },
    },
};

const Button = {
    baseStyle: {
        fontWeight: '500',
        borderRadius: '4px',
        fontSize: '16px',
        lineHeight: '20px',
        _hover: {
            _disabled: {
                bg: 'gray.300',
            },
        },
        _disabled: {
            bg: 'gray.300',
            opacity: 1,
            color: 'white',
        },
    },

    sizes: {
        md: {
            p: '12px 16px',
            minHeight: '44px',
        },
        sm: {
            fontSize: '14px',
            p: '10px 14px',
            minHeight: '40px',
        },
    },
    variants: {
        primary: primaryButton,
        'primary-success': {
            ...primaryButton,
            _dark: {
                bg: 'green.900',
                _hover: {
                    bg: 'green.1000',
                },
                _disabled: {
                    bg: 'gray.800',
                    color: 'gray.600',
                    _hover: {
                        bg: 'gray.800',
                        color: 'gray.600',
                    },
                },
            },
        },
        secondary: {
            bg: 'gray.100',
            color: 'gray.900',
            _hover: {
                bg: 'gray.200',
                _disabled: {
                    bg: 'gray.100',
                    color: 'gray.400',
                },
            },
            _disabled: {
                bg: 'gray.100',
                color: 'gray.400',
            },
        },
        'circular-icon': {
            width: '32px',
            height: '32px',
            background: 'none',
            alignSelf: 'center',
            borderRadius: 'var(--chakra-radii-base)',
            ' span': {
                marginRight: 0,
            },
            ' svg path': {
                fill: 'gray.600',
            },
            _hover: {
                background: 'none',
                ' svg path': {
                    fill: 'blue.400',
                },
                _disabled: {
                    background: 'none',
                    ' svg path': {
                        fill: 'var(--chakra-colors-gray-300)',
                    },
                    _dark: {
                        bg: 'none',
                        ' svg path': {
                            fill: 'gray.600',
                        },
                    },
                },
            },
            _disabled: {
                borderRadius: 'var(--chakra-radii-full)',
                bg: 'none',
                ' span': {
                    opacity: 1,
                },
                ' circle': {
                    fill: 'gray.100',
                },
                ' svg path': {
                    fill: 'var(--chakra-colors-gray-300)',
                },
                _dark: {
                    bg: 'none',
                    ' circle': {
                        fill: 'gray.800',
                    },
                    ' svg path': {
                        fill: 'gray.600',
                    },
                },
            },
        },
        'secondary-special': {
            bg: 'white',
            color: 'gray.900',
            _hover: {
                bg: 'gray.200',
            },
        },
        special: {
            bg: 'gray.100',
            color: 'red.900',
            ' svg path': {
                fill: 'red.900',
            },
            _hover: {
                bg: 'red.100',
            },
            _dark: {
                _hover: {
                    bg: 'red.700',
                    color: 'red.400',
                    ' svg path': {
                        fill: 'red.400',
                    },
                },
            },
        },
        'special-green': {
            bg: 'green.900',
            color: 'white',
            _hover: {
                bg: 'green.500',
            },
        },
        'special-blue': {
            bg: 'none',
            color: 'blue.400',
            _hover: {
                bg: 'none',
            },
            _dark: {
                bg: 'none',
                color: 'gray.200',
                _hover: {
                    bg: 'none',
                },
            },
        },
        pagination: {
            bg: 'white',
            color: 'gray.800',
            border: '1px solid',
            borderColor: 'gray.200',
            minHeight: '0',
            padding: '9px',
            _hover: {
                _disabled: {
                    bg: 'white',
                },
            },
            _disabled: {
                bg: 'white',
            },
        },
        map: {
            textTransform: 'capitalize',
            padding: '10px 10px',
            bg: 'gray.500',
            color: 'white',
            ' span': {
                marginRight: '0',
            },
            _hover: {
                bg: 'white',
                color: 'blue.400',
                ' svg path': {
                    fill: 'blue.400',
                },
            },

            _dark: {
                bg: 'gray.800',
                _hover: {
                    bg: 'white',
                },
            },
        },
        ghost() {
            // @todo add hover color to button icons
            return {
                color: 'gray.600',
                _hover: { bg: 'gray.100' },

                _dark: { color: 'gray.100', _hover: { bg: 'gray.600' } },
            };
        },
        'sidebar-button': {
            bg: 'white',

            _dark: {
                background: 'gray.600',
            },
        },
        'add-item-button': {
            bg: 'none',

            _dark: {
                background: 'none',
            },
        },
        'batteries-plus': {
            ' svg': {
                width: '18px',
                height: '18px',
                path: {
                    fill: 'white',
                    stroke: 'white',
                },
                circle: {
                    fill: 'gray.500',
                },
            },

            _hover: {
                ' svg': {
                    path: {
                        fill: 'white',
                    },
                    circle: {
                        fill: 'blue.400',
                    },
                },
            },

            _dark: {
                bg: 'none',
            },
        },
        'batteries-add': {
            bg: 'none',
            color: 'gray.800',
            _hover: {
                bg: 'none',
                color: 'black',
            },
        },
        'batteries-delete': {
            bg: 'none',

            ' svg path': {
                fill: 'gray.600',
            },
            _hover: {
                bg: 'none',
                ' svg path': {
                    fill: 'blue.400',
                },
            },
        },
        'batteries-bidding': {
            width: '26px',
            height: '29px',
            minHeight: '29px',
            minWidth: '26px',
            maxWidth: '26px',
            borderRadius: 'md',
            cursor: 'pointer',
            p: '0',

            _disabled: {
                cursor: 'auto',
            },
        },
        link: {
            color: 'blue.400',
            bg: 'unset',
            minH: 'unset',

            _hover: {
                bg: 'none',
                textDecoration: 'underline',
            },
        },
    },

    defaultProps: {
        variant: 'primary',
        size: 'md',
    },
};

export default Button;
