import styled from '@emotion/styled/macro';
import { Box } from '@chakra-ui/react';

const Tooltip = ({ children, label, isDisabled, ...restProps }) => {
    return isDisabled ? (
        children
    ) : (
        <Wrapper {...restProps}>
            <div className="tooltip_children_wrapper">{children}</div>
            <div className="tooltip_wrapper">{label}</div>
        </Wrapper>
    );
};

const Wrapper = styled(Box)`
    position: relative;
    display: inline-block;

    > div:first-of-type {
        &:focus-within + div,
        &:hover + div {
            opacity: 1;
            transform: translate(-50%, 135%);
            visibility: visible;
        }

        + div {
            transition: transform 0.15s linear;
            opacity: 1;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 145%);
            bottom: 0;
            border-radius: 6px;
            background: var(--chakra-colors-gray-500);
            color: var(--chakra-colors-white);
            visibility: hidden;
            padding: 1px 8px;
            width: max-content;
            line-height: 18px;
            font-size: 12px;
            box-shadow: var(--chakra-shadows-md);
            z-index: 100;
            pointer-events: none;
            text-transform: capitalize;
        }
    }
`;

export default Tooltip;
