import { Field } from 'formik';
import _ from 'lodash';

import FormField from './FormField';
import CustomCheckbox from './CustomCheckbox';

import InfoPopover from './InfoPopover';

// If you need to extend CustomCheckbox functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for CustomCheckbox should be excluded from ...props and manually added
const CheckboxFormikField = ({ id, name, label, children, isDisabled, isChecked, breakWord, info, ...props }) => {
    return (
        <Field type="checkbox" name={name}>
            {({ field, meta, form }) => {
                const status = _.get(form.status, field.name);
                const isInvalid = meta.touched && (meta.error || status?.errors?.length > 0);
                const isWarning = meta.touched && status?.warnings?.length > 0;

                return (
                    <FormField
                        id={id}
                        name={name}
                        isDisabled={isDisabled}
                        label={label}
                        isInvalid={isInvalid}
                        error={meta.error}
                        status={status}
                        {...props}
                    >
                        <CustomCheckbox
                            {...field}
                            onChange={(e) => {
                                form.setStatus(_.omit(form.status, field.name));
                                field.onChange(e);
                            }}
                            isChecked={field.checked}
                            isDisabled={isDisabled}
                            isInvalid={isInvalid}
                            isWarning={isWarning}
                            breakWord={breakWord}
                        >
                            {children}
                            {info && <InfoPopover info={info} />}
                        </CustomCheckbox>
                    </FormField>
                );
            }}
        </Field>
    );
};

export default CheckboxFormikField;
