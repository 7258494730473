import { createContext, useState, useMemo } from 'react';

export const SortOrderContext = createContext();

const SortOrderProvider = ({ children }) => {
    const [sortOrder, setSortOrder] = useState({});

    return useMemo(
        () => <SortOrderContext.Provider value={{ sortOrder, setSortOrder }}>{children}</SortOrderContext.Provider>,
        [children, sortOrder]
    );
};

export default SortOrderProvider;
