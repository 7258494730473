import { Icon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled/macro';

import { ReactComponent as EditIcon } from '../../icons/edit.svg';

const DataGridButton = (props) => {
    return (
        <ButtonWrapper>
            {props.icon || <Icon as={EditIcon} />}
            <span>{props.children || <FormattedMessage id="common_edit" />}</span>
        </ButtonWrapper>
    );
};

const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    text-transform: capitalize;
    color: var(--chakra-colors-ag-cell-color);
    font-size: 14px;
    cursor: pointer;

    svg {
        font-size: 20px;
        margin-right: 8px;
    }

    span {
        color: var(--chakra-colors-ag-cell-color-readonly);
    }
`;

export default DataGridButton;
