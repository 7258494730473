import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import {
    CONSTRAINT_COMPONENTS_PRIMARY_KEY,
    CONSTRAINT_COMPONENTS_TABLE_ID,
    GLOBAL_CONSTRAINTS_SCHEMA_CODE,
} from 'constants/globalConstraints';
import GlobalConstraintsSubItem from 'components/globalConstraints/GlobalConstraintsSubItem';

import { getDtoFromDataValueObject, findKeysWithDifferences } from 'components/autoform/utils/autoformUtils';
import { saveAutoformRecords } from 'store/autoform/autoformApi';
import { useLocation } from 'react-router-dom';
import { clearDataForTable } from 'store/autoform/autoformSlice';

const useGlobalConstraintConfig = () => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});
    const location = useLocation();

    useEffect(() => {
        dispatch(clearDataForTable({ tableId: CONSTRAINT_COMPONENTS_TABLE_ID }));
        setInitialValues({});
    }, [dispatch, location]);

    const onBeforeSuccess = useCallback(
        async ({ values, metadata, parameters }) => {
            const formValues = values.constraintComponents ?? {};
            const changedRecordIds = findKeysWithDifferences(formValues, initialValues);

            const records = Object.keys(formValues)
                .filter((data) => changedRecordIds.includes(data))
                .map((data) => {
                    const tableMetadata = metadata.tables.find((t) => t.id === CONSTRAINT_COMPONENTS_TABLE_ID);

                    return getDtoFromDataValueObject(formValues[data], tableMetadata);
                });

            if (records.length) {
                await dispatch(
                    saveAutoformRecords({
                        tableId: CONSTRAINT_COMPONENTS_TABLE_ID,
                        records,
                        primaryKeyRecordName: CONSTRAINT_COMPONENTS_PRIMARY_KEY,
                        parameters,
                    })
                ).unwrap();
                setInitialValues({ constraintComponents: formValues });
            }
        },
        [dispatch, initialValues]
    );

    const dirtyCheck = useCallback(
        ({ values, initialValues: formInitialValues }) => {
            return !_.isEqual(values, _.merge({}, initialValues, formInitialValues));
        },
        [initialValues]
    );
    return useMemo(
        () => ({
            autoformId: GLOBAL_CONSTRAINTS_SCHEMA_CODE,
            dirtyCheck,
            renderSubItem: (item, setSelectedLevel3ItemDescription, editChildTableModal) => {
                return (
                    <GlobalConstraintsSubItem
                        item={item}
                        setSelectedLevel3ItemDescription={setSelectedLevel3ItemDescription}
                        editChildTableModal={editChildTableModal}
                        setInitialValues={setInitialValues}
                    />
                );
            },
            onBeforeSuccess,
        }),
        [onBeforeSuccess, dirtyCheck]
    );
};

export default useGlobalConstraintConfig;
