import { useRef, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import DataGridWrapper from '../grid/DataGridWrapper';
import DataGrid from '../grid/DataGrid';

const PeakPeriodTab = ({ options, basis, updateBasis, issues }) => {
    const gridApi = useRef(null);
    const intl = useIntl();

    const columns = useMemo(
        () => [
            {
                field: 'onPeakPeriodId',
                type: 'select',
                cellEditorParams: { options: options.peakPeriods, required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_on_peak' }),
                minWidth: 180,
                sortable: false,
            },
            {
                field: 'offPeakPeriodId',
                type: 'select',
                cellEditorParams: { options: options.peakPeriods, required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_off_peak' }),
                minWidth: 180,
                sortable: false,
            },
            {
                field: 'atcPeriodId',
                type: 'select',
                cellEditorParams: { options: options.peakPeriods, required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_atc_peak' }),
                minWidth: 180,
                sortable: false,
            },
        ],
        [intl, options.peakPeriods]
    );

    const onGridReady = useCallback(
        (params) => {
            const { onPeakPeriodId, offPeakPeriodId, atcPeriodId } = basis;
            gridApi.current = params.api;

            gridApi.current.setRowData([
                {
                    onPeakPeriodId,
                    offPeakPeriodId,
                    atcPeriodId,
                },
            ]);
        },
        [basis]
    );

    const updateBasisData = useCallback(
        async (rowIds) => {
            const rows = rowIds.map((id) => gridApi.current.getRowNode(id));

            const { onPeakPeriodId, offPeakPeriodId, atcPeriodId } = rows[0].data;

            updateBasis({
                ...basis,
                onPeakPeriodId,
                offPeakPeriodId,
                atcPeriodId,
            });
        },
        [basis, updateBasis]
    );

    return (
        <DataGridWrapper>
            <DataGrid
                onGridReady={onGridReady}
                onDataChange={updateBasisData}
                suppressPaginationPanel
                columns={columns}
                issues={issues}
            />
        </DataGridWrapper>
    );
};

export default PeakPeriodTab;
