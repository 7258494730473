import * as ThermalService from '../../../services/thermals';
import { resourceVariablesGridKeys, resourceVariablesGroupKeys } from 'constants/thermal';

export const thermalTabNames = Object.values(resourceVariablesGridKeys).reduce((obj, value) => {
    obj[value] = ThermalService.getResourceVariablesTabName(value);
    return obj;
}, {});

const getGroupKeyFriendlyName = (groupKey) => {
    switch (groupKey) {
        case resourceVariablesGroupKeys.PERIODS:
            return 'Generation Period Tabs';
        case resourceVariablesGroupKeys.FUELS:
            return 'Fuels Tabs';
        case resourceVariablesGroupKeys.GENERATION_CAPACITY:
            return 'Generation Capacity Tab';
        case resourceVariablesGroupKeys.FORCED_OUTAGES:
            return 'Forced Outages Tab';
        case resourceVariablesGroupKeys.ANCILLARY_CONTRIBUTION:
            return 'Ancillary Contribution Tab';
        case resourceVariablesGroupKeys.LINKED_RESOURCE:
            return 'Linked Resource Tab';
        case resourceVariablesGroupKeys.GENERATION_DERATE:
            return 'Generation Derate Tab';
        case resourceVariablesGroupKeys.FORCED_OUTAGE_TEMP:
            return 'Temperature Dependent Forced Outages Tab';
        default:
            return '';
    }
};

export const getSelectedThermalTabProperties = (outerSelectedTab, innerTabGroupKey, intl) => {
    if (outerSelectedTab > 0) {
        return {
            typeKey: 'thermals',
            friendlyName: 'Scheduled Outages and Historical Output Tabs',
        };
    }
    return {
        typeKey: `thermal-resource-variables-${innerTabGroupKey}`,
        friendlyName: getGroupKeyFriendlyName(innerTabGroupKey),
        groupKey: innerTabGroupKey,
        downloadBodyMessage: intl.formatMessage({
            id: `thermal_download_modal_explanation_${innerTabGroupKey.replace(/-/g, '_')}`,
        }),
    };
};
