import { ReactComponent as FolderOpened } from '../../icons/folder-opened.svg';
import { ReactComponent as FolderClosed } from '../../icons/folder-closed.svg';

import { getNodeIcon } from '../../services/items';

const PortfolioNodeIcon = ({ isFolder, isExpanded, typeId, isMaster }) => {
    const Icon = getNodeIcon(typeId);

    if (isFolder) {
        return isExpanded ? (
            <FolderOpened
                role="presentation"
                className={`${isMaster ? 'master-node ' : ''}portfolio-label-icon folder-opened portfolio-folder`}
            />
        ) : (
            <FolderClosed
                role="presentation"
                className={`${isMaster ? 'master-node ' : ''}portfolio-label-icon folder-closed portfolio-folder`}
            />
        );
    }

    return <Icon role="presentation" className={`${isMaster ? 'master-node ' : ''}portfolio-label-icon`} />;
};

export default PortfolioNodeIcon;
