import { Route, Redirect } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { getPrioritizedConnectionConfig } from '../../services/connections';
import useNavigation from '../../hooks/useNavigation';
import useUserPermissions from '../../hooks/useUserPermissions';
import DisabledPage from 'pages/DisabledPage';

const PrivateRoute = ({ children, ...rest }) => {
    const isAuthenticated = useIsAuthenticated();
    const { isAdmin } = useUserPermissions();
    const { isAdminRoute, isDisabledRoute, isTopMenuFetched } = useNavigation();

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (isAuthenticated) {
                    if (location.pathname === '/connection') {
                        return children;
                    }

                    const connectionConfig = getPrioritizedConnectionConfig();

                    if (!connectionConfig) {
                        if (location.pathname === '/environment' || location.pathname === '/book') {
                            return children;
                        }

                        return <Redirect to="/connection" />;
                    }

                    if (!isTopMenuFetched) {
                        return null;
                    }

                    if (!isAdmin && isAdminRoute(location.pathname)) {
                        return <Redirect to="/" />;
                    }
                    if (isDisabledRoute(location.pathname)) {
                        return <DisabledPage />;
                    }

                    return children;
                } else {
                    return <Redirect to={{ pathname: '/login', search: window.location.search }} />;
                }
            }}
        />
    );
};

export default PrivateRoute;
