import { useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useIntl } from 'react-intl';
import { format, parseISO } from 'date-fns';

import { checkParameterPeriodOverlap, createParameterPeriod, isMainParameter } from '../../../services/batteries';

import useCommonToast from '../../../hooks/useCommonToast';

import ManageMainParameterPeriodModal from './ManageMainParameterPeriodModal';
import ManageSubParameterPeriodModal from './ManageSubParameterPeriodModal';
import TimelineTrackElement from './TimelineTrackElement';
import PeriodOverlapModal from './PeriodOverlapModal';

const ParameterTrack = ({
    time,
    periods,
    subParameters,
    parameterDbField,
    parentParameterDbField,
    isLastElement = false,
    subParameterData,
    subBatteryId,
    updateRibbonData,
    onScrollCellIdChange,
    ribbonStaticData,
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();

    const editMainParameterPeriodModal = useDisclosure();
    const editSubParameterPeriodModal = useDisclosure();
    const overlapConfirmationModal = useDisclosure();

    const [mainParameter, setMainParameter] = useState({ dbField: null, parameterValues: null });
    const [subParameter, setSubParameter] = useState({ dbField: null, parameterValues: null });
    const [parameterPayload, setParameterPayload] = useState(null);

    const onEditMainParameterPeriodModalOpen = (periodData, dbField) => {
        setMainParameter({ dbField: dbField, parameterValues: periodData });
        editMainParameterPeriodModal.onOpen();
    };

    const onEditMainParameterPeriodModalClose = () => {
        editMainParameterPeriodModal.onClose();
        setMainParameter({ dbField: null, parameterValues: null });
    };

    const onEditSubParameterPeriodModalOpen = (periodData, dbField) => {
        const mappedData = {
            ...periodData,
            batteryLevel: subParameterData.batteryLevel,
            peakPeriod: subParameterData.peakPeriod,
            peakPeriodId: subParameterData.peakPeriodId,
            percentOutage: subParameterData.percentOutage,
            productType: subParameterData.productType,
            productTypeId: subParameterData.productTypeId,
        };

        setSubParameter({ dbField: dbField, parameterValues: mappedData });
        editSubParameterPeriodModal.onOpen();
    };

    const onEditSubParameterPeriodModalClose = () => {
        editSubParameterPeriodModal.onClose();
        setSubParameter({ dbField: null, parameterValues: null });
    };

    const onTimelineValueChange = async (payload, elementStartDate) => {
        const dataPeriod = format(parseISO(elementStartDate), 'yyyy-MM');

        await createParameterPeriod(subBatteryId, payload);
        onScrollCellIdChange(dataPeriod);
        await updateRibbonData();
    };

    const checkOverlap = async (parameterValues, parentModalOnClose) => {
        setParameterPayload(parameterValues);

        try {
            const overlapResult = await checkParameterPeriodOverlap(subBatteryId, parameterValues);

            if (overlapResult.hasPeriodOverlap) {
                overlapConfirmationModal.onOpen();
            } else {
                onConfirmedPeriodCreation(parameterValues, false, parentModalOnClose);
            }
        } catch (err) {}
    };

    const onConfirmedPeriodCreation = async (payload, shouldCloseConfirmationModal = false, parentModalOnClose) => {
        const successMessage = intl.formatMessage({ id: 'batteries_timeline_modals_on_success' });

        try {
            await createParameterPeriod(subBatteryId, payload);

            if (shouldCloseConfirmationModal) {
                overlapConfirmationModal.onClose();
            }
            parentModalOnClose();

            toast(successMessage);
            updateRibbonData();
        } catch (err) {}
    };

    const isMain = isMainParameter(parameterDbField);
    const innerClassName = !isMain ? `track-periods sub` : `track-periods`;

    return (
        <ParameterTrackWrapper isMain={isMain} isLastElement={isLastElement}>
            <div className={innerClassName}>
                {periods.map((element, idx) => (
                    <TimelineTrackElement
                        key={`${idx}-${element.periodId}`}
                        time={time}
                        element={element}
                        parameterDbField={parameterDbField || parentParameterDbField}
                        onMainPeriodClick={onEditMainParameterPeriodModalOpen}
                        onSubPeriodClick={onEditSubParameterPeriodModalOpen}
                        onTimelineValueChange={onTimelineValueChange}
                        ribbonStaticData={ribbonStaticData}
                        subParameterData={subParameterData}
                    />
                ))}
            </div>

            {subParameters.map((item, idx) => {
                const { subParameters: innerSubParameters, periods } = item;
                const isLastOfKind = subParameters.length - idx === 1 ? true : false;

                return (
                    <ParameterTrack
                        key={idx}
                        time={time}
                        subParameters={innerSubParameters || []}
                        periods={periods || []}
                        parentParameterDbField={parameterDbField}
                        isLastElement={isLastOfKind}
                        subParameterData={item}
                        subBatteryId={subBatteryId}
                        updateRibbonData={updateRibbonData}
                        onScrollCellIdChange={onScrollCellIdChange}
                        ribbonStaticData={ribbonStaticData}
                    />
                );
            })}

            {!isMain && periods.length === 0 && <Box height="60px" />}

            {editMainParameterPeriodModal.isOpen && (
                <ManageMainParameterPeriodModal
                    isOpen
                    subBatteryId={subBatteryId}
                    selectedParameter={mainParameter}
                    onClose={onEditMainParameterPeriodModalClose}
                    updateRibbonData={updateRibbonData}
                    ribbonStaticData={ribbonStaticData}
                    modalTitleId="batteries_timeline_add_modal_heading"
                    btnTitleId="batteries_timeline_add_modal_save_btn"
                    checkOverlap={(parameters) => checkOverlap(parameters, editMainParameterPeriodModal.onClose)}
                    periodOverlapModal={
                        <PeriodOverlapModal
                            modal={overlapConfirmationModal}
                            onConfirmCallback={() =>
                                onConfirmedPeriodCreation(parameterPayload, true, editMainParameterPeriodModal.onClose)
                            }
                        />
                    }
                />
            )}

            {editSubParameterPeriodModal.isOpen && (
                <ManageSubParameterPeriodModal
                    isOpen
                    subBatteryId={subBatteryId}
                    selectedParameter={subParameter}
                    onClose={onEditSubParameterPeriodModalClose}
                    updateRibbonData={updateRibbonData}
                    ribbonStaticData={ribbonStaticData}
                    modalTitleId="batteries_timeline_edit_modal_heading"
                    btnTitleId="batteries_timeline_edit_modal_save_btn"
                    checkOverlap={(parameters) => checkOverlap(parameters, editSubParameterPeriodModal.onClose)}
                    periodOverlapModal={
                        <PeriodOverlapModal
                            modal={overlapConfirmationModal}
                            onConfirmCallback={() =>
                                onConfirmedPeriodCreation(parameterPayload, true, editSubParameterPeriodModal.onClose)
                            }
                        />
                    }
                />
            )}
        </ParameterTrackWrapper>
    );
};

const Timeline = ({
    timebar,
    timeState,
    data,
    subBatteryId,
    updateRibbonData,
    onScrollCellIdChange,
    ribbonStaticData,
}) => {
    return (
        <Wrapper>
            <TimelineGrid>
                {timebar.map(({ id, start, end }) => (
                    <GridCell id={id} key={id} style={timeState.toStyleLeftAndWidth(start, end)} />
                ))}
            </TimelineGrid>

            {data.map(({ parameterDbField, subParameters, periods }) => (
                <ParameterTrack
                    key={parameterDbField}
                    time={timeState}
                    subParameters={subParameters || []}
                    periods={periods || []}
                    parameterDbField={parameterDbField}
                    subBatteryId={subBatteryId}
                    updateRibbonData={updateRibbonData}
                    onScrollCellIdChange={onScrollCellIdChange}
                    ribbonStaticData={ribbonStaticData}
                />
            ))}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    background: var(--chakra-colors-background-secondary);
`;

const TimelineGrid = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const GridCell = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    background: var(--chakra-colors-background-secondary);
    border-right: 1px solid var(--chakra-colors-border-tertiary);
`;

const ParameterTrackWrapper = styled.div`
    height: ${(props) => (props.isLastElement ? '55px' : 'unset')};

    .track-periods {
        position: relative;
        height: ${(props) => (props.isMain ? '60px' : '55px')};
    }
    .track-periods.sub {
        height: 55px;
    }
`;

export default Timeline;
