import { Box } from '@chakra-ui/react';

import Error from '../utils/Error';

/**
 * Misconfiguration error panel.
 */
const MisconfigurationError = () => (
    <Box m={6}>
        <Error primaryId="common_error" secondaryId="autoform_misconfiguration_error" />
    </Box>
);

export default MisconfigurationError;
