import { useIntl, FormattedMessage } from 'react-intl';
import { useField } from 'formik';
import { Flex, Text } from '@chakra-ui/react';

import DateField from '../forms/DateField';
import CheckboxField from '../forms/CheckboxField';

import { enUSFormatExcludingSeconds } from '../../services/items';

const HedgeCommonPropertyDate = ({ name, label, isDisabled, onMultipleToggleChange, onChange, ...props }) => {
    const intl = useIntl();
    const [field, _, { setValue }] = useField(name); // eslint-disable-line
    const isMultipleSelected = field.value.includes('multiple');
    const possibleValues = field.value.filter((v) => v !== 'multiple');

    /**
     * Date values that can be displayed in the datepicker:
     * [1234567] - happy path
     * [1234567, 'multiple'] - if there is only a single real id in the value array then show it as selected
     *
     * Date values that can't be displayed in the datepicker:
     * [1234567, 7654321]
     * [1234567, 7654321, 'multiple']
     * ['multiple']
     */

    // when isMultipleSelected -> value goes to null in order to show the datepicker's placeholder
    const selected = isMultipleSelected ? null : possibleValues.length === 1 ? possibleValues[0] : null;
    const placeholder = intl.formatMessage({ id: isMultipleSelected ? 'hedge_select_multiple' : 'common_select_date' });

    return (
        <Flex direction="column">
            <DateField
                id={name}
                name={name}
                label={label}
                value={selected}
                isDisabled={isDisabled || isMultipleSelected}
                dateFormat={enUSFormatExcludingSeconds}
                placeholderText={placeholder}
                onChange={(date) => {
                    if (date == null) {
                        setValue([]);
                        onChange(null);
                    } else {
                        setValue([date]);
                    }
                }}
                onCalendarClose={() => {
                    // prevents saving empty date value
                    if (field.value.length > 0) {
                        onChange(field.value[0]);
                    }
                }}
                maxDate={new Date(9999, 0, 1)}
                isClearable={!isMultipleSelected}
            />

            <CheckboxField
                mt={2}
                id={`multiple_select_${name}`}
                name={`multiple_select_${name}`}
                isDisabled={isDisabled}
                isChecked={isMultipleSelected}
                onChange={onMultipleToggleChange}
            >
                <Text as="span" fontSize="12px" color="gray.600">
                    <FormattedMessage id="hedge_select_multiple" />
                </Text>
            </CheckboxField>
        </Flex>
    );
};

export default HedgeCommonPropertyDate;
