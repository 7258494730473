import React, { useRef } from 'react';
import SideDrawer from './SideDrawer';
import useNavigation from 'hooks/useNavigation';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import { ReactComponent as HelpIcon } from '../../icons/help.svg';
import { SHAREPOINT_TAGS, SP_COMMENTS_BAR, SP_FOOTER } from '../../constants/help';
import MessageBox from './MessageBox';
import { getHelpPage } from 'services/utils';
import { useEffect } from 'react';
import { useState } from 'react';
import { Skeleton } from '@chakra-ui/react';
import { useCallback } from 'react';

const skeletonsNumber = 3;

const HelpButtonComponent = ({ header = 'Help', helpAnchor = null, itemTypeId = null }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { config, getHelpPageUrl } = useNavigation();
    const iframeRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [helpContent, setHelpContent] = useState(null);

    const prepareIframe = useCallback(() => {
        if (iframeRef?.current && helpContent) {
            const doc = iframeRef.current?.contentWindow?.document;

            if (!doc) {
                setIsError(true);
                setIsLoading(false);
                return;
            }

            doc.innerHtml = '';
            doc.open();
            doc.write(helpContent);
            doc.close();

            //Disable all the link to avoid redirect to blank page
            const links = doc.getElementsByTagName('a');
            for (var index = 0; index < links.length; index++) {
                links[index].setAttribute('href', '#');
            }

            //Remove all the sharepoint specific tags from the html
            for (var i = 0; i < SHAREPOINT_TAGS.length; i++) {
                const child = doc.getElementById(SHAREPOINT_TAGS[i]);
                child?.remove();
            }

            const commentsbar = doc.getElementsByClassName(SP_COMMENTS_BAR.toString());
            commentsbar[0]?.remove();

            const footer = doc.querySelectorAll("[data-viewport-id='" + SP_FOOTER.toString() + "']");
            footer[0]?.remove();

            // snap to hash location in the iframe. Go to top if no helpAnchor
            doc.location.hash = helpAnchor;
        }
    }, [helpAnchor, helpContent]);

    const fetchHelpPage = async () => {
        setIsError(false);
        setIsLoading(true);

        let url = '';
        if (itemTypeId != null) {
            url = getHelpPageUrl(itemTypeId);
        } else {
            let urlConfig = Array.isArray(config)
                ? config[0]?.filter((p) => p.type === 'Link' && p.url?.startsWith('https'))
                : null; //get the config for the help link
            url = Array.isArray(urlConfig) ? urlConfig[0]?.url?.toString() : null;
        }

        //get the filename from the help url
        const filename = url?.split('/').pop()?.split('.')[0];
        if (filename == null) {
            setIsError(true);
            setIsLoading(false);
            setHelpContent(null);
            return;
        }

        try {
            const response = await getHelpPage(filename);
            setHelpContent(response);
        } catch (error) {
            setIsError(true);
            setHelpContent(null);
        }
        setIsLoading(false);
    };

    const handleButtonClick = () => {
        onOpen();
        fetchHelpPage();
    };

    useEffect(() => {
        prepareIframe();
    }, [helpContent, isLoading, isError, prepareIframe]);

    return (
        <>
            <IconButton
                mx={1}
                onClick={handleButtonClick}
                leftIcon={<HelpIcon />}
                type="button"
                variant="circular-icon"
                size="xs"
            ></IconButton>
            {isOpen && (
                <SideDrawer
                    isOpen
                    variant="help"
                    size="full"
                    onClose={onClose}
                    header={header}
                    content={
                        isLoading ? (
                            [...Array(skeletonsNumber).keys()].map((index) => (
                                <Skeleton mb={2} height="56px" key={index} />
                            ))
                        ) : isError || !helpContent ? (
                            <MessageBox primaryId="" secondaryId="common_help_error" status="info" />
                        ) : (
                            <iframe ref={iframeRef} title="Help" width="100%" height="100%" />
                        )
                    }
                ></SideDrawer>
            )}
        </>
    );
};

export default HelpButtonComponent;
