import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useDisclosure } from '@chakra-ui/react';

import { getPrimaryKeyColName, parseAutoformDataFilters, parseSortModel } from './utils/autoformUtils';
import DownloadModal from '../utils/DownloadModal';
import SecondaryButton from '../utils/SecondaryButton';

import { exportAutoformTables, getDescriptionColumn } from '../../services/autoforms';
import { getDataFilters } from '../../services/items';

import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import useAutoformMetadata from './hooks/useAutoformMetadata';

import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import useAutoformParams from './hooks/useAutoformParams';

const defaultConfig = {};

/**
 * Allows downloading multiple Autoform tables in an excel file.
 *
 * @param {object[]} tablesMetadata - Table metadata for the tables that will
 * be downloaded through this component.
 * @param {string} parentTableId - Id of the parent table for the tables
 * being downloaded.
 * @param {boolean} showFullTableList - Determines whether the full list of
 * table names is included in the download modal. Used when some tables
 * might be omitted from downloading.
 * @param {string} fileName - custom filename
 * @param {string} dataFilenameSuffix - suffix that should be used for level3 selected item name because of no "data" there
 * @param {object} config - flexible config for this and nested components
 * @param rest - Chakra props to be passed to the download button for positioning,
 * margins etc.
 */
const AutoformDownloadButton = ({
    tablesMetadata,
    parentTableId,
    showFullTableList = false,
    dataFilenameSuffix,
    onDownloadGeneric,
    config = defaultConfig,
    filterDate,
    ...rest
}) => {
    const tablesState = useSelector((state) => state.autoform?.tables ?? {});
    const { selectedItem } = useAutoformTableMetadata(parentTableId);
    const { metadata } = useAutoformMetadata();
    const downloadModal = useDisclosure();
    const tableNamesString = useMemo(
        () => tablesMetadata?.map((tableMetadata) => tableMetadata.name).join(', '),
        [tablesMetadata]
    );
    const parameters = useAutoformParams();

    const hasNestedContent = useMemo(() => {
        const minTablesLevel = tablesMetadata?.reduce(
            (result, table) => Math.min(result, table.level),
            tablesMetadata[0]?.level ?? 99
        );

        return minTablesLevel < metadata.maxTableHierarchyLevel;
    }, [tablesMetadata, metadata]);

    const getFormLabel = () => {
        let formLabel;

        const iterate = (tableId) => {
            const result = [];

            const table = metadata.tables.find((table) => table.id === tableId);

            if (table?.name != null) {
                result.push(table.name);
            }
            if (table?.parentId) {
                result.push(...iterate(table.parentId));
            }

            return result;
        };

        const tableNames = iterate(parentTableId).reverse().join('_');
        formLabel = `${metadata.schemaCode}`;

        if (tableNames?.length) {
            formLabel += `_${tableNames}`;
        }
        return formLabel;
    };

    const getFileName = (isTemplateOnly) => {
        const autoformName = `${metadata.formLabel}`.trim();

        let result;
        if (isTemplateOnly) {
            result = parentTableId
                ? metadata.tables.find((table) => table.id === parentTableId)?.name?.trim()
                : autoformName;
        } else {
            const iterate = (parentId) => {
                const result = [];

                const parentTable = metadata.tables.find((table) => table.id === parentId);
                if (parentTable) {
                    const visibleColumns = parentTable.columns.filter((column) => column.isVisible);
                    const primaryKey = getPrimaryKeyColName(parentTable.columns);
                    const descriptionColumn = getDescriptionColumn(visibleColumns);

                    const name =
                        tablesState[parentTable.id]?.data?.find(
                            (item) => item[primaryKey] === tablesState[parentTable.id].selectedItem
                        )?.[descriptionColumn.name] ??
                        dataFilenameSuffix ??
                        config?.dataFilenameSuffix;

                    if (name) {
                        result.push(name.trim());
                    }

                    if (parentTable.parentId) {
                        result.push(...iterate(parentTable.parentId));
                    }
                }

                return result;
            };

            result = parentTableId ? iterate(parentTableId).reverse().join('_') : autoformName;
        }

        return result ? result : autoformName;
    };

    const onDownloadPressed = (isTemplateOnly) => {
        const tablesData = [];
        for (let metadata of tablesMetadata) {
            let tableDto = { tableId: metadata.id };
            let filterData = {};
            if (tablesState[metadata.id]?.sortModel) {
                filterData.sortData = parseSortModel(tablesState[metadata.id]?.sortModel, metadata.columns);
            }

            const customFilters = tablesState[metadata.id]?.customFilters ?? [];
            const filters = parseAutoformDataFilters({ ...getDataFilters(metadata.id), customFilters }, metadata);
            filterData = { ...filterData, ...filters };
            filterData.pageSize = tablesState[metadata.id]?.pageSize ?? 500;
            filterData.page = tablesState[metadata.id]?.page ?? 1;
            tableDto.filterData = filterData;
            tablesData.push(tableDto);
        }
        const fileName = getFileName(isTemplateOnly);
        const exportPayload = {
            tablesData,
            parentRecordId: selectedItem,
            parameters,
            fileName,
            isTemplateOnly,
            formLabel: getFormLabel(),
        };

        return config?.exportAutoformTables
            ? config.exportAutoformTables({ isTemplateOnly, payload: exportPayload })
            : exportAutoformTables(exportPayload);
    };

    return (
        <>
            <SecondaryButton
                leftIcon={<DownloadIcon />}
                size="sm"
                type="button"
                variant="secondary"
                onClick={downloadModal.onOpen}
                ml={{ base: 0, xl: 6 }}
                {...rest}
            >
                <FormattedMessage
                    id="common_download_btn_tooltip_and_label"
                    values={{ tableNames: tableNamesString }}
                />
            </SecondaryButton>

            {downloadModal.isOpen && (
                <DownloadModal
                    isOpen
                    onClose={downloadModal.onClose}
                    onDownloadGeneric={onDownloadGeneric}
                    onDownloadTemplate={() => onDownloadPressed(true)}
                    onDownloadData={() => onDownloadPressed(false)}
                    filterDate={filterDate}
                    headerMessage={<FormattedMessage id="download_modal_header" />}
                    bodyMessage={
                        config?.downloadModalBodyMessage ?? (
                            <>
                                {showFullTableList ? (
                                    <FormattedMessage
                                        id="autoform_export_modal_description_table_list"
                                        values={{ tableNames: tableNamesString }}
                                    />
                                ) : (
                                    <FormattedMessage id="download_modal_explanation" />
                                )}

                                {hasNestedContent ? (
                                    <>
                                        {' '}
                                        <FormattedMessage id="common_download_modal_message_affix" />
                                    </>
                                ) : null}
                            </>
                        )
                    }
                />
            )}
        </>
    );
};

export default AutoformDownloadButton;
