const Table = {
    baseStyle: {
        table: {
            border: '1px',
            borderColor: 'gray.200',
            thead: {
                tr: {
                    th: {
                        color: 'gray.600',
                        border: '1px',
                        borderColor: 'gray.200',
                        fontWeight: '600',
                        px: '4',
                    },
                },
            },
            tbody: {
                tr: {
                    td: {
                        color: 'gray.800',
                        border: '1px',
                        borderColor: 'gray.200',
                        px: '4',
                    },
                },
            },
        },
    },
    defaultProps: {
        variant: 'unstyled',
    },
};

export default Table;
