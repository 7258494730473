import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Heading,
    Box,
    Button,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';

import CustomCheckbox from '../forms/CustomCheckbox';

const ConfirmationModal = ({
    header,
    content,
    confirmText,
    cancelText,
    onConfirm,
    hasExtraStep,
    additionalContent,
    isLoading = false,
    ...rest
}) => {
    const [agreed, setAgreed] = useState(false);

    return (
        <Modal size="md" {...rest}>
            <ModalOverlay />

            <ModalContent pt={3} borderRadius="2xl">
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3">
                        {header}
                    </Heading>
                </ModalHeader>

                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <ModalBody pb={5} borderBottom="1px" borderColor="border-secondary">
                    <Text size="sm" variant="dark">
                        {content}
                    </Text>

                    {additionalContent}

                    {hasExtraStep && (
                        <Box mt={4} bg="background-quaternary" borderRadius="5px" padding={4}>
                            <CustomCheckbox isChecked={agreed} onChange={() => setAgreed((prev) => !prev)}>
                                <FormattedMessage id="common_i_am_sure" />
                            </CustomCheckbox>
                        </Box>
                    )}
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} variant="secondary" onClick={rest.onClose}>
                        {cancelText || (
                            <Box as="span" textTransform="capitalize">
                                <FormattedMessage id="common_cancel" />
                            </Box>
                        )}
                    </Button>

                    <Button
                        isLoading={isLoading}
                        loadingText={<FormattedMessage id="common_loading" />}
                        isDisabled={hasExtraStep ? !agreed : false}
                        onClick={onConfirm}
                    >
                        {confirmText || (
                            <Box as="span" textTransform="capitalize">
                                <FormattedMessage id="common_continue" />
                            </Box>
                        )}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmationModal;
