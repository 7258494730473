import { Table, Thead, Tbody, Tr, Th, Td, Text, Alert, AlertIcon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { formatDate } from '../../services/items';
import SimpleGridSkeleton from '../../components/utils/SimpleGridSkeleton';

// Validation Progress status /state level codes
// 40 - Waiting to start/continue
// 60 - Running

// Validation Outputs status / state level codes
// 20 - Fail
// 1 - Pass

const StudyValidationsTable = ({ skipValidation, validationProgress }) => {
    if (skipValidation) {
        return (
            <Alert status="info" _dark={{ background: 'blue.100' }} color="black">
                <AlertIcon _dark={{ color: 'blue.500' }} />
                <FormattedMessage id="study_info_validations_skipped" />
            </Alert>
        );
    }

    const isValidationWaitingOrRunning = validationProgress.stateLevel === 40 || validationProgress.stateLevel === 60;
    const isValidationOutputEmpty = validationProgress.validationOutputs.length === 0;

    if (isValidationWaitingOrRunning && isValidationOutputEmpty) {
        return <SimpleGridSkeleton rows={9} cols={3} />;
    }

    if (!isValidationWaitingOrRunning && isValidationOutputEmpty) {
        return null;
    }

    return (
        <Table my={5}>
            <Thead>
                <Tr>
                    <Th>
                        <Text>
                            <FormattedMessage id="run_a_study_rule_description" />
                        </Text>
                    </Th>
                    <Th>
                        <Text>
                            <FormattedMessage id="run_a_study_state" />
                        </Text>
                    </Th>
                    <Th>
                        <Text>
                            <FormattedMessage id="run_a_study_details" />
                        </Text>
                    </Th>
                    <Th>
                        <Text>
                            <FormattedMessage id="run_a_study_portfolio_name" />
                        </Text>
                    </Th>
                    <Th>
                        <Text>
                            <FormattedMessage id="run_a_study_sensitivity" />
                        </Text>
                    </Th>
                    <Th>
                        <Text>
                            <FormattedMessage id="run_a_study_started" />
                        </Text>
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {validationProgress.validationOutputs.map(
                    ({ id, description, state, stateLevel, details, portfolioName, sensitivity, started }) => (
                        <Tr key={id}>
                            <Td>
                                <Text>{description}</Text>
                            </Td>

                            <Td>
                                <Text
                                    size="sm"
                                    variant="regular"
                                    color={
                                        stateLevel === 1 ? 'green.1000' : stateLevel === 20 ? 'text-error' : 'grey.800'
                                    }
                                >
                                    {state}
                                </Text>
                            </Td>
                            <Td>
                                <Text>{details} </Text>
                            </Td>
                            <Td>
                                <Text>{portfolioName} </Text>
                            </Td>
                            <Td>
                                <Text>{sensitivity} </Text>
                            </Td>
                            <Td>
                                <Text>{formatDate(new Date(started))} </Text>
                            </Td>
                        </Tr>
                    )
                )}
            </Tbody>
        </Table>
    );
};

export default StudyValidationsTable;
