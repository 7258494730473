import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Progress,
    Spacer,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import DragAndDropFile from 'components/upload/DragAndDropFile';
import {
    FAIL_STATUS,
    FINISHED_STATUSES,
    INITIAL_STATUS,
    SUCCESS_STATUS,
    SUCCESS_WARNING_STATUS,
    UPDATE_TYPE_REPLACE,
    UPDATE_TYPE_UPDATE,
} from 'constants/uploadDownload';
import { useFileUpload } from 'hooks/useFileUpload';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CloseIconButton, FileContainer, StyledUploadIcon, UploadIconSquaredWrapper } from './UploadModal';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import CustomCheckbox from 'components/forms/CustomCheckbox';
import { DownloadErrorReportButton } from './DownloadErrorReportButton';
import UpdateTypeSelect from './UploadTypeSelect';

export const UploadModalV2 = ({
    route,
    queryParams,
    headers,
    onAfterUploadChange,
    hideTreatWarningsAsErrors,
    isOpen,
    onClose,
    hubConnectionUrl,
    includeUpdateTypeSelect = true,
    requireConfirmation = true,
}) => {
    const [updateType, setUpdateType] = useState(UPDATE_TYPE_UPDATE);
    const [treatWarningsAsErrors, setTreatWarningsAsErrors] = useState(false);
    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmModalClose } = useDisclosure();
    const {
        file,
        fileUploadProgress,
        percentage,
        startUpload,
        isLoading,
        error,
        phase,
        handleFileChange,
        removeFile,
        cancelUpload,
        sseClientId,
        friendlyUploadProgress,
    } = useFileUpload(onAfterUploadChange, hubConnectionUrl);

    const reqHeaders = useMemo(() => {
        return headers;
    }, [headers]);

    const reqParams = useMemo(() => {
        const params = new URLSearchParams(queryParams);

        if (includeUpdateTypeSelect && updateType === UPDATE_TYPE_REPLACE) {
            params.append('isInsert', true);
        }
        if (sseClientId) {
            params.append('sseClientId', sseClientId);
        }

        if (treatWarningsAsErrors) {
            params.append('treatWarningsAsErrors', treatWarningsAsErrors);
        }

        return params;
    }, [queryParams, includeUpdateTypeSelect, updateType, sseClientId, treatWarningsAsErrors]);

    const handleUpload = useCallback(() => {
        startUpload(route, reqParams, reqHeaders);
    }, [startUpload, route, reqParams, reqHeaders]);

    const handleUploadClick = () => {
        if (requireConfirmation) {
            onConfirmOpen();
        } else {
            handleUpload();
        }
    };

    const handleUploadConfirm = () => {
        handleUpload();
        onConfirmModalClose();
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent pt={3}>
                    <ModalHeader pr={12}>
                        <Heading as="h3" variant="h3" textTransform="capitalize">
                            <FormattedMessage id="upload_modal_header" />
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                    <ModalBody borderBottom="1px" borderColor="border-secondary">
                        {includeUpdateTypeSelect && (
                            <UpdateTypeSelect mb={10} updateType={updateType} setUpdateType={setUpdateType} />
                        )}

                        {!file ? (
                            <DragAndDropFile handleFileChange={handleFileChange} />
                        ) : (
                            <>
                                <FileContainer>
                                    <UploadIconSquaredWrapper>
                                        <StyledUploadIcon />
                                    </UploadIconSquaredWrapper>
                                    <Flex direction="column" justify="center" flex="1" minHeight="60px">
                                        <Text>{file.name}</Text>
                                        {isLoading && (
                                            <>
                                                <Progress
                                                    variant={fileUploadProgress !== file.size && 'blue'}
                                                    my={2}
                                                    value={percentage}
                                                    isIndeterminate={percentage === -1}
                                                />
                                                {phase.status === INITIAL_STATUS ? (
                                                    <FormattedMessage
                                                        id="upload_modal_size_uploaded"
                                                        values={{
                                                            uploadedSize: friendlyUploadProgress,
                                                            totalSize: file.friendlySize,
                                                        }}
                                                    />
                                                ) : (
                                                    <Text>{percentage !== -1 && `${percentage}%`}</Text>
                                                )}
                                            </>
                                        )}
                                    </Flex>
                                    {!(isLoading || phase.status !== INITIAL_STATUS) && (
                                        <CloseIconButton variant="ghost" icon={<CloseIcon />} onClick={removeFile} />
                                    )}
                                </FileContainer>

                                {isLoading && (
                                    <Alert status="info">
                                        <AlertIcon />
                                        {phase.title}
                                    </Alert>
                                )}

                                {(phase.status === FAIL_STATUS || error) && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        {phase.status === FAIL_STATUS ? phase.title : error}
                                    </Alert>
                                )}

                                {phase.status === SUCCESS_STATUS && (
                                    <Alert status="success">
                                        <AlertIcon />
                                        <FormattedMessage id="upload_modal_successful_import" />
                                    </Alert>
                                )}

                                {phase.status === SUCCESS_WARNING_STATUS && (
                                    <Alert status="warning">
                                        <AlertIcon />
                                        <FormattedMessage id="upload_modal_successful_warning_import" />
                                    </Alert>
                                )}
                            </>
                        )}
                    </ModalBody>

                    <ModalFooter justifyContent="stretch" flexWrap="wrap">
                        <Flex flex="1" justifyContent="space-between">
                            {hideTreatWarningsAsErrors || isLoading || phase.status !== INITIAL_STATUS ? (
                                <Spacer />
                            ) : (
                                <CustomCheckbox
                                    isChecked={treatWarningsAsErrors}
                                    onChange={() => setTreatWarningsAsErrors((prev) => !prev)}
                                >
                                    <FormattedMessage id="upload_modal_accept_warnings_checkbox" />
                                </CustomCheckbox>
                            )}

                            <Box>
                                {(phase.status === FAIL_STATUS ||
                                    (phase.data && phase.status === SUCCESS_WARNING_STATUS)) && (
                                    <DownloadErrorReportButton ml={3} fileName={phase?.data} />
                                )}
                                {isLoading ? (
                                    <Button type="submit" textTransform="capitalize" ml={3} onClick={cancelUpload}>
                                        <FormattedMessage id="common_cancel" />
                                    </Button>
                                ) : (
                                    <>
                                        {FINISHED_STATUSES.includes(phase.status) || error ? (
                                            <Button type="submit" textTransform="capitalize" ml={3} onClick={onClose}>
                                                <FormattedMessage id="common_continue" />
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                isLoading={isLoading}
                                                textTransform="capitalize"
                                                disabled={!file}
                                                ml={3}
                                                onClick={handleUploadClick}
                                            >
                                                <FormattedMessage id="common_upload_btn_tooltip_and_label" />
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {isConfirmOpen && (
                <ConfirmationModal
                    isOpen={isConfirmOpen}
                    header="Upload"
                    onClose={onConfirmModalClose}
                    hasExtraStep
                    onConfirm={handleUploadConfirm}
                    content={
                        updateType === UPDATE_TYPE_UPDATE ? (
                            <FormattedMessage id="upload_confirmation_update_message" />
                        ) : (
                            <FormattedMessage id="upload_confirmation_replace_message" />
                        )
                    }
                />
            )}
        </>
    );
};
