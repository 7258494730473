import { Box } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from './ConfirmationModal';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, header, content, isLoading, ...rest }) => {
    return isOpen ? (
        <ConfirmationModal
            isOpen
            onClose={onClose}
            header={header ?? <FormattedMessage id={'common_delete_all_modal_heading'} />}
            hasExtraStep
            content={content ?? <FormattedMessage id={'common_delete_all_modal_description'} />}
            confirmText={
                <Box as="span" textTransform="capitalize">
                    <FormattedMessage id="common_delete" />
                </Box>
            }
            onConfirm={onConfirm}
            isLoading={isLoading}
            {...rest}
        />
    ) : null;
};

export default DeleteConfirmationModal;
