import InputFormikField from '../forms/InputFormikField';
import DateFormikField from '../forms/DateFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';

const AddDataModalField = ({ type, id, label, editable, info, params }) => {
    switch (type) {
        case 'select':
            return (
                <ComboboxFormikField
                    id={id}
                    name={id}
                    label={label}
                    options={params.options || []}
                    labelKey="description"
                    valueKey="id"
                    isRequired={params.required}
                    isDisabled={!editable}
                    info={info}
                />
            );
        case 'number':
            return (
                <NumberInputFormikField
                    id={id}
                    name={id}
                    label={label}
                    isRequired={params.required}
                    clampValueOnBlur={false}
                    keepWithinRange={false}
                    isDisabled={!editable}
                    info={info}
                    min={params.min ?? undefined} // Chakra treats min={null} as min={0}
                    max={params.max ?? undefined} // Chakra treats max={null} as max={0}
                />
            );
        case 'checkbox':
            return (
                <CheckboxFormikField id={id} name={id} isRequired={params.required} isDisabled={!editable} info={info}>
                    {label}
                </CheckboxFormikField>
            );
        case 'date':
            return (
                <DateFormikField
                    id={id}
                    name={id}
                    label={label}
                    isRequired={params.required}
                    dateFormat={params.dateFormat}
                    showTimeInput={params.showTimeInput}
                    isDisabled={!editable}
                    minDate={params.minDate}
                    maxDate={params.maxDate}
                    info={info}
                />
            );
        default:
            return (
                <InputFormikField
                    id={id}
                    name={id}
                    label={label}
                    type="text"
                    isRequired={params.required}
                    isDisabled={!editable}
                    info={info}
                />
            );
    }
};

export default AddDataModalField;
