import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    Heading,
    Flex,
    Box,
    SimpleGrid,
    Divider,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Spacer,
    Collapse,
    IconButton,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import { Formik, Form } from 'formik';

import { onHasUnsavedChanges } from '../store/helpers/helpersSlice';

import { checkItemInputEvent } from '../services/items';
import { preserveConnectionSearchParams } from '../services/connections';
import {
    getSpotPriceDefinition,
    getSpotPriceDropdownOptions,
    deleteSpotPrice,
    editSpotPrice,
    formatLookupValues,
} from '../services/spotPrice';
import {
    CAISO_OPTION,
    ISO_RTO_OPTIONS,
    DARTM_OPTIONS,
    DARTM_OPTIONS_CAISO,
    TRAVERSE_INPUT_TABLE_DESCRIPTION,
    POWERSIMM_INPUT_TABLE_DESCRIPTION,
    isSubmitDisabled,
} from '../services/utils';
import { getElectricNodeListData } from '../services/traverseApi';

import useHistoryPush from '../hooks/useHistoryPush';
import useCommonToast from '../hooks/useCommonToast';

import LoadingModal from '../components/modal/LoadingModal';

import AutomaticFormikDirtyCheck from '../components/forms/AutomaticFormikDirtyCheck';
import Error from '../components/utils/Error';
import DefinitionsSkeleton from '../components/utils/DefinitionsSkeleton';
import Tooltip from '../components/utils/Tooltip';
import ActionBar from '../components/utils/ActionBar';

import PromptWrapper from '../components/utils/PromptWrapper';

import ComboboxFormikField from '../components/forms/ComboboxFormikField';
import NumberInputFormikField from '../components/forms/NumberInputFormikField';
import InputFormikField from '../components/forms/InputFormikField';

import SpotPriceData from '../components/spotPrice/SpotPriceData';
import SpotPriceLookupValuesField from '../components/spotPrice/SpotPriceLookupValuesField';

import SortOrderProvider from '../components/grid/utils/SortOrderContext';

import { ReactComponent as EditIcon } from '../icons/edit-circled.svg';
import { ReactComponent as SpotPriceIcon } from '../icons/spot-price.svg';
import { ReactComponent as SaveIcon } from '../icons/save.svg';
import { ReactComponent as BreadcrumbSeparatorIcon } from '../icons/breadcrumb-separator.svg';
import CustomItemIcon from 'components/utils/CustomItemIcon';
import InfoPopover from 'components/forms/InfoPopover';

const SpotPrice = () => {
    const { spotPriceId } = useParams();
    const { search } = useLocation();

    const intl = useIntl();
    const { toast } = useCommonToast();
    const dispatch = useDispatch();

    const historyPush = useHistoryPush();
    const formRef = useRef();

    const searchParams = new URLSearchParams(search);
    const basisId = searchParams.get('basisId');
    const mode = searchParams.get('mode');
    const isNewItem = mode !== null && mode === 'new';

    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);

    const [spotPriceDefinition, setSpotPriceDefinition] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [spotPriceDropdownOptions, setSpotPriceDropdownOptions] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [inEditDescription, setInEditDescription] = useState(false);

    const [showTraverseOptions, setShowTraverseOptions] = useState(false);

    const [electricNodeList, setElectricNodeList] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [confirmedDiscardChanges, setConfirmedDiscardChanges] = useState(false);

    const TRAVERSE_INPUT_TABLE_ID = useMemo(() => {
        return spotPriceDropdownOptions.data?.inputTableMaps.find(
            (inputTable) => inputTable.description === TRAVERSE_INPUT_TABLE_DESCRIPTION
        )?.id;
    }, [spotPriceDropdownOptions.data?.inputTableMaps]);

    const POWERSIMM_INPUT_TABLE_ID = useMemo(() => {
        return spotPriceDropdownOptions.data?.inputTableMaps.find(
            (inputTable) => inputTable.description === POWERSIMM_INPUT_TABLE_DESCRIPTION
        )?.id;
    }, [spotPriceDropdownOptions.data?.inputTableMaps]);

    const fetchElectricNodeListData = useCallback(async (isoRto) => {
        const electricNodes = await getElectricNodeListData(isoRto);
        setElectricNodeList(electricNodes);
    }, []);

    const fetchSpotPriceData = useCallback(() => {
        return getSpotPriceDefinition(spotPriceId)
            .then(async (data) => {
                setSpotPriceDefinition((prev) => ({ ...prev, error: false, data }));

                if (data.inputTableMapId === TRAVERSE_INPUT_TABLE_ID) {
                    const { isoRto, electricNode, dartm } = formatLookupValues(data.lookupIdValues);
                    await fetchElectricNodeListData(isoRto);
                    formRef.current.setValues({
                        ...formRef.current.values,
                        isoRto,
                        electricNode,
                        dartm,
                    });
                    setShowTraverseOptions(true);
                }
            })
            .catch(() => setSpotPriceDefinition((prev) => ({ ...prev, error: true })))
            .finally(() => setSpotPriceDefinition((prev) => ({ ...prev, loading: false })));
    }, [spotPriceId, TRAVERSE_INPUT_TABLE_ID, fetchElectricNodeListData]);

    const fetchSpotPriceDropdownOptions = useCallback(() => {
        return getSpotPriceDropdownOptions()
            .then((data) => setSpotPriceDropdownOptions((prev) => ({ ...prev, error: false, data })))
            .catch(() => setSpotPriceDropdownOptions((prev) => ({ ...prev, error: true })))
            .finally(() => setSpotPriceDropdownOptions((prev) => ({ ...prev, loading: false })));
    }, []);

    const fetchData = useCallback(async () => {
        await fetchSpotPriceDropdownOptions();
        fetchSpotPriceData();
    }, [fetchSpotPriceDropdownOptions, fetchSpotPriceData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const navigateToBasisConfigurator = (forceRedirect = false) => {
        historyPush({
            pathname: `/basis-configurator/${basisId}`,
            hash: '#spot-price',
            search: preserveConnectionSearchParams(window.location.search),
            forceRedirect,
        });
    };

    const formLabels = {
        name: intl.formatMessage({ id: 'spot_price_name' }),
        inputTable: intl.formatMessage({ id: 'spot_price_input_table' }),
        isoRto: intl.formatMessage({ id: 'spot_price_iso_rto' }),
        electricNode: intl.formatMessage({ id: 'spot_price_electric_node' }),
        dartm: intl.formatMessage({ id: 'spot_price_dartm' }),
        lookupValues: intl.formatMessage({ id: 'spot_price_lookup_values' }),
        moduleProcessId: intl.formatMessage({ id: 'spot_price_module_process' }),
        commodityId: intl.formatMessage({ id: 'spot_price_commodity' }),
        spotPriceWeightId: intl.formatMessage({ id: 'spot_price_weight_schema' }),
        minSimPrice: intl.formatMessage({ id: 'spot_price_min_sim_price' }),
        maxSimPrice: intl.formatMessage({ id: 'spot_price_max_sim_price' }),
        onPeakPeakPeriodId: intl.formatMessage({ id: 'spot_price_on_peak_period' }),
        offPeakPeakPeriodId: intl.formatMessage({ id: 'spot_price_off_peak_period' }),
        atcPeakPeriodId: intl.formatMessage({ id: 'spot_price_atc_peak_period' }),
    };

    const initialFormValues = {
        description: spotPriceDefinition.data?.description,
        inputTable: spotPriceDefinition.data?.inputTableMapId,
        lookupValues: spotPriceDefinition.data?.lookupIdValues || '',
        moduleProcessId: spotPriceDefinition.data?.moduleProcessId,
        commodityId: spotPriceDefinition.data?.commodityId,
        spotPriceWeightId: spotPriceDefinition.data?.spotPriceWeightId,
        minSimPrice: spotPriceDefinition.data?.minPrice,
        maxSimPrice: spotPriceDefinition.data?.maxPrice,
        onPeakPeakPeriodId: spotPriceDefinition.data?.onPeakPeakPeriodId,
        offPeakPeakPeriodId: spotPriceDefinition.data?.offPeakPeakPeriodId,
        atcPeakPeriodId: spotPriceDefinition.data?.atcPeakPeriodId,
    };

    const onFormSubmit = async (params) => {
        const successMessage = intl.formatMessage({ id: 'spot_price_save_changes_success' });
        const {
            description,
            commodityId,
            moduleProcessId,
            minSimPrice,
            maxSimPrice,
            spotPriceWeightId,
            inputTable,
            lookupValues,
            onPeakPeakPeriodId,
            offPeakPeakPeriodId,
            atcPeakPeriodId,
        } = params;

        const payload = {
            description,
            commodityId,
            moduleProcessId,
            minPrice: parseFloat(minSimPrice),
            maxPrice: parseFloat(maxSimPrice),
            spotPriceWeightId,
            inputTableMapId: inputTable,
            lookupIdValues: lookupValues,
            onPeakPeakPeriodId,
            offPeakPeakPeriodId,
            atcPeakPeriodId,
        };

        await editSpotPrice(spotPriceId, payload);
        toast(successMessage);
        navigateToBasisConfigurator(true);
    };

    const onInputTableChange = (value) => {
        if (value === TRAVERSE_INPUT_TABLE_ID) {
            formRef.current.setValues({
                ...formRef.current.values,
                inputTable: value,
                lookupValues: '',
                isoRto: '',
                electricNode: '',
                dartm: '',
            });

            setShowTraverseOptions(true);
        } else {
            if (showTraverseOptions) {
                const { isoRto, electricNode, dartm, ...rest } = formRef.current.values;
                formRef.current.setValues({ ...rest, inputTable: value, lookupValues: '' });
                setShowTraverseOptions(false);
            }
        }
    };

    const onIsoRtoOptionChange = async (value) => {
        setIsLoadingData(true);
        const electricNodes = await getElectricNodeListData(value);
        setElectricNodeList(electricNodes);
        formRef.current.setValues({
            ...formRef.current.values,
            electricNode: '',
            lookupValues: '',
            dartm: '',
        });
        setIsLoadingData(false);
    };

    const onCancelSpotPriceCreationConfirm = async () => {
        const successMessage = intl.formatMessage({ id: 'spot_price_delete_success' });
        await deleteSpotPrice(spotPriceId);
        toast(successMessage);
    };

    const onBasisSetupBreadcrumbClicked = () => {
        navigateToBasisConfigurator();
    };

    const validateItemName = (value) => {
        let error;
        const trimmed = value.trim();

        if (!trimmed) {
            error = intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Name' });
        }

        if (trimmed.length > 50) {
            error = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Name', lengthRule: '50 characters or less' }
            );
        }
        return error;
    };

    const validateRequiredField = (value, label) => {
        let error;
        if (value === '') {
            error = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }
        return error;
    };

    const validateSimPrice = (field, label, minSimPrice, maxSimPrice) => {
        let error;
        if ((field === 'minSimPrice' && minSimPrice === '') || (field === 'maxSimPrice' && maxSimPrice === '')) {
            error = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        } else if (field === 'minSimPrice' && maxSimPrice && Number(minSimPrice) >= Number(maxSimPrice)) {
            formRef.current.setFieldTouched('maxSimPrice', true, false);
            error = intl.formatMessage({ id: 'spot_price_min_sim_price_validation' });
        } else if (field === 'maxSimPrice' && minSimPrice && Number(maxSimPrice) <= Number(minSimPrice)) {
            formRef.current.setFieldTouched('minSimPrice', true, false);
            error = intl.formatMessage({ id: 'spot_price_max_sim_price_validation' });
        }
        return error;
    };

    const validateLookupValues = (inputTable, lookupValues) => {
        let error;

        if (inputTable && inputTable !== POWERSIMM_INPUT_TABLE_ID) {
            error = validateRequiredField(lookupValues, formLabels.lookupValues);
        }
        return error;
    };

    const validateForm = (values) => {
        const description = validateItemName(values.description);
        const isoRto = validateRequiredField(values, formLabels.isoRto);
        const electricNode = validateRequiredField(values.electricNode, formLabels.electricNode);
        const dartm = validateRequiredField(values.dartm, formLabels.dartm);

        const minSimPrice = validateSimPrice(
            'minSimPrice',
            formLabels.minSimPrice,
            values.minSimPrice,
            values.maxSimPrice
        );

        const maxSimPrice = validateSimPrice(
            'maxSimPrice',
            formLabels.maxSimPrice,
            values.minSimPrice,
            values.maxSimPrice
        );

        const lookupValues = validateLookupValues(values.inputTable, values.lookupValues);

        return {
            ...(description ? { description } : null),
            ...(isoRto ? { isoRto } : null),
            ...(electricNode ? { electricNode } : null),
            ...(dartm ? { dartm } : null),
            ...(minSimPrice ? { minSimPrice } : null),
            ...(maxSimPrice ? { maxSimPrice } : null),
            ...(lookupValues ? { lookupValues } : null),
        };
    };

    return (
        <>
            <Box pt={'80px'} pb={'50px'}>
                {spotPriceDefinition.loading || spotPriceDropdownOptions.loading ? (
                    <DefinitionsSkeleton />
                ) : spotPriceDefinition.error || spotPriceDropdownOptions.error ? (
                    <Box m={6}>
                        <Error
                            primaryId="common_error"
                            secondaryId="common_loading_error"
                            additionalText="spot price"
                        />
                    </Box>
                ) : (
                    <>
                        <Breadcrumb pt="12px" px="24px" separator={<BreadcrumbSeparatorIcon />}>
                            <BreadcrumbItem onClick={onBasisSetupBreadcrumbClicked}>
                                <BreadcrumbLink>
                                    <FormattedMessage id="basis_setup" />
                                </BreadcrumbLink>
                            </BreadcrumbItem>

                            <BreadcrumbItem isCurrentPage>
                                <BreadcrumbLink>
                                    <FormattedMessage id="spot_price_page_title" />
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        </Breadcrumb>

                        <Formik
                            enableReinitialize
                            initialValues={initialFormValues}
                            onSubmit={onFormSubmit}
                            innerRef={formRef}
                            validate={validateForm}
                        >
                            {({ isSubmitting, errors, touched, values }) => (
                                <Form>
                                    <AutomaticFormikDirtyCheck
                                        isFormDirty={isFormDirty}
                                        onFormDirtyChange={setIsFormDirty}
                                    />
                                    <ActionBar>
                                        <Flex align="center">
                                            <CustomItemIcon icon={<SpotPriceIcon />} isStroke={true} />
                                            {!inEditDescription ? (
                                                <Heading px={2} as="h2" variant="h2">
                                                    {spotPriceDefinition.data.description}
                                                </Heading>
                                            ) : (
                                                <InputFormikField
                                                    px={3}
                                                    flex="1"
                                                    maxW="500px"
                                                    name="description"
                                                    type="text"
                                                    onKeyDown={checkItemInputEvent}
                                                />
                                            )}

                                            <Box display="inline-block" mr={4}>
                                                <Tooltip
                                                    label={intl.formatMessage({
                                                        id: 'common_edit_btn_tooltip_and_label',
                                                    })}
                                                >
                                                    <IconButton
                                                        aria-label={intl.formatMessage({
                                                            id: 'common_edit_btn_tooltip_and_label',
                                                        })}
                                                        variant="circular-icon"
                                                        leftIcon={<EditIcon />}
                                                        onClick={() => setInEditDescription((prev) => !prev)}
                                                    />
                                                </Tooltip>
                                            </Box>
                                            <InfoPopover
                                                info={<FormattedMessage id="spot_price_name_info" />}
                                                placement="bottom"
                                            />
                                        </Flex>

                                        <Spacer display={{ base: 'none', xl: 'block' }} />

                                        <Button variant="secondary" onClick={() => navigateToBasisConfigurator()}>
                                            <Box as="span" textTransform="capitalize">
                                                <FormattedMessage id={isNewItem ? 'common_cancel' : 'common_back'} />
                                            </Box>
                                        </Button>

                                        <Button
                                            leftIcon={<SaveIcon />}
                                            isDisabled={isSubmitDisabled({ errors, touched })}
                                            isLoading={isSubmitting}
                                            type="submit"
                                            size="sm"
                                            variant="primary-success"
                                        >
                                            <FormattedMessage id="common_save_and_return" />
                                        </Button>
                                    </ActionBar>

                                    <Divider />

                                    <Heading as="h3" variant="h3" px={6} py={7}>
                                        <FormattedMessage id="spot_price_definitions" />
                                    </Heading>

                                    <ComboboxFormikField
                                        id="inputTable"
                                        name="inputTable"
                                        label={formLabels.inputTable}
                                        options={spotPriceDropdownOptions.data.inputTableMaps}
                                        onChange={(value) => onInputTableChange(value)}
                                        labelKey="description"
                                        valueKey="id"
                                        pb={5}
                                        pl={6}
                                        pr={{ base: 6, lg: 0 }}
                                        width={{ base: '100%', lg: '33%' }}
                                    />

                                    <CustomCollapse in={showTraverseOptions} animateOpacity>
                                        <SimpleGrid columns={3} pb={5} px={6} spacingX={6} spacingY={5}>
                                            <ComboboxFormikField
                                                id="isoRto"
                                                name="isoRto"
                                                label={formLabels.isoRto}
                                                options={ISO_RTO_OPTIONS}
                                                valueKey="id"
                                                labelKey="description"
                                                onChange={onIsoRtoOptionChange}
                                                isRequired
                                            />

                                            <ComboboxFormikField
                                                id="electricNode"
                                                name="electricNode"
                                                label={formLabels.electricNode}
                                                options={electricNodeList}
                                                isRequired
                                                isDisabled={!values.isoRto}
                                            />

                                            <ComboboxFormikField
                                                id="dartm"
                                                name="dartm"
                                                label={formLabels.dartm}
                                                options={
                                                    values.isoRto === CAISO_OPTION ? DARTM_OPTIONS_CAISO : DARTM_OPTIONS
                                                }
                                                labelKey="description"
                                                valueKey="id"
                                                isRequired
                                                isDisabled={!values.isoRto}
                                            />
                                        </SimpleGrid>
                                    </CustomCollapse>

                                    <SimpleGrid columns={{ xl: 3 }} pb={5} px={6} spacingX={6} spacingY={5}>
                                        {showTraverseOptions ? (
                                            <SpotPriceLookupValuesField
                                                name="lookupValues"
                                                label={formLabels.lookupValues}
                                                isDisabled
                                            />
                                        ) : (
                                            <InputFormikField
                                                name="lookupValues"
                                                type="text"
                                                label={formLabels.lookupValues}
                                                isRequired={
                                                    values.inputTable && values.inputTable !== POWERSIMM_INPUT_TABLE_ID
                                                }
                                            />
                                        )}

                                        <ComboboxFormikField
                                            id="commodityId"
                                            name="commodityId"
                                            label={formLabels.commodityId}
                                            options={spotPriceDropdownOptions.data.commodities}
                                            labelKey="description"
                                            valueKey="id"
                                            isRequired
                                        />

                                        <ComboboxFormikField
                                            id="moduleProcessId"
                                            name="moduleProcessId"
                                            label={formLabels.moduleProcessId}
                                            options={spotPriceDropdownOptions.data.moduleProcesses}
                                            labelKey="description"
                                            valueKey="id"
                                            isRequired
                                        />

                                        <ComboboxFormikField
                                            id="spotPriceWeightId"
                                            name="spotPriceWeightId"
                                            label={formLabels.spotPriceWeightId}
                                            options={spotPriceDropdownOptions.data.modelWeights}
                                            labelKey="description"
                                            valueKey="id"
                                            isRequired
                                        />

                                        <NumberInputFormikField
                                            id="minSimPrice"
                                            name="minSimPrice"
                                            label={formLabels.minSimPrice}
                                            isRequired
                                        />

                                        <NumberInputFormikField
                                            id="maxSimPrice"
                                            name="maxSimPrice"
                                            label={formLabels.maxSimPrice}
                                            isRequired
                                        />

                                        <ComboboxFormikField
                                            id="onPeakPeakPeriodId"
                                            name="onPeakPeakPeriodId"
                                            label={formLabels.onPeakPeakPeriodId}
                                            options={spotPriceDropdownOptions.data.peakPeriods}
                                            labelKey="description"
                                            valueKey="id"
                                            isRequired
                                        />

                                        <ComboboxFormikField
                                            id="offPeakPeakPeriodId"
                                            name="offPeakPeakPeriodId"
                                            label={formLabels.offPeakPeakPeriodId}
                                            options={spotPriceDropdownOptions.data.peakPeriods}
                                            labelKey="description"
                                            valueKey="id"
                                            isRequired
                                        />

                                        <ComboboxFormikField
                                            id="atcPeakPeriodId"
                                            name="atcPeakPeriodId"
                                            label={formLabels.atcPeakPeriodId}
                                            options={spotPriceDropdownOptions.data.peakPeriods}
                                            labelKey="description"
                                            valueKey="id"
                                            isRequired
                                        />
                                    </SimpleGrid>
                                </Form>
                            )}
                        </Formik>

                        <SortOrderProvider>
                            <SpotPriceData
                                spotPriceId={spotPriceId}
                                peakPeriods={spotPriceDropdownOptions.data.peakPeriods}
                            />
                        </SortOrderProvider>

                        <PromptWrapper
                            when={hasUnsavedChanges || (isNewItem && !confirmedDiscardChanges)}
                            onPromptConfirm={() => {
                                if (isNewItem) {
                                    setConfirmedDiscardChanges(true);
                                    onCancelSpotPriceCreationConfirm();
                                }
                            }}
                            header={<FormattedMessage id="common_discard_changes" />}
                            content={
                                <FormattedMessage
                                    id={
                                        isNewItem ? 'spot_price_cancel_confirmation' : 'common_confirmation_explanation'
                                    }
                                />
                            }
                        />
                    </>
                )}
            </Box>

            {isLoadingData && <LoadingModal header={intl.formatMessage({ id: 'common_loading' })} isOpen />}
        </>
    );
};

const CustomCollapse = styled(Collapse)`
    overflow: visible !important;
`;

export default SpotPrice;
