import React, { useCallback, useMemo } from 'react';
import { MenuItem, Link } from '@chakra-ui/react';
import CustomNavLink from '../../utils/CustomNavLink';
import styled from '@emotion/styled/macro';
import useUserPermissions from '../../../hooks/useUserPermissions';
import { ReactComponent as PremiumIcon } from 'icons/premium.svg';

const NavigationMenuItem = ({ config }) => {
    const { isAdmin } = useUserPermissions();
    const handler = useCallback((event) => config?.clickHandler && config.clickHandler(event), [config]);

    const isDisabled = useMemo(() => config?.adminOnly && !isAdmin, [config, isAdmin]);
    const isPremium = useMemo(() => config?.isEnabled === false, [config]);

    return isDisabled ? (
        <StyledMenuItem
            as={Link}
            onClick={handler}
            isDisabled={isDisabled}
            color="menu-item-text"
            _hover={{ bg: 'menu-item-hover' }}
        >
            {config.label}
        </StyledMenuItem>
    ) : config.url.startsWith('http') ? (
        <StyledMenuItem
            as={Link}
            href={config.url}
            target="_blank"
            onClick={handler}
            color="menu-item-text"
            _hover={{ bg: 'menu-item-hover' }}
        >
            {config.label}
        </StyledMenuItem>
    ) : (
        <StyledMenuItem
            as={CustomNavLink}
            exact={config.url === '/'}
            to={config.url}
            onClick={handler}
            color="menu-item-text"
            display="flex"
            justifyContent="space-between"
            _hover={{ bg: 'menu-item-hover' }}
        >
            {config.label}
            {isPremium && <PremiumIcon />}
        </StyledMenuItem>
    );
};

const StyledMenuItem = styled(MenuItem)`
    padding: 10px var(--chakra-space-4);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 6px;

    &[aria-disabled='true'] {
        opacity: 1;
        color: var(--chakra-colors-gray-400);
    }

    &:hover {
        text-decoration: none;
    }

    &.active {
        color: var(--chakra-colors-black);
        background-color: var(--chakra-colors-blue-50);
    }

    &:focus {
        box-shadow: none;
    }
`;

export default NavigationMenuItem;
