import { useState, useEffect, useRef } from 'react';
import { Button, SimpleGrid, FormLabel, Box } from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';

import { getVariableStatistics, setVariableStatistics } from '../../services/studies';

import MainModal from '../modal/MainModal';

import SimpleGridSkeleton from '../../components/utils/SimpleGridSkeleton';
import Error from '../utils/Error';

import CustomCheckbox from '../forms/CustomCheckbox';

const VariableStatisticsModal = ({ id, group, onClose, onSuccess, onError }) => {
    const [checked, setChecked] = useState([]);
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const initialChecked = useRef([]);

    useEffect(() => {
        (async () => {
            try {
                const statistics = await getVariableStatistics(id, group.variableGroupId);

                const checkedStatistics = statistics
                    .filter((elem) => elem.isSelected)
                    .map((elem) => elem.variableStatisticId);

                initialChecked.current = checkedStatistics.sort().join(',');

                const meanStatistic = statistics.find((statistic) => statistic.name.toUpperCase() === 'MEAN');

                if (meanStatistic) {
                    const meanStatisticId = meanStatistic.variableStatisticId;
                    const isMeanStatisticSelected = meanStatistic.isSelected;

                    if (!isMeanStatisticSelected) {
                        checkedStatistics.push(meanStatisticId);
                    }
                }

                setChecked(checkedStatistics);
                statistics.sort((a, b) => a.name.localeCompare(b.name));

                setStatistics(statistics);
            } catch {
                setLoadingError(true);
            } finally {
                setLoading(false);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleCheckbox = (id) => {
        setChecked((prev) => {
            return prev.includes(id) ? prev.filter((v) => v !== id) : prev.concat(id);
        });
    };

    const submitChange = async () => {
        setSubmitLoading(true);
        let payload = {
            variableId: id,
            variableGroupId: group.variableGroupId,
            statisticIds: checked,
        };
        try {
            await setVariableStatistics(payload);
            setSubmitLoading(false);

            const iterationVar = statistics.find((elem) => elem.name === 'ITERATIONS');
            const meanVar = statistics.find((elem) => elem.name === 'MEAN');
            onSuccess(
                id,
                group.variableGroupId,
                checked.length,
                checked.includes(iterationVar.variableStatisticId),
                checked.includes(meanVar.variableStatisticId)
            );
        } catch (err) {
            onError(err);
        }
    };

    const isDirty = () => {
        return checked.slice().sort().join(',') === initialChecked.current;
    };

    return (
        <MainModal
            size="4xl"
            scrollBehavior="inside"
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="run_a_study_edit_statistics" />}
            content={
                <>
                    {loading || loadingError ? (
                        <>
                            {loading && <SimpleGridSkeleton columns={12} rows={4} />}
                            {loadingError && (
                                <Box mt={6}>
                                    <Error
                                        primaryId="common_error"
                                        secondaryId="common_loading_error"
                                        additionalText="variable statistics"
                                    />
                                </Box>
                            )}
                        </>
                    ) : (
                        <>
                            <FormLabel>
                                <FormattedMessage id="run_a_study_select_statistics" />
                            </FormLabel>
                            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                                {statistics.map(({ variableStatisticId, name }) => (
                                    <CustomCheckbox
                                        key={variableStatisticId}
                                        isChecked={checked.includes(variableStatisticId)}
                                        onChange={() => toggleCheckbox(variableStatisticId)}
                                        isDisabled={name.toUpperCase() === 'MEAN'}
                                    >
                                        {name}
                                    </CustomCheckbox>
                                ))}
                            </SimpleGrid>
                        </>
                    )}
                </>
            }
            footerRightSlot={
                <Button isDisabled={isDirty()} isLoading={submitLoading} onClick={submitChange} ml={3}>
                    <FormattedMessage id="common_save_changes_button" />
                </Button>
            }
        />
    );
};

export default VariableStatisticsModal;
