import { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Autoform from './Autoform';
import SpotPriceFields from '../components/spotPriceAutoform/SpotPriceFields';
import { getInputFormName } from 'components/autoform/utils/autoformUtils';
import { SPOT_PRICE_AUTOFORM_SCHEMA_CODE } from 'constants/spotPrice';

const initialValues = {
    isoRto: '',
    electricNode: '',
    dartm: '',
};

const SpotPriceAutoform = () => {
    const [extraInitialValues, setExtraInitialValues] = useState(initialValues);
    const getAddRecordInputName = useCallback((_, __, columnName) => columnName, []);
    const location = useLocation();

    useEffect(() => {
        setExtraInitialValues(initialValues);
    }, [location]);

    return (
        <Autoform
            config={{
                autoformId: SPOT_PRICE_AUTOFORM_SCHEMA_CODE,
                level1InputFields: (
                    <SpotPriceFields
                        getInputFormName={getInputFormName}
                        mode="edit"
                        setInitialValues={setExtraInitialValues}
                    />
                ),
                addRecordFields: (
                    <SpotPriceFields
                        getInputFormName={getAddRecordInputName}
                        mode="add"
                        setInitialValues={setExtraInitialValues}
                    />
                ),
                extraInitialValues,
            }}
        />
    );
};

export default SpotPriceAutoform;
