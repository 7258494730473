import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import {
    DEFAULT_HUB_CONNECTION_URL,
    FINISHED_STATUSES,
    INITIAL_STATUS,
    SUCCESS_STATUS,
    SUCCESS_WARNING_STATUS,
} from 'constants/uploadDownload';
import { useIntl } from 'react-intl';
import { scopes } from 'services/auth';
import { fileUploadRequest, getHumanFileSize } from 'services/items';
import useExceptionToast from './useExceptionToast';

export const useFileUpload = (onAfterUploadChange, hubConnectionUrl) => {
    const intl = useIntl();
    const hubConnection = useRef(null);
    const reqAbortController = useRef(null);
    const { instance } = useMsal();
    const [percentage, setPercentage] = useState(0);
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [sseClientId, setSseClientId] = useState('');
    const [error, setError] = useState('');
    const { exceptionToast } = useExceptionToast();

    const [phase, setPhase] = useState({
        status: INITIAL_STATUS,
        title: intl.formatMessage({ id: 'upload_modal_initial_stage_title' }),
    });

    const closeHubConnection = () => {
        if (hubConnection.current) {
            hubConnection.current?.stop();
            hubConnection.current = null;
        }
        setIsLoading(false);
        setPercentage(0);
        setFileUploadProgress(0);
    };

    const cancelUpload = () => {
        if (reqAbortController.current) {
            reqAbortController.current?.abort();
        }
    };

    const handleFileChange = (file) => {
        const formattedFile = new File([file], file.name, { type: file.type });
        formattedFile['friendlySize'] = getHumanFileSize(file.size, true, 2);

        setFile(formattedFile);
    };

    const startUpload = useCallback(
        async (route, queryParams = {}, headers = {}) => {
            setIsLoading(true);

            if (reqAbortController.current) {
                reqAbortController.current?.abort();
            }

            reqAbortController.current = new AbortController();
            const signal = reqAbortController.current?.signal;

            try {
                await fileUploadRequest(route, file, queryParams, headers, { signal });
                setError('');
            } catch (err) {
                if (err?.code === 'ERR_CANCELED') {
                    setError(intl.formatMessage({ id: 'upload_request_canceled_message' }));
                } else {
                    const errorMessage =
                        err.response?.data?.error || intl.formatMessage({ id: 'upload_modal_failed_import' });
                    setError(errorMessage);
                    const validationException =
                        err.response?.data?.details &&
                        err.response?.data?.details?.toUpperCase().includes('VALIDATIONEXCEPTION');

                    if (validationException) {
                        const toastErrorDetails = err.response?.data?.details;
                        exceptionToast(
                            intl.formatMessage({ id: 'upload_modal_validation_exception' }),
                            toastErrorDetails
                        );
                    }
                }
            } finally {
                setIsLoading(false);
                reqAbortController.current = null;
            }
        },
        [file, intl, exceptionToast]
    );

    const removeFile = () => {
        setFile(null);
    };

    const friendlyUploadProgress = useMemo(() => {
        return getHumanFileSize(fileUploadProgress, true, 2);
    }, [fileUploadProgress]);

    useEffect(() => {
        const url = hubConnectionUrl ?? DEFAULT_HUB_CONNECTION_URL;

        const handleReceiveStatusUpdate = (statusData) => {
            setPhase(statusData);
            setPercentage(0);

            if (
                (statusData.status === SUCCESS_WARNING_STATUS || statusData.status === SUCCESS_STATUS) &&
                onAfterUploadChange
            ) {
                onAfterUploadChange();
            }

            if (FINISHED_STATUSES.includes(statusData.status)) closeHubConnection();
        };

        const handleReceivePercentageUpdate = (percentage) => {
            setPercentage(percentage);
        };

        hubConnection.current = new HubConnectionBuilder()
            .withUrl(url, {
                accessTokenFactory: async () => {
                    const authResult = await instance.acquireTokenSilent({ scopes: scopes });
                    return authResult?.accessToken;
                },
            })
            .withAutomaticReconnect()
            .build();

        hubConnection.current.on('ReceiveStatusUpdate', handleReceiveStatusUpdate);
        hubConnection.current.on('ReceivePercentageUpdate', handleReceivePercentageUpdate);

        hubConnection.current.start({ withCredentials: false }).then(() => {
            const sseClientId = hubConnection.current.connection.connectionId;
            setSseClientId(sseClientId);
        });

        return () => {
            hubConnection.current?.off('ReceiveStatusUpdate', handleReceiveStatusUpdate);
            hubConnection.current?.off('ReceivePercentageUpdate', handleReceivePercentageUpdate);
            closeHubConnection();
        };
    }, [hubConnectionUrl, instance, onAfterUploadChange]);

    return {
        removeFile,
        cancelUpload,
        startUpload,
        handleFileChange,
        file,
        error,
        phase,
        isLoading,
        percentage,
        sseClientId,
        fileUploadProgress,
        friendlyUploadProgress,
    };
};
