import { FormattedMessage } from 'react-intl';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    UnorderedList,
    ListItem,
    CloseButton,
} from '@chakra-ui/react';

const GeneralFormValidationsAlert = ({ title, messages, isOpen = true, onClose, status = 'error', ...rest }) => {
    return (
        isOpen && (
            <Alert status={status} alignItems="flex-start" py={4} mb={4} {...rest}>
                <AlertIcon />
                <Box flex="1">
                    <AlertTitle textTransform="capitalize">
                        {title ?? <FormattedMessage id="common_errors" />}
                    </AlertTitle>

                    <AlertDescription display="block">
                        <UnorderedList>
                            {messages.map((message, index) => (
                                <ListItem key={index}>{message}</ListItem>
                            ))}
                        </UnorderedList>
                    </AlertDescription>
                </Box>

                {onClose && <CloseButton onClick={onClose} />}
            </Alert>
        )
    );
};

export default GeneralFormValidationsAlert;
