import { FormattedMessage, useIntl } from 'react-intl';
import CustomIconButton from '../utils/CustomIconButton';
import Popover from '../utils/Popover';

import { ReactComponent as InfoIcon } from '../../icons/info.svg';

import { getSelectedReportRequirements, getSelectedProperties } from '../../services/studies';
import { formatDate, formatDateOnly, enUSFormatExcludingTime } from '../../services/items';

import StudySummaryGrid from './StudySummaryGrid';
import { FEATURE_FLAG_NAMES, useIsFeatureActive } from '../../hooks/features-flags';

const StudyInfoGrid = ({ studyData }) => {
    const intl = useIntl();
    const isFOSimulationsFieldVisible = useIsFeatureActive(FEATURE_FLAG_NAMES.STUDY_SETTINGS_FO_SIMS_COUNT_VISIBLE);

    const labels = {
        portfolios: intl.formatMessage({ id: 'portfolios_title' }),
        simulations: intl.formatMessage({ id: 'run_a_study_label_simulations' }),
        foSimulations: intl.formatMessage({ id: 'run_a_study_label_fo_simulations' }),
        priority: intl.formatMessage({ id: 'run_a_study_settings_priority' }),
        reports: intl.formatMessage({ id: 'run_a_study_settings_report_requirements' }),
        timeIntervals: intl.formatMessage({ id: 'run_a_study_settings_time_intervals' }),
        peakPeriods: intl.formatMessage({ id: 'run_a_study_settings_peak_periods' }),
        sensitivity: intl.formatMessage({ id: 'run_a_study_settings_sensitivity' }),
        variables: intl.formatMessage({ id: 'run_a_study_variables' }),
        runDate: intl.formatMessage({ id: 'run_a_study_settings_run_schedule' }),
        startDate: intl.formatMessage({ id: 'common_grid_start_date' }),
        endDate: intl.formatMessage({ id: 'common_grid_end_date' }),
        book: intl.formatMessage({ id: 'common_book' }),
        codebase: intl.formatMessage({ id: 'run_a_study_label_codebase' }),
        customCodebase: intl.formatMessage({ id: 'run_a_study_label_customCodebase' }),
        notes: intl.formatMessage({ id: 'run_a_study_label_notes' }),
        properties: intl.formatMessage({ id: 'run_a_study_label_studyProperties' }),
    };

    const isDeterministicChecked =
        parseInt(studyData.study.simulations) === 1 && parseInt(studyData.study.loadSimReps) === 1;

    let foSimulationsField = null;
    if (isFOSimulationsFieldVisible || parseInt(studyData.study.loadSimReps) > 1) {
        foSimulationsField = {
            // Deterministic checkbox disables both sim and FO sim counts and sends "1" to the database.
            title: labels.foSimulations,
            value: (
                <>
                    {studyData.study.loadSimReps}
                    {isDeterministicChecked && (
                        <Popover
                            placement="top"
                            trigger={
                                <CustomIconButton
                                    mt="0px !important"
                                    ml="5px !important"
                                    background="none !important"
                                    float="none !important"
                                    icon={<InfoIcon />}
                                />
                            }
                        >
                            <FormattedMessage id="study_info_deterministic_label" />
                        </Popover>
                    )}
                </>
            ),
        };
    }

    const summaryData = [
        {
            title: labels.portfolios,
            value: studyData.portfolios.map((portfolio) => portfolio.properties.name).join(', '),
        },
        {
            title: labels.simulations,
            value: (
                <>
                    {studyData.study.simulations}
                    {isDeterministicChecked && (
                        <Popover
                            placement="top"
                            trigger={
                                <CustomIconButton
                                    mt="0px !important"
                                    ml="5px !important"
                                    background="none !important"
                                    float="none !important"
                                    icon={<InfoIcon />}
                                />
                            }
                        >
                            <FormattedMessage id="study_info_deterministic_label" />
                        </Popover>
                    )}
                </>
            ),
        },
        foSimulationsField,
        {
            title: labels.priority,
            value: studyData.settings.priorities.join(', '),
        },
        {
            title: labels.reports,
            value: getSelectedReportRequirements({
                ptdPayOff: studyData.study.ptdPayOff,
                simReports: studyData.study.simReports,
            }),
        },
        {
            title: labels.timeIntervals,
            value: studyData.settings.dateIntervals.map((dateInt) => dateInt.description).join(', '),
        },
        {
            title: labels.peakPeriods,
            value: studyData.settings.peakPeriods.map((period) => period.description).join(', '),
        },
        {
            title: labels.sensitivity,
            value: studyData.settings.sensitivities.map((sensitivity) => sensitivity.description).join(', '),
        },
        {
            title: labels.variables,
            value: studyData.selectedVariablesCount,
        },
        {
            title: labels.runDate,
            value: formatDate(new Date(studyData.study.scheduledRunDateTime)),
        },
        {
            title: labels.startDate,
            value: formatDateOnly(studyData.study.studyStartDate, enUSFormatExcludingTime),
        },
        {
            title: labels.endDate,
            value: formatDateOnly(studyData.study.studyEndDate, enUSFormatExcludingTime),
        },
        {
            title: labels.book,
            value: studyData.study.bookName,
        },
        {
            title: labels.notes,
            value: studyData.study.notes,
        },
        {
            title: labels.properties,
            value: getSelectedProperties(studyData.dynamicProperties).join(', '),
        },
        {
            title: labels.codebase,
            value: studyData.codebase,
        },
        {
            title: labels.customCodebase,
            value: studyData.customCodebase,
        },
    ];

    return <StudySummaryGrid data={summaryData} />;
};

export default StudyInfoGrid;
