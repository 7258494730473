import { useEffect, useMemo, useState } from 'react';

const useGroupsInitialValues = ({ autoformInitialValues, groupUsers, groupPermissions }) => {
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        setInitialValues((initialValues) => ({
            ...initialValues,
            ...autoformInitialValues,
        }));
    }, [autoformInitialValues]);

    useEffect(() => {
        setInitialValues((initialValues) => ({
            ...initialValues,
            groupUsers: groupUsers.reduce((acc, groupUser) => {
                acc[groupUser.value] = groupUser.isChecked;
                return acc;
            }, {}),
            groupPermissions: groupPermissions.reduce((acc, userRole) => {
                acc[userRole.value] = userRole.isChecked;

                return acc;
            }, {}),
        }));
    }, [groupUsers, groupPermissions]);

    return useMemo(() => ({ initialValues, setInitialValues }), [initialValues]);
};

export default useGroupsInitialValues;
