import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * Selects the autoform metadata from the store and does useful data
 * transformation.
 * metadata, hierarchicalTables, tableMap, itemTypeId
 * @returns {object} metadata - The raw autoform metadata.
 * @returns {{ level1: tableMetadata[], level2: tableMetadata[], level3: tableMetadata[], level4: tableMetadata[]}} hierarchicalTables -
 * table metadata grouped by the level of each table.
 * @returns {{[tableId]: tableMetadata}} tableMap - Map of all tables for quick access by id.
 * @returns {string} itemTypeId - The item type id parameter used to determine whether the autoform is an item
 * and can be added to portfolios.
 */
const useAutoformMetadata = () => {
    const metadata = useSelector((state) => state.autoform.metadata);

    const itemTypeId = useMemo(
        () => metadata?.parameters.find((parameter) => parameter?.name === 'ITEMTYPEID'),
        [metadata?.parameters]
    );

    const getTablesByLevel = useCallback(
        (level) => {
            return metadata.tables.filter((table) => table.level === level);
        },
        [metadata]
    );

    const hierarchicalTables = useMemo(() => {
        if (!metadata) {
            return {
                level1: [],
                level2: [],
                level3: [],
                level4: [],
            };
        }

        return {
            level1: getTablesByLevel(1),
            level2: getTablesByLevel(2),
            level3: getTablesByLevel(3),
            level4: getTablesByLevel(4),
        };
    }, [metadata, getTablesByLevel]);

    const tableMap = useMemo(() => {
        if (!metadata) return {};
        return metadata.tables.reduce((acc, item) => {
            acc[item.id] = item;

            return acc;
        }, {});
    }, [metadata]);
    return { metadata, hierarchicalTables, tableMap, itemTypeId };
};

export default useAutoformMetadata;
