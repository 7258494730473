import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    getAutoformTableData,
    saveAutoformRecords as saveAutoformRecordsService,
    deleteAutoformTableRow as deleteAutoformTableService,
    deleteAutoformTableRows as deleteAutoformTableRowsService,
    addSingleAutoformTableRow as addAutoformTableRowService,
    refetchSelectedItemBackendData as refetchBackendData,
    sanitizeDates,
    getBulkUpdateData as getAutoformBulkUpdateData,
} from '../../services/autoforms';
import { copyItem as copyAutoformRecordsService } from '../../services/items';

export const getAutoformMetadata = createAsyncThunk('getAutoformMetadata', async (autoformCode, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
        source.cancel();
    });
    const response = await axios.get(`/auto-forms/${autoformCode}/metadata`, {
        cancelToken: source.token,
    });
    return response.data;
});

export const getAutoformLevelMeta = createAsyncThunk(
    'getAutoformLevelMeta',
    async ({ schemaCode, tableId }, { signal }) => {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });
        const response = await axios.get(`/auto-forms/${schemaCode}/tables/${tableId}`, {
            cancelToken: source.token,
        });
        return {
            schemaCode,
            tableId,
            meta: response.data,
        };
    }
);

export const getAutoformLevelData = createAsyncThunk(
    'getAutoformLevelData',
    async ({ tableId, parameters, pageSize, page, selectedParentRecordId, filters, dateTimeFilters }, { signal }) => {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });
        const data = await getAutoformTableData({
            tableId,
            parameters,
            selectedParentRecordId,
            pageSize,
            page,
            cancelToken: source.token,
            filters,
            dateTimeFilters,
        });
        return {
            tableId,
            data: data.items,
        };
    }
);

export const getAutoformLevelDropdowns = createAsyncThunk(
    'getAutoformLevelDropdowns',
    async ({ tableId, parameters, parentRecordId }, { signal }) => {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });
        const params = new URLSearchParams();
        parameters.forEach((parameterValue) => {
            params.append('parameters', parameterValue);
        });

        if (parentRecordId) {
            params.append('parentRecordId', parentRecordId);
        }

        const response = await axios.get(`/auto-forms/dropdown-data/table/${tableId}`, {
            cancelToken: source.token,
            params,
        });
        return {
            tableId,
            data: response.data,
        };
    }
);

export const addAutoformTableRow = createAsyncThunk(
    'addAutoformTableRow',
    async ({ tableId, record, parentRecordId, parameters }, { rejectWithValue }) => {
        try {
            const response = await addAutoformTableRowService({ record, tableId, parentRecordId, parameters });

            return {
                tableId,
                data: {
                    ...response,
                    createdRecord: sanitizeDates(response.createdRecord),
                },
            };
        } catch (error) {
            return rejectWithValue(error?.response?.data || error);
        }
    }
);

export const copyAutoformRecords = createAsyncThunk('copyAutoFormRecords', async ({ itemId, tableId }, { signal }) => {
    const response = await copyAutoformRecordsService(itemId);
    return {
        tableId,
        data: response,
    };
});

export const saveAutoformRecords = createAsyncThunk(
    'saveAutoformRecords',
    async ({ tableId, records, primaryKeyRecordName, parameters }, { signal, rejectWithValue }) => {
        try {
            const response = await saveAutoformRecordsService({ tableId, records, parameters });

            return {
                tableId,
                data: records.map(sanitizeDates),
                error: response,
                primaryKeyRecordName,
            };
        } catch (error) {
            return rejectWithValue({ error: error?.response?.data || error, tableId });
        }
    }
);

export const deleteAutoformTableRow = createAsyncThunk(
    'deleteAutoformTableRow',
    async ({ tableId, recordId, primaryKeyColumnName, parameters }, { signal, rejectWithValue }) => {
        try {
            await deleteAutoformTableService({ recordId, tableId, primaryKeyColumnName, parameters });
            return {
                tableId,
                recordId,
                primaryKeyColumnName,
            };
        } catch (error) {
            return rejectWithValue(error?.response?.data || error);
        }
    }
);
export const deleteAutoformTableRows = createAsyncThunk(
    'deleteAutoformTableRows',
    async ({ tableId, recordsIds, primaryKeyColumnName, parameters }, { signal, rejectWithValue }) => {
        try {
            await deleteAutoformTableRowsService({ recordsIds, tableId, primaryKeyColumnName, parameters });
            return {
                tableId,
                recordsIds,
                primaryKeyColumnName,
            };
        } catch (error) {
            return rejectWithValue(error?.response?.data || error);
        }
    }
);

export const refetchSelectedItemBackendData = createAsyncThunk(
    'refetchBackendData',
    async ({ tableId, itemId, primaryKeyColumnName }, { signal }) => {
        const response = await refetchBackendData({ tableId, itemId, primaryKeyColumnName });
        return {
            tableId,
            itemId,
            primaryKeyColumnName,
            data: response,
        };
    }
);

export const getBulkUpdateData = createAsyncThunk('getBulkUpdateData', getAutoformBulkUpdateData);
