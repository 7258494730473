import { useIntl } from 'react-intl';
import { Flex, useColorMode } from '@chakra-ui/react';

import defaultLogoURL from '../../icons/logos/power-simm-outlines.svg';
import whiteLogoURL from '../../icons/logos/power-simm-outlines-white.svg';

const Logo = ({ lightModeSrc = defaultLogoURL, darkModeSrc = whiteLogoURL, src, alt, ...props }) => {
    const intl = useIntl();
    const { colorMode } = useColorMode();

    return (
        <Flex {...props} justifyContent="center">
            <img
                src={src ? src : colorMode === 'light' ? lightModeSrc : darkModeSrc}
                alt={alt || intl.formatMessage({ id: 'common_ascend_logo' })}
                width={props.width || props.w}
            />
        </Flex>
    );
};

export default Logo;
