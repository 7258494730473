import { useCallback, useMemo } from 'react';
import { Flex, Box, Button, useToast } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

const useCommonToast = () => {
    const intl = useIntl();
    const continueLabel = intl.formatMessage({ id: 'common_continue' });

    const toast = useToast({
        defaultOptions: {
            position: 'top',
        },
    });

    // status defaulted to 'success'
    const wrapper = useCallback(
        (params) => {
            if (typeof params === 'string') {
                return toast({
                    position: 'top',
                    render: ({ onClose }) => (
                        <ToastWrapper status={'success'}>
                            <Box mr={6}>{params}</Box>

                            <ContinueButton onClick={onClose}>{continueLabel}</ContinueButton>
                        </ToastWrapper>
                    ),
                });
            } else {
                const { status = 'success', message, slot, ...options } = params;

                if (!toast.isActive(options.id)) {
                    return toast({
                        position: 'top',
                        render: ({ onClose }) => (
                            <ToastWrapper status={status}>
                                <Box mr={6}>{message}</Box>

                                <ContinueButton onClick={onClose}>{continueLabel}</ContinueButton>

                                {slot}
                            </ToastWrapper>
                        ),
                        ...options,
                    });
                }
            }
        },
        [continueLabel, toast]
    );

    return useMemo(() => ({ toast: wrapper, chakraToast: toast }), [wrapper, toast]);
};

const ToastWrapper = ({ status, ...props }) => {
    // toast light and dark colors per status are defined in src\theme\colors.js
    const textColor = `${status}-toast-text-color`;
    const bgColor = `${status}-toast-background`;
    const borderColor = `${status}-toast-border-color`;

    return (
        <Flex
            wrap="wrap"
            align="center"
            px={6}
            py={4}
            color={textColor}
            borderWidth="1px"
            borderRadius="md"
            status={status}
            bg={bgColor}
            borderColor={borderColor}
            {...props}
        />
    );
};

const ContinueButton = (props) => {
    return <Button textTransform="capitalize" ml="auto" variant="secondary" bg="white" {...props} />;
};

export default useCommonToast;
