import { useState, useEffect, useCallback, useRef } from 'react';
import {
    Heading,
    Flex,
    Box,
    SimpleGrid,
    useDisclosure,
    Button,
    Divider,
    Spacer,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Accordion,
    Text,
    IconButton,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { onHasUnsavedChanges } from '../store/helpers/helpersSlice';

import { checkItemInputEvent, flatItemDescriptions, getItemType } from '../services/items';
import {
    getForwardCurveDefinitions,
    editForwardCurve,
    deleteForwardCurve,
    deleteForwardItem,
    getForwardItemBooks,
} from '../services/forwardCurve';
import { preserveConnectionSearchParams } from '../services/connections';

import useCommonToast from '../hooks/useCommonToast';
import useHistoryPush from '../hooks/useHistoryPush';

import AutomaticFormikDirtyCheck from '../components/forms/AutomaticFormikDirtyCheck';
import InputFormikField from '../components/forms/InputFormikField';

import Error from '../components/utils/Error';
import DefinitionsSkeleton from '../components/utils/DefinitionsSkeleton';
import Tooltip from '../components/utils/Tooltip';
import AddSubItem from '../components/utils/AddSubItem';
import DeleteRecordModal from '../components/utils/DeleteRecordModal';
import ActionBar from '../components/utils/ActionBar';
import SecondaryButton from '../components/utils/SecondaryButton';
import ItemPropertiesModal from '../components/utils/ItemPropertiesModal';
import CustomItemIcon from '../components/utils/CustomItemIcon';
import PromptWrapper from '../components/utils/PromptWrapper';

import ComboboxFormikField from '../components/forms/ComboboxFormikField';
import CheckboxFormikField from './../components/forms/CheckboxFormikField';

import AddForwardCurveModal from '../components/forwardCurve/AddForwardCurveModal';
import ForwardCurveDefinition from '../components/forwardCurve/ForwardCurveDefinition';

import { ReactComponent as TrendIcon } from '../icons/trend.svg';
import { ReactComponent as SaveIcon } from '../icons/save.svg';
import { ReactComponent as EditIcon } from '../icons/edit-circled.svg';
import { ReactComponent as FileIcon } from '../icons/file.svg';
import { ReactComponent as BreadcrumbSeparatorIcon } from '../icons/breadcrumb-separator.svg';
import { isSubmitDisabled } from '../services/utils';

const ForwardItem = () => {
    const { forwardItemId } = useParams();
    const { search } = useLocation();

    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();
    const formRef = useRef();
    const dispatch = useDispatch();

    const locations = useSelector((state) => state.item.dataForItemCreation.locations);
    const itemTypes = useSelector((state) => state.item.itemTypes);

    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);

    const itemId = Number(forwardItemId);
    const itemType = 'Forward Curve';
    const { itemTypeId } = getItemType(itemTypes, itemType) || {};

    const searchParams = new URLSearchParams(search);
    const basisId = searchParams.get('basisId');
    const mode = searchParams.get('mode');
    const isNewItem = mode !== null && mode === 'new';

    const addSubItemModal = useDisclosure();
    const deleteSubItemModal = useDisclosure();
    const itemPropertiesModal = useDisclosure();

    const [isFormDirty, setIsFormDirty] = useState(false);
    const [confirmedDiscardChanges, setConfirmedDiscardChanges] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [forwardItemData, setForwardItemData] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [books, setBooks] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [subItemForDeletion, setSubItemForDeletion] = useState(null);
    const [editDescriptionConfig, setEditDescriptionConfig] = useState(null);

    const formLabels = {
        commodityType: intl.formatMessage({ id: 'basis_commodity_type' }),
        selectPlaceholder: intl.formatMessage({ id: 'common_forms_select_option' }),
        master: intl.formatMessage({ id: 'common_master' }),
        market: intl.formatMessage({ id: 'add_new_forward_item_market_name' }),
    };

    const initialFormValues = {
        description: selectedItem?.description,
        bookId: selectedItem?.bookId,
        locationId: selectedItem?.locationId,
        definitions: {},
        securityId: selectedItem?.securityId,
    };

    forwardItemData.data?.forwardCurveDefinitionResponses.forEach((def) => {
        initialFormValues.definitions[def.id] = {};
        initialFormValues.definitions[def.id]['id'] = def.id;
        initialFormValues.definitions[def.id]['description'] = def.description;
        initialFormValues.definitions[def.id]['commodityId'] = def.commodityId;
        initialFormValues.definitions[def.id]['peakPeriodLabelId'] = def.peakPeriodLabelId;
        initialFormValues.definitions[def.id]['marketScaler'] = def.marketScaler;
        initialFormValues.definitions[def.id]['currencyID'] = def.currencyID;
        initialFormValues.definitions[def.id]['marketId'] = def.marketId;
        initialFormValues.definitions[def.id]['useCurveDeveloper'] = def.useCurveDeveloper;
        initialFormValues.definitions[def.id]['securityId'] = def.securityId;
    });

    const fetchForwardItemBooks = useCallback(() => {
        return getForwardItemBooks(itemId)
            .then((data) => setBooks((prev) => ({ ...prev, data })))
            .catch(() => setBooks((prev) => ({ ...prev, error: true })))
            .finally(() => setBooks((prev) => ({ ...prev, loading: false })));
    }, [itemId]);

    const fetchForwardItemDefinitions = useCallback(() => {
        return getForwardCurveDefinitions(itemId)
            .then((data) => {
                setSelectedItem(data.forwardPriceItem);
                setEditDescriptionConfig(flatItemDescriptions(data.forwardCurveDefinitionResponses, itemId));
                setForwardItemData((prev) => ({ ...prev, data }));
            })
            .catch(() => setForwardItemData((prev) => ({ ...prev, error: true })))
            .finally(() => setForwardItemData((prev) => ({ ...prev, loading: false })));
    }, [itemId]);

    useEffect(() => {
        fetchForwardItemBooks();
        fetchForwardItemDefinitions();
    }, [fetchForwardItemBooks, fetchForwardItemDefinitions]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const navigateToBasisConfigurator = (forceRedirect = false) => {
        historyPush({
            pathname: `/basis-configurator/${basisId}`,
            hash: '#forward-price',
            search: preserveConnectionSearchParams(window.location.search),
            forceRedirect,
        });
    };

    const validateItemDescription = (value, itemId, charLength) => {
        const trimmed = value.trim();
        const label = `${intl.formatMessage({ id: 'forward_curve_label' })} name`;

        let errorMessage = '';

        if (trimmed.length === 0) {
            errorMessage = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        } else if (trimmed.length > charLength) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label, lengthRule: `${charLength} characters or less` }
            );
        }

        if (errorMessage !== '') {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [itemId]: { ...prevState[itemId], penDisabled: true },
                };
            });
        } else {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [itemId]: { ...prevState[itemId], penDisabled: false },
                };
            });
        }

        return errorMessage;
    };

    const onFormSubmit = async ({ bookId, locationId, description, definitions, securityId }) => {
        const successMessage = intl.formatMessage({ id: 'forward_curve_save_changes_success' });

        const subForwardCurves = [];

        Object.values(definitions).forEach((subForwardCurve) => {
            subForwardCurves.push({
                ...subForwardCurve,
                itemId: selectedItem.id,
                marketScaler: subForwardCurve.marketScaler === null ? null : Number(subForwardCurve.marketScaler),
                currencyID: subForwardCurve.currencyID,
                marketId: subForwardCurve.marketId,
                useCurveDeveloper: subForwardCurve.useCurveDeveloper,
                securityId,
            });
        });
        try {
            await editForwardCurve({
                forwardCurveItem: {
                    description,
                    bookId,
                    itemTypeId,
                    locationId: locationId || null,
                    itemId: selectedItem.id,
                    securityId,
                },
                subForwardCurves,
            });

            toast(successMessage);
            navigateToBasisConfigurator(true);
        } catch (error) {
            if (error.response.status !== 401) {
                let serverError;

                if (error.response.status !== 500) {
                    serverError = error.response.data.error || error.response.data.title;
                }

                toast({
                    status: 'error',
                    message: serverError || intl.formatMessage({ id: 'common_generic_saving_error' }),
                });
            }
        }
    };

    const onEditDescription = (itemId) => {
        if (!editDescriptionConfig[itemId].penDisabled) {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [itemId]: { isActive: !prevState[itemId].isActive, penDisabled: false },
                };
            });
        }
    };

    const onAddSubItem = (newSubItem) => {
        setEditDescriptionConfig((prevState) => ({
            ...prevState,
            [newSubItem.id]: { isActive: false, penDisabled: false },
        }));

        setForwardItemData((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                forwardCurveDefinitionResponses: [...prev.data.forwardCurveDefinitionResponses, newSubItem],
            },
        }));

        addSubItemModal.onClose();
    };

    const onSubItemDeleteModalOpen = (subItemId, subItemDesc) => {
        setSubItemForDeletion({
            id: subItemId,
            description: subItemDesc,
        });

        deleteSubItemModal.onOpen();
    };

    const onSubItemDeleteModalClose = () => {
        deleteSubItemModal.onClose();
        setSubItemForDeletion(null);
    };

    const onSubItemDelete = async (subItemId) => {
        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'forward curve' });

        try {
            setForwardItemData((prev) => ({ ...prev, loading: true }));

            await deleteForwardCurve(subItemId);
            await fetchForwardItemDefinitions();

            toast(successMessage);
        } finally {
            setForwardItemData((prev) => ({ ...prev, loading: false }));
            onSubItemDeleteModalClose();
        }
    };

    const onCancelForwardItemCreationConfirm = async () => {
        const successMessage = intl.formatMessage({ id: 'basis_forward_item_delete_success' });
        const errorMessage = intl.formatMessage({ id: 'common_generic_delete_error' });

        try {
            await deleteForwardItem(selectedItem.id);

            toast(successMessage);
        } catch {
            toast({
                status: 'error',
                message: errorMessage,
            });
        }
    };

    const onBasisSetupBreadcrumbClicked = () => {
        navigateToBasisConfigurator();
    };

    const validate = (values) => {
        const errors = {};

        for (let defId of Object.keys(values.definitions)) {
            if (values.definitions[defId].useCurveDeveloper && !values.definitions[defId].marketId) {
                return {
                    definitions: {
                        [defId]: {
                            marketId: intl.formatMessage(
                                { id: 'common_forms_validation_required' },
                                { label: formLabels.market }
                            ),
                        },
                    },
                };
            }
        }
        return errors;
    };

    return (
        <Box pt={'80px'} pb={'50px'}>
            {forwardItemData.loading || books.loading ? (
                <DefinitionsSkeleton />
            ) : forwardItemData.error || books.error ? (
                <Box m={6}>
                    <Error primaryId="common_error" secondaryId="common_loading_error" additionalText="forward item" />
                </Box>
            ) : (
                <>
                    <Breadcrumb pt="12px" px="24px" separator={<BreadcrumbSeparatorIcon />}>
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={onBasisSetupBreadcrumbClicked}>
                                <FormattedMessage id="basis_setup" />
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>
                                <FormattedMessage id="forward_curve_breadcrumb" />
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>

                    <Formik
                        enableReinitialize
                        initialValues={initialFormValues}
                        onSubmit={onFormSubmit}
                        innerRef={formRef}
                        validate={validate}
                    >
                        {({ isSubmitting, errors, touched, values }) => (
                            <Form>
                                <AutomaticFormikDirtyCheck
                                    isFormDirty={isFormDirty}
                                    onFormDirtyChange={setIsFormDirty}
                                />
                                <Flex pt={3} px={6} align="center" flexDir={{ base: 'column', xl: 'row' }}>
                                    <Flex w={{ base: '100%', xl: 'fit-content' }} align="center">
                                        <CustomItemIcon isMaster={selectedItem.securityId} icon={<TrendIcon />} />

                                        {!editDescriptionConfig[selectedItem.id]?.isActive ? (
                                            <Heading px={2} as="h2" variant="h2" maxW="700px">
                                                {values.description}
                                            </Heading>
                                        ) : (
                                            <InputFormikField
                                                px={3}
                                                flex="1"
                                                maxW="500px"
                                                validate={(value) =>
                                                    validateItemDescription(value, selectedItem.id, 100)
                                                }
                                                name="description"
                                                type="text"
                                                onKeyDown={checkItemInputEvent}
                                                isFastField
                                            />
                                        )}

                                        <Box display="inline-block" mr={4}>
                                            <Tooltip
                                                label={intl.formatMessage({
                                                    id: 'common_edit_btn_tooltip_and_label',
                                                })}
                                            >
                                                <IconButton
                                                    aria-label={intl.formatMessage({
                                                        id: 'common_edit_btn_tooltip_and_label',
                                                    })}
                                                    variant="circular-icon"
                                                    leftIcon={<EditIcon />}
                                                    onClick={() => onEditDescription(selectedItem.id)}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Flex>

                                    <Spacer display={{ base: 'none', xl: 'block' }} />

                                    <Button
                                        variant="secondary"
                                        mr={{ base: 0, xl: 4 }}
                                        my={{ base: 4, xl: 0 }}
                                        w={{ base: '100%', xl: 'fit-content' }}
                                        onClick={() => navigateToBasisConfigurator()}
                                    >
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id={isNewItem ? 'common_cancel' : 'common_back'} />
                                        </Box>
                                    </Button>

                                    <Button
                                        leftIcon={<SaveIcon />}
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        type="submit"
                                        size="sm"
                                        variant="primary-success"
                                        w={{ base: '100%', xl: 'fit-content' }}
                                    >
                                        <FormattedMessage id="common_save_and_return" />
                                    </Button>
                                </Flex>

                                <ActionBar>
                                    <SecondaryButton
                                        leftIcon={<FileIcon />}
                                        size="sm"
                                        variant="solid"
                                        onClick={itemPropertiesModal.onOpen}
                                        flexShrink={0}
                                    >
                                        <FormattedMessage id="common_properties" />
                                    </SecondaryButton>
                                </ActionBar>

                                <Divider />

                                <SimpleGrid pt={7} pb={5} px={6} columns={{ xl: 3 }} spacingX={6} spacingY={4}>
                                    <Box>
                                        <Text
                                            fontSize={12}
                                            color="gray.600"
                                            fontWeight="500"
                                            mb={1}
                                            textTransform="capitalize"
                                        >
                                            <FormattedMessage id="forward_curve_location" />
                                        </Text>
                                        <ComboboxFormikField
                                            id="locationId"
                                            name="locationId"
                                            label={formLabels.location}
                                            options={locations}
                                            labelKey="description"
                                            valueKey="id"
                                            showPlaceholder={true}
                                            placeholderValue=""
                                            isFastField
                                        />
                                    </Box>
                                    <Box>
                                        <Text
                                            fontSize={12}
                                            color="gray.600"
                                            fontWeight="500"
                                            mb={1}
                                            textTransform="capitalize"
                                        >
                                            <FormattedMessage id="forward_curve_book" />
                                        </Text>
                                        <ComboboxFormikField
                                            id="bookId"
                                            name="bookId"
                                            label={formLabels.book}
                                            options={books.data}
                                            valueKey="id"
                                            labelKey="description"
                                            showPlaceholder={false}
                                            isFastField
                                        />
                                    </Box>

                                    <CheckboxFormikField id="securityId" name="securityId" mt={6}>
                                        {formLabels.master}
                                    </CheckboxFormikField>
                                </SimpleGrid>

                                <Box>
                                    {forwardItemData.data.forwardCurveDefinitionResponses.length === 0 ? (
                                        <AddSubItem
                                            onOpen={addSubItemModal.onOpen}
                                            messageId="forward_curve_add_new_forward_curve"
                                        />
                                    ) : (
                                        <Accordion allowToggle defaultIndex={[0]}>
                                            {forwardItemData.data.forwardCurveDefinitionResponses.map((def) => {
                                                return (
                                                    <ForwardCurveDefinition
                                                        key={def.id}
                                                        forwardItemData={forwardItemData.data}
                                                        forwardItemDefinitionData={def}
                                                        onAddSubItemModalOpen={addSubItemModal.onOpen}
                                                        editDescriptionConfig={editDescriptionConfig}
                                                        onEditDescription={onEditDescription}
                                                        validateItemDescription={validateItemDescription}
                                                        forwardItemId={itemId}
                                                        onSubItemDeleteModalOpen={onSubItemDeleteModalOpen}
                                                    />
                                                );
                                            })}
                                        </Accordion>
                                    )}
                                </Box>
                            </Form>
                        )}
                    </Formik>

                    {addSubItemModal.isOpen && (
                        <AddForwardCurveModal
                            isOpen
                            onClose={addSubItemModal.onClose}
                            forwardItem={forwardItemData.data}
                            onAddSubItem={onAddSubItem}
                        />
                    )}

                    {deleteSubItemModal.isOpen && (
                        <DeleteRecordModal
                            onClose={onSubItemDeleteModalClose}
                            headerItem={intl.formatMessage({ id: 'forward_curve_label' })}
                            contentMessageItem={subItemForDeletion.description}
                            onConfirm={() => onSubItemDelete(subItemForDeletion.id)}
                        />
                    )}

                    {itemPropertiesModal.isOpen && (
                        <ItemPropertiesModal
                            isOpen
                            onClose={itemPropertiesModal.onClose}
                            itemId={itemId}
                            successMessage={intl.formatMessage({ id: 'forward_curve_save_changes_success' })}
                        />
                    )}
                    <PromptWrapper
                        when={hasUnsavedChanges || (isNewItem && !confirmedDiscardChanges)}
                        onPromptConfirm={() => {
                            if (isNewItem) {
                                setConfirmedDiscardChanges(true);
                                onCancelForwardItemCreationConfirm();
                            }
                        }}
                        header={<FormattedMessage id="common_discard_changes" />}
                        content={
                            <FormattedMessage
                                id={isNewItem ? 'forward_curve_cancel_confirmation' : 'common_confirmation_explanation'}
                            />
                        }
                    />
                </>
            )}
        </Box>
    );
};

export default ForwardItem;
