import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Tabs, TabList, TabPanels, Tab, TabPanel, Box, Flex, Text, IconButton } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import Tooltip from '../utils/Tooltip';

import { whyJobIsNotRunningInfo } from '../../services/zapper';
import { formatDataGridDate } from '../../services/items';
import { appVersion, copyTextToClipboard } from '../../services/utils';

import SimpleGridSkeleton from '../utils/SimpleGridSkeleton';
import Error from '../utils/Error';
import DataGrid from '../grid/DataGrid';
import DataGridWrapper from '../grid/DataGridWrapper';
import SortOrderProvider from '../grid/utils/SortOrderContext';

import MainModal from '../modal/MainModal';

import { ReactComponent as CopyIcon } from '../../icons/copy-circled.svg';
import { ReactComponent as AlertIcon } from '../../icons/mdi-close-box-outline-red.svg';
import { ReactComponent as CheckIcon } from '../../icons/mdi-checkbox-outline-green.svg';

const initialState = {
    // status summary of parent jobs not marked as complete that
    // are attached to the job that is not running.
    ancestors: [],
    // list of machines that can run the given job.
    // this includes the current cost and maximum allowed cost.
    costOverview: null,
    // flag indicating whether or not the scheduled runtime is in the future.
    isRuntimeInFuture: false,
    // flag indicating whether or not the job is past its specified expiration date.
    // if this flag is true, it indicates the system will not attempt to pick up the specified job.
    isJobExpired: false,
    // flag indicating that the current job is set to run the portfolio reporting module
    // and another reporting job is currently running.
    isReportingBlocking: false,
    // information related to the job queue on the various machines that can run the given job.
    // queue info: if the lowestJobPosition is < 0, the job is not in any of its machines queues.
    // {int LowestPosition, MachinePositions[{MachineName, int JobPosition}]}
    queueInfo: null,
    loading: true,
    error: false,
};

const WhyJobNotRunningModal = ({ jobId, jobInfo, onClose }) => {
    const intl = useIntl();
    const textRef = useRef(null);
    const dateNow = new Date();
    const parsedData = [jobInfo];
    const runDate = formatDataGridDate({ value: jobInfo?.scheduledRunTime });

    const [whyJobIsNotRunningData, setWhyJobIsNotRunningData] = useState(initialState);

    const fetchWhyJobIsNotRunningData = useCallback(() => {
        return whyJobIsNotRunningInfo(jobId)
            .then((response) => setWhyJobIsNotRunningData((prev) => ({ ...prev, ...response })))
            .catch(() => setWhyJobIsNotRunningData((prev) => ({ ...prev, error: true })))
            .finally(() => setWhyJobIsNotRunningData((prev) => ({ ...prev, loading: false })));
    }, [jobId]);

    useEffect(() => {
        fetchWhyJobIsNotRunningData();
    }, [fetchWhyJobIsNotRunningData]);

    const failedParentsData = whyJobIsNotRunningData?.ancestors;
    const activeParentsFound = Array.isArray(failedParentsData) && failedParentsData.length > 0;
    const costOverviewMachineSummaryData = whyJobIsNotRunningData?.costOverview?.machineSummary;
    const jobQueueInfoNotFound = whyJobIsNotRunningData?.queueInfo == null;
    const jobQueueMachinePositionsData = whyJobIsNotRunningData?.queueInfo?.machinePositions;
    const lowestQueuePosition = whyJobIsNotRunningData?.queueInfo?.lowestPosition;

    const totalMachinesCount = costOverviewMachineSummaryData?.length;

    const areAllMachinesMaxedOut = costOverviewMachineSummaryData?.every(
        (machine) => machine.currentCost >= machine.maximumCost
    );
    const availableMachines = costOverviewMachineSummaryData?.filter(
        (machine) => machine.currentCost < machine.maximumCost
    );
    const availableMachinesCount = availableMachines?.length;

    //#region Grid Columns Definitions
    const jobGridColumns = useMemo(() => {
        return [
            {
                field: 'status',
                editable: false,
                sortable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_status' }),
                minWidth: 350,
                checkboxSelection: false,
                headerCheckboxSelection: false,
                cellRenderer: 'StudyTrackerStatusBadge',
                cellRendererParams: {
                    isInJobsTable: true,
                    pb: 0,
                    pt: '2px',
                },
            },
            {
                type: 'number',
                field: 'jobId',
                editable: false,
                sortable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_job_id' }),
            },
            {
                type: 'number',
                field: 'studyId',
                editable: false,
                sortable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_study_id' }),
            },
        ];
    }, [intl]);

    const ancestorsGridColumns = useMemo(() => {
        return [
            {
                field: 'status',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_status' }),
                minWidth: 350,
                checkboxSelection: false,
                headerCheckboxSelection: false,
                cellRenderer: 'StudyTrackerStatusBadge',
                cellRendererParams: {
                    isInJobsTable: true,
                    pb: 0,
                    pt: '2px',
                },
            },
            {
                type: 'number',
                field: 'jobId',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_job_id' }),
                sort: 'desc',
            },
            {
                type: 'number',
                field: 'studyId',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_study_id' }),
                sort: 'desc',
            },
        ];
    }, [intl]);

    const machinesPositionsGridColumns = useMemo(() => {
        return [
            {
                field: 'machineName',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_not_running_machine_name' }),
                minWidth: 350,
                sort: 'asc',
            },
            {
                type: 'number',
                field: 'jobPosition',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_not_running_machine_job_position' }),
                sort: 'desc',
            },
        ];
    }, [intl]);

    const machinesCostGridColumns = useMemo(() => {
        return [
            {
                field: 'machineName',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_not_running_machine_name' }),
                minWidth: 350,
                sort: 'asc',
            },
            {
                type: 'number',
                field: 'currentCost',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_not_running_machine_current_cost' }),
                sort: 'desc',
            },
            {
                type: 'number',
                field: 'maximumCost',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_not_running_machine_maximum_cost' }),
                sort: 'desc',
            },
        ];
    }, [intl]);
    //#endregion

    const handleCopyClick = () => {
        const header = intl.formatMessage({ id: 'job_not_running_header' }, { jobId });
        const text = `\n\nStudy Id: ${jobInfo?.studyId} Job Id: ${jobId} Scheduled Run Time: ${runDate} \n\n`;
        const summary = textRef?.current?.innerText + '\n\n';
        const json = JSON.stringify(whyJobIsNotRunningData, null, '\t');
        const end = `\n\n${appVersion} \n\n${dateNow?.toString()}`;

        const result = `${header} ${text}${summary}${json} ${end}`;

        copyTextToClipboard(result);
    };

    const warningMessage = (messageText) => {
        return (
            <Box display="flex" alignItems={'center'} mt="4">
                <AlertIcon />
                <Text color="text-error" ml={3}>
                    {messageText}
                </Text>
            </Box>
        );
    };

    const infoMessage = (messageText) => {
        return (
            <Box display="flex" alignItems={'center'} mt="4">
                <CheckIcon />
                <Text ml={3}>{messageText}</Text>
            </Box>
        );
    };

    const summaryInfo = () => {
        // lowestQueuePosition == 1 => Job is next in queue
        let lowestQueuePositionMessage =
            lowestQueuePosition === 1
                ? intl.formatMessage({ id: 'job_not_running_info_message_job_next_in_queue' })
                : intl.formatMessage(
                      { id: 'job_not_running_info_message_job_queue_position' },
                      { lowestQueuePosition }
                  );

        if (jobQueueInfoNotFound) {
            lowestQueuePositionMessage = intl.formatMessage({ id: 'job_not_running_info_message_job_queue_not_found' });
        }

        // jobCost == 0 passed green check
        // jobCost > 0  look at machine costs, if all maxed out then fail
        // info: x machines out of y machines can pickup jobs/are available to run jobs
        // error: no machines can pick up their job cost all current >= maximum
        const isJobCostZero =
            whyJobIsNotRunningData?.costOverview?.jobCost != null &&
            whyJobIsNotRunningData?.costOverview?.jobCost === 0;

        const jobCostMachinesMaxedOutMessage =
            areAllMachinesMaxedOut && intl.formatMessage({ id: 'job_not_running_error_message_machines_maxed_out' });

        const jobCostMachinesMessage =
            totalMachinesCount > 0 &&
            intl.formatMessage(
                { id: 'job_not_running_info_message_machines_count' },
                { availableMachinesCount, totalMachinesCount }
            );

        return (
            <>
                <Box ref={textRef} mt="6">
                    {/* queue info: if the lowestJobPosition is < 0, the job is not in any of its machines queues. */}
                    {lowestQueuePosition < 0
                        ? warningMessage(intl.formatMessage({ id: 'job_not_running_error_message_not_in_queue' }))
                        : infoMessage(lowestQueuePositionMessage)}

                    {activeParentsFound
                        ? warningMessage(intl.formatMessage({ id: 'job_not_running_error_message_has_active_parents' }))
                        : infoMessage(intl.formatMessage({ id: 'job_not_running_info_message_no_active_parent' }))}

                    {isJobCostZero && infoMessage(intl.formatMessage({ id: 'job_not_running_info_message_zero_cost' }))}

                    {!isJobCostZero && areAllMachinesMaxedOut && warningMessage(jobCostMachinesMaxedOutMessage)}

                    {!isJobCostZero && !areAllMachinesMaxedOut && infoMessage(jobCostMachinesMessage)}

                    {whyJobIsNotRunningData?.isRuntimeInFuture
                        ? warningMessage(
                              intl.formatMessage(
                                  { id: 'job_not_running_error_message_scheduled_in_future' },
                                  { runDate }
                              )
                          )
                        : infoMessage(
                              intl.formatMessage({ id: 'job_not_running_info_message_scheduled' }, { runDate })
                          )}

                    {whyJobIsNotRunningData?.isReportingBlocking
                        ? warningMessage(intl.formatMessage({ id: 'job_not_running_error_message_reporting_blocked' }))
                        : infoMessage(
                              intl.formatMessage({ id: 'job_not_running_info_message_reporting_not_blocking' })
                          )}

                    {whyJobIsNotRunningData?.isJobExpired
                        ? warningMessage(intl.formatMessage({ id: 'job_not_running_error_message_job_expired' }))
                        : infoMessage(intl.formatMessage({ id: 'job_not_running_info_message_job_not_expired' }))}
                </Box>
            </>
        );
    };

    return (
        <MainModal
            isOpen
            onClose={onClose}
            size="3xl"
            header={
                <>
                    <FormattedMessage id="job_not_running_header" values={{ jobId }} />
                </>
            }
            content={
                <Box width={720} height={600}>
                    <SortOrderProvider>
                        <SingleJobDataGridWrapper>
                            <DataGrid
                                rowData={parsedData}
                                rowModelType="clientSide"
                                rowHeight={34}
                                suppressRowClickSelection={true}
                                disableReadOnlyStyles={true}
                                columns={jobGridColumns}
                                pagination={false}
                                suppressRowHighlight={true}
                            />
                        </SingleJobDataGridWrapper>
                    </SortOrderProvider>

                    <Tabs>
                        <TabList>
                            <Tab>
                                <FormattedMessage id="job_not_running_tab1_name" />
                            </Tab>
                            {activeParentsFound && (
                                <Tab>
                                    <FormattedMessage id="job_not_running_tab2_name" />
                                </Tab>
                            )}
                            {Array.isArray(jobQueueMachinePositionsData) && jobQueueMachinePositionsData.length > 0 && (
                                <Tab>
                                    <FormattedMessage id="job_not_running_tab3_name" />
                                </Tab>
                            )}
                            {Array.isArray(costOverviewMachineSummaryData) &&
                                costOverviewMachineSummaryData.length > 0 && (
                                    <Tab>
                                        <FormattedMessage id="job_not_running_tab4_name" />
                                    </Tab>
                                )}
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                {whyJobIsNotRunningData.loading ? (
                                    <SimpleGridSkeleton rows={6} cols={1} />
                                ) : whyJobIsNotRunningData.error ? (
                                    <Box m={6}>
                                        <Error
                                            primaryId="common_error"
                                            secondaryId="common_loading_error"
                                            additionalText="details data"
                                        />
                                    </Box>
                                ) : (
                                    summaryInfo()
                                )}
                            </TabPanel>
                            {activeParentsFound && (
                                <TabPanel>
                                    <SortOrderProvider>
                                        <JobsDataGridWrapper>
                                            <DataGrid
                                                rowData={failedParentsData}
                                                rowModelType="clientSide"
                                                paginationPageSize={10}
                                                rowHeight={34}
                                                suppressRowClickSelection={true}
                                                disableReadOnlyStyles={true}
                                                columns={ancestorsGridColumns}
                                                pagination={true}
                                                suppressRowHighlight={true}
                                            />
                                        </JobsDataGridWrapper>
                                    </SortOrderProvider>
                                </TabPanel>
                            )}
                            {Array.isArray(jobQueueMachinePositionsData) && jobQueueMachinePositionsData.length > 0 && (
                                <TabPanel>
                                    <SortOrderProvider>
                                        <JobsDataGridWrapper>
                                            <DataGrid
                                                rowData={jobQueueMachinePositionsData}
                                                rowModelType="clientSide"
                                                paginationPageSize={10}
                                                rowHeight={34}
                                                suppressRowClickSelection={true}
                                                disableReadOnlyStyles={true}
                                                columns={machinesPositionsGridColumns}
                                                pagination={true}
                                                suppressRowHighlight={true}
                                            />
                                        </JobsDataGridWrapper>
                                    </SortOrderProvider>
                                </TabPanel>
                            )}
                            {Array.isArray(costOverviewMachineSummaryData) &&
                                costOverviewMachineSummaryData.length > 0 && (
                                    <TabPanel>
                                        <SortOrderProvider>
                                            <JobsDataGridWrapper>
                                                <DataGrid
                                                    rowData={costOverviewMachineSummaryData}
                                                    rowModelType="clientSide"
                                                    paginationPageSize={10}
                                                    rowHeight={34}
                                                    suppressRowClickSelection={true}
                                                    disableReadOnlyStyles={true}
                                                    columns={machinesCostGridColumns}
                                                    pagination={true}
                                                    suppressRowHighlight={true}
                                                />
                                            </JobsDataGridWrapper>
                                        </SortOrderProvider>
                                    </TabPanel>
                                )}
                        </TabPanels>
                    </Tabs>
                </Box>
            }
            secondaryButton={
                <Flex gap="10px">
                    <Tooltip label={'click to copy summary to clipboard'}>
                        <IconButton
                            aria-label={'click to copy summary to clipboard'}
                            variant="circular-icon"
                            leftIcon={<CopyIcon />}
                            onClick={handleCopyClick}
                            disabled={whyJobIsNotRunningData.loading}
                        />
                    </Tooltip>
                    <Button variant="secondary" onClick={onClose}>
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_close" />
                        </Box>
                    </Button>
                </Flex>
            }
        />
    );
};

const JobsDataGridWrapper = styled(DataGridWrapper)`
    margin-top: 10px;

    .ag-cell {
        line-height: 34px;
    }

    min-height: 62px;
    max-height: 432px;
`;

const SingleJobDataGridWrapper = styled(DataGridWrapper)`
    margin-bottom: 20px;

    .ag-cell {
        line-height: 34px;
    }

    max-height: 62px;
`;

export default WhyJobNotRunningModal;
