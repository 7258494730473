import { FormattedMessage } from 'react-intl';
import { Heading, SimpleGrid } from '@chakra-ui/react';

import ComboboxFormikField from '../forms/ComboboxFormikField';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';
import { helpAnchors } from 'constants/help';
import { SLICEOFDAY_PARTIAL_DELIVERABILITY } from 'constants/autoforms';
import { FEATURE_FLAG_NAMES, useIsFeatureActive } from 'hooks/features-flags';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import NumberInputFormikField from 'components/forms/NumberInputFormikField';

const RenewableDefinitionPanel = ({ idx, definitions, type, formLabels, helpPageUrlItemTypeId = null }) => {
    // Formik considers numbers in fields as an array of objects
    const fieldNamePrefix = `definitions.${idx}`;
    const isCAMSliceOfDayVisible = useIsFeatureActive(FEATURE_FLAG_NAMES.CAM_SLICEOFDAY_FIELDS_VISIBLE);
    const { values } = useFormikContext();

    const pcdsPercentDisabled = useMemo(() => {
        if (!isCAMSliceOfDayVisible) {
            return true;
        }

        const selectedDeliverabilityStatusId = values?.definitions[idx]?.deliverabilityStatusId;
        const selectedDeliverability = definitions?.deliverabilityStatusOptions
            ?.find((x) => x.id === selectedDeliverabilityStatusId)
            ?.description?.trim()
            .toUpperCase();
        return selectedDeliverability !== SLICEOFDAY_PARTIAL_DELIVERABILITY;
    }, [isCAMSliceOfDayVisible, values, definitions?.deliverabilityStatusOptions, idx]);

    return (
        <>
            <Heading as="h4" variant="h4" textTransform="capitalize">
                <FormattedMessage id="renewable_definitions_heading" values={{ renewable: type }} />
                <HelpButtonComponent
                    helpAnchor={helpAnchors.RENEWABLE_DEFINITIONS}
                    itemTypeId={helpPageUrlItemTypeId}
                />
            </Heading>

            <SimpleGrid columns={{ xl: 4 }} spacingX={6}>
                <ComboboxFormikField
                    id={`${fieldNamePrefix}.moduleProcessId`}
                    name={`${fieldNamePrefix}.moduleProcessId`}
                    mt={3}
                    label={formLabels.modelType}
                    options={definitions.modelTypes}
                    valueKey="id"
                    labelKey="labelName"
                    placeholderValue=""
                    placeholderLabel={formLabels.selectPlaceholder}
                    isFastField
                    validateOnBlur={false}
                    isRequired
                />

                <ComboboxFormikField
                    id={`${fieldNamePrefix}.weatherZoneId`}
                    name={`${fieldNamePrefix}.weatherZoneId`}
                    mt={3}
                    label={formLabels.weatherZone}
                    options={definitions.weatherZones}
                    valueKey="id"
                    labelKey="name"
                    placeholderValue=""
                    placeholderLabel={formLabels.selectPlaceholder}
                    isFastField
                    validateOnBlur={false}
                    isRequired
                />

                <ComboboxFormikField
                    id={`${fieldNamePrefix}.modelWeightId`}
                    name={`${fieldNamePrefix}.modelWeightId`}
                    mt={3}
                    label={formLabels.dataWeight}
                    options={definitions.dataWeightings}
                    labelKey="description"
                    valueKey="id"
                    placeholderValue=""
                    placeholderLabel={formLabels.selectPlaceholder}
                    isFastField
                    validateOnBlur={false}
                    isRequired
                />

                {Array.isArray(definitions?.plantTypes) && (
                    <ComboboxFormikField
                        id={`${fieldNamePrefix}.plantTypeId`}
                        name={`${fieldNamePrefix}.plantTypeId`}
                        mt={3}
                        label={formLabels.plantType}
                        options={definitions?.plantTypes}
                        labelKey="description"
                        valueKey="id"
                        placeholderLabel="-"
                        placeholderValue={null}
                        showPlaceholder={true}
                        isFastField
                        validateOnBlur={false}
                    />
                )}
                {isCAMSliceOfDayVisible && (
                    <>
                        <ComboboxFormikField
                            id={`${fieldNamePrefix}.deliverabilityStatusId`}
                            name={`${fieldNamePrefix}.deliverabilityStatusId`}
                            label={<FormattedMessage id="renewable_deliverability_status" />}
                            options={definitions.deliverabilityStatusOptions}
                            labelKey="description"
                            valueKey="id"
                            mt={3}
                            placeholderValue={null}
                            showPlaceholder={true}
                        />
                        <NumberInputFormikField
                            id={`${fieldNamePrefix}.pcdsPercent`}
                            name={`${fieldNamePrefix}.pcdsPercent`}
                            keepWithinRange={true}
                            min={0}
                            max={100}
                            step={1}
                            label={<FormattedMessage id="renewable_pcds_percent" />}
                            isDisabled={pcdsPercentDisabled}
                            isRequired={!pcdsPercentDisabled}
                            mt={3}
                        />
                    </>
                )}
            </SimpleGrid>
        </>
    );
};

export default RenewableDefinitionPanel;
