import { Popover as ChakraPopover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow } from '@chakra-ui/react';

const Popover = ({ children, trigger, width, ...rest }) => {
    return (
        <ChakraPopover {...rest}>
            <PopoverTrigger>{trigger}</PopoverTrigger>
            <PopoverContent width={width} ml="4px">
                <PopoverArrow />
                <PopoverBody>{children}</PopoverBody>
            </PopoverContent>
        </ChakraPopover>
    );
};

export default Popover;
