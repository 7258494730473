import axios from 'axios';
import SecondaryButton from './SecondaryButton';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { scopes, msalInstance } from '../../services/auth';

const DownloadLogButton = ({ id, label, endpoint, queryParameters }) => {
    const API_URL = process.env.REACT_APP_API;

    const { connectionId, environmentId, bookId } = axios.defaults.headers;

    const accounts = msalInstance.getAllAccounts();

    const isDisabled = accounts?.length ? false : true;

    const redirectToFileUrl = async () => {
        if (!endpoint.startsWith('/') && !API_URL.endsWith('/')) {
            endpoint = '/' + endpoint;
        }
        let url = new URL(`${API_URL}${endpoint}`);

        if (accounts?.length) {
            const { accessToken } = await msalInstance.acquireTokenSilent({ scopes, account: accounts[0] });
            url.searchParams.append('authorization', `Bearer ${accessToken}`);
        }

        url.searchParams.append('environmentId', environmentId);
        url.searchParams.append('connectionId', connectionId);
        url.searchParams.append('bookId', bookId);

        if (queryParameters) {
            const params = queryParameters.split(';');
            params.forEach((param) => {
                let indexOfSplit = param.indexOf(':');
                if (indexOfSplit > 0) {
                    url.searchParams.append(param.substring(0, indexOfSplit), param.substring(indexOfSplit + 1));
                }
            });
        }

        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <SecondaryButton
            id={id}
            leftIcon={<DownloadIcon />}
            w="auto !important"
            size="sm"
            type="button"
            variant="secondary"
            onClick={redirectToFileUrl}
            isDisabled={isDisabled}
        >
            {label}
        </SecondaryButton>
    );
};

export default DownloadLogButton;
