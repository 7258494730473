import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDisclosure, Alert, AlertIcon, Box, Skeleton, AlertTitle, AlertDescription } from '@chakra-ui/react';
import Tree from '../react-tree/Tree';
import LayoutWrapper from '../layout/LayoutWrapper';
import LayoutSidebar from '../layout/LayoutSidebar';
import { getHydroTree, filterNodesByName, removeTreeNode, getItemId } from '../../services/hydro';
import TreeWrapper from '../tree/TreeWrapper';
import useAutoformLoadMetadata from '../autoform/hooks/useAutoformLoadMetadata';
import useAutoformMetadata from '../autoform/hooks/useAutoformMetadata';
import HydroNodeIcon from './HydroNodeIcon';
import HydroArrowIcon from './HydroArrowIcon';
import HoverLabelIcons from './HoverLabelIcons';
import AutoformAddRecordModal from '../autoform/AutoformAddRecordModal';
import AddItemPortfolios from '../utils/AddItemPortfolios';
import { addItemToPortfolios, copyItem as copyAutoformRecordsService } from '../../services/items';
import { getDtoFromDataValueObject } from '../autoform/utils/autoformUtils';
import useAutoformParams from '../autoform/hooks/useAutoformParams';
import useAutoformTableMetadata from '../autoform/hooks/useAutoformTableMetadata';
import { addMultipleAutoformTableRow, checkDataSingle } from '../../services/autoforms';
import useCommonToast from '../../hooks/useCommonToast';
import FilteredWrapper from '../tree/FilteredWrapper';
import { renameNode } from '../../services/portfolios';
import useAddToPortfolios from '../../hooks/useAddToPortfolios';
import useAutoformLoadTableDropdowns from '../autoform/hooks/useAutoformLoadTableDropdowns';
import hydroPlaceholder from '../../images/hydro-unselected.png';
import WrapperEmptyState from '../layout/WrapperEmptyState';
import useHistoryPush from '../../hooks/useHistoryPush';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import AutoformContainer from '../autoform/AutoformContainer';
import ConfirmationModal from '../modal/ConfirmationModal';
import { onHasUnsavedChanges } from '../../store/helpers/helpersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as HydroPlantUnitIcon } from '../../icons/item-icons/hydro-plant-unit.svg';
import { Title } from 'react-head';

const unitOutagesTableId = 59299;

const HydroTreeTab = ({ autoformId, pageTitle, setPersistedRoute }) => {
    const { isLoading: isMetadataLoading } = useAutoformLoadMetadata(autoformId);
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const { toast, chakraToast } = useCommonToast();
    const dispatch = useDispatch();
    const addToPortfolioModal = useDisclosure();
    const addItemModal = useDisclosure();
    const confirmationModal = useDisclosure();
    const parameters = useAutoformParams();
    const intl = useIntl();
    const historyPush = useHistoryPush();
    let { url, path } = useRouteMatch();
    const { pathname } = useLocation();
    const pathId = useMemo(() => (url === pathname ? null : parseInt(pathname.split('/').pop())), [url, pathname]);

    const tableId = useMemo(() => hierarchicalTables.level1[0]?.id, [hierarchicalTables]);
    const { metadata: tableMetadata } = useAutoformTableMetadata(tableId);
    useAutoformLoadTableDropdowns(tableId);

    const treeExpandedInitialized = useRef(false);

    const [treeData, setTreeData] = useState([]);
    const [filteredTreeData, setFilteredTreeData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingError, setIsLoadingError] = useState(false);
    const [selected, setSelected] = useState(null);
    const [expanded, setExpanded] = useState([]);
    const [focused, setFocused] = useState(null);
    const [search, setSearch] = useState('');
    const [temporaryNode, setTemporaryNode] = useState(null);

    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);

    const { onAddToPortfolioModalOpen, closeAddToPortfolioModal, onAddItemToPortfolioSuccess } = useAddToPortfolios({
        onModalOpen: addToPortfolioModal.onOpen,
        onModalClose: addToPortfolioModal.onClose,
        temporaryNodeName: temporaryNode?.properties?.name,
        setTemporaryNode,
    });

    const loadTree = useCallback(() => {
        setIsLoading(true);

        return getHydroTree()
            .then((data) => {
                const itemId = pathId ? getItemId(data, pathId) : undefined;
                if (itemId) setPersistedRoute(itemId);
                setIsLoadingError(false);
                setTreeData(data);
                setFocused(data.length > 0 ? data[0].id : null);
                return data;
            })
            .catch(() => setIsLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [pathId, setPersistedRoute]);

    const secureChangingSelectedNode = useCallback(
        (node) => {
            if (node?.properties?.isItem) {
                setPersistedRoute(node.properties.id);
            }
            if (hasUnsavedChanges && selected !== node) {
                setTemporaryNode(node);
                confirmationModal.onOpen();
            } else {
                setFocused(node.id);
                setSelected(node);
                setTemporaryNode(null);
            }
        },
        [confirmationModal, hasUnsavedChanges, selected, setPersistedRoute]
    );

    useEffect(() => {
        loadTree();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selected) {
            let targetUrl = url;
            if (selected.properties.isPlantUnit) {
                targetUrl += `/${encodeURIComponent(selected.properties.id)}`;
            }
            historyPush(targetUrl);
        }
        //it prevents wrong history push on changing tab
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, historyPush]);

    useEffect(() => {
        if (treeExpandedInitialized?.current === false && pathId && treeData?.length) {
            treeExpandedInitialized.current = true;
            let nodeIdsToOpen = [];

            // Inner function
            const findTreeItemsToOpen = (items) => {
                let result = false;

                for (let i = 0; i < items?.length; i++) {
                    const treeItem = items[i];

                    if (
                        treeItem.properties?.id === pathId || // Exact match found
                        // Or continue to search tree item's children
                        (treeItem.nodes?.length && findTreeItemsToOpen(treeItem.nodes))
                    ) {
                        result = true;
                        nodeIdsToOpen.push(treeItem.id);

                        if (treeItem.properties?.isPlantUnit) {
                            setSelected(treeItem);
                        }

                        if (
                            treeItem.properties?.id === pathId &&
                            treeItem.properties?.isItem &&
                            treeItem.nodes?.length
                        ) {
                            // Need to expand children of selected top item on first load
                            for (let n = 0; n < treeItem.nodes?.length; n++) {
                                const child = treeItem.nodes[n];
                                nodeIdsToOpen.push(child.id);
                            }
                        }

                        break;
                    }
                }

                return result;
            };

            findTreeItemsToOpen(treeData);
            setExpanded(nodeIdsToOpen);
        }
    }, [pathId, treeData, treeExpandedInitialized]);

    const onAddModalOpen = useCallback(
        (node) => {
            setTemporaryNode(node);

            addItemModal.onOpen();
        },
        // addModal causes unnecessary rerender
        [] // eslint-disable-line
    );

    const onAddRecord = useCallback(
        async (record) => {
            await addMultipleAutoformTableRow({
                tableId: unitOutagesTableId,
                records: [getDtoFromDataValueObject(record, tableMetadata)],
                parentRecordId: temporaryNode?.properties.id,
                parameters,
            });

            toast(intl.formatMessage({ id: 'common_item_creation_success' }, { type: '' }));

            loadTree();
        },
        [tableMetadata, temporaryNode, toast, intl, parameters, loadTree]
    );

    const onCheckData = useCallback(
        (record) =>
            checkDataSingle({
                tableId: unitOutagesTableId,
                parentRecordId: temporaryNode?.properties.id,
                record: getDtoFromDataValueObject(record, tableMetadata),
                parameters,
            }),
        [temporaryNode, tableMetadata, parameters]
    );

    const onAutoformCopy = useCallback(
        async (node) => {
            const progressToast = toast({
                status: 'info',
                message: intl.formatMessage({ id: 'autoform_common_copy_btn_progress_message' }),
            });

            const result = await copyAutoformRecordsService(node.properties.id);
            const reloadedTreeData = await loadTree();

            chakraToast.close(progressToast);

            const newNode = reloadedTreeData.find((item) => item.properties.id === result.id);

            if (newNode) {
                secureChangingSelectedNode(newNode);
                toast({
                    status: 'success',
                    message: intl.formatMessage(
                        { id: 'common_copy_btn_success_message' },
                        { name: newNode.properties.name }
                    ),
                });
            }
        },
        [toast, intl, loadTree, chakraToast, secureChangingSelectedNode]
    );

    useEffect(() => {
        const term = search.trimStart().toLowerCase();

        if (term && term.length >= 2) {
            const data = filterNodesByName(treeData, term);

            setFilteredTreeData(data);
            setFocused(data.length > 0 ? data[0].id : null);
        } else {
            setFilteredTreeData(treeData);
            setFocused(treeData.length > 0 ? treeData[0].id : null);
        }
    }, [search, treeData]);

    const onNodeRename = useCallback(
        (name) => {
            setTreeData((prev) => renameNode(prev, selected.properties.id, name));
        },
        [selected]
    );

    const onNodeItemDelete = useCallback(() => {
        setTreeData((prev) => removeTreeNode(prev, selected.properties.id));
        setSelected(null);

        setFocused(filteredTreeData[0].id);
    }, [filteredTreeData, selected]);

    const onExpandChange = useCallback(
        ({ id }) => {
            if (id && id.includes('_')) {
                const splitId = id.split('_');
                if (splitId[0] === 'itemId') {
                    setPersistedRoute(splitId[1]);
                }
            }
            setExpanded((prev) => (prev.includes(id) ? prev.filter((node) => node !== id) : prev.concat(id)));
        },
        [setPersistedRoute]
    );

    const onFocusChange = useCallback(({ id }) => {
        setFocused(id);
    }, []);

    const renderLabel = useCallback(
        (node) => {
            return (
                <div className={`${node?.isExpandable ? '' : 'non-expandable '}hydro-label-wrapper`}>
                    {node?.isExpandable && (
                        <span
                            onClick={() => {
                                onExpandChange(node);
                                setFocused(node.id);
                            }}
                        >
                            <HydroArrowIcon isExpanded={node?.isExpanded} />
                        </span>
                    )}

                    <div
                        onClick={(event) => {
                            if (event.shiftKey && node?.isExpandable) {
                                onExpandChange(node);
                            } else {
                                secureChangingSelectedNode(node);
                            }
                        }}
                        className="hydro-label"
                    >
                        <FilteredWrapper>
                            <HydroNodeIcon node={node} />

                            {search.trimStart().length >= 2 ? (
                                <div className="path-wrapper">
                                    <span className="path">{node.path}</span>

                                    <span>{node.properties.name}</span>
                                </div>
                            ) : (
                                <span>{node.properties.name}</span>
                            )}
                        </FilteredWrapper>
                    </div>

                    <HoverLabelIcons
                        node={node}
                        onCopy={onAutoformCopy}
                        onAddToPortfolio={onAddToPortfolioModalOpen}
                        onAddItem={onAddModalOpen}
                    />
                </div>
            );
        },
        [search, onAutoformCopy, onAddToPortfolioModalOpen, onAddModalOpen, onExpandChange, secureChangingSelectedNode]
    );

    const onDiscardChangesConfirm = useCallback(() => {
        setSelected(temporaryNode);
        confirmationModal.onClose();
        dispatch(onHasUnsavedChanges(false));
    }, [confirmationModal, dispatch, temporaryNode]);

    const renderEmptyState = useCallback(
        () => (
            <WrapperEmptyState
                imgSrc={hydroPlaceholder}
                messages={{
                    header: <FormattedMessage id="no_power_plant_selected" />,
                    body: <FormattedMessage id="select_power_plant_hint" />,
                }}
            />
        ),
        []
    );

    const autoformConfig = useMemo(
        () => ({
            addSubItemMessageId: 'autoform_add_new_subitem',
            hidePropertiesButton: true,
            autoformIcon: <HydroPlantUnitIcon />,
        }),
        []
    );
    const noSearchResults = useMemo(
        () => search.trim().length > 0 && filteredTreeData.length === 0,
        [search, filteredTreeData]
    );

    return (
        <>
            <Title>{pageTitle}</Title>

            <LayoutWrapper
                hasFooter={false}
                hasSecondaryMenu
                sidebar={
                    <LayoutSidebar
                        itemType={'portfolio'}
                        selected={selected}
                        titleId={'hydro-title'}
                        titleMessage={metadata?.formLabel}
                        accordionPlaceholderId={'autoform_select_item'}
                        searchTerm={search}
                        searchPlaceholderId={'common_search'}
                        searchCallback={setSearch}
                    >
                        <TreeWrapper classNamePrefix="hydro">
                            {isLoading || isMetadataLoading || !metadata ? (
                                <Skeleton mx={6}>
                                    <Box h="34px">
                                        <FormattedMessage id="common_loading" />
                                    </Box>
                                </Skeleton>
                            ) : isLoadingError ? (
                                <Box mx={6}>
                                    <Alert status="error">
                                        <AlertIcon />

                                        <Box>
                                            <AlertTitle textTransform="capitalize">
                                                <FormattedMessage id="common_error" />
                                            </AlertTitle>

                                            <AlertDescription>
                                                <FormattedMessage
                                                    id="common_loading_error"
                                                    values={{ items: intl.formatMessage({ id: 'common_items' }) }}
                                                />
                                            </AlertDescription>
                                        </Box>
                                    </Alert>
                                </Box>
                            ) : noSearchResults ? (
                                <Box px={6}>
                                    <Alert status="info">
                                        <AlertIcon />
                                        <FormattedMessage id="common_no_search_results" />
                                    </Alert>
                                </Box>
                            ) : (
                                <Tree
                                    aria-labelledby="hydro-title"
                                    nodes={filteredTreeData}
                                    focused={focused}
                                    onFocusChange={onFocusChange}
                                    expanded={expanded}
                                    onExpandChange={onExpandChange}
                                    selected={selected ? selected.id : null}
                                    onSelectChange={secureChangingSelectedNode}
                                    renderLabel={renderLabel}
                                    expandOnSelect={false}
                                />
                            )}
                        </TreeWrapper>
                    </LayoutSidebar>
                }
                content={
                    <Switch>
                        <Route exact path={path}>
                            {renderEmptyState()}
                        </Route>

                        <Route path={`${path}/:itemId`}>
                            {selected && selected.properties?.isPlantUnit ? (
                                <AutoformContainer
                                    isLoading={isMetadataLoading}
                                    autoformId={autoformId}
                                    onNodeRename={onNodeRename}
                                    onNodeItemDelete={onNodeItemDelete}
                                    config={autoformConfig}
                                />
                            ) : (
                                renderEmptyState()
                            )}
                        </Route>
                    </Switch>
                }
            >
                {confirmationModal.isOpen && (
                    <ConfirmationModal
                        isOpen
                        hasExtraStep
                        onClose={confirmationModal.onClose}
                        onConfirm={onDiscardChangesConfirm}
                        header={<FormattedMessage id="common_discard_changes" />}
                        content={<FormattedMessage id="common_confirmation_explanation" />}
                    />
                )}

                {addItemModal.isOpen && (
                    <AutoformAddRecordModal
                        tableId={tableId}
                        itemType={'Item'}
                        isOpen={addItemModal.isOpen}
                        onClose={addItemModal.onClose}
                        onCreateRecord={onAddRecord}
                        onCheckData={onCheckData}
                    />
                )}
                {addToPortfolioModal.isOpen && (
                    <AddItemPortfolios
                        onClose={closeAddToPortfolioModal}
                        sourceId={temporaryNode?.properties?.id}
                        itemName={temporaryNode?.properties?.name}
                        onAddSuccess={onAddItemToPortfolioSuccess}
                        onSubmit={addItemToPortfolios}
                    />
                )}
            </LayoutWrapper>
        </>
    );
};

export default HydroTreeTab;
