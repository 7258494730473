const Progress = {
    parts: ['label', 'filledTrack', 'track'],

    baseStyle: {
        track: {
            width: '100%',
            borderRadius: '2xl',
            backgroundColor: 'green.300',
        },
        filledTrack: {
            backgroundColor: 'green.500',
        },
    },

    variants: {
        blue: {
            track: {
                width: '100%',
                borderRadius: '2xl',
                backgroundColor: 'gray.200',
            },
            filledTrack: {
                backgroundColor: 'blue.500',
            },
        },
    },

    defaultProps: {
        size: 'sm',
    },
};

export default Progress;
