import { useMemo, useRef, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Box, Button } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { getJobParameters } from '../../services/studies';
import DataGridWrapper from '../grid/DataGridWrapper';
import DataGrid from '../grid/DataGrid';
import MainModal from '../modal/MainModal';
import SortOrderProvider from '../grid/utils/SortOrderContext';

const JobParametersModal = ({ jobId, onClose }) => {
    const intl = useIntl();
    const parametersGridApi = useRef();

    const onParametersGridReady = useCallback(
        async (params) => {
            parametersGridApi.current = params.api;
            try {
                const response = await getJobParameters(jobId);
                parametersGridApi.current.setRowData(response);
            } catch {
                parametersGridApi.current.showNoRowsOverlay();
            }
        },
        [jobId]
    );

    const parametersColumns = useMemo(
        () => [
            {
                field: 'parameter',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_parameters_parameter_name' }),
                minWidth: 180,
                sort: 'asc',
            },
            {
                field: 'varValue',
                editable: false,
                headerName: intl.formatMessage({ id: 'job_parameters_varvalue_name' }),
                minWidth: 180,
            },
        ],
        [intl]
    );

    return (
        <MainModal
            isOpen
            onClose={onClose}
            size="6xl"
            header={<FormattedMessage id="job_parameters_header" values={{ id: jobId }} />}
            content={
                <SortOrderProvider>
                    <JobParametersGridWrapper>
                        <DataGrid
                            onGridReady={onParametersGridReady}
                            disableReadOnlyStyles={true}
                            columns={parametersColumns}
                            pagination={false}
                        />
                    </JobParametersGridWrapper>
                </SortOrderProvider>
            }
            secondaryButton={
                <Button variant="secondary" onClick={onClose}>
                    <Box as="span" textTransform="capitalize">
                        <FormattedMessage id="common_close" />
                    </Box>
                </Button>
            }
        />
    );
};

const JobParametersGridWrapper = styled(DataGridWrapper)`
    flex: 1 1 300px;
    margin-top: 12px;

    .ag-row-even {
        background: var(--chakra-colors-ag-row-even) !important;
    }
`;

export default JobParametersModal;
