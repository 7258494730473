import { forwardRef } from 'react';
import { Input } from '@chakra-ui/react';

import FormField from './FormField';

// If you need to extend Input functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for Input should be excluded from ...props and manually added
const InputField = ({ type, value, onChange, placeholder, isWarning, ...props }, ref) => (
    <FormField {...props}>
        <Input
            type={type}
            placeholder={placeholder}
            // zDefaultValue could be returned which means empty value
            value={value === 'zDefaultValue' ? '' : value}
            borderColor={isWarning ? 'orange.300' : undefined}
            _hover={isWarning ? { borderColor: 'orange.300' } : undefined}
            onChange={onChange}
            ref={ref}
        />
    </FormField>
);

export default forwardRef(InputField);
