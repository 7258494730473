import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    autoUpdateStudies: false,
};

const studiesSlice = createSlice({
    name: 'studies',
    initialState,
    reducers: {
        onStudiesAutoUpdate(state, action) {
            state.autoUpdateStudies = action.payload;
        },
    },
});

export const { onStudiesAutoUpdate } = studiesSlice.actions;
export default studiesSlice.reducer;
