import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Heading,
    Flex,
    Box,
    Accordion,
    SimpleGrid,
    IconButton,
    useDisclosure,
    Button,
    Divider,
    Spacer,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { getLoadDefinitions, deleteLoadDefinition, editLoad, createLoadDefinition } from '../../services/loads';
import { validateLongLat } from '../../services/portfolios';
import {
    deleteItem,
    isFilterApplied,
    getDataFilters,
    checkItemInputEvent,
    flatItemDescriptions,
} from '../../services/items';

import LoadDefinition from './LoadDefinition';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import ShowHideSidebarButton from '../layout/ShowHideSidebarButton';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';
import AutomaticFormikDirtyCheck from '../forms/AutomaticFormikDirtyCheck';

import ConfirmationModal from '../modal/ConfirmationModal';

import ItemFilters from '../utils/ItemFilters';
import Error from '../utils/Error';
import SideDrawer from '../utils/SideDrawer';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import AddSubItem from '../utils/AddSubItem';
import AddSubItemModal from '../utils/AddSubItemModal';
import DeleteRecordModal from '../utils/DeleteRecordModal';
import ItemPropertiesModal from '../utils/ItemPropertiesModal';
import SecondaryIconButton from '../utils/SecondaryIconButton';
import FiltersButton from '../utils/FiltersButton';
import Tooltip from '../utils/Tooltip';
import ActionBar from '../utils/ActionBar';
import CustomItemIcon from '../utils/CustomItemIcon';
import PromptWrapper from '../utils/PromptWrapper';
import HelpButtonComponent from '../utils/HelpButtonComponent';
import ErrorAccordion from '../utils/ErrorAccordion';

import { onHasUnsavedChanges } from '../../store/helpers/helpersSlice';

import { ReactComponent as PowerCableIcon } from '../../icons/power-cable.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as DeleteLoadIcon } from '../../icons/delete.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as FileIcon } from '../../icons/file.svg';
import { isSubmitDisabled } from '../../services/utils';

import { helpAnchors } from 'constants/help';

// Load component is used in Loads and Portfolios
// onLoadEdit, onLoadDelete, typeId props are active if the Load component is called from Loads
// loadItem, onNodeRename, onNodeChangeIsMaster, onNodeOwnershipPercentageUpdate, onNodeItemDelete props are active if the Load component is called from Portfolios

const Load = ({
    onLoadEdit,
    onLoadDelete,
    typeId,
    loadItem,
    onNodeRename,
    onNodeChangeIsMaster,
    onNodeOwnershipPercentageUpdate,
    onNodeItemDelete,
    refreshMapLayer,
    helpPageUrlItemTypeId,
}) => {
    const { loadId } = useParams();
    const { toast } = useCommonToast();
    const intl = useIntl();
    const historyPush = useHistoryPush();
    const dispatch = useDispatch();

    const deleteModal = useDisclosure();
    const deleteItemModal = useDisclosure();
    const filterDrawer = useDisclosure();
    const propertiesModal = useDisclosure();
    const addSubItemModal = useDisclosure();

    const dataForItemCreation = useSelector((state) => state.item.dataForItemCreation);
    const pricingPoints = dataForItemCreation?.pricingPoints;
    const rtelecBasis = dataForItemCreation?.rtelecBasis;

    const itemId = Number(loadId || loadItem.properties.id);
    const itemTypeId = typeId || loadItem.properties.typeId;
    const item = 'load';

    const [loadDefinitions, setLoadDefinitions] = useState(null);
    const [selectedLoad, setSelectedLoad] = useState(null);
    const [filters, setFilters] = useState(() => getDataFilters(item));

    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [subloadForDeletion, setSubloadForDeletion] = useState(null);
    const [editDescriptionConfig, setEditDescriptionConfig] = useState(null);
    const [isFormDirty, setIsFormDirty] = useState(false);

    const selectedLoadRef = useRef(null);

    const formLabels = {
        book: intl.formatMessage({ id: 'load_book' }),
        pricingPoint: intl.formatMessage({ id: 'common_electric_pricing_point' }),
        rtelecBasis: intl.formatMessage({ id: 'common_rtelec_pricing_point' }),
        percentOwned: intl.formatMessage({ id: 'load_percent_owned' }),
        modelType: intl.formatMessage({ id: 'load_definitions_model_type' }),
        weatherZone: intl.formatMessage({ id: 'load_definitions_weather_zone' }),
        dataWeight: intl.formatMessage({ id: 'load_definitions_data_weighting' }),
        retailPriceItem: intl.formatMessage({ id: 'load_definitions_retail_price_item' }),
        native: intl.formatMessage({ id: 'load_definitions_native' }),
        nativeOptionTrue: intl.formatMessage({ id: 'load_definitions_native_option_true' }),
        nativeOptionFalse: intl.formatMessage({ id: 'load_definitions_native_option_false' }),
        selectPlaceholder: intl.formatMessage({ id: 'common_forms_select_option' }),
        latitude: intl.formatMessage({ id: 'latitude' }),
        longitude: intl.formatMessage({ id: 'longitude' }),
        master: intl.formatMessage({ id: 'common_master' }),
        plantType: intl.formatMessage({ id: 'load_definitions_plant_type' }),
    };

    const initialFormValues = {
        description: selectedLoad?.description,
        bookId: selectedLoad?.bookId,
        pricingPointId: selectedLoad?.pricingPointId,
        rtelecBasisPriceId: selectedLoad?.rtelecBasisPriceId,
        definitions: loadDefinitions?.loadDefinitionResponses.length > 0 && {},
        latitude: selectedLoad?.latitude !== null ? selectedLoad?.latitude : '',
        longitude: selectedLoad?.longitude !== null ? selectedLoad?.longitude : '',
        securityId: selectedLoad?.securityId,
    };

    const nativeOptions = useMemo(
        () => [
            { id: 0, description: formLabels.nativeOptionTrue },
            { id: -1, description: formLabels.nativeOptionFalse },
        ],
        [formLabels.nativeOptionTrue, formLabels.nativeOptionFalse]
    );

    loadDefinitions?.loadDefinitionResponses.forEach((def, idx) => {
        // Formik considers numbers in fields as an array of objects
        initialFormValues.definitions[idx] = {};

        initialFormValues.definitions[idx]['loadId'] = def.id;
        initialFormValues.definitions[idx]['description'] = def.description;
        initialFormValues.definitions[idx]['modelWeightId'] = def.modelWeightId || null;
        initialFormValues.definitions[idx]['moduleProcessId'] = def.moduleProcessId || null;
        initialFormValues.definitions[idx]['retailPriceItemId'] = def.retailPriceItemId || '';
        initialFormValues.definitions[idx]['weatherZoneId'] = def.weatherZoneId || null;
        initialFormValues.definitions[idx]['nativeLoad'] = def.nativeLoad ? 0 : -1; // Options are 0 or -1
        initialFormValues.definitions[idx]['plantTypeId'] = def.plantTypeId || null;

        return initialFormValues.definitions[idx];
    });

    const fetchLoadDefinitions = useCallback(() => {
        return getLoadDefinitions(itemId)
            .then((data) => {
                setSelectedLoad(data.loadItem);
                selectedLoadRef.current = data.loadItem;
                setLoadDefinitions({ ...data, nativeOptions });
                setEditDescriptionConfig(flatItemDescriptions(data.loadDefinitionResponses, itemId));

                // This is needed to show results after previous error
                setLoadingError(false);
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [itemId, nativeOptions]);

    useEffect(() => {
        setIsLoading(true);
        fetchLoadDefinitions();
    }, [fetchLoadDefinitions]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const onDeleteModalOpen = (subloadId, subloadDesc) => {
        setSubloadForDeletion({
            id: subloadId,
            description: subloadDesc,
        });
        deleteModal.onOpen();
    };

    const onDeleteModalClose = () => {
        deleteModal.onClose();
        setSubloadForDeletion(null);
    };

    const onSubLoadDelete = async (subloadId) => {
        onDeleteModalClose();

        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'sub-load' });

        try {
            setIsLoading(true);

            await deleteLoadDefinition(subloadId);
            await fetchLoadDefinitions();

            toast(successMessage);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    // Move validateDescription
    // Move loadModules to services
    // Add all this to hedges
    const validateDescription = (value, selectedLoadId) => {
        const trimmed = value.trim();
        let errorMessage = '';

        if (trimmed.length === 0) {
            errorMessage = intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Load name' });
        } else if (trimmed.length > 100) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Load name', lengthRule: '100 characters or less' }
            );
        }

        if (errorMessage !== '') {
            setEditDescriptionConfig((prevState) => {
                return { ...prevState, [selectedLoadId]: { ...prevState[selectedLoadId], penDisabled: true } };
            });
        } else {
            setEditDescriptionConfig((prevState) => {
                return { ...prevState, [selectedLoadId]: { ...prevState[selectedLoadId], penDisabled: false } };
            });
        }

        return errorMessage;
    };

    const onLoadFormSubmit = async ({
        description,
        bookId,
        pricingPointId: ppId,
        rtelecBasisPriceId,
        definitions,
        longitude,
        latitude,
        securityId,
    }) => {
        const successMessage = intl.formatMessage({ id: 'load_save_changes_success' });
        const arcGISError = intl.formatMessage({ id: 'common_item_arcgis_lat_long_save_failed' });

        const pricingPointId = ppId || null;
        const subLoads = [];

        Object.values(definitions).forEach((subLoad) =>
            subLoads.push({
                ...subLoad,
                nativeLoad: subLoad.nativeLoad === 0 ? true : false,
                retailPriceItemId: subLoad.retailPriceItemId || null,
            })
        );

        const payload = {
            load: {
                description,
                bookId,
                itemTypeId,
                pricingPointId,
                rtelecBasisPriceId,
                itemId,
                longitude: longitude !== '' ? longitude : null,
                latitude: latitude !== '' ? latitude : null,
                securityId,
            },
            subLoads,
        };

        try {
            const { arcGisIssue } = await editLoad(payload);

            await fetchLoadDefinitions();

            if (!loadItem) {
                onLoadEdit({
                    id: selectedLoad.id,
                    description,
                    bookId,
                    pricingPointId,
                    rtelecBasisPriceId,
                    securityId,
                });
            } else {
                onNodeRename(description);
                onNodeChangeIsMaster(securityId);
            }

            toast({
                message: successMessage,
                slot: arcGisIssue && <ErrorAccordion message={arcGISError} details={arcGisIssue} status={'warn'} />,
                duration: arcGisIssue && 20000,
            });
        } finally {
            if (typeof refreshMapLayer === 'function') {
                refreshMapLayer({ long: longitude, lat: latitude, objectRefId: selectedLoadRef.current.objectRefId });
            }
        }
    };

    const onItemDelete = async (targetLoad) => {
        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'load item' });

        await deleteItem(targetLoad.id);

        dispatch(onHasUnsavedChanges(false));

        //check whether the load item is deleted from Load or Portfolios
        if (loadItem) {
            onNodeItemDelete();
        } else {
            onLoadDelete(targetLoad);
            historyPush('/loads');
        }

        toast(successMessage);
    };

    const onApplyFilters = (newFilters) => {
        filterDrawer.onClose();

        setFilters(newFilters);
    };

    const onEditDescription = (loadId) => {
        if (!editDescriptionConfig[loadId].penDisabled) {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [loadId]: { isActive: !prevState[loadId].isActive, penDisabled: false },
                };
            });
        }
    };

    const onAddSubItem = (subLoad) => {
        addSubItemModal.onClose();

        setEditDescriptionConfig((prevState) => ({
            ...prevState,
            [subLoad.id]: { isActive: false, penDisabled: false },
        }));

        setLoadDefinitions((prev) => ({
            ...prev,
            loadDefinitionResponses: [...prev.loadDefinitionResponses, subLoad],
        }));
    };

    const validate = (values) => {
        let longitude = validateLongLat(values.longitude, 'long', values.latitude);
        let latitude = validateLongLat(values.latitude, 'lat', values.longitude);

        return {
            ...(longitude ? { longitude } : null),
            ...(latitude ? { latitude } : null),
        };
    };

    return (
        <>
            <PromptWrapper />

            <ExpandableItemWrapper>
                {isLoading ? (
                    <DefinitionsSkeleton />
                ) : loadingError ? (
                    <Box m={6}>
                        <Error
                            primaryId="common_error"
                            secondaryId="common_loading_error"
                            additionalText="definitions"
                        />
                    </Box>
                ) : (
                    <>
                        <Formik
                            enableReinitialize
                            initialValues={initialFormValues}
                            onSubmit={onLoadFormSubmit}
                            validate={validate}
                        >
                            {({ isSubmitting, errors, touched, values }) => (
                                <Form>
                                    <AutomaticFormikDirtyCheck
                                        isFormDirty={isFormDirty}
                                        onFormDirtyChange={setIsFormDirty}
                                    />

                                    <Flex pt={3} pl={6} align="center">
                                        <CustomItemIcon isMaster={selectedLoad.securityId} icon={PowerCableIcon} />
                                        {!editDescriptionConfig[selectedLoad.id]?.isActive ? (
                                            <Heading px={2} as="h2" variant="h2">
                                                {values.description}
                                            </Heading>
                                        ) : (
                                            <InputFormikField
                                                px={3}
                                                flex="1"
                                                maxW="500px"
                                                validate={(value) => validateDescription(value, selectedLoad.id)}
                                                name="description"
                                                type="text"
                                                onKeyDown={checkItemInputEvent}
                                                isFastField
                                            />
                                        )}

                                        <Box display="inline-block" mr={4}>
                                            <Tooltip
                                                label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}
                                            >
                                                <IconButton
                                                    aria-label={intl.formatMessage({
                                                        id: 'common_edit_btn_tooltip_and_label',
                                                    })}
                                                    variant="circular-icon"
                                                    leftIcon={<EditIcon />}
                                                    onClick={() => onEditDescription(selectedLoad.id)}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Flex>

                                    <ActionBar>
                                        <ShowHideSidebarButton />

                                        <SecondaryIconButton
                                            leftIcon={<FileIcon />}
                                            size="sm"
                                            variant="secondary"
                                            onClick={propertiesModal.onOpen}
                                            flexShrink={0}
                                        >
                                            <FormattedMessage id="portfolio_item_properties" />
                                        </SecondaryIconButton>

                                        <FiltersButton
                                            hasIndicator={isFilterApplied(item)}
                                            onClick={filterDrawer.onOpen}
                                        />
                                        <HelpButtonComponent
                                            helpAnchor={helpAnchors.LOAD_FILTER_BAR}
                                            itemTypeId={helpPageUrlItemTypeId}
                                        />
                                        <Spacer display={{ base: 'none', xl: 'block' }} />

                                        <Button
                                            leftIcon={<DeleteLoadIcon />}
                                            type="button"
                                            variant="special"
                                            onClick={deleteItemModal.onOpen}
                                            size="sm"
                                        >
                                            <FormattedMessage id="common_delete_item_btn" />
                                        </Button>

                                        <Button
                                            leftIcon={<SaveIcon />}
                                            isDisabled={isSubmitDisabled({ errors, touched })}
                                            isLoading={isSubmitting}
                                            type="submit"
                                            variant="primary-success"
                                            size="sm"
                                        >
                                            <FormattedMessage id="common_save_changes_button" />
                                        </Button>
                                    </ActionBar>

                                    <Divider />

                                    <SimpleGrid pt={7} pb={0} px={6} columns={{ xl: 4 }} spacingX={6} spacingY={4}>
                                        <ComboboxFormikField
                                            id="bookId"
                                            name="bookId"
                                            label={formLabels.book}
                                            options={loadDefinitions.books}
                                            valueKey="id"
                                            labelKey="name"
                                            isFastField
                                        />

                                        <InputFormikField
                                            id="latitude"
                                            name="latitude"
                                            label={formLabels.latitude}
                                            type="number"
                                            isFastField
                                        />

                                        <InputFormikField
                                            id="longitude"
                                            name="longitude"
                                            label={formLabels.longitude}
                                            type="number"
                                            isFastField
                                        />

                                        <CheckboxFormikField id="securityId" name="securityId" mt={5}>
                                            {formLabels.master}
                                        </CheckboxFormikField>
                                    </SimpleGrid>

                                    <SimpleGrid pt={7} pb={5} px={6} columns={{ xl: 2 }} spacingX={6} spacingY={4}>
                                        <ComboboxFormikField
                                            id="pricingPointId"
                                            name="pricingPointId"
                                            label={formLabels.pricingPoint}
                                            info={<FormattedMessage id="common_pricing_point_empty_tooltip" />}
                                            options={pricingPoints}
                                            valueKey="id"
                                            labelKey="description"
                                            showPlaceholder={true}
                                            placeholderValue={null}
                                            isFastField
                                        />

                                        <ComboboxFormikField
                                            id="rtelecBasisPriceId"
                                            name="rtelecBasisPriceId"
                                            label={formLabels.rtelecBasis}
                                            options={rtelecBasis}
                                            labelKey="description"
                                            valueKey="id"
                                            showPlaceholder={true}
                                            placeholderValue={null}
                                            isFastField
                                        />
                                    </SimpleGrid>

                                    <Box>
                                        {loadDefinitions.loadDefinitionResponses.length === 0 ? (
                                            <AddSubItem onOpen={addSubItemModal.onOpen} />
                                        ) : (
                                            <Accordion allowToggle defaultIndex={[0]}>
                                                {loadDefinitions.loadDefinitionResponses.map((def, idx) => {
                                                    const itemId = def.id;
                                                    return (
                                                        <LoadDefinition
                                                            key={itemId}
                                                            itemLabel={item}
                                                            itemId={itemId}
                                                            idx={idx}
                                                            definitions={loadDefinitions}
                                                            formLabels={formLabels}
                                                            filters={filters}
                                                            onDeleteModalOpen={onDeleteModalOpen}
                                                            editDescriptionConfig={editDescriptionConfig}
                                                            onEditDescription={onEditDescription}
                                                            setEditDescriptionConfig={setEditDescriptionConfig}
                                                            onAddSubItemModalOpen={addSubItemModal.onOpen}
                                                            helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                                                        />
                                                    );
                                                })}
                                            </Accordion>
                                        )}
                                    </Box>
                                </Form>
                            )}
                        </Formik>

                        {deleteModal.isOpen && (
                            <DeleteRecordModal
                                onClose={onDeleteModalClose}
                                headerItem="Sub-load"
                                contentMessageItem={subloadForDeletion.description}
                                onConfirm={() => {
                                    onSubLoadDelete(subloadForDeletion.id);
                                }}
                            />
                        )}

                        {deleteItemModal.isOpen && (
                            <ConfirmationModal
                                isOpen
                                onClose={deleteItemModal.onClose}
                                header={<FormattedMessage id="common_delete_modal_heading" values={{ item: 'Item' }} />}
                                hasExtraStep
                                content={
                                    <FormattedMessage
                                        id="common_delete_modal_msg"
                                        values={{ item: selectedLoad.description }}
                                    />
                                }
                                confirmText={
                                    <Box as="span" textTransform="capitalize">
                                        <FormattedMessage id="common_delete" />
                                    </Box>
                                }
                                onConfirm={() => {
                                    deleteItemModal.onClose();
                                    onItemDelete(selectedLoad);
                                }}
                            />
                        )}

                        {propertiesModal.isOpen && (
                            <ItemPropertiesModal
                                isOpen
                                onClose={propertiesModal.onClose}
                                itemId={selectedLoad.id}
                                portfolio={loadItem}
                                onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                            />
                        )}

                        {filterDrawer.isOpen && (
                            <SideDrawer
                                isOpen
                                onClose={filterDrawer.onClose}
                                header={<FormattedMessage id="common_filter_heading" values={{ item: 'Load' }} />}
                                content={
                                    <ItemFilters
                                        filters={filters}
                                        onApplyFilters={onApplyFilters}
                                        item={item}
                                        itemStartFilterHeading={<FormattedMessage id={'common_item_start_filter'} />}
                                        secondaryFilterHeading={<FormattedMessage id={'common_item_created_filter'} />}
                                    />
                                }
                                placement="right"
                            />
                        )}

                        {addSubItemModal.isOpen && (
                            <AddSubItemModal
                                isOpen
                                itemType="Load"
                                itemTypeId={itemTypeId}
                                onClose={addSubItemModal.onClose}
                                definitions={loadDefinitions}
                                formLabels={formLabels}
                                selectedLoad={selectedLoad}
                                onCreateSubItem={createLoadDefinition}
                                onAddSubItem={onAddSubItem}
                            />
                        )}
                    </>
                )}
            </ExpandableItemWrapper>
        </>
    );
};

export default Load;
