import { useState } from 'react';
import { Flex, Box, IconButton } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useIntl } from 'react-intl';

import { ReactComponent as ChevronDownIcon } from '../../icons/arrow_drop_down.svg';

import Footer from '../../components/layout/Footer';

const DashboardLayoutWrapper = ({ header, sidebar, content, hasFooter = false, children }) => {
    const intl = useIntl();

    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <ContentWrapper bg="background-primary">
            <SidebarWrapper isExpanded={isExpanded} bg="background-septenary">
                {!isExpanded && <Box className="sidebar">{sidebar}</Box>}

                <Box className="sidebar-button-wrapper">
                    <IconButton
                        variant="sidebar-button"
                        className="sidebar-button"
                        icon={<ChevronDownIcon />}
                        aria-label={intl.formatMessage({
                            id: 'common_download_btn_tooltip_and_label',
                        })}
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                </Box>
            </SidebarWrapper>

            <MainContent bg="background-secondary">
                {header}
                {content}

                {hasFooter && (
                    <Box mt="auto" mb="0">
                        <Footer />
                    </Box>
                )}
            </MainContent>

            {children}
        </ContentWrapper>
    );
};

const ContentWrapper = styled.div`
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;

    @media (min-width: 992px) {
        // 1px for the border to be visible
        top: 81px;
        position: fixed;
        overflow-y: initial;
        flex-direction: row;
    }
`;

const SidebarWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'isExpanded' })`
    position: relative;
    overflow-y: auto;

    // width is 4px larger to clear the Chakra focus shadow
    width: ${(props) => (props.isExpanded ? '20px' : 'auto')};

    .sidebar {
        padding: 32px 24px;
    }

    .sidebar-button-wrapper {
        display: none;
        align-items: center;

        .sidebar-button {
            transform: ${(props) => props.isExpanded && 'rotate(180deg)'};
            transition-property: none;
            height: 44px;
            min-width: 0;
            width: ${(props) => (props.isExpanded ? '16px' : '44px')};
            border-top-right-radius: ${(props) => (props.isExpanded ? '0' : 'var(--chakra-radii-md)')};
            border-bottom-right-radius: ${(props) => (props.isExpanded ? '0' : 'var(--chakra-radii-md)')};
            border-top-left-radius: var(--chakra-radii-md);
            border-bottom-left-radius: var(--chakra-radii-md);
            overflow-x: hidden;

            svg {
                transform: rotate(90deg) ${(props) => props.isExpanded && 'scale(1.5)'};

                path {
                    fill: var(--chakra-colors-black);
                }
            }

            &:hover {
                background: var(--chakra-colors-blue-100);
            }
        }
    }

    @media (min-width: 992px) {
        .sidebar {
            width: 320px;
        }

        .sidebar-button-wrapper {
            display: flex;
            position: absolute;

            // position is 4px to the right to clear the Chakra focus shadow
            right: ${(props) => (props.isExpanded ? '4px' : '20px')};
            top: 20px;
        }
    }
`;

const MainContent = styled(Flex)`
    flex-direction: column;
    flex: 1 1 auto;

    @media (min-width: 769px) {
        overflow-y: auto;
    }
`;

export default DashboardLayoutWrapper;
