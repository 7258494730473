import { useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalContent,
    ModalCloseButton,
    ModalOverlay,
    useMediaQuery,
} from '@chakra-ui/react';

import DataGridWrapper from './DataGridWrapper';
import DataGridReadOnly from './DataGridReadOnly';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';

const DataSourceViewModal = ({ onClose, fetchDataFunction, ...rest }) => {
    const [isDesktop] = useMediaQuery('(min-width: 769px)');

    const [dataGridRows, setDataGridRows] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [databaseTableName, setdatabaseTableName] = useState(null);

    const setColumns = (row) => {
        let columnDefinitions = [];
        for (let key in row) {
            columnDefinitions.push({
                field: key,
                headerTooltip: key.toUpperCase(),
            });
        }
        setColumnDefs(columnDefinitions);
    };

    const fetchData = useCallback(async () => {
        if (fetchDataFunction !== undefined) {
            const tableData = await fetchDataFunction();
            if (tableData?.dataBaseTableName) {
                setdatabaseTableName(tableData.dataBaseTableName);
            }

            if (tableData?.columnDefinitions?.length > 0) {
                setColumnDefs(tableData.columnDefinitions);
            } else if (tableData?.columnDefinitions === null || tableData?.columnDefinitions === undefined) {
                if (tableData?.rowData?.length > 0) {
                    setColumns(tableData.rowData[0]);
                } else if (tableData?.items?.length > 0) {
                    setColumns(tableData.items[0]);
                } else {
                    setColumnDefs([]);
                }
            }

            if (tableData?.rowData !== undefined) {
                setDataGridRows(tableData.rowData);
            } else if (tableData?.items !== undefined) {
                setDataGridRows(tableData.items);
            } else {
                setDataGridRows([]);
            }
        } else {
            setColumnDefs([]);
            setDataGridRows(null);
        }
        setIsLoading(false);
    }, [fetchDataFunction]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Modal isOpen onClose={onClose} size={isDesktop ? '6xl' : 'full'}>
            <ModalOverlay />

            <ModalContent pt={3} borderRadius={isDesktop ? '2xl' : 0}>
                <ModalHeader>
                    <FormattedMessage id="datasourceview_modal_title" />
                </ModalHeader>

                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <ModalBody>
                    {isLoading ? (
                        <div>
                            <FormattedMessage id="common_loading" />
                            <DefinitionsSkeleton />
                        </div>
                    ) : (
                        <div>
                            {databaseTableName != null ? (
                                <div style={{ marginBottom: '25px' }}>
                                    <h4>Table Name: {databaseTableName}</h4>
                                </div>
                            ) : null}
                            <DataGridWrapper>
                                <DataGridReadOnly columns={columnDefs} rowData={dataGridRows} />
                            </DataGridWrapper>
                        </div>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default DataSourceViewModal;
