import { addDays, addHours } from 'date-fns';
import axios from 'axios';

export const convertRelativeToUTCDateMidnight = (value) => {
    const todayDate = new Date();
    const relativeDate = addDays(todayDate, value);
    const midnightRelativeDate = new Date(
        Date.UTC(relativeDate.getUTCFullYear(), relativeDate.getUTCMonth(), relativeDate.getUTCDate(), 0, 0, 0)
    );
    return midnightRelativeDate;
};

export const convertRelativeToUTCDateMidnightExtraDayMinusOneHour = (value) => {
    // Per business request include extra day for end date, but do not include midnight
    const extraDay = value + 1;
    let newDate = convertRelativeToUTCDateMidnight(extraDay);
    newDate = addHours(newDate, -1);
    return newDate;
};

export const scrollFromTopWithOffset = (element, offset) => {
    const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate + offset });
};

export const noop = () => {};

export const formatDuration = (params) => {
    if (!params.value) return '0h 0m 0s';

    const hours = Math.floor(params.value / 3600);
    const minutes = Math.floor((params.value % 3600) / 60);
    const seconds = ((params.value % 3600) % 60) % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
};

export const appVersion = `PowerSIMM V - ${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ID}`;

export const basisConfiguratorSectionScrollOffset = -140;

export const ISO_RTO_OPTIONS = [
    {
        id: 'CAISO',
        description: 'CAISO',
    },
    {
        id: 'ERCOT',
        description: 'ERCOT',
    },
    {
        id: 'ISONE',
        description: 'ISONE',
    },
    {
        id: 'MISO',
        description: 'MISO',
    },
    {
        id: 'NYISO',
        description: 'NYISO',
    },
    {
        id: 'PJM',
        description: 'PJM',
    },
    {
        id: 'SPP',
        description: 'SPP',
    },
];

export const DARTM_OPTIONS = [
    {
        id: 'ENERGY_DA',
        description: 'DA',
    },
    {
        id: 'ENERGY_RT_5',
        description: 'RT 5MM',
    },
];

export const DARTM_OPTIONS_CAISO = [
    {
        id: 'ENERGY_DA',
        description: 'DA',
    },
    {
        id: 'ENERGY_RT_5',
        description: 'RT 5MM',
    },
    {
        id: 'ENERGY_RT_15',
        description: 'RT 15MM',
    },
];

export const CAISO_OPTION = 'CAISO';

export const BASIS_SETUP_DARTM_OPTIONS = {
    da: 'da',
    rt5mm: 'rt5mm',
    rt15mm: 'rt15mm',
};

export const TRAVERSE_INPUT_TABLE_DESCRIPTION = 'Traverse';

export const POWERSIMM_INPUT_TABLE_DESCRIPTION = 'PowerSimm SpotPriceDataTable';

export const isSomeErrorsTouched = (errors, touched) => {
    for (const key of Object.keys(errors ?? {})) {
        if (
            typeof errors[key] === 'object' &&
            !Array.isArray(errors[key]) &&
            isSomeErrorsTouched(errors[key], touched[key] ?? {})
        ) {
            return true;
        } else if (touched[key] === true) {
            return true;
        }
    }
    return false;
};

export const isSubmitDisabled = ({ errors, touched }) => isSomeErrorsTouched(errors, touched);

export const getHelpPage = async (fileName, payload) => {
    //Call the help-page endpoint to get
    const response = await axios.get(`/help-page?fileName=${fileName}`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response?.data;
};

export const isEmpty = (val) => !val || val.length === 0;

export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}
