import { useMemo } from 'react';
import { Heading } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import HelpButtonComponent from 'components/utils/HelpButtonComponent';

import { helpAnchors } from 'constants/help';

import {
    getHistoricalOutputs,
    updateHistoricalOutputs,
    createHistoricalOutputs,
    deleteHistoricalOutputs,
    deleteAllHistoricalOutputs,
    validateHistoricalOutputs,
    getHistoricalOutputsDataSourceView,
} from '../../services/thermals';
import { getValidRowsWithDates } from '../../services/grid';

import { additionalGridKeys } from '../../constants/thermal';

import SubItemData from '../itemData/SubItemData';

const HistoricalOutput = ({
    subThermalId,
    metadata,
    onLoadingStart,
    onLoadingEnd,
    type,
    helpPageUrlItemTypeId = null,
}) => {
    const intl = useIntl();

    const gridResource = useMemo(() => {
        return {
            create: (rows) => createHistoricalOutputs(subThermalId, rows),
            read: (pagination) => getHistoricalOutputs(subThermalId, { ...pagination }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['eventDate']);
                return updateHistoricalOutputs(subThermalId, validRows);
            },
            delete: (rows) =>
                deleteHistoricalOutputs(
                    subThermalId,
                    rows.map((row) => row.id)
                ),
            deleteAll: () => deleteAllHistoricalOutputs(subThermalId),
            validate: (rows) => validateHistoricalOutputs(subThermalId, rows),
            getDataSourceView: (pagination) => getHistoricalOutputsDataSourceView(subThermalId, { ...pagination }),
        };
    }, [subThermalId]);

    const columns = useMemo(() => {
        return [
            {
                type: 'date',
                field: 'eventDate',
                headerName: intl.formatMessage({ id: 'thermal_historical_output_event_date' }),
                sort: 'asc',
                cellEditorParams: { required: true },
            },
            {
                field: 'value',
                type: 'number',
                headerName: intl.formatMessage({ id: 'thermal_common_value' }),
                cellEditorParams: { required: true },
            },
            {
                headerName: intl.formatMessage({ id: 'thermal_historical_output_quantity' }),
                minWidth: 180,
                field: 'quantityTypeId',
                type: 'select',
                cellEditorParams: {
                    options: metadata.dropdowns.quantityTypes,
                    required: true,
                },
            },
        ];
    }, [intl, metadata.dropdowns.quantityTypes]);

    return (
        <>
            <Heading as="h3" variant="h3" textTransform="capitalize" mt={2}>
                <FormattedMessage id="thermal_historical_output_heading" />
                <HelpButtonComponent
                    helpAnchor={
                        type === 'generation asset'
                            ? helpAnchors.THERMAL_HISTORICAL_OUTPUT
                            : helpAnchors.DSM_HISTORICAL_OUTPUT
                    }
                    itemTypeId={helpPageUrlItemTypeId}
                />
            </Heading>

            <SubItemData
                tab="12"
                name={additionalGridKeys.HISTORICAL_OUTPUT}
                onLoadingStart={onLoadingStart}
                onLoadingEnd={onLoadingEnd}
                gridResource={gridResource}
                columns={columns}
                addDataColumns={columns}
                primaryKeyName="id"
            />
        </>
    );
};

export default HistoricalOutput;
