import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';

import FormServerErrorMessage from './FormServerErrorMessage';
import FormWarningMessage from './FormWarningMessage';
import InfoPopover from './InfoPopover';

// This component will remain as the only FormField
// The other clone in utils folder will be deleted when the whole field refactor is completed

const FormField = ({ label, children, error, info, status, warning, ...props }) => {
    return (
        <FormControl {...props}>
            {info && <InfoPopover info={info} />}

            {label && <FormLabel>{label}</FormLabel>}

            {children}

            {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
            {warning ? <FormWarningMessage key={warning}>{warning}</FormWarningMessage> : null}
            {status?.errors?.map((err) => (
                <FormServerErrorMessage key={err}>{err}</FormServerErrorMessage>
            ))}
            {status?.warnings?.map((warning) => (
                <FormWarningMessage key={warning}>{warning}</FormWarningMessage>
            ))}
        </FormControl>
    );
};
export default FormField;
