import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SimpleGrid } from '@chakra-ui/react';

import useHistoryPush from '../../hooks/useHistoryPush';

import SecondaryButton from '../utils/SecondaryButton';
import ComboboxField from '../forms/ComboboxField';

import SectionHint from './SectionHint';
import BasisConfigDropdownButtonGroup from './BasisConfigDropdownButtonGroup';

const SpotPriceSection = ({ options, basis, updateBasis, status }) => {
    const intl = useIntl();
    const historyPush = useHistoryPush();

    const [isLoading, setIsLoading] = useState({
        refSpotPriceId: false,
        delSpotPriceId: false,
    });

    const navigateToSpotPrice = useCallback(
        (spotPriceId) => {
            const search = new URLSearchParams();
            search.append('basisId', basis.basisId);

            historyPush({
                pathname: `/spot-price/${spotPriceId}`,
                search,
            });
        },
        [basis.basisId, historyPush]
    );

    const onSpotPriceOptionChange = useCallback(
        async (field, value) => {
            setIsLoading((prev) => ({ ...prev, [field]: true }));

            await updateBasis({ ...basis, [field]: value });

            setIsLoading((prev) => ({ ...prev, [field]: false }));
        },
        [basis, updateBasis]
    );

    return (
        <>
            <SectionHint hintHeader="hint_heading_spot_price" hintText="hint_text_spot_price" />

            <SimpleGrid pb={5} px={{ base: 5, sm: 0 }} columns={{ md: 2 }} spacingX={6}>
                <BasisConfigDropdownButtonGroup>
                    <ComboboxField
                        id="refSpotPriceId"
                        name="refSpotPriceId"
                        options={options.spotPrices}
                        valueKey="id"
                        labelKey="description"
                        value={basis.refSpotPriceId}
                        onChange={(value) => onSpotPriceOptionChange('refSpotPriceId', value)}
                        label={intl.formatMessage({ id: 'basis_reference_spot_price' })}
                        showPlaceholder={true}
                        isInvalid={status.refSpotPriceId?.errors.length}
                        isWarning={status.refSpotPriceId?.warnings.length}
                        status={status.refSpotPriceId}
                    />

                    <SecondaryButton
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={() => navigateToSpotPrice(basis.refSpotPriceId)}
                        w={{ sm: 'auto' }}
                        isDisabled={!basis.refSpotPriceId || isLoading.refSpotPriceId}
                    >
                        <FormattedMessage id="basis_configurator_view_edit" />
                    </SecondaryButton>
                </BasisConfigDropdownButtonGroup>

                <BasisConfigDropdownButtonGroup>
                    <ComboboxField
                        id="delSpotPriceId"
                        name="delSpotPriceId"
                        options={options.spotPrices}
                        valueKey="id"
                        labelKey="description"
                        value={basis.delSpotPriceId}
                        onChange={(value) => onSpotPriceOptionChange('delSpotPriceId', value)}
                        label={intl.formatMessage({ id: 'basis_delivery_spot_price' })}
                        showPlaceholder={true}
                        isInvalid={status.delSpotPriceId?.errors.length}
                        isWarning={status.delSpotPriceId?.warnings.length}
                        status={status.delSpotPriceId}
                    />

                    <SecondaryButton
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={() => navigateToSpotPrice(basis.delSpotPriceId)}
                        w={{ sm: 'auto' }}
                        isDisabled={!basis.delSpotPriceId || isLoading.delSpotPriceId}
                    >
                        <FormattedMessage id="basis_configurator_view_edit" />
                    </SecondaryButton>
                </BasisConfigDropdownButtonGroup>
            </SimpleGrid>
        </>
    );
};

export default SpotPriceSection;
