import { useState, useCallback, useEffect, useRef } from 'react';
import { Box, Button, TabList, Tab, Tabs, TabPanels, TabPanel, SimpleGrid } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';

import useCommonToast from '../../hooks/useCommonToast';

import { getProperties, createExpressJob } from '../../services/expressJobs';

import { getUpdatedProperties } from '../../services/dynamicProperties';

import MainModal from '../modal/MainModal';

import SimpleGridSkeleton from '../utils/SimpleGridSkeleton';
import Error from '../utils/Error';

import ComboboxField from '../forms/ComboboxField';
import DateField from '../forms/DateField';

import ExpressJobsProperties from './ExpressJobsProperties';

const SUBMIT_PRIORITY_DEFAULT_OPTION_ID = 6;

// "Archived", "Completed Successfully", "Failure", "Stopped", "Running"
const NON_TRANSFERABLE_STATUS_IDS = [-4, -1, -2, -3, 0];

const ExpressJobsActionsModal = ({
    selectedJobToImport,
    onClose,
    dropdownOptions,
    actions,
    onCreateJob,
    admin = false,
}) => {
    const [properties, setProperties] = useState({
        data: null,
        loading: false,
        error: false,
    });

    const [selectedAction, setSelectedAction] = useState();

    const [selectedSettings, setSelectedSettings] = useState({
        submitPriority: SUBMIT_PRIORITY_DEFAULT_OPTION_ID,
        scheduledRunDate: new Date(),
        jobType: null,
        associatedStudy: null,
    });

    const [isCreatingExpressJob, setIsCreatingExpressJob] = useState(false);

    const intl = useIntl();
    const { toast } = useCommonToast();
    const formPropertiesRef = useRef();

    const fetchProperties = useCallback(
        (moduleId) => {
            return getProperties(moduleId, null, admin)
                .then((data) => setProperties((prev) => ({ ...prev, data: data.inboundProperties })))
                .catch(() => setProperties((prev) => ({ ...prev, error: true })))
                .finally(() => setProperties((prev) => ({ ...prev, loading: false })));
        },
        [admin]
    );

    useEffect(() => {
        if (selectedJobToImport) {
            const action = actions.data.find((elem) => elem.description === selectedJobToImport.action);

            setSelectedAction(action ? action.id : null);
            setProperties((prev) => ({ ...prev, loading: true }));
            if (action) {
                fetchProperties(action.id);
            }

            setSelectedSettings({
                submitPriority: selectedJobToImport.statusId
                    ? NON_TRANSFERABLE_STATUS_IDS.includes(selectedJobToImport.statusId)
                        ? SUBMIT_PRIORITY_DEFAULT_OPTION_ID
                        : selectedJobToImport.statusId
                    : SUBMIT_PRIORITY_DEFAULT_OPTION_ID,
                scheduledRunDate: new Date(selectedJobToImport.scheduledRunTime),
                jobType: selectedJobToImport.jobTypeId,
                associatedStudy: selectedJobToImport.portfolioJobManagementId,
            });
        }
    }, [selectedJobToImport, actions, fetchProperties, dropdownOptions]);

    useEffect(() => {
        if (!selectedJobToImport) {
            setSelectedSettings((prev) => ({
                ...prev,
                associatedStudy: dropdownOptions.data.studies[dropdownOptions.data.studies.length - 1].id,
            }));
        }
    }, [dropdownOptions, selectedJobToImport]);

    const onActionSelect = (moduleId) => {
        setSelectedAction(moduleId);
        setProperties((prev) => ({ ...prev, loading: true }));
        fetchProperties(moduleId);
    };

    const onExpressJobCreate = async () => {
        const successMessage = intl.formatMessage(
            { id: 'common_creation_success' },
            {
                item: admin
                    ? intl.formatMessage({ id: 'admin_express_job' })
                    : intl.formatMessage({ id: 'express_job' }),
            }
        );
        const updatedProperties = getUpdatedProperties(properties.data, formPropertiesRef.current.values).map(
            (property) => ({
                propertyTypeId: property.id,
                varValue: property.selectedValue,
            })
        );

        setIsCreatingExpressJob(true);

        try {
            await createExpressJob(
                {
                    moduleId: selectedAction,
                    statusId: selectedSettings.submitPriority,
                    scheduledRunTime: selectedSettings.scheduledRunDate,
                    jobTypeId: selectedSettings.jobType,
                    portfolioJobManagementId: selectedSettings.associatedStudy,
                    properties: updatedProperties,
                },
                admin
            );

            onCreateJob();
            toast(successMessage);
            onClose();
        } catch (err) {
            setIsCreatingExpressJob(false);
        }
    };

    const onFormReset = () => {
        formPropertiesRef.current.resetForm();
        setSelectedSettings({
            submitPriority: SUBMIT_PRIORITY_DEFAULT_OPTION_ID,
            scheduledRunDate: new Date(),
            jobType: null,
            associatedStudy: dropdownOptions.data.studies[dropdownOptions.data.studies.length - 1].id,
        });
    };

    return (
        <MainModal
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="admin_express_jobs_actions_modal_heading" />}
            content={
                <>
                    {actions.loading || dropdownOptions.loading ? (
                        <SimpleGridSkeleton rows={3} cols={1} />
                    ) : actions.error || dropdownOptions.error ? (
                        <Error primaryId="common_error" secondaryId="common_loading_error" additionalText="data" />
                    ) : (
                        <Tabs>
                            <TabList>
                                <Tab>
                                    <FormattedMessage id="admin_express_jobs_actions_modal_tab_actions" />
                                </Tab>
                                <Tab isDisabled={!selectedAction}>
                                    <FormattedMessage id="admin_express_jobs_actions_modal_tab_advanced_settings" />
                                </Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <ComboboxField
                                        id="actions"
                                        name="actions"
                                        label={<FormattedMessage id="admin_express_jobs_actions_modal_tab_actions" />}
                                        options={actions.data}
                                        value={selectedAction}
                                        onChange={onActionSelect}
                                        valueKey="id"
                                        labelKey="description"
                                        placeholderValue=""
                                        placeholderLabel={intl.formatMessage({ id: 'common_forms_select_option' })}
                                    />

                                    {selectedAction && (
                                        <ExpressJobsProperties
                                            properties={properties}
                                            formPropertiesRef={formPropertiesRef}
                                        />
                                    )}
                                </TabPanel>

                                <TabPanel>
                                    <SimpleGrid columns={2} spacingX={3} spacingY={3}>
                                        <ComboboxField
                                            id="submitPriority"
                                            name="submitPriority"
                                            label={<FormattedMessage id="admin_express_jobs_submit_priority" />}
                                            options={dropdownOptions.data.priorities}
                                            value={selectedSettings.submitPriority}
                                            onChange={(value) =>
                                                setSelectedSettings((prev) => ({ ...prev, submitPriority: value }))
                                            }
                                            valueKey="id"
                                            labelKey="description"
                                            placeholderValue=""
                                            placeholderLabel={intl.formatMessage({ id: 'common_forms_select_option' })}
                                        />

                                        <DateField
                                            id="scheduledRunDate"
                                            name="scheduledRunDate"
                                            label={<FormattedMessage id="admin_express_jobs_scheduledRunDate" />}
                                            value={selectedSettings.scheduledRunDate}
                                            onChange={(value) =>
                                                setSelectedSettings((prev) => ({ ...prev, scheduledRunDate: value }))
                                            }
                                            showTimeInput
                                            utc={false}
                                        />

                                        <ComboboxField
                                            id="jobType"
                                            name="jobType"
                                            label={<FormattedMessage id="admin_express_jobs_job_type" />}
                                            options={dropdownOptions.data.jobTypes}
                                            value={selectedSettings.jobType}
                                            onChange={(value) =>
                                                setSelectedSettings((prev) => ({ ...prev, jobType: value }))
                                            }
                                            valueKey="id"
                                            labelKey="description"
                                            showPlaceholder={true}
                                            placeholderLabel={intl.formatMessage({
                                                id: 'admin_express_jobs_job_type_placeholder',
                                            })}
                                            placeholderValue={null}
                                        />

                                        <ComboboxField
                                            id="associatedStudyPortfolioSensitivity"
                                            name="associatedStudyPortfolioSensitivity"
                                            label={
                                                <FormattedMessage id="admin_express_jobs_associated_study_portfolio_sensitivity" />
                                            }
                                            options={dropdownOptions.data.studies}
                                            value={selectedSettings.associatedStudy}
                                            onChange={(value) =>
                                                setSelectedSettings((prev) => ({ ...prev, associatedStudy: value }))
                                            }
                                            valueKey="id"
                                            labelKey="description"
                                            placeholderValue=""
                                            placeholderLabel={intl.formatMessage({ id: 'common_forms_select_option' })}
                                        />
                                    </SimpleGrid>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    )}
                </>
            }
            secondaryButton={
                <Button variant="secondary" isDisabled={!selectedAction}>
                    <Box as="span" textTransform="capitalize" onClick={onFormReset}>
                        <FormattedMessage id="common_reset" />
                    </Box>
                </Button>
            }
            footerRightSlot={
                <Button ml={3} isDisabled={!selectedAction} isLoading={isCreatingExpressJob}>
                    <Box as="span" textTransform="capitalize" onClick={onExpressJobCreate}>
                        <FormattedMessage id="common_submit" />
                    </Box>
                </Button>
            }
        />
    );
};

export default ExpressJobsActionsModal;
