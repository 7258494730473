import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getAutoformLevelData } from '../../../store/autoform/autoformApi';
import useAutoformLoadTableDropdowns from './useAutoformLoadTableDropdowns';
import useAutoformParams from './useAutoformParams';

/**
 * Loads the data for a single table based on its tableId and whether there is
 * a record selected in the parent table. Results are filtered by the selected
 * parent record.
 *
 * @param {string} tableId - Id of the table to load data for.
 * @param {string} selectedParentRecordId - Record id for the selected record
 * of the parent table.
 * @returns {boolean} isLoading - Is the data still being loaded.
 * @returns {boolean} loadingError - Is there an error loading the data.
 * @returns {callback} reloadData - Reload the data manually. TODO: Rename property
 */
const useAutoformLoadTableData = (tableId, selectedParentRecordId, config) => {
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const dispatch = useDispatch();
    useAutoformLoadTableDropdowns(tableId, selectedParentRecordId);

    const parameters = useAutoformParams();

    const reloadData = useCallback(() => {
        const pageSize = config?.pageSize ?? undefined;
        const page = 1;
        return dispatch(
            getAutoformLevelData({
                tableId,
                parameters,
                pageSize,
                page,
                selectedParentRecordId,
            })
        );
    }, [dispatch, config?.pageSize, selectedParentRecordId, tableId, parameters]);

    useEffect(() => {
        if (tableId) {
            setIsLoading(true);
            // TODO: Pass in parent selected item id from parent metadata
            const metadataPromise = reloadData();
            metadataPromise
                .then((action) => {
                    if (!action.meta.aborted) {
                        setIsLoading(false);
                    }
                })
                .catch((err) => setLoadingError(true));
            return () => {
                metadataPromise?.abort();
            };
        }
    }, [reloadData, config?.refetchDataCounter, tableId]);

    return { isLoading, loadingError, reloadData: reloadData };
};

export default useAutoformLoadTableData;
