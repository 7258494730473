import ReactDOM from 'react-dom';
import styled from '@emotion/styled/macro';

//This cannot be converted to functional component because of specific ag-grid
class DataGridTooltip {
    constructor() {
        this.el = document.createElement('div');
        this.el.style['position'] = 'absolute';
        this.el.style['z-index'] = 100;
    }

    init(params) {
        this.props = params;

        ReactDOM.render(<StyledTooltip>{this.props.value}</StyledTooltip>, this.el);
    }

    getGui() {
        return this.el;
    }
}

const StyledTooltip = styled.div`
    border-radius: 6px;
    background: var(--chakra-colors-gray-500);
    color: var(--chakra-colors-white);
    padding: 1px 8px;
    width: max-content;
    line-height: 18px;
    font-size: 12px;
    box-shadow: var(--chakra-shadows-md);
    z-index: 100;
    pointer-events: none;
    margin-top: 10px;
`;

export default DataGridTooltip;
