import { Field, FastField } from 'formik';
import { Textarea } from '@chakra-ui/react';
import _ from 'lodash';

import FormField from './FormField';

// If you need to extend Textarea functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for Textarea should be excluded from ...props and manually added
const FormFieldComponent = ({ field, meta, form, placeholder, size, resize, maxH, h, ...props }) => {
    const status = _.get(form.status, field.name);
    const isInvalid = meta.touched && (meta.error || status?.errors?.length > 0);
    const isWarning = meta.touched && status?.warnings?.length > 0;
    return (
        <FormField name={field.name} isInvalid={isInvalid} error={meta.error} status={status} {...props}>
            <Textarea
                {...field}
                onChange={(e) => {
                    form.setStatus(_.omit(form.status, field.name));
                    field.onChange(e);
                }}
                placeholder={placeholder}
                size={size}
                resize={resize}
                maxH={maxH}
                h={h}
                borderColor={isWarning ? 'orange.300' : undefined}
                _hover={isWarning ? { borderColor: 'orange.300' } : undefined}
                // zDefaultValue could be returned which means empty value
                value={field?.value === 'zDefaultValue' || !field?.value ? '' : field.value}
            />
        </FormField>
    );
};

const TextareaFormikField = ({ name, validate, isFastField = false, ...props }) => {
    return (
        <>
            {isFastField ? (
                <FastField name={name} validate={validate}>
                    {({ field, meta, form }) => <FormFieldComponent field={field} meta={meta} form={form} {...props} />}
                </FastField>
            ) : (
                <Field name={name} validate={validate}>
                    {({ field, meta, form }) => <FormFieldComponent field={field} meta={meta} form={form} {...props} />}
                </Field>
            )}
        </>
    );
};

export default TextareaFormikField;
