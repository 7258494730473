import React, { useEffect, useCallback, useContext, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { Divider, Box } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getItemFormKey, getTableFormKey } from '../../components/autoform/utils/autoformUtils';

import { setSelectedItemForTable, updateBook } from '../../store/autoform/autoformSlice';
import { getInputFormName } from '../autoform/utils/autoformUtils';
import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';

import useAutoformMetadata from '../autoform/hooks/useAutoformMetadata';
import useAutoformTableData from '../autoform/hooks/useAutoformTableData';
import useAutoformReloadItemData from '../autoform/hooks/useAutoformReloadItemData';
import useAutoformTableMetadata from '../autoform/hooks/useAutoformTableMetadata';
import GeneralValidationPanelContainer from '../autoform/GeneralValidationPanelContainer';
import CheckboxesGrid from './CheckboxesGrid';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import { addUsersToBook } from '../../services/userManagement';
import useHistoryPush from '../../hooks/useHistoryPush';

import AutoformItemFields from '../autoform/AutoformItemFields';
import useBookUsers from '../../hooks/userManagement/useBookUsers';
import useBookUsersInitialValues from '../../hooks/userManagement/useBookUsersInitialValues';
import ItemTopHeaderContainer from '../autoform/ItemTopHeaderContainer';
import useCommonToast from '../../hooks/useCommonToast';
import { ReactComponent as BookIcon } from '../../icons/book-closed-icon.svg';
import AutoformUiContext from '../autoform/utils/AutoformUiContext';
import { onHasUnsavedChanges } from 'store/helpers/helpersSlice';

import Error from '../utils/Error';
import PromptWrapper from 'components/utils/PromptWrapper';
import AutomaticFormikDirtyCheck from 'components/forms/AutomaticFormikDirtyCheck';

const Books = () => {
    const intl = useIntl();
    const { toast } = useCommonToast();

    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const level1TableId = hierarchicalTables.level1[0]?.id;
    const dispatch = useDispatch();
    const historyPush = useHistoryPush();
    const [isFormDirty, setIsFormDirty] = useState(false);

    let {
        params: { itemId: routeId },
    } = useRouteMatch();

    const selectedItemId = parseInt(routeId);
    const { selectedItem: selectedItemData } = useAutoformTableData(level1TableId);
    const { descriptionColumnName, selectedItem, primaryKeyColumnName } = useAutoformTableMetadata(level1TableId);
    const { setEditingState } = useContext(AutoformUiContext);

    const inputName = getInputFormName(level1TableId, selectedItemId, descriptionColumnName);
    let tableFormKey = getTableFormKey(level1TableId);
    let itemFormKey = getItemFormKey(selectedItem);
    const { isLoading: isReloadItemLoading, reloadItemLoadingError } = useAutoformReloadItemData(
        level1TableId,
        selectedItemId
    );
    const { bookUsers, isLoading: isBookUsersLoading, isError: isBookUsersError } = useBookUsers(selectedItem);

    useEffect(() => {
        if (selectedItemId && level1TableId) {
            dispatch(setSelectedItemForTable({ itemId: selectedItemId, tableId: level1TableId }));
            setEditingState({});
        }
    }, [dispatch, metadata, selectedItemId, level1TableId, setEditingState]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const onNodeItemDelete = useCallback(() => {
        dispatch(setSelectedItemForTable({ itemId: null, tableId: level1TableId }));
        dispatch(onHasUnsavedChanges(false));

        historyPush({
            pathname: '/user-management/books',
            search: new URLSearchParams(window.location.search),
        });
    }, [historyPush, dispatch, level1TableId]);

    const { initialValues: initialFormValues, setInitialValues } = useBookUsersInitialValues(
        level1TableId,
        selectedItem,
        bookUsers,
        selectedItemData,
        descriptionColumnName
    );

    const onFormSubmit = async (values) => {
        const successMessage = intl.formatMessage({ id: 'user_management_book_change_success' });
        const selectedUserBooks = Object.entries(values.userBooks)
            .filter(([key, value]) => !!value)
            .map(([key, value]) => ({ value: key, text: key, isChecked: value }));
        const updatedValues = [];

        const nameValue =
            values[tableFormKey][itemFormKey][descriptionColumnName] === ''
                ? null
                : values[tableFormKey][itemFormKey][descriptionColumnName];

        const descriptionValue =
            values[tableFormKey][itemFormKey]['DESCRIPTION'] === ''
                ? null
                : values[tableFormKey][itemFormKey]['DESCRIPTION'];

        updatedValues.push(
            addUsersToBook(selectedItem, {
                name: nameValue,
                description: descriptionValue,
                defaultFlag: !!values[tableFormKey][itemFormKey]['DEFAULTFLAG'],
                users: selectedUserBooks,
            })
        );
        await Promise.all(updatedValues);
        setEditingState({});

        dispatch(
            updateBook({
                tableId: level1TableId,
                itemId: selectedItemId,
                primaryKeyColumnName,
                descriptionColumnName,
                item: {
                    [descriptionColumnName]: values[tableFormKey][itemFormKey][descriptionColumnName],
                    DESCRIPTION: values[tableFormKey][itemFormKey]['DESCRIPTION'],
                    DEFAULTFLAG: values[tableFormKey][itemFormKey]['DEFAULTFLAG'] ? 1 : 0,
                },
            })
        );
        setInitialValues(values);
        toast(successMessage);
    };

    if (isBookUsersError || reloadItemLoadingError)
        return (
            <Box m={6}>
                <Error primaryId="common_error" secondaryId="common_loading_error" />
            </Box>
        );
    if (!metadata || selectedItem !== selectedItemId || isBookUsersLoading || isReloadItemLoading)
        return <DefinitionsSkeleton />;

    return (
        <>
            <ExpandableItemWrapper>
                <Formik enableReinitialize initialValues={initialFormValues} onSubmit={onFormSubmit}>
                    {() => (
                        <Form>
                            <PromptWrapper />
                            <AutomaticFormikDirtyCheck isFormDirty={isFormDirty} onFormDirtyChange={setIsFormDirty} />
                            <ItemTopHeaderContainer
                                inputName={`${inputName}`}
                                tableId={level1TableId}
                                onNodeItemDelete={onNodeItemDelete}
                                config={{
                                    deleteModalHeaderItem: intl.formatMessage({ id: 'user_management_book' }),
                                    deleteSuccessMessage: intl.formatMessage({
                                        id: 'user_management_book_delete_success',
                                    }),
                                    itemDeleteButtonMessageId: 'user_management_delete_book',
                                    autoformIcon: <BookIcon />,
                                }}
                            />
                            <Box px="6">
                                <GeneralValidationPanelContainer tableId={level1TableId} />
                                <AutoformItemFields tableId={level1TableId} getInputFormName={getInputFormName} />
                            </Box>
                            <Divider />
                            <CheckboxesGrid
                                data={bookUsers}
                                breakWord={true}
                                fieldNamePrefix="userBooks"
                                header={<FormattedMessage id="user_management_users" />}
                            />
                        </Form>
                    )}
                </Formik>
            </ExpandableItemWrapper>
        </>
    );
};

export default Books;
