import { configureStore } from '@reduxjs/toolkit';

import connectionReducer from './connection/connectionSlice';
import itemReducer from './item/itemSlice';
import helpersReducer from './helpers/helpersSlice';
import forecastReducer from './forecast/forecastSlice';
import adjustmentReducer from './adjustment/adjustmentSlice';
import autoformReducer from './autoform/autoformSlice';
import studiesReducer from './studies/studiesSlice';
import jobsReducer from './jobs/jobsSlice';
import navigationReducer from './navigation/navigationSlice';
import permissionsReducer from './permissions/permissionsSlice';
import powerBiReducer from './powerBi/powerBiSlice';
import featureFlagsReducer from './featureFlags/featureFlagsSlice';

export const store = configureStore({
    reducer: {
        connections: connectionReducer,
        item: itemReducer,
        helpers: helpersReducer,
        forecast: forecastReducer,
        adjustment: adjustmentReducer,
        autoform: autoformReducer,
        studies: studiesReducer,
        jobs: jobsReducer,
        navigation: navigationReducer,
        permissions: permissionsReducer,
        powerBi: powerBiReducer,
        featureFlags: featureFlagsReducer,
    },
});
