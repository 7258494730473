import { Text, Button, SkeletonText } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@chakra-ui/react';
import Error from 'components/utils/Error';
import useGroupPermissionDetails from '../../hooks/userManagement/useGroupPermissionDetails';
import SimpleModal from '../modal/SimpleModal';

const GroupPermissionDetailsModal = ({ onClose, permissionId }) => {
    const { groupPermissionDetails, isLoading, isError } = useGroupPermissionDetails(permissionId);

    if (isError)
        return (
            <Box m={6}>
                <Error primaryId="common_error" secondaryId="common_loading_error" />
            </Box>
        );

    return (
        <SimpleModal
            title={
                isLoading ? (
                    <FormattedMessage id="common_loading" />
                ) : (
                    <FormattedMessage id="user_management_details_for" values={{ name: groupPermissionDetails.name }} />
                )
            }
            size={'2xl'}
            isOpen
            onClose={onClose}
            withFooterDivider={true}
            footerContent={
                <Button mt={2} mb={2} type="button" align="end" onClick={onClose} textTransform="capitalize">
                    <FormattedMessage id="common_continue" />
                </Button>
            }
        >
            <Box mt={2}>
                {isLoading ? (
                    <Box>
                        <SkeletonText noOfLines={3} spacing="3" padding={3} />
                    </Box>
                ) : (
                    <>
                        <Text size="md" variant="regular" color="gray.900">
                            {groupPermissionDetails?.description ? (
                                groupPermissionDetails.description
                            ) : (
                                <FormattedMessage id="user_management_missing_role_details" />
                            )}
                        </Text>
                    </>
                )}
            </Box>
        </SimpleModal>
    );
};

export default GroupPermissionDetailsModal;
