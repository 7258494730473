import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import axios from 'axios';

import { AuthenticatedTemplate } from '@azure/msal-react';

import DesktopNavigation from './desktopNavigation/DesktopNavigation';
import MobileNavigation from './mobileNavigation/MobileNavigation';

import { getNavigationConfig } from '../../store/navigation/navigationApi';
import { setLoading } from '../../store/navigation/navigationSlice';

import LoadingModal from '../modal/LoadingModal';

const NavigationBar = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { isLoading } = useSelector((state) => state.navigation);
    const connectionConfig = useSelector((state) => state.connections);

    useEffect(() => {
        const { connectionId, environmentId, bookId } = axios.defaults.headers;
        if (bookId && environmentId && connectionId) {
            dispatch(setLoading(true));
            dispatch(getNavigationConfig()).finally(() => dispatch(setLoading(false)));
        }
    }, [dispatch, connectionConfig]);

    return (
        <AuthenticatedTemplate>
            {isLoading ? (
                <LoadingModal header={intl.formatMessage({ id: 'common_loading' })} isOpen />
            ) : (
                <React.Fragment>
                    <DesktopNavigation />
                    <MobileNavigation />
                </React.Fragment>
            )}
        </AuthenticatedTemplate>
    );
};

export default NavigationBar;
