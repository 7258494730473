import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, Flex, Box, IconButton, useDisclosure, Button, Spacer, Divider } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    getAncillaries,
    getBiddingStrategyDefinitions,
    updateBiddingStrategy,
    deleteBiddingStrategy,
    getBiddingStrategy,
    createBid,
    deleteBid,
} from '../../services/biddingStrategies';
import { checkItemInputEvent, exportItemData } from '../../services/items';
import { isSubmitDisabled } from '../../services/utils';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import ShowHideSidebarButton from '../layout/ShowHideSidebarButton';

import InputFormikField from '../forms/InputFormikField';
import AutomaticFormikDirtyCheck from '../forms/AutomaticFormikDirtyCheck';

import ConfirmationModal from '../modal/ConfirmationModal';

import UploadButton from '../itemData/UploadButton';

import Error from '../utils/Error';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import Tooltip from '../utils/Tooltip';
import ActionBar from '../utils/ActionBar';
import CustomItemIcon from '../utils/CustomItemIcon';
import PromptWrapper from '../utils/PromptWrapper';
import SecondaryButton from '../utils/SecondaryButton';
import DownloadModal from '../utils/DownloadModal';
import SortOrderProvider from '../grid/utils/SortOrderContext';

import BatteryBiddingStrategy from '../batteries/BatteryBiddingStrategy';

import { onHasUnsavedChanges } from '../../store/helpers/helpersSlice';

import { ReactComponent as BatteryIcon } from '../../icons/item-icons/battery-secondary.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as DeleteLoadIcon } from '../../icons/delete.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';

const BiddingStrategy = ({ onEdit, onDelete }) => {
    const intl = useIntl();
    const { strategyId } = useParams();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();
    const dispatch = useDispatch();

    const deleteModal = useDisclosure();
    const downloadModal = useDisclosure();

    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);

    const [key, setKey] = useState(0);
    const [selected, setSelected] = useState({ id: strategyId, description: '' });
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);
    const [editDescriptionConfig, setEditDescriptionConfig] = useState(null);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [ancillaries, setAncillaries] = useState([]);

    const initialFormValues = {
        description: selected.description,
    };

    const refreshGrid = useCallback(() => setKey((prev) => prev + 1), []);

    useEffect(() => {
        setIsLoading(true);

        Promise.all([getBiddingStrategyDefinitions(strategyId), getAncillaries()])
            .then(([definition, ancillaries]) => {
                setSelected(definition);
                setAncillaries(ancillaries);
                setEditDescriptionConfig({
                    [definition.id]: { isActive: false, penDisabled: false },
                });

                // This is needed to show results after previous error
                setLoadingError(false);
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [strategyId]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const validateDescription = (value) => {
        const trimmed = value.trim();
        let errorMessage = '';

        if (trimmed.length === 0) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: 'Bidding strategy name' }
            );
        } else if (trimmed.length > 100) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Bidding strategy name', lengthRule: '100 characters or less' }
            );
        }

        setEditDescriptionConfig((prevState) => {
            return { ...prevState, [selected.id]: { ...prevState[selected.id], penDisabled: errorMessage !== '' } };
        });

        return errorMessage;
    };

    const onSubmit = async (values, formikBag) => {
        const successMessage = intl.formatMessage({ id: 'bidding_strategy_save_changes_success' });

        await updateBiddingStrategy(selected.id, { description: values.description });

        setEditDescriptionConfig({
            [selected.id]: { isActive: false, penDisabled: false },
        });

        onEdit({
            id: selected.id,
            description: values.description,
        });

        // on other screen we re-load definitions which automatically resets the form and formik dirty computed property
        // but here we have only one field and a complete refresh is not necessary
        formikBag.resetForm({ values });

        toast(successMessage);
    };

    const onStrategyDelete = async () => {
        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'bidding strategy' });

        setIsDeleting(true);

        await deleteBiddingStrategy(selected.id);

        setEditDescriptionConfig({
            [selected.id]: { isActive: false, penDisabled: false },
        });

        dispatch(onHasUnsavedChanges(false));

        setIsDeleting(false);
        deleteModal.onClose();

        onDelete(selected);
        historyPush('/bidding-strategies');

        toast(successMessage);
    };

    const onEditDescription = () => {
        if (!editDescriptionConfig[selected.id].penDisabled) {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [selected.id]: { isActive: !prevState[selected.id].isActive, penDisabled: false },
                };
            });
        }
    };

    const gridResource = useMemo(() => {
        return {
            read: () => getBiddingStrategy(selected.id),
            createBid: (payload) => createBid(selected.id, payload),
            deleteBid: (participationId) => deleteBid(selected.id, participationId),
        };
    }, [selected.id]);

    const onDownloadData = useCallback(
        (exportTemplate) => exportItemData(`/bidding-strategies/${selected.id}/export`, { exportTemplate }),
        [selected.id]
    );

    return (
        <>
            <PromptWrapper
                when={hasUnsavedChanges}
                header={<FormattedMessage id="common_discard_changes" />}
                content={<FormattedMessage id="common_confirmation_explanation" />}
            />

            <ExpandableItemWrapper>
                {isLoading ? (
                    <DefinitionsSkeleton />
                ) : loadingError ? (
                    <Box m={6}>
                        <Error
                            primaryId="common_error"
                            secondaryId="common_loading_error"
                            additionalText="definitions"
                        />
                    </Box>
                ) : (
                    <>
                        <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
                            {({ isSubmitting, errors, touched, values }) => (
                                <Form>
                                    <AutomaticFormikDirtyCheck
                                        isFormDirty={isFormDirty}
                                        onFormDirtyChange={setIsFormDirty}
                                    />

                                    <Flex pt={3} pl={6} align="center">
                                        <CustomItemIcon icon={BatteryIcon} />

                                        {!editDescriptionConfig[selected.id]?.isActive ? (
                                            <Heading px={2} as="h2" variant="h2">
                                                {values.description}
                                            </Heading>
                                        ) : (
                                            <InputFormikField
                                                px={3}
                                                flex="1"
                                                maxW="500px"
                                                validate={validateDescription}
                                                name="description"
                                                type="text"
                                                onKeyDown={checkItemInputEvent}
                                                isFastField
                                            />
                                        )}

                                        <Box display="inline-block" mr={4}>
                                            <Tooltip
                                                label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}
                                            >
                                                <IconButton
                                                    aria-label={intl.formatMessage({
                                                        id: 'common_edit_btn_tooltip_and_label',
                                                    })}
                                                    variant="circular-icon"
                                                    leftIcon={<EditIcon />}
                                                    onClick={onEditDescription}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Flex>

                                    <ActionBar>
                                        <ShowHideSidebarButton />

                                        <Spacer display={{ base: 'none', xl: 'block' }} />

                                        <Button
                                            leftIcon={<DeleteLoadIcon />}
                                            type="button"
                                            variant="special"
                                            onClick={deleteModal.onOpen}
                                            size="sm"
                                            textTransform="capitalize"
                                        >
                                            <FormattedMessage id="common_delete" />
                                        </Button>

                                        <Button
                                            leftIcon={<SaveIcon />}
                                            isDisabled={isSubmitDisabled({ errors, touched })}
                                            isLoading={isSubmitting}
                                            type="submit"
                                            variant="primary-success"
                                            size="sm"
                                        >
                                            <FormattedMessage id="common_save_changes_button" />
                                        </Button>
                                    </ActionBar>

                                    <Divider />
                                </Form>
                            )}
                        </Formik>

                        <Box px={6} key={key}>
                            <SortOrderProvider>
                                <BatteryBiddingStrategy
                                    actionBarSlot={
                                        <Box mt="22px">
                                            <SecondaryButton
                                                leftIcon={<DownloadIcon />}
                                                w="auto !important"
                                                mr={2}
                                                size="sm"
                                                type="button"
                                                variant="secondary"
                                                onClick={downloadModal.onOpen}
                                            >
                                                <FormattedMessage id="common_download_btn_tooltip_and_label" />
                                            </SecondaryButton>

                                            <UploadButton
                                                type="bidding-strategy"
                                                itemId={selected.id}
                                                hasLabel={true}
                                                onAfterUploadChange={refreshGrid}
                                                w="auto !important"
                                                hideTreatWarningsAsErrors
                                            />

                                            {downloadModal.isOpen && (
                                                <DownloadModal
                                                    isOpen
                                                    onClose={downloadModal.onClose}
                                                    onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                                                    onDownloadTemplate={() => onDownloadData(true)}
                                                />
                                            )}
                                        </Box>
                                    }
                                    gridResource={gridResource}
                                    ancillaries={ancillaries}
                                    templateConfig={{
                                        templateId: null,
                                        hasUpload: false,
                                    }}
                                />
                            </SortOrderProvider>
                        </Box>

                        {deleteModal.isOpen && (
                            <ConfirmationModal
                                isOpen
                                isLoading={isDeleting}
                                onClose={deleteModal.onClose}
                                header={
                                    <FormattedMessage
                                        id="common_delete_modal_heading"
                                        values={{ item: 'Bidding Strategy' }}
                                    />
                                }
                                hasExtraStep
                                content={
                                    <FormattedMessage
                                        id="common_delete_modal_msg"
                                        values={{ item: selected.description }}
                                    />
                                }
                                confirmText={
                                    <Box as="span" textTransform="capitalize">
                                        <FormattedMessage id="common_delete" />
                                    </Box>
                                }
                                onConfirm={onStrategyDelete}
                            />
                        )}
                    </>
                )}
            </ExpandableItemWrapper>
        </>
    );
};

export default BiddingStrategy;
