import Datepicker from './Datepicker';
import FormField from './FormField';

// If you need to extend Datepicker functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for Datepicker should be excluded from ...props and manually added
const DateField = ({
    isDisabled,
    value,
    onChange,
    onCalendarClose,
    maxDate,
    minDate,
    utc,
    dateFormat,
    showTimeInput,
    placeholderText,
    isClearable,
    isWarning,
    showMonthYearPicker,
    autoComplete,
    isInPortal,
    ...props
}) => (
    <FormField isDisabled={isDisabled} {...props}>
        <Datepicker
            showTimeInput={showTimeInput}
            disabled={isDisabled}
            value={value}
            onChange={onChange}
            onCalendarClose={onCalendarClose}
            dateFormat={dateFormat}
            placeholderText={placeholderText}
            isClearable={isClearable}
            maxDate={maxDate}
            minDate={minDate}
            isWarning={isWarning}
            utc={utc}
            showMonthYearPicker={showMonthYearPicker}
            autoComplete={autoComplete}
            portalId={isInPortal && 'datepicker-portal'}
        />
    </FormField>
);

export default DateField;
