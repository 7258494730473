import { useCallback, useMemo, useState } from 'react';
import {
    Heading,
    Box,
    Flex,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useMediaQuery,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import AutoformUploadButton from './AutoformUploadButton';
import AutoformDownloadButton from './AutoformDownloadButton';
import useAutoformMetadata from './hooks/useAutoformMetadata';
import useAutoformSubmit from './hooks/useAutoformSubmit';
import AutoformTabs from './AutoformTabs';
import AutoformTableContainer from './AutoformTableContainer';
import useAutoformBulkUpdate from './hooks/useAutoformBulkUpdate';
import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import useAutoformFormValidation from './hooks/useAutoformFormValidation';
import useCommonToast from 'hooks/useCommonToast';

/**
 * Modal to show level 4 tables.
 *
 * @param {object[]} parentTableId - Parent of the tables displayed here.
 * @param {string|number} header
 * @param {callback} onClose
 *
 */
const EditChildTablesModal = ({ parentTableId, header, onClose, config }) => {
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const { toast } = useCommonToast();
    const intl = useIntl();

    const displayedTables = useMemo(
        () => hierarchicalTables.level4.filter((t) => t.parentId === parentTableId),
        [parentTableId, hierarchicalTables]
    );

    const { selectedItem } = useAutoformTableMetadata(parentTableId);
    const [selectedTable, setSelectedTable] = useState(displayedTables[0]);
    const [isSavingBulkUpdate, setIsSavingBulkUpdate] = useState(false);
    const { submitBulkUpdate } = useAutoformSubmit({});
    const tablesState = useSelector((state) => state.autoform.tables);

    const { validationSettings, handleDependentDataChange } = useAutoformBulkUpdate({
        tableId: selectedTable?.id ?? displayedTables[0]?.id,
        parentRecordId: selectedItem,
        autoformConfig: config,
    });

    const { onValidate } = useAutoformFormValidation({ validationSettings });

    const isBulkUpdateEnabled = useMemo(() => {
        for (const displayedTable of displayedTables) {
            const isBulkUpdateEnabledForTable =
                Object.keys(tablesState[displayedTable.id]?.bulkUpdateInitialValues ?? {}).length > 0;
            if (isBulkUpdateEnabledForTable && displayedTable.canUpdate) {
                return true;
            }
        }
        return false;
    }, [tablesState, displayedTables]);

    const tableConfig = useMemo(
        () => ({
            dataFilenameSuffix: header?.toString(),
        }),
        [header]
    );

    const onSubmit = useCallback(
        async (values, formBag) => {
            setIsSavingBulkUpdate(true);
            const isSuccessful = await submitBulkUpdate(values, formBag);
            if (isSuccessful) {
                toast(intl.formatMessage({ id: 'autoform_save_changes_success' }));
            }
            setIsSavingBulkUpdate(false);
        },
        [intl, toast, submitBulkUpdate]
    );

    const initialValues = useMemo(() => ({ bulkUpdate: {} }), []);

    return (
        <Modal size={isDesktop ? '4xl' : 'full'} isOpen>
            <Formik onSubmit={onSubmit} validate={onValidate} initialValues={initialValues}>
                {({ dirty, setValues, setTouched, status, setStatus }) => (
                    <Form>
                        <ModalOverlay />
                        <ModalContent pt={3} borderRadius={isDesktop ? '2xl' : 0}>
                            <ModalHeader pr={12}>
                                <Heading as="h3" variant="h3">
                                    {header}
                                </Heading>
                            </ModalHeader>

                            <ModalCloseButton mt={4} mr={3} h={6} w={6} onClick={onClose} />

                            <ModalBody>
                                <AutoformTabs
                                    renderTabPanel={(subTable) => (
                                        <AutoformTableContainer
                                            schemaCode={metadata.schemaCode}
                                            tableId={subTable.id}
                                            onActionInitiated={() =>
                                                handleDependentDataChange({
                                                    setValues,
                                                    setTouched,
                                                    status,
                                                    setStatus,
                                                })
                                            }
                                            showDownloadUploadButton={displayedTables.length === 1}
                                            config={tableConfig}
                                        />
                                    )}
                                    mt={6}
                                    tables={displayedTables}
                                    onTabOpen={(index) => setSelectedTable(displayedTables[index])}
                                    tabListButtons={
                                        <Flex ml="auto" order={{ xl: 0 }} w={{ base: '100%', xl: 'auto' }}>
                                            <AutoformDownloadButton
                                                mr={2}
                                                tablesMetadata={displayedTables}
                                                parentTableId={parentTableId}
                                                config={tableConfig}
                                            />
                                            {!!displayedTables.find((table) => table.canCreate) && (
                                                <AutoformUploadButton
                                                    tablesMetadata={displayedTables}
                                                    parentTableId={parentTableId}
                                                />
                                            )}
                                        </Flex>
                                    }
                                ></AutoformTabs>
                            </ModalBody>

                            {isBulkUpdateEnabled ? (
                                <ModalFooter justifyContent="flex-end" flexWrap="wrap">
                                    <Box ml="auto">
                                        <Button variant="secondary" onClick={onClose}>
                                            <Box as="span" textTransform="capitalize">
                                                <FormattedMessage id="common_cancel" />
                                            </Box>
                                        </Button>

                                        <Button type="submit" ml={4} isDisabled={!dirty} isLoading={isSavingBulkUpdate}>
                                            <FormattedMessage id="common_save_changes_button" />
                                        </Button>
                                    </Box>
                                </ModalFooter>
                            ) : null}
                        </ModalContent>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default EditChildTablesModal;
