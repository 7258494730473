import CustomIconButton from 'components/utils/CustomIconButton';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';

const DetailsButton = ({ onDetailsButtonClick }) => {
    return (
        <CustomIconButton
            float="none"
            mt="0px !important"
            ml="10px !important"
            icon={<InfoIcon />}
            onClick={onDetailsButtonClick}
        />
    );
};

export default DetailsButton;
