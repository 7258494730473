import { HStack, IconButton, useMediaQuery } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useIntl } from 'react-intl';

import ContextMenu from '../utils/ContextMenu';
import Tooltip from '../utils/Tooltip';

import { ReactComponent as RemoveIcon } from '../../icons/mdi-minus-circle.svg';

const PortfolioItemHoverLabelIcons = ({ selected, onRemove }) => {
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const intl = useIntl();

    const items = [
        {
            id: 1,
            label: intl.formatMessage({ id: 'common_remove' }),
            action: () => onRemove(selected),
        },
    ];

    return isDesktop ? (
        <Wrapper className="icons-wrapper">
            <Tooltip label={intl.formatMessage({ id: 'common_remove' })}>
                <CustomIcon
                    icon={<RemoveIcon />}
                    aria-label={intl.formatMessage({ id: 'common_remove' })}
                    onClick={() => onRemove(selected)}
                    variant="circular-icon"
                />
            </Tooltip>
        </Wrapper>
    ) : (
        <ContextMenu aria-label={intl.formatMessage({ id: 'common_actions' })} items={items} />
    );
};

const Wrapper = styled(HStack)`
    display: none;
    margin-left: auto;
`;

const CustomIcon = styled(IconButton)`
    height: 24px;
    width: 24px;
    background: none;
    min-height: 0;
    min-width: 0;
    svg path {
        fill: var(--chakra-colors-gray-600);
    }

    &:focus,
    &:hover {
        background: none;
        box-shadow: none;

        svg path {
            fill: var(--chakra-colors-text-error);
        }
    }
`;

export default PortfolioItemHoverLabelIcons;
