import { useCallback, useMemo, useEffect } from 'react';
import { useRouteMatch, Route, Switch, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import LayoutWrapper from '../components/layout/LayoutWrapper';
import useAutoformMetadata from '../components/autoform/hooks/useAutoformMetadata';
import SidebarItemListContainer from '../components/autoform/SidebarItemListContainer';
import LoadingModal from '../components/modal/LoadingModal';
import { Title } from 'react-head';

import autoformPlaceholder from '../images/autoform-unselected.png';
import WrapperEmptyState from '../components/layout/WrapperEmptyState';
import useAutoformLoadMetadata from '../components/autoform/hooks/useAutoformLoadMetadata';
import useHistoryPush from '../hooks/useHistoryPush';
import AutoformContainer from 'components/autoform/AutoformContainer';
import Autoform from 'components/autoform/Autoform';
import { APP_NAME as appName } from 'constants/autoforms';
import _ from 'lodash';
import { onHasUnsavedChanges, setSearchTerm } from 'store/helpers/helpersSlice';

const defaultConfig = {
    addSubItemMessageId: 'autoform_add_new_subitem',
};

const AutoformPage = ({ hasSecondaryMenu, config: customConfig = {} }) => {
    const config = useMemo(() => _.merge({}, defaultConfig, customConfig), [customConfig]);

    const intl = useIntl();
    const isSidebarVisible = useSelector((state) => state.helpers.isSidebarVisible);
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const dispatch = useDispatch();
    let {
        path,
        params: { autoformId },
    } = useRouteMatch();
    const historyPush = useHistoryPush();
    const schemaCode = config.autoformId ?? autoformId;

    const { isLoading, loadingError } = useAutoformLoadMetadata(schemaCode);

    const level1TableId = hierarchicalTables.level1[0]?.id;
    const showAsGridHint = hierarchicalTables.level1[0]?.showAsGridHint;

    const onItemDelete = useCallback(() => {
        dispatch(onHasUnsavedChanges(false));
        historyPush({
            pathname: '.',
            search: new URLSearchParams(window.location.search),
        });
    }, [historyPush, dispatch]);

    useEffect(
        () => () => {
            dispatch(setSearchTerm(''));
        },
        [dispatch, schemaCode]
    );
    if (loadingError) {
        return <Redirect to={{ pathname: '/', search: window.location.search }} />;
    } else if (!metadata || isLoading) {
        return <LoadingModal header={intl.formatMessage({ id: 'autoform_loading_modal_message' })} isOpen />;
    }

    return (
        <>
            <Title>{config?.pageTitle ?? `${metadata.formLabel} - ${appName}`}</Title>

            <LayoutWrapper
                hasSecondaryMenu={hasSecondaryMenu}
                sidebar={
                    isSidebarVisible &&
                    (!showAsGridHint || metadata.maxTableHierarchyLevel > 1) && (
                        <SidebarItemListContainer tableId={level1TableId} config={config} />
                    )
                }
                content={
                    !showAsGridHint || metadata.maxTableHierarchyLevel > 1 ? (
                        <Switch>
                            <Route exact path={path}>
                                <WrapperEmptyState
                                    imgSrc={autoformPlaceholder}
                                    item="record"
                                    page="left"
                                    noun="properties"
                                />
                            </Route>

                            <Route path={`${path}/:itemId`}>
                                <AutoformContainer onNodeItemDelete={onItemDelete} config={config} />
                            </Route>
                        </Switch>
                    ) : (
                        // Table grid display
                        <Autoform onNodeItemDelete={onItemDelete} config={config} />
                    )
                }
            />
        </>
    );
};

export default AutoformPage;
