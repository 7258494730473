import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Heading, Flex, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import useCommonToast from '../hooks/useCommonToast';

import { formatMultisort, exportItemData } from '../services/items';
import {
    GRID_KEY,
    getVariables,
    addVariableRows,
    deleteVariableRows,
    getValidVariableRows,
    modifyVariables,
    getDropdownOptions,
    getVariableSources,
    multisortPairs,
} from '../services/variables';

import DataGridWrapper from '../components/grid/DataGridWrapper';
import DataGrid from '../components/grid/DataGrid';
import AddDataModal from '../components/grid/AddDataModal';
import SortOrderProvider from '../components/grid/utils/SortOrderContext';

import CheckboxField from '../components/forms/CheckboxField';
import ComboboxField from 'components/forms/ComboboxField';

import UploadButton from '../components/itemData/UploadButton';

import LoadingModal from '../components/modal/LoadingModal';

import EquationsModal from '../components/utils/EquationsModal';
import MainWrapper from '../components/utils/MainWrapper';
import SecondaryButton from '../components/utils/SecondaryButton';
import DownloadModal from '../components/utils/DownloadModal';
import Error from '../components/utils/Error';

import { ReactComponent as EditIcon } from '../icons/edit-circled.svg';
import { ReactComponent as DownloadIcon } from '../icons/download.svg';
import { ReactComponent as AddIcon } from '../icons/add-item.svg';

const Variables = () => {
    const intl = useIntl();

    const equationModal = useDisclosure();
    const downloadModal = useDisclosure();
    const addDataModal = useDisclosure();

    const gridApi = useRef(null);
    const { toast } = useCommonToast();
    const selectedNodeRef = useRef(null);

    const [filters, setFilters] = useState({
        onlyUserVariables: false,
        showInactiveVariables: false,
        source: '',
    });

    const [sources, setSources] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [dropdownOptions, setDropdownOptions] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const fetchVariableSources = useCallback(() => {
        return getVariableSources()
            .then((data) => setSources((prev) => ({ ...prev, error: false, data })))
            .catch(() => setSources((prev) => ({ ...prev, error: true })))
            .finally(() => setSources((prev) => ({ ...prev, loading: false })));
    }, []);

    const fetchDropdownOptions = useCallback(() => {
        return getDropdownOptions()
            .then((data) => setDropdownOptions((prev) => ({ ...prev, error: false, data })))
            .catch(() => setDropdownOptions((prev) => ({ ...prev, error: true })))
            .finally(() => setDropdownOptions((prev) => ({ ...prev, loading: false })));
    }, []);

    useEffect(() => {
        fetchDropdownOptions();
        fetchVariableSources();
    }, [fetchDropdownOptions, fetchVariableSources]);

    const getServerSideData = useCallback(
        async (params) => {
            const page = params.request.startRow / 500 + 1;
            const sorting = formatMultisort(params.request.sortModel, multisortPairs);

            const { onlyUserVariables, source, showInactiveVariables } = filters;

            const payload = {
                paging: {
                    page,
                    pageSize: 500,
                },
                sorting,
                source,
                onlyUserVariables,
                showInactiveVariables,
            };

            const data = await getVariables(payload);

            if (!data.totalCount) {
                params.api.showNoRowsOverlay();
            } else {
                params.api.hideOverlay();
            }

            params.success({
                rowData: data.items,
                rowCount: data.totalCount,
            });
        },
        [filters]
    );

    const openEquationModal = useCallback(
        (params) => {
            selectedNodeRef.current = params;
            equationModal.onOpen();
        },
        [equationModal]
    );

    const closeEquationModal = () => {
        selectedNodeRef.current = null;
        equationModal.onClose();
    };

    const onEquationCellClick = useCallback(
        (params) => {
            openEquationModal(params);
        },
        [openEquationModal]
    );

    const gridResource = useMemo(() => {
        return {
            create: (rows) => addVariableRows(rows),
        };
    }, []);

    const gridColumns = useMemo(
        () => [
            {
                field: 'bookId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data?.books, required: true },
                headerName: intl.formatMessage({ id: 'variables_grid_book' }),
                minWidth: 180,
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
            {
                field: 'source',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'variables_grid_source' }),
                initialSort: 'asc',
                initialSortIndex: 1,
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
            {
                field: 'varName',
                cellEditorParams: { required: true },
                headerName: intl.formatMessage({ id: 'variables_grid_name' }),
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
            {
                field: 'useFlag',
                type: 'checkbox',
                headerName: intl.formatMessage({ id: 'variables_grid_active' }),
                editable: false,
            },
            {
                field: 'calculationOrder',
                type: 'number',
                cellEditorParams: { required: true },
                headerName: intl.formatMessage({ id: 'variables_grid_calc_order' }),
                initialSort: 'asc',
                initialSortIndex: 2,
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
            {
                field: 'description',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'variables_grid_description' }),
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
            {
                field: 'varEquation',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'variables_grid_equation' }),
                cellRenderer: 'CellIconButton',
                cellRendererParams: { onClick: onEquationCellClick, icon: <EditIcon /> },
                minWidth: 250,
                editable: ({ data: { systemVariable } }) => !systemVariable,
                sortable: false,
            },
            {
                field: 'timeSummaryType',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'variables_grid_time_summ' }),
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
            {
                field: 'weightVariable',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'variables_grid_weight_variable' }),
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
            {
                field: 'labelName',
                cellEditorParams: { required: true },
                headerName: intl.formatMessage({ id: 'variables_grid_label_name' }),
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
            {
                field: 'sasFormat',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'variables_grid_sas_format' }),
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
            {
                field: 'statUnitId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.data?.statUnits, required: false },
                headerName: intl.formatMessage({ id: 'variables_grid_stat_unit' }),
                minWidth: 180,
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
            {
                field: 'additionalParentVarNameList',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'variables_grid_additional_var_list' }),
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
            {
                field: 'outputMultiplier',
                type: 'number',
                headerName: intl.formatMessage({ id: 'variables_grid_output_multiplier' }),
                editable: ({ data: { systemVariable } }) => !systemVariable,
            },
        ],
        [dropdownOptions.data?.books, dropdownOptions.data?.statUnits, intl, onEquationCellClick]
    );

    const addDataColumns = useMemo(() => {
        return gridColumns.map((col) => ({ ...col, editable: true }));
    }, [gridColumns]);

    const serverError = (error, errorMessage) => {
        let serverError = error.response.data.error || errorMessage;

        if (error.response.data.errors && Object.values(error.response.data.errors).length) {
            serverError = Object.values(error.response.data.errors)[0][0];
        }

        if (error.response.status !== 401) {
            toast({
                status: 'error',
                message: serverError,
            });
        }
    };

    const updateVariables = async (rowIds) => {
        const rows = rowIds.map((id) => gridApi.current.getRowNode(id));

        const validRows = getValidVariableRows(rows);

        if (validRows.length === 0) return;

        const successMessage = intl.formatMessage({ id: 'variables_save_changes_success' });
        const errorMessage = intl.formatMessage({ id: 'common_generic_processing_error' });

        try {
            await modifyVariables(validRows);
            await fetchVariableSources();
            toast(successMessage);
        } catch (error) {
            serverError(error, errorMessage);
        }
    };

    const onDeleteRow = async (rowIds) => {
        const nodes = rowIds.map((id) => gridApi.current.getRowNode(id).data);
        const variableRows = nodes.map((elem) => elem.variableId);

        await deleteVariableRows(variableRows);

        toast(intl.formatMessage({ id: 'common_delete_grid_msg' }, { n: variableRows.length }));

        refreshGridData();
    };

    const onAfterUpload = () => {
        fetchVariableSources();
        refreshGridData();
    };

    const onDownloadData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const payload = {
                ...filters,
                sorting: sortOrder[GRID_KEY],
                exportTemplate,
            };

            return exportItemData('/variables/export', payload);
        },
        [filters]
    );

    const refreshGridData = useCallback(() => {
        if (gridApi.current) {
            gridApi.current.refreshServerSideStore({
                purge: true,
            });
        }
    }, []);

    const onAddRowsSuccess = () => {
        const successToast = intl.formatMessage({ id: 'common_generic_item_change_success' });

        toast(successToast);

        refreshGridData();
        fetchVariableSources();
    };

    return (
        <SortOrderProvider>
            <MainWrapper>
                <Heading as="h1" variant="h1" mb={4} py={3}>
                    <FormattedMessage id="variables_title" />
                </Heading>
                <>
                    {dropdownOptions.loading || sources.loading ? (
                        <LoadingModal header={<FormattedMessage id="common_loading" />} isOpen />
                    ) : (
                        <>
                            {dropdownOptions.error || sources.error ? (
                                <Box m={6}>
                                    <Error
                                        primaryId="common_error"
                                        secondaryId="common_loading_error"
                                        additionalText={intl.formatMessage({
                                            id: 'variables_filters',
                                        })}
                                    />
                                </Box>
                            ) : (
                                <>
                                    <FiltersWrapper>
                                        <ComboboxField
                                            id="column"
                                            name="column"
                                            label={intl.formatMessage({ id: 'variables_grid_source' })}
                                            placeholderLabel={intl.formatMessage({
                                                id: 'variables_select_placeholder',
                                            })}
                                            placeholderValue=""
                                            showPlaceholder={true}
                                            options={sources.data}
                                            onChange={(value) => setFilters((prev) => ({ ...prev, source: value }))}
                                            value={filters.source}
                                        />

                                        <CheckboxField
                                            m={0}
                                            display="flex"
                                            isChecked={filters.onlyUserVariables}
                                            onChange={() =>
                                                setFilters((prev) => ({
                                                    ...prev,
                                                    onlyUserVariables: !prev.onlyUserVariables,
                                                }))
                                            }
                                        >
                                            <Text size="sm">
                                                <FormattedMessage id="variables_filter_show_only_user" />
                                            </Text>
                                        </CheckboxField>

                                        <CheckboxField
                                            m={0}
                                            display="flex"
                                            isChecked={filters.showInactiveVariables}
                                            onChange={() =>
                                                setFilters((prev) => ({
                                                    ...prev,
                                                    showInactiveVariables: !prev.showInactiveVariables,
                                                }))
                                            }
                                        >
                                            <Text size="sm">
                                                <FormattedMessage id="variables_filter_show_inactive" />
                                            </Text>
                                        </CheckboxField>

                                        <Spacer display={{ base: 'none', xl: 'block' }} />

                                        <Flex
                                            flex="1"
                                            w="auto !important"
                                            align="center"
                                            justify={{ base: 'flex-start', xl: 'flex-end' }}
                                        >
                                            <SecondaryButton
                                                mr={2}
                                                leftIcon={<DownloadIcon />}
                                                w="auto !important"
                                                size="sm"
                                                type="button"
                                                variant="secondary"
                                                onClick={downloadModal.onOpen}
                                            >
                                                <FormattedMessage id="common_download_btn_tooltip_and_label" />
                                            </SecondaryButton>

                                            <UploadButton
                                                type="variables"
                                                hasLabel={true}
                                                w="auto !important"
                                                onAfterUploadChange={onAfterUpload}
                                                hideTreatWarningsAsErrors
                                            />

                                            <SecondaryButton
                                                ml={4}
                                                variant="secondary"
                                                size="sm"
                                                leftIcon={<AddIcon />}
                                                onClick={addDataModal.onOpen}
                                                textTransform="capitalize"
                                            >
                                                <FormattedMessage id="common_add_rows_btn" />
                                            </SecondaryButton>
                                        </Flex>
                                    </FiltersWrapper>

                                    <StyledGridWrapper>
                                        <DataGrid
                                            name={GRID_KEY}
                                            onGridReady={onGridReady}
                                            onDelete={onDeleteRow}
                                            enableBrowserTooltips={true}
                                            serverSideDatasource={{ getRows: getServerSideData }}
                                            onDataChange={updateVariables}
                                            paginationPageSize={500}
                                            cacheBlockSize={500}
                                            rowModelType="serverSide"
                                            serverSideStoreType="partial"
                                            columns={gridColumns}
                                            multisortPairs={multisortPairs}
                                            gridResource={gridResource}
                                            addDataColumns={addDataColumns}
                                            onAddRowsSuccess={onAddRowsSuccess}
                                        />
                                    </StyledGridWrapper>
                                </>
                            )}
                        </>
                    )}
                </>

                {equationModal.isOpen && <EquationsModal node={selectedNodeRef.current} onClose={closeEquationModal} />}

                {downloadModal.isOpen && (
                    <DownloadModal
                        isOpen
                        onClose={downloadModal.onClose}
                        onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                        onDownloadTemplate={() => onDownloadData(true)}
                        bodyMessage={<FormattedMessage id="download_modal_explanation" />}
                    />
                )}

                {addDataModal.isOpen && (
                    <AddDataModal
                        isOpen
                        onClose={addDataModal.onClose}
                        columns={addDataColumns}
                        gridResource={gridResource}
                        onAddRowsSuccess={onAddRowsSuccess}
                        heading={<FormattedMessage id="common_add_rows_heading" />}
                        instructions={<FormattedMessage id="common_add_rows_sub_heading_without_date" />}
                    />
                )}
            </MainWrapper>
        </SortOrderProvider>
    );
};

const FiltersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    > * {
        width: 100%;
        margin-bottom: 16px;
    }

    > div:last-of-type {
        margin-top: 8px;
        margin-right: 0;
    }

    @media (min-width: 768px) {
        > * {
            margin-right: 24px;
            width: auto;
        }

        > button {
            margin-top: 20px;
        }

        > div {
            width: 250px;
        }
    }
`;

const StyledGridWrapper = styled(DataGridWrapper)`
    flex: 1 1 300px;
    padding-bottom: 60px;
`;

export default Variables;
