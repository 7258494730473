import { useState, useEffect } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';

import { FEATURE_FLAG_NAMES } from '../../constants/featureFlags';
import { SLICEOFDAY_PARTIAL_DELIVERABILITY } from 'constants/autoforms';
import { useIsFeatureActive } from '../../hooks/features-flags';
import { isNumberCellEmpty } from 'services/items';

const SubBatteryDefinitions = ({ batteryDefinitions, formValues, idx }) => {
    const isCAMSliceOfDayVisible = useIsFeatureActive(FEATURE_FLAG_NAMES.CAM_SLICEOFDAY_FIELDS_VISIBLE);
    const [pcdsPercentValue, setPcdsPercentValue] = useState(null);
    const [pcdsPercentDisabled, setPcdsPercentDisabled] = useState(true);
    const [pcdsPercentWarningMessage, setPcdsPercentWarningMessage] = useState(null);
    const [pcdsPercentDisplayWarning, setPcdsPercentDisplayWarning] = useState(false);
    const [numRACyclesWarningMessage, setNumRACyclesWarningMessage] = useState(null);
    const [numRACyclesDisplayWarning, setNumRACyclesDisplayWarning] = useState(false);

    useEffect(() => {
        if (isCAMSliceOfDayVisible) {
            const subBatteryDeliverabilityStatusId = formValues?.subBatteries[idx]?.deliverabilityStatusId ?? -1;
            setPcdsPercentValue(isNumberCellEmpty(formValues?.subBatteries[idx]?.pcdsPercent));
            const selectedDeliverability = batteryDefinitions.definitions?.deliverabilityStatusOptions
                ?.find((element) => element.id === subBatteryDeliverabilityStatusId)
                ?.description?.trim()
                .toUpperCase();
            if (selectedDeliverability && SLICEOFDAY_PARTIAL_DELIVERABILITY === selectedDeliverability && pcdsPercentDisabled) {
                setPcdsPercentDisabled(false);
            }
        }
    }, [isCAMSliceOfDayVisible, batteryDefinitions, formValues, idx, pcdsPercentDisabled]);

    const onDeliverabilityStatusChange = async (value) => {
        if (isCAMSliceOfDayVisible) {
            const selectedDeliverability = batteryDefinitions.definitions?.deliverabilityStatusOptions
                ?.find((x) => x.id === value)
                ?.description?.trim()
                .toUpperCase();
            if (SLICEOFDAY_PARTIAL_DELIVERABILITY === selectedDeliverability) {
                setPcdsPercentDisabled(false);
                validatePCDSPercentWarning(pcdsPercentValue);
            } else {
                setPcdsPercentDisabled(true);
                setPcdsPercentDisplayWarning(false);
                setPcdsPercentWarningMessage(null);
            }
        }
    };

    const validateNumRACyclesWarning = async (value) => {
        if (isCAMSliceOfDayVisible) {
            if (value < 1) {
                setNumRACyclesDisplayWarning(true);
                setNumRACyclesWarningMessage(<FormattedMessage id="batteries_num_ra_cycles_warning" />);
            } else {
                setNumRACyclesDisplayWarning(false);
                setNumRACyclesWarningMessage(null);
            }
        }
    };

    const validatePCDSPercentWarning = async (value) => {
        if (isCAMSliceOfDayVisible) {
            value = isNumberCellEmpty(value);
            if (pcdsPercentValue !== value) {
                setPcdsPercentValue(value);
                if (value === null) {
                    setPcdsPercentDisplayWarning(true);
                    setPcdsPercentWarningMessage(<FormattedMessage id="batteries_pcds_percent_warning" />);
                } else {
                    setPcdsPercentDisplayWarning(false);
                    setPcdsPercentWarningMessage(null);
                }
            }
        }
    };

    return (
        <SimpleGrid columns={4} mt={3} spacingX={5} spacingY={5}>
            <ComboboxFormikField
                id={`subBatteries[${idx}].batteryTypeId`}
                name={`subBatteries[${idx}].batteryTypeId`}
                label={<FormattedMessage id="batteries_battery_type" />}
                options={batteryDefinitions.definitions.batteryTypes}
                labelKey="description"
                valueKey="id"
                placeholderValue={null}
                showPlaceholder={true}
            />
            {isCAMSliceOfDayVisible && (
                <>
                <NumberInputFormikField
                    id={`subBatteries[${idx}].numRACycles`}
                    name={`subBatteries[${idx}].numRACycles`}
                    keepWithinRange={true}
                    min={0}
                    max={100}
                    step={0.1}
                    defaultValue={1}
                    isRequired
                    label={<FormattedMessage id="batteries_num_ra_cycles" />}
                    warning={numRACyclesWarningMessage}
                    showWarning={numRACyclesDisplayWarning}
                    validate={validateNumRACyclesWarning}
                />
                <ComboboxFormikField
                    id={`subBatteries[${idx}].deliverabilityStatusId`}
                    name={`subBatteries[${idx}].deliverabilityStatusId`}
                    label={<FormattedMessage id="batteries_deliverability_status" />}
                    options={batteryDefinitions.definitions.deliverabilityStatusOptions}
                    labelKey="description"
                    valueKey="id"
                    onChange={onDeliverabilityStatusChange}
                    placeholderValue={null}
                    showPlaceholder={true}
                />
                <NumberInputFormikField
                    id={`subBatteries[${idx}].pcdsPercent`}
                    name={`subBatteries[${idx}].pcdsPercent`}
                    keepWithinRange={true}
                    min={0}
                    max={100}
                    step={1}
                    label={<FormattedMessage id="batteries_pcds_percent" />}
                    isDisabled={pcdsPercentDisabled}
                    isRequired={!pcdsPercentDisabled}
                    warning={pcdsPercentWarningMessage}
                    showWarning={pcdsPercentDisplayWarning}
                    validate={validatePCDSPercentWarning}
                />
                </>
            )}
        </SimpleGrid>
    );
};

export default SubBatteryDefinitions;
