import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { preserveConnectionSearchParams, addToSearchParams } from '../services/connections';

const useHistoryPush = () => {
    const history = useHistory();

    const wrapper = useCallback(
        (params) => {
            const config = preserveConnectionSearchParams(window.location.search);

            if (typeof params === 'string') {
                // user is doing a simple history.push('/loads')
                // strip out everything except connection+env+book query params
                history.push({
                    pathname: params,
                    search: config,
                });
            } else {
                // user provided some custom query params: history.push({ pathname: '/jobs', search: ids });
                // check if params.search are equal to the user config
                // for cases such as basis configurator history pushes where we also add hash argument
                // if params.search and config are not the same we combine the provided search and the allow list of connection+env+book query params

                const search = params.search === config ? config : addToSearchParams(params.search, config);
                const hash = params.hash || null;

                history.push({
                    pathname: params.pathname,
                    search,
                    hash,
                    forceRedirect: params.forceRedirect || false,
                });
            }
        },
        [history]
    );

    return wrapper;
};

export default useHistoryPush;
