import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled/macro';

import SecondaryButton from './SecondaryButton';

import { ReactComponent as FilterIcon } from '../../icons/filter.svg';

const FiltersButton = ({ hasIndicator, children, ...rest }) => {
    return (
        <SecondaryButton
            leftIcon={<FilterIcon />}
            rightIcon={hasIndicator && <IndicatorIcon />}
            type="button"
            variant="secondary"
            size="sm"
            {...rest}
        >
            {children || <FormattedMessage id="common_filters_btn" />}
        </SecondaryButton>
    );
};

const IndicatorIcon = styled.div`
    background: var(--chakra-colors-blue-400);
    height: 6px;
    width: 6px;
    border-radius: var(--chakra-radii-md);
    margin-top: -10px;
`;

export default FiltersButton;
