import axios from 'axios';

export const getPowerBiEmbedToken = async ({ reportId, dataset, identity }) => {
    const payload = {
        datasets: [
            {
                id: dataset.id,
            },
        ],
        reports: [
            {
                id: reportId,
            },
        ],
    };

    if (dataset?.isEffectiveIdentityRequired) {
        payload.identities = [
            {
                username: identity,
                datasets: [dataset.id],
            },
        ];
    }

    const response = await axios.post('/power-bi-embedded-token', payload);

    return response.data;
};

export const getGroups = async () => {
    const response = await axios.get('/power-bi-workspaces');

    return response.data;
};

export const getReports = async ({ groupId }) => {
    const response = await axios.get(`/power-bi-reports?groupId=${groupId}`);

    return response.data?.value;
};

export const getDataset = async ({ groupId, datasetId }) => {
    const response = await axios.get(`/power-bi-datasets?groupId=${groupId}&datasetId=${datasetId}`);

    return response.data;
};
