import { useState, useEffect, useCallback, useRef } from 'react';
import {
    Box,
    Heading,
    Flex,
    Button,
    Text,
    useDisclosure,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    SimpleGrid,
    Accordion,
    useBoolean,
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    CloseButton,
    UnorderedList,
    ListItem,
    Radio,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, setNestedObjectValues } from 'formik';

import {
    deleteVariableGroup,
    getStudyResults,
    getTemplates,
    getStudyData,
    copyVariableGroup,
    sortVariableGroupsByDescription,
    getParentStudyInformation,
    getDefaultStudySettings,
    sortTemplatesAlphabetically,
    sortTemplatesChronologically,
    setUserSortOrder,
    getUserSortOrder,
} from '../services/studies';
import { getDynamicPropValue, mapUpdatedStudyProperties } from '../services/dynamicProperties';
import { enUSFormatExcludingTime } from '../services/items';

import useCommonToast from '../hooks/useCommonToast';
import useHistoryPush from '../hooks/useHistoryPush';

import ConfirmationModal from '../components/modal/ConfirmationModal';
import LoadingModal from '../components/modal/LoadingModal';

import Error from '../components/utils/Error';
import SelectPortfolioModal from '../components/utils/SelectPortfolioModal';
import SimpleGridSkeleton from '../components/utils/SimpleGridSkeleton';

import InputFormikField from '../components/forms/InputFormikField';
import TextareaFormikField from '../components/forms/TextareaFormikField';
import DateFormikField from '../components/forms/DateFormikField';
import CustomCheckbox from '../components/forms/CustomCheckbox';
import ComboboxField from '../components/forms/ComboboxField';
import ComboboxFormikField from '../components/forms/ComboboxFormikField';
import MultiSelectField from '../components/forms/MultiSelectField';
import RadioGroupField from '../components/forms/RadioGroupField';

import SettingsTab from '../components/studies/SettingsTab';
import VariableGroupModal from '../components/studies/VariableGroupModal';
import StudyConfirmationModal from '../components/studies/StudyConfirmationModal';
import VariableGroup from '../components/studies/VariableGroup';
import StudyPropertiesTab from '../components/studies/StudyPropertiesTab';

import { ReactComponent as FolderClosed } from '../icons/folder-closed.svg';
import { ReactComponent as WarningIcon } from '../icons/warning.svg';
import { FEATURE_FLAG_NAMES, useIsFeatureActive } from '../hooks/features-flags';

const fieldsValidationLabels = {
    portfolios: 'portfolios',
    variables: 'variables',
    peakPeriods: 'peakPeriods',
    sensitivity: 'sensitivity',
    timeIntervals: 'timeIntervals',
    customCodebaseDuplication: 'customCodebaseDuplication',
};

const StudyRun = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedPortfolios, setSelectedPortfolios] = useState([]);
    const [modules, setModules] = useState([]);
    const [selectedModules, setSelectedModules] = useState([]);
    const [settings, setSettings] = useState([]);
    const [variableGroups, setVariableGroups] = useState([]);
    const [selectedVariableGroups, setSelectedVariableGroups] = useState([]);
    const [variableGroupToEdit, setVariableGroupToEdit] = useState();
    const [variableGroupToDelete, setVariableGroupToDelete] = useState();
    const [selectedPeakPeriods, setPeakPeriods] = useState([]);
    const [selectedSensitivity, setSensitivity] = useState([]);
    const [selectedReports, setReports] = useState([]);
    const [selectedTimeIntervals, setTimeIntervals] = useState([]);
    const [runDate, setRunDate] = useState(new Date());
    const [priority, setPriority] = useState('Normal');
    const [timeStepper, setTimeStepper] = useState();
    const [curvePrice, setCurvePrice] = useState();
    const [curveSource, setCurveSource] = useState();
    const [studyResults, setStudyResults] = useState([]);
    const [studyNames, setStudyNames] = useState([]);
    const [simulations, setSimulations] = useState(1);
    const [foSimulations, setFOSimulations] = useState(1);
    const [errors, setErrors] = useState({});
    const [templates, setTemplates] = useState([]);
    const [templateLoading, setTemplateLoading] = useState(false);
    const [studyType, setStudyType] = useState();
    const [parentStudyInfo, setParentStudyInfo] = useState({});
    const [sortOrder, setSortOrder] = useState(() => getUserSortOrder());

    const [defaultStudyProperties, setDefaultStudyProperties] = useState([]);
    const [studyProperties, setStudyProperties] = useState([]);

    const [codebase, setCodebase] = useState();
    const [studyTemplateId, setStudyTemplateId] = useState(null);

    const [isLoading, setIsLoading] = useState({
        defaultConfigurations: true,
        templates: true,
        studyResults: true,
    });

    const [loadingError, setLoadingError] = useState({
        defaultConfigurations: false,
        templates: false,
        studyResults: false,
    });

    const [curvePriceDefaultValue, setCurvePriceDefaultValue] = useState();
    const [curveSourceDefaultValue, setCurveSourceDefaultValue] = useState();
    const [studyTypeDefaultValue, setStudyTypeDefaultValue] = useState();
    const [defaultTimeStep, setDefaultTimeStep] = useState();
    const [newStudyNameValue, setNewStudyName] = useState('');

    const [isRunReportsNowChecked, onIsRunReportsNowChanged] = useBoolean(false);
    const [isDeterministicChecked, onIsDeterministicChanged] = useBoolean(true);
    const [isTemplateChecked, onIsTemplateChanged] = useBoolean(false);
    const [isDoNotDeleteChecked, onIsDoNotDeleteChanged] = useBoolean(false);
    const [errorBoxShown, onErrorBoxToggle] = useBoolean(true);

    const { toast } = useCommonToast();
    const intl = useIntl();
    const historyPush = useHistoryPush();

    const formRef = useRef();
    const formPropertiesRef = useRef();
    const errorBoxRef = useRef();

    const selectPortfolioModal = useDisclosure();
    const createVariableGroupModal = useDisclosure();
    const editVariableGroupModal = useDisclosure();
    const runStudyConfirmationModal = useDisclosure();
    const deleteVariableGroupModal = useDisclosure();

    const isFOSimulationsFieldVisible = useIsFeatureActive(FEATURE_FLAG_NAMES.STUDY_SETTINGS_FO_SIMS_COUNT_VISIBLE);

    // We are using state tab change in order to rerender the TabPanels
    // This is needed so the TabListWrapper can accurately detect if its css-hidden
    // Else functions like .clientWidth and .scrollWidth will return 0
    // isLazy is not an option, due to other tabs returning vital refs.
    const handleTabChange = (index) => {
        setTabIndex(index);
    };

    const fetchStudyDefaultConfigurations = useCallback(async () => {
        return getStudyData(-1)
            .then((data) => {
                const { modules, settings, variableGroups, dynamicProperties } = data;

                const defaultTimeIntervals = getDefaultStudySettings(settings.dateIntervals);
                const defaultPeakPeriods = getDefaultStudySettings(settings.peakPeriods);
                const defaultSensitivities = getDefaultStudySettings(settings.sensitivities);

                setTimeIntervals(defaultTimeIntervals);
                setPeakPeriods(defaultPeakPeriods);
                setSensitivity(defaultSensitivities);

                setSettings(settings);

                // @TranslationWarning
                const defaultCurvePrice = settings.curvePriceTypes.find(
                    (elem) => elem.description === 'DEFAULTMID'
                )?.id;
                setCurvePriceDefaultValue(defaultCurvePrice);

                //Default source should be the first in the list.
                const defaultSourcePrice = settings.curveSources[0]?.id;
                setCurveSourceDefaultValue(defaultSourcePrice);

                const defaultTimeStep = settings.timeStepSize.find((elem) => elem.description === '1').id;
                setDefaultTimeStep(defaultTimeStep);

                const defaultStudyType = settings.studyTypes ? settings.studyTypes[0].id : '';
                setStudyTypeDefaultValue(defaultStudyType);

                setStudyType(defaultStudyType);
                setCurvePrice(defaultCurvePrice);
                setCurveSource(defaultSourcePrice);
                setTimeStepper(defaultTimeStep);

                setVariableGroups(variableGroups);
                setModules(modules);

                setDefaultStudyProperties(dynamicProperties);
                setStudyProperties(dynamicProperties);
            })
            .catch(() => {
                setLoadingError((prev) => ({ ...prev, defaultConfigurations: true }));
            })
            .finally(() => {
                setIsLoading((prev) => ({ ...prev, defaultConfigurations: false }));
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchTemplates = useCallback(async () => {
        return getTemplates()
            .then(([...data]) => {
                let sortedTemplates;
                if (sortOrder === 'alphabetical') {
                    sortedTemplates = sortTemplatesAlphabetically(data);
                } else {
                    sortedTemplates = sortTemplatesChronologically(data);
                }

                setTemplates(sortedTemplates);

                setStudyNames(data.filter((elem) => elem.description).map((elem) => elem.description.toUpperCase()));
            })
            .catch(() => setLoadingError((prev) => ({ ...prev, templates: true })))
            .finally(() => setIsLoading((prev) => ({ ...prev, templates: false })));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchStudyResults = useCallback(async () => {
        return getStudyResults()
            .then((data) => setStudyResults(data))
            .catch(() => setLoadingError((prev) => ({ ...prev, studyResults: true })))
            .finally(() => setIsLoading((prev) => ({ ...prev, studyResults: false })));
    }, []);

    useEffect(() => {
        fetchStudyDefaultConfigurations();
        fetchStudyResults();
        fetchTemplates();
    }, [fetchStudyDefaultConfigurations, fetchStudyResults, fetchTemplates]);

    const refreshData = async () => {
        await fetchTemplates();
    };

    const formLabels = {
        studyName: intl.formatMessage({ id: 'run_a_study_label_study_name' }),
        notes: intl.formatMessage({ id: 'run_a_study_label_notes' }),
        simulationStart: intl.formatMessage({ id: 'run_a_study_label_simulation_start' }),
        simulationEnd: intl.formatMessage({ id: 'run_a_study_label_simulation_end' }),
        useResultsFrom: intl.formatMessage({ id: 'run_a_study_label_use_results_from' }),
        studyName_placeholder: intl.formatMessage({ id: 'run_a_study_placeholder_study_name' }),
        notes_placeholder: intl.formatMessage({ id: 'run_a_study_placeholder_notes' }),
        useResultsFrom_placeholder: intl.formatMessage({ id: 'run_a_study_placeholder_none' }),
        loading_template: intl.formatMessage({ id: 'run_a_study_loading_template' }),
    };

    const errorLabels = {
        errorHeader: intl.formatMessage({ id: 'run_a_study_error_box_header' }),
        errorSettingsTab: intl.formatMessage({ id: 'run_a_study_error_box_settings' }),
        errorVariablesTab: intl.formatMessage({ id: 'run_a_study_error_box_variables' }),
        errorStudyPropertiesTab: intl.formatMessage({ id: 'run_a_study_error_box_study_properties' }),
    };

    const initialFormValues = {
        studyName: '',
        useResultsFrom: '',
        simulationStart: '',
        simulationEnd: '',
        notes: '',
    };

    const validateStudyData = () => {
        const studyValidationRulesErrors = {};

        if (
            selectedPeakPeriods.length === 0 ||
            selectedTimeIntervals.length === 0 ||
            selectedSensitivity.length === 0
        ) {
            studyValidationRulesErrors.tabSettings = true;
        }

        if (selectedPeakPeriods.length === 0) {
            studyValidationRulesErrors.peakPeriods = intl.formatMessage({
                id: 'run_a_study_validation_missing_peak_period',
            });
        }

        if (selectedTimeIntervals.length === 0) {
            studyValidationRulesErrors.timeIntervals = intl.formatMessage({
                id: 'run_a_study_validation_missing_time_interval',
            });
        }

        if (selectedSensitivity.length === 0) {
            studyValidationRulesErrors.sensitivity = intl.formatMessage({
                id: 'run_a_study_validation_missing_sensitivity',
            });
        }

        if (selectedVariableGroups.length === 0) {
            studyValidationRulesErrors.variables = intl.formatMessage({
                id: 'run_a_study_validation_missing_variables',
            });
            studyValidationRulesErrors.tabVariables = true;
        }

        if (selectedPortfolios.length === 0) {
            studyValidationRulesErrors.portfolios = intl.formatMessage({
                id: 'run_a_study_validation_missing_portfolio',
            });
        }

        if (checkCustomCodebaseDuplication()) {
            studyValidationRulesErrors.customCodebaseDuplication = intl.formatMessage({
                id: 'run_a_study_validation_codebase_duplication',
            });
            studyValidationRulesErrors.tabStudyProperties = true;
        }

        return studyValidationRulesErrors;
    };

    const removeStudyValidationErrors = (...validFields) => {
        setErrors((prev) => {
            const currentErrors = { ...prev };
            validFields.forEach((field) => delete currentErrors[field]);

            const settingsTabErrors =
                currentErrors.peakPeriods || currentErrors.sensitivity || currentErrors.timeIntervals;

            if (currentErrors.tabVariables && !currentErrors.variables) {
                delete currentErrors.tabVariables;
            }

            if (currentErrors.tabSettings && !settingsTabErrors) {
                delete currentErrors.tabSettings;
            }

            if (currentErrors.tabStudyProperties && !currentErrors.customCodebaseDuplication) {
                delete currentErrors.tabStudyProperties;
            }

            return currentErrors;
        });
    };

    const onRunStudyClick = async () => {
        await refreshData();

        // Run Study
        const studyFormErrors = await formRef.current.validateForm();
        const studyValidationRulesErrors = validateStudyData();
        const hasFormErrors = Object.keys(studyFormErrors).length > 0;
        const hasValidationRulesErrors = Object.keys(studyValidationRulesErrors).length > 0;
        const isStudyDataInvalid = hasFormErrors || hasValidationRulesErrors;

        if (hasFormErrors) {
            formRef.current.setTouched(setNestedObjectValues(studyFormErrors, true));
        }

        if (hasValidationRulesErrors) {
            setErrors(studyValidationRulesErrors);
            onErrorBoxToggle.on();
            errorBoxRef.current.scrollIntoView();
            errorBoxRef.current.focus();
        }

        if (!isStudyDataInvalid) {
            runStudyConfirmationModal.onOpen();
        }
    };

    // Dynamic check for formik if multiple bookmacrocodebase error present
    const onDynamicPropsChange = () => {
        const duplication = checkCustomCodebaseDuplication();
        if (errors.tabStudyProperties && !duplication) {
            removeStudyValidationErrors(fieldsValidationLabels.customCodebaseDuplication);
        }
    };

    const toggleSettings = (value, type) => {
        const validFields = [];

        if (type === 'peak') {
            const peakPeriods = selectedPeakPeriods.includes(value)
                ? selectedPeakPeriods.filter((v) => v !== value)
                : selectedPeakPeriods.concat(value);

            if (errors.peakPeriods && peakPeriods.length > 0) {
                validFields.push(fieldsValidationLabels.peakPeriods);
            }

            setPeakPeriods(peakPeriods);
        }

        if (type === 'sensitivity') {
            const sensitivity = selectedSensitivity.includes(value)
                ? selectedSensitivity.filter((v) => v !== value)
                : selectedSensitivity.concat(value);

            if (errors.sensitivity && sensitivity.length > 0) {
                validFields.push(fieldsValidationLabels.sensitivity);
            }

            setSensitivity(sensitivity);
        }

        if (type === 'time') {
            const timeIntervals = selectedTimeIntervals.includes(value)
                ? selectedTimeIntervals.filter((v) => v !== value)
                : selectedTimeIntervals.concat(value);

            if (errors.timeIntervals && timeIntervals.length > 0) {
                validFields.push(fieldsValidationLabels.timeIntervals);
            }

            setTimeIntervals(timeIntervals);
        }

        if (type === 'report') {
            setReports((prev) => {
                return prev.includes(value) ? prev.filter((v) => v !== value) : prev.concat(value);
            });
        }

        if (type === 'modules') {
            setSelectedModules((prev) => {
                return prev.includes(value) ? prev.filter((v) => v !== value) : prev.concat(value);
            });
        }

        if (validFields.length > 0) {
            removeStudyValidationErrors(...validFields);
        }
    };

    const onSelectPortfolioModalContinue = (portfolios) => {
        setSelectedPortfolios(portfolios);
        if (errors.portfolios) {
            removeStudyValidationErrors(fieldsValidationLabels.portfolios);
        }
        selectPortfolioModal.onClose();
    };

    const onCreateVariableGroupSuccess = async (newVariableGroup) => {
        setVariableGroups((prev) => sortVariableGroupsByDescription([...prev, newVariableGroup]));
        createVariableGroupModal.onClose();
    };

    const onEditVariableGroupSuccess = (updatedVariableGroup) => {
        const selectedGroups = selectedVariableGroups.map((variableGroup) => {
            if (variableGroup.variableGroupId === updatedVariableGroup.variableGroupId) {
                return updatedVariableGroup;
            }

            return variableGroup;
        });

        const updatedVariableGroups = variableGroups.map((variableGroup) => {
            if (variableGroup.variableGroupId === updatedVariableGroup.variableGroupId) {
                return updatedVariableGroup;
            }

            return variableGroup;
        });

        editVariableGroupModal.onClose();

        setVariableGroups(updatedVariableGroups);
        setSelectedVariableGroups(selectedGroups);
        setVariableGroupToEdit(null);
    };

    const onVariableGroupEdit = (variableGroup) => {
        setVariableGroupToEdit(variableGroup);
        editVariableGroupModal.onOpen();
    };

    const onVariableGroupDelete = (variableGroup) => {
        setVariableGroupToDelete(variableGroup);
        deleteVariableGroupModal.onOpen();
    };

    const onVariableGroupDeleteConfirmed = async () => {
        try {
            await deleteVariableGroup(variableGroupToDelete.variableGroupId);

            const updatedVariableGroups = variableGroups.filter(
                (variableGroup) => variableGroup.variableGroupId !== variableGroupToDelete.variableGroupId
            );

            const selectedGroups = selectedVariableGroups.filter(
                (variableGroup) => variableGroup.variableGroupId !== variableGroupToDelete.variableGroupId
            );

            deleteVariableGroupModal.onClose();

            setSelectedVariableGroups(selectedGroups);
            setVariableGroups(updatedVariableGroups);
            setVariableGroupToDelete(null);
            editVariableGroupModal.onClose();

            const successMessage = intl.formatMessage({ id: 'run_a_study_delete_variable_group_success' });
            toast(successMessage);
        } catch {
            const errorMessage = intl.formatMessage({ id: 'common_generic_delete_error' });
            toast({
                status: 'error',
                message: errorMessage,
            });
            deleteVariableGroupModal.onClose();
        }
    };

    const onVariableGroupModalClose = () => {
        deleteVariableGroupModal.onClose();
        setVariableGroupToDelete(null);
    };

    const onVariableGroupCopy = async (variableGroupId) => {
        const copiedGroup = await copyVariableGroup(variableGroupId);

        setVariableGroups((prev) => sortVariableGroupsByDescription([...prev, copiedGroup]));
        setSelectedVariableGroups((prev) => sortVariableGroupsByDescription([...prev, copiedGroup]));
    };

    const onVariableGroupDeselect = async (variableGroupId) => {
        setSelectedVariableGroups((prev) => prev.filter((e) => e.variableGroupId !== variableGroupId));
    };

    const onVariableStatisticEdit = (id, groupId, checkedCount, iterationChecked, meanChecked) => {
        let tempGroups = [...variableGroups];
        let tempSelected = [...selectedVariableGroups];

        let groupInd = tempGroups.findIndex((elem) => elem.variableGroupId === groupId);
        let variableInd = tempGroups[groupInd].variables.findIndex((elem) => elem.variableId === id);

        let variableIteration = tempGroups[groupInd].variables[variableInd].hasIterations;
        let variableMean = tempGroups[groupInd].variables[variableInd].hasMean;
        let statisticsCount = tempGroups[groupInd].variables[variableInd].statisticsCount;

        if (
            variableIteration !== iterationChecked ||
            variableMean !== meanChecked ||
            statisticsCount !== checkedCount
        ) {
            tempGroups[groupInd].variables[variableInd].hasIterations = iterationChecked;
            tempGroups[groupInd].variables[variableInd].hasMean = meanChecked;
            tempGroups[groupInd].variables[variableInd].statisticsCount = checkedCount;

            groupInd = tempSelected.findIndex((elem) => elem.variableGroupId === groupId);
            variableInd = tempSelected[groupInd].variables.findIndex((elem) => elem.variableId === id);

            tempSelected[groupInd].variables[variableInd].hasIterations = iterationChecked;
            tempSelected[groupInd].variables[variableInd].hasMean = meanChecked;
            tempSelected[groupInd].variables[variableInd].statisticsCount = checkedCount;

            setVariableGroups(tempGroups);
            setSelectedVariableGroups(tempSelected);
        }
    };

    const validateStudyNameOnTemplateChange = () => {
        //determine if new study name already exists
        if (studyNames.find((name) => name === formRef.current.values.studyName.trim().toUpperCase())) {
            //set field as touched and set field error so both the error message below the dropdown and the error box display
            formRef.current.setFieldError(
                'studyName',
                intl.formatMessage({ id: 'run_a_study_validation_name_duplication' })
            );
            formRef.current.setFieldTouched('studyName');
        } else {
            //set field as not touched and delete the error from the form errors
            formRef.current.setFieldTouched('studyName', false, false);
            let errors = formRef.current.errors;
            if (errors?.studyName) {
                delete errors.studyName;
            }
            formRef.current.setErrors(errors);
        }
    };

    const validateStudyDataOnTemplateChange = (studyData) => {
        const { portfolios, configVariableGroups, settings } = studyData;
        const validFields = [];

        if (errors.portfolios && portfolios.length > 0) {
            validFields.push(fieldsValidationLabels.portfolios);
        }
        if (errors.variables && configVariableGroups.length > 0) {
            validFields.push(fieldsValidationLabels.variables);
        }
        if (errors.peakPeriods && settings.peakPeriods.length > 0) {
            validFields.push(fieldsValidationLabels.peakPeriods);
        }
        if (errors.sensitivity && settings.sensitivities.length > 0) {
            validFields.push(fieldsValidationLabels.sensitivity);
        }
        if (errors.timeIntervals && settings.dateIntervals.length > 0) {
            validFields.push(fieldsValidationLabels.timeIntervals);
        }

        if (validFields.length > 0) {
            removeStudyValidationErrors(...validFields);
        }
    };

    const getNextCopyName = (name) => {
        const regexStr = '^[vV]([1-9]\\d*)$';
        let newName = name.trim() + ' v1';

        if (name.trim().includes(' ')) {
            let arr = name.trim().split(' ');
            const lastStr = arr[arr.length - 1];
            if (lastStr.match(regexStr)) {
                arr.pop();
                const oldNumber = lastStr.substring(1);
                const newNumber = Number(oldNumber) + 1;
                arr.push('v' + newNumber);
                newName = arr.join(' ');
            }
        }
        return newName;
    };

    const changeTemplate = async (id) => {
        setTemplateLoading(true);
        if (id) {
            setStudyTemplateId(id);

            const config = await getStudyData(id);

            const { settings, modules, study, portfolios, selectedVariableGroupIds, dynamicProperties, codebase } =
                config;

            //General Info
            setSelectedPortfolios(portfolios);
            setStudyProperties(dynamicProperties);
            const newName = study?.name ? getNextCopyName(study.name.trim()) : 'Study Name Not Found';
            formRef.current.setFieldValue('studyName', newName);
            formRef.current.setFieldValue('notes', study?.notes);
            formRef.current.setFieldValue('simulationStart', study?.startTime ? new Date(study.startTime) : '');
            formRef.current.setFieldValue('simulationEnd', study?.endTime ? new Date(study.endTime) : '');

            setNewStudyName(formRef.current.values.studyName);

            //Use parent study if it exists, otherwise use selected template/study
            if (studyResults?.find((elem) => elem.id === parseInt(study?.parentStudyId))) {
                formRef.current.setFieldValue('useResultsFrom', study.parentStudyId);
                setParentStudyInfo({
                    studyId: study?.parentStudyId,
                    startDate: study?.parentStudyStartDate,
                    endDate: study?.parentStudyEndDate,
                    simulations: study?.parentSimulations ? study.parentSimulations : 1,
                    loadSimReps: study?.parentLoadSimReps ? study.parentLoadSimReps : 1,
                });
            } else {
                formRef.current.setFieldValue('useResultsFrom', study?.studyId);
                setParentStudyInfo({
                    studyId: study?.studyId,
                    startDate: study?.startDate,
                    endDate: study?.endDate,
                    simulations: study?.simulations ? study.simulations : 1,
                    loadSimReps: study?.loadSimReps ? study.loadSimReps : 1,
                });
            }

            setCodebase(codebase);

            //Modules Tab
            setSelectedModules(modules.map((elem) => elem.id));

            //Variable Tab
            const configVariableGroups = variableGroups.filter((elem) =>
                selectedVariableGroupIds.includes(elem.variableGroupId)
            );
            setSelectedVariableGroups(configVariableGroups);

            //Settings Tab
            setPeakPeriods(settings.peakPeriods.map((elem) => elem.id));
            setSensitivity(settings.sensitivities.map((elem) => elem.id));
            setTimeIntervals(settings.dateIntervals.map((elem) => elem.id));
            setCurvePrice(settings.curvePriceTypes.length ? settings.curvePriceTypes[0].id : curvePriceDefaultValue);
            setCurveSource(settings.curveSources.length ? settings.curveSources[0].id : curveSourceDefaultValue);
            setPriority(settings.priorities && settings.priorities.length ? settings.priorities[0] : 'Normal');

            const studySimulationsCountDefault = study?.simulations ? study.simulations : 1;
            setSimulations(studySimulationsCountDefault);

            // If Feature Flag is disabled/isFOSimulationsFieldVisible = false then loadSimReps/FO sim will be defaulted to 1
            let studyFOSimulationsCountDefault = 1;
            if (isFOSimulationsFieldVisible) {
                // LoadSimReps field is repurposed to contain Forced Outages Simulations count (FO sim)
                studyFOSimulationsCountDefault = study?.loadSimReps ? study.loadSimReps : 1;
            }
            setFOSimulations(studyFOSimulationsCountDefault);

            setRunDate(study?.scheduledRunDateTime ? new Date(study.scheduledRunDateTime) : '');
            setStudyType(study?.studyTypeId ? study.studyTypeId : studyTypeDefaultValue);
            setTimeStepper(study?.timeStepId ? study.timeStepId : defaultTimeStep);

            // Deterministic checkbox disables both sim and FO sim counts and sends "1" to the database.
            let isDeterministicCheckedDefault = parseInt(studySimulationsCountDefault) === 1;
            if (isFOSimulationsFieldVisible) {
                isDeterministicCheckedDefault =
                    parseInt(studySimulationsCountDefault) === 1 && parseInt(studyFOSimulationsCountDefault) === 1;
            }
            isDeterministicCheckedDefault ? onIsDeterministicChanged.on() : onIsDeterministicChanged.off();

            if (study?.doNotDelete) {
                onIsDoNotDeleteChanged.on();
            } else {
                onIsDoNotDeleteChanged.off();
            }

            onIsTemplateChanged.off(); //default to isTemplate = false

            const reports = [];

            if (study?.ptdPayOff) reports.push('PTDPAYOFF');
            if (study?.simReports) reports.push('SIMREPORTS');

            setReports(reports);

            validateStudyNameOnTemplateChange();
            validateStudyDataOnTemplateChange({ portfolios, configVariableGroups, settings });
        } else {
            setStudyTemplateId(null);
            setSelectedPortfolios([]);
            setStudyProperties(defaultStudyProperties);

            formRef.current.setFieldValue('studyName', '');
            formRef.current.setFieldValue('notes', '');
            formRef.current.setFieldValue('simulationStart', '');
            formRef.current.setFieldValue('simulationEnd', '');
            formRef.current.setFieldValue('useResultsFrom', '');
            setParentStudyInfo({});
            setCodebase();
            setNewStudyName('');

            //Modules Tab
            setSelectedModules([]);

            //Variable Tab
            setSelectedVariableGroups([]);

            //Settings Tab
            setPeakPeriods([]);
            setSensitivity([]);
            setTimeIntervals([]);

            setCurvePrice(curvePriceDefaultValue);
            setCurveSource(curveSourceDefaultValue);
            setStudyType(studyTypeDefaultValue);
            setTimeStepper(defaultTimeStep);

            setPriority('Normal');
            setSimulations(1);
            setFOSimulations(1);
            setRunDate(new Date());
            setReports([]);
            onIsTemplateChanged.off();
            onIsDoNotDeleteChanged.off();
        }
        setTemplateLoading(false);
    };

    const validateSimulationStartDate = (startDate, endDate) => {
        if (!startDate) {
            return intl.formatMessage({ id: 'run_a_study_validation_simulation_start' });
        }

        if (endDate && startDate >= endDate) {
            return intl.formatMessage({ id: 'run_a_study_validation_date_smaller' });
        }
    };

    const validateSimulationEndDate = (startDate, endDate) => {
        if (!endDate) {
            return intl.formatMessage({ id: 'run_a_study_validation_simulation_end' });
        }

        if (startDate && endDate <= startDate) {
            return intl.formatMessage({ id: 'run_a_study_validation_date_bigger' });
        }
    };

    const validate = async (values) => {
        let studyName, notes, simulationStart, simulationEnd;
        if (newStudyNameValue.trim() === '' && values.studyName.trim() === '') {
            setNewStudyName('');
        }
        if (newStudyNameValue.trim() !== '' && values.studyName.trim() === '') {
            await refreshData();
            setNewStudyName(values.studyName);
        } else if (
            (newStudyNameValue.trim() === '' && values.studyName.trim() !== '') ||
            newStudyNameValue.trim().toUpperCase() !== values.studyName.trim().toUpperCase()
        ) {
            await refreshData();
            setNewStudyName(values.studyName.trim());
        }

        if (values.studyName.trim() === '') {
            studyName = intl.formatMessage({ id: 'run_a_study_validation_name_required' });
        } else if (values.studyName.trim() !== '' && studyNames.includes(values.studyName.trim().toUpperCase())) {
            studyName = intl.formatMessage({ id: 'run_a_study_validation_name_duplication' });
        }

        if (values.notes === '') {
            notes = intl.formatMessage({ id: 'run_a_study_validation_notes_required' });
        }

        simulationStart = validateSimulationStartDate(values.simulationStart, values.simulationEnd);
        simulationEnd = validateSimulationEndDate(values.simulationStart, values.simulationEnd);

        return {
            ...(studyName ? { studyName } : null),
            ...(notes ? { notes } : null),
            ...(simulationEnd ? { simulationEnd } : null),
            ...(simulationStart ? { simulationStart } : null),
        };
    };

    const fetchParentStudyInformation = async (val) => {
        if (val) {
            const parentStudy = await getParentStudyInformation(val);
            setParentStudyInfo({
                studyId: parentStudy.studyId,
                startDate: parentStudy.startDate,
                endDate: parentStudy.endDate,
                simulations: parentStudy.simulations ? parentStudy.simulations : 1,
                loadSimReps: parentStudy.loadSimReps ? parentStudy.loadSimReps : 1,
            });
        } else {
            setParentStudyInfo({});
        }
    };

    const checkTemplateDivider = (prev, curr) => {
        if (prev.isTemplate !== curr.isTemplate) return true;
    };

    const checkCustomCodebaseDuplication = () => {
        const othersValue = getDynamicPropValue(
            'OTHERPARAMS',
            formPropertiesRef.current.values.properties,
            defaultStudyProperties
        );

        const customCodebaseValue = getDynamicPropValue(
            'bookmacrocodebase',
            formPropertiesRef.current.values.properties,
            defaultStudyProperties
        );

        if (othersValue.indexOf('bookmacrocodebase=') > -1 && customCodebaseValue) {
            return true;
        }

        return false;
    };

    const getCustomCodebaseValue = () => {
        const othersValue = getDynamicPropValue(
            'OTHERPARAMS',
            formPropertiesRef.current.values.properties,
            defaultStudyProperties
        );
        const customCodebaseValue = getDynamicPropValue(
            'bookmacrocodebase',
            formPropertiesRef.current.values.properties,
            defaultStudyProperties
        );

        let customCodebase = '';

        // Get CustomCodebase value from Others field
        const newLinePattern = 'bookmacrocodebase=(.*)[\n|\r\n|\r]{1}';
        const endLinePattern = 'bookmacrocodebase=(.*)';

        // Try getting the field if its between other lines
        let regexResult = othersValue.match(newLinePattern);
        if (regexResult) {
            customCodebase = regexResult[1];
        } else {
            // If the string bookmacrocodebase exists and we are in the else statement it would mean it is the only line
            if (othersValue.indexOf('bookmacrocodebase=') > -1) {
                regexResult = othersValue.match(endLinePattern);
                customCodebase = regexResult[1];
            }
        }

        // Get CustomCodebase value from CustomCodebase field
        if (customCodebaseValue) {
            customCodebase = customCodebaseValue;
        }

        return customCodebase;
    };

    const onVariableGroupSelect = (value) => {
        setSelectedVariableGroups(value);
        if (errors.tabVariables) {
            removeStudyValidationErrors(fieldsValidationLabels.variables);
        }
    };

    const onSortChange = (value) => {
        setSortOrder(value);
        setUserSortOrder(value);

        if (value === 'chronological') {
            setTemplates((prev) => sortTemplatesChronologically(prev));
            return;
        }

        setTemplates((prev) => sortTemplatesAlphabetically(prev));
    };

    const handleRunStudyConfirmationModalOnClose = async () => {
        await refreshData();
        validateStudyNameOnTemplateChange();
        runStudyConfirmationModal.onClose();
    };

    return (
        <>
            <MainWrapper>
                <CenteredWrapper>
                    <Heading as="h1" variant="h1" mt={5}>
                        <FormattedMessage id="run_a_study" />
                    </Heading>

                    <SimpleGrid
                        columns={{ base: 1, md: 2 }}
                        spacing={5}
                        mt={10}
                        pb={5}
                        borderBottom="1px solid var(--chakra-colors-border-secondary)"
                    >
                        <ComboboxField
                            mb={3}
                            id="configurationTemplate"
                            name="configurationTemplate"
                            label={intl.formatMessage({ id: 'run_a_study_configuration_template' })}
                            options={templates}
                            valueKey="id"
                            labelKey="description"
                            showPlaceholder={true}
                            placeholderLabel={formLabels.useResultsFrom_placeholder}
                            onChange={changeTemplate}
                            isDisabled={isLoading.defaultConfigurations}
                            isControlled={false}
                            hasDivider={checkTemplateDivider}
                        />

                        <RadioGroupField
                            label={<FormattedMessage id="run_a_study_sort_order" />}
                            name="sortingOrder"
                            value={sortOrder}
                            onChange={onSortChange}
                        >
                            <Box mt="12px">
                                <Radio value="alphabetical" mr={5}>
                                    <FormattedMessage id="run_a_study_sort_alphabetical" />
                                </Radio>
                                <Radio value="chronological">
                                    <FormattedMessage id="run_a_study_sort_chronological" />
                                </Radio>
                            </Box>
                        </RadioGroupField>
                    </SimpleGrid>
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        py={5}
                        borderBottom="1px solid var(--chakra-colors-border-secondary)"
                    >
                        <Box>
                            <Text>
                                <FormattedMessage id="run_a_study_portfolio" />
                            </Text>
                            <Flex mt={2} flexWrap="wrap">
                                {selectedPortfolios.length > 0 ? (
                                    selectedPortfolios.map((portfolio, index, array) => {
                                        const suffix = index === array.length - 1 ? '' : ',';

                                        return (
                                            <Flex alignItems="center" flexWrap="wrap" mr={3} key={portfolio.id}>
                                                <FolderClosed />
                                                <Text ml={1} color="black" size="md">
                                                    {portfolio.properties.name + suffix}
                                                </Text>
                                            </Flex>
                                        );
                                    })
                                ) : (
                                    <Text color={errors.portfolios ? 'text-error' : 'black'} size="md">
                                        <FormattedMessage id="run_a_study_no_portfolio_selected" />
                                    </Text>
                                )}
                            </Flex>
                        </Box>

                        <Box>
                            <Button onClick={selectPortfolioModal.onOpen}>
                                {selectedPortfolios.length > 0 ? (
                                    <FormattedMessage id="run_a_study_change_portfolio" />
                                ) : (
                                    <FormattedMessage id="run_a_study_select_a_portfolio" />
                                )}
                            </Button>
                        </Box>
                    </Flex>
                    <Formik
                        enableReinitialize
                        initialValues={initialFormValues}
                        validate={validate}
                        innerRef={formRef}
                        validateOnChange={false}
                    >
                        {({ values, setFieldTouched }) => (
                            <Form>
                                <Box my={5}>
                                    <InputFormikField
                                        name="studyName"
                                        type="text"
                                        label={formLabels.studyName}
                                        placeholder={formLabels.studyName_placeholder}
                                    />
                                </Box>
                                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={3}>
                                    <Box>
                                        <ComboboxFormikField
                                            id="useResultsFrom"
                                            name="useResultsFrom"
                                            label={formLabels.useResultsFrom}
                                            onChange={(val) => fetchParentStudyInformation(val)}
                                            options={studyResults}
                                            valueKey="id"
                                            labelKey="description"
                                            showPlaceholder={true}
                                            placeholderValue=""
                                            placeholderLabel={formLabels.useResultsFrom_placeholder}
                                        />

                                        <SimpleGrid columns={{ base: 1, md: 2 }} my={5} spacing={5}>
                                            <DateFormikField
                                                id="simulationStart"
                                                name="simulationStart"
                                                label={formLabels.simulationStart}
                                                isDisabled={false}
                                                dateFormat={enUSFormatExcludingTime}
                                                showTimeInput={false}
                                                onChange={(value) => {
                                                    if (value && !values.simulationEnd) {
                                                        setFieldTouched('simulationEnd', true, false);
                                                    }
                                                }}
                                            />

                                            <DateFormikField
                                                id="simulationEnd"
                                                name="simulationEnd"
                                                label={formLabels.simulationEnd}
                                                isDisabled={false}
                                                dateFormat={enUSFormatExcludingTime}
                                                showTimeInput={false}
                                                onChange={(value) => {
                                                    if (value && !values.simulationStart) {
                                                        setFieldTouched('simulationStart', true, false);
                                                    }
                                                }}
                                            />
                                        </SimpleGrid>
                                    </Box>

                                    <TextareaFormikField
                                        id="notes"
                                        name="notes"
                                        label={formLabels.notes}
                                        placeholder={formLabels.notes_placeholder}
                                        resize="none"
                                        h="124px"
                                    />
                                </SimpleGrid>
                            </Form>
                        )}
                    </Formik>
                </CenteredWrapper>

                {(Object.keys(errors).length > 0 || formRef?.current?.errors?.studyName) && errorBoxShown && (
                    <CenteredWrapper ref={errorBoxRef} tabIndex={-1}>
                        <Alert status="error" alignItems="flex-start">
                            <AlertIcon />
                            <Box flex="1">
                                <AlertTitle>{errorLabels.errorHeader}</AlertTitle>
                                <AlertDescription display="block">
                                    {errors.tabSettings && (
                                        <Box my={5}>
                                            <Text fontWeight={400} color="black" _dark={{ color: 'black' }}>
                                                {errorLabels.errorSettingsTab}
                                            </Text>
                                            <UnorderedList>
                                                {errors.peakPeriods && <ListItem>{errors.peakPeriods}</ListItem>}
                                                {errors.timeIntervals && <ListItem>{errors.timeIntervals}</ListItem>}
                                                {errors.sensitivity && <ListItem>{errors.sensitivity}</ListItem>}
                                            </UnorderedList>
                                        </Box>
                                    )}

                                    {errors.tabVariables && (
                                        <Box my={5}>
                                            <Text fontWeight={400} color="black" _dark={{ color: 'black' }}>
                                                {errorLabels.errorVariablesTab}
                                            </Text>
                                            <UnorderedList>
                                                {errors.variables && <ListItem>{errors.variables}</ListItem>}
                                            </UnorderedList>
                                        </Box>
                                    )}

                                    {errors.tabStudyProperties && (
                                        <Box my={5}>
                                            <Text fontWeight={400} color="black" _dark={{ color: 'black' }}>
                                                {errorLabels.errorStudyPropertiesTab}
                                            </Text>
                                            <UnorderedList>
                                                {errors.customCodebaseDuplication && (
                                                    <ListItem>{errors.customCodebaseDuplication}</ListItem>
                                                )}
                                            </UnorderedList>
                                        </Box>
                                    )}

                                    {errors.portfolios && (
                                        <Box my={5}>
                                            <Text fontWeight={400} color="black" _dark={{ color: 'black' }}>
                                                {errors.portfolios}
                                            </Text>
                                        </Box>
                                    )}
                                    {formRef?.current?.errors?.studyName && (
                                        <Text fontWeight={400} color="black" _dark={{ color: 'black' }}>
                                            {formRef?.current?.errors?.studyName}
                                        </Text>
                                    )}
                                </AlertDescription>
                            </Box>
                            <CloseButton
                                onClick={() => onErrorBoxToggle.toggle()}
                                position="absolute"
                                right="8px"
                                top="8px"
                            />
                        </Alert>
                    </CenteredWrapper>
                )}

                <Box mt={10}>
                    <Tabs px={0} tabIndex={tabIndex} onChange={handleTabChange}>
                        <CenteredWrapper>
                            <StyledTabList>
                                <StyledTab>
                                    <FormattedMessage id="run_a_study_modules" />
                                    {errors.modules && <WarningIcon />}
                                </StyledTab>
                                <StyledTab>
                                    <FormattedMessage id="run_a_study_variables" />
                                    {errors.tabVariables && <WarningIcon />}
                                </StyledTab>
                                <StyledTab>
                                    <FormattedMessage id="run_a_study_settings" />
                                    {errors.tabSettings && <WarningIcon />}
                                </StyledTab>
                                <StyledTab>
                                    <FormattedMessage id="run_a_study_study_parameters" />
                                    {errors.tabStudyProperties && <WarningIcon />}
                                </StyledTab>
                            </StyledTabList>
                        </CenteredWrapper>

                        <Box
                            bg="background-secondary"
                            borderTop="1px"
                            borderBottom="1px"
                            borderColor="border-secondary"
                        >
                            <CenteredWrapper>
                                <TabPanels>
                                    <TabPanel>
                                        {isLoading.defaultConfigurations ? (
                                            <SimpleGridSkeleton rows={12} cols={4} />
                                        ) : loadingError.defaultConfigurations ? (
                                            <Box m={6}>
                                                <Error
                                                    primaryId="common_error"
                                                    secondaryId="common_loading_error"
                                                    additionalText="modules"
                                                />
                                            </Box>
                                        ) : (
                                            <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                                                {modules.map(({ id, description }) => {
                                                    return (
                                                        <CustomCheckbox
                                                            key={id}
                                                            isChecked={selectedModules.includes(id)}
                                                            onChange={() => toggleSettings(id, 'modules')}
                                                        >
                                                            {description}
                                                        </CustomCheckbox>
                                                    );
                                                })}
                                            </SimpleGrid>
                                        )}
                                    </TabPanel>
                                    <TabPanel>
                                        {isLoading.defaultConfigurations ? (
                                            <SimpleGridSkeleton rows={5} cols={1} />
                                        ) : loadingError.defaultConfigurations ? (
                                            <Box m={6}>
                                                <Error
                                                    primaryId="common_error"
                                                    secondaryId="common_loading_error"
                                                    additionalText="variable groups"
                                                />
                                            </Box>
                                        ) : (
                                            <Flex
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                flexDirection={{ base: 'column', lg: 'row' }}
                                            >
                                                <MultiSelectField
                                                    id="variableGroups"
                                                    name="variableGroups"
                                                    label={intl.formatMessage({ id: 'run_a_study_variables_groups' })}
                                                    placeholderLabel={intl.formatMessage({
                                                        id: 'common_forms_select_option',
                                                    })}
                                                    multipleSelectedLabel={intl.formatMessage({
                                                        id: 'run_a_study_multiple_selected_placeholder',
                                                    })}
                                                    allSelectedLabel={intl.formatMessage({
                                                        id: 'common_all_selected_label',
                                                    })}
                                                    options={variableGroups}
                                                    labelKey="description"
                                                    value={selectedVariableGroups}
                                                    onChange={onVariableGroupSelect}
                                                    isInvalid={errors.variables}
                                                    error={errors.variables}
                                                />

                                                <Button
                                                    mt={6}
                                                    ml={5}
                                                    variant="special-blue"
                                                    onClick={createVariableGroupModal.onOpen}
                                                >
                                                    <FormattedMessage id="run_a_study_create_a_new_group" />
                                                </Button>
                                            </Flex>
                                        )}

                                        <Box mt={5}>
                                            <Accordion allowToggle>
                                                {selectedVariableGroups.map((variableGroup) => (
                                                    <VariableGroup
                                                        key={variableGroup.variableGroupId}
                                                        variableGroup={variableGroup}
                                                        onVariableGroupEdit={onVariableGroupEdit}
                                                        onVariableGroupDelete={onVariableGroupDelete}
                                                        onVariableGroupCopy={onVariableGroupCopy}
                                                        onVariableStatisticEdit={onVariableStatisticEdit}
                                                        onVariableGroupDeselect={onVariableGroupDeselect}
                                                    />
                                                ))}
                                            </Accordion>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel>
                                        {isLoading.defaultConfigurations && Object.keys(settings).length === 0 ? (
                                            <SimpleGridSkeleton rows={12} cols={4} />
                                        ) : (
                                            <SettingsTab
                                                settings={settings}
                                                errors={errors}
                                                toggleSettings={toggleSettings}
                                                selectedPeakPeriods={selectedPeakPeriods}
                                                selectedSensitivity={selectedSensitivity}
                                                selectedTimeIntervals={selectedTimeIntervals}
                                                selectedReports={selectedReports}
                                                runDate={runDate}
                                                setRunDate={setRunDate}
                                                isRunReportsNowChecked={isRunReportsNowChecked}
                                                onIsRunReportsNowChanged={onIsRunReportsNowChanged}
                                                isDeterministicChecked={isDeterministicChecked}
                                                onIsDeterministicChanged={onIsDeterministicChanged}
                                                priority={priority}
                                                setPriority={setPriority}
                                                setTimeStepper={setTimeStepper}
                                                timeStepper={timeStepper}
                                                curvePrice={curvePrice}
                                                setCurvePrice={setCurvePrice}
                                                curveSource={curveSource}
                                                setCurveSource={setCurveSource}
                                                simulations={simulations}
                                                setSimulations={setSimulations}
                                                foSimulations={foSimulations}
                                                setFOSimulations={setFOSimulations}
                                                isFOSimulationsFieldVisible={isFOSimulationsFieldVisible}
                                                isTemplateChecked={isTemplateChecked}
                                                onIsTemplateChanged={onIsTemplateChanged}
                                                isDoNotDeleteChecked={isDoNotDeleteChecked}
                                                onIsDoNotDeleteChanged={onIsDoNotDeleteChanged}
                                                studyType={studyType}
                                                setStudyType={setStudyType}
                                            />
                                        )}
                                    </TabPanel>
                                    <TabPanel>
                                        <StudyPropertiesTab
                                            isLoading={isLoading.defaultConfigurations || templateLoading}
                                            loadingError={loadingError.defaultConfigurations}
                                            properties={studyProperties}
                                            formPropertiesRef={formPropertiesRef}
                                            onDynamicPropsChange={onDynamicPropsChange}
                                        />
                                    </TabPanel>
                                </TabPanels>
                            </CenteredWrapper>
                        </Box>
                    </Tabs>
                </Box>

                <StickyButtonsWrapper>
                    <CenteredWrapper>
                        <Flex justifyContent="flex-end">
                            <Button variant="secondary" textTransform="capitalize" onClick={() => historyPush('/')}>
                                <FormattedMessage id="common_cancel" />
                            </Button>
                            <Button ml={5} onClick={onRunStudyClick}>
                                <FormattedMessage id="run_a_study_run_study" />
                            </Button>
                        </Flex>
                    </CenteredWrapper>
                </StickyButtonsWrapper>
            </MainWrapper>

            {templateLoading && <LoadingModal header={formLabels.loading_template} isOpen />}

            {runStudyConfirmationModal.isOpen && (
                <StudyConfirmationModal
                    onClose={handleRunStudyConfirmationModalOnClose}
                    isFOSimulationsFieldVisible={isFOSimulationsFieldVisible}
                    studyData={{
                        parentStudyInfo: parentStudyInfo,
                        studyResults: studyResults,
                        settings: settings,
                        codebase: codebase,
                        customCodebase: getCustomCodebaseValue(),
                        reports: selectedReports,
                        portfolios: selectedPortfolios,
                        description: formRef.current.values.studyName,
                        parentStudyId: formRef.current.values.useResultsFrom,
                        startDate: formRef.current.values.simulationStart,
                        endDate: formRef.current.values.simulationEnd,
                        notes: formRef.current.values.notes,
                        moduleIds: selectedModules,
                        variableGroups: selectedVariableGroups,
                        ptdPayOff: selectedReports.includes('PTDPAYOFF'),
                        simReports: selectedReports.includes('SIMREPORTS'),
                        studyTypeId: studyType,
                        isTemplate: isTemplateChecked,
                        doNotDelete: isDoNotDeleteChecked,
                        timeStepSizeId: timeStepper,
                        simulations: simulations,
                        loadSimReps: foSimulations,
                        isDeterministicChecked,
                        runSchedule: isRunReportsNowChecked ? new Date() : runDate,
                        priority: priority,
                        curvePriceTypeId: curvePrice,
                        curveSourceId: curveSource,
                        dateIntervalIds: selectedTimeIntervals,
                        peakPeriodIds: selectedPeakPeriods,
                        sensitivityIds: selectedSensitivity,
                        studyProperties: mapUpdatedStudyProperties(
                            studyProperties,
                            formPropertiesRef.current.values.properties
                        ),
                        otherProperties: getDynamicPropValue(
                            'OTHERPARAMS',
                            formPropertiesRef.current.values.properties,
                            defaultStudyProperties
                        ),
                        studyTemplateId: studyTemplateId,
                    }}
                />
            )}

            {selectPortfolioModal.isOpen && (
                <SelectPortfolioModal
                    selectedPortfolios={selectedPortfolios}
                    onSelectPortfolioModalContinue={onSelectPortfolioModalContinue}
                    onClose={selectPortfolioModal.onClose}
                />
            )}

            {createVariableGroupModal.isOpen && (
                <VariableGroupModal
                    onCreateVariableGroupSuccess={onCreateVariableGroupSuccess}
                    onClose={createVariableGroupModal.onClose}
                />
            )}

            {editVariableGroupModal.isOpen && (
                <VariableGroupModal
                    onEditVariableGroupSuccess={onEditVariableGroupSuccess}
                    onClose={editVariableGroupModal.onClose}
                    variableGroup={variableGroupToEdit}
                    actionType="edit"
                    onVariableGroupDelete={onVariableGroupDelete}
                />
            )}

            {deleteVariableGroupModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={onVariableGroupModalClose}
                    header={<FormattedMessage id="run_a_study_delete_variable_group" />}
                    hasExtraStep
                    content={
                        <FormattedMessage
                            id="run_a_study_delete_variable_group_modal_message"
                            values={{ item: variableGroupToDelete.description }}
                        />
                    }
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_delete" />
                        </Box>
                    }
                    onConfirm={onVariableGroupDeleteConfirmed}
                />
            )}
        </>
    );
};

const MainWrapper = styled(Box)`
    position: fixed;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin-bottom: 120px;
`;

const CenteredWrapper = styled(Box)`
    width: 100%;
    max-width: 1314px;
    margin: 0 auto;
    padding: 0 16px;
`;

const StyledTabList = styled(TabList)`
    border-bottom: none;
`;

const StyledTab = styled(Tab)`
    padding: 16px 0;
    margin-right: 24px;
    margin-bottom: 0;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    color: var(--chakra-colors-gray-600);
    border-bottom-width: 4px;

    &:hover {
        color: var(--chakra-colors-blue-400);
    }
`;

const StickyButtonsWrapper = styled(Box)`
    width: 100%;
    position: fixed;
    bottom: 40px;
    min-height: 80px;
    background: var(--chakra-colors-background-tertiary);
    display: flex;
    align-items: center;
`;

export default StudyRun;
