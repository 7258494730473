import { Switch, FormControl, FormLabel, Flex, Box, Center } from '@chakra-ui/react';
import React, { useState, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export const PlotBandControls = ({ setChartOptions, plotBands }) => {
    const [bandState, setBandState] = useState([]);

    const plotBandIds = useMemo(() => {
        return Array.from(new Set(plotBands?.map((band) => band?.id)));
    }, [plotBands]);

    const handleTogglePlotBand = (event, id) => {
        const checked = event?.target?.checked;
        const updatedState = { ...bandState, [id]: checked };
        let removedIds = [];
        for (const key in updatedState) {
            if (!updatedState[key]) {
                removedIds.push(key);
            }
        }
        const filteredPlotBands = plotBands?.filter((b) => !removedIds.includes(b?.id));
        setBandState(updatedState);
        setChartOptions({ xAxis: { plotBands: filteredPlotBands } });
    };

    useEffect(() => {
        setBandState(plotBandIds?.reduce((o, key) => ({ ...o, [key]: true }), {}));
    }, [plotBandIds]);

    return (
        <>
            {plotBandIds && (
                <Flex flexDir="column" mb={2} px={4} gap={2}>
                    {plotBandIds.map((id) => (
                        <Flex key={id} alignItems="center" justifyContent="space-between">
                            <FormControl px={2} display="flex" alignItems="center" justifyContent="space-between">
                                <FormLabel htmlFor={`plot-band-${id}`} mb="0" whiteSpace={'nowrap'}>
                                    <Center>
                                        <FormattedMessage
                                            id="common_show_value_with_potential_multiples"
                                            values={{ value: id }}
                                        />
                                    </Center>
                                </FormLabel>
                                <Switch
                                    id={`plot-band-${id}`}
                                    size="sm"
                                    colorScheme="blue"
                                    isChecked={bandState[id]}
                                    onChange={(event) => handleTogglePlotBand(event, id)}
                                />
                            </FormControl>
                            <Box
                                rounded="full"
                                px={1}
                                h={4}
                                w={4}
                                bg={plotBands?.find((b) => b?.id === id)?.color || 'transparent'}
                            />
                        </Flex>
                    ))}
                </Flex>
            )}
        </>
    );
};
