import axios from 'axios';
import { isEmpty } from '../utils';

export const getElectricNodeListData = async (isoRto) => {
    const response = await axios.get(`/traverse/node-list-data/${isoRto}`);
    return response.data;
};

export const getIsoHubList = async (isoRto) => {
    const response = await axios.get(`/traverse/hub-list/${isoRto}`);
    return response?.data;
};

export const getIsDefaultHubNode = async (iso, hub, node) => {
    if (isEmpty(iso) || isEmpty(hub) || isEmpty(node)) return { isValid: true, statusText: '' };

    const response = await axios.get(`/traverse/check-node/${iso}/${node}?hub=${hub}`);
    return response?.data;
};

export const getIsNodeHistoryValid = async (iso, node) => {
    if (isEmpty(iso) || isEmpty(node)) return { isValid: true, statusText: '' };

    // Note: response1 holds the return data object. Properties of this
    // object may be altered after basis endpoint call

    const response1 = await axios.get(`/traverse/check-node/${iso}/${node}`);

    const histIsValid = response1?.data?.isValid;
    if (!histIsValid) return response1?.data;

    // If traverse node history is valid we then make a call to basis
    // endpoint to check if scalars are present

    const payload = { iso: iso, node: node };
    const response2 = await axios.post(`/basis/validateBasisScalars`, payload, {
        suppressErrorMessageHandler: true,
    });

    if (response2?.data?.isValidScaler === 1) return response1?.data;

    response1.data.isValid = false;
    response1.data.statusText = 'Basis scalars may not be available for this node';
    return response1?.data;
};
