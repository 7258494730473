const FormError = {
    parts: ['text', 'icon'],

    baseStyle: {
        text: {
            color: 'red.900',
            borderColor: 'red.900',
            _dark: {
                color: 'red.800',
                borderColor: 'red.800',
            },
        },
        icon: {
            color: 'red.900',
            borderColor: 'red.900',
            _dark: {
                color: 'red.800',
                borderColor: 'red.800',
            },
        },
    },
};

export default FormError;
