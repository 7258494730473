import { useIntl, FormattedMessage } from 'react-intl';
import { Text } from '@chakra-ui/react';
import NumberInputFormikField from '../forms/NumberInputFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';
import { useFormikContext } from 'formik';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import { useMemo } from 'react';
import InputFormikField from '../forms/InputFormikField';
import DateFormikField from '../forms/DateFormikField';
import { INPUT_TYPES } from '../../constants/fields';

const defaultConfig = {};

/**
 *
 * @param {string} id - input's id
 * @param {string} name - input's name
 * @param {string} label - input's label
 * @param {INPUT_TYPES} inputType - input's type
 * @param {object} gridCellEditorParams - grid cell's settings
 * @param {object} config - containing config for this and nested components
 * @returns {null|*}
 * @constructor
 */
const AutoformBulkUpdateField = ({ id, name, label, inputType, gridCellEditorParams, config = defaultConfig }) => {
    const intl = useIntl();
    const { values: formValues } = useFormikContext();

    const switcherName = useMemo(() => `${name}--switcher`, [name]);
    const formikFieldName = useMemo(() => `bulkUpdate.${name}`, [name]);
    const formikSwitcherFieldName = useMemo(() => `bulkUpdate.${switcherName}`, [switcherName]);
    const formikFieldValue = useMemo(() => formValues?.bulkUpdate?.[name], [formValues?.bulkUpdate, name]);
    const isVariedByRecord = useMemo(
        () => !!formValues?.bulkUpdate?.[switcherName],
        [formValues?.bulkUpdate, switcherName]
    );

    const switcher = useMemo(
        () => (
            <CheckboxFormikField id={`${id}--switcher`} name={formikSwitcherFieldName}>
                <Text as="span" fontSize="12px" color="gray.600">
                    <FormattedMessage id={config?.bulkUpdateSwitcherLabelId ?? 'common_varied_by_record'} />
                </Text>
            </CheckboxFormikField>
        ),
        [id, formikSwitcherFieldName, config]
    );

    switch (inputType) {
        case INPUT_TYPES.TextBox:
            return (
                <>
                    <InputFormikField
                        id={id}
                        name={formikFieldName}
                        label={label}
                        placeholder={intl.formatMessage({
                            id:
                                isVariedByRecord && formikFieldValue !== null
                                    ? 'hedge_select_multiple'
                                    : 'common_add_content',
                        })}
                        showPlaceholderIfDisabled
                        isDisabled={isVariedByRecord}
                        isRequired={gridCellEditorParams?.required}
                        type="text"
                    />

                    {switcher}
                </>
            );
        case INPUT_TYPES.ComboBox:
            return (
                <>
                    <ComboboxFormikField
                        id={id}
                        name={formikFieldName}
                        label={label}
                        options={gridCellEditorParams?.options ?? []}
                        valueKey="id"
                        labelKey="description"
                        showPlaceholderIfDisabled
                        isDisabled={isVariedByRecord}
                        showPlaceholder={true}
                        placeholderValue={null}
                        placeholderLabel={isVariedByRecord ? intl.formatMessage({ id: 'hedge_select_multiple' }) : '-'}
                        isRequired={gridCellEditorParams?.required}
                    />

                    {switcher}
                </>
            );
        case INPUT_TYPES.NumericUpDown:
            return (
                <>
                    <NumberInputFormikField
                        id={id}
                        name={formikFieldName}
                        label={label}
                        showPlaceholderIfDisabled
                        isDisabled={isVariedByRecord}
                        placeholder={intl.formatMessage({
                            id:
                                isVariedByRecord && formikFieldValue !== null
                                    ? 'hedge_select_multiple'
                                    : 'common_select_number',
                        })}
                        isRequired={gridCellEditorParams?.required}
                        clampValueOnBlur={false}
                        keepWithinRange={false}
                        min={gridCellEditorParams?.min ?? undefined} // Chakra treats min={null} as min={0}
                        max={gridCellEditorParams?.max ?? undefined} // Chakra treats max={null} as max={0}
                    />

                    {switcher}
                </>
            );
        case INPUT_TYPES.CheckBox:
            return (
                <>
                    <ComboboxFormikField
                        id={id}
                        name={formikFieldName}
                        label={label}
                        options={[
                            { value: 1, label: 'True' },
                            { value: 0, label: 'False' },
                        ]}
                        valueKey="value"
                        labelKey="label"
                        showPlaceholderIfDisabled
                        isDisabled={isVariedByRecord}
                        showPlaceholder={isVariedByRecord}
                        placeholderValue={null}
                        placeholderLabel={isVariedByRecord ? intl.formatMessage({ id: 'hedge_select_multiple' }) : '-'}
                    />

                    {switcher}
                </>
            );
        case INPUT_TYPES.MonthCalendar:
            return (
                <>
                    <DateFormikField
                        id={id}
                        name={formikFieldName}
                        label={label}
                        placeholderText={intl.formatMessage({
                            id:
                                isVariedByRecord && formikFieldValue !== null
                                    ? 'hedge_select_multiple'
                                    : 'common_select_date',
                        })}
                        showPlaceholderIfDisabled
                        isDisabled={isVariedByRecord}
                        dateFormat={gridCellEditorParams?.dateFormat}
                        showTimeInput={gridCellEditorParams?.showTimeInput}
                        minDate={gridCellEditorParams?.minDate}
                        maxDate={gridCellEditorParams?.maxDate}
                    />
                    {switcher}
                </>
            );
        default:
            return null;
    }
};

export default AutoformBulkUpdateField;
