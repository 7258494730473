import CustomCheckbox from './CustomCheckbox';
import FormField from './FormField';

// If you need to extend CustomCheckbox functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for CustomCheckbox should be excluded from ...props and manually added
const CheckboxField = ({
    onChange,
    children,
    isChecked,
    isDisabled,
    isInvalid,
    isWarning,
    isIndeterminate,
    ...props
}) => {
    const { id, name } = { ...props };
    const inputIdForLabel = id ?? name;

    return (
        <FormField {...props} isDisabled={isDisabled}>
            <CustomCheckbox
                id={inputIdForLabel}
                onChange={onChange}
                isChecked={isChecked}
                isDisabled={isDisabled}
                isInvalid={isInvalid}
                isIndeterminate={isIndeterminate}
                isWarning={isWarning}
            >
                {children}
            </CustomCheckbox>
        </FormField>
    );
};

export default CheckboxField;
