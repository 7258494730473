import { Alert, AlertIcon, AlertTitle, AlertDescription, Box } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

const MessageBox = ({ primaryId, secondaryId, additionalText, status, ...rest }) => {
    //Returns a component with a custom status
    return (
        <Alert status={status} {...rest}>
            <AlertIcon />

            <Box>
                {primaryId && (
                    <AlertTitle textTransform="capitalize">
                        <FormattedMessage id={primaryId} />
                    </AlertTitle>
                )}

                <AlertDescription>
                    <FormattedMessage id={secondaryId} values={{ items: additionalText }} />
                </AlertDescription>
            </Box>
        </Alert>
    );
};

export default MessageBox;
