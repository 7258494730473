import axios from 'axios';
import { format, parseISO } from 'date-fns';

import { formatChartData } from '../items';

export const GRID_KEYS = {
    loadData: 'loadData',
    loadForecast: 'loadForecast',
    loadModel: 'loadModel',
};

export const getLoadDefinitions = async (itemId) => {
    const response = await axios.get(`/loads/${itemId}/sub-loads`, { suppressErrorMessageHandler: true });
    return response.data;
};

export const createLoadDefinition = async (payload) => {
    const response = await axios.post(`/loads/sub-load`, payload);
    return response.data;
};

export const deleteLoadDefinition = (subloadId) => axios.delete(`/loads/${subloadId}`);

export const formatDate = (str) => format(parseISO(str), 'Pp');

export const editLoad = async (payload) => {
    const response = await axios.put('/loads/modify-load-sub-loads', payload);

    if (response?.status === axios.HttpStatusCode.MultiStatus) {
        // Status 207 MultiStatus
        const multiStatus = response?.data?.multiStatus;
        if (Array.isArray(multiStatus)) {
            // No need to check for axios.HttpStatusCode.NoContent
            // Status 200 OK
            const data = multiStatus.find((s) => s.statusCode === axios.HttpStatusCode.Ok)?.value;
            // Status 424 FailedDependency
            const arcGisIssue = multiStatus.find(
                (s) => s.statusCode === axios.HttpStatusCode.FailedDependency && s?.value?.isArcGisError
            )?.value?.error;
            return { data, arcGisIssue };
        }
    }

    return response;
};

export const getLoadData = async (loadId, payload) => {
    const response = await axios.post(`/loads/${loadId}/load-data`, payload, { suppressErrorMessageHandler: true });

    return response.data;
};

export const getLoadDataDataSourceView = async (loadId, payload) => {
    const response = await axios.post(`/loads/${loadId}/load-data/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getLoadForecast = async (loadId, payload) => {
    const response = await axios.post(`/loads/${loadId}/load-forecast`, payload, { suppressErrorMessageHandler: true });

    return response.data;
};

export const getLoadForecastDataSourceView = async (loadId, payload) => {
    const response = await axios.post(`/loads/${loadId}/load-forecast/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getLoadModel = async (loadId, payload) => {
    const response = await axios.post(`/loads/${loadId}/load-model`, payload, { suppressErrorMessageHandler: true });

    return response.data;
};

export const getLoadModelDataSourceView = async (loadId, payload) => {
    const response = await axios.post(`/loads/${loadId}/load-model/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const updateLoadData = (subLoadId, payload) => axios.put(`/loads/${subLoadId}/load-data`, payload);

export const updateLoadForecast = (subLoadId, payload) => axios.put(`/loads/${subLoadId}/load-forecasts`, payload);

export const updateLoadModel = (subLoadId, payload) => axios.put(`/loads/${subLoadId}/load-models`, payload);

export const getLoadDataChart = async (loadId, payload) => {
    const response = await axios.post(`/loads/${loadId}/load-data-sampling`, payload, {
        suppressErrorMessageHandler: true,
    });

    return formatChartData(response.data);
};

export const getLoadForecastChart = async (loadId, payload) => {
    const response = await axios.post(`/loads/${loadId}/load-forecast-sampling`, payload, {
        suppressErrorMessageHandler: true,
    });
    return formatChartData(response.data);
};

export const createLoadData = (subLoadId, payload) => axios.post(`/loads/${subLoadId}/create-load-data`, payload);

export const createLoadForecasts = (subLoadId, payload) =>
    axios.post(`/loads/${subLoadId}/create-load-forecasts`, payload);

export const createLoadModels = (subLoadId, payload) => axios.post(`/loads/${subLoadId}/create-load-models`, payload);

export const deleteLoadData = (subLoadId, payload) =>
    axios.delete(`/loads/${subLoadId}/load-data`, { data: payload, suppressErrorMessageHandler: true });

export const deleteLoadForecasts = (subLoadId, payload) =>
    axios.delete(`/loads/${subLoadId}/load-forecasts`, { data: payload, suppressErrorMessageHandler: true });

export const deleteLoadModels = (subLoadId, payload) =>
    axios.delete(`/loads/${subLoadId}/load-models`, { data: payload, suppressErrorMessageHandler: true });

export const deleteAllLoadData = (subLoadId) =>
    axios.delete(`/loads/${subLoadId}/delete-all-load-data`, { suppressErrorMessageHandler: true });

export const deleteAllLoadForecasts = (subLoadId) =>
    axios.delete(`/loads/${subLoadId}/delete-all-load-forecasts`, { suppressErrorMessageHandler: true });

export const deleteAllLoadModels = (subLoadId) =>
    axios.delete(`/loads/${subLoadId}/delete-all-load-models`, { suppressErrorMessageHandler: true });

export const validateLoadData = async (subLoadId, payload) => {
    const response = await axios.post(`/loads/${subLoadId}/validate/load-data`, payload);
    return response.data;
};

export const validateLoadForecasts = async (subLoadId, payload) => {
    const response = await axios.post(`/loads/${subLoadId}/validate/load-forecast`, payload);
    return response.data;
};

export const validateLoadModels = async (subLoadId, payload) => {
    const response = await axios.post(`/loads/${subLoadId}/validate/load-model`, payload);
    return response.data;
};
