import { Text, Flex } from '@chakra-ui/react';

const DataGridIssueMessage = ({ severity, message }) => (
    <Flex mb={3}>
        <Text
            textTransform="uppercase"
            as="span"
            color={severity === 'Error' ? 'red.1000' : 'yellow.500'}
            _dark={{ color: severity === 'Error' ? 'red.1000' : 'yellow.500' }}
            mr={1}
            size="xs"
        >
            {severity}:
        </Text>

        <Text as="span" color="gray.800" size="xs" variant="regular" wordBreak="break-word">
            {message}
        </Text>
    </Flex>
);

export default DataGridIssueMessage;
