import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@chakra-ui/react';

import Level1Autoform from './Level1Autoform';
import Level2Autoform from './Level2Autoform';
import Level3Autoform from './Level3Autoform';
import Level4Autoform from './Level4Autoform';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import Error from '../utils/Error';
import AutoformUiProvider from './AutoformUiProvider';

import { resetSortAndPagination } from '../../store/autoform/autoformSlice';

import useAutoformMetadata from './hooks/useAutoformMetadata';
import useAutoformReloadItemData from './hooks/useAutoformReloadItemData';
import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';

/**
 * Component that renders the appropriate autoform level UI for different maxTableHierarchy values.
 * Used in the AutoformPage as well as in the Portfolios screen.
 *
 * @param {object[]} onNodeRename - Column definitions compatible with AG Grid. Defaults
 * are provided by the hook useAutoformColumnDefinitions
 * @param {string} tableId - Id of the table displayed in the AG Grid.
 * @param {callback} onNodeRename - Optional. Callback to rename the portfolio item in the portfolios screen.
 * @param {callback} onNodeItemDelete - Optional. Callback to delete the portfolio item in the portfolios screen.
 * @param {callback} onNodeOwnershipPercentageUpdate - Optional. Callback to change the percent ownership
 * of the item in the portfolio in the portfolios screen.
 * @param {object} config - Object allowing to override specific sections of the Autoform engine with custom
 * functionality
 */
const Autoform = ({
    onNodeRename,
    onNodeItemDelete,
    portfolioItem,
    onNodeOwnershipPercentageUpdate,
    helpPageUrlItemTypeId = null,
    config = {},
}) => {
    const dispatch = useDispatch();
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const level1TableId = hierarchicalTables.level1[0]?.id;
    const itemSidebarView =
        metadata?.maxTableHierarchyLevel > 1 || !(hierarchicalTables.level1[0]?.showAsGridHint ?? true);
    const { selectedItem: selectedItemId } = useAutoformTableMetadata(level1TableId);

    useEffect(() => {
        if (level1TableId) {
            dispatch(resetSortAndPagination());
        }
    }, [dispatch, metadata.maxTableHierarchyLevel, level1TableId]);

    const { isLoading, loadingError } = useAutoformReloadItemData(
        level1TableId,
        itemSidebarView ? selectedItemId : undefined
    );

    if (loadingError)
        return (
            <Box m={6}>
                <Error primaryId="common_error" secondaryId="common_loading_error" />
            </Box>
        );
    if ((itemSidebarView && isLoading) || !metadata) return <DefinitionsSkeleton />;

    return (
        <AutoformUiProvider>
            {metadata.maxTableHierarchyLevel === 1 && (
                <Level1Autoform
                    helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                    config={config}
                    itemId={selectedItemId}
                    portfolioItem={portfolioItem}
                    onNodeRename={onNodeRename}
                    onNodeItemDelete={onNodeItemDelete}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                />
            )}
            {metadata.maxTableHierarchyLevel === 2 && (
                <Level2Autoform
                    helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                    config={config}
                    portfolioItem={portfolioItem}
                    onNodeRename={onNodeRename}
                    onNodeItemDelete={onNodeItemDelete}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                />
            )}
            {metadata.maxTableHierarchyLevel === 3 && (
                <Level3Autoform
                    helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                    config={config}
                    portfolioItem={portfolioItem}
                    onNodeRename={onNodeRename}
                    onNodeItemDelete={onNodeItemDelete}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                />
            )}
            {metadata.maxTableHierarchyLevel === 4 && (
                <Level4Autoform
                    helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                    config={config}
                    portfolioItem={portfolioItem}
                    onNodeRename={onNodeRename}
                    onNodeItemDelete={onNodeItemDelete}
                    onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                />
            )}
        </AutoformUiProvider>
    );
};

export default Autoform;
