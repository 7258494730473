import { useState, useEffect, useRef } from 'react';
import { SimpleGrid, Flex, Text, Box, useDisclosure } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import useDebounce from '../../hooks/useDebounce';

import ComboboxField from '../forms/ComboboxField';
import CheckboxField from '../forms/CheckboxField';

import SecondaryButton from '../utils/SecondaryButton';
import AddItemButton from './../utils/AddItemButton';

import SectionHint from './SectionHint';
import BasicModelModal from './BasicModelModal';
import BasisConfigDropdownButtonGroup from './BasisConfigDropdownButtonGroup';

import { ReactComponent as AddIcon } from './../../icons/add.svg';

const ModelDefinitionsSection = ({ basis, options, status, updateBasis, onFinish }) => {
    const intl = useIntl();

    const [isCheckedValues, setIsCheckedValues] = useState({
        lmpFlag: basis.lmpFlag,
        forwardSim: basis.forwardSim,
        hourlySim: basis.hourlySim,
        dailySim: basis.dailySim,
    });

    const editBasicModelId = useRef();
    const isComponentMounted = useRef(false);

    const debouncedValue = useDebounce(isCheckedValues, 250);
    const basicModelModal = useDisclosure();

    const onModelDefinitionsOptionChange = (field, value) => {
        updateBasis({ ...basis, [field]: value });
    };

    useEffect(() => {
        if (isComponentMounted.current) {
            updateBasis({ ...basis, ...isCheckedValues });
        } else {
            isComponentMounted.current = true;
        }
        // need only debauncedValue as a dependency
        // ESLint requires other vars which are part of the useEffect as dependencies as well thus disabling the rule
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    // @TranslationWarning
    const basisModelTypeOptionId = options.basisModelTypes.find(
        (modelType) => modelType.description === 'Basic Model'
    ).id;

    const onBasicModelEdit = () => {
        editBasicModelId.current = basis.basicModelId;
        basicModelModal.onOpen();
    };

    const onBasicModelEditCompleted = () => {
        editBasicModelId.current = undefined;
        onFinish();
    };

    const onBasicModelClose = () => {
        editBasicModelId.current = undefined;
        basicModelModal.onClose();
    };

    return (
        <>
            <SectionHint hintHeader="hint_heading_model_definition" hintText="hint_text_model_definition" />

            <SimpleGrid pb={5} px={{ base: 5, sm: 0 }} columns={{ md: 3 }} spacingX={6} spacingY={6}>
                <ComboboxField
                    id="basisModelTypeId"
                    name="basisModelTypeId"
                    options={options.basisModelTypes}
                    valueKey="id"
                    labelKey="description"
                    value={basis.basisModelTypeId}
                    onChange={(value) => onModelDefinitionsOptionChange('basisModelTypeId', value)}
                    label={intl.formatMessage({ id: 'basis_configurator_basis_model_type' })}
                    showPlaceholder={true}
                    isInvalid={status.basisModelTypeId?.errors.length}
                    isWarning={status.basisModelTypeId?.warnings.length}
                    status={status.basisModelTypeId}
                />

                <Box>
                    <BasisConfigDropdownButtonGroup>
                        <ComboboxField
                            id="basicModelId"
                            name="basicModelId"
                            options={options.basicModels}
                            valueKey="id"
                            labelKey="description"
                            value={basis.basicModelId}
                            onChange={(value) => onModelDefinitionsOptionChange('basicModelId', value)}
                            isDisabled={basis.basisModelTypeId !== basisModelTypeOptionId}
                            label={intl.formatMessage({
                                id: 'basis_configurator_basis_model',
                            })}
                            showPlaceholder={true}
                            isInvalid={status.basicModelId?.errors.length}
                            isWarning={status.basicModelId?.warnings.length}
                            status={status.basicModelId}
                        />

                        <SecondaryButton
                            size="sm"
                            type="button"
                            variant="secondary"
                            onClick={onBasicModelEdit}
                            w={{ sm: 'auto' }}
                            isDisabled={!basis.basicModelId}
                        >
                            <FormattedMessage id="basis_configurator_view_edit" />
                        </SecondaryButton>
                    </BasisConfigDropdownButtonGroup>

                    <AddItemButton
                        leftIcon={<AddIcon />}
                        onClick={() => basicModelModal.onOpen()}
                        mb="0 !Important"
                        pl="5px !Important"
                        size="sm"
                    >
                        <Text size="sm" color="gray.900" pl={1} pt={1}>
                            <FormattedMessage id="basis_configurator_add_new_basic_model" />
                        </Text>
                    </AddItemButton>
                </Box>

                <ComboboxField
                    id="moduleProcessId"
                    name="moduleProcessId"
                    options={options.moduleProcesses}
                    valueKey="id"
                    labelKey="description"
                    value={basis.moduleProcessId}
                    onChange={(value) => onModelDefinitionsOptionChange('moduleProcessId', value)}
                    label={intl.formatMessage({ id: 'basis_configurator_module_process' })}
                    showPlaceholder={true}
                    isInvalid={status.moduleProcessId?.errors.length}
                    isWarning={status.moduleProcessId?.warnings.length}
                    status={status.moduleProcessId}
                />

                <ComboboxField
                    id="modelWeightId"
                    name="modelWeightId"
                    options={options.modelWeights}
                    valueKey="id"
                    labelKey="description"
                    value={basis.modelWeightId}
                    onChange={(value) => onModelDefinitionsOptionChange('modelWeightId', value)}
                    label={intl.formatMessage({ id: 'basis_configurator_model_weight' })}
                    showPlaceholder={true}
                    isInvalid={status.modelWeightId?.errors.length}
                    isWarning={status.modelWeightId?.warnings.length}
                    status={status.modelWeightId}
                />

                <ComboboxField
                    id="transformMethodId"
                    name="transformMethodId"
                    options={options.transformMethods}
                    valueKey="id"
                    labelKey="description"
                    value={basis.transformMethodId}
                    onChange={(value) => onModelDefinitionsOptionChange('transformMethodId', value)}
                    label={intl.formatMessage({ id: 'basis_configurator_transform_method' })}
                    showPlaceholder={true}
                    isInvalid={status.transformMethodId?.errors.length}
                    isWarning={status.transformMethodId?.warnings.length}
                    status={status.transformMethodId}
                />
            </SimpleGrid>

            <Flex flexDirection={{ base: 'column', sm: 'row' }} mb={3}>
                <CheckboxField
                    isChecked={isCheckedValues.lmpFlag}
                    onChange={(event) => setIsCheckedValues((prev) => ({ ...prev, lmpFlag: event.target.checked }))}
                    ml={{ base: 5, md: 0 }}
                    width="auto"
                    isInvalid={status.lmpFlag?.errors.length}
                    isWarning={status.lmpFlag?.warnings.length}
                    status={status.lmpFlag}
                >
                    <Text size="sm">
                        <FormattedMessage id="basis_configurator_full_destination" />
                    </Text>
                </CheckboxField>

                <CheckboxField
                    isChecked={isCheckedValues.hourlySim}
                    onChange={(event) => setIsCheckedValues((prev) => ({ ...prev, hourlySim: event.target.checked }))}
                    ml={5}
                    width="auto"
                    isInvalid={status.hourlySim?.errors.length}
                    isWarning={status.hourlySim?.warnings.length}
                    status={status.hourlySim}
                >
                    <Text size="sm">
                        <FormattedMessage id="basis_configurator_hourly_sim" />
                    </Text>
                </CheckboxField>

                <CheckboxField
                    isChecked={isCheckedValues.forwardSim}
                    onChange={(event) => setIsCheckedValues((prev) => ({ ...prev, forwardSim: event.target.checked }))}
                    ml={5}
                    width="auto"
                    isInvalid={status.forwardSim?.errors.length}
                    isWarning={status.forwardSim?.warnings.length}
                    status={status.forwardSim}
                >
                    <Text size="sm">
                        <FormattedMessage id="basis_configurator_forward_sim" />
                    </Text>
                </CheckboxField>

                <CheckboxField
                    isChecked={isCheckedValues.dailySim}
                    onChange={(event) => setIsCheckedValues((prev) => ({ ...prev, dailySim: event.target.checked }))}
                    ml={5}
                    width="auto"
                    isInvalid={status.dailySim?.errors.length}
                    isWarning={status.dailySim?.warnings.length}
                    status={status.dailySim}
                >
                    <Text size="sm">
                        <FormattedMessage id="basis_configurator_daily_sim" />
                    </Text>
                </CheckboxField>
            </Flex>

            {basicModelModal.isOpen && (
                <BasicModelModal
                    isOpen
                    basisId={basis.basisId}
                    basicModelId={editBasicModelId.current}
                    onClose={onBasicModelClose}
                    onFinish={onBasicModelEditCompleted}
                />
            )}
        </>
    );
};

export default ModelDefinitionsSection;
