import axios from 'axios';

export const GRID_KEYS = {
    renewableData: 'renewableData',
    renewableForecast: 'renewableForecast',
    renewableCosts: 'renewableCosts',
    renewableAncillaries: 'renewableAncillaries',
    renewableModel: 'renewableModel',
};

export const getSubRenewables = async (itemId) => {
    const response = await axios.get(`/renewables/${itemId}/sub-renewables`, { suppressErrorMessageHandler: true });
    return response.data;
};

export const editRenewable = async (payload) => {
    const response = await axios.put('/renewables/modify-renewable-sub-renewables', payload);

    if (response?.status === axios.HttpStatusCode.MultiStatus) {
        // Status 207 MultiStatus
        const multiStatus = response?.data?.multiStatus;
        if (Array.isArray(multiStatus)) {
            // No need to check for axios.HttpStatusCode.NoContent
            // Status 200 OK
            const data = multiStatus.find((s) => s.statusCode === axios.HttpStatusCode.Ok)?.value;
            // Status 424 FailedDependency
            const arcGisIssue = multiStatus.find(
                (s) => s.statusCode === axios.HttpStatusCode.FailedDependency && s?.value?.isArcGisError
            )?.value?.error;
            return { data, arcGisIssue };
        }
    }

    return response;
};

export const createRenewableDefinition = async (payload) => {
    const response = await axios.post(`/renewables/sub-renewable`, payload);
    return response.data;
};

export const deleteSubRenewable = (subRenewableId) => axios.delete(`/renewables/${subRenewableId}`);

export const getRenewableDataChart = async (renewableId, payload) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-chart-data`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getRenewableForecastChart = async (renewableId, payload) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-chart-forecast`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getRenewableData = async (renewableId, payload) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-data`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getRenewableDataDataSourceView = async (renewableId, payload) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-data/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getRenewableForecast = async (renewableId, payload) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-forecast`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getRenewableForecastDataSourceView = async (renewableId, payload) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-forecast/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getRenewableCosts = async (renewableId, payload) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-cost`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getRenewableCostsDataSourceView = async (renewableId, payload) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-cost/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getRenewableAncillaries = async (renewableId, payload) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-ancillary`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getRenewableAncillariesDataSourceView = async (renewableId, payload) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-ancillary/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getRenewableModels = async (renewableId, payload) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-model`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getRenewableModelsDataSourceView = async (renewableId, pagination) => {
    const response = await axios.post(`/renewables/${renewableId}/renewable-model/datasourceview`, pagination, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const updateRenewableData = (subRenewableId, payload) =>
    axios.put(`/renewables/${subRenewableId}/renewable-data`, payload, { suppressErrorMessageHandler: true });

export const updateRenewableForecast = (subRenewableId, payload) =>
    axios.put(`/renewables/${subRenewableId}/renewable-forecasts`, payload);

export const updateRenewableCosts = (subRenewableId, payload) =>
    axios.put(`/renewables/${subRenewableId}/renewable-costs`, payload, { suppressErrorMessageHandler: true });

export const updateRenewableAncillaries = (subRenewableId, payload) =>
    axios.put(`/renewables/${subRenewableId}/renewable-ancillaries`, payload, { suppressErrorMessageHandler: true });

export const updateRenewableModels = (subRenewableId, payload) =>
    axios.put(`/renewables/${subRenewableId}/renewable-models`, payload, { suppressErrorMessageHandler: true });

export const createRenewableData = (subRenewableId, payload) =>
    axios.post(`/renewables/${subRenewableId}/create-renewable-data`, payload, { suppressErrorMessageHandler: true });

export const validateRenewableData = async (subRenewableId, payload) => {
    const response = await axios.post(`/renewables/${subRenewableId}/validate/create-renewable-data`, payload);
    return response?.data;
};

export const createRenewableForecasts = (subRenewableId, payload) =>
    axios.post(`/renewables/${subRenewableId}/create-renewable-forecast`, payload);

export const validateRenewableForecasts = async (subRenewableId, payload) => {
    const response = await axios.post(`/renewables/${subRenewableId}/validate/create-renewable-forecast`, payload);
    return response.data;
};

export const createRenewableCosts = (subRenewableId, payload) =>
    axios.post(`/renewables/${subRenewableId}/create-renewable-costs`, payload, { suppressErrorMessageHandler: true });

export const createRenewableAncillaries = (subRenewableId, payload) =>
    axios.post(`/renewables/${subRenewableId}/create-renewable-ancillaries`, payload, {
        suppressErrorMessageHandler: true,
    });

export const createRenewableModels = (subRenewableId, payload) =>
    axios.post(`/renewables/${subRenewableId}/create-renewable-models`, payload, { suppressErrorMessageHandler: true });

export const deleteRenewableData = (renewableId, payload) =>
    axios.delete(`/renewables/${renewableId}/renewable-data`, { data: payload, suppressErrorMessageHandler: true });

export const deleteRenewableForecasts = (renewableId, payload) =>
    axios.delete(`/renewables/${renewableId}/renewable-forecasts`, {
        data: payload,
        suppressErrorMessageHandler: true,
    });

export const deleteRenewableCosts = (renewableId, payload) =>
    axios.delete(`/renewables/${renewableId}/renewable-costs`, { data: payload, suppressErrorMessageHandler: true });

export const deleteRenewableAncillaries = (renewableId, payload) =>
    axios.delete(`/renewables/${renewableId}/renewable-ancillaries`, {
        data: payload,
        suppressErrorMessageHandler: true,
    });

export const deleteRenewableModels = (renewableId, payload) =>
    axios.delete(`/renewables/${renewableId}/renewable-models`, { data: payload, suppressErrorMessageHandler: true });

export const deleteAllRenewableData = (renewableId) =>
    axios.delete(`/renewables/${renewableId}/delete-all-renewable-data`, { suppressErrorMessageHandler: true });

export const deleteAllRenewableForecasts = (renewableId) =>
    axios.delete(`/renewables/${renewableId}/delete-all-renewable-forecasts`, { suppressErrorMessageHandler: true });

export const deleteAllRenewableCosts = (renewableId) =>
    axios.delete(`/renewables/${renewableId}/delete-all-renewable-costs`, { suppressErrorMessageHandler: true });

export const deleteAllRenewableAncillaries = (renewableId) =>
    axios.delete(`/renewables/${renewableId}/delete-all-renewable-ancillaries`, { suppressErrorMessageHandler: true });

export const deleteAllRenewableModels = (renewableId) =>
    axios.delete(`/renewables/${renewableId}/delete-all-renewable-models`, { suppressErrorMessageHandler: true });
