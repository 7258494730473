import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, setNestedObjectValues } from 'formik';
import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';

import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';

import AutoformItemFields from './AutoformItemFields';
import GeneralValidationPanel from './GeneralValidationPanel';
import {
    getAddRecordModalInitialFormValues,
    mapGeneralValidationMessages,
    getAddRecordModalValidationSettings,
    mapValidationMessagesToFormikStatus,
} from './utils/autoformUtils';
import useCommonToast from '../../hooks/useCommonToast';
import useAutoformFormValidation from './hooks/useAutoformFormValidation';
import { isSubmitDisabled } from 'services/utils';

/**
 * Add New Autoform Record Modal to add a record for records that are not displayed as a grid.
 *
 * @param {object[]} onNodeRename - Column definitions compatible with AG Grid. Defaults
 * are provided by the hook useAutoformColumnDefinitions
 * @param {string} tableId - Id of the table to add records to.
 * @param {string} schemaCode - Schema code of the autoform.
 * @param {string} itemType - The type of the record being created. Item/Sub-item.
 * @param {callback} onCreateRecord - Callback that does the async logic of creating an
 * item in the BE.
 * @param {object} config - containing config for this and nested components
 */
const AutoformAddRecordModal = ({ tableId, schemaCode, itemType, onCreateRecord, onCheckData, config, ...rest }) => {
    const { sortedColumns, metadata } = useAutoformTableMetadata(tableId);
    const bookId = useSelector((state) => state.connections.book);

    const [generalErrors, setGeneralErrors] = useState([]);
    const [generalWarnings, setGeneralWarnings] = useState([]);
    const [isCheckingData, setIsCheckingData] = useState(false);

    const { toast } = useCommonToast();
    const intl = useIntl();

    const initialFormValues = useMemo(() => {
        const defaultColumnValues = getAddRecordModalInitialFormValues(sortedColumns);
        if (defaultColumnValues?.BOOKID != null) {
            // Default to connection's book id
            defaultColumnValues.BOOKID = bookId;
        }
        return defaultColumnValues;
    }, [sortedColumns, bookId]);

    const validationSettings = useMemo(
        () => getAddRecordModalValidationSettings(metadata, sortedColumns),
        [metadata, sortedColumns]
    );

    const { onValidate } = useAutoformFormValidation({
        validationSettings,
    });

    const handleErrorsAndWarnings = (issues, setStatus, setTouched) => {
        const status = mapValidationMessagesToFormikStatus(issues[0].fields, (columnName) => columnName);
        const { errors: generalErrors, warnings: generalWarnings } = mapGeneralValidationMessages(
            issues[0].generalValidationMessages
        );

        setGeneralErrors(generalErrors);
        setGeneralWarnings(generalWarnings);

        setStatus(status);
        setTouched(setNestedObjectValues(status, true));
    };

    const checkData = async (payload, { validateForm, setStatus, setTouched }) => {
        const errors = await validateForm();
        if (Object.keys(errors).length > 0) {
            setTouched(setNestedObjectValues(errors, true));
        } else {
            setIsCheckingData(true);

            try {
                const responseData = await onCheckData(payload);
                if (responseData?.issues) {
                    handleErrorsAndWarnings(responseData.issues, setStatus, setTouched);
                } else {
                    setStatus({});
                    toast(intl.formatMessage({ id: 'common_check_data_success' }));
                }
            } catch (error) {
                if (error.response?.data?.issues) {
                    handleErrorsAndWarnings(error.response.data.issues, setStatus, setTouched);
                }
            }
            setIsCheckingData(false);
        }
    };
    const onFormSubmit = async (payload, { setStatus, setTouched }) => {
        try {
            const responseData = await onCreateRecord(payload);
            if (responseData?.issues) {
                handleErrorsAndWarnings(responseData.issues, setStatus, setTouched);
            }
            rest.onClose && rest.onClose();
        } catch (error) {
            if (error.issues && error.issues[0]?.fields) {
                handleErrorsAndWarnings(error.issues, setStatus, setTouched);
            }
        }
    };

    return (
        <Modal {...rest}>
            <ModalOverlay />

            <ModalContent pt={3}>
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="autoform_add_new_item" values={{ item: itemType }} />
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik initialValues={initialFormValues} validate={onValidate} onSubmit={onFormSubmit}>
                    {({ isSubmitting, errors, touched, values, validateForm, setStatus, setTouched }) => {
                        const isDisabled = isSubmitDisabled({ errors, touched });

                        return (
                            <Form>
                                <ModalBody borderBottom="1px" borderColor="border-secondary">
                                    <GeneralValidationPanel errors={generalErrors} warnings={generalWarnings} />
                                    {config?.addRecordFields ? (
                                        config.addRecordFields
                                    ) : (
                                        <AutoformItemFields
                                            tableId={tableId}
                                            skipDescriptionColumnName={false}
                                            getInputFormName={(_, __, columnName) => columnName}
                                            mode="add"
                                        />
                                    )}
                                </ModalBody>

                                <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                    {onCheckData && (
                                        <Button
                                            isLoading={isCheckingData}
                                            variant="secondary"
                                            onClick={() => checkData(values, { validateForm, setStatus, setTouched })}
                                            isDisabled={isDisabled}
                                        >
                                            <Box as="span" textTransform="capitalize">
                                                <FormattedMessage id="common_check_data" />
                                            </Box>
                                        </Button>
                                    )}

                                    <Box ml="auto">
                                        <Button variant="secondary" onClick={rest.onClose}>
                                            <Box as="span" textTransform="capitalize">
                                                <FormattedMessage id="common_cancel" />
                                            </Box>
                                        </Button>

                                        <Button type="submit" isDisabled={isDisabled} isLoading={isSubmitting} ml={3}>
                                            <FormattedMessage id="common_item_create_btn" />
                                        </Button>
                                    </Box>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default AutoformAddRecordModal;
