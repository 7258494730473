import { isValid } from 'date-fns';
import { INPUT_TYPES } from '../../constants/fields';

export const mapDynamicProperties = (properties) => {
    const groups = {};

    properties.forEach((property) => {
        groups['group_' + property.groupId] = {};

        property.properties.forEach((currentProperty) => {
            let initialPropertyValue = currentProperty.selectedValue;

            if (currentProperty.type === INPUT_TYPES.MonthCalendar && initialPropertyValue !== null) {
                initialPropertyValue = isValid(new Date(initialPropertyValue)) ? new Date(initialPropertyValue) : null;
            }

            if (currentProperty.type === INPUT_TYPES.CheckBox && initialPropertyValue !== null) {
                const truthyValues = ['true', true, '1', 1];
                const falsyValues = ['false', false, '0', 0];

                if (truthyValues.includes(initialPropertyValue)) {
                    initialPropertyValue = true;
                }

                if (falsyValues.includes(initialPropertyValue)) {
                    initialPropertyValue = false;
                }
            }

            if (initialPropertyValue === null && currentProperty.type !== INPUT_TYPES.CheckBox) {
                initialPropertyValue = '';
            }

            if (initialPropertyValue === null && currentProperty.type === INPUT_TYPES.CheckBox) {
                initialPropertyValue = false;
            }

            if (currentProperty.type === INPUT_TYPES.CheckListBox) {
                // Array of selected options keys
                initialPropertyValue = initialPropertyValue ? initialPropertyValue.split(',') : [];
            }

            groups['group_' + property.groupId]['field_' + currentProperty.id] = initialPropertyValue;
        });
    });

    return groups;
};

export const getUpdatedProperties = (initialValues, values) => {
    const editableProperties = initialValues.reduce((editablePropertiesAccumulator, currentProperty) => {
        const properties = currentProperty.properties.reduce((propertiesAccumulator, property) => {
            if (!property.readOnly) {
                propertiesAccumulator.push(property);
            }

            return propertiesAccumulator;
        }, []);

        if (properties.length > 0) {
            editablePropertiesAccumulator.push(currentProperty);
        }

        return editablePropertiesAccumulator;
    }, []);

    const updatedProperties = editableProperties.reduce((updatedPropertiesAccumulator, editableProperty) => {
        editableProperty.properties.forEach((property) => {
            const selectedValue = property.selectedValue;
            const propertyValue = values.properties['group_' + editableProperty.groupId]['field_' + property.id];

            let newPropertyValue = null;
            if (property.readOnly === false && property.type === INPUT_TYPES.MonthCalendar) {
                newPropertyValue = propertyValue;
            } else {
                newPropertyValue =
                    propertyValue != null && typeof propertyValue !== 'string'
                        ? propertyValue.toString()
                        : propertyValue;
            }

            if (
                (selectedValue === null && newPropertyValue === '') ||
                (selectedValue === null && newPropertyValue === 'false')
            ) {
                return;
            }

            if (newPropertyValue !== selectedValue) {
                updatedPropertiesAccumulator.push({
                    ...property,
                    selectedValue: newPropertyValue,
                });
            }
        });

        return updatedPropertiesAccumulator;
    }, []);

    return updatedProperties;
};

export const mapUpdatedStudyProperties = (initialProperties, updatedProperties) => {
    const studyProperties = initialProperties.reduce((updatedPropertiesAccumulator, mainProperty) => {
        mainProperty.properties.forEach((property) => {
            const selectedValue = property.selectedValue;
            const propertyValue = updatedProperties['group_' + mainProperty.groupId]['field_' + property.id];
            const newPropertyValue = typeof propertyValue !== 'string' ? propertyValue.toString() : propertyValue;

            // Reset Checkboxes with readOnly values to defaultValues ( Since null/undefined will be converted to false/0 )
            // BE does not allow changes to readOnly values
            if (property.readOnly && property.type === INPUT_TYPES.CheckBox) {
                updatedPropertiesAccumulator.push({ ...property });
                return;
            }

            if (
                (selectedValue === null && newPropertyValue === '') ||
                (selectedValue === null && newPropertyValue === 'false')
            ) {
                updatedPropertiesAccumulator.push({ ...property });
            } else {
                updatedPropertiesAccumulator.push({
                    ...property,
                    selectedValue:
                        property.type === INPUT_TYPES.MonthCalendar ? new Date(newPropertyValue) : newPropertyValue,
                });
            }
        });

        return updatedPropertiesAccumulator;
    }, []);
    return studyProperties;
};

export const getDynamicPropValue = (code, currentProps, allProps) => {
    if (Object.keys(currentProps).length === 0) return undefined;

    const allFields = Object.values(allProps).flatMap((group) => {
        return group.properties.map((prop) => ({ ...prop, groupId: group.groupId }));
    });

    const dynamicField = allFields.find((elem) => elem.code.toLowerCase() === code.toLowerCase());
    if (!dynamicField) return undefined;

    return currentProps[`group_${dynamicField.groupId}`][`field_${dynamicField.id}`];
};
