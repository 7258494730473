import { Skeleton, Stack, Box } from '@chakra-ui/react';

const RibbonSkeleton = () => {
    return (
        <Box mt={4}>
            <Stack>
                <Skeleton height="60px" />
                <Skeleton height="60px" />
                <Skeleton height="60px" />
                <Skeleton height="60px" />
                <Skeleton height="60px" />
            </Stack>
        </Box>
    );
};

export default RibbonSkeleton;
