import styled from '@emotion/styled/macro';
import { Box } from '@chakra-ui/react';

const CustomRequiredIndicator = () => {
    return <StyledAsterisk _dark={{ color: 'red.300' }}>*</StyledAsterisk>;
};

const StyledAsterisk = styled(Box)`
    display: inline;
    margin-inline-start: var(--chakra-space-1);
    color: var(--chakra-colors-red-500);
`;

export default CustomRequiredIndicator;
