import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getDateIntervals = createAsyncThunk('getDateIntervals', async () => {
    const response = await axios.get(`/hedges/date-intervals`);
    return response.data;
});

export const getLinkedObjects = createAsyncThunk('getLinkedObjects', async () => {
    const response = await axios.get(`/hedges/linked-objects`);
    return response.data;
});
