import { useState, useEffect, useCallback } from 'react';
import { Button, Box, Text, Flex, useDisclosure, useMediaQuery, Progress } from '@chakra-ui/react';

import { FormattedMessage, useIntl } from 'react-intl';

import { getStudyValidationProgress, skipStudyValidationProgress, cancelStudyJobs } from '../../services/studies';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import ConfirmationModal from '../modal/ConfirmationModal';
import MainModal from '../modal/MainModal';

import SimpleGridSkeleton from '../../components/utils/SimpleGridSkeleton';
import Error from '../../components/utils/Error';
import CustomIconButton from '../../components/utils/CustomIconButton';
import Popover from '../../components/utils/Popover';

import StudyValidationsTable from './StudyValidationsTable';

import { ReactComponent as InfoIcon } from '../../icons/info.svg';

// Validation Progress status/state level codes
// 0 - Completed
// 40 - Waiting to start/continue
// 60 - Running

const StudyValidationsModal = ({ studyId, studyName }) => {
    const [validationProgress, setValidationProgress] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);

    const [isDesktop] = useMediaQuery('(min-width: 769px)');

    const skipValidationsConfirmationModal = useDisclosure();
    const cancelJobsConfirmationModal = useDisclosure();
    const closeValidationsConfirmationModal = useDisclosure();

    const historyPush = useHistoryPush();
    const { toast } = useCommonToast();
    const intl = useIntl();

    const isInitialProgressLoaded = Object.keys(validationProgress).length > 0;
    const isProgressWaitingOrRunning =
        isInitialProgressLoaded && (validationProgress.stateLevel === 40 || validationProgress.stateLevel === 60);
    const isProgressRunning = isInitialProgressLoaded && validationProgress.stateLevel === 60;

    const fetchStudyValidationProgress = useCallback(() => {
        return getStudyValidationProgress(studyId)
            .then((data) => {
                setValidationProgress(data);
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [studyId]);

    useEffect(() => {
        fetchStudyValidationProgress();
    }, [fetchStudyValidationProgress]);

    useEffect(() => {
        const timer = setTimeout(async () => {
            try {
                if (isProgressWaitingOrRunning) {
                    const newValidationProgress = await getStudyValidationProgress(studyId);
                    setValidationProgress(newValidationProgress);
                }
            } catch {
                const errorMessage = intl.formatMessage({ id: 'run_a_study_validations_loading_error' });

                toast({
                    status: 'error',
                    message: errorMessage,
                });

                setIsLoading(false);
                setLoadingError(true);
                clearTimeout(timer);
            }
        }, 10000);

        return () => {
            clearTimeout(timer);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationProgress]);

    const onStudyValidationsCloseConfirmation = () => {
        historyPush('/');
    };

    const onStudyValidationsSkip = async () => {
        const errorMessage = intl.formatMessage({ id: 'run_a_study_skip_validations_error' });

        try {
            await skipStudyValidationProgress(studyId);
            historyPush('/');
        } catch {
            toast({
                status: 'error',
                message: errorMessage,
            });
        }
    };

    const onStudyJobsCancel = async () => {
        const errorMessage = intl.formatMessage({ id: 'run_a_study_cancel_jobs_error' });

        try {
            await cancelStudyJobs(studyId);
            historyPush('/');
        } catch {
            toast({
                status: 'error',
                message: errorMessage,
            });
        }
    };

    return (
        <>
            <MainModal
                scrollBehavior="inside"
                isOpen
                onClose={closeValidationsConfirmationModal.onOpen}
                size={isDesktop ? '6xl' : 'full'}
                header={
                    <Flex alignItems="center">
                        <FormattedMessage id="run_a_study_validating" />
                        <Text color="black" mx={2}>
                            {studyName}
                        </Text>

                        <Popover
                            placement="bottom"
                            trigger={<CustomIconButton mt="0px !important" mr="5px !important" icon={<InfoIcon />} />}
                            width="600px"
                        >
                            <Text size="sm">
                                <FormattedMessage id="run_a_study_validation_info" />
                            </Text>
                            <Text size="sm" mt={3}>
                                <FormattedMessage id="run_a_study_validation_info_note" />
                            </Text>
                        </Popover>
                    </Flex>
                }
                content={
                    isLoading ? (
                        <SimpleGridSkeleton rows={9} cols={3} />
                    ) : loadingError ? (
                        <Box m={6}>
                            <Error
                                primaryId="common_error"
                                secondaryId="common_loading_error"
                                additionalText="validation progress"
                            />
                        </Box>
                    ) : (
                        <StudyValidationsTable validationProgress={validationProgress} />
                    )
                }
                footerLeftSlot={
                    <Flex flex="1" alignItems="center">
                        <Text size="sm">{validationProgress.status}</Text>
                        {isProgressRunning && (
                            <Progress flex="1" size="sm" colorScheme="green" isIndeterminate borderRadius="md" mx={5} />
                        )}
                    </Flex>
                }
                secondaryButton={
                    <Button variant="secondary" onClick={cancelJobsConfirmationModal.onOpen} ml={3}>
                        <FormattedMessage id="run_a_study_cancel_jobs" />
                    </Button>
                }
                footerRightSlot={
                    <Button
                        variant="secondary"
                        textTransform="capitalize"
                        isDisabled={validationProgress.stateLevel === 0}
                        onClick={skipValidationsConfirmationModal.onOpen}
                        ml={3}
                    >
                        <FormattedMessage id="run_a_study_skip_validations" />
                    </Button>
                }
            />

            {skipValidationsConfirmationModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={skipValidationsConfirmationModal.onClose}
                    header={<FormattedMessage id="run_a_study_skip_validations" />}
                    hasExtraStep
                    content={<FormattedMessage id="run_a_study_validations_continue_confirmation_modal_text" />}
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_ok" />
                        </Box>
                    }
                    onConfirm={onStudyValidationsSkip}
                />
            )}

            {cancelJobsConfirmationModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={cancelJobsConfirmationModal.onClose}
                    header={<FormattedMessage id="run_a_study_validations_continue_confirmation_modal_title" />}
                    hasExtraStep
                    content={<FormattedMessage id="run_a_study_validations_modal_cancel_jobs" />}
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_ok" />
                        </Box>
                    }
                    onConfirm={onStudyJobsCancel}
                />
            )}

            {closeValidationsConfirmationModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={closeValidationsConfirmationModal.onClose}
                    header={<FormattedMessage id="run_a_study_validations_continue_confirmation_modal_title" />}
                    hasExtraStep
                    content={
                        <FormattedMessage
                            id={
                                isProgressWaitingOrRunning
                                    ? 'run_a_study_validations_modal_close_validations_running'
                                    : 'run_a_study_validations_modal_close_validations_completed'
                            }
                        />
                    }
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_ok" />
                        </Box>
                    }
                    onConfirm={onStudyValidationsCloseConfirmation}
                />
            )}
        </>
    );
};

export default StudyValidationsModal;
