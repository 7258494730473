import { useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as FileIcon } from '../../icons/file.svg';
import { ReactComponent as FilePaperclipIcon } from '../../icons/file-paperclip.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

import { downloadFile } from '../../services/items';
import Tile from './Tile';
import SimpleModal from '../modal/SimpleModal';
import { SortOrderContext } from '../grid/utils/SortOrderContext';
import FilterIndicationHelper from './FilterIndicationHelper';

const DownloadModal = ({
    itemType,
    onDownloadGeneric,
    onDownloadData,
    onDownloadTemplate,
    headerMessage,
    bodyMessage,
    filterDate,
    ...rest
}) => {
    const { sortOrder } = useContext(SortOrderContext);

    const date = new Date(Date.parse(filterDate));

    const [isLoading, setLoading] = useState(false);

    const headerText = headerMessage || <FormattedMessage id="download_modal_header" />;
    const bodyText = bodyMessage || <FormattedMessage id="download_modal_explanation" />;

    const onDownloadFile = async (isTemplate, isGeneric) => {
        try {
            setLoading(true);

            if (isGeneric) {
                const data = await onDownloadGeneric();
                downloadFile(data);
            } else {
                const data = isTemplate ? await onDownloadTemplate() : await onDownloadData(sortOrder);
                downloadFile(data);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <SimpleModal title={headerText} withHeaderDivider={true} size={'lg'} {...rest}>
            <StyledText>{bodyText}</StyledText>

            {isLoading ? (
                <Flex mb={7} align="center" justify="center">
                    <Spinner />
                </Flex>
            ) : (
                <>
                    <Tile
                        leftIcon={FileIcon}
                        label={<FormattedMessage id="download_modal_template" />}
                        rightIcon={DownloadIcon}
                        onClick={() => onDownloadFile(true, false)}
                    />
                    <Tile
                        leftIcon={CustomFilePaperclipIcon}
                        label={<FormattedMessage id="download_modal_data" />}
                        rightIcon={DownloadIcon}
                        onClick={() => onDownloadFile(false, false)}
                        labelHelper={isNaN(Date.parse(filterDate)) ? undefined : <FilterIndicationHelper date={date} />}
                    />
                    {typeof onDownloadGeneric === 'function' && (
                        <Tile
                            leftIcon={CustomFilePaperclipIcon}
                            label={<FormattedMessage id="download_modal_data_generic" />}
                            rightIcon={DownloadIcon}
                            onClick={() => onDownloadFile(false, true)}
                            labelHelper={
                                isNaN(Date.parse(filterDate)) ? undefined : <FilterIndicationHelper date={date} />
                            }
                        />
                    )}
                </>
            )}
        </SimpleModal>
    );
};

const CustomFilePaperclipIcon = styled(FilePaperclipIcon)`
    background: none;
    height: 30px;
    width: auto;

    path:first-of-type {
        fill: var(--chakra-colors-blue-200);
    }
    path:last-of-type {
        fill: var(--chakra-colors-blue-400);
    }
`;

const StyledText = styled(Text)`
    color: var(--chakra-colors-gray-700);
    font-weight: 500;
    margin-bottom: 24px;
    font-size: 15px;
`;

export default DownloadModal;
