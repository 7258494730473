import axios from 'axios';

export const GRID_KEY = 'priceFormula';

export const getDropdownOptions = async () => {
    const response = await axios.get('/spot-price/dropdown-options');
    return response.data;
};

export const getPriceFormulaTypes = async () => {
    const response = await axios.get('/price-formula/types');
    return response.data;
};

export const getPriceFormula = async (priceFormulaId) => {
    const response = await axios.get(`/price-formula/${priceFormulaId}`);
    return response.data;
};

export const createPriceFormula = (payload) => axios.post('/price-formula/create', payload);

export const getPriceEquations = async (priceFormulaId, payload) => {
    const response = await axios.post(`/price-formula/${priceFormulaId}/price-equations`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const updatePriceEquations = (priceFormulaId, payload) =>
    axios.put(`/price-formula/${priceFormulaId}/update-price-equations`, payload, {
        suppressErrorMessageHandler: true,
    });

export const deleteAllPriceEquations = (priceFormulaId) =>
    axios.delete(`/price-formula/${priceFormulaId}/delete-all-price-equations`, {
        suppressErrorMessageHandler: true,
    });

export const deletePriceEquations = (priceFormulaId, rowIds) =>
    axios.delete(`/price-formula/${priceFormulaId}/delete-price-equations`, {
        data: rowIds,
        suppressErrorMessageHandler: true,
    });

export const createPriceEquations = async (priceFormulaId, payload) => {
    const response = await axios.post(`/price-formula/${priceFormulaId}/add-price-equations`, payload, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const multisortPairs = {
    peakPeriodLabelId: 'peakPeriodLabelDescription',
};

export const deletePriceFormula = (priceFormulaId) => axios.delete(`/price-formula/${priceFormulaId}/delete`);

export const editPriceFormula = (payload) =>
    axios.put('/price-formula/update', payload, { suppressErrorMessageHandler: true });

export const getPriceFormulas = async () => {
    const response = await axios.get('/price-formula');
    return response.data;
};
