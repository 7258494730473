import { useState } from 'react';
import { Box, IconButton, Spacer } from '@chakra-ui/react';
import { ReactComponent as ChevronIcon } from '../../icons/chevron-down.svg';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';

import Popover from '../utils/Popover';
import CustomIconButton from '../utils/CustomIconButton';

import CustomCheckbox from '../forms/CustomCheckbox';

import debounce from 'lodash/debounce';

import styled from '@emotion/styled/macro';

const MapTreeNode = ({ layer }) => {
    const [checked, setChecked] = useState(layer.visible);

    const [open, openChildren] = useState(false);

    const [childrenItems, setChildrenItems] = useState(
        layer.childrenNodes ? layer.childrenNodes.map((elem) => elem.visible) : []
    );

    const allChecked = childrenItems.every(Boolean);
    const isIndeterminate = childrenItems.some(Boolean) && !allChecked;

    const toggleLayer = debounce((layer, value, isChild, index) => {
        if (layer.fullyLoaded === false) return;

        const isChecked = isChild ? childrenItems[index] : checked;
        layer.visible = !isChecked;

        if (isChild) {
            childrenItems[index] = value;
            setChildrenItems([...childrenItems]);
        } else {
            if (layer.childrenNodes) {
                // Check if all children layers are fully loaded
                for (const elem of layer.childrenNodes) {
                    if (elem.fullyLoaded === false) return;
                }

                for (const elem of layer.childrenNodes) {
                    if (value) {
                        elem.visible = true;
                    } else {
                        elem.visible = false;
                    }
                }
            }

            setChecked(value);
            setChildrenItems([...childrenItems.fill(value)]);
        }
    }, 150);

    return (
        <NodeContainer>
            <Box display="flex" alignItems="center">
                <CustomCheckbox
                    isIndeterminate={isIndeterminate}
                    onChange={(e) => toggleLayer(layer, isIndeterminate ? true : e.target.checked)}
                    isChecked={checked}
                />

                {layer.childrenNodes && (
                    <DropdownButton
                        variant="outline"
                        colorScheme="green"
                        icon={<ChevronIcon />}
                        onClick={() => openChildren((prev) => !prev)}
                    />
                )}

                <NodeHeader visibleAtScale={!layer.visibleAtScale}>
                    {layer.custom_title ? layer.custom_title : layer.title}
                </NodeHeader>
                <Spacer />
                {layer.description !== '' && (
                    <Popover placement="top" trigger={<CustomIconButton icon={<InfoIcon />} />}>
                        {layer.description}
                    </Popover>
                )}
            </Box>

            {layer.childrenNodes && (
                <ChildrenNodesContainer isOpen={open}>
                    {layer.childrenNodes.map((childLayer, index) => {
                        return (
                            <NodeContainer key={childLayer.id}>
                                <Box display="flex" alignItems="center">
                                    <CustomCheckbox
                                        onChange={(e) => toggleLayer(childLayer, e.target.checked, true, index)}
                                        isChecked={childrenItems[index]}
                                    />
                                    <NodeHeader visibleAtScale={!childLayer.visibleAtScale}>
                                        {childLayer.custom_title || childLayer.title}
                                    </NodeHeader>
                                </Box>
                            </NodeContainer>
                        );
                    })}
                </ChildrenNodesContainer>
            )}
        </NodeContainer>
    );
};
const NodeContainer = styled(Box)`
    padding: 5px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;
const NodeHeader = styled('p', { shouldForwardProp: (prop) => prop !== 'visibleAtScale' })`
    margin-left: 10px;
    opacity: ${(props) => (props.visibleAtScale ? '40%' : '100%')};
`;
const ChildrenNodesContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'isOpen' })`
    padding-left: 25px;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;
const DropdownButton = styled(IconButton)`
    width: 16px;
    height: 16px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    outline: none;
    box-shadow: none;
    border: none;
    margin-left: 10px;

    svg {
        width: 16px;
        height: 16px;
    }
    circle {
        fill: transparent;
        display: none;
    }
`;

export default MapTreeNode;
