import axios from 'axios';
import { HOURS_COUNT } from '../../constants/shapeSet';

export const fetchEntryTypeMap = async () => {
    const response = await axios.get('/shapeset/entrytypemap');
    return response.data;
};

export const getEntryTypeCode = (entryTypeId, entryTypeMap) =>
    entryTypeMap?.find((type) => type.id === entryTypeId)?.code;
export const getDataForRow = (data, month, day, hour) => {
    const existingData = data.find((row) => row.MONTHNUM === month && row.DOWNUM === day && row.HOURNUM === hour);
    return { value: existingData?.SHAPEVALUE ?? undefined, id: existingData?.SHAPEDATAID };
};

export const reduceMonthDoWRows = (rows, data) => {
    let output = { recordsToAdd: [], recordsToUpdate: [] };
    rows.forEach((row) => {
        const rowData = row.data;
        const month = rowData.MonthNum;
        const hour = 0;
        const days = data.filter((entry) => entry.MONTHNUM === month && entry.HOURNUM === hour);
        const clientSideDays = Object.entries(rowData).filter(([key]) => !isNaN(parseInt(key)));
        output = clientSideDays.reduce((acc, [day, value]) => {
            const originalDay = days.find((d) => d.DOWNUM === parseInt(day));

            if (originalDay && value !== originalDay.SHAPEVALUE) {
                acc.recordsToUpdate.push({
                    ...originalDay,
                    SHAPEVALUE: value,
                });
            } else if (!originalDay && value) {
                acc.recordsToAdd.push({
                    MONTHNUM: month,
                    DOWNUM: parseInt(day),
                    HOURNUM: hour,
                    SHAPEVALUE: value,
                });
            }
            return acc;
        }, output);
    });
    return output;
};
export const reduceMonthDayHourRows = (rows, data) => {
    let output = { recordsToAdd: [], recordsToUpdate: [] };
    rows.forEach((row) => {
        const rowData = row.data;
        const day = rowData.DoWNum;
        const month = rowData.MonthNum;
        const hours = data.filter((entry) => entry.MONTHNUM === month && entry.DOWNUM === day);
        const clientSideHours = Object.entries(rowData).filter(([key]) => !isNaN(parseInt(key)));
        output = clientSideHours.reduce((acc, [hour, value]) => {
            const originalHour = hours.find((h) => h.HOURNUM === parseInt(hour));
            if (originalHour && value !== originalHour.SHAPEVALUE) {
                acc.recordsToUpdate.push({
                    ...originalHour,
                    SHAPEVALUE: value,
                });
            } else if (!originalHour && value) {
                acc.recordsToAdd.push({
                    MONTHNUM: month,
                    DOWNUM: day,
                    HOURNUM: parseInt(hour),
                    SHAPEVALUE: value,
                });
            }
            return acc;
        }, output);
    });
    return output;
};

export const sortShapeSetEnum = (obj) =>
    Object.entries(obj)
        .sort((entry1, entry2) => {
            return entry1[1] > entry2[1] ? 1 : -1;
        })
        .map(([key, val]) => val);

export const createShapeSetHourlyRow = (data, day, month, translations, shouldTranslateDay = true) => {
    const newRow = {
        id: `${month}_${day}`, // Important ot have a unique ID to keep AG Grid updated efficiently
        Month: translations.months[month],
        MonthNum: month,
        DoWNum: day,
        DoW: shouldTranslateDay ? translations.days[day] : `${day}`,
    };

    for (let hour = 0; hour < HOURS_COUNT; hour++) {
        const { value } = getDataForRow(data, month, day, hour);
        newRow[`${hour}`] = value;
    }
    return newRow;
};

export const getRowNodeId = (params) => {
    return params.id;
};
