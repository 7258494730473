import { useState } from 'react';

const useKeyRefresh = () => {
    const [key, setKey] = useState(0);

    const refreshKey = () => {
        // this will be used to increment key that will refresh the component
        setKey((k) => k + 1);
    };

    return [key, refreshKey];
};

export default useKeyRefresh;
