import { Flex, Text, Image, Link, Box } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Title } from 'react-head';

import LayoutWrapper from 'components/layout/LayoutWrapper';
import { APP_NAME } from 'constants/autoforms';

import { ReactComponent as PremiumIcon } from 'icons/premium-large.svg';

const DisabledPage = () => {
    return (
        <>
            <Title>
                <FormattedMessage id="restricted_access" /> - {APP_NAME}
            </Title>
            <LayoutWrapper backgroundColor="background-octenary">
                <Flex justify="center" width="100%">
                    <Flex
                        mx="auto"
                        my={16}
                        px={4}
                        textAlign="center"
                        direction="column"
                        alignItems="center"
                        maxW="600px"
                    >
                        <Image borderRadius="50%" width="373px" height="373px" alt="" src={'/splash/1.png'} />
                        <Box mt="-30px">
                            <PremiumIcon />
                        </Box>

                        <Text fontSize="md" mb={2} mt={12} color="gray.900">
                            <FormattedMessage id="disabled_page_header" />
                        </Text>

                        <Text fontSize="sm" mb={6} color="gray.800" fontWeight="normal">
                            <FormattedMessage id="disabled_page_description" />{' '}
                            <Link fontSize="sm" href="mailto:supportps@ascendanalytics.com">
                                supportps@ascendanalytics.com
                            </Link>
                        </Text>
                    </Flex>
                </Flex>
            </LayoutWrapper>
        </>
    );
};

export default DisabledPage;
