const Heading = {
    baseStyle: {
        _dark: {
            color: 'white',
        },
    },

    variants: {
        h1: {
            fontSize: '40px',
            lineHeight: '48px',
            fontWeight: '600',
        },
        h2: {
            fontSize: '24px',
            lineHeight: '32px',
            fontWeight: '600',
        },
        h3: {
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: '600',
        },
        h4: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '600',
        },
        'subheading-bold': {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '500',
        },
        subheading: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '400',

            _dark: {
                color: 'gray.300',
            },
        },
    },

    defaultProps: {
        variant: 'h2',
    },
};

export default Heading;
