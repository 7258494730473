import { useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';

import { Box, Text, Flex } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';

import { getBatteryLabelSwitch, mapPeriodsData } from '../../../services/batteries';
import { createTimebar, createTimeState, getRange, getFocusedCell } from '../../../services/batteries/ribbon';

import Sidebar from './Sidebar';
import Timeline from './Timeline';
import RibbonSkeleton from './RibbonSkeleton';

const TimeRibbon = ({
    data,
    metadata,
    selectedView,
    subBatteryId,
    updateRibbonData,
    focusCellId,
    onFocusCellIdChange,
    ribbonStaticData,
}) => {
    const intl = useIntl();

    const mainWrapperRef = useRef();
    const sidebarRef = useRef();
    const timelineContainerRef = useRef();

    // transform periods data for timeline
    const timelineData = mapPeriodsData(data);

    // create the timeState based on selected view & timeline range
    const timelineRange = getRange(data, selectedView);
    const timebar = createTimebar(timelineRange, selectedView);
    const timeState = createTimeState({
        start: timelineRange.earliest,
        end: timelineRange.latest,
        selectedView: selectedView,
    });

    useEffect(() => {
        const id = getFocusedCell(timeState.selectedView, focusCellId);
        const cell = mainWrapperRef.current.querySelector(`[data-period="${id}"]`);

        if (cell) {
            mainWrapperRef.current.scrollLeft = cell.offsetLeft;
        }
    }, [focusCellId, timeState.selectedView]);

    const batteryLevelUnit = getBatteryLabelSwitch(metadata.batteryLevelLabel);

    const timebarCells = timebar.map((cell) => {
        const style = timeState.toStyleLeftAndWidth(cell.start, cell.end);
        const label =
            timeState.selectedView === 1
                ? intl.formatDate(cell.start, {
                      year: '2-digit',
                      month: 'short',
                  })
                : intl.formatDate(cell.start, {
                      year: 'numeric',
                  });

        return { ...cell, style: style, label: label };
    });

    const onMainWrapperScroll = (arg) => {
        sidebarRef.current.scrollTop = arg.target.scrollTop;
    };

    return (
        <>
            {timeState ? (
                <Flex flexDirection="row">
                    <SideWrapper ref={sidebarRef}>
                        <StyledHeader>
                            <StyledTitleText>
                                <FormattedMessage id="batteries_timeline_editor_sidebar_title" />
                            </StyledTitleText>
                        </StyledHeader>
                        <Sidebar
                            data={timelineData}
                            metadata={metadata}
                            batteryLevelUnit={batteryLevelUnit}
                            subBatteryId={subBatteryId}
                            updateRibbonData={updateRibbonData}
                            ribbonStaticData={ribbonStaticData}
                        />
                    </SideWrapper>

                    <MainWrapper ref={mainWrapperRef} onScroll={onMainWrapperScroll}>
                        <Timebar>
                            {timebarCells.map((cell) => (
                                <TimebarCell
                                    key={cell.id}
                                    data-period={cell.id}
                                    style={cell.style}
                                    color="text-quaternary"
                                >
                                    {cell.label}
                                </TimebarCell>
                            ))}
                        </Timebar>

                        <TimelineContainer ref={timelineContainerRef}>
                            <Timeline
                                timebar={timebar}
                                timeState={timeState}
                                data={data}
                                subBatteryId={subBatteryId}
                                updateRibbonData={updateRibbonData}
                                onScrollCellIdChange={onFocusCellIdChange}
                                ribbonStaticData={ribbonStaticData}
                            />
                        </TimelineContainer>
                    </MainWrapper>
                </Flex>
            ) : (
                <RibbonSkeleton />
            )}
        </>
    );
};

const SideWrapper = styled(Box)`
    width: 330px;
    display: inline-block;
    height: 600px;
    overflow: hidden;
    border: 1px solid var(--chakra-colors-border-tertiary);
`;

const StyledHeader = styled(Box)`
    position: sticky;
    top: 0;
    z-index: 2;
    background: var(--chakra-colors-background-octenary);
`;

const StyledTitleText = styled(Text)`
    height: 51px;
    line-height: 40px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: var(--chakra-colors-black);
`;

const MainWrapper = styled(Box)`
    width: calc(100% - 330px);
    height: 600px;
    overflow-y: auto;
    overflow-x: scroll;
    border: 1px solid var(--chakra-colors-border-tertiary);
    background: var(--chakra-colors-background-octenary);
`;

const Timebar = styled(Box)`
    height: 51px;
    line-height: 40px;
    position: sticky;
    top: 0;
    z-index: 2;
    will-change: transform;
`;

const TimebarCell = styled(Box)`
    position: absolute;
    text-align: center;
    height: inherit;
    background: var(--chakra-colors-background-octenary);
    border-right: 1px solid var(--chakra-colors-border-tertiary);
    font-size: 14px;
    font-weight: 500;
`;

const TimelineContainer = styled(Box)`
    height: auto;
    background: var(--chakra-colors-background-octenary);
`;

export default TimeRibbon;
