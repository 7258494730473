import { createSlice } from '@reduxjs/toolkit';
import { getUserPermissions } from './permissionsApi';

const initialState = {
    permissions: [],
    isLoading: false,
};

const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: {
        [getUserPermissions.fulfilled]: (state, action) => {
            state.permissions = action.payload;
        },
        [getUserPermissions.rejected]: (state) => {
            state.permissions = [];
        },
    },
});

export const { setLoading } = permissionsSlice.actions;
export default permissionsSlice.reducer;
