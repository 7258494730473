import { useMemo, useState, useEffect } from 'react';
import { getItemFormKey, getTableFormKey } from '../../components/autoform/utils/autoformUtils';

const useBookUsersValues = (level1TableId, selectedItem, userBooks, selectedItemData, descriptionColumnName) => {
    const [initialValues, setInitialValues] = useState();

    const titleValue = useMemo(() => {
        let tableFormKey = getTableFormKey(level1TableId);
        let itemFormKey = getItemFormKey(selectedItem);
        return {
            [tableFormKey]: {
                [itemFormKey]: {
                    [descriptionColumnName]: selectedItemData && selectedItemData[descriptionColumnName],
                    DESCRIPTION: selectedItemData && (selectedItemData['DESCRIPTION'] ?? ''),
                    DEFAULTFLAG: selectedItemData && selectedItemData['DEFAULTFLAG'] === 1,
                },
            },
        };
    }, [level1TableId, selectedItem, selectedItemData, descriptionColumnName]);

    useEffect(() => {
        setInitialValues((values) => ({
            ...values,
            ...titleValue,
        }));
    }, [titleValue]);

    useEffect(() => {
        setInitialValues((values) => ({
            ...values,
            userBooks: userBooks?.reduce((acc, userBook) => {
                acc[userBook.value] = userBook.isChecked;
                return acc;
            }, {}),
        }));
    }, [userBooks]);

    return { initialValues, setInitialValues };
};

export default useBookUsersValues;
