import { createSlice } from '@reduxjs/toolkit';
import { getFeatureFlags } from './featureFlagsApi';

const initialState = {
    features: [],
};

const featureFlagsSlice = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {},
    extraReducers: {
        [getFeatureFlags.fulfilled]: (state, action) => {
            state.features = action.payload;
        },
    },
});

export default featureFlagsSlice.reducer;
