import { Box } from '@chakra-ui/react';
import { Formik, Form } from 'formik';

import { mapDynamicProperties } from '../../services/dynamicProperties';

import SimpleGridSkeleton from '../utils/SimpleGridSkeleton';
import Error from '../utils/Error';
import DynamicPropertyTabs from '../utils/DynamicPropertyTabs';

const ExpressJobsProperties = ({ properties, formPropertiesRef }) => {
    if (properties.loading) {
        return (
            <Box mt={3}>
                <SimpleGridSkeleton rows={4} cols={4} />
                <Box mt={3}>
                    <SimpleGridSkeleton rows={3} cols={1} />
                </Box>
            </Box>
        );
    }

    if (properties.error) {
        return (
            <Box mt={6}>
                <Error primaryId="common_error" secondaryId="common_loading_error" additionalText="properties" />
            </Box>
        );
    }

    const initialFormValues = {
        properties: mapDynamicProperties(properties.data),
    };

    return (
        <Formik initialValues={initialFormValues} innerRef={formPropertiesRef}>
            <Form>
                <DynamicPropertyTabs groups={properties.data} defaultIndex={0} />
            </Form>
        </Formik>
    );
};

export default ExpressJobsProperties;
