export const layersConfig = [
    {
        title: 'ISO Market Points',
        description: `This layer represents pricing nodes in each of the ISOs. 
            Locations are not available for all nodes, so this displays only nodes with coordinates available. 
            Locations match the precision of the underlying source, primarily EIA and ISO publications. 
            Updated: April 2024`,
    },
    {
        title: 'IRA Locational Incentives',
        description: `This set of layers show Ascend’s interpretation of eligibility for the Energy Community and/or Low-Income Community tax credits
            (10% bonus ITC/PTC) as described in the Inflation Reduction Act. 
            Ascend has also included layers indicating future eligibility where coal plants and mines are currently operating. 
            Employment-based Energy Communities are projected twice; 
            once using a more conservative definition of fossil fuel jobs, and once with a more optimistic set. 
            Layers have also been included showing all Energy Communities regardless of qualifying criteria, 
            as well as intersections showing areas eligible for both Low-Income and Energy Community credits. 
            Updated: 2024`,
    },
    {
        title: 'Substations',
        description: `This layer represents electric power substations primarily associated with electric power transmission. 
            In this layer, substations are considered facilities and equipment that switch, transform, 
            or regulate electric power at voltages equal to, or greater than, 69 kilovolts. 
            Substations with a maximum operating voltage less than 69 kilovolts may be included, 
            depending on the availability of authoritative sources, but coverage of these features should not be considered complete. 
            `,
    },
    {
        title: 'Power Plants',
        description: `This layer represents electric power plants. Power plants are all the land and land rights, structures and improvements, 
            boiler or reactor vessel equipment, engines and engine-driven generators, turbo generator units, 
            accessory electric equipment, and miscellaneous power plant equipment are grouped together for each individual facility. 
            Included are the following plant types: 
            hydroelectric dams, fossil fuel (coal, natural gas, or oil), nuclear, solar, wind, geothermal, and biomass. 
            Updated: 2023`,
    },
    {
        title: 'EIA Planned Generators',
        description: `This layer displays the EIA inventory of planned generators. 
            The list does not include every project in each ISO, but it provides a useful subset to identify planned projects. 
            Updated: 2023`,
    },
    {
        title: 'Planned Retirements',
        description:
            'This layer displays the generators in the EIA inventory that have planned retirement dates. Updated: June 2023',
    },
    {
        title: 'Transmission Lines',
        description: `This layer represents electric power transmission lines. Transmission Lines are the system of structures, wires, 
            insulators and associated hardware that carry electric energy from one point to another in an electric power system. 
            Lines are operated at relatively high voltages varying from 69 kV up to 765 kV, 
            and are capable of transmitting large quantities of electricity over long distances. 
            Underground transmission lines are included where sources were available. 
            `,
    },
    {
        title: 'Electric Retail Service Territories',
        description:
            'This layer represents electric power retail service territories. These are areas serviced by electric power utilities responsible for the retail sale of electric power to local customers, whether residential, industrial, or commercial.',
    },
    // Also removed from getLayerOrder()
    // {
    //     title: 'Qualified Opportunity Zones',
    //     description:
    //         'A QOZ is an economically distressed community where new investments, under certain conditions, may be eligible for preferential tax treatment. QOZs were added to the tax code by the Tax Cuts and Jobs Act on December 22, 2017.',
    // },
    {
        title: '2022 USA Median Home Value',
        description:
            'This layer shows the median home value in the U.S. in 2022 in a multiscale map by country, state, county, ZIP Code, tract, and block group.',
    },
    {
        title: 'Ascend ISO Forecasting Subregions',
        description: `This layer shows Ascend's forecasting regions within an ISO, which are based on Ascend's analysis of geospatial 
            boundaries and patterns that affect current and future resource buildout and price formation. 
            Updated: 2024`,
    },
    {
        title: 'Solar & Storage Project Pipeline',
        description:
            'This layer shows North American Energy Storage projects across several stages of development. Several technologies are included, such as pumped hydro facilities and lithium ion batteries.',
    },
    {
        title: 'Planned Storage & Solar Projects (Construction)',
        custom_title: 'Construction',
    },
    {
        title: 'Planned Storage & Solar Projects (Contracted)',
        custom_title: 'Contracted',
    },
    {
        title: 'Planned Storage & Solar Projects (Operational)',
        custom_title: 'Operational',
    },
    {
        title: 'Planned Storage & Solar Projects (Pipeline/Proposed)',
        custom_title: 'Pipeline/Proposed',
    },
    {
        title: 'Interconnection Queue',
        description: `This layer maps active projects in the interconnection queue (IQ) for each ISO, aggregated by county. 
            Updated: 2024`,
    },
];
