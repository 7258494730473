import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAutoformMetadata } from '../../../store/autoform/autoformApi';
import { clearAutoformStore } from '../../../store/autoform/autoformSlice';

/**
 * Load autoform metadata from BE into redux based on schemaCode or itemTypeId.
 * Autoform metadata needs to be present in the store for any of the autoform
 * components to work.
 *
 * @param {string} autoformId - Either schemaCode or itemTypeId of the autoform
 * that we want to load into the store.
 * @returns {boolean} isLoading - Is the metadata still being loaded.
 * @returns {boolean} loadingError - metadata had loading error.
 */
const useAutoformLoadMetadata = (autoformId) => {
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setloadingError] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setIsLoading(true);
        const metadataPromise = dispatch(getAutoformMetadata(autoformId));
        metadataPromise
            .then((action) => {
                if (action.meta?.requestStatus === 'rejected') {
                    setloadingError(true);
                    setIsLoading(false);
                } else if (!action.meta.aborted) {
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                setloadingError(true);
                setIsLoading(false);
            });
        return () => {
            metadataPromise.abort();
            dispatch(clearAutoformStore());
        };
    }, [dispatch, autoformId]);

    return { isLoading, loadingError };
};

export default useAutoformLoadMetadata;
