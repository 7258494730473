import { Box } from '@chakra-ui/react';
import { Formik, Form } from 'formik';

import DynamicPropertyTabs from '../utils/DynamicPropertyTabs';
import Error from '../utils/Error';
import SimpleGridSkeleton from '../utils/SimpleGridSkeleton';

import { mapDynamicProperties } from '../../services/dynamicProperties';

const StudyPropertiesTab = ({
    isLoading = true,
    loadingError,
    properties,
    formPropertiesRef,
    onDynamicPropsChange,
}) => {
    if (isLoading) {
        return (
            <Box>
                <SimpleGridSkeleton rows={4} cols={4} />
                <Box mt={3}>
                    <SimpleGridSkeleton rows={3} cols={1} />
                </Box>
            </Box>
        );
    }

    if (loadingError) {
        return (
            <Box mt={6}>
                <Error primaryId="common_error" secondaryId="common_loading_error" additionalText="study properties" />
            </Box>
        );
    }

    const initialFormValues = {
        properties: mapDynamicProperties(properties),
    };

    return (
        <Formik
            enableReinitialize
            validate={onDynamicPropsChange}
            initialValues={initialFormValues}
            innerRef={formPropertiesRef}
        >
            {() => (
                <Form>
                    <DynamicPropertyTabs groups={properties} defaultIndex={0} />
                </Form>
            )}
        </Formik>
    );
};

export default StudyPropertiesTab;
