import { useState, useCallback, useContext, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tabs, TabPanels, TabPanel, TabList, Tab, Flex, useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useParams } from 'react-router-dom';
import { differenceInDays } from 'date-fns';

import { refreshMultisort, exportItemData, exportItemDataGeneric } from '../../services/items';

import {
    resourceVariablesGroupedGridKeys,
    additionalGridKeys,
    resourceVariablesGroupKeys,
} from '../../constants/thermal';

import { SortOrderContext } from '../grid/utils/SortOrderContext';

import SecondaryButton from '../utils/SecondaryButton';
import DownloadModal from '../utils/DownloadModal';

import UploadButton from '../itemData/UploadButton';

import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

import ResourceVariables from './ResourceVariables';
import HistoricalOutput from './HistoricalOutput';
import ScheduledOutages from './ScheduledOutages';
import { useSelector } from 'react-redux';
import { SUBSCHEMACODE_THERMAL } from 'constants/uploadDownload';
import { getSelectedThermalTabProperties } from './util/thermalHelpers';

const SubThermalData = ({
    subThermalId,
    defaultItemId,
    type,
    metadata,
    bulkUpdate: {
        bulkUpdateVisibility,
        bulkUpdateLoading,
        bulkUpdateFields,
        allVariedByDate,
        generateFields,
        getBulkUpdateState,
        onFieldChange,
        onToggleVariedByDate,
        onToggleAllVariedByDate,
        clearBulkUpdateState,
    },
    forceGridRefresh,
    filters,
    helpPageUrlItemTypeId = null,
}) => {
    const intl = useIntl();
    const ref = useRef();
    const downloadModal = useDisclosure();
    const { setSortOrder } = useContext(SortOrderContext);
    const { thermalId } = useParams();

    //For 43528 - if there is no itemid in the route, gets the itemId that has been passed on.
    const itemId = thermalId ? Number(thermalId) : defaultItemId;

    const [key, setKey] = useState(0);
    const [loadingQueue, setLoadingQueue] = useState([]);
    const [innerSelectedTab, setInnerSelectedTab] = useState({
        index: 0,
        groupKey: resourceVariablesGroupKeys.PERIODS,
    });
    const [outerSelectedTab, setOuterSelectedTab] = useState(0);

    const bookId = useSelector((state) => state.connections.book);
    const uploadParameters = [`${bookId}`];

    const showOuterTabButtons = outerSelectedTab !== 0;

    const selectedTabProperties = useMemo(() => {
        const tabProperties = getSelectedThermalTabProperties(outerSelectedTab, innerSelectedTab.groupKey, intl);
        return tabProperties;
    }, [innerSelectedTab, outerSelectedTab, intl]);

    const refreshTabs = useCallback(() => {
        setKey((prev) => prev + 1);
        setSortOrder((prev) => ({
            ...prev,
            ...refreshMultisort([
                ...Object.values(resourceVariablesGroupedGridKeys).flat(),
                ...Object.values(additionalGridKeys),
            ]),
        }));
    }, [setSortOrder]);

    const onLoadingStart = useCallback((key) => {
        setLoadingQueue((prev) => prev.concat(key));
    }, []);

    const onLoadingEnd = useCallback((key) => {
        setLoadingQueue((prev) => prev.filter((p) => p !== key));
    }, []);

    const onDownloadData = useCallback(
        (exportTemplate, groupKey, sortOrder = {}) => {
            if (groupKey && outerSelectedTab === 0) {
                const gridKeys = resourceVariablesGroupedGridKeys[groupKey];
                const sorting = gridKeys
                    ?.map((key) => sortOrder[key])
                    ?.filter((sort) => sort != null)
                    ?.flat();

                const payload = { sorting, endDate: filters.minEndDate, exportTemplate };
                return exportItemData(`/thermal/${subThermalId}/${groupKey}-export`, payload);
            }

            const payload = {
                scheduledOutagesSorting: sortOrder[additionalGridKeys.SCHEDULED_OUTAGES],
                historicalOutputSorting: sortOrder[additionalGridKeys.HISTORICAL_OUTPUT],
                exportTemplate,
            };

            return exportItemData(`/thermal/${subThermalId}/scheduled-and-historical-outputs-export`, payload);
        },
        [subThermalId, filters?.minEndDate, outerSelectedTab]
    );

    const onDownloadGeneric = useCallback(() => {
        const itemCompId = subThermalId;
        const filterDate = filters?.minEndDate ? new Date(filters?.minEndDate) : undefined;
        const daysBack = filterDate ? Math.abs(differenceInDays(filterDate, new Date())) : undefined;
        return exportItemDataGeneric(SUBSCHEMACODE_THERMAL, itemId, daysBack, itemCompId);
    }, [itemId, subThermalId, filters?.minEndDate]);

    return (
        <Tabs
            px={0}
            mt={6}
            isLazy
            isManual
            lazyBehavior="keepMounted"
            key={key}
            index={outerSelectedTab}
            onChange={setOuterSelectedTab}
        >
            <OuterTabList flexWrap={{ base: 'wrap' }} showBorder={showOuterTabButtons}>
                <Tab>
                    <FormattedMessage id="unit_resource_variables" />
                </Tab>

                <Tab>
                    <FormattedMessage id="thermal_scheduled_outages_heading" />
                </Tab>

                <Tab>
                    <FormattedMessage id="thermal_historical_output_heading" />
                </Tab>

                <Flex
                    ml={{ base: 'auto' }}
                    mb={{ base: 1 }}
                    order={{ base: -1, xl: 0 }}
                    w={{ base: '100%', xl: 'auto' }}
                    flexWrap={{ base: 'wrap' }}
                >
                    <>
                        <SecondaryButton
                            leftIcon={<DownloadIcon />}
                            w="auto !important"
                            ml={{ base: 0 }}
                            mr={{ base: 2 }}
                            mb={{ base: 1 }}
                            mt={{ base: 1 }}
                            size="sm"
                            type="button"
                            variant="secondary"
                            onClick={downloadModal.onOpen}
                        >
                            <FormattedMessage id="common_download_btn_tooltip_and_label" />
                        </SecondaryButton>

                        <UploadButton
                            type={selectedTabProperties.typeKey}
                            typeFriendlyName={selectedTabProperties.friendlyName}
                            parameters={uploadParameters}
                            subschemaCode={SUBSCHEMACODE_THERMAL}
                            itemId={subThermalId}
                            hasLabel={true}
                            onAfterUploadChange={outerSelectedTab === 0 ? ref?.current?.onUploadChange : refreshTabs}
                            w="auto !important"
                            ml={{ base: 0 }}
                            mr={{ base: 2 }}
                            mb={{ base: 1 }}
                            mt={{ base: 1 }}
                        />
                    </>

                    <SecondaryButton
                        onClick={refreshTabs}
                        size="sm"
                        variant="secondary"
                        leftIcon={<RefreshIcon />}
                        isLoading={loadingQueue.length > 0}
                        loadingText={intl.formatMessage({ id: 'common_loading' })}
                        textTransform="capitalize"
                        mr={{ base: 2 }}
                        mb={{ base: 1 }}
                        mt={{ base: 1 }}
                    >
                        <FormattedMessage id="common_refresh" />
                    </SecondaryButton>
                </Flex>
            </OuterTabList>

            <TabPanels>
                <TabPanel pt={0}>
                    <ResourceVariables
                        ref={ref}
                        innerSelectedTab={innerSelectedTab}
                        onInnerTabChange={setInnerSelectedTab} // Inner tab's state is lifted here in order to not reset tabs on refresh
                        onRefreshTabs={refreshTabs}
                        subThermalId={subThermalId}
                        metadata={metadata}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        bulkUpdate={{
                            bulkUpdateVisibility,
                            bulkUpdateLoading,
                            bulkUpdateFields,
                            allVariedByDate,
                            generateFields,
                            getBulkUpdateState,
                            onFieldChange,
                            onToggleVariedByDate,
                            onToggleAllVariedByDate,
                            clearBulkUpdateState,
                        }}
                        forceGridRefresh={forceGridRefresh}
                        filters={filters}
                        type={type}
                        helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                    />
                </TabPanel>

                <TabPanel>
                    <ScheduledOutages
                        subThermalId={subThermalId}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        type={type}
                        helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                    />
                </TabPanel>

                <TabPanel>
                    <HistoricalOutput
                        subThermalId={subThermalId}
                        metadata={metadata}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        type={type}
                        helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                    />
                </TabPanel>
            </TabPanels>

            {downloadModal.isOpen && (
                <DownloadModal
                    isOpen
                    onClose={downloadModal.onClose}
                    onDownloadGeneric={onDownloadGeneric}
                    onDownloadData={(sortOrder) => onDownloadData(false, selectedTabProperties?.groupKey, sortOrder)}
                    onDownloadTemplate={() => onDownloadData(true, selectedTabProperties?.groupKey)}
                    bodyMessage={selectedTabProperties?.downloadBodyMessage}
                    filterDate={filters?.minEndDate}
                />
            )}
        </Tabs>
    );
};

const OuterTabList = styled(TabList, { shouldForwardProp: (prop) => prop !== 'showBorder' })`
    ${(props) => (props.showBorder ? '' : 'border-bottom: none;')} //to avoid the double appearing border
    width: 100%;
    align-items: center;
`;

export default SubThermalData;
