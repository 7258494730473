import { useIntl, FormattedMessage } from 'react-intl';
import { Text } from '@chakra-ui/react';
import { MULTIPLE_PLACEHOLDER_VALUE, BULK_FIELD_TOUCHED_PLACEHOLDER_VALUE } from '../../constants/thermal';

import InputField from '../forms/InputField';
import NumberInputField from '../forms/NumberInputField';
import ComboboxField from '../forms/ComboboxField';
import CheckboxField from '../forms/CheckboxField';

const BulkUpdateFields = ({
    property: {
        id,
        name,
        value,
        label,
        type,
        options,
        placeholder = '-',
        requiredFields,
        isInvalid,
        error,
        warning,
        isWarning,
    },
    onChange,
    onToggle,
}) => {
    const intl = useIntl();

    const isMultipleSelected = !!value?.includes(MULTIPLE_PLACEHOLDER_VALUE);
    const wasFieldTouched = !!value?.includes(BULK_FIELD_TOUCHED_PLACEHOLDER_VALUE);

    /** Thermal Bulk Update (varied by date) Fields Values
     * [] - no value
     * [null] - null is also considered as a unique value
     * [1234567] - a single real id in the value array -> show it as selected, field is enabled, grid column hidden
     * [1234567, null, 'multiple'] - two real ids in the value array -> show Select Multiple, field is disabled, grid column visible
     */
    let selected = undefined;

    if (isMultipleSelected) {
        // Varied by date checked
        selected = MULTIPLE_PLACEHOLDER_VALUE;
    } else if (value?.length === 1 || wasFieldTouched) {
        // Display first array element in bulk update field
        selected = value[0];
    }

    const checkbox = (
        <CheckboxField
            mt={2}
            id={`multiple_select_${name}`}
            name={`multiple_select_${name}`}
            isChecked={isMultipleSelected}
            onChange={() => onToggle(name)}
        >
            <Text as="span" fontSize="12px" color="gray.600">
                <FormattedMessage id="common_bulk_update_varied_by_date" />
            </Text>
        </CheckboxField>
    );

    switch (type) {
        case 'TextBox':
            return (
                <>
                    <InputField
                        id={id}
                        name={name}
                        label={label}
                        // here we test against multiple because it is a valid string for a text field
                        value={selected === MULTIPLE_PLACEHOLDER_VALUE || selected == null ? '' : selected}
                        placeholder={intl.formatMessage({
                            id: isMultipleSelected ? 'hedge_select_multiple' : 'common_add_content',
                        })}
                        onChange={(event) => onChange(name, [event.target.value])}
                        isDisabled={isMultipleSelected}
                        type="text"
                        warning={warning}
                        error={error}
                        isInvalid={isInvalid}
                        isWarning={isWarning}
                    />

                    {checkbox}
                </>
            );
        case 'ComboBox':
            return (
                <>
                    <ComboboxField
                        id={id}
                        name={name}
                        label={label}
                        value={selected}
                        options={options}
                        valueKey="id"
                        labelKey="description"
                        isDisabled={isMultipleSelected}
                        onChange={(value) => onChange(name, [value])}
                        showPlaceholder={!requiredFields?.includes(name) || isMultipleSelected}
                        placeholderValue={isMultipleSelected ? MULTIPLE_PLACEHOLDER_VALUE : null}
                        placeholderLabel={
                            isMultipleSelected ? intl.formatMessage({ id: 'hedge_select_multiple' }) : placeholder
                        }
                        warning={warning}
                        error={error}
                        isInvalid={isInvalid}
                        isWarning={isWarning}
                    />

                    {checkbox}
                </>
            );
        case 'NumericUpDown':
            return (
                <>
                    <NumberInputField
                        id={id}
                        name={name}
                        label={label}
                        value={selected == null ? '' : selected}
                        onChange={(value) => onChange(name, [value])}
                        isDisabled={isMultipleSelected}
                        placeholder={intl.formatMessage({
                            id: isMultipleSelected ? 'hedge_select_multiple' : 'common_select_number',
                        })}
                        warning={warning}
                        error={error}
                        isInvalid={isInvalid}
                        isRequired={requiredFields?.includes(name)}
                        isWarning={isWarning}
                    />

                    {checkbox}
                </>
            );
        // for Checkbox in Bulk update we use Combobox with two options, this approach doesn't need metadata changes
        case 'CheckBox':
            return (
                <>
                    <ComboboxField
                        id={id}
                        name={name}
                        label={label}
                        value={typeof selected === 'boolean' ? (selected === true ? 0 : 1) : selected}
                        options={[
                            { id: 0, description: 'True' },
                            { id: 1, description: 'False' },
                        ]}
                        valueKey="id"
                        labelKey="description"
                        isDisabled={isMultipleSelected}
                        onChange={(value) => onChange(name, [value])}
                        showPlaceholder={isMultipleSelected}
                        placeholderValue={-1}
                        placeholderLabel={intl.formatMessage({ id: 'hedge_select_multiple' })}
                        warning={warning}
                        error={error}
                        isInvalid={isInvalid}
                        isWarning={isWarning}
                    />

                    {checkbox}
                </>
            );
        default:
            return null;
    }
};

export default BulkUpdateFields;
