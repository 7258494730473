export const MULTIPLE_PLACEHOLDER_VALUE = 'multiple';
export const BULK_FIELD_TOUCHED_PLACEHOLDER_VALUE = 'BULK_FIELD_TOUCHED_PLACEHOLDER_VALUE';
export const CHECKED = 'checked';
export const UNCHECKED = 'unchecked';
export const INDETERMINATE = 'indeterminate';
export const GENERATION_ASSET_TYPE_KEY = 'generation_asset';
export const DSM_TYPE_KEY = 'dsm';

export const transitionMatrixGridKeys = {
    PERIODS: 'matrixPeriods',
    TRANSITIONS: 'matrixTransitions',
};

export const resourceVariablesGridKeys = {
    OPERATIONAL_DATES_KEY: 'operationalDates',
    STARTUP_SHUTDOWN_KEY: 'startupShutdown',
    RUN_CONSTRAINTS_KEY: 'runConstraints',
    HEAT_RATES_KEY: 'fuelHeatRates',
    FUEL_COSTS_KEY: 'fuelCosts',
    COST_EMISSIONS_KEY: 'fuelCostEmissions',
    GENERATION_CAPACITY_KEY: 'generationCapacity',
    FORCED_OUTAGES_KEY: 'forcedOutages',
    ANCILLARY_CONTRIBUTIONS_KEY: 'ancillaryContributions',
    LINKED_RESOURCES_KEY: 'linkedResource',
    GENERATION_DERATE_KEY: 'generationDerate',
    FORCED_OUTAGE_TEMP_KEY: 'forcedOutageTemp',
};

export const additionalGridKeys = {
    SCHEDULED_OUTAGES: 'thermalScheduledOutages',
    HISTORICAL_OUTPUT: 'thermalHistoricalOutput',
};

// sorting in Thermal is done by startDate despite the fact that we don't show it everywhere in the UI
export const multisortPairs = {
    periodId: 'startDate',
    periodName: 'startDate',
    description: 'startDate',
    dispatchTypeId: 'dispatchType',
    ucSubHourlyMethodId: 'ucSubHourlyMethod',
    startupFuelType: 'startupFuelType',
    peakPeriodId: 'peakPeriod',
    fuelTypeId: 'fuelType',
    emissionControlId: 'emissionControl',
    fuelEmissionBlendId: 'fuelEmissionBlend',
    toThisDay: 'toThisDay',
    ancillaryProductId: 'ancillaryProduct',
    parentId1: 'parentDescription1',
    parentId2: 'parentDescription2',
    parentId3: 'parentDescription3',
    parentStateId1: 'parentStateDescription1',
    parentStateId2: 'parentStateDescription2',
    parentStateId3: 'parentStateDescription3',
    unitStateId: 'unitStateDescription',
};

export const resourceVariablesGroupKeys = {
    PERIODS: 'periods',
    FUELS: 'fuels',
    GENERATION_CAPACITY: 'generation-capacity',
    FORCED_OUTAGES: 'forced-outages',
    ANCILLARY_CONTRIBUTION: 'ancillary-contributions',
    LINKED_RESOURCE: 'link-resources',
    GENERATION_DERATE: 'generation-derate',
    FORCED_OUTAGE_TEMP: 'forced-outage-temp',
};

export const resourceVariablesGroupedGridKeys = {
    [resourceVariablesGroupKeys.PERIODS]: [
        resourceVariablesGridKeys.OPERATIONAL_DATES_KEY,
        resourceVariablesGridKeys.STARTUP_SHUTDOWN_KEY,
        resourceVariablesGridKeys.RUN_CONSTRAINTS_KEY,
    ],
    [resourceVariablesGroupKeys.FUELS]: [
        resourceVariablesGridKeys.HEAT_RATES_KEY,
        resourceVariablesGridKeys.FUEL_COSTS_KEY,
        resourceVariablesGridKeys.COST_EMISSIONS_KEY,
    ],
    [resourceVariablesGroupKeys.GENERATION_CAPACITY]: [resourceVariablesGridKeys.GENERATION_CAPACITY_KEY],
    [resourceVariablesGroupKeys.FORCED_OUTAGES]: [resourceVariablesGridKeys.FORCED_OUTAGES_KEY],
    [resourceVariablesGroupKeys.ANCILLARY_CONTRIBUTION]: [resourceVariablesGridKeys.ANCILLARY_CONTRIBUTIONS_KEY],
    [resourceVariablesGroupKeys.LINKED_RESOURCE]: [resourceVariablesGridKeys.LINKED_RESOURCES_KEY],
    [resourceVariablesGroupKeys.GENERATION_DERATE]: [resourceVariablesGridKeys.GENERATION_DERATE_KEY],
    [resourceVariablesGroupKeys.FORCED_OUTAGE_TEMP]: [resourceVariablesGridKeys.FORCED_OUTAGE_TEMP_KEY],
};

export const resourceVariableRequiredFieldKeys = {
    FORCED_OUTAGES_EFOR: 'efor',
    FORCED_OUTAGES_PERCENT_OUTAGE: 'percentOutage',
    FORCED_OUTAGES_OUTAGE_DURATION_MEAN: 'outageDurationMean',
    FORCED_OUTAGES_OUTAGE_STD_DEV: 'outageStdDev',
};
