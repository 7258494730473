import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { NavHashLink } from 'react-router-hash-link';

import { preserveConnectionSearchParams } from '../../services/connections';
import { scrollFromTopWithOffset, basisConfiguratorSectionScrollOffset } from '../../services/utils';

const ConfiguratorNavigation = ({ configuratorSections, activeLinkId, basisId }) => {
    return (
        <Navigation>
            {configuratorSections.map((section) => (
                <StyledNavHashLink
                    replace
                    key={section.id}
                    to={{
                        pathname: `/basis-configurator/${basisId}`,
                        hash: `#${section.id}`,
                        search: preserveConnectionSearchParams(window.location.search),
                    }}
                    scroll={(section) => scrollFromTopWithOffset(section, basisConfiguratorSectionScrollOffset)}
                    isActive={() => {
                        return section.id === activeLinkId;
                    }}
                    activeStyle={{ color: 'var(--chakra-colors-text-quinary)' }}
                >
                    {section.title}
                </StyledNavHashLink>
            ))}
        </Navigation>
    );
};

const Navigation = styled(Flex)`
    height: 68px;
    align-items: center;

    a:first-of-type {
        margin-left: 0;
    }
`;

const StyledNavHashLink = styled(NavHashLink)`
    font-size: 14px;
    font-weight: 400;
    color: var(--chakra-colors-gray-500);
    text-transform: capitalize;
    margin: 0px 24px;

    &:hover {
        color: var(--chakra-colors-gray-600);
    }
    &:focus {
        box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
        border-radius: var(--chakra-radii-base);
        outline: none;
    }
`;

export default ConfiguratorNavigation;
