import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Box, Flex, IconButton, Select, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { isEqual } from 'lodash';

import { getManualEditInputDbFieldName, getPeriodIdsFromSubParameter } from '../../../services/batteries';

import useCommonToast from '../../../hooks/useCommonToast';

import { onHasUnsavedChanges } from '../../../store/helpers/helpersSlice';

import CustomIconButton from '../../utils/CustomIconButton';

import { ReactComponent as InfoIcon } from '../../../icons/info.svg';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { ReactComponent as WarningIcon } from '../../../icons/warning.svg';
import { ReactComponent as AddItemIcon } from '../../../icons/item-icons/add-item.svg';

const SubParameterKey = ({
    parameter,
    batteryLevelUnit,
    totalSubParametersCount,
    onDeleteSubParameterModalOpen,
    onSidebarValueChange,
    ribbonStaticData,
    onAddSubParameterPeriodModalOpen,
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const dispatch = useDispatch();

    const { parameters, peakPeriods, ancillaries, readonlyFields } = ribbonStaticData;
    const isPercentOutageReadOnly = readonlyFields ? readonlyFields.includes('PERCENTOUTAGE') : false;

    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);

    const initialInputValue =
        parameter.batteryLevel ||
        parameter.percentOutage ||
        intl.formatMessage({ id: 'batteries_timeline_null_value' });

    const [inputValue, setInputValue] = useState(initialInputValue);
    const [selectState, setSelectState] = useState({
        peakPeriodId: parameter.peakPeriodId || '',
        productTypeId: parameter.productTypeId || '',
    });
    const [isInvalid, setIsInvalid] = useState(null);
    const [errorMessageId, setErrorMessageId] = useState(null);

    const dbParameter = parameters.find((p) => p.dbFieldName === parameter.parameterDbField);

    const onInputChange = (e, caller) => {
        const value = e.target.value;
        setInputValue(value);

        const isValid = validateInputValue(value);
        setIsInvalid(!isValid);

        const isDirty = !isEqual(Number(value), Number(parameter[caller]));
        if (isDirty !== hasUnsavedChanges) {
            dispatch(onHasUnsavedChanges(isDirty));
        }
    };

    const validateInputValue = (value) => {
        if (!value) {
            setErrorMessageId('batteries_timeline_add_modal_required_value');
            return false;
        }

        const numbersRegExp = new RegExp('^[0-9]+([.][0-9]+)?$');
        if (!numbersRegExp.test(value)) {
            setErrorMessageId('batteries_timeline_number_invalid_value');
            return false;
        }

        setErrorMessageId(null);
        return true;
    };

    const onKeyPress = (e) => {
        const translatedNull = intl.formatMessage({ id: 'batteries_timeline_null_value' });

        if (e.key === 'Enter' && !isInvalid && e.target.value !== translatedNull) {
            // the dbFieldName has to be populated with the underlying 'dbFieldName' of the parameter
            const inputDbField = getManualEditInputDbFieldName(parameter.parameterDbField);

            const payload = {
                parameter: { ...dbParameter.value, dbFieldName: inputDbField },
                subPeriodIds: getPeriodIdsFromSubParameter(parameter.periods),
                value: Number(e.target.value),
            };

            onSidebarValueChange(payload);
        }
    };

    const onSelectChange = (which, val) => {
        setSelectState((values) => {
            return { ...values, [which]: val };
        });

        const payload = {
            parameter: { ...dbParameter.value, dbFieldName: which === 'productTypeId' ? 'ProductType' : 'PeakPeriod' },
            subPeriodIds: getPeriodIdsFromSubParameter(parameter.periods),
            value: val === 0 ? null : val,
        };

        onSidebarValueChange(payload);
    };

    const onCloseIconClick = () => {
        const payload = {
            ids: getPeriodIdsFromSubParameter(parameter.periods),
            dbTableName: dbParameter.value.dbTableName,
        };

        // deleting for paramaters 'Max. Charge power at SOC' / 'Max. Discharge power at SOC' is prohibited
        // when there are less than 1 subparameter related values for the given parameter
        if (
            (parameter.parameterDbField === 'MaxChargePowerSOC' ||
                parameter.parameterDbField === 'MaxDischargePowerSOC') &&
            totalSubParametersCount <= 1
        ) {
            const errorMessage = intl.formatMessage({ id: 'batteries_timeline_sidebar_invalid_delete_action' });
            toast({
                status: 'error',
                message: errorMessage,
            });
        } else {
            onDeleteSubParameterModalOpen(payload);
        }
    };

    const onPlusIconClick = () => {
        onAddSubParameterPeriodModalOpen(parameter);
    };

    const selectWrapper = (which) => {
        const val = selectState[which];
        const options = which === 'peakPeriodId' ? peakPeriods : ancillaries;
        return (
            <StyledSelect value={val} onChange={(e) => onSelectChange(which, Number(e.target.value))}>
                <option value="">-</option>
                {options.map((item, idx) => (
                    <option key={idx} value={item.id}>
                        {item.description}
                    </option>
                ))}
            </StyledSelect>
        );
    };

    const hasEmptyValue =
        initialInputValue === intl.formatMessage({ id: 'batteries_timeline_null_value' }) || initialInputValue === '';

    const valueInputGroup = (which) => {
        const readOnly = which === 'percentOutage' && isPercentOutageReadOnly ? true : false;

        return (
            <InputGroup width="90px">
                <StyledInput
                    type="text"
                    value={inputValue}
                    onChange={(e) => onInputChange(e, which)}
                    isInvalid={isInvalid}
                    onKeyPress={onKeyPress}
                    readOnly={readOnly}
                />

                {(isInvalid || hasEmptyValue) && (
                    <InputRightElement>
                        <Tooltip
                            label={
                                <FormattedMessage
                                    id={isInvalid ? errorMessageId : 'batteries_timeline_null_parameter_label_tooltip'}
                                />
                            }
                        >
                            <StyledCustomIconButton
                                isWarning={isInvalid}
                                icon={isInvalid ? <WarningIcon /> : <InfoIcon />}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Tooltip>
                    </InputRightElement>
                )}
            </InputGroup>
        );
    };

    const renderValue = () => {
        const percentageDescription = intl.formatMessage(
            { id: 'batteries_timeline_sidebar_at_battery_level' },
            { unit: batteryLevelUnit }
        );
        const outageDescription = intl.formatMessage({ id: 'batteries_timeline_sidebar_at_percent_outage' });
        const ancillaryDescription = intl.formatMessage({ id: 'batteries_timeline_sidebar_at_ancillary' });

        switch (parameter.parameterDbField) {
            // {Peak period} / {batteryLevel}
            case 'MaxChargePowerSOC':
            case 'MaxDischargePowerSOC':
            case 'OperationCost':
            case 'CostlessAdder':
                return (
                    <>
                        {selectWrapper('peakPeriodId')}
                        <StyledDescription isFullWidth={true}>{percentageDescription}</StyledDescription>
                        {valueInputGroup('batteryLevel')}
                    </>
                );

            // {PeakPeriod}
            case 'ChargingCosts':
            case 'DischargingCosts':
            case 'ChargingAdder':
            case 'DischargingAdder':
            case 'RTChargingAdder':
            case 'RTDischargingAdder':
            case 'MaxEnergyStorage':
            case 'MinEnergyStorage':
                return <>{selectWrapper('peakPeriodId')}</>;

            // at {percentOutage}
            case 'EFOR':
            case 'OutageMean':
            case 'OutageStandard':
                return (
                    <>
                        <StyledDescription isFullWidth={true}>{outageDescription}</StyledDescription>
                        {valueInputGroup('percentOutage')}
                    </>
                );

            // {PeakPeriod} at {AncillaryProductType}
            case 'AllowedContribution':
                return (
                    <>
                        {selectWrapper('peakPeriodId')}
                        <StyledDescription isFullWidth={false}>{ancillaryDescription}</StyledDescription>
                        {selectWrapper('productTypeId')}
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <li>
            <SubParameterWrapper>
                {renderValue()}
                <IconButton
                    bg="none"
                    minW="25px"
                    borderRadius="base"
                    w="25px"
                    variant="batteries-delete"
                    icon={<CloseIcon />}
                    onClick={onCloseIconClick}
                />

                <IconButton
                    bg="none"
                    minW="25px"
                    w="25px"
                    variant="batteries-plus"
                    borderRadius="base"
                    icon={<AddItemIcon />}
                    onClick={onPlusIconClick}
                />
            </SubParameterWrapper>
        </li>
    );
};

const SubParameterWrapper = styled(Flex)`
    height: 50px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    margin-bottom: 5px;
    padding: 5px;
`;

const StyledSelect = styled(Select)`
    background: var(--chakra-colors-white);
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:focus {
        background: var(--chakra-colors-white);
    }
`;

const StyledCustomIconButton = styled(CustomIconButton, { shouldForwardProp: (prop) => prop !== 'isWarning' })`
    margin-top: 0px;
    svg {
        width: 16px;
        height: 16px;
    }
    svg circle,
    svg path {
        stroke: ${(props) => (props.isWarning ? 'var(--chakra-colors-transparent)' : 'var(--chakra-colors-gray-500)')};
    }
`;

const StyledInput = styled(Input)`
    background: var(--chakra-colors-white);
    font-size: 13px;
    width: 90px;

    &:hover,
    &:focus {
        background: var(--chakra-colors-white);
    }
`;

const StyledDescription = styled(Box, { shouldForwardProp: (prop) => prop !== 'isFullWidth' })`
    width: ${(props) => (props.isFullWidth === true ? '100%' : 'unset')};
    margin: ${(props) => (props.isFullWidth === true ? '0' : '0 5px')};
    font-size: 12px;
    text-align: center;
`;

export default SubParameterKey;
