import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';

/**
 * This component does form dirty check
 *
 * @param {boolean} isFormDirty - form's "dirty" flag
 * @param {function} onFormDirtyChange - change "dirty" handler
 * @param {object} initialValues - initial form values
 * @param {object} config - containing config for this and nested components
 * @returns {null}
 * @constructor
 */
const AutoformFormikDirtyCheck = ({ isFormDirty, onFormDirtyChange, initialValues, config }) => {
    const { values } = useFormikContext();

    const defaultDirtyCheck = useCallback(() => {
        return !_.isEqual(values, initialValues);
    }, [values, initialValues]);

    useEffect(() => {
        const dirtyCheckHandler = config?.dirtyCheck ?? defaultDirtyCheck;
        const dirty = dirtyCheckHandler({ values, initialValues });

        if (isFormDirty !== dirty) {
            onFormDirtyChange(dirty);
        }
    }, [defaultDirtyCheck, values, config?.dirtyCheck, initialValues, isFormDirty, onFormDirtyChange]);

    return null;
};

export default AutoformFormikDirtyCheck;
