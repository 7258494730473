import { useEffect, useMemo, useState } from 'react';
import { getItemFormKey, getTableFormKey } from '../../components/autoform/utils/autoformUtils';

const useUsersInitialValues = (
    level1TableId,
    selectedItem,
    selectedItemData,
    descriptionColumnName,
    userBooks,
    userRoles
) => {
    const [initialValues, setInitialValues] = useState();
    const titleValue = useMemo(() => {
        let tableFormKey = getTableFormKey(level1TableId);
        let itemFormKey = getItemFormKey(selectedItem);
        return {
            [tableFormKey]: {
                [itemFormKey]: {
                    [descriptionColumnName]: selectedItemData && selectedItemData[descriptionColumnName],
                },
            },
        };
    }, [level1TableId, selectedItem, selectedItemData, descriptionColumnName]);

    const initialFormValues = useMemo(
        () => ({
            ...titleValue,
            userBooks: userBooks.reduce((acc, userBook) => {
                acc[userBook.value] = userBook.isChecked;
                return acc;
            }, {}),
            userRoles: userRoles.reduce((acc, userRole) => {
                acc[userRole.value] = userRole.isChecked;
                return acc;
            }, {}),
        }),
        [titleValue, userBooks, userRoles]
    );

    useEffect(() => {
        setInitialValues(initialFormValues);
    }, [initialFormValues]);

    return { initialValues, setInitialValues };
};

export default useUsersInitialValues;
