import React, { useCallback } from 'react';
import { Box, Flex, Divider } from '@chakra-ui/react';
import useNavigation from '../../../hooks/useNavigation';
import NavigationImageLink from './NavigationImageLink';
import NavigationButton from './NavigationButton';
import NavigationMenuGroup from './NavigationMenuGroup';
import NavigationMenu from './NavigationMenu';
import NavigationMenuItem from './NavigationMenuItem';
import NavigationLink from './NavigationLink';
import NavigationDarkModeCheckbox from './NavigationDarkModeCheckbox';

import styled from '@emotion/styled/macro';

const DesktopNavigation = () => {
    const { config } = useNavigation();

    const renderItem = useCallback((item) => {
        let result;

        switch (item?.type) {
            case 'ImageLink':
                result = <NavigationImageLink config={item} />;

                break;
            case 'Button':
                result = <NavigationButton config={item} />;

                break;
            case 'Menu':
                result = <NavigationMenu config={item} renderItem={renderItem} />;

                break;
            case 'MenuItemsGroup':
                result = <NavigationMenuGroup config={item} renderItem={renderItem} />;

                break;
            case 'MenuItem':
                result = <NavigationMenuItem config={item} />;

                break;
            case 'Link':
                result = <NavigationLink config={item} />;

                break;

            case 'Checkbox':
                result = <NavigationDarkModeCheckbox config={item} />;

                break;
            default:
                result = null;

                break;
        }

        return result;
    }, []);

    const renderNavigationPart = useCallback(
        (navigationPart) => {
            let isNeedDelimiter = false;

            return navigationPart.map((item, itemIndex) => {
                let result = null;
                const isVisible = item?.desktopConfig?.isVisible;

                if (isVisible === undefined || isVisible === true) {
                    const renderedItem = renderItem(item);

                    result = renderedItem ? (
                        <React.Fragment key={itemIndex}>
                            {isNeedDelimiter ? (
                                <Divider
                                    orientation="vertical"
                                    height="30px"
                                    mx={item?.desktopConfig?.delimiterGap ?? '30px'}
                                    borderColor={item?.desktopConfig?.delimiterColor ?? 'gray.200'}
                                />
                            ) : null}

                            <Box>{renderedItem}</Box>
                        </React.Fragment>
                    ) : null;
                }

                isNeedDelimiter |= !!result;

                return result;
            });
        },
        [renderItem]
    );

    return (
        <StyledFlex bg="background-tertiary">
            {config.map((navigationPart, navigationPartIndex) => (
                <Flex key={navigationPartIndex} alignItems={'center'} flexShrink={0}>
                    {renderNavigationPart(navigationPart)}
                </Flex>
            ))}
        </StyledFlex>
    );
};

const StyledFlex = styled(Flex)`
    width: 100%;
    position: fixed;
    box-shadow: var(--chakra-shadows-sm);
    z-index: var(--chakra-zIndices-docked);
    justify-content: space-between;
    align-items: center;
    padding: var(--chakra-space-3) var(--chakra-space-6);

    @media screen and (max-width: 899px) {
        display: none;
    }

    @media screen and (min-width: 900px) {
        display: flex;
    }
`;

export default DesktopNavigation;
