import { useCallback, useState } from 'react';
import { Box, useDisclosure, Flex } from '@chakra-ui/react';

import useAutoformMetadata from './hooks/useAutoformMetadata';
import useAutoformTableHierarchy from './hooks/useAutoformTableHierarchy';

import AutoformTabs from './AutoformTabs';
import AutoformTableContainer from './AutoformTableContainer';
import AutoformItemFields from './AutoformItemFields';
import SubItemListContainer from './SubItemListContainer';
import ItemTopHeaderContainer from './ItemTopHeaderContainer';
import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import ItemFormContainer from './ItemFormContainer';
import EditChildTablesModal from './EditChildTablesModal';
import MisconfigurationError from './MisconfigurationError';
import GeneralValidationPanelContainer from './GeneralValidationPanelContainer';
import AutoformDownloadButton from './AutoformDownloadButton';
import AutoformUploadButton from './AutoformUploadButton';

import { getInputFormName } from './utils/autoformUtils';

import SortOrderProvider from '../grid/utils/SortOrderContext';
import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import useAutoformBulkUpdate from './hooks/useAutoformBulkUpdate';

/**
 * UI For right panel of level 3 and 4 autoforms.
 *
 * @param {object} portfolioItem - Item in the portfolios screen.
 * @param {callback} onNodeRename - Optional. Callback to rename the portfolio item in the portfolios screen.
 * @param {callback} onNodeItemDelete - Optional. Callback to delete the portfolio item in the portfolios screen.
 * @param {callback} onNodeOwnershipPercentageUpdate - Optional. Callback to change the percent ownership
 * of the item in the portfolio in the portfolios screen.
 * @param {object} config - containing config for this and nested components
 */
const Level4Autoform = ({
    config = {},
    onNodeRename,
    onNodeItemDelete,
    portfolioItem,
    onNodeOwnershipPercentageUpdate,
    helpPageUrlItemTypeId = null,
}) => {
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const { selectedItem: subItemId } = useAutoformTableMetadata(hierarchicalTables.level2[0]?.id);
    const editChildTableModal = useDisclosure();
    const [selectedTable, setSelectedTable] = useState();
    const [selectedLevel3ItemDescription, setSelectedLevel3ItemDescription] = useState();

    const { tablesWithChildrenMap: level2TablesWithChildrenMap } = useAutoformTableHierarchy(
        hierarchicalTables.level2,
        hierarchicalTables.level3
    );

    const { tablesWithChildrenMap: level3tablesWithChildrenMap } = useAutoformTableHierarchy(
        hierarchicalTables.level3,
        hierarchicalTables.level4
    );

    const onSelectItem = useCallback(
        (item) => {
            setSelectedLevel3ItemDescription(item.description);
            editChildTableModal.onOpen();
        },
        [editChildTableModal]
    );

    const onTabOpen = useCallback(
        (index, level2TableId) => setSelectedTable(level2TablesWithChildrenMap[level2TableId][index]),
        [level2TablesWithChildrenMap]
    );

    const { itemFormContainerConfig, handleDependentDataChange } = useAutoformBulkUpdate({
        tableId: selectedTable?.id ?? hierarchicalTables.level3[0]?.id,
        parentRecordId: subItemId,
        autoformConfig: config,
    });

    if (
        !metadata ||
        hierarchicalTables.level1.length === 0 ||
        hierarchicalTables.level2.length === 0 ||
        hierarchicalTables.level3.length === 0
    ) {
        return <MisconfigurationError />;
    }
    const level1TableId = hierarchicalTables.level1[0]?.id;

    const renderAutoformTable = (table) => (
        <AutoformTableContainer
            schemaCode={metadata.schemaCode}
            tableId={table.id}
            showDownloadUploadButton={hierarchicalTables.level2.length === 1}
            config={config}
            helpPageUrlItemTypeId={helpPageUrlItemTypeId}
        />
    );

    const renderSubItemList = (level2Table, setFormikValues, setFormikTouched, formikStatus, setFormikStatus) => (
        <SubItemListContainer
            tableId={level2Table.id}
            schemaCode={metadata.schemaCode}
            isLazy={config?.isSubItemListLazy}
            renderSubItem={
                config?.renderSubItem
                    ? (item) => config.renderSubItem(item, setSelectedLevel3ItemDescription, editChildTableModal)
                    : (item) => (
                          <>
                              <GeneralValidationPanelContainer tableId={level2Table.id} />
                              <AutoformItemFields tableId={level2Table.id} getInputFormName={getInputFormName} />
                              {!!level2TablesWithChildrenMap[level2Table.id]?.length && (
                                  <AutoformTabs
                                      onTabOpen={(index) => onTabOpen(index, level2Table.id)}
                                      renderTabPanel={(table) => (
                                          <AutoformTableContainer
                                              schemaCode={metadata.schemaCode}
                                              tableId={table.id}
                                              showDownloadUploadButton={hierarchicalTables.level3.length === 1}
                                              childrenTables={level3tablesWithChildrenMap[table.id]}
                                              helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                                              onSelectItem={onSelectItem}
                                              onActionInitiated={() =>
                                                  handleDependentDataChange({
                                                      setValues: setFormikValues,
                                                      setTouched: setFormikTouched,
                                                      status: formikStatus,
                                                      setStatus: setFormikStatus,
                                                  })
                                              }
                                              config={config}
                                          />
                                      )}
                                      mt={6}
                                      tables={level2TablesWithChildrenMap[level2Table.id]}
                                      tabListButtons={
                                          <Flex ml="auto" order={{ base: -1, xl: 0 }} w={{ base: '100%', xl: 'auto' }}>
                                              <AutoformDownloadButton
                                                  tablesMetadata={hierarchicalTables.level3}
                                                  parentTableId={level2Table.id}
                                                  mr={2}
                                                  showFullTableList={true}
                                              />
                                              {!!hierarchicalTables.level3.find((table) => table.canCreate) && (
                                                  <AutoformUploadButton
                                                      tablesMetadata={hierarchicalTables.level3}
                                                      parentTableId={level2Table.id}
                                                  />
                                              )}
                                          </Flex>
                                      }
                                  />
                              )}
                          </>
                      )
            }
            config={config}
        />
    );
    return (
        <SortOrderProvider>
            <ExpandableItemWrapper>
                <ItemFormContainer
                    includeLevel2Values={true}
                    onNodeRename={onNodeRename}
                    config={itemFormContainerConfig}
                >
                    {({ setValues, setTouched, status, setStatus }) => (
                        <>
                            <ItemTopHeaderContainer
                                tableId={level1TableId}
                                onNodeItemDelete={onNodeItemDelete}
                                portfolioItem={portfolioItem}
                                onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                                config={config}
                            />

                            <Box px={6}>
                                <GeneralValidationPanelContainer tableId={level1TableId} />
                                {config?.level1InputFields ?? (
                                    <AutoformItemFields tableId={level1TableId} getInputFormName={getInputFormName} />
                                )}
                            </Box>

                            <AutoformTabs
                                renderTabPanel={(level2Table) =>
                                    level2TablesWithChildrenMap[level2Table.id]?.length > 0 ? (
                                        renderSubItemList(level2Table, setValues, setTouched, status, setStatus)
                                    ) : (
                                        <Box px={6}>{renderAutoformTable(level2Table)}</Box>
                                    )
                                }
                                tabListPx={6}
                                tables={hierarchicalTables.level2}
                                tabListButtons={
                                    <Flex ml="auto" order={{ base: -1, xl: 0 }} w={{ base: '100%', xl: 'auto' }}>
                                        <AutoformDownloadButton
                                            tablesMetadata={hierarchicalTables.level2}
                                            parentTableId={level1TableId}
                                            mr={2}
                                            showFullTableList={true}
                                        />
                                        {!!hierarchicalTables.level2.find((table) => table.canCreate) && (
                                            <AutoformUploadButton
                                                tablesMetadata={hierarchicalTables.level2}
                                                parentTableId={level1TableId}
                                            />
                                        )}
                                    </Flex>
                                }
                            />
                            {editChildTableModal.isOpen && (
                                <EditChildTablesModal
                                    header={selectedLevel3ItemDescription}
                                    parentTableId={selectedTable?.id || hierarchicalTables.level3[0]?.id}
                                    onClose={editChildTableModal.onClose}
                                    config={config}
                                />
                            )}
                        </>
                    )}
                </ItemFormContainer>
            </ExpandableItemWrapper>
        </SortOrderProvider>
    );
};

export default Level4Autoform;
