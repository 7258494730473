const Text = {
    baseStyle: {
        color: 'gray.600',

        _dark: {
            color: 'gray.300',
        },
    },

    sizes: {
        md: {
            fontSize: '16px',
            lineHeight: '20px',
        },
        sm: {
            fontSize: '14px',
            lineHeight: '20px',
        },
        xs: {
            fontSize: '12px',
            lineHeight: '18px',
        },
    },

    variants: {
        dark: {
            color: 'gray.800',
            fontWeight: '500',
        },
        bold: {
            fontWeight: '600',
        },
        medium: {
            fontWeight: '500',
        },
        regular: {
            fontWeight: '400',
        },
        highlighted: {
            color: 'black',
            bg: 'orange.200',
            borderRadius: '4px',
            curspr: 'pointer',
            _dark: {
                color: 'black',
            },
        },
    },

    defaultProps: {
        variant: 'medium',
    },
};

export default Text;
