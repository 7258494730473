import { useMemo, useRef, useEffect, useCallback, useState } from 'react';
import { closestIndexTo } from 'date-fns';
import { Box, Flex, VStack, Skeleton, Tabs, TabList, TabPanels, Tab, TabPanel, useDisclosure } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import useCommonToast from '../../hooks/useCommonToast';

import { formatMultisort } from '../../services/items';
import { updateIssues } from '../../services/grid';

import EmptyGraph from '../itemData/EmptyGraph';

import DataGrid from '../grid/DataGrid';
import DataGridWrapper from '../grid/DataGridWrapper';
import AddDataModal from '../grid/AddDataModal';

import ConfirmationModal from '../modal/ConfirmationModal';

import GeneralFormValidationsAlert from '../forms/GeneralFormValidationsAlert';

import { mapGeneralValidationMessages } from '../autoform/utils/autoformUtils';

import DataChart from '../utils/DataChart';
import Tooltip from '../utils/Tooltip';
import SecondaryButton from '../utils/SecondaryButton';

import DeleteAllButton from './DeleteAllButton';

import { ReactComponent as GraphIcon } from '../../icons/graph.svg';
import { ReactComponent as TableRowsIcon } from '../../icons/table-rows.svg';
import { ReactComponent as AddIcon } from '../../icons/add-item.svg';
import { Highchart } from '../charts/Highchart';
import { DEFAULT_FILTERED_POINT_COUNT, DEFAULT_POINT_COUNT } from 'constants/charting';

const SubItemData = ({
    hasChart,
    actionBarSlot,
    tab,
    name,
    yAxisLabel,
    chartResource,
    gridResource,
    columns,
    addDataColumns,
    onLoadingStart,
    onLoadingEnd,
    onGridDataChange,
    successMessage,
    multisortPairs,
    onCellKeyPress,
    phrasing = {},
    shouldRefreshGridData = false,
    primaryKeyName,
}) => {
    const { toast } = useCommonToast();
    const intl = useIntl();
    const gridApi = useRef();
    const deleteAllModal = useDisclosure();
    const deleteModal = useDisclosure();
    const addDataModal = useDisclosure();
    const globalErrorsDisclosure = useDisclosure({ defaultIsOpen: true });
    const globalWarningsDisclosure = useDisclosure({ defaultIsOpen: true });

    const [isChartLoading, setIsChartLoading] = useState(false);
    const [index, setIndex] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [isChartLoaded, setIsChartLoaded] = useState(false);
    const [idsForDeletion, setIdsForDeletion] = useState([]);
    const [issues, setIssues] = useState([]);
    const [globalErrors, setGlobalErrors] = useState([]);
    const [globalWarnings, setGlobalWarnings] = useState([]);
    const [dataSourcePagination, setDataSourcePagination] = useState();

    const createPhrasing = phrasing.create;
    const deletePhrasing = phrasing.delete;

    //For chart/HighChart to display 'No Results' in case of no data
    const canCreate = typeof gridResource?.create === 'function';

    const globalIssues = useMemo(
        () => ({ errors: globalErrors, warnings: globalWarnings }),
        [globalErrors, globalWarnings]
    );

    const onChartRead = useCallback(
        async (range, existingData) => {
            const chartTabKey = `${tab}-0`;

            onLoadingStart && onLoadingStart(chartTabKey);
            setIsChartLoading(true);

            try {
                const pagination = {
                    pointCount: range ? DEFAULT_FILTERED_POINT_COUNT : DEFAULT_POINT_COUNT,
                    startDateFrom: range ? range.start : null,
                    startDateTo: range ? range.end : null,
                };

                const data = await chartResource.read(pagination);

                // // check if the endpoint is returning a data array and not full highchart config
                const isDataOnly = Array.isArray(data);

                if (!isDataOnly) {
                    setChartData(data);
                    return data;
                }

                if (range && existingData) {
                    // @todo can we instead use the same approach shown here?
                    // the `navigator.series` is different from the root `series`
                    // https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/stock/demo/lazy-loading/

                    // insert the new granular data between the 2 data points that are closest to the received data
                    const existingDates = existingData.map(([date]) => date);
                    const startIndex = closestIndexTo(data[0][0], existingDates);
                    const endIndex = closestIndexTo(data[data.length - 1][0], existingDates) + 1;

                    const result = existingData.slice(0, startIndex).concat(data, existingData.slice(endIndex));

                    setChartData(result);
                } else {
                    // setup/reset everything
                    setChartData(data);
                }
            } catch (error) {
                if (error?.response?.status !== 401) {
                    let serverError;

                    if (error?.response?.status !== 500) {
                        serverError = error?.response?.data?.error || error?.response?.data?.title;
                    }

                    toast({
                        status: 'error',
                        message: serverError || intl.formatMessage({ id: 'common_generic_loading_error' }),
                    });
                }
            } finally {
                setIsChartLoading(false);
                onLoadingEnd && onLoadingEnd(chartTabKey);
            }
        },
        // Chakra's toast identity changes too often
        [chartResource, intl, tab, onLoadingStart, onLoadingEnd] // eslint-disable-line
    );

    const serverSideDatasource = useMemo(() => {
        return {
            async getRows(params) {
                const gridTabKey = `${tab}-1`;
                onLoadingStart && onLoadingStart(gridTabKey);

                const pagination = {
                    paging: {
                        pageSize: 500,
                        page: params.request.startRow / 500 + 1,
                    },
                    sorting: formatMultisort(params.request.sortModel, multisortPairs),
                };
                setDataSourcePagination(pagination);

                const data = await gridResource.read(pagination);

                if (data.items.length === 0) {
                    params.api.showNoRowsOverlay();
                } else {
                    params.api.hideOverlay();
                }

                params.success({ rowData: data.items, rowCount: data.totalCount });

                onLoadingEnd && onLoadingEnd(gridTabKey);
            },
        };

        //Adding multisortPairs will cause constant reloads
    }, [gridResource, tab, onLoadingStart, onLoadingEnd]); // eslint-disable-line

    const serverSideDataSourceView = useMemo(() => {
        return {
            async getRows() {
                const data = await gridResource.getDataSourceView(dataSourcePagination);
                return data;
            },
        };
    }, [gridResource, dataSourcePagination]);

    const onGridReady = useCallback((params) => {
        gridApi.current = params.api;
    }, []);

    const resetServerValidation = useCallback((resetIssues = true) => {
        resetIssues && setIssues([]);
        setGlobalErrors([]);
        setGlobalWarnings([]);
    }, []);

    const refreshGridData = useCallback(() => {
        resetServerValidation();

        if (gridApi.current) {
            gridApi.current.refreshServerSideStore({
                purge: true,
            });
        }
    }, [resetServerValidation]);

    const onDeleteRow = useCallback(
        async (rowIds) => {
            if (deletePhrasing) {
                deleteModal.onClose();
            }

            const rowsForDeletion = rowIds.map((id) => gridApi.current.getRowNode(id).data);
            const gridTabKey = `${tab}-1`;
            onLoadingStart && onLoadingStart(gridTabKey);

            try {
                await gridResource.delete(rowsForDeletion);
                onGridDataChange && onGridDataChange();

                toast(intl.formatMessage({ id: 'common_delete_grid_msg' }, { n: rowsForDeletion.length }));

                refreshGridData();
            } catch (error) {
                if (error?.response?.status !== 401) {
                    let serverError;

                    if (error.response.status !== 500) {
                        serverError = error.response.data.error || error.response.data.title;
                    }

                    toast({
                        status: 'error',
                        message: serverError || intl.formatMessage({ id: 'common_generic_delete_error' }),
                    });
                }
            } finally {
                onLoadingEnd && onLoadingEnd(gridTabKey);
            }
        },
        // Chakra's toast identity changes too often
        [gridResource, intl, tab, onLoadingStart, onLoadingEnd] // eslint-disable-line
    );

    const onDataChange = useCallback(
        async (rowIds) => {
            const successToast = successMessage || intl.formatMessage({ id: 'common_generic_item_change_success' });
            const rows = rowIds.map((id) => gridApi.current.getRowNode(id).data);

            const gridTabKey = `${tab}-1`;
            onLoadingStart && onLoadingStart(gridTabKey);

            // reset the previous errors and warnings
            resetServerValidation(false);

            try {
                const { data = {} } = await gridResource.update(rows);
                onGridDataChange && onGridDataChange();

                let updatedIssues = [];

                if (data.issues) {
                    // if we had previous errors, we make a change and now we only have warnings
                    // we swap messages in the issues dialog for the row
                    updatedIssues = updateIssues(issues, data.issues);
                } else {
                    // if we had previous errors/warnings, we made a change and we don't have anything wrong
                    // we delete all messages in the issues dialog for the row
                    const ids = rowIds.map((rowId) => gridApi.current.getRowNode(rowId).data[primaryKeyName]);
                    updatedIssues = issues.filter((issueObj) => !ids.includes(issueObj.id));
                }

                if (data.globalIssues) {
                    // make sure to re-open the disclosures again
                    globalWarningsDisclosure.onOpen();

                    // handle global warnings
                    const { warnings } = mapGeneralValidationMessages(data.globalIssues);
                    setGlobalWarnings(warnings);
                }

                setIssues(updatedIssues);

                toast(successToast);
            } catch (error) {
                if (error.response.status !== 500 && error.response.status !== 401) {
                    if (error.response?.data.issues) {
                        setIssues((prev) => [...prev, ...error.response.data.issues]);
                    }

                    if (error.response?.data.globalIssues) {
                        // make sure to re-open the disclosures again,
                        // because the user might have closed them and is submitting the form again
                        globalErrorsDisclosure.onOpen();
                        globalWarningsDisclosure.onOpen();

                        // handle global errors and warnings
                        const { warnings, errors } = mapGeneralValidationMessages(error.response.data.globalIssues);
                        setGlobalWarnings(warnings);
                        setGlobalErrors(errors);
                    }

                    if (error.response?.data?.error || error.response?.data?.title) {
                        const serverError = error.response.data.error || error.response.data.title;

                        toast({
                            status: 'error',
                            message: serverError || intl.formatMessage({ id: 'common_generic_saving_error' }),
                        });
                    }
                }
            } finally {
                onLoadingEnd && onLoadingEnd(gridTabKey);
            }
        },
        // Chakra's toast identity changes too often
        // eslint-disable-next-line
        [
            gridResource,
            intl,
            tab,
            onLoadingStart,
            onLoadingEnd,
            issues,
            globalErrorsDisclosure,
            globalWarningsDisclosure,
            resetServerValidation,
        ]
    );

    const onDeleteAllConfirm = useCallback(
        async () => {
            deleteAllModal.onClose();

            const gridTabKey = `${tab}-1`;
            onLoadingStart && onLoadingStart(gridTabKey);

            try {
                await gridResource.deleteAll();
                onGridDataChange && onGridDataChange();
            } catch (error) {
                if (error?.response?.status !== 401) {
                    let serverError;

                    if (error.response.status !== 500) {
                        serverError = error.response.data.error || error.response.data.title;
                    }

                    toast({
                        status: 'error',
                        message: serverError || intl.formatMessage({ id: 'common_delete_all_modal_on_error' }),
                    });
                }
            } finally {
                onLoadingEnd && onLoadingEnd(gridTabKey);
            }
        },
        // Chakra's toast and modal identity changes too often
        [gridResource, intl, tab, onLoadingStart, onLoadingEnd] // eslint-disable-line
    );

    const onViewChange = useCallback((newIndex) => setIndex(newIndex), []);

    const onAddRowsSuccess = () => {
        const successToast = successMessage || intl.formatMessage({ id: 'common_generic_item_change_success' });

        toast(successToast);

        onGridDataChange && onGridDataChange();
        refreshGridData();

        //refresh the chart after data has been added successfully
        if (hasChart) {
            onChartRead().finally(() => {
                setIsChartLoaded(true);
            });
        }
        renderChart();
    };

    const onGridDelete = useCallback(
        (rowIds) => {
            if (typeof gridResource.delete === 'function') {
                if (deletePhrasing) {
                    deleteModal.onOpen();
                    setIdsForDeletion(rowIds);
                } else {
                    onDeleteRow(rowIds);
                }
            }
        },
        [deletePhrasing, deleteModal, gridResource.delete, onDeleteRow]
    );

    useEffect(() => {
        if (hasChart) {
            onChartRead().finally(() => {
                setIsChartLoaded(true);
            });
        }
    }, [hasChart, onChartRead]);

    useEffect(() => {
        if (shouldRefreshGridData) {
            refreshGridData();
        }
    }, [shouldRefreshGridData, refreshGridData]);

    const grid = (
        <DataGridWrapper>
            <DataGrid
                name={name}
                serverSideDatasource={serverSideDatasource}
                serverSideDataSourceView={serverSideDataSourceView.getRows}
                onGridReady={onGridReady}
                onDataChange={onDataChange}
                onDelete={onGridDelete}
                rowModelType="serverSide"
                serverSideStoreType="partial"
                paginationPageSize={500}
                cacheBlockSize={500}
                columns={columns}
                onCellKeyPress={onCellKeyPress}
                phrasing={createPhrasing}
                gridResource={gridResource}
                onAddRowsSuccess={onAddRowsSuccess}
                addDataColumns={addDataColumns}
                multisortPairs={multisortPairs}
                issues={issues}
                globalIssues={globalIssues}
                primaryKeyName={primaryKeyName}
            />
        </DataGridWrapper>
    );

    const commonGridActions = (
        <>
            {typeof gridResource.deleteAll === 'function' && (
                <DeleteAllButton
                    mr={4}
                    onDelete={onDeleteAllConfirm}
                    refetchData={refreshGridData}
                    modalHeader={deletePhrasing?.header}
                    modalContent={deletePhrasing?.content}
                    mb={{ base: 4, xl: 0 }}
                />
            )}

            {typeof gridResource.create === 'function' && (
                <SecondaryButton
                    variant="secondary"
                    size="sm"
                    leftIcon={<AddIcon />}
                    onClick={addDataModal.onOpen}
                    textTransform="capitalize"
                >
                    <FormattedMessage id="common_add_rows_btn" />
                </SecondaryButton>
            )}
        </>
    );

    const commonGridAlerts = (
        <>
            {globalErrors.length > 0 && (
                <GeneralFormValidationsAlert
                    isOpen={globalErrorsDisclosure.isOpen}
                    onClose={globalErrorsDisclosure.onClose}
                    messages={globalErrors}
                />
            )}

            {globalWarnings.length > 0 && (
                <GeneralFormValidationsAlert
                    status="warning"
                    title={<FormattedMessage id="common_warning" />}
                    isOpen={globalWarningsDisclosure.isOpen}
                    onClose={globalWarningsDisclosure.onClose}
                    messages={globalWarnings}
                />
            )}
        </>
    );

    const renderChart = () => {
        if (!isChartLoaded) {
            return (
                <VStack spacing={4} align="stretch">
                    <Skeleton h="56px" />
                    <Skeleton h="56px" />
                    <Skeleton h="56px" />
                    <Skeleton h="56px" />
                    <Skeleton h="56px" />
                </VStack>
            );
        }
        if (!Array.isArray(chartData)) {
            return (
                <Highchart
                    fetchData={onChartRead}
                    initialData={chartData}
                    onAdd={addDataModal.onOpen}
                    canCreate={canCreate}
                />
            );
        }

        return chartData.length > 0 ? (
            <DataChart yAxisLabel={yAxisLabel} getData={onChartRead} data={chartData} isLoading={isChartLoading} />
        ) : (
            <EmptyGraph messageId="common_no_data_found" onAdd={addDataModal.onOpen} canCreate={canCreate} />
        );
    };

    return (
        <>
            {hasChart ? (
                <Tabs index={index} onChange={onViewChange} variant="icon" isLazy isManual lazyBehavior="keepMounted">
                    <Flex justifyContent="flex-end" wrap="wrap">
                        <Flex
                            order={{ base: 1, lg: 0 }}
                            w={{ base: '100%', lg: 'auto' }}
                            justifyContent="flex-end"
                            mt={{ base: 4, lg: 0 }}
                            mb={{ base: 4, lg: 0 }}
                        >
                            {actionBarSlot && actionBarSlot(index)}

                            {index === 1 && commonGridActions}
                        </Flex>

                        <TabList ml={4}>
                            <Tab>
                                <Tooltip label={intl.formatMessage({ id: 'common_graph_btn_aria_label' })}>
                                    <GraphIcon />
                                </Tooltip>
                            </Tab>

                            <Tab>
                                <Tooltip label={intl.formatMessage({ id: 'common_tabular_btn_aria_label' })}>
                                    <TableRowsIcon />
                                </Tooltip>
                            </Tab>
                        </TabList>
                    </Flex>

                    <TabPanels mt={2}>
                        <TabPanel>{renderChart()}</TabPanel>
                        <TabPanel>
                            {commonGridAlerts}
                            {grid}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            ) : (
                <Box>
                    <Flex mb={6} justifyContent="flex-end" wrap="wrap">
                        {actionBarSlot && actionBarSlot(index)}

                        {commonGridActions}
                    </Flex>

                    {commonGridAlerts}

                    {grid}
                </Box>
            )}

            {deleteModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={deleteModal.onClose}
                    header={deletePhrasing?.header}
                    hasExtraStep
                    content={deletePhrasing?.content}
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_delete" />
                        </Box>
                    }
                    onConfirm={() => onDeleteRow(idsForDeletion)}
                />
            )}

            {addDataModal.isOpen && (
                <AddDataModal
                    isOpen
                    onClose={addDataModal.onClose}
                    columns={addDataColumns}
                    gridResource={gridResource}
                    onAddRowsSuccess={onAddRowsSuccess}
                    heading={createPhrasing?.header}
                    instructions={createPhrasing?.content}
                />
            )}
        </>
    );
};

export default SubItemData;
