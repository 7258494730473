import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Text, Alert, AlertIcon, AlertTitle, AlertDescription, Skeleton, useDisclosure } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';

import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as ImagePlaceholder } from '../../icons/image-placeholder.svg';

import { addAutoformTableRow } from '../../store/autoform/autoformApi';

import { getNodeIcon, copyItem as copyAutoformRecordsService, addItemToPortfolios } from '../../services/items';

import useCommonToast from '../../hooks/useCommonToast';
import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import useAutoformLoadTableData from './hooks/useAutoformLoadTableData';
import useAutoformTableData from './hooks/useAutoformTableData';
import useAutoformMetadata from './hooks/useAutoformMetadata';
import useAutoformParams from './hooks/useAutoformParams';
import useHistoryPush from '../../hooks/useHistoryPush';
import { getDtoFromDataValueObject } from './utils/autoformUtils';

import AutoformAddRecordModal from './AutoformAddRecordModal';
import VirtualisedItemList from 'components/utils/VirtualisedItemList';
import LayoutSidebar from '../layout/LayoutSidebar';
import AddItemPortfolios from '../../components/utils/AddItemPortfolios';
import AddItemButton from '../../components/utils/AddItemButton';
import ItemLinkWrapper from '../layout/ItemLinkWrapper';
import ItemListWrapper from '../layout/ItemListWrapper';
import { checkDataSingle } from 'services/autoforms';
import { setSearchTerm } from '../../store/helpers/helpersSlice';
import useAddToPortfolios from '../../hooks/useAddToPortfolios';

/**
 * The sidebar list on the left. Renders all level 1 records
 * with the specified table ids as a virtualized list.
 *
 * TODO: Remove schemaCode
 *
 * @param {string} tableId - target table id
 * @param {object} config - flexible config for this and nested components
 * @returns {*}
 */
const SidebarItemListContainer = ({ tableId, config = {} }) => {
    let { url } = useRouteMatch();
    const search = useSelector((state) => state.helpers.searchTerm);

    const dispatch = useDispatch();
    const historyPush = useHistoryPush();
    const { data: items } = useAutoformTableData(tableId);

    const addToPortfolioModal = useDisclosure();
    const addItemModal = useDisclosure();
    const { toast } = useCommonToast();
    const intl = useIntl();

    const { metadata, itemTypeId } = useAutoformMetadata();
    const {
        primaryKeyColumnName,
        metadata: tableMetadata,
        descriptionColumnName,
        selectedItem,
    } = useAutoformTableMetadata(tableId);
    const { isLoading, loadingError, reloadData } = useAutoformLoadTableData(tableId, undefined, { pageSize: 100000 });
    const parameters = useAutoformParams();

    const [temporaryNode, setTemporaryNode] = useState(null);

    const { onAddToPortfolioModalOpen, closeAddToPortfolioModal, onAddItemToPortfolioSuccess } = useAddToPortfolios({
        onModalOpen: addToPortfolioModal.onOpen,
        onModalClose: addToPortfolioModal.onClose,
        temporaryNodeName: temporaryNode ? temporaryNode[descriptionColumnName] : '',
        setTemporaryNode,
    });

    const shouldShowIcons = useMemo(() => {
        if (itemTypeId) return true;
        if (config.shouldShowCopyIcon) return true;
        return false;
    }, [itemTypeId, config.shouldShowCopyIcon]);

    const selectItem = (itemId) =>
        config?.selectItem
            ? config.selectItem({ url, itemId })
            : historyPush({
                  pathname: `${url}/${encodeURIComponent(itemId)}`,
                  search: new URLSearchParams(window.location.search),
              });

    const memoizedResults = useMemo(() => {
        const term = search.trimStart().toLowerCase();
        const filteredList = items
            .filter((list) => `${list[descriptionColumnName]}`.toLowerCase().includes(term))
            .sort((item1, item2) => `${item1[descriptionColumnName]}`.localeCompare(`${item2[descriptionColumnName]}`));
        return filteredList;
    }, [items, search, descriptionColumnName]);

    const copiedItemIndex = useMemo(() => {
        return memoizedResults.findIndex((item) => item[primaryKeyColumnName] === selectedItem);
    }, [memoizedResults, selectedItem, primaryKeyColumnName]);

    const noSearchResults = search.trim().length > 0 && memoizedResults.length === 0;

    const onAddRecord = async (record) => {
        const addedRecord = await dispatch(
            addAutoformTableRow({
                schemaCode: metadata.schemaCode,
                tableId,
                record: getDtoFromDataValueObject(record, tableMetadata),
                parameters,
            })
        ).unwrap();
        selectItem(addedRecord.data.createdRecord[primaryKeyColumnName]);
        addItemModal.onClose();

        toast(config?.createSuccessMessage ?? intl.formatMessage({ id: 'common_record_creation_success' }));
        return addedRecord.data;
    };

    const onCheckData = async (record) => {
        const responseData = await checkDataSingle({
            tableId,
            record: getDtoFromDataValueObject(record, tableMetadata),
            parameters,
        });
        return responseData;
    };

    const onAutoformCopy = async (autoformItems) => {
        toast({ status: 'info', message: intl.formatMessage({ id: 'autoform_common_copy_btn_progress_message' }) });
        const response = await copyAutoformRecordsService(autoformItems[primaryKeyColumnName]);
        await Promise.all([reloadData(), selectItem(response.id)]);
    };

    const Icon = useMemo(() => getNodeIcon(+itemTypeId?.value, ImagePlaceholder), [itemTypeId]);

    const renderVirtualizedItem = useCallback(
        (virtualItem) => {
            const itemId = virtualItem[primaryKeyColumnName] + window.location.search;
            const wrapperProps = {
                // @todo itemId & url are used to generate a full URL to the details page of the item.
                // The string concat with window.location.search is a hacky way
                // to add any arbitrary query params (e.g. `parameterValues=6`) to the destination URL.
                itemId,
                url,
                isMaster: virtualItem?.SECURITYID,
                icon: config?.itemIcon ?? Icon,
                label: virtualItem[descriptionColumnName],
            };
            return config?.renderVirtualizedItem ? (
                config.renderVirtualizedItem(wrapperProps)
            ) : (
                <ItemLinkWrapper {...wrapperProps} />
            );
        },
        [config, Icon, descriptionColumnName, primaryKeyColumnName, url]
    );

    return (
        <LayoutSidebar
            itemType={'autoform'}
            selected={
                memoizedResults?.length && memoizedResults[copiedItemIndex]
                    ? memoizedResults[copiedItemIndex][descriptionColumnName]
                    : null
            }
            titleMessage={config.titleMessage ?? metadata.formLabel} // TODO provide title for side heading
            accordionPlaceholderId={config?.accordionPlaceholderId ?? 'autoform_select_item'} // TODO How do we provide translations for these labels
            searchPlaceholderId={config?.searchPlaceholderId ?? 'common_search'} // TODO How do we provide translations for these labels
            searchCallback={(item) => dispatch(setSearchTerm(item))}
            searchTerm={search}
        >
            <ItemListWrapper>
                <Box px={6} pb={2}>
                    {tableMetadata.canCreate && (
                        <AddItemButton leftIcon={<AddIcon />} onClick={addItemModal.onOpen}>
                            <Text size="sm" color="gray.900" pl={1} pt={1}>
                                <FormattedMessage id={config?.addNewItemMessageId ?? 'autoform_add_new_item'} />
                            </Text>
                        </AddItemButton>
                    )}
                    {isLoading ? (
                        <Skeleton w="100%" mt={6}>
                            <Box h="34px">
                                <FormattedMessage id="common_loading" />
                            </Box>
                        </Skeleton>
                    ) : loadingError ? (
                        <Alert status="error">
                            <AlertIcon />
                            <Box>
                                <AlertTitle textTransform="capitalize">
                                    <FormattedMessage id="common_error" />
                                </AlertTitle>

                                <AlertDescription>
                                    <FormattedMessage id="common_loading_error" values={{ items: 'items' }} />
                                </AlertDescription>
                            </Box>
                        </Alert>
                    ) : noSearchResults ? (
                        <Alert status="info">
                            <AlertIcon />

                            <FormattedMessage id="common_no_search_results" />
                        </Alert>
                    ) : null}
                </Box>
                {!noSearchResults && !loadingError && !isLoading && (
                    <VirtualisedItemList
                        config={config}
                        items={memoizedResults}
                        onCopy={
                            config?.onCopy
                                ? (autoformItems) =>
                                      config.onCopy(autoformItems, reloadData, selectItem, primaryKeyColumnName)
                                : (autoformItems) => onAutoformCopy(autoformItems)
                        }
                        itemIndex={copiedItemIndex}
                        shouldShowIcons={shouldShowIcons}
                        onAddToPortfolioModalOpen={onAddToPortfolioModalOpen}
                        renderItems={renderVirtualizedItem}
                    />
                )}
            </ItemListWrapper>

            {addItemModal.isOpen && (
                <AutoformAddRecordModal
                    tableId={tableId}
                    itemType={config?.addRecordModalItemType ?? 'Item'} // TODO: Avoid hardcoded string.
                    isOpen={addItemModal.isOpen}
                    onClose={addItemModal.onClose}
                    onCreateRecord={onAddRecord}
                    config={config}
                    onCheckData={onCheckData}
                />
            )}
            {addToPortfolioModal.isOpen && (
                <AddItemPortfolios
                    onClose={closeAddToPortfolioModal}
                    sourceId={temporaryNode[primaryKeyColumnName]}
                    itemName={temporaryNode[descriptionColumnName]}
                    onAddSuccess={onAddItemToPortfolioSuccess}
                    onSubmit={addItemToPortfolios}
                />
            )}
        </LayoutSidebar>
    );
};

export default SidebarItemListContainer;
