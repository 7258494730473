import { useState, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import { SimpleGrid, Heading, Box, Button, Spacer } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useSelector, useDispatch } from 'react-redux';

import { getPortfolioProperties } from '../../services/portfolios';
import { mapDynamicProperties, getUpdatedProperties } from '../../services/dynamicProperties';

import { onHasUnsavedChanges } from '../../store/helpers/helpersSlice';

import PortfolioTabs from './PortfolioTabs';

import InputFormikField from '../forms/InputFormikField';
import TextareaFormikField from '../forms/TextareaFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import CheckboxFormikField from './../forms/CheckboxFormikField';
import AutomaticFormikDirtyCheck from './../forms/AutomaticFormikDirtyCheck';

import PromptWrapper from './../utils/PromptWrapper';

import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';

const Portfolio = ({ portfolio, metadata, onSubmit, onDelete }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [portfolioProperties, setPortfolioProperties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);

    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);

    const fetchPortfolioProperties = useCallback(() => {
        getPortfolioProperties(portfolio.properties.id)
            .then((response) => {
                setPortfolioProperties(response);
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [portfolio.properties.id]);

    useEffect(() => {
        setIsLoading(true);
        fetchPortfolioProperties();
    }, [fetchPortfolioProperties, portfolio.properties.portfolioTypeId]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const initialFormValues = {
        id: portfolio.properties.id,
        name: portfolio.properties.name,
        bookId: portfolio.properties.bookId,
        // dispatchTypeId, portfolioTypeId, description, currencyId may be already recorded as `null`
        // defaulting to empty string to prevent chakra prop type validations
        dispatchTypeId: portfolio.properties.dispatchTypeId || '',
        description: portfolio.properties.description || '',
        portfolioTypeId: portfolio.properties.portfolioTypeId || '',
        isMaster: portfolio.properties.isMaster,
        properties: mapDynamicProperties(portfolioProperties),
    };

    const onFormSubmit = async (values, actions) => {
        const updatedProperties = getUpdatedProperties(portfolioProperties, values);

        await onSubmit({ ...values, updatedProperties }, actions);

        fetchPortfolioProperties();
    };

    const validateName = (value) => {
        const trimmed = value.trim();

        if (trimmed.length === 0) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Portfolio Name' });
        } else if (trimmed.length > 200) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Portfolio name', lengthRule: '200 characters or less' }
            );
        }
    };

    const validateDescription = (value) => {
        if (value.length > 255) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Portfolio description', lengthRule: '255 characters or less' }
            );
        }
    };

    const validateSelect = (value, label) => {
        if (value.length === 0) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }
    };

    const formLabels = {
        name: intl.formatMessage({ id: 'portfolios_properties_name_label' }),
        id: intl.formatMessage({ id: 'portfolios_properties_id_label' }),
        type: intl.formatMessage({ id: 'portfolios_properties_type_label' }),
        dispatchType: intl.formatMessage({ id: 'portfolios_properties_dispatch_type_label' }),
        book: intl.formatMessage({ id: 'portfolios_properties_book_label' }),
        description: intl.formatMessage({ id: 'portfolios_properties_description_label' }),
        selectPlaceholder: intl.formatMessage({ id: 'common_forms_select_option' }),
        master: intl.formatMessage({ id: 'common_master' }),
    };

    return (
        <>
            <PromptWrapper
                when={hasUnsavedChanges}
                header={<FormattedMessage id="common_discard_changes" />}
                content={<FormattedMessage id="common_confirmation_explanation" />}
            />

            <Box my={10} px={6}>
                <Formik enableReinitialize initialValues={initialFormValues} onSubmit={onFormSubmit}>
                    {({ isSubmitting, dirty, isValid, errors, submitCount }) => (
                        <Form>
                            <AutomaticFormikDirtyCheck isFormDirty={isFormDirty} onFormDirtyChange={setIsFormDirty} />

                            <ActionBar>
                                <Heading variant="h2">
                                    <FormattedMessage id="portfolios_properties" />
                                </Heading>

                                <Spacer />

                                <Button leftIcon={<DeleteIcon />} type="button" variant="special" onClick={onDelete}>
                                    <FormattedMessage id="common_delete_portfolio_btn" />
                                </Button>

                                <Button
                                    leftIcon={<SaveIcon />}
                                    isDisabled={
                                        !dirty ||
                                        (!isValid && (errors.description != null || errors.name != null)) ||
                                        (!isValid && submitCount > 0)
                                    }
                                    isLoading={isSubmitting}
                                    type="submit"
                                >
                                    <FormattedMessage id="common_save_changes_button" />
                                </Button>
                            </ActionBar>

                            <InputFormikField
                                id="name"
                                name="name"
                                validate={validateName}
                                label={formLabels.name}
                                type="text"
                                placeholder={formLabels.name}
                            />

                            <SimpleGrid columns={{ xl: 2 }} spacingX={6}>
                                <InputFormikField
                                    mt={4}
                                    id="id"
                                    name="id"
                                    isDisabled
                                    label={formLabels.id}
                                    type="text"
                                    placeholder={formLabels.id}
                                />

                                <CheckboxFormikField id="isMaster" name="isMaster" mt={10}>
                                    {formLabels.master}
                                </CheckboxFormikField>

                                <ComboboxFormikField
                                    mt={4}
                                    id="portfolioTypeId"
                                    name="portfolioTypeId"
                                    validate={(value) => validateSelect(value, formLabels.type)}
                                    label={formLabels.type}
                                    placeholderValue=""
                                    placeholderLabel={formLabels.selectPlaceholder}
                                    options={metadata.portfolioTypes}
                                    valueKey="id"
                                    labelKey="description"
                                />

                                <ComboboxFormikField
                                    mt={4}
                                    id="dispatchTypeId"
                                    name="dispatchTypeId"
                                    validate={(value) => validateSelect(value, formLabels.dispatchType)}
                                    label={formLabels.dispatchType}
                                    placeholderValue=""
                                    placeholderLabel={formLabels.selectPlaceholder}
                                    options={metadata.dispatchTypes}
                                    valueKey="id"
                                    labelKey="description"
                                />

                                <ComboboxFormikField
                                    mt={4}
                                    id="bookId"
                                    name="bookId"
                                    label={formLabels.book}
                                    options={metadata.books}
                                    valueKey="id"
                                    labelKey="name"
                                />
                            </SimpleGrid>

                            <TextareaFormikField
                                mt={4}
                                id="description"
                                name="description"
                                validate={validateDescription}
                                label={formLabels.description}
                                placeholder={formLabels.description}
                            />

                            <PortfolioTabs
                                isLoading={isLoading}
                                loadingError={loadingError}
                                properties={portfolioProperties}
                            />
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

const ActionBar = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--chakra-colors-border-secondary);
    margin: 16px 0;
    padding: 12px 0;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;

    button {
        width: 100%;
    }

    @media (min-width: 1280px) {
        button {
            width: auto;
        }
    }
`;

export default Portfolio;
