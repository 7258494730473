import { memo } from 'react';
import styled from '@emotion/styled/macro';
import { SimpleGrid } from '@chakra-ui/react';

import CustomCheckbox from '../forms/CustomCheckbox';

import Tooltip from '../utils/Tooltip';

const VariablesList = ({ variables, isVariableChecked, onVariableCheckedChange, isListInvalid, ...rest }) => {
    return (
        <SimpleGrid mt={2} columns={{ base: 1, md: 3 }} spacing={5} {...rest}>
            {variables.map((variable) => (
                <CustomCheckbox
                    key={variable.variableId}
                    name={variable.variableId}
                    isChecked={isVariableChecked(variable)}
                    onChange={(event) => onVariableCheckedChange(variable, event.target.checked)}
                    isInvalid={isListInvalid}
                >
                    <StyledTooltip label={variable.variableName}>
                        <VariableLabel>{variable.variableName}</VariableLabel>
                    </StyledTooltip>
                </CustomCheckbox>
            ))}
        </SimpleGrid>
    );
};

const StyledTooltip = styled(Tooltip)`
    display: inline-flex;
`;

const VariableLabel = styled.span`
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
`;

export default memo(VariablesList);
