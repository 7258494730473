import { Menu, MenuButton, MenuList, MenuItem, IconButton } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as ContextMenuIcon } from '../../icons/kebab-menu.svg';

const ContextMenu = (props) => {
    return (
        <Menu>
            <CustomMenuBtn
                as={IconButton}
                aria-label={props['aria-label']}
                icon={<ContextMenuIcon />}
                variant="ghost"
            />

            <MenuList>
                {props.items.map((item) => (
                    <MenuItem key={item.id} onClick={item.action} color="gray.900" textTransform="capitalize">
                        {item.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

const CustomMenuBtn = styled(MenuButton)`
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;

    &,
    &:active,
    &[data-active] {
        background: none;
    }
`;

export default ContextMenu;
