import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs, TabPanels, TabPanel, TabList } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import AutoformTableContainer from '../autoform/AutoformTableContainer';
import AutoformItemFields from '../autoform/AutoformItemFields';
import GeneralValidationPanelContainer from '../autoform/GeneralValidationPanelContainer';
import useAutoformTableMetadata from '../autoform/hooks/useAutoformTableMetadata';
import useAutoformTableData from '../autoform/hooks/useAutoformTableData';
import { getInputFormName } from '../autoform/utils/autoformUtils';
import useAutoformMetadata from '../autoform/hooks/useAutoformMetadata';

import { SHAPE_TYPE_COLUMN_NAME, SHAPE_TYPES, HOUR_COLUMN_NAME, VALUE_COLUMN_NAME } from '../../constants/shapeSet';

import { getEntryTypeCode } from '../../services/shapeSet';

import useShapeSetEntryTypeMap from '../../hooks/shapeSet/useShapeSetEntryTypeMap';

import TabListWrapper from '../utils/TabListWrapper';
import ShapeSetGrid from './ShapeSetGrid';
import SortOrderProvider from '../grid/utils/SortOrderContext';

const ShapeSetSubItem = () => {
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const level2Table = hierarchicalTables.level2[0];
    const { sortedColumns } = useAutoformTableMetadata(level2Table?.id);
    const level3Table = hierarchicalTables.level3[0];
    const { selectedItem } = useAutoformTableData(level2Table.id);
    const [shapeType, setShapeType] = useState(null);

    const { entryTypeMap, isLoading: isLoadingEntryTypeMap } = useShapeSetEntryTypeMap();

    const reduceColumnDefinitions = useCallback(
        (acc, col) => {
            let mappedCol = { ...col };
            if (col.field !== VALUE_COLUMN_NAME) {
                mappedCol.editable = false;
            }
            if (shapeType !== SHAPE_TYPES.Year_Month_DoW || col.field !== HOUR_COLUMN_NAME) {
                acc.push(mappedCol);
            }
            return acc;
        },

        [shapeType]
    );

    useEffect(() => {
        const shapeTypeCol = sortedColumns.find((col) => col.name === SHAPE_TYPE_COLUMN_NAME);
        if (shapeTypeCol && selectedItem) {
            const selectedShapeType = selectedItem[shapeTypeCol.name];
            setShapeType(getEntryTypeCode(selectedShapeType, entryTypeMap));
        }
    }, [sortedColumns, selectedItem, entryTypeMap]);

    const onChange = useCallback(
        (column) => (value) => {
            if (column.name === SHAPE_TYPE_COLUMN_NAME) {
                setShapeType(getEntryTypeCode(value, entryTypeMap));
            }
        },
        [entryTypeMap]
    );

    const config = useMemo(() => ({ onChange }), [onChange]);

    return (
        <>
            <GeneralValidationPanelContainer tableId={level2Table.id} />
            <AutoformItemFields tableId={level2Table.id} getInputFormName={getInputFormName} config={config} />

            <Tabs isLazy>
                <TabList w="100%" flexWrap={{ base: 'wrap', xl: 'nowrap' }} alignItems="center">
                    <TabListWrapper>
                        <Tab>
                            <FormattedMessage id="shape_set_details_tab" />
                        </Tab>
                        <Tab>{level3Table.name}</Tab>
                    </TabListWrapper>
                </TabList>

                <SortOrderProvider>
                    <TabPanels mt={6}>
                        <TabPanel pt={4}>
                            <ShapeSetGrid shapeType={shapeType} isLoadingEntryTypeMap={isLoadingEntryTypeMap} />
                        </TabPanel>
                        <TabPanel pt={4}>
                            <AutoformTableContainer
                                schemaCode={metadata.schemaCode}
                                tableId={level3Table.id}
                                showDownloadUploadButton={true}
                                config={{
                                    reduceColumnDefinitions,
                                }}
                            />
                        </TabPanel>
                    </TabPanels>
                </SortOrderProvider>
            </Tabs>
        </>
    );
};

export default ShapeSetSubItem;
