import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Heading,
    Flex,
    Box,
    SimpleGrid,
    Button,
    Divider,
    IconButton,
    Spacer,
    Accordion,
    useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    getSubRenewables,
    editRenewable,
    createRenewableDefinition,
    deleteSubRenewable,
} from '../../services/renewables';
import { validateLongLat } from '../../services/portfolios';
import {
    deleteItem,
    getDataFilters,
    checkItemInputEvent,
    flatItemDescriptions,
    isFilterApplied,
    capitalizeFirstLetter,
} from '../../services/items';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import ShowHideSidebarButton from '../layout/ShowHideSidebarButton';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';
import AutomaticFormikDirtyCheck from '../forms/AutomaticFormikDirtyCheck';

import ConfirmationModal from '../modal/ConfirmationModal';

import Tooltip from '../utils/Tooltip';
import Error from '../utils/Error';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import AddSubItem from '../utils/AddSubItem';
import SideDrawer from '../utils/SideDrawer';
import ItemPropertiesModal from '../utils/ItemPropertiesModal';
import ItemFilters from '../utils/ItemFilters';
import DeleteRecordModal from '../utils/DeleteRecordModal';
import SecondaryIconButton from '../utils/SecondaryIconButton';
import FiltersButton from '../utils/FiltersButton';
import ActionBar from '../utils/ActionBar';
import CustomItemIcon from '../utils/CustomItemIcon';
import PromptWrapper from '../utils/PromptWrapper';
import HelpButtonComponent from '../utils/HelpButtonComponent';
import ErrorAccordion from '../utils/ErrorAccordion';

import { onHasUnsavedChanges } from '../../store/helpers/helpersSlice';

import AddSubRenewableModal from './AddSubRenewableModal';
import RenewableDefinition from './RenewableDefinition';

import { ReactComponent as RenewablesIcon } from '../../icons/turbine.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as FileIcon } from '../../icons/file.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { isSubmitDisabled } from '../../services/utils';
import { helpAnchors } from 'constants/help';

const RENEWABLES_ITEM_TYPE = 'renewables';
const RENEWABLES_ROUTE = 'renewables';
const RENEWABLES_ITEM_TYPE_DISPLAY_LABEL = 'renewable';

// Renewable component is used in Renewables and Portfolios pages
// typeId, onRenewableEdit props are active if the Renewable component is called from Renewables
// renewableItem, onNodeRename, onNodeChangeIsMaster, onNodeOwnershipPercentageUpdate props are active if the Renewable component is called from Portfolios

const Renewable = ({
    typeId,
    onRenewableEdit,
    onRenewableDelete,
    renewableItem,
    onNodeRename,
    onNodeChangeIsMaster,
    onNodeOwnershipPercentageUpdate,
    onNodeItemDelete,
    refreshMapLayer,
    helpPageUrlItemTypeId = null,
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();
    const { renewableId } = useParams();
    const dispatch = useDispatch();

    const dataForItemCreation = useSelector((state) => state.item.dataForItemCreation);
    const pricingPoints = dataForItemCreation?.pricingPoints;
    const rtelecBasis = dataForItemCreation?.rtelecBasis;

    const itemId = Number(renewableId || renewableItem.properties.id);
    const itemTypeId = typeId || renewableItem.properties.typeId;

    const itemType = RENEWABLES_ITEM_TYPE;
    const typeLabel = RENEWABLES_ITEM_TYPE_DISPLAY_LABEL;

    const [selectedRenewable, setSelectedRenewable] = useState(null);
    const [renewableDefinitions, setRenewableDefinitions] = useState(null);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [editDescriptionConfig, setEditDescriptionConfig] = useState(null);
    const [subRenewableForDeletion, setSubRenewableForDeletion] = useState(null);
    const [filters, setFilters] = useState(() => getDataFilters(itemType));
    const [isFormDirty, setIsFormDirty] = useState(false);

    const selectedRenewableRef = useRef(null);

    const propertiesModal = useDisclosure();
    const deleteRenewableModal = useDisclosure();
    const addSubRenewableModal = useDisclosure();
    const deleteSubRenewableModal = useDisclosure();
    const filterDrawer = useDisclosure();

    const formLabels = {
        book: intl.formatMessage({ id: 'renewable_book' }),
        pricingPoint: intl.formatMessage({ id: 'common_electric_pricing_point' }),
        rtelecBasis: intl.formatMessage({ id: 'common_rtelec_pricing_point' }),
        weatherZone: intl.formatMessage({ id: 'renewable_definitions_weather_zone' }),
        modelType: intl.formatMessage({ id: 'renewable_definitions_model_type' }),
        dataWeight: intl.formatMessage({ id: 'renewable_definitions_data_weighting' }),
        plantType: intl.formatMessage({ id: 'renewable_definitions_plant_type' }),
        selectPlaceholder: intl.formatMessage({ id: 'common_forms_select_option' }),
        latitude: intl.formatMessage({ id: 'latitude' }),
        longitude: intl.formatMessage({ id: 'longitude' }),
        master: intl.formatMessage({ id: 'common_master' }),
    };

    const initialFormValues = useMemo(() => {
        const definitions = {};
        renewableDefinitions?.renewableDefinitionResponses.forEach((def, idx) => {
            // Formik considers numbers in fields as an array of objects
            definitions[idx] = {};
            definitions[idx]['renewableId'] = def.id;
            definitions[idx]['description'] = def.description;
            definitions[idx]['weatherZoneId'] = def.weatherZoneId || null;
            definitions[idx]['moduleProcessId'] = def.moduleProcessId || null;
            definitions[idx]['modelWeightId'] = def.modelWeightId || null;
            definitions[idx]['plantTypeId'] = def.plantTypeId || null;
            definitions[idx]['deliverabilityStatusId'] = def.deliverabilityStatusId || null;
            definitions[idx]['pcdsPercent'] = def.pcdsPercent || null;
        });
        return {
            description: selectedRenewable?.description,
            bookId: selectedRenewable?.bookId,
            pricingPointId: selectedRenewable?.pricingPointId,
            definitions: definitions,
            latitude: selectedRenewable?.latitude !== null ? selectedRenewable?.latitude : '',
            longitude: selectedRenewable?.longitude !== null ? selectedRenewable?.longitude : '',
            securityId: selectedRenewable?.securityId,
            rtelecBasisPriceId: selectedRenewable?.rtelecBasisPriceId,
        };
    }, [selectedRenewable, renewableDefinitions]);

    const fetchSubRenewableItem = useCallback(() => {
        return getSubRenewables(itemId)
            .then((data) => {
                setSelectedRenewable(data.renewableItem);
                selectedRenewableRef.current = data.renewableItem;
                setRenewableDefinitions(data);

                const descriptionsConfig = flatItemDescriptions(data.renewableDefinitionResponses, itemId);
                setEditDescriptionConfig(descriptionsConfig);

                // This is needed to show results after previous error
                setLoadingError(false);
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [itemId]);

    useEffect(() => {
        setIsLoading(true);
        fetchSubRenewableItem();
    }, [fetchSubRenewableItem]);

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const onRenewableFormSubmit = async ({
        bookId,
        description,
        pricingPointId: ppId,
        definitions,
        longitude,
        latitude,
        securityId,
        rtelecBasisPriceId,
    }) => {
        const successMessage = intl.formatMessage({ id: 'renewable_save_changes_success' });
        const arcGISError = intl.formatMessage({ id: 'common_item_arcgis_lat_long_save_failed' });

        const pricingPointId = ppId || null;
        const subRenewables = [];

        Object.values(definitions).forEach((subRenewable) => {
            return subRenewables.push(subRenewable);
        });

        var payload = {
            renewable: {
                description,
                bookId,
                itemTypeId,
                pricingPointId,
                itemId,
                longitude: longitude !== '' ? longitude : null,
                latitude: latitude !== '' ? latitude : null,
                securityId,
                rtelecBasisPriceId,
            },
            subRenewables,
        };

        try {
            const { arcGisIssue } = await editRenewable(payload);

            await fetchSubRenewableItem();

            if (!renewableItem) {
                onRenewableEdit({
                    id: selectedRenewable.id,
                    description,
                    bookId,
                    pricingPointId,
                    securityId,
                    rtelecBasisPriceId,
                });
            } else {
                onNodeRename(description);
                onNodeChangeIsMaster(securityId);
            }

            toast({
                message: successMessage,
                slot: arcGisIssue && <ErrorAccordion message={arcGISError} details={arcGisIssue} status={'warn'} />,
                duration: arcGisIssue && 20000,
            });
        } finally {
            if (typeof refreshMapLayer === 'function') {
                refreshMapLayer({
                    long: longitude,
                    lat: latitude,
                    objectRefId: selectedRenewableRef.current.objectRefId,
                });
            }
        }
    };

    const onRenewableItemDelete = async (renewable) => {
        const successMessage = intl.formatMessage({ id: 'renewables_item_delete_success' });

        await deleteItem(renewable.id);

        dispatch(onHasUnsavedChanges(false));

        //check whether the Renewable item is deleted from Renewables page or Portfolios
        if (renewableItem) {
            onNodeItemDelete();
        } else {
            onRenewableDelete(renewable);
            // renewables route
            historyPush(`/${RENEWABLES_ROUTE}`);
        }

        toast(successMessage);
    };

    const onEditDescription = (renewableId) => {
        if (!editDescriptionConfig[renewableId].penDisabled) {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [renewableId]: { isActive: !prevState[renewableId].isActive, penDisabled: false },
                };
            });
        }
    };

    const validateDescription = (value, selectedRenewableId) => {
        const trimmed = value.trim();
        let errorMessage = '';

        if (trimmed.length === 0) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_required' },
                { label: `${capitalizeFirstLetter(typeLabel)} name` }
            );
        } else if (trimmed.length > 100) {
            errorMessage = intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: `${capitalizeFirstLetter(typeLabel)} name`, lengthRule: '100 characters or less' }
            );
        }

        if (errorMessage !== '') {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [selectedRenewableId]: { ...prevState[selectedRenewableId], penDisabled: true },
                };
            });
        } else {
            setEditDescriptionConfig((prevState) => {
                return {
                    ...prevState,
                    [selectedRenewableId]: { ...prevState[selectedRenewableId], penDisabled: false },
                };
            });
        }

        return errorMessage;
    };

    const onAddSubItem = (subRenewable) => {
        setEditDescriptionConfig((prevState) => ({
            ...prevState,
            [subRenewable.id]: { isActive: false, penDisabled: false },
        }));

        setRenewableDefinitions((prev) => ({
            ...prev,
            renewableDefinitionResponses: [...prev.renewableDefinitionResponses, subRenewable],
        }));

        addSubRenewableModal.onClose();
    };

    const onSubRenewableDeleteModalOpen = (subRenewableId, subRenewableDesc) => {
        setSubRenewableForDeletion({
            id: subRenewableId,
            description: subRenewableDesc,
        });

        deleteSubRenewableModal.onOpen();
    };

    const onSubRenewableDeleteModalClose = () => {
        deleteSubRenewableModal.onClose();
        setSubRenewableForDeletion(null);
    };

    const onSubRenewableDelete = async (subRenewableId) => {
        onSubRenewableDeleteModalClose();

        const successMessage = intl.formatMessage({ id: 'renewable_delete_success' });

        try {
            setIsLoading(true);

            await deleteSubRenewable(subRenewableId);
            await fetchSubRenewableItem();

            toast(successMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const onApplyFilters = (newFilters) => {
        filterDrawer.onClose();

        setFilters(newFilters);
    };

    const validate = (values) => {
        let longitude = validateLongLat(values.longitude, 'long', values.latitude);
        let latitude = validateLongLat(values.latitude, 'lat', values.longitude);

        return {
            ...(longitude ? { longitude } : null),
            ...(latitude ? { latitude } : null),
        };
    };

    return (
        <>
            <PromptWrapper />

            <ExpandableItemWrapper>
                {isLoading ? (
                    <DefinitionsSkeleton />
                ) : loadingError ? (
                    <Box m={6}>
                        <Error
                            primaryId="common_error"
                            secondaryId="common_loading_error"
                            additionalText="definitions"
                        />
                    </Box>
                ) : (
                    <>
                        <Formik
                            enableReinitialize
                            initialValues={initialFormValues}
                            onSubmit={onRenewableFormSubmit}
                            validate={validate}
                        >
                            {({ isSubmitting, errors, touched, values }) => (
                                <Form>
                                    <AutomaticFormikDirtyCheck
                                        isFormDirty={isFormDirty}
                                        onFormDirtyChange={setIsFormDirty}
                                    />

                                    <Flex pt={3} pl={6} align="center">
                                        <CustomItemIcon
                                            isMaster={selectedRenewable.securityId}
                                            icon={<RenewablesIcon />}
                                        />

                                        {!editDescriptionConfig[selectedRenewable.id]?.isActive ? (
                                            <Heading px={2} as="h2" variant="h2">
                                                {values.description}
                                            </Heading>
                                        ) : (
                                            <InputFormikField
                                                px={3}
                                                flex="1"
                                                maxW="500px"
                                                validate={(value) => validateDescription(value, selectedRenewable.id)}
                                                name="description"
                                                type="text"
                                                onKeyDown={checkItemInputEvent}
                                                isFastField
                                            />
                                        )}

                                        <Box display="inline-block" mr={4}>
                                            <Tooltip
                                                label={intl.formatMessage({ id: 'common_edit_btn_tooltip_and_label' })}
                                            >
                                                <IconButton
                                                    leftIcon={<EditIcon />}
                                                    aria-label={intl.formatMessage({
                                                        id: 'common_edit_btn_tooltip_and_label',
                                                    })}
                                                    variant="circular-icon"
                                                    onClick={() => onEditDescription(selectedRenewable.id)}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Flex>

                                    <ActionBar>
                                        <ShowHideSidebarButton />

                                        <SecondaryIconButton
                                            leftIcon={<FileIcon />}
                                            size="sm"
                                            variant="secondary"
                                            onClick={propertiesModal.onOpen}
                                            flexShrink={0}
                                        >
                                            <FormattedMessage id="portfolio_item_properties" />
                                        </SecondaryIconButton>

                                        <FiltersButton
                                            hasIndicator={isFilterApplied(itemType)}
                                            onClick={filterDrawer.onOpen}
                                        />
                                        <HelpButtonComponent
                                            helpAnchor={helpAnchors.RENEWABLE_FILTERING}
                                            itemTypeId={helpPageUrlItemTypeId}
                                        />

                                        <Spacer display={{ base: 'none', xl: 'block' }} />

                                        <Button
                                            leftIcon={<DeleteIcon />}
                                            type="button"
                                            variant="special"
                                            size="sm"
                                            onClick={deleteRenewableModal.onOpen}
                                        >
                                            <FormattedMessage id="common_delete_item_btn" />
                                        </Button>

                                        <Button
                                            leftIcon={<SaveIcon />}
                                            isDisabled={isSubmitDisabled({ errors, touched })}
                                            isLoading={isSubmitting}
                                            type="submit"
                                            variant="primary-success"
                                            size="sm"
                                        >
                                            <FormattedMessage id="common_save_changes_button" />
                                        </Button>
                                    </ActionBar>

                                    <Divider />

                                    <SimpleGrid pt={7} pb={0} px={6} columns={{ xl: 4 }} spacingX={6} spacingY={4}>
                                        <ComboboxFormikField
                                            id="bookId"
                                            name="bookId"
                                            label={formLabels.book}
                                            options={renewableDefinitions.books}
                                            valueKey="id"
                                            labelKey="name"
                                            isFastField
                                            isRequired
                                        />
                                        <InputFormikField
                                            id="latitude"
                                            name="latitude"
                                            label={formLabels.latitude}
                                            type="number"
                                            isFastField
                                        />
                                        <InputFormikField
                                            id="longitude"
                                            name="longitude"
                                            label={formLabels.longitude}
                                            type="number"
                                            isFastField
                                        />
                                        <CheckboxFormikField id="securityId" name="securityId" mt={5}>
                                            {formLabels.master}
                                        </CheckboxFormikField>
                                    </SimpleGrid>

                                    <SimpleGrid pt={7} pb={5} px={6} columns={{ xl: 2 }} spacingX={6} spacingY={4}>
                                        <ComboboxFormikField
                                            id="pricingPointId"
                                            name="pricingPointId"
                                            label={formLabels.pricingPoint}
                                            info={<FormattedMessage id="common_pricing_point_empty_tooltip" />}
                                            options={pricingPoints}
                                            valueKey="id"
                                            labelKey="description"
                                            showPlaceholder={true}
                                            placeholderValue={null}
                                            isFastField
                                        />
                                        <ComboboxFormikField
                                            id="rtelecBasisPriceId"
                                            name="rtelecBasisPriceId"
                                            label={formLabels.rtelecBasis}
                                            options={rtelecBasis}
                                            labelKey="description"
                                            valueKey="id"
                                            showPlaceholder={true}
                                            placeholderValue={null}
                                            isFastField
                                        />
                                    </SimpleGrid>

                                    <Box>
                                        {renewableDefinitions.renewableDefinitionResponses.length === 0 ? (
                                            <AddSubItem onOpen={addSubRenewableModal.onOpen} />
                                        ) : (
                                            <Accordion allowToggle defaultIndex={[0]}>
                                                {renewableDefinitions.renewableDefinitionResponses.map((def, idx) => {
                                                    const itemId = def.id;
                                                    return (
                                                        <RenewableDefinition
                                                            key={itemId}
                                                            itemId={itemId}
                                                            itemTypeId={typeId}
                                                            type={itemType}
                                                            idx={idx}
                                                            definitions={renewableDefinitions}
                                                            formLabels={formLabels}
                                                            filters={filters}
                                                            editDescriptionConfig={editDescriptionConfig}
                                                            helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                                                            onEditDescription={onEditDescription}
                                                            setEditDescriptionConfig={setEditDescriptionConfig}
                                                            onAddSubRenewableModalOpen={addSubRenewableModal.onOpen}
                                                            onDeleteSubRenewableModalOpen={
                                                                onSubRenewableDeleteModalOpen
                                                            }
                                                        />
                                                    );
                                                })}
                                            </Accordion>
                                        )}
                                    </Box>
                                </Form>
                            )}
                        </Formik>

                        {propertiesModal.isOpen && (
                            <ItemPropertiesModal
                                isOpen
                                onClose={propertiesModal.onClose}
                                itemId={selectedRenewable.id}
                                portfolio={renewableItem}
                                onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                            />
                        )}

                        {deleteRenewableModal.isOpen && (
                            <ConfirmationModal
                                isOpen
                                onClose={deleteRenewableModal.onClose}
                                header={<FormattedMessage id="common_delete_modal_heading" values={{ item: 'Item' }} />}
                                hasExtraStep
                                content={
                                    <FormattedMessage
                                        id="common_delete_modal_msg"
                                        values={{ item: selectedRenewable.description }}
                                    />
                                }
                                confirmText={
                                    <Box as="span" textTransform="capitalize">
                                        <FormattedMessage id="common_delete" />
                                    </Box>
                                }
                                onConfirm={() => {
                                    deleteRenewableModal.onClose();
                                    onRenewableItemDelete(selectedRenewable);
                                }}
                            />
                        )}

                        {addSubRenewableModal.isOpen && (
                            <AddSubRenewableModal
                                isOpen
                                onClose={addSubRenewableModal.onClose}
                                itemTypeId={itemTypeId}
                                itemType={itemType}
                                definitions={renewableDefinitions}
                                formLabels={formLabels}
                                selectedRenewableId={selectedRenewable.id}
                                onCreateSubItem={createRenewableDefinition}
                                onAddSubItem={onAddSubItem}
                            />
                        )}

                        {deleteSubRenewableModal.isOpen && (
                            <DeleteRecordModal
                                onClose={onSubRenewableDeleteModalClose}
                                headerItem={intl.formatMessage({
                                    id: 'renewable_sub_renewable_heading',
                                })}
                                contentMessageItem={subRenewableForDeletion.description}
                                onConfirm={() => onSubRenewableDelete(subRenewableForDeletion.id)}
                            />
                        )}

                        {filterDrawer.isOpen && (
                            <SideDrawer
                                isOpen
                                onClose={filterDrawer.onClose}
                                header={<FormattedMessage id="common_filter_heading" values={{ item: itemType }} />}
                                content={
                                    <ItemFilters
                                        onApplyFilters={onApplyFilters}
                                        item={itemType}
                                        itemStartFilterHeading={<FormattedMessage id={'common_item_start_filter'} />}
                                        secondaryFilterHeading={<FormattedMessage id={'common_item_created_filter'} />}
                                    />
                                }
                                placement="right"
                            />
                        )}
                    </>
                )}
            </ExpandableItemWrapper>
        </>
    );
};

export default Renewable;
