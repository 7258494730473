import { useCallback, useMemo } from 'react';
import { Flex, Button, createStandaloneToast } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import theme from '../theme';
import ErrorAccordion from '../components/utils/ErrorAccordion';

const useExceptionToast = () => {
    const intl = useIntl();

    const renderExceptionToast = useCallback(
        (toastMsg, toastDetails, toastStatus = 'error') => {
            const toast = createStandaloneToast({ theme });
            const continueLabel = intl.formatMessage({ id: 'common_continue' });
            const toastId = 'ExceptionToast';

            // toast light and dark colors per status are defined in src\theme\colors.js
            const textColor = `${toastStatus}-toast-text-color`;
            const bgColor = `${toastStatus}-toast-background`;
            const borderColor = `${toastStatus}-toast-border-color`;

            if (!toast.isActive(toastId)) {
                toast({
                    render: ({ onClose }) => (
                        <Flex
                            wrap="wrap"
                            direction="row"
                            align="center"
                            px={6}
                            py={4}
                            borderWidth="1px"
                            borderRadius="md"
                            color={textColor}
                            bg={bgColor}
                            borderColor={borderColor}
                        >
                            <ErrorAccordion status={toastStatus} message={toastMsg} details={toastDetails} />
                            <Button
                                textTransform="capitalize"
                                ml="auto"
                                variant="secondary"
                                bg="white"
                                onClick={onClose}
                            >
                                {continueLabel}
                            </Button>
                        </Flex>
                    ),
                    position: 'top',
                    id: toastId,
                    duration: 20000,
                });
            }
        },
        [intl]
    );

    return useMemo(() => ({ exceptionToast: renderExceptionToast }), [renderExceptionToast]);
};

export default useExceptionToast;
