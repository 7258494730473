import { useSelector } from 'react-redux';
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

const ExpandableItemWrapper = (props) => {
    const isSidebarVisible = useSelector((state) => state.helpers.isSidebarVisible);

    return <Wrapper expanded={!isSidebarVisible} {...props} />;
};

const Expand = css`
    position: absolute;
    top: 0;
    bottom: 40px;
    left: 0;
    right: 0;
    background: var(--chakra-colors-background-primary);
    z-index: 2;
    overflow: auto;
`;

const Wrapper = styled.div`
    ${(props) => props.expanded && Expand};
`;

export default ExpandableItemWrapper;
