import { useMemo, useCallback, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Flex, Tabs, Tab, TabPanels, TabPanel, useDisclosure, TabList } from '@chakra-ui/react';

import { getValidRowsWithDates } from '../../services/grid';
import {
    GRID_KEYS,
    createForwardCurveData,
    getForwardCurveData,
    updateForwardCurveData,
    deleteForwardCurveData,
    deleteAllForwardCurveData,
    createForwardCurveOptions,
    getForwardCurveOptions,
    updateForwardCurveOptions,
    deleteForwardCurveOptions,
    deleteAllForwardCurveOptions,
    getForwardPriceModelDefinitions,
    updateModelDefinitionsData,
    multisortPairs,
    formatCurveSource,
    validateForwardCurveData,
    validateForwardCurveOptions,
} from '../../services/forwardCurve';
import {
    isFilterApplied,
    getDataFilters,
    parseDataFilters,
    refreshMultisort,
    exportItemData,
} from '../../services/items';

import { SortOrderContext } from '../grid/utils/SortOrderContext';

import ItemFilters from '../../components/utils/ItemFilters';
import SideDrawer from '../../components/utils/SideDrawer';
import SecondaryButton from '../../components/utils/SecondaryButton';
import FiltersButton from '../../components/utils/FiltersButton';
import DownloadModal from '../../components/utils/DownloadModal';

import SubItemData from '../itemData/SubItemData';
import UploadButton from '../itemData/UploadButton';

import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

const ForwardCurveData = ({ forwardCurveId, options, forwardItemId }) => {
    const intl = useIntl();

    const item = 'forwardCurve';

    const filterDrawer = useDisclosure();
    const downloadModal = useDisclosure();
    const { setSortOrder } = useContext(SortOrderContext);

    const [key, setKey] = useState(0);
    const [filters, setFilters] = useState(() => getDataFilters(item));
    const [modelOptions, setModelOptions] = useState({
        curveTypes: [],
        curveSources: [],
        weightSchema: [],
        moduleProcesses: [],
    });
    const [loadingQueue, setLoadingQueue] = useState([]); // unique keys represeting which resource is in loading state
    const [selectedTab, setSelectedTab] = useState(0);

    const showAdditionalGridButtons = selectedTab !== 2;

    const refreshTabs = useCallback(() => {
        setKey((prev) => prev + 1);
        setSortOrder((prev) => ({ ...prev, ...refreshMultisort(Object.values(GRID_KEYS)) }));
    }, [setSortOrder]);

    const onLoadingStart = useCallback((key) => {
        setLoadingQueue((prev) => prev.concat(key));
    }, []);

    const onLoadingEnd = useCallback((key) => {
        setLoadingQueue((prev) => prev.filter((p) => p !== key));
    }, []);

    const onApplyFilters = (newFilters) => {
        filterDrawer.onClose();

        setFilters(newFilters);
    };

    const forwardCurveDataGridResource = useMemo(() => {
        return {
            create: (rows) => createForwardCurveData(forwardCurveId, rows),
            read: (pagination) => getForwardCurveData(forwardCurveId, { ...pagination, forwardCurveFilter: filters }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['transactionDate', 'deliveryDate', 'updateDateTime']);

                return updateForwardCurveData(forwardCurveId, validRows);
            },
            delete: (rows) =>
                deleteForwardCurveData(
                    forwardCurveId,
                    rows.map((row) => row.id)
                ),
            deleteAll: () => deleteAllForwardCurveData(forwardCurveId),
            validate: (rows) => validateForwardCurveData(forwardCurveId, rows),
        };
    }, [forwardCurveId, filters]);

    const forwardCurveDataColumns = useMemo(
        () => [
            {
                field: 'transactionDate',
                type: 'date',
                headerName: intl.formatMessage({ id: 'forward_curve_transaction_date' }),
                initialSort: 'asc',
                initialSortIndex: 1,
            },
            {
                field: 'peakPeriodLabelId',
                type: 'select',
                cellEditorParams: { options: options.peakPeriods },
                headerName: intl.formatMessage({ id: 'forward_curve_peak_period' }),
                minWidth: 180,
                initialSort: 'asc',
                initialSortIndex: 3,
            },
            {
                field: 'deliveryDate',
                type: 'date',
                headerName: intl.formatMessage({ id: 'forward_curve_delivery_date' }),
                initialSort: 'asc',
                initialSortIndex: 2,
            },
            {
                field: 'price',
                type: 'number',
                headerName: intl.formatMessage({ id: 'forward_curve_price' }),
            },
            {
                field: 'updateDateTime',
                type: 'date',
                headerName: intl.formatMessage({ id: 'forward_curve_update_date' }),
            },
        ],
        [intl, options]
    );

    const forwardCurveOptionsGridResource = useMemo(() => {
        return {
            create: (rows) => createForwardCurveOptions(forwardCurveId, rows),
            read: (pagination) =>
                getForwardCurveOptions(forwardCurveId, { ...pagination, forwardCurveFilter: filters }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, [
                    'transactionDate',
                    'deliveryDate',
                    'updateDateTime',
                    'expiration',
                ]);

                return updateForwardCurveOptions(forwardCurveId, validRows);
            },
            delete: (rows) =>
                deleteForwardCurveOptions(
                    forwardCurveId,
                    rows.map((row) => row.forwardCurveOptionId)
                ),
            deleteAll: () => deleteAllForwardCurveOptions(forwardCurveId),
            validate: (rows) => validateForwardCurveOptions(forwardCurveId, rows),
        };
    }, [forwardCurveId, filters]);

    const forwardCurveOptionsColumns = useMemo(
        () => [
            {
                field: 'intervalName',
                headerName: intl.formatMessage({ id: 'forward_curve_interval_type' }),
            },
            {
                field: 'optionType',
                headerName: intl.formatMessage({ id: 'forward_curve_option_type' }),
            },
            {
                field: 'price',
                type: 'number',
                headerName: intl.formatMessage({ id: 'forward_curve_price' }),
            },
            {
                field: 'strikePrice',
                type: 'number',
                headerName: intl.formatMessage({ id: 'forward_curve_strike_price' }),
            },
            {
                field: 'deliveryDate',
                type: 'date',
                headerName: intl.formatMessage({ id: 'forward_curve_delivery_date' }),
                initialSort: 'asc',
                initialSortIndex: 2,
            },
            {
                field: 'peakPeriodId',
                type: 'select',
                cellEditorParams: { options: options.peakPeriods },
                headerName: intl.formatMessage({ id: 'forward_curve_peak_period' }),
                minWidth: 180,
                initialSort: 'asc',
                initialSortIndex: 3,
            },
            {
                field: 'bid',
                type: 'number',
                headerName: intl.formatMessage({ id: 'forward_curve_bid' }),
            },
            {
                field: 'ask',
                type: 'number',
                headerName: intl.formatMessage({ id: 'forward_curve_ask' }),
            },
            {
                field: 'settle',
                type: 'number',
                headerName: intl.formatMessage({ id: 'forward_curve_settle' }),
            },
            {
                field: 'vol',
                type: 'number',
                headerName: intl.formatMessage({ id: 'forward_curve_vol' }),
            },
            {
                field: 'expiration',
                type: 'date',
                headerName: intl.formatMessage({ id: 'forward_curve_expiration_date' }),
            },
            {
                field: 'transactionDate',
                type: 'date',
                headerName: intl.formatMessage({ id: 'forward_curve_transaction_date' }),
                initialSort: 'asc',
                initialSortIndex: 1,
            },
            {
                field: 'updateDateTime',
                type: 'date',
                headerName: intl.formatMessage({ id: 'forward_curve_update_date' }),
            },
        ],
        [intl, options]
    );

    const modelDefinitionsGridResource = useMemo(() => {
        return {
            read: async (pagination) => {
                const data = await getForwardPriceModelDefinitions(forwardCurveId, {
                    ...pagination,
                    forwardCurveFilter: filters,
                });

                setModelOptions({
                    curveTypes: data.curveTypes,
                    curveSources: data.curveSources,
                    weightSchema: data.weightSchema,
                    moduleProcesses: data.moduleProcesses,
                });

                return {
                    items: data.forwardCurveModelDefinition,
                    totalCount: data.forwardCurveModelDefinition.length,
                };
            },
            update: (rows) => updateModelDefinitionsData(forwardItemId, rows),
        };
    }, [forwardItemId, forwardCurveId, filters]);

    const modelDefinitionsColumns = useMemo(
        () => [
            {
                field: 'description',
                headerName: intl.formatMessage({ id: 'forward_curve_name' }),
                minWidth: 180,
                initialSort: 'asc',
                initialSortIndex: 1,
                type: 'text',
                cellEditorParams: { required: true },
            },
            {
                field: 'curveTypeId',
                type: 'select',
                cellEditorParams: { options: modelOptions.curveTypes, required: true },
                headerName: intl.formatMessage({ id: 'forward_curve_type' }),
                minWidth: 180,
            },
            {
                field: 'moduleProcessId',
                type: 'select',
                cellEditorParams: { options: modelOptions.moduleProcesses, required: true },
                headerName: intl.formatMessage({ id: 'forward_curve_module_process_flow' }),
                minWidth: 180,
            },
            {
                field: 'curveSourceId',
                headerName: intl.formatMessage({ id: 'forward_curve_source' }),
                minWidth: 180,
                editable: false,
                valueFormatter: (params) => formatCurveSource(params.value, modelOptions.curveSources),
            },
            {
                field: 'minSim',
                type: 'number',
                headerName: intl.formatMessage({ id: 'forward_curve_min_sim' }),
                minWidth: 180,
            },
            {
                field: 'maxSim',
                type: 'number',
                headerName: intl.formatMessage({ id: 'forward_curve_max_sim' }),
                minWidth: 180,
            },
            {
                field: 'weightSchemaId',
                type: 'select',
                cellEditorParams: { options: modelOptions.weightSchema, required: true },
                headerName: intl.formatMessage({ id: 'forward_curve_weight_schema' }),
                minWidth: 180,
            },
        ],
        [intl, modelOptions]
    );

    const onDownloadData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const { startDateFrom, startDateTo, ...rest } = parseDataFilters(filters);

            const payload = {
                forwardCurveFilter: {
                    transactionDateFrom: startDateFrom,
                    transactionDateTo: startDateTo,
                    ...rest,
                },
                dataSorting: sortOrder[GRID_KEYS.forwardCurveData],
                optionsSorting: sortOrder[GRID_KEYS.forwardCurveOptions],
                exportTemplate,
            };

            return exportItemData(`forward-curve/${forwardCurveId}/export`, payload);
        },
        [forwardCurveId, filters]
    );

    return (
        <Tabs
            px={0}
            mt={6}
            isLazy
            isManual
            lazyBehavior="keepMounted"
            key={key}
            index={selectedTab}
            onChange={setSelectedTab}
        >
            <TabList w="100%" flexWrap={{ base: 'wrap', xl: 'nowrap' }} alignItems="center">
                <Tab>
                    <FormattedMessage id="forward_curve_tab_data" />
                </Tab>
                <Tab>
                    <FormattedMessage id="forward_curve_tab_options" />
                </Tab>
                <Tab>
                    <FormattedMessage id="forward_curve_tab_model" />
                </Tab>

                <Flex ml="auto" gridGap={2} order={{ base: -1, xl: 0 }} w={{ base: '100%', xl: 'auto' }}>
                    {showAdditionalGridButtons && (
                        <>
                            <SecondaryButton
                                leftIcon={<DownloadIcon />}
                                w="auto !important"
                                size="sm"
                                type="button"
                                variant="secondary"
                                onClick={downloadModal.onOpen}
                            >
                                <FormattedMessage id="common_download_btn_tooltip_and_label" />
                            </SecondaryButton>

                            <UploadButton
                                type="forward-curve"
                                itemId={forwardCurveId}
                                hasLabel={true}
                                w="auto !important"
                                onAfterUploadChange={refreshTabs}
                            />

                            <FiltersButton hasIndicator={isFilterApplied(item)} onClick={filterDrawer.onOpen} />
                        </>
                    )}

                    <SecondaryButton
                        onClick={refreshTabs}
                        size="sm"
                        variant="secondary"
                        leftIcon={<RefreshIcon />}
                        isLoading={loadingQueue.length > 0}
                        loadingText={intl.formatMessage({ id: 'common_loading' })}
                        textTransform="capitalize"
                        ml="auto"
                    >
                        <FormattedMessage id="common_refresh" />
                    </SecondaryButton>
                </Flex>
            </TabList>

            <TabPanels mt={4}>
                <TabPanel>
                    <SubItemData
                        tab="0"
                        name={GRID_KEYS.forwardCurveData}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={forwardCurveDataGridResource}
                        columns={forwardCurveDataColumns}
                        addDataColumns={forwardCurveDataColumns}
                        multisortPairs={multisortPairs}
                        primaryKeyName="id"
                    />
                </TabPanel>

                <TabPanel>
                    <SubItemData
                        tab="1"
                        name={GRID_KEYS.forwardCurveOptions}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={forwardCurveOptionsGridResource}
                        columns={forwardCurveOptionsColumns}
                        addDataColumns={forwardCurveOptionsColumns}
                        multisortPairs={multisortPairs}
                        primaryKeyName="forwardCurveOptionId"
                    />
                </TabPanel>

                <TabPanel>
                    <SubItemData
                        tab="2"
                        name={GRID_KEYS.forwardCurveModelDefinitions}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={modelDefinitionsGridResource}
                        columns={modelDefinitionsColumns}
                        multisortPairs={multisortPairs}
                        primaryKeyName="id"
                    />
                </TabPanel>
            </TabPanels>

            {filterDrawer.isOpen && (
                <SideDrawer
                    isOpen
                    onClose={filterDrawer.onClose}
                    header={<FormattedMessage id="forward_curve_filters_heading" />}
                    content={
                        <ItemFilters
                            filters={filters}
                            onApplyFilters={onApplyFilters}
                            item={item}
                            itemStartFilterHeading={<FormattedMessage id={'forward_curve_start_filter'} />}
                            secondaryFilterHeading={<FormattedMessage id={'common_item_created_filter'} />}
                        />
                    }
                    placement="right"
                />
            )}

            {downloadModal.isOpen && (
                <DownloadModal
                    isOpen
                    onClose={downloadModal.onClose}
                    onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                    onDownloadTemplate={() => onDownloadData(true)}
                    bodyMessage={<FormattedMessage id="basis_forward_item_export_text" />}
                />
            )}
        </Tabs>
    );
};

export default ForwardCurveData;
