import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useAutoformMetadata from './useAutoformMetadata';

const useAutoformParams = () => {
    const { search } = useLocation();
    const { metadata } = useAutoformMetadata();
    const customParameters = useSelector((state) => state.autoform?.customParameters);

    const queryParams = useMemo(() => {
        const query = new URLSearchParams(search);
        const parameters = query.get('parameterValues');
        return parameters;
    }, [search]);

    const parameters = useMemo(() => {
        let result;
        if (customParameters) {
            result = customParameters;
        } else if (queryParams) {
            return queryParams.split(';');
        } else {
            result = metadata?.parameters.map((parameter) => parameter.value) ?? [];
        }
        return result;
    }, [customParameters, queryParams, metadata?.parameters]);

    return parameters;
};

export default useAutoformParams;
