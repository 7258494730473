import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';

import { ReactComponent as FirstPageIcon } from '../../icons/first-page.svg';
import { ReactComponent as LeftArrowIcon } from '../../icons/prev-page.svg';
import { ReactComponent as LastPageIcon } from '../../icons/last-page.svg';
import { ReactComponent as RightArrowIcon } from '../../icons/next-page.svg';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';

const Pagination = ({ items, renderItem, pageSize, ...rest }) => {
    const [page, setPage] = useState(1);

    const totalPages = useMemo(() => Math.max(Math.ceil(items.length / pageSize), 1), [pageSize, items]);

    const visibleItems = useMemo(() => items.slice((page - 1) * pageSize, page * pageSize), [page, pageSize, items]);

    return (
        <Box>
            {visibleItems.map((item) => renderItem(item))}
            <Flex justifyContent="flex-end" alignItems="center" {...rest}>
                <StyledButton
                    type="button"
                    variant="pagination"
                    isDisabled={page === 1}
                    mr={2}
                    onClick={() => setPage(1)}
                >
                    <FirstPageIcon />
                </StyledButton>
                <StyledButton
                    type="button"
                    variant="pagination"
                    isDisabled={page === 1}
                    onClick={() => setPage((page) => page - 1)}
                >
                    <LeftArrowIcon />
                </StyledButton>
                <Text mx={6} size="sm" fontWeight="semibold" color="gray.800">
                    <FormattedMessage id="pagination_pages" values={{ page, totalPages }} />
                </Text>
                <StyledButton
                    type="button"
                    variant="pagination"
                    isDisabled={page === totalPages}
                    mr={2}
                    onClick={() => setPage((page) => page + 1)}
                >
                    <RightArrowIcon />
                </StyledButton>
                <StyledButton
                    type="button"
                    variant="pagination"
                    isDisabled={page === totalPages}
                    onClick={() => setPage(totalPages)}
                >
                    <LastPageIcon />
                </StyledButton>
            </Flex>
        </Box>
    );
};

const StyledButton = styled(Button)`
    svg {
        color: black;
        ${(props) => (props.isDisabled ? 'opacity: 0.3;' : '')};
    }
`;

export default Pagination;
