import { DATA_TYPES, INPUT_TYPES } from './fields';

export const FilterOperationsEnum = {
    // Valid for ['System.Int32', 'System.Double', 'System.DateTime']
    GreaterThan: 1,
    // Valid for ['System.Int32', 'System.Double', 'System.DateTime']
    LessThan: 2,
    // Valid for ['System.Int32', 'System.Double, 'System.DateTime']
    GreaterThanOrEqual: 3,
    // Valid for ['System.Int32', 'System.Double, 'System.DateTime']
    LessThanOrEqual: 4,
    // Valid for ['System.Int32', 'System.Double, 'System.DateTime', 'System.String']
    Equal: 5,
    // Valid for ['System.Int32', 'System.Double, 'System.DateTime', 'System.String']
    NotEqual: 6,
    // Valid for ['System.String']
    StartsWith: 7,
    // Valid for ['System.String']
    EndsWith: 8,
    // Valid for ['System.String']
    Includes: 9,
};

export const FILTER_DATA_TYPES = [
    {
        translationKey: 'option_greater_than',
        validFor: [DATA_TYPES.Double, DATA_TYPES.Int, DATA_TYPES.DateTime],
        filterOperation: FilterOperationsEnum.GreaterThan,
    },
    {
        translationKey: 'option_less_than',
        validFor: [DATA_TYPES.Double, DATA_TYPES.Int, DATA_TYPES.DateTime],
        filterOperation: FilterOperationsEnum.LessThan,
    },
    {
        translationKey: 'option_equal',
        validFor: [INPUT_TYPES.ComboBox, DATA_TYPES.DateTime, INPUT_TYPES.CheckBox, DATA_TYPES.Double, DATA_TYPES.Int],
        filterOperation: FilterOperationsEnum.Equal,
    },
    {
        translationKey: 'option_not_equal',
        validFor: [INPUT_TYPES.ComboBox, DATA_TYPES.DateTime, INPUT_TYPES.CheckBox, DATA_TYPES.Double, DATA_TYPES.Int],
        filterOperation: FilterOperationsEnum.NotEqual,
    },
    {
        translationKey: 'option_starts_with',
        validFor: [DATA_TYPES.String],
        filterOperation: FilterOperationsEnum.StartsWith,
    },
    {
        translationKey: 'option_ends_with',
        validFor: [DATA_TYPES.String],
        filterOperation: FilterOperationsEnum.EndsWith,
    },
    {
        translationKey: 'option_includes',
        validFor: [DATA_TYPES.String],
        filterOperation: FilterOperationsEnum.Includes,
    },
];

export const DATE_TIME_FILTER_TYPES = {
    StartDateRange: 1,
    EndDateRange: 2,
    Overlap: 3,
    UpdateDateLatest: 4,
    UpdateDateLatestAsOf: 5,
    UpdateDateRange: 6,
};

export const ERROR_MESSAGE_TRANSLATIONS = {
    0: 'common',
    1: '',
};

export const DELETE_CONFIRMATION_TYPE = {
    None: 1,
    SingleGrid: 2,
    MultiGrid: 3,
    Rows: 4,
};

export const APP_NAME = 'PowerSIMM V';

export const INCLUDE_CHART_VIEW_KEY = 'CHART_TABLE_VIEW';

export const TAB_GROUP_SETTING_KEY = 'GROUP_KEY';

export const SLICEOFDAY_PARTIAL_DELIVERABILITY = 'PARTIAL CAPACITY DELIVERABILITY STATUS';
