import { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import DataGrid from '../grid/DataGrid';
import DataGridWrapper from '../grid/DataGridWrapper';

import useAutoformMetadata from 'components/autoform/hooks/useAutoformMetadata';
import useAutoformTableData from 'components/autoform/hooks/useAutoformTableData';
import useAutoformTableMetadata from 'components/autoform/hooks/useAutoformTableMetadata';
import useAutoformParams from 'components/autoform/hooks/useAutoformParams';
import useCommonToast from 'hooks/useCommonToast';
import useShapeSetTranslations from 'hooks/shapeSet/useShapeSetTranslations';

import { saveAutoformRecords, addMultipleAutoformTableRow } from 'services/autoforms';
import { sortShapeSetEnum, getDataForRow, getRowNodeId, reduceMonthDoWRows } from 'services/shapeSet';

import { MONTHS, DAYS_OF_WEEK } from 'constants/shapeSet';
import { noop } from 'services/utils';

const YearMonthDoWGrid = ({ reloadData }) => {
    const gridApiRef = useRef(null);
    const intl = useIntl();
    const { toast } = useCommonToast();
    const { hierarchicalTables } = useAutoformMetadata();
    const level2TableId = hierarchicalTables.level2[0]?.id;
    const level3Table = hierarchicalTables.level3[0];
    const { data } = useAutoformTableData(level3Table.id);
    const { primaryKeyColumnName } = useAutoformTableMetadata(level3Table.id);
    const { selectedItem } = useAutoformTableMetadata(level2TableId);
    const parameters = useAutoformParams();
    const translations = useShapeSetTranslations();

    const parsedData = useMemo(() => {
        let output = [];
        const { ALL, ...months } = MONTHS;
        const { ALL: allDays, ...days } = DAYS_OF_WEEK;
        sortShapeSetEnum(months).forEach((month) => {
            const newRow = {
                id: `${month}`, // Important ot have a unique ID to keep AG Grid updated efficiently
                Month: translations.months[month],
                MonthNum: month,
            };

            sortShapeSetEnum(days).forEach((day) => {
                const { value } = getDataForRow(data, month, day, 0);
                newRow[`${day}`] = value;
            });

            output.push(newRow);
        });

        return output;
    }, [data, translations]);

    const columnDefs = useMemo(() => {
        const { ALL: allDays, ...days } = DAYS_OF_WEEK;
        return [
            {
                field: 'Month',
                type: 'number',
                headerName: translations.month,
                editable: false,
            },
            ...sortShapeSetEnum(days).map((day) => ({
                field: `${day}`,
                type: 'number',
                headerName: translations.days[day],
            })),
        ];
    }, [translations]);

    const updateServerSideData = async (rowIds) => {
        const gridApi = gridApiRef.current;
        const rows = rowIds.map((id) => gridApi.getRowNode(id));
        let output = reduceMonthDoWRows(rows, data);

        const requests = [];
        if (output.recordsToUpdate.length) {
            requests.push(
                saveAutoformRecords({
                    tableId: level3Table.id,
                    records: output.recordsToUpdate,
                    primaryKeyRecordName: primaryKeyColumnName,
                    parameters,
                })
            );
        }
        if (output.recordsToAdd.length) {
            requests.push(
                addMultipleAutoformTableRow({
                    tableId: level3Table.id,
                    records: output.recordsToAdd,
                    parentRecordId: selectedItem,
                    parameters,
                })
            );
        }
        await Promise.all(requests);
        const successMessage = intl.formatMessage({ id: 'common_generic_item_change_success' });
        toast(successMessage);
        reloadData();
    };
    return (
        <DataGridWrapper>
            <DataGrid
                columns={columnDefs}
                rowData={parsedData}
                onGridReady={(params) => {
                    gridApiRef.current = params.api;
                }}
                blockLoadDebounceMillis={100}
                getRowNodeId={getRowNodeId}
                onDataChange={updateServerSideData}
                onDelete={noop}
                suppressRowTransform={true}
                rowModelType="clientSide"
                serverSideStoreType="partial"
                suppressRowClickSelection={true}
                immutableData={true}
                pagination={false}
            />
        </DataGridWrapper>
    );
};

export default YearMonthDoWGrid;
