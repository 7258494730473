import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Heading, Flex, useDisclosure, Tabs, TabList, Tab, Tooltip, TabPanel, TabPanels } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useFormikContext } from 'formik';

import AutoformUploadButton from './AutoformUploadButton';
import AutoformDownloadButton from './AutoformDownloadButton';
import AutoformDeleteConfirmationModal from './AutoformDeleteConfirmationModal';
import SearchBar from '../../components/utils/SearchBar';
import DeleteAllButton from '../../components/itemData/DeleteAllButton';
import AddGridRowsContainer from './AddGridRowsContainer';
import DataGridWrapper from '../grid/DataGridWrapper';
import DataGrid from '../grid/DataGrid';

import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import useAutoformColumnDefinitions from './hooks/useAutoformColumnDefinitions';
import useAutoformLoadTableDropdowns from './hooks/useAutoformLoadTableDropdowns';
import useAutoformParams from './hooks/useAutoformParams';
import useCommonToast from '../../hooks/useCommonToast';

import {
    getDataValueObjectFromDto,
    getDtoFromDataValueObject,
    parseAutoformDataFilters,
    parseSortModel,
    isFilterApplied,
    mapGeneralValidationMessages,
} from './utils/autoformUtils';

import {
    setSelectedItemForTable,
    setFiltersForTable,
    setSortAndPaginationForTable,
} from '../../store/autoform/autoformSlice';

import {
    getAutoformTableData,
    getAutoformTableDataSourceView,
    getValidRows,
    saveAutoformRecords,
    deleteAutoformTableRows,
    deleteAutoformGridTable,
    addMultipleAutoformTableRow,
    checkDataMultiple,
    generateBulkUpdateSwitcherName,
    getAutoformChartData,
} from '../../services/autoforms';

import { getDataFilters } from '../../services/items';
import { updateIssues } from '../../services/grid';

import { INPUT_TYPES } from '../../constants/fields';
import { DELETE_CONFIRMATION_TYPE, FilterOperationsEnum, INCLUDE_CHART_VIEW_KEY } from '../../constants/autoforms';
import { ReactComponent as GraphIcon } from '../../icons/graph.svg';
import { ReactComponent as TableRowsIcon } from '../../icons/table-rows.svg';

import ItemFilters from '../utils/ItemFilters';
import SideDrawer from '../utils/SideDrawer';
import CustomFilter from './CustomFilter';
import FiltersButton from '../utils/FiltersButton';
import { filterIssuesColumn } from 'services/grid';
import AutoformBulkUpdate from './AutoformBulkUpdate';

import GeneralFormValidationsAlert from '../forms/GeneralFormValidationsAlert';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';
import { Highchart } from 'components/charts/Highchart';
import { DEFAULT_FILTERED_POINT_COUNT, DEFAULT_PAGINATED_PAGE_SIZE, DEFAULT_POINT_COUNT } from 'constants/charting';
import AddDataModal from 'components/grid/AddDataModal';
import useKeyRefresh from 'hooks/useKeyRefresh';

const defaultConfig = {};

/**
 * All logic required for displaying and doing CRUD operations on a table
 * to be displayed as AG Grid.
 *
 * @param {string} tableId - Id of the table.
 * @param {string} schemaCode - Schema code of the autoform.
 * @param {callback} childrenTables - Tables that are children in the metadata to this table.
 * @param {callback} onSelectItem - Callback to select an item from the table.
 * @param children
 * @param {boolean} showDownloadUploadButton
 * @param {boolean} showFiltersButton - show/hide flag for filters button
 * @param {boolean} showSearchbar
 * @param {boolean} showHeader
 * @param {boolean} ignoreRequiredParentValidation - will cause partial validation skip on BE during "delete all" action
 * @param {object} config - flexible config for this and nested components
 * @param {callback} onActionInitiated - Callback called after each possible grid action on success
 * @param {DELETE_CONFIRMATION_TYPE} rowDeleteConfirmation - will render or not confirmation modal after delete 1-* rows
 * @param customButtons - optional additional buttons
 * @param helpPageUrlItemTypeId - optional item type ID for displaying the appropriate help page
 */
const AutoformTableContainer = ({
    schemaCode,
    tableId,
    childrenTables,
    onSelectItem,
    children,
    showDownloadUploadButton,
    showFiltersButton = true,
    showSearchbar = false,
    showHeader = true,
    ignoreRequiredParentValidation = false,
    config = defaultConfig,
    onActionInitiated,
    rowDeleteConfirmation = DELETE_CONFIRMATION_TYPE.None,
    isReadOnly,
    customButtons,
    helpPageUrlItemTypeId = null,
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const dispatch = useDispatch();
    const formikContext = useFormikContext();
    const filterDrawer = useDisclosure();
    const deleteRowConfirmModal = useDisclosure();
    const globalErrorsDisclosure = useDisclosure({ defaultIsOpen: true });
    const globalWarningsDisclosure = useDisclosure({ defaultIsOpen: true });
    const [key, refreshKey] = useKeyRefresh();

    const [searchString, setSearchString] = useState('');
    const gridApiRef = useRef(null);
    const gridColumnApiRef = useRef(null);
    const tablesState = useSelector((state) => state.autoform?.tables);
    const metadata = useSelector((state) => state.autoform?.metadata);
    const customFilters = useMemo(
        () => tablesState?.[tableId] && tablesState[tableId]?.customFilters,
        [tablesState, tableId]
    );
    const reloadGridFlag = useSelector((state) => state.autoform?.reloadGridFlag);
    const pendingRowIds = useRef([]);
    const [issues, setIssues] = useState([]);
    const [globalErrors, setGlobalErrors] = useState([]);
    const [globalWarnings, setGlobalWarnings] = useState([]);
    const [dataSourceViewRequest, setDataSourceViewRequest] = useState([]);
    const [index, setIndex] = useState(0);
    const onViewChange = useCallback((newIndex) => setIndex(newIndex), []);

    const addDataModalForChart = useDisclosure();

    const globalIssues = useMemo(
        () => ({ errors: globalErrors, warnings: globalWarnings }),
        [globalErrors, globalWarnings]
    );

    const {
        metadata: tableMetadata,
        sortedColumns,
        primaryKeyColumnName,
        selectedItem,
        descriptionColumn,
        descriptionColumnName,
        dropdownOptions,
        helpAnchor,
    } = useAutoformTableMetadata(tableId);
    const { selectedItem: selectedParentRecordId } = useAutoformTableMetadata(tableMetadata && tableMetadata.parentId);
    useAutoformLoadTableDropdowns(
        tableId,
        config?.excludeParentRecordIdDropdownParameter ? undefined : selectedParentRecordId
    );
    const parameters = useAutoformParams();
    const [filters, setFilters] = useState(() =>
        parseAutoformDataFilters({ ...getDataFilters(tableId), customFilters }, tableMetadata)
    );

    const { getTableData, saveTableData, onDataLoad, addTableRow, onCheckData, deleteAll, getTableDataSourceView } =
        config;

    const translations = useMemo(
        () => ({
            searchText: intl.formatMessage({ id: 'common_search' }),
        }),
        [intl]
    );

    const hasChart = useMemo(() => {
        return Number(tableMetadata?.settings.find((s) => s?.name === INCLUDE_CHART_VIEW_KEY)?.value) === 1;
    }, [tableMetadata]);

    useEffect(() => {
        if (gridApiRef.current) {
            refetchData();
        }
        // eslint-disable-next-line
    }, [reloadGridFlag]);

    let columnDefinitions = useAutoformColumnDefinitions(tableId);

    columnDefinitions = useMemo(() => {
        if (config?.columnDefinitions) {
            return config.columnDefinitions;
        } else if (config?.reduceColumnDefinitions) {
            return columnDefinitions.reduce(config.reduceColumnDefinitions, []);
        } else {
            return columnDefinitions;
        }
    }, [config?.reduceColumnDefinitions, config?.columnDefinitions, columnDefinitions]);

    const bulkUpdateParentRecordId = useMemo(() => {
        let result = selectedParentRecordId;

        if (!selectedParentRecordId && tableMetadata?.parentId && metadata.tables?.length) {
            let currentTable = tableMetadata;

            for (let i = 0; i < metadata.tables.length; i++) {
                const parentTableId = currentTable?.parentId;

                if (!parentTableId || !tablesState[parentTableId]) {
                    break;
                }

                if (tablesState[parentTableId].selectedItem) {
                    result = tablesState[parentTableId].selectedItem;
                    break;
                } else {
                    currentTable = metadata.tables.find((table) => table.id === parentTableId);
                }
            }
        }

        return result;
    }, [selectedParentRecordId, tableMetadata, tablesState, metadata]);

    const validateAutoformCustomFilters = useCallback(
        (values) => {
            let hasErrors = false;
            const validationMessages = values.customFilters.map((customFilter) => {
                let result = {};
                if (customFilter.columnId) {
                    if (
                        customFilter.filterValue === '' ||
                        customFilter.filterValue === undefined ||
                        customFilter.filterValue === null
                    ) {
                        hasErrors = true;
                        result.filterValue = intl.formatMessage({ id: 'common_forms_invalid_value' });
                    }
                    if (
                        customFilter.operation === '' ||
                        customFilter.operation === undefined ||
                        customFilter.operation === null
                    ) {
                        hasErrors = true;
                        result.operation = intl.formatMessage({ id: 'common_forms_invalid_value' });
                    }
                }
                return result;
            });
            return hasErrors ? { customFilters: validationMessages } : null;
        },
        [intl]
    );

    const getItemDescription = useCallback(
        (item) => {
            let description = item[descriptionColumnName];
            if (descriptionColumn?.inputTypeDescription === INPUT_TYPES.ComboBox) {
                const options = dropdownOptions[descriptionColumn.id];
                description = options.find((opt) => opt.value === item[descriptionColumnName])?.text;
            }
            return description;
        },
        [descriptionColumnName, descriptionColumn, dropdownOptions]
    );

    const selectItem = useCallback(
        (item) => {
            dispatch(setSelectedItemForTable({ itemId: item[primaryKeyColumnName], tableId }));
            const description = getItemDescription(item);
            onSelectItem && onSelectItem({ id: item[primaryKeyColumnName], description });
        },
        [onSelectItem, dispatch, primaryKeyColumnName, getItemDescription, tableId]
    );

    const resetServerValidation = useCallback((resetIssues = true) => {
        resetIssues && setIssues([]);
        setGlobalErrors([]);
        setGlobalWarnings([]);
    }, []);

    const refetchData = useCallback(() => {
        const gridApi = gridApiRef.current;

        resetServerValidation();

        //if 'Delete All' is performed before grid has been loaded
        if (gridApi) {
            gridApi.refreshServerSideStore({
                purge: true,
            });
        }
    }, [resetServerValidation]);

    const searchFilters = useMemo(() => {
        const filters = [];
        if (searchString) {
            const searchColumn = sortedColumns.find((col) => col?.dataTypeDescription === 'System.String');
            if (searchColumn) {
                filters.push({
                    columnId: searchColumn.id,
                    operation: FilterOperationsEnum.Includes,
                    filterValue: searchString,
                });
            }
        }
        return filters;
    }, [searchString, sortedColumns]);

    const getChartData = useCallback(
        async (range) => {
            if (hasChart) {
                const rangeFilters = range ? [{ type: 3, fromDate: range?.start, toDate: range?.end }] : [];
                const requestPayload = {
                    tableId,
                    selectedParentRecordId,
                    parameters,
                    filters: [...searchFilters, ...(filters?.filters ? filters?.filters : [])],
                    dateTimeFilters: [...rangeFilters, ...filters?.dateTimeFilters],
                    pointCount: range ? DEFAULT_FILTERED_POINT_COUNT : DEFAULT_POINT_COUNT,
                };
                return await getAutoformChartData(requestPayload);
            }
        },
        [hasChart, parameters, tableId, filters, selectedParentRecordId, searchFilters]
    );

    const getServerSideData = useCallback(
        async (params) => {
            const pageSize = DEFAULT_PAGINATED_PAGE_SIZE;
            const page = Math.floor(params.request.startRow / pageSize + 1);
            const sortData = parseSortModel(params.request.sortModel, sortedColumns);
            dispatch(setSortAndPaginationForTable({ tableId, sortModel: params.request.sortModel, pageSize, page }));

            const requestPayload = {
                parameters,
                schemaCode,
                page,
                pageSize,
                tableId,
                selectedParentRecordId,
                sortData,
                filters: [...searchFilters, ...(filters?.filters ? filters?.filters : [])],
                dateTimeFilters: filters?.dateTimeFilters,
            };

            setDataSourceViewRequest(requestPayload);

            const tableData = await (getTableData
                ? getTableData(requestPayload)
                : getAutoformTableData(requestPayload));

            if (tableData.totalCount > 0) {
                params.api.hideOverlay();
            } else {
                //Adding timeout to make sure the NoRowsOverlay is displayed instead of a blank grid
                setTimeout(() => {
                    params.api.showNoRowsOverlay();
                }, 0);
            }

            const parsedTableData = tableData.items.map((item) => getDataValueObjectFromDto(item, tableMetadata));

            onDataLoad && onDataLoad(parsedTableData);

            params.success({ rowData: parsedTableData, rowCount: tableData.totalCount });
        },
        [
            dispatch,
            schemaCode,
            tableId,
            parameters,
            selectedParentRecordId,
            filters,
            sortedColumns,
            tableMetadata,
            getTableData,
            onDataLoad,
            searchFilters,
        ]
    );

    const getDataSourceView = useCallback(async () => {
        const tableData = await (getTableData
            ? getTableDataSourceView(dataSourceViewRequest)
            : getAutoformTableDataSourceView(dataSourceViewRequest));
        return tableData;
    }, [dataSourceViewRequest, getTableData, getTableDataSourceView]);

    const updateServerSideData = async (rowIds) => {
        const gridApi = gridApiRef.current;
        const rows = rowIds.map((id) => gridApi.getRowNode(id));
        const validRows = getValidRows(rows, tableMetadata.columns);
        const dtoRows = validRows.map((row) => {
            let dto = getDtoFromDataValueObject(row, tableMetadata);
            if (dto.issues) {
                delete dto.issues;
            }
            return dto;
        });

        if (validRows.length === 0) return;

        try {
            const requestPayload = {
                records: dtoRows,
                tableId,
                parameters,
            };
            const responseData = await (saveTableData
                ? saveTableData(requestPayload, refetchData)
                : saveAutoformRecords(requestPayload));

            toast(intl.formatMessage({ id: 'autoform_save_changes_success' }));

            let updatedIssues = [];

            if (responseData?.issues) {
                // if we had previous errors, we make a change and now we only have warnings
                // we swap messages in the issues dialog for the row
                updatedIssues = updateIssues(issues, responseData.issues);
            } else {
                // if we had previous errors/warnings, we made a change and we don't have anything wrong
                // we delete all messages in the issues dialog for the row
                const ids = rowIds.map((rowId) => gridApiRef.current.getRowNode(rowId).data[primaryKeyColumnName]);
                updatedIssues = issues.filter((issueObj) => !ids.includes(issueObj.id));
            }

            if (responseData?.globalIssues) {
                // make sure to re-open the disclosures again
                globalWarningsDisclosure.onOpen();

                // handle global warnings
                const { warnings } = mapGeneralValidationMessages(responseData.globalIssues);
                setGlobalWarnings(warnings);
            }

            setIssues(updatedIssues);

            onActionInitiated && onActionInitiated();
        } catch (error) {
            if (error.response?.data.issues) {
                setIssues((prev) => [...prev, ...error.response.data.issues]);
            }

            if (error.response?.data.globalIssues) {
                // make sure to re-open the disclosures again,
                // because the user might have closed them and is submitting the form again
                globalErrorsDisclosure.onOpen();
                globalWarningsDisclosure.onOpen();

                // handle global errors and warnings
                const { warnings, errors } = mapGeneralValidationMessages(error.response.data.globalIssues);
                setGlobalWarnings(warnings);
                setGlobalErrors(errors);
            }
        }
    };

    const deleteRows = useCallback(
        async (rowIds) => {
            const gridApi = gridApiRef.current;
            const selectedRowsIds = rowIds.map((id) => gridApi.getRowNode(id).data[primaryKeyColumnName]);

            if (selectedRowsIds.length === 0) return;

            await deleteAutoformTableRows({
                recordsIds: selectedRowsIds,
                tableId,
                parameters,
            });
            toast(intl.formatMessage({ id: 'common_delete_grid_msg' }, { n: selectedRowsIds.length }));

            refetchData();

            onActionInitiated && onActionInitiated();
        },
        [intl, onActionInitiated, parameters, primaryKeyColumnName, refetchData, tableId, toast]
    );

    const onDeleteRowsInitiated = useCallback(
        (rowIds) => {
            if (!tableMetadata?.canDelete) {
                return null;
            }
            if (rowDeleteConfirmation === DELETE_CONFIRMATION_TYPE.None) {
                deleteRows(rowIds);
            } else {
                pendingRowIds.current = rowIds;
                deleteRowConfirmModal.onOpen();
            }
        },
        [deleteRows, deleteRowConfirmModal, pendingRowIds, rowDeleteConfirmation, tableMetadata?.canDelete]
    );

    const deleteRowsConfirm = useCallback(async () => {
        deleteRowConfirmModal.onClose();
        await deleteRows(pendingRowIds.current);
        pendingRowIds.current = [];
    }, [deleteRowConfirmModal, deleteRows, pendingRowIds]);

    const selectRowForEditing = useCallback(
        ({ api, node }) => {
            if (node?.data) {
                selectItem(node.data);
            }
        },
        [selectItem]
    );

    const deleteAllRecords = useCallback(async () => {
        const payload = {
            tableId,
            parentRecordId: selectedParentRecordId,
            parameters,
            ignoreRequiredParentValidation,
        };

        deleteAll ? await deleteAll(payload) : await deleteAutoformGridTable(payload);

        // if there is a chart, update the chart key which would automatically refresh the chart
        if (hasChart) refreshKey();

        onActionInitiated && onActionInitiated();
    }, [
        tableId,
        selectedParentRecordId,
        parameters,
        ignoreRequiredParentValidation,
        onActionInitiated,
        deleteAll,
        hasChart,
        refreshKey,
    ]);

    const onApplyFilters = (filterValues) => {
        filterDrawer.onClose();

        const mapFilterValues = parseAutoformDataFilters(filterValues, tableMetadata);
        setFilters(mapFilterValues);
        dispatch(setFiltersForTable({ tableId, filters: filterValues.customFilters }));
    };

    const deleteAllContent = useMemo(() => {
        if (!tableMetadata.canDelete) {
            return null;
        }

        return config?.renderDeleteAll ? (
            config.renderDeleteAll({ refetchData, deleteAllRecords, tableId })
        ) : (
            <DeleteAllButton refetchData={refetchData} onDelete={deleteAllRecords} mb={{ base: 4, xl: 0 }} />
        );
    }, [refetchData, deleteAllRecords, config, tableMetadata?.canDelete, tableId]);

    const postProcessGridColumnDefinitions = useCallback(
        (definitions) => {
            const bulkFields = formikContext?.values?.bulkUpdate;

            if (bulkUpdateParentRecordId && tableId && bulkFields && sortedColumns) {
                definitions.map((definition) => {
                    const columnId = sortedColumns.find((column) => column.name === definition?.field)?.id;
                    const targetSwitcherName = generateBulkUpdateSwitcherName(
                        bulkUpdateParentRecordId,
                        tableId,
                        columnId
                    );

                    if (bulkFields.hasOwnProperty(targetSwitcherName)) {
                        definition.hide = !bulkFields[targetSwitcherName];
                    }

                    return definition;
                });
            }

            return definitions;
        },
        [bulkUpdateParentRecordId, tableId, sortedColumns, formikContext]
    );

    let gridColDefs = useMemo(() => {
        let result = columnDefinitions.map((def) => ({
            ...def,
            ...(isReadOnly ? { editable: false } : null),
        }));
        if (childrenTables?.length > 0) {
            result.push({
                type: 'button',
                colId: 'editChild',
                field: 'instrumentId',
                headerName:
                    config.childrenTablesColumnName ??
                    (childrenTables.length > 1
                        ? intl.formatMessage({ id: 'autoform_edit_column_header' })
                        : childrenTables[0].name),
                resizable: true,
                sortable: false,
                onCellClicked: selectRowForEditing,
            });
        }

        postProcessGridColumnDefinitions(result);

        return result;
    }, [
        columnDefinitions,
        config?.childrenTablesColumnName,
        childrenTables,
        isReadOnly,
        selectRowForEditing,
        intl,
        postProcessGridColumnDefinitions,
    ]);

    const gridResource = useMemo(() => {
        const create = tableMetadata.canCreate
            ? async (newRows) => {
                  if (newRows.length === 0) return;

                  const formattedRows = newRows.map((row) => getDtoFromDataValueObject(row, tableMetadata));

                  const payload = {
                      tableId,
                      records: filterIssuesColumn(formattedRows),
                      parentRecordId: selectedParentRecordId,
                      parameters,
                  };

                  return addTableRow
                      ? await addTableRow({ payload, newRows })
                      : await addMultipleAutoformTableRow(payload);
              }
            : undefined;

        return {
            create,
            validate: async (newRows) => {
                const formattedRows = newRows.map((row) => getDtoFromDataValueObject(row, tableMetadata));

                const payload = {
                    tableId,
                    records: filterIssuesColumn(formattedRows),
                    parentRecordId: selectedParentRecordId,
                    parameters,
                };

                const responseData = onCheckData
                    ? await onCheckData({ payload, newRows })
                    : await checkDataMultiple(payload);

                return responseData;
            },
            getDataSourceView: async () => getDataSourceView(dataSourceViewRequest),
        };
    }, [
        parameters,
        selectedParentRecordId,
        tableId,
        tableMetadata,
        addTableRow,
        onCheckData,
        getDataSourceView,
        dataSourceViewRequest,
    ]);

    //For HighChart to display 'No Results' in case of no data
    const canCreate = typeof gridResource?.create === 'function';

    const onAddRowsSuccess = useCallback(() => {
        const successMessage = intl.formatMessage({ id: 'common_generic_item_change_success' });
        toast(successMessage);
        refetchData();

        // if there is a chart, update the chart key which would automatically refresh the chart
        if (hasChart) refreshKey();

        onActionInitiated && onActionInitiated();
    }, [intl, toast, refetchData, onActionInitiated, refreshKey, hasChart]);

    const addGridRowsContent = useMemo(() => {
        if (!tableMetadata.canCreate || isReadOnly) {
            return null;
        }

        return config?.renderAddGridRows ? (
            config.renderAddGridRows({ columnDefinitions, gridResource, onAddRowsSuccess })
        ) : (
            <AddGridRowsContainer
                columnDefinitions={config?.addDataColumns ?? columnDefinitions}
                gridResource={gridResource}
                onAddRowsSuccess={onAddRowsSuccess}
                config={config}
            />
        );
    }, [columnDefinitions, gridResource, onAddRowsSuccess, config, isReadOnly, tableMetadata?.canCreate]);

    const grid = (
        <DataGridWrapper height={config?.gridWrapperHeight}>
            <DataGrid
                serverSideDatasource={{
                    getRows: getServerSideData,
                }}
                serverSideDataSourceView={getDataSourceView}
                onGridReady={(params) => {
                    gridApiRef.current = params.api;
                    gridColumnApiRef.current = params.columnApi;
                }}
                blockLoadDebounceMillis={100}
                onDataChange={updateServerSideData}
                onDelete={onDeleteRowsInitiated}
                rowModelType="serverSide"
                serverSideStoreType="partial"
                paginationPageSize={DEFAULT_PAGINATED_PAGE_SIZE}
                cacheBlockSize={500}
                suppressRowClickSelection={true}
                onCellKeyPress={(params) => {
                    if (params.event.key === 'Enter' && params.column.colId === 'editChild') {
                        selectRowForEditing(params);
                    }
                }}
                enableBrowserTooltips={false}
                tooltipMouseTrack={false}
                issues={issues}
                globalIssues={globalIssues}
                primaryKeyName={primaryKeyColumnName}
                columns={gridColDefs}
                addDataColumns={config?.addDataColumns ?? columnDefinitions}
                onAddRowsSuccess={onAddRowsSuccess}
                gridResource={gridResource}
            />

            <AutoformDeleteConfirmationModal
                isOpen={deleteRowConfirmModal.isOpen}
                onClose={deleteRowConfirmModal.onClose}
                rowDeleteConfirmation={rowDeleteConfirmation}
                onConfirm={deleteRowsConfirm}
            />
        </DataGridWrapper>
    );

    return (
        <>
            {showHeader && (
                <Flex align="center">
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        {tableMetadata.name}
                    </Heading>
                    {helpAnchor && <HelpButtonComponent helpAnchor={helpAnchor} itemTypeId={helpPageUrlItemTypeId} />}
                </Flex>
            )}

            {formikContext ? (
                <AutoformBulkUpdate
                    tableId={tableId}
                    parentRecordId={bulkUpdateParentRecordId}
                    columnDefinitions={columnDefinitions}
                    columns={sortedColumns}
                    config={config}
                />
            ) : null}

            <CustomBox mb={6}>
                {showSearchbar && <SearchBar textHolder={translations.searchText} onChange={setSearchString} />}

                <Flex justify="flex-end" w="100%" wrap="wrap" direction={{ base: 'column', xl: 'row' }} py={3} px={0}>
                    {showDownloadUploadButton && (
                        <>
                            <AutoformDownloadButton
                                tablesMetadata={[tableMetadata]}
                                parentTableId={tableMetadata?.parentId}
                                mr={{ base: 0, xl: 4 }}
                                mb={{ base: 4, xl: 4 }}
                                config={config}
                            />
                            {tableMetadata.canCreate && (
                                <AutoformUploadButton
                                    tablesMetadata={[tableMetadata]}
                                    parentTableId={tableMetadata?.parentId}
                                    mr={{ base: 0, xl: 4 }}
                                    mb={{ base: 4, xl: 0 }}
                                    config={config}
                                />
                            )}
                        </>
                    )}

                    {showFiltersButton && (
                        <FiltersButton
                            mr={{ base: 0, xl: 4 }}
                            mb={{ base: 4, xl: 0 }}
                            hasIndicator={isFilterApplied(tableId, customFilters)}
                            onClick={filterDrawer.onOpen}
                        />
                    )}

                    {customButtons}

                    {deleteAllContent}

                    {addGridRowsContent}
                </Flex>

                {globalErrors.length > 0 && (
                    <GeneralFormValidationsAlert
                        isOpen={globalErrorsDisclosure.isOpen}
                        onClose={globalErrorsDisclosure.onClose}
                        messages={globalErrors}
                    />
                )}

                {globalWarnings.length > 0 && (
                    <GeneralFormValidationsAlert
                        status="warning"
                        title={<FormattedMessage id="common_warning" />}
                        isOpen={globalWarningsDisclosure.isOpen}
                        onClose={globalWarningsDisclosure.onClose}
                        messages={globalWarnings}
                    />
                )}
            </CustomBox>

            {hasChart ? (
                <Tabs index={index} onChange={onViewChange} variant="icon" isLazy isManual lazyBehavior="keepMounted">
                    <>
                        <Flex justifyContent="flex-end" wrap="wrap">
                            <TabList ml={4}>
                                <Tab>
                                    <Tooltip label={intl.formatMessage({ id: 'common_graph_btn_aria_label' })}>
                                        <GraphIcon />
                                    </Tooltip>
                                </Tab>

                                <Tab>
                                    <Tooltip label={intl.formatMessage({ id: 'common_tabular_btn_aria_label' })}>
                                        <TableRowsIcon />
                                    </Tooltip>
                                </Tab>
                            </TabList>
                        </Flex>

                        <TabPanels mt={2}>
                            <TabPanel>
                                {
                                    <Highchart
                                        //refreshKey() would update this key to refresh this chart when data is updated
                                        key={key}
                                        fetchData={getChartData}
                                        onAdd={addDataModalForChart.onOpen}
                                        canCreate={canCreate}
                                    />
                                }
                            </TabPanel>
                            <TabPanel>{grid}</TabPanel>
                        </TabPanels>
                    </>
                </Tabs>
            ) : (
                <>{grid}</>
            )}
            {addDataModalForChart.isOpen && (
                <AddDataModal
                    isOpen
                    onClose={addDataModalForChart.onClose}
                    columns={config?.addDataColumns ?? columnDefinitions}
                    gridResource={gridResource}
                    onAddRowsSuccess={onAddRowsSuccess}
                />
            )}
            {children && typeof children === 'function' && children({ selectedItem })}
            {filterDrawer.isOpen && (
                <SideDrawer
                    isOpen
                    header={
                        <FormattedMessage
                            id="autoform_filter_drawer_heading"
                            values={{ tableName: tableMetadata.name }}
                        />
                    }
                    content={
                        <ItemFilters
                            item={tableId}
                            tableName={tableMetadata.name}
                            onApplyFilters={onApplyFilters}
                            hasEndDateFilter={tableMetadata.hasEndDateFilter}
                            hasStartDateFilter={tableMetadata.hasStartDateFilter}
                            hasUpdateOrCreateDateFilter={tableMetadata.hasUpdateOrCreateDateFilter}
                            customFilters={customFilters ?? [{ columnId: '', operation: '', filterValue: '' }]}
                            validateCustomFilters={validateAutoformCustomFilters}
                            itemStartFilterHeading={
                                tableMetadata.startDateColumnName && tableMetadata.endDateColumnName ? (
                                    <FormattedMessage
                                        id={'autoform_item_start_filter'}
                                        values={{
                                            startFilter: tableMetadata.startDateColumnName,
                                            endFilter: tableMetadata.endDateColumnName,
                                        }}
                                    />
                                ) : (
                                    <FormattedMessage
                                        id={'autoform_item_single_filter'}
                                        values={{
                                            filterName:
                                                tableMetadata.startDateColumnName || tableMetadata.endDateColumnName,
                                        }}
                                    />
                                )
                            }
                            secondaryFilterHeading={<FormattedMessage id={'common_item_update_filter'} />}
                            filterHint={<FormattedMessage id="common_autoform_apply_filter_message" />}
                        >
                            <CustomFilter columns={sortedColumns} tableId={tableId} />
                        </ItemFilters>
                    }
                    onClose={filterDrawer.onClose}
                    placement="right"
                />
            )}
        </>
    );
};

const CustomBox = styled(Box)`
    display: flex;
    align-items: center;

    @media (max-width: 1280px) {
        flex-wrap: wrap;
        flex-direction: column;
    }
`;

export default AutoformTableContainer;
