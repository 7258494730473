import { Box, Text, Flex, Divider, Button } from '@chakra-ui/react';
import { PLANT_CONFIGURATION_CODE } from 'constants/hydroProject';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as HydroItemIcon } from '../../icons/item-icons/hydro-project.svg';
import styled from '@emotion/styled/macro';
import CustomNavLink from 'components/utils/CustomNavLink';

const HydroProjectPortfolio = ({ portfolioItem }) => {
    return (
        <Flex flexDirection="column" h="100%" backgroundColor="background-secondary">
            <Flex alignItems="center" px={6} mt={7} mb={5}>
                <StyledHydroItemIcon isMaster={portfolioItem.properties?.isMaster} />
                <Text ml={3} color="gray.900" fontSize="2xl">
                    {portfolioItem.label}
                </Text>
            </Flex>
            <Divider />
            <Box px={6}>
                <Text mt={8} mb={4}>
                    <FormattedMessage id="hydro_project_configure_item" />
                </Text>
                <Button
                    as={CustomNavLink}
                    to={`/hydro-project/${PLANT_CONFIGURATION_CODE}/${portfolioItem.properties.id}`}
                    type="button"
                >
                    <FormattedMessage id="common_configure" />
                </Button>
            </Box>
        </Flex>
    );
};

const StyledHydroItemIcon = styled(HydroItemIcon, { shouldForwardProp: (prop) => prop !== 'isMaster' })`
    width: 32px;
    height: 32px;
    color: var(--chakra-colors-${(props) => (props.isMaster ? 'green-500' : 'gray-600')});
`;

export default HydroProjectPortfolio;
