import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';

import GeneralValidationPanel from './GeneralValidationPanel';
import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';

/**
 * Checks store for general validation errors or warnings in
 * this table and displays them.
 *
 * @param {string[]} tableId
 *
 */
const GeneralValidationPanelContainer = ({ tableId }) => {
    const { selectedItem } = useAutoformTableMetadata(tableId);
    const validationMessages = useSelector(
        (state) =>
            state.autoform.tables[tableId] &&
            state.autoform.tables[tableId].generalValidationMessages &&
            state.autoform.tables[tableId].generalValidationMessages[selectedItem]
    );

    if (!validationMessages?.errors || !validationMessages?.warnings) return null;
    if (validationMessages.errors.length === 0 && validationMessages.warnings.length === 0) return null;

    return (
        <Box mt={6}>
            <GeneralValidationPanel errors={validationMessages.errors} warnings={validationMessages.warnings} />
        </Box>
    );
};

export default GeneralValidationPanelContainer;
