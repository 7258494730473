import { useState, useEffect } from 'react';

import { getBookUsers } from '../../services/userManagement';

const useBookUsers = (bookId) => {
    const [bookUsers, setBookUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (bookId) {
            setIsLoading(true);
            setIsError(false);

            getBookUsers(bookId)
                .then((response) => {
                    setBookUsers(response);
                })
                .catch(() => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [bookId]);

    return { bookUsers, isLoading, isError };
};
export default useBookUsers;
