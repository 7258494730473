import Autoform from './Autoform';
import { SHAPE_SET_SCHEMA_CODE } from 'constants/shapeSet';

import ShapeSetSubItem from 'components/shapeSet/ShapeSetSubItem';

const ShapeSet = () => {
    return (
        <Autoform
            config={{
                autoformId: SHAPE_SET_SCHEMA_CODE,
                renderSubItem: (item) => {
                    return <ShapeSetSubItem item={item} />;
                },
            }}
        />
    );
};

export default ShapeSet;
