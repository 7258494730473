import { InputLeftAddon, InputRightAddon } from '@chakra-ui/react';

import { INPUT_TYPES, DATA_TYPES } from '../../constants/fields';

import InputFormikField from '../forms/InputFormikField';
import DateFormikField from '../forms/DateFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import ProgressBarFormikField from '../forms/ProgressBarFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';

/**
 * Component to display an appropriate formik field input.
 *
 * @param {string} inputType - Input type. Value from INPUT_TYPES enum. Determined
 * by column metadata
 * @param {string} inputName
 * @param {string} inputLabel
 * @param {string} dataType - The data type of the column. Input type. Value from DATA_TYPES enum.
 * @param {{value:any, text:string}[]} dropdownOptions - List of objects that represent the dropdown options
 * for a combobox.
 * @param {string} inputPlaceholder - Placeholder for the input where appropriate.
 * @param {object} settings - Validation settings for different inputs.
 * @param {boolean} isDisabled
 * @param {boolean} isRequired
 * @param {object} formats - Formatting options for different inputs.
 * @param {boolean} showTimeInput - Is time picker displayed in the Datepicker.
 * @param {callback} onChange - Is time picker displayed in the Datepicker.
 */
const AutoformInputFormikField = ({
    inputType,
    inputName,
    inputLabel,
    dataType,
    dropdownOptions,
    inputPlaceholder,
    settings,
    isDisabled,
    isRequired = false,
    formats,
    showTimeInput,
    onChange,
    ...rest
}) => {
    const numberField = (
        <NumberInputFormikField
            id={inputName}
            name={inputName}
            label={inputLabel}
            placeholder={inputPlaceholder}
            isDisabled={isDisabled}
            step={settings?.NUDSTEP && +settings.NUDSTEP}
            max={settings?.NUDMAX && +settings.NUDMAX}
            min={settings?.NUDMIN && +settings.NUDMIN}
            info={settings?.CELLTOOLTIP}
            precision={dataType === DATA_TYPES.Int ? 0 : null}
            isRequired={isRequired}
            clampValueOnBlur={false}
            keepWithinRange={false}
            leftElement={
                formats.numberFormat === 'currency' && (
                    <InputLeftAddon pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" mr={1} />
                )
            }
            rightElement={formats.numberFormat === 'percent' && <InputRightAddon children="%" ml={1} />}
        />
    );
    const dateField = (
        <DateFormikField
            id={inputName}
            name={inputName}
            label={inputLabel}
            placeholder={inputPlaceholder}
            info={settings?.CELLTOOLTIP}
            isDisabled={isDisabled}
            minDate={settings?.NUDMIN}
            maxDate={settings?.NUDMAX}
            dateFormat={formats.dateFormat}
            showTimeInput={showTimeInput}
            isRequired={isRequired}
            isInPortal={rest.isInPortal}
        />
    );
    switch (inputType) {
        case INPUT_TYPES.ComboBox:
            return (
                <ComboboxFormikField
                    id={inputName}
                    name={inputName}
                    label={inputLabel}
                    options={dropdownOptions}
                    valueKey="value"
                    labelKey="text"
                    info={settings?.CELLTOOLTIP}
                    isDisabled={isDisabled}
                    isRequired={isRequired}
                    showPlaceholder={!isRequired}
                    placeholderValue={''}
                    onChange={onChange}
                    validateOnBlur={true}
                />
            );
        case INPUT_TYPES.CheckBox:
            return (
                <CheckboxFormikField
                    mt={6}
                    id={inputName}
                    name={inputName}
                    isDisabled={isDisabled}
                    isRequired={isRequired}
                    onChange={onChange && ((e) => onChange(e.target.checked))}
                    info={settings?.CELLTOOLTIP}
                >
                    {inputLabel}
                </CheckboxFormikField>
            );
        case INPUT_TYPES.NumericUpDown:
            return numberField;
        case INPUT_TYPES.MonthCalendar:
            return dateField;
        case INPUT_TYPES.ProgressBar:
            return <ProgressBarFormikField id={inputName} name={inputName} label={inputLabel} />;
        default:
            switch (dataType) {
                case DATA_TYPES.Int:
                case DATA_TYPES.Double:
                    return numberField;
                case DATA_TYPES.DateTime:
                    return dateField;
                default:
                    return (
                        <InputFormikField
                            id={inputName}
                            name={inputName}
                            label={inputLabel}
                            isDisabled={isDisabled}
                            placeholder={inputPlaceholder}
                            info={settings?.CELLTOOLTIP}
                            isRequired={isRequired}
                            leftElement={
                                formats.numberFormat === 'currency' && (
                                    <InputLeftAddon
                                        pointerEvents="none"
                                        color="gray.300"
                                        fontSize="1.2em"
                                        children="$"
                                        mr={1}
                                    />
                                )
                            }
                            rightElement={formats.numberFormat === 'percent' && <InputRightAddon children="%" ml={1} />}
                        />
                    );
            }
    }
};

export default AutoformInputFormikField;
