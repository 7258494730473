import { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Heading, useDisclosure, Box } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useIntl, FormattedMessage } from 'react-intl';

import useHistoryPush from '../hooks/useHistoryPush';

import { formatDataGridDate } from '../services/items';
import { getRestorationJobs, getStatuses, editJobStatuses } from '../services/restorationInputExtract';
import { getDropdownOptions } from '../services/expressJobs';

import MainWrapper from '../components/utils/MainWrapper';
import ExpressAndRestorationGrid from '../components/utils/ExpressAndRestorationGrid';

import InfoButtonModal from '../components/restorationInputExtract/InfoButtonModal';
import JobLogsModal from '../components/studies/JobLogsModal';
import LoadingModal from '../components/modal/LoadingModal';

const RestorationInputExtract = () => {
    const intl = useIntl();
    const historyPush = useHistoryPush();
    const gridApi = useRef(null);
    const infoModal = useDisclosure();

    const selectedJobForModal = useRef(null);
    const jobLogsModal = useDisclosure();

    const canUseRestoration = useSelector((state) => state.helpers.canUseRestoration);
    const canUseInputDataExtract = useSelector((state) => state.helpers.canUseInputDataExtract);
    const [restorationInputExtractTitleID, setTitleID] = useState('restoration_input_extract_title');

    const [statuses, setStatuses] = useState({
        data: [],
        loading: true,
        error: false,
    });

    const [dropdownOptions, setDropdownOptions] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const fetchStatuses = useCallback(() => {
        return getStatuses()
            .then((data) => setStatuses((prev) => ({ ...prev, error: false, data })))
            .catch(() => setStatuses((prev) => ({ ...prev, error: true })))
            .finally(() => setStatuses((prev) => ({ ...prev, loading: false })));
    }, []);

    const fetchDropdownOptions = useCallback(() => {
        return getDropdownOptions()
            .then((data) => setDropdownOptions((prev) => ({ ...prev, error: false, data })))
            .catch(() => setDropdownOptions((prev) => ({ ...prev, error: true })))
            .finally(() => setDropdownOptions((prev) => ({ ...prev, loading: false })));
    }, []);

    useEffect(() => {
        fetchDropdownOptions();
        fetchStatuses();
    }, [fetchStatuses, fetchDropdownOptions]);

    const onJobInfoClick = useCallback(
        ({ data: node }) => {
            selectedJobForModal.current = node;
            infoModal.onOpen();
            gridApi.current.stopEditing();
        },
        [infoModal]
    );

    const onViewJobLogsClicked = useCallback(
        ({ data: node }) => {
            selectedJobForModal.current = node;
            jobLogsModal.onOpen();
            gridApi.current.stopEditing();
        },
        [jobLogsModal]
    );

    const onViewPortfolioClicked = useCallback(
        ({ data: node }) => {
            if (node?.portfolioId) {
                const search = new URLSearchParams();
                search.append('portfolioId', node?.portfolioId);

                historyPush({
                    pathname: '/portfolios',
                    search,
                });
            }
        },
        [historyPush]
    );

    const onGridReady = useCallback((params) => {
        gridApi.current = params.api;
    }, []);

    const gridResource = useMemo(() => {
        return {
            read: getRestorationJobs,
            update: editJobStatuses,
        };
    }, []);

    const gridColumns = useMemo(() => {
        return [
            {
                field: 'jobStatus',
                editable: false,
                headerName: intl.formatMessage({ id: 'jobs_table_status' }),
                minWidth: 300,
                checkboxSelection: true,
                cellRenderer: 'StudyTrackerStatusBadge',
                headerComponent: 'CheckboxColumnHeader',
                cellRendererParams: {
                    serversideCheckbox: true,
                    isInJobsTable: true,
                },
            },
            {
                field: 'moreActions',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'restoration_input_extract_table_moreActions' }),
                minWidth: 100,
                editable: false,
                sortable: false,
                cellRenderer: 'MoreActionsCell',
                cellRendererParams: { onJobInfoClick, onViewJobLogsClicked, onViewPortfolioClicked },
            },
            {
                type: 'number',
                field: 'jobId',
                editable: false,
                headerName: intl.formatMessage({ id: 'restoration_input_extract_table_id' }),
            },
            {
                field: 'studyName',
                editable: false,
                headerName: intl.formatMessage({ id: 'restoration_input_extract_study_name' }),
            },
            {
                type: 'number',
                field: 'studyId',
                editable: false,
                headerName: intl.formatMessage({ id: 'restoration_input_extract_study_id' }),
            },
            {
                field: 'portfolioName',
                editable: false,
                headerName: intl.formatMessage({ id: 'restoration_input_extract_portfolio_name' }),
                minWidth: 220,
            },
            {
                type: 'number',
                field: 'portfolioId',
                editable: false,
                headerName: intl.formatMessage({ id: 'restoration_input_extract_portfolio_id' }),
            },
            {
                field: 'action',
                editable: false,
                headerName: intl.formatMessage({ id: 'restoration_input_extract_module' }),
                minWidth: 200,
            },
            {
                field: 'processedByMachine',
                editable: false,
                headerName: intl.formatMessage({ id: 'restoration_input_extract_processed_by' }),
                minWidth: 160,
            },
            {
                field: 'startDate',
                editable: false,
                headerName: intl.formatMessage({ id: 'restoration_input_extract_startDate' }),
                minWidth: 200,
                valueFormatter: (params) => formatDataGridDate(params),
                initialSort: 'asc',
                initialSortIndex: 1,
            },
            {
                field: 'endDate',
                editable: false,
                headerName: intl.formatMessage({ id: 'restoration_input_extract_endDate' }),
                minWidth: 200,
                valueFormatter: (params) => formatDataGridDate(params),
            },
            {
                field: 'runTimeString',
                editable: false,
                headerName: intl.formatMessage({ id: 'restoration_input_extract_run_time' }),
                minWidth: 120,
            },
        ];
    }, [intl, onJobInfoClick, onViewJobLogsClicked, onViewPortfolioClicked]);

    useEffect(() => {
        if (canUseRestoration && canUseInputDataExtract) {
            setTitleID('restoration_input_extract_title');
        } else if (!canUseRestoration && canUseInputDataExtract) {
            setTitleID('input_extract_title');
        } else if (canUseRestoration && !canUseInputDataExtract) {
            setTitleID('restoration_title');
        }
    }, [canUseRestoration, canUseInputDataExtract]);

    return (
        <MainWrapper>
            <Box>
                <Heading as="h2" variant="h2" mt={3}>
                    <FormattedMessage id={restorationInputExtractTitleID} />
                </Heading>
            </Box>
            {dropdownOptions?.loading || statuses?.loading ? (
                <LoadingModal header={<FormattedMessage id="common_loading" />} isOpen />
            ) : (
                <CustomWrapper>
                    <ExpressAndRestorationGrid
                        statuses={statuses.data}
                        gridApi={gridApi}
                        onGridReady={onGridReady}
                        gridResource={gridResource}
                        columns={gridColumns}
                        filtersEnabled={true}
                    />

                    {infoModal.isOpen && (
                        <InfoButtonModal
                            onClose={infoModal.onClose}
                            selectedJobToImport={selectedJobForModal.current}
                            dropdownOptions={dropdownOptions}
                        />
                    )}

                    {jobLogsModal.isOpen && (
                        <JobLogsModal jobId={selectedJobForModal.current?.jobId} onClose={jobLogsModal.onClose} />
                    )}
                </CustomWrapper>
            )}
        </MainWrapper>
    );
};

const CustomWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`;

export default RestorationInputExtract;
