import { Accordion, AccordionItem, AccordionPanel, Box, Flex, Heading, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import AccordionButton from '../utils/AccordionButton';

import { ReactComponent as QuestionMarkIcon } from '../../icons/question-mark-square.svg';

const SectionHint = ({ hintHeader, hintText }) => {
    return (
        <Accordion variant="section-hint" allowMultiple>
            <AccordionItem bg="background-tertiary">
                <AccordionButton>
                    {(icon) => (
                        <>
                            <Flex w="100%" py={3} px={4} align="center" backgroundColor={'background-tertiary'}>
                                <Box color="basis-configurator-icon">
                                    <QuestionMarkIcon />
                                </Box>

                                <Heading as="h4" variant="h4" ml={4} color="gray.800">
                                    <FormattedMessage id={hintHeader} />
                                </Heading>
                            </Flex>

                            {icon}
                        </>
                    )}
                </AccordionButton>

                <AccordionPanel pb={4}>
                    <Text fontSize="sm" mx={{ base: 4, md: 12 }} maxW="700px">
                        <FormattedMessage id={hintText} />
                    </Text>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default SectionHint;
