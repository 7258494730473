import { forwardRef } from 'react';
import styled from '@emotion/styled/macro';
import { Box, Text } from '@chakra-ui/react';

import { ReactComponent as SelectArrowIcon } from '../../icons/multiselect-arrow.svg';

const SelectButton = forwardRef(({ isInvalid, isDisabled, isWarning, buttonLabel, children, ...rest }, ref) => {
    return (
        <StyledSelectButton
            type="button"
            isInvalid={isInvalid}
            isDisabled={isDisabled}
            isWarning={isWarning}
            ref={ref}
            {...rest}
        >
            <Box flex={1} maxWidth="calc(100% - 16px)">
                <Text
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textTransform="capitalize"
                    fontWeight={400}
                    color={isInvalid ? 'text-error' : isDisabled ? 'gray.500' : 'input-text'}
                    _dark={{ color: isInvalid ? 'text-error' : isDisabled ? 'gray.500' : 'input-text' }}
                >
                    {buttonLabel}
                </Text>
            </Box>

            {children}

            <Box display="flex" color="input-arrow">
                <SelectArrowIcon />
            </Box>
        </StyledSelectButton>
    );
});

const StyledSelectButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--chakra-sizes-10);
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    border-radius: var(--chakra-radii-md);
    border: ${(props) =>
        props.isInvalid
            ? '1px solid var(--chakra-colors-text-error)'
            : props.isWarning
            ? '1px solid var(--chakra-colors-orange-300)'
            : props.isDisabled
            ? '1px solid var(--chakra-colors-input-disabled-border)'
            : '1px solid var(--chakra-colors-transparent)'};
    background: ${(props) =>
        props.isDisabled ? 'var(--chakra-colors-background-primary)' : 'var(--chakra-colors-background-quaternary)'};

    text-align: left;

    outline: 2px solid transparent;
    outline-offset: 2px;
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);

    padding-right: var(--chakra-sizes-3);
    padding-inline-start: var(--chakra-space-4);

    &:hover {
        border: 1px solid
            ${(props) =>
                props.isInvalid
                    ? 'var(--chakra-colors-text-error)'
                    : props.isWarning
                    ? 'var(--chakra-colors-orange-300)'
                    : props.isDisabled
                    ? 'var(--chakra-colors-input-disabled-border)'
                    : 'var(--chakra-colors-gray-300)'};
    }

    &:focus {
        background: var(--chakra-colors-background-tertiary);
        border-color: var(--chakra-colors-blue-400);
    }

    svg {
        width: 1em;
        margin-top: -4px;
    }

    &[aria-expanded='true'] {
        background: var(--chakra-colors-background-tertiary);
        border: 1px solid var(--chakra-colors-blue-400);
    }
`;

export default SelectButton;
