import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getItemTypes = createAsyncThunk('getItemTypes', async () => {
    const response = await axios.get(`/items/itemTypes`);

    return response.data;
});

export const getPricingPoints = createAsyncThunk('getPricingPoints', async () => {
    const response = await axios.get('/items/additional-data-for-creation');

    return response.data;
});
