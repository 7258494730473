import { useIntl } from 'react-intl';
import Autoform from './Autoform';
import { WEIGHT_SCHEMA_CODE } from 'constants/weightSchema';
import { copyModelWeight as copyModelWeightService } from '../services/weightingSchema';
import useCommonToast from '../hooks/useCommonToast';

const WeightSchemaAutoform = () => {
    const intl = useIntl();
    const { toast } = useCommonToast();

    const onAutoformCopy = async (autoformItems, reloadData, selectItem, primaryKeyColumnName) => {
        toast({ status: 'info', message: intl.formatMessage({ id: 'copy_btn_text' }) });
        const response = await copyModelWeightService(autoformItems[primaryKeyColumnName]);
        await Promise.all([reloadData(), selectItem(response.modelWeightId)]);
    };

    return (
        <Autoform
            config={{
                autoformId: WEIGHT_SCHEMA_CODE,
                deleteModalHeaderItem: intl.formatMessage({ id: 'weight_schema_page_title' }),
                deleteSuccessMessage: intl.formatMessage({
                    id: 'common_record_delete_success',
                }),
                shouldShowCopyIcon: true,
                itemDeleteButtonMessageId: 'common_delete_btn_tooltip_and_label',
                addNewItemMessageId: 'autoform_add_new_item',
                accordionPlaceholderId: 'weight_schema_page_title',
                searchPlaceholderId: 'common_search',
                addRecordModalItemType: intl.formatMessage({ id: 'autoform_save_changes_success' }),
                createSuccessMessage: intl.formatMessage({ id: 'common_record_creation_success' }),
                onCopy: onAutoformCopy,
            }}
        />
    );
};
export default WeightSchemaAutoform;
