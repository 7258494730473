import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

const BasisConfigDropdownButtonGroup = ({ children, ...rest }) => {
    return (
        <FlexWrapper gridGap={2} direction={{ base: 'column', sm: 'row' }}>
            {children}
        </FlexWrapper>
    );
};

const FlexWrapper = styled(Flex)`
    > button {
        margin-top: 22px; // the combobox label height + bottom margin
    }
`;

export default BasisConfigDropdownButtonGroup;
