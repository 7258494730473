import { Field } from 'formik';
import FormField from './FormField';
import _ from 'lodash';

import NumberInput from './NumberInput';
import { useCallback } from 'react';

// If you need to extend NumberInput functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for NumberInput should be excluded from ...props and manually added
const NumberInputFormikField = ({
    id,
    name,
    label,
    placeholder,
    precision,
    step,
    min,
    max,
    hasStepperButtons,
    clampValueOnBlur,
    keepWithinRange,
    leftElement,
    rightElement,
    validate,
    showPlaceholderIfDisabled,
    isRequired,
    warning,
    showWarning,
    ...props
}) => {
    const getFieldValue = useCallback(
        (field) => {
            let value = '';
            const regex = new RegExp('^((-)?(.)?)$');

            if (!showPlaceholderIfDisabled || !props?.isDisabled || !placeholder) {
                // zDefaultValue could be returned and Chakra uses toString(value)
                value = Number.isNaN(Number.parseFloat(field.value)) && !regex.test(field.value) ? '' : field.value; //regex to allow for values starting with '-' , '.' and '-.'
            }

            return value;
        },
        [showPlaceholderIfDisabled, props, placeholder]
    );

    return (
        <Field name={name} validate={validate}>
            {({ field, meta, form }) => {
                const status = _.get(form.status, field.name);
                const isInvalid = meta.touched && (meta.error || status?.errors?.length > 0);
                const isWarning = (meta.touched && status?.warnings?.length > 0) || showWarning;
                return (
                    <FormField
                        id={id}
                        name={name}
                        label={label}
                        isInvalid={isInvalid}
                        error={meta.error}
                        status={status}
                        isRequired={isRequired}
                        warning={warning}
                        {...props}
                    >
                        <NumberInput
                            // {...field} also contains onChange, which we overwrite
                            {...field}
                            value={getFieldValue(field)}
                            onChange={(value) => {
                                form.setFieldValue(name, value);
                                form.setStatus(_.omit(form.status, field.name));
                            }}
                            placeholder={placeholder}
                            precision={precision}
                            isWarning={isWarning}
                            step={step}
                            max={max}
                            min={min}
                            leftElement={leftElement}
                            rightElement={rightElement}
                            hasStepperButtons={hasStepperButtons}
                            clampValueOnBlur={clampValueOnBlur}
                            keepWithinRange={keepWithinRange}
                            isRequired={isRequired}
                        />
                    </FormField>
                );
            }}
        </Field>
    );
};

export default NumberInputFormikField;
