import React, { useMemo, useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { uniqBy } from 'lodash';

import {
    addGenerationPeriodsTableRow,
    getResourceVariablesTableData,
    getResourceVariablesDataSourceView,
    saveResourceVariablesTableData,
    exportAutoformTables,
    importAutoformTables,
} from '../../../services/batteries';
import { setFiltersConfig } from '../../../services/items';
import { filterIssuesColumn } from '../../../services/grid';
import { getDtoFromDataValueObject } from '../../autoform/utils/autoformUtils';
import { addMultipleAutoformTableRow } from '../../../services/autoforms';
import { checkDataMultiple, deleteAutoformGridTable } from '../../../services/batteries';

import { setCustomParameters } from '../../../store/autoform/autoformSlice';

import useAutoformMetadata from '../../autoform/hooks/useAutoformMetadata';
import useAutoformSelectItem from '../../autoform/hooks/useAutoformSelectItem';
import useAutoformColumnDefinitions from '../../autoform/hooks/useAutoformColumnDefinitions';
import useAutoformTableMetadata from '../../autoform/hooks/useAutoformTableMetadata';

import AutoformTabs from '../../autoform/AutoformTabs';
import AutoformTableContainer from '../../autoform/AutoformTableContainer';

import DefinitionsSkeleton from '../../utils/DefinitionsSkeleton';
import FiltersButton from '../../utils/FiltersButton';

import ResourceVariablesFilters from './ResourceVariablesFilters';

import DeleteAllButton from '../../itemData/DeleteAllButton';

import { DELETE_CONFIRMATION_TYPE } from '../../../constants/autoforms';
import useAutoformLoadTableDropdowns from 'components/autoform/hooks/useAutoformLoadTableDropdowns';

export const filtersStorageNamespace = 'resourceVariables';
const operationalDatesTableId = 25583;
const parametersTableId = 25585;

const ResourceVariablesTabs = forwardRef(
    (
        {
            itemId,
            innerSelectedTab,
            onInnerTabChange,
            batteryName,
            subBatteryName,
            subBatteryId,
            onDataChange,
            onAutoformTableChange,
            onLoadingStart,
            onLoadingEnd,
            filters,
            setFilters,
        },
        ref
    ) => {
        const intl = useIntl();
        const dispatch = useDispatch();
        const filterDisclosure = useDisclosure();

        const { metadata, hierarchicalTables } = useAutoformMetadata();
        //avoiding place subBatteryId here because we have it in the parameters
        useAutoformLoadTableDropdowns(parametersTableId);
        const parentTableId = useMemo(() => hierarchicalTables.level2[0]?.id, [hierarchicalTables.level2]);

        const operationalDatesColumnDefinitions = useAutoformColumnDefinitions(operationalDatesTableId);
        const parametersColumnDefinitions = useAutoformColumnDefinitions(parametersTableId);

        const { metadata: operationDatesTableMetadata } = useAutoformTableMetadata(operationalDatesTableId);
        const { metadata: parametersTableMetadata } = useAutoformTableMetadata(parametersTableId);

        const combinedColumnDefinitions = useMemo(
            () => uniqBy([...operationalDatesColumnDefinitions, ...parametersColumnDefinitions], 'field'),
            [operationalDatesColumnDefinitions, parametersColumnDefinitions]
        );

        const [selectedTable, setSelectedTable] = useState(null);

        useAutoformSelectItem(parentTableId, subBatteryId);

        useEffect(() => {
            if (metadata && subBatteryId) {
                dispatch(setCustomParameters(['55', subBatteryId.toString()]));
            }
        }, [metadata, subBatteryId, dispatch]);

        const autoformTables = useMemo(
            () => (hierarchicalTables.level3 ?? []).concat(hierarchicalTables.level4),
            [hierarchicalTables]
        );

        useEffect(() => {
            setSelectedTable(autoformTables[innerSelectedTab]);
        }, [autoformTables, innerSelectedTab]);

        useEffect(() => {
            onAutoformTableChange && onAutoformTableChange(selectedTable);
        }, [onAutoformTableChange, selectedTable]);

        const isCombinedTableId = useCallback(
            (tableId) => [operationalDatesTableId, parametersTableId].includes(tableId),
            []
        );

        const getCombinedFormattedRows = useCallback(
            (newRows) =>
                newRows?.map((row) => ({
                    ...getDtoFromDataValueObject(row, operationDatesTableMetadata),
                    ...getDtoFromDataValueObject(row, parametersTableMetadata),
                })),
            [operationDatesTableMetadata, parametersTableMetadata]
        );

        const getTableData = useCallback(
            async (params) => {
                onLoadingStart(params.tableId);

                const result = await getResourceVariablesTableData({
                    ...params,
                    minEndDate: !!filters?.minEndDate ? filters.minEndDate : null,
                });

                onLoadingEnd(params.tableId);

                return result;
            },
            [filters, onLoadingStart, onLoadingEnd]
        );

        const getTableDataSourceView = useCallback(
            async (params) => {
                const result = await getResourceVariablesDataSourceView({
                    ...params,
                    minEndDate: filters?.minEndDate ? filters.minEndDate : null,
                });

                return result;
            },
            [filters]
        );

        const saveTableData = useCallback((payload) => {
            return saveResourceVariablesTableData(payload);
        }, []);

        const addTableRow = useCallback(
            ({ payload, newRows }) => {
                if (!isCombinedTableId(payload?.tableId)) {
                    return addMultipleAutoformTableRow(payload);
                }

                const formattedRows = getCombinedFormattedRows(newRows);

                return addGenerationPeriodsTableRow({ ...payload, records: filterIssuesColumn(formattedRows) });
            },
            [getCombinedFormattedRows, isCombinedTableId]
        );

        const onCheckData = useCallback(
            ({ payload, newRows }) => {
                if (!isCombinedTableId(payload?.tableId)) {
                    return checkDataMultiple(payload);
                }

                const formattedRows = getCombinedFormattedRows(newRows);

                return checkDataMultiple({ ...payload, records: filterIssuesColumn(formattedRows) });
            },
            [getCombinedFormattedRows, isCombinedTableId]
        );

        const renderDeleteAll = useCallback(
            ({ refetchData, deleteAllRecords, tableId }) => (
                <DeleteAllButton
                    refetchData={refetchData}
                    onDelete={deleteAllRecords}
                    modalHeader={
                        isCombinedTableId(tableId) ? <FormattedMessage id={'common_delete_multi_grid'} /> : null
                    }
                    modalContent={
                        isCombinedTableId(tableId) ? (
                            <FormattedMessage id={'delete_multi_grid_modal_description'} />
                        ) : null
                    }
                    mb={{ base: 4, xl: 0 }}
                />
            ),
            [isCombinedTableId]
        );

        const handleDeleteAllRecords = useCallback(
            (payload) => {
                return deleteAutoformGridTable({ ...payload, itemId });
            },
            [itemId]
        );

        const autoformTableContainerConfig = useMemo(
            () => ({
                onCheckData,
                getTableData,
                getTableDataSourceView,
                renderDeleteAll,
                saveTableData,
                deleteAll: handleDeleteAllRecords,
                bulkUpdateSwitcherLabelId: 'common_bulk_update_varied_by_date',
                bulkUpdateConstantValuesLabelId: 'common_bulk_update_constant_values_heading',
                bulkUpdateVariedAllLabelId: 'common_bulk_update_all_varied_by_date',
                excludeParentRecordIdDropdownParameter: true,
            }),
            [onCheckData, getTableData, renderDeleteAll, saveTableData, handleDeleteAllRecords, getTableDataSourceView]
        );

        const combinedTablesAutoformTableConfig = useMemo(
            () => ({
                ...autoformTableContainerConfig,
                deleteAll: undefined,
                addTableRow,
                addDataColumns: combinedColumnDefinitions,
            }),
            [combinedColumnDefinitions, addTableRow, autoformTableContainerConfig]
        );

        const getDownloadFileName = useCallback(
            (isTemplateOnly) => {
                let result;
                const tabName = isCombinedTableId(selectedTable?.id)
                    ? intl.formatMessage({ id: 'batteries_operation_dates' })
                    : selectedTable?.name;

                if (isTemplateOnly) {
                    result = intl.formatMessage({ id: 'batteries_download_template' }, { tabName });
                } else {
                    result = `${batteryName}_${subBatteryName}_${tabName}`;
                }

                return result;
            },
            [batteryName, subBatteryName, isCombinedTableId, selectedTable, intl]
        );

        const downloadModalBodyMessage = useMemo(() => {
            let target;

            if (isCombinedTableId(selectedTable?.id)) {
                const names = [operationalDatesTableId, parametersTableId].map(
                    (id) => autoformTables.find((item) => item.id === id)?.name
                );
                target = `${names.join(' and ')} tabs`;
            } else {
                target = `${selectedTable?.name} tab`;
            }

            return <FormattedMessage id="common_download_modal_message" values={{ target }} />;
        }, [isCombinedTableId, selectedTable, autoformTables]);

        const uploadFriendlyNameText = useMemo(() => {
            let target;

            if (isCombinedTableId(selectedTable?.id)) {
                const names = [operationalDatesTableId, parametersTableId].map(
                    (id) => autoformTables.find((item) => item.id === id)?.name
                );
                target = `${names.join(' and ')} tabs`;
            } else {
                target = `${selectedTable?.name} tab`;
            }
            return target;
        }, [isCombinedTableId, selectedTable, autoformTables]);

        const downloadButtonConfig = useMemo(
            () => ({
                exportAutoformTables: async ({ isTemplateOnly, payload }) => {
                    const { fileName, parentRecordId, tablesData, ...params } = payload;
                    const targetTableData = tablesData.find((item) => item?.tableId === selectedTable.id);

                    if (targetTableData) {
                        return await exportAutoformTables({
                            ...params,
                            fileName: getDownloadFileName(isTemplateOnly),
                            payload: {
                                ...targetTableData,
                                parentRecordId: isCombinedTableId(targetTableData.tableId) ? parentRecordId : undefined,
                                filterData: {
                                    page: targetTableData?.filterData?.page,
                                    pageSize: targetTableData?.filterData?.pageSize,
                                    sortData: targetTableData?.filterData?.sortData,
                                    minEndDate: filters.minEndDate ? filters.minEndDate : null,
                                },
                            },
                        });
                    }
                },
                downloadModalBodyMessage,
            }),
            [filters, isCombinedTableId, selectedTable, getDownloadFileName, downloadModalBodyMessage]
        );

        const uploadButtonConfig = useMemo(
            () => ({
                importAutoformTables: async ({ tableIds, ...params }) => {
                    return await importAutoformTables(
                        {
                            ...params,
                            tableId: selectedTable.id,
                        },
                        { suppressErrorMessageHandler: true }
                    );
                },
                uploadFriendlyNameText,
            }),
            [selectedTable, uploadFriendlyNameText]
        );

        useImperativeHandle(
            ref,
            () => ({
                uploadButtonConfig,
                downloadButtonConfig,
                parentTableId,
                autoformTables,
            }),
            [uploadButtonConfig, downloadButtonConfig, parentTableId, autoformTables]
        );

        const renderAutoformTable = useCallback(
            (table) => (
                <AutoformTableContainer
                    schemaCode={metadata.schemaCode}
                    tableId={table.id}
                    showDownloadButton={autoformTables?.length === 1}
                    ignoreRequiredParentValidation={table.level === 4}
                    showFiltersButton={false}
                    onActionInitiated={onDataChange}
                    config={
                        isCombinedTableId(table.id) ? combinedTablesAutoformTableConfig : autoformTableContainerConfig
                    }
                    rowDeleteConfirmation={
                        isCombinedTableId(table.id) ? DELETE_CONFIRMATION_TYPE.MultiGrid : DELETE_CONFIRMATION_TYPE.None
                    }
                />
            ),
            [
                metadata,
                autoformTables,
                autoformTableContainerConfig,
                combinedTablesAutoformTableConfig,
                onDataChange,
                isCombinedTableId,
            ]
        );

        const onApplyFilters = useCallback(
            (data) => {
                setFiltersConfig(data, filtersStorageNamespace);
                setFilters(data);
            },
            [setFilters]
        );

        const onTabOpen = useCallback(
            (index) => {
                setSelectedTable(autoformTables[index]);
                onInnerTabChange(index);
            },
            [autoformTables, onInnerTabChange]
        );

        return metadata && autoformTables.length ? (
            <>
                <StyledAutoformTabs
                    variant="badges"
                    renderTabPanel={renderAutoformTable}
                    tables={autoformTables}
                    onTabOpen={onTabOpen}
                    selectedTab={innerSelectedTab}
                    tabListButtons={
                        <Flex ml="auto" order={{ base: -1, xl: 0 }} w={{ base: '100%', xl: 'auto' }}>
                            <FiltersButton
                                ml={{ base: 0, xl: 6 }}
                                mr={2}
                                hasIndicator={filters?.minEndDate}
                                onClick={filterDisclosure.onOpen}
                            />
                        </Flex>
                    }
                />

                <ResourceVariablesFilters
                    tableName={selectedTable?.name}
                    onApplyFilters={onApplyFilters}
                    filters={filters}
                    onClose={filterDisclosure.onClose}
                    isOpen={filterDisclosure.isOpen}
                />
            </>
        ) : (
            <DefinitionsSkeleton />
        );
    }
);

const StyledAutoformTabs = styled(AutoformTabs)`
    .chakra-tabs__tablist {
        margin: -17px -24px 0;
        width: auto;

        @media (min-width: 769px) {
            margin: -17px -30px 0;
        }
    }
`;

export default ResourceVariablesTabs;
