import { useMemo, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text, Flex, Button, Box, useDisclosure, Spacer } from '@chakra-ui/react';

import { colors } from '../../theme/colors';

import {
    createDateAsUTC,
    lenientParse,
    isNumberCellEmpty,
    isStringAValidNumber,
    isStringAValidDate,
    isStringAValidBoolean,
    isStringAValidSelectOption,
    enUSFormat,
} from '../../services/items';
import { cellValidationThreshold, columnTypes } from '../../services/grid';

import { mapGeneralValidationMessages } from '../autoform/utils/autoformUtils';

import useDebounce from '../../hooks/useDebounce';
import useTabDelimitedParser from '../../hooks/useTabDelimitedParser';
import useCommonToast from '../../hooks/useCommonToast';

import TextareaField from '../forms/TextareaField';
import GeneralFormValidationsAlert from '../forms/GeneralFormValidationsAlert';

import DataGrid from './DataGrid';
import DataGridWrapper from './DataGridWrapper';
import { isAfter, isBefore, parseISO, format } from 'date-fns';

const AddMultiRowsForm = ({ columns, onAdd, onValidate, onClose, isLoading, isValidating, instructions }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const errorsDisclosure = useDisclosure({ defaultIsOpen: true });
    const warningsDisclosure = useDisclosure({ defaultIsOpen: true });

    const [rawStringData, setRawStringData] = useState('');
    const [clientSideErrorMessage, setClientSideErrorMessage] = useState('');
    const [serverSideErrorMessage, setServerSideErrorMessage] = useState('');
    const [isParsing, setIsParsing] = useState(false);
    const [issues, setIssues] = useState([]);
    const [globalErrors, setGlobalErrors] = useState([]);
    const [globalWarnings, setGlobalWarnings] = useState([]);

    const globalIssues = useMemo(
        () => ({ errors: globalErrors, warnings: globalWarnings }),
        [globalErrors, globalWarnings]
    );

    const addDataLabel = intl.formatMessage({ id: 'common_add_rows_input_placeholder' });
    const invalidClientSideData = intl.formatMessage({ id: 'common_add_rows_error_message' });
    const invalidServerSideData = intl.formatMessage({ id: 'common_add_rows_serverside_error_message' });

    const generateBaseColDefFromConfig = useCallback((column) => {
        const defaultParams = columnTypes.hasOwnProperty(column.type) ? columnTypes[column.type].cellEditorParams : {};
        // If column has 'editable' property then check that, otherwise check 'isReadonly'
        // If column does not have 'isReadonly' property then edit will be allowed
        const isEditable = column.editable == null ? !column.isReadonly : column.editable;

        return {
            field: column.field,
            headerName: column.headerName,
            headerTooltip: column.headerTooltip,
            editable: isEditable,
            cellEditorParams: column.cellEditorParams || defaultParams || {},
        };
    }, []);

    // this hash isn't just the columns array keyed by the field
    // it also adds the column `type` needed for validation and formatting
    const colDefsHash = useMemo(() => {
        return columns.reduce((acc, col) => {
            acc[col.field] = {
                ...generateBaseColDefFromConfig(col),
                type: col.type,
            };

            return acc;
        }, {});
    }, [columns, generateBaseColDefFromConfig]);

    const validateTextarea = useCallback(
        (val) => {
            if (!val.trim().length) {
                setClientSideErrorMessage(invalidClientSideData);
            }
        },
        [invalidClientSideData]
    );

    const resetServerValidation = useCallback((errorMessage = '') => {
        setIssues([]);
        setGlobalErrors([]);
        setGlobalWarnings([]);
        setServerSideErrorMessage(errorMessage);
    }, []);

    const validateNumberColumn = useCallback(
        (value, column, result = {}) => {
            const isValid = isStringAValidNumber(value);

            if (isValid) {
                const val = Number(value);

                // apply min/max validations only when the min/max params are not null or undefined
                // eslint-disable-next-line eqeqeq
                if (column.cellEditorParams.min != undefined && val < column.cellEditorParams.min) {
                    result.message =
                        intl.formatMessage({ id: 'common_forms_gt' }, { label: column.headerName }) +
                        ` ${column.cellEditorParams.min}`;
                    // eslint-disable-next-line eqeqeq
                } else if (column.cellEditorParams.max != undefined && val > column.cellEditorParams.max) {
                    result.message =
                        intl.formatMessage({ id: 'common_forms_lt' }, { label: column.headerName }) +
                        ` ${column.cellEditorParams.max}`;
                } else {
                    result.isValid = true;
                }
            } else {
                result.message = intl.formatMessage({ id: 'common_invalid_number' }, { label: column.headerName });
            }

            return result;
        },
        [intl]
    );

    const validateTextColumn = useCallback(
        (value, column, result = {}) => {
            if (
                column.cellEditorParams.minLength !== null &&
                value.length > 0 &&
                value.length < column.cellEditorParams.minLength
            ) {
                result.message = intl.formatMessage(
                    { id: 'common_forms_string_gt' },
                    { label: column.headerName, limit: column.cellEditorParams.minLength }
                );
            } else if (column.cellEditorParams.maxLength !== null && value.length > column.cellEditorParams.maxLength) {
                result.message = intl.formatMessage(
                    { id: 'common_forms_string_lt' },
                    { label: column.headerName, limit: column.cellEditorParams.maxLength }
                );
            } else {
                result.isValid = true;
            }

            return result;
        },
        [intl]
    );

    const validateDateColumn = useCallback(
        (value, column, result = {}) => {
            if (!isStringAValidDate(value)) {
                result.message = intl.formatMessage({ id: 'common_invalid_date_format' }, { label: column.headerName });
            } else {
                const newDate = createDateAsUTC(lenientParse(value)).toISOString();

                if (
                    column.cellEditorParams?.minDate &&
                    isBefore(parseISO(newDate), new Date(column.cellEditorParams.minDate))
                ) {
                    result.message =
                        intl.formatMessage({ id: 'common_forms_gt' }, { label: column.headerName }) +
                        ` ${format(new Date(column.cellEditorParams?.minDate), enUSFormat)}`;
                } else if (
                    column.cellEditorParams?.maxDate &&
                    isAfter(parseISO(newDate), new Date(column.cellEditorParams.maxDate))
                ) {
                    result.message =
                        intl.formatMessage({ id: 'common_forms_lt' }, { label: column.headerName }) +
                        ` ${format(new Date(column.cellEditorParams?.maxDate), enUSFormat)}`;
                } else {
                    result.isValid = true;
                }
            }

            return result;
        },
        [intl]
    );

    const validateColumn = useCallback(
        (value, column) => {
            let result = {
                isValid: false,
                severity: 'Error',
                message: null,
            };
            const isRequired = column.cellEditorParams.required;
            const options = column.cellEditorParams.options;
            const isReadonly = !column.editable;
            const defaultValue = column.cellEditorParams.defaultValue;

            if (!isRequired && !value) {
                // field is not required and can be empty
                result.isValid = true;
            } else if (isRequired && !value) {
                // field is required and can not be empty
                result.message = intl.formatMessage(
                    { id: 'common_forms_validation_required' },
                    { label: column.headerName }
                );
            } else if (isReadonly && defaultValue && value !== defaultValue) {
                // Battery Percent Outages Readonly field should not be editable and should be defaulted to 100 in grid as well
                result.message = intl.formatMessage(
                    { id: 'common_forms_validation_readonly_default' },
                    { label: column.headerName, defaultValue }
                );
            } else if (column.type === 'number') {
                result = validateNumberColumn(value, column, result);
            } else if (column.type === 'date') {
                result = validateDateColumn(value, column, result);
            } else if (column.type === 'checkbox' && !isStringAValidBoolean(value)) {
                result.message = intl.formatMessage(
                    { id: 'common_invalid_boolean_format' },
                    { label: column.headerName }
                );
            } else if (column.type === 'select' && !isStringAValidSelectOption(value, options)) {
                result.message = intl.formatMessage({ id: 'common_invalid_selected_option' });
            } else if (column.type === 'text') {
                result = validateTextColumn(value, column, result);
            } else {
                result.isValid = true;
            }

            return result;
        },
        [intl, validateTextColumn, validateNumberColumn, validateDateColumn]
    );

    const getCellValidationBorderColor = useCallback(
        (params) => {
            const column = colDefsHash[params.colDef.field];

            // client-side errors first
            if (!validateColumn(params.value, column).isValid) {
                return { borderColor: colors.red['900'] };
            }

            const rowIssues = issues.find((issue) => issue.index === params.rowIndex);

            if (rowIssues) {
                // grab the per-field errors and warnings
                const fieldValidations = rowIssues.fields.filter((f) => f.name === params.colDef.field);

                const hasServerErrors = fieldValidations.some((field) =>
                    field.validationMessages.some((validation) => validation.severity === 'Error')
                );

                if (hasServerErrors) {
                    return { borderColor: colors.red['900'] };
                } else {
                    const hasServerWarnings = fieldValidations.some((field) =>
                        field.validationMessages.some((validation) => validation.severity === 'Warning')
                    );

                    if (hasServerWarnings) {
                        return { borderColor: colors.orange['400'] };
                    }
                }
            }

            // no errors or warnings anywhere
            return { borderColor: 'none' };
        },
        [colDefsHash, validateColumn, issues]
    );

    const colDefs = useMemo(() => {
        return columns.map((col) => {
            return {
                ...generateBaseColDefFromConfig(col),
                cellStyle: getCellValidationBorderColor,
            };
        });
    }, [getCellValidationBorderColor, columns, generateBaseColDefFromConfig]);

    const onParseStart = useCallback(() => setIsParsing(true), []);

    const onParseComplete = useCallback(
        (parsed) => {
            setIsParsing(false);

            if (parsed?.length > 0) {
                let count = 1;
                const issues = [];

                for (let index = 0; index < parsed?.length; index++) {
                    const row = parsed[index];

                    if (count > cellValidationThreshold) {
                        setClientSideErrorMessage('');
                        return;
                    }

                    const issueObject = {
                        id: null,
                        index,
                        fields: [],
                        generalValidationMessages: [],
                    };

                    for (const field in row) {
                        if (row.hasOwnProperty(field)) {
                            const column = colDefsHash[field];
                            const value = row[field];
                            const validationData = validateColumn(value, column);

                            if (!validationData.isValid) {
                                issueObject.fields.push({
                                    name: column.field,
                                    value: value,
                                    validationMessages: [
                                        {
                                            severity: validationData.severity,
                                            message: validationData.message,
                                        },
                                    ],
                                });
                            }
                        }
                    }

                    if (issueObject.fields.length) {
                        issues.push(issueObject);
                    }

                    count++;
                }

                if (issues.length) {
                    setClientSideErrorMessage(invalidClientSideData);
                    setIssues(issues);
                } else {
                    setClientSideErrorMessage('');
                }
            }
        },
        [colDefsHash, validateColumn, invalidClientSideData]
    );

    const debouncedInput = useDebounce(rawStringData, 250);
    const parsedData = useTabDelimitedParser({
        value: debouncedInput,
        columns,
        onStart: onParseStart,
        onComplete: onParseComplete,
    });

    /**
     * Transforms the form values to expected BE API format based on the field type.
     * BE expects empty, non-required values to be `null` and number values to be a `Number` or `null`.
     */
    const formatValues = useCallback(
        (rows) => {
            return rows.map((data) => {
                let formatted = {};

                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        const col = colDefsHash[key];
                        const value = data[key];

                        // @todo these checks and parser are modified versions of the valueSetters of the DataGrid columnTypes
                        if (value === '' && col.type !== 'checkbox') {
                            // @todo is the BE using null consistently or does it sometimes expect an empty string for empty values?
                            formatted[key] = null;
                        } else if (col.type === 'number') {
                            formatted[key] = isNumberCellEmpty(value);
                        } else if (col.type === 'date') {
                            formatted[key] = createDateAsUTC(lenientParse(value));
                        } else if (col.type === 'checkbox') {
                            if (value === '1' || value === 'true') {
                                formatted[key] = true;
                            } else {
                                formatted[key] = false;
                            }
                        } else if (col.type === 'select') {
                            const options = col.cellEditorParams.options || [];
                            const maybeId = value;
                            const maybeIdNumber = Number(value);
                            const maybeDescription = value;

                            // try to find a matching select option that either matches the id property or the description
                            const option = options.find(({ id, description }) => {
                                return id === maybeId || id === maybeIdNumber || description === maybeDescription;
                            });

                            if (option) {
                                formatted[key] = option.id;
                            }
                        } else {
                            // fallback to value when non of the above if-else checks don't match
                            formatted[key] = value;
                        }
                    }
                }

                return formatted;
            });
        },
        [colDefsHash]
    );

    const normalizeErrorResponse = useCallback(
        (err) => {
            // reset the previous errors and warnings
            resetServerValidation(invalidServerSideData);

            // make sure to re-open the disclosures again,
            // because the user might have closed them and is submitting the form again
            errorsDisclosure.onOpen();

            if (err.response.status !== 401 && err.response.status !== 500) {
                if (err.response.data.issues) {
                    setIssues(err.response.data.issues);
                }

                if (err.response.data.globalIssues) {
                    // handle global errors and warnings
                    const { warnings, errors } = mapGeneralValidationMessages(err.response.data.globalIssues);
                    setGlobalWarnings(warnings);
                    setGlobalErrors(errors);
                }

                if (err.response?.data?.error || err.response?.data?.title) {
                    const serverError = err.response.data.error || err.response.data.title;

                    toast({
                        status: 'error',
                        message: serverError || intl.formatMessage({ id: 'common_generic_saving_error' }),
                    });
                }
            }
        },
        [intl, toast, errorsDisclosure, invalidServerSideData, resetServerValidation]
    );

    const onSubmit = useCallback(async () => {
        validateTextarea(rawStringData);

        if (parsedData?.length) {
            const rows = formatValues(parsedData);

            try {
                await onAdd(rows);
            } catch (err) {
                normalizeErrorResponse(err);
            }
        }
    }, [validateTextarea, rawStringData, parsedData, formatValues, onAdd, normalizeErrorResponse]);

    const onCheck = useCallback(async () => {
        validateTextarea(rawStringData);

        if (parsedData?.length) {
            const rows = formatValues(parsedData);

            try {
                const data = await onValidate(rows);

                if (data.issues) {
                    setIssues(data.issues);
                }

                if (data.globalIssues) {
                    // handle global warnings
                    const { warnings } = mapGeneralValidationMessages(data.globalIssues);
                    setGlobalWarnings(warnings);
                }

                if (!data) {
                    toast(intl.formatMessage({ id: 'common_check_data_success' }));
                }
            } catch (err) {
                normalizeErrorResponse(err);
            }
        }
    }, [validateTextarea, rawStringData, parsedData, formatValues, onValidate, intl, toast, normalizeErrorResponse]);

    const onValueChange = useCallback(
        (event) => {
            const value = event.target.value;

            validateTextarea(value);
            setRawStringData(value);

            // clear server errors on textarea change
            resetServerValidation();
        },
        [validateTextarea, resetServerValidation]
    );

    const onBlurChange = useCallback(
        (event) => {
            validateTextarea(event.target.value);
        },
        [validateTextarea]
    );

    return (
        <>
            {globalErrors.length > 0 && (
                <GeneralFormValidationsAlert
                    isOpen={errorsDisclosure.isOpen}
                    onClose={errorsDisclosure.onClose}
                    messages={globalErrors}
                />
            )}

            {globalWarnings.length > 0 && (
                <GeneralFormValidationsAlert
                    status="warning"
                    title={<FormattedMessage id="common_warning" />}
                    isOpen={warningsDisclosure.isOpen}
                    onClose={warningsDisclosure.onClose}
                    messages={globalWarnings}
                />
            )}

            <Text variant="regular" mb={4}>
                {instructions || <FormattedMessage id="common_add_rows_sub_heading" />}
            </Text>

            <TextareaField
                isRequired={true}
                mb={4}
                id="data"
                name="data"
                label={addDataLabel}
                value={rawStringData}
                onChange={onValueChange}
                onBlur={onBlurChange}
                placeholder={addDataLabel}
                isInvalid={!!clientSideErrorMessage || !!serverSideErrorMessage}
                error={clientSideErrorMessage || serverSideErrorMessage}
                pb={!(clientSideErrorMessage || serverSideErrorMessage) && '25px'}
            />

            <Box mb={4}>
                <DataGridWrapper height="475px">
                    <DataGrid
                        rowData={parsedData}
                        columns={colDefs}
                        rowModelType="clientSide"
                        issues={issues}
                        globalIssues={globalIssues}
                    />
                </DataGridWrapper>
            </Box>

            <Flex justify="flex-end" borderTop="1px" borderColor="border-secondary" pt={4} pb={2} px={6} mx={-6} mt={9}>
                {onValidate && (
                    <Button
                        variant="secondary"
                        onClick={onCheck}
                        isDisabled={!!clientSideErrorMessage}
                        isLoading={isValidating}
                    >
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_check_data" />
                        </Box>
                    </Button>
                )}

                <Spacer />

                <Button variant="secondary" onClick={onClose}>
                    <Box as="span" textTransform="capitalize">
                        <FormattedMessage id="common_cancel" />
                    </Box>
                </Button>

                <Button
                    isDisabled={!!clientSideErrorMessage}
                    isLoading={isLoading || isParsing}
                    ml={4}
                    type="button"
                    textTransform="capitalize"
                    variant="primary-success"
                    onClick={onSubmit}
                >
                    <FormattedMessage id="common_add" />
                </Button>
            </Flex>
        </>
    );
};

export default AddMultiRowsForm;
