import { useRef, useCallback, useEffect } from 'react';
import { useVirtual } from 'react-virtual';
import styled from '@emotion/styled/macro';
import { noop } from '../../services/utils/index';
import { useSelector } from 'react-redux';
import NavigationItem from '../layout/NavigationItem';
import ItemHoverLabelIcons from '../utils/ItemHoverLabelIcons';

const VirtualisedItemList = ({
    items,
    onCopy,
    itemIndex,
    renderItems,
    onAddToPortfolioModalOpen,
    shouldShowIcons = true,
    config = {},
}) => {
    const parentRef = useRef();
    const lastIndex = useRef(null);
    const { permissions } = useSelector((state) => state.permissions);
    const { virtualItems, scrollToIndex, totalSize } = useVirtual({
        size: items.length,
        parentRef: parentRef,
        estimateSize: useCallback(() => 40, []),
        paddingStart: 8,
        paddingEnd: 8,
    });

    useEffect(() => {
        if (itemIndex >= 0) {
            const isAfterReInitialization = lastIndex.current === -1 && itemIndex !== -1;
            scrollToIndex(itemIndex, { align: isAfterReInitialization ? 'center' : 'auto' });
        }

        lastIndex.current = itemIndex;
    }, [itemIndex, scrollToIndex]);

    return (
        <Wrapper ref={parentRef}>
            <ul style={{ minHeight: totalSize }}>
                {virtualItems.map((virtualItem) => {
                    return (
                        <StyledNavigationItem
                            ref={virtualItem.measureRef}
                            key={virtualItem.index}
                            style={{ top: virtualItem.start }}
                        >
                            {renderItems(items[virtualItem.index])}

                            {shouldShowIcons && (
                                <ItemHoverLabelIcons
                                    onCopy={
                                        permissions.includes('POWERSIMM_ADMIN') ||
                                        permissions.includes('POWERSIMM_COPY')
                                            ? onCopy
                                            : noop
                                    }
                                    selected={items[virtualItem.index]}
                                    onAdd={config.shouldShowCopyIcon ? noop : onAddToPortfolioModalOpen}
                                />
                            )}
                        </StyledNavigationItem>
                    );
                })}
            </ul>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    height: 100%;
    position: relative;
    overflow-y: auto;
`;

const StyledNavigationItem = styled(NavigationItem)`
    position: absolute;
    left: 0;
    width: calc(100% - 2 * var(--chakra-space-6));
    box-shadow: none !important;
    border: 3px solid transparent;
    border-radius: var(--chakra-radii-lg);
    margin: 0 var(--chakra-space-6);

    > * {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    &:hover,
    &:focus-within {
        // Chakra uses 3px outline shadow with that exact outline color
        border: 3px solid rgba(66, 153, 225, 0.6);
    }
`;

export default VirtualisedItemList;
