import { useRouteMatch, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Box, Tabs, TabList, Tab } from '@chakra-ui/react';
import TabListWrapper from '../utils/TabListWrapper';
import useHistoryPush from '../../hooks/useHistoryPush';
import { useCallback, useMemo, useEffect, useState } from 'react';
import useAutoformMetadata from 'components/autoform/hooks/useAutoformMetadata';
import useAutoformTableMetadata from 'components/autoform/hooks/useAutoformTableMetadata';

const TabularPage = ({ routes, renderRoutes, isAutoForm = false }) => {
    let { path } = useRouteMatch();
    let { pathname } = useLocation();
    let historyPush = useHistoryPush();
    const [selectedTab, setSelectedTab] = useState(0);
    const [persistedRoute, setPersistedRoute] = useState('');

    const { hierarchicalTables } = useAutoformMetadata();
    const level1TableId = hierarchicalTables?.level1[0]?.id;
    const { selectedItem, primaryKeyColumnName } = useAutoformTableMetadata(level1TableId);

    const getPathByRouteIndex = useCallback((index) => `${path}/${routes[index].relativePath}`, [path, routes]);

    const updateRouteBySelected = useCallback(
        (route) => {
            if (selectedItem && primaryKeyColumnName === 'ITEMID') {
                setPersistedRoute(selectedItem);
                return `${route}/${selectedItem}`;
            }
            const persistedRouteUpdate = persistedRoute ? `${route}/${persistedRoute}` : `${route}`;

            return persistedRouteUpdate;
        },
        [primaryKeyColumnName, selectedItem, persistedRoute]
    );

    const defaultTabIndex = useMemo(() => {
        let index = 0;
        for (let i = 0; i < routes.length; i++) {
            const pathByRouteIndex = getPathByRouteIndex(i);
            if (pathname.startsWith(pathByRouteIndex)) {
                index = i;
                break;
            }
        }

        return index;
    }, [routes, pathname, getPathByRouteIndex]);

    const onTabOpen = useCallback(
        (index) => {
            const relativePath = getPathByRouteIndex(index);
            if (isAutoForm) {
                historyPush(relativePath);
            } else {
                const updatedPath = updateRouteBySelected(relativePath);
                historyPush(updatedPath);
            }
        },
        [historyPush, getPathByRouteIndex, updateRouteBySelected, isAutoForm]
    );

    useEffect(() => {
        if (selectedTab !== defaultTabIndex) {
            setSelectedTab(defaultTabIndex);
        }
    }, [defaultTabIndex, selectedTab, setSelectedTab]);

    return (
        <>
            <Box bg="background-secondary" pt="73px">
                <Tabs isManual isLazy onChange={onTabOpen} index={selectedTab}>
                    <TabList>
                        <Box px={6} w="100%">
                            <TabListWrapper>
                                {routes.map((route) => (
                                    <Tab key={route.relativePath} flexShrink={0}>
                                        {route.name}
                                    </Tab>
                                ))}
                            </TabListWrapper>
                        </Box>
                    </TabList>
                </Tabs>
            </Box>

            <Switch>
                <Route exact path={path}>
                    {/* If exact path then redirect to first tab index == 0 */}
                    <Redirect to={{ pathname: getPathByRouteIndex(0), search: window.location.search }} />
                </Route>

                {renderRoutes
                    ? // If renderRoutes() function was passed in from parent componenet as prop then use it to render
                      renderRoutes({ path, routes, selectedTab, setPersistedRoute })
                    : // Otherwise render custom component stored in route.component
                      routes.map((route, index) => (
                          <Route path={getPathByRouteIndex(index)} key={route.relativePath}>
                              {route.component}
                          </Route>
                      ))}
            </Switch>
        </>
    );
};

export default TabularPage;
