import { useIntl, FormattedMessage } from 'react-intl';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Skeleton,
    Box,
    Text,
    Heading,
    SimpleGrid,
    Flex,
    Spacer,
    Button,
} from '@chakra-ui/react';

import SearchBar from '../../components/utils/SearchBar';

const ConnectionStep = ({
    onSearch,
    isLoading,
    current,
    step,
    total,
    title,
    subtitle,
    description,
    onErrorDismiss,
    error,
    noResults,
    children,
    showVersionButton = false,
    onVersionModalOpen,
    ...rest
}) => {
    const intl = useIntl();

    return (
        <div hidden={current === step ? null : true} {...rest}>
            <Text mb={12} _dark={{ color: 'gray.500' }}>
                <FormattedMessage id="connection_options_step_n_of_m" values={{ step, total }} /> - {title}
            </Text>
            <Box>
                <Flex direction={{ base: 'column', lg: 'row' }}>
                    <Heading mb={2}>{subtitle}</Heading>

                    <Spacer />

                    <Box w={{ base: '100%', lg: '33%' }} m={{ base: '8px 0 16px', lg: '8px 0 0' }}>
                        <SearchBar
                            minH={10}
                            textHolder={intl.formatMessage({ id: 'connection_search_placeholder' }, { n: 2 })}
                            onChange={onSearch}
                        />
                    </Box>

                    {showVersionButton && (
                        <Button
                            variant="secondary"
                            size="sm"
                            m={{ base: '0 0 16px', lg: '8px 0 0 12px' }}
                            onClick={onVersionModalOpen}
                        >
                            <FormattedMessage id="connection_select_version" />
                        </Button>
                    )}
                </Flex>

                <Heading variant="subheading" mb={6}>
                    {description}
                </Heading>

                {error && (
                    <Alert status="error">
                        <AlertIcon />

                        <Box>
                            <AlertTitle textTransform="capitalize">
                                <FormattedMessage id="common_error" />
                            </AlertTitle>

                            <AlertDescription>{error}</AlertDescription>
                        </Box>

                        {onErrorDismiss && (
                            <CloseButton onClick={onErrorDismiss} position="absolute" right={2} top={2} />
                        )}
                    </Alert>
                )}

                {noResults ? (
                    <Alert status="info">
                        <AlertIcon />

                        <Box>
                            <AlertTitle>
                                <FormattedMessage id="common_no_search_results" />
                            </AlertTitle>
                        </Box>
                    </Alert>
                ) : (
                    <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={4}>
                        {isLoading ? (
                            <>
                                <Skeleton height={370} />
                                <Skeleton height={370} />
                                <Skeleton height={370} />
                            </>
                        ) : (
                            children
                        )}
                    </SimpleGrid>
                )}
            </Box>
        </div>
    );
};

export default ConnectionStep;
