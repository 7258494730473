import { Heading, Text, Button, Box } from '@chakra-ui/react';

const DashboardSidebarCard = ({ title, description, buttonText, buttonRoute, onClick, isDisabled, ...rest }) => (
    <Box w="100%" {...rest}>
        <Heading as="h4" variant="h4">
            {title}
        </Heading>

        <Text size="xs" variant="regular" mt={2}>
            {description}
        </Text>

        <Button
            mt={4}
            onClick={onClick}
            isDisabled={isDisabled}
            w={{ base: '100%', md: 'auto' }}
            _disabled={{ bg: 'white', color: 'gray.400' }}
        >
            {buttonText}
        </Button>
    </Box>
);

export default DashboardSidebarCard;
