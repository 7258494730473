import { useDisclosure } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import SecondaryButton from '../utils/SecondaryButton';

import { ReactComponent as AddIcon } from '../../icons/add-item.svg';
import AddRowsModal from './AddRowsModal';

const AddRowsButton = ({ reloadData, isDisabled }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <>
                <SecondaryButton
                    onClick={onOpen}
                    leftIcon={<AddIcon />}
                    size="sm"
                    type="button"
                    variant="secondary"
                    textTransform="capitalize"
                    isDisabled={isDisabled}
                >
                    <FormattedMessage id="common_add_rows" />
                </SecondaryButton>

                {isOpen && <AddRowsModal onClose={onClose} reloadData={reloadData} />}
            </>
        </>
    );
};

export default AddRowsButton;
