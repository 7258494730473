import { useState, useEffect, useCallback } from 'react';
import { Flex, Box, VStack, Heading, Button, Link, useDisclosure } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getDashboardSummary } from './../services/studies';

import { ReactComponent as CubeIcon } from '../icons/cube.svg';
import { ReactComponent as PowerBiIcon } from '../icons/power-bi.svg';

import DashboardLayoutWrapper from '../components/dashboard/DashboardLayoutWrapper';
import DashboardStats from '../components/dashboard/DashboardStats';
import DashboardSidebarCard from '../components/dashboard/DashboardSidebarCard';
import PowerBiModal from '../components/dashboard/PowerBiModal';
import StudyGrid from '../components/studies/StudyGrid';
import Tooltip from '../components/utils/Tooltip';

import TablespaceDetailModal from '../components/dashboard/TablespaceDetailModal';

import useHistoryPush from '../hooks/useHistoryPush';

import { getGroups } from '../store/powerBi/powerBiApi';
import { setLoading, reset } from '../store/powerBi/powerBiSlice';

const Dashboard = () => {
    const intl = useIntl();
    const historyPush = useHistoryPush();
    const powerBiModal = useDisclosure();
    const tableSpaceDetailModal = useDisclosure();
    const dispatch = useDispatch();
    const { isLoading: powerBiIsLoading, groups } = useSelector((state) => state.powerBi);
    const canUseRestoration = useSelector((state) => state.helpers.canUseRestoration);
    const canUseInputDataExtract = useSelector((state) => state.helpers.canUseInputDataExtract);

    const [noDataFound, setNoDataFound] = useState(false);
    const [dashboardSummary, setDashboardSummary] = useState({});

    const schemaGroupId = useSelector((state) => state.connections.schemaGroupId);
    const workspacesFound = groups?.length;

    const fetchSchemaGroupId = useCallback(async () => {
        const dashbordResult = await getDashboardSummary(schemaGroupId);
        setDashboardSummary(dashbordResult);
    }, [schemaGroupId]);

    const [runningTitleID, setRunningTitleID] = useState('dashboard_restorations_portfolio_extracts_running');
    const [restorationInputExtractTitleID, setTitleID] = useState('restoration_input_extract_title');

    useEffect(() => {
        fetchSchemaGroupId().catch(() => setNoDataFound(true));
    }, [fetchSchemaGroupId]);

    useEffect(() => {
        const handleGetGroups = async () => {
            if (groups === undefined) {
                dispatch(setLoading(true));
                await dispatch(getGroups());
                dispatch(setLoading(false));
            }
        };
        handleGetGroups();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups]);

    useEffect(() => {
        if (canUseRestoration && canUseInputDataExtract) {
            setRunningTitleID('dashboard_restorations_portfolio_extracts_running');
            setTitleID('restoration_input_extract_title');
        } else if (!canUseRestoration && canUseInputDataExtract) {
            setRunningTitleID('dashboard_portfolio_extracts_running');
            setTitleID('input_extract_title');
        } else if (canUseRestoration && !canUseInputDataExtract) {
            setRunningTitleID('dashboard_restorations_running');
            setTitleID('restoration_title');
        }
    }, [canUseRestoration, canUseInputDataExtract]);

    const navigateToPowerBiReports = useCallback(
        (group) => {
            const targetGroup = group ?? groups[0];

            if (targetGroup) {
                dispatch(reset());
                historyPush(`/power_bi_reports/${targetGroup.id}`);
            }
        },
        [dispatch, historyPush, groups]
    );

    const handlePowerBiBtnClick = useCallback(() => {
        if (groups?.length > 1) {
            powerBiModal.onOpen();
        } else {
            navigateToPowerBiReports();
        }
    }, [groups, powerBiModal, navigateToPowerBiReports]);

    const stats = [
        {
            label: intl.formatMessage({ id: 'dashboard_studies_running' }),
            isLabelVisible: true,
            value: intl.formatMessage({ id: 'dashboard_n_studies' }, { n: dashboardSummary.runningStudies }),
            isValueCritical: false,
            description: intl.formatMessage(
                { id: 'dashboard_you_have_n_running' },
                { n: dashboardSummary.startedByYou }
            ),
            hasDivider: false,
        },
        {
            label: (
                <Link onClick={tableSpaceDetailModal.onOpen} variant="tertiary" fontSize="14px">
                    <FormattedMessage id="dashboard_db_space" />
                </Link>
            ),

            isLabelVisible: true,
            value: intl.formatMessage(
                { id: 'dashboard_percentage_full' },
                { percentage: dashboardSummary.percentUsed === null ? '--' : Math.round(dashboardSummary.percentUsed) }
            ),
            isValueCritical: true,
            description: intl.formatMessage(
                { id: 'dashboard_db_space_ratio' },
                {
                    usedSize: (dashboardSummary.used === null ? '--' : Math.round(dashboardSummary.used)) + ' GB',
                    remainingSize:
                        (dashboardSummary.remaining === null ? '--' : Math.round(dashboardSummary.remaining)) + ' GB',
                }
            ),
            hasDivider: false,
        },
        ...(canUseRestoration || canUseInputDataExtract
            ? [
                  {
                      label: intl.formatMessage({ id: runningTitleID }),
                      isLabelVisible: true,
                      value: intl.formatMessage({ id: 'dashboard_n_jobs' }, { n: dashboardSummary.runningJobsCount }),
                      isValueCritical: false,
                      description: (
                          <Link
                              onClick={() => historyPush('/restoration-input-extract')}
                              variant="tertiary"
                              fontSize="14px"
                          >
                              <FormattedMessage id={restorationInputExtractTitleID} />
                          </Link>
                      ),
                      hasDivider: false,
                  },
              ]
            : [runningTitleID]),
    ];

    return (
        <DashboardLayoutWrapper
            header={
                <Box px={4}>
                    <Flex align="center" wrap="wrap">
                        <Flex flex="1">
                            {noDataFound ? (
                                intl.formatMessage({ id: 'common_no_data_found' })
                            ) : Object.keys(dashboardSummary).length === 0 ? (
                                intl.formatMessage({ id: 'common_loading' })
                            ) : (
                                <DashboardStats stats={stats} />
                            )}
                        </Flex>

                        {tableSpaceDetailModal.isOpen && (
                            <TablespaceDetailModal
                                schemaGroupId={schemaGroupId}
                                onClose={tableSpaceDetailModal.onClose}
                            />
                        )}

                        <Tooltip
                            label={intl.formatMessage({ id: 'no_assigned_workspaces' })}
                            isDisabled={workspacesFound}
                            w={{ base: '100%', lg: 'auto' }}
                        >
                            <Button
                                isLoading={powerBiIsLoading}
                                leftIcon={<PowerBiIcon />}
                                onClick={handlePowerBiBtnClick}
                                variant="secondary"
                                size="sm"
                                w={{ base: '100%', lg: 'auto' }}
                                mr={3}
                                isDisabled={!workspacesFound}
                            >
                                <FormattedMessage id="power_bi_reports" />
                            </Button>
                        </Tooltip>

                        <Button
                            as={Link}
                            leftIcon={<CubeIcon />}
                            size="sm"
                            w={{ base: '100%', lg: 'auto' }}
                            mt={{ base: '16px', md: '0' }}
                            href="https://go.ascendondemand.com/"
                            isExternal={true}
                        >
                            <FormattedMessage id="power_cube_button_text" />
                        </Button>
                    </Flex>
                </Box>
            }
            sidebar={
                <>
                    <Heading as="h3" variant="h3" pb={7}>
                        <FormattedMessage id="dashboard_quick_actions" />
                    </Heading>

                    <VStack spacing={10}>
                        <DashboardSidebarCard
                            title={<FormattedMessage id="dashboard_edit_portfolio" />}
                            description={<FormattedMessage id="dashboard_edit_portfolio_desc" />}
                            buttonText={<FormattedMessage id="dashboard_portfolios" />}
                            onClick={() => historyPush('/portfolios')}
                        />

                        <DashboardSidebarCard
                            title={<FormattedMessage id="dashboard_add_pricing_location" />}
                            description={<FormattedMessage id="dashboard_add_pricing_location_desc" />}
                            buttonText={<FormattedMessage id="dashboard_add_pricing_location_btn" />}
                            onClick={() => historyPush('/basis-configurator')}
                        />

                        <DashboardSidebarCard
                            title={<FormattedMessage id="dashboard_run_a_study_btn" />}
                            description={<FormattedMessage id="dashboard_run_a_study_desc" />}
                            buttonText={<FormattedMessage id="dashboard_run_a_study_btn" />}
                            onClick={() => historyPush('/run-a-study')}
                        />
                    </VStack>
                </>
            }
            content={
                <>
                    <StudyGrid />
                    {powerBiModal.isOpen && (
                        <PowerBiModal
                            groups={groups}
                            onGroupClick={navigateToPowerBiReports}
                            onClose={powerBiModal.onClose}
                            scrollBehavior="inside"
                            isOpen
                        />
                    )}
                </>
            }
            hasFooter={false}
        />
    );
};

export default Dashboard;
