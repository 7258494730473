import { useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Box, Text, Flex, IconButton } from '@chakra-ui/react';

import { appVersion } from '../../services/utils';

import { ReactComponent as QuestionMarkIcon } from '../../icons/question-mark.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';

const StatusBar = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { connectionTitle, environmentTitle, bookTitle } = useSelector((state) => state.connections);
    const hasExistingConfig = connectionTitle && environmentTitle && bookTitle;

    return (
        <AuthenticatedTemplate>
            <Wrapper bg="background-quaternary">
                <Flex alignItems="center" py={1} px={6} minH={10}>
                    <Text minW="200px">{appVersion}</Text>

                    <StyledIconButton
                        variant="ghost"
                        isRound
                        ml="auto"
                        icon={<QuestionMarkIcon />}
                        onClick={() => setIsExpanded((prev) => !prev)}
                        color={isExpanded ? 'blue.400' : 'gray.600'}
                    />
                </Flex>

                {hasExistingConfig && (
                    <Flex
                        gridColumnGap={6}
                        gridRowGap={3}
                        ml={{ base: 0, md: 'auto' }}
                        order={{ base: -1, md: 0 }}
                        direction={{ base: 'column', md: 'row' }}
                        display={{ base: isExpanded ? 'flex' : 'none', md: 'flex' }}
                        pt={{ base: 5, md: 0 }}
                        pr={{ base: 10, md: 6 }}
                        pb={{ base: 3, md: 0 }}
                        pl={6}
                    >
                        <ValueWrapper>
                            <Text as="span" color="gray.500">
                                <FormattedMessage id="common_connection" />
                                {': '}
                            </Text>

                            <Text as="span" variant="dark" wordBreak="break-word">
                                {connectionTitle}
                            </Text>
                        </ValueWrapper>

                        <ValueWrapper>
                            <Text as="span" color="gray.500">
                                <FormattedMessage id="common_environment" />
                                {': '}
                            </Text>

                            <Text as="span" variant="dark" wordBreak="break-word">
                                {environmentTitle}
                            </Text>
                        </ValueWrapper>

                        <ValueWrapper>
                            <Text as="span" color="gray.500">
                                <FormattedMessage id="common_book" />
                                {': '}
                            </Text>

                            <Text as="span" variant="dark" wordBreak="break-word">
                                {bookTitle}
                            </Text>
                        </ValueWrapper>

                        <CloseIconButton
                            variant="ghost"
                            icon={<CloseIcon />}
                            onClick={() => setIsExpanded(false)}
                            position="absolute"
                            top={5}
                            right={6}
                        />
                    </Flex>
                )}
            </Wrapper>
        </AuthenticatedTemplate>
    );
};

const Wrapper = styled(Box)`
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    z-index: var(--chakra-zIndices-docked);

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

const ValueWrapper = styled.div`
    min-width: 100px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
`;

const StyledIconButton = styled(IconButton)`
    min-width: 20px;
    min-height: 20px;
    height: auto;

    @media (min-width: 768px) {
        display: none;
    }
`;

const CloseIconButton = styled(StyledIconButton)`
    padding: 2px;

    svg {
        width: 100%;
        height: 100%;
    }
`;

export default StatusBar;
