import { Flex, Progress, Text } from '@chakra-ui/react';
import { useImperativeHandle, forwardRef } from 'react';

const DataGridProgressBar = forwardRef(({ data, column }, ref) => {
    const progressValue = data[column.getColDef().field];

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return progressValue;
            },
        };
    });

    return (
        <Flex align="center" width="100%">
            <Text width={12}>{progressValue}%</Text>
            <Progress value={progressValue} mx={5} />
        </Flex>
    );
});

export default DataGridProgressBar;
