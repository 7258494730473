import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { Flex, Box, Button, HStack, Heading, Text, useDisclosure } from '@chakra-ui/react';

import {
    getBasisById,
    getBasisList,
    getBasisDropdownOptions,
    editBasis,
    deleteBasis,
    mapCommoditiesByTypeAndDescription,
} from '../services/basis';
import { scrollFromTopWithOffset, basisConfiguratorSectionScrollOffset } from '../services/utils';
import { getIssuesMap } from '../services/grid';

import useCommonToast from '../hooks/useCommonToast';
import useHistoryPush from '../hooks/useHistoryPush';
import useFirstVisibleElement from '../hooks/useFirstVisibleElement';

import ConfiguratorNavigation from '../components/basisConfigurator/ConfiguratorNavigation';
import AddForwardItemModal from '../components/basisConfigurator/AddForwardItemModal';
import AddSpotPriceModal from '../components/basisConfigurator/AddSpotPriceModal';
import CommodityModal from '../components/basisConfigurator/CommodityModal';
import DartSpreadSection from '../components/basisConfigurator/DartSpreadSection';
import PeakPeriodSection from '../components/basisConfigurator/PeakPeriodSection';
import SpotPriceSection from '../components/basisConfigurator/SpotPriceSection';
import PriceFormulaSection from '../components/basisConfigurator/PriceFormulaSection';
import ForwardPriceSection from '../components/basisConfigurator/ForwardPriceSection';
import ModelDefinitionsSection from '../components/basisConfigurator/ModelDefinitionsSection';
import ScalerSection from '../components/basisConfigurator/ScalerSection';
import AddPriceFormulaModal from '../components/basisConfigurator/AddPriceFormulaModal';

import ConfirmationModal from '../components/modal/ConfirmationModal';
import MainModal from '../components/modal/MainModal';

import AddItemButton from '../components/utils/AddItemButton';
import SecondaryButton from '../components/utils/SecondaryButton';
import Error from '../components/utils/Error';
import DefinitionsSkeleton from '../components/utils/DefinitionsSkeleton';

import SortOrderProvider from '../components/grid/utils/SortOrderContext';

import ComboboxField from '../components/forms/ComboboxField';
import InputField from '../components/forms/InputField';
import GeneralFormValidationsAlert from '../components/forms/GeneralFormValidationsAlert';

import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as DeleteLoadIcon } from '../icons/delete.svg';

const BasisConfigurator = () => {
    const location = useLocation();
    const { basisId } = useParams();

    const issuesBoxRef = useRef();

    const editCommodityId = useRef();

    const { toast } = useCommonToast();
    const intl = useIntl();

    const historyPush = useHistoryPush();

    const addForwardCurveAndPriceModal = useDisclosure();
    const addSpotPriceModal = useDisclosure();
    const addCommodityModal = useDisclosure();
    const deleteBasisModal = useDisclosure();
    const validateBasisModal = useDisclosure();
    const addPriceFormulaModal = useDisclosure();
    const deleteBasisWithLinkedObjectsModal = useDisclosure();
    const editCommodityModal = useDisclosure();
    const errorsDisclosure = useDisclosure({ defaultIsOpen: true });
    const warningsDisclosure = useDisclosure({ defaultIsOpen: true });

    const peakPeriodRef = useRef();
    const modelDefinitionRef = useRef();
    const forwardPriceRef = useRef();
    const spotPriceRef = useRef();
    const scalerRef = useRef();
    const dartRef = useRef();
    const priceFormulaRef = useRef();

    const [basis, setBasis] = useState({});
    const [initialBasisName, setInitialBasisName] = useState();
    const [activeLinkId, setActiveLinkId] = useState('');
    const [basisList, setBasisList] = useState([]);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [commodityTypes, setCommodityTypes] = useState([]);
    const [commodityError, setCommodityError] = useState(false);
    const [status, setStatus] = useState({});
    const [messages, setMessages] = useState({ errors: [], warnings: [] });

    const hasCommodity = basis.commodityId != null;

    const configuratorSections = useMemo(
        () => [
            {
                id: 'peak-period',
                ref: peakPeriodRef,
                title: <FormattedMessage id="basis_configurator_peak_period" />,
            },
            {
                id: 'model-definition',
                ref: modelDefinitionRef,
                title: <FormattedMessage id="basis_configurator_model_definition" />,
            },
            {
                id: 'forward-price',
                ref: forwardPriceRef,
                title: <FormattedMessage id="basis_configurator_forward_price" />,
            },
            {
                id: 'spot-price',
                ref: spotPriceRef,
                title: <FormattedMessage id="basis_configurator_spot_price" />,
            },
            {
                id: 'scaler',
                ref: scalerRef,
                title: <FormattedMessage id="basis_configurator_scaler" />,
            },
            {
                id: 'dart',
                ref: dartRef,
                title: <FormattedMessage id="basis_configurator_dart" />,
            },
            {
                id: 'price-formula',
                ref: priceFormulaRef,
                title: <FormattedMessage id="basis_price_formula" />,
            },
        ],
        []
    );

    // scroll to hash if reload has occurred
    useEffect(() => {
        const { hash } = location;
        if (hash) {
            const id = hash.replace('#', '');
            const section = configuratorSections.find((el) => el.id === id).ref;
            if (section && section.current) {
                scrollFromTopWithOffset(section.current, basisConfiguratorSectionScrollOffset);
            }
        }
    }, [configuratorSections, location, isLoading]);

    // set active link in configurator navigation based on first visible section on screen
    const observedElements = useCallback(() => {
        const elements = configuratorSections.map(({ ref }) => ref.current).filter((el) => el);
        return elements;
    }, [configuratorSections]);

    const idByObservedElement = useRef(new Map());

    useEffect(() => {
        const referencedIds = configuratorSections.map(({ id }) => id);

        observedElements().reduce((currentId, observedElement) => {
            const observedId = observedElement.id;
            if (observedId && referencedIds.includes(observedId)) {
                currentId = observedId;
            }
            idByObservedElement.current.set(observedElement, currentId);

            return currentId;
        }, '');
    }, [observedElements, configuratorSections, isLoading]);

    useFirstVisibleElement(observedElements, (element) => {
        const id = element ? idByObservedElement.current.get(element) ?? '' : '';
        if (id !== activeLinkId) {
            setActiveLinkId(id);
        }
    });

    const fetchBasisDropdownOptions = useCallback(() => {
        return getBasisDropdownOptions()
            .then((data) => {
                const commodities = mapCommoditiesByTypeAndDescription(data.commodityTypes);
                setDropdownOptions({ ...data, commodities });
                setCommodityTypes(data.commodityTypes);
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, []);

    const fetchInitialData = useCallback(async () => {
        const fetchBasisList = getBasisList();
        const fetchDropdownOptions = getBasisDropdownOptions();
        const fetchBasis = getBasisById(basisId);

        return Promise.all([fetchBasisList, fetchDropdownOptions, fetchBasis])
            .then((data) => {
                const commodities = mapCommoditiesByTypeAndDescription(data[1].commodityTypes);
                setBasisList(data[0]);
                setDropdownOptions({ ...data[1], commodities });
                setCommodityTypes(data[1].commodityTypes);
                setBasis(data[2]);
                setInitialBasisName(data[2].description);
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [basisId]);

    useEffect(() => {
        fetchInitialData();
    }, [fetchInitialData]);

    const normalizeIssues = (data) => {
        if (data.issues) {
            // make sure to re-open the disclosures again,
            // because the user might have closed them and is submitting the form again
            errorsDisclosure.onOpen();
            warningsDisclosure.onOpen();

            // when creating a single record we expect at most only 1 issue
            const issue = data.issues[0];

            // handle specific field errors
            let fieldStatus = {};

            for (const field of issue.fields) {
                const errors = field.validationMessages
                    .filter(({ severity }) => severity === 'Error')
                    .map(({ message }) => message);

                const warnings = field.validationMessages
                    .filter(({ severity }) => severity === 'Warning')
                    .map(({ message }) => message);

                fieldStatus[field.name] = { errors, warnings };
            }

            const { errors, warnings } = getIssuesMap(data.issues)[0];
            setMessages({ errors, warnings });

            setStatus(fieldStatus);
        } else {
            errorsDisclosure.onClose();
            warningsDisclosure.onClose();

            // we delete all messages in the issues for the row
            setStatus({});
            setMessages({ errors: [], warnings: [] });
        }
    };

    const updateBasis = async (updatedBasis) => {
        const successMessage = intl.formatMessage({ id: 'basis_save_changes_success' });

        try {
            setBasis(updatedBasis);

            const { data = {} } = await editBasis(basis.basisId, { ...updatedBasis, useWarnings: true });
            normalizeIssues(data);

            toast(successMessage);
        } catch (err) {
            if (err.response.status !== 500) {
                if (err.response.data.issues) {
                    normalizeIssues(err.response.data);
                }
            }
        }
    };

    const basisNameValidationError = !basis.description
        ? intl.formatMessage(
              { id: 'common_forms_validation_required' },
              { label: intl.formatMessage({ id: 'basis_configurator_create_basis_name' }) }
          )
        : intl.formatMessage(
              { id: 'common_forms_validation_length' },
              { label: 'Basis Name', lengthRule: '50 characters or less' }
          );

    const onBasisNameUpdate = async () => {
        if (initialBasisName !== basis.description && basis.description && basis.description.length <= 50) {
            try {
                await updateBasis({ ...basis, description: basis.description });
            } finally {
                setInitialBasisName(basis.description);
            }
        }
    };

    const onBasisDelete = async () => {
        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'basis' });

        await deleteBasis(basis.basisId);
        deleteBasisModal.onClose();
        historyPush('/basis');

        toast(successMessage);
    };

    const onCommodityChange = async (commodityId) => {
        const selectedCommodityType = commodityTypes.find((type) => {
            return type.commodities.find((commodity) => commodity.id === commodityId);
        });

        await updateBasis({ ...basis, commodityId, commodityTypeId: selectedCommodityType.id });

        if (commodityError) {
            setCommodityError(false);
        }
    };

    const onSelectNewCommodity = async (commodityId, commodityTypeId) => {
        await updateBasis({ ...basis, commodityId, commodityTypeId: commodityTypeId });

        if (commodityError) {
            setCommodityError(false);
        }
    };

    const onCommodityEdit = () => {
        editCommodityId.current = basis.commodityId;
        editCommodityModal.onOpen();
    };

    const onCommodityEditSuccess = () => {
        editCommodityId.current = undefined;
        fetchBasisDropdownOptions();
    };

    const onCommodityEditCompleted = () => {
        editCommodityId.current = undefined;
        editCommodityModal.onClose();
    };

    const onBasisSetupCompleteSuccess = () => {
        const successMessage = intl.formatMessage({ id: 'basis_configurator_update_basis_completed' });
        toast(successMessage);
        historyPush(`/basis/${basis.basisId}`);
    };

    const onBasisSetupComplete = () => {
        if (!hasCommodity) {
            setCommodityError(true);
            return;
        }
        if (messages.errors.length > 0 || messages.warnings.length > 0) {
            validateBasisModal.onOpen();
        } else {
            onBasisSetupCompleteSuccess();
        }
    };

    const onBasisValidationModalClose = () => {
        validateBasisModal.onClose();

        if (messages.errors.length || messages.warnings.length) {
            // make sure to re-open the disclosures again,
            // because the user might have closed them
            errorsDisclosure.onOpen();
            warningsDisclosure.onOpen();

            scrollFromTopWithOffset(issuesBoxRef.current, basisConfiguratorSectionScrollOffset);
        }
    };

    const onBasisSetupValidationWarningConfirmed = () => {
        validateBasisModal.onClose();
        onBasisSetupCompleteSuccess();
    };

    if (isLoading || loadingError) {
        return (
            <Box pt={20} px={1}>
                {isLoading ? (
                    <DefinitionsSkeleton />
                ) : (
                    <Error primaryId="common_error" secondaryId="common_loading_error" additionalText="basis" />
                )}
            </Box>
        );
    }

    return (
        <MainWrapper>
            <HeaderWrapper>
                <CenteredFlex>
                    <Heading as="h1" variant="h1" mt={12}>
                        <FormattedMessage id="basis_configurator_create_basis_heading" />
                    </Heading>

                    <Text fontSize="sm" my={5} maxW="700px">
                        <FormattedMessage id="hint_text_basis_setup" />
                    </Text>

                    <InputField
                        id="basisName"
                        name="basisName"
                        label={intl.formatMessage({ id: 'basis_configurator_create_basis_name' })}
                        value={basis.description}
                        type="text"
                        onChange={(e) => setBasis((prev) => ({ ...prev, description: e.target.value }))}
                        onBlur={onBasisNameUpdate}
                        maxW="600px"
                        isRequired
                        isInvalid={!basis.description || basis.description.length > 50}
                        error={basisNameValidationError}
                    />
                </CenteredFlex>

                <CenteredFlex py={5}>
                    <Heading as="h4" variant="h4">
                        <FormattedMessage id="basis_configurator_commodity_label" />
                    </Heading>

                    <Text fontSize="sm" mt={2} mb={3} maxW="700px">
                        <FormattedMessage id="hint_text_commodity" />
                    </Text>

                    <Flex
                        align="flex-end"
                        gridGap={2}
                        flexDirection={{ base: 'column', sm: 'row' }}
                        height="max-content"
                        maxW="600px"
                    >
                        <ComboboxField
                            id="commodityId"
                            name="commodityId"
                            options={dropdownOptions.commodities}
                            valueKey="id"
                            labelKey="description"
                            value={basis.commodityId}
                            onChange={(value) => onCommodityChange(value)}
                            label={intl.formatMessage({ id: 'basis_commodity' })}
                            isRequired
                            isInvalid={commodityError}
                            error={
                                commodityError &&
                                intl.formatMessage(
                                    { id: 'basis_configurator_required_field_validation' },
                                    { label: intl.formatMessage({ id: 'basis_commodity' }) }
                                )
                            }
                        />

                        <SecondaryButton
                            size="sm"
                            type="button"
                            variant="secondary"
                            onClick={onCommodityEdit}
                            isDisabled={!hasCommodity}
                            w={{ sm: 'auto' }}
                            mb={commodityError ? 7 : 0}
                        >
                            <FormattedMessage id="basis_configurator_view_edit" />
                        </SecondaryButton>
                    </Flex>

                    <CustomAddItemButton
                        leftIcon={<AddIcon />}
                        onClick={() => addCommodityModal.onOpen()}
                        m="0px !Important"
                        size="sm"
                    >
                        <Text size="sm" color="gray.900" pl={1} pt={1}>
                            <FormattedMessage id="basis_configurator_add_new_commodity" />
                        </Text>
                    </CustomAddItemButton>

                    <Box mt={5} ref={issuesBoxRef}>
                        {messages.errors.length > 0 && (
                            <GeneralFormValidationsAlert
                                isOpen={errorsDisclosure.isOpen}
                                onClose={errorsDisclosure.onClose}
                                messages={messages.errors}
                            />
                        )}

                        {messages.warnings.length > 0 && (
                            <GeneralFormValidationsAlert
                                status="warning"
                                title={<FormattedMessage id="common_warnings" />}
                                isOpen={warningsDisclosure.isOpen}
                                onClose={warningsDisclosure.onClose}
                                messages={messages.warnings}
                            />
                        )}
                    </Box>
                </CenteredFlex>
            </HeaderWrapper>

            <NavigationWrapper display={hasCommodity ? 'flex' : 'none'}>
                <CenteredFlex>
                    <ConfiguratorNavigation
                        configuratorSections={configuratorSections}
                        activeLinkId={activeLinkId}
                        basisId={basisId}
                    />
                </CenteredFlex>
            </NavigationWrapper>

            <SortOrderProvider>
                <ContentWrapper display={hasCommodity ? 'flex' : 'none'}>
                    <CenteredFlex>
                        <SectionWrapper>
                            <Heading
                                variant="h2"
                                textTransform="capitalize"
                                id="peak-period"
                                ref={peakPeriodRef}
                                my={6}
                            >
                                <FormattedMessage id="basis_configurator_peak_period" />
                            </Heading>

                            <PeakPeriodSection
                                basis={basis}
                                options={dropdownOptions}
                                updateBasis={updateBasis}
                                status={status}
                            />
                        </SectionWrapper>

                        <SectionWrapper>
                            <Heading
                                variant="h2"
                                textTransform="capitalize"
                                id="model-definition"
                                ref={modelDefinitionRef}
                                my={6}
                            >
                                <FormattedMessage id="basis_configurator_model_definition" />
                            </Heading>

                            <ModelDefinitionsSection
                                basis={basis}
                                options={dropdownOptions}
                                updateBasis={updateBasis}
                                status={status}
                                onFinish={fetchBasisDropdownOptions}
                            />
                        </SectionWrapper>

                        <SectionWrapper>
                            <Flex justify="space-between" align="center">
                                <Heading
                                    variant="h2"
                                    textTransform="capitalize"
                                    id="forward-price"
                                    ref={forwardPriceRef}
                                    my={6}
                                >
                                    <FormattedMessage id="basis_configurator_forward_price" />
                                </Heading>

                                <Box>
                                    <AddItemButton
                                        leftIcon={<AddIcon />}
                                        onClick={() => addForwardCurveAndPriceModal.onOpen()}
                                    >
                                        <Text size="sm" color="gray.900" pl={1} pt={1}>
                                            <FormattedMessage id="basis_configurator_add_new_forward_curve_and_price_item" />
                                        </Text>
                                    </AddItemButton>
                                </Box>
                            </Flex>

                            <ForwardPriceSection
                                basis={basis}
                                options={dropdownOptions}
                                updateBasis={updateBasis}
                                status={status}
                            />
                        </SectionWrapper>

                        <SectionWrapper>
                            <Flex justify="space-between" align="center">
                                <Heading
                                    variant="h2"
                                    textTransform="capitalize"
                                    id="spot-price"
                                    ref={spotPriceRef}
                                    my={6}
                                >
                                    <FormattedMessage id="basis_configurator_spot_price" />
                                </Heading>

                                <Box>
                                    <AddItemButton leftIcon={<AddIcon />} onClick={() => addSpotPriceModal.onOpen()}>
                                        <Text size="sm" color="gray.900" pl={1} pt={1}>
                                            <FormattedMessage id="basis_configurator_add_new_spot_price" />
                                        </Text>
                                    </AddItemButton>
                                </Box>
                            </Flex>

                            <SpotPriceSection
                                basis={basis}
                                options={dropdownOptions}
                                updateBasis={updateBasis}
                                status={status}
                            />
                        </SectionWrapper>

                        <SectionWrapper>
                            <Heading variant="h2" textTransform="capitalize" id="scaler" ref={scalerRef} my={6}>
                                <FormattedMessage id="basis_configurator_scaler" />
                            </Heading>

                            <ScalerSection
                                basis={basis}
                                options={dropdownOptions}
                                updateBasis={updateBasis}
                                item="basis"
                            />
                        </SectionWrapper>

                        <SectionWrapper>
                            <Heading variant="h2" textTransform="capitalize" id="dart" ref={dartRef} my={6}>
                                <FormattedMessage id="basis_configurator_dart" />
                            </Heading>

                            <DartSpreadSection
                                basis={basis}
                                options={dropdownOptions}
                                updateBasis={updateBasis}
                                basisList={basisList}
                            />
                        </SectionWrapper>

                        <SectionWrapper borderBottom="unset !important">
                            <Flex justify="space-between" align="center">
                                <Heading
                                    variant="h2"
                                    textTransform="capitalize"
                                    id="price-formula"
                                    ref={priceFormulaRef}
                                    my={6}
                                >
                                    <FormattedMessage id="basis_price_formula" />
                                </Heading>

                                <Box>
                                    <AddItemButton leftIcon={<AddIcon />} onClick={addPriceFormulaModal.onOpen}>
                                        <Text size="sm" color="gray.900" pl={1} pt={1}>
                                            <FormattedMessage id="basis_configurator_add_new_price_formula" />
                                        </Text>
                                    </AddItemButton>
                                </Box>
                            </Flex>

                            <PriceFormulaSection basis={basis} options={dropdownOptions} updateBasis={updateBasis} />
                        </SectionWrapper>
                    </CenteredFlex>
                </ContentWrapper>
            </SortOrderProvider>

            <ActionButtons>
                <HStack spacing={4}>
                    <Button
                        leftIcon={<DeleteLoadIcon />}
                        variant="special"
                        textTransform="capitalize"
                        onClick={
                            basis.linkedObjectsCount
                                ? deleteBasisWithLinkedObjectsModal.onOpen
                                : deleteBasisModal.onOpen
                        }
                    >
                        <FormattedMessage id="basis_delete_btn" />
                    </Button>

                    <Button
                        isDisabled={basis.description.length === 0 || basis.description.length > 50 || commodityError}
                        textTransform="capitalize"
                        onClick={onBasisSetupComplete}
                    >
                        <FormattedMessage id="basis_configurator_update_basis" />
                    </Button>
                </HStack>
            </ActionButtons>

            {addForwardCurveAndPriceModal.isOpen && (
                <AddForwardItemModal isOpen onClose={addForwardCurveAndPriceModal.onClose} basisId={basisId} />
            )}

            {addSpotPriceModal.isOpen && (
                <AddSpotPriceModal isOpen onClose={addSpotPriceModal.onClose} basisId={basisId} />
            )}

            {addCommodityModal.isOpen && (
                <CommodityModal
                    isOpen
                    onClose={addCommodityModal.onClose}
                    basisId={basisId}
                    onFinish={fetchBasisDropdownOptions}
                    onSelectNewCommodity={onSelectNewCommodity}
                />
            )}

            {editCommodityModal.isOpen && (
                <CommodityModal
                    isOpen
                    basisId={basis.basisId}
                    commodityId={editCommodityId.current}
                    onClose={onCommodityEditCompleted}
                    onFinish={onCommodityEditSuccess}
                />
            )}

            {deleteBasisModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={deleteBasisModal.onClose}
                    header={<FormattedMessage id="common_delete_modal_heading" values={{ item: 'Basis' }} />}
                    hasExtraStep
                    content={
                        <FormattedMessage
                            id="common_delete_modal_msg"
                            values={{
                                item: basis.description,
                            }}
                        />
                    }
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_delete" />
                        </Box>
                    }
                    onConfirm={onBasisDelete}
                />
            )}

            {validateBasisModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={onBasisValidationModalClose}
                    header={<FormattedMessage id="basis_configurator_update_basis_missing_information" />}
                    hasExtraStep
                    content={<FormattedMessage id="basis_configurator_update_basis_missing_information_text" />}
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_continue" />
                        </Box>
                    }
                    cancelText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_back" />
                        </Box>
                    }
                    onConfirm={onBasisSetupValidationWarningConfirmed}
                />
            )}

            {addPriceFormulaModal.isOpen && (
                <AddPriceFormulaModal isOpen onClose={addPriceFormulaModal.onClose} basisId={basis.basisId} />
            )}

            {deleteBasisWithLinkedObjectsModal.isOpen && (
                <MainModal
                    scrollBehavior="inside"
                    isOpen
                    onClose={deleteBasisWithLinkedObjectsModal.onClose}
                    header={<FormattedMessage id="common_delete_modal_heading" values={{ item: 'Basis' }} />}
                    content={
                        <FormattedMessage
                            id="basis_delete_modal_linked_objects_msg"
                            values={{
                                count: basis.linkedObjectsCount,
                            }}
                        />
                    }
                    footerRightSlot={
                        <Button ml={3} isDisabled>
                            <Box as="span" textTransform="capitalize">
                                <FormattedMessage id="common_delete" />
                            </Box>
                        </Button>
                    }
                />
            )}
        </MainWrapper>
    );
};

const MainWrapper = styled(Box)`
    width: 100%;
    position: relative;
    top: 73px;
    padding-bottom: 100px;
`;

const HeaderWrapper = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;

    // higher than the sticky nav to allow the commodityId combobox dropdown list to open above the sticky nav
    // the base z-index value of "docked" is used which is what the main nav and the status bar are using
    z-index: calc(var(--chakra-zIndices-docked) - 1);
`;

const NavigationWrapper = styled(Box)`
    justify-content: center;
    position: sticky;
    top: 73px;
    z-index: calc(var(--chakra-zIndices-docked) - 2);
    background: var(--chakra-colors-background-tertiary);
    border-top: 1px solid var(--chakra-colors-border-secondary);
    border-bottom: 1px solid var(--chakra-colors-border-secondary);

    @media screen and (max-width: 899px) {
        top: 64px;
    }
`;

const ContentWrapper = styled(Flex)`
    justify-content: center;
    padding: 10px 0 10px 0;
    background: var(--chakra-colors-background-secondary);
`;

const CenteredFlex = styled(Flex)`
    margin: 0 20px;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
`;

const ActionButtons = styled(Flex)`
    position: fixed;
    bottom: 40px;
    width: 100%;
    height: 80px;
    padding: 0 16px;
    align-items: center;
    justify-content: flex-end;
    background: var(--chakra-colors-background-tertiary);
    border-top: 1px solid var(--chakra-colors-border-secondary);

    // equal to the HeaderWrapper z-index, to make sure the commodityId combobox dropdown list doesn't overlap the action buttons
    z-index: calc(var(--chakra-zIndices-docked) - 1);
`;

const CustomAddItemButton = styled(AddItemButton)`
    margin: 0;
    padding: 0;
    width: max-content;
`;

const SectionWrapper = styled(Box)`
    width: 100%;
    min-height: 100px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--chakra-colors-border-secondary);
`;

export default BasisConfigurator;
