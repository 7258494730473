const Tabs = {
    parts: ['root', 'tab', 'tablist', 'tabpanel', 'tabpanels', 'indicator'],

    defaultProps: {
        variant: 'default',
    },

    baseStyle: {
        tab: {
            textTransform: 'capitalize',

            _dark: {
                color: 'gray.300',

                _selected: {
                    color: 'white',
                },

                _hover: {
                    color: 'white',
                },
            },

            _focus: {
                borderRadius: 'var(--chakra-radii-base)',
            },

            _disabled: {
                color: 'gray.400',
                cursor: 'not-allowed',
                _hover: {
                    color: 'gray.400',
                },
            },
        },

        tablist: {
            color: 'black',

            _dark: {
                color: 'gray.300',
                borderColor: 'gray.600',
            },
        },

        tabpanel: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    variants: {
        default: {
            tab: {
                color: 'black',
                borderBottom: '2px solid',
                borderColor: 'transparent',
                padding: 'var(--chakra-space-4) var(--chakra-space-2)',
                marginRight: 'var(--chakra-space-4)',
                marginBottom: '0',

                _hover: {
                    color: 'blue.400',
                },

                _selected: {
                    borderBottom: '4px solid',
                    borderColor: 'blue.400',
                    color: 'blue.600',
                },
                _focus: {
                    boxShadow: 'inset var(--chakra-shadows-outline)',
                },
            },

            tablist: {
                borderBottom: '1px solid',
                borderColor: 'gray.200',
            },
        },

        badges: {
            tab: {
                height: '36px',
                padding: '0 16px !important',
                whiteSpace: 'nowrap',
                borderBottom: 'none',
                borderRadius: 'full',
                fontWeight: 500,
                color: 'black',
                bg: 'gray.100',
                marginRight: '24px',
                '&:last-child': {
                    marginRight: 0,
                },

                _hover: {
                    color: 'blue.400',
                },

                _selected: {
                    color: 'white',
                    bg: 'blue.400',
                    borderBottom: 'none',
                    _hover: {
                        color: 'white',
                    },
                },

                _focus: {
                    borderRadius: 'var(--chakra-radii-full) !important',
                },

                _dark: {
                    color: 'white',
                    bg: 'gray.800',
                    _selected: {
                        color: 'white',
                        bg: 'blue.400',
                        borderBottom: 'none',
                        _hover: {
                            color: 'white',
                        },
                    },
                },
            },

            tablist: {
                margin: 0,
                padding: '10px 24px',
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: 'gray.200',
                backgroundColor: 'gray.50',

                _dark: {
                    bg: 'black',
                },
            },
        },

        icon: {
            tablist: {
                borderBottom: 'none',
                display: 'inline-flex',
                bg: 'gray.100',
                borderRadius: '4px',
                padding: '4px',
                gap: '4px',
            },

            tab: {
                color: 'gray.600',
                borderRadius: '3px',
                width: '32px',
                height: '32px',
                padding: 0,
                _selected: {
                    color: 'blue.400',
                    bg: 'white',
                },
                _hover: {
                    color: 'blue.400',
                },
                _dark: {
                    color: 'gray.600',
                    _selected: {
                        color: 'blue.400',
                        bg: 'white',
                    },
                    _hover: {
                        color: 'blue.400',
                    },
                },
            },
        },
    },
};

export default Tabs;
