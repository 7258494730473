import React from 'react';
import styled from '@emotion/styled/macro';
import { useIntl } from 'react-intl';
import { Flex } from '@chakra-ui/react';
import splash0 from 'images/splash/0.png';
import splash1 from 'images/splash/1.png';
import splash2 from 'images/splash/2.png';
import splash3 from 'images/splash/3.png';
import splash4 from 'images/splash/4.jpg';

import Logo from '../utils/Logo';

import allWhiteLogoURL from '../../icons/logos/logo-all-white.svg';

const images = [splash0, splash1, splash2, splash3, splash4];
const currDate = new Date();

const SplashScreen = () => {
    const intl = useIntl();
    const todaysSplashIndex = currDate.getDate() % images.length;

    return (
        <Flex h="calc(100vh - 72px)">
            <CoverImageWrapper>
                <CoverImage
                    src={isNaN(todaysSplashIndex) ? splash4 : images[todaysSplashIndex]}
                    alt={intl.formatMessage({ id: 'common_ascend_splash_screen_image_alt' })}
                />
                <Overlay />
            </CoverImageWrapper>

            <SplashLogo src={allWhiteLogoURL} />
        </Flex>
    );
};

const Overlay = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
`;

const CoverImageWrapper = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
`;

const CoverImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const SplashLogo = styled(Logo)`
    margin: auto;
    opacity: 0;
    width: 80%;

    animation: 2.5s ease 0.3s normal forwards 1 fadein;

    @keyframes fadein {
        0% {
            opacity: 0;
        }
        44% {
            opacity: 1;
        }
        88% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    @media screen and (min-width: 48em) {
        width: unset;
    }

    @media screen and (min-width: 80em) {
        margin-top: 15%;
    }
`;

export default SplashScreen;
