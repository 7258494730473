const Accordion = {
    parts: ['root', 'container', 'button', 'panel'],

    defaultProps: {
        variant: 'primary',
    },

    baseStyle: {
        container: {
            border: 0,
        },
    },

    variants: {
        primary: {
            button: {
                width: '40px',
                height: '40px',
                minHeight: '40px',
                px: '4px',
                py: 0,
                mr: '12px',
                borderRadius: 'base',
                color: 'gray.600',

                _hover: {
                    background: 'none',
                    color: 'blue.400',
                },
            },
        },

        sidebar: {
            // styles needed for the virtualization scrolling to work on desktop
            root: {
                flexGrow: '1',
                flexShrink: '1',
                minHeight: '0',
            },

            container: {
                height: '100%',
            },

            button: {
                background: 'gray.100',
                boxShadow: 'none',
                color: 'black',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '1.5',
                px: '24px',
                py: '16px',
                display: {
                    base: 'flex',
                    md: 'none',
                },

                _hover: {
                    background: 'gray.100',
                },
            },

            panel: {
                // styles needed for the virtualization scrolling to work on desktop
                display: 'flex',
                flexDirection: 'column',
                height: '100%',

                background: {
                    base: 'gray.100',
                    md: 'transparent',
                },
                boxShadow: 'none',
                py: 0,
                px: {
                    base: '12px',
                    md: 0,
                },
            },
        },

        'mobile-navigation': {
            panel: {
                p: 0,
            },

            button: {
                justifyContent: 'flex-start',
                padding: 0,
                width: 'auto',
                minHeight: 0,
                borderRadius: 'base',
            },
        },

        'section-hint': {
            root: {
                my: '24px',
                background: 'white',
            },

            button: {
                color: 'gray.600',

                _hover: {
                    background: 'transparent',
                    color: 'blue.400',
                },
            },
        },
    },
};

export default Accordion;
