import { useRef, useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Flex,
    SimpleGrid,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { getDynamicProperties, getModuleByDesc } from '../../services/restorationInputExtract';

import MainModal from '../modal/MainModal';

import InputField from '../forms/InputField';
import ComboboxField from '../forms/ComboboxField';
import DateField from '../forms/DateField';

import SimpleGridSkeleton from '../utils/SimpleGridSkeleton';
import Error from '../utils/Error';

import ExpressJobsProperties from '../expressJobs/ExpressJobsProperties';

import DataGridWrapper from '../grid/DataGridWrapper';
import DataGrid from '../grid/DataGrid';
import SortOrderProvider from '../grid/utils/SortOrderContext';

import {
    INPUT_DATA_EXTRACTION_MODULE_NAME,
    STUDY_RESTORATION_MODULE_NAME,
} from '../../constants/restorationInputExtract';

const InfoButtonModal = ({ selectedJobToImport: selectedJob, onClose, dropdownOptions }) => {
    const [moduleStudyRestorationId, setModuleStudyRestorationId] = useState(null);
    const [moduleInputDataExtractionId, setModuleInputDataExtractionId] = useState(null);

    const formPropertiesRef = useRef();
    const gridApi = useRef([]);

    const [outboundProperties, setOutboundProperties] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [allTables, setTables] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const fetchModuleIds = useCallback(() => {
        getModuleByDesc(INPUT_DATA_EXTRACTION_MODULE_NAME).then((response) => {
            setModuleInputDataExtractionId(response.id);
        });

        getModuleByDesc(STUDY_RESTORATION_MODULE_NAME).then((response) => {
            setModuleStudyRestorationId(response.id);
        });
    }, []);

    useEffect(() => {
        fetchModuleIds();
    }, [fetchModuleIds]);

    const fetchDynamicProperties = useCallback(async (moduleId, jobId) => {
        try {
            const data = await getDynamicProperties(moduleId, jobId);

            setOutboundProperties((prev) => ({
                ...prev,
                data: data.outboundProperties.map((item) => {
                    return {
                        ...item,
                        properties: item.properties.map((property) => ({ ...property, readOnly: true })),
                    };
                }),
                loading: false,
            }));

            setTables((prev) => ({
                ...prev,
                data: {
                    tables: data.tables,
                    names: data.tableNames,
                },
                loading: false,
            }));
        } catch {
            setOutboundProperties((prev) => ({ ...prev, error: true, loading: false }));
            setTables((prev) => ({ ...prev, error: true, loading: false }));
        }
    }, []);

    useEffect(() => {
        fetchDynamicProperties(selectedJob.moduleId, selectedJob.jobId);
    }, [fetchDynamicProperties, selectedJob.moduleId, selectedJob.jobId]);

    const updateAllTables = async () => {
        setTables((prev) => ({ ...prev, loading: true }));

        try {
            const allProperties = await getDynamicProperties(selectedJob.moduleId, selectedJob.jobId);
            setTables((prev) => ({
                ...prev,
                data: {
                    tables: allProperties.tables,
                    names: allProperties.tableNames,
                },
            }));

            for (let index in gridApi.current) {
                gridApi.current[index].setRowData(allProperties.tables[index]);
                if (allProperties.tables[index].length === 0) {
                    gridApi.current[index].showNoRowsOverlay();
                }
            }
        } catch {
            setTables((prev) => ({ ...prev, error: true }));
        } finally {
            setTables((prev) => ({ ...prev, loading: false }));
        }
    };

    const onGridReady = useCallback(
        (params, index) => {
            gridApi.current[index] = params.api;

            gridApi.current[index].setRowData(allTables.data.tables[index]);
            if (allTables.data.tables[index].length === 0) {
                gridApi.current[index].showNoRowsOverlay();
            }
        },
        [allTables]
    );

    return (
        <MainModal
            isOpen
            onClose={onClose}
            header={
                selectedJob.moduleId === moduleStudyRestorationId ? (
                    <FormattedMessage
                        id="restoration_input_extract_info_modal_header_restoration"
                        values={{ name: selectedJob.studyName }}
                    />
                ) : selectedJob.moduleId === moduleInputDataExtractionId ? (
                    <FormattedMessage
                        id="restoration_input_extract_info_modal_header_extraction"
                        values={{ name: selectedJob.studyName || selectedJob.portfolioName }}
                    />
                ) : (
                    ''
                )
            }
            content={
                <>
                    {dropdownOptions.loading ? (
                        <SimpleGridSkeleton rows={3} cols={1} />
                    ) : dropdownOptions.error ? (
                        <Error primaryId="common_error" secondaryId="common_loading_error" additionalText="data" />
                    ) : (
                        <Tabs>
                            <TabList>
                                <Tab>
                                    <FormattedMessage id="restoration_input_extract_info_modal_tab_input" />
                                </Tab>
                                <Tab>
                                    <FormattedMessage id="restoration_input_extract_info_modal_tab_advanced_settings" />
                                </Tab>
                                <Tab>
                                    <FormattedMessage id="restoration_input_extract_info_modal_tab_output" />
                                </Tab>
                                {allTables.data?.tables?.length > 0 && (
                                    <Tab>
                                        <FormattedMessage id="restoration_input_extract_info_modal_tab_output_tables" />
                                    </Tab>
                                )}
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <InputField
                                        id="actions"
                                        name="actions"
                                        label={
                                            <FormattedMessage id="restoration_input_extract_info_modal_tab_input_action" />
                                        }
                                        value={selectedJob.action}
                                        isDisabled
                                    />

                                    <Divider mt={8} />

                                    {(selectedJob.moduleId === moduleStudyRestorationId || selectedJob.studyId) && (
                                        <>
                                            <Text mt={6} mb={2}>
                                                <FormattedMessage id="restoration_input_extract_info_modal_tab_input_study" />
                                            </Text>

                                            <Flex columns={{ xl: 2 }} gap={4}>
                                                <InputField
                                                    id="studyId"
                                                    name="studyId"
                                                    label={
                                                        <FormattedMessage id="restoration_input_extract_info_modal_tab_input_study_id" />
                                                    }
                                                    value={selectedJob.studyId || ''}
                                                    isDisabled
                                                    width="120px"
                                                />

                                                <InputField
                                                    id="studyName"
                                                    name="studyName"
                                                    label={
                                                        <FormattedMessage id="restoration_input_extract_info_modal_tab_input_study_name" />
                                                    }
                                                    value={selectedJob.studyName || ''}
                                                    isDisabled
                                                />
                                            </Flex>
                                        </>
                                    )}

                                    {selectedJob.moduleId === moduleInputDataExtractionId && (
                                        <>
                                            <Text mt={4} mb={2}>
                                                <FormattedMessage id="restoration_input_extract_info_modal_tab_input_portfolio" />
                                            </Text>

                                            <Flex columns={{ xl: 2 }} gap={4}>
                                                <InputField
                                                    id="portfolioId"
                                                    name="portfolioId"
                                                    label={
                                                        <FormattedMessage id="restoration_input_extract_info_modal_tab_input_portfolio_id" />
                                                    }
                                                    value={selectedJob.portfolioId || ''}
                                                    isDisabled
                                                    width="120px"
                                                />

                                                <InputField
                                                    id="portfolioName"
                                                    name="portfolioName"
                                                    label={
                                                        <FormattedMessage id="restoration_input_extract_info_modal_tab_input_portfolio_name" />
                                                    }
                                                    value={selectedJob.portfolioName || ''}
                                                    isDisabled
                                                />
                                            </Flex>
                                        </>
                                    )}
                                </TabPanel>

                                <TabPanel>
                                    <SimpleGrid columns={2} spacingX={3} spacingY={3}>
                                        <ComboboxField
                                            id="submitPriority"
                                            name="submitPriority"
                                            label={
                                                <FormattedMessage id="restoration_input_extract_info_modal_tab_advanced_settings_submit_priority" />
                                            }
                                            options={dropdownOptions.data.priorities}
                                            value={selectedJob.statusId}
                                            valueKey="id"
                                            labelKey="description"
                                            isDisabled
                                        />

                                        <DateField
                                            id="scheduledRunDate"
                                            name="scheduledRunDate"
                                            label={
                                                <FormattedMessage id="restoration_input_extract_info_modal_tab_advanced_settings_scheduledRunDate" />
                                            }
                                            value={selectedJob.scheduledRunTime}
                                            showTimeInput
                                            utc={false}
                                            isDisabled
                                        />

                                        <ComboboxField
                                            id="jobType"
                                            name="jobType"
                                            label={
                                                <FormattedMessage id="restoration_input_extract_info_modal_tab_advanced_settings_job_type" />
                                            }
                                            options={dropdownOptions.data.jobTypes}
                                            value={selectedJob.jobTypeId}
                                            valueKey="id"
                                            labelKey="description"
                                            isDisabled
                                        />

                                        <ComboboxField
                                            id="associatedStudyPortfolioSensitivity"
                                            name="associatedStudyPortfolioSensitivity"
                                            label={
                                                <FormattedMessage id="restoration_input_extract_info_modal_tab_advanced_settings_associated_study_portfolio_sensitivity" />
                                            }
                                            options={dropdownOptions.data.studies}
                                            value={selectedJob.portfolioJobManagementId}
                                            valueKey="id"
                                            labelKey="description"
                                            isDisabled
                                        />
                                    </SimpleGrid>
                                </TabPanel>

                                <TabPanel>
                                    {selectedJob.moduleId === moduleInputDataExtractionId && (
                                        <ExpressJobsProperties
                                            properties={outboundProperties}
                                            formPropertiesRef={formPropertiesRef}
                                        />
                                    )}
                                </TabPanel>

                                <TabPanel>
                                    {allTables.loading ? (
                                        <SimpleGridSkeleton rows={3} cols={1} />
                                    ) : allTables.error ? (
                                        <Error
                                            primaryId="common_error"
                                            secondaryId="common_loading_error"
                                            additionalText="data"
                                        />
                                    ) : (
                                        <Box>
                                            {allTables.data.tables.length > 0 && (
                                                <Tabs>
                                                    <TabList>
                                                        {allTables.data.tables.map((table, index) => (
                                                            <Tab key={index}>
                                                                {allTables.data.names[index] || (
                                                                    <FormattedMessage
                                                                        id="restoration_input_extract_info_modal_tab_tables_default_table_header"
                                                                        values={{ index: index + 1 }}
                                                                    />
                                                                )}
                                                            </Tab>
                                                        ))}
                                                        <Flex ml="auto" w="auto">
                                                            <Button variant="secondary">
                                                                <Box
                                                                    as="span"
                                                                    textTransform="capitalize"
                                                                    onClick={updateAllTables}
                                                                >
                                                                    <FormattedMessage id="common_refresh" />
                                                                </Box>
                                                            </Button>
                                                        </Flex>
                                                    </TabList>
                                                    <TabPanels>
                                                        {allTables.data.tables.map((table, index) => (
                                                            <TabPanel key={index}>
                                                                <SortOrderProvider>
                                                                    <DataGridWrapper>
                                                                        <DataGrid
                                                                            onGridReady={(api) =>
                                                                                onGridReady(api, index)
                                                                            }
                                                                            pageSize={100}
                                                                            rowSelection="multiple"
                                                                            disableReadOnlyStyles={true}
                                                                            columns={Object.keys(table[0]).map(
                                                                                (key) => {
                                                                                    return {
                                                                                        field: key,
                                                                                        headerName: key,
                                                                                        editable: false,
                                                                                    };
                                                                                }
                                                                            )}
                                                                        />
                                                                    </DataGridWrapper>
                                                                </SortOrderProvider>
                                                            </TabPanel>
                                                        ))}
                                                    </TabPanels>
                                                </Tabs>
                                            )}
                                        </Box>
                                    )}
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    )}
                </>
            }
        />
    );
};

export default InfoButtonModal;
