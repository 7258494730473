import {
    InputGroup,
    NumberInput as ChakraNumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';

const NumberInput = ({
    placeholder,
    hasPercentage,
    hasStepperButtons = true,
    rightElement,
    leftElement,
    isWarning,
    isRequired,
    ...props
}) => {
    const intl = useIntl();
    const defaultPlaceholder = isRequired ? intl.formatMessage({ id: 'common_select_number' }) : '-';

    return (
        <InputGroup>
            {leftElement}
            <ChakraNumberInput w="100%" {...props}>
                <NumberInputField
                    placeholder={placeholder ? placeholder : defaultPlaceholder}
                    borderColor={isWarning ? 'orange.300' : undefined}
                    _hover={isWarning ? { borderColor: 'orange.300' } : undefined}
                    // when precision is used, built in browser error is triggered for negative values
                    // https://github.com/chakra-ui/chakra-ui/issues/2976
                    pattern="(-)?[0-9]*(.[0-9]+)?"
                />

                {hasStepperButtons && (
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                )}
            </ChakraNumberInput>
            {rightElement}
        </InputGroup>
    );
};

export default NumberInput;
