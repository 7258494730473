import { useCallback, useMemo } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Autoform from './Autoform';
import TabularPage from '../components/tabs/TabularPage';
import { APP_NAME } from '../constants/autoforms';

const variablesSchemaId = 'PSECONRES';

const EconomicReserve = () => {
    const intl = useIntl();

    const routes = useMemo(
        () => [
            {
                name: <FormattedMessage id="economic_reserve_data" />,
                relativePath: 'PSECONRESERVE',
            },
            {
                name: <FormattedMessage id="economic_reserve_variables" />,
                relativePath: variablesSchemaId,
            },
        ],
        []
    );

    const getInstructions = useCallback(
        (selectedTab) =>
            routes[selectedTab].relativePath === variablesSchemaId ? (
                <FormattedMessage id="common_add_rows_sub_heading_without_date" />
            ) : undefined,
        [routes]
    );

    return (
        <TabularPage
            routes={routes}
            renderRoutes={({ path, selectedTab }) => (
                <Route path={`${path}/:autoformId`}>
                    <Autoform
                        hasSecondaryMenu={true}
                        config={{
                            //Seems like "descriptionName" is not used at all. Could be removed by Ascend after the release
                            descriptionName: <FormattedMessage id="economic_reserve_variables" />,
                            addDataModalInstructions: getInstructions(selectedTab),
                            pageTitle: `${intl.formatMessage({ id: 'economic_reserve' })} - ${APP_NAME}`,
                        }}
                    />
                </Route>
            )}
        />
    );
};

export default EconomicReserve;
