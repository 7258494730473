import axios from 'axios';

export const whyJobIsNotRunningInfo = async (jobId) => {
    const response = await axios.get(`/zapper/job-not-running/${jobId}`);
    return response.data;
    //return testDataWithMachinePositions;
};

// eslint-disable-next-line
const testDataWithMachinePositions = {
    isRuntimeInFuture: true,
    isJobExpired: true,
    isReportingBlocking: true,
    ancestors: [
        {
            jobId: 205280,
            statusId: -5,
            status: 'Deleted',
            studyId: 6047,
        },
        {
            jobId: 205282,
            statusId: -3,
            status: 'Stopped',
            studyId: 6047,
        },
        {
            jobId: 205284,
            statusId: -3,
            status: 'Stopped',
            studyId: 6047,
        },
        {
            jobId: 11,
            statusId: -5,
            status: 'Deleted',
            studyId: null,
        },
        {
            jobId: 22,
            statusId: -3,
            status: 'Stopped',
            studyId: 222,
        },
        {
            jobId: 33,
            statusId: 100,
            status: 'Queued In Batch Compute',
            studyId: 333,
        },
        {
            jobId: 44,
            statusId: 100,
            status: 'Queued In Batch Compute',
            studyId: null,
        },
    ],
    costOverview: {
        jobCost: 10,
        machineSummary: [
            {
                machineName: 'BATCHVM001',
                currentCost: 0,
                maximumCost: 1000,
            },
            {
                machineName: 'PSV-IMAGE-VM-03',
                currentCost: 0,
                maximumCost: 1000,
            },
            {
                machineName: 'BATCHVM001OLGA1',
                currentCost: 5,
                maximumCost: 1000,
            },
            {
                machineName: 'PSV-IMAGE-VM-03OLGA1',
                currentCost: 500,
                maximumCost: 1000,
            },
            {
                machineName: 'BATCHVM001OLGA2',
                currentCost: 5,
                maximumCost: 1000,
            },
            {
                machineName: 'PSV-IMAGE-VM-03OLGA2',
                currentCost: 500,
                maximumCost: 1000,
            },
            {
                machineName: 'BATCHVM001OLGA3',
                currentCost: 5,
                maximumCost: 1000,
            },
            {
                machineName: 'PSV-IMAGE-VM-03OLGA3',
                currentCost: 500,
                maximumCost: 1000,
            },
            {
                machineName: 'BATCHVM001OLGA4',
                currentCost: 5,
                maximumCost: 5,
            },
            {
                machineName: 'PSV-IMAGE-VM-03OLGA4',
                currentCost: 500,
                maximumCost: 500,
            },
            {
                machineName: 'BATCHVM001OLGA5',
                currentCost: 5,
                maximumCost: 4,
            },
            {
                machineName: 'PSV-IMAGE-VM-03OLGA5',
                currentCost: 500,
                maximumCost: 400,
            },
        ],
    },
    queueInfo: {
        lowestPosition: -1,
        machinePositions: [
            {
                machineName: 'BATCHVM001',
                jobPosition: 0,
            },
            {
                machineName: 'PSV-IMAGE-VM-03',
                jobPosition: 0,
            },
            {
                machineName: 'BATCHVM001OLGA1',
                jobPosition: 10,
            },
            {
                machineName: 'PSV-IMAGE-VM-03OLGA1',
                jobPosition: 20,
            },
            {
                machineName: 'BATCHVM001OLGA2',
                jobPosition: 10,
            },
            {
                machineName: 'PSV-IMAGE-VM-03OLGA2',
                jobPosition: 20,
            },
            {
                machineName: 'BATCHVM001OLGA3',
                jobPosition: 10,
            },
            {
                machineName: 'PSV-IMAGE-VM-03OLGA3',
                jobPosition: 20,
            },
            {
                machineName: 'BATCHVM001OLGA4',
                jobPosition: 10,
            },
            {
                machineName: 'PSV-IMAGE-VM-03OLGA4',
                jobPosition: 20,
            },
            {
                machineName: 'BATCHVM001OLGA5',
                jobPosition: 10,
            },
            {
                machineName: 'PSV-IMAGE-VM-03OLGA5',
                jobPosition: 20,
            },
        ],
    },
};
