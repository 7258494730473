import { useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';
import {
    Text,
    Flex,
    useBoolean,
    Popover as ChakraPopover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
} from '@chakra-ui/react';

import CustomIconButton from '../utils/CustomIconButton';

import { ReactComponent as WarningIcon } from '../../icons/warning.svg';

const DataGridIssuesDialog = (props) => {
    const ref = useRef();
    const [isOpen, setIsOpen] = useBoolean();

    const generateIssues = (issues, type) =>
        issues.map((issue, idx) => (
            <Flex key={idx} mb={3}>
                <Text as="span" color={type === 'error' ? 'red.1000' : 'yellow.500'} mr={1} size="xs">
                    {type.toUpperCase() + ':'}
                </Text>
                <Text as="span" color="gray.800" size="xs" variant="regular" wordBreak={'break-word'}>
                    {issue}
                </Text>
            </Flex>
        ));

    useEffect(() => {
        ref.current = props.eGridCell.closest('.ag-center-cols-viewport');
    }, [props.eGridCell]);

    return (
        <>
            {props.value && (
                <ChakraPopover
                    strategy="fixed"
                    placement="bottom-end"
                    isOpen={isOpen}
                    onOpen={setIsOpen.on}
                    onClose={setIsOpen.off}
                >
                    <PopoverTrigger>
                        <StyledCustomIconButton icon={<WarningIcon />} isWarning={props.value['error'].length === 0} />
                    </PopoverTrigger>

                    <Portal containerRef={ref}>
                        <PopoverContent>
                            <PopoverBody bg="gray.100" p={3} textAlign="left" shadow="xs" maxH="170px" overflowY="auto">
                                {generateIssues(props.value.error, 'error')}
                                {generateIssues(props.value.warning, 'warning')}
                            </PopoverBody>
                        </PopoverContent>
                    </Portal>
                </ChakraPopover>
            )}
        </>
    );
};

const StyledCustomIconButton = styled(CustomIconButton, { shouldForwardProp: (prop) => prop !== 'isWarning' })`
    margin-top: 0px;
    margin-left: 4px;
    height: 20px;
    width: 20px;
    svg {
        padding: 0.5px;
    }
    svg path {
        stroke: var(--chakra-colors-transparent);
        fill: ${(props) => (props.isWarning ? 'var(--chakra-colors-yellow-500)' : 'var(--chakra-colors-red-1000)')};
    }
`;

export default DataGridIssuesDialog;
