import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled/macro';
import { AgGridReact } from 'ag-grid-react';

import StrategyGridWrapper from './StrategyGridWrapper';

import TableButton from './TableButton';
import DataGridTooltip from '../../grid/DataGridTooltip';

const StrategyGrid = ({ columns, rowData, disabled, shouldDisableButtons, ...rest }) => {
    const intl = useIntl();

    const columnTypes = useMemo(
        () => ({
            monthName: {
                valueGetter: (params) => {
                    if (params.data === undefined) {
                        return intl.formatMessage({ id: 'common_loading' });
                    } else {
                        return params.data.monthName;
                    }
                },
            },

            button: {
                cellRenderer: 'TableButton',
                cellRendererParams: { disabled: shouldDisableButtons },
                valueGetter: (params) => {
                    if (params.data === undefined) {
                        return intl.formatMessage({ id: 'common_loading' });
                    } else {
                        const colId = Number(params.colDef.field);

                        return params.data.genParticipationIds[colId];
                    }
                },
            },
        }),
        [intl, shouldDisableButtons]
    );

    const defaultColDef = useMemo(
        () => ({
            minWidth: 46,
            flex: 1,
            lockPosition: true,
            editable: false,
            suppressMenu: true,
            tooltipComponent: DataGridTooltip,
        }),
        []
    );

    const columnsWithTooltip = useMemo(() => {
        return columns?.map((column) => {
            const tooltip = column.headerTooltip
                ? `${column.headerName?.toUpperCase()}: ${column.headerTooltip}`
                : column.headerName?.toUpperCase();
            return { ...column, headerTooltip: tooltip };
        });
    }, [columns]);

    const frameworkComponents = useMemo(() => ({ TableButton }), []);

    return (
        <StrategyGridWrapper>
            {disabled && <InnerWrapper />}

            <AgGridReact
                rowModelType="clientSide"
                groupDisplayType="groupRows"
                groupDefaultExpanded={-1}
                suppressContextMenu={true}
                columnTypes={columnTypes}
                frameworkComponents={frameworkComponents}
                headerHeight={44}
                rowHeight={44}
                defaultColDef={defaultColDef}
                columnDefs={columnsWithTooltip}
                suppressClipboardPaste={true}
                rowData={rowData}
                pagination={false}
                {...rest}
            />
        </StrategyGridWrapper>
    );
};

const InnerWrapper = styled.div`
    position: absolute;
    inset: 0;
    cursor: pointer;
    z-index: 9999;
`;

export default StrategyGrid;
