import axios from 'axios';

export const getCommoditiesDropdownOptions = async () => {
    const respons = await axios.get('/commodities');
    return respons.data;
};

export const createCommodity = (payload) => axios.post('/commodities', payload);

export const getCommodityById = async (commodityId) => {
    const respons = await axios.get(`/commodities/${commodityId}`);
    return respons.data;
};

export const updateCommodity = (commodityId, payload) => axios.put(`/commodities/${commodityId}`, payload);
