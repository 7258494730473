import { Tab, Tabs, TabPanels, TabPanel, TabList, Flex } from '@chakra-ui/react';

import TabListWrapper from '../utils/TabListWrapper';
import { isNextTableInGroup, isPreviousTableInGroup } from 'services/autoforms';
import { TAB_GROUP_SETTING_KEY } from 'constants/autoforms';
import { useMemo } from 'react';

/**
 * Render a list of tables in tabular view.
 *
 * @param {string} variant - Variant for chakra tabs. Variants are defined in the theme.
 * @param {object[]} tables - List of table metadata.
 * @param {callback} renderTabPanel - Render prop for tab content.
 * @param {ReactComponent} tabListButtons - Additional buttons displayed next to tabs.
 * @param {callback} onTabOpen - Callback that passes the selected tab id
 * @param {number} selectedTab - Selected tab index
 * @param {number|string} mt - Chakra spacing prop for TabPanel
 * @param {number|string} tabListPx - Chakra spacing prop for TabList padding.
 * @param rest
 */
const AutoformTabs = ({
    variant,
    tables,
    renderTabPanel,
    tabListButtons,
    onTabOpen,
    selectedTab,
    mt,
    tabListPx,
    ...rest
}) => {
    const shouldGroupTabs = useMemo(() => {
        return tables?.some((t) => t?.settings.some((s) => s.name === TAB_GROUP_SETTING_KEY));
    }, [tables]);

    return (
        <Tabs isLazy lazyBehavior="unmount" variant={variant} index={selectedTab} onChange={onTabOpen} {...rest}>
            {tables.length > 1 && (
                <TabList px={tabListPx} w="100%" flexWrap={{ base: 'wrap', xl: 'nowrap' }} alignItems="center">
                    <TabListWrapper showButtons={true}>
                        {tables.map((table, idx) => {
                            if (shouldGroupTabs) {
                                const isPrevInGroup = isPreviousTableInGroup(tables, idx);
                                const isNextInGroup = isNextTableInGroup(tables, idx);
                                return (
                                    <Flex
                                        key={idx}
                                        bg="gray.200"
                                        p="10px"
                                        borderLeftRadius={isPrevInGroup ? '' : '4px'}
                                        borderRightRadius={isNextInGroup ? '' : '4px'}
                                        mr={isNextInGroup ? '' : '4px'}
                                    >
                                        <Tab>{table.name}</Tab>
                                    </Flex>
                                );
                            }

                            return <Tab key={idx}>{table.name}</Tab>;
                        })}
                    </TabListWrapper>

                    {tabListButtons}
                </TabList>
            )}

            <TabPanels mt={mt}>
                {tables.map((table, index) => (
                    <TabPanel key={index} pt={4}>
                        {renderTabPanel(table)}
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    );
};

export default AutoformTabs;
