import { useEffect } from 'react';

import Autoform from './Autoform';
import useAutoformLoadMetadata from './hooks/useAutoformLoadMetadata';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import useAutoformLoadTableDropdowns from './hooks/useAutoformLoadTableDropdowns';
import useAutoformMetadata from './hooks/useAutoformMetadata';
import useAutoformTableData from './hooks/useAutoformTableData';
import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import useAutoformSelectItem from './hooks/useAutoformSelectItem';

/**
 * * Container to load metadata for the selected autoform
 * in the portfolios screen.
 *
 * @param {string} autoformId - autoform id
 * @param {string} itemId - item's id
 * @param {function} onNodeRename - node rename handler
 * @param {function} onNodeChangeIsMaster - isMaster change handler
 * @param {callback} onNodeItemDelete - Optional. Callback to delete the portfolio item in the portfolios screen.
 * @param {object} portfolioItem - Item in the portfolios screen.
 * @param {callback} onNodeOwnershipPercentageUpdate - Optional. Callback to change the percent ownership
 * @param {object} config - flexible config for this and nested components
 * @returns {*}
 */
const PortfolioAutoformContainer = ({
    autoformId,
    itemId,
    onNodeRename,
    onNodeChangeIsMaster,
    onNodeItemDelete,
    portfolioItem,
    onNodeOwnershipPercentageUpdate,
    config = {},
}) => {
    const { isLoading } = useAutoformLoadMetadata(autoformId);
    const { itemTypeId } = useAutoformMetadata();
    const { hierarchicalTables } = useAutoformMetadata();
    const level1TableId = hierarchicalTables?.level1[0]?.id;

    useAutoformLoadTableDropdowns(level1TableId);
    const { selectedItem } = useAutoformTableData(level1TableId);
    const { selectedItem: selectedItemId } = useAutoformTableMetadata(level1TableId);

    useAutoformSelectItem(level1TableId, itemId);

    useEffect(() => {
        if (selectedItem?.SECURITYID !== undefined && itemId === selectedItemId) {
            onNodeChangeIsMaster && onNodeChangeIsMaster(selectedItem.SECURITYID);
        }
    }, [selectedItem, selectedItemId, itemId, onNodeChangeIsMaster]);

    if (isLoading || parseInt(itemTypeId?.value) !== autoformId || itemId !== selectedItemId)
        return <DefinitionsSkeleton />;

    return (
        <Autoform
            helpPageUrlItemTypeId={autoformId}
            onNodeRename={onNodeRename}
            onNodeChangeIsMaster={onNodeChangeIsMaster}
            onNodeItemDelete={onNodeItemDelete}
            portfolioItem={portfolioItem}
            onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
            config={config}
        />
    );
};

export default PortfolioAutoformContainer;
