import { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

const useNoStayZones = (modal) => {
    const intl = useIntl();

    const [selectedRowId, setSelectedRowId] = useState(null);

    const openModal = useCallback(
        (unitId) => {
            setSelectedRowId(unitId);
            modal.onOpen();
        },
        [modal]
    );

    const onCellClicked = useCallback(
        (params) => {
            openModal(params.value);
        },
        [openModal]
    );

    const closeModal = useCallback(() => {
        setSelectedRowId(null);
        modal.onClose();
    }, [modal]);

    const onCellKeyPress = useCallback(
        (params) => {
            if (params.event.key === 'Enter' && params.column.colId === 'noStayZonesEdit') {
                openModal(params.value);
            }
        },
        [openModal]
    );

    const column = useMemo(() => {
        return {
            type: 'button',
            colId: 'noStayZonesEdit',
            field: 'id',
            headerName: intl.formatMessage({ id: 'no_stay_zones' }),
            onCellClicked: onCellClicked,
            sortable: false,
        };
        // onCellClicked breaks multisorting
    }, [intl]); // eslint-disable-line

    return { selectedRowId, column, onCellKeyPress, closeModal };
};

export default useNoStayZones;
