import { useContext } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { Flex, Box, Collapse } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, IconButton } from '@chakra-ui/react';

import { ReactComponent as SettingsIcon } from 'icons/cog.svg';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';

import { ChartListContext } from 'pages/ChartViewer';
import ChartSpecModal from 'components/chartViewer/ChartSpecModal';

const ChartActions = ({ chartSpec }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { handleChartRemove: handleChartDelete } = useContext(ChartListContext);
    return (
        <>
            <Box>
                <IconButton
                    aria-label="Configure chart"
                    variant="outline"
                    mr="2"
                    onClick={onOpen}
                    icon={<SettingsIcon />}
                />
                <IconButton
                    aria-label="Delete chart"
                    variant="outline"
                    mr="2"
                    onClick={(e) => handleChartDelete(chartSpec, e.target)}
                    icon={<DeleteIcon />}
                />
            </Box>
            <ChartSpecModal chartSpec={chartSpec} isOpen={isOpen} onClose={onClose} />
        </>
    );
};

const ChartTable = () => {
    const { allChartSpecs } = useContext(ChartListContext);

    const getDateString = (sDate) => {
        let dateVal = new Date(sDate);
        return dateVal instanceof Date ? dateVal.toLocaleString() : sDate;
    };

    const ChartSpecsRows = () => {
        if (Array.isArray(allChartSpecs)) {
            return allChartSpecs.map((item) => (
                <Tr key={item.chartId}>
                    <Td>{item.title}</Td>
                    <Td>{item.relStartMonth}</Td>
                    <Td>{item.relEndMonth}</Td>
                    <Td>{item.active ? 'Yes' : 'No'}</Td>
                    <Td>{getDateString(item.updateDate)}</Td>
                    <Td>
                        <ChartActions chartSpec={item} />
                    </Td>
                </Tr>
            ));
        } else return null;
    };

    return (
        <TableContainer mt={2} mb={2} bg="whitesmoke" width="90vw" mx="auto">
            <Table size="sm">
                <Thead>
                    <Tr>
                        <>
                            <Th>Chart Title</Th>
                            <Th>Relative Start Month</Th>
                            <Th>Relative End Month</Th>
                            <Th>Show</Th>
                            <Th>Last Update</Th>
                            <Th>Chart Actions</Th>
                        </>
                    </Tr>
                </Thead>
                <Tbody>
                    <ChartSpecsRows />
                </Tbody>
            </Table>
        </TableContainer>
    );
};

const ChartList = ({ isOpen }) => {
    return (
        <>
            <Flex color="white" bg="lightgray" justifyContent={'space-around'}>
                <Collapse in={isOpen} animateOpacity mx="auto">
                    <ChartTable />
                </Collapse>
            </Flex>
        </>
    );
};

export default ChartList;
