import { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import MainModal from '../modal/MainModal';

import SubItemData from '../itemData/SubItemData';

import {
    getNoStayZones,
    updateNoStayZones,
    createSubThermalCostNoStayZones,
    deleteNoStayZones,
    deleteAllNoStayZones,
    validateNoStayZones,
} from '../../services/thermals';

const NoStayZonesModal = ({ rowId, subThermalId, onClose }) => {
    const intl = useIntl();

    const columns = useMemo(() => {
        return [
            {
                type: 'number',
                field: 'lowerBound',
                headerName: intl.formatMessage({ id: 'no_stay_zones_lower_bound' }),
                sort: 'asc',
            },
            {
                type: 'number',
                field: 'upperBound',
                headerName: intl.formatMessage({ id: 'no_stay_zones_upper_bound' }),
            },
        ];
    }, [intl]);

    const noStayZonesGridResource = useMemo(() => {
        return {
            create: (rows) =>
                createSubThermalCostNoStayZones(
                    subThermalId,
                    rows.map((row) => ({ subThermalFuelId: rowId, ...row }))
                ),
            read: (pagination) => getNoStayZones(subThermalId, rowId, { ...pagination }),
            update: (rows) => updateNoStayZones(subThermalId, rowId, rows),
            delete: (rows) =>
                deleteNoStayZones(
                    subThermalId,
                    rowId,
                    rows.map((row) => row.id)
                ),
            deleteAll: () => deleteAllNoStayZones(subThermalId, rowId),
            validate: (rows) =>
                validateNoStayZones(
                    subThermalId,
                    rows.map((row) => ({ subThermalFuelId: rowId, ...row }))
                ),
        };
    }, [subThermalId, rowId]);

    return (
        <MainModal
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="no_stay_zones" />}
            content={
                <SubItemData
                    tab="1"
                    heading={<FormattedMessage id="common_date_periods" />}
                    gridResource={noStayZonesGridResource}
                    columns={columns}
                    addDataColumns={columns}
                    primaryKeyName="id"
                />
            }
            secondaryButton={<></>}
            footerRightSlot={<></>}
        />
    );
};

export default NoStayZonesModal;
