import { useState, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Spacer } from '@chakra-ui/react';
import Tree from '../react-tree/Tree';
import {
    findPortfolioByPropertiesId,
    filterNodesByName,
    extractPortfoliosFromNodesExcludeSelected,
    getChildPortfolioNodeIds,
    copyPortfolio,
} from '../../services/portfolios';
import useCommonToast from '../../hooks/useCommonToast';

import PortfolioTreeWrapper from './PortfolioTreeWrapper';
import PortfolioNodeIcon from './PortfolioNodeIcon';
import PortfolioArrowIcon from './PortfolioArrowIcon';
import MainModal from '../modal/MainModal';
import SearchBar from '../utils/SearchBar';

const CopyPortfolioModal = ({ onClose, source, portfolios, onCopySuccess }) => {
    const { toast } = useCommonToast();
    const intl = useIntl();

    const [isLoading, setIsLoading] = useState(false);
    const [target, setTarget] = useState(() => {
        return source.properties.parentId != null
            ? findPortfolioByPropertiesId(portfolios, source.properties.parentId)
            : null;
    });

    const [nodes] = useState(() => {
        return extractPortfoliosFromNodesExcludeSelected(portfolios, source.id);
    });
    const [filteredNodes, setFilteredNodes] = useState(() => {
        return extractPortfoliosFromNodesExcludeSelected(portfolios, source.id);
    });

    const [expanded, setExpanded] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        const term = search.trimStart().toLowerCase();

        if (term && term.length >= 2) {
            const data = filterNodesByName(portfolios, term);
            const ids = getChildPortfolioNodeIds(data);

            setFilteredNodes(data);
            setExpanded(ids);
        } else {
            setFilteredNodes(nodes);
            setExpanded(portfolios.map((node) => node.id));
        }
    }, [search]); // eslint-disable-line

    const onSelectChange = useCallback((node) => {
        setTarget(node);
    }, []);

    const onExpandChange = useCallback(({ id }) => {
        setExpanded((prev) => (prev.includes(id) ? prev.filter((node) => node !== id) : prev.concat(id)));
    }, []);

    const onCopy = async () => {
        setIsLoading(true);

        try {
            const mappedCopies = await copyPortfolio(source.properties.id, target.properties.id);

            toast(
                intl.formatMessage(
                    { id: 'portfolios_copy_success' },
                    { name: source.properties.name, destination: target.properties.name }
                )
            );

            setIsLoading(false);
            onCopySuccess(mappedCopies);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const renderLabel = useCallback((node) => {
        return (
            <div className={`${node?.isExpandable ? '' : 'non-expandable '}portfolio-label-wrapper`}>
                {node?.isExpandable && (
                    <span
                        onClick={() => {
                            setExpanded((prev) =>
                                prev.includes(node.id) ? prev.filter((id) => id !== node.id) : prev.concat(node.id)
                            );
                        }}
                    >
                        <PortfolioArrowIcon isExpanded={node?.isExpanded} isMaster={node.properties.isMaster} />
                    </span>
                )}

                <div
                    onClick={(event) => {
                        if (event.shiftKey && node?.isExpandable) {
                            setExpanded((prev) =>
                                prev.includes(node.id)
                                    ? prev.filter((nodeId) => nodeId !== node.id)
                                    : prev.concat(node.id)
                            );
                        } else {
                            setTarget(node);
                        }
                    }}
                    className="portfolio-label"
                >
                    <PortfolioNodeIcon
                        isFolder={node.isFolder}
                        isExpanded={node?.isExpanded}
                        isMaster={node.properties.isMaster}
                        typeId={node.properties.typeId}
                    />

                    <span>{node.properties.name}</span>
                </div>
            </div>
        );
    }, []);

    return (
        <MainModal
            scrollBehavior="inside"
            isOpen
            onClose={onClose}
            header={
                <FormattedMessage
                    id="portfolios_properties_copy_modal_heading"
                    values={{ name: source.properties.name }}
                />
            }
            content={
                <>
                    <SearchBar
                        textHolder={intl.formatMessage({ id: 'common_search_portfolios' })}
                        onChange={(value) => setSearch(value)}
                    />

                    <Spacer mb={5} />

                    <PortfolioTreeWrapper isDisabled={false} isDisplayedInModal={true}>
                        <Tree
                            aria-labelledby="portfolios-title"
                            nodes={filteredNodes}
                            expanded={expanded}
                            onExpandChange={onExpandChange}
                            selected={target ? target.id : null}
                            onSelectChange={onSelectChange}
                            renderLabel={renderLabel}
                            expandOnSelect={false}
                        />
                    </PortfolioTreeWrapper>
                </>
            }
            footerRightSlot={
                <Button isLoading={isLoading} isDisabled={!target || target.id === source.id} onClick={onCopy} ml={3}>
                    <FormattedMessage id="portfolios_properties_copy_modal_btn" />
                </Button>
            }
        />
    );
};

export default CopyPortfolioModal;
