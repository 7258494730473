const Modal = {
    parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],

    baseStyle: {
        overlay: {
            /* backdropFilter: 'blur(10px)', */
        },

        dialog: {
            background: 'white',

            _dark: {
                background: 'black',
                border: '1px solid',
                borderColor: 'gray.600',
            },
        },

        footer: {
            borderTop: '1px',
            borderColor: 'gray.200',

            _dark: {
                borderColor: 'gray.600',
            },
        },

        closeButton: {
            color: 'black',

            _dark: {
                color: 'gray.600',
            },
        },
    },

    defaultProps: {
        size: '3xl',
    },
};

export default Modal;
