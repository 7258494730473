import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';

import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import useAutoformFormInitialValues from './hooks/useAutoformFormInitialValues';
import useAutoformMetadata from './hooks/useAutoformMetadata';
import { getTableFormKey, getItemFormKey } from './utils/autoformUtils';
import useAutoformSubmit from './hooks/useAutoformSubmit';

import { onHasUnsavedChanges } from 'store/helpers/helpersSlice';
import PromptWrapper from 'components/utils/PromptWrapper';
import AutoformFormikDirtyCheck from './AutoformFormikDirtyCheck';
import useAutoformFormValidationConfig from './hooks/useAutoformFormValidationConfig';
import useAutoformFormValidation from './hooks/useAutoformFormValidation';

/**
 * Handles form management and submission when editing level 1 and 2 tables.
 * Provides initial values, warnings and serverside validation.
 *
 * @param children - React children components
 * @param {boolean} includeLevel2Values - In some cases we do not want to include
 * level 2 table values in the form.
 * @param {function} onNodeRename - Rename the original node in portfolios screen.
 * @param {object} config - containing config for this and nested components
 *
 */
const ItemFormContainer = ({ children, includeLevel2Values, onNodeRename, config }) => {
    const dispatch = useDispatch();
    const { hierarchicalTables } = useAutoformMetadata();
    const [freezeInitialValues, setFreezeInitialValues] = useState(false);
    const [initialFormikStatus, setInitialFormikStatus] = useState({});
    const level1TableId = hierarchicalTables.level1[0].id;
    const [isFormDirty, setIsFormDirty] = useState(false);

    const { selectedItem: selectedLevel1Item, descriptionColumnName } = useAutoformTableMetadata(level1TableId);

    const initialValues = useAutoformFormInitialValues({
        includeLevel2Values,
        selectedLevel1Item,
        extraInitialValues: config?.extraInitialValues,
        freezeInitialValues,
    });

    const validationSettings = useAutoformFormValidationConfig({
        includeLevel2Values,
        selectedLevel1Item,
        extraValidationConfig: config?.extraValidationConfig,
    });

    const { onValidate } = useAutoformFormValidation({
        validationSettings,
    });

    const { handleSubmit } = useAutoformSubmit({ setFreezeInitialValues, setInitialFormikStatus });

    useEffect(() => {
        dispatch(onHasUnsavedChanges(isFormDirty));
    }, [dispatch, isFormDirty]);

    const onFormSubmit = async (values, formBag) => {
        return handleSubmit({
            values,
            formBag,
            initialFormValues: initialValues,
            onBeforeSubmit: config?.onBeforeSubmit,
            onBeforeSuccess: (onBeforeSuccessParams) => {
                const itemDescription =
                    values[getTableFormKey(level1TableId)] &&
                    values[getTableFormKey(level1TableId)][getItemFormKey(selectedLevel1Item)] &&
                    values[getTableFormKey(level1TableId)][getItemFormKey(selectedLevel1Item)][descriptionColumnName];
                onNodeRename && onNodeRename(itemDescription);
                config?.onBeforeSuccess && config?.onBeforeSuccess(onBeforeSuccessParams);
            },
        });
    };

    return (
        <Formik
            validate={onValidate}
            enableReinitialize
            initialValues={initialValues}
            initialStatus={initialFormikStatus}
            onSubmit={onFormSubmit}
        >
            {(formikProps) => (
                <Form>
                    <PromptWrapper />
                    <AutoformFormikDirtyCheck
                        initialValues={initialValues}
                        isFormDirty={isFormDirty}
                        onFormDirtyChange={setIsFormDirty}
                        config={config}
                    />
                    {typeof children === 'function' ? children(formikProps) : children}
                </Form>
            )}
        </Formik>
    );
};

export default ItemFormContainer;
