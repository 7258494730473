import { useCallback, useEffect, useMemo } from 'react';
import { getBulkUpdateData } from '../../../store/autoform/autoformApi';
import _ from 'lodash';
import { parseColumnSettings } from '../utils/autoformUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setBulkUpdateDataForTable } from '../../../store/autoform/autoformSlice';
import useAutoformParams from './useAutoformParams';

const useAutoformBulkUpdate = ({ tableId, parentRecordId, autoformConfig = {}, customLoadDataHandler }) => {
    const dispatch = useDispatch();
    const parameters = useAutoformParams();
    const autoformTablesState = useSelector((state) => state.autoform.tables);
    const bulkUpdateFields = useMemo(
        () => autoformTablesState?.[tableId]?.bulkUpdateFields,
        [autoformTablesState, tableId]
    );

    const loadBulkUpdateFormData = useCallback(() => {
        let result = false;
        if (parentRecordId && tableId) {
            let allVariedByRecord = false;
            dispatch(setBulkUpdateDataForTable({ bulkUpdateData: { [parentRecordId.toString()]: null }, tableId }));

            //try to get the bulk update switcher checkboxes and determine if they all are checked
            const switchers = Array.from(document.querySelectorAll('[id$="--switcher"'));
            if (Array.isArray(switchers) && switchers.length > 0) {
                const uncheckedSwitchers = switchers.filter((x) => x.checked === false);
                if (uncheckedSwitchers.length === 0) {
                    allVariedByRecord = true;
                }
            }

            const payload = {
                tableId,
                parentRecordId,
                parameters,
                allVariedByRecord,
            };

            dispatch(customLoadDataHandler ? customLoadDataHandler(payload) : getBulkUpdateData(payload));
            result = true;
        }

        return result;
    }, [parentRecordId, tableId, parameters, customLoadDataHandler, dispatch]);

    const handleDependentDataChange = useCallback(
        ({ setValues, setTouched, status, setStatus }) => {
            const loadResult = loadBulkUpdateFormData();

            if (loadResult) {
                setValues((values) => {
                    const bulkUpdate = {};
                    const fieldsToUnTouch = {};

                    Object.keys(values?.bulkUpdate ?? {})
                        .filter((key) => key.includes(`${parentRecordId}__${tableId}`))
                        .forEach((key) => {
                            fieldsToUnTouch[key] = false;
                        });

                    setTouched(fieldsToUnTouch, false);

                    Object.keys(values?.bulkUpdate ?? {})
                        .filter((key) => !key.includes(`${parentRecordId}__${tableId}`))
                        .forEach((key) => {
                            bulkUpdate[key] = values?.bulkUpdate[key];
                        });

                    return {
                        ...values,
                        bulkUpdate,
                    };
                });

                setStatus({ ...status, bulkUpdate: {} });
            }
        },
        [loadBulkUpdateFormData, parentRecordId, tableId]
    );

    useEffect(() => {
        loadBulkUpdateFormData();
    }, [loadBulkUpdateFormData]);

    const validationSettings = useMemo(() => {
        const result = [];

        for (const bulkUpdateField of bulkUpdateFields ?? []) {
            const settings = parseColumnSettings(bulkUpdateField.columnMetadata.settings);

            result.push({
                ...settings,
                isRequired: bulkUpdateField.columnMetadata.isRequired,
                inputName: `bulkUpdate.${bulkUpdateField.name}`,
                dataType: bulkUpdateField.columnMetadata.dataTypeDescription,
                inputType: bulkUpdateField.columnMetadata.inputTypeDescription,
                label: bulkUpdateField.columnMetadata.displayName,
                onBeforeValidate: (values) => !values?.bulkUpdate?.[`${bulkUpdateField.name}--switcher`],
            });
        }

        return result;
    }, [bulkUpdateFields]);

    const dirtyCheck = useCallback(
        ({ values, initialValues }) => {
            let result = !!(autoformConfig?.dirtyCheck && autoformConfig.dirtyCheck({ values, initialValues }));

            if (autoformTablesState && values?.bulkUpdate) {
                let bulkUpdateInitialValues = {};
                const allowedNames = Object.keys(values?.bulkUpdate ?? {}).filter((key) => !key.includes('--switcher'));

                for (const tableKey in autoformTablesState) {
                    const table = autoformTablesState[tableKey];

                    if (table?.bulkUpdateInitialValues) {
                        const namesToProcess = _.intersection(allowedNames, Object.keys(table.bulkUpdateInitialValues));

                        for (const name of namesToProcess) {
                            bulkUpdateInitialValues[name] = table.bulkUpdateInitialValues[name];
                        }
                    }
                }

                result =
                    result ||
                    !_.isEqual(
                        {
                            ...values,
                            bulkUpdate: _.pick(values?.bulkUpdate ?? {}, ...allowedNames),
                        },
                        { ...initialValues, bulkUpdate: bulkUpdateInitialValues }
                    );
            } else if (!autoformConfig?.dirtyCheck) {
                result = result || !_.isEqual(values, initialValues);
            }

            return result;
        },
        [autoformConfig, autoformTablesState]
    );

    const itemFormContainerConfig = useMemo(
        () =>
            _.merge({}, autoformConfig, {
                extraValidationConfig: validationSettings,
                dirtyCheck,
            }),
        [autoformConfig, validationSettings, dirtyCheck]
    );

    return useMemo(
        () => ({
            loadBulkUpdateFormData,
            handleDependentDataChange,
            validationSettings,
            itemFormContainerConfig,
            dirtyCheck,
        }),
        [loadBulkUpdateFormData, validationSettings, itemFormContainerConfig, dirtyCheck, handleDependentDataChange]
    );
};

export default useAutoformBulkUpdate;
