import { useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Flex,
    Progress,
    Alert,
    AlertIcon,
    IconButton,
    Spacer,
    Text,
    Radio,
    RadioGroup,
    Stack,
    useDisclosure,
} from '@chakra-ui/react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import styled from '@emotion/styled/macro';
import { useDispatch } from 'react-redux';

import {
    uploadFile,
    downloadFile,
    exportValidationResults,
    getHumanFileSize,
    uploadFileGeneric,
} from '../../services/items';
import { importAutoformTables } from '../../services/autoforms';

import { toggleReloadGridFlag } from '../../store/autoform/autoformSlice';
import useAutoformMetadata from '../autoform/hooks/useAutoformMetadata';
import useAutoformTableMetadata from '../autoform/hooks/useAutoformTableMetadata';

import CustomCheckbox from '../forms/CustomCheckbox';

import DragAndDropFile from '../upload/DragAndDropFile';

import { ReactComponent as UploadIcon } from '../../icons/upload.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import {
    UPDATE_TYPE_REPLACE,
    UPDATE_TYPE_UPDATE,
    UPLOAD_TABS_ALL,
    UPLOAD_TABS_CURRENT,
} from 'constants/uploadDownload';
import { msalInstance, scopes } from 'services/auth';
import useExceptionToast from '../../hooks/useExceptionToast';

const FINISHED_STATUSES = [4, 5, 6];
const INITIAL_STATUS = -1;
const SUCCESS_STATUS = 4;
const SUCCESS_WARNING_STATUS = 5;
const FAIL_STATUS = 6;

const UploadModal = ({
    onAfterUploadChange,
    onClose,
    type,
    typeFriendlyName,
    subschemaCode,
    itemId,
    onTemplatesConfigChange,
    config,
    tablesMetadata,
    parentTableId,
    parameters,
    queryParams,
    hideTreatWarningsAsErrors,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { exceptionToast } = useExceptionToast();

    const includeGenericUpload = subschemaCode?.length > 0;

    const isAutoformUpload = tablesMetadata !== undefined;

    const { metadata: autoformMetadata } = useAutoformMetadata();
    const { selectedItem: parentRecordId } = useAutoformTableMetadata(parentTableId);

    const percentageRef = useRef(0);
    const uploadRequestAbortController = useRef(0);
    const hubConnection = useRef();

    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmModalClose } = useDisclosure();
    const [tabUploadType, setTabUploadType] = useState(UPLOAD_TABS_CURRENT);
    const [updateType, setUpdateType] = useState(UPDATE_TYPE_UPDATE);
    const [percentage, setPercentage] = useState(0);
    const [file, setFile] = useState();
    const [importLoading, setImportLoading] = useState(false);
    const [importError, setImportError] = useState('');
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [treatWarningsAsErrors, setTreatWarningsAsErrors] = useState(false);

    const [phase, setPhase] = useState({
        status: INITIAL_STATUS,
        title: intl.formatMessage({ id: 'upload_modal_initial_stage_title' }),
    });

    const handleFileUpload = (file) => {
        const formattedFile = new File([file], file.name, { type: file.type });
        formattedFile['friendlySize'] = getHumanFileSize(file.size, true, 2);

        setFile(formattedFile);
    };

    const removeFile = () => {
        setFile(undefined);
    };

    const prepareServerSentEvents = async (isGenericUpload = false) => {
        if (!hubConnection.current) {
            const connection = new HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_API}/hubs/uploadprogress`, {
                    accessTokenFactory: async () => {
                        const msalObj = await msalInstance.acquireTokenSilent({ scopes: scopes });
                        return msalObj?.accessToken;
                    },
                })
                .withAutomaticReconnect()
                .build();

            hubConnection.current = connection;

            hubConnection.current.on('ReceiveStatusUpdate', (statusData) => {
                setPhase(statusData);
                setPercentage(0);

                if (
                    (statusData.status === SUCCESS_WARNING_STATUS || statusData.status === SUCCESS_STATUS) &&
                    onAfterUploadChange
                ) {
                    onAfterUploadChange();
                }

                if (FINISHED_STATUSES.includes(statusData.status)) closeHubConnection();
            });
            hubConnection.current.on('ReceivePercentageUpdate', (percentage) => {
                setPercentage(percentage);
            });

            await hubConnection.current.start({ withCredentials: false });
            const sseClientId = hubConnection.current.connection.connectionId;

            importData(sseClientId, isGenericUpload);
        }
    };

    const importData = async (sseClientId, isGenericUpload = false) => {
        setImportLoading(true);

        const abortController = new AbortController();
        uploadRequestAbortController.current = abortController;

        const additionalConfig = {
            onUploadProgress: (progressEvent) => {
                setFileUploadProgress(progressEvent.loaded);
                setPercentage((progressEvent.loaded / file.size) * 100);
                percentageRef.current = (progressEvent.loaded / file.size) * 100;
            },
            signal: abortController.signal,
            params: queryParams,
        };

        try {
            if (isGenericUpload && tabUploadType === UPLOAD_TABS_ALL) {
                await uploadFileGeneric(
                    file,
                    subschemaCode,
                    itemId,
                    parameters,
                    updateType === UPDATE_TYPE_REPLACE,
                    additionalConfig,
                    sseClientId,
                    treatWarningsAsErrors
                );
                if (isAutoformUpload) {
                    const tableIds = tablesMetadata.map((metadata) => metadata.id);
                    dispatch(toggleReloadGridFlag({ tableIds }));
                }
            } else {
                if (isAutoformUpload) {
                    const tableIds = tablesMetadata.map((metadata) => metadata.id);
                    const parameters = autoformMetadata.parameters.map((parameter) => parameter.value);

                    const autoformImportPayload = {
                        file,
                        tableIds,
                        parentRecordId,
                        parameters,
                        sseClientId,
                        additionalConfig,
                        treatWarningsAsErrors,
                    };

                    config?.importAutoformTables
                        ? await config.importAutoformTables(autoformImportPayload)
                        : await importAutoformTables(autoformImportPayload);

                    dispatch(toggleReloadGridFlag({ tableIds }));
                } else {
                    await uploadFile(type, itemId, file, additionalConfig, sseClientId, treatWarningsAsErrors);
                    // for bidding strategy we need to update the templates config for the given sub item to null (hence ='Custom' strategy)
                    if (type === 'batteries') {
                        onTemplatesConfigChange(itemId, { templateId: null, hasUpload: true });
                    }
                }
            }
            setImportError('');
        } catch (err) {
            const validationException =
                err.response?.data?.details &&
                err.response?.data?.details?.toUpperCase().includes('VALIDATIONEXCEPTION');
            const errorMessage = err.response?.data?.error || intl.formatMessage({ id: 'upload_modal_failed_import' });
            setImportError(errorMessage);

            if (validationException) {
                const toastErrorDetails = err.response?.data?.details;
                exceptionToast(intl.formatMessage({ id: 'upload_modal_validation_exception' }), toastErrorDetails);
            }
        } finally {
            setImportLoading(false);
            uploadRequestAbortController.current = undefined;
        }
    };

    const handleUploadConfirm = async () => {
        await prepareServerSentEvents(true);
        onConfirmModalClose();
    };

    const closeHubConnection = () => {
        if (hubConnection.current) {
            hubConnection.current.stop();
            hubConnection.current = undefined;
        }
    };

    const downloadErrorReport = async () => {
        setDownloadLoading(true);
        try {
            const fileInfo = await exportValidationResults(phase.data);
            downloadFile(fileInfo);
        } finally {
            setDownloadLoading(false);
        }
    };

    const cancelImport = async () => {
        if (uploadRequestAbortController.current) {
            uploadRequestAbortController.current.abort();
            uploadRequestAbortController.current = undefined;
        }

        closeHubConnection();
        onClose();
    };

    const handleUploadClick = () => {
        if (includeGenericUpload && tabUploadType === UPLOAD_TABS_ALL) {
            onConfirmOpen();
        } else {
            prepareServerSentEvents();
        }
    };

    const onCloseModal = () => {
        if (importLoading) {
            cancelImport();
        } else {
            onClose();
        }
    };

    const friendlyUploadProgress = useMemo(() => {
        return getHumanFileSize(fileUploadProgress, true, 2);
    }, [fileUploadProgress]);

    return (
        <>
            <Modal isOpen onClose={onCloseModal}>
                <ModalOverlay />

                <ModalContent pt={3}>
                    <ModalHeader pr={12}>
                        <Heading as="h3" variant="h3" textTransform="capitalize">
                            <FormattedMessage id="upload_modal_header" />
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                    <ModalBody borderBottom="1px" borderColor="border-secondary">
                        {includeGenericUpload && (
                            <Box mb={4}>
                                <RadioGroup onChange={setTabUploadType} value={tabUploadType}>
                                    <Stack mb={2} direction="column">
                                        <Radio value={UPLOAD_TABS_CURRENT}>
                                            {config?.uploadFriendlyNameText
                                                ? config.uploadFriendlyNameText
                                                : typeFriendlyName ?? <>Upload current tab</>}
                                        </Radio>
                                        <Radio value={UPLOAD_TABS_ALL}>All Tabs</Radio>
                                    </Stack>
                                </RadioGroup>
                                <Box ml={10}>
                                    {tabUploadType === UPLOAD_TABS_ALL && (
                                        <RadioGroup onChange={setUpdateType} value={updateType}>
                                            <Stack direction="row">
                                                <Radio mr={2} value={UPDATE_TYPE_UPDATE}>
                                                    Update
                                                </Radio>
                                                <Radio value={UPDATE_TYPE_REPLACE}>Replace</Radio>
                                            </Stack>
                                        </RadioGroup>
                                    )}
                                </Box>
                            </Box>
                        )}
                        {!file ? (
                            <DragAndDropFile handleFileChange={handleFileUpload} />
                        ) : (
                            <>
                                <FileContainer>
                                    <UploadIconSquaredWrapper>
                                        <StyledUploadIcon />
                                    </UploadIconSquaredWrapper>
                                    <Flex direction="column" justify="center" flex="1" minHeight="60px">
                                        <Text>{file.name}</Text>
                                        {importLoading && (
                                            <>
                                                <Progress
                                                    variant={fileUploadProgress !== file.size && 'blue'}
                                                    my={2}
                                                    value={percentage}
                                                    isIndeterminate={percentage === -1}
                                                />
                                                {phase.status === INITIAL_STATUS ? (
                                                    <FormattedMessage
                                                        id="upload_modal_size_uploaded"
                                                        values={{
                                                            uploadedSize: friendlyUploadProgress,
                                                            totalSize: file.friendlySize,
                                                        }}
                                                    />
                                                ) : (
                                                    <Text>{percentage !== -1 && `${percentage}%`}</Text>
                                                )}
                                            </>
                                        )}
                                    </Flex>
                                    {!(importLoading || phase.status !== INITIAL_STATUS) && (
                                        <CloseIconButton variant="ghost" icon={<CloseIcon />} onClick={removeFile} />
                                    )}
                                </FileContainer>

                                {importLoading && (
                                    <Alert status="info">
                                        <AlertIcon />
                                        {phase.title}
                                    </Alert>
                                )}

                                {(phase.status === FAIL_STATUS || importError) && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        {phase.status === FAIL_STATUS ? phase.title : importError}
                                    </Alert>
                                )}

                                {phase.status === SUCCESS_STATUS && (
                                    <Alert status="success">
                                        <AlertIcon />
                                        <FormattedMessage id="upload_modal_successful_import" />
                                    </Alert>
                                )}

                                {phase.status === SUCCESS_WARNING_STATUS && (
                                    <Alert status="warning">
                                        <AlertIcon />
                                        <FormattedMessage id="upload_modal_successful_warning_import" />
                                    </Alert>
                                )}
                            </>
                        )}
                    </ModalBody>

                    <ModalFooter justifyContent="stretch" flexWrap="wrap">
                        <Flex flex="1" justifyContent="space-between">
                            {hideTreatWarningsAsErrors || importLoading || phase.status !== INITIAL_STATUS ? (
                                <Spacer />
                            ) : (
                                <CustomCheckbox
                                    isChecked={treatWarningsAsErrors}
                                    onChange={() => setTreatWarningsAsErrors((prev) => !prev)}
                                >
                                    <FormattedMessage id="upload_modal_accept_warnings_checkbox" />
                                </CustomCheckbox>
                            )}

                            <Box>
                                {(phase.status === FAIL_STATUS ||
                                    (phase.data && phase.status === SUCCESS_WARNING_STATUS)) && (
                                    <Button
                                        type="submit"
                                        isLoading={downloadLoading}
                                        ml={3}
                                        textTransform="capitalize"
                                        onClick={downloadErrorReport}
                                        variant="secondary"
                                    >
                                        <FormattedMessage id="upload_modal_download_error_report" />
                                    </Button>
                                )}
                                {importLoading ? (
                                    <Button type="submit" textTransform="capitalize" ml={3} onClick={cancelImport}>
                                        <FormattedMessage id="common_cancel" />
                                    </Button>
                                ) : (
                                    <>
                                        {FINISHED_STATUSES.includes(phase.status) || importError ? (
                                            <Button type="submit" textTransform="capitalize" ml={3} onClick={onClose}>
                                                <FormattedMessage id="common_continue" />
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                isLoading={importLoading}
                                                textTransform="capitalize"
                                                disabled={!file}
                                                ml={3}
                                                onClick={handleUploadClick}
                                            >
                                                <FormattedMessage id="common_upload_btn_tooltip_and_label" />
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {isConfirmOpen && (
                <ConfirmationModal
                    isOpen={isConfirmOpen}
                    header="All-tab Upload"
                    onClose={onConfirmModalClose}
                    hasExtraStep
                    onConfirm={handleUploadConfirm}
                    content={
                        updateType === UPDATE_TYPE_UPDATE ? (
                            <FormattedMessage id="upload_confirmation_update_message" />
                        ) : (
                            <FormattedMessage id="upload_confirmation_replace_message" />
                        )
                    }
                />
            )}
        </>
    );
};

export const UploadIconSquaredWrapper = styled(Flex)`
    background: var(--chakra-colors-gray-100);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    min-height: 60px;
`;

export const FileContainer = styled(Flex)`
    min-height: 100px;
    align-items: center;
    padding-bottom: 24px;
    grid-gap: 12px;
`;
export const StyledUploadIcon = styled(UploadIcon)`
    fill: #677380;
`;

export const CloseIconButton = styled(IconButton)`
    padding: 2px;
    min-width: 20px;
    min-height: 20px;

    height: 24px;
    width: 24px;

    svg {
        width: 14px;
        height: 14px;
    }
`;
export default UploadModal;
