import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDataValueObjectFromDto } from '../utils/autoformUtils';
import useAutoformTableMetadata from './useAutoformTableMetadata';

/**
 * Selects the data from the store for a single table and parses it.
 *
 * @param {string} tableId - Id of the table to which the record belongs.
 * @returns {object[]} data - An array of table rows as objects, parsed.
 * @returns {object} selectedItem - The record selected for this particular table
 * from the UI.
 */
const useAutoformTableData = (tableId) => {
    const data = useSelector((state) => state.autoform.tables[tableId] && state.autoform.tables[tableId].data);
    const { metadata, selectedItem: selectedItemId, primaryKeyColumnName } = useAutoformTableMetadata(tableId);

    const parsedData = useMemo(
        () => (data ? data.map((item) => getDataValueObjectFromDto(item, metadata)) : []),
        [data, metadata]
    );

    const selectedItem = useMemo(() => {
        return data?.find((record) => record[primaryKeyColumnName] === selectedItemId);
    }, [data, selectedItemId, primaryKeyColumnName]);

    return { data: parsedData, selectedItem };
};

export default useAutoformTableData;
