import { useState } from 'react';
import styled from '@emotion/styled/macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton } from '@chakra-ui/react';

import { useDisclosure } from '@chakra-ui/react';

import Tooltip from '../utils/Tooltip';
import UploadModal from '../utils/UploadModal';

import { ReactComponent as UploadIcon } from '../../icons/upload.svg';
import { ReactComponent as CircledUploadIcon } from '../../icons/upload-circled.svg';
import SecondaryButton from 'components/utils/SecondaryButton';

const UploadButton = ({
    itemId,
    type,
    hasLabel,
    typeFriendlyName,
    subschemaCode,
    isInsideMenu = false,
    onTemplatesConfigChange,
    onAfterUploadChange,
    hideTreatWarningsAsErrors,
    parameters,
    queryParams,
    ...rest
}) => {
    const intl = useIntl();
    const uploadModal = useDisclosure();

    const [changesQueued, setChangesQueued] = useState(false);

    const closeModal = () => {
        if (changesQueued && onAfterUploadChange) {
            onAfterUploadChange();
        }
        uploadModal.onClose();
    };

    return (
        <>
            {hasLabel ? (
                <SecondaryButton
                    leftIcon={<UploadIcon />}
                    size="sm"
                    type="button"
                    variant="secondary"
                    onClick={uploadModal.onOpen}
                    {...rest}
                >
                    <FormattedMessage id="common_upload_btn_tooltip_and_label" />
                </SecondaryButton>
            ) : (
                <TooltipWrapper>
                    <Tooltip label={intl.formatMessage({ id: 'common_upload_btn_tooltip_and_label' })}>
                        <IconButton
                            aria-label={intl.formatMessage({ id: 'common_upload_btn_tooltip_and_label' })}
                            icon={<CircledUploadIcon />}
                            onClick={uploadModal.onOpen}
                            mr={6}
                            variant="circular-icon"
                        />
                    </Tooltip>
                </TooltipWrapper>
            )}
            {uploadModal.isOpen && (
                <UploadModal
                    onClose={closeModal}
                    type={type}
                    itemId={itemId}
                    typeFriendlyName={typeFriendlyName}
                    subschemaCode={subschemaCode}
                    parameters={parameters}
                    queryParams={queryParams}
                    onAfterUploadChange={() => setChangesQueued(true)}
                    onTemplatesConfigChange={onTemplatesConfigChange}
                    hideTreatWarningsAsErrors={hideTreatWarningsAsErrors}
                />
            )}
        </>
    );
};

const TooltipWrapper = styled.div`
    .tooltip_wrapper {
        transform: translate(-70%, 110%);
        &:focus {
            transform: translate(-70%, 110%);
        }
    }

    .tooltip_children_wrapper + div {
        transform: translate(-70%, 110%);
        &:focus {
            transform: translate(-70%, 110%);
        }
    }
`;

export default UploadButton;
