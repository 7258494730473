import { Heading, Modal, ModalOverlay, ModalContent, Flex, ModalHeader, Spinner } from '@chakra-ui/react';

const LoadingModal = ({ header, ...rest }) => {
    return (
        <Modal size="md" isCentered {...rest}>
            <ModalOverlay />

            <ModalContent borderRadius="2xl">
                <Flex justify="space-between" align="center" px={5}>
                    <ModalHeader flex="1" pl={0}>
                        <Heading as="h3" variant="h3">
                            {header}
                        </Heading>
                    </ModalHeader>
                    <Spinner />
                </Flex>
            </ModalContent>
        </Modal>
    );
};

export default LoadingModal;
