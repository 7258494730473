import { useCallback, useMemo } from 'react';
import useCommonToast from './useCommonToast';
import { useIntl } from 'react-intl';

const useAddToPortfolios = ({ onModalOpen, onModalClose, temporaryNodeName, setTemporaryNode }) => {
    const { toast } = useCommonToast();
    const intl = useIntl();

    const onAddToPortfolioModalOpen = useCallback(
        (item) => {
            setTemporaryNode(item);
            onModalOpen();
        },
        [onModalOpen, setTemporaryNode]
    );

    const closeAddToPortfolioModal = useCallback(() => {
        onModalClose();
        setTemporaryNode(null);
    }, [onModalClose, setTemporaryNode]);

    const onAddItemToPortfolioSuccess = useCallback(
        (selectedPortfolios) => {
            let message;

            if (selectedPortfolios.length === 1) {
                message = intl.formatMessage(
                    { id: 'common_add_item_success_message' },
                    { item: temporaryNodeName, destination: selectedPortfolios[0].properties.name }
                );
            } else {
                message = intl.formatMessage(
                    { id: 'common_add_item_to_multiple_portfolios_success_message' },
                    { item: temporaryNodeName, n: selectedPortfolios.length }
                );
            }

            toast(message);
            closeAddToPortfolioModal();
        },
        [temporaryNodeName, intl, closeAddToPortfolioModal, toast]
    );

    return useMemo(
        () => ({
            onAddToPortfolioModalOpen,
            closeAddToPortfolioModal,
            onAddItemToPortfolioSuccess,
        }),
        [onAddToPortfolioModalOpen, closeAddToPortfolioModal, onAddItemToPortfolioSuccess]
    );
};

export default useAddToPortfolios;
