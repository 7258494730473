import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useAutoformTableMetadata from './useAutoformTableMetadata';

import { COLUMN_TYPES, INPUT_TYPES, DATA_TYPES, DATE_FORMATS_WITHOUT_TIME } from '../../../constants/fields';
import { parseColumnSettings, parseDateFormat, parseNumberFormat } from '../utils/autoformUtils';

/**
 * Generate column definitions for AG Grid based on the metadata of that particular autoform table.
 * Prerequisites: Autoform Metadata should be loaded into the redux store (useLoadAutoformMetadata).
 * @param {string} tableId - The value of the Select.
 * @returns {array} The column definitions to be used by AG Grid directly.
 */
const useAutoformColumnDefinitions = (tableId) => {
    const { metadata: tableMetadata, sortedColumns, dropdownOptions } = useAutoformTableMetadata(tableId);

    const sortModel = useSelector(
        (state) => state.autoform?.tables[tableId] && state.autoform?.tables[tableId]?.sortModel
    );
    const formatDropdownOptions = (options) => options?.map((opt) => ({ id: opt.value, description: opt.text }));

    const columnDefinitions = useMemo(() => {
        const visibleColumns = sortedColumns?.filter((column) => column.isVisible);
        return visibleColumns?.map((col) => {
            if (!(col.inputTypeDescription in COLUMN_TYPES))
                throw Error(`Unsupported column type ${col.inputTypeDescription}`);

            const settings = parseColumnSettings(col.settings);
            let type = COLUMN_TYPES[col.inputTypeDescription];
            let cellEditorParams = {};
            const sortingOrder = {};

            if (col.inputTypeDescription === INPUT_TYPES.TextBox) {
                cellEditorParams = {
                    minLength: parseInt(settings?.STRINGMINLENGTH),
                    maxLength: parseInt(settings?.STRINGMAXLENGTH),
                };
            }
            if (col.inputTypeDescription === INPUT_TYPES.ComboBox && dropdownOptions[col.id]) {
                cellEditorParams = {
                    options: formatDropdownOptions(dropdownOptions[col.id]),
                };
            }
            if (
                col.inputTypeDescription === INPUT_TYPES.NumericUpDown ||
                (col.inputTypeDescription === INPUT_TYPES.TextBox &&
                    (col.dataTypeDescription === DATA_TYPES.Int || col.dataTypeDescription === DATA_TYPES.Double))
            ) {
                cellEditorParams = {
                    min: settings?.NUDMIN,
                    max: settings?.NUDMAX,
                    format: parseNumberFormat(col),
                };
                type = COLUMN_TYPES[INPUT_TYPES.NumericUpDown];
            }
            if (
                col.inputTypeDescription === INPUT_TYPES.MonthCalendar ||
                (col.inputTypeDescription === INPUT_TYPES.TextBox && col.dataTypeDescription === DATA_TYPES.DateTime)
            ) {
                const dateFormat = parseDateFormat(col);
                type = COLUMN_TYPES[INPUT_TYPES.MonthCalendar];
                cellEditorParams = {
                    minDate: settings?.NUDMIN,
                    maxDate: settings?.NUDMAX,
                    dateFormat: dateFormat,
                    showTimeInput: !DATE_FORMATS_WITHOUT_TIME.includes(col.formatCode),
                };
            }
            if (sortModel) {
                const sortDirectionColumn = sortModel?.find((sortingColumn) => sortingColumn.colId === col.name);
                if (sortDirectionColumn) sortingOrder.initialSort = sortDirectionColumn.sort;
            }

            const columnDefinition = {
                type,
                cellEditorParams: {
                    ...cellEditorParams,
                    required: col.isRequired,
                    defaultValue: col.defaultValue,
                    isReadOnly: col.isReadOnly,
                },
                ...sortingOrder,
                field: col.name,
                headerName: col.displayName,
                headerTooltip: settings?.CELLTOOLTIP,
                editable: !col.isReadOnly && tableMetadata?.canUpdate,
            };

            return columnDefinition;
        });
    }, [sortedColumns, dropdownOptions, sortModel, tableMetadata?.canUpdate]);
    return columnDefinitions;
};

export default useAutoformColumnDefinitions;
