import { useState, useEffect } from 'react';
import { getItems } from 'services/globalConstraints';

const useGlobalConstraintsItems = () => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);

        getItems()
            .then((response) => {
                setItems(response);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return { items, isLoading, isError };
};

export default useGlobalConstraintsItems;
