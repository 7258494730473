import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const AutomaticFormikDirtyCheck = ({ isFormDirty, onFormDirtyChange }) => {
    const { dirty } = useFormikContext();

    useEffect(() => {
        if (isFormDirty !== dirty) {
            onFormDirtyChange(dirty);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty]);

    return null;
};

export default AutomaticFormikDirtyCheck;
