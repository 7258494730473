const Card = {
    baseStyle: {
        background: 'white',
        borderRadius: 'md',
        boxShadow: 'xs',
        padding: 6,
        border: '1px solid',
        borderColor: 'gray.200',
        _hover: {
            boxShadow: 'lg',
        },
        _dark: {
            borderColor: 'transparent',
            background: 'gray.800',
            boxShadow: 'unset',
        },
    },

    variants: {
        rounded: {},
        rectangle: {
            borderRadius: 0,
        },
    },

    defaultProps: {
        variant: 'rounded',
    },
};

export default Card;
