import styled from '@emotion/styled/macro';
import SecondaryButton from 'components/utils/SecondaryButton';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as UploadIcon } from '../../icons/upload.svg';
import { ReactComponent as CircledUploadIcon } from '../../icons/upload-circled.svg';
import { IconButton, Tooltip } from '@chakra-ui/react';

export const UploadButtonV2 = ({ hasLabel, ...rest }) => {
    const intl = useIntl();
    return (
        <>
            {hasLabel ? (
                <SecondaryButton leftIcon={<UploadIcon />} size="sm" type="button" variant="secondary" {...rest}>
                    <FormattedMessage id="common_upload_btn_tooltip_and_label" />
                </SecondaryButton>
            ) : (
                <TooltipWrapper>
                    <Tooltip label={intl.formatMessage({ id: 'common_upload_btn_tooltip_and_label' })}>
                        <IconButton
                            aria-label={intl.formatMessage({ id: 'common_upload_btn_tooltip_and_label' })}
                            icon={<CircledUploadIcon />}
                            mr={6}
                            variant="circular-icon"
                            {...rest}
                        />
                    </Tooltip>
                </TooltipWrapper>
            )}
        </>
    );
};

const TooltipWrapper = styled.div`
    .tooltip_wrapper {
        transform: translate(-70%, 110%);
        &:focus {
            transform: translate(-70%, 110%);
        }
    }

    .tooltip_children_wrapper + div {
        transform: translate(-70%, 110%);
        &:focus {
            transform: translate(-70%, 110%);
        }
    }
`;
