import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useAutoformMetadata from './useAutoformMetadata';
import useAutoformTableHierarchy from './useAutoformTableHierarchy';
import {
    getPrimaryKeyColName,
    getTableFormKey,
    getItemFormKey,
    getInitialValuesForDataRow,
    getDataValueObjectFromDto,
    getFormTableIds,
} from '../utils/autoformUtils';

/**
 * Generate initial values for multiple tables on level 1 and 2 depending on autoform metadata.
 * Default values compatible with Formik for each inputType and dataType are supplied. These
 * need to be reverted when the request is sent to the backend.
 *
 * Prerequisites: Autoform Metadata should be loaded into the redux store (useLoadAutoformMetadata).
 * @param {number} selectedLevel1Item - The selected item from the level 1 autoform table for which
 * we want to send values to the server.
 * @param {boolean} includeLevel2Values - Should initial values for level 2 tables be included. In cases
 * where the autoform max table hierarchy level is > 2, we need this to be true.
 * @param {boolean} freezeInitialValues - Stop initial values recalculation to allow several requests to
 * happen sequentially and not flash old values in the form.
 * @returns {object} The initial values to be used by Formik directly. Has format:
 * {
 *    table123: {
 *       item123: {
 *          [COLUMN_NAME]: value
 *       }
 *    }
 * }
 */
const useAutoformFormInitialValues = ({
    includeLevel2Values,
    selectedLevel1Item,
    extraInitialValues,
    freezeInitialValues = false,
}) => {
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const { tablesWithChildrenMap } = useAutoformTableHierarchy(hierarchicalTables.level2, hierarchicalTables.level3);
    const tableData = useSelector((state) => state.autoform.tables);
    const [initialValues, setInitialValues] = useState({});

    const memoizedInitialValues = useMemo(() => {
        if (metadata) {
            const tableIds = getFormTableIds(hierarchicalTables, includeLevel2Values, tablesWithChildrenMap);

            const initialValues = tableIds.reduce((acc, tableId) => {
                const tableKey = getTableFormKey(tableId);
                const tableMetadata = metadata.tables.find((t) => t.id === tableId);
                const data = tableData[tableId];
                if (!tableMetadata || !data?.data || !Array.isArray(data.data)) {
                    return acc;
                }
                if (!acc[tableKey]) {
                    acc[tableKey] = {};
                }

                const primaryColName = getPrimaryKeyColName(tableMetadata.columns);

                data.data?.forEach((dataRow) => {
                    const parsedRow = getDataValueObjectFromDto(dataRow, tableMetadata);
                    const itemId = dataRow[primaryColName];
                    const itemKey = getItemFormKey(itemId);

                    if (hierarchicalTables?.level1[0]?.id === tableId && itemId !== selectedLevel1Item) {
                        return;
                    }
                    acc[tableKey][itemKey] = getInitialValuesForDataRow(parsedRow, tableMetadata);
                });

                return acc;
            }, {});

            return { ...initialValues, ...extraInitialValues };
        }
        return { ...extraInitialValues };
    }, [
        metadata,
        selectedLevel1Item,
        includeLevel2Values,
        hierarchicalTables,
        tableData,
        tablesWithChildrenMap,
        extraInitialValues,
    ]);

    useEffect(() => {
        if (!freezeInitialValues) {
            setInitialValues(memoizedInitialValues);
        }
    }, [freezeInitialValues, memoizedInitialValues]);

    return initialValues;
};

export default useAutoformFormInitialValues;
