const Input = {
    parts: ['field', 'addon', 'element'],

    variants: {
        filled: {
            field: {
                bg: 'gray.100',
                borderWidth: 1,
                _placeholder: {
                    color: 'gray.600',
                },
                _hover: {
                    bg: 'gray.100',
                    borderColor: 'gray.300',
                },
                _focus: {
                    borderColor: 'blue.400',
                    bg: 'white',
                },
                _invalid: {
                    color: 'red.900',
                    borderColor: 'red.900',
                },
                _disabled: {
                    bg: 'white',
                    borderColor: 'gray.200',
                    color: 'gray.500',
                    opacity: 1,
                    _placeholder: {
                        color: 'gray.500',
                    },
                },
                _readOnly: {
                    bg: 'white',
                    color: 'gray.500',
                    borderColor: 'gray.200',
                },
                _dark: {
                    color: 'white',
                    background: 'gray.800',

                    _placeholder: {
                        color: 'gray.500',
                    },

                    _invalid: {
                        color: 'red.800',
                        borderColor: 'red.800',
                    },

                    _hover: {
                        borderColor: 'gray.500',
                    },

                    _focus: {
                        borderColor: 'blue.400',
                    },

                    _disabled: {
                        borderColor: 'gray.600',
                        bg: 'black',
                        color: 'gray.600',
                        _placeholder: {
                            color: 'gray.600',
                        },
                    },
                },
            },

            element: {
                color: 'gray.600',

                _dark: {
                    color: 'gray.500',
                },
            },
        },
    },

    defaultProps: {
        variant: 'filled',
    },
};

export default Input;
