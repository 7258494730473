import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Heading,
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useMediaQuery,
} from '@chakra-ui/react';

const MainModal = ({
    size = '3xl',
    header,
    content,
    footerLeftSlot,
    footerRightSlot,
    secondaryButton,
    initialFocusRef,
    ...props
}) => {
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const defaultRef = useRef();

    return (
        <Modal initialFocusRef={initialFocusRef || defaultRef} {...props} size={isDesktop ? size : 'full'}>
            <ModalOverlay />

            <ModalContent pt={3} borderRadius={isDesktop ? '2xl' : 0}>
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3">
                        {header}
                    </Heading>
                </ModalHeader>

                <ModalCloseButton ref={defaultRef} mt={4} mr={3} h={6} w={6} />

                <ModalBody>{content}</ModalBody>

                <ModalFooter justifyContent="stretch" flexWrap="wrap">
                    {footerLeftSlot}

                    <Box ml="auto">
                        {secondaryButton || (
                            <Button variant="secondary" onClick={props.onClose}>
                                <Box as="span" textTransform="capitalize">
                                    <FormattedMessage id="common_cancel" />
                                </Box>
                            </Button>
                        )}

                        {footerRightSlot}
                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default MainModal;
