import { Textarea } from '@chakra-ui/react';

import FormField from './FormField';

// If you need to extend Textarea functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for Textarea should be excluded from ...props and manually added
const TextareaField = ({ value, onChange, placeholder, isWarning, ...props }) => {
    return (
        <FormField {...props}>
            <Textarea
                // zDefaultValue could be returned which means empty value
                value={value === 'zDefaultValue' ? '' : value}
                borderColor={isWarning ? 'orange.300' : undefined}
                _hover={isWarning ? { borderColor: 'orange.300' } : undefined}
                onChange={onChange}
                placeholder={placeholder}
            />
        </FormField>
    );
};

export default TextareaField;
