import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Flex, Spacer, useDisclosure } from '@chakra-ui/react';

import DeleteAllButton from '../itemData/DeleteAllButton';

import {
    getItemComponentLookup,
    getSystemComponentsForModel,
    createSystemComponents,
    updateSystemComponentCells,
    deleteSystemComponents,
} from '../../services/mainMarket';

import { formatMultisort } from '../../services/items';

import useCommonToast from '../../hooks/useCommonToast';

import MainModal from '../modal/MainModal';

import DataGrid from '../grid/DataGrid';
import DataGridWrapper from '../grid/DataGridWrapper';
import AddDataModal from '../grid/AddDataModal';

import SecondaryButton from '../utils/SecondaryButton';

import { ReactComponent as AddIcon } from '../../icons/add-item.svg';

const EditSystemComponentModal = ({ modelId, onClose }) => {
    const gridApi = useRef(null);
    const intl = useIntl();
    const { toast } = useCommonToast();
    const addDataModal = useDisclosure();

    const [dropdownOptions, setDropdownOptions] = useState({
        itemComponentLookups: [],
        loading: true,
        error: false,
    });

    const fetchDropdownOptions = useCallback(() => {
        return getItemComponentLookup()
            .then((data) => setDropdownOptions((prev) => ({ ...prev, itemComponentLookups: data })))
            .catch((err) => setDropdownOptions((prev) => ({ ...prev, error: true }))) ///s
            .finally(() => setDropdownOptions((prev) => ({ ...prev, loading: false })));
    }, []);

    useEffect(() => {
        fetchDropdownOptions();
    }, [fetchDropdownOptions]);

    const serversideDataSource = useMemo(() => {
        return {
            getRows: async (params) => {
                const pagination = {
                    paging: {
                        pageSize: 500,
                        page: params.request.startRow / 500 + 1,
                    },
                    sorting: formatMultisort(params.request.sortModel),
                };

                const response = await getSystemComponentsForModel(modelId, pagination);

                if (response.totalCount === 0) {
                    params.api.showNoRowsOverlay();
                } else {
                    params.api.hideOverlay();
                }

                params.success({ rowData: response.data, rowCount: response.totalCount });
            },
        };
    }, [modelId]);

    const refreshServerSideData = useCallback(() => {
        gridApi.current.api.refreshServerSideStore({
            purge: true,
        });
    }, []);

    const updateServerSideData = useCallback(
        async (rowIds) => {
            const rows = rowIds.map((id) => gridApi.current.api.getRowNode(id));

            if (rows.length === 0) return;
            await updateSystemComponentCells(modelId, rows);

            toast(intl.formatMessage({ id: 'main_market_system_component_on_success' }));
        },
        [modelId, intl, toast]
    );

    const onDeleteRow = useCallback(
        async (rowIds) => {
            const systemComponentIds = rowIds.map(
                (id) => gridApi.current?.api?.getRowNode(id)?.data?.systemComponentId
            );

            if (systemComponentIds.length === 0) return;

            await deleteSystemComponents(modelId, systemComponentIds);
            toast(intl.formatMessage({ id: 'common_delete_grid_msg' }, { n: systemComponentIds.length }));

            refreshServerSideData();
        },
        [intl, toast, modelId, refreshServerSideData]
    );

    const columns = useMemo(() => {
        return [
            {
                field: 'itemCompId',
                type: 'select',
                cellEditorParams: { options: dropdownOptions.itemComponentLookups, required: false },
                headerName: intl.formatMessage({ id: 'main_market_system_component_item_component' }),
                minWidth: 180,
            },
        ];
    }, [dropdownOptions.itemComponentLookups, intl]);

    const gridResource = useMemo(() => {
        return {
            create: (rows) => {
                return createSystemComponents(modelId, rows);
            },
        };
    }, [modelId]);

    const onAddRowsSuccess = useCallback(() => {
        toast(intl.formatMessage({ id: 'common_generic_item_change_success' }));
        refreshServerSideData();
    }, [toast, intl, refreshServerSideData]);

    return (
        <MainModal
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="main_market_system_component_modal_title" />}
            content={
                <Box my={4}>
                    <Flex mt={4} mb={12} wrap="wrap">
                        <Spacer display={{ base: 'none', sm: 'block' }} />

                        <DeleteAllButton
                            disabled={true}
                            subItemId={modelId}
                            onDelete={null}
                            refetchData={refreshServerSideData}
                            mb={{ base: 4, xl: 0 }}
                        />

                        <SecondaryButton
                            ml={4}
                            variant="secondary"
                            size="sm"
                            leftIcon={<AddIcon />}
                            onClick={addDataModal.onOpen}
                            textTransform="capitalize"
                        >
                            <FormattedMessage id="common_add_rows_btn" />
                        </SecondaryButton>
                    </Flex>

                    <DataGridWrapper>
                        <DataGrid
                            ref={gridApi}
                            serverSideDatasource={serversideDataSource}
                            onDataChange={updateServerSideData}
                            onDelete={onDeleteRow}
                            rowModelType="serverSide"
                            serverSideStoreType="partial"
                            paginationPageSize={500}
                            cacheBlockSize={500}
                            columns={columns}
                            addDataColumns={columns}
                            gridResource={gridResource}
                            onAddRowsSuccess={onAddRowsSuccess}
                        />
                    </DataGridWrapper>

                    {addDataModal.isOpen && (
                        <AddDataModal
                            isOpen
                            onClose={addDataModal.onClose}
                            columns={columns}
                            gridResource={gridResource}
                            onAddRowsSuccess={onAddRowsSuccess}
                            initialMode="basic"
                        />
                    )}
                </Box>
            }
            secondaryButton={<></>}
        />
    );
};

export default EditSystemComponentModal;
