import { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';

import { Formik, Form } from 'formik';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import { createPriceFormula, getPriceFormulaTypes, getPriceFormulas } from '../../services/priceFormula';

import SimpleGridSkeleton from '../../components/utils/SimpleGridSkeleton';
import Error from '../../components/utils/Error';

import ComboboxFormikField from '../forms/ComboboxFormikField';
import InputFormikField from '../forms/InputFormikField';
import { isSubmitDisabled } from '../../services/utils';

const AddPriceFormulaModal = ({ basisId, ...rest }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();

    const [priceFormulas, setPriceFormulas] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [formulaTypes, setFormulaTypes] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const fetchPriceFormulas = useCallback(() => {
        return getPriceFormulas()
            .then((data) =>
                setPriceFormulas((prev) => ({ ...prev, data: data.map((priceFormula) => priceFormula.description) }))
            )
            .catch(() => setPriceFormulas((prev) => ({ ...prev, error: true })))
            .finally(() => setPriceFormulas((prev) => ({ ...prev, loading: false })));
    }, []);

    const fetchFormulaTypes = useCallback(() => {
        return getPriceFormulaTypes()
            .then((data) => setFormulaTypes((prev) => ({ ...prev, data })))
            .catch(() => setFormulaTypes((prev) => ({ ...prev, error: true })))
            .finally(() => setFormulaTypes((prev) => ({ ...prev, loading: false })));
    }, []);

    useEffect(() => {
        fetchPriceFormulas();
        fetchFormulaTypes();
    }, [fetchPriceFormulas, fetchFormulaTypes]);

    const formLabels = {
        name: intl.formatMessage({ id: 'basis_configurator_price_formula_name' }),
        priceFormulaType: intl.formatMessage({ id: 'basis_configurator_price_formula_type' }),
    };

    const initialFormValues = {
        name: '',
        priceFormulaTypeId: '',
    };

    const onFormSubmit = async ({ name, priceFormulaTypeId }) => {
        const successMessage = intl.formatMessage({ id: 'price_formula_successful_creation' });

        const { data } = await createPriceFormula({
            description: name,
            priceFormulaTypeId,
        });

        const search = new URLSearchParams();
        search.append('basisId', basisId);
        search.append('mode', 'new');
        toast(successMessage);

        historyPush({
            pathname: `/price-formula/${data.priceFormulaId}`,
            search,
        });
    };

    const validateItemName = (value) => {
        const trimmed = value.trim();

        if (!trimmed) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Price Formula name' });
        }

        if (trimmed.length > 100) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Price Formula name', lengthRule: '100 characters or less' }
            );
        }

        const isExisting = priceFormulas.data.includes(value);

        if (isExisting) {
            return intl.formatMessage({ id: 'price_formula_name_validation' });
        }
    };

    const validateRequiredField = (value, label) => {
        let error;
        if (value === '') {
            error = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }
        return error;
    };

    return (
        <Modal {...rest}>
            <ModalOverlay />

            <ModalContent pt={3}>
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="basis_configurator_price_formula_create_new" />
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik enableReinitialize initialValues={initialFormValues} onSubmit={onFormSubmit}>
                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <ModalBody borderBottom="1px" borderColor="border-secondary">
                                {priceFormulas.loading || formulaTypes.loading ? (
                                    <SimpleGridSkeleton rows={9} cols={1} />
                                ) : priceFormulas.error || formulaTypes.error ? (
                                    <Box m={6}>
                                        <Error
                                            primaryId="common_error"
                                            secondaryId="common_loading_error"
                                            additionalText="formula price data"
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <InputFormikField
                                            name="name"
                                            type="text"
                                            label={formLabels.name}
                                            validate={validateItemName}
                                            isRequired
                                            isFastField
                                            mb={5}
                                        />

                                        <ComboboxFormikField
                                            id="priceFormulaTypeId"
                                            name="priceFormulaTypeId"
                                            label={formLabels.priceFormulaType}
                                            options={formulaTypes.data}
                                            validate={(value) =>
                                                validateRequiredField(value, formLabels.priceFormulaType)
                                            }
                                            labelKey="description"
                                            valueKey="id"
                                            showPlaceholder={false}
                                            isRequired
                                            isFastField
                                            mb={5}
                                        />
                                    </>
                                )}
                            </ModalBody>

                            <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                <Box ml="auto">
                                    <Button variant="secondary" onClick={rest.onClose}>
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id="common_cancel" />
                                        </Box>
                                    </Button>

                                    <Button
                                        type="submit"
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        ml={3}
                                        textTransform="capitalize"
                                    >
                                        <FormattedMessage id="common_continue" />
                                    </Button>
                                </Box>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default AddPriceFormulaModal;
