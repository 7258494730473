import axios from 'axios';

export const getItemTypes = async () => {
    const response = await axios.get('/realized-values/itemtypes');

    return response.data;
};

export const getItems = async (itemTypeId) => {
    const params = new URLSearchParams();

    params.append('itemTypeId', itemTypeId);

    const response = await axios.get('/realized-values/items', { params, suppressErrorMessageHandler: true });

    return response.data;
};

export const getItemComponents = async (itemId) => {
    const params = new URLSearchParams();

    params.append('itemId', itemId);

    const response = await axios.get('/realized-values/components', { params, suppressErrorMessageHandler: true });

    return response.data;
};

export const addMultipleTableRows = async ({ itemTypeId, itemId, itemComponentId, records }) => {
    const params = new URLSearchParams();

    params.append('itemTypeId', itemTypeId);
    params.append('itemId', itemId);
    params.append('itemComponentId', itemComponentId);

    const response = await axios.post('/realized-values/records/create/multiple', records, { params });

    return response.data;
};

export const importAutoformTables = async ({
    bookId,
    itemTypeId,
    itemId,
    itemComponentId,
    additionalConfig,
    treatWarningsAsErrors,
    sseClientId,
    file,
}) => {
    const formData = new FormData();
    formData.append('file', file);

    const params = new URLSearchParams();

    params.append('bookId', bookId);
    params.append('itemTypeId', itemTypeId);
    params.append('itemId', itemId);
    params.append('itemComponentId', itemComponentId);
    if (sseClientId) {
        params.append('sseClientId', sseClientId);
    }
    if (treatWarningsAsErrors) {
        params.append('treatWarningsAsErrors', treatWarningsAsErrors);
    }

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params,
        suppressErrorMessageHandler: true,
        ...additionalConfig,
    };

    let response = await axios.post('/realized-values/import', formData, config);

    return response.data;
};
