import { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Box } from '@chakra-ui/react';
import { getChartDataSeries } from 'services/chartViewer';

const getChartOptions = (spec) => {
    return {
        rangeSelector: {
            selected: 1,
        },
        title: {
            text: spec.title,
        },
        series: [],
    };
};

const getAllSeries = async (spec) => {
    let series = [];

    for (let i = 0; i < spec.seriesInfo.length; i++) {
        let ser = spec.seriesInfo[i];

        let dataSeries = await getChartDataSeries(spec.chartId, ser.seriesId);
        series.push({ name: `${ser.name}`, data: dataSeries });
    }

    return series;
};

const ChartItem = ({ spec }) => {
    const chartRef = useRef(null);
    const [options, setOptions] = useState(getChartOptions(spec));

    useEffect(() => {
        const fetchData = async () => {
            chartRef.current.chart.showLoading();
            return await getAllSeries(spec);
        };
        fetchData()
            .then((results) => setOptions({ title: { text: spec.title }, series: results }))
            .catch((err) => console.error(`fetchData error: ${err}`))
            .finally(() => chartRef.current.chart.hideLoading());
    }, [spec]);

    return (
        <Box minWidth={'46%'} maxWidth={'95%'}>
            <HighchartsReact ref={chartRef} highcharts={Highcharts} constructorType={'stockChart'} options={options} />
        </Box>
    );
};

export default ChartItem;
