import { ReactComponent as RightArrow } from '../../icons/right-arrow.svg';
import { ReactComponent as DownArrow } from '../../icons/arrow_drop_down.svg';

const PortfolioArrowIcon = ({ isExpanded, isMaster }) => {
    const Icon = isExpanded ? DownArrow : RightArrow;

    return <Icon role="presentation" className={`${isMaster ? 'master-node ' : ''}portfolio-label-icon arrow-icon`} />;
};

export default PortfolioArrowIcon;
