import { useState } from 'react';
import styled from '@emotion/styled/macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Text, Flex, Button, useDisclosure, IconButton } from '@chakra-ui/react';

import {
    getSubParameterButtonTitle,
    deleteSubParameter,
    updateParameterPeriodValue,
    isMainParameter,
    createParameterPeriod,
    checkParameterPeriodOverlap,
} from '../../../services/batteries';

import useCommonToast from '../../../hooks/useCommonToast';

import SubParameterKey from './SubParameterKey';
import AddSubParameterValueModal from './AddSubParameterValueModal';
import DeleteSubParameterModal from './DeleteSubParameterModal';
import ManageMainParameterPeriodModal from './ManageMainParameterPeriodModal';
import ManageSubParameterPeriodModal from './ManageSubParameterPeriodModal';
import PeriodOverlapModal from './PeriodOverlapModal';

import Popover from '../../utils/Popover';
import CustomIconButton from '../../utils/CustomIconButton';

import { ReactComponent as InfoIcon } from '../../../icons/info.svg';
import { ReactComponent as AddIcon } from '../../../icons/add.svg';
import { ReactComponent as AddItemIcon } from '../../../icons/item-icons/add-item.svg';

const ParameterKey = ({ parameter, batteryLevelUnit, subBatteryId, updateRibbonData, ribbonStaticData, metadata }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();

    const addSubParameterValue = useDisclosure();
    const deleteSubParameterValue = useDisclosure();
    const addMainParameterPeriodModal = useDisclosure();
    const addSubParameterPeriodModal = useDisclosure();
    const overlapConfirmationModal = useDisclosure();

    const [selectedForDeletion, setSelectedForDeletion] = useState(null);
    const [selectedMainParameter, setSelectedMainParameter] = useState({ dbField: null, parameterValues: null });
    const [selectedSubParameter, setSelectedSubParameter] = useState({ dbField: null, parameterValues: null });
    const [parameterPayload, setParameterPayload] = useState(null);

    const onAddMainParameterPeriodModalOpen = () => {
        setSelectedMainParameter({ dbField: parameterDbField, parameterValues: {} });
        addMainParameterPeriodModal.onOpen();
    };

    const onAddMainParameterPeriodModalClose = () => {
        addMainParameterPeriodModal.onClose();
        setSelectedMainParameter({ dbField: null, parameterValues: null });
    };

    const onAddSubParameterPeriodModalOpen = (param) => {
        setSelectedSubParameter({ dbField: parameter.parameterDbField, parameterValues: param });
        addSubParameterPeriodModal.onOpen();
    };

    const onAddSubParameterPeriodModalClose = () => {
        addSubParameterPeriodModal.onClose();
        setSelectedSubParameter({ dbField: null, parameterValues: null });
    };

    const onAddSubParameterValueModalOpen = () => {
        setSelectedSubParameter({ dbField: parameter.parameterDbField, parameterValues: parameter.subParameters });
        addSubParameterValue.onOpen();
    };

    const onAddSubParameterValueModalClose = () => {
        addSubParameterValue.onClose();
        setSelectedSubParameter({ dbField: null, parameterValues: null });
    };

    const onDeleteSubParameterModalOpen = (selectedData) => {
        setSelectedForDeletion(selectedData);
        deleteSubParameterValue.onOpen();
    };

    const onDeleteSubParameterConfirm = async (payload) => {
        const successMessage = intl.formatMessage({ id: 'batteries_timeline_delete_on_success' });

        deleteSubParameterValue.onClose();
        setSelectedForDeletion(null);

        await deleteSubParameter(subBatteryId, payload);
        await updateRibbonData();
        toast(successMessage);
    };

    const onSidebarValueChange = async (payload) => {
        const successMessage = intl.formatMessage({ id: 'batteries_timeline_modals_on_success' });

        await updateParameterPeriodValue(subBatteryId, payload);
        await updateRibbonData();
        toast(successMessage);
    };

    const checkOverlap = async (parameterValues, parentModalOnClose) => {
        setParameterPayload(parameterValues);

        const overlapResult = await checkParameterPeriodOverlap(subBatteryId, parameterValues);

        if (overlapResult.hasPeriodOverlap) {
            overlapConfirmationModal.onOpen();
        } else {
            onConfirmedPeriodCreation(parameterValues, false, parentModalOnClose);
        }
    };

    const onConfirmedPeriodCreation = async (payload, shouldCloseConfirmationModal = false, parentModalOnClose) => {
        const successMessage = intl.formatMessage({ id: 'batteries_timeline_modals_on_success' });

        try {
            await createParameterPeriod(subBatteryId, payload);

            if (shouldCloseConfirmationModal) {
                overlapConfirmationModal.onClose();
            }
            parentModalOnClose();

            toast(successMessage);
            updateRibbonData();
        } catch (err) {}
    };

    const { title, subParameters, parameterDbField, isRequired } = parameter;

    const transformedSubParameters = subParameters?.map((item, idx) => {
        return { ...item, id: idx, parameterDbField };
    });

    const subParameterButtonTitleId = getSubParameterButtonTitle(parameterDbField);

    const isMain = isMainParameter(parameterDbField);

    return (
        <div className={`${isMain ? 'parameter' : 'sub-parameter'}`}>
            <li>
                <div className="parameter-key-item">
                    <Flex alignItems="center">
                        <span className="title">{title}</span>

                        {isRequired && (
                            <Popover
                                strategy="fixed"
                                placement="bottom"
                                trigger={<StyledInfoIconButton icon={<InfoIcon />} />}
                            >
                                <FormattedMessage id="batteries_timeline_parameters_required_tooltip" />
                            </Popover>
                        )}
                    </Flex>

                    {isMain && subParameters.length === 0 && (
                        <IconButton
                            icon={<AddItemIcon />}
                            onClick={onAddMainParameterPeriodModalOpen}
                            variant="batteries-plus"
                        />
                    )}
                </div>

                {!isMain && (
                    <>
                        <StyledSubParameterKeys>
                            {transformedSubParameters.map((item) => (
                                <SubParameterKey
                                    key={item.id}
                                    parameter={item}
                                    batteryLevelUnit={batteryLevelUnit}
                                    totalSubParametersCount={transformedSubParameters.length}
                                    onDeleteSubParameterModalOpen={onDeleteSubParameterModalOpen}
                                    onSidebarValueChange={onSidebarValueChange}
                                    ribbonStaticData={ribbonStaticData}
                                    onAddSubParameterPeriodModalOpen={onAddSubParameterPeriodModalOpen}
                                />
                            ))}
                        </StyledSubParameterKeys>

                        <Flex>
                            <Button
                                mb="10px"
                                variant="batteries-add"
                                w="100%"
                                justifyContent="left"
                                leftIcon={<AddIcon />}
                                size="sm"
                                onClick={onAddSubParameterValueModalOpen}
                            >
                                <Text size="sm" textTransform="capitalize" color="inherit">
                                    <FormattedMessage id={subParameterButtonTitleId} />
                                </Text>
                            </Button>
                        </Flex>
                    </>
                )}

                {addSubParameterValue.isOpen && (
                    <AddSubParameterValueModal
                        isOpen
                        subBatteryId={subBatteryId}
                        selected={selectedSubParameter}
                        onClose={onAddSubParameterValueModalClose}
                        updateRibbonData={updateRibbonData}
                        ribbonStaticData={ribbonStaticData}
                        metadata={metadata}
                        checkOverlap={(parameters) => checkOverlap(parameters, addSubParameterValue.onClose)}
                        periodOverlapModal={
                            <PeriodOverlapModal
                                modal={overlapConfirmationModal}
                                onConfirmCallback={() =>
                                    onConfirmedPeriodCreation(parameterPayload, true, addSubParameterValue.onClose)
                                }
                            />
                        }
                    />
                )}

                {deleteSubParameterValue.isOpen && (
                    <DeleteSubParameterModal
                        onClose={deleteSubParameterValue.onClose}
                        headerMessageId="batteries_timeline_delete_heading"
                        contentMessageItemId="batteries_timeline_delete_content_message"
                        onConfirm={() => {
                            onDeleteSubParameterConfirm(selectedForDeletion);
                        }}
                    />
                )}

                {addMainParameterPeriodModal.isOpen && (
                    <ManageMainParameterPeriodModal
                        isOpen
                        subBatteryId={subBatteryId}
                        selectedParameter={selectedMainParameter}
                        onClose={onAddMainParameterPeriodModalClose}
                        updateRibbonData={updateRibbonData}
                        ribbonStaticData={ribbonStaticData}
                        modalTitleId="batteries_timeline_add_modal_heading"
                        btnTitleId="batteries_timeline_add_modal_save_btn"
                        checkOverlap={(parameters) => checkOverlap(parameters, addMainParameterPeriodModal.onClose)}
                        periodOverlapModal={
                            <PeriodOverlapModal
                                modal={overlapConfirmationModal}
                                onConfirmCallback={() =>
                                    onConfirmedPeriodCreation(
                                        parameterPayload,
                                        true,
                                        addMainParameterPeriodModal.onClose
                                    )
                                }
                            />
                        }
                    />
                )}

                {addSubParameterPeriodModal.isOpen && (
                    <ManageSubParameterPeriodModal
                        isOpen
                        subBatteryId={subBatteryId}
                        selectedParameter={selectedSubParameter}
                        onClose={onAddSubParameterPeriodModalClose}
                        updateRibbonData={updateRibbonData}
                        ribbonStaticData={ribbonStaticData}
                        modalTitleId="batteries_timeline_add_modal_heading"
                        btnTitleId="batteries_timeline_add_modal_save_btn"
                        checkOverlap={(parameters) => checkOverlap(parameters, addSubParameterPeriodModal.onClose)}
                        periodOverlapModal={
                            <PeriodOverlapModal
                                modal={overlapConfirmationModal}
                                onConfirmCallback={() =>
                                    onConfirmedPeriodCreation(
                                        parameterPayload,
                                        true,
                                        addSubParameterPeriodModal.onClose
                                    )
                                }
                            />
                        }
                    />
                )}
            </li>
        </div>
    );
};

const Sidebar = ({ data, batteryLevelUnit, subBatteryId, updateRibbonData, ribbonStaticData, metadata }) => {
    return (
        <ParametersWrapper>
            <ParameterKeys>
                {data.map((item) => (
                    <ParameterKey
                        key={item.id}
                        parameter={item}
                        batteryLevelUnit={batteryLevelUnit}
                        subBatteryId={subBatteryId}
                        updateRibbonData={updateRibbonData}
                        ribbonStaticData={ribbonStaticData}
                        metadata={metadata}
                    />
                ))}
            </ParameterKeys>
            <Box h="20px" />
        </ParametersWrapper>
    );
};

const ParametersWrapper = styled(Box)`
    position: relative;
    display: inline-block;
    vertical-align: top;
    background: var(--chakra-colors-background-octenary);
    width: 100%;
`;

const ParameterKeys = styled.ul`
    margin: 0;
    padding-left: 0;
    list-style: none;

    .parameter {
        height: 60px;
    }

    .sub-parameter {
        background: var(--chakra-colors-background-quaternary);
        margin: 0 4px;
        border-radius: 4px;
        margin-bottom: 10px;

        li {
            padding: 0;
            border-radius: 0px 0px 6px 6px;

            ul {
                padding: 5px 0 0;
            }
        }
    }

    li {
        padding: 0 5px;

        .parameter-key-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            text-align: left;
            background: var(--chakra-colors-background-primary);
            border: 1px solid var(--chakra-colors-border-secondary);
            border-radius: 6px;

            [data-popper-reference-hidden] {
                visibility: hidden !important;
                opacity: 0;
                z-index: -1 !important;
                pointer-events: none;
            }

            .title {
                margin: 0 8px;
                font-size: 14px;
            }
        }
    }
`;

const StyledSubParameterKeys = styled.ul`
    margin: 0;
    padding-left: 0;
    list-style: none;
    border-radius: 0px 0px 6px 6px;

    li {
        background: var(--chakra-colors-background-senary);
    }
`;

const StyledInfoIconButton = styled(CustomIconButton)`
    margin-top: 0px;
    margin-right: 10px;

    svg path {
        stroke: var(--chakra-colors-blue-400);
    }
    svg circle {
        stroke: var(--chakra-colors-blue-400);
    }
`;

export default Sidebar;
