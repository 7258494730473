import { useMemo, useCallback, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Flex, Heading, Tabs, TabPanels, TabPanel, TabList, Tab, useDisclosure } from '@chakra-ui/react';

import { getValidRowsWithDates } from '../../services/grid';
import {
    GRID_KEYS,
    getLoadDataChart,
    createLoadData,
    getLoadData,
    getLoadDataDataSourceView,
    updateLoadData,
    deleteLoadData,
    deleteAllLoadData,
    getLoadForecastChart,
    createLoadForecasts,
    getLoadForecast,
    getLoadForecastDataSourceView,
    updateLoadForecast,
    deleteLoadForecasts,
    deleteAllLoadForecasts,
    createLoadModels,
    getLoadModel,
    getLoadModelDataSourceView,
    updateLoadModel,
    deleteLoadModels,
    deleteAllLoadModels,
    validateLoadData,
    validateLoadForecasts,
    validateLoadModels,
} from '../../services/loads';
import { parseDataFilters, refreshMultisort, exportItemData } from '../../services/items';

import { SortOrderContext } from '../grid/utils/SortOrderContext';

import SubItemData from '../itemData/SubItemData';
import DownloadModal from '../utils/DownloadModal';
import UploadButton from '../itemData/UploadButton';
import SecondaryButton from '../../components/utils/SecondaryButton';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';

import Combobox from '../forms/Combobox';

import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { helpAnchors } from 'constants/help';

import { FEATURE_FLAG_NAMES, useIsFeatureActive } from '../../hooks/features-flags';

const SubLoadData = ({ itemLabel, subLoadId, itemId, filters, helpPageUrlItemTypeId = null }) => {
    const forecastTypes = useSelector((state) => state.forecast.types);
    const intl = useIntl();
    const downloadModal = useDisclosure();

    const { setSortOrder } = useContext(SortOrderContext);

    const [key, setKey] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loadingQueue, setLoadingQueue] = useState([]); // unique keys represeting which resource is in loading state
    const [forecastType, setForecastType] = useState(forecastTypes.find((type) => type.description === 'Energy').id);

    const isLoadModelVisible = useIsFeatureActive(FEATURE_FLAG_NAMES.LOAD_MODEL_TAB_VISIBLE);

    const refreshTabs = useCallback(() => {
        setKey((prev) => prev + 1);
        setSortOrder((prev) => ({ ...prev, ...refreshMultisort(Object.values(GRID_KEYS)) }));
    }, [setSortOrder]);

    const onLoadingStart = useCallback((key) => {
        setLoadingQueue((prev) => prev.concat(key));
    }, []);

    const onLoadingEnd = useCallback((key) => {
        setLoadingQueue((prev) => prev.filter((p) => p !== key));
    }, []);

    const loadDataChartResource = useMemo(() => {
        return {
            read: (pagination) => {
                const payload = {
                    pointCount: pagination.pointCount,
                    startDateFrom: pagination.startDateFrom || filters.startDateFrom,
                    startDateTo: pagination.startDateTo || filters.startDateTo,
                    updateDateFrom: filters.updateDateFrom,
                    updateDateTo: filters.updateDateTo,
                    filterType: filters.filterType,
                };

                return getLoadDataChart(subLoadId, payload);
            },
        };
    }, [subLoadId, filters]);

    const loadDataGridResource = useMemo(() => {
        return {
            create: (rows) => createLoadData(subLoadId, rows),
            read: (pagination) => getLoadData(subLoadId, { ...pagination, ...filters }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate', 'updateDateTime']);

                return updateLoadData(subLoadId, validRows);
            },
            delete: (rows) =>
                deleteLoadData(
                    subLoadId,
                    rows.map((row) => row.loadDataId)
                ),
            deleteAll: () => deleteAllLoadData(subLoadId),
            validate: (rows) => validateLoadData(subLoadId, rows),
            getDataSourceView: (pagination) => getLoadDataDataSourceView(subLoadId, { ...pagination, ...filters }),
        };
    }, [subLoadId, filters]);

    const loadForecastChartResource = useMemo(() => {
        return {
            read: (pagination) => {
                const payload = {
                    pointCount: pagination.pointCount,
                    startDateFrom: pagination.startDateFrom || filters.startDateFrom,
                    startDateTo: pagination.startDateTo || filters.startDateTo,
                    updateDateFrom: filters.updateDateFrom,
                    updateDateTo: filters.updateDateTo,
                    filterType: filters.filterType,
                    forecastTypeId: forecastType,
                };

                return getLoadForecastChart(subLoadId, payload);
            },
        };
    }, [subLoadId, filters, forecastType]);

    const loadForecastGridResource = useMemo(() => {
        return {
            create: (rows) => createLoadForecasts(subLoadId, rows),
            read: (pagination) => getLoadForecast(subLoadId, { ...pagination, ...filters }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate', 'updateDateTime']);

                return updateLoadForecast(subLoadId, validRows);
            },
            delete: (rows) =>
                deleteLoadForecasts(
                    subLoadId,
                    rows.map((row) => row.loadForecastId)
                ),
            deleteAll: () => deleteAllLoadForecasts(subLoadId),
            validate: (rows) => validateLoadForecasts(subLoadId, rows),
            getDataSourceView: (pagination) => getLoadForecastDataSourceView(subLoadId, { ...pagination, ...filters }),
        };
    }, [subLoadId, filters]);

    const loadModelGridResource = useMemo(() => {
        return {
            create: (rows) => createLoadModels(subLoadId, rows),
            read: (pagination) => getLoadModel(subLoadId, { ...pagination }),
            update: (rows) => updateLoadModel(subLoadId, rows),
            delete: (rows) =>
                deleteLoadModels(
                    subLoadId,
                    rows.map((row) => row.loadModelId)
                ),
            deleteAll: () => deleteAllLoadModels(subLoadId),
            validate: (rows) => validateLoadModels(subLoadId, rows),
            getDataSourceView: (pagination) => getLoadModelDataSourceView(subLoadId, { ...pagination }),
        };
    }, [subLoadId]);

    const loadDataUnitLabel = intl.formatMessage({ id: 'common_item_unit_label' }, { item: 'Load', unit: 'MWh' });

    const dataColumns = useMemo(
        () => [
            {
                type: 'date',
                field: 'startDate',
                headerName: intl.formatMessage({ id: 'common_grid_start_date' }),
                sort: 'asc',
            },
            {
                type: 'date',
                field: 'endDate',
                headerName: intl.formatMessage({ id: 'common_grid_end_date' }),
            },
            {
                field: 'load',
                type: 'number',
                headerName: loadDataUnitLabel,
            },
            {
                type: 'date',
                field: 'updateDateTime',
                headerName: intl.formatMessage({ id: 'common_grid_created' }),
                sort: 'asc',
            },
        ],
        [intl, loadDataUnitLabel]
    );

    const modelColumns = useMemo(
        () => [
            {
                type: 'checkbox',
                field: 'useFlag',
                headerName: intl.formatMessage({ id: 'common_grid_model_use' }),
            },
            {
                field: 'description',
                headerName: intl.formatMessage({ id: 'common_grid_model_description' }),
            },
            {
                field: 'formulaStatement',
                headerName: intl.formatMessage({ id: 'common_grid_model_formula' }),
            },
            {
                field: 'runOrder',
                type: 'number',
                headerName: intl.formatMessage({ id: 'common_grid_model_run_order' }),
            },
        ],
        [intl]
    );

    const forecastColumns = useMemo(
        () => [
            {
                field: 'forecastTypeId',
                type: 'select',
                cellEditorParams: {
                    options: forecastTypes,
                    constraints: forecastTypes.map((type) => type.description),
                    required: true,
                },
                headerName: intl.formatMessage({ id: 'common_grid_forecast_type' }),
            },
            {
                type: 'date',
                field: 'startDate',
                headerName: intl.formatMessage({ id: 'common_grid_start_date' }),
                sort: 'asc',
            },
            {
                type: 'date',
                field: 'endDate',
                headerName: intl.formatMessage({ id: 'common_grid_end_date' }),
            },
            {
                field: 'expectedEnergy',
                type: 'number',
                headerName: intl.formatMessage(
                    { id: 'common_item_unit_label' },
                    { item: 'Expected Energy', unit: 'MWh' }
                ),
            },
            {
                field: 'expectedPeakDemand',
                type: 'number',
                headerName: intl.formatMessage(
                    { id: 'common_item_unit_label' },
                    { item: 'Expected Peak Demand', unit: 'MW' }
                ),
            },
            {
                field: 'stdDevOfEnergy',
                type: 'number',
                headerName: intl.formatMessage({ id: 'common_grid_std_dev_of_energy' }),
            },
            {
                type: 'date',
                field: 'updateDateTime',
                headerName: intl.formatMessage({ id: 'common_grid_created' }),
                sort: 'asc',
            },
        ],
        [forecastTypes, intl]
    );

    const phrasing = useMemo(
        () => ({
            create: {
                header: <FormattedMessage id="common_add_rows_heading" />,
                content: <FormattedMessage id="common_add_rows_sub_heading_without_date" />,
            },
        }),
        []
    );

    const onDownloadData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const payload = {
                ...parseDataFilters(filters),
                forecastTypeId: forecastType,
                dataSorting: sortOrder[GRID_KEYS.loadData],
                forecastSorting: sortOrder[GRID_KEYS.loadForecast],
                modelsSorting: sortOrder[GRID_KEYS.loadModel],
                exportTemplate,
            };

            return exportItemData(`loads/${itemId}/export`, payload);
        },
        [itemId, forecastType, filters]
    );

    return (
        <Tabs
            px={0}
            mt={6}
            isLazy
            isManual
            lazyBehavior="keepMounted"
            key={key}
            index={selectedTab}
            onChange={setSelectedTab}
        >
            <TabList w="100%" flexWrap={{ base: 'wrap', xl: 'nowrap' }} alignItems="center">
                <Tab>
                    <FormattedMessage id="common_historical_data_data" />
                </Tab>

                <Tab>
                    <FormattedMessage id="common_historical_data_forecast" />
                </Tab>

                {isLoadModelVisible && (
                    <Tab>
                        <FormattedMessage id="common_historical_data_model" />
                    </Tab>
                )}

                <Flex ml="auto" order={{ base: -1, xl: 0 }} w={{ base: '100%', xl: 'auto' }}>
                    <SecondaryButton
                        leftIcon={<DownloadIcon />}
                        w="auto !important"
                        mr={2}
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={downloadModal.onOpen}
                    >
                        <FormattedMessage id="common_download_btn_tooltip_and_label" />
                    </SecondaryButton>

                    <UploadButton
                        type="loads"
                        itemId={itemId}
                        hasLabel={true}
                        onAfterUploadChange={refreshTabs}
                        mr={4}
                        w="auto !important"
                    />

                    <SecondaryButton
                        onClick={refreshTabs}
                        size="sm"
                        variant="secondary"
                        leftIcon={<RefreshIcon />}
                        isLoading={loadingQueue.length > 0}
                        loadingText={intl.formatMessage({ id: 'common_loading' })}
                        textTransform="capitalize"
                        ml="auto"
                    >
                        <FormattedMessage id="common_refresh" />
                    </SecondaryButton>
                </Flex>
            </TabList>

            <TabPanels mt={4}>
                <TabPanel>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="common_historical_data_heading" values={{ item: 'Load' }} />
                        <HelpButtonComponent helpAnchor={helpAnchors.LOAD_DATA} itemTypeId={helpPageUrlItemTypeId} />
                    </Heading>

                    <SubItemData
                        hasChart
                        tab="0"
                        name={GRID_KEYS.loadData}
                        yAxisLabel={loadDataUnitLabel}
                        chartResource={loadDataChartResource}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={loadDataGridResource}
                        columns={dataColumns}
                        addDataColumns={dataColumns}
                        primaryKeyName="loadDataId"
                    />
                </TabPanel>

                <TabPanel>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="common_historical_data_forecast_heading" values={{ item: 'Load' }} />
                        <HelpButtonComponent
                            helpAnchor={helpAnchors.LOAD_FORECAST}
                            itemTypeId={helpPageUrlItemTypeId}
                        />
                    </Heading>

                    <SubItemData
                        hasChart
                        actionBarSlot={(index) =>
                            index === 0 ? (
                                <Combobox
                                    width="var(--chakra-sizes-2xs) !important"
                                    options={forecastTypes}
                                    valueKey="id"
                                    labelKey="description"
                                    isControlled={false}
                                    onChange={setForecastType}
                                />
                            ) : null
                        }
                        tab="1"
                        name={GRID_KEYS.loadForecast}
                        yAxisLabel={loadDataUnitLabel}
                        chartResource={loadForecastChartResource}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={loadForecastGridResource}
                        columns={forecastColumns}
                        addDataColumns={forecastColumns}
                        primaryKeyName="loadForecastId"
                    />
                </TabPanel>

                <TabPanel>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="common_historical_data_model_heading" values={{ item: 'Load' }} />
                        <HelpButtonComponent helpAnchor={helpAnchors.LOAD_MODEL} itemTypeId={helpPageUrlItemTypeId} />
                    </Heading>

                    <SubItemData
                        tab="2"
                        name={GRID_KEYS.loadModel}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={loadModelGridResource}
                        phrasing={phrasing}
                        columns={modelColumns}
                        addDataColumns={modelColumns}
                        primaryKeyName="loadModelId"
                    />
                </TabPanel>
            </TabPanels>

            {downloadModal.isOpen && (
                <DownloadModal
                    isOpen
                    onClose={downloadModal.onClose}
                    onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                    onDownloadTemplate={() => onDownloadData(true)}
                />
            )}
        </Tabs>
    );
};

export default SubLoadData;
