import Autoform from '../components/autoform/Autoform';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Flex, Heading, Text } from '@chakra-ui/react';
import {
    addMultipleTableRows,
    getItemComponents,
    getItems,
    getItemTypes,
    importAutoformTables,
} from '../services/realizedValues';
import { setCustomParameters } from '../store/autoform/autoformSlice';
import useAutoformMetadata from '../components/autoform/hooks/useAutoformMetadata';
import useAutoformLoadMetadata from '../components/autoform/hooks/useAutoformLoadMetadata';
import useAutoformColumnDefinitions from '../components/autoform/hooks/useAutoformColumnDefinitions';
import LoadingModal from '../components/modal/LoadingModal';
import { FormattedMessage, useIntl } from 'react-intl';
import ComboboxField from '../components/forms/ComboboxField';
import LayoutWrapper from '../components/layout/LayoutWrapper';
import { exportAutoformTables } from '../services/autoforms';
import { Title } from 'react-head';
import { APP_NAME } from '../constants/autoforms';

const targetSchemaId = 'PSREALIZEDVALUES';

const RealizedValues = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [itemTypes, setItemTypes] = useState([]);
    const [selectedItemType, setSelectedItemType] = useState(null);

    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isItemsLoading, setIsItemsLoading] = useState(false);

    const [itemComponents, setItemComponents] = useState([]);
    const [selectedItemComponent, setSelectedItemComponent] = useState(null);
    const [isItemComponentsLoading, setIsItemComponentsLoading] = useState(false);
    const [addDataColumns, setAddDataColumns] = useState({});

    useAutoformLoadMetadata(targetSchemaId);
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const level1Table = hierarchicalTables?.level1?.[0];

    const bookId = useSelector((state) => state.connections.book);
    const tableId = level1Table?.id;
    const columnDefinitions = useAutoformColumnDefinitions(tableId);

    useEffect(() => {
        getItemTypes().then((data) => {
            setItemTypes(data);
            setSelectedItemType(null);
        });
    }, []);

    useEffect(() => {
        if (columnDefinitions && selectedItemComponent) {
            // Setting default values for read only columns so they are populated on Add Table Rows modal for visual consistency
            // Do not really need those defaults for Realized Values insert operation, end point picks up passed in parameters separately
            const columnsWithDefaultValues = columnDefinitions.map((col) => {
                let colWithDefault = col;
                const field = colWithDefault.field?.toUpperCase();
                if (field === 'ITEMTYPEID') {
                    colWithDefault.cellEditorParams.defaultValue = selectedItemType;
                }
                if (field === 'ITEMID') {
                    colWithDefault.cellEditorParams.defaultValue = selectedItem;
                }
                if (field === 'ITEMCOMPID') {
                    colWithDefault.cellEditorParams.defaultValue = selectedItemComponent;
                }

                return colWithDefault;
            });

            setAddDataColumns(columnsWithDefaultValues);
        }
    }, [columnDefinitions, selectedItemType, selectedItem, selectedItemComponent]);

    const handleItemTypeChange = useCallback((itemTypeId) => {
        setSelectedItemType(itemTypeId);
        setSelectedItem(null);
        setSelectedItemComponent(null);

        setItems([]);
        setItemComponents([]);

        setIsItemsLoading(true);
        getItems(itemTypeId)
            .then((itemsData) => {
                setItems(itemsData);
            })
            .finally(() => {
                setIsItemsLoading(false);
            });
    }, []);

    const handleItemsChange = useCallback((itemId) => {
        setSelectedItem(itemId);
        setSelectedItemComponent(null);

        setItemComponents([]);

        setIsItemComponentsLoading(true);
        getItemComponents(itemId)
            .then((itemComponentsData) => {
                setItemComponents(itemComponentsData);
            })
            .finally(() => {
                setIsItemComponentsLoading(false);
            });
    }, []);

    const handleItemComponentsPage = useCallback(
        (itemComponentId) => {
            setSelectedItemComponent(itemComponentId);

            // Subschema PSREALIZEDVALUES Parameters Required: BOOKID;ITEMTYPEID;ITEMID;ITEMCOMPID
            // Table REALIZEDVALUESTABLE Post SQL: WHERE ITEMTYPEID = {1} AND ITEMID = {2} AND ITEMCOMPID = {3}
            dispatch(setCustomParameters([selectedItemType, selectedItem, itemComponentId]));
        },
        [dispatch, selectedItemType, selectedItem]
    );

    const addTableRow = useCallback(
        ({ payload }) => {
            return addMultipleTableRows({
                itemTypeId: selectedItemType,
                itemId: selectedItem,
                itemComponentId: selectedItemComponent,
                records: payload.records,
            });
        },
        [selectedItemType, selectedItem, selectedItemComponent]
    );

    const importHandler = useCallback(
        (args) =>
            importAutoformTables({
                bookId,
                itemTypeId: selectedItemType,
                itemId: selectedItem,
                itemComponentId: selectedItemComponent,
                ...args,
            }),
        [bookId, selectedItemType, selectedItem, selectedItemComponent]
    );

    const exportHandler = useCallback(
        ({ isTemplateOnly, payload }) => {
            let selectedItemFileName = itemTypes?.find((item) => item.value === selectedItemType)?.text;
            selectedItemFileName =
                selectedItemFileName + '-' + items?.find((item) => item.value === selectedItem)?.text;
            selectedItemFileName =
                selectedItemFileName + '-' + itemComponents?.find((item) => item.value === selectedItemComponent)?.text;

            const fileName = isTemplateOnly ? metadata.formLabel : selectedItemFileName;

            return exportAutoformTables({ ...payload, fileName });
        },
        [selectedItemType, selectedItem, selectedItemComponent, itemTypes, items, itemComponents, metadata?.formLabel]
    );

    // Custom config, addDataColumns have extra default values for readonly fields
    const autoformConfig = useMemo(
        () => ({
            showSearchbar: false,
            hideLevel1AutoformHeader: true,
            gridWrapperHeight: '485px',
            addTableRow,
            importAutoformTables: importHandler,
            exportAutoformTables: exportHandler,
            addDataColumns,
        }),
        [addTableRow, importHandler, exportHandler, addDataColumns]
    );

    return (
        <>
            <Title>
                <FormattedMessage id="realized_values" /> - {APP_NAME}
            </Title>

            {metadata ? (
                <LayoutWrapper
                    content={
                        <>
                            <Flex pt={9} pl={6} pr={6} flexDirection="column">
                                <Heading as="h1" variant="h1" pb="18px">
                                    {metadata?.description}
                                </Heading>

                                <Text size="md" color="gray.800" fontWeight="normal" pb={6}>
                                    <FormattedMessage id="realized_values_hint" />
                                </Text>

                                <Flex>
                                    <ComboboxField
                                        label={<FormattedMessage id="realized_values_item_type" />}
                                        value={selectedItemType}
                                        onChange={handleItemTypeChange}
                                        options={itemTypes}
                                        valueKey="value"
                                        labelKey="text"
                                        showPlaceholder={true}
                                        placeholderValue={null}
                                        isControlled={true}
                                        mr={4}
                                    />

                                    <ComboboxField
                                        label={<FormattedMessage id="realized_values_items" />}
                                        value={selectedItem}
                                        onChange={handleItemsChange}
                                        options={items}
                                        valueKey="value"
                                        labelKey="text"
                                        showPlaceholder={true}
                                        placeholderValue={null}
                                        placeholderLabel={
                                            isItemsLoading ? intl.formatMessage({ id: 'common_loading' }) : undefined
                                        }
                                        isControlled={true}
                                        mr={4}
                                    />

                                    <ComboboxField
                                        label={<FormattedMessage id="realized_values_item_components" />}
                                        value={selectedItemComponent}
                                        onChange={handleItemComponentsPage}
                                        options={itemComponents}
                                        valueKey="value"
                                        labelKey="text"
                                        showPlaceholder={true}
                                        placeholderValue={null}
                                        placeholderLabel={
                                            isItemComponentsLoading
                                                ? intl.formatMessage({ id: 'common_loading' })
                                                : undefined
                                        }
                                        isControlled={true}
                                    />
                                </Flex>

                                {selectedItemComponent ? (
                                    <Divider orientation="horizontal" mt={6} mb="-12px" borderColor="gray.200" />
                                ) : null}
                            </Flex>

                            {selectedItemComponent ? <Autoform config={autoformConfig} /> : null}
                        </>
                    }
                />
            ) : (
                <LoadingModal header={<FormattedMessage id="common_loading" />} isOpen />
            )}
        </>
    );
};

export default RealizedValues;
