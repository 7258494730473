import { useState, useMemo } from 'react';

import styled from '@emotion/styled/macro';

import { Flex, Text, InputGroup, InputLeftElement, Input, InputRightElement, IconButton } from '@chakra-ui/react';

import { useIntl, FormattedMessage } from 'react-intl';

import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';

import Popover from '../utils/Popover';
import CustomIconButton from '../utils/CustomIconButton';
import MultiSelect from '../forms/MultiSelect';
import Combobox from '../forms/Combobox';

const RestorationInputExtract = ({ refreshGridData, statuses = [], additionalFilters }) => {
    const intl = useIntl();

    const [filterView, setFilterView] = useState(1);
    const [jobStatusFilter, setJobStatusFilter] = useState([]);
    const [portfolioNameFilter, setPortfolioNameFilter] = useState('');
    const [studyNameFilter, setStudyNameFilter] = useState('');

    const defaultFiltersValues = useMemo(
        () => ({
            statusIds: [],
            portfolioNameQuery: '',
            studyNameQuery: '',
        }),
        []
    );

    const onFilterViewChange = (val) => {
        if (val === 1) {
            if (portfolioNameFilter) setPortfolioNameFilter('');
            if (studyNameFilter) setStudyNameFilter('');
        } else if (val === 2) {
            if (jobStatusFilter) setJobStatusFilter([]);
            if (studyNameFilter) setStudyNameFilter('');
        } else if (val === 3) {
            if (jobStatusFilter) setJobStatusFilter([]);
            if (portfolioNameFilter) setPortfolioNameFilter('');
        }

        setFilterView(val);
        additionalFilters.current = defaultFiltersValues;

        refreshGridData();
    };

    return (
        <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            pl={2}
            maxWidth="650px"
        >
            <Flex pr={4}>
                <Popover
                    placement="top"
                    trigger={<CustomIconButton mt="0px !important" mr="5px !important" icon={<InfoIcon />} />}
                >
                    <FormattedMessage id="restoration_input_extract_tracker_filter_info" />
                </Popover>
                <Text minWidth="fit-content" fontSize={14} textAlign="center" mr={2}>
                    <FormattedMessage id="restoration_input_extract_tracker_search_by" />
                </Text>
            </Flex>

            <InputGroup>
                <StyledInputLeftElement
                    children={
                        <Combobox
                            options={[
                                {
                                    label: intl.formatMessage({
                                        id: 'restoration_input_extract_tracker_job_status',
                                    }),
                                    value: 1,
                                },
                                {
                                    label: intl.formatMessage({
                                        id: 'restoration_input_extract_tracker_portfolio_name',
                                    }),
                                    value: 2,
                                },
                                {
                                    label: intl.formatMessage({
                                        id: 'restoration_input_extract_tracker_study_name',
                                    }),
                                    value: 3,
                                },
                            ]}
                            valueKey="value"
                            labelKey="label"
                            value={filterView}
                            onChange={(val) => {
                                onFilterViewChange(val);
                            }}
                        />
                    }
                />

                {filterView === 1 && (
                    <MultiSelect
                        placeholderLabel={intl.formatMessage({
                            id: 'restoration_input_extract_tracker_job_status_search_placeholder',
                        })}
                        multipleSelectedLabel={intl.formatMessage({
                            id: 'run_a_study_multiple_selected_placeholder',
                        })}
                        options={statuses}
                        value={jobStatusFilter}
                        valueKey="id"
                        labelKey="description"
                        onChange={(value) => {
                            setJobStatusFilter(value);
                            additionalFilters.current = { ...additionalFilters.current, statusIds: value };
                        }}
                        minWidth={{ xl: '540px' }}
                        maxWidth={{ '2xl': '600px' }}
                        pl="180px"
                        pr="40px"
                        bg="background-quaternary"
                        borderRadius="var(--chakra-radii-md)"
                    />
                )}

                {filterView === 2 && (
                    <StyledInput
                        type="text"
                        value={portfolioNameFilter}
                        onChange={(e) => {
                            setPortfolioNameFilter(e.target.value);
                            additionalFilters.current = {
                                ...additionalFilters.current,
                                portfolioNameQuery: e.target.value,
                            };
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                refreshGridData();
                            }
                        }}
                        maxWidth={{ '2xl': '600px' }}
                        placeholder={intl.formatMessage({
                            id: 'restoration_input_extract_tracker_portfolio_name_search_placeholder',
                        })}
                    />
                )}

                {filterView === 3 && (
                    <StyledInput
                        type="text"
                        value={studyNameFilter}
                        onChange={(e) => {
                            setStudyNameFilter(e.target.value);
                            additionalFilters.current = {
                                ...additionalFilters.current,
                                studyNameQuery: e.target.value,
                            };
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                refreshGridData();
                            }
                        }}
                        maxWidth={{ '2xl': '600px' }}
                        placeholder={intl.formatMessage({
                            id: 'restoration_input_extract_tracker_study_name_search_placeholder',
                        })}
                    />
                )}

                <InputRightElement
                    children={
                        <StyledIconButton
                            onClick={() => refreshGridData()}
                            variant="ghost"
                            aria-label={intl.formatMessage({
                                id: 'restoration_input_extract_tracker_search_btn_aria_label',
                            })}
                            icon={<SearchIcon />}
                        />
                    }
                />
            </InputGroup>
        </Flex>
    );
};

const StyledInputLeftElement = styled(InputLeftElement)`
    left: 1px;
    width: 175px;
    input {
        height: var(--chakra-sizes-9);
    }
`;

const StyledInput = styled(Input)`
    padding-left: 180px;
    padding-right: 40px;
`;

const StyledIconButton = styled(IconButton)`
    &:hover {
        background: none;
        svg path {
            fill: var(--chakra-colors-blue-400);
        }
    }
`;

export default RestorationInputExtract;
