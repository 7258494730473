import { Field, FastField } from 'formik';
import _ from 'lodash';

import FormField from './FormField';
import Combobox from './Combobox';
import { useCallback, useState } from 'react';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { useDisclosure } from '@chakra-ui/react';

// If you need to extend Combobox functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for Combobox should be excluded from ...props and manually added
const FormFieldComponent = ({
    field,
    meta,
    form,
    options,
    valueKey,
    labelKey,
    onChange,
    onBlur,
    showPlaceholder,
    placeholderValue,
    placeholderLabel,
    isDisabled,
    isControlled,
    hasDivider,
    validateOnBlur = true,
    requireConfirmation = false,
    confirmationConfig = undefined,
    additionalContent = undefined,
    showPlaceholderIfDisabled,
    placement,
    strategy,
    modifiers,
    isRequired,
    ...props
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [tempSelection, setTempSelection] = useState();
    const status = _.get(form.status, field.name);
    const isInvalid = meta.touched && (meta.error || status?.errors?.length > 0);
    const isWarning = status?.warnings?.length > 0;

    const getFieldValue = useCallback(
        (field) => {
            let value = placeholderValue;

            if (!showPlaceholderIfDisabled || !isDisabled || !placeholderLabel || !showPlaceholder) {
                value = field.value;
            }

            return value;
        },
        [showPlaceholderIfDisabled, isDisabled, placeholderValue, placeholderLabel, showPlaceholder]
    );

    const handleConfirm = async () => {
        setLoading(true);
        form.setFieldValue(field.name, tempSelection);
        form.setStatus(_.omit(form.status, field.name));
        // Additional onChange logic could be hooked
        if (onChange) {
            await onChange(tempSelection);
        }
        setLoading(false);
        onClose();
    };

    return (
        <>
            <FormField
                name={field.name}
                isInvalid={isInvalid}
                isDisabled={isDisabled}
                error={meta.error}
                status={status}
                isRequired={isRequired}
                {...props}
            >
                <Combobox
                    {...field}
                    onChange={(val) => {
                        if (requireConfirmation) {
                            setTempSelection(val);
                            onOpen();
                        } else {
                            form.setFieldValue(field.name, val);
                            form.setStatus(_.omit(form.status, field.name));
                            // Additional onChange logic could be hooked
                            if (onChange) {
                                onChange(val);
                            }
                        }
                    }}
                    onBlur={() => {
                        form.setFieldTouched(field.name, true, validateOnBlur);

                        // Additional onBlur logic could be hooked
                        if (onBlur) {
                            onBlur();
                        }
                    }}
                    options={options}
                    value={getFieldValue(field)}
                    valueKey={valueKey}
                    labelKey={labelKey}
                    isInvalid={isInvalid}
                    isWarning={isWarning}
                    isDisabled={isDisabled}
                    showPlaceholder={showPlaceholder || field.value == null}
                    placeholderValue={placeholderValue}
                    placeholderLabel={placeholderLabel}
                    isControlled={isControlled}
                    hasDivider={hasDivider}
                    placement={placement}
                    strategy={strategy}
                    modifiers={modifiers}
                    isRequired={isRequired}
                />
            </FormField>
            {requireConfirmation && isOpen && (
                <ConfirmationModal
                    hasExtraStep
                    isLoading={loading}
                    isOpen={isOpen}
                    onClose={onClose}
                    onConfirm={handleConfirm}
                    header={confirmationConfig?.header}
                    content={confirmationConfig?.content}
                    additionalContent={confirmationConfig?.additionalContent}
                    confirmText={confirmationConfig?.confirmText}
                />
            )}
        </>
    );
};

const ComboboxFormikField = ({
    name,
    validate,
    confirmationConfig,
    isFastField = false,
    requireConfirmation = false,
    ...props
}) => (
    <>
        {isFastField ? (
            <FastField name={name} validate={validate}>
                {({ field, meta, form }) => (
                    <FormFieldComponent
                        requireConfirmation={requireConfirmation}
                        confirmationConfig={confirmationConfig}
                        field={field}
                        meta={meta}
                        form={form}
                        {...props}
                    />
                )}
            </FastField>
        ) : (
            <Field name={name} validate={validate}>
                {({ field, meta, form }) => (
                    <FormFieldComponent
                        requireConfirmation={requireConfirmation}
                        confirmationConfig={confirmationConfig}
                        field={field}
                        meta={meta}
                        form={form}
                        {...props}
                    />
                )}
            </Field>
        )}
    </>
);

export default ComboboxFormikField;
