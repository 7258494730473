export const DATA_TYPES = {
    Double: 'System.Double',
    Int: 'System.Int32',
    String: 'System.String',
    DateTime: 'System.DateTime',
};
export const INPUT_TYPES = {
    DropDownForm: 'DropDownForm',
    TextBox: 'TextBox',
    ComboBox: 'ComboBox',
    MonthCalendar: 'MonthCalendar',
    CheckBox: 'CheckBox',
    NumericUpDown: 'NumericUpDown',
    ProgressBar: 'ProgressBar',
    CheckListBox: 'CheckListBox',
    LinkLabel: 'LinkLabel',
    DownloadButton: 'DownloadButton',
};

export const INPUT_FORMATS = {
    c: 'c',
    D: 'D',
    d: 'd',
    G: 'G',
    g: 'g',
    f: 'f',
    F: 'F',
    m: 'm',
    n: 'n',
    p: 'p',
    s: 's',
    T: 'T',
    t: 't',
    u: 'u',
    U: 'U',
    y: 'y',
    Percent: 'Percent',
    // check and add int.32 format
};

export const DATE_FORMATS = {
    [INPUT_FORMATS.D]: 'PPPP', // Wednesday, March 12, 2008
    [INPUT_FORMATS.d]: 'P', // 3/12/2008
    [INPUT_FORMATS.G]: undefined, // Default for app
    [INPUT_FORMATS.g]: 'Pp', // 3/12/2008 11:07 AM
    [INPUT_FORMATS.f]: 'PPPPp',
    [INPUT_FORMATS.F]: 'PPPPpp',
    [INPUT_FORMATS.m]: 'LLLL d',
    [INPUT_FORMATS.s]: "y-MM-dd'T'HH:mm:ss",
    [INPUT_FORMATS.T]: 'pp',
    [INPUT_FORMATS.t]: 'p',
    [INPUT_FORMATS.u]: "y-MM-dd HH:mm:ss'Z'",
    [INPUT_FORMATS.U]: 'PPPPpp',
    [INPUT_FORMATS.y]: 'LLLL, y',
};

export const NUMBER_FORMATS = {
    [INPUT_FORMATS.Percent]: 'percent',
    [INPUT_FORMATS.c]: 'currency',
};

export const COLUMN_TYPES = {
    [INPUT_TYPES.DropDownForm]: undefined, //'TODO',
    [INPUT_TYPES.TextBox]: 'text',
    [INPUT_TYPES.ComboBox]: 'select',
    [INPUT_TYPES.MonthCalendar]: 'date',
    [INPUT_TYPES.CheckBox]: 'checkbox',
    [INPUT_TYPES.NumericUpDown]: 'number',
    [INPUT_TYPES.ProgressBar]: 'progressBar',
};

export const DATE_FORMATS_WITHOUT_TIME = [INPUT_FORMATS.D, INPUT_FORMATS.d, INPUT_FORMATS.m, INPUT_FORMATS.y];
