import { Flex } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import useHistoryPush from './../../hooks/useHistoryPush';

import SecondaryButton from '../utils/SecondaryButton';
import ComboboxField from '../forms/ComboboxField';
import SectionHint from './SectionHint';

const PriceFormulaSection = ({ options, basis, updateBasis }) => {
    const intl = useIntl();
    const historyPush = useHistoryPush();

    const onPriceFormulaEdit = () => {
        const search = new URLSearchParams();
        search.append('basisId', basis.basisId);

        historyPush({
            pathname: `/price-formula/${basis.priceFormulaId}`,
            search,
        });
    };

    return (
        <>
            <SectionHint hintHeader={'hint_heading_price_formula'} hintText={'hint_text_price_formula'} />

            <Flex
                align="flex-end"
                gridGap={2}
                flexDirection={{ base: 'column', sm: 'row' }}
                maxW={{ base: '100%', lg: '50%' }}
            >
                <ComboboxField
                    id="priceFormulaId"
                    name="priceFormulaId"
                    options={options.priceFormula}
                    valueKey="id"
                    labelKey="description"
                    value={basis.priceFormulaId}
                    onChange={(value) => updateBasis({ ...basis, priceFormulaId: value })}
                    label={intl.formatMessage({ id: 'basis_price_formula' })}
                    showPlaceholder={true}
                    placement="top"
                />

                <SecondaryButton
                    size="sm"
                    type="button"
                    variant="secondary"
                    onClick={onPriceFormulaEdit}
                    isDisabled={!basis.priceFormulaId}
                    w={{ sm: 'auto' }}
                >
                    <FormattedMessage id="basis_configurator_view_edit" />
                </SecondaryButton>
            </Flex>
        </>
    );
};

export default PriceFormulaSection;
