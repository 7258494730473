// Groups that have only one layer and do not need to have a drop down selection
export const SUBSTATIONS_GROUP = 'SUBSTATIONS';
export const IQ_GROUP = 'Interconnection Queue';
export const ELECTRIC_RETAIL_SERVICE_TERRITORIES_GROUP = 'ELECTRIC RETAIL SERVICE TERRITORIES';
export const RETIRED_GENERATORS_GROUP = 'RETIRED GENERATORS';
export const EIA_PLANNED_GENERATORS_GROUP = 'EIA PLANNED GENERATORS';
export const ELECTRIC_LOAD_GROUP = '2016 County Electric Load Estimates';
export const USA_MEDIAN_HOME_VALUE_GROUP = '2022 USA Median Home Value';
export const POPULATION_DENSITY_GROUP = 'Population Density';

// Groups that have multiple layers
export const ISO_LAYER_GROUP = 'ISO Market Points';
export const IRA_LAYER_GROUP = 'IRA Locational Incentives';
export const PLANNED_STORAGE_LAYER_GROUP = 'Solar & Storage Project Pipeline';
export const POWERPLANTS_LAYER_GROUP = 'Power Plants';
export const TRANSMISSION_LINES_LAYER_GROUP = 'Transmission Lines';
export const PLANNED_RETIREMENTS_GROUP = 'Planned Retirements';
export const ISO_FORECASTING_SUBREGIONS_GROUP = 'Ascend ISO Forecasting Subregions';

// If we have multiple layers in the group we need to provide a way to find them by title
export const allISO = ['CAISO', 'CAISO w/ EIM', 'ERCOT', 'ISO-NE', 'MISO', 'NYISO', 'PJM', 'SPP'];

export const allIRA = 'IRA -';

export const allPowerplants = [
    'Battery Storage Plants - USA (EIA)',
    'Biomass Plants - USA (EIA)',
    'Coal-based Power Plants - USA (EIA)',
    'Geothermal Power Plants - USA (EIA)',
    'Hydroelectric Power Plants - USA (EIA)',
    'Natural Gas Power Plants - USA (EIA)',
    'Nuclear Power Plants - USA (EIA)',
    'Petroleum-based Power Plants - USA (EIA)',
    'Pumped-storage Power Plants - USA (EIA)',
    'Solar-based Power Plants - USA (EIA)',
    'Wind-based Power Plants - USA (EIA)',
    'Other Power Plants - USA (EIA)',
];

export const allPlannedStorage = [
    'Planned Storage & Solar Projects (Construction)',
    'Planned Storage & Solar Projects (Contracted)',
    'Planned Storage & Solar Projects (Operational)',
    'Planned Storage & Solar Projects (Pipeline/Proposed)',
];

export const allTransmissionLines = [
    'Transmission Lines (Under 200kV)',
    'Transmission Lines (200 - 499kV)',
    'Transmission Lines (500kV And Above)',
];

export const allPlannedRetirements = 'Retiring in';

export const allISOSubregions = [
    'CAISO Subregions',
    'ERCOT Subregions',
    'ISO-NE Subregions',
    'MISO Subregions',
    'NYISO Subregions',
    'PJM Subregions',
    'SPP Subregions',
];

/** 
    This is used to group and display layers in the Features Sidebar Layers tab
    to allow user to select or unselect a layer to be visible or hidden in the map view. 
    Some layers are stand alone and are not under any group. 
    powerSimmCustomLayer.layerOrder = -1 is hardcoded to be displayed as the first layer in Features Sidebar Layers tab. 
    Note: this is not used to actually order layers to be shown the map view itself.  
*/
export const getLayerFeaturesSidebarOrder = (title) => {
    const featuresSidebarDisplayOrderArr = [
        ISO_LAYER_GROUP,
        ISO_FORECASTING_SUBREGIONS_GROUP,
        IRA_LAYER_GROUP,
        SUBSTATIONS_GROUP,
        TRANSMISSION_LINES_LAYER_GROUP,
        IQ_GROUP,
        POWERPLANTS_LAYER_GROUP,
        PLANNED_RETIREMENTS_GROUP,
        PLANNED_STORAGE_LAYER_GROUP,
        ELECTRIC_RETAIL_SERVICE_TERRITORIES_GROUP,
        RETIRED_GENERATORS_GROUP,
        EIA_PLANNED_GENERATORS_GROUP,
        ELECTRIC_LOAD_GROUP, // currently '2016 County Electric Load Estimates' layer,
        USA_MEDIAN_HOME_VALUE_GROUP,
        POPULATION_DENSITY_GROUP,
    ];

    const order = featuresSidebarDisplayOrderArr.findIndex((elem) => title?.toUpperCase().includes(elem.toUpperCase()));
    return order > -1 ? order : 100;
};

export const AUTOCREATE_BASIS_ACTION_ID = 'autocreate-basis';

export const LAYERS_GROUPS = [
    {
        id: 'powerplant_node',
        layerOrder: getLayerFeaturesSidebarOrder(POWERPLANTS_LAYER_GROUP),
        childrenNodes: [],
        title: POWERPLANTS_LAYER_GROUP,
        fakeNode: true,
        minScale: 12000000,
        maxScale: 0,
        url: '',
        visible: false,
    },
    {
        id: 'iso_node',
        layerOrder: getLayerFeaturesSidebarOrder(ISO_LAYER_GROUP),
        childrenNodes: [],
        title: ISO_LAYER_GROUP,
        fakeNode: true,
        minScale: 0,
        maxScale: 0,
        url: '',
        visible: true,
    },
    {
        id: 'planned_storage_node',
        layerOrder: getLayerFeaturesSidebarOrder(PLANNED_STORAGE_LAYER_GROUP),
        childrenNodes: [],
        title: PLANNED_STORAGE_LAYER_GROUP,
        fakeNode: true,
        minScale: 0,
        maxScale: 0,
        url: '',
        visible: false,
    },
    {
        id: 'ira_node',
        layerOrder: getLayerFeaturesSidebarOrder(IRA_LAYER_GROUP),
        childrenNodes: [],
        title: IRA_LAYER_GROUP,
        fakeNode: true,
        minScale: 0,
        maxScale: 0,
        url: '',
        visible: false,
    },
    {
        id: 'transmission_lines_node',
        layerOrder: getLayerFeaturesSidebarOrder(TRANSMISSION_LINES_LAYER_GROUP),
        childrenNodes: [],
        title: TRANSMISSION_LINES_LAYER_GROUP,
        fakeNode: true,
        minScale: 0,
        maxScale: 0,
        url: '',
        visible: false,
    },
    {
        id: 'planned_retirements_node',
        layerOrder: getLayerFeaturesSidebarOrder(PLANNED_RETIREMENTS_GROUP),
        childrenNodes: [],
        title: PLANNED_RETIREMENTS_GROUP,
        fakeNode: true,
        minScale: 0,
        maxScale: 0,
        url: '',
        visible: false,
    },
    {
        id: 'forecasting_subregions_node',
        layerOrder: getLayerFeaturesSidebarOrder(ISO_FORECASTING_SUBREGIONS_GROUP),
        childrenNodes: [],
        title: ISO_FORECASTING_SUBREGIONS_GROUP,
        fakeNode: true,
        minScale: 0,
        maxScale: 0,
        url: '',
        visible: false,
    },
];
