import { useIntl } from 'react-intl';
import { SimpleGrid } from '@chakra-ui/react';

import ComboboxField from '../forms/ComboboxField';
import SectionHint from './SectionHint';

const PeakPeriodSection = ({ options, basis, updateBasis, status }) => {
    const intl = useIntl();

    const onPeakPeriodOptionChanged = async (field, value) => {
        await updateBasis({ ...basis, [field]: value });
    };

    const onPeakPeriodStatus = status['onPeakPeriodId'];
    const offPeakPeriodStatus = status['offPeakPeriodId'];
    const atcPeriodStatus = status['atcPeriodId'];

    return (
        <>
            <SectionHint hintHeader={'hint_heading_peak_period'} hintText={'hint_text_peak_period'} />

            <SimpleGrid pb={5} px={{ base: 5, sm: 0 }} columns={{ md: 3 }} spacingX={6}>
                <ComboboxField
                    id="onPeakPeriodId"
                    name="onPeakPeriodId"
                    options={options.peakPeriods}
                    valueKey="id"
                    labelKey="description"
                    value={basis.onPeakPeriodId}
                    onChange={(value) => onPeakPeriodOptionChanged('onPeakPeriodId', value)}
                    label={intl.formatMessage({ id: 'basis_tabs_on_peak' })}
                    showPlaceholder={true}
                    isInvalid={onPeakPeriodStatus?.errors.length}
                    isWarning={onPeakPeriodStatus?.warnings.length}
                    status={onPeakPeriodStatus}
                />

                <ComboboxField
                    id="offPeakPeriodId"
                    name="offPeakPeriodId"
                    options={options.peakPeriods}
                    valueKey="id"
                    labelKey="description"
                    value={basis.offPeakPeriodId}
                    onChange={(value) => onPeakPeriodOptionChanged('offPeakPeriodId', value)}
                    label={intl.formatMessage({ id: 'basis_tabs_off_peak' })}
                    showPlaceholder={true}
                    isInvalid={offPeakPeriodStatus?.errors.length}
                    isWarning={offPeakPeriodStatus?.warnings.length}
                    status={offPeakPeriodStatus}
                />

                <ComboboxField
                    id="atcPeakPeriodId"
                    name="atcPeakPeriodId"
                    options={options.peakPeriods}
                    valueKey="id"
                    labelKey="description"
                    value={basis.atcPeriodId}
                    onChange={(value) => onPeakPeriodOptionChanged('atcPeriodId', value)}
                    label={intl.formatMessage({ id: 'basis_tabs_atc_peak' })}
                    showPlaceholder={true}
                    isInvalid={atcPeriodStatus?.errors.length}
                    isWarning={atcPeriodStatus?.warnings.length}
                    status={atcPeriodStatus}
                />
            </SimpleGrid>
        </>
    );
};

export default PeakPeriodSection;
