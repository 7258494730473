import { Alert, AlertIcon, AlertTitle, AlertDescription, Box } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

const Error = ({ primaryId, secondaryId, additionalText, ...rest }) => {
    return (
        <Alert status="error" _dark={{ background: 'red.100' }} {...rest}>
            <AlertIcon _dark={{ color: 'red.500' }} />

            <Box>
                {primaryId && (
                    <AlertTitle textTransform="capitalize">
                        <FormattedMessage id={primaryId} />
                    </AlertTitle>
                )}

                <AlertDescription>
                    <FormattedMessage id={secondaryId} values={{ items: additionalText }} />
                </AlertDescription>
            </Box>
        </Alert>
    );
};

export default Error;
