import { Box, useColorMode } from '@chakra-ui/react';
import { getStudyStatusStyle } from '../../services/studies';

const StudyTrackerName = ({ value, data, valueFormatted }) => {
    const { colorMode } = useColorMode();

    const status = getStudyStatusStyle(data.status);

    return (
        <Box color={colorMode === 'dark' ? status.style.darkColor : status.style.color}>
            {valueFormatted ? valueFormatted : value}
        </Box>
    );
};

export default StudyTrackerName;
