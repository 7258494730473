import { Heading, Icon, Flex, Text, Button, Box, useStyleConfig } from '@chakra-ui/react';
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { FormattedMessage } from 'react-intl';

const ConnectionOption = ({ title, subtitle, icon, isSelected, onClick }) => {
    const cardStyles = useStyleConfig('Card');

    return (
        <Wrapper __css={cardStyles} isSelected={isSelected}>
            {!!icon && (
                <OptionIcon className="connection-option-icon">
                    <Icon boxSize="2em" as={icon} />
                </OptionIcon>
            )}

            <Flex flexDirection="column" mt={icon ? 4 : 8}>
                <Heading className="connection-option-title" as="h3" variant="h3" align="center">
                    {title}
                </Heading>

                <Text className="connection-option-subtitle" variant="regular" textAlign="center" size="xs" mt={1}>
                    {subtitle}
                </Text>
            </Flex>

            <Button maxW={40} isFullWidth mt="auto" mb={0} onClick={onClick} textTransform="capitalize">
                <FormattedMessage id="common_select" />
            </Button>
        </Wrapper>
    );
};

const Hover = css`
    box-shadow: var(--chakra-shadows-lg);

    .connection-option-title {
        color: var(--chakra-colors-blue-400);
    }

    .connection-option-subtitle {
        opacity: 1;
    }
`;

const Wrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'isSelected' })`
    min-height: 370px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
        ${Hover}
    }

    ${(props) => (props.isSelected ? Hover : '')}
`;

const OptionIcon = styled.span`
    flex: 0 0 auto;
    width: 88px;
    height: 88px;
    border-radius: var(--chakra-radii-full);
    background: var(--chakra-colors-gray-100);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--chakra-colors-blue-500);
    margin-top: 8px;
`;

export default ConnectionOption;
