import { ReactComponent as DocumentIcon } from '../../icons/item-icons/document.svg';
import { ReactComponent as GenerationAssetIcon } from '../../icons/item-icons/generation-asset.svg';
import { ReactComponent as FinancialInstrumentIcon } from '../../icons/item-icons/financial-instrument.svg';
import { ReactComponent as MarketPriceModelIcon } from '../../icons/item-icons/market-price-model.svg';
import { ReactComponent as GasStorageIcon } from '../../icons/item-icons/gas-storage.svg';
import { ReactComponent as GasTransportIcon } from '../../icons/item-icons/gas-transport.svg';
import { ReactComponent as RetailPricingIcon } from '../../icons/item-icons/retail-pricing.svg';
import { ReactComponent as ForwardCurveIcon } from '../../icons/item-icons/forward-curve.svg';
import { ReactComponent as LoadIcon } from '../../icons/item-icons/load.svg';
import { ReactComponent as HydroIcon } from '../../icons/item-icons/hydro.svg';
import { ReactComponent as TransmissionLossIcon } from '../../icons/item-icons/transmission-loss.svg';
import { ReactComponent as DsmIcon } from '../../icons/item-icons/dsm.svg';
import { ReactComponent as WindTurbineIcon } from '../../icons/item-icons/wind-turbine.svg';
import { ReactComponent as FixedCostIcon } from '../../icons/item-icons/fixed-cost.svg';
import { ReactComponent as GasLoadIcon } from '../../icons/item-icons/gas-load.svg';
import { ReactComponent as HydroProjectIcon } from '../../icons/item-icons/hydro-project.svg';
import { ReactComponent as AccountsIcon } from '../../icons/item-icons/accounts.svg';
import { ReactComponent as AncillaryReserveIcon } from '../../icons/item-icons/ancillary-reserve.svg';
import { ReactComponent as EconomicAssumptionIcon } from '../../icons/item-icons/economic-assumption.svg';
import { ReactComponent as InclusiveResourceIcon } from '../../icons/item-icons/inclusive-resource.svg';
import { ReactComponent as ExclusiveResourceIcon } from '../../icons/item-icons/exclusive-resource.svg';
import { ReactComponent as ScheduledMaintenanceOptimizationIcon } from '../../icons/item-icons/scheduled-maintenance-optimization.svg';
import { ReactComponent as ResourcePlanningMastIcon } from '../../icons/item-icons/resource-planning-mast.svg';
import { ReactComponent as DeterministicRunSettingsIcon } from '../../icons/item-icons/deterministic-run-settings.svg';
import { ReactComponent as ForwardCurveConstraintsIcon } from '../../icons/item-icons/forward-curve-constraints.svg';
import { ReactComponent as RenewableEnergyCreditIcon } from '../../icons/item-icons/renewable-energy-credit.svg';
import { ReactComponent as HedgeModelIcon } from '../../icons/item-icons/hedge-model.svg';
import { ReactComponent as TradableProductIcon } from '../../icons/item-icons/tradable-product.svg';
import { ReactComponent as StructuredProductIcon } from '../../icons/item-icons/structured-product.svg';
import { ReactComponent as CaesIcon } from '../../icons/item-icons/caes.svg';
import { ReactComponent as BatteryIcon } from '../../icons/item-icons/battery.svg';
import { ReactComponent as ShapeSetsIcon } from '../../icons/item-icons/shape-sets.svg';
import { ReactComponent as GlobalConstraintsIcon } from '../../icons/item-icons/global-constraints.svg';
import { ReactComponent as FunctionalForms1Icon } from '../../icons/item-icons/functional-forms-1.svg';
import { ReactComponent as TransmissionLineIcon } from '../../icons/item-icons/transmission-line.svg';
import { ReactComponent as ReserveIcon } from '../../icons/item-icons/reserve.svg';
import { ReactComponent as NetDemandLimitsIcon } from '../../icons/item-icons/net-demand-limits.svg';
import { ReactComponent as SolarIcon } from '../../icons/item-icons/solar.svg';
import { ReactComponent as CustomerLoadManagerIcon } from '../../icons/item-icons/clm-resistor.svg';

/*
Autoform must have metadata for ITEMTYPEID parameter in order to have a match with FE Icons for autoformIcon
For example: CLM - ITEMTYPEID = 110 mappedItemTypes.CUSTOMER_LOAD_MANAGER
Subschema Code: PSCUSTLOADMAN
Parameters Required: BOOKID;ITEMTYPEID
Default Parameters: 1;110
*/
const mappedItemTypes = {
    DEMOTIEPOINT: {
        id: -1,
        description: 'Demotiepoint',
        icon: DocumentIcon,
    },
    GENERATION_ASSET: {
        id: 2,
        description: 'Generation Asset',
        icon: GenerationAssetIcon,
        type: 'generation_asset',
        alias: 'Thermal',
    },
    FINANCIAL_INSTRUMENT: {
        id: 3,
        description: 'Financial Instrument',
        icon: FinancialInstrumentIcon,
        alias: 'Instrument Types',
    },
    MARKET_PRICE_MODEL: {
        id: 4,
        description: 'Market Price Model',
        icon: MarketPriceModelIcon,
    },
    GAS_STORAGE: {
        id: 6,
        description: 'Gas Storage',
        icon: GasStorageIcon,
    },
    GAS_TRANSPORT: {
        id: 7,
        description: 'Gas Transport',
        icon: GasTransportIcon,
    },
    RETAIL_PRICING: {
        id: 8,
        description: 'Retail Pricing',
        icon: RetailPricingIcon,
    },
    FORWARD_CURVE: {
        id: 11,
        description: 'Forward Curve',
        icon: ForwardCurveIcon,
        alias: 'Forward Curves',
    },
    LOAD: {
        id: 12,
        description: 'Load',
        icon: LoadIcon,
    },
    HYDRO: {
        id: 13,
        description: 'Hydro',
        icon: HydroIcon,
    },
    TRANSMISSION_LOSS: {
        id: 14,
        description: 'Transmission Loss',
        icon: TransmissionLossIcon,
        alias: 'Transmission Losses',
    },
    PHYSICAL_INSTRUMENT: {
        id: 15,
        description: 'Physical Instrument',
        icon: DocumentIcon,
        alias: 'Instrument Types',
    },
    DSM: {
        id: 16,
        description: 'Load Flex / DSM',
        icon: DsmIcon,
        type: 'dsm',
    },
    // Both Wind and Solar item types are now the same Renewables item type
    // Leaving item type description: 'Wind Asset' for now, there is no plan to change it in metadata at the moment
    WIND_ASSET: {
        id: 17,
        description: 'Wind Asset', // renewables item type description must remain 'Wind Asset'
        icon: WindTurbineIcon,
        alias: 'Renewable',
    },
    FIXED_COST: {
        id: 18,
        description: 'Fixed Cost',
        icon: FixedCostIcon,
    },
    TRANSMISSION_LINE: {
        id: 19,
        description: 'Transmission Line',
        icon: TransmissionLineIcon,
        alias: 'Transmission Lines',
    },
    GAS_LOAD: {
        id: 20,
        description: 'Gas Load',
        icon: GasLoadIcon,
    },
    HYDRO_PROJECT: {
        id: 21,
        description: 'Hydro Project',
        icon: HydroProjectIcon,
    },
    ACCOUNTS: {
        id: 22,
        description: 'Accounts',
        icon: AccountsIcon,
    },
    ANCILLARY_RESERVE: {
        id: 23,
        description: 'Ancillary Reserve',
        icon: AncillaryReserveIcon,
        alias: 'Ancillaries',
    },
    LIABILITY: {
        id: 24,
        description: 'Liability',
        icon: ResourcePlanningMastIcon,
    },
    ECONOMIC_ASSUMPTION: {
        id: 25,
        description: 'Economic Assumption',
        icon: EconomicAssumptionIcon,
    },
    NEW_PROJECT_DETAILS: {
        id: 26,
        description: 'New Project Details',
        icon: ResourcePlanningMastIcon,
    },
    PLANNING_CONSTRAINT: {
        id: 27,
        description: 'Planning Constraint',
        icon: ResourcePlanningMastIcon,
    },
    INCLUSIVE_RESOURCE: {
        id: 28,
        description: 'Inclusive Resource',
        icon: InclusiveResourceIcon,
    },
    EXCLUSIVE_RESOURCE: {
        id: 29,
        description: 'Exclusive Resource',
        icon: ExclusiveResourceIcon,
    },
    DETERMINISTIC_RUN_SETTINGS: {
        id: 30,
        description: 'Deterministic Run Settings',
        icon: DeterministicRunSettingsIcon,
    },
    SHEDULED_MAINTENANCE_OPTIMIZATION: {
        id: 31,
        description: 'Scheduled Maintenance Optimization',
        icon: ScheduledMaintenanceOptimizationIcon,
    },
    EXISTING_ASSET_BASE: {
        id: 33,
        description: 'Existing Asset Base',
        icon: ResourcePlanningMastIcon,
    },
    PLANNING_OBJECTIVE_FUNCTION: {
        id: 34,
        description: 'Planning Objective Function',
        icon: ResourcePlanningMastIcon,
    },
    PLANNING_CONSTRAINT_SET: {
        id: 35,
        description: 'Planning Constraint Set',
        icon: ResourcePlanningMastIcon,
    },
    DETERMINISTIC_SETTINGS: {
        id: 40,
        description: 'Deterministic Settings',
        icon: DeterministicRunSettingsIcon,
    },
    FORWARD_CURVE_CONSTRAINTS: {
        id: 41,
        description: 'Forward Curve Constraints',
        icon: ForwardCurveConstraintsIcon,
    },
    RENEWABLE_ENERGY_CREDIT: {
        id: 42,
        description: 'Renewable Energy Credit',
        icon: RenewableEnergyCreditIcon,
    },
    HEDGE: {
        id: 43,
        description: 'Hedge Model',
        icon: HedgeModelIcon,
        alias: 'Hedges & Contracts',
    },
    TRADABLE_PRODUCT: {
        id: 44,
        description: 'Tradable Product',
        icon: TradableProductIcon,
    },
    STRUCTURED_PRODUCT: {
        id: 45,
        description: 'Structured Product',
        icon: StructuredProductIcon,
    },
    RESERVE_FOLLOWING: {
        id: 46,
        description: 'Reserve Following',
        icon: ReserveIcon,
    },
    RESERVE_CONTINGENCY: {
        id: 47,
        description: 'Reserve Contingency',
        icon: ReserveIcon,
    },
    BATTERY: {
        id: 48,
        description: 'Battery',
        icon: BatteryIcon,
    },
    CAES: {
        id: 49,
        description: 'CAES',
        icon: CaesIcon,
    },
    BATTERY_STORAGE: {
        id: 55,
        description: 'Battery Storage',
        icon: BatteryIcon,
    },
    SHAPE_SETS: {
        id: 65,
        description: 'Shape Sets',
        icon: ShapeSetsIcon,
    },
    GLOBAL_CONSTRAINTS: {
        id: 75,
        description: 'Global Constraints',
        icon: GlobalConstraintsIcon,
    },
    FUNCTIONAL_FORMS: {
        id: 85,
        description: 'Functional Forms',
        icon: FunctionalForms1Icon,
    },
    TX_NODE: {
        id: 90,
        description: 'TX Node',
        icon: TransmissionLineIcon,
    },
    TX_LINE: {
        id: 92,
        description: 'TX Line',
        icon: TransmissionLineIcon,
    },
    ECONOMIC_RESERVE: {
        id: 95,
        description: 'Economic Reserve',
        icon: ReserveIcon,
    },
    NET_DEMAND_LIMITS: {
        id: 97,
        description: 'Net Demand Limits',
        icon: NetDemandLimitsIcon,
        alias: 'Net Demand',
    },
    // Both Wind and Solar item types are now the same Renewables item type id 17
    // Solar specific item type will be deprecated
    SOLAR_ASSET: {
        id: 100,
        description: 'Solar Asset',
        icon: SolarIcon,
        alias: 'Solar',
    },
    CUSTOMER_LOAD_MANAGER: {
        id: 110,
        description: 'Customer Load Manager',
        icon: CustomerLoadManagerIcon,
    },
};

export default mappedItemTypes;
