import { Button, Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

const AddDataButton = ({ messageId, onAdd }) => {
    return (
        <Flex align="baseline">
            <Button
                variant="unstyled"
                color="blue.400"
                fontSize="14px"
                lineHeight="20px"
                minHeight="0"
                height="30px"
                onClick={onAdd}
            >
                <FormattedMessage id={messageId} />
            </Button>
            &nbsp;
            <Text size="sm" variant="regular">
                <FormattedMessage id="datagrid_or_try_changing_the_filters" />
            </Text>
        </Flex>
    );
};

export default AddDataButton;
