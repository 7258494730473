import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Heading, Flex, Text, Box, FormLabel, useDisclosure } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import useAutoformMetadata from 'components/autoform/hooks/useAutoformMetadata';
import useAutoformTableMetadata from 'components/autoform/hooks/useAutoformTableMetadata';
import useAutoformTableData from 'components/autoform/hooks/useAutoformTableData';
import { CONSTRAINT_COMPONENTS_PRIMARY_KEY, CONSTRAINT_COMPONENTS_TABLE_ID } from '../../constants/globalConstraints';

import AddRowsButton from './AddRowsButton';
import useAutoformLoadTableData from 'components/autoform/hooks/useAutoformLoadTableData';
import DefinitionsSkeleton from 'components/utils/DefinitionsSkeleton';
import useAutoformLoadTableDropdowns from 'components/autoform/hooks/useAutoformLoadTableDropdowns';
import ComboboxFormikField from 'components/forms/ComboboxFormikField';
import CheckboxField from 'components/forms/CheckboxField';
import NumberInputFormikField from 'components/forms/NumberInputFormikField';
import useGlobalConstraintsSubItems from 'hooks/globalConstraints/useGlobalConstraintsSubItems';
import Pagination from 'components/utils/Pagination';
import DeleteRecordModal from 'components/utils/DeleteRecordModal';
import SecondaryButton from 'components/utils/SecondaryButton';

import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import useCommonToast from 'hooks/useCommonToast';
import useAutoformParams from 'components/autoform/hooks/useAutoformParams';
import { deleteAutoformTableRows } from 'store/autoform/autoformApi';
import { getInitialValuesForDataRow } from 'components/autoform/utils/autoformUtils';

const GlobalConstraintsComponentsTab = ({ portfolioConstraint, setInitialValues }) => {
    const intl = useIntl();
    const parameters = useAutoformParams();
    const dispatch = useDispatch();

    const { hierarchicalTables } = useAutoformMetadata();
    const itemIdsRef = useRef([]);
    const { values, setValues } = useFormikContext();
    const { toast } = useCommonToast();
    const [refetchConstraintsCounter, setRefetchConstraintsCounter] = useState(0);
    const level2Table = hierarchicalTables.level2[0];
    const { selectedItem: selectedParentRecordId } = useAutoformTableMetadata(level2Table?.id);
    const { isLoading, reloadData } = useAutoformLoadTableData(CONSTRAINT_COMPONENTS_TABLE_ID, selectedParentRecordId, {
        refetchDataCounter: refetchConstraintsCounter,
    });
    const { data } = useAutoformTableData(CONSTRAINT_COMPONENTS_TABLE_ID);
    const { data: parentData } = useAutoformTableData(level2Table.id);

    const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();

    const [selection, setSelection] = useState({});
    const [subItemsLoadingForItem, setSubItemsLoadingForItem] = useState(false);

    useAutoformLoadTableDropdowns(CONSTRAINT_COMPONENTS_TABLE_ID, selectedParentRecordId);

    const { metadata, dropdownOptions, sortedColumns } = useAutoformTableMetadata(CONSTRAINT_COMPONENTS_TABLE_ID);

    const mappedData = useMemo(() => data.map((row, index) => ({ ...row, recordNumber: index + 1 })), [data]);
    const itemIds = useMemo(() => {
        let changesDetected = false;
        let result;
        const itemIdSet = Object.values(values.constraintComponents || {}).reduce((acc, row) => {
            if (row.TARGETITEMID !== null && itemIdsRef.current.indexOf(row.TARGETITEMID) === -1) {
                changesDetected = true;
            }
            acc.add(row.TARGETITEMID);
            return acc;
        }, new Set());
        if (!changesDetected) {
            result = itemIdsRef.current;
        } else {
            result = [...itemIdSet].filter((id) => !!id);
            itemIdsRef.current = result;
        }
        return result;
    }, [values?.constraintComponents]);

    const constraintComponentsInitialValues = useMemo(
        () =>
            data.reduce((acc, row) => {
                acc[row.OPTCONSTRAINTCOMPID] = getInitialValuesForDataRow(row, metadata);

                return acc;
            }, {}),
        [data, metadata]
    );

    useEffect(() => {
        setRefetchConstraintsCounter((counter) => ++counter);
    }, [parentData]);

    useEffect(() => {
        if (Object.values(constraintComponentsInitialValues).length) {
            setValues((values) => ({
                ...values,
                constraintComponents: {
                    ...constraintComponentsInitialValues,
                    ...values.constraintComponents,
                },
            }));

            setInitialValues((values) => ({
                constraintComponents: {
                    ...constraintComponentsInitialValues,
                    ...values.constraintComponents,
                },
            }));
        }
    }, [constraintComponentsInitialValues, setInitialValues, setValues]);

    const { subItems, isLoading: isSubItemsLoading } = useGlobalConstraintsSubItems(itemIds);

    const columnMap = useMemo(
        () =>
            sortedColumns.reduce((acc, col) => {
                acc[col.name] = col;
                return acc;
            }, {}),
        [sortedColumns]
    );

    const subItemsMap = useMemo(
        () =>
            subItems.reduce((acc, subItem) => {
                if (!acc[subItem.itemId]) {
                    acc[subItem.itemId] = [];
                }
                acc[subItem.itemId].push(subItem);
                return acc;
            }, {}),
        [subItems]
    );

    const translations = useMemo(
        () => ({
            itemLabel: intl.formatMessage({
                id: 'global_constraints_item',
            }),
            subItemLabel: intl.formatMessage({
                id: 'global_constraints_sub_item',
            }),
            weightFactor: intl.formatMessage({
                id: 'global_constraints_weight_factor',
            }),
            constraint: intl.formatMessage({
                id: 'global_constraints_constraint',
            }),
            constraints: intl.formatMessage({
                id: 'global_constraints_constraints',
            }),
            thisConstraint: intl.formatMessage({
                id: 'global_constraints_this_constraint',
            }),
            multipleConstraints: intl.formatMessage({
                id: 'global_constraints_multiple_constraints',
            }),
        }),
        [intl]
    );

    const recordsToDelete = useMemo(
        () =>
            Object.entries(selection)
                .filter(([key, val]) => val)
                .map(([key, val]) => parseInt(key)),
        [selection]
    );

    const onOptionsDelete = async () => {
        const successMessage = intl.formatMessage(
            { id: 'common_delete_success' },
            { item: recordsToDelete.length > 1 ? translations.constraints : translations.constraint }
        );
        await dispatch(
            deleteAutoformTableRows({
                tableId: CONSTRAINT_COMPONENTS_TABLE_ID,
                recordsIds: recordsToDelete,
                primaryKeyColumnName: CONSTRAINT_COMPONENTS_PRIMARY_KEY,
                parameters,
            })
        ).unwrap();
        toast(successMessage);
        onDeleteModalClose();
        setSelection({});
        setInitialValues((values) => ({
            ...values,
            constraintComponents: {
                ...Object.entries(values.constraintComponents).reduce((acc, [key, val]) => {
                    if (recordsToDelete.indexOf(parseInt(key)) === -1) {
                        acc[key] = val;
                    }
                    return acc;
                }, {}),
            },
        }));
        setValues((values) => ({
            ...values,
            constraintComponents: {
                ...Object.entries(values.constraintComponents).reduce((acc, [key, val]) => {
                    if (recordsToDelete.indexOf(parseInt(key)) === -1) {
                        acc[key] = val;
                    }
                    return acc;
                }, {}),
            },
        }));
    };

    const handleCheckboxChange = (e, row) =>
        setSelection((selection) => ({
            ...selection,
            [row.OPTCONSTRAINTCOMPID]: e.target.checked,
        }));

    const handleTargetItemChange = (row, value) => {
        setSubItemsLoadingForItem(row.OPTCONSTRAINTCOMPID);
        setValues((values) => ({
            ...values,
            constraintComponents: {
                ...values.constraintComponents,
                [row.OPTCONSTRAINTCOMPID]: {
                    ...values.constraintComponents[row.OPTCONSTRAINTCOMPID],
                    TARGETITEMID: value,
                    TARGETCOMPONENTID: '',
                },
            },
        }));
    };

    return (
        <>
            <Heading as="h3" variant="h3" textTransform="capitalize">
                <FormattedMessage id="global_constraints_item_constraints_tab" />
            </Heading>
            <Flex justify="flex-end" w="100%" wrap="wrap" direction={{ base: 'column', xl: 'row' }} py={3} px={0}>
                <SecondaryButton
                    onClick={onDeleteModalOpen}
                    leftIcon={<DeleteIcon />}
                    size="sm"
                    type="button"
                    variant="secondary"
                    textTransform="capitalize"
                    mb={{ base: 4, xl: 0 }}
                    disabled={recordsToDelete.length === 0 || portfolioConstraint}
                    mr={4}
                >
                    <FormattedMessage id="common_delete" />
                </SecondaryButton>
                <AddRowsButton
                    portfolioConstraint={portfolioConstraint}
                    isDisabled={portfolioConstraint}
                    reloadData={reloadData}
                />
            </Flex>
            {isLoading ? (
                <DefinitionsSkeleton />
            ) : (
                <>
                    <Pagination
                        items={mappedData}
                        mt={5}
                        renderItem={(row) => {
                            return (
                                <Flex
                                    borderBottom="1px solid"
                                    borderColor="border-secondary"
                                    py={6}
                                    alignItems="center"
                                    key={row.OPTCONSTRAINTCOMPID}
                                >
                                    <Box>
                                        <FormLabel>
                                            <FormattedMessage id="global_constraints_num" />
                                        </FormLabel>
                                        <Text fontSize="md" mr={6} py={2}>
                                            {row.recordNumber}.
                                        </Text>
                                    </Box>
                                    <Box>
                                        <FormLabel>
                                            <FormattedMessage id="global_constraints_selection" />
                                        </FormLabel>
                                        <Box pt="7px" pb="13px">
                                            <CheckboxField
                                                isChecked={selection[row.OPTCONSTRAINTCOMPID] ?? false}
                                                isDisabled={portfolioConstraint}
                                                onChange={(e) => handleCheckboxChange(e, row)}
                                            />
                                        </Box>
                                    </Box>
                                    <ComboboxFormikField
                                        label={translations.itemLabel}
                                        name={`constraintComponents.${row.OPTCONSTRAINTCOMPID}.TARGETITEMID`}
                                        options={dropdownOptions[columnMap.TARGETITEMID.id]}
                                        isDisabled={portfolioConstraint}
                                        valueKey="value"
                                        labelKey="text"
                                        onChange={(value) => handleTargetItemChange(row, value)}
                                        mr={6}
                                    />
                                    <ComboboxFormikField
                                        label={translations.subItemLabel}
                                        name={`constraintComponents.${row.OPTCONSTRAINTCOMPID}.TARGETCOMPONENTID`}
                                        options={
                                            subItemsMap[
                                                values.constraintComponents &&
                                                    values.constraintComponents[row.OPTCONSTRAINTCOMPID]?.TARGETITEMID
                                            ]
                                        }
                                        isDisabled={portfolioConstraint}
                                        valueKey="generationId"
                                        labelKey="description"
                                        showPlaceholder={
                                            isSubItemsLoading && subItemsLoadingForItem === row.OPTCONSTRAINTCOMPID
                                        }
                                        placeholderLabel={
                                            isSubItemsLoading && subItemsLoadingForItem === row.OPTCONSTRAINTCOMPID
                                                ? intl.formatMessage({ id: 'common_loading' })
                                                : undefined
                                        }
                                        placeholderValue=""
                                        isControlled={true}
                                        mr={6}
                                    />
                                    <NumberInputFormikField
                                        label={translations.weightFactor}
                                        name={`constraintComponents.${row.OPTCONSTRAINTCOMPID}.WEIGHTFACTOR`}
                                        isDisabled={portfolioConstraint}
                                    />
                                </Flex>
                            );
                        }}
                        pageSize={5}
                    />
                </>
            )}

            {isDeleteModalOpen && (
                <DeleteRecordModal
                    onOpen={onDeleteModalOpen}
                    onClose={onDeleteModalClose}
                    headerItem={recordsToDelete.length > 1 ? translations.multipleConstraints : translations.constraint}
                    contentMessageItem={
                        recordsToDelete.length > 1 ? translations.multipleConstraints : translations.thisConstraint
                    }
                    onConfirm={onOptionsDelete}
                />
            )}
        </>
    );
};

export default GlobalConstraintsComponentsTab;
