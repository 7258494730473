import { forwardRef, useRef, useMemo, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';

import { formatMultisort } from '../../services/items';
import { columnTypes } from '../../services/grid';

import SortAscIcon from '../../icons/sort-asc.svg';
import SortDescIcon from '../../icons/sort-desc.svg';
import SortNoneIcon from '../../icons/sort-none.svg';
import FirstPageIcon from '../../icons/first-page.svg';
import LeftArrowIcon from '../../icons/prev-page.svg';
import LastPageIcon from '../../icons/last-page.svg';
import RightArrowIcon from '../../icons/next-page.svg';
import GridMenuIcon from '../../icons/grid-menu.svg';

import DataGridButton from '../hedges/DataGridButton';
import StudyTrackerStatusBadge from '../studies/StudyTrackerStatusBadge';
import StudyTrackerName from '../studies/StudyTrackerName';
import CellIconButton from '../utils/CellIconButton';
import MoreActionsCell from '../utils/MoreActionsCell';
import ViewJobLogsCell from './ViewJobLogsCell';
import StatementMessageTypeCell from './StatementMessageTypeCell';
import DownloadButtonCell from './DownloadButtonCell';
import CheckboxColumnHeader from '../utils/CheckboxColumnHeader';

import { SortOrderContext } from './utils/SortOrderContext';

import DataGridCalendar from './DataGridCalendar';
import DataGridCombobox from './DataGridCombobox';
import DataGridCheckbox from './DataGridCheckbox';
import DataGridCheckboxEditor from './DataGridCheckboxEditor';
import DataGridProgressBar from './DataGridProgressBar';
import DataGridNoRowsOverlay from './DataGridNoRowsOverlay';
import DataGridIssuesDialog from './DataGridIssuesDialog';
import DataGridInternalIssuesDialog from './DataGridInternalIssuesDialog';
import DataGridTooltip from './DataGridTooltip';

const DataGridReadOnly = forwardRef(
    (
        {
            name,
            columns,
            className,
            isInitialRequestSent,
            disableReadOnlyStyles = false,
            rowHeight = 44,
            pagination = true,
            phrasing = {},
            gridResource,
            onGridReady,
            multisortPairs = {},
            primaryKeyName,
            rowData,
            ...rest
        },
        ref
    ) => {
        const intl = useIntl();
        const { setSortOrder } = useContext(SortOrderContext);

        const gridApi = useRef();

        const noRowsOverlayComponentFramework = useMemo(() => {
            return DataGridNoRowsOverlay;
        }, []);

        //https://ag-grid.com/javascript-data-grid/context-menu/
        const getContextMenuItems = useCallback(() => {
            let items = [];

            items.push('resetColumns');
            return items;
        }, []);

        //https://ag-grid.com/javascript-data-grid/column-menu/
        const getMainMenuItems = useCallback(() => {
            let items = [];
            items.push('autoSizeThis', 'resetColumns');
            return items;
        }, []);

        const onSortChanged = useCallback(
            (params) => {
                const columnState = params.columnApi.getColumnState();

                const sortOrder = formatMultisort(
                    columnState.filter((col) => col.sort !== null),
                    multisortPairs
                );

                setSortOrder((prev) => ({ ...prev, [name]: sortOrder }));
            },
            [setSortOrder, name, multisortPairs]
        );

        const setGridRef = (node) => {
            gridApi.current = node?.api;
            if (typeof onGridReady === 'function') {
                onGridReady(node);
            }
        };

        const getRowId = useMemo(() => {
            return primaryKeyName ? (params) => params.data[primaryKeyName] : undefined;
        }, [primaryKeyName]);

        return (
            <>
                <AgGridReact
                    className={className}
                    ref={ref}
                    localeText={{
                        loadingOoo: intl.formatMessage({ id: 'common_loading' }),
                        noRowsToShow: intl.formatMessage({ id: 'common_no_data_found' }),
                    }}
                    suppressColumnVirtualisation={true} // col virtualization messes up the cell borders
                    defaultColDef={{
                        editable: false,
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        lockPosition: true,
                        menuTabs: ['generalMenuTab'],
                        unSortIcon: true,
                        resizable: true,
                        tooltipComponent: DataGridTooltip,
                        suppressKeyboardEvent: true,
                        cellStyle: undefined,
                        // cellClass does not update, hence using cellClasRules as a replacemant
                        cellClassRules: {
                            readonly: true,
                        },
                        // the default text editor of ag grid changes the newValue to undefined
                        // when a user enters and exits edit mode without changing anything to an empty cell
                        valueParser: (params) => {
                            return params.newValue === undefined && params.oldValue === null ? null : params.newValue;
                        },
                    }}
                    suppressMenuHide={true}
                    getMainMenuItems={getMainMenuItems}
                    getContextMenuItems={getContextMenuItems}
                    columnTypes={columnTypes}
                    pagination={pagination}
                    enableRangeSelection={true}
                    frameworkComponents={{
                        DataGridCalendar,
                        DataGridCheckbox,
                        DataGridCheckboxEditor,
                        DataGridProgressBar,
                        DataGridCombobox,
                        DataGridButton,
                        StudyTrackerStatusBadge,
                        StudyTrackerName,
                        DataGridNoRowsOverlay,
                        DataGridIssuesDialog,
                        DataGridInternalIssuesDialog,
                        CellIconButton,
                        MoreActionsCell,
                        ViewJobLogsCell,
                        StatementMessageTypeCell,
                        DownloadButtonCell,
                        CheckboxColumnHeader,
                    }}
                    icons={{
                        sortAscending: `<img src="${SortAscIcon}" />`,
                        sortDescending: `<img src="${SortDescIcon}" />`,
                        sortUnSort: `<img src="${SortNoneIcon}" />`,
                        first: `<img src="${FirstPageIcon}" />`,
                        previous: `<img src="${LeftArrowIcon}" />`,
                        next: `<img src="${RightArrowIcon}" />`,
                        last: `<img src="${LastPageIcon}" />`,
                        menu: `<img src="${GridMenuIcon}" />`,
                    }}
                    rowHeight={rowHeight}
                    columnDefs={columns}
                    rowData={rowData}
                    onSortChanged={onSortChanged}
                    noRowsOverlayComponentFramework={noRowsOverlayComponentFramework}
                    tooltipShowDelay={0}
                    onGridReady={setGridRef}
                    getRowId={getRowId}
                    enableCellChangeFlash={true}
                    stopEditingWhenCellsLoseFocus={true}
                    suppressScrollWhenPopupsAreOpen={true}
                    {...rest}
                />
            </>
        );
    }
);

export default DataGridReadOnly;
