import { Fragment } from 'react';
import styled from '@emotion/styled/macro';
import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    HStack,
    VStack,
    Box,
    StackDivider,
    Text,
    Heading,
} from '@chakra-ui/react';

const DashboardStats = ({ stats }) => {
    return (
        <>
            <StatsWrapper spacing={6}>
                {stats.map(({ isLabelVisible, label, value, description, hasDivider }, index) => (
                    <Fragment key={index}>
                        <StatContainer>
                            <Stat my={0}>
                                <StatLabel>
                                    <Text size="sm" _dark={{ color: 'gray.100' }} opacity={isLabelVisible ? 1 : 0}>
                                        {label}
                                    </Text>
                                </StatLabel>

                                <StatNumber>
                                    {/* @todo add isCriticalValue red.900 when needed */}
                                    <Heading as="h4" variant="h4" py="2px">
                                        {value}
                                    </Heading>
                                </StatNumber>

                                <StatHelpText _dark={{ color: 'gray.400' }} mb={0}>
                                    {description}
                                </StatHelpText>
                            </Stat>
                        </StatContainer>

                        {hasDivider && <StackDivider width="1px" bgColor="gray.100" />}
                    </Fragment>
                ))}
            </StatsWrapper>

            <MobileStatsWrapper spacing={5} align="stretch">
                {stats.map(({ isLabelVisible, label, value, description }, index) => (
                    <Box key={index}>
                        <Stat>
                            {isLabelVisible ? (
                                <StatLabel>
                                    <Text size="sm" _dark={{ color: 'gray.100' }}>
                                        {label}
                                    </Text>
                                </StatLabel>
                            ) : null}

                            <StatNumber fontSize={{ base: '18px' }}>
                                {/* @todo add isCriticalValue red.900 when needed */}
                                <Heading as="h4" variant="h4" py="2px">
                                    {value}
                                </Heading>
                            </StatNumber>

                            <StatHelpText _dark={{ color: 'gray.400' }} mb={0}>
                                {description}
                            </StatHelpText>
                        </Stat>
                    </Box>
                ))}
            </MobileStatsWrapper>
        </>
    );
};

const StatsWrapper = styled(HStack)`
    gap: 24px;

    @media screen and (max-width: 375px) {
        display: none;
    }

    @media screen and (max-width: 796px) and (min-width: 768px) {
        gap: 12px;
    }
`;

const StatContainer = styled(Box)`
    margin: 0 !important;
`;

const MobileStatsWrapper = styled(VStack)`
    @media screen and (min-width: 376px) {
        display: none;
    }
`;

export default DashboardStats;
