import { useCallback, useState, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Box, useDisclosure } from '@chakra-ui/react';

import {
    GRID_KEY,
    createPriceEquations,
    getPriceEquations,
    updatePriceEquations,
    deletePriceEquations,
    deleteAllPriceEquations,
    multisortPairs,
} from '../../services/priceFormula';
import { exportItemData } from '../../services/items';

import UploadButton from '../itemData/UploadButton';
import SubItemData from '../itemData/SubItemData';

import DownloadModal from '../../components/utils/DownloadModal';
import SecondaryButton from '../../components/utils/SecondaryButton';

import SortOrderProvider from '../grid/utils/SortOrderContext';

import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

const PriceEquations = ({ peakPeriods, priceFormulaId }) => {
    const downloadModal = useDisclosure();

    const [key, setKey] = useState(0);

    const intl = useIntl();

    const refreshTabs = useCallback(() => setKey((prev) => prev + 1), []);

    const phrasing = useMemo(
        () => ({
            header: <FormattedMessage id="common_add_rows_heading" />,
            content: <FormattedMessage id="common_add_rows_sub_heading_without_date" />,
        }),
        []
    );

    const onDownloadData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const payload = {
                sorting: sortOrder[GRID_KEY],
                exportTemplate,
            };

            return exportItemData(`price-formula/${priceFormulaId}/export`, payload);
        },
        [priceFormulaId]
    );

    const gridColumns = useMemo(
        () => [
            {
                field: 'runOrder',
                type: 'number',
                cellEditorParams: { required: true },
                headerName: intl.formatMessage({ id: 'price_equation_run_order' }),
                initialSort: 'asc',
                initialSortIndex: 1,
            },
            {
                field: 'peakPeriodLabelId',
                type: 'select',
                cellEditorParams: { options: peakPeriods, required: false },
                headerName: intl.formatMessage({ id: 'price_equation_peak_periods' }),
                minWidth: 180,
                initialSort: 'asc',
                initialSortIndex: 2,
            },
            {
                field: 'equation',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'price_equation_equation' }),
            },
        ],
        [intl, peakPeriods]
    );

    const gridResource = useMemo(() => {
        return {
            create: (rows) => createPriceEquations(priceFormulaId, rows),
            read: (pagination) => getPriceEquations(priceFormulaId, { ...pagination }),
            update: (rows) => updatePriceEquations(priceFormulaId, rows),
            delete: (rows) =>
                deletePriceEquations(
                    priceFormulaId,
                    rows.map((row) => row.priceFormulaEqId)
                ),
            deleteAll: () => deleteAllPriceEquations(priceFormulaId),
        };
    }, [priceFormulaId]);

    return (
        <SortOrderProvider>
            <Box p={5}>
                <SubItemData
                    key={key}
                    tab="0"
                    name={GRID_KEY}
                    actionBarSlot={() => (
                        <>
                            <SecondaryButton
                                leftIcon={<DownloadIcon />}
                                w="auto !important"
                                mr={2}
                                size="sm"
                                type="button"
                                variant="secondary"
                                onClick={downloadModal.onOpen}
                            >
                                <FormattedMessage id="common_download_btn_tooltip_and_label" />
                            </SecondaryButton>

                            <UploadButton
                                type="price-formula"
                                itemId={priceFormulaId}
                                hasLabel={true}
                                onAfterUploadChange={refreshTabs}
                                mr={4}
                                w="auto !important"
                                hideTreatWarningsAsErrors
                            />
                        </>
                    )}
                    gridResource={gridResource}
                    columns={gridColumns}
                    addDataColumns={gridColumns}
                    successMessage={intl.formatMessage({ id: 'price_equation_save' })}
                    multisortPairs={multisortPairs}
                    phrasing={phrasing}
                />

                {downloadModal.isOpen && (
                    <DownloadModal
                        isOpen
                        onClose={downloadModal.onClose}
                        onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                        onDownloadTemplate={() => onDownloadData(true)}
                        bodyMessage={<FormattedMessage id="price_equation_download_modal_explanation" />}
                    />
                )}
            </Box>
        </SortOrderProvider>
    );
};

export default PriceEquations;
