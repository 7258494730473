import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Flex, Button, Container, useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import { getEnvByConnectionId, getVersionsByEnvAndConnectionId } from '../services/connections';
import useConnectionSearch from '../hooks/useConnectionSearch';
import useHistoryPush from '../hooks/useHistoryPush';

import ConnectionStep from '../components/connection/ConnectionStep';
import ConnectionOption from '../components/connection/ConnectionOption';
import MainModal from '../components/modal/MainModal';

import ComboboxField from '../components/forms/ComboboxField';

import { ReactComponent as BeakerIcon } from '../icons/beaker.svg';

const Environment = () => {
    const intl = useIntl();

    const historyPush = useHistoryPush();

    const { search: locationSearch } = useLocation();
    const searchParams = new URLSearchParams(locationSearch);

    const switchVersionModal = useDisclosure();

    const connectionConfig = useSelector((state) => state.connections);

    const { search, setSearch, setSearchCollection, noSearchResultsFound, searchResultsFound } = useConnectionSearch();

    const connectionId = searchParams.get('_connectionId') || connectionConfig.connection;
    const connectionTitle = searchParams.get('_connectionTitle') || connectionConfig.connectionTitle;

    const [env, setEnv] = useState({
        isLoading: false,
        options: [],
        error: '',
        value: connectionConfig?.environment,
        schemaGroupId: '',
        title: connectionConfig?.environmentTitle,
    });

    const [versions, setVersions] = useState({
        options: [],
        temporary: { name: null, url: null, isDefault: false },
        selected: { name: null, url: null, isDefault: false },
    });

    const handleSearch = (value) => {
        setSearch((prev) => ({ ...prev, term: value }));
        setSearchCollection(env.options);
    };

    const getEnvironments = useCallback(async () => {
        setEnv((prev) => ({ ...prev, isLoading: true }));

        try {
            const connectionEnv = await getEnvByConnectionId(connectionId);
            // There are might be no version selection allowed for the user or no versions with isDefault == true in the list :(
            let connectionVersions = await getVersionsByEnvAndConnectionId(connectionId);

            if (connectionVersions?.length > 0) {
                // Found connection versions
                // Dummy Default version name for version selection drop down display only
                const defaultVersionName = intl.formatMessage({ id: 'connection_default_version_name' });
                // Add dummy Default version to versions drop down selection list
                connectionVersions = [{ name: defaultVersionName, url: null, isDefault: true }, ...connectionVersions];

                // Select first Default version
                const defaultVersion = connectionVersions.find((v) => v.isDefault);

                setVersions({
                    options: connectionVersions,
                    temporary: defaultVersion,
                    selected: defaultVersion,
                });
            }

            setEnv((prev) => ({
                ...prev,
                isLoading: false,
                options: connectionEnv.map((item) => ({
                    title: item.name,
                    subtitle: item.description,
                    value: item.id,
                    schemaGroupId: item.schemaGroupId,
                    icon: BeakerIcon,
                })),
            }));
        } catch (err) {
            if (err.response.status === 500) {
                setEnv((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: intl.formatMessage({ id: 'connection_error_fetch_env' }),
                }));
            }
        }
    }, [intl, connectionId]);

    useEffect(() => {
        getEnvironments();
    }, [getEnvironments]);

    const onEnvSelect = async (selected, schemaSelected, environmentTitle) => {
        setEnv((prev) => ({ ...prev, value: selected, schemaGroupId: schemaSelected, title: environmentTitle }));

        const search = new URLSearchParams();
        search.append('_envId', selected);
        search.append('_envTitle', environmentTitle);
        search.append('_connectionId', connectionId);
        search.append('_connectionTitle', connectionTitle);
        search.append('_schemaGroupId', schemaSelected);
        search.append('_selectedVersionName', versions?.selected?.name);

        // Go to books selection
        historyPush({
            pathname: `/book`,
            search,
        });
    };

    const onVersionSelect = (selected) => {
        const temporaryVersion = versions.options.find((version) => version.url === selected);
        setVersions((prev) => ({ ...prev, temporary: temporaryVersion }));
    };

    const onVersionDismiss = (isConfirmed) => {
        if (isConfirmed) {
            setVersions((prev) => ({ ...prev, selected: versions.temporary }));
        } else {
            setVersions((prev) => ({ ...prev, temporary: versions.selected }));
        }

        switchVersionModal.onClose();
    };

    return (
        <Flex minHeight="100vh" bg="background-secondary" pt={40} pb={20} flexDirection="column">
            <MainModal
                size="md"
                isOpen={switchVersionModal.isOpen}
                onClose={() => onVersionDismiss(false)}
                header={<FormattedMessage id="connection_select_version" />}
                content={
                    <>
                        <VersionWarningMessage>
                            <FormattedMessage id="connection_select_version_modal_text" />
                        </VersionWarningMessage>

                        <ComboboxField
                            my={6}
                            id="version"
                            name="version"
                            label={intl.formatMessage({ id: 'connection_select_version' })}
                            options={versions.options}
                            valueKey="url"
                            labelKey="name"
                            placeholderLabel={versions.temporary.name}
                            placeholderValue={versions.temporary.url}
                            value={versions.temporary.url}
                            onChange={onVersionSelect}
                        />
                    </>
                }
                secondaryButton={
                    <Button
                        textTransform="capitalize"
                        minW={100}
                        variant="secondary"
                        onClick={() => onVersionDismiss(false)}
                    >
                        <FormattedMessage id="common_cancel" />
                    </Button>
                }
                footerRightSlot={
                    <Button textTransform="capitalize" minW={100} ml={4} onClick={() => onVersionDismiss(true)}>
                        <FormattedMessage id="connection_select_version" />
                    </Button>
                }
            />

            <Container maxW={1056}>
                <ConnectionStep
                    onSearch={handleSearch}
                    current={2}
                    showVersionButton={versions?.options?.length > 0}
                    onVersionModalOpen={switchVersionModal.onOpen}
                    step={2}
                    total={3}
                    title={<FormattedMessage id="connection_select_environment" />}
                    subtitle={<FormattedMessage id="connection_environment" />}
                    description={<FormattedMessage id="connection_please_select_the_environment" />}
                    isLoading={env.isLoading}
                    error={env.error}
                    noResults={noSearchResultsFound}
                    onErrorDismiss={() => {
                        setEnv((prev) => ({ ...prev, error: '' }));
                    }}
                >
                    {(searchResultsFound ? search.results : env.options).map((o) => (
                        <ConnectionOption
                            {...o}
                            key={o.value}
                            isSelected={env.value === o.value}
                            onClick={() => onEnvSelect(o.value, o.schemaGroupId, o.title)}
                        />
                    ))}
                </ConnectionStep>
            </Container>
        </Flex>
    );
};

const VersionWarningMessage = styled.div`
    color: var(--chakra-colors-orange-600);
    font-size: var(--chakra-fontSizes-md);
`;

export default Environment;
