import { useState, useImperativeHandle, forwardRef } from 'react';
import styled from '@emotion/styled/macro';

import Combobox from '../forms/Combobox';

const DataGridCombobox = forwardRef(({ options, data, required, node, column }, ref) => {
    const [selected, setSelected] = useState(data[column.getColDef().field] ?? '-1');

    const editorParams = column.getColDef().cellEditorParams;

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selected === '-1' ? '' : options.find(({ id }) => id === selected)?.id;
            },
        };
    });

    return (
        <DataGridComboboxWrapper
            options={options}
            valueKey="id"
            labelKey="description"
            value={selected}
            onChange={setSelected}
            placeholderLabel={editorParams.placeholderLabel}
            placeholderValue={editorParams.placeholderValue}
            showPlaceholder={!required} //@todo refactor App-wide required to be showPlaceholder similar to Combobox API
        />
    );
});

const DataGridComboboxWrapper = styled(Combobox)`
    input {
        background: var(--chakra-colors-background-tertiary);
        border-color: var(--chakra-colors-gray-300);

        &:hover:not(:focus) {
            background: var(--chakra-colors-background-tertiary);
            border-color: var(--chakra-colors-gray-400);
        }
    }
`;

export default DataGridCombobox;
