import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { Button, IconButton, useDisclosure } from '@chakra-ui/react';

import { loadModules } from 'esri-loader';

import styled from '@emotion/styled/macro';

import { FormattedMessage, useIntl } from 'react-intl';

import { extentMap, getPowersimmLayerUrl } from '../../services/portfolios';
import { getPins, getBookmarks, styleIsoLayerField } from '../../services/maps';

import {
    LAYERS_GROUPS,
    allISO,
    getLayerFeaturesSidebarOrder,
    AUTOCREATE_BASIS_ACTION_ID,
} from '../../constants/portfolios';

import { ReactComponent as ExpandFullscreenIcon } from '../../icons/expand-fullscreen.svg';
import { ReactComponent as CollapseFullscreenIcon } from '../../icons/collapse-fullscreen.svg';
import basisIcon from '../../icons/trend.svg';
import { ReactComponent as EyeIcon } from '../../icons/eye.svg';
import { ReactComponent as EyeIconHidden } from '../../icons/eye_hidden.svg';

import MapDrawer from './MapDrawer';
import SideDrawer from '../utils/SideDrawer';
import useHistoryPush from '../../hooks/useHistoryPush';
import useAcquireArcGISToken from '../../hooks/useAcquireArcGISToken';
import { isoConfig } from '../../services/portfolios/isoConfig';

const MAP_LOADING_THRESHOLD_MS = 5000; // 10000 10 seconds

const PortfolioMap = ({ selected, mapExpanded, toggleMap, onPowersimLayerLoaded }) => {
    const map = useRef();
    const view = useRef();
    const basemapToggle = useRef();
    const searchWidget = useRef();
    const legendWidget = useRef();
    const mapDiv = useRef(null);
    const pinsLayer = useRef(null);
    const powersimmLayer = useRef();
    const allLayers = useRef(LAYERS_GROUPS);

    const filterDrawer = useDisclosure();
    const intl = useIntl();
    const historyPush = useHistoryPush();

    const [mapIsLoading, setMapIsLoading] = useState(true);
    const [legendIsVisible, setLegendIsVisible] = useState(false);

    // Custom hook to retrieve and register ArcGIS OAuth 2.0 token to view web map and feature layers
    const { arcGISOAuthToken, isAcquiring, isError } = useAcquireArcGISToken();

    useEffect(() => {
        if (arcGISOAuthToken && !isError && !isAcquiring) {
            loadMap();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arcGISOAuthToken, isAcquiring, isError]);

    useEffect(() => {
        extentMap(selected, powersimmLayer.current, view.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const [pins, setPins] = useState({
        data: [],
        loading: true,
        error: false,
    });

    const [bookmarks, setBookmarks] = useState({
        data: [],
        loading: true,
        error: false,
    });

    const pinImages = useMemo(() => {
        return {
            blue: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyMCAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMS40NzEgMjQuNDU1NEwxMS40NzE5IDI0LjQ1NDNDMTIuNjQ5IDIyLjk4MTEgMTQuNTg1NyAyMC40NTI0IDE2LjIzMDYgMTcuNzg0MUMxNy4wNTMgMTYuNDUwMSAxNy44MDg0IDE1LjA3MTcgMTguMzU5NiAxMy43NjYxQzE4LjkwNzMgMTIuNDY4NyAxOS4yNzA4IDExLjIwMzEgMTkuMjcwOCAxMC4xMDYzQzE5LjI3MDggNC45ODUyMyAxNS4xMTc2IDAuODMzOTg0IDkuOTk4NDQgMC44MzM5ODRDNC44NzczOSAwLjgzMzk4NCAwLjcyNjA3NCA0Ljk4NTMgMC43MjYwNzQgMTAuMTA2M0MwLjcyNjA3NCAxMS4yMDI0IDEuMDg2NjIgMTIuNDY3NSAxLjYzMTEyIDEzLjc2NTFDMi4xNzkwNSAxNS4wNzA4IDIuOTMwNTYgMTYuNDQ5MyAzLjc1MDQ3IDE3Ljc4MzVDNS4zOTAxIDIwLjQ1MTcgNy4zMjY3NSAyMi45ODA1IDguNTI2OTEgMjQuNDU2NkM5LjI4OTI3IDI1LjQwMzQgMTAuNzA5MiAyNS40MDMgMTEuNDcxIDI0LjQ1NTRaTTEyLjQyMjYgMTAuMTA2M0MxMi40MjI2IDExLjQ0MyAxMS4zMzUxIDEyLjUzMDUgOS45OTg0NCAxMi41MzA1QzguNjYxNzQgMTIuNTMwNSA3LjU3NDMyIDExLjQ0MyA3LjU3NDMyIDEwLjEwNjNDNy41NzQzMiA4Ljc2OTY1IDguNjYxNzQgNy42ODIyMyA5Ljk5ODQ0IDcuNjgyMjNDMTEuMzM1MSA3LjY4MjIzIDEyLjQyMjYgOC43Njk2NSAxMi40MjI2IDEwLjEwNjNaIiBmaWxsPSIjMDA0RDhDIiBzdHJva2U9IndoaXRlIi8+DQo8L3N2Zz4NCg==',
            orange: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyMCAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMS40NyAyNC40NTU0TDExLjQ3MDkgMjQuNDU0M0MxMi42NDggMjIuOTgxMSAxNC41ODQ3IDIwLjQ1MjQgMTYuMjI5NiAxNy43ODQxQzE3LjA1MiAxNi40NTAxIDE3LjgwNzQgMTUuMDcxNyAxOC4zNTg2IDEzLjc2NjFDMTguOTA2MyAxMi40Njg3IDE5LjI2OTggMTEuMjAzMSAxOS4yNjk4IDEwLjEwNjNDMTkuMjY5OCA0Ljk4NTIzIDE1LjExNjYgMC44MzM5ODQgOS45OTc0NiAwLjgzMzk4NEM0Ljg3NjQxIDAuODMzOTg0IDAuNzI1MDk4IDQuOTg1MyAwLjcyNTA5OCAxMC4xMDYzQzAuNzI1MDk4IDExLjIwMjQgMS4wODU2NCAxMi40Njc1IDEuNjMwMTUgMTMuNzY1MUMyLjE3ODA3IDE1LjA3MDggMi45Mjk1OSAxNi40NDkzIDMuNzQ5NSAxNy43ODM1QzUuMzg5MTMgMjAuNDUxNyA3LjMyNTc3IDIyLjk4MDUgOC41MjU5MyAyNC40NTY2QzkuMjg4MyAyNS40MDM0IDEwLjcwODIgMjUuNDAzIDExLjQ3IDI0LjQ1NTRaTTEyLjQyMTYgMTAuMTA2M0MxMi40MjE2IDExLjQ0MyAxMS4zMzQyIDEyLjUzMDUgOS45OTc0NiAxMi41MzA1QzguNjYwNzcgMTIuNTMwNSA3LjU3MzM0IDExLjQ0MyA3LjU3MzM0IDEwLjEwNjNDNy41NzMzNCA4Ljc2OTY1IDguNjYwNzcgNy42ODIyMyA5Ljk5NzQ2IDcuNjgyMjNDMTEuMzM0MiA3LjY4MjIzIDEyLjQyMTYgOC43Njk2NSAxMi40MjE2IDEwLjEwNjNaIiBmaWxsPSIjRkY2NzAwIiBzdHJva2U9IndoaXRlIi8+DQo8L3N2Zz4NCg==',
            green: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyMCAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMS40NzE5IDI0LjQ1NTRMMTEuNDcyOSAyNC40NTQzQzEyLjY1IDIyLjk4MTEgMTQuNTg2NyAyMC40NTI0IDE2LjIzMTYgMTcuNzg0MUMxNy4wNTM5IDE2LjQ1MDEgMTcuODA5MyAxNS4wNzE3IDE4LjM2MDUgMTMuNzY2MUMxOC45MDgyIDEyLjQ2ODcgMTkuMjcxOCAxMS4yMDMxIDE5LjI3MTggMTAuMTA2M0MxOS4yNzE4IDQuOTg1MjMgMTUuMTE4NiAwLjgzMzk4NCA5Ljk5OTQxIDAuODMzOTg0QzQuODc4MzcgMC44MzM5ODQgMC43MjcwNTEgNC45ODUzIDAuNzI3MDUxIDEwLjEwNjNDMC43MjcwNTEgMTEuMjAyNCAxLjA4NzYgMTIuNDY3NSAxLjYzMjEgMTMuNzY1MUMyLjE4MDAyIDE1LjA3MDggMi45MzE1NCAxNi40NDkzIDMuNzUxNDUgMTcuNzgzNUM1LjM5MTA4IDIwLjQ1MTcgNy4zMjc3MyAyMi45ODA1IDguNTI3ODggMjQuNDU2NkM5LjI5MDI1IDI1LjQwMzQgMTAuNzEwMSAyNS40MDMgMTEuNDcxOSAyNC40NTU0Wk0xMi40MjM1IDEwLjEwNjNDMTIuNDIzNSAxMS40NDMgMTEuMzM2MSAxMi41MzA1IDkuOTk5NDEgMTIuNTMwNUM4LjY2MjcyIDEyLjUzMDUgNy41NzUyOSAxMS40NDMgNy41NzUyOSAxMC4xMDYzQzcuNTc1MjkgOC43Njk2NSA4LjY2MjcyIDcuNjgyMjMgOS45OTk0MSA3LjY4MjIzQzExLjMzNjEgNy42ODIyMyAxMi40MjM1IDguNzY5NjUgMTIuNDIzNSAxMC4xMDYzWiIgZmlsbD0iIzVBQjAyNiIgc3Ryb2tlPSJ3aGl0ZSIvPg0KPC9zdmc+DQo=',
        };
    }, []);

    const populatePins = useCallback(
        async (pins) => {
            const [Graphic] = await loadModules(['esri/Graphic']);
            pins.forEach((pin) => {
                let imgSrc = pinImages[pin.data.pinType];

                if (!pin.data.point.type) {
                    pin.data.point.type = 'point';
                }

                const symbol = {
                    height: 18,
                    width: 14,
                    url: imgSrc,
                    type: 'picture-marker',
                };

                const g = new Graphic({
                    geometry: pin.data.point,
                    attributes: {
                        id: pin.id,
                    },
                    symbol: symbol,
                    visible: true,
                });

                pinsLayer.current.graphics.add(g);

                if (pin.description && pin.description.length > 0) {
                    let textSymbol = {
                        type: 'text',
                        color: 'black',
                        haloColor: 'white',
                        haloSize: '1px',
                        text: pin.description,
                        horizontalAlignment: 'center',
                        yoffset: -20,
                        font: {
                            size: 10,
                            family: 'Arial',
                        },
                    };

                    let l = new Graphic({
                        geometry: pin.data.point,
                        attributes: {
                            id: pin.id + '_label',
                            labelGraphic: true,
                        },
                        symbol: textSymbol,
                        visible: true,
                    });

                    pinsLayer.current.graphics.add(l);
                }
            });
        },
        [pinImages]
    );

    const fetchPins = useCallback(async () => {
        try {
            try {
                const data = await getPins();
                const formattedPins = data.map((pin) => ({ ...pin, data: JSON.parse(pin.data) }));
                setPins((prev) => ({ ...prev, data: formattedPins }));
                populatePins(formattedPins);
            } catch {
                return setPins((prev_1) => ({ ...prev_1, error: true }));
            }
        } finally {
            return setPins((prev_2) => ({ ...prev_2, loading: false }));
        }
    }, [populatePins]);

    const fetchBookmarks = useCallback(async () => {
        try {
            try {
                const data = await getBookmarks();
                const formattedBookmarks = data.map((bookmark) => ({ ...bookmark, data: JSON.parse(bookmark.data) }));
                setBookmarks((prev) => ({ ...prev, data: formattedBookmarks }));
            } catch {
                return setBookmarks((prev_1) => ({ ...prev_1, error: true }));
            }
        } finally {
            return setBookmarks((prev_2) => ({ ...prev_2, loading: false }));
        }
    }, []);

    const loadMap = () => {
        loadModules([
            'esri/WebMap',
            'esri/views/MapView',
            'esri/widgets/BasemapToggle',
            'esri/widgets/Search',
            'esri/layers/GraphicsLayer',
            'esri/widgets/Legend',
        ])
            .then(async ([WebMap, MapView, BasemapToggle, Search, GraphicsLayer, Legend]) => {
                map.current = new WebMap({
                    basemap: 'arcgis-topographic',
                    portalItem: {
                        // WebMap Id
                        id: process.env.REACT_APP_ARCGIS_PORTAL_ID,
                    },
                });

                setTimeout(() => {
                    const loadStatus = map?.current?.loadStatus;
                    // not-loaded — the resource has not been asked to load its metadata and its state isn't properly initialized.
                    // loading — the resource is in the process of loading its metadata asynchronously.
                    // failed — the resource failed to load its metadata (for example, due to network outage, or the operation was cancelled, and so on.) The error encountered is available from the loadError property.
                    // loaded — the resource successfully loaded its metadata and its state is properly initialized.
                    if (loadStatus === 'failed') {
                        console.error('PowerSIMM Map loading failed, please refresh the page...');
                    }
                    if (loadStatus === 'loading') {
                        map.current.cancelLoad();
                        console.error(
                            'PowerSIMM Map loading took too long, cancelling load, please refresh the page...'
                        );
                    }

                    setMapIsLoading(false);
                }, MAP_LOADING_THRESHOLD_MS);

                view.current = new MapView({
                    container: mapDiv.current,
                    map: map.current,
                    center: [-70, 25],
                    zoom: 4,
                });

                pinsLayer.current = new GraphicsLayer({
                    id: 'map-pins-layer',
                    title: 'Map Pins',
                    legendTitle: 'Map Pins',
                });
                map.current.add(pinsLayer.current);

                fetchBookmarks();
                fetchPins();

                basemapToggle.current = new BasemapToggle({
                    view: view.current,
                    nextBasemap: 'arcgis-imagery',
                });

                searchWidget.current = new Search({
                    view: view.current,
                });

                legendWidget.current = new Legend({
                    view: view.current,
                    visible: false,
                    hideLayersNotInCurrentView: true,
                    style: {
                        type: 'classic',
                    },
                });

                view.current.on('immediate-click', (event) => {
                    onMapViewImmediateClick(event);
                });

                map.current.layers.on('change', (event) => {
                    onMapLayersChange(event);
                });

                view.current.on('layerview-create', (event) => {
                    onMapViewCreateLayer(event);
                });

                view.current.popup.watch('selectedFeature', (graphic) => {
                    graphicPopupTemplateActions(graphic);
                });

                view.current.popup.viewModel.on('trigger-action', (event) => {
                    onMapViewTriggerEventAction(event);
                });

                if (powersimmLayer.current === undefined) {
                    await addPowersimmLayer();
                }

                // add ISO Feature Layers by layer service url, ISO layers are no longer stored in ArcGIS map
                addISOLayers();

                view.current.popup.autoOpenEnabled = false;
                view.current.popup.defaultPopupTemplateEnabled = true;
                view.current.ui.add(basemapToggle.current, 'bottom-right');
                view.current.ui.add(searchWidget.current, 'top-left');
                view.current.ui.add(legendWidget.current, 'top-right');
            })
            .catch((err) => {
                console.error('Map or Layer error occured...');
                if (err) console.error(err);
                setMapIsLoading(false);
            });
    };

    const onPinCreateSuccess = (pin) => {
        const formattedPin = { ...pin, data: JSON.parse(pin.data) };
        setPins((prev) => ({ ...prev, data: [...prev.data, formattedPin] }));
        populatePins([formattedPin]);
    };

    const onPinDeleteSuccess = (id) => {
        const graphic = pinsLayer.current.graphics.find((i) => i.attributes.id === id);
        if (graphic) {
            pinsLayer.current.graphics.remove(graphic);
        }

        const label = pinsLayer.current.graphics.find((i) => i.attributes.id === id + '_label');
        if (label) {
            pinsLayer.current.graphics.remove(label);
        }

        setPins((prev) => ({ ...prev, data: prev.data.filter((e) => e.id !== id) }));
    };

    const onBookmarkCreateSuccess = (bookmark) => {
        const formattedBookmark = { ...bookmark, data: JSON.parse(bookmark.data) };
        setBookmarks((prev) => ({ ...prev, data: [...prev.data, formattedBookmark] }));
    };

    const onBookmarkDeleteSuccess = (id) => {
        setBookmarks((prev) => ({ ...prev, data: prev.data.filter((e) => e.id !== id) }));
    };

    const onMapViewImmediateClick = (event) => {
        view.current.hitTest(event).then((res) => {
            const graphicsClicked = res.results
                .map((e) => {
                    if (e.graphic.geometry) return e.graphic;
                    return null;
                })
                .filter((e) => e);
            if (graphicsClicked.length > 0) {
                view.current.popup.open({
                    features: graphicsClicked,
                    location: graphicsClicked[0].geometry,
                });
            }
        });
    };

    const onMapViewCreateLayer = (event) => {
        const createLayer = event.layer;
        const numberOfLayers = map.current.layers?.length || 3;
        // The index location for placing the layer in ArcGIS map view. The bottom-most layer has an index of 0.
        const orderPinsLayerIdx = numberOfLayers - 1;
        const orderPowersimmLayerIdx = numberOfLayers - 2;

        // map pins should be the first top layer
        if (createLayer.id === 'map-pins-layer') {
            map.current.reorder(createLayer, orderPinsLayerIdx);
        }

        // custom layer should be top second
        if (createLayer.isPowerSimmLayer) {
            if (powersimmLayer.current === undefined) {
                powersimmLayer.current = createLayer;
                onPowersimLayerLoaded(createLayer, map.current, view.current);
                extentMap(selected, powersimmLayer.current, view.current);
                map.current.reorder(createLayer, orderPowersimmLayerIdx);
            }
        }

        // ISO layers should be third top layers
        if (createLayer.isIsoLayer) {
            const idx = orderPowersimmLayerIdx - createLayer.idxIsoLayer - 1;
            map.current.reorder(createLayer, idx);
        }

        const layerIndex = allLayers.current.findIndex((elem) => createLayer.id === elem.id);
        if (layerIndex > -1) {
            allLayers.current[layerIndex].fullyLoaded = true;
        }

        const notLoadedArcGISMapLayers = allLayers.current.filter(
            (layer) => !layer.fullyLoaded && !layer.fakeNode && !layer.isPowerSimmLayer
        );

        if (notLoadedArcGISMapLayers.length < 1) {
            // Finished loading all ArcGIS map's layers successfully
            setMapIsLoading(false);
        }
    };

    const graphicPopupTemplateActions = (graphic) => {
        if (graphic == null) return;

        const graphicTemplate = graphic.getEffectivePopupTemplate();
        if (graphicTemplate) {
            if (allISO.includes(graphic.layer.title)) {
                graphicTemplate.actions = [
                    {
                        id: AUTOCREATE_BASIS_ACTION_ID,
                        title: intl.formatMessage({ id: 'basis_configurator_create_basis_heading' }),
                        image: basisIcon,
                    },
                ];
            } else {
                graphicTemplate.actions = [];
            }
        }
    };

    const onMapViewTriggerEventAction = (event) => {
        if (event.action.id === AUTOCREATE_BASIS_ACTION_ID) {
            const selectedFeature = view.current.popup.viewModel?.selectedFeature;
            let isoRto = selectedFeature?.layer.title?.includes('CAISO') ? 'CAISO' : selectedFeature.layer.title;

            if (isoRto === 'ISO-NE') isoRto = 'ISONE'; // The correct string that BE supports is ISONE

            const node =
                selectedFeature?.attributes.NODE ||
                view.current.popup.viewModel?.selectedFeatureViewModel?.formattedAttributes?.global?.NODE;

            const lat =
                selectedFeature?.attributes.LAT ||
                view.current.popup.viewModel?.selectedFeatureViewModel?.formattedAttributes?.global?.LAT;

            const long =
                selectedFeature?.attributes.LONG ||
                view.current.popup.viewModel?.selectedFeatureViewModel?.formattedAttributes?.global?.LONG;

            const search = new URLSearchParams({
                node,
                isoRto,
                lat,
                long,
            });

            if (node && isoRto) {
                historyPush({ pathname: '/basis-configurator', search });
            }
        }
    };

    const onMapLayersChange = (event) => {
        if (event.added.length > 0) {
            addLayers([...event.added]);
        }
    };

    const addLayers = (layers) => {
        const oldLayers = allLayers.current.map((elem) => elem.id);
        const missingLayers = layers
            .filter((elem) => !oldLayers.includes(elem.id))
            .map((elem) => {
                elem.layerOrder =
                    elem.layerOrder !== undefined ? elem.layerOrder : getLayerFeaturesSidebarOrder(elem.title);
                return elem;
            });

        if (missingLayers.length > 0) {
            allLayers.current = [...allLayers.current, ...missingLayers];
        }

        const layerLegendInfos = allLayers.current.map((layer) => {
            const legendTitle = layer.legendTitle ?? layer.title;

            return {
                layer: layer,
                title: legendTitle,
            };
        });

        legendWidget.current.layerInfos = layerLegendInfos;
    };

    const addPowersimmLayer = async () => {
        try {
            const url = await getPowersimmLayerUrl();

            loadModules(['esri/layers/FeatureLayer']).then(([FeatureLayer]) => {
                // powersimm_aodmulti_demobeta9 color: "#149ece" RGB 20 158 206 "rgba(20, 158, 206, 0.5)"
                // Set a basic symbol on a layer to visualize all custom features the same way
                const itemsRenderer = {
                    type: 'simple', // autocasts as new SimpleRenderer()
                    symbol: {
                        type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
                        size: '24px',
                        color: 'rgba(20, 158, 206, 0.5)',
                        outline: {
                            // autocasts as new SimpleLineSymbol()
                            width: '3px',
                            color: 'rgba(255, 255, 255, 0.8)',
                        },
                    },
                };

                const powerSimmCustomLayer = new FeatureLayer({
                    url: url,
                    renderer: itemsRenderer,
                    // legendTitle is our custom property, not ArcGIS layer property.
                    legendTitle: 'Custom PowerSIMM Layer',
                    // isPowerSimmLayer is our custom property, not ArcGIS layer property.
                    isPowerSimmLayer: true,
                    // layerOrder is our custom property, not ArcGIS layer property.
                    // layerOrder is used to determine order display of show/hide layer groups in FE Features Sidebar Layers tab selection section
                    // PowerSimmLayer checkbox should be at the top, hence -1.
                    // use map.current.reorder(layer, index) to reorder layers z-index in the map view
                    layerOrder: -1,
                });

                if (!powerSimmCustomLayer.title) {
                    powerSimmCustomLayer.title = 'Custom PowerSIMM Layer';
                }

                map.current.add(powerSimmCustomLayer);
                powerSimmCustomLayer.load().catch((err) => {
                    console.error(`Custom PowerSIMM Layer load error: ${err?.message || err?.name}`);
                });
            });
        } catch (err) {
            console.error(`Custom PowerSIMM Layer creation error: ${err?.message || err?.name}`);
        }
    };

    const addISOLayers = () => {
        loadModules(['esri/layers/FeatureLayer', 'esri/popup/FieldInfo', 'esri/PopupTemplate']).then(
            ([FeatureLayer, FieldInfo, PopupTemplate]) => {
                isoConfig.map((iso, idx) => {
                    // Create and add iso layers based on Config file layer url reference to https://services3.arcgis.com/nQ5O3IT5kOSeZVQU/arcgis/rest/services
                    const isoLayer = new FeatureLayer({
                        isIsoLayer: true,
                        idxIsoLayer: idx,
                        title: iso.mapLayerTitle,
                        url: iso.mapLayerUrl,
                        visible: false,
                        legendTitle: `ISO Market Points - ${iso.mapLayerTitle}`,
                    });

                    map.current.add(isoLayer);

                    const search = {
                        layer: isoLayer,
                        searchFields: ['NODE'],
                        displayField: 'NODE',
                        exactMatch: false,
                        name: iso.mapLayerTitle + ': NODE',
                        enableSuggestions: true,
                    };
                    addMapSearchWidgetSources([search]);

                    // In order to have feature layer fields for popup we need to make sure layer is loaded first
                    isoLayer
                        .load()
                        .then(() => {
                            const defaultRendererField = iso.defaultRendererField;
                            // Make all ISO layers visible in map by default, except for 'CAISO' layer
                            let isVislble = true;

                            if (iso.name === 'CAISO') {
                                // 'CAISO w/ EIM' layer has all 'CAISO' layer's nodes as well, so we will hide 'CAISO' layer by default
                                isVislble = false;
                            }
                            const allFields = isoLayer.fields;

                            const isoConfigFields = allFields
                                ?.map((field) => {
                                    return new FieldInfo({ fieldName: field.name, label: field.alias, visible: true });
                                })
                                ?.sort((a, b) => {
                                    const upperCasedA = a.fieldName?.toUpperCase();
                                    const upperCasedB = b.fieldName?.toUpperCase();
                                    // We want NODE, LAT and LONG fields to be shown on top of the popup
                                    if (upperCasedB === 'NODE' || upperCasedB === 'LAT' || upperCasedB === 'LONG')
                                        return 1;
                                    // Order by field name in popup
                                    return upperCasedA >= upperCasedB ? 0 : -1;
                                })
                                ?.filter((f) => f.fieldName?.toUpperCase() !== 'OBJECTID'); // Remove OBJECT ID from popup display

                            const popupTemplate = new PopupTemplate({
                                title: '{NODE}',
                                content: [
                                    {
                                        type: 'fields',
                                        description: iso.mapLayerTitle + ' ' + iso.version,
                                    },
                                ],
                                fieldInfos: isoConfigFields,
                            });
                            isoLayer.popupTemplate = popupTemplate;

                            // Dynamically style ISO layer deafult field
                            if (defaultRendererField) {
                                styleIsoLayerField(view.current, iso, isoLayer, isVislble, defaultRendererField);
                            }
                        })
                        .catch((err) => {
                            console.error(
                                `${iso?.mapLayerTitle || 'Not Found'} ISO Layer load error: ${
                                    err?.message || err?.name
                                }`
                            );
                        });

                    return isoLayer;
                });
            }
        );
    };

    // Feature Layers that are coming from ArcGIS web map should have their search setup in web map settings.
    // Feature Layers that are dynamically retrieved from ArcGIS services and then added to the FE map
    // have to be added to searchWidget search sources options in code.
    const addMapSearchWidgetSources = (searchSourcesArray) => {
        if (searchWidget?.current == null || !Array.isArray(searchSourcesArray)) return;

        const existingSources = searchWidget.current.sources?.items;

        if (Array.isArray(existingSources) && existingSources.length > 0) {
            searchWidget.current.sources = [...existingSources, ...searchSourcesArray];
        } else {
            searchWidget.current.sources = [...searchSourcesArray];
        }
    };

    return (
        <MapContainer isExpanded={mapExpanded}>
            <MapComponent ref={mapDiv} />

            <ActionButtonContainer>
                {selected.properties.parentId && (
                    <Button
                        leftIcon={mapExpanded ? <CollapseFullscreenIcon /> : <ExpandFullscreenIcon />}
                        size="sm"
                        type="button"
                        variant="map"
                        onClick={toggleMap}
                    >
                        <FormattedMessage
                            id={
                                mapExpanded
                                    ? 'portfolio_map_expand_active_button'
                                    : 'portfolio_map_expand_inactive_button'
                            }
                        />
                    </Button>
                )}

                <Button
                    title="Open Map Layers Features Sidebar"
                    type="button"
                    variant="map"
                    mx="10px"
                    onClick={filterDrawer.onOpen}
                    isDisabled={!view.current}
                    size="sm"
                >
                    <FormattedMessage id="portfolio_map_features" />
                </Button>

                <IconButton
                    leftIcon={legendIsVisible ? <EyeIconHidden /> : <EyeIcon />}
                    title={intl.formatMessage({
                        id: legendIsVisible ? 'portfolio_map_hide_legend_button' : 'portfolio_map_show_legend_button',
                    })}
                    type="button"
                    variant="map"
                    onClick={(e) => {
                        const isVisible = legendWidget.current.visible;
                        legendWidget.current.visible = !isVisible;
                        setLegendIsVisible(!isVisible);
                    }}
                    isDisabled={!view.current}
                    isLoading={mapIsLoading}
                    size="sm"
                />
            </ActionButtonContainer>

            {filterDrawer.isOpen && (
                <SideDrawer
                    isOpen
                    size="map_md"
                    onClose={filterDrawer.onClose}
                    header={<FormattedMessage id="portfolio_map_features" />}
                    variant="mapDrawer"
                    trapFocus={false}
                    content={
                        <MapDrawer
                            layers={allLayers.current}
                            map={map.current}
                            view={view.current}
                            pins={pins}
                            bookmarks={bookmarks}
                            pinsLayer={pinsLayer.current}
                            onPinCreateSuccess={onPinCreateSuccess}
                            onPinDeleteSuccess={onPinDeleteSuccess}
                            onBookmarkCreateSuccess={onBookmarkCreateSuccess}
                            onBookmarkDeleteSuccess={onBookmarkDeleteSuccess}
                        />
                    }
                    placement="left"
                />
            )}
        </MapContainer>
    );
};

const ActionButtonContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 15px;
`;

const MapContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'isExpanded' })`
    position: relative;
    min-height: ${(props) => (props.isExpanded ? 'calc(100vh - 120px)' : '300px')};
    overflow: ${(props) => props.isExpanded && 'hidden'};

    &:focus-within {
        &:after {
            content: '';
            box-sizing: border-box;
            position: absolute;
            z-index: 999;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            outline: auto 10px var(--chakra-ring-color);
            outline-offset: -3px;
            pointer-events: none;
            overflow: hidden;
        }
    }

    .esri-ui-top-left {
        display: flex;
        flex-direction: row;
    }

    .esri-search {
        margin-left: 10px;
    }

    .esri-legend {
        margin-top: 36px;
    }
`;

const MapComponent = styled.div`
    height: 100%;

    .esri-view-surface--inset-outline:focus {
        &:after {
            outline: none;
        }
    }

    .esri-zoom .esri-widget--button:first-of-type {
        margin-bottom: 2px;
    }
`;

export default PortfolioMap;
