import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setLoading } from '../store/permissions/permissionsSlice';
import { getUserPermissions } from '../store/permissions/permissionsApi';
import { POWERSIMM_ADMIN } from '../constants/permissions';

const useUserPermissions = () => {
    const dispatch = useDispatch();
    const { permissions } = useSelector((state) => state.permissions);

    const retrieveUserConnectionPermissions = useCallback(() => {
        const { connectionId, environmentId, bookId } = axios.defaults.headers;
        if (bookId && environmentId && connectionId) {
            dispatch(setLoading(true));
            dispatch(getUserPermissions()).finally(() => dispatch(setLoading(false)));
        }
    }, [dispatch]);

    const isAdmin = useMemo(() => permissions.includes(POWERSIMM_ADMIN), [permissions]);

    return useMemo(
        () => ({
            retrieveUserConnectionPermissions,
            isAdmin,
        }),
        [retrieveUserConnectionPermissions, isAdmin]
    );
};

export default useUserPermissions;
