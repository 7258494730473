import { FormattedMessage } from 'react-intl';
import SimpleModal from '../modal/SimpleModal';
import { Button, Flex, Text, ButtonGroup } from '@chakra-ui/react';
import useHistoryPush from '../../hooks/useHistoryPush';

const StudySubmittedModal = ({ studyName, onClose }) => {
    const historyPush = useHistoryPush();

    const handleGoToDashboard = () => {
        historyPush('/');
    };

    return (
        <>
            <SimpleModal
                scrollBehavior="inside"
                isOpen
                onClose={onClose}
                size={'3xl'}
                headerContent={
                    <Flex alignItems="center">
                        <FormattedMessage id="run_a_study_study_creation_success" />
                    </Flex>
                }
                children={
                    <Text color="black" mx={2}>
                        Study Name: {studyName}
                    </Text>
                }
                footerContent={
                    <ButtonGroup ml="auto" spacing="6">
                        <Button variant="secondary" textTransform="capitalize" onClick={handleGoToDashboard} size="sm">
                            Go To Dashboard
                        </Button>
                        <Button variant="primary" textTransform="capitalize" onClick={onClose} size="sm">
                            <FormattedMessage id="common_close" />
                        </Button>
                    </ButtonGroup>
                }
            />
        </>
    );
};

export default StudySubmittedModal;
