import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Box,
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
} from '@chakra-ui/react';

import { Form, Formik } from 'formik';

import useCommonToast from '../../hooks/useCommonToast';

import {
    getCommoditiesDropdownOptions,
    createCommodity,
    getCommodityById,
    updateCommodity,
} from '../../services/commodity';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';

import SimpleGridSkeleton from '../utils/SimpleGridSkeleton';
import Error from '../utils/Error';
import { isSubmitDisabled } from '../../services/utils';

const CommodityModal = ({ basisId, onFinish, onSelectNewCommodity, commodityId, ...props }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();

    const [commodityDropdownOptions, setCommodityDropdownOptions] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [initialValues, setInitialValues] = useState({
        loading: false,
        error: false,
        data: {
            description: '',
            commodityTypeId: '',
            locationId: '',
            peakPeriodId: '',
            quantityTypeId: '',
        },
    });

    const hasCommodity = commodityId != null;

    const fetchCommodity = useCallback((id) => {
        return getCommodityById(id)
            .then((data) => {
                const formatData = { ...data };
                Object.keys(data).forEach((key) => {
                    formatData[key] = data[key] || '';
                });

                setInitialValues((prev) => ({ ...prev, error: false, data: formatData }));
            })
            .catch(() => setInitialValues((prev) => ({ ...prev, error: true })))
            .finally(() => setInitialValues((prev) => ({ ...prev, loading: false })));
    }, []);

    const fetchCommodityDropdownOptions = useCallback(() => {
        return getCommoditiesDropdownOptions()
            .then((data) => setCommodityDropdownOptions((prev) => ({ ...prev, error: false, data: data })))
            .catch(() => setCommodityDropdownOptions((prev) => ({ ...prev, error: true })))
            .finally(() => setCommodityDropdownOptions((prev) => ({ ...prev, loading: false })));
    }, []);

    useEffect(() => {
        fetchCommodityDropdownOptions();
        if (hasCommodity) {
            setInitialValues((prev) => ({ ...prev, loading: true }));
            fetchCommodity(commodityId);
        }
    }, [fetchCommodityDropdownOptions, commodityId, fetchCommodity, hasCommodity]);

    const formLabels = {
        description: intl.formatMessage({ id: 'add_commodity_modal_name' }),
        commodityTypeId: intl.formatMessage({ id: 'add_commodity_modal_commodity_type' }),
        locationId: intl.formatMessage({ id: 'add_commodity_modal_location' }),
        peakPeriodId: intl.formatMessage({ id: 'add_commodity_modal_peak_period' }),
        quantityTypeId: intl.formatMessage({ id: 'add_commodity_modal_quantity_type' }),
    };

    const onFormSubmit = async ({ description, commodityTypeId, locationId, peakPeriodId, quantityTypeId }) => {
        const successMessageUpdate = intl.formatMessage({ id: 'edit_commodity_modal_success' });
        const successMessage = intl.formatMessage({ id: 'add_commodity_modal_create_success' });

        const payload = {
            commodityTypeId,
            locationId: locationId || null,
            peakPeriodId: peakPeriodId || null,
            quantityTypeId: quantityTypeId || null,
            description,
        };

        if (commodityId) {
            await updateCommodity(commodityId, payload);
            toast(successMessageUpdate);
        } else {
            const newCommodity = await createCommodity(payload);
            onSelectNewCommodity(newCommodity.data.id, commodityTypeId);
            toast(successMessage);
        }

        props.onClose();
        onFinish();
    };

    const validateCommodityName = (value) => {
        const trimmed = value.trim();

        if (!trimmed) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Commodity name' });
        }

        if (trimmed.length > 50) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Commodity name', lengthRule: '50 characters or less' }
            );
        }
    };

    const validateRequiredField = (value, label) => {
        let error;
        if (value === '') {
            error = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }
        return error;
    };

    return (
        <Modal {...props}>
            <ModalOverlay />

            <ModalContent pt={3}>
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id={hasCommodity ? 'common_edit' : 'basis_configurator_add_new_commodity'} />{' '}
                        {initialValues.data.description}
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik enableReinitialize initialValues={initialValues.data} onSubmit={onFormSubmit}>
                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <ModalBody>
                                {commodityDropdownOptions.loading || initialValues.loading ? (
                                    <SimpleGridSkeleton rows={9} cols={1} />
                                ) : commodityDropdownOptions.error || initialValues.error ? (
                                    <Box m={6}>
                                        <Error
                                            primaryId="common_error"
                                            secondaryId="common_loading_error"
                                            additionalText="commodity"
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <InputFormikField
                                            name="description"
                                            type="text"
                                            label={formLabels.description}
                                            validate={validateCommodityName}
                                            isRequired
                                            isFastField
                                        />

                                        <SimpleGrid columns={2} mt={5} spacingX={3} spacingY={5}>
                                            <ComboboxFormikField
                                                id="commodityTypeId"
                                                name="commodityTypeId"
                                                label={formLabels.commodityTypeId}
                                                options={commodityDropdownOptions.data.commodityTypes}
                                                validate={(value) =>
                                                    validateRequiredField(value, formLabels.commodityTypeId)
                                                }
                                                labelKey="description"
                                                valueKey="id"
                                                isRequired
                                                isFastField
                                                isDisabled={hasCommodity} // commodityTypeId combobox should be disabled when editing
                                            />
                                            <ComboboxFormikField
                                                id="locationId"
                                                name="locationId"
                                                label={formLabels.locationId}
                                                options={commodityDropdownOptions.data.locations}
                                                labelKey="description"
                                                valueKey="id"
                                                isFastField
                                            />
                                            <ComboboxFormikField
                                                id="peakPeriodId"
                                                name="peakPeriodId"
                                                label={formLabels.peakPeriodId}
                                                options={commodityDropdownOptions.data.peakPeriods}
                                                labelKey="description"
                                                valueKey="id"
                                                isFastField
                                            />
                                            <ComboboxFormikField
                                                id="quantityTypeId"
                                                name="quantityTypeId"
                                                label={formLabels.quantityTypeId}
                                                options={commodityDropdownOptions.data.quantityTypes}
                                                labelKey="description"
                                                valueKey="id"
                                                isFastField
                                            />
                                        </SimpleGrid>
                                    </>
                                )}
                            </ModalBody>

                            <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                <Box ml="auto">
                                    <Button variant="secondary" onClick={props.onClose}>
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id={hasCommodity ? 'common_back' : 'common_cancel'} />
                                        </Box>
                                    </Button>

                                    <Button
                                        type="submit"
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        ml={3}
                                        textTransform="capitalize"
                                    >
                                        <FormattedMessage id={hasCommodity ? 'common_update' : 'common_continue'} />
                                    </Button>
                                </Box>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default CommodityModal;
