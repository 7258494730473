import styled from '@emotion/styled/macro';
import { Box } from '@chakra-ui/react';

export const StyledStandaloneIconWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--chakra-radii-md);
    min-width: 40px;
    min-height: 40px;
`;

export default StyledStandaloneIconWrapper;
