import { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDisclosure } from '@chakra-ui/react';

import {
    getBasisScalar,
    modifyScalar,
    deleteAllScalarData,
    addScalarRows,
    isFilterApplied,
    deleteScalerRows,
    multisortPairs,
    validateScalar,
} from '../../services/basis';
import { getValidRowsWithDates } from '../../services/grid';

import SideDrawer from '../utils/SideDrawer';
import BasisFilters from '../utils/BasisFilters';
import FiltersButton from '../utils/FiltersButton';

import SubItemData from '../itemData/SubItemData';

const ScalarTab = ({
    tab,
    name,
    options,
    basis,
    filters,
    onFiltersChange,
    item,
    onLoadingStart,
    onLoadingEnd,
    actionBarSlot,
}) => {
    const intl = useIntl();
    const filterDrawer = useDisclosure();

    const basisLabel = intl.formatMessage({ id: 'basis_title' });

    const gridResource = useMemo(() => {
        return {
            create: (rows) => addScalarRows(basis.basisId, rows),
            read: (pagination) =>
                getBasisScalar(basis.basisId, {
                    ...pagination,
                    startDateFrom: filters.startDateFrom || '2010-02-25T15:31:30.555Z',
                    startDateTo: filters.startDateTo || '2022-02-25T15:31:30.555Z',
                }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate']);
                return modifyScalar(validRows);
            },
            delete: (rows) => {
                const data = rows.map((elem) => elem.scalarId);
                return deleteScalerRows(basis.basisId, data);
            },
            deleteAll: () => deleteAllScalarData(basis.basisId),
            validate: (rows) => validateScalar(rows),
        };
    }, [basis.basisId, filters.startDateFrom, filters.startDateTo]);

    const columns = useMemo(
        () => [
            {
                field: 'startDate',
                type: 'date',
                headerName: intl.formatMessage({ id: 'basis_tabs_start_date' }),
                initialSort: 'asc',
                initialSortIndex: 1,
                cellEditorParams: { required: true },
            },
            {
                field: 'endDate',
                type: 'date',
                headerName: intl.formatMessage({ id: 'basis_tabs_end_date' }),
                cellEditorParams: { required: true },
            },
            {
                type: 'checkbox',
                field: 'fullDestination',
                headerName: intl.formatMessage({ id: 'basis_tabs_full_destination' }),
            },
            {
                field: 'minValue',
                type: 'number',
                headerName: intl.formatMessage({ id: 'basis_tabs_min_value' }),
            },
            {
                field: 'maxValue',
                type: 'number',
                headerName: intl.formatMessage({ id: 'basis_tabs_max_value' }),
            },
            {
                field: 'meanValue',
                type: 'number',
                headerName: intl.formatMessage({ id: 'basis_tabs_mean_value' }),
            },
            {
                field: 'stdDev',
                type: 'number',
                headerName: intl.formatMessage({ id: 'basis_tabs_std_dev' }),
            },
            {
                field: 'peakPeriodId',
                type: 'select',
                cellEditorParams: { options: options.peakPeriods, required: true },
                headerName: intl.formatMessage({ id: 'basis_tab_peak_periods' }),
                minWidth: 180,
                initialSort: 'asc',
                initialSortIndex: 2,
            },
            {
                field: 'statUnitId',
                type: 'select',
                cellEditorParams: { options: options.statUnits, required: true },
                headerName: intl.formatMessage({ id: 'basis_tabs_stat_unit' }),
                minWidth: 180,
            },
        ],
        [intl, options.peakPeriods, options.statUnits]
    );

    const onApplyFilters = (newFilters) => {
        filterDrawer.onClose();
        onFiltersChange(newFilters);
    };

    return (
        <>
            <SubItemData
                actionBarSlot={() => (
                    <>
                        {actionBarSlot && actionBarSlot}

                        <FiltersButton
                            mb={{ base: 4, xl: 0 }}
                            mr={4}
                            hasIndicator={isFilterApplied(item)}
                            onClick={filterDrawer.onOpen}
                        />
                    </>
                )}
                tab={tab}
                name={name}
                onLoadingStart={onLoadingStart}
                onLoadingEnd={onLoadingEnd}
                gridResource={gridResource}
                columns={columns}
                addDataColumns={columns}
                multisortPairs={multisortPairs}
                primaryKeyName="scalarId"
            />

            {filterDrawer.isOpen && (
                <SideDrawer
                    isOpen
                    onClose={filterDrawer.onClose}
                    header={<FormattedMessage id="common_filter_heading" values={{ item: basisLabel }} />}
                    content={<BasisFilters onApplyFilters={onApplyFilters} item={item} />}
                    placement="right"
                />
            )}
        </>
    );
};

export default ScalarTab;
