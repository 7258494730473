import { useCallback, useState, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDisclosure } from '@chakra-ui/react';

import { GRID_KEYS } from '../../services/basis';
import { exportItemData } from '../../services/items';

import SectionHint from './SectionHint';
import DownloadModal from '../utils/DownloadModal';
import SecondaryButton from '../utils/SecondaryButton';
import UploadButton from '../itemData/UploadButton';
import DartSpreadTab from '../basis/DartSpreadTab';

import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

const DartSpreadSection = ({ options, basis, basisList, updateBasis }) => {
    const downloadModal = useDisclosure();

    const [key, setKey] = useState(0);

    const refreshTabs = useCallback(() => setKey((prev) => prev + 1), []);

    const onDownloadData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const payload = {
                sorting: sortOrder[GRID_KEYS.basisConfiguratorDartSpread],
                exportTemplate,
            };

            return exportItemData(`/basis/${basis.basisId}/export-dart-spread`, payload);
        },
        [basis.basisId]
    );

    return (
        <Fragment key={key}>
            <SectionHint hintHeader="hint_heading_dart_spread" hintText="hint_text_dart_spread" />

            <DartSpreadTab
                actionBarSlot={
                    <>
                        <SecondaryButton
                            leftIcon={<DownloadIcon />}
                            w="auto !important"
                            mr={2}
                            mb={{ base: 4, xl: 0 }}
                            size="sm"
                            type="button"
                            variant="secondary"
                            onClick={downloadModal.onOpen}
                        >
                            <FormattedMessage id="common_download_btn_tooltip_and_label" />
                        </SecondaryButton>

                        <UploadButton
                            type="dart-spread"
                            itemId={basis.basisId}
                            hasLabel={true}
                            mr={4}
                            mb={{ base: 4, xl: 0 }}
                            w="auto !important"
                            onAfterUploadChange={refreshTabs}
                            hideTreatWarningsAsErrors
                        />
                    </>
                }
                name={GRID_KEYS.basisConfiguratorDartSpread}
                options={options}
                basis={basis}
                basisList={basisList}
                updateBasis={updateBasis}
                additionalButtons={true}
            />

            {downloadModal.isOpen && (
                <DownloadModal
                    isOpen
                    onClose={downloadModal.onClose}
                    onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                    onDownloadTemplate={() => onDownloadData(true)}
                    bodyMessage={<FormattedMessage id="dart_spread_download_modal_explanation" />}
                />
            )}
        </Fragment>
    );
};

export default DartSpreadSection;
