import { useCallback, useMemo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useField, useFormikContext } from 'formik';
import { Box, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';

import { ISO_RTO_OPTIONS, DARTM_OPTIONS, DARTM_OPTIONS_CAISO, CAISO_OPTION } from '../../services/utils';
import { formatLookupValues } from '../../services/spotPrice';
import { getElectricNodeListData } from '../../services/traverseApi';

import SpotPriceInputField from './SpotPriceInputField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import SpotPriceLookupValuesField from '../spotPrice/SpotPriceLookupValuesField';

import LoadingModal from '../modal/LoadingModal';

import useAutoformMetadata from '../autoform/hooks/useAutoformMetadata';
import useAutoformTableMetadata from '../autoform/hooks/useAutoformTableMetadata';

const SpotPriceFields = ({ mode = 'edit', setInitialValues, getInputFormName }) => {
    const { hierarchicalTables } = useAutoformMetadata();

    const tableId = hierarchicalTables.level1[0]?.id;
    const { selectedItem, dropdownOptions, sortedColumns } = useAutoformTableMetadata(tableId);
    const showLayoutGaps = useBreakpointValue({ base: false, xl: true });
    const { values, dirty, setValues, setFieldValue } = useFormikContext();
    const [{ value: inputTableFieldValue }] = useField(getInputFormName(tableId, selectedItem, 'INPUTTABLEMAPIDTABLE'));
    const [{ value: lookupIdValues }] = useField(getInputFormName(tableId, selectedItem, 'LOOKUPIDVALUES'));
    const [isoRto, setIsoRto] = useState('');
    const intl = useIntl();

    const inputTableColumn = useMemo(
        () => sortedColumns.find((col) => col.name === 'INPUTTABLEMAPIDTABLE'),
        [sortedColumns]
    );
    const spotPriceDropdownOptions = useMemo(
        () => dropdownOptions && dropdownOptions[inputTableColumn.id],
        [dropdownOptions, inputTableColumn]
    );
    const TRAVERSE_INPUT_TABLE_ID = useMemo(
        () => spotPriceDropdownOptions?.find((option) => option.text === 'Traverse')?.value,
        [spotPriceDropdownOptions]
    );

    const showTraverseOptions = useMemo(
        () => inputTableFieldValue === TRAVERSE_INPUT_TABLE_ID,
        [inputTableFieldValue, TRAVERSE_INPUT_TABLE_ID]
    );

    const [electricNodeList, setElectricNodeList] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const formLabels = useMemo(
        () => ({
            isoRto: intl.formatMessage({ id: 'spot_price_iso_rto' }),
            electricNode: intl.formatMessage({ id: 'spot_price_electric_node' }),
            dartm: intl.formatMessage({ id: 'spot_price_dartm' }),
            lookupValues: intl.formatMessage({ id: 'spot_price_lookup_values' }),
        }),
        [intl]
    );

    useEffect(() => {
        if (showTraverseOptions && !dirty) {
            const { isoRto, electricNode, dartm } = formatLookupValues(lookupIdValues);
            if (isoRto && electricNode && dartm) {
                setIsoRto(isoRto);
                setInitialValues({
                    isoRto,
                    electricNode,
                    dartm,
                });
            }
        }
    }, [showTraverseOptions, setInitialValues, lookupIdValues, dirty]);

    const refetchNodes = useCallback(() => {
        setIsLoadingData(true);
        getElectricNodeListData(isoRto).then((electricNodes) => {
            setElectricNodeList(electricNodes);
            setIsLoadingData(false);
        });
    }, [isoRto]);

    useEffect(() => {
        if (showTraverseOptions && isoRto) {
            refetchNodes();
        }
    }, [isoRto, refetchNodes, showTraverseOptions]);

    const onInputTableChange = useCallback(
        (value) => {
            if (value === TRAVERSE_INPUT_TABLE_ID) {
                setValues({
                    ...values,
                    isoRto: '',
                    electricNode: '',
                    dartm: '',
                });
            } else {
                if (showTraverseOptions) {
                    const { isoRto, electricNode, dartm, ...rest } = values;
                    setValues({
                        ...rest,
                    });
                }
            }
            setFieldValue(getInputFormName(tableId, selectedItem, 'INPUTTABLEMAPIDTABLE'), value);
            setFieldValue(getInputFormName(tableId, selectedItem, 'LOOKUPIDVALUES'), '');
        },
        [
            values,
            showTraverseOptions,
            setValues,
            getInputFormName,
            TRAVERSE_INPUT_TABLE_ID,
            tableId,
            selectedItem,
            setFieldValue,
        ]
    );

    const validateRequiredField = (value, label) => {
        let error;
        if (value === '') {
            error = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }
        return error;
    };

    const onIsoRtoOptionChange = (value) => {
        setIsoRto(value);
        setFieldValue('electricNode', '', false);
        setFieldValue('dartm', '', false);
        setFieldValue(getInputFormName(tableId, selectedItem, 'LOOKUPIDVALUES'), '', false);
    };

    return (
        <SimpleGrid pt={7} pb={5} columns={{ xl: mode === 'edit' ? 3 : 2 }} spacingX={6} spacingY={4}>
            {mode === 'add' && (
                <SpotPriceInputField mode={mode} getInputFormName={getInputFormName} columnName="DESCRIPTION" />
            )}

            <SpotPriceInputField
                mode={mode}
                columnName="INPUTTABLEMAPIDTABLE"
                getInputFormName={getInputFormName}
                onChange={onInputTableChange}
            />

            {mode === 'edit' && showLayoutGaps && (
                <>
                    <Box />
                    <Box />
                </>
            )}

            {showTraverseOptions && (
                <>
                    <ComboboxFormikField
                        id="isoRto"
                        name="isoRto"
                        label={formLabels.isoRto}
                        options={ISO_RTO_OPTIONS}
                        valueKey="id"
                        labelKey="description"
                        validate={(value) => validateRequiredField(value, formLabels.isoRto)}
                        onChange={onIsoRtoOptionChange}
                        isRequired
                    />

                    <ComboboxFormikField
                        id="electricNode"
                        name="electricNode"
                        label={formLabels.electricNode}
                        options={electricNodeList}
                        validate={(value) => validateRequiredField(value, formLabels.electricNode)}
                        isRequired
                        isDisabled={!values.isoRto}
                    />

                    <ComboboxFormikField
                        id="dartm"
                        name="dartm"
                        label={formLabels.dartm}
                        options={values.isoRto === CAISO_OPTION ? DARTM_OPTIONS_CAISO : DARTM_OPTIONS}
                        labelKey="description"
                        valueKey="id"
                        validate={(value) => validateRequiredField(value, formLabels.dartm)}
                        isRequired
                        isDisabled={!values.isoRto}
                    />
                </>
            )}
            {showTraverseOptions ? (
                <SpotPriceLookupValuesField
                    name={getInputFormName(tableId, selectedItem, 'LOOKUPIDVALUES')}
                    label={formLabels.lookupValues}
                    isDisabled={true}
                />
            ) : (
                <SpotPriceInputField mode={mode} getInputFormName={getInputFormName} columnName="LOOKUPIDVALUES" />
            )}
            <SpotPriceInputField mode={mode} getInputFormName={getInputFormName} columnName="COMMODITYID" />
            <SpotPriceInputField mode={mode} getInputFormName={getInputFormName} columnName="MODULEPROCESSID" />
            <SpotPriceInputField mode={mode} getInputFormName={getInputFormName} columnName="MINPRICE" />
            <SpotPriceInputField mode={mode} getInputFormName={getInputFormName} columnName="MAXPRICE" />
            <SpotPriceInputField mode={mode} getInputFormName={getInputFormName} columnName="SPOTPRICEWEIGHTID" />
            <SpotPriceInputField mode={mode} getInputFormName={getInputFormName} columnName="ONPEAKPEAKPERIODLABELID" />
            <SpotPriceInputField
                mode={mode}
                getInputFormName={getInputFormName}
                columnName="OFFPEAKPEAKPERIODLABELID"
            />
            <SpotPriceInputField mode={mode} getInputFormName={getInputFormName} columnName="ATCPEAKPERIODLABELID" />

            {isLoadingData && <LoadingModal header={intl.formatMessage({ id: 'common_loading' })} isOpen />}
        </SimpleGrid>
    );
};

export default SpotPriceFields;
