import styled from '@emotion/styled/macro';

const StrategyGridWrapper = styled.div`
    height: 600px;
    position: relative;

    [class^='ag-'],
    [class^='ag-']:after,
    [class^='ag-']:before,
    [class^='ag-']:focus {
        box-sizing: border-box;
        outline: 0;
    }

    .ag-root-wrapper-body {
        border: 1px solid var(--chakra-colors-border-secondary);
    }

    .ag-header-cell-text {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.66;
        color: var(--chakra-colors-text-senary);
    }

    .ag-react-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .ag-header-cell {
        border-right: 1px solid var(--chakra-colors-border-secondary);
    }

    .ag-header-cell:focus {
        background: var(--chakra-colors-ag-row-hover);
    }

    .ag-header-cell[aria-colindex='4'] {
        border-right: none;
    }

    .ag-header-row {
        border-bottom: 1px solid var(--chakra-colors-border-secondary);
        background: var(--chakra-colors-background-octenary);
    }

    .ag-row-group {
        justify-content: center;
        background: var(--chakra-colors-blue-100);
        color: var(--chakra-colors-ag-row-group-batteries);
        text-align: center;
    }

    .ag-group-child-count {
        display: none;
    }

    .ag-sort-order {
        display: none;
    }

    .ag-header-cell,
    .ag-cell:not(.ag-cell-inline-editing) {
        padding-left: 7px;
        padding-right: 7px;
    }

    .ag-cell {
        color: var(--chakra-colors-text-septenery);
        font-size: 13px;
        font-weight: 500;
        line-height: 44px;
        border: 1px solid transparent;
        border-right: 1px solid var(--chakra-colors-border-secondary);
    }

    .ag-cell[aria-colindex='1'] {
        background: var(--chakra-colors-background-octenary);
    }

    .ag-cell[aria-colindex='2'] {
        background: var(--chakra-colors-background-octenary);
    }

    .ag-cell[aria-colindex='3'] {
        background: var(--chakra-colors-background-octenary);
    }

    .ag-cell:focus {
        background: var(--chakra-colors-ag-row-hover);
    }

    .ag-row {
        border-top: 1px solid var(--chakra-colors-border-secondary);
        background: var(--chakra-colors-background-tertiary);
    }

    .ag-row-first {
        border-top: 1px solid transparent;
    }

    .ag-header-cell-resize::after {
        content: '';
        position: absolute;
        z-index: 1;
        display: block;
        left: calc(50% - 1px);
        width: 2px;
        height: 40%;
        top: 15%;
        background-color: var(--chakra-colors-gray-300);
    }
`;

export default StrategyGridWrapper;
