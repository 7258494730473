import { useMemo, useCallback, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tabs, Tab, TabPanels, TabPanel, Flex, useDisclosure, TabList } from '@chakra-ui/react';

import { getValidRowsWithDates } from '../../services/grid';
import {
    getDataFilters,
    isFilterApplied,
    parseDataFilters,
    refreshMultisort,
    exportItemData,
} from '../../services/items';
import {
    GRID_KEYS,
    getSpotPriceData,
    createSpotPriceData,
    updateSpotPriceData,
    deleteSpotPriceData,
    deleteAllSpotPriceData,
    getSpotPriceOptionData,
    createSpotPriceOptionData,
    updateSpotPriceOptionData,
    deleteSpotPriceOptionData,
    deleteAllSpotPriceOptionData,
    multisortPairs,
    getSpotPriceVolatilityForecast,
    createSpotPriceVolatilityForecast,
    updateSpotPriceVolatilityForecast,
    deleteSpotPriceVolatilityForecast,
    deleteAllSpotPriceVolatilityForecast,
    validateSpotPriceData,
} from '../../services/spotPrice';

import { SortOrderContext } from '../grid/utils/SortOrderContext';

import SecondaryButton from '../utils/SecondaryButton';
import FiltersButton from '../utils/FiltersButton';
import SideDrawer from '../utils/SideDrawer';
import ItemFilters from '../utils/ItemFilters';
import DownloadModal from '../utils/DownloadModal';

import SubItemData from '../itemData/SubItemData';
import UploadButton from '../itemData/UploadButton';

import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

const SpotPriceData = ({ spotPriceId, peakPeriods }) => {
    const item = 'SpotPrice';

    const intl = useIntl();
    const filterDrawer = useDisclosure();
    const downloadModal = useDisclosure();
    const { setSortOrder } = useContext(SortOrderContext);

    const [key, setKey] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loadingQueue, setLoadingQueue] = useState([]); // unique keys representing which resource is in loading state
    const [filters, setFilters] = useState(() => getDataFilters(item));

    const refreshTabs = useCallback(() => {
        setKey((prev) => prev + 1);
        setSortOrder((prev) => ({ ...prev, ...refreshMultisort(Object.values(GRID_KEYS)) }));
    }, [setSortOrder]);

    const onLoadingStart = useCallback((key) => {
        setLoadingQueue((prev) => prev.concat(key));
    }, []);

    const onLoadingEnd = useCallback((key) => {
        setLoadingQueue((prev) => prev.filter((p) => p !== key));
    }, []);

    const onApplyFilters = (newFilters) => {
        filterDrawer.onClose();

        setFilters(newFilters);
    };

    const spotPriceDataGridResource = useMemo(() => {
        return {
            create: (rows) => createSpotPriceData(spotPriceId, rows),
            read: (pagination) => getSpotPriceData(spotPriceId, { ...pagination, filter: filters }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate', 'updateDateTime']);
                return updateSpotPriceData(spotPriceId, validRows);
            },
            delete: (rows) => deleteSpotPriceData(rows.map((row) => row.spotPriceDataId)),
            deleteAll: () => deleteAllSpotPriceData(spotPriceId),
            validate: (rows) => validateSpotPriceData(spotPriceId, rows),
        };
    }, [filters, spotPriceId]);

    const spotPriceVolatilityForecastGridResource = useMemo(() => {
        return {
            create: (rows) => createSpotPriceVolatilityForecast(spotPriceId, rows),
            read: (pagination) => getSpotPriceVolatilityForecast(spotPriceId, { ...pagination, filter: filters }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate', 'createDateTime']);
                return updateSpotPriceVolatilityForecast(spotPriceId, validRows);
            },
            delete: (rows) =>
                deleteSpotPriceVolatilityForecast(
                    spotPriceId,
                    rows.map((row) => row.spotVolForecastId)
                ),
            deleteAll: () => deleteAllSpotPriceVolatilityForecast(spotPriceId),
        };
    }, [filters, spotPriceId]);

    const dataColumns = useMemo(
        () => [
            {
                field: 'startDate',
                type: 'date',
                headerName: intl.formatMessage({ id: 'spot_price_data_tab_start_date' }),
                initialSort: 'asc',
                initialSortIndex: 1,
            },
            {
                field: 'endDate',
                type: 'date',
                headerName: intl.formatMessage({ id: 'spot_price_data_tab_end_date' }),
            },
            {
                field: 'price',
                type: 'number',
                headerName: intl.formatMessage({ id: 'spot_price_data_tab_price' }),
            },
            {
                field: 'updateDateTime',
                type: 'date',
                headerName: intl.formatMessage({ id: 'spot_price_data_tab_created' }),
            },
        ],
        [intl]
    );

    const forecastColumns = useMemo(
        () => [
            {
                field: 'startDate',
                type: 'date',
                headerName: intl.formatMessage({
                    id: 'spot_price_volatility_forecast_tab_start_date',
                }),
            },
            {
                field: 'endDate',
                type: 'date',
                headerName: intl.formatMessage({
                    id: 'spot_price_volatility_forecast_tab_end_date',
                }),
            },
            {
                field: 'peakPeriodLabelId',
                type: 'select',
                cellEditorParams: { options: peakPeriods, required: true },
                headerName: intl.formatMessage({
                    id: 'spot_price_volatility_forecast_tab_peak_periods',
                }),
                initialSort: 'asc',
                initialSortIndex: 1,
            },
            {
                field: 'vol',
                type: 'number',
                headerName: intl.formatMessage({ id: 'spot_price_volatility_forecast_tab_vol' }),
            },
            {
                field: 'createDateTime',
                type: 'date',
                headerName: intl.formatMessage({
                    id: 'spot_price_volatility_forecast_tab_created',
                }),
            },
        ],
        [intl, peakPeriods]
    );

    const spotPriceOptionDataGridResource = useMemo(() => {
        return {
            create: (rows) => createSpotPriceOptionData(spotPriceId, rows),
            read: (pagination) =>
                getSpotPriceOptionData(spotPriceId, {
                    ...pagination,
                    filter: filters,
                }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['deliveryDate', 'transactionDate', 'updateDateTime']);
                return updateSpotPriceOptionData(spotPriceId, validRows);
            },
            delete: (rows) => deleteSpotPriceOptionData(rows.map((row) => row.spotPriceOptionId)),
            deleteAll: () => deleteAllSpotPriceOptionData(spotPriceId),
        };
    }, [filters, spotPriceId]);

    const optionColumns = useMemo(
        () => [
            {
                field: 'deliveryDate',
                type: 'date',
                headerName: intl.formatMessage({ id: 'spot_price_option_data_tab_delivery_date' }),
            },
            {
                field: 'intervalName',
                headerName: intl.formatMessage({ id: 'spot_price_option_data_tab_interval_name' }),
            },
            {
                field: 'optionType',
                headerName: intl.formatMessage({ id: 'spot_price_option_data_tab_option_type' }),
            },
            {
                field: 'price',
                type: 'number',
                headerName: intl.formatMessage({ id: 'spot_price_option_data_tab_price' }),
            },
            {
                field: 'bid',
                type: 'number',
                headerName: intl.formatMessage({ id: 'spot_price_option_data_tab_bid' }),
            },
            {
                field: 'ask',
                type: 'number',
                headerName: intl.formatMessage({ id: 'spot_price_option_data_tab_ask' }),
            },
            {
                field: 'strikePrice',
                type: 'number',
                headerName: intl.formatMessage({ id: 'spot_price_option_data_tab_strike_price' }),
            },
            {
                field: 'peakPeriodLabelId',
                type: 'select',
                cellEditorParams: { options: peakPeriods },
                headerName: intl.formatMessage({ id: 'spot_price_option_data_tab_peak_period' }),
            },
            {
                field: 'settle',
                type: 'number',
                headerName: intl.formatMessage({ id: 'spot_price_option_data_tab_settle_price' }),
            },
            {
                field: 'transactionDate',
                type: 'date',
                headerName: intl.formatMessage({
                    id: 'spot_price_option_data_tab_transaction_date',
                }),
            },
            {
                field: 'updateDateTime',
                type: 'date',
                headerName: intl.formatMessage({ id: 'spot_price_option_data_tab_created' }),
                initialSort: 'asc',
                initialSortIndex: 1,
            },
        ],
        [intl, peakPeriods]
    );

    const onDownloadData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const payload = {
                filter: parseDataFilters(filters),
                dataSorting: sortOrder[GRID_KEYS.spotPriceData],
                volatilityForecastSorting: sortOrder[GRID_KEYS.spotPriceVolatilityForecast],
                optionsSorting: sortOrder[GRID_KEYS.spotPriceOptions],
                exportTemplate,
            };

            return exportItemData(`spot-price/${spotPriceId}/export`, payload);
        },
        [spotPriceId, filters]
    );

    return (
        <Tabs px={7} isLazy isManual lazyBehavior="keepMounted" key={key} index={selectedTab} onChange={setSelectedTab}>
            <TabList w="100%" flexWrap={{ base: 'wrap', xl: 'nowrap' }} alignItems="center">
                <Tab>
                    <FormattedMessage id="spot_price_tab_data" />
                </Tab>
                <Tab>
                    <FormattedMessage id="spot_price_tab_volatility_forecast" />
                </Tab>
                <Tab>
                    <FormattedMessage id="spot_price_tab_option_data" />
                </Tab>

                <Flex ml="auto" gridGap={2} order={{ base: -1, xl: 0 }} w={{ base: '100%', xl: 'auto' }}>
                    <SecondaryButton
                        leftIcon={<DownloadIcon />}
                        w="auto !important"
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={downloadModal.onOpen}
                    >
                        <FormattedMessage id="common_download_btn_tooltip_and_label" />
                    </SecondaryButton>

                    <UploadButton
                        type="spot-price"
                        itemId={spotPriceId}
                        hasLabel={true}
                        w="auto !important"
                        onAfterUploadChange={refreshTabs}
                    />

                    <FiltersButton hasIndicator={isFilterApplied(item)} onClick={filterDrawer.onOpen} />

                    <SecondaryButton
                        onClick={refreshTabs}
                        size="sm"
                        variant="secondary"
                        leftIcon={<RefreshIcon />}
                        isLoading={loadingQueue.length > 0}
                        loadingText={intl.formatMessage({ id: 'common_loading' })}
                        textTransform="capitalize"
                        ml="auto"
                    >
                        <FormattedMessage id="common_refresh" />
                    </SecondaryButton>
                </Flex>
            </TabList>

            <TabPanels>
                <TabPanel>
                    <SubItemData
                        tab="0"
                        name={GRID_KEYS.spotPriceData}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={spotPriceDataGridResource}
                        columns={dataColumns}
                        addDataColumns={dataColumns}
                        successMessage={intl.formatMessage({ id: 'spot_price_data_grid_update_success' })}
                        primaryKeyName="spotPriceDataId"
                    />
                </TabPanel>

                <TabPanel>
                    <SubItemData
                        tab="1"
                        name={GRID_KEYS.spotPriceVolatilityForecast}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={spotPriceVolatilityForecastGridResource}
                        columns={forecastColumns}
                        addDataColumns={forecastColumns}
                        successMessage={intl.formatMessage({ id: 'spot_price_data_grid_update_success' })}
                        multisortPairs={multisortPairs}
                    />
                </TabPanel>

                <TabPanel>
                    <SubItemData
                        tab="2"
                        name={GRID_KEYS.spotPriceOptions}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={spotPriceOptionDataGridResource}
                        columns={optionColumns}
                        addDataColumns={optionColumns}
                        successMessage={intl.formatMessage({ id: 'spot_price_data_grid_update_success' })}
                        multisortPairs={multisortPairs}
                    />
                </TabPanel>
            </TabPanels>

            {filterDrawer.isOpen && (
                <SideDrawer
                    isOpen
                    onClose={filterDrawer.onClose}
                    header={<FormattedMessage id="spot_price_filters_heading" />}
                    content={
                        <ItemFilters
                            filters={filters}
                            onApplyFilters={onApplyFilters}
                            item={item}
                            itemStartFilterHeading={<FormattedMessage id={'spot_price_start_filter'} />}
                            secondaryFilterHeading={<FormattedMessage id={'common_item_created_filter'} />}
                        />
                    }
                    placement="right"
                />
            )}

            {downloadModal.isOpen && (
                <DownloadModal
                    isOpen
                    onClose={downloadModal.onClose}
                    onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                    onDownloadTemplate={() => onDownloadData(true)}
                />
            )}
        </Tabs>
    );
};

export default SpotPriceData;
