import { memo, useCallback } from 'react';
import ItemLinkWrapper from '../layout/ItemLinkWrapper';

const OptimizedItemLinkWrapper = ({ icon, url, item, onClick }) => {
    const _onClick = useCallback(() => {
        onClick(item);
    }, [item, onClick]);

    return (
        <ItemLinkWrapper
            url={url}
            itemId={item.id}
            isMaster={item.securityId}
            label={item.description}
            icon={icon}
            onClick={_onClick}
        />
    );
};

export default memo(OptimizedItemLinkWrapper);
