import styled from '@emotion/styled/macro';

const FormServerErrorMessage = styled.div`
    color: var(--chakra-colors-text-error);
    margin-top: var(--chakra-space-2);
    font-size: var(--chakra-fontSizes-sm);
    line-height: var(--chakra-lineHeights-normal);
    border-color: var(--chakra-colors-text-error);
`;

export default FormServerErrorMessage;
