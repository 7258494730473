import { useState, useEffect, useCallback, useMemo } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Skeleton, Text, Box, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import useCommonToast from '../hooks/useCommonToast';
import useHistoryPush from '../hooks/useHistoryPush';

import {
    getItemsByMultipleTypes,
    addItemNextToParent,
    sortItemsByDescription,
    copyItem,
    findItem,
    addItemToPortfolios,
} from '../services/items';

import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as HedgesAndContractsIcon } from '../icons/item-icons/hedges-and-contracts.svg';

import hedgesPlaceholder from '../images/hedges-unselected.png';

import LayoutSidebar from '../components/layout/LayoutSidebar';
import LayoutWrapper from '../components/layout/LayoutWrapper';
import WrapperEmptyState from '../components/layout/WrapperEmptyState';
import ItemListWrapper from '../components/layout/ItemListWrapper';
import OptimizedItemLinkWrapper from '../components/utils/OptimizedItemLinkWrapper';
import AddItemPortfolios from '../components/utils/AddItemPortfolios';
import AddItemButton from '../components/utils/AddItemButton';
import VirtualisedItemList from '../components/utils/VirtualisedItemList';

import AddHedgeItemModal from '../components/hedges/AddHedgeItemModal';
import Hedge from '../components/hedges/Hedge';

const HedgesContracts = () => {
    const { itemTypes } = useSelector((state) => state.item);
    const { isSidebarVisible } = useSelector((state) => state.helpers);

    const { path, url } = useRouteMatch();

    const [hedgesAndContracts, setHedgesAndContracts] = useState([]);

    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loadingError, setLoadingError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [temporaryNode, setTemporaryNode] = useState(null);

    const addHedgeItemModal = useDisclosure();
    const addModal = useDisclosure();
    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();

    const getData = useCallback(() => {
        const financialAndPhysicalInstruments = itemTypes.filter(
            (itemType) =>
                itemType.description === 'Financial Instrument' || itemType.description === 'Physical Instrument'
        );

        if (financialAndPhysicalInstruments.length > 0) {
            const IDs = financialAndPhysicalInstruments.map((instrument) => instrument.itemTypeId);
            getItemsByMultipleTypes(IDs)
                .then((data) => setHedgesAndContracts(data))
                .catch(() => setLoadingError(true))
                .finally(() => setIsLoading(false));
        }
    }, [itemTypes]);

    useEffect(() => {
        getData();
    }, [getData]);

    // @todo don't recalculate the itemIndex on each rerender - useMemo
    const itemIndex = searchResults.findIndex((item) => item.id === selected?.id);

    const noSearchResults = useMemo(() => {
        return search.trim().length > 0 && searchResults.length === 0;
    }, [search, searchResults]);

    const onHedgeClick = useCallback((hedge) => {
        if (hedge) {
            setSelected(hedge);
        }
    }, []);

    useEffect(() => {
        const term = search.trimStart().toLowerCase();
        const filteredData = hedgesAndContracts.filter((o) => o.description.toLowerCase().includes(term));
        setSearchResults(filteredData);
    }, [hedgesAndContracts, search]);

    const updateHedges = (newHedgeItem) => {
        setHedgesAndContracts(sortItemsByDescription([...hedgesAndContracts, newHedgeItem]));
        setSelected(newHedgeItem);
    };

    const onHedgeCopy = async ({ id }) => {
        toast({ status: 'info', message: intl.formatMessage({ id: 'common_copy_btn_progress_message' }) });
        const temporaryHedge = await copyItem(id);
        setHedgesAndContracts((prev) => addItemNextToParent(prev, id, temporaryHedge));

        historyPush(`${url}/${temporaryHedge.id}`);
    };

    const onAddModalOpen = ({ id }) => {
        setTemporaryNode(findItem(hedgesAndContracts, id));
        addModal.onOpen();
    };

    const closeAddModal = () => {
        addModal.onClose();
        setTemporaryNode(null);
    };

    const onAddSuccess = (selectedPortfolios) => {
        toast(getAddItemSuccessMessage(temporaryNode, selectedPortfolios));
        closeAddModal();
    };

    const getAddItemSuccessMessage = (item, portfolios) => {
        if (portfolios.length === 1) {
            return intl.formatMessage(
                { id: 'common_add_item_success_message' },
                { item: item.description, destination: portfolios[0].properties.name }
            );
        }

        return intl.formatMessage(
            { id: 'common_add_item_to_multiple_portfolios_success_message' },
            { item: item.description, n: portfolios.length }
        );
    };

    const onSearchChange = (value) => {
        setSearch(value);
    };

    const onHedgeDelete = (hedgeId) => {
        const updatedHedgesAndContracts = hedgesAndContracts.filter((hedge) => hedge.id !== hedgeId);
        setHedgesAndContracts(updatedHedgesAndContracts);
    };

    const onHedgeEdit = (updatedHedge) => {
        const updatedHedges = hedgesAndContracts.map((hedge) => {
            if (hedge.id === updatedHedge.id) {
                return { ...updatedHedge };
            }
            return hedge;
        });
        setHedgesAndContracts(updatedHedges);
    };

    return (
        <LayoutWrapper
            sidebar={
                isSidebarVisible && (
                    <LayoutSidebar
                        selected={selected}
                        titleMessage={intl.formatMessage({ id: 'hedges_and_contracts_title' })}
                        accordionPlaceholderId="hedges_and_contracts_select_item"
                        searchPlaceholderId="common_search_hedges_and_contracts"
                        searchValue={search}
                        searchCallback={onSearchChange}
                        searchTerm={search}
                    >
                        <ItemListWrapper>
                            <Box px={6} pb={2}>
                                <AddItemButton
                                    disabled={itemTypes.length === 0}
                                    leftIcon={<AddIcon />}
                                    onClick={() => addHedgeItemModal.onOpen()}
                                >
                                    <Text size="sm" color="gray.900" pl={1} pt={1}>
                                        <FormattedMessage id="hedges_and_contracts_add_new_deal" />
                                    </Text>
                                </AddItemButton>

                                {isLoading ? (
                                    <Skeleton>
                                        <Box h="34px">
                                            <FormattedMessage id="common_loading" />
                                        </Box>
                                    </Skeleton>
                                ) : loadingError ? (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <Box>
                                            <AlertTitle textTransform="capitalize">
                                                <FormattedMessage id="common_error" />
                                            </AlertTitle>

                                            <AlertDescription>
                                                <FormattedMessage
                                                    id="common_loading_error"
                                                    values={{ items: 'deals' }}
                                                />
                                            </AlertDescription>
                                        </Box>
                                    </Alert>
                                ) : noSearchResults ? (
                                    <Alert status="info">
                                        <AlertIcon />
                                        <FormattedMessage id="common_no_search_results" />
                                    </Alert>
                                ) : null}
                            </Box>
                            {!noSearchResults && !loadingError && !isLoading && (
                                <VirtualisedItemList
                                    items={searchResults}
                                    onCopy={onHedgeCopy}
                                    itemIndex={itemIndex}
                                    onAddToPortfolioModalOpen={onAddModalOpen}
                                    renderItems={(hedgeAndContract) => (
                                        <OptimizedItemLinkWrapper
                                            url={url}
                                            icon={HedgesAndContractsIcon}
                                            onClick={onHedgeClick}
                                            item={hedgeAndContract}
                                        />
                                    )}
                                />
                            )}
                        </ItemListWrapper>
                    </LayoutSidebar>
                )
            }
            content={
                <Switch>
                    <Route exact path={path}>
                        <WrapperEmptyState imgSrc={hedgesPlaceholder} page="Hedge" noun="configuration" />
                    </Route>

                    {hedgesAndContracts.length > 0 && (
                        <Route path={`${path}/:hedgeId`}>
                            <Hedge onHedgeDelete={onHedgeDelete} onHedgeEdit={onHedgeEdit} />
                        </Route>
                    )}
                </Switch>
            }
        >
            {addModal.isOpen && (
                <AddItemPortfolios
                    onClose={closeAddModal}
                    sourceId={temporaryNode.id}
                    itemName={temporaryNode.description}
                    onAddSuccess={onAddSuccess}
                    onSubmit={addItemToPortfolios}
                />
            )}
            {addHedgeItemModal.isOpen && (
                <AddHedgeItemModal isOpen updateItems={updateHedges} onClose={addHedgeItemModal.onClose} />
            )}
        </LayoutWrapper>
    );
};

export default HedgesContracts;
