import CustomCheckbox from '../forms/CustomCheckbox';

const DataGridCheckbox = ({ value, setValue, colDef, isDisabled = false }) => {
    return (
        <CustomCheckbox
            isChecked={value}
            onChange={(e) => colDef.editable && setValue(e.target.checked)}
            isDisabled={isDisabled}
        />
    );
};

export default DataGridCheckbox;
