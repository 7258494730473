import React, { useCallback } from 'react';
import { Box, Drawer, DrawerHeader, DrawerBody, DrawerContent, DrawerOverlay, useDisclosure } from '@chakra-ui/react';

import useNavigation from '../../../hooks/useNavigation';
import NavigationPanel from './NavigationPanel';
import NavigationButton from './NavigationButton';
import NavigationLink from './NavigationLink';
import NavigationAccordion from './NavigationAccordion';
import NavigationListGroup from './NavigationListGroup';
import NavigationListItem from './NavigationListItem';
import NavigationDarkModeCheckbox from './NavigationDarkModeCheckbox';
import styled from '@emotion/styled/macro';

const MobileNavigation = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { config } = useNavigation();

    const renderItem = useCallback(
        (item) => {
            let result;

            switch (item?.type) {
                case 'ImageLink':
                    result = null;

                    break;
                case 'Button':
                    result = <NavigationButton config={item} onClick={onClose} />;

                    break;
                case 'Menu':
                    result = <NavigationAccordion config={item} renderItem={renderItem} />;

                    break;
                case 'MenuItemsGroup':
                    result = <NavigationListGroup config={item} renderItem={renderItem} />;

                    break;
                case 'MenuItem':
                    result = <NavigationListItem config={item} onClick={onClose} />;

                    break;
                case 'Link':
                    result = <NavigationLink config={item} onClick={onClose} />;

                    break;
                case 'Checkbox':
                    result = <NavigationDarkModeCheckbox config={item} />;

                    break;
                default:
                    result = null;

                    break;
            }

            return result;
        },
        [onClose]
    );

    const renderNavigationPart = useCallback(
        (navigationPart) => {
            let isNeedDelimiter = null;

            const items = navigationPart
                .map((item, itemIndex) => {
                    let result = null;
                    const isVisible = item?.mobileConfig?.isVisible;

                    if (isVisible === undefined || isVisible === true) {
                        const renderedItem = renderItem(item);

                        result = renderedItem ? (
                            <Box mt={isNeedDelimiter ? '30px' : 0} key={itemIndex}>
                                {renderedItem}
                            </Box>
                        ) : null;
                    }

                    isNeedDelimiter |= !!result;

                    return result;
                })
                .filter((item) => item !== null);

            return items.length ? <Box mb="30px">{items}</Box> : null;
        },
        [renderItem]
    );

    return (
        <StyledBox bg="background-tertiary">
            <NavigationPanel onHamburgerClick={onOpen} />

            <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay />

                <DrawerContent _dark={{ bg: 'background-tertiary' }}>
                    <DrawerHeader boxShadow="sm">
                        <NavigationPanel onHamburgerClick={onClose} onLogoClick={onClose} isActive={true} />
                    </DrawerHeader>

                    <StyledDrawerBody>
                        {config.map((navigationPart, navigationPartIndex) => (
                            <React.Fragment key={navigationPartIndex}>
                                {renderNavigationPart(navigationPart)}
                            </React.Fragment>
                        ))}
                    </StyledDrawerBody>
                </DrawerContent>
            </Drawer>
        </StyledBox>
    );
};

const StyledBox = styled(Box)`
    width: 100%;
    position: fixed;
    boxshadow: var(--chakra-shadows-sm);
    z-index: var(--chakra-zIndices-docked);
    align-items: center;
    padding: 10.5px var(--chakra-space-5);

    @media screen and (max-width: 899px) {
        display: block;
    }

    @media screen and (min-width: 900px) {
        display: none;
    }
`;

const StyledDrawerBody = styled(DrawerBody)`
    display: flex;
    flex-direction: column;
    padding: var(--chakra-space-10) var(--chakra-space-5);
    margin-bottom: -30px;
`;

export default MobileNavigation;
