import { useMediaQuery } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import TooltipIconList from '../utils/TooltipIconList';
import ContextMenu from '../utils/ContextMenu';

import { ReactComponent as AddPortfolio } from '../../icons/add-portfolio.svg';
import { ReactComponent as Copy } from '../../icons/copy.svg';
import { ReactComponent as AddItem } from '../../icons/add-item.svg';
import { useMemo } from 'react';

const HoverLabelIcons = ({ node, onCopy, onAddToPortfolio, onAddItem }) => {
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const intl = useIntl();

    const items = useMemo(() => {
        let result = [];

        if (node.properties.isItem) {
            result = [
                {
                    id: 1,
                    label: intl.formatMessage({ id: 'common_add_to_portfolio_btn_tooltip_and_label' }),
                    action: () => onAddToPortfolio(node),
                    Icon: AddPortfolio,
                },
                {
                    id: 2,
                    label: intl.formatMessage({ id: 'common_copy_btn_tooltip_and_label' }),
                    action: () => onCopy(node),
                    Icon: Copy,
                },
            ];
        } else if (node.properties.isPlant) {
            result = [
                {
                    id: 1,
                    label: intl.formatMessage({ id: 'add_plant_unit' }),
                    action: () => onAddItem(node),
                    Icon: AddItem,
                },
            ];
        }

        return result;
    }, [node, intl, onAddItem, onAddToPortfolio, onCopy]);

    if (!items?.length) {
        return null;
    }

    return isDesktop ? (
        <TooltipIconList items={items} />
    ) : (
        <ContextMenu aria-label={intl.formatMessage({ id: 'common_actions' })} items={items} />
    );
};

export default HoverLabelIcons;
