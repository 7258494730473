const Divider = {
    baseStyle: {
        opacity: 1,
        borderColor: 'gray.200',

        _dark: {
            borderColor: 'gray.600',
        },
    },
};

export default Divider;
