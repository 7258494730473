import axios from 'axios';

export const getUserBooks = async (userId) => {
    const response = await axios.get('/user-management/booksforuser/all', { params: { username: userId } });

    return response.data;
};

export const updateUserBooks = async (userId, assignedBooks) => {
    const response = await axios.post('/user-management/booksforuser/update', assignedBooks, {
        params: { username: userId },
    });

    return response.data;
};

export const getUserRoles = async (username) => {
    const response = await axios.get('/user-management/rolesforuser/all', { params: { username } });

    return response.data;
};

export const getUsersForRole = async (roleId) => {
    const response = await axios.get(`/user-management/usersforrole/all/${roleId}`);

    return response.data;
};

export const getPermissionsForRole = async (roleId) => {
    const response = await axios.get(`/user-management/permissionsforrole/all/${roleId}`);

    return response.data;
};

export const updateUserRoles = async (username, assignedRoles) => {
    const response = await axios.post('/user-management/rolesforuser/update', assignedRoles, { params: { username } });

    return response.data;
};

export const updateGroupUsers = async (roleId, assignedUsers) => {
    const response = await axios.post(`/user-management/usersforrole/update/${roleId}`, assignedUsers, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const updateGroupPermissions = async (roleId, assignedPermissions) => {
    const response = await axios.post(`/user-management/permissionsforrole/update/${roleId}`, assignedPermissions, {
        suppressErrorMessageHandler: true,
    });

    return response.data;
};

export const getUserRoleDetails = async (roleId) => {
    const response = await axios.get(`/user-management/role/${roleId}`);

    return response.data;
};

export const getGroupPermissionDetails = async (permissionId) => {
    const response = await axios.get(`/user-management/permission/${permissionId}`);

    return response.data;
};

export const getBookUsers = async (bookId) => {
    const response = await axios.get(`/user-management/usersforbook/${bookId}/users`);

    return response.data;
};

export const addUsersToBook = async (bookId, assignedUser) => {
    const response = await axios.post(`/user-management/usersforbook/${bookId}/update/`, assignedUser);

    return response.data;
};
