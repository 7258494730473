import axios from 'axios';

export const getRestorationJobs = async (payload) => {
    const response = await axios.post('/restoration-jobs/jobs', payload);
    return response.data;
};

export const createRestorationJob = async (payload) => {
    return await axios.post('/restoration-jobs/create', payload);
};

export const downloadPortfolioFlatFiles = async (itemId) =>
    await axios.post(`/restoration-jobs/download-portfolio-flat-files/${itemId}`);

export const restoreStudy = async (itemId) => await axios.post(`/restoration-jobs/restore-study/${itemId}`);

export const downloadStudyFlatFiles = async (itemId) =>
    await axios.post(`/restoration-jobs/download-study-flat-files/${itemId}`);

export const getStatuses = async () => {
    const respons = await axios.get('/restoration-jobs/statuses');
    return respons.data;
};

export const editJobStatuses = (payload) => {
    return axios.put('/restoration-jobs/status', payload);
};

export const getDynamicProperties = async (moduleId, jobId = null) => {
    const respons = await axios.get(
        `/restoration-jobs/${moduleId}/dynamic-properties${jobId ? '?jobId=' + jobId : ''}`
    );
    return respons.data;
};

export const getModuleByDesc = async (moduleDesc) => {
    const response = await axios.get(`/restoration-jobs/module${moduleDesc ? '?moduleDesc=' + moduleDesc : ''}`);
    return response.data;
};
