const sidebarScrollbarCss = `
    @media (min-width: 769px) {    
        flex-grow: 1;
        flex-shrink: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;

        [role='tree'] {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
`;

export default sidebarScrollbarCss;
