import { useMemo, useState } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import SimpleModal from 'components/modal/SimpleModal';
import MultiSelectFormikField from 'components/forms/MultiSelectFormikField';
import DefinitionsSkeleton from 'components/utils/DefinitionsSkeleton';
import useGlobalConstraintsItems from 'hooks/globalConstraints/useGlobalConstraintsItems';
import useGlobalConstraintsSubItems from 'hooks/globalConstraints/useGlobalConstraintsSubItems';
import NumberInputFormikField from 'components/forms/NumberInputFormikField';
import { addMultipleAutoformTableRow } from '../../services/autoforms';
import { CONSTRAINT_COMPONENTS_TABLE_ID } from 'constants/globalConstraints';
import useAutoformTableMetadata from 'components/autoform/hooks/useAutoformTableMetadata';
import useAutoformMetadata from 'components/autoform/hooks/useAutoformMetadata';
import useAutoformParams from 'components/autoform/hooks/useAutoformParams';
import useCommonToast from 'hooks/useCommonToast';

const AddRowsModal = ({ onClose, reloadData }) => {
    const { isLoading: isLoadingItems, items } = useGlobalConstraintsItems();
    const [itemIds, setItemIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { hierarchicalTables } = useAutoformMetadata();
    const level2Table = hierarchicalTables.level2[0];
    const { selectedItem: selectedParentRecordId } = useAutoformTableMetadata(level2Table?.id);
    const parameters = useAutoformParams();
    const { subItems, isLoading: isLoadingSubitems } = useGlobalConstraintsSubItems(itemIds);
    const { toast } = useCommonToast();

    const intl = useIntl();

    const translations = useMemo(
        () => ({
            itemsLabel: intl.formatMessage({
                id: 'global_constraints_items',
            }),
            subItemsLabel: intl.formatMessage({
                id: 'global_constraints_sub_items',
            }),
            weightFactor: intl.formatMessage({
                id: 'global_constraints_weight_factor',
            }),
        }),
        [intl]
    );

    const subItemsMap = useMemo(
        () =>
            subItems.reduce((acc, subItem) => {
                acc[subItem.generationId] = subItem;
                return acc;
            }, {}),
        [subItems]
    );

    const validate = (value, label) => {
        let error;
        if (!Array.isArray(value) || value.length === 0) {
            error = intl.formatMessage({ id: 'common_forms_validation_required' }, { label });
        }
        return error;
    };

    const initialValues = useMemo(
        () => ({
            items: [],
            subItems: [],
            weightFactor: '',
        }),
        []
    );

    const filterSubItems = (subItemIds) => subItemIds.filter((subItemId) => !!subItemsMap[subItemId]);

    const onSubmit = async (values) => {
        setIsLoading(true);
        const weightFactor = parseFloat(values.weightFactor);
        const items = values.items;
        const itemsWithSubItems = [];

        const records = values.subItems.reduce((acc, subItemId) => {
            const itemId = subItemsMap[subItemId]?.itemId;
            if (itemId) {
                acc.push({
                    TARGETCOMPONENTID: subItemId,
                    TARGETITEMID: itemId,
                    WEIGHTFACTOR: isNaN(weightFactor) ? null : weightFactor,
                });
                itemsWithSubItems.push(itemId);
            }
            return acc;
        }, []);

        const itemsWithoutSubItems = items.filter((itemId) => itemsWithSubItems.indexOf(itemId) === -1);

        itemsWithoutSubItems.forEach((itemId) => {
            records.push({
                TARGETCOMPONENTID: null,
                TARGETITEMID: itemId,
                WEIGHTFACTOR: isNaN(weightFactor) ? null : weightFactor,
            });
        });

        const payload = {
            tableId: CONSTRAINT_COMPONENTS_TABLE_ID,
            records,
            parentRecordId: selectedParentRecordId,
            parameters,
        };

        await addMultipleAutoformTableRow(payload);

        const successMessage = intl.formatMessage({ id: 'common_generic_item_change_success' });
        toast(successMessage);
        setIsLoading(false);

        onClose();
        reloadData();
    };

    return (
        <SimpleModal title={<FormattedMessage id="common_add_rows" />} onClose={onClose} isOpen>
            <Formik onSubmit={onSubmit} initialValues={initialValues}>
                {({ values }) => (
                    <Form>
                        {isLoadingItems ? (
                            <DefinitionsSkeleton />
                        ) : (
                            <>
                                <MultiSelectFormikField
                                    name="items"
                                    options={items}
                                    valueKey="itemId"
                                    labelKey="description"
                                    showSelectAll={false}
                                    onChange={setItemIds}
                                    isRequired={true}
                                    label={translations.itemsLabel}
                                    validate={(value) => validate(value, translations.itemsLabel)}
                                    multipleSelectedLabel={intl.formatMessage(
                                        {
                                            id: 'global_constraints_items_multiselect_placeholder',
                                        },
                                        { count: values.items?.length }
                                    )}
                                />

                                <MultiSelectFormikField
                                    name="subItems"
                                    options={subItems}
                                    valueKey="generationId"
                                    labelKey="description"
                                    showSelectAll={false}
                                    mt={4}
                                    isDisabled={values.items.length === 0}
                                    label={translations.subItemsLabel}
                                    multipleSelectedLabel={intl.formatMessage(
                                        {
                                            id: 'global_constraints_subitems_multiselect_placeholder',
                                        },
                                        { count: filterSubItems(values.subItems).length }
                                    )}
                                    placeholderLabel={
                                        isLoadingSubitems
                                            ? intl.formatMessage({
                                                  id: 'common_loading',
                                              })
                                            : undefined
                                    }
                                />

                                <NumberInputFormikField name="weightFactor" mt={4} label={translations.weightFactor} />

                                <Flex justifyContent="flex-end">
                                    <Button
                                        mt={6}
                                        mb={2}
                                        mr={4}
                                        type="button"
                                        variant="secondary"
                                        align="end"
                                        onClick={onClose}
                                        textTransform="capitalize"
                                    >
                                        <FormattedMessage id="common_back" />
                                    </Button>
                                    <Button
                                        mt={6}
                                        mb={2}
                                        type="submit"
                                        align="end"
                                        textTransform="capitalize"
                                        isLoading={isLoading}
                                    >
                                        <FormattedMessage id="common_continue" />
                                    </Button>
                                </Flex>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </SimpleModal>
    );
};

export default AddRowsModal;
