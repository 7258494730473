import React, { useRef, useCallback, useMemo } from 'react';
import { Box, useFormControl } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useSelect, useMultipleSelection } from 'downshift';
import { useVirtual } from 'react-virtual';
import { useIntl } from 'react-intl';

import SelectButton from '../utils/SelectButton';

import CustomCheckbox from '../forms/CustomCheckbox';
/**
 * Custom Select component
 * @param {any[]} value - The values of the Select.
 * @param {string | object | boolean | number} options - List of options to show in the select component.
 * @param {(value[]) => void} onChange - Callback that is called when the select value changes.
 * @param {string} valueKey - The object key used to extract the value of the component from the selected option.
 * @param {string} labelKey - The object key used to extract the visible label of the component from each <option value="" className=""></option>
 * @param {(option) => string} getCustomLabel - Callback to get a custom label from each option.
 * @param {boolean} isInvalid - Determines whether invalid state is shown in the select.
 * @param {boolean} isDisabled - Is the select disabled.
 * @param {boolean} isControlled - Make the component uncontrolled if set to false.
 * @param {(option) => boolean} isOptionDisabled - Callback to determine whether an option is shown as disabled.
 * @param {string} placeholderLabel - Placeholer String to show when placeholder is active.
 * @param {string} multipleSelectedLabel - Label to show when more than one item is selected.
 * @param {boolean} showSelectedCount - Should show the counter with the number of selected items;
 * @param {ChakraBoxParams} menuProps - Chakra params to pass on to the menu
 * @param {boolean} showSelectAll - Determines wheter to show a Select all option.
 * @param {any} rest - Pass on Chakra params to parent Box.
 */
const MultiSelect = React.memo(
    ({
        value,
        options = [],
        onChange,
        onBlur,
        valueKey,
        labelKey,
        getCustomLabel,
        isInvalid,
        isWarning,
        isDisabled,
        isControlled = true,
        isOptionDisabled,
        placeholderLabel = '-',
        multipleSelectedLabel,
        showSelectedCount,
        menuProps,
        showSelectAll = true,
        ...rest
    }) => {
        const intl = useIntl();
        const selectAllLabel = intl.formatMessage({ id: 'common_select_all' });

        const formattedOptions = useMemo(() => {
            if (showSelectAll) {
                let tempValue = 'all_selected';

                if (valueKey && labelKey) {
                    tempValue = {
                        [valueKey]: 'all_selected',
                        [labelKey]: selectAllLabel,
                    };
                }
                return [tempValue, ...options];
            }
            return options;
        }, [labelKey, valueKey, options, showSelectAll, selectAllLabel]);

        const checkedOptions = useMemo(() => {
            if (value.length === options.length && showSelectAll) {
                return [...value, 'all_selected'];
            }

            return value;
        }, [value, options, showSelectAll]);

        const getValue = useCallback((option) => (valueKey && option ? option[valueKey] : option), [valueKey]);
        const getLabel = useCallback(
            (option) => {
                if (option === undefined || option === '') {
                    return placeholderLabel;
                }

                if (option === 'all_selected') return selectAllLabel;

                if ((labelKey && labelKey === valueKey && getValue(option) === '') || getValue(option) === undefined) {
                    return placeholderLabel;
                }
                if (getCustomLabel) {
                    return getCustomLabel(option);
                }
                return labelKey && option ? option[labelKey] : option;
            },
            [getValue, labelKey, valueKey, getCustomLabel, placeholderLabel, selectAllLabel]
        );

        const { onFocus: chakraOnFocus, onBlur: chakraOnBlur } = useFormControl({});
        const listRef = useRef();
        const controlledSelectedItems = useMemo(
            () => formattedOptions.filter((opt) => checkedOptions.findIndex((v) => v === getValue(opt)) !== -1) || [],
            [getValue, formattedOptions, checkedOptions]
        );
        const { virtualItems, scrollToIndex, totalSize } = useVirtual({
            size: formattedOptions.length,
            parentRef: listRef,
            overscan: 2,
            paddingStart: 8,
            estimateSize: useCallback(() => 40, []),
            paddingEnd: 8,
        });
        const { getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } = useMultipleSelection({
            selectedItems: isControlled ? controlledSelectedItems : undefined,
            onSelectedItemsChange: ({ selectedItems: items }) => {
                let itemIds = items.map((item) => getValue(item));
                let previouslySelected = selectedItems.map((item) => getValue(item));

                if (showSelectAll) {
                    // On Select All Uncheck
                    if (previouslySelected.includes('all_selected') && !itemIds.includes('all_selected')) {
                        const checkedDisabledItemIds = options
                            .filter((item) => item.disabled)
                            .map((item) => getValue(item))
                            .filter((item) => previouslySelected.includes(item));
                        onChange(checkedDisabledItemIds);
                        return;
                    }

                    // On Select All Check
                    if (!previouslySelected.includes('all_selected') && itemIds.includes('all_selected')) {
                        const enabledItemIds = options.filter((item) => !item.disabled).map((item) => getValue(item));
                        const checkedDisabledItemIds = options
                            .filter((item) => item.disabled)
                            .map((item) => getValue(item))
                            .filter((item) => previouslySelected.includes(item));
                        onChange([...enabledItemIds, ...checkedDisabledItemIds]);
                        return;
                    }

                    if (itemIds.includes('all_selected')) {
                        itemIds = itemIds.filter((e) => e !== 'all_selected');
                    }
                }

                onChange(itemIds);
            },
        });

        const selectedItemsMap = useMemo(() => {
            return selectedItems.reduce((acc, item) => {
                acc.add(getValue(item));
                return acc;
            }, new Set());
        }, [selectedItems, getValue]);

        const buttonLabel = useMemo(() => {
            return selectedItems.length === 0
                ? placeholderLabel
                : selectedItems.length === 1
                  ? getLabel(selectedItems[0])
                  : multipleSelectedLabel;
        }, [getLabel, multipleSelectedLabel, placeholderLabel, selectedItems]);

        const { getItemProps, getMenuProps, highlightedIndex, getToggleButtonProps, isOpen } = useSelect({
            items: formattedOptions,
            selectedItem: null,
            scrollIntoView: () => {},
            onHighlightedIndexChange: ({ highlightedIndex }) => scrollToIndex(highlightedIndex),
            itemToString: getLabel,
            stateReducer: (state, { changes, type }) => {
                switch (type) {
                    case useSelect.stateChangeTypes.MenuKeyDownEnter:
                    case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
                    case useSelect.stateChangeTypes.ItemClick:
                        return {
                            ...changes,
                            highlightedIndex: state.highlightedIndex,
                            isOpen: true, // keep the menu open after selection.
                        };
                    case useSelect.stateChangeTypes.MenuMouseLeave:
                        return {
                            ...changes,
                            highlightedIndex: state.highlightedIndex,
                        };
                    default:
                        return changes;
                }
            },
            onStateChange: ({ type, selectedItem }) => {
                switch (type) {
                    case useSelect.stateChangeTypes.MenuKeyDownEnter:
                    case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
                    case useSelect.stateChangeTypes.ItemClick:
                        if (selectedItem && isOptionDisabled ? !isOptionDisabled(selectedItem) : true) {
                            selectedItemsMap.has(getValue(selectedItem))
                                ? removeSelectedItem(selectedItem)
                                : addSelectedItem(selectedItem);
                        }
                        break;
                    default:
                        break;
                }
            },
        });

        return (
            <SelectWrapper {...rest}>
                <SelectButton
                    isInvalid={isInvalid}
                    isDisabled={isDisabled}
                    buttonLabel={buttonLabel}
                    isWarning={isWarning}
                    {...getToggleButtonProps(getDropdownProps({ disabled: isDisabled }))}
                >
                    {showSelectedCount && selectedItems.length > 0 && (
                        <SelectedItemsBox mx={2}>{selectedItems.length}</SelectedItemsBox>
                    )}
                </SelectButton>
                <ListWrapper
                    {...getMenuProps({
                        ref: listRef,
                        isOpen,
                        onFocus: chakraOnFocus,
                        onBlur: (e) => {
                            chakraOnBlur(e);
                        },
                    })}
                    {...menuProps}
                >
                    {isOpen && (
                        <>
                            <li key="total-size" style={{ height: totalSize }} />
                            {virtualItems.map((virtualRow) => (
                                <ListItem
                                    key={virtualRow.index}
                                    {...getItemProps({
                                        item: formattedOptions[virtualRow.index],
                                        index: virtualRow.index,
                                        style: {
                                            transform: `translateY(${virtualRow.start}px)`,
                                        },
                                        isDisabled: isOptionDisabled
                                            ? isOptionDisabled(formattedOptions[virtualRow.index])
                                            : false,
                                        onClick: (e) => {
                                            e.preventDefault();
                                        },
                                    })}
                                    ref={virtualRow.measureRef}
                                    isHighlighted={highlightedIndex === virtualRow.index}
                                    // isSelected={} @todo highlight selected items and correct styles
                                >
                                    {getLabel(formattedOptions[virtualRow.index])}
                                    <CustomCheckbox
                                        name={`custom_checkbox_${virtualRow.index}`}
                                        ml="5px"
                                        isDisabled={
                                            isOptionDisabled
                                                ? isOptionDisabled(formattedOptions[virtualRow.index])
                                                : false
                                        }
                                        isChecked={selectedItemsMap.has(getValue(formattedOptions[virtualRow.index]))}
                                        onChange={() => 'Not in use'} //@todo extend checkbox components to use defaultChecked
                                    />
                                </ListItem>
                            ))}
                        </>
                    )}
                </ListWrapper>
            </SelectWrapper>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.value === nextProps.value &&
            prevProps.options === nextProps.options &&
            prevProps.valueKey === nextProps.valueKey &&
            prevProps.labelKey === nextProps.labelKey &&
            prevProps.getCustomLabel === nextProps.getCustomLabel &&
            prevProps.isInvalid === nextProps.isInvalid &&
            prevProps.isDisabled === nextProps.isDisabled &&
            prevProps.isControlled === nextProps.isControlled &&
            prevProps.isOptionDisabled === nextProps.isOptionDisabled &&
            prevProps.multipleSelectedLabel === nextProps.multipleSelectedLabel &&
            prevProps.placeholderLabel === nextProps.placeholderLabel &&
            prevProps.placeholderValue === nextProps.placeholderValue &&
            prevProps.onChange === nextProps.onChange &&
            prevProps.menuProps === nextProps.menuProps &&
            prevProps.showSelectedCount === nextProps.showSelectedCount
        );
    }
);

export default MultiSelect;

const SelectWrapper = styled(Box)`
    width: 100%;
    position: relative;
`;

const ListWrapper = styled('ul', { shouldForwardProp: (prop) => prop !== 'isOpen' })`
    max-height: 230px;
    min-width: 100%;
    overflow-y: auto;
    box-shadow: var(--chakra-shadows-md);
    border-radius: 0 var(--chakra-radii-md) var(--chakra-radii-md) var(--chakra-radii-md);
    background-color: var(--chakra-colors-background-tertiary);
    position: absolute;
    z-index: 100;
    list-style: none;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};

    outline: 2px solid transparent;
    outline-offset: 2px;
`;

const ListItem = styled.li`
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    padding: var(--chakra-space-2) var(--chakra-space-4);
    background-color: ${(props) =>
        props.isHighlighted
            ? 'var(--chakra-colors-item-hover)'
            : props.isSelected
              ? 'var(--chakra-colors-item-selected)'
              : 'var(--chakra-colors-background-tertiary)'};
    ${(props) => (props.isDisabled ? 'opacity: 0.4;' : '')}
    color: ${(props) => (props.isSelected ? 'var(--chakra-colors-blue-400)' : 'var(--chakra-colors-item-text)')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const SelectedItemsBox = styled(Box)`
    background-color: var(--chakra-colors-gray-500);
    color: var(--chakra-colors-white);
    text-align: center;
    padding: 2px 0px;
    font-size: 12px;
    border-radius: 5px;
    width: 23px;
    min-width: 23px;
`;
