import { Skeleton, SimpleGrid } from '@chakra-ui/react';

const SimpleGridSkeleton = ({ rows, cols, height = 40, spacing = 5 }) => {
    return (
        <SimpleGrid columns={cols} spacing={spacing}>
            {Array.from(Array(rows).keys()).map((rowNum) => (
                <Skeleton key={rowNum} height={`${height}px`} />
            ))}
        </SimpleGrid>
    );
};

export default SimpleGridSkeleton;
