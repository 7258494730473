import {
    addDays,
    addMonths,
    addYears,
    compareAsc,
    compareDesc,
    eachMonthOfInterval,
    eachYearOfInterval,
    endOfYear,
    format,
    parseISO,
    startOfYear,
} from 'date-fns';

const millisecondsPerDay = 24 * 60 * 60 * 1000;

// function to build an array consisting of objects representing each month that should be presented on the header for monthly view
// based on the earliest and latest date
export const buildMonthCells = (start, end) => {
    const months = eachMonthOfInterval({ start, end });

    const result = months.map((item) => {
        const start = item;
        const end = addMonths(item, 1);
        const year = item.getFullYear();
        const cellId = format(item, 'yyyy-MM');

        return { id: cellId, year, start, end };
    });

    return result;
};

// function to build an array consisting of objects representing each year that should be presented on the header for yearly view
export const buildYearCells = (start, end) => {
    const years = eachYearOfInterval({ start, end });

    const result = years.map((item) => {
        const start = item;
        const end = addYears(item, 1);
        const year = item.getFullYear();
        const cellId = format(item, 'yyyy');

        return { id: cellId, year, start, end };
    });

    return result;
};

// function to create the timebar cells for the given view
export const createTimebar = (range, selectedView) => {
    const { earliest, latest } = range;
    return selectedView === 1 ? buildMonthCells(earliest, latest) : buildYearCells(earliest, latest);
};

// function to get timeline's range based on incoming validated periods & selected view
// for monthly view we want the earliest and latest date based on the periods data,
// for yearly view we want first day of the year of the earliest and the last day of the year of the latest
export const getRange = (data, selectedView) => {
    // flat all parameter periods values into a single array
    const parameterPeriods = data
        .map((item) => item.periods)
        .filter((item) => item.length > 0)
        .flat();

    // in case of no data (=no main parameter periods) we'll use the current year
    if (parameterPeriods.length === 0) {
        let today = new Date();
        let earliest = startOfYear(today);

        return selectedView === 1
            ? { earliest, latest: endOfYear(today) }
            : { earliest, latest: addDays(endOfYear(today), 1) };
    }

    const startDates = parameterPeriods.map((item) => parseISO(item.startDate));
    const endDates = parameterPeriods.map((item) => parseISO(item.endDate));

    const [earliest] = startDates.sort(compareAsc);
    const [latest] = endDates.sort(compareDesc);

    return selectedView === 1 ? { earliest, latest } : { earliest: startOfYear(earliest), latest: endOfYear(latest) };
};

// function to create the time state object used by the timeline component which handles all related cell calculations
export const createTimeState = ({ start, end, selectedView, minWidth = 100 }) => {
    const totalDurationInMs = end - start;
    const durationInDays = totalDurationInMs / millisecondsPerDay;
    const initialWidth = selectedView === 1 ? durationInDays * 4 : durationInDays * 0.5;

    const timelineWidth = initialWidth > minWidth ? initialWidth : minWidth;

    const timelineWidthStyle = `${timelineWidth}px`;

    const toX = (from) => {
        const duration = from - start;
        const value = duration / totalDurationInMs;

        return Math.round(value * timelineWidth);
    };

    const toStyleLeft = (from) => ({
        left: `${toX(from)}px`,
    });

    // calculates cell or element left position and total width
    // isTrackElement is optional flag used to indicate if the function is calculating for cells or for elements
    const toStyleLeftAndWidth = (from, to, isTrackElement = false) => {
        // left position is the result of the subtraction (incoming cell start value - the timeline start),
        // divided by the total length and multiplied by the total width
        const left = isTrackElement ? toX(from) + 1 : toX(from);

        // width position is the result of the subtraction (incoming cell end value - the timeline start),
        // divided by the total length and multiplied by the total width
        const width = isTrackElement ? toX(to) - left - 1 : toX(to) - left;

        return {
            left: `${left}px`,
            width: `${width}px`,
        };
    };

    return {
        timelineWidth,
        timelineWidthStyle,
        start,
        end,
        toX,
        toStyleLeft,
        toStyleLeftAndWidth,
        selectedView,
    };
};

// function to get the cell id (data-period attribute value) based on selected view with optional parameter for month
export const getFocusedCell = (selectedView, cellId, month = '01') => {
    if (cellId) {
        const year = cellId.substring(0, 4);
        return selectedView === 1 ? cellId : year;
    } else {
        const year = new Date().getFullYear();
        return selectedView === 1 ? `${year}-${month}` : `${year}`;
    }
};
