import { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SimpleGrid } from '@chakra-ui/react';

import {
    getBasisDartSpread,
    modifyDartSpread,
    deleteAllDartSpreadData,
    addDartSpreadRows,
    deleteDartSpreadRows,
    multisortPairs,
} from '../../services/basis';

import ComboboxField from '../forms/ComboboxField';

import SubItemData from '../itemData/SubItemData';

const DartSpreadTab = ({
    tab,
    name,
    options,
    basis,
    basisList,
    updateBasis,
    onLoadingStart,
    onLoadingEnd,
    actionBarSlot,
}) => {
    const intl = useIntl();

    const [daHourlyId, setDaHourlyId] = useState(basis.daHourlyBasisId);
    const [scalingIntervalId, setScalingIntervalId] = useState(basis.scalingintervalId);

    const gridResource = useMemo(() => {
        return {
            create: (rows) => addDartSpreadRows(basis.basisId, rows),
            read: (pagination) => getBasisDartSpread(basis.basisId, pagination),
            update: (rows) => modifyDartSpread(rows),
            delete: (rows) => {
                const data = rows.map((elem) => elem.id);
                return deleteDartSpreadRows(basis.basisId, data);
            },
            deleteAll: () => deleteAllDartSpreadData(basis.basisId),
        };
    }, [basis.basisId]);

    const columns = useMemo(
        () => [
            {
                field: 'month',
                type: 'number',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_month' }),
                initialSort: 'asc',
                initialSortIndex: 2,
            },
            {
                field: 'year',
                type: 'number',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_year' }),
                initialSort: 'asc',
                initialSortIndex: 1,
            },
            {
                field: 'peakPeriodLabelId',
                type: 'select',
                cellEditorParams: { options: options.peakPeriods, required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_peak_period' }),
                minWidth: 180,
                initialSort: 'asc',
                initialSortIndex: 3,
            },
            {
                field: 'multiplier',
                type: 'number',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_multiplier' }),
            },
            {
                field: 'adder',
                type: 'number',
                cellEditorParams: { required: false },
                headerName: intl.formatMessage({ id: 'basis_tabs_adder' }),
            },
        ],
        [intl, options.peakPeriods]
    );

    const handleSelectChange = async (value, type) => {
        const tempBasis = { ...basis };

        if (type === 'daHourly') {
            setDaHourlyId(value);
            tempBasis.daHourlyBasisId = value;
        }
        if (type === 'scaling') {
            setScalingIntervalId(value);
            tempBasis.scalingintervalId = value;
        }

        updateBasis(tempBasis);
    };

    const phrasing = useMemo(
        () => ({
            create: {
                content: <FormattedMessage id="common_add_rows_sub_heading_without_date" />,
            },
        }),
        []
    );

    return (
        <>
            <SimpleGrid pb={5} columns={{ xl: 4 }} spacingX={6} spacingY={4}>
                <ComboboxField
                    id="scalingIntervalId"
                    name="scalingIntervalId"
                    options={options.scalingIntervals}
                    valueKey="id"
                    labelKey="description"
                    value={scalingIntervalId}
                    onChange={(value) => handleSelectChange(value, 'scaling')}
                    label={intl.formatMessage({ id: 'basis_tabs_scaling_interval' })}
                    showPlaceholder={true}
                    placeholderValue={null}
                />

                <ComboboxField
                    id="daHourlyId"
                    name="daHourlyId"
                    options={basisList}
                    valueKey="id"
                    labelKey="description"
                    value={daHourlyId}
                    onChange={(value) => handleSelectChange(value, 'daHourly')}
                    label={intl.formatMessage({ id: 'basis_tabs_da_hourly_basis' })}
                    showPlaceholder={true}
                    placeholderValue={null}
                />
            </SimpleGrid>

            <SubItemData
                actionBarSlot={() => actionBarSlot && actionBarSlot}
                tab={tab}
                name={name}
                onLoadingStart={onLoadingStart}
                onLoadingEnd={onLoadingEnd}
                gridResource={gridResource}
                columns={columns}
                addDataColumns={columns}
                multisortPairs={multisortPairs}
                phrasing={phrasing}
            />
        </>
    );
};

export default DartSpreadTab;
