import { useCallback, useMemo, useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react';
import { isDate, parseISO } from 'date-fns';

import DatePicker from '../forms/Datepicker';

const getInitialValue = (value, nullable) => {
    if (!value || value === 'Invalid Date' || !isDate(parseISO(value))) {
        const date = new Date();

        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));

        return nullable ? null : utcDate;
    }

    return new Date(value);
};

const DataGridCalendar = forwardRef(({ nullable = false, column, value, api }, ref) => {
    const initialValue = getInitialValue(value, nullable);
    const [date, setDate] = useState(initialValue);
    const editorParams = column.getColDef()?.cellEditorParams;
    const dateRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            dateRef.current.setFocus();
        });
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                if (typeof date === 'string') {
                    return date;
                }

                if (!date) {
                    return nullable ? null : 'Invalid Date';
                }

                return date;
            },
        };
    });

    const onCalendarClose = useCallback(() => {
        if (initialValue !== date) {
            setTimeout(() => {
                api.stopEditing();
                api.tabToNextCell();
                api.tabToPreviousCell();
            });
        }
    }, [initialValue, date, api]);

    const popperModifiers = useMemo(
        () => ({
            name: 'flip',
            enabled: true,
            options: {
                fallbackPlacements: ['top-start', 'bottom-start', 'left-start', 'right-start'],
            },
        }),
        []
    );

    return (
        <DatePicker
            ref={dateRef}
            value={date}
            onChange={setDate}
            dateFormat={editorParams.dateFormat}
            showTimeInput={editorParams.showTimeInput}
            minDate={editorParams.minDate}
            maxDate={editorParams.maxDate}
            onCalendarClose={onCalendarClose}
            popperModifiers={popperModifiers}
            isClearable={nullable}
        />
    );
});

export default DataGridCalendar;
