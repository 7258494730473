import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import { scopes } from '../services/auth';

import { setAuthState } from '../store/connection/connectionSlice';

const useAcquireTokenSilent = () => {
    const dispatch = useDispatch();
    const { inProgress, instance, accounts } = useMsal();

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            console.log('init token acquire');

            if (accounts.length > 0) {
                instance
                    .acquireTokenSilent({ scopes, account: accounts[0] })
                    .then(({ accessToken }) => {
                        axios.defaults.headers.Authorization = `Bearer ${accessToken}`;

                        dispatch(setAuthState('success'));
                        console.log('DEBUG ACCESS TOKEN FOUND ');
                    })
                    .catch((err) => {
                        console.dir(err);

                        dispatch(setAuthState('interaction-required'));
                    });
            } else {
                console.log('no accounts hint');
                // no users in localStorage at all
                dispatch(setAuthState('no-users'));
            }
        }
    }, [inProgress, instance, accounts, dispatch]);
};

export default useAcquireTokenSilent;
