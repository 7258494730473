import { Text, Flex, Tooltip, Box } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useIntl } from 'react-intl';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';

const StudySummaryGrid = ({ data }) => {
    const intl = useIntl();

    const getValidationTooltip = (warnings) => {
        return warnings
            .map((warning) =>
                Object.keys(warning).length > 1
                    ? intl.formatMessage(
                          { id: warning.id },
                          { [Object.keys(warning)[1]]: warning[Object.keys(warning)[1]] }
                      )
                    : intl.formatMessage({ id: warning.id })
            )
            .join('. ');
    };

    return (
        <>
            {data.map((field, ind) => {
                if (field == null) return null;
                const { title, value, warnings } = field;

                return (
                    <PropertyNode key={ind}>
                        <PropertyLabel>{title}:</PropertyLabel>

                        <Box fontSize="14px" fontWeight="600" color="study-summary-grid">
                            {value}
                        </Box>

                        {warnings && warnings.length > 0 && (
                            <Tooltip label={getValidationTooltip(warnings)}>
                                <CustomInfoIcon />
                            </Tooltip>
                        )}
                    </PropertyNode>
                );
            })}
        </>
    );
};

const PropertyLabel = styled(Text)`
    text-align: start;
    font-size: 12px;
    font-weight: 500;
    opacity: 1;
    letter-spacing: 0.5px;
    line-height: 1.5;
    color: var(--chakra-colors-gray-600);
`;

const PropertyNode = styled(Flex)`
    grid-gap: 10px;
    align-items: center;
    margin: 10px 0px;
`;

const CustomInfoIcon = styled(InfoIcon)`
    width: 16px;
    padding-bottom: 2px;

    circle {
        fill: var(--chakra-colors-yellow-500);
        stroke: var(--chakra-colors-yellow-500);
    }

    path {
        stroke: var(--chakra-colors-white);
    }

    &:hover {
        cursor: pointer;
    }
`;

export default StudySummaryGrid;
