import { createSlice } from '@reduxjs/toolkit';
import { getUserRestorationPermission, getUserInputDataExtractionPermission } from './helpersApi';

const initialState = {
    isSidebarVisible: true,
    hasUnsavedChanges: false,
    searchTerm: '',
    canUseRestoration: false,
    canUseRestorationLoading: false,
    canUseInputDataExtract: false,
    canUseInputDataExtractLoading: false,
    arcGISOAuthToken: '',
};

const helpersSlice = createSlice({
    name: 'helpers',
    initialState,
    reducers: {
        onIsSidebarVisible(state, action) {
            state.isSidebarVisible = action.payload;
        },
        onHasUnsavedChanges(state, action) {
            state.hasUnsavedChanges = action.payload;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        setArcGISOAuthToken(state, action) {
            state.arcGISOAuthToken = action.payload;
        },
    },
    extraReducers: {
        [getUserRestorationPermission.fulfilled]: (state, action) => {
            state.canUseRestoration = action.payload;
            state.canUseRestorationLoading = false;
        },
        [getUserRestorationPermission.rejected]: (state) => {
            state.canUseRestorationLoading = false;
        },
        [getUserRestorationPermission.pending]: (state) => {
            state.canUseRestorationLoading = true;
        },
        [getUserInputDataExtractionPermission.fulfilled]: (state, action) => {
            state.canUseInputDataExtract = action.payload;
            state.canUseInputDataExtractLoading = false;
        },
        [getUserInputDataExtractionPermission.rejected]: (state) => {
            state.canUseInputDataExtractLoading = false;
        },
        [getUserInputDataExtractionPermission.pending]: (state) => {
            state.canUseInputDataExtractLoading = true;
        },
    },
});

export const { onIsSidebarVisible, onHasUnsavedChanges, setSearchTerm, setArcGISOAuthToken } = helpersSlice.actions;

export default helpersSlice.reducer;
