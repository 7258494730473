import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Button, Box, Text, Flex, useDisclosure, Divider, Heading, Table, Tbody, Tr, Td } from '@chakra-ui/react';

import { getStudyValidationRules, createStudy, verifyStudyData } from '../../services/studies';
import { lenientParse, enUSFormatExcludingTime, enUSFormatExcludingSeconds } from '../../services/items';

import { FormattedMessage, useIntl } from 'react-intl';
import { format } from 'date-fns';

import ConfirmationModal from '../modal/ConfirmationModal';
import MainModal from '../modal/MainModal';

import SimpleGridSkeleton from '../../components/utils/SimpleGridSkeleton';
import Error from '../../components/utils/Error';

import CustomCheckbox from '../../components/forms/CustomCheckbox';

import StudyValidationsModal from './StudyValidationsModal';
import StudySummaryGrid from './StudySummaryGrid';
import StudySubmittedModal from './StudySubmittedModal';

const StudyConfirmationModal = ({ onClose, studyData, isFOSimulationsFieldVisible }) => {
    const { book } = useSelector((state) => state.connections);
    const { books } = useSelector((state) => state.item.dataForItemCreation);

    const [validationRules, setValidationRules] = useState([]);
    const [selectedValidationRules, setSelectedValidationRules] = useState([]);
    const [skipValidationRules, setSkipValidationRules] = useState(false);
    const [newStudyId, setNewStudyId] = useState();

    const [isLoading, setIsLoading] = useState({
        validationRules: true,
        studyRun: false,
    });

    const [validationRulesLoadingError, setValidationRulesLoadingError] = useState(false);

    const runStudyConfirmationModal = useDisclosure();
    const studyValidationsModal = useDisclosure();
    const studySubmittedModal = useDisclosure();

    const intl = useIntl();

    const fetchValidationRules = useCallback(() => {
        return getStudyValidationRules()
            .then((data) => {
                setValidationRules(data);
            })
            .catch(() => setValidationRulesLoadingError(true))
            .finally(() => setIsLoading((prev) => ({ ...prev, validationRules: false })));
    }, []);

    useEffect(() => {
        fetchValidationRules();
    }, [fetchValidationRules]);

    const onValidationRuleSelect = (validationRuleId, isChecked) => {
        if (isChecked) {
            setSelectedValidationRules((prev) => [...prev, validationRuleId]);
        } else {
            setSelectedValidationRules((prev) => prev.filter((selectedId) => selectedId !== validationRuleId));
        }
    };

    const labels = {
        portfolios: intl.formatMessage({ id: 'portfolios_title' }),
        studyName: intl.formatMessage({ id: 'run_a_study_label_study_name' }),
        simulations: intl.formatMessage({ id: 'run_a_study_label_simulations' }),
        foSimulations: intl.formatMessage({ id: 'run_a_study_label_fo_simulations' }),
        priority: intl.formatMessage({ id: 'run_a_study_settings_priority' }),
        reports: intl.formatMessage({ id: 'run_a_study_settings_report_requirements' }),
        timeIntervals: intl.formatMessage({ id: 'run_a_study_settings_time_intervals' }),
        peakPeriods: intl.formatMessage({ id: 'run_a_study_settings_peak_periods' }),
        sensitivity: intl.formatMessage({ id: 'run_a_study_settings_sensitivity' }),
        variables: intl.formatMessage({ id: 'run_a_study_variables' }),
        runDate: intl.formatMessage({ id: 'run_a_study_settings_run_schedule' }),
        book: intl.formatMessage({ id: 'common_book' }),
        useStudyResultsFrom: intl.formatMessage({ id: 'run_a_study_label_use_results_from' }),
        simulationStart: intl.formatMessage({ id: 'run_a_study_label_simulation_start' }),
        simulationEnd: intl.formatMessage({ id: 'run_a_study_label_simulation_end' }),
        codebase: intl.formatMessage({ id: 'run_a_study_label_codebase' }),
        customCodebase: intl.formatMessage({ id: 'run_a_study_label_customCodebase' }),
        notes: intl.formatMessage({ id: 'run_a_study_label_notes' }),
        properties: intl.formatMessage({ id: 'run_a_study_label_studyProperties' }),
    };

    const isDeterministicChecked =
        studyData.isDeterministicChecked ||
        (parseInt(studyData.simulations) === 1 && parseInt(studyData.loadSimReps) === 1);

    let foSimulationsField = null;
    if (isFOSimulationsFieldVisible) {
        foSimulationsField = {
            title: labels.foSimulations,
            value: isDeterministicChecked
                ? intl.formatMessage({ id: 'run_a_study_simulations_deterministic' })
                : studyData.loadSimReps,
            warnings: verifyStudyData('foSimulations', {
                foSimulations: studyData.isDeterministicChecked ? 1 : studyData.loadSimReps,
                parentStudyFOSimulations: studyData.parentStudyInfo.loadSimReps,
            }),
        };
    }

    const parentStudy = studyData.studyResults?.find((elem) => elem.id === parseInt(studyData.parentStudyId));

    const props = [
        {
            title: labels.portfolios,
            value:
                studyData.portfolios.length > 0
                    ? studyData.portfolios.map((portfolio) => portfolio.properties.name).join(', ')
                    : intl.formatMessage({ id: 'run_a_study_no_portfolio_selected' }),
            warnings: verifyStudyData('portfolios', {
                portfolios: studyData.portfolios,
            }),
        },
        {
            title: labels.studyName,
            value: studyData.description,
            warnings: verifyStudyData('studyName'),
        },
        {
            title: labels.useStudyResultsFrom,
            value:
                studyData.parentStudyId === '' || parentStudy == null
                    ? intl.formatMessage({ id: 'run_a_study_placeholder_none' })
                    : parentStudy.description,
            warnings: verifyStudyData('useStudyResults', {
                parentStudyId: studyData.parentStudyId,
            }),
        },
        {
            title: labels.simulations,
            value: isDeterministicChecked
                ? intl.formatMessage({ id: 'run_a_study_simulations_deterministic' })
                : studyData.simulations,
            warnings: verifyStudyData('simulations', {
                simulations: studyData.isDeterministicChecked ? 1 : studyData.simulations,
                parentStudySimulations: studyData.parentStudyInfo.simulations,
            }),
        },
        foSimulationsField,
        {
            title: labels.simulationStart,
            value: studyData.startDate ? format(lenientParse(studyData.startDate), enUSFormatExcludingTime) : undefined,
            warnings: verifyStudyData('simulationStart', {
                startDate: studyData.startDate,
                parentStudyStartDate: studyData.parentStudyInfo.startDate,
            }),
        },
        {
            title: labels.simulationEnd,
            value: studyData.endDate ? format(lenientParse(studyData.endDate), enUSFormatExcludingTime) : undefined,
            warnings: verifyStudyData('simulationEnd', {
                endDate: studyData.endDate,
                parentStudyEndDate: studyData.parentStudyInfo.endDate,
            }),
        },
        {
            title: labels.priority,
            value: studyData.priority,
            warnings: verifyStudyData('priority'),
        },
        {
            title: labels.reports,
            value: studyData.reports.join(', '),
            warnings: verifyStudyData('reports'),
        },
        {
            title: labels.timeIntervals,
            value: studyData.settings.dateIntervals
                .filter((elem) => studyData.dateIntervalIds.includes(elem.id))
                .map((elem) => elem.description)
                .join(', '),
            warnings: verifyStudyData('timeIntervals', {
                settings: studyData.settings,
                dateIntervals: studyData.dateIntervalIds,
                reports: studyData.reports,
            }),
        },
        {
            title: labels.peakPeriods,
            value: studyData.settings.peakPeriods
                .filter((elem) => studyData.peakPeriodIds.includes(elem.id))
                .map((elem) => elem.description)
                .join(', '),
            warnings: verifyStudyData('peakPeriods'),
        },
        {
            title: labels.sensitivity,
            value: studyData.settings.sensitivities
                .filter((elem) => studyData.sensitivityIds.includes(elem.id))
                .map((elem) => elem.description)
                .join(', '),
            warnings: verifyStudyData('sensitivity', {
                settings: studyData.settings,
                sensitivities: studyData.sensitivityIds,
            }),
        },
        {
            title: labels.variables,
            value: studyData.variableGroups
                .map((elem) => elem.variables.length)
                .reduce((count, item) => count + item, 0),
            warnings: verifyStudyData('variables', {
                variableGroups: studyData.variableGroups,
            }),
        },
        {
            title: labels.runDate,
            value: format(studyData.runSchedule, enUSFormatExcludingSeconds),
            warnings: verifyStudyData('runDate', {
                runSchedule: studyData.runSchedule,
            }),
        },
        {
            title: labels.book,
            value: books.find((elem) => elem.id === book).name,
            warnings: verifyStudyData('books'),
        },
        {
            title: labels.codebase,
            value: studyData.codebase || '',
            warnings: verifyStudyData('codebase'),
        },
        {
            title: labels.customCodebase,
            value: studyData.customCodebase || '',
            warnings: verifyStudyData('customCodebase'),
        },
        {
            title: labels.notes,
            value: studyData.notes,
            warnings: verifyStudyData('notes'),
        },
        {
            title: labels.properties,
            value: studyData.otherProperties,
            warnings: verifyStudyData('properties'),
        },
    ];

    const onRunStudyConfirmationSuccess = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, studyRun: true }));
            runStudyConfirmationModal.onClose();

            const payload = {
                portfolioIds: studyData.portfolios.map((portfolio) => portfolio.properties.id),
                description: studyData.description,
                parentStudyId: studyData.parentStudyId !== '' ? studyData.parentStudyId : null,
                notes: studyData.notes,
                startDate: studyData.startDate,
                endDate: studyData.endDate,
                moduleIds: studyData.moduleIds,
                variableGroupIds: studyData.variableGroups.map((variableGroup) => variableGroup.variableGroupId),
                ptdPayOff: studyData.ptdPayOff,
                simReports: studyData.simReports,
                studyTypeId: studyData.studyTypeId,
                isTemplate: studyData.isTemplate,
                doNotDelete: studyData.doNotDelete,
                timeStepSizeId: studyData.timeStepSizeId,
                simulations: studyData.isDeterministicChecked ? 1 : parseInt(studyData.simulations),
                // Deterministic checkbox disables both sim and FO sim counts and sends "1" to the database.
                loadSimReps: studyData.isDeterministicChecked ? 1 : parseInt(studyData.loadSimReps),
                runSchedule: studyData.runSchedule,
                priority: studyData.priority,
                curvePriceTypeId: studyData.curvePriceTypeId,
                curveSourceId: studyData.curveSourceId,
                skipValidation: skipValidationRules,
                dateIntervalIds: studyData.dateIntervalIds,
                peakPeriodIds: studyData.peakPeriodIds,
                sensitivityIds: studyData.sensitivityIds,
                properties: studyData.studyProperties,
                validationRuleIds: selectedValidationRules,
                templateStudyId: studyData.studyTemplateId,
            };

            // Run Study creates a new study
            const newStudyId = await createStudy(payload);

            setNewStudyId(newStudyId);
            setIsLoading((prev) => ({ ...prev, studyRun: false }));

            if (skipValidationRules) {
                studySubmittedModal.onOpen();
            } else {
                studyValidationsModal.onOpen();
            }
        } catch (error) {
            setIsLoading((prev) => ({ ...prev, studyRun: false }));
        }
    };

    const isStudyRunButtonDisabled = () => {
        if (!skipValidationRules && selectedValidationRules.length === 0) {
            return true;
        }

        return false;
    };

    return (
        <>
            <MainModal
                size="4xl"
                scrollBehavior="inside"
                isOpen
                onClose={onClose}
                header={<FormattedMessage id="run_a_study_summary" />}
                content={
                    <>
                        <Box>
                            <StudySummaryGrid data={props} />

                            <Divider />

                            <Flex justifyContent="space-between" mt={5}>
                                <Heading as="h3" variant="h3" color="gray.600">
                                    <FormattedMessage id="run_a_study_study_validation" />
                                </Heading>

                                <Flex alignItems="center">
                                    <CustomCheckbox
                                        mr={2}
                                        isChecked={skipValidationRules}
                                        onChange={() => setSkipValidationRules((prev) => !prev)}
                                    >
                                        <FormattedMessage id="run_a_study_skip_validation" />
                                    </CustomCheckbox>
                                </Flex>
                            </Flex>

                            {isLoading.validationRules ? (
                                <Box mt={5}>
                                    <SimpleGridSkeleton rows={3} cols={1} mt={5} />
                                </Box>
                            ) : validationRulesLoadingError ? (
                                <Box m={6}>
                                    <Error
                                        primaryId="common_error"
                                        secondaryId="common_loading_error"
                                        additionalText="validation rules"
                                    />
                                </Box>
                            ) : (
                                <>
                                    {!skipValidationRules && (
                                        <>
                                            <Text size="sm" mt={5} pl={3} textTransform="uppercase" color="gray.600">
                                                <FormattedMessage id="run_a_study_validation_rule_name" />
                                            </Text>

                                            <Table mt={5}>
                                                <Tbody>
                                                    {validationRules.map(({ id, description }) => (
                                                        <Tr key={id}>
                                                            <Td>{description}</Td>
                                                            <Td>
                                                                <CustomCheckbox
                                                                    key={id}
                                                                    isChecked={selectedValidationRules.includes(id)}
                                                                    onChange={(event) =>
                                                                        onValidationRuleSelect(id, event.target.checked)
                                                                    }
                                                                />
                                                            </Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </>
                                    )}
                                </>
                            )}
                        </Box>
                    </>
                }
                footerRightSlot={
                    <Button
                        isDisabled={isStudyRunButtonDisabled()}
                        isLoading={isLoading.studyRun}
                        onClick={runStudyConfirmationModal.onOpen}
                        ml={3}
                    >
                        <FormattedMessage id="run_a_study_run_study" />
                    </Button>
                }
            />

            {runStudyConfirmationModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={runStudyConfirmationModal.onClose}
                    header={<FormattedMessage id="run_a_study_run_study" />}
                    hasExtraStep
                    content={<FormattedMessage id="run_a_study_confirmation_modal" />}
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="run_a_study_run_study" />
                        </Box>
                    }
                    onConfirm={onRunStudyConfirmationSuccess}
                />
            )}

            {studyValidationsModal.isOpen && (
                <StudyValidationsModal studyId={newStudyId} studyName={studyData.description} />
            )}

            {studySubmittedModal.isOpen && <StudySubmittedModal studyName={studyData.description} onClose={onClose} />}
        </>
    );
};

export default StudyConfirmationModal;
