import { useCallback, useState, useRef, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
import SecondaryButton from '../utils/SecondaryButton';
import DataGridWrapper from '../grid/DataGridWrapper';
import DataGrid from '../grid/DataGrid';
import JobsStatusModal from '../studies/JobsStatusModal';

import { formatMultisort } from '../../services/items';
import { noop } from '../../services/utils';

import SortOrderProvider from '../grid/utils/SortOrderContext';
import RestorationInputFilters from '../../components/utils/RestorationInputFilters';
import useCommonToast from '../../hooks/useCommonToast';

import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';

const ExpressAndRestorationGrid = ({
    columns,
    multisortPairs,
    gridApi,
    onGridReady,
    gridResource,
    filtersEnabled = false,
    statuses = [],
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const statusModal = useDisclosure();

    const defaultFiltersValues = useMemo(
        () => ({
            statusIds: [],
            portfolioNameQuery: '',
            studyNameQuery: '',
        }),
        []
    );

    const additionalFilters = useRef(defaultFiltersValues);

    const [selectedJobs, setSelectedJobs] = useState([]);
    const [checked, checkedToggle] = useState(false);
    const [jobsDataLoading, setJobsDataLoading] = useState(false);

    const refreshGridData = () => {
        gridApi.current.refreshServerSideStore({
            purge: true,
        });
    };

    const toggleSelectAllCheckbox = useCallback(
        (value) => {
            gridApi.current.forEachNode((node) => node.setSelected(value, false, true));
            const nodes = gridApi.current.getSelectedNodes();
            const jobs = nodes.map((node) => node.data);
            setSelectedJobs(jobs);
            checkedToggle(value);
        },
        [gridApi]
    );

    const formattedColumns = useMemo(() => {
        return columns.map((c) => {
            if (c?.cellRendererParams?.serversideCheckbox) {
                return {
                    ...c,
                    headerComponentParams: {
                        selectAllChecked: checked,
                        toggleSelectAllCheckbox: toggleSelectAllCheckbox,
                    },
                };
            }
            return c;
        });
    }, [checked, columns, toggleSelectAllCheckbox]);

    const changeJobsStatus = async (statusId) => {
        const successMessage = intl.formatMessage({ id: 'jobs_tracker_change_status_message' });

        statusModal.onClose();
        const payload = {
            jobIds: selectedJobs.map((elem) => elem.jobId),
            statusId: parseInt(statusId),
        };

        await gridResource.update(payload);
        toast(successMessage);

        setSelectedJobs([]);
        gridApi.current.forEachNode((node) => node.setSelected(false, false, true));
        checkedToggle(false);

        refreshGridData();
    };

    const serverSideDatasource = useMemo(() => {
        return {
            async getRows(params) {
                setJobsDataLoading(true);
                const page = params.request.startRow / 500 + 1;
                const sorting = formatMultisort(params.request.sortModel, multisortPairs);

                //Clear selection
                const previouslySelectedNodes = gridApi.current.getSelectedNodes().map((node) => {
                    node.setSelected(false, false, true);
                    return node.data.jobId;
                });

                const payload = {
                    paging: {
                        page,
                        pageSize: 500,
                    },
                    sorting,
                    ...additionalFilters.current,
                };

                const data = await gridResource.read(payload);

                if (!data.totalCount) {
                    params.api.showNoRowsOverlay();
                } else {
                    params.api.hideOverlay();
                }

                params.success({
                    rowData: data.items,
                    rowCount: data.totalCount,
                });

                let newlySelectedNodes = [];

                gridApi.current.forEachNode((node) => {
                    if (previouslySelectedNodes.includes(node.data?.jobId)) {
                        node.setSelected(true, false, true);
                        newlySelectedNodes.push(node.data);
                    }
                });

                setSelectedJobs(newlySelectedNodes);
                checkedToggle(data.totalCount && newlySelectedNodes.length === data.totalCount);
                setJobsDataLoading(false);
            },
        };
    }, [multisortPairs, gridResource, gridApi]);

    const onSelectionChanged = useCallback(() => {
        const nodes = gridApi.current.getSelectedNodes();
        const jobs = nodes.map((node) => node.data);
        setSelectedJobs(jobs);

        if (checked && jobs.length !== gridApi.current.getDisplayedRowCount()) {
            checkedToggle(false);
        }
        if (!checked && jobs.length === gridApi.current.getDisplayedRowCount()) {
            checkedToggle(true);
        }
    }, [checked, gridApi]);

    return (
        <SortOrderProvider>
            <Flex
                mb={1}
                alignItems="end"
                w={{ base: '100%', md: 'auto' }}
                gridGap="10px"
                flexDirection={{ base: 'column', md: 'row' }}
            >
                {selectedJobs.length > 0 && (
                    <Button
                        size="sm"
                        textTransform="capitalize"
                        onClick={statusModal.onOpen}
                        isDisabled={jobsDataLoading}
                    >
                        <FormattedMessage id="jobs_tracker_change_status" />
                    </Button>
                )}
                {filtersEnabled && (
                    <RestorationInputFilters
                        refreshGridData={refreshGridData}
                        statuses={statuses}
                        additionalFilters={additionalFilters}
                    />
                )}
                <SecondaryButton
                    leftIcon={<RefreshIcon />}
                    size="sm"
                    type="button"
                    variant="secondary"
                    textTransform="capitalize"
                    onClick={refreshGridData}
                    isLoading={jobsDataLoading}
                    loadingText={intl.formatMessage({ id: 'common_loading' })}
                >
                    <FormattedMessage id="common_refresh" />
                </SecondaryButton>
            </Flex>

            <JobsDataGridWrapper>
                <DataGrid
                    disableReadOnlyStyles={true}
                    onGridReady={onGridReady}
                    rowHeight={34}
                    onDelete={noop}
                    enableBrowserTooltips={true}
                    serverSideDatasource={serverSideDatasource}
                    onDataChange={noop}
                    paginationPageSize={500}
                    cacheBlockSize={500}
                    rowModelType="serverSide"
                    serverSideStoreType="partial"
                    columns={formattedColumns}
                    onSelectionChanged={onSelectionChanged}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    enableRangeSelection={false}
                    multisortPairs={multisortPairs}
                />
            </JobsDataGridWrapper>

            {statusModal.isOpen && (
                <JobsStatusModal onClose={statusModal.onClose} statuses={statuses} onStatusChange={changeJobsStatus} />
            )}
        </SortOrderProvider>
    );
};

const JobsDataGridWrapper = styled(DataGridWrapper)`
    flex: 1 1 300px;
    margin-top: 10px;
    padding-bottom: 54px;

    .ag-cell {
        line-height: 34px;
    }

    .ag-selection-checkbox {
        margin-top: 7px;
    }
`;

export default ExpressAndRestorationGrid;
