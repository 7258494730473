export const SHAPE_SET_SCHEMA_CODE = 'PSSHAPESET';

export const MONTHS = {
    ALL: 0,
    JAN: 1,
    FEB: 2,
    MAR: 3,
    APR: 4,
    MAY: 5,
    JUN: 6,
    JUL: 7,
    AUG: 8,
    SEP: 9,
    OCT: 10,
    NOV: 11,
    DEC: 12,
};
export const DAYS_OF_WEEK = {
    ALL: 0,
    SUN: 1,
    MON: 2,
    TUE: 3,
    WED: 4,
    THU: 5,
    FRI: 6,
    SAT: 7,
};

export const SHAPE_TYPE_COLUMN_NAME = 'SHAPETYPEID';
export const SHAPE_TYPES = {
    DoW_Hour: 'DOW-HOUR',
    Year_DoW_Hour: 'YEAR-DOW-HOUR',
    Month_DoW_Hour: 'MONTH-DOW-HOUR',
    Year_Month_DoW_Hour: 'YEAR-MONTH-DOW-HOUR',
    Day_Hour: 'DAY-HOUR',
    Year_Month_DoW: 'YEAR-MONTH-DOW',
    Month_Hour: 'MONTH-HOUR',
};

export const HOUR_COLUMN_NAME = 'HOURNUM';
export const VALUE_COLUMN_NAME = 'SHAPEVALUE';

export const DAYS_COUNT = 7;
export const HOURS_COUNT = 24;
export const DAYS_IN_YEAR = 365;
