const Breadcrumb = {
    parts: ['link', 'item', 'container', 'separator'],

    baseStyle: {
        container: {
            my: '16px',
        },

        link: {
            textTransform: 'capitalize',
            color: 'blue.400',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '20px',

            _dark: {
                color: 'white',
            },

            _activeLink: {
                color: 'gray.800',
                opacity: '0.8',

                _dark: {
                    color: 'gray.300',
                },
                _hover: {
                    textDecoration: 'none',
                    cursor: 'auto',
                },
            },
        },

        separator: {
            color: 'gray.400',
            mx: '2px',
        },
    },
};

export default Breadcrumb;
