import { useState, useEffect } from 'react';

import { getUserRoleDetails } from 'services/userManagement';

const useUserRoleDetails = (roleId) => {
    const [userRoleDetails, setUserRoleDetails] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        if (roleId) {
            setIsLoading(true);
            setIsError(false);

            getUserRoleDetails(roleId)
                .then((response) => {
                    setUserRoleDetails(response);
                })
                .catch(() => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [roleId]);

    return { userRoleDetails, isLoading, isError };
};

export default useUserRoleDetails;
