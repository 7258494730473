import { extendTheme } from '@chakra-ui/react';

import styles from './styles';
import fonts from './fonts';
import shadows from './shadows';
import { semanticTokens, colors } from './colors';
import breakpoints from './breakpoints';

import Accordion from './components/Accordion';
import Alert from './components/Alert';
import Badge from './components/Badge';
import Breadcrumb from './components/Breadcrumb';
import Button from './components/Button';
import Card from './components/Card';
import Checkbox from './components/Checkbox';
import Divider from './components/Divider';
import Drawer from './components/Drawer';
import FormError from './components/FormError';
import FormLabel from './components/FormLabel';
import Heading from './components/Heading';
import Input from './components/Input';
import Link from './components/Link';
import Modal from './components/Modal';
import NumberInput from './components/NumberInput';
import Popover from './components/Popover';
import Progress from './components/Progress';
import Radio from './components/Radio';
import Select from './components/Select';
import Switch from './components/Switch';
import Table from './components/Table';
import Tabs from './components/Tabs';
import Text from './components/Text';
import Textarea from './components/Textarea';
import Tooltip from './components/Tooltip';

const theme = extendTheme({
    styles,
    colors,
    fonts,
    shadows,
    breakpoints,
    components: {
        Accordion,
        Alert,
        Badge,
        Breadcrumb,
        Button,
        Card,
        Checkbox,
        Divider,
        Drawer,
        FormError,
        FormLabel,
        Heading,
        Input,
        Link,
        Modal,
        NumberInput,
        Popover,
        Progress,
        Radio,
        Select,
        Switch,
        Table,
        Tabs,
        Text,
        Textarea,
        Tooltip,
    },
    semanticTokens,
    initialColorMode: 'light',
    useSystemColorMode: false,
});

export default theme;
