import { useMediaQuery } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import TooltipIconList from '../utils/TooltipIconList';
import ContextMenu from '../utils/ContextMenu';

import { ReactComponent as Copy } from '../../icons/copy.svg';
import { ReactComponent as Expand } from '../../icons/expand.svg';
import { ReactComponent as AddPortfolio } from '../../icons/add-portfolio.svg';
import { ReactComponent as Move } from '../../icons/move.svg';
import { ReactComponent as Download } from '../../icons/download.svg';
import { ReactComponent as AddItem } from '../../icons/mdi-plus-circle.svg';
import { ReactComponent as RemoveIcon } from '../../icons/mdi-minus-circle.svg';

const PortfolioLabelIcons = ({
    selected,
    onDownload,
    onCopy,
    onExpandChange,
    onAddPortfolio,
    onMovePortfolio,
    onAddItems,
    onRemoveItems,
}) => {
    const [isDesktop] = useMediaQuery('(min-width: 769px)');
    const intl = useIntl();

    const items = [
        ...(onDownload
            ? [
                  {
                      id: 1,
                      label: intl.formatMessage({ id: 'portfolios_properties_download_btn_tooltip_and_label' }),
                      action: (event) => onDownload(selected, event),
                      Icon: Download,
                  },
              ]
            : []),
        ...(onCopy
            ? [
                  {
                      id: 2,
                      label: intl.formatMessage({ id: 'common_copy_btn_tooltip_and_label' }),
                      action: (event) => onCopy(selected, event),
                      Icon: Copy,
                  },
              ]
            : []),
        ...(onExpandChange
            ? [
                  {
                      id: 3,
                      label: intl.formatMessage({ id: 'portfolios_properties_expand_btn_tooltip_and_label' }),
                      action: (event) => onExpandChange(selected, event),
                      Icon: Expand,
                  },
              ]
            : []),
        ...(onAddPortfolio
            ? [
                  {
                      id: 4,
                      label: intl.formatMessage({ id: 'portfolios_properties_add_portfolio_btn_tooltip_and_label' }),
                      action: (event) => onAddPortfolio(selected, event),
                      Icon: AddPortfolio,
                  },
              ]
            : []),
        ...(onAddItems
            ? [
                  {
                      id: 5,
                      label: intl.formatMessage({ id: 'portfolios_properties_add_item_btn_tooltip_and_label' }),
                      action: (event) => onAddItems(selected, event),
                      Icon: AddItem,
                  },
              ]
            : []),
        ...(onRemoveItems
            ? [
                  {
                      id: 6,
                      label: intl.formatMessage({ id: 'portfolios_properties_remove_item_btn_tooltip_and_label' }),
                      action: (event) => onRemoveItems(selected, event),
                      Icon: RemoveIcon,
                  },
              ]
            : []),
        ...(onMovePortfolio
            ? [
                  {
                      id: 7,
                      label: intl.formatMessage({ id: 'portfolios_properties_move_btn_tooltip_and_label' }),
                      action: (event) => onMovePortfolio(selected, event),
                      Icon: Move,
                  },
              ]
            : []),
    ];

    return isDesktop ? (
        <TooltipIconList items={items} />
    ) : (
        <ContextMenu aria-label={intl.formatMessage({ id: 'common_actions' })} items={items} />
    );
};

export default PortfolioLabelIcons;
