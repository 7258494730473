import { chakra } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import CheckboxIcon from '../../icons/check.svg';

const DataGridWrapper = styled.div`
    height: 575px;

    .ag-body-viewport {
        border: 1px solid var(--chakra-colors-ag-body-viewport);
    }

    .ag-warning-issues .ag-body-viewport {
        border-color: var(--chakra-colors-orange-400);
    }

    .ag-error-issues .ag-body-viewport {
        border-color: var(--chakra-colors-text-error);
    }

    *,
    *:after,
    *:before,
    *:focus {
        box-sizing: border-box;
        outline: 0;
    }

    .ag-header-cell-text {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.66;
        color: var(--chakra-colors-ag-header-cell-text);
    }

    .ag-header-icon > img {
        max-width: unset;
        height: 100%;
    }

    .ag-header-cell {
        padding-bottom: 6px;
    }

    .ag-sort-order {
        display: none;
    }

    .ag-header-cell {
        padding-left: 8px;
    }

    .ag-cell:not(.ag-cell-inline-editing),
    .ag-loading {
        padding-left: 8px;
        padding-right: 8px;
    }

    .ag-react-container {
        height: 100%;
        display: flex;
        align-items: center;

        // this is to prevent content from jumping when selecting cells
        border-bottom: 1px solid transparent;

        > input {
            width: 100%;
            height: 100%;
        }
    }

    .ag-popup-editor .ag-react-container {
        min-width: 250px; // a default min width to prevent comboboxes from shrinking too much
        max-width: 400px;
        width: 100%;

        margin: 2px;

        > .react-datepicker-wrapper {
            width: 254px; // as wide as the opened datepicker
        }
    }

    .ag-cell-range-selected .ag-react-container {
        border-bottom: none;
    }

    .ag-cell {
        color: var(--chakra-colors-ag-cell-color);
        font-size: 14px;
        line-height: 44px;
        border-left: 1px solid var(--chakra-colors-ag-cell-border);
        border-top: 1px solid var(--chakra-colors-ag-cell-border);
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
    }

    .ag-cell.readonly {
        background-color: var(--chakra-colors-ag-readonly-cell-background);
        border-left: 1px solid var(--chakra-colors-ag-readonly-cell-border);
        border-right: 1px solid var(--chakra-colors-ag-readonly-cell-border);
        border-top: 1px solid var(--chakra-colors-ag-readonly-cell-border);
        color: var(--chakra-colors-ag-cell-color-readonly);
    }

    .ag-row-last .ag-cell {
        border-bottom: 1px solid var(--chakra-colors-ag-cell-border);
    }

    .ag-row-last .ag-cell.readonly {
        border-bottom: 1px solid var(--chakra-colors-gray-400);
    }

    .ag-cell.readonly + .ag-cell.readonly {
        border-left: 1px solid transparent;
    }

    .ag-row-even {
        background: var(--chakra-colors-ag-row-even);
    }

    .ag-menu-option-active,
    .ag-row-hover {
        background-color: var(--chakra-colors-ag-row-hover);
    }

    .ag-paging-panel {
        margin-top: 24px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 600;
        color: var(--chakra-colors-ag-paging-panel);
    }

    .ag-paging-description {
        margin-left: 16px;
        margin-right: 16px;
    }

    .ag-paging-page-summary-panel {
        display: flex;
        gap: 8px;
    }

    .ag-paging-row-summary-panel {
        margin-left: 0;
        margin-right: auto;
    }

    .ag-paging-button {
        background: var(--chakra-colors-ag-paging-button);
        border: 1px solid var(--chakra-colors-ag-cell-border);
        padding: 9px;
        border-radius: var(--chakra-radii-base);
    }

    .ag-paging-button.ag-disabled {
        cursor: not-allowed;
    }

    .ag-paging-button.ag-disabled img {
        opacity: 0.3;
    }

    .ag-header-cell-resize::after {
        content: '';
        position: absolute;
        z-index: 1;
        display: block;
        left: calc(50% - 1px);
        width: 2px;
        height: 40%;
        top: 15%;
        background-color: var(--chakra-colors-gray-300);
    }

    // cell selection
    .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
        border-left: 1px solid var(--chakra-colors-gray-400);
    }
    .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
        border-right: 1px solid var(--chakra-colors-gray-400);
    }
    .ag-row-selected {
        background-color: rgba(33, 150, 243, 0.3);
    }
    .ag-cell-range-selected:not(.ag-cell-popup-editing) {
        background-color: rgba(33, 150, 243, 0.2);
    }
    .ag-cell-range-selected:not(.ag-cell-popup-editing).ag-cell-range-chart {
        background-color: rgba(0, 88, 255, 0.1) !important;
    }
    .ag-cell-range-selected:not(.ag-cell-popup-editing).ag-cell-range-chart.ag-cell-range-chart-category {
        background-color: rgba(0, 255, 132, 0.1) !important;
    }
    .ag-cell-range-selected-1:not(.ag-cell-popup-editing) {
        background-color: rgba(33, 150, 243, 0.2);
    }
    .ag-cell-range-selected-2:not(.ag-cell-popup-editing) {
        background-color: rgba(33, 150, 243, 0.36);
    }
    .ag-cell-range-selected-3:not(.ag-cell-popup-editing) {
        background-color: rgba(33, 150, 243, 0.488);
    }
    .ag-cell-range-selected-4:not(.ag-cell-popup-editing) {
        background-color: rgba(33, 150, 243, 0.5904);
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-top {
        border-top-color: var(--chakra-colors-blue-300);
        border-top-width: 1px;
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right {
        border-right-color: var(--chakra-colors-blue-300);
        border-right-width: 1px;
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom {
        border-bottom-color: var(--chakra-colors-blue-300);
        border-bottom-width: 1px;
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left {
        border-left-color: var(--chakra-colors-blue-300);
        border-left-width: 1px;
    }
    .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
    .ag-ltr .ag-cell-range-single-cell,
    .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle {
        border: 1px solid var(--chakra-colors-blue-300);
        outline: initial;
    }
    .ag-cell-inline-editing {
        border-color: rgba(33, 150, 243, 0.4) !important;
    }

    .ag-cell.ag-cell-data-changed:not(.readonly) {
        background-color: var(--chakra-colors-blue-200);
    }

    .ag-header-select-all {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .ag-selection-checkbox {
        width: 18px;
        height: 18px;
        margin: 15px 10px 0 0;
        align-self: flex-start;
    }

    .ag-header-select-all,
    .ag-selection-checkbox {
        input[type='checkbox'] {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            width: 18px;
            height: 18px;
            background: var(--chakra-colors-ag-selection-checkbox);
            border: 2px solid var(--chakra-colors-ag-selection-border);
            border-radius: 2px;

            &:checked {
                content: url(${CheckboxIcon});
                background-color: var(--chakra-colors-green-500);
                border: 2px solid var(--chakra-colors-green-500);
                border-radius: 2px;
            }
        }
    }

    .ag-tooltip-custom {
        transition: opacity 0.5s ease-in;
    }
    .ag-tooltip-hiding {
        opacity: 0;
    }

    .ag-menu {
        background: var(--chakra-colors-ag-menu);
        color: var(--chakra-colors-ag-menu-text);
        padding: 8px 0;
        font-size: 16px;
        box-shadow: 0px 4px 10px rgba(26, 26, 26, 0.2);
        border-radius: 6px;
    }

    // the below style should to be removed if column menu tabs needs to be displayed
    .ag-menu-header {
        display: none;
    }

    .ag-header-cell-menu-button {
        width: 20px;
        display: flex;
        justify-content: flex-end;
        padding-right: 8px;
    }

    .ag-menu-option,
    .ag-header-cell-menu-button {
        cursor: pointer;
    }

    .ag-menu-option-text {
        padding: 6px 16px;
    }

    .ag-menu-separator {
        height: 16px;
    }

    .ag-menu-separator-part:after {
        content: '';
        display: block;
        border-top: 1px solid var(--chakra-colors-gray-200);
    }
    .ag-cell-editor input {
        background: var(--chakra-colors-background-primary);
    }
`;

export default chakra(DataGridWrapper);
