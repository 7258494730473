import { FormattedMessage } from 'react-intl';
import { Box } from '@chakra-ui/react';

import ConfirmationModal from '../modal/ConfirmationModal';

// Shared component by Loads/Batteries for deleting sub item of the given type
const DeleteRecordModal = ({ headerItem, contentMessageItem, forAutoForm, ...rest }) => {
    return (
        <ConfirmationModal
            isOpen
            header={
                <FormattedMessage
                    id={forAutoForm ? 'common_delete_btn_tooltip_and_label' : 'common_delete_modal_heading'}
                    values={{ item: headerItem }}
                />
            }
            hasExtraStep
            content={<FormattedMessage id="common_delete_modal_msg" values={{ item: contentMessageItem }} />}
            confirmText={
                <Box as="span" textTransform="capitalize">
                    <FormattedMessage id="common_delete" />
                </Box>
            }
            {...rest}
        />
    );
};

export default DeleteRecordModal;
