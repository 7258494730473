import { useState, useEffect } from 'react';

import { fetchEntryTypeMap } from '../../services/shapeSet';

const useShapeSetEntryTypeMap = () => {
    const [entryTypeMap, setEntryTypeMap] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);

        fetchEntryTypeMap()
            .then((response) => {
                setEntryTypeMap(response);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return { entryTypeMap, isLoading, isError };
};
export default useShapeSetEntryTypeMap;
