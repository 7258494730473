import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled/macro';
import { Flex, Badge, Tooltip } from '@chakra-ui/react';
import { ReactComponent as ErrorIcon } from '../../icons/warning.svg';
import { ReactComponent as WarningIcon } from '../../icons/soft-warning.svg';

import { getStudyStatusStyle } from '../../services/studies';

/**
 * cellRenderer: 'StudyTrackerStatusBadge' is used in Study and Jobs Grids
 */
const StudyTrackerStatusBadge = ({ value, data, isInJobsTable = false, pt = '2px' }) => {
    const status = useMemo(() => getStudyStatusStyle(value), [value]);

    // isInJobsTable == true: Job Grid data boolean props: hasError and hasWarning
    // isInJobsTable == false: Study Grid data array props: jobsWithError[] and jobsWithWarning[]
    const hasErros = isInJobsTable ? !!data?.hasError : !!data?.jobsWithError?.length;
    const hasWarnings = isInJobsTable ? !!data?.hasWarning : !!data?.jobsWithWarning?.length;

    let tooltipWarningsMessage = '';
    if (hasWarnings) {
        isInJobsTable
            ? (tooltipWarningsMessage = <FormattedMessage id="study_tracker_tooltip_job_warnings" />)
            : (tooltipWarningsMessage = (
                  <FormattedMessage
                      id="study_tracker_tooltip_study_warnings"
                      values={{ n: data?.jobsWithWarning?.length }}
                  />
              ));
    }

    if (hasErros) {
        isInJobsTable
            ? (tooltipWarningsMessage = <FormattedMessage id="study_tracker_tooltip_job_errors" />)
            : (tooltipWarningsMessage = (
                  <FormattedMessage
                      id="study_tracker_tooltip_study_errors"
                      values={{ n: data?.jobsWithError?.length }}
                  />
              ));
    }

    if (hasErros && hasWarnings) {
        isInJobsTable
            ? (tooltipWarningsMessage = <FormattedMessage id="study_tracker_tooltip_job_errors_and_warnings" />)
            : (tooltipWarningsMessage = (
                  <FormattedMessage
                      id="study_tracker_tooltip_study_errors_and_warnings"
                      values={{
                          errorsCount: data?.jobsWithError?.length,
                          warningsCount: data?.jobsWithWarning?.length,
                      }}
                  />
              ));
    }

    const StatusBadgeInfo = () => {
        return (
            <>
                {isInJobsTable ? (
                    // Job Status Message
                    <>{value}</>
                ) : (
                    // Study Status Message
                    <FormattedMessage
                        id="study_tracker_study_status"
                        values={{ status: value, percentComplete: data?.percentComplete }}
                    />
                )}
            </>
        );
    };

    const StatusWarnings = () => {
        return (
            (hasWarnings || hasErros) && (
                <Tooltip label={tooltipWarningsMessage}>
                    {hasErros ? <ErrorIcon /> : hasWarnings && <WarningIcon />}
                </Tooltip>
            )
        );
    };

    return (
        <BadgeWrapperDiv>
            <Flex justifyContent="space-between" align="center" pt={pt}>
                <Badge variant={status.style?.badgeVariant} colorScheme={status.style?.colorScheme}>
                    {!!value ? <StatusBadgeInfo /> : 'N/A'}
                </Badge>
                <StatusWarnings />
            </Flex>
        </BadgeWrapperDiv>
    );
};

const BadgeWrapperDiv = styled.div`
    width: 350px;
`;

function propsAreEqual(prevProps, nextProps) {
    const prevPropsHasErros = prevProps.isInJobsTable
        ? !!prevProps.data?.hasError
        : prevProps.data?.jobsWithError?.length;
    const prevPropsHasWarnings = prevProps.isInJobsTable
        ? !!prevProps.data?.hasWarning
        : prevProps.data?.jobsWithWarning?.length;

    const nextPropsHasErros = nextProps.isInJobsTable
        ? !!nextProps.data?.hasError
        : nextProps.data?.jobsWithError?.length;
    const nextPropsHasWarnings = nextProps.isInJobsTable
        ? !!nextProps.data?.hasWarning
        : nextProps.data?.jobsWithWarning?.length;

    const areEqual =
        prevProps.value === nextProps.value &&
        prevProps.isInJobsTable === nextProps.isInJobsTable &&
        prevProps.data?.percentComplete === nextProps.data?.percentComplete &&
        prevPropsHasErros === nextPropsHasErros &&
        prevPropsHasWarnings === nextPropsHasWarnings;

    return areEqual;
}

export default memo(StudyTrackerStatusBadge, propsAreEqual);
