import { Field, FastField } from 'formik';
import { Input, InputGroup } from '@chakra-ui/react';
import _ from 'lodash';

import FormField from './FormField';
import { useCallback } from 'react';

// If you need to extend Input functionality keep in mind that {...props} is on FormControl to support all Chakra props
// Specific props for Input should be excluded from ...props and manually added
const FormFieldComponent = ({
    field,
    meta,
    type,
    placeholder,
    form,
    leftElement,
    rightElement,
    showPlaceholderIfDisabled,
    ...props
}) => {
    const status = _.get(form.status, field.name);
    const isInvalid = meta.touched && (meta.error || status?.errors?.length > 0);
    const isWarning = meta.touched && status?.warnings?.length > 0;

    const getFieldValue = useCallback(
        (field) => {
            let value = '';

            if (!showPlaceholderIfDisabled || !props?.isDisabled || !placeholder) {
                // switching items quickly sometimes result in undefined value
                // zDefaultValue could be returned which means empty value
                value = field.value === undefined || field.value === 'zDefaultValue' ? '' : field.value;
            }

            return value;
        },
        [showPlaceholderIfDisabled, props, placeholder]
    );

    return (
        <FormField name={field.name} isInvalid={isInvalid} error={meta.error} status={status} {...props}>
            <InputGroup>
                {leftElement}

                <Input
                    {...field}
                    onChange={(e) => {
                        form.setStatus(_.omit(form.status, field.name));
                        field.onChange(e);
                    }}
                    type={type}
                    placeholder={placeholder}
                    borderColor={isWarning ? 'orange.300' : undefined}
                    _hover={isWarning ? { borderColor: 'orange.300' } : undefined}
                    value={getFieldValue(field)}
                    required={false}
                />
                {rightElement}
            </InputGroup>
        </FormField>
    );
};

const InputFormikField = ({ name, validate, isFastField = false, leftElement, rightElement, ...props }) => {
    return (
        <>
            {isFastField ? (
                <FastField name={name} validate={validate}>
                    {({ field, meta, form }) => (
                        <FormFieldComponent
                            field={field}
                            meta={meta}
                            form={form}
                            leftElement={leftElement}
                            rightElement={rightElement}
                            {...props}
                        />
                    )}
                </FastField>
            ) : (
                <Field name={name} validate={validate}>
                    {({ field, meta, form }) => (
                        <FormFieldComponent
                            field={field}
                            meta={meta}
                            form={form}
                            leftElement={leftElement}
                            rightElement={rightElement}
                            {...props}
                        />
                    )}
                </Field>
            )}
        </>
    );
};

export default InputFormikField;
