import { useState, useRef } from 'react';
import { Flex, Button, Box, Code, IconButton } from '@chakra-ui/react';
import Tooltip from '../utils/Tooltip';
import { ReactComponent as CopyIcon } from '../../icons/copy-circled.svg';
import { appVersion, copyTextToClipboard } from '../../services/utils';

// A custom component to show error message and details section
const ErrorAccordion = ({ message, details, status = 'info' }) => {
    const [showDetails, setShowDetails] = useState('none');
    const textRef = useRef(null);
    const dateNow = new Date();

    function toggleDetails() {
        let val = showDetails === 'none' ? 'block' : 'none';
        setShowDetails(val);
    }

    function handleClick() {
        copyTextToClipboard(textRef?.current?.innerText);
    }

    const copyIconStyle = {
        display: showDetails,
        cursor: 'pointer',
    };

    const messageColor = `${status}-error-accordion-message-text-color`;
    const borderColor = `${status}-error-accordion-border-color`;

    return (
        <>
            <Flex>
                <Box mt={1} mr={2} color={messageColor}>
                    {message}
                </Box>
                {details && (
                    <Button onClick={toggleDetails} size="xs" variant="outline" mt={1} mr={5} ml={5}>
                        {'Details'}
                    </Button>
                )}
                <Tooltip label={'click to copy details to clipboard'}>
                    <IconButton
                        aria-label={'click to copy details to clipboard'}
                        size="xs"
                        variant="circular-icon"
                        mt={0}
                        leftIcon={<CopyIcon />}
                        style={copyIconStyle}
                        onClick={handleClick}
                    />
                </Tooltip>
            </Flex>
            <Flex
                mt={2}
                mb={2}
                maxHeight={'38em'}
                borderWidth="1px"
                borderRadius="md"
                borderColor={borderColor}
                overflow={'auto'}
                style={{ display: showDetails }}
            >
                <Code w="100%" p={2} color="gray.800" bgColor="gray.100" ref={textRef}>
                    <p>{details}</p>
                    <p>{appVersion}</p>
                    <p>{dateNow?.toString()}</p>
                </Code>
            </Flex>
        </>
    );
};

export default ErrorAccordion;
