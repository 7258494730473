import { useCallback } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';

import { DATA_TYPES, INPUT_TYPES } from 'constants/fields';

const useAutoformFormValidation = ({ validationSettings }) => {
    const intl = useIntl();

    const onValidate = useCallback(
        (values) => {
            const clientSideErrors = validationSettings.reduce((acc, field) => {
                if (field?.onBeforeValidate && !field?.onBeforeValidate(values)) {
                    return acc;
                }

                const value = _.get(values, field.inputName);

                // check for empty fields (a.k.a required fields)
                // type of value can be string or boolean
                // we also don't check for invalid dates, numbers or booleans, because the form controls don't permit them
                if (field.isRequired && typeof value === 'string' && value.length === 0) {
                    _.set(
                        acc,
                        field.inputName,
                        intl.formatMessage({ id: 'common_forms_validation_required' }, { label: field.label })
                    );

                    return acc;
                } else if (
                    field.inputType === INPUT_TYPES.NumericUpDown ||
                    (field.inputType === INPUT_TYPES.TextBox &&
                        (field.dataType === DATA_TYPES.Int || field.dataType === DATA_TYPES.Double))
                ) {
                    const val = Number(value);
                    if (field.NUDMIN != null && val < field.NUDMIN) {
                        _.set(
                            acc,
                            field.inputName,
                            intl.formatMessage({ id: 'common_forms_gt' }, { label: field.label }) + ' ' + field.NUDMIN
                        );
                    }

                    if (field.NUDMAX != null && val > field.NUDMAX) {
                        _.set(
                            acc,
                            field.inputName,
                            intl.formatMessage({ id: 'common_forms_lt' }, { label: field.label }) + ' ' + field.NUDMAX
                        );
                    }
                } else if (typeof value === 'string' && field.inputType === INPUT_TYPES.TextBox) {
                    if (
                        field.STRINGMINLENGTH != null &&
                        value.length > 0 &&
                        value.length < parseInt(field.STRINGMINLENGTH)
                    ) {
                        _.set(
                            acc,
                            field.inputName,
                            intl.formatMessage(
                                { id: 'common_forms_string_gt' },
                                { label: field.label, limit: field.STRINGMINLENGTH }
                            )
                        );
                    }
                    if (field.STRINGMAXLENGTH != null && value.length > parseInt(field.STRINGMAXLENGTH)) {
                        _.set(
                            acc,
                            field.inputName,
                            intl.formatMessage(
                                { id: 'common_forms_string_lt' },
                                { label: field.label, limit: field.STRINGMAXLENGTH }
                            )
                        );
                    }
                }

                return acc;
            }, {});

            return clientSideErrors;
        },
        [validationSettings, intl]
    );

    return { onValidate };
};

export default useAutoformFormValidation;
