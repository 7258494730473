import { useState, useMemo, useCallback } from 'react';
import { Button, SimpleGrid, Flex, Box, useDisclosure, Spacer } from '@chakra-ui/react';
import { Form, Formik, setNestedObjectValues } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { columnTypes } from '../../services/grid';
import { isNumberCellEmpty } from '../../services/items';
import { mapGeneralValidationMessages } from '../autoform/utils/autoformUtils';

import useCommonToast from '../../hooks/useCommonToast';

import GeneralFormValidationsAlert from '../forms/GeneralFormValidationsAlert';

import AddDataModalField from './AddDataModalField';
import { isSubmitDisabled } from 'services/utils';

const AddSingleRowForm = ({ columns, onAdd, onValidate: onServerValidate, isLoading, isValidating, onClose }) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const errorsDisclosure = useDisclosure({ defaultIsOpen: true });
    const warningsDisclosure = useDisclosure({ defaultIsOpen: true });
    const [generalErrors, setGeneralErrors] = useState([]);
    const [generalWarnings, setGeneralWarnings] = useState([]);
    const [globalErrors, setGlobalErrors] = useState([]);
    const [globalWarnings, setGlobalWarnings] = useState([]);

    const allErrors = useMemo(() => [...globalErrors, ...generalErrors], [generalErrors, globalErrors]);
    const allWarnings = useMemo(() => [...globalWarnings, ...generalWarnings], [generalWarnings, globalWarnings]);

    const formControls = useMemo(() => {
        return columns.map((col) => {
            const defaultParams = columnTypes.hasOwnProperty(col.type) ? columnTypes[col.type].cellEditorParams : {};

            // If column has 'editable' property then check that, otherwise check 'isReadonly'
            // If column does not have 'isReadonly' property then edit will be allowed
            const isEditable = col.editable == null ? !col.isReadonly : col.editable;

            return {
                id: col.field,
                label: col.headerName,
                type: col.type,
                editable: isEditable,
                info: col.headerTooltip,
                params: col.cellEditorParams || defaultParams || {},
            };
        });
    }, [columns]);

    /**
     * Transforms the form values to expected BE API format based on the field type.
     * BE expects empty, non-required values to be `null` and number values to be a `Number` or `null`.
     * We don't transform other field types like a date, checkbox, or select, because the form controls already provide
     * the correctly formatted values with the correct timezone offset.
     */
    const formatFieldValues = useCallback(
        (values) => {
            return formControls.reduce((acc, field) => {
                const value = values[field.id];

                if (value === '') {
                    acc[field.id] = null;

                    return acc;
                }

                if (field.type === 'number') {
                    acc[field.id] = isNumberCellEmpty(value);

                    return acc;
                }

                acc[field.id] = value;

                return acc;
            }, {});
        },
        [formControls]
    );

    const resetServerValidation = useCallback(() => {
        setGeneralErrors([]);
        setGeneralWarnings([]);
        setGlobalErrors([]);
        setGlobalWarnings([]);
    }, []);

    const onSubmit = useCallback(
        async (values, { setErrors, setStatus }) => {
            try {
                const formattedValues = formatFieldValues(values);

                await onAdd([formattedValues]);
            } catch (err) {
                // reset the previous general errors and warnings
                resetServerValidation();

                // make sure to re-open the disclosures again,
                // because the user might have closed them and is submitting the form again
                errorsDisclosure.onOpen();
                warningsDisclosure.onOpen();

                if (err.response.status !== 401 && err.response.status !== 500) {
                    if (err.response.data.issues) {
                        // when creating a single record we expect at most only 1 issue
                        const issue = err.response.data.issues[0];

                        // handle general errors and warnings
                        const { warnings, errors } = mapGeneralValidationMessages(issue.generalValidationMessages);
                        setGeneralWarnings(warnings);
                        setGeneralErrors(errors);

                        // handle specific field errors
                        let fieldErrors = {};
                        let fieldStatus = {};

                        for (const field of issue.fields) {
                            const errors = field.validationMessages
                                .filter(({ severity }) => severity === 'Error')
                                .map(({ message }) => message);

                            const warnings = field.validationMessages
                                .filter(({ severity }) => severity === 'Warning')
                                .map(({ message }) => message);
                            fieldStatus[field.name] = { errors, warnings };
                        }

                        setErrors(fieldErrors);
                        setStatus(fieldStatus);
                    }

                    if (err.response?.data?.globalIssues) {
                        // handle global errors and warnings
                        const { warnings, errors } = mapGeneralValidationMessages(err.response.data.globalIssues);
                        setGlobalWarnings(warnings);
                        setGlobalErrors(errors);
                    }

                    if (err.response?.data?.error || err.response?.data?.title) {
                        const serverError = err.response.data.error || err.response.data.title;

                        toast({
                            status: 'error',
                            message: serverError || intl.formatMessage({ id: 'common_generic_saving_error' }),
                        });
                    }
                }
            }
        },
        [intl, toast, onAdd, formatFieldValues, resetServerValidation, errorsDisclosure, warningsDisclosure]
    );

    const onValidate = useCallback(
        (values) => {
            const clientSideErrors = formControls.reduce((acc, field) => {
                const value = values[field.id];

                // check for empty fields (a.k.a required fields)
                // type of value can be string or boolean
                // we also don't check for invalid dates, numbers or booleans, because the form controls don't permit them
                if (field.params.required && typeof value === 'string' && value.length === 0) {
                    acc[field.id] = intl.formatMessage(
                        { id: 'common_forms_validation_required' },
                        { label: field.label }
                    );

                    return acc;
                } else if (field.type === 'number') {
                    const val = Number(value);

                    if (field.params.min != null && val < field.params.min) {
                        acc[field.id] =
                            intl.formatMessage({ id: 'common_forms_gt' }, { label: field.label }) +
                            ' ' +
                            field.params.min;
                    }

                    if (field.params.max != null && val > field.params.max) {
                        acc[field.id] =
                            intl.formatMessage({ id: 'common_forms_lt' }, { label: field.label }) +
                            ' ' +
                            field.params.max;
                    }
                } else if (field.type === 'text') {
                    if (field.params.minLength !== null && value.length > 0 && value.length < field.params.minLength) {
                        acc[field.id] = intl.formatMessage(
                            { id: 'common_forms_string_gt' },
                            { label: field.label, limit: field.params.minLength }
                        );
                    }
                    if (field.params.maxLength !== null && value.length > field.params.maxLength) {
                        acc[field.id] = intl.formatMessage(
                            { id: 'common_forms_string_lt' },
                            { label: field.label, limit: field.params.maxLength }
                        );
                    }
                }

                return acc;
            }, {});

            return clientSideErrors;
        },
        [formControls, intl]
    );

    const initialValues = useMemo(() => {
        return columns.reduce((acc, column) => {
            // if column has defaultValue set it
            let defaultValue = '';
            if (column.type === 'number' || column.type === 'text' || column.type === 'select') {
                defaultValue = column.cellEditorParams?.defaultValue ? column.cellEditorParams?.defaultValue : '';
            }
            // when using checkboxes with Formik a boolean or array is expected
            acc[column.field] = column.type === 'checkbox' ? false : defaultValue;

            return acc;
        }, {});
    }, [columns]);

    const onCheck = useCallback(
        async (values, setErrors, setStatus, setTouched, validateForm) => {
            const errors = await validateForm();
            // reset the previous general errors and warnings
            resetServerValidation();

            if (Object.keys(errors).length > 0) {
                setTouched(setNestedObjectValues(errors, true));
            } else {
                try {
                    const formattedValues = formatFieldValues(values);

                    const data = await onServerValidate([formattedValues]);

                    // make sure to re-open the disclosures again
                    warningsDisclosure.onOpen();

                    if (data.issues) {
                        // reset the previous general warnings
                        setGeneralWarnings([]);

                        // when creating a single record we expect at most only 1 issue
                        const issue = data.issues[0];

                        // handle general warnings
                        const { warnings } = mapGeneralValidationMessages(issue.generalValidationMessages);
                        setGeneralWarnings(warnings);

                        // handle specific field warnings
                        let fieldStatus = {};

                        for (const field of issue.fields) {
                            const warnings = field.validationMessages
                                .filter(({ severity }) => severity === 'Warning')
                                .map(({ message }) => message);
                            fieldStatus[field.name] = { warnings };
                        }

                        setStatus(fieldStatus);
                        setTouched(setNestedObjectValues(fieldStatus, true));
                    }

                    if (data.globalIssues) {
                        // handle global warnings
                        const { warnings } = mapGeneralValidationMessages(data.globalIssues);
                        setGlobalWarnings(warnings);
                    }

                    if (!data) {
                        toast(intl.formatMessage({ id: 'common_check_data_success' }));
                    }
                } catch (err) {
                    // make sure to re-open the disclosures again,
                    // because the user might have closed them and is submitting the form again
                    errorsDisclosure.onOpen();
                    warningsDisclosure.onOpen();

                    if (err.response?.status !== 500) {
                        if (err.response?.data?.issues) {
                            // when creating a single record we expect at most only 1 issue
                            const issue = err.response.data.issues[0];

                            // handle general errors and warnings
                            const { warnings, errors } = mapGeneralValidationMessages(issue.generalValidationMessages);
                            setGeneralWarnings(warnings);
                            setGeneralErrors(errors);

                            // handle specific field errors
                            let fieldErrors = {};
                            let fieldStatus = {};

                            for (const field of issue.fields) {
                                const errors = field.validationMessages
                                    .filter(({ severity }) => severity === 'Error')
                                    .map(({ message }) => message);

                                const warnings = field.validationMessages
                                    .filter(({ severity }) => severity === 'Warning')
                                    .map(({ message }) => message);
                                fieldStatus[field.name] = { errors, warnings };
                            }

                            setErrors(fieldErrors);
                            setStatus(fieldStatus);
                            setTouched(setNestedObjectValues(fieldStatus, true));
                        }

                        if (err.response?.data?.globalIssues) {
                            // handle global errors and warnings
                            const { warnings, errors } = mapGeneralValidationMessages(err.response.data.globalIssues);
                            setGlobalWarnings(warnings);
                            setGlobalErrors(errors);
                        }
                    }
                }
            }
        },
        [intl, toast, errorsDisclosure, formatFieldValues, resetServerValidation, onServerValidate, warningsDisclosure]
    );

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={onValidate}>
            {({ errors, values, touched, isSubmitting, setTouched, validateForm, setStatus, setErrors }) => {
                const isDisabled = isSubmitDisabled({ errors, touched });

                return (
                    <Form noValidate>
                        {/* ^ disable the built-in HTML form validations, because we have custom ones */}

                        {allErrors.length > 0 && (
                            <GeneralFormValidationsAlert
                                isOpen={errorsDisclosure.isOpen}
                                onClose={errorsDisclosure.onClose}
                                messages={allErrors}
                            />
                        )}
                        {allWarnings.length > 0 && (
                            <GeneralFormValidationsAlert
                                status="warning"
                                title={<FormattedMessage id="common_warning" />}
                                isOpen={warningsDisclosure.isOpen}
                                onClose={warningsDisclosure.onClose}
                                messages={allWarnings}
                            />
                        )}

                        <SimpleGrid columns={{ base: 1, sm: 2, md: formControls.length > 12 ? 4 : 2 }} gap={6}>
                            {formControls.map((col) => (
                                <AddDataModalField key={col.id} {...col} />
                            ))}
                        </SimpleGrid>

                        <Flex
                            justify="flex-end"
                            borderTop="1px"
                            borderColor="border-secondary"
                            pt={4}
                            pb={2}
                            px={6}
                            mx={-6}
                            mt={9}
                        >
                            {onServerValidate && (
                                <Button
                                    variant="secondary"
                                    onClick={() => onCheck(values, setErrors, setStatus, setTouched, validateForm)}
                                    isDisabled={isDisabled}
                                    isLoading={isValidating}
                                >
                                    <Box as="span" textTransform="capitalize">
                                        <FormattedMessage id="common_check_data" />
                                    </Box>
                                </Button>
                            )}

                            <Spacer />

                            <Button variant="secondary" onClick={onClose}>
                                <Box as="span" textTransform="capitalize">
                                    <FormattedMessage id="common_cancel" />
                                </Box>
                            </Button>

                            <Button
                                isDisabled={isDisabled}
                                isLoading={isLoading || isSubmitting}
                                ml={4}
                                type="submit"
                                textTransform="capitalize"
                                variant="primary-success"
                            >
                                <FormattedMessage id="common_add" />
                            </Button>
                        </Flex>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AddSingleRowForm;
