import { useState, useEffect, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';

import SortAscIcon from '../../icons/sort-asc.svg';
import SortDescIcon from '../../icons/sort-desc.svg';
import SortNoneIcon from '../../icons/sort-none.svg';
import CheckboxField from '../forms/CheckboxField';

const CheckboxColumnHeader = ({
    setSort,
    column,
    enableSorting,
    displayName,
    api,
    toggleSelectAllCheckbox,
    selectAllChecked,
}) => {
    const [ascSort, setAscSort] = useState('inactive');
    const [descSort, setDescSort] = useState('inactive');
    const [noSort, setNoSort] = useState('inactive');

    const onSortChanged = useCallback(() => {
        setAscSort(column.isSortAscending() ? 'active' : 'inactive');
        setDescSort(column.isSortDescending() ? 'active' : 'inactive');
        setNoSort(!column.isSortAscending() && !column.isSortDescending() ? 'active' : 'inactive');
    }, [column]);

    const onSortRequested = (order, event) => {
        setSort(order, event.shiftKey);
    };

    useEffect(() => {
        column.addEventListener('sortChanged', onSortChanged);
        onSortChanged();
    }, [column, onSortChanged]);

    const nextSort = (event) => {
        if (ascSort === 'active') onSortRequested('desc', event);
        if (descSort === 'active') onSortRequested('', event);
        if (noSort === 'active') onSortRequested('asc', event);
    };

    let sort = null;
    if (enableSorting) {
        sort = (
            <div style={{ display: 'inline-block' }}>
                {ascSort === 'active' && (
                    <div
                        onClick={(event) => onSortRequested('desc', event)}
                        onTouchEnd={(event) => onSortRequested('desc', event)}
                        className={`customSortDownLabel ${ascSort}`}
                    >
                        <img src={SortAscIcon} alt="" />
                    </div>
                )}
                {descSort === 'active' && (
                    <div
                        onClick={(event) => onSortRequested('', event)}
                        onTouchEnd={(event) => onSortRequested('', event)}
                        className={`customSortUpLabel ${descSort}`}
                    >
                        <img src={SortDescIcon} alt="" />
                    </div>
                )}
                {noSort === 'active' && (
                    <div
                        onClick={(event) => onSortRequested('asc', event)}
                        onTouchEnd={(event) => onSortRequested('asc', event)}
                        className={`customSortRemoveLabel ${noSort}`}
                    >
                        <img src={SortNoneIcon} alt="" />
                    </div>
                )}
            </div>
        );
    }

    return (
        <Flex alignItems={{ base: 'flex-start', md: 'center' }} p={'1px'}>
            <CheckboxField
                isChecked={selectAllChecked}
                onChange={(event) => toggleSelectAllCheckbox(event.target.checked)}
                flex="max-content 0 0"
                mr={3}
            />
            <div className="ag-cell-label-container" role="presentation">
                <div className="ag-header-cell-label" role="presentation">
                    <span onClick={nextSort} className="ag-header-cell-text" role="columnheader">
                        {displayName}
                    </span>
                    {sort}
                </div>
            </div>
        </Flex>
    );
};

export default CheckboxColumnHeader;
