import { useRef, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import DataGridWrapper from '../grid/DataGridWrapper';
import DataGrid from '../grid/DataGrid';

const SpotPriceTab = ({ options, basis, updateBasis, issues }) => {
    const intl = useIntl();
    const gridApi = useRef(null);

    const columns = useMemo(
        () => [
            {
                field: 'refSpotPriceId',
                type: 'select',
                cellEditorParams: { options: options.spotPrices, required: false },
                headerName: intl.formatMessage({ id: 'basis_reference_spot_price' }),
                minWidth: 180,
                sortable: false,
            },
            {
                field: 'delSpotPriceId',
                type: 'select',
                cellEditorParams: { options: options.spotPrices, required: false },
                headerName: intl.formatMessage({ id: 'basis_delivery_spot_price' }),
                minWidth: 180,
                sortable: false,
            },
        ],
        [intl, options.spotPrices]
    );

    const onGridReady = useCallback(
        (params) => {
            const { delSpotPriceId, refSpotPriceId } = basis;
            gridApi.current = params.api;

            gridApi.current.setRowData([
                {
                    delSpotPriceId,
                    refSpotPriceId,
                },
            ]);
        },
        [basis]
    );

    const updateBasisData = useCallback(
        async (rowIds) => {
            const rows = rowIds.map((id) => gridApi.current.getRowNode(id));
            const { delSpotPriceId, refSpotPriceId } = rows[0].data;

            updateBasis({
                ...basis,
                delSpotPriceId,
                refSpotPriceId,
            });
        },
        [basis, updateBasis]
    );

    return (
        <DataGridWrapper>
            <DataGrid
                onGridReady={onGridReady}
                onDataChange={updateBasisData}
                suppressPaginationPanel
                columns={columns}
                issues={issues}
            />
        </DataGridWrapper>
    );
};

export default SpotPriceTab;
