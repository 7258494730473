import { useState, useCallback, useMemo } from 'react';

import { generateBulkUpdateFields, getCheckedTabFields } from '../../../services/thermals';

import {
    MULTIPLE_PLACEHOLDER_VALUE,
    CHECKED,
    UNCHECKED,
    INDETERMINATE,
    BULK_FIELD_TOUCHED_PLACEHOLDER_VALUE,
} from '../../../constants/thermal';

const useBulkUpdate = () => {
    const [bulkUpdateVisibility, setBulkUpdateVisibility] = useState({});
    const [bulkUpdateLoading, setBulkUpdateLoading] = useState(false);

    // bulkUpdateFields have fields values for display in Tab's Varied By Date section
    const [bulkUpdateFields, setBulkUpdateFields] = useState({});

    // changedBulkUpdateFields have fields values to pass to form submit to do bulk update of Tab's data
    const [changedBulkUpdateFields, setChangedBulkUpdateFields] = useState({});

    const [allVariedByDate, setAllVariedByDate] = useState({}); // {[subThermalId]: {[tabName]: (CHECKED or UNCHECKED or INDETERMINATE)}}
    const [serverErrors, setServerErrors] = useState([]); //[{ colName: "field column name", error: "error message"}]

    const generateFields = useCallback(
        (subThermalId, metadata, gridKey, options) => {
            const values = bulkUpdateFields[subThermalId] || {};
            return generateBulkUpdateFields(subThermalId, gridKey, metadata, values, options, serverErrors);
        },
        [bulkUpdateFields, serverErrors]
    );

    const clearBulkUpdateState = useCallback(() => {
        setChangedBulkUpdateFields({});
    }, []);

    const updateAllVariedByDate = useCallback((subThermalId, tabName, fields) => {
        const checkedTabFields = getCheckedTabFields(fields);

        const isAllVariedByDateIndeterminate = checkedTabFields.length > 0;
        const isAllVariedByDateUnchecked = checkedTabFields.length === 0;
        const isAllVariedByDateChecked = Object.keys(fields).length === checkedTabFields.length;

        const mutateAllVariedByDate = (state) => {
            setAllVariedByDate((prev) => ({
                ...prev,
                [subThermalId]: { ...prev[subThermalId], [tabName]: state },
            }));
        };

        if (isAllVariedByDateUnchecked) {
            mutateAllVariedByDate(UNCHECKED);
        } else if (isAllVariedByDateChecked && isAllVariedByDateIndeterminate) {
            mutateAllVariedByDate(CHECKED);
        } else if (isAllVariedByDateIndeterminate) {
            mutateAllVariedByDate(INDETERMINATE);
        }
    }, []);

    const getBulkUpdateState = useCallback(
        async (subThermalId, getState, tabName) => {
            setBulkUpdateLoading(true);

            let data = await getState(subThermalId);
            const hasTableData = data.hasData;

            delete data.hasData;
            setBulkUpdateVisibility((prev) => {
                return {
                    ...prev,
                    [subThermalId]: {
                        ...prev[subThermalId],
                        [tabName]: hasTableData,
                    },
                };
            });

            const maybeAddMultiple = (list) => (list.length > 1 ? list.concat(MULTIPLE_PLACEHOLDER_VALUE) : list);

            const newFields = {};
            for (const key in data) {
                newFields[key] = maybeAddMultiple(data[key]);
            }

            updateAllVariedByDate(subThermalId, tabName, newFields);
            setBulkUpdateFields((prev) => {
                return {
                    ...prev,
                    [subThermalId]: {
                        ...prev[subThermalId],
                        [tabName]: newFields,
                    },
                };
            });
            setBulkUpdateLoading(false);
        },
        [setBulkUpdateFields, updateAllVariedByDate]
    );

    const onFieldChange = useCallback(
        (subThermalId, name, value, tabName) => {
            const newFields = { ...bulkUpdateFields };

            if (Array.isArray(value)) {
                const updateValue = value[0];

                for (const key in newFields[subThermalId][tabName]) {
                    if (key === name) {
                        newFields[subThermalId][tabName][key] = [updateValue, BULK_FIELD_TOUCHED_PLACEHOLDER_VALUE];
                    }
                }

                setBulkUpdateFields(newFields);
                setChangedBulkUpdateFields((prev) => {
                    return {
                        ...prev,
                        [subThermalId]: {
                            ...prev[subThermalId],
                            [tabName]: { ...prev[subThermalId]?.[tabName], [name]: [updateValue] },
                        },
                    };
                });
            }
        },
        [bulkUpdateFields]
    );

    const onToggleVariedByDate = useCallback(
        (subThermalId, name, tabName) => {
            const oldValue = bulkUpdateFields[subThermalId][tabName][name];

            // Toggled MULTIPLE selected switch/checkbox varied by date
            const newValue = oldValue?.includes(MULTIPLE_PLACEHOLDER_VALUE)
                ? oldValue?.filter((c) => c !== MULTIPLE_PLACEHOLDER_VALUE)
                : oldValue?.concat(MULTIPLE_PLACEHOLDER_VALUE);

            // True  if we have varied by date check box checked.
            // False if we have varied by date check box unchecked
            // corresponding changedBulkUpdateFields field value will be used for bulk update on form submit
            const isMultipleSelected = !!newValue?.includes(MULTIPLE_PLACEHOLDER_VALUE);

            const tabValues = {
                ...bulkUpdateFields[subThermalId][tabName],
                [name]: newValue,
            };

            const updatedBulkFields = {
                ...bulkUpdateFields,
                [subThermalId]: { ...bulkUpdateFields[subThermalId], [tabName]: tabValues },
            };

            updateAllVariedByDate(subThermalId, tabName, updatedBulkFields[subThermalId][tabName]);
            setBulkUpdateFields(updatedBulkFields);

            // Was this bulk update value already changed and stored in bulkUpdateFields at some point?
            const wasFieldTouched = !!newValue?.includes(BULK_FIELD_TOUCHED_PLACEHOLDER_VALUE);

            if (!isMultipleSelected && Array.isArray(newValue) && wasFieldTouched) {
                // Set it for bulk update in changedBulkUpdateFields again
                onFieldChange(subThermalId, name, newValue, tabName);
            }

            const hasChangedBulkUpdateFields = Object.keys(changedBulkUpdateFields)?.length > 0;

            if (isMultipleSelected && hasChangedBulkUpdateFields) {
                // If isMultipleSelected then need to check if this field was edited and clear that field's value from changedBulkUpdateFields
                // If it was the only one, then need to clear changedBulkUpdateFields so it is not used in form submit
                const editingBulkUpdateFields = { ...changedBulkUpdateFields };
                const tabObjects = editingBulkUpdateFields[subThermalId];
                const tabChangedColumns = tabObjects[tabName];

                if (tabChangedColumns) {
                    // Remove that column from changed bulk update fields
                    delete tabChangedColumns[name];
                    setChangedBulkUpdateFields((prev) => {
                        return {
                            ...prev,
                            [subThermalId]: {
                                ...prev[subThermalId],
                                [tabName]: tabChangedColumns,
                            },
                        };
                    });

                    const numberOfTabColumnsChanged = Object.keys(tabChangedColumns)?.length;
                    if (numberOfTabColumnsChanged === 0) {
                        // Remove tab object from subid if it no longer has any changed columns
                        delete tabObjects[tabName];
                        setChangedBulkUpdateFields((prev) => {
                            return {
                                ...prev,
                                [subThermalId]: tabObjects,
                            };
                        });
                    }

                    const numberOfTabObjectChanged = Object.keys(tabObjects)?.length;
                    if (numberOfTabObjectChanged === 0) {
                        // Remove subid object from changedBulkUpdateFields
                        delete editingBulkUpdateFields[subThermalId];
                        setChangedBulkUpdateFields(editingBulkUpdateFields);
                    }

                    const numberOfBulkUpdateSubIds = Object.keys(editingBulkUpdateFields)?.length;

                    if (numberOfBulkUpdateSubIds === 0) {
                        clearBulkUpdateState();
                    }
                }
            }
        },
        [bulkUpdateFields, changedBulkUpdateFields, clearBulkUpdateState, onFieldChange, updateAllVariedByDate]
    );

    const onToggleAllVariedByDate = useCallback(
        (subThermalId, tabName) => {
            const isAllVariedByDateChecked = allVariedByDate[subThermalId][tabName] === CHECKED;
            const isAllVariedByDateIndeterminate = allVariedByDate[subThermalId][tabName] === INDETERMINATE;

            const fields = {};

            for (const fieldName in bulkUpdateFields[subThermalId][tabName]) {
                const oldValue = bulkUpdateFields[subThermalId][tabName][fieldName];
                const newValue = isAllVariedByDateChecked
                    ? oldValue.filter((c) => c !== MULTIPLE_PLACEHOLDER_VALUE)
                    : oldValue.includes(MULTIPLE_PLACEHOLDER_VALUE)
                    ? oldValue
                    : oldValue.concat(MULTIPLE_PLACEHOLDER_VALUE);

                fields[fieldName] = newValue;
            }

            const updatedBulkFields = {
                ...bulkUpdateFields,
                [subThermalId]: { ...bulkUpdateFields[subThermalId], [tabName]: fields },
            };

            const allVariedByDateState = isAllVariedByDateIndeterminate
                ? CHECKED
                : isAllVariedByDateChecked
                ? UNCHECKED
                : CHECKED;

            setBulkUpdateFields(updatedBulkFields);
            setAllVariedByDate((prev) => ({
                ...prev,
                [subThermalId]: { ...prev[subThermalId], [tabName]: allVariedByDateState },
            }));
        },
        [bulkUpdateFields, allVariedByDate]
    );

    return useMemo(
        () => ({
            bulkUpdateVisibility,
            bulkUpdateLoading,
            bulkUpdateFields,
            changedBulkUpdateFields,
            allVariedByDate,
            generateFields,
            getBulkUpdateState,
            onFieldChange,
            onToggleVariedByDate,
            onToggleAllVariedByDate,
            clearBulkUpdateState,
            setServerErrors,
        }),
        [
            bulkUpdateVisibility,
            bulkUpdateLoading,
            bulkUpdateFields,
            changedBulkUpdateFields,
            allVariedByDate,
            generateFields,
            getBulkUpdateState,
            onFieldChange,
            onToggleVariedByDate,
            onToggleAllVariedByDate,
            clearBulkUpdateState,
            setServerErrors,
        ]
    );
};

export default useBulkUpdate;
