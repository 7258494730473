import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading, SimpleGrid } from '@chakra-ui/react';

import ComboboxFormikField from '../forms/ComboboxFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';

import HelpButtonComponent from 'components/utils/HelpButtonComponent';

import { helpAnchors } from 'constants/help';
import { SLICEOFDAY_PARTIAL_DELIVERABILITY } from 'constants/autoforms';

import { FEATURE_FLAG_NAMES } from '../../constants/featureFlags';
import { useIsFeatureActive } from '../../hooks/features-flags';
import { isNumberCellEmpty } from 'services/items';

const ThermalDefinitions = ({ idx, itemTypeId, formLabels, data, type, helpPageUrlItemTypeId = null }) => {
    const isCAMSliceOfDayVisible = useIsFeatureActive(FEATURE_FLAG_NAMES.CAM_SLICEOFDAY_FIELDS_VISIBLE);
    const [pcdsPercentValue, setPcdsPercentValue] = useState(null);
    const [pcdsPercentDisabled, setPcdsPercentDisabled] = useState(true);
    const [pcdsPercentWarningMessage, setPcdsPercentWarningMessage] = useState(null);
    const [pcdsPercentDisplayWarning, setPcdsPercentDisplayWarning] = useState(false);

    useEffect(() => {
        if (isCAMSliceOfDayVisible) {
            const subThermalDeliverabilityStatusId = data.definitions?.subThermals[idx]?.deliverabilityStatusId ?? -1;
            const selectedDeliverability = data.definitions?.deliverabilityStatusOptions
                ?.find((element) => element.id === subThermalDeliverabilityStatusId)
                ?.description?.trim()
                .toUpperCase();
            if (selectedDeliverability && SLICEOFDAY_PARTIAL_DELIVERABILITY === selectedDeliverability && pcdsPercentDisabled) {
                setPcdsPercentDisabled(false);
            }
        }
    }, [isCAMSliceOfDayVisible, data, idx, pcdsPercentDisabled]);

    const onDeliverabilityStatusChange = async (value) => {
        if (isCAMSliceOfDayVisible) {
            const selectedDeliverability = data.definitions?.deliverabilityStatusOptions
                ?.find((x) => x.id === value)
                ?.description?.trim()
                .toUpperCase();
            if (SLICEOFDAY_PARTIAL_DELIVERABILITY === selectedDeliverability) {
                setPcdsPercentDisabled(false);
            } else {
                setPcdsPercentDisabled(true);
                setPcdsPercentDisplayWarning(false);
                setPcdsPercentWarningMessage(null);
            }
        }
    };

    const validatePCDSPercentWarning = async (value) => {
        if (isCAMSliceOfDayVisible) {
            value = isNumberCellEmpty(value);
            if (pcdsPercentValue !== value) {
                setPcdsPercentValue(value);
                if (value === null) {
                    setPcdsPercentDisplayWarning(true);
                    setPcdsPercentWarningMessage(<FormattedMessage id="thermal_pcds_percent_warning" />);
                } else {
                    setPcdsPercentDisplayWarning(false);
                    setPcdsPercentWarningMessage(null);
                }
            }
        }
    };

    return (
        <>
            <Heading as="h4" variant="h4" textTransform="capitalize">
                <FormattedMessage id="unit_definitions" />
                <HelpButtonComponent
                    helpAnchor={
                        type === 'generation asset'
                            ? helpAnchors.THERMAL_UNIT_DEFINITIONS
                            : helpAnchors.DSM_UNIT_DEFINITIONS
                    }
                    itemTypeId={helpPageUrlItemTypeId}
                />
            </Heading>

            <SimpleGrid columns={{ xl: 4 }} spacingX={6}>
                <ComboboxFormikField
                    id={`subThermals[${idx}].unitTypeId`}
                    name={`subThermals[${idx}].unitTypeId`}
                    mt={3}
                    label={formLabels.unitType}
                    options={data.definitions.unitTypes}
                    labelKey="description"
                    valueKey="id"
                    placeholderLabel="-"
                    placeholderValue={null}
                    showPlaceholder={true}
                />
                {isCAMSliceOfDayVisible && (
                    <>
                    <ComboboxFormikField
                        id={`subThermals[${idx}].deliverabilityStatusId`}
                        name={`subThermals[${idx}].deliverabilityStatusId`}
                        label={<FormattedMessage id="thermal_deliverability_status" />}
                        options={data.definitions.deliverabilityStatusOptions}
                        labelKey="description"
                        valueKey="id"
                        onChange={onDeliverabilityStatusChange}
                        mt={3}
                        placeholderValue={null}
                        showPlaceholder={true}
                    />
                    <NumberInputFormikField
                        id={`subThermals[${idx}].pcdsPercent`}
                        name={`subThermals[${idx}].pcdsPercent`}
                        keepWithinRange={true}
                        min={0}
                        max={100}
                        step={1}
                        label={<FormattedMessage id="thermal_pcds_percent" />}
                        isDisabled={pcdsPercentDisabled}
                        isRequired={!pcdsPercentDisabled}
                        mt={3}
                        validate={validatePCDSPercentWarning}
                        warning={pcdsPercentWarningMessage}
                        showWarning={pcdsPercentDisplayWarning}
                    />
                    </>
                )}

                {itemTypeId !== 2 && ( // dsmLoadId is excluded in Thermal(Generation asset)
                    <ComboboxFormikField
                        id={`subThermals[${idx}].dsmLoadId`}
                        name={`subThermals[${idx}].dsmLoadId`}
                        mt={3}
                        label={formLabels.dsmLoad}
                        options={data.definitions.dsmLoads}
                        labelKey="description"
                        valueKey="id"
                        placeholderLabel="-"
                        placeholderValue={null}
                        showPlaceholder={true}
                    />
                )}
            </SimpleGrid>
        </>
    );
};

export default ThermalDefinitions;
