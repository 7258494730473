import { SimpleGrid, FormControl, Box, Text, Radio, Flex, FormErrorMessage } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import DateField from '../forms/DateField';
import NumberInputField from '../forms/NumberInputField';
import CustomCheckbox from '../forms/CustomCheckbox';
import RadioGroupField from '../forms/RadioGroupField';
import ComboboxField from '../forms/ComboboxField';

import { ReactComponent as WarningIcon } from '../../icons/warning.svg';

const SettingsTab = ({
    settings,
    toggleSettings,
    selectedPeakPeriods,
    selectedSensitivity,
    selectedReports,
    selectedTimeIntervals,
    runDate,
    setRunDate,
    isRunReportsNowChecked,
    onIsRunReportsNowChanged,
    isDeterministicChecked,
    onIsDeterministicChanged,
    timeStepper,
    setTimeStepper,
    priority,
    setPriority,
    curvePrice,
    setCurvePrice,
    curveSource,
    setCurveSource,
    simulations,
    setSimulations,
    foSimulations,
    setFOSimulations,
    isFOSimulationsFieldVisible,
    errors,
    studyType,
    setStudyType,
    isDoNotDeleteChecked,
    onIsDoNotDeleteChanged,
    isTemplateChecked,
    onIsTemplateChanged,
}) => {
    const intl = useIntl();

    const priorityOptions = [
        { id: 'Low', description: 'Low' },
        { id: 'Normal', description: 'Normal' },
        { id: 'High', description: 'High' },
        { id: 'Urgent', description: 'Urgent' },
    ];

    const reportOptions = [
        { id: 'PTDPAYOFF', description: 'Prior to Delivery Payoffs Reports' },
        { id: 'SIMREPORTS', description: 'Simulation Validation Reports' },
    ];

    return (
        <>
            <Box mb={5} pb={5} borderBottom="1px solid" borderColor="border-secondary">
                <SimpleGrid columns={{ base: 1, lg: 2 }} gap={4}>
                    <Flex
                        gridGap={4}
                        pr={4}
                        borderRight="1px solid"
                        borderColor={{ base: 'transparent', lg: 'border-secondary' }}
                    >
                        <Box width="2xs">
                            <ComboboxField
                                mb={0}
                                id="studyType"
                                name="studyType"
                                label={intl.formatMessage({ id: 'run_a_study_settings_study_type' })}
                                options={settings.studyTypes}
                                valueKey="id"
                                labelKey="description"
                                value={studyType}
                                onChange={setStudyType}
                            />
                        </Box>
                        {/* <Flex align="flex-end">  */}
                        <Flex align="center">
                            <CustomCheckbox isChecked={isTemplateChecked} onChange={onIsTemplateChanged.toggle}>
                                <FormattedMessage id="run_a_study_settings_is_a_template" />
                            </CustomCheckbox>
                        </Flex>
                        {/* <Flex align="flex-end">  */}
                        <Flex align="center">
                            <CustomCheckbox isChecked={isDoNotDeleteChecked} onChange={onIsDoNotDeleteChanged.toggle}>
                                <FormattedMessage id="run_a_study_settings_do_not_delete" />
                            </CustomCheckbox>
                        </Flex>
                    </Flex>

                    <SimpleGrid columns={3} gap={4} pl={{ base: 0, lg: 2 }}>
                        <ComboboxField
                            mb={0}
                            id="timeStepSize"
                            name="timeStepSize"
                            label={intl.formatMessage({ id: 'run_a_study_settings_time_step' })}
                            options={settings.timeStepSize}
                            valueKey="id"
                            labelKey="description"
                            value={timeStepper}
                            onChange={setTimeStepper}
                        />

                        <NumberInputField
                            id="numberOfSimulations"
                            name="numberOfSimulations"
                            label={intl.formatMessage({ id: 'run_a_study_settings_simulations' })}
                            placeholder={intl.formatMessage({ id: 'common_select_number' })}
                            min={1}
                            precision={0}
                            isDisabled={isDeterministicChecked}
                            value={isDeterministicChecked ? 1 : simulations}
                            onChange={(e) => setSimulations(e)}
                        />

                        {isFOSimulationsFieldVisible && (
                            <NumberInputField
                                id="numberOfFOSimulations"
                                name="numberOfFOSimulations"
                                label={intl.formatMessage({ id: 'run_a_study_settings_fo_simulations' })}
                                placeholder={intl.formatMessage({ id: 'common_select_number' })}
                                min={1}
                                precision={0}
                                isDisabled={isDeterministicChecked}
                                value={isDeterministicChecked ? 1 : foSimulations}
                                onChange={(e) => setFOSimulations(e)}
                            />
                        )}
                        {/* Deterministic checkbox disables both sim and FO sim counts and sends "1" to the database.  */}
                        <Flex align="center">
                            <CustomCheckbox
                                isChecked={isDeterministicChecked}
                                onChange={onIsDeterministicChanged.toggle}
                            >
                                <FormattedMessage id="run_a_study_settings_deterministic" />
                            </CustomCheckbox>
                        </Flex>
                    </SimpleGrid>
                </SimpleGrid>
            </Box>
            <Box mb={5} pb={10} borderBottom="1px solid" borderColor="border-secondary">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                    <Box gridColumnStart="1" gridColumnEnd="2">
                        <Text mb={3}>
                            <FormattedMessage id="run_a_study_settings_run_schedule" />
                        </Text>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                            <Box mb="40px">
                                <DateField
                                    mb={5}
                                    maxWidth="300px"
                                    id="scheduleStartDate"
                                    name="scheduleStartDate"
                                    label={intl.formatMessage({ id: 'run_a_study_settings_date_and_time' })}
                                    isDisabled={isRunReportsNowChecked}
                                    value={runDate}
                                    onChange={(date) => setRunDate(date)}
                                    utc={false}
                                />

                                <CustomCheckbox
                                    isChecked={isRunReportsNowChecked}
                                    onChange={onIsRunReportsNowChanged.toggle}
                                >
                                    <FormattedMessage id="run_a_study_settings_date_picker_run_now" />
                                </CustomCheckbox>
                            </Box>
                        </SimpleGrid>
                    </Box>
                    <Box>
                        <Text mb={3}>
                            <FormattedMessage id="run_a_study_settings_curve_settings" />
                        </Text>

                        <ComboboxField
                            mb={3}
                            id="curvePrice"
                            name="curvePrice"
                            label={intl.formatMessage({ id: 'run_a_study_settings_curve_price' })}
                            options={settings.curvePriceTypes}
                            valueKey="id"
                            labelKey="description"
                            value={curvePrice}
                            onChange={setCurvePrice}
                        />

                        <ComboboxField
                            mb={3}
                            id="curveSource"
                            name="curveSource"
                            label={intl.formatMessage({ id: 'run_a_study_settings_curve_source' })}
                            options={settings.curveSources}
                            valueKey="id"
                            labelKey="description"
                            value={curveSource}
                            onChange={setCurveSource}
                        />
                    </Box>
                </SimpleGrid>

                <RadioGroupField
                    label={<FormattedMessage id="run_a_study_settings_priority" />}
                    name="settingsPriority"
                    value={priority}
                    onChange={setPriority}
                >
                    {priorityOptions.map(({ id, description }) => (
                        <Radio key={id} value={id} mr={5}>
                            {description}
                        </Radio>
                    ))}
                </RadioGroupField>
            </Box>
            <FormControl id="options">
                <Box mb={5} pb={10} borderBottom="1px solid" borderColor="border-secondary">
                    <Text mb={3}>
                        <FormattedMessage id="run_a_study_settings_report_requirements" />
                    </Text>
                    <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                        {reportOptions.map(({ id, description }) => (
                            <CustomCheckbox
                                key={id}
                                isChecked={selectedReports.includes(id)}
                                onChange={() => toggleSettings(id, 'report')}
                            >
                                {description}
                            </CustomCheckbox>
                        ))}
                    </SimpleGrid>
                </Box>

                <Box mb={5} pb={10} borderBottom="1px solid" borderColor="border-secondary">
                    <FormControl isInvalid={errors.timeIntervals}>
                        <Flex mb={3} align="center">
                            <Text display="flex" align="center">
                                <FormattedMessage id="run_a_study_settings_time_intervals" />
                            </Text>
                            {errors.timeIntervals && (
                                <Flex ml={2} gridGap={3}>
                                    <WarningIcon />
                                    <FormErrorMessage mt={0}>{errors.timeIntervals}</FormErrorMessage>
                                </Flex>
                            )}
                        </Flex>
                        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                            {settings.dateIntervals.map(({ id, description }) => (
                                <CustomCheckbox
                                    key={id}
                                    isChecked={selectedTimeIntervals.includes(id)}
                                    onChange={() => toggleSettings(id, 'time')}
                                >
                                    {description}
                                </CustomCheckbox>
                            ))}
                        </SimpleGrid>
                    </FormControl>
                </Box>
                <Box mb={5} pb={10} borderBottom="1px solid" borderColor="border-secondary">
                    <FormControl isInvalid={errors.peakPeriods}>
                        <Flex mb={3} align="center">
                            <Text display="flex" align="center">
                                <FormattedMessage id="run_a_study_settings_peak_periods" />
                            </Text>
                            {errors.peakPeriods && (
                                <Flex ml={2} gridGap={3}>
                                    <WarningIcon />
                                    <FormErrorMessage mt={0}>{errors.peakPeriods}</FormErrorMessage>
                                </Flex>
                            )}
                        </Flex>

                        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                            {settings.peakPeriods.map(({ id, description }) => (
                                <CustomCheckbox
                                    key={id}
                                    isChecked={selectedPeakPeriods.includes(id)}
                                    onChange={(event) => toggleSettings(id, 'peak')}
                                >
                                    {description}
                                </CustomCheckbox>
                            ))}
                        </SimpleGrid>
                    </FormControl>
                </Box>

                <Box mb={5} pb={10} borderBottom="1px solid" borderColor="border-secondary">
                    <FormControl isInvalid={errors.sensitivity}>
                        <Flex mb={3} align="center">
                            <Text display="flex" align="center">
                                <FormattedMessage id="run_a_study_settings_sensitivity" />
                            </Text>
                            {errors.sensitivity && (
                                <Flex ml={2} gridGap={3}>
                                    <WarningIcon />
                                    <FormErrorMessage mt={0}>{errors.sensitivity}</FormErrorMessage>
                                </Flex>
                            )}
                        </Flex>

                        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                            {settings.sensitivities.map(({ id, description }) => (
                                <CustomCheckbox
                                    key={id}
                                    isChecked={selectedSensitivity.includes(id)}
                                    onChange={(event) => toggleSettings(id, 'sensitivity')}
                                >
                                    {description}
                                </CustomCheckbox>
                            ))}
                        </SimpleGrid>
                    </FormControl>
                </Box>
            </FormControl>
        </>
    );
};
export default SettingsTab;
