import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Books from './Books';
import Error from '../utils/Error';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import SidebarItemListContainer from '../autoform/SidebarItemListContainer';
import useAutoformLoadMetadata from '../autoform/hooks/useAutoformLoadMetadata';
import useAutoformMetadata from '../autoform/hooks/useAutoformMetadata';
import LayoutWrapper from '../layout/LayoutWrapper';
import WrapperEmptyState from '../layout/WrapperEmptyState';
import usersPlaceholder from '../../images/users-unselected.png';
import AutoformUiProvider from '../autoform/AutoformUiProvider';
import { ReactComponent as BookIcon } from '../../icons/book-closed-icon.svg';
import { setSearchTerm } from 'store/helpers/helpersSlice';

const BooksTabs = () => {
    const intl = useIntl();
    const AUTOFORM_CODE = 'PSBOOKMNG';
    const { isLoading, loadingError } = useAutoformLoadMetadata(AUTOFORM_CODE);
    const { metadata, hierarchicalTables } = useAutoformMetadata();
    const level1TableId = hierarchicalTables.level1[0]?.id;
    let { path } = useRouteMatch();
    const dispatch = useDispatch();

    useEffect(
        () => () => {
            dispatch(setSearchTerm(''));
        },
        [dispatch]
    );

    if (loadingError)
        return (
            <Box m={6}>
                <Error primaryId="common_error" secondaryId="common_loading_error" />
            </Box>
        );

    if (isLoading || !metadata) return <DefinitionsSkeleton />;
    return (
        <>
            <LayoutWrapper
                hasSecondaryMenu={true}
                sidebar={
                    <SidebarItemListContainer
                        schemaCode={metadata.schemaCode}
                        tableId={level1TableId}
                        config={{
                            addNewItemMessageId: 'user_management_add_new_book',
                            accordionPlaceholderId: 'user_management_select_book',
                            searchPlaceholderId: 'user_management_search_books',
                            addRecordModalItemType: intl.formatMessage({ id: 'user_management_book' }),
                            createSuccessMessage: intl.formatMessage({ id: 'user_management_book_create_success' }),
                            itemIcon: <BookIcon />,
                        }}
                    />
                }
                content={
                    <Switch>
                        <Route exact path={path}>
                            <WrapperEmptyState imgSrc={usersPlaceholder} page="books" noun="properties" item="book" />
                        </Route>

                        <Route path={`${path}/:itemId`}>
                            <AutoformUiProvider>
                                <Books />
                            </AutoformUiProvider>
                        </Route>
                    </Switch>
                }
            />
        </>
    );
};

export default BooksTabs;
