import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedItemForTable } from '../../../store/autoform/autoformSlice';

const useAutoformSelectItem = (tableId, itemId) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (itemId && tableId) {
            dispatch(setSelectedItemForTable({ itemId, tableId }));
        }
    }, [dispatch, itemId, tableId]);
};

export default useAutoformSelectItem;
