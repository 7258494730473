import { useState, useMemo } from 'react';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import debounce from 'lodash/debounce';

const SearchBar = ({ textHolder, onChange, variant = 'filled', searchTerm, ...rest }) => {
    const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);

    const debouncedValue = useMemo(() => debounce(onChange, 250), [onChange]);

    const onSearchTermChange = (event) => {
        setCurrentSearchTerm(event.target.value);
        debouncedValue(event.target.value);
    };

    return (
        <InputGroup>
            <StyledInput
                type="text"
                h={9}
                pl={10}
                pr={4}
                variant={variant}
                onChange={onSearchTermChange}
                placeholder={textHolder}
                value={currentSearchTerm}
                {...rest}
            />

            <InputLeftElement pointerEvents="none" zIndex="1">
                <SearchIcon />
            </InputLeftElement>
        </InputGroup>
    );
};

const StyledInput = styled(Input)`
    &:focus + div {
        color: var(--chakra-colors-blue-400) !important;
    }
`;

export default SearchBar;
