import { MenuItem, Text, useColorMode } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import CustomCheckbox from '../../forms/CustomCheckbox';

const NavigationDarkModeCheckbox = ({ config }) => {
    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <Wrapper onClick={toggleColorMode}>
            <Text size="sm" position="absolute" left="16px" right="0" zIndex="1">
                {config.label}
            </Text>

            <CustomCheckbox isChecked={colorMode === 'dark'} onChange={toggleColorMode} tabIndex={-1} />
        </Wrapper>
    );
};

const Wrapper = styled(MenuItem)`
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding: 12px 16px;
    border-radius: var(--chakra-radii-md);

    > p {
        color: var(--chakra-colors-menu-item-text) !important;
    }

    &:hover,
    &:focus-within {
        background: var(--chakra-colors-menu-item-hover) !important;
    }
`;

export default NavigationDarkModeCheckbox;
