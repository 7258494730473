import { Box, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { UPDATE_TYPE_REPLACE, UPDATE_TYPE_UPDATE } from 'constants/uploadDownload';
import React from 'react';

export default function UpdateTypeSelect({ updateType, setUpdateType, ...rest }) {
    return (
        <Box {...rest}>
            <RadioGroup onChange={setUpdateType} value={updateType}>
                <Stack direction="row">
                    <Radio mr={2} value={UPDATE_TYPE_UPDATE}>
                        Update
                    </Radio>
                    <Radio value={UPDATE_TYPE_REPLACE}>Replace</Radio>
                </Stack>
            </RadioGroup>
        </Box>
    );
}
